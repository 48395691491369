import React, { useState } from 'react';

// Helpers
import styled from 'styled-components';
import APIConfig from '../../../config/api';
import useTranslation from '../../../hooks/useTranslation';

// Components
import { Button, Card, Typography } from 'antd';
import { ReactComponent as PrimaryLogo } from '../../../resources/app-logos/primary-logo.svg';

const SignIn = () => {
  const { t } = useTranslation('auth');

  const [loading, setLoading] = useState(false);

  const handleSignIn = () => {
    setLoading(true);
    window.location.href = `${APIConfig.authPublicApi}/azure/sign-in`;
  };

  return (
    <CardWrapper>
      <StyledLogo />
      <StyledDescription level={4}>
        {t('signIn.welcome')} <br /> {t('signIn.sign_in_label')}
      </StyledDescription>
      <Button block type="primary" onClick={handleSignIn} loading={loading}>
        {t('signIn.azure_sign_in')}
      </Button>
    </CardWrapper>
  );
};

const CardWrapper = styled(Card)`
  margin-top: -100px;
  width: 400px;
  height: auto;
  text-align: center;

  @media screen and (max-width: 576px) {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    padding: 30px;
    padding-top: 100px !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-card-body {
      width: 100%;
      padding: 10px;
    }
  }
`;

const StyledDescription = styled(Typography.Title)`
  text-align: center;
  color: ${({ theme }) => theme.textLightColor2} !important;
  padding: ${({ theme }) => theme.paddingXXl} 0;
`;

const StyledLogo = styled(PrimaryLogo)`
  width: 300px;
`;

export default SignIn;
