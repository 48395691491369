// helpers
import { SubRouteModel } from '../../../typings/routes';

// helper components & layouts
import PageLayout from '../../../layouts/PageLayout';
import Navigation from '../../../pages/Navigation';

// pages
import CashFlow from '../../../pages/Reporting/CashFlow';
import BalanceSheet from '../../../pages/Reporting/BalanceSheet';
import TrialBalance from '../../../pages/Reporting/TrialBalance';
import GeneralLedger from 'pages/Reporting/GeneralLedger';
import IncomeStatement from '../../../pages/Reporting/IncomeStatement';
import ExposureByCurrency from '../../../pages/Reporting/ExposureByCurrency';
import ThresholdReporting from 'pages/Reporting/ThresholdReporting';
import EntityDetailsChanges from 'pages/Reporting/EntityDetailsChanges';
import ClientGroupAndRevenue from '../../../pages/Reporting/ClientGroupAndRevenue';
import ClientGroupAndBalances from '../../../pages/Reporting/ClientGroupAndBalances';

enum ReportingRoutesEnum {
  Reporting_Root = '/system-reporting',
  Reporting_BalanceSheet = '/system-reporting/balance-sheet',
  Reporting_TrialBalance = '/system-reporting/trial-balance',
  Reporting_IncomeStatement = '/system-reporting/income-statement',
  Reporting_ClientGroupBalance = '/system-reporting/client-group-balances',
  Reporting_ClientGroupRevenue = '/system-reporting/client-group-revenue',
  Reporting_ExposureByCurrency = '/system-reporting/exposure-by-currency',
  Reporting_GeneralLedger = '/system-reporting/general-ledger',
  Reporting_CashFlow = '/system-reporting/cash-flow',
  Reporting_ThresholdReporting = '/system-reporting/threshold-reporting',
  Reporting_EntryChangesLog = '/system-reporting/entry-changes-log',
}

const reportingRoutes: SubRouteModel[] = [
  {
    title: 'Reporting',
    path: ReportingRoutesEnum.Reporting_Root,
    layout: PageLayout,
    component: Navigation,
    permission: { permissionKey: 'Reporting_View' },
    childRoutes: [
      {
        title: 'Balance Sheet',
        path: ReportingRoutesEnum.Reporting_BalanceSheet,
        layout: PageLayout,
        component: BalanceSheet,
        permission: { permissionKey: 'Reporting_BalanceSheet_View' },
      },
      {
        title: 'Income Statement',
        path: ReportingRoutesEnum.Reporting_IncomeStatement,
        layout: PageLayout,
        component: IncomeStatement,
        permission: { permissionKey: 'Reporting_IncomeStatement_View' },
      },
      {
        title: 'Client Group and Balances',
        path: ReportingRoutesEnum.Reporting_ClientGroupBalance,
        layout: PageLayout,
        component: ClientGroupAndBalances,
        permission: { permissionKey: 'Reporting_ClientGroupAndBalances_View' },
      },
      {
        title: 'Client Group and Revenue',
        path: ReportingRoutesEnum.Reporting_ClientGroupRevenue,
        layout: PageLayout,
        component: ClientGroupAndRevenue,
        permission: { permissionKey: 'Reporting_ClientGroupAndRevenue_View' },
      },
      {
        title: 'Exposure by Currency',
        path: ReportingRoutesEnum.Reporting_ExposureByCurrency,
        layout: PageLayout,
        component: ExposureByCurrency,
        permission: { permissionKey: 'Reporting_ExposureByCurrency_View' },
      },

      {
        title: 'Trial Balance',
        path: ReportingRoutesEnum.Reporting_TrialBalance,
        layout: PageLayout,
        component: TrialBalance,
        permission: { permissionKey: 'Reporting_TrialBalance_View' },
      },

      {
        title: 'General Ledger',
        path: ReportingRoutesEnum.Reporting_GeneralLedger,
        layout: PageLayout,
        component: GeneralLedger,
        permission: { permissionKey: 'Reporting_GeneralLedger_View' },
      },

      {
        title: 'Cash Flow',
        path: ReportingRoutesEnum.Reporting_CashFlow,
        layout: PageLayout,
        component: CashFlow,
        permission: { permissionKey: 'Reporting_CashFlow_View' },
      },
      {
        title: 'Threshold Reporting Report',
        path: ReportingRoutesEnum.Reporting_ThresholdReporting,
        layout: PageLayout,
        component: ThresholdReporting,
        permission: { permissionKey: 'Reporting_Threshold_View' },
      },
      {
        title: 'Entity Detail Changes',
        path: ReportingRoutesEnum.Reporting_EntryChangesLog,
        layout: PageLayout,
        component: EntityDetailsChanges,
        permission: { permissionKey: 'Reporting_EntryChangesLog_View' },
      },
    ],
  },
];

export { reportingRoutes, ReportingRoutesEnum };
