import * as yup from 'yup';
import { STRING_VALIDATION_RULE } from 'validations/common';

export const NewGroupValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(2, 'Name is too short')
    .max(200, 'Name is too long')
    .required('This field is required'),
  description: STRING_VALIDATION_RULE.min(2, 'Description is too short')
    .max(200, 'Description is too long')
    .required('This field is required'),
});
