import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../../../../hooks/useFetch';
import useUserAccess from '../../../../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { ForexSpreadLimitModel } from '../../../../../../../../typings/banking/foreignExchange';
import {
  ClientGroupForexSpreadLimitModel,
  IClientGroup,
} from '../../../../../../../../typings/crm/client-group';
import {
  foreignExchangeAPI,
  NewSpreadForClientGroupModel,
} from '../../../../../../../../api/banking/foreignExchangeAPI';

// components
import SectionTitle from '../../../../../../../../components/Typography/SectionTitle';
import ForeignExchangeSpreadsTable from '../../../../../../../../components/Tables/TableTemplates/Accounting/ForeignExchangeSpreadsTable';
import EditSpreadModal, {
  FormValuesModel,
} from '../../../../../../../../components/Modals/TemplateModalDialogs/CRM/EditSpreadModal';
import { message, Modal } from 'antd';

interface IProps {
  clientGroup: IClientGroup;
}

const ForeignExchangeSection = ({ clientGroup }: IProps) => {
  const { t } = useTranslation(['crm', 'common', 'banking']);
  const [hasEditAccess] = useUserAccess([
    'CRM_ClientGroup_Details_FeeScheduleTab_SpreadMatrix_Edit',
  ]);
  const [updateTableTrigger, updateTable] = useState({});
  const [selectedSpreadEntry, setSelectedSpreadEntry] =
    useState<ForexSpreadLimitModel | null>(null);

  const { response, loading } = useFetch(
    () =>
      foreignExchangeAPI.fetchForeignExchangeSpreadsByClientGroupId(
        clientGroup.numericId,
      ),
    [clientGroup.numericId, updateTableTrigger],
  );

  const handleActionsClick = (key: string, record: ForexSpreadLimitModel) => {
    switch (key) {
      case 'update_spread_value':
        setSelectedSpreadEntry(record as ForexSpreadLimitModel);
        break;
    }
  };

  const handleUpdateModalClose = (shouldUpdateTable?: boolean) => {
    if (shouldUpdateTable) {
      updateTable({});
    }

    setSelectedSpreadEntry(null);
  };

  const handleFormSubmit = async (values: FormValuesModel) => {
    if (values.isDefault) {
      const newSpreadEntry: NewSpreadForClientGroupModel = {
        rangeId: values.spreadId,
        spread:
          AccountsHelpers.convertAmountFromIntToBigInt(values.spread) || 0,
      };

      await foreignExchangeAPI.createNewSpreadForClientGroup(
        String(clientGroup.numericId),
        newSpreadEntry,
      );
    } else {
      await foreignExchangeAPI.updateClientGroupSpreadById(values.spreadId, {
        spread:
          AccountsHelpers.convertAmountFromIntToBigInt(values.spread) || 0,
      });
    }
  };

  const handleResetToDefault = (spread: ClientGroupForexSpreadLimitModel) => {
    Modal.confirm({
      content: t('client_group.foreign_exchange.reset_to_default_msg'),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: () => resetSpreadToDefault(spread.id),
    });
  };

  const resetSpreadToDefault = async (spreadId: number) => {
    await foreignExchangeAPI.deleteSpreadEntryById(spreadId);
    message.success(
      t('client_group.foreign_exchange.reset_fee_schedule_success'),
    );
    setSelectedSpreadEntry(null);
    updateTable({});
  };

  return (
    <>
      <SectionTitle>
        {t('forex.foreign_exchange_title', { ns: 'banking' })}
      </SectionTitle>

      <ForeignExchangeSpreadsTable
        canEdit={hasEditAccess}
        loading={loading}
        data={response || []}
        total={response?.length || 0}
        onActionsClick={handleActionsClick}
      />

      <EditSpreadModal
        onSubmit={handleFormSubmit}
        isVisible={!!selectedSpreadEntry}
        spread={selectedSpreadEntry}
        closeCallback={handleUpdateModalClose}
        onResetToDefault={handleResetToDefault}
      />
    </>
  );
};

export default ForeignExchangeSection;
