import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { message } from 'antd';
import { forexTransfersTemplateAPI } from '../../../../../api/approval/approvalManagement/forexTransfersTemplateAPI';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';

// components
import ApprovalGroupsModal, {
  ApprovalGroupFormValuesModel,
} from '../../../../../components/Modals/TemplateModalDialogs/ApprovalManagement/ApprovalGroupsModal';

type IProps = RequiredPropsForModalDialogModel;

const AddApprovalGroupModal = ({ isVisible, closeCallback }: IProps) => {
  const { t } = useTranslation(['approval_management', 'server_errors']);

  const initialFormValues: ApprovalGroupFormValuesModel = {
    name: '',
    description: '',
    participants: [],
    approvalWorkflowType: 'forex-transfers',
  };

  const handleFormSubmit = async (newGroup: ApprovalGroupFormValuesModel) => {
    await forexTransfersTemplateAPI.createApprovalGroup(newGroup);
    message.success(t('approval_management.approval_groups.success_create'));
  };

  return (
    <ApprovalGroupsModal
      title={t('approval_management.approval_groups.add_group')}
      onSubmit={handleFormSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
    />
  );
};

export default AddApprovalGroupModal;
