import React, { memo, useMemo } from 'react';

// helpers
import { StyledComponentProps } from '../../../../typings/common';
import { ButtonType as AntDButtonTypes } from 'antd/lib/button';

// components
import { Button as AntDButton } from 'antd';

type ButtonHTMLTypes = 'button' | 'submit' | 'reset';

export type ButtonSizes = 'middle' | 'large';
export type ButtonTypes = 'primary' | 'bordered' | 'dashed' | 'text';

export interface ButtonProps {
  size?: ButtonSizes;
  type?: ButtonTypes;
  loading?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  fullWidth?: boolean;
  danger?: boolean;
  htmlType?: ButtonHTMLTypes;
  icon?: React.ReactNode;
}

interface IProps extends ButtonProps, StyledComponentProps {
  children: React.ReactNode;
}

const Button = memo(
  ({
    children,
    size = 'middle',
    type = 'primary',
    disabled,
    loading,
    fullWidth,
    danger,
    onClick,
    htmlType,
    icon,
    ...rest
  }: IProps) => {
    const mappedType = useMemo<AntDButtonTypes | undefined>(() => {
      if (!type) {
        return undefined;
      }

      let result: AntDButtonTypes | undefined = undefined;
      switch (type) {
        case 'primary':
          result = 'primary';
          break;

        case 'bordered':
          result = 'default';
          break;

        case 'dashed':
          result = 'dashed';
          break;

        case 'text':
          result = 'text';
          break;
      }

      return result;
    }, [type]);

    return (
      <AntDButton
        size={size}
        type={mappedType}
        disabled={disabled}
        loading={loading}
        block={fullWidth}
        onClick={onClick}
        danger={danger}
        htmlType={htmlType}
        icon={icon}
        {...rest}
      >
        {children}
      </AntDButton>
    );
  },
);

export default Button;
