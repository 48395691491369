import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { ClientApprovalEntryModel } from 'apiAdapters/approval/clientAccountManagementRulesAdapterAPI';

// components
import Text from '@core_components/Text';
import StatusLabel, { LABEL_STATUSES } from 'components/Typography/StatusLabel';

export type CircleServiceTemplateDataModel = {
  connected: boolean;
};

interface IProps {
  approvalEntry: ClientApprovalEntryModel<CircleServiceTemplateDataModel>;
}

const CircleService = ({ approvalEntry }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <Text variant="body1">
      {`${t(
        'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.circle_service_changes_template.action',
      )} `}

      {approvalEntry.changes.new?.connected ? (
        <StatusLabel
          status={LABEL_STATUSES.success}
          text={t(
            'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.circle_service_changes_template.actions.connect',
          )}
        />
      ) : (
        <StatusLabel
          status={LABEL_STATUSES.error}
          text={t(
            'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.circle_service_changes_template.actions.disconnect',
          )}
        />
      )}
    </Text>
  );
};

export default CircleService;
