import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { NotificationStatuses } from 'enums/admin/orac';

// components
import StatusLabel from '../../../Typography/StatusLabel';

const STATUS_MAP = {
  info: [NotificationStatuses.New],
  warning: [],
  success: [NotificationStatuses.Processed],
  error: [NotificationStatuses.Error],
};

interface IProps {
  status: NotificationStatuses;
}

const NotificationStatus = memo(({ status }: IProps) => {
  const { t } = useTranslation('orac');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(`notifications.notification_statuses.${status || 'none'}`)}
    />
  );
});

export default NotificationStatus;
