import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { useField } from 'formik';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import CountryFormSelect from 'components/Forms/FormComponents/SelectInputs/CountryFormSelect';
import TrueFalseFormSelect from 'components/Forms/FormComponents/SelectInputs/TrueFalseFormSelect';
import { Col, Row } from 'antd';

interface IProps {
  namePrefix: string;
  disabled?: boolean;
}

export interface FormValuesModel {
  id?: string;
  name: string;
  isRegulated: boolean | null;
  regulatedCountries: string[];
}

/* Related validation schema: onboarding-new/structure/OrganizationShortInfoFormValidationSchema **/
const OrganizationShortInfoForm = ({ namePrefix, disabled }: IProps) => {
  const { t } = useTranslation('crm');
  const [field] = useField<FormValuesModel>(namePrefix);

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col span="12">
          <FormField
            label={t(
              'client_group.structure.relationships.form_fields.organization.legal_name',
            )}
            name={`${namePrefix}.name`}
            component={FormInput}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col span="12">
          <FormField
            label={t(
              'client_group.structure.relationships.form_fields.organization.is_regulated',
            )}
            name={`${namePrefix}.isRegulated`}
            component={TrueFalseFormSelect}
            disabled={disabled}
          />
        </Col>

        {field.value?.isRegulated && (
          <Col span="12">
            <FormField
              label={t(
                'client_group.structure.relationships.form_fields.organization.regulation_countries',
              )}
              name={`${namePrefix}.regulatedCountries`}
              component={CountryFormSelect}
              additionalProps={{ mode: 'multiple' }}
              disabled={disabled}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default OrganizationShortInfoForm;
