import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { IDocumentForm } from 'components/Forms/FormComponents/AdditionalFieldNew';
import { IComplianceInfo } from '../../../../../../../../typings/crm/client-group';
import { SubmitClientGroupComplianceDataValidationSchema } from 'validations/crm/clientGroups';

// components
import Form from '@core_components/Form';
import SubmitButton from '@common_components/Buttons/SubmitButton';
import { Col, Row } from 'antd';
import {
  default as ComplianceFormTemplate,
  FormValuesModel,
} from '../../../../../../../../components/Forms/TemplateForms/CRM/ComplianceForm';

interface IProps {
  complianceInformation?: IComplianceInfo | null;
  isViewOnly: boolean;
  onSubmit: (values: FormValuesModel) => void;
}

const ComplianceForm = ({
  complianceInformation,
  isViewOnly,
  onSubmit,
}: IProps) => {
  const { t } = useTranslation('common');

  const initialValues = useMemo<FormValuesModel | null>(() => {
    if (!complianceInformation) {
      return null;
    }

    const riskRatingMatrixDocument =
      complianceInformation.riskRatingMatrixDocumentJson
        ? ([
            JSON.parse(complianceInformation.riskRatingMatrixDocumentJson),
          ] as IDocumentForm[])
        : [];

    return {
      riskRatingMatrixDocument: riskRatingMatrixDocument?.map((e) => {
        const file = e.files[0];
        return {
          id: e.id,
          fileId: file.id,
          file: { ...file, contentType: 'text/plain', path: '', _id: '' },
          name: file.name,
        };
      }),
      riskLevel: complianceInformation.riskLevel,
      dueDiligence: complianceInformation.dueDiligence,
      dueDiligenceDetails: complianceInformation.dueDiligenceDetails,
    };
  }, [complianceInformation]);

  return (
    <>
      <Form<FormValuesModel>
        onSubmit={onSubmit}
        disabled={isViewOnly}
        initialValues={initialValues}
        validationSchema={SubmitClientGroupComplianceDataValidationSchema}
        renderForm={
          <>
            <ComplianceFormTemplate />
            <Row justify="end">
              <Col>
                <SubmitButton type="primary" disabled={isViewOnly}>
                  {t('save')}
                </SubmitButton>
              </Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default ComplianceForm;
