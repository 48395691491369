import React from 'react';

// helpers
import { AnswerModel } from '../../../../../api/crm/onboarding/onboardingAnswerAPI';
import { IDocumentForm } from 'components/Forms/FormComponents/AdditionalFieldNew';
import { HighlightFieldStatuses } from '@core_components/FormField';
import { ApplicationDocumentationValidationSchema } from '../../../../../validations/crm/clientGroups';
import { FormValuesModel as ActionButtonsFormValuesModel } from './ActionButtons';
import {
  AdditionalFieldType,
  OnboardType,
  RelationshipRelatedTypes,
} from '../../../../../enums/crm/crm';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';

export interface AdditionalFieldFormItemModel {
  _id: string;
  itemId: string;
  itemType: OnboardType;
  clientGroupId: string;

  requestedByAdmin: boolean;

  isApproved: boolean | null;
  isRejected: boolean | null;
  notReviewed: boolean | null;
  reviewComment: string;

  isFinalized: boolean;

  answer: {
    providedBy?: {
      id: string;
      name: string;
    };
    isProvided: boolean;
    valueJSON: string;
    reason: string;
  } | null;

  field: {
    type: AdditionalFieldType;
    name: string;
    relatedTo: RelationshipRelatedTypes;
    isTemplateField: boolean;
    placeholder: string;
    description: string;
    options: {
      selectOptions: { valueJSON: string; type: string }[];
      selectOptionsValues: { valueJSON: string; type: string }[];
      onboardingRequired: boolean;
      formDocument: IDocumentForm | null;
      documentRequiresCertification: boolean;
      isForRegulatedOnly: boolean;
    };
  };

  sources: {
    type: 'relationship' | 'client' | 'admin';
    id: string;
    templateId: string;
  }[];
}

export interface FormValuesModel extends ActionButtonsFormValuesModel {
  reviewProcess: {
    type: 'contact-review' | 'client-group-review';
    entryId: string;
  };

  itemId: string;
  itemType: OnboardType;

  additionalFields: AdditionalFieldFormItemModel[];
  additionalDocuments: AnswerModel[];
}

interface IProps {
  onSubmit: (values: FormValuesModel) => Promise<void>;
  initialFormValues: FormValuesModel;
  isViewOnly?: boolean;
  highlightFields?: Record<string, HighlightFieldStatuses>;
}

const ApplicationDocumentationForm = ({
  initialFormValues,
  onSubmit,
  isViewOnly,
  highlightFields,
}: IProps) => {
  return (
    <Form
      highlightFields={highlightFields}
      disabled={isViewOnly}
      validationSchema={ApplicationDocumentationValidationSchema}
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      confirmExitWithoutSaving
      renderForm={<InnerForm />}
    />
  );
};

export default ApplicationDocumentationForm;
