import { CANADA_DISTRICTS, UNITED_STATES_DISTRICTS } from 'constants/districts';

const CountryHelpers = {
  getDistrict: function (country: string, district: string) {
    let result = '';

    switch (country) {
      case 'US':
        result = UNITED_STATES_DISTRICTS[district];
        break;
      case 'CA':
        result = CANADA_DISTRICTS[district];
        break;
    }

    return result || district;
  },
};

export { CountryHelpers };
