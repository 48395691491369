import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { FilterKeys } from '..';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';

// components
import { SearchInput } from 'components/Fields/SearchInput';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Popover, Row, Typography } from 'antd';

interface IProps {
  handleChange: (key: FilterKeys, value: any) => void;
}

const FilterBar = ({ handleChange }: IProps) => {
  const { t } = useTranslation('finance');

  return (
    <Row gutter={[16, 16]}>
      <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
        <Row>
          <StyledCol span={24}>
            <Typography.Text ellipsis>
              {t('journals.table.search_journals_by')}
            </Typography.Text>
            <Popover
              content={t('journals.table.search_information')}
              placement="top"
            >
              <StyledInfoCircleOutlined />
            </Popover>
          </StyledCol>
          <Col span={24}>
            <SearchInput
              size="large"
              placeholder={t('journals.table.type_description')}
              onSearch={(searchQuery: string) =>
                handleChange('search', searchQuery)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  margin-left: ${({ theme }) => theme.marginXs};
  color: ${({ theme }) => theme.warningColor};
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export default FilterBar;
