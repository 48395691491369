import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useQueryParam from '@hooks/useQueryParam';
import useTranslation from 'hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { TransferTemplateTypes } from 'enums/finance/transferTemplates';
import { ClientTransactionTypes } from 'enums/banking/transactions';
import { TransactionDocumentModel } from 'typings/finance/transaction';
import { ClientTransferApprovalStatuses } from 'enums/transfers/transfers';

// components
import Link from '@common_components/Texts/Link';
import DocumentLink from 'components/Typography/DocumentLink';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import ClientTransactionStatus from 'components/Additional/Statuses/ClientTransactionStatus';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

export type InternalTransactionSenderData = {
  type: ClientTransactionTypes;
  purpose: string;
  amount: string;
  postingDate: string;
  valueDate: string;
  requestedBy: string;
  status: ClientTransferApprovalStatuses;
  workflowExpirationDate: number;
  template: { id: number; name: string } | null;
  relatedDocuments: TransactionDocumentModel[];
  transactionNumber: string;
  orderingCustomer: {
    accountName: string;
    accountNumber: string;
    address: string;
    reference: string;
  };
  beneficiary: {
    accountName: string;
    accountNumber: string;
    reference: string;
  };
};

interface IProps {
  journalEntryId: number | null;
  transaction: InternalTransactionSenderData;
}

const ValueWrapper = memo(({ children }) => {
  return (
    <EllipsisTooltip maxTextContainerWidth="500px" title={children}>
      {children}
    </EllipsisTooltip>
  );
});

const Sender = memo(({ transaction, journalEntryId }: IProps) => {
  const { t } = useTranslation('banking');
  const history = useHistory();

  const clientGroupId = useQueryParam({ param: 'id' });

  const descriptionData = useMemo<DescriptionItem[]>(() => {
    let result: DescriptionItem[] = [];
    const {
      type,
      purpose,
      orderingCustomer,
      beneficiary,
      amount,
      postingDate,
      valueDate,
      requestedBy,
      template,
      status,
      relatedDocuments,
      transactionNumber,
    } = transaction;

    result = [
      {
        label: t(
          'transactions.transaction_details.internal.transaction_number',
        ),
        description: transactionNumber,
      },
      {
        label: t('transactions.transaction_details.internal.type'),
        description: t(`transactions.types.${ClientTransactionTypes[type]}`),
      },

      {
        label: t('transactions.transaction_details.internal.purpose'),
        description: purpose,
      },

      {
        label: t('transactions.transaction_details.internal.ordering_customer'),
        description: (
          <StyledInnerDescriptionSection
            size="small"
            bordered={false}
            background="none"
            data={[
              {
                label: t(
                  'transactions.transaction_details.internal.account_name',
                ),
                description: (
                  <ValueWrapper>{orderingCustomer.accountName}</ValueWrapper>
                ),
              },

              {
                label: t(
                  'transactions.transaction_details.internal.account_number',
                ),
                description: (
                  <ValueWrapper>{orderingCustomer.accountNumber}</ValueWrapper>
                ),
              },

              {
                label: t('transactions.transaction_details.internal.address'),
                description: (
                  <ValueWrapper>{orderingCustomer.address}</ValueWrapper>
                ),
              },

              {
                label: t(
                  'transactions.transaction_details.internal.from_account_reference',
                ),
                description: (
                  <ValueWrapper>{orderingCustomer.reference}</ValueWrapper>
                ),
              },
            ]}
          />
        ),
      },

      {
        label: t('transactions.transaction_details.internal.beneficiary'),
        description: (
          <StyledInnerDescriptionSection
            size="small"
            bordered={false}
            background="none"
            data={[
              {
                label: t(
                  'transactions.transaction_details.internal.transfer_template',
                ),
                hidden: !template,
                description: (
                  <Link
                    onClick={() => {
                      history.push({
                        pathname: RoutePaths.CRM_Client_Group_Details,
                        search: `?id=${String(clientGroupId)}&tab=transferTemplates&templateId=${template?.id}&templateType=${
                          TransferTemplateTypes.Internal
                        }`,
                      });
                    }}
                  >
                    {AccountsHelpers.getTransferTemplateName(
                      String(template?.id),
                      String(template?.name),
                    )}
                  </Link>
                ),
              },
              {
                label: t(
                  'transactions.transaction_details.internal.account_name',
                ),
                description: (
                  <ValueWrapper>{beneficiary.accountName}</ValueWrapper>
                ),
              },

              {
                label: t(
                  'transactions.transaction_details.internal.account_number',
                ),
                description: (
                  <ValueWrapper>{beneficiary.accountNumber}</ValueWrapper>
                ),
              },

              {
                label: t(
                  'transactions.transaction_details.internal.to_account_reference',
                ),
                description: (
                  <ValueWrapper>{beneficiary.reference}</ValueWrapper>
                ),
              },
            ]}
          />
        ),
      },

      {
        label: t('transactions.transaction_details.internal.amount'),
        description: amount,
      },

      {
        label: t('transactions.transaction_details.internal.posting_date'),
        description: postingDate,
      },

      {
        label: t('transactions.transaction_details.internal.value_date'),
        description: valueDate,
      },

      {
        label: t('transactions.transaction_details.internal.requested_by'),
        description: requestedBy,
      },

      {
        label: t('transactions.transaction_details.internal.status'),
        description: (
          <ClientTransactionStatus
            transaction={{
              status,
              expiresAt: transaction.workflowExpirationDate,
            }}
          />
        ),
      },

      {
        label: t('transactions.transaction_details.internal.documents'),
        description: relatedDocuments.map(({ dmsId, name, linkToDownload }) => (
          <div key={dmsId}>
            <DocumentLink
              text={name}
              fileId={linkToDownload}
              documentId={dmsId}
            />
          </div>
        )),
      },
    ];

    return result;
  }, [t, transaction, journalEntryId, clientGroupId]);

  return <DescriptionSection data={descriptionData} />;
});

const StyledInnerDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-label {
    width: 250px !important;
    max-width: 250px !important;
  }
`;

export default Sender;
