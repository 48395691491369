import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';

// components
import { Select, SelectProps } from 'antd';

type IProps = SelectProps<any>;

const ActiveStatusSelect = (props: IProps) => {
  const { t } = useTranslation('finance');

  const renderOptions = () => {
    const options = [
      { key: 'true', name: t('accounts.new.active') },
      { key: 'false', name: t('accounts.new.inactive') },
    ];

    return options.map(({ key, name }) => (
      <Select.Option key={key} value={key}>
        {name}
      </Select.Option>
    ));
  };

  return <Select {...props}>{renderOptions()}</Select>;
};

export default ActiveStatusSelect;
