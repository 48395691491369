import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { OrganizationAddressType } from '../../../../../enums/crm/crm';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

type EntityAddressTypeFormSelectProps = SelectProps;

const EntityAddressTypeFormSelect = (
  props: EntityAddressTypeFormSelectProps,
) => {
  const { t } = useTranslation('crm');

  const options = useMemo<SelectOption[]>(() => {
    return Object.values(OrganizationAddressType).map((value) => ({
      id: value,
      label: t(`organization_address_type.${value}`),
    }));
  }, [t]);

  return <FormSelect {...props} options={options} />;
};

export default EntityAddressTypeFormSelect;
