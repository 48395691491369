import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { BankAccountStatuses } from 'enums/banking/accounts';

// components
import StatusLabel from 'components/Typography/StatusLabel';

const STATUS_MAP = {
  warning: [
    BankAccountStatuses.PendingApproval,
    BankAccountStatuses.Processing,
  ],
  success: [BankAccountStatuses.Active],
  error: [BankAccountStatuses.BankRejected],
  info: [BankAccountStatuses.Closed],
};

interface IProps {
  status: BankAccountStatuses;
}

const BankAccountStatus = memo(({ status }: IProps) => {
  const { t } = useTranslation('finance');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(`bank_account_statuses.${status}`, '')}
    />
  );
});

export default BankAccountStatus;
