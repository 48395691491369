import React from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import transactionsAPI from 'api/compliance/transactionsAPI';
import { IEntity } from 'typings/crm/entity';

// components
import RDCAlertsTable from './RDCAlertsTable';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import RDCInfoSection from '../../../../../../components/Additional/RDC/RDCInfoSection';
import { Divider, Typography } from 'antd';

interface IProps {
  entity: IEntity;
}

const RDC = ({ entity }: IProps) => {
  const { t } = useTranslation('compliance');

  const { response, loading } = useFetch(
    () =>
      entity.rdcReview?.reviewProcessId
        ? transactionsAPI.fetchReviewProcessById(
            entity.rdcReview?.reviewProcessId,
          )
        : null,
    [entity.rdcReview?.reviewProcessId],
  );

  return (
    <>
      {!entity.rdcReview?.reviewProcessId ? (
        <Typography.Text>
          {t('rdc_review_info_section.rdc_not_started')}
        </Typography.Text>
      ) : (
        <LoadingWrapper loading={loading}>
          {response && (
            <>
              <RDCInfoSection
                data={{
                  createdAt: response.reviewProcess.createdOn,
                  requestParamsJSON: response.reviewProcess.requestParamsJSON,
                }}
              />
              <Divider />
              <RDCAlertsTable entity={entity} />
            </>
          )}
        </LoadingWrapper>
      )}
    </>
  );
};

export default RDC;
