import React from 'react';

// helpers
import { SubRouteModel } from '../../typings/routes';

// layouts & helper components
import PageLayout from '../../layouts/PageLayout';
import { Redirect } from 'react-router-dom';

// pages
import Security from '../../pages/Profile/Security';
import ManageSecurityKeys from '../../pages/Profile/ManageSecurityKeys';
import AccountNotifications from '../../pages/Profile/AccountNotifications';

// route urls enum
enum ProfileRoutesEnum {
  Profile_Root = '/profile',
  Profile_SignInSecurity = '/profile/sign-in-and-security',
  Profile_SignInSecurity_ManageSecurityKeys = '/profile/sign-in-and-security/security-keys',
  Profile_NotificationPreferences = '/profile/notification-preferences',
}

const userProfileRoutes: SubRouteModel[] = [
  {
    title: 'Profile',
    path: ProfileRoutesEnum.Profile_Root,
    layout: PageLayout,
    hideFromNavigation: true,
    component: () => <Redirect to={ProfileRoutesEnum.Profile_SignInSecurity} />,
    childRoutes: [
      {
        title: 'Sign-in and Security',
        path: ProfileRoutesEnum.Profile_SignInSecurity,
        layout: PageLayout,
        component: Security,
        layoutProps: { hideContentCard: true },
        childRoutes: [
          {
            hideFromNavigation: true,
            title: 'Manage security keys',
            path: ProfileRoutesEnum.Profile_SignInSecurity_ManageSecurityKeys,
            layout: PageLayout,
            component: ManageSecurityKeys,
            layoutProps: {
              goBackPath: ProfileRoutesEnum.Profile_SignInSecurity,
            },
          },
        ],
      },
      {
        title: 'Account Notifications',
        path: ProfileRoutesEnum.Profile_NotificationPreferences,
        layout: PageLayout,
        component: AccountNotifications,
        layoutProps: { hideContentCard: true },
      },
    ],
  },
];

export { userProfileRoutes, ProfileRoutesEnum };
