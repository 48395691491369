import React from 'react';
import Select, { SelectProps } from 'antd/lib/select';

interface IProps extends SelectProps<any> {
  data: string[];
}

const PhoneNumberSelect = ({ value, data, ...rest }: IProps) => {
  const renderOptions = () => {
    return data.map((el) => (
      <Select.Option key={el} value={el}>
        {el}
      </Select.Option>
    ));
  };

  return (
    <Select {...rest} value={value || undefined}>
      {renderOptions()}
    </Select>
  );
};

export default PhoneNumberSelect;
