import React, { useEffect } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import useQueryParam from '../../../../hooks/useQueryParam';
import { contactsAPI } from '../../../../api/crm/contact/contactsAPI';
import { messagingAPI } from '../../../../api/messaging/messagingAPI';
import { onboardingAPI } from '../../../../api/crm/onboarding/onboardingAPI';
import { ContactHelpers } from '@helpers/crm/contact';
import { IOnboardingStatus } from '../../../../typings/crm/onboarding';

// components
import ClientInfo from './ClientInfo';
import ClientTabs from './ClientTabs';
import { Skeleton } from 'antd';
import {
  CardWrapper,
  PageLayoutHelperPropsModel,
} from '../../../../layouts/PageLayout';

type IProps = PageLayoutHelperPropsModel;

const ClientDetails = ({ setHeaderOptions }: IProps) => {
  const { t } = useTranslation('crm');

  const clientId = useQueryParam({
    param: 'id',
    noParamRedirect: '/crm/client-groups',
  });
  const clientGroupId = useQueryParam({ param: 'clientGroupId' });
  const clientGroupNumericId = useQueryParam({ param: 'clientGroupNumericId' });

  const { response: contact, loading: contactLoading } = useFetch(() => {
    return clientId ? contactsAPI.fetchContactById(clientId) : null;
  }, [clientId]);

  const { response: onboardingStatus, loading: onboardingStatusLoading } =
    useFetch<IOnboardingStatus>(() => {
      return clientId && clientGroupId
        ? onboardingAPI.getStatus(clientId, clientGroupId)
        : null;
    }, [clientId, clientGroupId]);

  const { response: messagesStatus } = useFetch(() => {
    return clientGroupNumericId
      ? messagingAPI.getMessagesStatus(clientGroupNumericId)
      : null;
  }, [clientGroupNumericId]);

  useEffect(() => {
    if (contact) {
      setHeaderOptions({
        title: t('client.title', {
          name: ContactHelpers.getContactName(contact),
        }),
      });
    }
  }, [contact]);

  return (
    <Skeleton
      loading={
        (contactLoading && !contact) ||
        (onboardingStatusLoading && !onboardingStatus)
      }
    >
      {!!contact && (
        <>
          <CardWrapper>
            <ClientInfo contact={contact} onboardingStatus={onboardingStatus} />
          </CardWrapper>
          <CardWrapper>
            <ClientTabs
              contact={contact}
              messagesStatus={messagesStatus || undefined}
              clientGroupIdNumericId={parseInt(clientGroupNumericId)}
            />
          </CardWrapper>
        </>
      )}
    </Skeleton>
  );
};

export default ClientDetails;
