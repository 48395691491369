import APIConfig from '../../../config/api';
import { APIService } from 'api/axiosInstance';
import { ApprovalRuleSetModel } from 'typings/approval/templates';
import { IApprovalRuleAccount } from 'components/Modals/TemplateModalDialogs/CRM/ApprovalEntryModalDialog/InnerContent/ChangeDifferenceSection/Templates/TransactionRule';
import { FetchParamsModel, FetchResponseModel } from 'typings/common';

export type TransactionRuleFromQuery = {
  id: string;
  isActive?: boolean;
  bankAccounts: {
    bankAccountNumber: string;
    bankAccountName: string;
    bankAccountCurrency: string;
  }[];
  approvalRuleSets: {
    approvalThreasholds: {
      threasholdMin: number | string;
      threasholdMax: number | string;
      ticked?: boolean;
    }[];
    approvalRuleItems: {
      minimunApprovalThreashold: number;
      approvalGroup: TransactionRuleApprovalGroupFromQuery;
    }[];
  }[];
};

export interface INewTransactionRuleTemplate {
  bankAccounts: IApprovalRuleAccount[];
  approvalRuleSets: ApprovalRuleSetModel[];
}

export type TransactionRuleApprovalGroupFromQuery = {
  id: string;
  name: string;
  description: string;
  isActive?: boolean;
  isDeleted?: boolean;
  participants: {
    userId: string;
    userFullName: string;
  }[];
};

export interface TransactionAccountModel {
  accountNumber: string;
  accountName: string;
  currencyCode: string;
}

const clientTransactionRulesAPI = {
  fetchTransactionRules: (clientGroupId: number, params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<TransactionRuleFromQuery>>(
      `${APIConfig.approvalApi}/transaction/client/${clientGroupId}/templates`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchTransactionRuleById: (
    clientGroupId: number,
    transactionRuleId: string,
  ) => {
    return APIService.get<TransactionRuleFromQuery>(
      `${APIConfig.approvalApi}/transaction/client/${clientGroupId}/templates/${transactionRuleId}`,
    ).then(({ data }) => data);
  },
};

export { clientTransactionRulesAPI };
