import React from 'react';

// helpers
import { NewAccountCategoryValidationSchema } from '../../../../../validations/accounting/accountCategories';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import LoadingWrapper from '../../../../WrapperComponents/LoadingWrapper';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  title: string;
  initialFormValues?: FormValuesModel | null;
  loadingInitialData?: boolean;
  onSubmit: (values: FormValuesModel) => Promise<void>;
}

export interface FormValuesModel {
  accountCategoryName: string;
  accountCategoryTypeId: number;
  description: string;
  financialTypeId: number;
  isClient?: boolean;
  parentCategoryId: number | null;
  financialTypeName: string;
}

const AccountCategoryRuleModal = ({
  title,
  isVisible,
  closeCallback,
  loadingInitialData,
  initialFormValues = null,
  onSubmit,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      onSubmit={onSubmit}
      initialValues={isVisible ? initialFormValues : null}
      confirmExitWithoutSaving
      enableReinitialize
      validationSchema={NewAccountCategoryValidationSchema}
      renderForm={
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <LoadingWrapper loading={!!loadingInitialData}>
            {!!initialFormValues && <InnerForm />}
          </LoadingWrapper>
        </ModalDialog>
      }
    />
  );
};

export default AccountCategoryRuleModal;
