import React, { useEffect } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';

// components
import CompliancePage from '../../modules/Compliance/CompliancePage';
import NotIntegratedToAPIMark from '../../components/Additional/NotIntegratedToAPIMark';

type IProps = PageLayoutHelperPropsModel;

const Compliance = ({ setHeaderOptions }: IProps) => {
  useEffect(() => {
    setHeaderOptions({ appendToTitle: <NotIntegratedToAPIMark /> });
  }, []);

  return <CompliancePage />;
};

export default Compliance;
