import React, { useEffect, useState } from 'react';
import useUserAccess from '../../../hooks/useUserAccess';

// helpers
import { PageLayoutHelperPropsModel } from '../../../layouts/PageLayout';

// components
import AddRole from '../../../modules/ORAC/Roles/AddRole';
import RolesTable from '../../../modules/ORAC/Roles/RolesTable';

type IProps = PageLayoutHelperPropsModel;

const Roles = ({ setHeaderOptions }: IProps) => {
  const [hasCreateAccess] = useUserAccess(['ORAC_Roles_Create']);
  const [updateTableTrigger, updateTable] = useState({});

  useEffect(() => {
    if (hasCreateAccess) {
      setHeaderOptions({
        extra: () => <AddRole onAdd={() => updateTable({})} />,
      });
    }
  }, [hasCreateAccess]);

  return <RolesTable updateTableTrigger={updateTableTrigger} />;
};

export default Roles;
