import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { darkTheme } from '../../../../../../../resources/theme/styled';
import { DateHelpers } from '@helpers/date';
import { FormValuesModel } from '../..';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { useFormikContext } from 'formik';
import { IJournalEntryModel } from '../../../../../../../typings/finance/journal';
import { JournalEntryTemplateTypes } from '../../../../../../../enums/finance/finance';

// components
import Text from '../../../../../../DesignSystem/Core/Text';
import InfoIconWithTooltip from '../../../../../../Icons/InfoIconWithTooltip';
import OtherAccountsPopover from './Components/OtherAccountsPopover';
import ReconciliationStatusSelect from './Components/ReconciliationStatusSelect';
import Table, {
  RequiredPropsForTableModel,
  SortProps,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

export type ActionKeys = 'edit' | 'view_journal';

export interface IProps extends RequiredPropsForTableModel<IJournalEntryModel> {
  isDebit: boolean;
  currentAccountNumber: string;
  canChangeReconciliationStatus?: boolean;
  sortCallback: (sort: SortProps) => void;
  onActionsClick?: (
    key: ActionKeys,
    record: IJournalEntryModel,
    newValue?: any,
  ) => void;
}

const AccountJournalEntriesTable = memo(
  ({
    isDebit,
    currentAccountNumber,
    sortCallback,
    onActionsClick,
    canChangeReconciliationStatus,
    ...rest
  }: IProps) => {
    const { t } = useTranslation('finance');
    const { values } = useFormikContext<FormValuesModel>();

    const renderFormattedMoney = (amount?: number) => {
      if (!amount) {
        return '';
      }

      return AccountsHelpers.convertAmountBigIntToLocaleString(amount);
    };

    const renderReconciliationStatus = (record: IJournalEntryModel) => {
      const isUpdated = !!values.entriesHash[record.id];
      const textColor = values.entriesHash[record.id]
        ? darkTheme.warningColor
        : undefined;
      const canUpdateStatus =
        !!onActionsClick && !!values.isEditMode && !isUpdated;

      return (
        <ReconciliationStatusWrapper color={textColor}>
          <StyledReconciliationStatusSelect
            disabled={!canUpdateStatus}
            value={
              isUpdated
                ? values.entriesHash[record.id].newReconciliationStatus
                : record.reconciliationStatus
            }
            onSelect={(newValue) =>
              onActionsClick && onActionsClick('edit', record, newValue)
            }
          />

          {onActionsClick && values.isEditMode && isUpdated && (
            <StyledInfoIconWithTooltip
              tooltipMessage={
                <>
                  <Text variant="subtitle1" color={darkTheme.whiteColor}>
                    {t(
                      'accounts.account_details.updated_reconciliation_status_tooltip',
                      {
                        newStatus: t(
                          `accounts.account_details.reconciliation_statuses.${values.entriesHash[record.id].newReconciliationStatus}`,
                        ),
                      },
                    )}
                  </Text>

                  {values.entriesHash[record.id].message && (
                    <Text variant="subtitle1" color={darkTheme.whiteColor}>
                      {t(
                        'accounts.account_details.comment_of_updated_reconciliation_status_tooltip',
                        {
                          comment: values.entriesHash[record.id].message,
                        },
                      )}
                    </Text>
                  )}
                </>
              }
            />
          )}
        </ReconciliationStatusWrapper>
      );
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          sorter: true,
          sortDirections: ['descend'],
          showSorterTooltip: false,
          key: 'posting_date',
          title: t('accounts.account_details.date'),
          render: (record: IJournalEntryModel) =>
            record.postingDate
              ? DateHelpers.formatTimestampToString(record.postingDate)
              : '',
        },

        {
          sorter: true,
          sortDirections: ['descend'],
          showSorterTooltip: false,
          key: 'value_date',
          title: t('accounts.account_details.value_date'),
          render: (record: IJournalEntryModel) =>
            record.valueDate
              ? DateHelpers.formatTimestampToString(record.valueDate)
              : '',
        },

        {
          key: 'journal',
          title: t('accounts.account_details.journal'),
          render: (record: IJournalEntryModel) =>
            onActionsClick ? (
              <Typography.Link
                onClick={() => onActionsClick('view_journal', record)}
              >
                {record.journalId}
              </Typography.Link>
            ) : (
              record.journalId
            ),
        },
        {
          key: 'template',
          title: t('accounts.account_details.template'),
          render: (record: IJournalEntryModel) =>
            t(
              `accounts.account_details.journal_entry_types.${JournalEntryTemplateTypes[record.journalEntryType]}`,
            ),
        },
        {
          key: 'type',
          title: t('accounts.account_details.type'),
          render: (record: IJournalEntryModel) => record.financialTypeName,
        },
        {
          key: 'other_accounts',
          title: t('accounts.account_details.other_accounts'),
          render: (record: IJournalEntryModel) => (
            <OtherAccountsPopover
              journalEntryId={record.journalId}
              currentAccountNumber={currentAccountNumber}
            />
          ),
        },
        {
          title: t('accounts.account_details.description'),
          key: 'description',
          render: (record: IJournalEntryModel) => record.description,
        },
        {
          title: `${t('accounts.account_details.decrease')} (${
            isDebit
              ? t('accounts.account_details.credit')
              : t('accounts.account_details.debit')
          })`,
          key: 'decrease',
          render: (record: IJournalEntryModel) =>
            renderFormattedMoney(
              isDebit ? record.creditAmount : record.debitAmount,
            ),
        },
        {
          title: `${t('accounts.account_details.increase')} (${
            isDebit
              ? t('accounts.account_details.debit')
              : t('accounts.account_details.credit')
          })`,
          key: 'increase',
          render: (record: IJournalEntryModel) =>
            renderFormattedMoney(
              isDebit ? record.debitAmount : record.creditAmount,
            ),
        },
        {
          hidden: !canChangeReconciliationStatus,
          width: 200,
          sorter: true,
          sortDirections: ['descend'],
          showSorterTooltip: false,
          title: t('accounts.account_details.is_reconciled'),
          key: 'reconciliation_status',
          render: renderReconciliationStatus,
        },
        {
          title: t('accounts.account_details.balance'),
          key: 'balance',
          render: (record: IJournalEntryModel) =>
            renderFormattedMoney(record.balance),
        },
      ];
      return result;
    }, [
      isDebit,
      canChangeReconciliationStatus,
      values,
      currentAccountNumber,
      onActionsClick,
    ]);

    return (
      <Table
        columns={columns}
        onSortChange={(sorter: SortProps) => sortCallback(sorter)}
        {...rest}
      />
    );
  },
);

const StyledInfoIconWithTooltip = styled(InfoIconWithTooltip)`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.marginXs};
`;

const ReconciliationStatusWrapper = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
`;

const StyledReconciliationStatusSelect = styled(ReconciliationStatusSelect)`
  width: 100%;
`;

export default AccountJournalEntriesTable;
