// helpers
import { SubRouteModel } from '../../../typings/routes';

// helper components & layouts
import PageLayout from '../../../layouts/PageLayout';
import Navigation from '../../../pages/Navigation';

// pages
import Journals from '../../../pages/Finance/Journals';
import Accounts from '../../../pages/Finance/Accounts';
import Currencies from '../../../pages/Finance/Currencies';
import FiscalPeriod from '../../../pages/Finance/FiscalPeriod';
import CurrencyRates from '../../../pages/Finance/CurrencyRates';
import FinancialTypes from '../../../pages/Finance/FinancialTypes';
import AccountCategories from '../../../pages/Finance/AccountCategories';
import AccountDetailsPage from '../../../pages/Finance/AccountDetailsPage';
import AccountCategoryDetails from '../../../modules/Finance/AccountCategories/AccountCategoryDetails';

enum FinancesRoutesEnum {
  Finance_Root = '/finance',
  Finance_Journals = '/finance/journals',
  Finance_Accounts = '/finance/accounts',
  Finance_Account_Details = '/finance/accounts/account-journals',
  Finance_Currencies = '/finance/currencies',
  Finance_AccountCategories = '/finance/account-categories',
  Finance_AccountCategory_Details = '/finance/account-categories/account-category',
  Finance_FiscalPeriod = '/finance/fiscal-period',
  Finance_CurrencyRates = '/finance/currency-rates',
  Finance_FinancialTypes = '/finance/financial-types',
}

const financeRoutes: SubRouteModel[] = [
  {
    title: 'Finance',
    path: FinancesRoutesEnum.Finance_Root,
    layout: PageLayout,
    component: Navigation,
    permission: { permissionKey: 'Finance_View' },
    childRoutes: [
      {
        title: 'Journals',
        path: FinancesRoutesEnum.Finance_Journals,
        layout: PageLayout,
        component: Journals,
        permission: { permissionKey: 'Finance_Journals_View' },
      },

      {
        title: 'Accounts',
        path: FinancesRoutesEnum.Finance_Accounts,
        layout: PageLayout,
        component: Accounts,
        permission: { permissionKey: 'Finance_Accounts_View' },
        childRoutes: [
          {
            title: 'Account',
            hideFromNavigation: true,
            path: FinancesRoutesEnum.Finance_Account_Details,
            layout: PageLayout,
            component: AccountDetailsPage,
            permission: { permissionKey: 'Finance_Accounts_Details_View' },
            layoutProps: {
              goBackPath: FinancesRoutesEnum.Finance_Accounts,
            },
          },
        ],
      },

      {
        title: 'Currencies',
        path: FinancesRoutesEnum.Finance_Currencies,
        layout: PageLayout,
        component: Currencies,
        permission: { permissionKey: 'Finance_Currencies_View' },
      },

      {
        title: 'Account Categories',
        path: FinancesRoutesEnum.Finance_AccountCategories,
        layout: PageLayout,
        component: AccountCategories,
        permission: { permissionKey: 'Finance_AccountCategories_View' },
        childRoutes: [
          {
            title: 'Account Category',
            hideFromNavigation: true,
            path: FinancesRoutesEnum.Finance_AccountCategory_Details,
            layout: PageLayout,
            component: AccountCategoryDetails,
            permission: {
              permissionKey: 'Finance_AccountCategories_Details_View',
            },
            layoutProps: {
              goBackPath: FinancesRoutesEnum.Finance_AccountCategories,
            },
          },
        ],
      },

      {
        title: 'Fiscal Period',
        path: FinancesRoutesEnum.Finance_FiscalPeriod,
        layout: PageLayout,
        component: FiscalPeriod,
        permission: { permissionKey: 'Finance_FiscalPeriod_View' },
      },

      {
        title: 'Currency Rates',
        path: FinancesRoutesEnum.Finance_CurrencyRates,
        layout: PageLayout,
        component: CurrencyRates,
        permission: { permissionKey: 'Finance_CurrencyRates_View' },
      },

      {
        title: 'Financial Types',
        path: FinancesRoutesEnum.Finance_FinancialTypes,
        layout: PageLayout,
        component: FinancialTypes,
        permission: { permissionKey: 'Finance_CurrencyRates_View' },
      },
    ],
  },
];

export { financeRoutes, FinancesRoutesEnum };
