import React from 'react';

// components
import EntryChangesLogReport from 'modules/Reporting/EntryChangesLogReport';

const EntityDetailsChanges = () => {
  return <EntryChangesLogReport />;
};

export default EntityDetailsChanges;
