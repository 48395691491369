import React, { ReactNode, useEffect, useRef, useState } from 'react';

// helpers
import { useDispatch, useSelector } from 'react-redux';
import {
  IDocumentBase,
  IUploadFile,
} from '../../../../../typings/documents/documents';
import { generateUniqId } from '../../../../../helpers/utils';
import { uploadFiles } from '../../../../../redux/actions/upload';
import { ShortDocumentModel } from '../';
import { IDocumentAssociationInfo } from '../../../../../modules/CRM/Documents/UploadDocumentsDialog';

import { StateModel } from '../../../../../redux/reducers';
import { StateModel as UploadStateModel } from '../../../../../redux/reducers/upload';

interface IProps {
  name: string;
  association: IDocumentAssociationInfo;
  description?: string;
  tags?: string[];
  accept?: string;
  setLoading?: (isLoading: boolean) => void;
  onUpload: (documents: ShortDocumentModel[]) => void;
  children?: ReactNode;
}

const UploadDocumentButton = ({
  name,
  association,
  description,
  tags = [],
  accept,
  setLoading,
  onUpload,
  children,
}: IProps) => {
  const dispatch = useDispatch();
  const uploadInputRef = useRef<any>(null);

  const uploadState = useSelector<StateModel, UploadStateModel>(
    (state) => state.upload,
  );

  const [shouldSaveDocuments, setShouldSaveDocuments] = useState(false);

  // Handle upload finished event
  // Since we're using core upload document service, the files can be uploaded from another field
  // e.g. we have 2 or more upload document fields in one form:
  // so in this after upload we should update only the field from where user started uploading some files
  // So for this we have shouldSaveDocuments variable, which allows us to know whether we need to update the value
  useEffect(() => {
    const { isFinished, files } = uploadState;

    // Check if the upload process was finished and whether the upload process was started from this field
    if (isFinished && shouldSaveDocuments) {
      const relatedFiles = Object.keys(files)
        .map((key) => {
          let result = null;

          if (files[key].relatedToFieldName === name) {
            result = { ...files[key].dataBaseEntry };
          }

          return result;
        })
        .filter((e) => !!e) as IDocumentBase[];

      const filteredFiles: ShortDocumentModel[] = relatedFiles.map((e) => ({
        id: e._id,
        name: e.name,
        file: e.files && e.files[0],
      }));

      onUpload(filteredFiles);
      setLoading && setLoading(false);
      setShouldSaveDocuments(false);
    }
  }, [uploadState.isFinished]);

  const onBrowseClick = () => {
    uploadInputRef.current.click();
  };

  const onFileSelect = () => {
    if (uploadInputRef.current && uploadInputRef.current.files.length) {
      handleFilesSelect(
        Array.prototype.slice.call(uploadInputRef.current.files),
      );

      uploadInputRef.current.value = '';
    }
  };

  const handleFilesSelect = (files: File[]) => {
    if (!files.length) {
      return;
    }

    const formattedFiles = files.reduce<IUploadFile[]>((acc, file: File) => {
      if (file.size) {
        acc.push({
          id: generateUniqId(),
          originalName: file.name,
          associations: [],
          name: file.name,
          description,
          tags,
          file,
        });
      }

      return acc;
    }, []);

    if (formattedFiles.length) {
      dispatch(uploadFiles([...formattedFiles], association, name));
      setLoading && setLoading(false);
      setShouldSaveDocuments(true);
    }
  };

  return (
    <div onClick={onBrowseClick}>
      {children}
      <input
        ref={uploadInputRef}
        type="file"
        multiple
        accept={accept}
        style={{ display: 'none' }}
        onChange={() => onFileSelect()}
      />
    </div>
  );
};

export default UploadDocumentButton;
