import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { darkTheme } from '@resources/theme/styled';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import {
  CHANGE_VIEW_TYPES,
  removeChangeViewHandler,
  setChangeViewHandler,
} from 'redux/actions/view';

// components
import Button from '@core_components/Button';
import IconSVG from '@core_components/IconSVG';

import { Col, Modal, Row } from 'antd';
import { ReactComponent as InfoIcon } from '@resources/icons/remix-icons/information-line.svg';

interface IChangeView {
  changeView?: () => void;
  type?: CHANGE_VIEW_TYPES;
}

interface IProps {
  onSave?: () => Promise<void>;
}

// Show confirmation modal if form is dirty and user leaves without saving
const ConfirmationExitWithoutSaving = ({ onSave }: IProps) => {
  const { t } = useTranslation(['common', 'form']);
  const form = useFormikContext();
  const dispatch = useDispatch();

  const {
    dirty,
    isValid,
    submitCount,
    resetForm,
    submitForm,
    validateForm,
    isSubmitting,
  } = form;

  const [changeView, setChangeView] = useState<IChangeView | undefined>();
  const [lastSubmitCount, setLastSubmitCount] = useState(submitCount);

  useEffect(() => {
    if (dirty || !isValid) {
      dispatch(setChangeViewHandler(onOpenModal));
    } else {
      dispatch(removeChangeViewHandler());
    }
  }, [dirty, isValid]);

  useEffect(() => {
    if (lastSubmitCount !== submitCount) {
      setLastSubmitCount(submitCount);
      resetForm(form);
    }
  }, [submitCount]);

  const onOpenModal = (changeView?: () => void, type?: CHANGE_VIEW_TYPES) => {
    setChangeView({ changeView, type });
  };

  const onCancel = useCallback(() => {
    setChangeView(undefined);
  }, [changeView]);

  const onNo = useCallback(() => {
    setChangeView(undefined);
    resetForm(form);
    changeView?.changeView && changeView.changeView();
  }, [changeView]);

  const onYes = useCallback(async () => {
    if (onSave) {
      await onSave();
    } else {
      await submitForm();
    }

    // If form has errors close modal
    const errors = await validateForm();
    if (Object.keys(errors).length) {
      return setChangeView(undefined);
    }

    setChangeView(undefined);
    changeView?.changeView && changeView.changeView();
  }, [changeView]);

  const confirmMessage = useMemo(() => {
    switch (changeView?.type) {
      case CHANGE_VIEW_TYPES.MODAL:
        return t('confirm_exit_without_save.title', { ns: 'form' });

      default:
        return t('confirm_exit_without_save.sub_title', { ns: 'form' });
    }
  }, [changeView?.type]);

  return (
    <Modal
      zIndex={9999}
      closable={false}
      visible={!!changeView}
      onCancel={onCancel}
      footer={
        <Row justify="end" gutter={[16, 0]}>
          <Col>
            <Button type="bordered" onClick={onCancel} disabled={isSubmitting}>
              {t('cancel')}
            </Button>
          </Col>
          <Col>
            <Button onClick={onNo} danger disabled={isSubmitting}>
              {t('no')}
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={onYes} loading={isSubmitting}>
              {t('yes')}
            </Button>
          </Col>
        </Row>
      }
    >
      <StyledModalContent>
        <StyledIconSVG component={InfoIcon} color={darkTheme.warningColor} />
        {confirmMessage}
      </StyledModalContent>
    </Modal>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

const StyledModalContent = styled.div`
  font-size: ${({ theme }) => theme.fontSizeSm};
`;

export default memo(ConfirmationExitWithoutSaving);
