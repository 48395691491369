import React from 'react';

// components
import { Divider } from 'antd';
import PermissionsList from './PermissionsList';
import SelectedPermissionsList from './SelectedPermissionsList';

interface IProps {
  canSetForbiddenPermissions?: boolean;
  onlyForbiddenPermissions?: boolean;
}

const InnerForm = (props: IProps) => {
  return (
    <>
      <PermissionsList {...props} />
      <Divider />
      <SelectedPermissionsList {...props} />
    </>
  );
};

export default InnerForm;
