import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';

// constants
import { AccountRecoveryEntryStatus } from '../../enums/auth/accountRecovery';
import { AccountRecoveryRequestModel } from '../../typings/ORAC/accountRecoveryRequests';
import { accountRecoveryRequestAdapter } from '../../apiAdapters/orac/accountRecoveryRequestsAdapter';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

interface FetchAccountRecoveryRequestsParams extends FetchParamsModel {
  name: string;
  status: AccountRecoveryEntryStatus | null;
}

const accountRecoveryRequestsAPI = {
  fetchAccountRecoveryRequests: (
    params: FetchAccountRecoveryRequestsParams,
  ) => {
    return APIService.get<FetchResponseModel<AccountRecoveryRequestModel>>(
      `${APIConfig.authApi}/recovery-requests`,
      { params },
    ).then(({ data }) => data);
  },

  fetchAccountRecoveryRequestById: (requestId: string) => {
    return APIService.get<AccountRecoveryRequestModel>(
      `${APIConfig.authApi}/recovery-requests/${requestId}`,
    ).then((response) =>
      accountRecoveryRequestAdapter.fetchAccountRecoveryRequestById(
        response.data,
      ),
    );
  },

  approveAccountRecoveryRequestById: (requestId: string) => {
    return APIService.patch<AccountRecoveryRequestModel>(
      `${APIConfig.authApi}/recovery-requests/${requestId}`,
      {
        status: AccountRecoveryEntryStatus.Approved,
      },
    ).then(({ data }) => data);
  },

  rejectAccountRecoveryRequestById: (requestId: string) => {
    return APIService.patch<AccountRecoveryRequestModel>(
      `${APIConfig.authApi}/recovery-requests/${requestId}`,
      {
        status: AccountRecoveryEntryStatus.Rejected,
      },
    ).then(({ data }) => data);
  },
};

export { accountRecoveryRequestsAPI };
