import React from 'react';

// helpers
import { SelectProps } from 'antd/lib/select';
import useTranslation from '../../../../../hooks/useTranslation';

// constants
import { AdditionalFieldType } from '../../../../../enums/crm/crm';

// components
import { Select } from 'antd';

type IProps = SelectProps<any>;

const FieldTypeSelect = (props: IProps) => {
  const { t } = useTranslation('crm');
  const renderOptions = () => {
    const fieldTypesArray = Object.values(AdditionalFieldType);

    const options = fieldTypesArray.map((fieldType) => ({
      key: fieldType,
      name: t(`additional_field_type.${fieldType}`),
    }));

    return options.map(({ key, name }) => (
      <Select.Option key={key} value={key}>
        {name}
      </Select.Option>
    ));
  };

  return <Select {...props}>{renderOptions()}</Select>;
};

export default FieldTypeSelect;
