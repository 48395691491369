import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { useReviewProcessContext } from '../../../../../../hooks/domainHooks/useReviewProcess';
import { ReviewProcessConfigurationRuleModel } from '../../../../../../typings/compliance/reviewProcess';

// components
import RuleSummaryRow from './RuleSummaryRow';
import { Col, Row, Typography } from 'antd';

const ReviewConfigurationsSection = memo(() => {
  const { t } = useTranslation('compliance');
  const { transactionReviewProcess } = useReviewProcessContext();

  const renderRules = (rules: ReviewProcessConfigurationRuleModel[]) => {
    if (!rules.length) {
      return null;
    }

    return rules.map((rule, i) => <RuleSummaryRow key={i} rule={rule} />);
  };

  return (
    <Row>
      <Col span={8}>
        <StyledTitleText>
          {t('transactions.review_configurations')}
        </StyledTitleText>
      </Col>
      <Col span={16}>
        {renderRules(transactionReviewProcess.alert?.rules || [])}
      </Col>
    </Row>
  );
});

const StyledTitleText = styled(Typography.Text)`
  color: ${({ theme }) => theme.whiteColor};
  font-weight: 600;
`;

export default ReviewConfigurationsSection;
