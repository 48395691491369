import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { RDCReviewStatuses } from '../../../../../../enums/compliance/reviewConfiguration';
import { useReviewProcessContext } from '../../../../../../hooks/domainHooks/useReviewProcess';

// components
import StatusLabel from '../../../../../../components/Typography/StatusLabel';
import { Col, Row, Typography } from 'antd';

const RDCReviewSection = () => {
  const { t } = useTranslation('compliance');
  const { rdcReviewProcess } = useReviewProcessContext();

  const pendingAlerts = !rdcReviewProcess.progress
    ? 0
    : rdcReviewProcess.progress.triggered -
      (rdcReviewProcess.progress.approved + rdcReviewProcess.progress.rejected);

  return (
    <Row>
      <Col span={8}>
        <StyledTitleText>{t('transactions.rdc_review')}</StyledTitleText>
      </Col>
      <Col span={16}>
        <Row>
          <Col span={10}>{t('transactions.triggered_alerts')}</Col>
          <Col span={14}>{rdcReviewProcess.progress?.triggered}</Col>
        </Row>

        <Row>
          <Col span={10} push={1}>
            {t(
              `review_statuses.${RDCReviewStatuses[RDCReviewStatuses.Matched]}`,
            )}
          </Col>
          <Col span={14}>
            <StatusLabel
              status="error"
              text={String(rdcReviewProcess.progress?.rejected)}
            />
          </Col>
        </Row>

        <Row>
          <Col span={10} push={1}>
            {t(
              `review_statuses.${RDCReviewStatuses[RDCReviewStatuses.NotMatched]}`,
            )}
          </Col>
          <Col span={14}>
            <StatusLabel
              status="success"
              text={String(rdcReviewProcess.progress?.approved)}
            />
          </Col>
        </Row>

        <Row>
          <Col span={10} push={1}>
            {t(
              `review_statuses.${RDCReviewStatuses[RDCReviewStatuses.Pending]}`,
            )}
          </Col>
          <Col span={14}>
            <StatusLabel status="warning" text={String(pendingAlerts)} />
          </Col>
        </Row>

        <Row>
          <Col span={10}>{t('transactions.not_triggered')}</Col>
          <Col span={14}>
            <StatusLabel
              status="info"
              text={String(rdcReviewProcess.progress?.notTriggered)}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledTitleText = styled(Typography.Text)`
  color: ${({ theme }) => theme.whiteColor};
  font-weight: 600;
`;

export default RDCReviewSection;
