import StoreHelper from '../StoreHelper';
import { UploadStatus } from '../../constants/documents';
import { generateUniqId } from '../../helpers/utils';
import { IDocumentAssociationInfo } from '../../modules/CRM/Documents/UploadDocumentsDialog';
import { DocumentHelpers, IChunkHash } from '../../helpers/documents';
import {
  IDocumentBase,
  IUploadFile,
  UploadDocumentAssociationModel,
} from '../../typings/documents/documents';

// Actions
export enum ActionType {
  SET_UPLOAD_FILES = 'upload/SET_UPLOAD_FILES',
  SET_UPLOAD_STATUS = 'upload/SET_UPLOAD_STATUS',
  SET_UPLOAD_FINISH_STATUS = 'upload/SET_UPLOAD_FINISH_STATUS',
  SET_UPLOAD_FILE_STATUS = 'upload/SET_UPLOAD_FILE_STATUS',
  SET_FILE_CHUNKS = 'upload/SET_FILE_CHUNKS',
  SET_CHUNK_STATUS = 'upload/SET_CHUNK_STATUS',
  UPDATE_CHUNK_PERCENTAGE = 'upload/UPDATE_CHUNK_PERCENTAGE',
  SET_FILE_TAGS = 'upload/SET_FILE_TAGS',
  SET_FILE_NAME = 'upload/SET_FILE_NAME',
  SET_FILE_DATA_BASE_ID = 'upload/SET_FILE_DATA_BASE_ID',
  REFRESH_UPLOAD_STATE = 'upload/REFRESH_UPLOAD_STATE',
  UPLOAD_FILES = 'upload/UPLOAD_FILES',
  SET_SHOULD_SHOW_NOTIFICATION_STATUS = 'upload/SET_SHOULD_SHOW_NOTIFICATION_STATUS',
  REMOVE_FILE = 'upload/REMOVE_FILE',
}

export interface IFileHash {
  [key: string]: IFileItem;
}

export interface IFileItem {
  id: string;
  dataBaseId?: string;
  file: File;
  fileName: string;
  description?: string;
  chunkSize: number;
  status: UploadStatus;
  uploadPercentage: number;
  chunksArray: IChunkHash;
  tags: string[];
  relatedToFieldName?: string;
  association: IDocumentAssociationInfo | null;
  associations: UploadDocumentAssociationModel[];
  dataBaseEntry: IDocumentBase | null;
}

// Action creators
export const uploadFiles =
  (
    files: IUploadFile[],
    association?: IDocumentAssociationInfo,
    relatedToFieldName?: string,
  ) =>
  async (dispatch: any) => {
    const uploadData = StoreHelper.getUpload();
    let result: IFileHash = {};

    let sizeToUpload =
      uploadData.status === UploadStatus.WAIT && uploadData.isFinished
        ? 0
        : uploadData.sizeToUpload;

    // TODO: refactor relation logic
    files.forEach(
      ({ id, file, name, description, relatedTo, tags, associations }) => {
        result[id] = {
          id: id,
          file: file,
          fileName: name,
          relatedToFieldName: relatedTo || relatedToFieldName,
          description: description,
          chunkSize: DocumentHelpers.calcChunkSize(file.size),
          status: UploadStatus.WAIT,
          uploadPercentage: 0,
          chunksArray: {},
          association: association || null,
          associations: associations || [],
          tags,
          dataBaseEntry: null,
        };

        sizeToUpload += file.size;
      },
    );

    if (uploadData.status === UploadStatus.WAIT && uploadData.isFinished) {
      await dispatch(refreshUploadState());
      result = { ...result };
    } else {
      result = { ...result, ...uploadData.files };
    }

    // Hack for safari
    const sortedFiles: IFileHash = {};

    Object.keys(result)
      .sort()
      .forEach(function (key) {
        sortedFiles[key] = result[key];
      });

    dispatch({
      type: ActionType.UPLOAD_FILES,
      payload: { files: sortedFiles, sizeToUpload },
      key: 'files',
    });
  };

export const setUploadFiles = (
  files: File[],
  association?: IDocumentAssociationInfo,
) => {
  const newFilesData: IFileHash = {};
  let fileId = null;
  let sizeToUpload = 0;

  Array.prototype.slice.call(files).forEach((item) => {
    fileId = generateUniqId();

    newFilesData[fileId] = {
      id: fileId,
      file: item,
      fileName: item.name,
      chunkSize: DocumentHelpers.calcChunkSize(item.size),
      status: UploadStatus.WAIT,
      uploadPercentage: 0,
      chunksArray: {},
      tags: [],
      association: association || null,
      dataBaseEntry: null,
      associations: [],
    };

    sizeToUpload += item.size;
  });

  // Hack for safari
  const sortedFiles: IFileHash = {};
  Object.keys(newFilesData)
    .sort()
    .forEach(function (key) {
      sortedFiles[key] = newFilesData[key];
    });

  return {
    type: ActionType.SET_UPLOAD_FILES,
    payload: { files: sortedFiles, sizeToUpload },
    key: 'files',
  };
};

export const setUploadStatus = (status: UploadStatus) => ({
  type: ActionType.SET_UPLOAD_STATUS,
  payload: status,
  key: 'status',
});

export const setUploadFinishStatus = (isFinished: boolean) => ({
  type: ActionType.SET_UPLOAD_FINISH_STATUS,
  payload: isFinished,
  key: 'isFinished',
});

export const setUploadFileStatus = (fileId: string, status: UploadStatus) => ({
  type: ActionType.SET_UPLOAD_FILE_STATUS,
  payload: { fileId, status },
});

export const setFileChunks = (fileId: string, chunksArray: IChunkHash) => ({
  type: ActionType.SET_FILE_CHUNKS,
  payload: { fileId, chunksArray },
});

export const setChunkStatus = (
  fileId: string,
  chunkId: string,
  status: UploadStatus,
) => ({
  type: ActionType.SET_CHUNK_STATUS,
  payload: { fileId, chunkId, status },
});

export const removeFile = (fileId: string) => ({
  type: ActionType.REMOVE_FILE,
  payload: { fileId },
});

export const updateChunkPercentage = (
  fileId: string,
  chunkId: string,
  percentage: number,
) => {
  const uploadData = StoreHelper.getUpload();
  const updatedFile = { ...uploadData.files[fileId] };

  updatedFile.chunksArray = {
    ...updatedFile.chunksArray,
    [chunkId]: {
      ...updatedFile.chunksArray[chunkId],
      uploadPercentage: percentage,
    },
  };

  // Find file upload progress
  const fileUploadProgress = updatedFile.chunksArray
    ? (Object.keys(updatedFile.chunksArray).reduce(
        (acc, cur) =>
          (acc +=
            updatedFile.chunksArray[cur].file.size *
            updatedFile.chunksArray[cur].uploadPercentage),
        0,
      ) /
        updatedFile.file.size) |
      0
    : 0;

  updatedFile.uploadPercentage = fileUploadProgress;
  uploadData.files[fileId] = { ...updatedFile };

  // Find general progress percentage
  const generalProgress = uploadData.files
    ? (Object.keys(uploadData.files).reduce(
        (acc, cur) =>
          (acc +=
            uploadData.files[cur].file.size *
            uploadData.files[cur].uploadPercentage),
        0,
      ) /
        uploadData.sizeToUpload) |
      0
    : 0;

  return {
    type: ActionType.UPDATE_CHUNK_PERCENTAGE,
    payload: { fileId, updatedFile, progress: generalProgress },
  };
};

export const setFileTags = (fileId: string, tags: string[]) => {
  const uploadData = StoreHelper.getUpload();
  const updatedFile: IFileItem = { ...uploadData.files[fileId] };
  updatedFile.tags = tags;

  return {
    type: ActionType.SET_FILE_TAGS,
    payload: {
      fileId,
      file: updatedFile,
    },
  };
};

export const setFileName = (fileId: string, name: string) => {
  const uploadData = StoreHelper.getUpload();
  const updatedFile: IFileItem = { ...uploadData.files[fileId] };
  updatedFile.fileName = name;

  return {
    type: ActionType.SET_FILE_NAME,
    payload: {
      fileId,
      file: updatedFile,
    },
  };
};

export const refreshUploadState = () => {
  return {
    type: ActionType.REFRESH_UPLOAD_STATE,
    payload: {
      status: UploadStatus.WAIT,
      shouldShowUploadNotification: true,
      progress: 0,
      sizeToUpload: 0,
      isFinished: false,
      files: {} as IFileHash,
    },
  };
};

export const setFileDataBaseId = (fileId: string, dataBaseId: string) => {
  const uploadData = StoreHelper.getUpload();
  const updatedFile: IFileItem = { ...uploadData.files[fileId], dataBaseId };

  return {
    type: ActionType.SET_FILE_DATA_BASE_ID,
    payload: {
      fileId,
      file: updatedFile,
    },
  };
};

export const setDataBaseFile = (
  fileId: string,
  dataBaseEntry: IDocumentBase,
) => {
  const uploadData = StoreHelper.getUpload();
  const updatedFile: IFileItem = { ...uploadData.files[fileId], dataBaseEntry };

  return {
    type: ActionType.SET_FILE_DATA_BASE_ID,
    payload: {
      fileId,
      file: updatedFile,
    },
  };
};

export const setUploadNotifcationStatus = (shouldShow: boolean) => ({
  type: ActionType.SET_SHOULD_SHOW_NOTIFICATION_STATUS,
  payload: shouldShow,
});
