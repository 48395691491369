import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../../../../routes/routes';

// components
import DetailsRow from '../../../../DetailsRow';
import { Collapse, Typography } from 'antd';

export type UpdateTransactionCurrencyListData = {
  correspondentBankId: number;
  correspondentBankName: string;
  old: { currencyId: number; currencyIsoCode: string }[];
  new: { currencyId: number; currencyIsoCode: string }[];
};

interface IProps {
  data: UpdateTransactionCurrencyListData;
}

const UpdateTransactionCurrencyListDetails = memo(({ data }: IProps) => {
  const { t } = useTranslation('approval_management');
  const history = useHistory();

  return (
    <Collapse defaultActiveKey={['general', 'old', 'new']}>
      <Collapse.Panel
        key="general"
        header={t(
          'approval_workflow.approval_workflow_changes.update_currency_list_for_correspondent_bank.general',
        )}
      >
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_currency_list_for_correspondent_bank.name',
          )}
          value={
            <Typography.Link
              onClick={() =>
                history.push({
                  pathname: RoutePaths.Banking_CorrespondentBankDetails,
                  search: `?id=${data.correspondentBankId}`,
                })
              }
            >
              {data.correspondentBankName}
            </Typography.Link>
          }
        />
      </Collapse.Panel>

      <Collapse.Panel
        key="old"
        header={t('approval_workflow.approval_workflow_changes.old_version')}
      >
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_currency_list_for_correspondent_bank.currencies',
          )}
          value={data.old.map((e) => e.currencyIsoCode).join(', ')}
        />
      </Collapse.Panel>

      <Collapse.Panel
        key="new"
        header={t('approval_workflow.approval_workflow_changes.new_version')}
      >
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_currency_list_for_correspondent_bank.currencies',
          )}
          value={data.new.map((e) => e.currencyIsoCode).join(', ')}
        />
      </Collapse.Panel>
    </Collapse>
  );
});

export default UpdateTransactionCurrencyListDetails;
