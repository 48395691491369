import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import { TransactionRuleFromQuery } from 'api/approval/approvalManagement/clientTransactionRulesAPI';

// components
import Text from '@core_components/Text';
import Button from '@core_components/Button';
import StatusLabel from 'components/Typography/StatusLabel';
import TransactionRuleMatrix from './TransactionRuleMatrix';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Col, Divider, Row } from 'antd';

export type ActionKeys = 'update';

interface IProps extends RequiredPropsForTableModel<TransactionRuleFromQuery> {
  onActionsClick: (key: ActionKeys, record: TransactionRuleFromQuery) => void;
}

const ClientTransactionRulesTable = ({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation('crm');
  const [hasAccessToUpdateApprovalRule] = useUserAccess([
    'CRM_ClientGroup_Details_TransactionRules_ApprovalRules_Edit',
  ]);

  const columns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        width: 300,
        key: 'account_number',
        title: t(
          'client_group.approval.client_transaction_rules.table.bank_account',
        ),
        render: (record: TransactionRuleFromQuery) =>
          record.bankAccounts.map((e, i) => (
            <>
              <Row key={i}>
                <Col span={24}>
                  <Text variant="body1">{e.bankAccountName}</Text>
                </Col>
                <Col span={24}>
                  <Text variant="body1">{e.bankAccountNumber}</Text>
                </Col>
                <Col span={24}>
                  <Text variant="body1">{e.bankAccountCurrency}</Text>
                </Col>
              </Row>
              {i < record.bankAccounts.length - 1 && <StyledDivider />}
            </>
          )),
      },

      {
        key: 'approval_matrix',
        title: t('client_group.approval.client_transaction_rules.table.matrix'),
        render: (record: TransactionRuleFromQuery) => (
          <TransactionRuleMatrix record={record} />
        ),
      },

      {
        key: 'status',
        title: t('client_group.approval.client_transaction_rules.table.status'),
        render: (record: TransactionRuleFromQuery) =>
          record.isActive ? (
            <StatusLabel
              status="success"
              text={t(
                'client_group.approval.client_transaction_rules.statuses.active',
              )}
            />
          ) : (
            <StatusLabel
              status="warning"
              text={t(
                'client_group.approval.client_transaction_rules.statuses.pending_review',
              )}
            />
          ),
      },

      {
        key: 'actions',
        width: '70px',
        hidden: !hasAccessToUpdateApprovalRule,
        render: (record: TransactionRuleFromQuery) =>
          record.isActive && (
            <Button
              type="bordered"
              onClick={() => onActionsClick('update', record)}
            >
              {t(
                'client_group.approval.client_transaction_rules.table.update_button',
              )}
            </Button>
          ),
      },
    ];
  }, [hasAccessToUpdateApprovalRule, onActionsClick]);

  return <Table columns={columns} {...rest} />;
};

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.paddingSm} 0px`};
`;

export default ClientTransactionRulesTable;
