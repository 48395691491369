import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { Moment } from 'moment';

// components
import DatePicker from '../../../../components/Forms/FormComponents/DatePicker';
import { Col, Row } from 'antd';

interface IProps {
  date: Moment;
  filterChange: (key: string, value: Moment) => void;
  isDisabled: boolean;
}

const FilterBar = ({ filterChange, date, isDisabled }: IProps) => {
  const { t } = useTranslation('finance');

  const filterGridSizes = { xl: 6, lg: 10, md: 24, sm: 24, xs: 24 };

  return (
    <Row gutter={[16, 16]}>
      <Col {...filterGridSizes}>
        <Row>
          <Col span={24}>{t('fiscal_period.table.calendar_year')}</Col>
          <Col span={24}>
            <StyledDatePicker
              size="large"
              value={date}
              picker="year"
              disabled={isDisabled}
              onChange={(newDate: Moment | null) =>
                newDate && filterChange('date', newDate)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export default FilterBar;
