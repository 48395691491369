import React, { useCallback, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from 'hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { debounce } from 'lodash';
import { darkTheme } from '@resources/theme/styled';
import { StyledComponentProps } from '../../../../typings/common';
import { AUTOCOMPLETE_DEBOUNCE_DELAY } from 'constants/global';

// components
import Spinner from '../Spinner';
import IconSVG from '@core_components/IconSVG';
import { Select as SelectAntD } from 'antd';
import { ReactComponent as CloseIcon } from '@resources/icons/remix-icons/close-line.svg';

type Value = string | number;

export type AutocompleteOption<Option = unknown> = {
  id: Value;
  label: React.ReactNode;
  model?: Option;
  disabled?: boolean;
};

export type AutocompleteEventOption<Option = unknown> = {
  key: string;
  value: string;
  disabled?: boolean;
  children: React.ReactNode;
  model?: Option;
};

export interface AutocompleteProps<Option = unknown>
  extends StyledComponentProps {
  value?: string | number | string[] | number[];
  initialValue?: AutocompleteOption<Option> | AutocompleteOption<Option>[];

  minSearchLength?: number;
  excludeElementIds?: Value[];

  mode?: 'multiple';
  size?: AutocompleteSizes;
  disabled?: boolean;
  placeholder?: string;

  appendToMenu?: React.ReactNode;

  onBlur?: React.FocusEventHandler<HTMLElement>;
  refreshAutocompleteTrigger?: any;

  fetchData?: (searchQuery: string) => Promise<AutocompleteOption<Option>[]>;
  onChange?: (
    value: Value | Value[],
    option?:
      | AutocompleteEventOption<Option>
      | AutocompleteEventOption<Option>[],
  ) => void;
  onSelect?: (value: Value, option: AutocompleteEventOption<Option>) => void;
  onDeselect?: (value: Value, option: AutocompleteEventOption<Option>) => void;
}

export type AutocompleteSizes = 'large' | 'middle' | 'small';

function Autocomplete<Option = unknown>({
  value,
  initialValue,
  placeholder,
  disabled,
  size = 'large',
  onChange,
  onBlur,
  onSelect,
  onDeselect,
  mode,
  excludeElementIds,
  minSearchLength,
  fetchData,
  appendToMenu,
  refreshAutocompleteTrigger,
  ...rest
}: AutocompleteProps<Option>) {
  const { t } = useTranslation('form');
  const [isLoading, setLoading] = useState(false);
  const [availableOptions, setAvailableOptions] = useState<
    AutocompleteOption<Option>[]
  >([]);
  const [canUseInitialValue, setCanUseInitialValue] = useState(true);

  useFetch(async () => {
    if (!isLoading && refreshAutocompleteTrigger) {
      await fetchAndSaveOptions('');
    }
  }, [refreshAutocompleteTrigger]);

  const formattedValue = useMemo(() => {
    if (value) {
      if (canUseInitialValue) {
        setCanUseInitialValue(false);
      }

      return value;
    }

    if (Array.isArray(initialValue)) {
      if (canUseInitialValue) {
        setCanUseInitialValue(false);
        return initialValue.map(({ id }) => id);
      } else {
        return [];
      }
    } else {
      return initialValue?.id;
    }
  }, [value, initialValue]);

  const fieldPlaceholder = useMemo(() => {
    if (placeholder) {
      return placeholder;
    }

    if (mode === 'multiple') {
      return t('select_multiple_options');
    } else {
      return t('select_option');
    }
  }, [mode, placeholder, t]);

  const fetchAndSaveOptions = async (searchQuery: string) => {
    if (fetchData) {
      const options = await fetchData(searchQuery);
      setAvailableOptions(options);
      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(
    debounce(fetchAndSaveOptions, AUTOCOMPLETE_DEBOUNCE_DELAY),
    [fetchData],
  );

  const onSearch = (searchQuery: string) => {
    if (fetchData) {
      if (!minSearchLength || searchQuery.length >= minSearchLength) {
        setLoading(true);
        debouncedFetchData(searchQuery);
      } else {
        setAvailableOptions([]);
        setLoading(false);
      }
    }
  };

  const handleFocus: React.FocusEventHandler<HTMLElement> = async () => {
    if (!availableOptions.length) {
      await onSearch('');
    }
  };

  const formatOptions = (options: AutocompleteOption<Option>[]) => {
    return options.map((e) => (
      <SelectAntD.Option
        key={e.id}
        value={e.id}
        model={e.model}
        disabled={e.disabled}
      >
        {e.label}
      </SelectAntD.Option>
    ));
  };

  const renderOptions = (
    items: AutocompleteOption<Option>[],
    initialOptions?: AutocompleteOption<Option> | AutocompleteOption<Option>[],
    skipItemIds?: Value[],
  ): JSX.Element[] | null => {
    if (isLoading) {
      return null;
    }

    let options: AutocompleteOption<Option>[] | null = null;

    let initialOptionsArray: AutocompleteOption<Option>[] = [];

    if (initialOptions) {
      initialOptionsArray = Array.isArray(initialOptions)
        ? initialOptions
        : [initialOptions];
    }

    if (!items.length) {
      options = initialOptionsArray;
    } else {
      const itemsCopy = items.slice();

      const formattedOptionsCopy = initialOptionsArray.slice();
      const existingItemIds = itemsCopy.map((x) => x.id);
      // Find not existed initialItems in options array
      const notExistingItems = formattedOptionsCopy.filter(
        (x) => !existingItemIds.includes(x.id),
      );

      options = [...notExistingItems, ...itemsCopy];
    }

    if (skipItemIds) {
      options = options.filter((e) => !skipItemIds.includes(e.id));
    }

    return formatOptions(options);
  };

  return (
    <StyledSelectAntD
      {...rest}
      showArrow
      showSearch
      allowClear
      clearIcon={
        <StyledIconSVG
          component={CloseIcon}
          color={darkTheme.colorWhite}
          size="extra-small"
        />
      }
      value={formattedValue}
      mode={mode}
      size={size}
      disabled={disabled}
      onFocus={handleFocus}
      onBlur={onBlur}
      onChange={onChange as any}
      onSelect={onSelect as any}
      onDeselect={onDeselect as any}
      onSearch={onSearch}
      placeholder={fieldPlaceholder}
      loading={isLoading}
      filterOption={false}
      dropdownRender={(menu) => (
        <>
          {appendToMenu}
          {menu}
        </>
      )}
      notFoundContent={
        isLoading ? (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        ) : undefined
      }
    >
      {renderOptions(availableOptions || [], initialValue, excludeElementIds)}
    </StyledSelectAntD>
  );
}

const StyledSelectAntD = styled(SelectAntD)`
  .ant-select-clear {
    background-color: ${({ theme }) => theme.warningColor};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
`;

export default Autocomplete;
