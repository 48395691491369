import React from 'react';

// helpers
import useUserAccess from '../../../../../../../hooks/useUserAccess';
import { IClientGroup } from '../../../../../../../typings/crm/client-group';

// components
import TableSection from './TableSection';
import ForeignExchangeSection from './ForeignExchangeSection';
import { Divider } from 'antd';

interface IProps {
  clientGroup: IClientGroup;
}

const FeeScheduleTab = ({ clientGroup }: IProps) => {
  const [hasFeeScheduleAccess, hasFXSpreadMatrixAccess] = useUserAccess([
    'CRM_ClientGroup_Details_FeeScheduleTab_FeeSchedule_View',
    'CRM_ClientGroup_Details_FeeScheduleTab_SpreadMatrix_View',
  ]);

  return (
    <>
      {hasFeeScheduleAccess && <TableSection clientGroup={clientGroup} />}
      {hasFeeScheduleAccess && hasFXSpreadMatrixAccess && <Divider />}
      {hasFXSpreadMatrixAccess && (
        <ForeignExchangeSection clientGroup={clientGroup} />
      )}
    </>
  );
};

export default FeeScheduleTab;
