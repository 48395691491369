import React from 'react';

// helpers
import { SubRouteModel } from '../../typings/routes';

// layouts & helper components
import AuthLayout from '../../layouts/AuthLayout';
import { Redirect } from 'react-router-dom';

// components
import SignIn from '../../modules/Auth/SignIn';
import SessionActivate from '../../modules/Auth/SessionActivate';
import TwoFactorAuthentication from '../../pages/Authentication/TwoFactorAuthentication';

enum AuthRoutesEnum {
  Auth_Root = '/auth',
  Auth_SignIn = '/auth/signin',
  Auth_ActivateAccount = '/auth/activate',
  Auth_TwoFactorAuthentication = '/auth/2fa',
}

const authRoutes: SubRouteModel[] = [
  {
    title: 'Sign In',
    path: AuthRoutesEnum.Auth_Root,
    layout: AuthLayout,
    component: () => <Redirect to={AuthRoutesEnum.Auth_SignIn} />,
    childRoutes: [
      {
        title: 'Sign In',
        path: AuthRoutesEnum.Auth_SignIn,
        layout: AuthLayout,
        component: SignIn,
      },

      {
        title: 'Sign In',
        path: AuthRoutesEnum.Auth_ActivateAccount,
        layout: AuthLayout,
        component: SessionActivate,
      },

      {
        title: 'Two Factor Authentication',
        path: AuthRoutesEnum.Auth_TwoFactorAuthentication,
        layout: AuthLayout,
        component: TwoFactorAuthentication,
      },
    ],
  },
];

export { authRoutes, AuthRoutesEnum };
