import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { Moment } from 'moment';

// components
import DatePicker from '../../../../../components/Forms/FormComponents/DatePicker';
import { Col, Row, Typography } from 'antd';

interface IProps {
  filterChange: (value: Moment | null) => void;
  dateFilterValue: Moment | null;
}

const FilterBar = ({ dateFilterValue, filterChange }: IProps) => {
  const { t } = useTranslation(['finance', 'common']);
  const gridSizes = { xl: 6, lg: 8, md: 12, sm: 24, xs: 24 };

  return (
    <Row gutter={[16, 16]}>
      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('currency_rates.filter_by_date')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <StyledDatePicker
              size="large"
              placeholder={t('select_date', { ns: 'common' })}
              value={dateFilterValue}
              onChange={filterChange}
              disabledDate={(currentDate: Moment) =>
                !currentDate || currentDate.isAfter(new Date())
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export default FilterBar;
