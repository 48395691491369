import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';

import { Moment } from 'moment';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { DEFAULT_FULL_DATE_FORMAT } from '../../../../../../../constants/global';
import {
  ComparisonOperators,
  ExpectedDataTypes,
} from '../../../../../../../enums/compliance/reviewConfiguration';

// components
import DatePicker from '../../../../../../Forms/FormComponents/DatePicker';
import YesNoSelect from '../../../../../../Forms/FormComponents/SelectInputs/YesNoSelect';
import AmountField from '../../../../../../Forms/FormComponents/AmountField';
import ArrayOfNumberField from './ArrayOfNumberField';
import ArrayOfStringsSelect from './ArrayOfStringsSelect';
import CountryExpectedValueSelect from '../../../../../../Forms/FormComponents/SelectInputs/Compliance/CountryExpectedValueSelect';
import { Input, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface IProps {
  name: string;
  onChange: (newValue: any) => void;
  dataType: ExpectedDataTypes | null;
  dataOptions?: string[] | null;
  comparisonOperator: ComparisonOperators | null;
}

const ComparisonOperatorsForMultipleSelectMode = [
  ComparisonOperators.In,
  ComparisonOperators.NotIn,
  ComparisonOperators.IsAnyIn,
];

const EsxpectedValueField = ({
  dataType,
  dataOptions,
  comparisonOperator,
  ...rest
}: IProps) => {
  const { t } = useTranslation(['common', 'compliance']);

  const renderField = () => {
    const isMultipleMode =
      comparisonOperator &&
      ComparisonOperatorsForMultipleSelectMode.includes(comparisonOperator);
    if (dataOptions?.length) {
      return (
        <ArrayOfStringsSelect
          {...rest}
          mode={isMultipleMode ? 'multiple' : undefined}
          items={dataOptions}
          placeholder={t('enter_value')}
        />
      );
    }

    switch (dataType) {
      case ExpectedDataTypes.String: {
        return isMultipleMode ? (
          <ArrayOfStringsSelect
            {...rest}
            mode="tags"
            items={[]}
            placeholder={t('enter_value')}
          />
        ) : (
          <Input {...rest} placeholder={t('enter_value')} />
        );
      }

      // TODO: create a separate component for ArrayOfNumber, that will allow to enter only numbers
      case ExpectedDataTypes.Number: {
        return isMultipleMode ? (
          <ArrayOfStringsSelect
            {...rest}
            mode="tags"
            items={[]}
            placeholder={t('.enter_value')}
          />
        ) : (
          <StyledInputNumber
            {...rest}
            step={0}
            placeholder={t('enter_value')}
          />
        );
      }

      case ExpectedDataTypes.Boolean:
        return (
          <YesNoSelect
            {...rest}
            placeholder={t('select_option')}
            yesText={t('true')}
            noText={t('false')}
          />
        );

      case ExpectedDataTypes.Date:
        return (
          <StyledDatePicker
            {...rest}
            placeholder={t('select_date')}
            format={DEFAULT_FULL_DATE_FORMAT}
          />
        );

      case ExpectedDataTypes.ArrayOfString:
        return (
          <ArrayOfStringsSelect
            {...rest}
            mode="multiple"
            items={[]}
            placeholder={t('enter_value')}
          />
        );

      case ExpectedDataTypes.ArrayOfNumber:
        return (
          <ArrayOfNumberField
            {...rest}
            mode="multiple"
            items={[]}
            placeholder={t('enter_value')}
          />
        );

      case ExpectedDataTypes.CountryRiskLevel:
        return (
          <CountryExpectedValueSelect
            {...rest}
            placeholder={t('select_option')}
          />
        );

      default:
        return (
          <WarningText>
            <StyledInfoIcon />
            {t('review_configuration.choose_data_type_first')}
          </WarningText>
        );
    }
  };

  return <>{renderField()}</>;
};

const StyledInputNumber = styled(AmountField)`
  width: 100%;
`;

const StyledDatePicker = styled(DatePicker)<PickerProps<Moment>>`
  width: 100%;
`;

const WarningText = styled(Typography.Text)`
  color: ${({ theme }) => theme.warningColor};
`;

const StyledInfoIcon = styled(ExclamationCircleOutlined)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

export default EsxpectedValueField;
