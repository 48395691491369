import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { IRelationshipTemplateModel } from 'typings/crm/relationship-template';

// components
import Link from '@common_components/Texts/Link';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

export type ActionKeys = 'view';

export interface IProps
  extends RequiredPropsForTableModel<IRelationshipTemplateModel> {
  onActionsClick?: (
    key: ActionKeys,
    record: IRelationshipTemplateModel,
  ) => void;
}

const RelationshipTemplatesJustNameTable = memo(
  ({ onActionsClick, ...rest }: IProps) => {
    const { t } = useTranslation('crm');

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          key: 'name',
          title: t('entity_type_categories.table.name'),
          render: (record: IRelationshipTemplateModel) => {
            const name = `${record.parentName} / ${record.childName}`;
            if (onActionsClick) {
              return (
                <Link
                  onClick={() =>
                    onActionsClick && onActionsClick('view', record)
                  }
                >
                  {name}
                </Link>
              );
            } else {
              return name;
            }
          },
        },
      ];

      return result;
    }, [t, onActionsClick]);

    return <Table columns={columns} {...rest} size="small" bordered />;
  },
);

export default RelationshipTemplatesJustNameTable;
