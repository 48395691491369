import React, { useMemo } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '../../../../../hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { DateHelpers } from '@helpers/date';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';
import { ApprovalWorkflowInstanceModel } from '../../../../../typings/approval/approvalWorkflow';

// components
import Link from '@common_components/Texts/Link';
import DetailsRow from '../DetailsRow';
import ApprovalWorkflowStatus from 'components/Additional/Statuses/ApprovalWorkflowStatus';
import { Divider } from 'antd';

interface IProps {
  approvalInstance: ApprovalWorkflowInstanceModel;
}

const ApprovalInstanceInfo = ({ approvalInstance }: IProps) => {
  const { t } = useTranslation('approval_management');
  const history = useHistory();
  const [hasORACUserDetailsAccess] = useUserAccess(['ORAC_Users_Details_View']);

  const renderDetails = useMemo(() => {
    const options = [
      {
        title: t('approval_workflow.approval_template_name'),
        value: t(
          `approval_workflow.approval_templates.${approvalInstance.templateCode}`,
        ),
      },
      {
        title: t('approval_workflow.approval_template_code'),
        value: approvalInstance.templateCode,
      },
      {
        title: t('approval_workflow.entry_type'),
        value: t(
          `approval_workflow.approval_entry_types.${approvalInstance.entryType}`,
          approvalInstance.entryType,
        ),
      },
      {
        title: t('approval_workflow.status'),
        value: <ApprovalWorkflowStatus status={approvalInstance.status} />,
      },

      {
        title: t('approval_workflow.requested_by'),
        value: hasORACUserDetailsAccess ? (
          <Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.ORAC_User_Details,
                search: `?id=${approvalInstance.startedBy.userId}`,
              })
            }
          >
            {approvalInstance.startedBy.userName}
          </Link>
        ) : (
          approvalInstance.startedBy.userName
        ),
      },

      {
        title: t('approval_workflow.creation_date'),
        value: approvalInstance.startedBy.startedDate
          ? DateHelpers.formatDateToLocalString(
              approvalInstance.startedBy.startedDate,
              DEFAULT_DATE_TIME_FORMAT,
            )
          : '',
      },
    ];

    return options.map(({ title, value }, i) => (
      <DetailsRow key={i} title={title} value={value as string} />
    ));
  }, [approvalInstance, hasORACUserDetailsAccess, history]);

  return (
    <>
      <Divider orientation="left">
        {t('approval_workflow.approval_instance_information')}
      </Divider>
      {renderDetails}
    </>
  );
};

export default ApprovalInstanceInfo;
