import React from 'react';

// helpers
import styled from 'styled-components';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { BankingBankAccount } from 'typings/banking/bankAccounts';
import { bankingBankAccountsAPI } from 'api/banking/bankingBankAccountsAPI';
import { AUTOCOMPLETE_RESULT_LIMIT } from 'constants/global';

// components
import EllipsisTooltip from '../../../../../Tooltips/EllipsisTooltip';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteOption,
} from '@core_components/Autocomplete';
import { Typography } from 'antd';

interface IProps extends AutocompleteProps<BankingBankAccount> {
  isActive?: boolean | 'all';
  isFrozen?: boolean | 'all';
}

const ClientBankAccountsAutocomplete = ({
  isActive,
  isFrozen,
  ...rest
}: IProps) => {
  const fetchAccounts = async (searchText: string) => {
    const { data } = await bankingBankAccountsAPI.fetchBankAccounts({
      accountQuery: searchText,
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      isActive,
      isFrozen,
    });

    return data.map<AutocompleteOption<BankingBankAccount>>(
      (account: BankingBankAccount) => {
        const { accountNumber, accountName, currencyIsoCode, balance } =
          account;
        const accountBalance = `${currencyIsoCode} ${AccountsHelpers.convertAmountBigIntToLocaleString(balance)}`;
        return {
          id: accountNumber,
          label: (
            <EllipsisTooltip
              placement="right"
              overlayStyle={{ maxWidth: '300px' }}
              title={
                <>
                  <StyledParagraph>{accountNumber}</StyledParagraph>
                  <StyledParagraph>{accountName}</StyledParagraph>
                  <StyledParagraph>{accountBalance}</StyledParagraph>
                </>
              }
            >
              {AccountsHelpers.generateAccountFullName(
                accountName,
                accountNumber,
                currencyIsoCode,
                balance,
              )}
            </EllipsisTooltip>
          ),
          model: account,
        };
      },
    );
  };

  return <Autocomplete {...rest} fetchData={fetchAccounts} />;
};

const StyledParagraph = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
`;

export default ClientBankAccountsAutocomplete;
