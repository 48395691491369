import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { BankAccountTemplateData } from '..';

// components
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

interface IProps {
  data: BankAccountTemplateData;
}

const InnerTemplate = ({ data }: IProps) => {
  const { t } = useTranslation('crm');

  const descriptionsData = useMemo<DescriptionItem[]>(() => {
    return [
      {
        label: t(
          'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.bank_account_changes_template.account_name',
        ),
        description: data.bankAccount.accountName,
      },

      {
        label: t(
          'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.bank_account_changes_template.account_number',
        ),
        description: data.bankAccount.accountNumber,
      },

      {
        label: t(
          'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.bank_account_changes_template.currency',
        ),
        description: data.bankAccount.accountCurrency,
      },
    ];
  }, [data]);

  return <DescriptionSection data={descriptionsData} />;
};

export default InnerTemplate;
