import React, { useMemo } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import { DateHelpers } from '@helpers/date';
import { reportingAPI } from 'api/reporting/reportingAPI';
import { entityAdapter } from 'apiAdapters/crm/entity/entityAdapter';
import { compareObjects } from '@helpers/utils';
import { YYYYMMDD_TIME_FORMAT } from 'constants/global';
import { HighlightFieldStatuses } from '@core_components/FormField';

// components
import ViewEntryChangeLogDetailsModal, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/Reporting/ViewEntryChangeLogDetailsModal';

interface IProps {
  entryId: string | null;
  onModalClose: () => void;
}

const EntryDetailsModal = ({ entryId, onModalClose }: IProps) => {
  const { response } = useFetch(
    () => (entryId ? reportingAPI.fetchEntryChangesLogById(entryId) : null),
    [entryId],
  );

  const initialValues = useMemo<{
    initialFormValues: FormValuesModel;
    modifiedValues?: Record<string, HighlightFieldStatuses>;
  } | null>(() => {
    if (!response) {
      return null;
    }

    const { newState, previousState, entity } = response;
    const changedEntryDetails = {
      id: entity.id,
      type: entity.type,
      name: entity.name,
      changedDate: response._audit.createdAt,
      user: {
        id: response.user.id,
        name: response.user.name,
      },
    };

    if (newState?.organizationState && previousState?.organizationState) {
      const formattedOldState: FormValuesModel = {
        changedEntryDetails,
        entry: entityAdapter.formatEntityDataFromQueryToForm(
          previousState.organizationState,
        ),
      };
      const formattedNewState: FormValuesModel = {
        changedEntryDetails,
        entry: entityAdapter.formatEntityDataFromQueryToForm(
          newState.organizationState,
        ),
      };

      const modifiedValues = compareObjects(
        {
          ...formattedOldState,
          entry: {
            ...formattedOldState.entry,
            dateOfIncorporation: formattedOldState.entry.dateOfIncorporation
              ? DateHelpers.formatDateToString(
                  formattedOldState.entry.dateOfIncorporation,
                  YYYYMMDD_TIME_FORMAT,
                )
              : null,
          },
        },
        {
          ...formattedNewState,
          entry: {
            ...formattedNewState.entry,
            dateOfIncorporation: formattedNewState.entry.dateOfIncorporation
              ? DateHelpers.formatDateToString(
                  formattedNewState.entry.dateOfIncorporation,
                  YYYYMMDD_TIME_FORMAT,
                )
              : null,
          },
        },
      );

      if (
        formattedOldState.entry.addresses.length >
        formattedNewState.entry.addresses.length
      ) {
        const deletedAddresses = formattedOldState.entry.addresses.slice(
          -(
            formattedOldState.entry.addresses.length -
            formattedNewState.entry.addresses.length
          ),
        );
        formattedNewState.entry.addresses.push(...deletedAddresses);
      }

      return {
        modifiedValues,
        initialFormValues: formattedNewState,
      };
    } else {
      return null;
    }
  }, [response]);

  return (
    <ViewEntryChangeLogDetailsModal
      isVisible={!!entryId}
      closeCallback={onModalClose}
      initialValues={initialValues?.initialFormValues || null}
      highlightFields={initialValues?.modifiedValues}
      onSubmit={console.log}
    />
  );
};

export default EntryDetailsModal;
