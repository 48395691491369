import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { FieldValidationRule } from '@core_components/FormField';

// components
import InputPhoneNumber, {
  InputPhoneNumberProps,
} from '@core_components/InputPhoneNumber';

interface FormInputPhoneNumberProps extends InputPhoneNumberProps {
  validationRules?: FieldValidationRule[];
}

const FormInputPhoneNumber = memo(
  ({ validationRules, ...rest }: FormInputPhoneNumberProps) => {
    const { t } = useTranslation('form');

    const placeholder = useMemo(() => {
      if (!validationRules || !validationRules.length) {
        return t('field_placeholders.input_optional');
      }

      const isRequired = validationRules.find((e) => e.type === 'required');

      if (isRequired) {
        return t('field_placeholders.input_required');
      } else {
        return t('field_placeholders.input_optional');
      }
    }, [validationRules]);

    return <InputPhoneNumber {...rest} placeholder={placeholder} />;
  },
);

export default FormInputPhoneNumber;
