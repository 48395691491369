import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { TransferTemplatePermissions } from '../../../../../enums/finance/transferTemplates';

// components
import TwoColRow from '../../../TwoColRow';
import EllipsisTooltip from '../../../../Tooltips/EllipsisTooltip';

export type InternalTemplate = {
  id: number;
  creationDate: number;
  isOnlyMe: boolean;
  reference: string;
  accountName: string;
  templateName: string;
  accountNumber: string;
};

interface IProps {
  template: InternalTemplate;
  maxValueWidthForTooltip?: string;
}

const EnhancedTwoColRow = memo(
  ({
    label,
    value,
    maxTextContainerWidth,
  }: {
    label: React.ReactNode;
    value: React.ReactNode;
    maxTextContainerWidth?: string;
  }) => {
    return (
      <TwoColRow
        labelWidth="230px"
        label={label}
        value={
          <EllipsisTooltip
            title={value}
            maxTextContainerWidth={maxTextContainerWidth}
          >
            {value}
          </EllipsisTooltip>
        }
      />
    );
  },
);

const InternalTransferTemplateInfo = memo(
  ({ template, maxValueWidthForTooltip }: IProps) => {
    const { t } = useTranslation('crm');

    return (
      <>
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.internal_transfer_template_details_section.id',
          )}
          value={template.id}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.internal_transfer_template_details_section.date',
          )}
          value={DateHelpers.formatTimestampToString(template.creationDate)}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.internal_transfer_template_details_section.template_name',
          )}
          value={template.templateName}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.internal_transfer_template_details_section.permissions',
          )}
          value={t(
            `transfer_templates.transfer_template_permissions.${
              template.isOnlyMe
                ? TransferTemplatePermissions.Private
                : TransferTemplatePermissions.Public
            }`,
          )}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.internal_transfer_template_details_section.account_name',
          )}
          value={template.accountName}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.internal_transfer_template_details_section.account_number',
          )}
          value={template.accountNumber}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.internal_transfer_template_details_section.reference',
          )}
          value={template.reference}
        />
      </>
    );
  },
);

export default InternalTransferTemplateInfo;
