import React, { useCallback, useState } from 'react';

// helpers
import useTranslation from '../../../hooks/useTranslation';
import { ISendMessageForm } from '../../../components/Modals/TemplateModalDialogs/Messaging/SendMessageModal/InnerForm';
import { IDocumentAssociationInfo } from '../../CRM/Documents/UploadDocumentsDialog';

// components
import SendMessageModal from '../../../components/Modals/TemplateModalDialogs/Messaging/SendMessageModal';
import { Button } from 'antd';

interface IProps {
  categoryId?: number;
  association?: IDocumentAssociationInfo;
  createThread: (values: ISendMessageForm) => void;
  clientGroupId: string;
}

const SendMessage = ({
  categoryId,
  association,
  clientGroupId,
  createThread,
}: IProps) => {
  const { t } = useTranslation('crm');
  const [isVisible, setVisible] = useState(false);

  const handleSubmit = async (values: ISendMessageForm) => {
    await createThread(values);
  };

  const openModal = useCallback(() => {
    setVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <>
      <Button type="primary" onClick={openModal}>
        {t('messaging.new.send_message')}
      </Button>
      <SendMessageModal
        title={t('messaging.new.title')}
        sendButtonTitle={t('messaging.new.send_message')}
        isVisible={isVisible}
        closeCallback={closeModal}
        onSubmit={handleSubmit}
        categoryId={categoryId}
        clientGroupId={clientGroupId}
        association={association}
      />
    </>
  );
};

export default SendMessage;
