import React, { memo } from 'react';

// components
import ForgotUsernameRequestsTable from 'modules/ORAC/ForgotUsernameRequests/ForgotUsernameRequestsTable';

const ForgotUsernameRequestsPage = memo(() => {
  return <ForgotUsernameRequestsTable />;
});

export default ForgotUsernameRequestsPage;
