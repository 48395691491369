import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { INoteModel } from '../../../../../typings/note/note';
import { DateHelpers } from '@helpers/date';
import { NoteRelatedType } from '../../../../../enums/crm/crm';
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../../constants/global';

// components
import DocumentLink from '../../../../Typography/DocumentLink';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import Table, {
  RequiredPropsForTableModel,
  SortProps,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<INoteModel> {
  onSortChange?: (sorter: SortProps) => void;
}

const ComplianceNotesTable = memo((props: IProps) => {
  const { t } = useTranslation('crm');
  const history = useHistory();

  const renderRelatedTo = ({ sharedWith }: INoteModel) => {
    return sharedWith.map((model) => {
      let name = '';
      let navigateLink = '';

      switch (model.itemType) {
        case NoteRelatedType.Organization: {
          name = model.name;
          navigateLink = RoutePaths.CRM_Entities_Edit;
          break;
        }
        case NoteRelatedType.Contact: {
          name = model.name;
          navigateLink = RoutePaths.CRM_Contacts_Edit;
          break;
        }
        case NoteRelatedType.ClientGroup: {
          name = model.name;
          navigateLink = RoutePaths.CRM_Client_Group_Details;
          break;
        }
        case NoteRelatedType.ReviewProcess: {
          name = model.name
            ? model.name
            : t(`entity.notes.relatedToTypes.${model.itemType}`);
          navigateLink = RoutePaths.Compliance_Transactions_Details;
        }
      }

      return (
        <div key={model.itemId}>
          <Typography.Link
            onClick={() => history.push(`${navigateLink}?id=${model.itemId}`)}
          >
            {name}
          </Typography.Link>
        </div>
      );
    });
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        showSorterTooltip: false,
        title: t('entity.notes.date'),
        key: 'createdAt',
        width: '20%',
        ellipsis: true,
        render: (record: INoteModel) =>
          DateHelpers.formatDateToString(
            record.createdAt,
            DEFAULT_DATE_TIME_FORMAT,
          ),
      },
      {
        title: t('entity.notes.createdBy'),
        key: 'creator',
        width: '20%',
        ellipsis: true,
        render: (record: INoteModel) => record.creator.name || '',
      },
      {
        title: t('entity.notes.description'),
        key: 'text',
        render: (record: INoteModel) => (
          <EllipsisTooltip maxTextContainerWidth="400px" title={record.text}>
            {record.text}
          </EllipsisTooltip>
        ),
      },
      {
        title: t('entity.notes.documents'),
        key: 'documents',
        render: (record: INoteModel) =>
          record.documents.map((document) => {
            return (
              <div key={document._id}>
                <DocumentLink
                  documentId={document._id}
                  fileId={document.fileId}
                  text={document.name}
                  fileName={document.name}
                />
              </div>
            );
          }),
      },
      {
        title: t('entity.notes.relatedTo'),
        key: 'relatedTo',
        render: renderRelatedTo,
      },
    ];

    return result;
  }, []);

  return <Table {...props} columns={columns} />;
});

export default ComplianceNotesTable;
