import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { darkTheme } from '@resources/theme/styled';
import { StyledComponentProps } from 'typings/common';

// components
import IconSVG from '@core_components/IconSVG';
import { Popover } from 'antd';
import { ReactComponent as InfoIcon } from 'resources/icons/remix-icons/information-line.svg';

type IProps = StyledComponentProps;

const CannotDeleteOwnershipRelationshipTooltip = memo((props: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <Popover
      {...props}
      placement="left"
      content={t(
        'client_group.structure.node_modal.cannot_edit_ownership_relationship_tooltip',
      )}
      overlayStyle={{ zIndex: 99999 }}
    >
      <StyledIconSVG color={darkTheme.warningColor} component={InfoIcon} />
    </Popover>
  );
});

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
  margin-right: ${({ theme }) => theme.marginSm};
`;

export default CannotDeleteOwnershipRelationshipTooltip;
