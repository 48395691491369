// Actions
export enum ActionType {
  SET_CHANGE_VIEW_HANDLER = 'view/SET_CHANGE_VIEW_HANDLER',
  REMOVE_CHANGE_VIEW_HANDLER = 'view/REMOVE_CHANGE_VIEW_HANDLER',
}

export enum CHANGE_VIEW_TYPES {
  MODAL = 'modal',
  TABS = 'tabs',
  TREE = 'tree',
  PAGE = 'page',
}

// Action creators

// This action has to be called to trigger change view handler
// Example of usage:
// Trigger view change handler before navigation to some page
// dispatch(changeView(() => history.push("/accounts/account-summary"))
export const changeView =
  (callback: () => void, type: CHANGE_VIEW_TYPES = CHANGE_VIEW_TYPES.PAGE) =>
  async (dispatch: any, getState: any) => {
    const { handler } = getState().view;

    if (handler) {
      handler(() => {
        dispatch(removeChangeViewHandler());
        callback();
      }, type);
    } else {
      callback();
    }
  };

// Set handler function that will be called before changing the view
// Handler has one argument to trigger view change, when all confirm actions were done
export const setChangeViewHandler =
  (handler: (resolve?: () => void, type?: CHANGE_VIEW_TYPES) => void) =>
  async (dispatch: any) => {
    dispatch({
      type: ActionType.SET_CHANGE_VIEW_HANDLER,
      payload: handler,
      key: 'handler',
    });
  };

export const removeChangeViewHandler = () => async (dispatch: any) => {
  dispatch({
    type: ActionType.SET_CHANGE_VIEW_HANDLER,
    payload: null,
    key: 'handler',
  });
};
