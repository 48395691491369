import React from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import { reportingAPI } from '../../../api/reporting/reportingAPI';

// components
import ExposureByCurrencyTable from '../../../components/Tables/TableTemplates/Reporting/ExposureByCurrencyTable';

const ExposureByCurrencyReport = () => {
  const { response, loading } = useFetch(() =>
    reportingAPI.fetchExposureByCurrencyReport(),
  );

  return (
    <ExposureByCurrencyTable loading={loading} data={response?.data || []} />
  );
};

export default ExposureByCurrencyReport;
