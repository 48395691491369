import React, { useEffect } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_CURRENCIES_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import CurrenciesTable from '../../modules/Finance/Currencies/CurrenciesTable';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const Currencies = ({ setHeaderOptions }: IProps) => {
  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation link={GENESIS_CURRENCIES_DOCUMENTATION_LINK} />
      ),
    });
  }, []);

  return <CurrenciesTable />;
};

export default Currencies;
