import React, { useState } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useFetch from '../../../hooks/useFetch';
import useTranslation from '../../../hooks/useTranslation';
import { reportingAPI } from '../../../api/reporting/reportingAPI';

// components
import FilterBar from './FilterBar';
import ReportTree from '../../../components/Additional/ReportTree';
import LoadingWrapper from '../../../components/WrapperComponents/LoadingWrapper';
import { Divider } from 'antd';

const ClientGroupBalancesReport = () => {
  const { t } = useTranslation('reporting');

  const [filterDate, setFilterDate] = useState(moment());

  const { response, loading } = useFetch(
    () =>
      reportingAPI.fetchClientGroupBalancesReport(
        filterDate.endOf('d').format('X'),
      ),
    [filterDate],
  );

  const handleFilterChange = (key: string, value: any) => {
    switch (key) {
      case 'date':
        setFilterDate(value);
        break;
    }
  };

  return (
    <>
      <FilterBar
        date={filterDate}
        isDisabled={loading}
        filterChange={handleFilterChange}
      />

      <Divider />

      <LoadingWrapper loading={loading}>
        {response && response.data.length ? (
          <ReportTree displayResidentHeader={false} report={response.data} />
        ) : (
          <StyledEmpty>{t('no_data')}</StyledEmpty>
        )}
      </LoadingWrapper>
    </>
  );
};

const StyledEmpty = styled.div`
  font-size: 18px;
  text-align: center;
`;

export default ClientGroupBalancesReport;
