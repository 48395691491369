import React, { memo, useMemo } from 'react';

// helpers
import { COUNTRY_CODES } from '../../../../../constants/countryCodes';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type CountryFormSelectProps = SelectProps;

const CountryFormSelect = memo((props: CountryFormSelectProps) => {
  const options = useMemo<SelectOption[]>(() => {
    return Object.keys(COUNTRY_CODES).map((key) => ({
      id: key,
      label: COUNTRY_CODES[key],
    }));
  }, []);

  return <FormSelect {...props} options={options} />;
});

export default CountryFormSelect;
