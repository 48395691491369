import React, { useEffect } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_FINANCIAL_TYPES_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import FinancialTypesTable from '../../modules/Finance/FinancialTypes/FinancialTypesTable';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const FinancialTypes = ({ setHeaderOptions }: IProps) => {
  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation
          link={GENESIS_FINANCIAL_TYPES_DOCUMENTATION_LINK}
        />
      ),
    });
  }, []);

  return <FinancialTypesTable />;
};

export default FinancialTypes;
