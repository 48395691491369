import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';

// components
import Select from 'antd/lib/select';

interface IProps {
  [key: string]: any;
}

const { Option } = Select;

const ReconciliationSelect = (props: IProps) => {
  const { t } = useTranslation('common');

  const renderOptions = () =>
    [
      { name: 'all', value: 'all' },
      { name: 'yes', value: 'true' },
      { name: 'no', value: 'false' },
    ].map(({ name, value }) => (
      <Option key={name} value={value}>
        {t(`${name}`)}
      </Option>
    ));

  return (
    <StyledSelect
      {...props}
      defaultValue="all"
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {renderOptions()}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default ReconciliationSelect;
