import { MessageModel } from '../typings/messaging/messages';
import { MessageStatuses } from '../enums/messaging/messages';

const MessagesHelpers = {
  isMessageNew: function (message: MessageModel, currentUserId: string) {
    return (
      message.status.id === MessageStatuses.New &&
      message.from.id !== currentUserId
    );
  },
};

export { MessagesHelpers };
