import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { IContactModel } from '../../../../../typings/crm/contact';
import { COUNTRY_CODES } from '../../../../../constants/countryCodes';
import { ISocialMediaModel } from '../../../../../typings/crm/entity';

// components
import ContactStatus from 'components/Additional/Statuses/ContactStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

type IProps = RequiredPropsForTableModel<IContactModel>;

const ContactsTable = memo((props: IProps) => {
  const { t } = useTranslation('crm');

  const history = useHistory();

  const renderContactName = (contact: IContactModel) => {
    const contactName =
      [contact.firstName, contact.middleName, contact.lastName]
        .filter(Boolean)
        .join(' ') || null;

    return (
      <Typography.Link
        onClick={() =>
          history.push({
            pathname: RoutePaths.CRM_Contacts_Edit,
            search: `?id=${contact._id}`,
          })
        }
      >
        {contactName}
      </Typography.Link>
    );
  };

  const renderAddress = ({ address }: IContactModel) => {
    if (!address) return null;

    const addressName =
      [
        address.street,
        address.city,
        address.state,
        address.postalCode,
        address.country,
      ]
        .filter(Boolean)
        .join(' ') || null;

    return addressName && <Typography.Text>{addressName}</Typography.Text>;
  };

  const renderSocialMedia = (socialMedia: ISocialMediaModel[]) => {
    if (!socialMedia || !socialMedia.length) return null;

    return socialMedia.map((url, i: number) => (
      <div key={i}>
        <SocialMediaLink
          href={url.path}
          target="_blank"
          rel="noopener noreferrer"
        >
          {url.type}
        </SocialMediaLink>
      </div>
    ));
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: 300,
        key: 'name',
        title: t('contacts.table.name'),
        render: renderContactName,
      },
      {
        width: 300,
        key: 'username',
        title: t('contacts.table.username'),
        render: (record: IContactModel) => record.username || '',
      },
      {
        width: 300,
        key: 'emails',
        title: t('contacts.table.emails'),
        ellipsis: true,
        render: (record: IContactModel) =>
          record.emails.length
            ? record.emails.map(({ address = '' }) => (
                <StyledParagraph key={address}>{address}</StyledParagraph>
              ))
            : null,
      },
      {
        width: 200,
        key: 'phoneNumbers',
        title: t('contacts.table.phoneNumbers'),
        render: (record: IContactModel) =>
          record.phoneNumbers.length
            ? record.phoneNumbers.map(({ number = '' }) => (
                <StyledParagraph key={number}>{number}</StyledParagraph>
              ))
            : null,
      },
      {
        width: 270,
        key: 'address',
        title: t('contacts.table.primaryResidentialAddress'),
        render: renderAddress,
      },
      {
        width: 200,
        key: 'status',
        title: t('contacts.table.status'),
        render: (record: IContactModel) => (
          <ContactStatus status={record.status} />
        ),
      },
      {
        width: 200,
        key: 'countryOfBirth',
        title: t('contacts.table.countryOfBirth'),
        dataIndex: 'countryOfBirth',
        render: (record: IContactModel) =>
          COUNTRY_CODES[record.countryOfBirth] || '',
      },
      {
        width: 150,
        key: 'socialMedia',
        title: t('contacts.table.socialMedia'),
        dataIndex: 'socialMedia',
        render: renderSocialMedia,
      },
    ];

    return result;
  }, []);

  return <Table columns={columns} {...props} />;
});

const SocialMediaLink = styled(Typography.Link)`
  text-transform: capitalize;
`;

const StyledParagraph = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
`;

export default ContactsTable;
