import React, { useState, useEffect, useMemo } from 'react';

// helpers utils
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { StateModel } from '../../../../redux/reducers';
import { securityAPI } from '../../../../api/profile/securityAPI';
import { setSessionDuration } from '../../../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';

// components
import { CardWrapper } from '../../../../layouts/PageLayout';
import { message, Modal, Select, Typography } from 'antd';

const { Option } = Select;

const SessionSettings = () => {
  const { t } = useTranslation(['profile', 'common']);
  const dispatch = useDispatch();

  const [currentSessionTimeout, setCurrentSessionTimeout] = useState(0);

  const profileSessionDuration = useSelector<StateModel, number>(
    (state) => state.auth.profileData?.sessionDuration || 0,
  );

  useEffect(() => {
    setCurrentSessionTimeout(profileSessionDuration || 60 * 15);
  }, [profileSessionDuration]);

  const sessionExpireTimeouts = useMemo(
    () => [
      {
        title: `1 ${t('sign_in_and_security.session_settings.minute')}`,
        value: 60,
      },
      {
        title: `5 ${t('sign_in_and_security.session_settings.minutes')}`,
        value: 60 * 5,
      },
      {
        title: `10 ${t('sign_in_and_security.session_settings.minutes')}`,
        value: 60 * 10,
      },
      {
        title: `15 ${t('sign_in_and_security.session_settings.minutes')}`,
        value: 60 * 15,
      },
      {
        title: `30 ${t('sign_in_and_security.session_settings.minutes')}`,
        value: 60 * 30,
      },
      {
        title: `45 ${t('sign_in_and_security.session_settings.minutes')}`,
        value: 60 * 45,
      },
      {
        title: `1 ${t('sign_in_and_security.session_settings.hour')}`,
        value: 60 * 60,
      },
    ],
    [],
  );

  const renderExpireTimeoutOptions = () =>
    sessionExpireTimeouts.map(({ title, value }) => (
      <Option key={value} value={value}>
        {title}
      </Option>
    ));

  const handleTimeoutChange = (value: any) => {
    if (value === currentSessionTimeout) return;

    const selectedOptionTitle = sessionExpireTimeouts.find(
      (e) => e.value === value,
    )?.title;

    Modal.confirm({
      content: `${t('sign_in_and_security.session_settings.change_timeout_confirmation')} ${selectedOptionTitle}?`,
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: () => changeSessionTimeout(value),
    });
  };

  const changeSessionTimeout = async (newTimeout: number) => {
    await securityAPI.setSessionTimeout(newTimeout);
    setCurrentSessionTimeout(newTimeout);
    dispatch(setSessionDuration(newTimeout));
    message.success(t('sign_in_and_security.session_settings.change_success'));
  };

  return (
    <CardWrapper>
      <Typography.Title level={4}>
        {t('sign_in_and_security.session_settings.title')}
      </Typography.Title>
      <Typography.Text>
        {`${t('sign_in_and_security.session_settings.log_out_after')} `}
        <StyledSelect
          value={currentSessionTimeout}
          onSelect={handleTimeoutChange}
        >
          {renderExpireTimeoutOptions()}
        </StyledSelect>
        {` ${t('sign_in_and_security.session_settings.of_inactivity')}.`}
      </Typography.Text>
    </CardWrapper>
  );
};

const StyledSelect = styled(Select)`
  width: 150px;
`;

export default SessionSettings;
