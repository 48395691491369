import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { useField } from 'formik';
import { EmailType } from 'enums/crm/crm';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import DeleteButton from '@common_components/Buttons/DeleteButton';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import PrimaryStatusText from 'components/Typography/PrimaryStatusText';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import VerifiedStatusText from 'components/Typography/VerifiedStatusText';
import InfoIconWithPopover from '@common_components/Icons/InfoIconWithPopover';
import EmailTypeFormSelect from 'components/Forms/FormComponents/SelectInputs/EmailTypeFormSelect';
import { Col, Row } from 'antd';

interface IProps {
  baseName: string;
  onRemove?: () => void;
}

export type FormValuesModel = {
  id: string | null;
  type: EmailType | null;
  address: string;
  isVerified?: boolean;
  primary: {
    status: boolean;
  };
};

const EmailAddressForm = memo(({ baseName, onRemove }: IProps) => {
  const { t } = useTranslation('crm');
  const [field] = useField<FormValuesModel>(baseName);
  const gridSizes = { xl: 8, lg: 8, md: 8, sm: 24, xs: 24 };

  return (
    <Row gutter={[16, 0]} justify="space-between" align="middle">
      <Col {...gridSizes}>
        <FormField
          label={t('email_form.type')}
          name={`${baseName}.type`}
          component={EmailTypeFormSelect}
          disabled={field.value.primary.status}
        />
      </Col>

      <Col flex="1 1">
        <FormField
          label={t('email_form.address')}
          name={`${baseName}.address`}
          component={FormInput}
          disabled={field.value.primary.status}
        />
      </Col>

      <DivAlignCenter>
        <StyledHelperCol>
          {typeof field.value.isVerified === 'boolean' && (
            <VerifiedStatusText isVerified={field.value.isVerified} />
          )}
        </StyledHelperCol>

        <StyledHelperCol>
          {field.value.primary.status ? (
            <DivAlignCenter>
              <PrimaryStatusText />
              <InfoIconWithPopover
                placement="left"
                popoverContent={t(
                  'contacts.new.email_address_cannot_be_updated',
                )}
              />
            </DivAlignCenter>
          ) : (
            <HideIfDisabledForm hideIfSubmitting>
              {onRemove && <DeleteButton onClick={onRemove} />}
            </HideIfDisabledForm>
          )}
        </StyledHelperCol>
      </DivAlignCenter>
    </Row>
  );
});

const StyledHelperCol = styled(Col)`
  display: flex;
  align-items: flex-end;
`;

export default EmailAddressForm;
