import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { GroupBaseModel } from '../../typings/ORAC/groups';
import {
  NewUserModel,
  UserFullModel,
  UsersBaseModel,
} from '../../typings/ORAC/users';
import { RoleWithPermissionsModel } from '../../typings/ORAC/roles';
import { ObjectWithPermissionsModel } from '../../typings/ORAC/objects';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import { AddEditPermissionsRequestBodyModel } from '../../typings/ORAC/permissions';
import { AccountRecoveryRequestTypes } from '../../enums/admin/orac';
import { ISession, IUserDevice } from '../../typings/profile/security';

interface FetchWithNameSearchParams extends FetchParamsModel {
  name?: string;
}

interface UserDirectPermissionsParamsModel extends FetchWithNameSearchParams {
  forbidden?: boolean;
}

export interface EditPermissionsModel {
  add: string[];
  remove: string[];
}

export interface EditUserModel {
  name: string;
}

export interface VerifyIdentityCodeResponse {
  valid: boolean;
}

interface UpdateKeyDataModel {
  name: string;
}

interface ISessionsParams extends FetchParamsModel {
  inactive?: boolean;
}

interface ChangeDefault2FATypeModel {
  defaultTFAType: AccountRecoveryRequestTypes;
}

const usersAPI = {
  fetchUsers: (params: FetchWithNameSearchParams) => {
    return APIService.get<FetchResponseModel<UsersBaseModel>>(
      `${APIConfig.authApi}/users`,
      { params },
    ).then(({ data }) => data);
  },

  getUserById: (userId: string) => {
    return APIService.get<UserFullModel>(
      `${APIConfig.oracApi}/users/${userId}`,
    ).then(({ data }) => data);
  },

  createUser: (newUser: NewUserModel) => {
    return APIService.post<UserFullModel>(
      `${APIConfig.crmApi}/users`,
      newUser,
    ).then(({ data }) => data);
  },

  updateUser: (userId: string, updatedData: EditUserModel) => {
    return APIService.patch<UserFullModel>(
      `${APIConfig.authApi}/users/${userId}`,
      updatedData,
    ).then(({ data }) => data);
  },

  fetchGroupsByUserId: (userId: string, params: FetchWithNameSearchParams) => {
    return APIService.get<FetchResponseModel<GroupBaseModel>>(
      `${APIConfig.oracApi}/users/${userId}/groups`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchInheritedRoles: (userId: string, params: FetchWithNameSearchParams) => {
    return APIService.get<FetchResponseModel<RoleWithPermissionsModel>>(
      `${APIConfig.oracApi}/users/${userId}/inherited-roles`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchUserPermissions: (userId: string, params: FetchWithNameSearchParams) => {
    return APIService.get<FetchResponseModel<ObjectWithPermissionsModel>>(
      `${APIConfig.oracApi}/users/${userId}/permissions`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchDirectPermissionsByUserId: (
    userId: string,
    params: UserDirectPermissionsParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<ObjectWithPermissionsModel>>(
      `${APIConfig.oracApi}/users/${userId}/direct-permissions`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  editDirectPermissionsByUserId: (
    userId: string,
    newPermissions: AddEditPermissionsRequestBodyModel,
  ) => {
    return APIService.put<null>(
      `${APIConfig.oracApi}/users/${userId}/direct-permissions`,
      newPermissions,
    ).then(({ data }) => data);
  },

  verifyIdentityCode: (userId: string, code: string) => {
    return APIService.post<VerifyIdentityCodeResponse>(
      `${APIConfig.authApi}/users/identity-code`,
      { userId, code },
    ).then(({ data }) => data);
  },

  disableTwoFactorAuthentication: (userId: string) => {
    return APIService.post(
      `${APIConfig.authApi}/users/${userId}/2fa/disable`,
    ).then(({ data }) => data);
  },

  disableFidoAuthentication: (userId: string) => {
    return APIService.patch(
      `${APIConfig.authApi}/users/${userId}/2FA/fido/disable`,
    ).then(({ data }) => data);
  },

  changeDefaultAuthenticator: (
    userId: string,
    data: ChangeDefault2FATypeModel,
  ) => {
    return APIService.patch(
      `${APIConfig.authApi}/users/${userId}/2FA-type`,
      data,
    );
  },

  fetchSecurityKeys: (userId: string) => {
    return APIService.get(
      `${APIConfig.authApi}/users/${userId}/2FA/fido/keys`,
    ).then(({ data }) => data);
  },

  updateSecurityKeyByUserId: (
    keyId: string,
    userId: string,
    updatedData: UpdateKeyDataModel,
  ) => {
    return APIService.patch(
      `${APIConfig.authApi}/users/${userId}/2FA/fido/${keyId}`,
      updatedData,
    );
  },

  removeFidoKey: (userId: string, keyId: string) => {
    return APIService.post(
      `${APIConfig.authApi}/users/${userId}/2FA/fido/${keyId}/remove`,
    );
  },

  fetchDevicesByUserId: async (params: FetchParamsModel, userId: string) => {
    return APIService.get<FetchResponseModel<IUserDevice>>(
      `${APIConfig.authApi}/sessions/devices/${userId}`,
      { params },
    ).then(({ data }) => data);
  },

  fetchSessionsByUserId: async (params: ISessionsParams, userId: string) => {
    return APIService.get<FetchResponseModel<ISession>>(
      `${APIConfig.authApi}/sessions/${userId}`,
      { params },
    ).then(({ data }) => data);
  },

  deleteSessionByUserId: async (sessionId: string, userId: string) => {
    return APIService.post(
      `${APIConfig.authApi}/sessions/${sessionId}/${userId}`,
    ).then(({ data }) => data);
  },
};

export default usersAPI;
