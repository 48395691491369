import React from 'react';

// helpers
import { useTranslation } from 'react-i18next';

// components
import SecurityKeyOption from './SecurityKeyOption';
import { Typography } from 'antd';
import { CardWrapper } from '../../../../layouts/PageLayout';

const TwoFactorVerification = () => {
  const { t } = useTranslation('profile');

  return (
    <CardWrapper>
      <Typography.Title level={4}>
        {t('sign_in_and_security.two_factor_verification.title')}
      </Typography.Title>
      <SecurityKeyOption />
    </CardWrapper>
  );
};

export default TwoFactorVerification;
