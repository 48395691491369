import { IKeyRelation } from 'typings/crm/key-relationship-templates';
import { RelationshipTrackType } from 'enums/crm/crm';
import { IKeyRelationshipInstance } from 'typings/crm/onboarding';
import { IRelationshipTemplateModel } from 'typings/crm/relationship-template';

const RelationshipHelpers = {
  getRelationshipName: function (
    relationship: { childName: string; parentName: string },
    trackType?: RelationshipTrackType,
  ) {
    let result = '';

    if (trackType) {
      result =
        trackType === RelationshipTrackType.Parent
          ? relationship.parentName
          : trackType === RelationshipTrackType.Child
            ? relationship.childName
            : '';
    } else {
      result = `${relationship.parentName} / ${relationship.childName}`;
    }

    return result;
  },

  isKeyRelationshipOwnership: function (keyRelationship: IKeyRelation) {
    return keyRelationship.options?.additionalFields
      ? Object.keys(keyRelationship.options?.additionalFields).some(
          (key) =>
            typeof keyRelationship.options?.additionalFields[key]?.equalTo !==
            'undefined',
        )
      : undefined;
  },

  findOwnershipKeyRelationships: function (
    keyRelationships: IKeyRelation[],
  ): IKeyRelation[] {
    return keyRelationships.filter((keyRelation) =>
      this.isKeyRelationshipOwnership(keyRelation),
    );
  },

  isRelationshipTemplateOwnership: function (
    relTemplate: IRelationshipTemplateModel,
    keyRelationship: IKeyRelationshipInstance,
  ): boolean {
    const ownershipKeyRelationships = this.findOwnershipKeyRelationships(
      keyRelationship.keyRelations,
    );
    return ownershipKeyRelationships.some(
      (e) => e.relationshipTemplateId === relTemplate._id,
    );
  },
};

export { RelationshipHelpers };
