import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../hooks/useFetch';
import { StateModel } from '../../../../redux/reducers';
import { approvalAPI } from '../../../../api/crm/onboarding/approvalAPI';
import { useSelector } from 'react-redux';
import {
  ApprovalStatuses,
  ApprovalWorkflowStatuses,
} from '../../../../enums/approvalManagement/workflow';
import {
  IApprovalMember,
  IApprovalProgressState,
} from '../../../../typings/crm/onboarding';

// components
import ApprovalsMatrix from '../ApprovalsMatrix';
import WorkflowActions from '../WorkflowActions';
import { Space, Spin } from 'antd';

interface INewBusinessWorkflowStatusProps {
  workflowId: string;
  onUpdate: () => void;
}

const NewBusinessWorkflowStatus = ({
  workflowId,
  onUpdate,
}: INewBusinessWorkflowStatusProps) => {
  const myId = useSelector<StateModel, string>(
    (state) => state.auth.profileData?._id || '',
  );

  const [updateTrigger, setUpdateTrigger] = useState({});

  const { response, loading } = useFetch(
    () =>
      workflowId ? approvalAPI.fetchNewBusinessStatuses(workflowId) : null,
    [updateTrigger, workflowId],
  );

  const myMember = useMemo(() => {
    let member: IApprovalMember | undefined;
    response?.approvalProgressStateSets.forEach(
      (state: IApprovalProgressState) => {
        state.progressStateItems.forEach(
          ({ members }: { members: IApprovalMember[] }) => {
            member =
              members.find(
                (member: IApprovalMember) => member.userId === myId,
              ) || member;
          },
        );
      },
    );
    return member;
  }, [response]);

  if (loading) {
    return (
      <ContentWrapper>
        <Space size="middle">
          <Spin size="large" />
        </Space>
      </ContentWrapper>
    );
  }

  const handleApprove = async (reason: string) => {
    await approvalAPI.approveNewBusiness(workflowId, reason);
    setUpdateTrigger({});
    onUpdate();
  };

  const handleReject = async (reason: string) => {
    await approvalAPI.rejectNewBusiness(workflowId, reason);
    setUpdateTrigger({});
    onUpdate();
  };

  const isWorkflowInProgress =
    response?.status === ApprovalWorkflowStatuses.PendingApproval;
  const canApprove =
    isWorkflowInProgress &&
    myMember?.approvalAction === ApprovalStatuses.Pending;

  return (
    <>
      {!!response && (
        <ApprovalsMatrix
          approvalProgressStateSets={response.approvalProgressStateSets}
        />
      )}
      <WorkflowActions
        canReject={canApprove}
        canApprove={canApprove}
        onApprove={handleApprove}
        onReject={handleReject}
      />
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

export default NewBusinessWorkflowStatus;
