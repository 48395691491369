// Actions
export enum ActionType {
  SET_LANGUAGE = 'settings/SET_LANGUAGE',
}

// Action creators
export const setLanguage = (lang: string) => ({
  type: ActionType.SET_LANGUAGE,
  payload: lang,
  key: 'language',
});
