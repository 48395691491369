import React from 'react';

// components
import TransactionReview from './TransactionReview';

const ReviewConfigurationsTab = () => {
  return <TransactionReview />;
};

export default ReviewConfigurationsTab;
