import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { StateModel } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { documentsAPI } from '../../../../api/documents/documentsAPI';
import { IDocumentBase } from '../../../../typings/documents/documents';
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';
import { DocumentAssociationTypes } from '../../../../enums/crm/crm';

// components
import DocumentsTable from '../../../../components/Tables/TableTemplates/Documents/DocumentsTable';
import DocumentDetailsModal from '../../Documents/DocumentsDetailsModal';
import UploadDocumentsDialog from '../../Documents/UploadDocumentsDialog';
import { Col, Typography, Divider, Row, Button } from 'antd';

interface IProps {
  associationType: DocumentAssociationTypes;
  associationId: string;
}

const InternalDocuments = ({ associationType, associationId }: IProps) => {
  const uploadStatus = useSelector<StateModel, boolean>(
    (state) => state.upload.isFinished,
  );
  const { t } = useTranslation('crm');
  const [currentPage, setCurrentPage] = useState(1);
  const [updateTableTrigger, updateTable] = useState({});
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null,
  );
  const [isUploadDialogVisible, setUploadDialogVisible] = useState(false);

  const { response, loading } = useFetch(
    () =>
      documentsAPI.fetchDocuments({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        associationItemId: associationId,
        associationType,
      }),
    [
      associationId,
      associationType,
      updateTableTrigger,
      currentPage,
      uploadStatus,
    ],
  );

  const handleActionsChange = (key: string, document: IDocumentBase) => {
    switch (key) {
      case 'details':
        setSelectedDocumentId(document._id);
        break;
    }
  };

  const handleDetailsModalClose = (wasModified?: boolean) => {
    if (wasModified) {
      updateTable({});
    }

    setSelectedDocumentId(null);
  };

  return (
    <>
      <SectionTitle level={4}>
        {t('client_group.approval.internal_documents')}
      </SectionTitle>
      <StyledDivider />
      <Row gutter={[36, 16]}>
        <Col span={24}>
          <ButtonWrapper>
            <Button
              type="primary"
              size="large"
              onClick={() => setUploadDialogVisible(!isUploadDialogVisible)}
            >
              {t('entity.documents.uploadDocuments')}
            </Button>
          </ButtonWrapper>
        </Col>
        <Col span={24}>
          <DocumentsTable
            showAssociation
            data={response?.data || []}
            total={response?.total || 0}
            loading={loading}
            current={currentPage}
            onPaginationChange={setCurrentPage}
            onActionsClick={handleActionsChange}
          />
        </Col>

        <UploadDocumentsDialog
          isVisible={isUploadDialogVisible}
          closeCallback={() => setUploadDialogVisible(false)}
          association={{ type: associationType, id: associationId }}
        />

        <DocumentDetailsModal
          isVisible={!!selectedDocumentId}
          closeCallback={handleDetailsModalClose}
          documentId={selectedDocumentId as string}
        />
      </Row>
    </>
  );
};

const SectionTitle = styled(Typography.Title)`
  margin-top: ${({ theme }) => theme.marginMd};

  &.ant-typography-secondary {
    margin-bottom: 0 !important;
    text-align: center;
  }
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.marginXs} 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default InternalDocuments;
