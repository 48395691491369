import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ContactStatuses } from 'enums/crm/crm';

// components
import StatusLabel from 'components/Typography/StatusLabel';

const STATUS_MAP = {
  info: [ContactStatuses.None],
  warning: [ContactStatuses.InReview],
  success: [ContactStatuses.Approved],
  error: [],
};

interface IProps {
  status: ContactStatuses;
}

const ContactStatus = ({ status }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(`contacts.statuses.${status}`)}
    />
  );
};

export default ContactStatus;
