import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { IClientGroupShort } from '../../../../../typings/crm/client-group';

// components
import Link from '@common_components/Texts/Link';
import ClientGroupReviewStatus from 'components/Additional/Statuses/ClientGroupReviewStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel<IClientGroupShort>;

const AssociatedClientGroupsTable = memo((props: IProps) => {
  const { t } = useTranslation('crm');
  const history = useHistory();

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('client_group.rdc.name'),
        key: 'clientGroupName',
        render: (record: IClientGroupShort) => (
          <Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.CRM_Client_Group_Details,
                search: `?id=${record._id}`,
              })
            }
          >
            {record.clientGroupName}
          </Link>
        ),
      },

      {
        title: t('client_group.rdc.type'),
        key: 'reviewStatus',
        render: (record: IClientGroupShort) => (
          <ClientGroupReviewStatus status={record.reviewStatus} />
        ),
      },
    ];

    return result;
  }, []);

  return <Table columns={columns} {...props} />;
});

export default AssociatedClientGroupsTable;
