import React, { useMemo } from 'react';

// helpers
import { ChallengeForRegistrationModel } from '../../../../../typings/profile/fido';
import { RegisterSecurityKeyValidationSchema } from '../../../../../validations/auth';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  title: string;
  onSubmit: (values: FormValuesModel) => Promise<void>;
  getChallengeCallback: () => Promise<ChallengeForRegistrationModel>;
}

export enum RegisterSecurityKeySteps {
  Initial,
  RegisterKey,
  SetupNameForKey,
}

export interface SecurityKeyCredModel {
  id: string;
  rawId: string;
  type: string;
  response: {
    clientDataJSON: string;
    attestationObject: string;
  };
}

export interface FormValuesModel {
  name: string;
  currentStep: RegisterSecurityKeySteps;
  securityKeyCredInfo: SecurityKeyCredModel | null;
}

const RegisterSecurityKeyModal = ({
  title,
  isVisible,
  closeCallback,
  onSubmit,
  getChallengeCallback,
}: IProps) => {
  const initialFormValues = useMemo<FormValuesModel>(() => {
    return {
      name: '',
      currentStep: RegisterSecurityKeySteps.Initial,
      securityKeyCredInfo: null,
    };
  }, []);

  const handleSubmit = async (values: FormValuesModel) => {
    await onSubmit(values);
    closeCallback(true);
  };

  return (
    <Form
      confirmExitWithoutSaving
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={RegisterSecurityKeyValidationSchema}
      initialValues={isVisible ? initialFormValues : null}
      renderForm={
        <ModalDialog
          width={600}
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          hideFooterButtons
        >
          <InnerForm getChallengeCallback={getChallengeCallback} />
        </ModalDialog>
      }
    />
  );
};

export default RegisterSecurityKeyModal;
