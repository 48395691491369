import React, { useMemo, useState } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { EntityHelpers } from '@helpers/crm/entity';
import { ContactHelpers } from '@helpers/crm/contact';
import { NoteAssociation } from 'typings/note/note';
import { clientGroupsAPI } from 'api/crm/clientGroup/clientGroupsAPI';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { INewNoteBody, noteAPI } from 'api/note/noteAPI';
import { useReviewProcessContext } from '@hooks/domainHooks/useReviewProcess';
import {
  AssociateTypes,
  CrmItemType,
  NoteMessageType,
  NoteType,
} from 'enums/crm/crm';

// components
import NoteModalDialog, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/NoteModalDialog';
import { Button, Col, Row, message } from 'antd';

interface IProps {
  onAdd: () => void;
  reviewProcessId: string;
}

const AddNote = ({ onAdd, reviewProcessId }: IProps) => {
  const { t } = useTranslation('crm');
  const { clientGroupId } = useReviewProcessContext();
  const [isVisible, setIsVisible] = useState(false);

  const { response: directClient } = useFetch(
    () =>
      clientGroupId && isVisible
        ? clientGroupsAPI.fetchDirectClient(clientGroupId)
        : null,
    [clientGroupId, isVisible],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!directClient || !isVisible) {
      return null;
    }

    let crmItems: NoteAssociation[] = [];
    let crmItemsInitialValue: AutocompleteOption[] = [];

    switch (directClient.type) {
      case AssociateTypes.Contact: {
        crmItems = [
          {
            itemId: directClient.contact?._id as string,
            itemType: CrmItemType.Contact,
          },
        ];
        crmItemsInitialValue = [
          {
            id: directClient.contact?._id as string,
            label: `${ContactHelpers.getContactName(directClient.contact)} (${t(`association_types.${CrmItemType.Contact}`)})`,
            model: {
              itemId: directClient.contact?._id as string,
              itemType: CrmItemType.Contact,
            },
          },
        ];
        break;
      }
      case AssociateTypes.Organization: {
        crmItems = [
          {
            itemId: directClient.organization?._id as string,
            itemType: CrmItemType.Organization,
          },
        ];
        crmItemsInitialValue = [
          {
            id: directClient.organization?._id as string,
            label: `${EntityHelpers.getEntityName(directClient.organization)} (${t(`association_types.${CrmItemType.Organization}`)})`,
            model: {
              itemId: directClient.organization?._id as string,
              itemType: CrmItemType.Organization,
            },
          },
        ];
        break;
      }
    }

    return {
      eventDate: moment(),
      text: '',
      type: NoteMessageType.Compliance,
      crmItems,
      crmItemsInitialValue,
    };
  }, [directClient, isVisible]);

  const handleCloseNoteDialog = (wasModified?: boolean) => {
    if (wasModified) {
      onAdd();
    }

    setIsVisible(false);
  };

  const handleSubmit = async (values: FormValuesModel) => {
    const assignToFiltered =
      values.crmItems && values.crmItems.length
        ? values.crmItems.filter((e) => e.itemId !== reviewProcessId)
        : [];

    const reqBody = {
      text: values.text,
      type: values.type,
      eventDate: values.eventDate
        ? values.eventDate.toString()
        : moment().toString(),
      assignTo: [
        { itemId: reviewProcessId, itemType: NoteType.ReviewProcess },
        ...assignToFiltered,
      ],
      documentIds: values.documents?.map((document) => document.id),
    } as INewNoteBody;

    await noteAPI.createNote(reqBody);
    message.success(t('entity.notes.successAdd'));
  };

  return (
    <>
      <StyledRow justify="end">
        <Col>
          <Button
            type="primary"
            size="large"
            onClick={() => setIsVisible(true)}
          >
            {t('entity.notes.addNote')}
          </Button>
        </Col>
      </StyledRow>

      <NoteModalDialog
        hideNoteTypeField
        onSubmit={handleSubmit}
        isVisible={isVisible}
        closeCallback={handleCloseNoteDialog}
        initialValues={initialFormValues}
      />
    </>
  );
};

const StyledRow = styled(Row)`
  margin: ${({ theme }) => theme.marginMd} 0;
`;

export default AddNote;
