import React, { memo } from 'react';

// helpers
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import RelationshipsSection from './RelationshipsSection';
import NodeInformationSection from './NodeInformationSection';

const InnerForm = memo(() => {
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <NodeInformationSection />
      {!!values.template.id && <RelationshipsSection />}
    </>
  );
});

export default InnerForm;
