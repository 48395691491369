import React from 'react';

// helpers
import styled from 'styled-components';

interface IProps {
  icon: string;
  width?: string;
  height?: string;
  [key: string]: any;
}

const Icon = ({ icon, width = '20px', height = '20px', ...rest }: IProps) => {
  return <IconImage src={icon} width={width} height={height} {...rest} />;
};

const IconImage = styled.img<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

export default Icon;
