import React from 'react';

// Helpers & Utils
import styled from 'styled-components';
import { Moment } from 'moment';
import { DatePickerProps } from 'antd/lib/date-picker';

// Components
import { DatePicker } from 'antd';

const DatePickerAntd = DatePicker as any;

// Wrapper for DatePicker component to differ future and past dates
const DatePickerComponent = (props: DatePickerProps) => {
  const dateRender = (currentDate: Moment, today: Moment) => {
    return (
      <StyledDate
        className="ant-picker-cell-inner"
        isFuture={today.diff(currentDate) < 0}
      >
        {currentDate.date()}
      </StyledDate>
    );
  };

  return (
    <DatePickerAntd dateRender={dateRender} allowClear={false} {...props} />
  );
};

const StyledDate = styled.div<{ isFuture: boolean }>`
  color: ${({ isFuture, theme }) =>
    isFuture ? theme.highlightColor : 'none'} !important;
`;

export default DatePickerComponent;
