import React from 'react';

// helpers
import useTranslation from '../../../../../../../../../hooks/useTranslation';

// components
import { SearchInput } from 'components/Fields/SearchInput';
import { Col, Row, Typography } from 'antd';

interface IProps {
  onFilterChange: (key: string, value: string) => void;
}

const FilterBar = ({ onFilterChange }: IProps) => {
  const { t } = useTranslation('orac');

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('users.view.search_by_object_name')}
            </Typography.Text>
          </Col>

          <Col span={24}>
            <SearchInput
              size="large"
              placeholder={t('users.view.search_by_object_name_placeholder')}
              onSearch={(searchQuery: string) =>
                onFilterChange('search_by_name', searchQuery)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FilterBar;
