import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { styled } from 'styled-components';
import { darkTheme } from '@resources/theme/styled';

// components
import Text from '@core_components/Text';
import IconSVG from '@core_components/IconSVG';
import TableActions from 'components/Tables/TableActions';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Divider } from 'antd';
import { ReactComponent as ErrorIcon } from 'resources/icons/remix-icons/close-circle-line.svg';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import TransactionApprovalRulesMatrix, {
  ApprovalRule,
} from '../../Finance/TransactionApprovalRulesMatrix';

export type TableRecord = {
  id: string;
  bankAccounts: { name: string; purpose: string }[];
  approvalMatrix: ApprovalRule;
  errors?: string[];
};

export type ActionKeys = 'view';

interface IProps extends RequiredPropsForTableModel<TableRecord> {
  onActionsClick?: (key: ActionKeys, record: TableRecord) => void;
}

const OnboardingTransactionRulesTable = ({
  onActionsClick,
  ...rest
}: IProps) => {
  const { t } = useTranslation('onboarding');

  const tableColumns = useMemo<TableColumnModel[]>(
    () => [
      {
        key: 'account_number',
        title: t('crm_approval.transaction_approval_rules.table.bank_accounts'),
        width: 350,
        render: (record: TableRecord) => (
          <>
            {record.errors?.length ? (
              <DivAlignCenter>
                <StyledIconSVG
                  color={darkTheme.errorColor}
                  component={ErrorIcon}
                />
                <Text color={darkTheme.errorColor} gutterBottom>
                  {t(
                    'crm_approval.transaction_approval_rules.invalid_approval_rule',
                  )}
                </Text>
              </DivAlignCenter>
            ) : null}
            {record.bankAccounts.map((e, i) => (
              <React.Fragment key={i}>
                <Text
                  variant="body1"
                  weight="semi-bold"
                  color={darkTheme.whiteColor}
                >
                  <EllipsisTooltip maxTextContainerWidth="300px" title={e.name}>
                    {e.name}
                  </EllipsisTooltip>
                </Text>
                <Text variant="body1">
                  <DivAlignCenter>
                    <Text weight="semi-bold">
                      {t(
                        'crm_approval.transaction_approval_rules.table.purpose_of_bank_account',
                      )}
                    </Text>
                    <StyledEllipsisTooltip
                      maxTextContainerWidth="200px"
                      title={e.purpose}
                    >
                      {e.purpose}
                    </StyledEllipsisTooltip>
                  </DivAlignCenter>
                </Text>
                {i < record.bankAccounts.length - 1 && <StyledDivider />}
              </React.Fragment>
            ))}
          </>
        ),
      },
      {
        key: 'transaction_approval_matrix',
        title: t('crm_approval.transaction_approval_rules.table.approval_rule'),
        render: (record: TableRecord) => (
          <TransactionApprovalRulesMatrix
            approvalRule={record.approvalMatrix}
          />
        ),
      },
      {
        hidden: !onActionsClick,
        align: 'right',
        key: 'actions',
        width: 70,
        render: (record: TableRecord) => (
          <TableActions
            onSelect={(key) =>
              onActionsClick && onActionsClick(key as ActionKeys, record)
            }
            menu={
              [
                {
                  key: 'view',
                  name: t(
                    'crm_approval.transaction_approval_rules.table.view_rule_btn',
                  ),
                },
              ] as {
                key: ActionKeys;
                name: string;
              }[]
            }
          />
        ),
      },
    ],
    [onActionsClick],
  );

  return <Table disableMobileMode columns={tableColumns} {...rest} />;
};

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.paddingSm} 0px`};
`;

const StyledEllipsisTooltip = styled(EllipsisTooltip)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

export default OnboardingTransactionRulesTable;
