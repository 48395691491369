import React, { useMemo } from 'react';

// helpers
import { darkTheme } from '@resources/theme/styled';
import { OnboardingStepReviewStatuses } from 'typings/crm/onboarding';

// components
import IconSVG, { IconSizes } from '@core_components/IconSVG';
import { ReactComponent as InfoIcon } from 'resources/icons/remix-icons/information-line.svg';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/check-line.svg';
import { ReactComponent as CloseCircleIcon } from 'resources/icons/remix-icons/close-circle-line.svg';

interface IProps {
  status?: OnboardingStepReviewStatuses | OnboardingStepReviewStatuses[];
  size?: IconSizes;
}

const ReviewStatusIcon = ({ status, size }: IProps) => {
  const icon = useMemo(() => {
    if (!status) {
      return null;
    }

    let formattedStatus;
    let hasInvalid = false;
    let hasNotReviewed = false;

    if (Array.isArray(status)) {
      hasInvalid = status.includes('invalid');
      hasNotReviewed = status.includes('notReviewed');

      formattedStatus = hasInvalid
        ? 'invalid'
        : hasNotReviewed
          ? 'notReviewed'
          : 'valid';
    } else {
      formattedStatus = status;
    }

    let result = null;
    switch (formattedStatus) {
      case 'notReviewed':
        result = (
          <IconSVG
            component={InfoIcon}
            size={size}
            color={darkTheme.warningColor}
          />
        );
        break;

      case 'valid':
        result = (
          <IconSVG
            component={CheckIcon}
            size={size}
            color={darkTheme.primaryGreen}
          />
        );
        break;

      case 'invalid':
        result = (
          <>
            {hasNotReviewed ? (
              <IconSVG
                component={InfoIcon}
                size={size}
                color={darkTheme.warningColor}
              />
            ) : (
              <IconSVG
                component={CheckIcon}
                size={size}
                color={darkTheme.primaryGreen}
              />
            )}
            <IconSVG
              component={CloseCircleIcon}
              size={size}
              color={darkTheme.errorColor}
            />
          </>
        );
        break;
    }

    return result;
  }, [status, size]);

  return icon;
};

export default ReviewStatusIcon;
