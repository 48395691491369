import React, { useEffect } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { ICreateRelationshipTemplateFormBody } from '.';
import {
  AdditionalFieldType,
  RelationshipRelatedTypes,
} from '../../../../enums/crm/crm';
import {
  FormikProps,
  FieldArray,
  FieldArrayRenderProps,
  useFormikContext,
} from 'formik';

// components
import FormField from '@core_components/FormField';
import AddButton from '../../../../components/Buttons/AddButton';
import FieldForSelect from './FieldForSelect';
import AdditionalValuesTable from './AdditionalValuesTable';
import { Col, Row, Input, Typography, Divider } from 'antd';

const RelationshipTemplateForm = () => {
  const { t } = useTranslation('crm');
  const form = useFormikContext<ICreateRelationshipTemplateFormBody>();

  useEffect(() => {
    if (!form.dirty) return;

    handleOnParentRelatedStatusChange(form.values.parentType);
  }, [form.values.parentType]);

  // The additional field parent type is related to the general parent -> relatedTo value
  // if the general parent relatedTo === all, than user don't have any limits in parent type field (can choose any option)
  // if the general parent relatedTo === contacts or entities
  // than user user will be able to select only contacts only or entities only, depends on selected value in general parent
  // after changing general related to value we should update all parent types in additional fields array
  const handleOnParentRelatedStatusChange = (
    value: RelationshipRelatedTypes,
  ) => {
    if (value === RelationshipRelatedTypes.Any) return;

    let additionalFieldsCopy = form.values.additionalFields.slice();

    // update parent type to selected general parent relatedTo value
    additionalFieldsCopy = additionalFieldsCopy.map((e) => ({
      ...e,
      relatedTo: value,
    }));

    form.setFieldValue('additionalFields', additionalFieldsCopy);
  };

  const renderAdditionalFields = (
    form: FormikProps<ICreateRelationshipTemplateFormBody>,
  ) => (
    <>
      <SectionTitle level={4}>
        {t('relationship_templates.additional_fields')}
      </SectionTitle>
      <StyledDivider />
      <FieldArray
        name="additionalFields"
        render={(arrayHelper) => (
          <>
            {!!form.values.additionalFields.length && (
              <AdditionalValuesTable
                fieldName="additionalFields"
                handleDelete={arrayHelper.remove}
                additionalValues={form.values.additionalFields.slice()}
                fields={[
                  'name',
                  'onboardingRequired',
                  'description',
                  'type',
                  'additional',
                  'deleteField',
                ]}
              />
            )}

            <AddButton
              buttonSize="small"
              onClick={() =>
                addAdditionalField(arrayHelper, AdditionalFieldType.String)
              }
            >
              {t('relationship_templates.add_additional_field')}
            </AddButton>
          </>
        )}
      />
    </>
  );

  const renderOnboardingAdditionalFields = (
    form: FormikProps<ICreateRelationshipTemplateFormBody>,
  ) => (
    <>
      <SectionTitle level={4}>
        {t('relationship_templates.individual_application_documentation')}
      </SectionTitle>

      <StyledDivider />

      <FieldArray
        name="individualAdditionalFields"
        render={(arrayHelper) => (
          <>
            {!!form.values.individualAdditionalFields.length && (
              <AdditionalValuesTable
                fields={[
                  'name',
                  'onboardingRequired',
                  'description',
                  'type',
                  'additional',
                  'deleteField',
                ]}
                fieldName="individualAdditionalFields"
                handleDelete={arrayHelper.remove}
                additionalValues={form.values.individualAdditionalFields.slice()}
                disableParentType
              />
            )}

            <AddButton
              onClick={() =>
                addAdditionalField(
                  arrayHelper,
                  AdditionalFieldType.Document,
                  RelationshipRelatedTypes.Contact,
                )
              }
              buttonSize="small"
            >
              {t('relationship_templates.add_document')}
            </AddButton>
          </>
        )}
      />
    </>
  );

  const addAdditionalField = (
    arrayHelper: FieldArrayRenderProps,
    fieldType: AdditionalFieldType,
    relatedTo = '',
  ) => {
    const field = {
      title: '',
      fieldType,
      additionalValues: undefined,
      documentRequiresCertification: false,
      onboardingRequired: false,
      isForRegulatedOnly: false,
      relatedTo,
    };

    arrayHelper.push(field);
  };

  return (
    <>
      <NameRow gutter={[0, 20]}>
        <Col span="15">
          <FormField
            name="parentName"
            component={AppInput}
            additionalProps={{
              addonBefore: t('relationship_templates.parent_name'),
            }}
          />
        </Col>

        <Col span="8" offset="1">
          <FormField
            name="parentType"
            component={FieldForSelect}
            additionalProps={{ allowClear: true }}
          />
        </Col>
      </NameRow>
      <NameRow gutter={[0, 20]}>
        <Col span="15">
          <FormField
            name="childName"
            component={AppInput}
            additionalProps={{
              addonBefore: t('relationship_templates.child_name'),
            }}
          />
        </Col>
        <Col span="8" offset="1">
          <FormField
            name="childType"
            component={FieldForSelect}
            additionalProps={{ allowClear: true }}
          />
        </Col>
      </NameRow>
      {renderAdditionalFields(form)}
      {renderOnboardingAdditionalFields(form)}
    </>
  );
};

const SectionTitle = styled(Typography.Title)`
  margin-top: ${({ theme }) => theme.marginMd};
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.marginXs} 0;
`;

const NameRow = styled(Row)`
  border-collapse: collapse;
  box-sizing: border-box;
`;

const AppInput = styled(Input)`
  width: 100%;

  .ant-input-group-addon {
    min-width: 110px;
  }
`;

export default RelationshipTemplateForm;
