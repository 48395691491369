import React, { useState } from 'react';

// helpers utils
import styled from 'styled-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { changeView } from '../redux/actions/view';
import { useDispatch } from 'react-redux';
import withPermissions from '../hoc/withPermissions';
import { SubRouteLayoutProps, SubRouteModel } from '../typings/routes';
import useTranslation from '../hooks/useTranslation';
import useBreadcrumbs, { BreadcrumbModel } from '../hooks/useBreadcrumbs';

// routes
import { indexRoutes } from '../routes/routes';

// components
import { Breadcrumb, Card, PageHeader } from 'antd';

type IProps = SubRouteLayoutProps;

export interface ILayoutHeaderOptions {
  title?: string | any;
  appendToTitle?: string | React.ReactNode;
  cardTitle?: any;
  extra?: any;
  content?: any;
  description?: any;
}

export interface PageLayoutHelperPropsModel {
  setHeaderOptions: (options: ILayoutHeaderOptions) => void;
}

const PageLayout = ({ children, route }: IProps) => {
  const { t } = useTranslation('page_titles');

  const history: any = useHistory();
  const breakpoint = useBreakpoint();
  const breadcrumbs = useBreadcrumbs(route.path, indexRoutes);

  const [headerOptions, setHeaderOptions] = useState<ILayoutHeaderOptions>({
    title: '',
    appendToTitle: '',
    cardTitle: '',
    extra: null,
    content: null,
    description: null,
  });

  const dispatch = useDispatch();
  const historyPush = (path: any, state?: any) =>
    dispatch(changeView(() => history.push(path, state)));

  const isMobileResolution = () => !breakpoint.md;

  const renderBreadcrumbs = (breadcrumbsArray: BreadcrumbModel[]) => {
    if (!breadcrumbs.length) return null;
    const lastIndex = breadcrumbs.length - 1;
    return (
      <Breadcrumb>
        {breadcrumbsArray.map(({ title, path }, index) => (
          <Breadcrumb.Item
            key={path}
            onClick={index !== lastIndex ? () => historyPush(path) : undefined}
          >
            {t(title, { ns: 'page_titles' })}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  };

  // Add additional props to children
  const renderEnhancedChildren = () => {
    const result = React.Children.map(children, (child) => {
      const props = {
        setHeaderOptions: (options: ILayoutHeaderOptions) =>
          setHeaderOptions({ ...headerOptions, ...options }),
      };

      if (React.isValidElement(child)) {
        return React.cloneElement(child, props);
      }

      return child;
    });

    return result;
  };

  const handleOnBackClick = (goBackPath?: string) => {
    // TODO: Investigate this solution
    // Used history.location.key to check if prev location is our app
    if (history.length > 1 && !!history.location.key) {
      history.goBack();
    } else if (goBackPath) {
      historyPush(goBackPath);
    }
  };

  const renderContent = (route: SubRouteModel) => {
    let result = null;

    // Initially breakpoint object is an empty object
    // So to avoid double rendering we should render the content only after breakpoint value gets inited
    if (!isEmpty(breakpoint)) {
      const enahancedChildrenComponent = renderEnhancedChildren();

      result =
        route.layoutProps?.hideContentCard || isMobileResolution() ? (
          enahancedChildrenComponent
        ) : (
          <CardWrapper size="small">{enahancedChildrenComponent}</CardWrapper>
        );
    }

    return result;
  };

  return (
    <>
      <PageHeader
        title={
          <>
            {!route.layoutProps?.hideTitle &&
              (headerOptions.title || route.title)}{' '}
            {headerOptions.appendToTitle}
          </>
        }
        onBack={
          route.layoutProps && route.layoutProps.goBackPath
            ? () => handleOnBackClick(route.layoutProps?.goBackPath)
            : undefined
        }
        extra={
          typeof headerOptions.extra == 'function'
            ? headerOptions.extra()
            : headerOptions.extra
        }
        subTitle={
          typeof headerOptions.description == 'function'
            ? headerOptions.description()
            : headerOptions.description
        }
      >
        {renderBreadcrumbs(breadcrumbs)}
        {typeof headerOptions.content == 'function'
          ? headerOptions.content()
          : headerOptions.content}
        {renderContent(route)}
      </PageHeader>
    </>
  );
};

export const CardWrapper = styled(Card)<{ noMargin?: boolean }>`
  background: ${({ theme }) => theme.backgroundColor4};
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 30px;
  margin: ${({ theme, noMargin }) => (!noMargin ? theme.marginMd : 0)} 0;

  @media screen and (max-width: ${({ theme }) => theme.tabletScreen}) {
    padding: ${({ theme }) => theme.paddingMd};
  }

  & > .ant-card-head {
    padding-left: 0;
    padding-right: 0;
    .ant-card-head-title {
      margin-bottom: ${({ theme }) => theme.marginSm};
      padding: 0;
    }
  }

  & > .ant-card-body {
    padding: 0;
  }

  .ant-table,
  .ant-table-thead > tr > th,
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right,
  .ant-table-tbody > tr.ant-table-placeholder > td,
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: ${({ theme }) => theme.backgroundColor4};
  }
`;

export default withPermissions()(PageLayout);
