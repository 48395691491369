import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { FetchResponseModel, FetchParamsModel } from '../../typings/common';
import {
  ObjectAttributeModel,
  ObjectActionModel,
  ObjectBaseModel,
  ObjectWithPermissionsModel,
} from '../../typings/ORAC/objects';

interface FetchObjectParamsModel extends FetchParamsModel {
  name: string;
}

const objectsAPI = {
  fetchObjects: (params: FetchObjectParamsModel) => {
    return APIService.get<FetchResponseModel<ObjectBaseModel>>(
      `${APIConfig.oracApi}/objects`,
      { params },
    ).then(({ data }) => data);
  },

  fetchObjectsWithPermissions: (params: FetchObjectParamsModel) => {
    return APIService.get<FetchResponseModel<ObjectWithPermissionsModel>>(
      `${APIConfig.oracApi}/permissions`,
      { params },
    ).then(({ data }) => data);
  },

  fetchAttributesByObjectId: (objectId: string, params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<ObjectAttributeModel>>(
      `${APIConfig.oracApi}/objects/${objectId}/attributes`,
      { params },
    ).then(({ data }) => data);
  },

  fetchActionsByObjectId: (objectId: string) => {
    return APIService.get<FetchResponseModel<ObjectActionModel>>(
      `${APIConfig.oracApi}/objects/${objectId}/actions`,
    ).then(({ data }) => data);
  },

  getObjectDetailsById: (objectId: string) => {
    return APIService.get<ObjectBaseModel>(
      `${APIConfig.oracApi}/objects/${objectId}`,
    ).then(({ data }) => data);
  },
};

export default objectsAPI;
