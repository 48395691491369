import { DateHelpers } from '@helpers/date';
import { RDCMonitoringItem } from 'components/Tables/TableTemplates/Compliance/RDCMonitoringTable';
import { FetchResponseModel } from 'typings/common';
import { RDCMonitoringItemFromQuery } from 'api/compliance/rdcReviewAPI';

const rdcReviewAPIAdapter = {
  fetchRDCMonitoringAlerts: (
    response: FetchResponseModel<RDCMonitoringItemFromQuery>,
  ): FetchResponseModel<RDCMonitoringItem> => {
    const formattedData: RDCMonitoringItem[] = response.data.map((e) => ({
      id: e.id,
      node: e.node,
      date: DateHelpers.formatTimestampToString(e.createdAt),
      status: e.isCompleted ? 'completed' : 'not_completed',
    }));

    return {
      data: formattedData,
      total: response.total,
    };
  },
};

export default rdcReviewAPIAdapter;
