import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { AccountRecoveryEntryStatus } from '../../../../../enums/auth/accountRecovery';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';

// components
import { SearchInput } from 'components/Fields/SearchInput';
import { Col, Row, Select, Typography } from 'antd';

interface IProps {
  onFilterChange: (key: string, value: string) => void;
  selectedStatus: AccountRecoveryEntryStatus | null;
}

const FilterBar = ({ selectedStatus, onFilterChange }: IProps) => {
  const { t } = useTranslation('orac');

  return (
    <Row gutter={[16, 16]}>
      <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('account_recovery_requests.search_by_user')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <SearchInput
              size="large"
              placeholder={t(
                'account_recovery_requests.search_by_user_placeholder',
              )}
              onSearch={(searchQuery: string) =>
                onFilterChange('search-by-name', searchQuery)
              }
            />
          </Col>
        </Row>
      </Col>

      <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('account_recovery_requests.filter_by_status')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <StyledSelect
              value={selectedStatus || undefined}
              size="large"
              placeholder={t(
                'account_recovery_requests.filter_by_status_placeholder',
              )}
              onChange={(value: any) =>
                onFilterChange('filter-by-status', value)
              }
            >
              <Select.Option value={AccountRecoveryEntryStatus.Approved}>
                {t('account_recovery_requests.approved')}
              </Select.Option>
              =
              <Select.Option value={AccountRecoveryEntryStatus.Rejected}>
                {t('account_recovery_requests.rejected')}
              </Select.Option>
              <Select.Option value={AccountRecoveryEntryStatus.Pending}>
                {t('account_recovery_requests.pending')}
              </Select.Option>
              <Select.Option value={AccountRecoveryEntryStatus.Withdrawn}>
                {t('account_recovery_requests.withdrawn')}
              </Select.Option>
            </StyledSelect>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default FilterBar;
