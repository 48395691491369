import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { message } from 'antd';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { ClientGroupForexSpreadLimitModel } from '../../../../../typings/crm/client-group';
import { UpdateSpreadMatrixForClientGroupValidationSchema } from 'validations/accounting/forex';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import LoadingWrapper from '../../../../WrapperComponents/LoadingWrapper';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  spread: ClientGroupForexSpreadLimitModel | null;
  onSubmit: (values: FormValuesModel) => Promise<void>;
  onResetToDefault: (sreadEntry: ClientGroupForexSpreadLimitModel) => void;
}

export interface FormValuesModel {
  isDefault: boolean;
  clientId: number;
  spreadId: number;
  spread: number;
}

const EditSpreadModal = ({
  spread,
  isVisible,
  closeCallback,
  onSubmit,
  onResetToDefault,
}: IProps) => {
  const { t } = useTranslation('banking');

  const initialFormValues: FormValuesModel | null = useMemo(() => {
    if (!spread) {
      return null;
    }

    return {
      isDefault: spread.isDefault,
      spreadId: spread.id,
      clientId: spread.clientId,
      spread: AccountsHelpers.convertAmountFromBigIntToInt(spread.spread) || 0,
    };
  }, [spread]);

  const handleSubmit = async (values: FormValuesModel) => {
    await onSubmit(values);
    message.success(t('forex.matrix.edit_success'));
  };

  const handleResetClick = () => {
    onResetToDefault(spread as ClientGroupForexSpreadLimitModel);
  };

  return (
    <Form<FormValuesModel>
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={initialFormValues}
      validationSchema={UpdateSpreadMatrixForClientGroupValidationSchema}
      confirmExitWithoutSaving
      renderForm={
        <ModalDialog
          title={t('forex.matrix.edit_spread')}
          width={500}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <LoadingWrapper loading={!spread || !initialFormValues}>
            {!!initialFormValues && (
              <InnerForm onResetClick={handleResetClick} />
            )}
          </LoadingWrapper>
        </ModalDialog>
      }
    />
  );
};

export default EditSpreadModal;
