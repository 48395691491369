import React, { useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../routes/routes';
import { accountsAPI } from 'api/finance/account/accountsAPI';
import { IAccountModel } from '../../../../typings/finance/account';
import { FormValuesModel } from 'components/Forms/TemplateForms/Banking/UpdateSSIForm';
import { FormikContextType } from 'formik';
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';
import { correspondentBanksAPI } from '../../../../api/banking/correspondentBanksAPI';
import { bankingBankAccountsAdapter } from 'apiAdapters/banking/bankingBankAccountsAdapter';

// components
import WireDetailsModalDialog from '../../../../components/Modals/TemplateModalDialogs/Banking/WireDetailsModalDialog';
import BankAccountsTable, {
  ActionKeys,
} from '../../../../components/Tables/TableTemplates/Accounting/BankAccountsTable';

interface IProps {
  bankId: string;
}

const CorrespondentBankAccountsTab = ({ bankId }: IProps) => {
  const { t } = useTranslation('finance');
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [accountNumber, setAccountNumber] = useState<string | null>(null);

  const { response, loading } = useFetch(
    () =>
      bankId
        ? correspondentBanksAPI.fetchCorrespondentBankAccountsByBankId(bankId, {
            page: currentPage,
            limit: DEFAULT_TABLE_LIMIT,
          })
        : null,
    [bankId, currentPage],
  );

  const { response: data, loading: wireDetailsLoading } = useFetch(
    () =>
      accountNumber
        ? accountsAPI.fetchWireDetailsByAccountNumber(accountNumber)
        : null,
    [accountNumber],
  );

  const initialValues = useMemo<FormValuesModel | null>(() => {
    if (!data) {
      return null;
    }

    return {
      id: data.id,

      isManualBeneficiary: data.isManualBeneficiary,
      beneficiary: {
        address: data.beneficiary.address,
        accountName: data.beneficiary.accountName,
        accountNumber: data.beneficiary.accountNumber,
        city: data.beneficiary.city,
        country: data.beneficiary.country,
        district: data.beneficiary.district,
        postCode: data.beneficiary.postCode,
      },
      beneficiaryBank: {
        bankCodeType: data.beneficiaryBank.bankCodeType,
        bankCode: data.beneficiaryBank.bankCode,
        bankName: data.beneficiaryBank.bankName,
        country: data.beneficiaryBank.country,
        address: data.beneficiaryBank.address,
        city: data.beneficiaryBank.city,
        district: data.beneficiaryBank.district,
        postCode: data.beneficiaryBank.postCode,
      },
      intermediaryBank: {
        bankCodeType: data.intermediaryBank.bankCodeType,
        bankCode: data.intermediaryBank.bankCode,
        bankName: data.intermediaryBank.bankName,
        country: data.intermediaryBank.country,
        address: data.intermediaryBank.address,
        city: data.intermediaryBank.city,
        district: data.intermediaryBank.district,
        postCode: data.intermediaryBank.postCode,
      },
    };
  }, [data]);

  const handleActionsClick = (key: ActionKeys, record: IAccountModel) => {
    switch (key) {
      case 'details': {
        history.push({
          pathname: RoutePaths.Finance_Account_Details,
          search: `?id=${record.accountNumber}`,
        });
        break;
      }
      case 'wire_details': {
        setAccountNumber(record.accountNumber);
        break;
      }
    }
  };

  const onSubmit = async (values: FormValuesModel) => {
    if (accountNumber) {
      const data =
        bankingBankAccountsAdapter.mapWireDetailsFromQueryToWireDetailModel(
          values,
        );
      await correspondentBanksAPI.updateSSI(data, accountNumber);
      setAccountNumber(null);
      message.success(
        t('accounts.wire_instructions_details.success_update_message'),
      );
    }
  };

  const onToggleWireDetails = async (
    accountNumber: string,
    helpers: FormikContextType<FormValuesModel>,
  ) => {
    helpers.setErrors({});
    return await accountsAPI.fetchWireDetailsToogleByAccountNumber(
      accountNumber,
    );
  };

  return (
    <>
      <BankAccountsTable
        loading={loading}
        total={response?.total || 0}
        data={response?.data || []}
        current={currentPage}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsClick}
      />
      <WireDetailsModalDialog
        onSubmit={onSubmit}
        loading={wireDetailsLoading}
        onToggleWireDetails={onToggleWireDetails}
        title={t('accounts.wire_instructions_details.title')}
        isVisible={!!accountNumber}
        initialValues={initialValues}
        closeCallback={() => setAccountNumber(null)}
        accountNumber={accountNumber}
      />
    </>
  );
};

export default CorrespondentBankAccountsTab;
