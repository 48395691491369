import React, { useCallback, useMemo, useState } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import ObjectsList from './ObjectList';
import { Card } from 'antd';

interface IProps {
  canSetForbiddenPermissions?: boolean;
  onlyForbiddenPermissions?: boolean;
}

const SelectedPermissionsList = ({
  canSetForbiddenPermissions,
  onlyForbiddenPermissions,
}: IProps) => {
  const { t } = useTranslation(['orac', 'common']);
  const [page, setPage] = useState(1);
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  const tableColumns = useMemo(() => {
    return [
      { key: 'delete', name: t('delete', { ns: 'common' }), danger: true },
    ];
  }, []);

  const handleActionsClick = useCallback(
    (key: string, objectId: string) => {
      switch (key) {
        case 'delete':
          {
            const selectedPermissionsCopy = { ...values.selectedPermissions };
            delete selectedPermissionsCopy[objectId];
            setFieldValue('selectedPermissions', selectedPermissionsCopy);
          }
          break;
      }
    },
    [values],
  );

  const data = useMemo(
    () =>
      Object.keys(values.selectedPermissions).map((objectId: string) => ({
        objectId,
        ...values.selectedPermissions[objectId],
      })) || [],
    [values.selectedPermissions],
  );

  return (
    <Card title={t('permissions.edit.selected_permissions')}>
      <ObjectsList
        current={page}
        onPaginationChange={setPage}
        data={data}
        actions={tableColumns}
        onActionsClick={handleActionsClick}
        showForbiddenPermissions={canSetForbiddenPermissions}
        onlyForbiddenPermissions={onlyForbiddenPermissions}
      />
    </Card>
  );
};

export default SelectedPermissionsList;
