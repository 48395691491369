import React from 'react';

// helpers
import useUserAccess from '../../../../hooks/useUserAccess';
import useTranslation from '../../../../hooks/useTranslation';
import { GroupBaseModel } from '../../../../typings/ORAC/groups';

// components
import GroupInfo from './GroupInfo';

import RolesTab from './RolesTab';
import UsersTab from './UsersTab';
import ContextsTab from './ContextsTab';

import { CardWrapper } from '../../../../layouts/PageLayout';
import Tabs, { TabModel } from '../../../../components/Tabs';

interface IProps {
  group: GroupBaseModel;
}

const GroupDetails = ({ group }: IProps) => {
  const { t } = useTranslation('orac');

  const [
    hasRolesAcces,
    hasRolesUpdateAccess,
    hasRolesPermissionsAccess,
    hasUsersAccess,
    hasUsersUpdateAccess,
    hasContextsAccess,
    hasContextsUpdateAccess,
  ] = useUserAccess([
    'ORAC_Groups_Details_Roles_View',
    'ORAC_Groups_Details_Roles_Update',
    'ORAC_Groups_Details_Roles_Permissions_View',
    'ORAC_Groups_Details_Users_View',
    'ORAC_Groups_Details_Users_Update',
    'ORAC_Groups_Details_Contexts_View',
    'ORAC_Groups_Details_Contexts_Update',
  ]);

  const tabs: TabModel[] = [
    {
      key: 'roles',
      title: t('groups.view.roles'),
      hidden: !hasRolesAcces,
      content: (
        <RolesTab
          groupId={group.id}
          access={{
            canEditRoles: hasRolesUpdateAccess,
            canViewPermissions: hasRolesPermissionsAccess,
          }}
        />
      ),
    },
    {
      key: 'users',
      title: t('groups.view.users'),
      hidden: !hasUsersAccess,
      content: (
        <UsersTab
          groupId={group.id}
          access={{ canEditUsers: hasUsersUpdateAccess }}
        />
      ),
    },
    {
      key: 'contexts',
      title: t('groups.view.contexts'),
      hidden: !hasContextsAccess,
      content: (
        <ContextsTab
          groupId={group.id}
          access={{ canEditContexts: hasContextsUpdateAccess }}
        />
      ),
    },
  ];

  return (
    <>
      <CardWrapper>
        <GroupInfo group={group} />
      </CardWrapper>

      <CardWrapper>
        <Tabs tabs={tabs} />
      </CardWrapper>
    </>
  );
};

export default GroupDetails;
