import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { IMessagesStatus } from '../../../../../typings/messaging/messages';
import { IDocumentAssociationInfo } from '../../../Documents/UploadDocumentsDialog';

// components
import Tabs from '../../../../../components/Tabs';
import SentTab from './SentTab';
import InboxTab from './InboxTab';
import NewMessagesBadge from '../../../../../components/Additional/Messaging/NewMessagesBadge';

interface IProps {
  clientGroupId: number;
  clientUserId?: string;
  updateTableTrigger?: any;
  messagesStatus?: IMessagesStatus;
  association: IDocumentAssociationInfo;
  onSent?: () => void;
}

const MessagingTabs = ({
  clientGroupId,
  clientUserId,
  updateTableTrigger,
  messagesStatus,
  association,
}: IProps) => {
  const { t } = useTranslation('crm');

  const tabsProps = {
    defaultActiveKey: 'inbox',
    tabs: [
      {
        key: 'inbox',
        title: (
          <>
            {t('messaging.inbox')}
            <NewMessagesBadge
              count={messagesStatus?.newMessagesCount as number}
            />
          </>
        ),
        content: (
          <InboxTab clientGroupId={clientGroupId} association={association} />
        ),
      },
      {
        key: 'sent',
        title: t('messaging.sent'),
        content: (
          <SentTab
            clientGroupId={clientGroupId}
            clientUserId={clientUserId}
            updateTableTrigger={updateTableTrigger}
            association={association}
          />
        ),
      },
    ],
  };

  return <Tabs {...tabsProps} />;
};

export default MessagingTabs;
