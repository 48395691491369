import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { TransferTemplateTypes } from '../../../../../../enums/finance/transferTemplates';
import { TransferTemplateFromQuery } from '../../../../../../api/transferTemplates/transferTemplatesAPI';

// components
import TwoColRow from '../../../../../Additional/TwoColRow';
import EllipsisTooltip from '../../../../../Tooltips/EllipsisTooltip';

interface IProps {
  template: TransferTemplateFromQuery;
}

const TemplateShortInfo = memo(({ template }: IProps) => {
  const { t } = useTranslation('crm');

  const renderTemplate = ({
    templateTypeId,
    additionalData,
  }: TransferTemplateFromQuery) => {
    switch (templateTypeId) {
      case TransferTemplateTypes.Internal:
        return (
          <>
            <TwoColRow
              labelWidth="170px"
              label={`${t('transfer_templates.template_short_info.internal.account_name')}:`}
              value={additionalData?.accountName}
            />
            <TwoColRow
              labelWidth="170px"
              label={`${t('transfer_templates.template_short_info.internal.account_number')}:`}
              value={additionalData?.accountNumber}
            />
          </>
        );

      case TransferTemplateTypes.Wire:
        return (
          <>
            <TwoColRow
              labelWidth="170px"
              label={`${t('transfer_templates.template_short_info.wire.account_name')}:`}
              value={additionalData?.accountName}
            />
            <TwoColRow
              labelWidth="170px"
              label={`${t('transfer_templates.template_short_info.wire.account_number')}:`}
              value={additionalData?.accountNumber}
            />
            {additionalData?.beneficiaryBankData && (
              <TwoColRow
                labelWidth="170px"
                label={`${t('transfer_templates.template_short_info.wire.beneficiary_bank')}:`}
                value={
                  <EllipsisTooltip
                    title={`${additionalData.beneficiaryBankData.bankCode} ${additionalData.beneficiaryBankData.bankName}`}
                    maxTextContainerWidth="210px"
                  >
                    {`${additionalData.beneficiaryBankData.bankCode} ${additionalData.beneficiaryBankData.bankName}`}
                  </EllipsisTooltip>
                }
              />
            )}
            {additionalData?.intermediaryBankData && (
              <TwoColRow
                labelWidth="170px"
                label={`${t('transfer_templates.template_short_info.wire.intermediary_bank')}:`}
                value={
                  <EllipsisTooltip
                    title={`${additionalData.intermediaryBankData.bankCode} ${additionalData.intermediaryBankData.bankName}`}
                    maxTextContainerWidth="210px"
                  >
                    {`${additionalData.intermediaryBankData.bankCode} ${additionalData.intermediaryBankData.bankName}`}
                  </EllipsisTooltip>
                }
              />
            )}
          </>
        );

      case TransferTemplateTypes.Blockchain:
        return (
          <>
            <TwoColRow
              labelWidth="170px"
              label={`${t('transfer_templates.template_short_info.blockchain.network')}:`}
              value={additionalData.chain}
            />
            <TwoColRow
              labelWidth="170px"
              label={`${t('transfer_templates.template_short_info.blockchain.address')}:`}
              value={additionalData.address}
            />
            <TwoColRow
              labelWidth="170px"
              label={`${t('transfer_templates.template_short_info.blockchain.address_tag')}:`}
              value={additionalData.tag}
            />
          </>
        );
    }
  };

  return <>{renderTemplate(template)}</>;
});

export default TemplateShortInfo;
