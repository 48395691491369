import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import {
  FeeScheduleEntryModel,
  FeeScheduleModel,
} from '../../../../../typings/finance/feeSchedule';

// components
import FeeScheduleEntriesTable from '../FeeScheduleEntriesTable';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

interface IProps extends RequiredPropsForTableModel<FeeScheduleModel> {
  canEdit?: boolean;
  clientRelated?: boolean;
  onActionsClick?: (
    key: string,
    record: FeeScheduleModel | FeeScheduleEntryModel,
  ) => void;
}

const FeeScheduleTable = memo(
  ({ onActionsClick, canEdit, clientRelated, ...rest }: IProps) => {
    const { t } = useTranslation('crm');

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          title: t('client_group.fee_schedule.name'),
          key: 'name',
          render: (record: FeeScheduleModel) => record.feeScheduleTypeName,
        },
      ];

      return result;
    }, []);

    const expandableRow = useMemo(
      () => ({
        rowExpandable: ({ feeScheduleEntries }: FeeScheduleModel) =>
          !!feeScheduleEntries.length,
        expandedRowRender: ({ feeScheduleEntries }: FeeScheduleModel) => (
          <FeeScheduleEntriesTable
            clientRelated={clientRelated}
            canEdit={canEdit}
            size="small"
            data={feeScheduleEntries}
            total={feeScheduleEntries.length}
            current={1}
            onActionsClick={onActionsClick}
          />
        ),
      }),
      [canEdit, clientRelated, onActionsClick],
    );

    return <Table {...rest} columns={columns} expandable={expandableRow} />;
  },
);

export default FeeScheduleTable;
