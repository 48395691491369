import React, { useState, useEffect } from 'react';

// helpers
import useUserAccess from '../../../hooks/useUserAccess';
import useTranslation from '../../../hooks/useTranslation';
import { PageLayoutHelperPropsModel } from '../../../layouts/PageLayout';
import { GENESIS_RELATIONSHIP_TEMPLATES_DOCUMENTATION_LINK } from '../../../constants/externalLinks';

// components
import TooltipToDocumentation from '../../../components/Tooltips/TooltipToDocumentation';
import RelationshipTemplatesTable from '../../../modules/CRM/RelationhipTemplates/RelationshipTemplatesTable';
import AddEditRelationshipTemplate from '../../../modules/CRM/RelationhipTemplates/AddEditRelationshipTemplate';
import { Button } from 'antd';

type IProps = PageLayoutHelperPropsModel;

const RelationshipTemplates = ({ setHeaderOptions }: IProps) => {
  const { t } = useTranslation('crm');
  const [hasCreateAccess] = useUserAccess([
    'CRM_Configuration_RelationshipTemplates_Create',
  ]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [updateTableTrigger, updateTable] = useState({});

  useEffect(() => {
    if (hasCreateAccess) {
      setHeaderOptions({
        appendToTitle: (
          <TooltipToDocumentation
            link={GENESIS_RELATIONSHIP_TEMPLATES_DOCUMENTATION_LINK}
          />
        ),
        extra: () => (
          <Button type="primary" onClick={() => setModalVisible(true)}>
            {t('relationship_templates.create_title')}
          </Button>
        ),
      });
    }
  }, [hasCreateAccess]);

  const handleModalClose = (shouldUpdate?: boolean) => {
    if (shouldUpdate) {
      updateTable({});
    }
    setModalVisible(false);
  };

  return (
    <>
      <RelationshipTemplatesTable updateTableTrigger={updateTableTrigger} />
      {hasCreateAccess && (
        <AddEditRelationshipTemplate
          isVisible={isModalVisible}
          closeCallback={handleModalClose}
        />
      )}
    </>
  );
};

export default RelationshipTemplates;
