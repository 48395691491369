import React, { useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import rdcReviewAPI from 'api/compliance/rdcReviewAPI';
import { IEntity } from 'typings/crm/entity';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { RDCReviewAlertBaseModel } from 'typings/compliance/reviewProcess';

// components
import ReviewRDCAlertModal from '../ReviewRDCAlertModal';
import { default as TemplateRDCAlertsTable } from 'components/Tables/TableTemplates/Compliance/RDCAlertsTable';

interface IProps {
  entity: IEntity;
}

const RDCAlertsTable = ({ entity }: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAlertId, setSelectedAlertId] = useState<string | null>(null);
  const [updateAlertsTrigger, updateAlerts] = useState({});

  const { response, loading } = useFetch(
    () =>
      rdcReviewAPI.fetchAlertsByAssociationId({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        associationId: entity._id,
      }),
    [currentPage, entity._id, updateAlertsTrigger],
  );

  const handleDetailsClick = (record: RDCReviewAlertBaseModel) => {
    setSelectedAlertId(record._id);
  };

  const handleModalClose = (wasUpdated?: boolean) => {
    setSelectedAlertId(null);

    if (wasUpdated) {
      updateAlerts({});
    }
  };

  return (
    <>
      <TemplateRDCAlertsTable
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={currentPage}
        onPaginationChange={setCurrentPage}
        onDetailsClick={handleDetailsClick}
      />
      <ReviewRDCAlertModal
        alertId={selectedAlertId}
        closeCallback={handleModalClose}
      />
    </>
  );
};

export default RDCAlertsTable;
