import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import rolesAPI from '../../../../../api/orac/rolesAPI';
import { DEFAULT_TABLE_LIMIT } from '../../../../../constants/global';

// components
import UsersTable from '../../../../../components/Tables/TableTemplates/ORAC/UsersTable';

interface IProps {
  roleId: string;
}

const UsersTab = ({ roleId }: IProps) => {
  const [page, setPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      rolesAPI.fetchUsersByRoleId(roleId, { page, limit: DEFAULT_TABLE_LIMIT }),
    [page],
  );

  return (
    <UsersTable
      showAssignWayColumn
      data={response?.data || []}
      total={response?.total || 0}
      current={page}
      loading={loading}
      onPaginationChange={setPage}
    />
  );
};

export default UsersTab;
