import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { StateModel } from '../redux/reducers';
import { StateModel as AuthStateModel } from '../redux/reducers/auth';
import { StateModel as AppStateModel } from '../redux/reducers/app';
import { signOut } from '../redux/actions/auth';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { RouteLayoutPropsModel } from '../typings/routes';
import { RoutePaths } from '../routes/routes';

interface IComponentProps extends RouteComponentProps, RouteLayoutPropsModel {}

// This custom hoc is responsible for rendering private and non private pages
// If the selected page has isPrivate = true, than we should check if the user is authorized in the system
// Otherwise if the page has isPrivate = false, than we should render this page only for non authorized users
const withPrivate = () => (Component: any) => {
  const WithPrivate = (componentProps: IComponentProps) => {
    const { isPrivate } = componentProps.route;

    const dispatch = useDispatch();
    const { isAuthorized, profileData } = useSelector<
      StateModel,
      AuthStateModel
    >((store) => store.auth);
    const { lastActivityTimestamp } = useSelector<StateModel, AppStateModel>(
      (store) => store.app,
    );
    const redirectToPath = isAuthorized
      ? RoutePaths.UserRoutes_Root
      : RoutePaths.Auth_SignIn;

    // Instantly sign out user if session expired
    useEffect(() => {
      let timeoutId: NodeJS.Timeout;

      if (profileData?.sessionDuration) {
        timeoutId = setTimeout(() => {
          dispatch(signOut());
        }, profileData?.sessionDuration * 1000);
      }

      return () => {
        clearTimeout(timeoutId);
      };
    }, [lastActivityTimestamp, profileData?.sessionDuration]);

    return isAuthorized && isPrivate ? (
      <Component {...componentProps} />
    ) : !isAuthorized && !isPrivate ? (
      <Component {...componentProps} />
    ) : (
      <Redirect to={redirectToPath} />
    );
  };

  return WithPrivate;
};

export default withPrivate;
