import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../hooks/useTranslation';
import ErrorHandlerService from './service';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../routes/routes';
import { changeView } from '../../redux/actions/view';
import { useDispatch } from 'react-redux';

// components
import { Card, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons/lib';

interface IProps {
  error: any;
  removeError: () => void;
}

const ErrorPage = ({ error, removeError }: IProps) => {
  const { t } = useTranslation('server_errors');

  const history = useHistory();
  const dispatch = useDispatch();

  const redirectToHome = () => {
    removeError();
    dispatch(changeView(() => history.push(RoutePaths.UserRoutes_Root)));
  };

  return (
    <StyledWrapper>
      <StyledCard>
        <StyledTitle level={4}>
          <StyledWarningIcon />
          {t('error.unexpected_error')}
        </StyledTitle>
        <StyledText>
          {ErrorHandlerService.getErrorMessage(error)}
          <br />
          {t('error.message')}
          <Typography.Link onClick={redirectToHome}>
            {t('error.home_page')}
          </Typography.Link>
        </StyledText>
      </StyledCard>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: ${({ theme }) => theme.backgroundColor};
`;

const StyledCard = styled(Card)`
  width: 100%;
  max-width: 500px;
  margin: 0 ${({ theme }) => theme.marginMd};
`;

const StyledTitle = styled(Typography.Title)`
  text-align: center;
`;

const StyledText = styled(Typography.Paragraph)`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizeSm};
`;

const StyledWarningIcon = styled(ExclamationCircleOutlined)`
  margin-right: ${({ theme }) => theme.marginXs};
  color: ${({ theme }) => theme.errorColor};
`;

export default ErrorPage;
