import React from 'react';

// helpers utils
import { FetchResponseModel } from '../../../../typings/common';
import { ICurrencyRate } from '../../../../typings/finance/currencyRates';
import {
  currencyRatesAPI,
  IGetRatesForCurrencyParams,
} from '../../../../api/finance/currency/currencyRatesAPI';

// components
import { ExchangeCurrencyRateParams } from '../CurrencyRatesTable';
import { default as CurrencyRateModalTemplate } from '../../../../components/Modals/TemplateModalDialogs/Finance/CurrencyRateModal';

interface IProps {
  selectedCurrency: ExchangeCurrencyRateParams | null;
  isVisible: boolean;
  closeCallback: () => void;
}

export type FetchParams = IGetRatesForCurrencyParams;
export type FetchResponseType = FetchResponseModel<ICurrencyRate>;

const CurrencyRateModal = ({
  selectedCurrency,
  isVisible,
  closeCallback,
}: IProps) => {
  return (
    <CurrencyRateModalTemplate
      isVisible={isVisible}
      closeCallback={closeCallback}
      selectedCurrency={selectedCurrency}
      fetchCurrencyRatesCallback={currencyRatesAPI.getRatesForCurrency}
    />
  );
};

export default CurrencyRateModal;
