import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import { IUserDevice, ISession } from '../../typings/profile/security';
import {
  AssertionForLoginModel,
  ChallengeForRegistrationModel,
  GetChallengeForLoginResponseModel,
  SecurityKeyModel,
} from '../../typings/profile/fido';

interface ISessionsParams extends FetchParamsModel {
  inactive?: boolean;
}

export interface NewSecurityKeyModel {
  id: string;
  rawId: string;
  type: string;
  name: string;
  response: {
    clientDataJSON: string;
    attestationObject: string;
  };
}

interface UpdateKeyDataModel {
  name: string;
}

const securityAPI = {
  changeUsername: (newUsername: string) => {
    return new Promise((resolve) => setTimeout(() => resolve(true), 500));
  },

  setSessionTimeout: (seconds: number) => {
    return APIService.patch(`${APIConfig.authApi}/sessions/duration`, {
      seconds,
    }).then(({ data }) => data);
  },

  fetchSessions: (params: ISessionsParams) => {
    return APIService.get<FetchResponseModel<ISession>>(
      `${APIConfig.authApi}/sessions`,
      { params },
    ).then(({ data }) => data);
  },

  deleteSession: (sessionId: string) => {
    return APIService.delete(`${APIConfig.authApi}/sessions/${sessionId}`).then(
      ({ data }) => data,
    );
  },

  fetchDevices: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<IUserDevice>>(
      `${APIConfig.authApi}/sessions/devices`,
      { params },
    ).then(({ data }) => data);
  },

  getChallengeForRegistrationNewKey: () => {
    return APIService.post<ChallengeForRegistrationModel>(
      `${APIConfig.authApi}/2fa/fido/register-challenge`,
    ).then(({ data }) => data);
  },

  registerSecurityKey: (newKey: NewSecurityKeyModel) => {
    return APIService.post(
      `${APIConfig.authApi}/2fa/fido/register`,
      newKey,
    ).then(({ data }) => data);
  },

  getChallengeForDisablingFIDO: () => {
    return APIService.post<GetChallengeForLoginResponseModel>(
      `${APIConfig.authPublicApi}/fido/login-challenge`,
    ).then((response) => response.data);
  },

  disableSecurityKey2FA: (assertion: AssertionForLoginModel) => {
    return APIService.patch(
      `${APIConfig.authApi}/users/2FA/fido/disable`,
      assertion,
    ).then((response) => response.data);
  },

  fetchSecurityKeys: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<SecurityKeyModel>>(
      `${APIConfig.authApi}/users/2FA/fido/keys`,
      { params },
    ).then(({ data }) => data);
  },

  getChallengeForRemovingKey: (credId: string) => {
    return APIService.post<GetChallengeForLoginResponseModel>(
      `${APIConfig.authPublicApi}/fido/login-challenge`,
      { credId },
    ).then((response) => response.data);
  },

  removeFidoKey: (keyId: string, assertion: AssertionForLoginModel) => {
    return APIService.post(
      `${APIConfig.authApi}/users/2FA/fido/${keyId}/remove`,
      assertion,
    ).then((response) => response.data);
  },

  updateSecurityKeyById: (keyId: string, updatedData: UpdateKeyDataModel) => {
    return APIService.patch(
      `${APIConfig.authApi}/users/2FA/fido/${keyId}`,
      updatedData,
    ).then((response) => response.data);
  },
};

export { securityAPI };
