import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../../hooks/useFetch';
import usersAPI from '../../../../../../api/orac/usersAPI';
import useTranslation from '../../../../../../hooks/useTranslation';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../constants/global';

// components
import DevicesTable from '../../../../../../components/Tables/TableTemplates/Profile/DevicesTable';
import { CardWrapper } from '../../../../../../layouts/PageLayout';
import { Modal, Typography } from 'antd';

interface IProps {
  userId: string;
  updateTableTrigger: any;
  onSessionRemove: () => void;
}

const Devices = ({ userId, updateTableTrigger, onSessionRemove }: IProps) => {
  const { t } = useTranslation(['profile', 'common']);

  const [current, setCurrentPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      usersAPI.fetchDevicesByUserId(
        { page: current, limit: DEFAULT_TABLE_LIMIT },
        userId,
      ),
    [current, updateTableTrigger],
  );

  const handleSignOut = async (sessionId: string) => {
    Modal.confirm({
      content: t('sign_in_and_security.devices.sign_out_confirmation'),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        await usersAPI.deleteSessionByUserId(sessionId, userId);
        onSessionRemove();
      },
    });
  };

  return (
    <CardWrapper>
      <Typography.Title level={4}>
        {t('sign_in_and_security.devices.title')}
      </Typography.Title>
      <DevicesTable
        current={current}
        total={response?.total || 0}
        data={response?.data || []}
        onPaginationChange={setCurrentPage}
        loading={loading}
        onSignOut={handleSignOut}
      />
    </CardWrapper>
  );
};

export default Devices;
