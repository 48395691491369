import React from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { message } from 'antd';
import { FormValuesModel } from 'components/Forms/TemplateForms/CRM/ComplianceForm';
import {
  IClientGroupResponse,
  clientGroupsAPI,
} from '../../../../../../../api/crm/clientGroup/clientGroupsAPI';
import {
  ClientReviewStatus,
  ComplianceRiskLevel,
  NoteType,
} from '../../../../../../../enums/crm/crm';

// components
import ComplianceForm from './ComplianceForm';
import ComplianceNotes from './ComplianceNotes';

interface IProps {
  clientGroupData: IClientGroupResponse;
  updateClientGroup: () => void;
}

const ComplianceTab = ({ clientGroupData, updateClientGroup }: IProps) => {
  const { t } = useTranslation('common');
  const [hasNotesViewAccess] = useUserAccess([
    'CRM_ClientGroup_Details_Compliance_Notes_View',
  ]);

  // Form enabled before compliance on manager review and during compliance review
  const isFormEnabled =
    clientGroupData.clientGroup.reviewStatus ===
      ClientReviewStatus.ComplianceReview ||
    clientGroupData.clientGroup.reviewStatus ===
      ClientReviewStatus.ManagerReview;

  const handleSubmit = async (values: FormValuesModel) => {
    await clientGroupsAPI.postComplianceInformation({
      clientGroupId: clientGroupData.clientGroup._id,
      riskRatingMatrixDocumentId: values.riskRatingMatrixDocument[0].id || '',
      riskLevel: values.riskLevel || ComplianceRiskLevel.Medium,
      dueDiligence: values.dueDiligence,
      dueDiligenceDetails:
        values.dueDiligence === true ? values.dueDiligenceDetails : undefined,
    });
    message.success(t('successfully_saved'));
    updateClientGroup();
  };

  return (
    <>
      <ComplianceForm
        isViewOnly={!isFormEnabled}
        onSubmit={handleSubmit}
        complianceInformation={
          clientGroupData.clientGroup.complianceInformation
        }
      />

      {hasNotesViewAccess && (
        <ComplianceNotes
          associationType={NoteType.ClientGroup}
          associationId={clientGroupData.clientGroup._id}
        />
      )}
    </>
  );
};

export default ComplianceTab;
