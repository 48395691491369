import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';

import { darkTheme } from '../../../../resources/theme/styled';

// components
import { Card, Col, Row, Tooltip, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

interface IProps {
  title: string;
  icon: JSX.Element;
  navigateToPath: string;
  postRequestsCount: number;
  nonPostRequestsCount: number;
  hidePendingApprovals?: boolean;
}

// TODO:
// 1) Update helper texts for post/non post request counts

const NavigationOption = ({
  title,
  icon,
  hidePendingApprovals,
  navigateToPath,
  postRequestsCount,
  nonPostRequestsCount,
}: IProps) => {
  const { t } = useTranslation('compliance');
  const history = useHistory();

  return (
    <StyledCard onClick={() => history.push(navigateToPath)}>
      <Row gutter={[16, 0]}>
        <StyledIconCol flex="80px">
          <StyledIconWrapper>{icon}</StyledIconWrapper>
        </StyledIconCol>

        <Col flex="auto">
          <StyledInfoWrapper>
            <StyledParagraph>{title}</StyledParagraph>
            {!hidePendingApprovals && (
              <ReviewCountWrapper>
                <StyledText>{t('root_page.pending_reviews')}</StyledText>
                <div>
                  <Tooltip placement="bottom" title="Post requests count">
                    <StyledCountText color={darkTheme.warningColor}>
                      {postRequestsCount}
                    </StyledCountText>
                  </Tooltip>

                  <Tooltip placement="bottom" title="Non post requests count">
                    <StyledCountText color={darkTheme.errorColor}>
                      {nonPostRequestsCount}
                    </StyledCountText>
                  </Tooltip>
                </div>
              </ReviewCountWrapper>
            )}
          </StyledInfoWrapper>
        </Col>
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  cursor: pointer;
  transition: ${({ theme }) => theme.defaultTransition};
  border-radius: ${({ theme }) => theme.borderRadiusRegular};

  border-width: 2px;

  .ant-card-body {
    min-height: 112px;
    padding: 20px;
  }

  &:hover {
    border-color: ${({ theme }) => theme.primaryGreen};
  }
`;

const StyledIconCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconWrapper = styled.div`
  width: 60px;
  height: 60px;
  fill: ${({ theme }) => theme.whiteColor};
`;

const StyledInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledParagraph = styled(Typography.Paragraph)`
  font-size: ${({ theme }) => theme.fontSizeMd};
  color: ${({ theme }) => theme.whiteColor};
  margin-bottom: 0px !important;
`;

const StyledText = styled(Typography.Text)`
  color: ${({ theme }) => theme.textLightColor3};
`;

const StyledCountText = styled(Typography.Text)<{ color: string }>`
  font-size: 25px;
  margin-left: 25px;
  color: ${({ color }) => color};
`;

const ReviewCountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export default NavigationOption;
