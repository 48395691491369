import React, { memo, useEffect, useState } from 'react';

// helpers
import moment, { Moment } from 'moment';
import { PageLayoutHelperPropsModel } from 'layouts/PageLayout';

// components
import GeneralLedgerReport from 'modules/Reporting/GeneralLedgerReport';
import ExportGeneralLedgerToExcelButton from 'components/Additional/ExportGeneralLedgerToExcelButton';

type IProps = PageLayoutHelperPropsModel;

const GeneralLedger = memo(({ setHeaderOptions }: IProps) => {
  const [dateRange, setDateRange] = useState<[Moment, Moment] | null>([
    moment().add(-1, 'M').startOf('day'),
    moment().endOf('day'),
  ]);

  useEffect(() => {
    setHeaderOptions({
      extra: (
        <ExportGeneralLedgerToExcelButton selectedFilterDate={dateRange} />
      ),
    });
  }, [dateRange]);

  return (
    <GeneralLedgerReport
      selectedFilterDate={dateRange}
      setSelectedDate={setDateRange}
    />
  );
});

export default GeneralLedger;
