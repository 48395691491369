import React, { useMemo } from 'react';

// Helpers & Utils
import moment from 'moment';
import useFetch from '../../../../../hooks/useFetch';
import useUserAccess from '../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../hooks/useTranslation';
import { journalsAPI } from '../../../../../api/finance/journal/journalsAPI';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { journalsAdapter } from '../../../../../apiAdapters/finance/journal/journalsAdapter';
import {
  default as TemplateViewJournalModal,
  JournalFormValues,
} from '../../../../../components/Modals/TemplateModalDialogs/Finance/AddViewJournalModal';

// components
import { message } from 'antd';

interface IProps {
  journalId?: number;
  isVisible: boolean;
  closeCallback: (shouldUpdate?: boolean) => void;
}

const ViewEditJournalMod = ({
  isVisible,
  closeCallback,
  journalId,
}: IProps) => {
  const { t } = useTranslation('finance');
  const { response } = useFetch(
    () => (journalId ? journalsAPI.fetchJournalById(journalId) : null),
    [journalId],
  );
  const [hasUpdateAccess, hasReverseAccess] = useUserAccess([
    'Finance_Journals_Update',
    'Finance_Journals_Reverse',
  ]);

  const initialFormValues = useMemo<JournalFormValues | null>(() => {
    if (!isVisible || !response) {
      return null;
    }

    return {
      isReversalFrom: response.isReversal
        ? response.reversalJournalId
        : undefined,
      journalId,
      isReversalJournal: false,
      journalValueDate: moment.unix(response.valueDate),
      journal: {
        entries: AccountsHelpers.formatJournalEntryToFormJournalEntry(
          response.journalEntries,
        ),
      },
      isEditMode: false,
    };
  }, [response, journalId, isVisible]);

  const handleSubmit = async (values: JournalFormValues) => {
    if (values.isEditMode) {
      await journalsAPI.updateJournalEntries(
        journalsAdapter.generateUpdateJournalRequestBody(values),
      );
      message.success(t('journals.update.success_update'));
    } else {
      // This logic is needed for reversal
      await journalsAPI.createJournal(
        journalsAdapter.generateRequestBody(values),
      );
      message.success(t('journals.new.success_create'));
    }
  };

  return (
    <TemplateViewJournalModal
      title={t('journals.journal_details')}
      canEdit={hasUpdateAccess}
      onSubmit={handleSubmit}
      isVisible={isVisible}
      canReverse={hasReverseAccess}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
    />
  );
};

export default ViewEditJournalMod;
