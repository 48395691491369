import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { AdditionalFieldType, OnboardType } from '../../../../enums/crm/crm';

// components
import DocumentLink from '../../../Typography/DocumentLink';
import { Typography } from 'antd';

interface AdditionalFieldFormItemModel {
  _id: string;
  itemId: string;
  itemType: OnboardType;
  clientGroupId: string;

  answer: {
    isProvided: boolean;
    valueJSON: string;
    reason: string;
  } | null;

  field: {
    type: AdditionalFieldType;
    name: string;
    relatedTo: 'any' | 'contact' | 'organization';
    isTemplateField: boolean;
    placeholder: string;
    description: string;
    options: {
      selectOptions: { valueJSON: string; type: string }[];
      selectOptionsValues: { valueJSON: string; type: string }[];
      onboardingRequired: boolean;
      documentRequiresCertification: boolean;
      isForRegulatedOnly: boolean;
    };
  };

  sources: {
    type: 'relationship' | 'client' | 'admin';
    id: string;
    templateId: string;
  }[];
}

interface IProps {
  data: AdditionalFieldFormItemModel;
}

const AdditionalFieldAnswer = ({ data }: IProps) => {
  const { t } = useTranslation(['crm', 'common']);

  const renderDocument = (document: any) => {
    return (
      <div>
        <DocumentLink
          key={document.id}
          text={document.files[0].name}
          documentId={document.id}
          fileId={document.files[0].id}
          fileName={document.files[0].name}
        />
      </div>
    );
  };

  const renderValue = (field: AdditionalFieldFormItemModel) => {
    if (!field.answer) {
      return (
        <StyledNotProvided>
          {t('not_provided', { ns: 'common' })}
        </StyledNotProvided>
      );
    }

    if (!field.answer.isProvided) {
      return (
        <>
          <StyledNotProvided>
            {t('not_provided', { ns: 'common' })}:
          </StyledNotProvided>
          <br />
          <Typography.Text>{field.answer.reason}</Typography.Text>
        </>
      );
    }

    const formattedValue = field.answer?.valueJSON
      ? JSON.parse(field.answer.valueJSON)
      : null;

    if (
      field.field.type === AdditionalFieldType.Document ||
      field.field.type === AdditionalFieldType.Form
    ) {
      return formattedValue
        ? Array.isArray(formattedValue)
          ? formattedValue.map(renderDocument)
          : renderDocument(formattedValue)
        : '';
    }

    if (field.field.type === AdditionalFieldType.Boolean) {
      return formattedValue
        ? t('client_group.approval.yes')
        : t('client_group.approval.no');
    }

    return <Typography.Text>{formattedValue}</Typography.Text>;
  };

  return <>{renderValue(data)}</>;
};

const StyledNotProvided = styled(Typography.Text)`
  color: ${({ theme }) => theme.textLightColor3};
`;

export default AdditionalFieldAnswer;
