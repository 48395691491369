import React, { useEffect } from 'react';

// helpers
import styled from 'styled-components';

// components
import useTranslation from '../../hooks/useTranslation';
import { StateModel } from '../../redux/reducers';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from '../../redux/reducers/auth';

// constants
import { RoutePaths } from '../../routes/routes';
import { TwoFactorVerificationTypes } from '../../enums/auth/auth';

// components
import EnableFido from '../../modules/Auth/TwoFactorAuthentication/EnableFido';
import FidoAuthentication from '../../modules/Auth/TwoFactorAuthentication/FidoAuthencation';
import { Card, message } from 'antd';

const TwoFactorAuthentication = () => {
  const history = useHistory();
  const { t } = useTranslation('auth');
  const { twoFactorVerificationData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  useEffect(() => {
    if (!twoFactorVerificationData) {
      message.info(t('two_factor_authentication.warning_no_auth_data'));
      history.push(RoutePaths.Auth_SignIn);
    }
  }, [twoFactorVerificationData]);

  const renderContent = () => {
    if (!twoFactorVerificationData) {
      return;
    }

    const isFidoEnabled = twoFactorVerificationData?.options.find(
      (e) => e.code === TwoFactorVerificationTypes.Fido && e.enabled,
    );

    if (isFidoEnabled) {
      return <FidoAuthentication />;
    } else {
      return <EnableFido />;
    }
  };

  return (
    <StyledCard>
      <ContentWrapper>{renderContent()}</ContentWrapper>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-top: -100px;
  width: 500px;
  height: auto;
  text-align: center;

  display: flex;
  justify-content: center;

  @media screen and (max-width: 576px) {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    padding: 30px;
    padding-top: 100px !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-card-body {
      width: 100%;
      padding: 10px;
    }
  }
`;

const ContentWrapper = styled.div`
  width: 400px;
  height: 440px;
`;

export default TwoFactorAuthentication;
