import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { IContactModel } from 'typings/crm/contact';
import { ContactStatuses } from 'enums/crm/crm';
import { changeRequestsAPI } from 'api/crm/changeRequests/changeRequestsAPI';

// components
import Text from '@core_components/Text';
import KYCForm from './KYCForm';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ChangeRequestDetails from './ChangeRequestDetails';
import { Divider } from 'antd';

interface IProps {
  contact: IContactModel;
  updateContactData: () => void;
}

const Approval = ({ contact, updateContactData }: IProps) => {
  const { t } = useTranslation('crm');
  const [updateChangeRequestTrigger, updateChangeRequest] = useState<any>();

  const { response, loading } = useFetch(
    () =>
      contact.status == ContactStatuses.InReview
        ? changeRequestsAPI.fetchContactChangeRequest(contact._id)
        : null,
    [contact, updateChangeRequestTrigger],
  );

  return (
    <LoadingWrapper loading={loading}>
      {response && response.clientChangeRequest ? (
        <>
          <ChangeRequestDetails changeRequest={response.clientChangeRequest} />
          <StyledDivider />
          <KYCForm
            updateContactCallback={() => updateContactData()}
            contactWithChangeRequest={response}
            updateChangeRequestCallback={() => updateChangeRequest({})}
          />
        </>
      ) : (
        <Text>{t('contacts.kyc_form.nothing_to_review')}</Text>
      )}
    </LoadingWrapper>
  );
};

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.marginXs} 0;
`;

export default Approval;
