import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { IAccountModel } from '../../../../../typings/finance/account';
import { AccountsHelpers } from '@helpers/finance/accounts';

// components
import BankAccountStatus from 'components/Additional/Statuses/BankAccountStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<IAccountModel> {
  onActionsClick: (key: string, value: string) => void;
}

const AccountsTable = memo(({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation('finance');

  const renderBalance = (
    key: 'balance' | 'balanceUSD' | 'homeCurrencyRate',
    record: IAccountModel,
  ) => {
    // Don't show balance for Income or Expense accounts
    if (record.financialTypeId === 40 || record.financialTypeId === 50) {
      if (key === 'balance') {
        return (
          <Typography.Link
            onClick={() => onActionsClick('details', record.accountNumber)}
          >
            {t('accounts.table.run_report')}
          </Typography.Link>
        );
      } else {
        return '';
      }
    }

    if (!record[key] && record[key] !== 0) return '';

    return (
      <Typography.Text>
        {AccountsHelpers.convertAmountBigIntToLocaleString(record[key])}
      </Typography.Text>
    );
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('accounts.table.account_number'),
        key: 'account_number',
        width: 270,
        render: (record: IAccountModel) => (
          <Typography.Link
            onClick={() => onActionsClick('details', record.accountNumber)}
          >
            {record.accountNumber}
          </Typography.Link>
        ),
      },
      {
        title: t('accounts.table.account_name'),
        key: 'account_name',
        width: 270,
        render: (record: IAccountModel) => record.accountName,
      },
      {
        title: t('accounts.table.currency'),
        key: 'currency',
        width: 100,
        render: (record: IAccountModel) => record.currencyCode,
      },
      {
        title: t('accounts.table.account_category_code'),
        key: 'accountCategoryId',
        width: 220,
        render: (record: IAccountModel) => record.accountCategoryId,
      },
      {
        title: t('accounts.table.status'),
        key: 'status',
        width: 100,
        render: (record: IAccountModel) => (
          <BankAccountStatus status={record.status} />
        ),
      },
      {
        title: t('accounts.table.reconciled'),
        key: 'isReconciled',
        width: 80,
        render: (record: IAccountModel) => (record.isReconciled ? 'R' : ''),
      },
      {
        title: t('accounts.new.account_category'),
        key: 'account_category',
        width: 180,
        render: (record: IAccountModel) => record.accountCategoryName,
      },
      {
        title: t('accounts.table.financial_type'),
        key: 'financial_type',
        width: 180,
        render: (record: IAccountModel) => record.financialTypeName,
      },
      {
        title: t('accounts.table.balance'),
        key: 'balance',
        width: 150,
        render: (record: IAccountModel) => renderBalance('balance', record),
      },
      {
        title: t('accounts.table.currency_rate'),
        key: 'currency_rate',
        width: 150,
        render: (record: IAccountModel) =>
          renderBalance('homeCurrencyRate', record),
      },
      {
        title: `${t('accounts.table.balance_home')} (USD)`,
        key: 'balance_home',
        width: 150,
        render: (record: IAccountModel) => renderBalance('balanceUSD', record),
      },
    ];

    return result;
  }, [t]);

  return <Table columns={columns} {...rest} />;
});

export default AccountsTable;
