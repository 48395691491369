import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';

interface Sizes {
  small: string;
  middle: string;
  large: string;
}

const sizes: Sizes = {
  small: 'fontSizeXs',
  middle: 'fontSizeSm',
  large: 'fontSizeMd',
};

const RemoveButton = styled(DeleteOutlined)<{ buttonSize?: keyof Sizes }>`
  color: ${({ theme }) => theme.errorColor};
  padding: ${({ theme }) => theme.paddingXs};
  font-size: ${({ theme, buttonSize }) => theme[sizes[buttonSize || 'middle']]};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.errorColorHover};
  }
`;

export default RemoveButton;
