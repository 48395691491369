import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import Checkbox from '@core_components/Checkbox';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import AdditionalFieldNew from 'components/Forms/FormComponents/AdditionalFieldNew';

const InnerForm = () => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      {!values.additionalField.options.onboardingRequired && (
        <FormField
          name="additionalField.isNotProvidedReason"
          component={Checkbox}
          shouldShowErrorMessage={false}
          additionalProps={{
            children: t(
              'client_group.approval.update_answer_of_additional_field_modal.not_applicable_or_available',
            ),
          }}
        />
      )}
      {values.additionalField.isNotProvidedReason ? (
        <FormField
          name="additionalField.notProvidedReason"
          component={FormInput}
        />
      ) : (
        <FormField
          label={t(
            'client_group.approval.update_answer_of_additional_field_modal.answer',
          )}
          name="additionalField.value"
          component={AdditionalFieldNew}
          additionalProps={{
            field: {
              type: values.additionalField.type,
              tags: [values.additionalField.name],
              options: values.additionalField.options.selectOptions,
              canProvideReason:
                !values.additionalField.options.onboardingRequired,
              formDocument: values.additionalField.options.formDocument,
              documentAssociation: values.documentAssociation,
            },
          }}
        />
      )}
    </>
  );
};

export default InnerForm;
