import React, { useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import { Moment } from 'moment';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { expiringDocumentsAPI } from 'api/crm/expiringDocuments/expiringDocumentsAPI';

// components
import FilterBar from './FilterBar';
import { Divider } from 'antd';
import { default as ExpiringDocumentsTemplateTable } from 'components/Tables/TableTemplates/Compliance/ExpiringDocumentsTable';

const ExpiringDocuments = () => {
  const [page, setPage] = useState(1);

  const [filterValues, setFilterValues] = useState<{
    search: string | undefined;
    contactIds: string[] | undefined;
    documentType: string[] | undefined;
    type: string[] | undefined;
    dateRange: [Moment | null, Moment | null] | undefined;
  }>({
    search: undefined,
    type: undefined,
    contactIds: undefined,
    documentType: undefined,
    dateRange: undefined,
  });

  const { response, loading } = useFetch(
    () =>
      expiringDocumentsAPI.fetchExpiringDocuments({
        page,
        limit: DEFAULT_TABLE_LIMIT,
        sortBy: 'expirationDate',
        sortDirection: true,
        status: 'finalized',
        type: filterValues.documentType
          ? filterValues.documentType.join(',')
          : undefined,
        search: filterValues.search ? filterValues.search : undefined,
        entityIds: filterValues.contactIds
          ? filterValues.contactIds.join(',')
          : undefined,
        toExpirationDate:
          filterValues.dateRange && filterValues.dateRange[1]
            ? filterValues.dateRange[1].endOf('d')?.format('X')
            : undefined,
        fromExpirationDate:
          filterValues.dateRange && filterValues.dateRange[0]
            ? filterValues.dateRange[0].startOf('d')?.format('X')
            : undefined,
      }),
    [page, filterValues],
  );

  const handleFilterChange = (key: string, value: unknown | undefined) => {
    switch (key) {
      case 'contact_name': {
        setFilterValues((prevState) => ({
          ...prevState,
          contactIds: (value as string[]) || [],
        }));
        break;
      }
      case 'expiry_date': {
        setFilterValues((prevState) => ({
          ...prevState,
          dateRange: (value as [Moment | null, Moment | null]) || [],
        }));
        break;
      }
      case 'document_type': {
        setFilterValues((prevState) => ({
          ...prevState,
          documentType: (value as string[]) || undefined,
        }));
        break;
      }
      case 'search': {
        setFilterValues((prevState) => ({
          ...prevState,
          search: (value as string) || '',
        }));
      }
    }
  };

  const onFilterChange = (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => {
    if (Object.keys(filters).length) {
      Object.keys(filters).forEach((key) =>
        handleFilterChange(key, filters[key] as any),
      );
    }
  };

  return (
    <>
      <FilterBar onFilterChange={handleFilterChange} />
      <Divider />
      <ExpiringDocumentsTemplateTable
        current={page}
        onFilterChange={onFilterChange}
        filterValues={filterValues}
        loading={loading}
        data={response?.data || []}
        total={response?.total || 0}
        onPaginationChange={setPage}
      />
    </>
  );
};

export default ExpiringDocuments;
