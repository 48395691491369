import React from 'react';

// helpers
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import { Modal } from 'antd';
import { FormValuesModel } from '../../../..';
import { useFormikContext } from 'formik';
import { onboardingAnswerAPI } from '../../../../../../../../../api/crm/onboarding/onboardingAnswerAPI';
import { onboardingAnswerAdapter } from '../../../../../../../../../apiAdapters/crm/onboarding/onboardingAnswerAdapter';

// components
import RemoveButton from '../../../../../../../../../components/Buttons/RemoveButton';

interface IProps {
  fieldIndex: number;
}

const RemoveAdditionalField = ({ fieldIndex }: IProps) => {
  const { t } = useTranslation(['crm', 'common']);
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  const onRemoveClick = async (index: number) => {
    Modal.confirm({
      content: t('client_group.approval.remove_required_document_confirmation'),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        const fieldId = values.additionalFields[index]._id;
        const formattedBody = onboardingAnswerAdapter.removeRequiredDocument(
          values,
          fieldId,
        );
        await onboardingAnswerAPI.reviewApplicationDocumentation(formattedBody);
        const additionalFieldsCopy = [...values.additionalFields];
        additionalFieldsCopy.splice(index, 1);
        setFieldValue('additionalFields', additionalFieldsCopy);
      },
    });
  };

  return <RemoveButton onClick={() => onRemoveClick(fieldIndex)} />;
};

export default RemoveAdditionalField;
