import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../../../hooks/useFetch';
import rdcReviewAPI from '../../../../../../../api/compliance/rdcReviewAPI';
import { IClientGroup } from '../../../../../../../typings/crm/client-group';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../../constants/global';

// components
import ClientGroupRelationshipsWithRDCTable from '../../../../../../../components/Tables/TableTemplates/CRM/ClientGroup/ClientGroupRelationshipsWithRDCTable';

interface IProps {
  clientGroup: IClientGroup;
}

const RDCTab = ({ clientGroup }: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      rdcReviewAPI.fetchClientGroupRelationshipsWithRDCData(
        { page: currentPage, limit: DEFAULT_TABLE_LIMIT },
        clientGroup._id,
      ),
    [currentPage, clientGroup],
  );

  return (
    <ClientGroupRelationshipsWithRDCTable
      loading={loading}
      data={response?.data || []}
      total={response?.total || 0}
      current={currentPage}
      onPaginationChange={setCurrentPage}
    />
  );
};

export default RDCTab;
