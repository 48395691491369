import { FetchResponseModel } from 'typings/common';
import { ApprovalRuleSetModel } from 'typings/approval/templates';
import { ApprovalGroupFromQuery } from 'api/approval/approvalManagement/approvalGroupsAPI';
import {
  ConditionModel,
  LimitModel,
} from 'components/Forms/TemplateForms/Approval/FinanceApprovalRulesForm';
import { INewTransactionRuleTemplate } from 'api/approval/approvalManagement/clientTransactionRulesAPI';
import {
  ApprovalGroupStatuses,
  ClientApprovalGroup,
} from 'typings/approval/approvalGroups';
import { FormValuesModel as INewTransactionRuleFormValues } from 'components/Forms/TemplateForms/Approval/FinanceApprovalRulesForm';

export function getApprovalGroupStatus(
  group: ApprovalGroupFromQuery,
): ApprovalGroupStatuses {
  if (group.isDeleted) {
    return 'deactivated';
  } else {
    return group.isActive ? 'active' : 'in-review';
  }
}

const approvalGroupsAPIAdapter = {
  formatApprovalGroupFromQueryToClientApprovalGroupModel: (
    data: FetchResponseModel<ApprovalGroupFromQuery>,
  ): FetchResponseModel<ClientApprovalGroup> => {
    const formattedData: ClientApprovalGroup[] = data.data.map((e) => ({
      id: e.id,
      name: e.name,
      status: getApprovalGroupStatus(e),
      description: e.description,
      participants: e.participants.map((e) => ({
        id: e.userId,
        name: e.userFullName,
      })),
    }));

    return {
      data: formattedData,
      total: data.total,
    };
  },

  generateNewTransactionRule(formValues: INewTransactionRuleFormValues) {
    const { bankAccounts, conditions, limits } = formValues;

    const result = {
      bankAccounts: bankAccounts || [],
      approvalRuleSets: this.getFormattedApprovalRulesSets(conditions, limits),
    } as INewTransactionRuleTemplate;

    return result;
  },

  getFormattedApprovalRulesSets(
    conditions: ConditionModel[],
    limits: LimitModel[],
  ) {
    const result: ApprovalRuleSetModel[] = conditions.map(
      ({ conditions: innerConditions }, outerIndex) => ({
        approvalRuleItems: innerConditions.map(
          ({ requiredApproversCount, approvalGroup }) => ({
            minimunApprovalThreashold: requiredApproversCount as number,
            approvalGroup: { id: approvalGroup },
          }),
        ),
        approvalThreasholds: limits.map(
          ({ from = 0, to = 0, toInfinite }, innerIndex) => ({
            threasholdMin: from,
            threasholdMax: toInfinite ? -1 : to,
            ticked: conditions[outerIndex].limitStatuses[innerIndex].isChecked,
          }),
        ),
      }),
    );

    return result;
  },
};

export { approvalGroupsAPIAdapter };
