import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { FiscalPeriodModel } from '../../../../../typings/finance/fiscalPeriod';
import { DEFAULT_DATE_FORMAT } from '../../../../../constants/global';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel<FiscalPeriodModel>;

const FiscalPeriodTable = memo((props: IProps) => {
  const { t } = useTranslation('finance');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('fiscal_period.table.calendar_year'),
        key: 'calendar_year',
        dataIndex: 'calendarYear',
        render: (record: FiscalPeriodModel) => record.calendarYear,
      },
      {
        title: t('fiscal_period.table.calendar_month'),
        key: 'calendar_month',
        render: (record: FiscalPeriodModel) => record.calendarMonth,
      },
      {
        title: t('fiscal_period.table.fiscal_year'),
        key: 'fiscal_year',
        render: (record: FiscalPeriodModel) => record.fiscalYear,
      },
      {
        title: t('fiscal_period.table.fiscal_month'),
        key: 'fiscal_month',
        render: (record: FiscalPeriodModel) => record.fiscalMonth,
      },
      {
        title: t('fiscal_period.table.start_date'),
        key: 'start_date',
        render: (record: FiscalPeriodModel) =>
          DateHelpers.formatTimestampToString(
            Number(record.startDate),
            DEFAULT_DATE_FORMAT,
          ),
      },
      {
        title: t('fiscal_period.table.end_date'),
        key: 'end_date',
        render: (record: FiscalPeriodModel) =>
          DateHelpers.formatTimestampToString(
            Number(record.endDate),
            DEFAULT_DATE_FORMAT,
          ),
      },
    ];

    return result;
  }, [t]);

  return <Table columns={columns} {...props} />;
});

export default FiscalPeriodTable;
