// helpers
import styled from 'styled-components';

// components
import { Descriptions } from 'antd';

const Description = styled(Descriptions)`
  .ant-descriptions-item-label {
    color: ${({ theme }) => theme.whiteColor} !important;
    font-weight: 600;
    margin-bottom: ${({ theme }) => theme.marginXXs};
  }

  .ant-descriptions-item-content {
    display: flex !important;
    flex-direction: column !important;
  }
`;

export default Description;
