import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import { StyledComponentProps } from '../../../../typings/common';

// components
import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';

export interface InputPhoneNumberProps extends StyledComponentProps {
  name?: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const InputPhoneNumber = memo(
  ({
    value,
    onChange,
    onBlur,
    disabled,
    placeholder,
    name,
    ...rest
  }: InputPhoneNumberProps) => {
    return (
      <StyledPhoneInput
        {...rest}
        name={name}
        value={value as string}
        onChange={onChange as any}
        onBlur={onBlur as any}
        disabled={disabled}
        placeholder={placeholder}
        defaultCountry="KY"
        international
        className="ant"
        numberInputProps={{ className: 'ant-input ant-input-lg' }}
      />
    );
  },
);

const StyledPhoneInput = styled(PhoneInput)`
  .PhoneInputCountry {
    margin-right: 0px;
    padding: 0px 10px;

    border: ${({ theme }) => `1px solid ${theme.cardBorderColor}`};
    border-right: 0px;
  }
`;

export default InputPhoneNumber;
