import React, { memo, useMemo } from 'react';

// helpers utils
import useTranslation from '../../../../../hooks/useTranslation';
import { enumToMap } from '../../../../../helpers/utils';
import { GenderOptions } from '../../../../../enums/crm/crm';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type GenderFormSelectProps = SelectProps;

const GenderFormSelect = memo((props: GenderFormSelectProps) => {
  const { t } = useTranslation('crm');

  const options = useMemo<SelectOption[]>(() => {
    const genderOptions = Array.from(enumToMap(GenderOptions));

    return genderOptions.map((e) => ({
      id: e[1],
      label: t(`gender.${e[1]}`),
    }));
  }, [t]);

  return <FormSelect {...props} options={options} />;
});

export default GenderFormSelect;
