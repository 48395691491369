import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { FilterOptionTypes } from '..';

// components
import RiskLevelSelect from '../../../../components/Forms/FormComponents/SelectInputs/Compliance/RiskLevelSelect';
import ReviewTypeSelect from '../../../../components/Forms/FormComponents/SelectInputs/Compliance/ReviewTypeSelect';
import ReferenceObjectsAutocomlete from '../../../../components/Forms/FormComponents/Autocompletes/Compliance/ReferenceObjectsAutocomplete';
import { Col, Row } from 'antd';

interface IProps {
  onFilterChange: (key: FilterOptionTypes, value?: string) => void;
}

const FilterBar = ({ onFilterChange }: IProps) => {
  const { t } = useTranslation('compliance');

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Row>
          <Col span={24}>{t('review_configuration.review_type')}</Col>
          <Col span={24}>
            <StyledReviewTypeSelect
              size="large"
              onChange={(newValue) => onFilterChange('review_type', newValue)}
            />
          </Col>
        </Row>
      </Col>

      <Col span={6}>
        <Row>
          <Col span={24}>{t('review_configuration.risk_level')}</Col>
          <Col span={24}>
            <StyledRiskLevelSelect
              size="large"
              onChange={(newValue) => onFilterChange('risk_level', newValue)}
            />
          </Col>
        </Row>
      </Col>

      <Col span={6}>
        <Row>
          <Col span={24}>{t('review_configuration.reference_object')}</Col>
          <Col span={24}>
            <StyledReferenceObjectsAutocomlete
              onChange={(newValue) =>
                onFilterChange('reference_object', newValue)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledReviewTypeSelect = styled(ReviewTypeSelect)`
  width: 100%;
`;

const StyledRiskLevelSelect = styled(RiskLevelSelect)`
  width: 100%;
`;

const StyledReferenceObjectsAutocomlete = styled(ReferenceObjectsAutocomlete)`
  width: 100%;
`;

export default FilterBar;
