import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import FormField from '@core_components/FormField';
import YesNoSelect from '../../../../../Forms/FormComponents/SelectInputs/YesNoSelect';
import AccountCategoryTreeSelect from '../../../../../Forms/FormComponents/Autocompletes/Finance/AccountCategoryTreeSelect';
import { Col, Input, Row } from 'antd';

interface ISelectCategoryModel {
  financialTypeName: string;
  categoryId: number;
}

const InnerForm = () => {
  const { t } = useTranslation(['finance', 'common', 'form']);

  const { setFieldValue } = useFormikContext<FormValuesModel>();

  const handleOnSelectAccountCategory = (
    _: string,
    item?: ISelectCategoryModel,
  ) => {
    setFieldValue('financialTypeName', item?.financialTypeName || '');
  };
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <FormField
            name="accountCategoryName"
            placeholder={t('account_categories.new.enter_value')}
            label={t('account_categories.new.account_category_name')}
            component={Input}
          />
        </Col>
        <Col span={12}>
          <FormField
            label={t('account_categories.new.is_client')}
            name="isClient"
            component={YesNoSelect}
            additionalProps={{
              allowClear: true,
              yesText: t('yes', { ns: 'common' }),
              noText: t('no', { ns: 'common' }),
              showNotProvidedOption: true,
            }}
            placeholder={t('select_option', { ns: 'common' })}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <FormField
            label={t('account_categories.new.account_category')}
            name="parentCategoryId"
            component={AccountCategoryTreeSelect}
            additionalProps={{
              allowClear: true,
              onSelect: handleOnSelectAccountCategory,
              canSelectOnlyParent: true,
            }}
            placeholder={t('enterPlaceholder', {
              ns: 'form',
              fieldName: t(
                'account_categories.new.account_category',
              ).toLowerCase(),
            })}
          />
        </Col>
        <Col span={12}>
          <FormField
            label={t('account_categories.new.financial_type')}
            name="financialTypeName"
            component={Input}
            disabled
            placeholder={t('account_categories.new.select_account_category')}
          />
        </Col>
      </Row>

      <FormField
        name="description"
        label={t('account_categories.new.description')}
        component={Input.TextArea}
        placeholder={t('account_categories.new.enter_value')}
        additionalProps={{
          autoSize: { minRows: 6, maxRows: 6 },
        }}
      />
    </>
  );
};

export default InnerForm;
