import React from 'react';

// helpers
import styled from 'styled-components';
import MockedFinancialTypes from '../../../../../mock/financial-types.json';

// components
import { Select } from 'antd';

interface IProps {
  [key: string]: any;
}

const { Option } = Select;

const FinancialTypeSelect = (props: IProps) => {
  const renderOptions = () =>
    MockedFinancialTypes.data.map(({ id, financialTypeName }) => (
      <Option key={id} value={financialTypeName}>
        {financialTypeName}
      </Option>
    ));

  return (
    <StyledSelect
      {...props}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {renderOptions()}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;
export default FinancialTypeSelect;
