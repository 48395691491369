import React, { memo } from 'react';

// helpers
import { TransactionWithComplianceInfoModel } from 'typings/compliance/transactions';

// components
import RDCAlertsTable from '../RDCAlertsTable';
import RDCInfoSection from 'components/Additional/RDC/RDCInfoSection';
import { Divider } from 'antd';

interface IProps {
  record: TransactionWithComplianceInfoModel;
}

const ReviewProcessDetails = memo(({ record }: IProps) => {
  return (
    <>
      <RDCInfoSection
        data={{
          createdAt: record.createdAt,
          requestParamsJSON: record.requestParamsJSON,
        }}
      />
      <Divider />
      <RDCAlertsTable reviewProcess={record} />
    </>
  );
});

export default ReviewProcessDetails;
