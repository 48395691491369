import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { enumToMap } from '../../../../../helpers/utils';
import { ClientAccountManagementApprovalStatuses } from 'typings/approval/approvalWorkflow';

// components
import Select, { SelectOption, SelectProps } from '@core_components/Select';

type IProps = SelectProps;

const ApprovalStatusSelect = memo((props: IProps) => {
  const { t } = useTranslation('crm');

  const options = useMemo<SelectOption[]>(() => {
    const approvalStatusOptions = Array.from(
      enumToMap(ClientAccountManagementApprovalStatuses),
    ).slice(0, 4);

    return approvalStatusOptions.map((e) => ({
      id: e[1],
      label: t(
        `client_group.administration_rules.pending_approvals.approval_statuses.${e[1]}`,
      ),
    }));
  }, [t]);

  return <Select {...props} options={options} />;
});

export default ApprovalStatusSelect;
