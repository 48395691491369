import React from 'react';

// ** Helpers & Utils **
import useTranslation from '../../../../hooks/useTranslation';
import { IContactModel } from '../../../../typings/crm/contact';
import { IMessagesStatus } from '../../../../typings/messaging/messages';

// ** Components **
import Tabs from '../../../../components/Tabs';
import Messages from '../Messages';
import NewMessagesBadge from '../../../../components/Additional/Messaging/NewMessagesBadge';

interface IProps {
  contact: IContactModel;
  messagesStatus?: IMessagesStatus;
  clientGroupIdNumericId?: number;
}

const ClientTabs = ({
  contact,
  messagesStatus,
  clientGroupIdNumericId,
}: IProps) => {
  const { t } = useTranslation('crm');

  // TODO: Add real permission checking for Workflow, Messaging
  const hasMessagingAccess = true;

  const tabsProps = {
    defaultActiveKey: 'messages',
    tabs: [
      {
        key: 'messages',
        title: (
          <>
            {t('client.tabs.messages')}
            <NewMessagesBadge
              count={messagesStatus?.newMessagesCount as number}
            />
          </>
        ),
        content: clientGroupIdNumericId && (
          <Messages
            contact={contact}
            clientGroupNumericId={clientGroupIdNumericId}
            messagesStatus={messagesStatus}
          />
        ),
        hidden: !clientGroupIdNumericId || !hasMessagingAccess,
      },
    ],
  };

  return <Tabs {...tabsProps} />;
};

export default ClientTabs;
