import React, { useCallback, useEffect } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../hooks/useFetch';
import useTranslation from '../../../hooks/useTranslation';
import moment, { Moment } from 'moment';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../routes/routes';
import { DateHelpers } from '@helpers/date';
import { reportingAPI } from '../../../api/reporting/reportingAPI';
import { fiscalPeriodAPI } from '../../../api/finance/fiscalPeriod/fiscalPeriodAPI';
import { ReportElementModel } from '../../../typings/reporting/reporting';

// components
import FilterBar from './FilterBar';
import ReportTree from '../../../components/Additional/ReportTree';
import LoadingWrapper from '../../../components/WrapperComponents/LoadingWrapper';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Divider, Tooltip } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

interface IProps {
  selectedFilterDate: [Moment, Moment] | null;
  setSelectedDate: (range: [Moment, Moment] | null) => void;
}

const IncomeStatementReport = ({
  selectedFilterDate,
  setSelectedDate,
}: IProps) => {
  const { t } = useTranslation('reporting');
  const history = useHistory();
  const { response: fiscalResponse, loading: fiscalLoading } = useFetch(
    () => fiscalPeriodAPI.getFiscalPeriodStartDate(),
    [],
  );

  useEffect(() => {
    if (fiscalResponse) {
      const startDate = moment(
        DateHelpers.formatTimestampToString(fiscalResponse.FiscalYearStart),
      );
      const endDate = moment(startDate).add(6, 'M');
      setSelectedDate([startDate, endDate]);
    }
  }, [fiscalResponse]);

  const { response, loading } = useFetch(
    () =>
      selectedFilterDate
        ? reportingAPI.fetchIncomeStatementReport(
            selectedFilterDate[0].startOf('d').format('X'),
            selectedFilterDate[1].endOf('d').format('X'),
          )
        : null,
    [selectedFilterDate],
  );

  // handle filter bar change
  const handleFilterChange = (key: string, value: any) => {
    if (selectedFilterDate) {
      switch (key) {
        case 'startDate':
          setSelectedDate([value, selectedFilterDate[1]]);
          break;
        case 'endDate':
          setSelectedDate([selectedFilterDate[0], value]);
          break;
      }
    }
  };

  const redirectToAccounts = (node: ReportElementModel) => {
    history.push({
      pathname: RoutePaths.Finance_Accounts,
      search: `?categoryId=${node.id}&categoryName=${encodeURIComponent(node.name)}`,
    });
  };

  const renderNode = useCallback((node: ReportElementModel) => {
    if (!node.children.length) {
      return (
        <>
          <EllipsisTooltip maxTextContainerWidth="400px" title={node.name}>
            {node.name}
          </EllipsisTooltip>
          <Tooltip
            title={t('income_statement.go_to_account_tooltip_message')}
            placement="bottom"
          >
            <ArrowRightOutlined onClick={() => redirectToAccounts(node)} />
          </Tooltip>
        </>
      );
    }
    return node.name;
  }, []);

  return (
    <LoadingWrapper loading={fiscalLoading}>
      <FilterBar
        filterChange={handleFilterChange}
        dateRangeValue={selectedFilterDate}
        isDisabled={fiscalLoading || loading}
      />
      <Divider />
      <LoadingWrapper loading={loading}>
        {response && (
          <Wrapper>
            <ReportTree report={response?.data} renderNode={renderNode} />
          </Wrapper>
        )}
      </LoadingWrapper>
    </LoadingWrapper>
  );
};

const Wrapper = styled.div`
  max-width: 100%;
  overflow: auto;
`;

export default IncomeStatementReport;
