import { ICreateRelationshipTemplateFormBody } from '../../../modules/CRM/RelationhipTemplates/AddEditRelationshipTemplate';
import {
  CreateRelationshipTemplateBody,
  EditRelationshipTemplateBody,
} from '../../../api/crm/relationship/relationshipTemplatesAPI';
import { IAdditionalField } from '../../../typings/crm/relationship-template';
import { AdditionalFieldType } from '../../../enums/crm/crm';

const relationshipTemplatesAdapter = {
  generateCreateRelationshipTemplateBody: (
    values: ICreateRelationshipTemplateFormBody,
  ): CreateRelationshipTemplateBody => ({
    parentName: values.parentName,
    parentType: values.parentType,
    childName: values.childName,
    childType: values.childType,
    additionalFields: values.additionalFields.length
      ? values.additionalFields.map(mapAdditionalField)
      : [],
    individualAdditionalFields: values.individualAdditionalFields.length
      ? values.individualAdditionalFields.map(mapAdditionalField)
      : [],
  }),

  generateUpdateRelationshipTemplateBody: (
    values: ICreateRelationshipTemplateFormBody,
  ): EditRelationshipTemplateBody => ({
    parentName: values.parentName,
    parentType: values.parentType,
    childName: values.childName,
    childType: values.childType,
    additionalFields: values.additionalFields.length
      ? values.additionalFields.map(mapAdditionalField)
      : [],
    individualAdditionalFields: values.individualAdditionalFields.length
      ? values.individualAdditionalFields.map(mapAdditionalField)
      : [],
  }),
};

export const mapAdditionalField = (
  additionalField: IAdditionalField,
): IAdditionalField => ({
  ...additionalField,
  options: {
    documentRequiresCertification:
      additionalField?.options?.documentRequiresCertification,
    isForRegulatedOnly: additionalField?.options?.isForRegulatedOnly,
    onboardingRequired: additionalField?.options?.onboardingRequired,
    isDirectClientOnly: additionalField?.options?.isDirectClientOnly,
    formDocumentId:
      additionalField.type === AdditionalFieldType.Form
        ? additionalField.options?.formDocument[0]?.id
        : undefined,
    selectOptions:
      additionalField?.options?.selectOptionsValues?.map((v) => ({
        type: AdditionalFieldType.String,
        valueJSON: JSON.stringify(v),
      })) || [],
  },
});

export { relationshipTemplatesAdapter };
