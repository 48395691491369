import React, { memo, useEffect, useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import useQueryParam from '../../../../hooks/useQueryParam';
import { useHistory } from 'react-router-dom';
import { BankingBankAccount } from '../../../../typings/banking/bankAccounts';
import { BankAccountStatuses } from 'enums/banking/accounts';
import { bankingBankAccountsAPI } from '../../../../api/banking/bankingBankAccountsAPI';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';

// components
import BankAccountModal from '../BankAccountModal';
import FilterBar, { FilterBarKeys } from './FilterBar';
import { Divider } from 'antd';
import {
  default as TemplateBankAccountsTable,
  ActionKeys,
} from '../../../../components/Tables/TableTemplates/Banking/BankAccountsTable';

const BankAccountsTable = memo(() => {
  const history = useHistory();
  const accountNumberFromURL = useQueryParam({ param: 'accountNumber' });
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [updateTableTrigger, updateTable] = useState({});
  const [selectedAccountNumber, setSelectedAccountNumber] = useState<
    null | string
  >(null);

  useEffect(() => {
    accountNumberFromURL && setSelectedAccountNumber(accountNumberFromURL);
  }, [accountNumberFromURL]);

  const [filterValues, setFilterValues] = useState<{
    status: BankAccountStatuses[] | undefined;
  }>({
    status: [
      BankAccountStatuses.Active,
      BankAccountStatuses.PendingApproval,
      BankAccountStatuses.Processing,
    ],
  });

  const { response, loading } = useFetch(
    () =>
      bankingBankAccountsAPI.fetchBankAccounts({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        accountQuery: searchQuery,
        isActive: 'all',
        isFrozen: 'all',
        status: filterValues.status ? filterValues.status.join(',') : undefined,
      }),
    [currentPage, searchQuery, updateTableTrigger, filterValues],
  );

  const handleFilterBarChange = (key: FilterBarKeys, value: string) => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }

    switch (key) {
      case 'search_by_account_number_name':
        setSearchQuery(value.trim());
        break;
    }
  };

  const handleActionsClick = (key: ActionKeys, record: BankingBankAccount) => {
    switch (key) {
      case 'bank-account-click':
        setSelectedAccountNumber(record.accountNumber);
        break;
    }
  };

  const handleModalCloseCallback = (wasSubmitted?: boolean) => {
    if (wasSubmitted) {
      updateTable({});
    }

    if (accountNumberFromURL) {
      history.replace({ search: '' });
    }

    setSelectedAccountNumber(null);
  };

  const handleFilterChange = (key: string, value: unknown | undefined) => {
    switch (key) {
      case 'status':
        setFilterValues((prev) => ({
          ...prev,
          status: value as BankAccountStatuses[],
        }));
        break;
    }
  };

  const onFilterChange = (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => {
    if (Object.keys(filters).length) {
      Object.keys(filters).forEach((key) =>
        handleFilterChange(key, filters[key] as any),
      );
    }
  };

  return (
    <>
      <FilterBar onFilterChange={handleFilterBarChange} />
      <Divider />
      <TemplateBankAccountsTable
        data={response?.data || []}
        total={response?.total}
        loading={loading}
        current={currentPage}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsClick}
        filters={filterValues}
        onFilterChange={onFilterChange}
      />
      <BankAccountModal
        isVisible={!!selectedAccountNumber}
        closeCallback={handleModalCloseCallback}
        accountNumber={selectedAccountNumber}
      />
    </>
  );
});

export default BankAccountsTable;
