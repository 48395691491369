import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { ClientTransactionTypes } from 'enums/banking/transactions';
import { TransactionDocumentModel } from 'typings/finance/transaction';
import { ClientTransferApprovalStatuses } from 'enums/transfers/transfers';

// components
import DocumentLink from 'components/Typography/DocumentLink';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import ClientTransactionStatus from 'components/Additional/Statuses/ClientTransactionStatus';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';
import WireTransferTemplateInfo, {
  WireTemplate,
} from '../../WireTransferTemplateInfo';

export type WireTransactionSenderData = {
  type: ClientTransactionTypes;
  purpose: string;
  amount: string;
  postingDate: string;
  valueDate: string;
  requestedBy: string;
  templateId: number;
  templateName: string;
  status: ClientTransferApprovalStatuses;
  workflowExpirationDate: number;
  relatedDocuments: TransactionDocumentModel[];
  template: WireTemplate | null;
  transactionNumber: string;
  senderReference: string;
  orderingCustomer: {
    accountName: string;
    accountNumber: string;
    address: string;
    reference: string;
  };
};

interface IProps {
  journalEntryId: number | null;
  transaction: WireTransactionSenderData;
}

const ValueWrapper = memo(({ children }) => {
  return (
    <EllipsisTooltip maxTextContainerWidth="500px" title={children}>
      {children}
    </EllipsisTooltip>
  );
});

const Sender = memo(({ transaction, journalEntryId }: IProps) => {
  const { t } = useTranslation('banking');

  const descriptionData = useMemo<DescriptionItem[]>(() => {
    let result: DescriptionItem[] = [];
    const {
      type,
      status,
      amount,
      purpose,
      template,
      valueDate,
      requestedBy,
      postingDate,
      senderReference,
      relatedDocuments,
      orderingCustomer,
      workflowExpirationDate,
      transactionNumber,
    } = transaction;

    result = [
      {
        label: t(
          'transactions.transaction_details.internal.transaction_number',
        ),
        description: transactionNumber,
      },
      {
        label: t('transactions.transaction_details.internal.type'),
        description: t(`transactions.types.${ClientTransactionTypes[type]}`),
      },

      {
        label: t('transactions.transaction_details.internal.purpose'),
        description: purpose,
      },

      {
        label: t(
          'transactions.transaction_details.international.sender_reference',
        ),
        description: senderReference,
      },

      {
        label: t('transactions.transaction_details.internal.ordering_customer'),
        description: (
          <StyledInnerDescriptionSection
            size="small"
            bordered={false}
            background="none"
            data={[
              {
                label: t(
                  'transactions.transaction_details.internal.account_name',
                ),
                description: (
                  <ValueWrapper>{orderingCustomer.accountName}</ValueWrapper>
                ),
              },

              {
                label: t(
                  'transactions.transaction_details.internal.account_number',
                ),
                description: (
                  <ValueWrapper>{orderingCustomer.accountNumber}</ValueWrapper>
                ),
              },

              {
                label: t('transactions.transaction_details.internal.address'),
                description: (
                  <ValueWrapper>{orderingCustomer.address}</ValueWrapper>
                ),
              },

              {
                label: t(
                  'transactions.transaction_details.internal.from_account_reference',
                ),
                description: (
                  <ValueWrapper>{orderingCustomer.reference}</ValueWrapper>
                ),
              },
            ]}
          />
        ),
      },

      {
        label: t('transactions.transaction_details.international.beneficiary'),
        description: template ? (
          <WireTransferTemplateInfo
            displayTemplateName
            hideGeneralInfo
            template={template}
          />
        ) : null,
      },

      {
        label: t('transactions.transaction_details.internal.amount'),
        description: amount,
      },

      {
        label: t('transactions.transaction_details.internal.posting_date'),
        description: postingDate,
      },

      {
        label: t('transactions.transaction_details.internal.value_date'),
        description: valueDate,
      },

      {
        label: t('transactions.transaction_details.internal.requested_by'),
        description: requestedBy,
      },

      {
        label: t('transactions.transaction_details.internal.status'),
        description: (
          <ClientTransactionStatus
            transaction={{ status, expiresAt: workflowExpirationDate }}
          />
        ),
      },

      {
        label: t('transactions.transaction_details.internal.documents'),
        description: relatedDocuments.map(({ dmsId, name, linkToDownload }) => (
          <div key={dmsId}>
            <DocumentLink
              text={name}
              fileId={linkToDownload}
              documentId={dmsId}
            />
          </div>
        )),
      },
    ];

    return result;
  }, [t, transaction, journalEntryId]);

  return <DescriptionSection data={descriptionData} />;
});

const StyledInnerDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-label {
    width: 250px !important;
    max-width: 250px !important;
  }
`;

export default Sender;
