import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { DateHelpers } from '@helpers/date';
import { NoteFromQuery } from 'api/note/noteAPI';
import { NoteRelatedType } from 'enums/crm/crm';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
} from 'constants/global';

// components
import Link from '@common_components/Texts/Link';
import Button from '@core_components/Button';
import DocumentLink from 'components/Typography/DocumentLink';
import NoteTypeSelect from 'modules/CRM/Notes/DashboardNotesTable/FilterBar/NoteTypeSelect';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import UsersAutocomplete from 'components/Forms/FormComponents/Autocompletes/ORAC/UsersAutocomplete';
import Table, {
  RequiredPropsForTableModel,
  SortProps,
  TableColumnModel,
} from '@core_components/Table';
import { DatePicker } from 'antd';

interface IProps extends RequiredPropsForTableModel<NoteFromQuery> {
  onActionsClick: (key: string, value: NoteFromQuery) => void;
  onSortChange?: (sorter: SortProps) => void;
  onFilterChange: (
    key: 'date_range' | 'type' | 'created_by',
    value: any,
  ) => void;
}

const NotesDashboardTable = memo(
  ({ onFilterChange, onActionsClick, ...rest }: IProps) => {
    const history = useHistory();
    const { t } = useTranslation('crm');

    const [
      hasORACUserDetailsAccess,
      hasContactDetailsAccess,
      hasEntityDetailsAccess,
      hasClientGroupDetailsAccess,
      hasComplianceTransactionDetailsAccess,
    ] = useUserAccess([
      'ORAC_Users_Details_View',
      'CRM_Contacts_Details_View',
      'CRM_Entities_Details_View',
      'CRM_ClientGroup_Details_View',
      'Compliance_Transactions_Details_View',
    ]);

    const columns = useMemo(() => {
      function getNoteItemLink(item: {
        id: string;
        name: string;
        type: NoteRelatedType;
      }) {
        let data = {
          name: '',
          link: '',
          hasAccess: false,
        };

        switch (item.type) {
          case NoteRelatedType.Organization: {
            data = {
              name: item.name,
              link: RoutePaths.CRM_Entities_Edit,
              hasAccess: hasEntityDetailsAccess,
            };
            break;
          }

          case NoteRelatedType.Contact: {
            data = {
              name: item.name,
              link: RoutePaths.CRM_Contacts_Edit,
              hasAccess: hasContactDetailsAccess,
            };
            break;
          }

          case NoteRelatedType.ClientGroup: {
            data = {
              name: item.name,
              link: RoutePaths.CRM_Client_Group_Details,
              hasAccess: hasClientGroupDetailsAccess,
            };
            break;
          }

          case NoteRelatedType.ReviewProcess: {
            data = {
              name: item.name
                ? item.name
                : t(`entity.notes.relatedToTypes.${item.type}`),
              link: RoutePaths.Compliance_Transactions_Details,
              hasAccess: hasComplianceTransactionDetailsAccess,
            };
          }
        }

        return (
          <div key={item.id}>
            {data.hasAccess ? (
              <Link
                onClick={() =>
                  history.push({
                    pathname: data.link,
                    search: `?id=${item.id}`,
                  })
                }
              >
                {data.name}
              </Link>
            ) : (
              data.name
            )}
          </div>
        );
      }

      const result: TableColumnModel[] = [
        {
          sorter: true,
          sortDirections: ['descend', 'ascend'],
          showSorterTooltip: false,
          filterDropdown: (
            <FilterWrapper>
              <StyledRangePicker
                allowClear
                size="large"
                format={DEFAULT_DATE_FORMAT}
                onChange={(e) => onFilterChange('date_range', e)}
              />
            </FilterWrapper>
          ),
          key: 'date',
          width: 190,
          title: t('notes.table.date'),
          render: (record: NoteFromQuery) =>
            DateHelpers.formatDateToString(
              record.createdAt,
              DEFAULT_DATE_TIME_FORMAT,
            ),
        },
        {
          width: 150,
          filterDropdown: (
            <FilterWrapper>
              <StyledNoteTypeSelect
                onChange={(value) => onFilterChange('type', value)}
              />
            </FilterWrapper>
          ),
          key: 'type',
          title: t('notes.table.type'),
          render: (record: NoteFromQuery) =>
            t(`entity.notes.types.${record.type}`),
        },
        {
          width: 400,
          key: 'description',
          title: t('notes.table.description'),
          render: (record: NoteFromQuery) => {
            return (
              <EllipsisTooltip
                maxTextContainerWidth="400px"
                title={record.text}
              >
                {record.text}
              </EllipsisTooltip>
            );
          },
        },
        {
          key: 'created_by',
          width: 200,
          filterDropdown: (
            <FilterWrapper>
              <StyledUsersAutocomplete
                onChange={(value) => onFilterChange('created_by', value)}
              />
            </FilterWrapper>
          ),
          title: t('notes.table.created_by'),
          render: (record: NoteFromQuery) => {
            const name = (
              <EllipsisTooltip
                maxTextContainerWidth="200px"
                title={record.creator.name}
              >
                {record.creator.name}
              </EllipsisTooltip>
            );

            if (!hasORACUserDetailsAccess) {
              return name;
            }

            return (
              <Link
                onClick={() =>
                  history.push({
                    pathname: RoutePaths.ORAC_User_Details,
                    search: `?id=${record.creator._id}`,
                  })
                }
              >
                {name}
              </Link>
            );
          },
        },
        {
          width: 300,
          key: 'documents',
          title: t('notes.table.documents'),
          render: (record: NoteFromQuery) => {
            if (!record.documents || !record.documents.length) {
              return null;
            }

            return record.documents.map((document) => (
              <div key={document._id}>
                <DocumentLink
                  key={document._id}
                  documentId={document._id}
                  fileId={document.fileId}
                  text={document.name}
                  fileName={document.name}
                />
              </div>
            ));
          },
        },

        {
          key: 'related_to',
          title: t('notes.table.related_to'),
          render: (record: NoteFromQuery) => {
            if (!record.sharedWith || !record.sharedWith.length) {
              return null;
            }

            return record.sharedWith.map((e) =>
              getNoteItemLink({
                id: e.itemId,
                name: e.name,
                type: e.itemType,
              }),
            );
          },
        },
        {
          width: 230,
          align: 'right',
          key: 'actions',
          render: (record: NoteFromQuery) => (
            <Button onClick={() => onActionsClick('view', record)}>
              {t('notes.table.view_button')}
            </Button>
          ),
        },
      ];

      return result;
    }, [
      t,
      history,
      hasORACUserDetailsAccess,
      hasContactDetailsAccess,
      hasEntityDetailsAccess,
      hasClientGroupDetailsAccess,
      hasComplianceTransactionDetailsAccess,
      onFilterChange,
    ]);

    return <Table columns={columns} {...rest} />;
  },
);

const FilterWrapper = styled.div`
  padding: 15px;
  min-width: 300px;
  max-width: 300px;
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;

const StyledNoteTypeSelect = styled(NoteTypeSelect)`
  width: 100%;
`;

const StyledUsersAutocomplete = styled(UsersAutocomplete)`
  width: 100%;
`;

export default NotesDashboardTable;
