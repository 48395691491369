import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import { StyledComponentProps } from '../../../typings/common';

// components
import { Layout } from 'antd';

const { Footer } = Layout;

type IProps = StyledComponentProps;

const AppFooter = (props: IProps) => {
  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <FooterWrapper {...props}>© {currentYear} Tenet Bank Ltd.</FooterWrapper>
  );
};

const FooterWrapper = styled(Footer)`
  color: ${({ theme }) => theme.textLightColor3};
  background: ${({ theme }) => theme.footerBackground};
  padding: ${({ theme }) => theme.containerPadding};
  margin-top: ${({ theme }) => theme.footerMargin};
`;

export default AppFooter;
