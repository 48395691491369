import { IReduxAction } from '../../typings/common';
import { UploadStatus } from '../../constants/documents';
import { ActionType, IFileHash } from '../actions/upload';

// Models
export interface StateModel {
  shouldShowUploadNotification: boolean;
  status: UploadStatus;
  progress: number;
  sizeToUpload: number;
  isFinished: boolean;
  files: IFileHash;
}

// Initial state
const initialData: StateModel = {
  shouldShowUploadNotification: true,
  status: UploadStatus.WAIT,
  progress: 0,
  sizeToUpload: 0,
  isFinished: false,
  files: {} as IFileHash,
};

// Reducer
const upload = (state = initialData, action: IReduxAction) => {
  const { type, payload, key } = action;
  switch (type) {
    case ActionType.UPLOAD_FILES: {
      return {
        ...state,
        isFinished: false,
        files: payload.files,
        sizeToUpload: payload.sizeToUpload,
        status: UploadStatus.ACTIVE,
      };
    }
    case ActionType.REFRESH_UPLOAD_STATE: {
      return {
        ...payload,
      };
    }
    case ActionType.SET_UPLOAD_FINISH_STATUS:
    case ActionType.SET_UPLOAD_STATUS:
      return { ...state, [key]: payload };
    case ActionType.SET_UPLOAD_FILES:
      return {
        ...state,
        isFinished: false,
        files: payload.files,
        sizeToUpload: payload.sizeToUpload,
      };
    case ActionType.SET_UPLOAD_FILE_STATUS:
      return {
        ...state,
        files: {
          ...state.files,
          [payload.fileId]: {
            ...state.files[payload.fileId],
            status: payload.status,
          },
        },
      };
    case ActionType.UPDATE_CHUNK_PERCENTAGE:
      return {
        ...state,
        progress: payload.progress,
        files: {
          ...state.files,
          [payload.fileId]: payload.updatedFile,
        },
      };
    case ActionType.SET_FILE_CHUNKS:
      return {
        ...state,
        files: {
          ...state.files,
          [payload.fileId]: {
            ...state.files[payload.fileId],
            chunksArray: payload.chunksArray,
          },
        },
      };
    case ActionType.SET_CHUNK_STATUS:
      return {
        ...state,
        files: {
          ...state.files,
          [payload.fileId]: {
            ...state.files[payload.fileId],
            chunksArray: {
              ...state.files[payload.fileId].chunksArray,
              [payload.chunkId]: {
                ...state.files[payload.fileId].chunksArray[payload.chunkId],
                status: payload.status,
              },
            },
          },
        },
      };
    case ActionType.SET_FILE_DATA_BASE_ID:
    case ActionType.SET_FILE_NAME:
    case ActionType.SET_FILE_TAGS:
      return {
        ...state,
        files: {
          ...state.files,
          [payload.fileId]: { ...payload.file },
        },
      };
    case ActionType.SET_SHOULD_SHOW_NOTIFICATION_STATUS:
      return {
        ...state,
        shouldShowUploadNotification: payload,
      };

    case ActionType.REMOVE_FILE: {
      const result: IFileHash = {};

      const files = Object.values(state.files).filter(
        (x) => x.dataBaseId === payload.fileId,
      );
      files.forEach((x) => {
        result[x.id] = {
          ...x,
        };
      });

      return {
        ...state,
        files: result,
      };
    }

    default:
      return state;
  }
};

export default upload;
