import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { IUserDevice } from '../../../../../typings/profile/security';
import { SessionHelpers } from '@helpers/sessions';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import StatusLabel, {
  LABEL_STATUSES,
} from '../../../../Typography/StatusLabel';
import { DesktopOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<IUserDevice> {
  onSignOut: (sessionId: string) => void;
}

const DevicesTable = memo(({ onSignOut, ...rest }: IProps) => {
  const { t } = useTranslation('profile');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('sign_in_and_security.devices.device_name'),
        key: 'device',
        render: (record: IUserDevice) => (
          <Typography.Text>
            <StyledDesktopIcon />
            {SessionHelpers.getDeviceName(record)}
          </Typography.Text>
        ),
      },
      {
        title: t('sign_in_and_security.devices.last_session'),
        key: 'lastSession',
        render: (record: IUserDevice) =>
          record.session?.updatedAt
            ? DateHelpers.formatDateToString(record.session.updatedAt, 'llll')
            : null,
      },
      {
        title: t('sign_in_and_security.devices.status'),
        key: 'status',
        render: (record: IUserDevice) =>
          record.session?._id && !record.session.deleted ? (
            <StatusLabel
              status={LABEL_STATUSES.success}
              text={t('sign_in_and_security.devices.online')}
            />
          ) : (
            <StatusLabel
              status={LABEL_STATUSES.error}
              text={t('sign_in_and_security.devices.offline')}
            />
          ),
      },
      {
        key: 'delete',
        render: (record: IUserDevice) =>
          record.session?._id && !record.session?.deleted ? (
            <Button
              danger
              onClick={() => onSignOut(String(record.session?._id))}
            >
              {t('sign_in_and_security.devices.sign_out')}
            </Button>
          ) : null,
      },
    ];

    return result;
  }, [t, onSignOut]);

  return <Table columns={columns} {...rest} />;
});

const StyledDesktopIcon = styled(DesktopOutlined)`
  margin-right: 10px;
`;

export default DevicesTable;
