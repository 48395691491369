import React, { useEffect } from 'react';

// helpers
import styled from 'styled-components';
import useQueryParam from '../../../hooks/useQueryParam';
import { userAPI } from '../../../api/auth/userAPI';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setTwoFactorVerificationData } from '../../../redux/actions/auth';

// constants
import { RoutePaths } from '../../../routes/routes';

// components
import { Space, Spin } from 'antd';

const SessionActivate = () => {
  const jwt = useQueryParam({
    param: 'jwt',
    noParamRedirect: RoutePaths.Auth_SignIn,
  });
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const activateSession = async () => {
      try {
        const response = await userAPI.activateSession(jwt);

        if (response.code === '2FA_required') {
          const default2faOption = response.options.find((e) => e.default);

          if (default2faOption) {
            dispatch(
              setTwoFactorVerificationData({
                temporaryToken: response.jwt,
                type: default2faOption.code,
                options: response.options,
              }),
            );

            history.push(RoutePaths.Auth_TwoFactorAuthentication);
          }
        }
      } catch (error) {
        history.push(RoutePaths.Auth_SignIn);
        throw error;
      }
    };

    if (jwt) {
      activateSession();
    }
  }, [jwt]);

  return (
    <ContentWrapper>
      <Space size="middle">
        <Spin size="large" />
      </Space>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export default SessionActivate;
