import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import { journalsAPI } from '../../../../../../../../../api/finance/journal/journalsAPI';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { JournalAccountModel } from '../../../../../../../../../typings/finance/journal';

// components
import { Popover, Spin, Typography } from 'antd';

const { Link } = Typography;

interface IProps {
  journalEntryId: number;
  currentAccountNumber: string;
}

const OtherAccountsPopover = ({
  journalEntryId,
  currentAccountNumber,
}: IProps) => {
  const { t } = useTranslation('finance');

  const [isLoading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<JournalAccountModel[] | null>(null);

  const renderPopoverContent = (loading: boolean) => (
    <Spin spinning={loading}>
      {accounts && !accounts.length
        ? t('accounts.table.no_other_accounts')
        : !!accounts?.length && renderAccounts(accounts)}
    </Spin>
  );

  const renderAccounts = (accounts: JournalAccountModel[]) =>
    accounts.map(({ accountName, prefix, intermediateHomeBalance }, i) => (
      <div
        key={i}
      >{`${accountName} - ${prefix} ${AccountsHelpers.convertAmountBigIntToLocaleString(intermediateHomeBalance)}`}</div>
    ));

  const handleVisibleChange = async () => {
    if (isLoading || accounts) return;

    setLoading(true);

    try {
      const response =
        await journalsAPI.fetchAccountsByJournalId(journalEntryId);
      const filteredAccounts =
        response?.data.filter(
          (e) => e.accountNumber !== currentAccountNumber,
        ) || [];

      setAccounts(filteredAccounts);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Popover
        placement="bottom"
        content={() => renderPopoverContent(isLoading)}
        onVisibleChange={handleVisibleChange}
      >
        <Link>{`-${t('accounts.table.split')}-`}</Link>
      </Popover>
    </>
  );
};

export default OtherAccountsPopover;
