import React, { memo, useMemo } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import { IApprovalMatrixRecord } from 'typings/finance/transaction';
import { ApprovalWorkflowInstanceModel } from 'typings/approval/approvalWorkflow';
import {
  ClientTransactionTypes,
  TransactionReviewStatuses,
} from '../../../../../../../enums/banking/transactions';

// components
import ApprovalMatrix from 'components/Tables/ApprovalsMatrix';
import { Divider } from 'antd';
import {
  default as InternalTransactionSender,
  InternalTransactionSenderData,
} from 'components/Additional/DetailsSections/Accounting/InternalTransaction/Sender';
import {
  default as WireTransactionSender,
  WireTransactionSenderData,
} from 'components/Additional/DetailsSections/Accounting/WireTransaction/Sender';

export type TransactionTypes = 'internal-sender' | 'wire-sender';

export type TransactionData = {
  transactionId: number | null;
  journalEntryId: number | null;

  type: TransactionTypes | null;
  sourceType: ClientTransactionTypes;
  requestorId: string;
  transactionStatus: TransactionReviewStatuses;
  approvalWorkflowId: string | null;
  transactionApprovals: IApprovalMatrixRecord[][] | null;
  transaction: InternalTransactionSenderData | WireTransactionSenderData | null;
};

interface IProps {
  transaction: TransactionData;
  workflow: ApprovalWorkflowInstanceModel;
}

const InnerContent = memo(({ transaction, workflow }: IProps) => {
  const { t } = useTranslation('banking');
  const [hasTransactionApprovalMatrixAccess] = useUserAccess([
    'CRM_Transaction_Approval_Matrix_View',
  ]);

  const formattedWorkflow = useMemo(() => {
    return workflow.approvalProgressStateSets.map((e) => e.progressStateItems);
  }, [workflow]);

  const content = useMemo(() => {
    switch (transaction.type) {
      case 'internal-sender':
        return (
          <InternalTransactionSender
            transaction={
              transaction.transaction as InternalTransactionSenderData
            }
            journalEntryId={transaction.journalEntryId || null}
          />
        );

      case 'wire-sender':
        return (
          <WireTransactionSender
            transaction={transaction.transaction as WireTransactionSenderData}
            journalEntryId={transaction.journalEntryId || null}
          />
        );

      default:
        return null;
    }
  }, [transaction]);

  return (
    <>
      {content}
      <Divider orientation="left">
        {t('transactions.transaction_details.approval_section')}
      </Divider>
      {hasTransactionApprovalMatrixAccess && (
        <ApprovalMatrix transactionApprovals={formattedWorkflow} />
      )}
    </>
  );
});

export default InnerContent;
