import React from 'react';

// helpers utils
import { StateModel } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouteModel, SubRouteModel } from '../../typings/routes';
import { StateModel as UserAccessStateModel } from '../../redux/reducers/userAccess';

// constants
import { PERMISSION_KEYS } from '../../config/permissions';

interface IProps {
  routes: RouteModel[];
}

// This component are responsible for rendering all, and check if the component has the permission to be rendered
// In this case if the page has permission we should check if the user has this permission
// if the user has permission we can render otherwise we should skip rendering
const RouteRenderer = ({ routes }: IProps) => {
  const { permissions } = useSelector<StateModel, UserAccessStateModel>(
    (state) => state.userAccess,
  );

  // Check route permissions
  // If the route has permissions -> check if user has such permissions
  const canRenderRoute = (route: RouteModel | SubRouteModel) => {
    if (!route.permission?.permissionKey) {
      return true;
    }

    return permissions[route.permission.permissionKey as PERMISSION_KEYS]
      .isAllowed;
  };

  const createRoute = (route: SubRouteModel): any => {
    const canRender = canRenderRoute(route);

    if (!canRender) {
      return null;
    }

    const routeNode = route.layout ? (
      <Route
        exact
        key={`${route.path}-${route.title}`}
        path={route.path as string}
        render={(routeProps) => (
          <route.layout {...routeProps} route={route}>
            <route.component {...routeProps} route={route} />
          </route.layout>
        )}
      />
    ) : (
      <Route
        key={`${route.path}-${route.title}`}
        path={route.path as string}
        render={(renderProps) => (
          <route.component {...renderProps} route={route} />
        )}
      />
    );

    return (
      <>
        {routeNode}
        {!!route.childRoutes?.length && renderRoutes(route.childRoutes)}
      </>
    );
  };

  const renderRoutes = (routesArray: SubRouteModel[]) =>
    routesArray.map((route) => createRoute(route));

  return (
    <Switch>
      {routes.map((route, index) => {
        const canRender = canRenderRoute(route);

        if (!canRender) return null;

        return (
          <Route
            key={index}
            path={route.path as string}
            render={(props) => (
              <route.layout {...props} route={route}>
                {renderRoutes(route.routes)}
              </route.layout>
            )}
          />
        );
      })}

      <Redirect to="/" />
    </Switch>
  );
};

export default RouteRenderer;
