import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { ClientReviewStatus } from '../../../../../enums/crm/crm';

// components
import ReviewStatusSelect from '../../../../../components/Forms/FormComponents/SelectInputs/ReviewStatusSelect';
import ClientGroupStatusSelect from '../../../../../components/Forms/FormComponents/SelectInputs/ClientGroupStatusSelect';
import { SearchInput } from 'components/Fields/SearchInput';
import { Row, Col, Typography } from 'antd';

interface IProps {
  status: string;
  reviewStatus: string;
  reviewStatuses: ClientReviewStatus[];
  onFilterChange: (key: string, value: any) => void;
}

const FilterBar = ({
  status,
  reviewStatus,
  reviewStatuses,
  onFilterChange,
}: IProps) => {
  const { t } = useTranslation('crm');

  const gridSizes = { xl: 6, lg: 8, md: 12, sm: 24, xs: 24 };

  return (
    <Row gutter={[16, 16]}>
      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('client_groups.search_client_groups')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <SearchInput
              size="large"
              placeholder={t('client_groups.search_client_groups_placeholder')}
              onSearch={(searchQuery: string) =>
                onFilterChange('search', searchQuery)
              }
            />
          </Col>
        </Row>
      </Col>

      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('client_group.filter_by_status')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <ClientGroupStatusSelect
              value={status}
              onChange={(value) => onFilterChange('filter_by_status', value)}
            />
          </Col>
        </Row>
      </Col>

      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('client_group.filter_by_review_status')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <ReviewStatusSelect
              value={reviewStatus}
              reviewStatuses={reviewStatuses}
              onChange={(value) =>
                onFilterChange('filter_by_review_status', value)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FilterBar;
