import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { IDocumentForm } from 'components/Forms/FormComponents/AdditionalFieldNew';
import { FetchResponseModel } from '../../../typings/common';
import { onboardingAnswerAdapter } from '../../../apiAdapters/crm/onboarding/onboardingAnswerAdapter';
import { IOnboardingAnswerResponse } from '../../../typings/crm/onboardingAnswer';
import {
  AdditionalFieldType,
  OnboardType,
  RelationshipRelatedTypes,
} from '../../../enums/crm/crm';

export interface ISetOnboardingAnswersBody {
  clientGroupId: string;
  itemId: string;
  itemType: string;
  fields: {
    id?: string;
    isDeleted?: boolean;
    keyRelationshipTemplateId: string;
    key: string;
    description?: string;
    type: string;
    value: any;
  }[];
}

export interface AnswerModel {
  _id: string;
  itemId: string;
  itemType: OnboardType;
  clientGroupId: string;

  answer: null | {
    details: { providedBy: { id: string; name: string } };
    isProvided: boolean;
    valueJSON: string;
    reason: string;
  };

  review: null | {
    isValid: boolean;
    comment: string;
  };

  status:
    | 'pending'
    | 'submitted'
    | 'in-review'
    | 'approved'
    | 'rejected'
    | 'finalized';

  field: {
    type: AdditionalFieldType;
    name: string;
    relatedTo: RelationshipRelatedTypes;
    isTemplateField: boolean;
    placeholder: string;
    description: string;
    options: {
      selectOptions: { valueJSON: string; type: string }[];
      selectOptionsValues: { valueJSON: string; type: string }[];
      onboardingRequired: boolean;
      formDocument: IDocumentForm | null;
      documentRequiresCertification: boolean;
      isForRegulatedOnly: boolean;
    };
  };

  sources: {
    type: 'relationship' | 'client' | 'admin';
    id: string;
    templateId: string;
  }[];
}

export interface ReviewApplicationDocumentationBodyModel {
  clientGroupId?: string;
  clientChangeRequestId?: string;
  itemId: string;
  itemType: OnboardType;
  operations: AnswerOperation[];
}

export type AnswerOperation =
  | CreateAnswerOperation
  | UpdateAnswerOperation
  | ReviewAnswerOperation
  | RemoveAnswerOperation
  | FillAnswerOperation;

export type Answer = {
  isProvided: boolean;
  valueJSON?: string;
  reason?: string;
};

export type Review = {
  isValid: boolean;
  comment?: string;
};

export type Field = {
  type: AdditionalFieldType | null;
  name: string;
  description: string;
  placeholder?: string;
  options?: {
    documentRequiresCertification?: boolean;
    selectOptions?: { valueJSON: string; type: string }[];
    formDocumentId?: string;
  };
};

export type CreateAnswerOperation = {
  type: 'create';
  payload: {
    field: Field;
    answer: Answer | null;
  };
};

export type UpdateAnswerOperation = {
  type: 'update';
  payload: {
    id: string;
    field: Field;
    answer: Answer | null;
  };
};

export type FillAnswerOperation = {
  type: 'answer';
  payload: {
    id: string;
    answer: Answer | null;
  };
};

export type ReviewAnswerOperation = {
  type: 'review';
  payload: {
    id: string;
    review: Review | null;
  };
};

export type RemoveAnswerOperation = {
  type: 'remove';
  payload: {
    id: string;
  };
};

export interface FetchOnboardingAnswersResponseModel {
  admin: FetchResponseModel<AnswerModel>;
  client: FetchResponseModel<AnswerModel>;
  clientChangeRequest?: FetchResponseModel<AnswerModel>;
  relationship_keyRelationshipTemplate?: FetchResponseModel<AnswerModel>;
}

export interface UpdateRelationshipsResponseModel {
  operations: {
    type: string;
    result: AnswerModel;
  }[];
}

export const onboardingAnswerAPI = {
  fetchLatestOnboardingAnswers: (params?: {
    itemId?: string;
    clientGroupId?: string;
  }) => {
    return APIService.get(`${APIConfig.crmApi}/onboarding/answers`, {
      params,
    }).then<IOnboardingAnswerResponse>((res) =>
      onboardingAnswerAdapter.fetchLatestOnboardingAnswers(res.data),
    );
  },

  fetchApplicationDocumentationStepAnswers: (
    crmItemId: string,
    clientGroupId?: string,
  ) => {
    return APIService.get<FetchOnboardingAnswersResponseModel>(
      `${APIConfig.crmApi}/client-documentation-items/${crmItemId}/grouped`,
      {
        params: { clientGroupId, limit: 100, page: 1 },
      },
    ).then(({ data }) => data);
  },

  fetchContactKYCFormVerificationDocuments: (
    crmItemId: string,
    clientChangeRequestId: string,
  ) => {
    return APIService.get<FetchOnboardingAnswersResponseModel>(
      `${APIConfig.crmApi}/client-documentation-items/${crmItemId}/grouped`,
      {
        params: { clientChangeRequestId, limit: 100, page: 1 },
      },
    ).then(({ data }) => data);
  },

  reviewApplicationDocumentation: (
    body: ReviewApplicationDocumentationBodyModel,
  ) => {
    return APIService.patch<UpdateRelationshipsResponseModel>(
      `${APIConfig.crmApi}/client-documentation-items/batch`,
      body,
    ).then(({ data }) => data);
  },

  updateAnswerOfAdditionalField: (
    body: ReviewApplicationDocumentationBodyModel,
  ) => {
    return APIService.patch<UpdateRelationshipsResponseModel>(
      `${APIConfig.crmApi}/client-documentation-items/batch`,
      body,
    ).then(({ data }) => data);
  },

  requireAdditionalInfoForClientGroup: (clientGroupId: string) => {
    return APIService.post<UpdateRelationshipsResponseModel>(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/require-additional-info`,
    );
  },
};
