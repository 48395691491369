import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';

// components
import { Typography } from 'antd';

const Default2FALabel = () => {
  const { t } = useTranslation('common');
  return <StyledText>{`(${t('default')})`}</StyledText>;
};

const StyledText = styled(Typography.Text)`
  margin-left: ${({ theme }) => theme.marginXXs};
  color: ${({ theme }) => theme.primaryGreen};
`;

export default Default2FALabel;
