import React from 'react';

// helpers
import { clientGroupsAPI } from '../../../../../../api/crm/clientGroup/clientGroupsAPI';
import { FormFieldPropsModel } from '@core_components/FormField';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import Autocomplete, {
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';

type Props = FormFieldPropsModel;

const ClientGroupRequestsAutocomplete = (props: Props) => {
  const fetchClientGroups = async (searchQuery: string) => {
    const { data } = await clientGroupsAPI.searchClientGroups({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      search: searchQuery,
    });
    return data.data.map<AutocompleteOption>((clientGroup) => {
      return {
        id: clientGroup._id,
        content: clientGroup.clientGroupName,
        model: clientGroup,
      };
    });
  };

  return <Autocomplete {...props} fetchData={fetchClientGroups} />;
};

export default ClientGroupRequestsAutocomplete;
