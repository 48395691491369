import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { enumToMap } from '../../../../../helpers/utils';
import { JournalTypes } from '../../../../../enums/finance/finance';

// components
import { Select } from 'antd';

interface IProps {
  onChange: (value: string) => void;
}

const TypeSelect = ({ onChange }: IProps) => {
  const { t } = useTranslation('finance');
  const [privateValue, setPrivateValue] = useState('');

  const renderOptions = () => {
    const genderOptions = Array.from(enumToMap(JournalTypes));

    return genderOptions.map((el) => (
      <Select.Option key={el[1]} value={el[1]}>
        {t(`journals.table.journal_types.${el[1]}`)}
      </Select.Option>
    ));
  };

  const handleSelectChange = (value: any) => {
    setPrivateValue(value);
    onChange(value);
  };

  return (
    <StyledSelect
      allowClear
      showArrow
      showSearch
      size="large"
      value={privateValue || undefined}
      onChange={handleSelectChange}
      placeholder={t('journals.table.filter_by_type_placeholder')}
    >
      {renderOptions()}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default TypeSelect;
