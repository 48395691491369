import { PermissionedAccessModel } from '.';

// Permission keys
export type BankingPermissionKeys =
  | 'Banking_View'
  | 'Banking_FeeSchedule_View'
  | 'Banking_FeeSchedule_FeeScheduleSection_View'
  | 'Banking_FeeSchedule_FeeScheduleSection_Edit'
  | 'Banking_FeeSchedule_SpreadMatrixSection_View'
  | 'Banking_FeeSchedule_SpreadMatrixSection_Edit'
  | 'Banking_CorrespondentBanks_View'
  | 'Banking_CorrespondentBanks_Details_View'
  | 'Banking_CorrespondentBanks_Details_Currencies_Update'
  | 'Banking_CorrespondentBanks_Details_CorrespondentClientsTab_View'
  | 'Banking_CorrespondentBanks_Details_CorrespondentAccountsTab_View'
  | 'Banking_BankAccounts_View'
  | 'Banking_BankAccount_Update'
  | 'Banking_Transactions_View'
  | 'Banking_Transactions_Create'
  | 'Banking_Transactions_SendToRDC'
  | 'Banking_Transactions_Return'
  | 'Banking_Transactions_Reject'
  | 'Banking_Transactions_Transmit'
  | 'Banking_Transactions_Update'
  | 'Banking_Transactions_Submit';

// Permission config
export const BankingPermissions: Record<
  BankingPermissionKeys,
  PermissionedAccessModel
> = {
  Banking_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Accounting:FeeSchedule:Read' },
      { permissionKey: 'Accounting:SpreadMatrix:Read' },
      { permissionKey: 'Accounting:CorrespondentBank:Read' },
      { permissionKey: 'Accounting:BankingAccount:Read' },
      { permissionKey: 'Accounting:BankingTransaction:Read' },
    ],
  },

  Banking_FeeSchedule_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Accounting:FeeSchedule:Read' },
      { permissionKey: 'Accounting:SpreadMatrix:Read' },
    ],
  },

  Banking_FeeSchedule_FeeScheduleSection_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:FeeSchedule:Read' }],
  },

  Banking_FeeSchedule_FeeScheduleSection_Edit: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:FeeSchedule:Update' }],
  },

  Banking_FeeSchedule_SpreadMatrixSection_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:SpreadMatrix:Read' }],
  },

  Banking_FeeSchedule_SpreadMatrixSection_Edit: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:SpreadMatrix:Update' }],
  },

  Banking_CorrespondentBanks_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:CorrespondentBank:Read' }],
  },

  Banking_CorrespondentBanks_Details_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:CorrespondentBank:Read' }],
  },

  Banking_CorrespondentBanks_Details_Currencies_Update: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:CorrespondentBank:Update' }],
  },

  Banking_CorrespondentBanks_Details_CorrespondentAccountsTab_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:CorrespondentBank:Read' }],
  },

  Banking_CorrespondentBanks_Details_CorrespondentClientsTab_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:CorrespondentBank:Read' }],
  },

  Banking_BankAccounts_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:BankingAccount:Read' }],
  },

  Banking_BankAccount_Update: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:BankingAccount:Update' }],
  },

  Banking_Transactions_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:BankingTransaction:Read' }],
  },

  Banking_Transactions_Create: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:BankingTransaction:Create' },
      { permissionKey: 'Approval:FinanceWorkflow:Create' },
      { permissionKey: 'CRM:ClientGroup:Read' },
    ],
  },

  Banking_Transactions_Update: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:BankingTransaction:Update' },
      { permissionKey: 'Approval:FinanceWorkflow:Create' },
      { permissionKey: 'CRM:ClientGroup:Read' },
    ],
  },

  Banking_Transactions_SendToRDC: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:BankingTransaction:StartRDC' },
      { permissionKey: 'CRM:ClientGroup:Read' },
    ],
  },

  Banking_Transactions_Return: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:BankingTransaction:Return' },
      { permissionKey: 'Approval:FinanceWorkflow:Create' },
      { permissionKey: 'CRM:ClientGroup:Read' },
    ],
  },

  Banking_Transactions_Reject: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:BankingTransaction:Reject' }],
  },

  Banking_Transactions_Transmit: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:BankingTransaction:Transmit' }],
  },

  Banking_Transactions_Submit: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:BankingTransaction:Submit' },
      { permissionKey: 'Approval:FinanceWorkflow:Create' },
      { permissionKey: 'CRM:ClientGroup:Read' },
    ],
  },
};
