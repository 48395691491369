import React, { memo, useCallback, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { OnboardType } from 'enums/crm/crm';
import { generateUniqId } from 'helpers/utils';
import { ContactHelpers } from '@helpers/crm/contact';
import { FormValuesModel } from '../..';

// components
import AddButton from '../../../../../../DesignSystem/Common/Buttons/AddButton';
import SectionIntro from '../../../../../../DesignSystem/Common/Texts/SectionIntro';
import FormErrorTooltip from '../../../../../../Forms/FormErrorTooltip';
import HideIfDisabledForm from '../../../../../../Forms/HideIfDisabledForm';
import ClientRelationshipForm, {
  FormValuesModel as RelationshipFormValuesModel,
} from 'components/Forms/TemplateForms/CRM/ClientRelationshipForm';
import { Card } from 'antd';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';

const RelationshipsSection = memo(() => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  const relationshipFromValue = useMemo(() => {
    let result = '';

    if (values.template.visibleFor) {
      if (values.template.visibleFor === OnboardType.Contact) {
        result = ContactHelpers.getContactName({
          firstName: values.contact?.firstName || '',
          lastName: values.contact?.lastName || '',
          middleName: values.contact?.middleName,
        });
      } else {
        result = values.organization?.name || '';
      }
    }

    return result;
  }, [values.contact, values.organization, values.template]);

  const selectedRelationshipTemplates = useMemo<
    Record<string, string[]>
  >(() => {
    return values.relationships.reduce<Record<string, string[]>>(
      (acc, next) => {
        if (next.relationshipTemplate.id && next.child.id) {
          if (acc[next.child.id] && acc[next.child.id].length) {
            acc[next.child.id].push(next.relationshipTemplate.id);
          } else {
            acc[next.child.id] = [next.relationshipTemplate.id];
          }
        }

        return acc;
      },
      {},
    );
  }, [values.relationships]);

  const renderRelationships = useCallback(
    (fieldArrayRenderProps: FieldArrayRenderProps) => {
      function getNewRelationship(): RelationshipFormValuesModel {
        return {
          customId: generateUniqId(),
          id: null,

          relationshipTemplate: {
            id: null,
            childRelationshipLabel: '',
          },

          parent: {
            id: '',
            type: values.template.visibleFor,
          },

          child: {
            id: null,
            type: null,
            label: '',
          },

          additionalFields: [],
        };
      }

      return (
        <>
          {values.relationships.map((rel, i) => (
            <StyledCard key={rel.customId}>
              <ClientRelationshipForm
                fieldName={`relationships.${i}`}
                removeCallback={() => fieldArrayRenderProps.remove(i)}
                relationshipIndex={i + 1}
                relationshipScopeId={values.activeApplicationScopeId}
                entryId={values.clientGroupId}
                relationshipFromValue={relationshipFromValue}
                entryType={values.clientGroupEntryType}
              />
            </StyledCard>
          ))}

          <HideIfDisabledForm hideIfSubmitting>
            <AddButton
              onClick={() => fieldArrayRenderProps.push(getNewRelationship())}
            >
              {t(
                'client_group.structure.node_modal.relationships.add_new_button',
              )}
            </AddButton>
          </HideIfDisabledForm>
        </>
      );
    },
    [values, selectedRelationshipTemplates, relationshipFromValue],
  );

  return (
    <>
      <SectionIntro
        title={t(
          'client_group.structure.node_modal.relationships.section_title',
        )}
        titleVariant="h4"
      />
      <StyledErrorWrapper>
        <FormErrorTooltip<FormValuesModel> errorKey="relationships" />
      </StyledErrorWrapper>
      <FieldArray name="relationships" render={renderRelationships} />
    </>
  );
});

const StyledCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const StyledErrorWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default RelationshipsSection;
