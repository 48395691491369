import { ISessionInfoGeolocation } from 'api/auth/userAPI';

// constants
const USER_SESSION_DATA = 'USER_SESSION_DATA';

export type SessionFromSessionStorage = {
  geolocation?: ISessionInfoGeolocation;
};

const SessionStorageHelper = {
  setUserSession: function (session: SessionFromSessionStorage) {
    sessionStorage.setItem(USER_SESSION_DATA, JSON.stringify(session));
  },

  getUserSession: function (): SessionFromSessionStorage | null {
    let result: SessionFromSessionStorage | null = null;
    const session = sessionStorage.getItem(USER_SESSION_DATA);

    if (session) {
      const parsedSession = JSON.parse(session);
      result =
        parsedSession && typeof parsedSession === 'object'
          ? (parsedSession as SessionFromSessionStorage)
          : null;
    }

    return result;
  },

  clearUserSession: () => {
    sessionStorage.removeItem(USER_SESSION_DATA);
  },
};

export { SessionStorageHelper };
