import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { IncomingTransferReportItem } from 'api/reporting/reportingAPI';

// components
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel<IncomingTransferReportItem>;

const IncomingTransfersTable = (props: IProps) => {
  const { t } = useTranslation('reporting');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: 200,
        title: t('threshold_reporting.incoming_transfers.table.date'),
        key: 'date',
        render: (record: IncomingTransferReportItem) =>
          DateHelpers.formatTimestampToString(record.valueDate),
      },

      {
        width: 200,
        title: t('threshold_reporting.incoming_transfers.table.reference'),
        key: 'reference',
        render: (record: IncomingTransferReportItem) =>
          record.transactionReferenceNumber,
      },

      {
        width: 170,
        title: t('threshold_reporting.incoming_transfers.table.currency'),
        key: 'currency',
        render: (record: IncomingTransferReportItem) => record.currency,
      },

      {
        width: 200,
        title: t('threshold_reporting.incoming_transfers.table.amount'),
        key: 'amount',
        render: (record: IncomingTransferReportItem) =>
          AccountsHelpers.convertAmountBigIntToLocaleString(record.amount),
      },

      {
        width: 200,
        title: t('threshold_reporting.incoming_transfers.table.amount_home'),
        key: 'amount_home',
        render: (record: IncomingTransferReportItem) =>
          AccountsHelpers.convertAmountBigIntToLocaleString(record.amountBase),
      },

      {
        width: 250,
        title: t(
          'threshold_reporting.incoming_transfers.table.originator_name',
        ),
        key: 'originator_name',
        render: (record: IncomingTransferReportItem) => (
          <EllipsisTooltip
            title={record.orderingCustomerAccountName}
            maxTextContainerWidth="250px"
          >
            {record.orderingCustomerAccountName}
          </EllipsisTooltip>
        ),
      },

      {
        width: 250,
        title: t(
          'threshold_reporting.incoming_transfers.table.originator_number',
        ),
        key: 'originator_number',
        render: (record: IncomingTransferReportItem) => (
          <EllipsisTooltip
            title={record.orderingCustomerAccountNumber}
            maxTextContainerWidth="250px"
          >
            {record.orderingCustomerAccountNumber}
          </EllipsisTooltip>
        ),
      },

      {
        width: 250,
        title: t(
          'threshold_reporting.incoming_transfers.table.originator_bank',
        ),
        key: 'originator_bank',
        render: (record: IncomingTransferReportItem) => (
          <EllipsisTooltip
            title={record.orderingInstitution.bankName}
            maxTextContainerWidth="250px"
          >
            {record.orderingInstitution.bankName}
          </EllipsisTooltip>
        ),
      },

      {
        width: 250,
        title: t(
          'threshold_reporting.incoming_transfers.table.originator_bank_address',
        ),
        key: 'originator_bank_address',
        render: (record: IncomingTransferReportItem) => (
          <EllipsisTooltip
            title={record.orderingInstitution.bankAddress}
            maxTextContainerWidth="250px"
          >
            {record.orderingInstitution.bankAddress}
          </EllipsisTooltip>
        ),
      },

      {
        width: 200,
        title: t(
          'threshold_reporting.incoming_transfers.table.originator_bank_code',
        ),
        key: 'originator_bank_code',
        render: (record: IncomingTransferReportItem) =>
          record.orderingInstitution.bankCode,
      },

      {
        width: 200,
        title: t(
          'threshold_reporting.incoming_transfers.table.beneficiary_name',
        ),
        key: 'beneficiary_name',
        render: (record: IncomingTransferReportItem) =>
          record.beneficiaryCustomerAccountName,
      },

      {
        width: 200,
        title: t(
          'threshold_reporting.incoming_transfers.table.beneficiary_number',
        ),
        key: 'beneficiary_number',
        render: (record: IncomingTransferReportItem) =>
          record.beneficiaryCustomerAccountNumber,
      },

      {
        title: t(
          'threshold_reporting.incoming_transfers.table.additional_info',
        ),
        key: 'additional_info',
        render: (record: IncomingTransferReportItem) => (
          <EllipsisTooltip
            title={record.otherDetails}
            maxTextContainerWidth="250px"
          >
            {record.otherDetails}
          </EllipsisTooltip>
        ),
      },
    ];

    return result;
  }, [t]);

  return <Table {...props} columns={columns} />;
};

export default IncomingTransfersTable;
