import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from '../../../../hooks/useUserAccess';
import useTranslation from '../../../../hooks/useTranslation';
import { correspondentBanksAPI } from '../../../../api/banking/correspondentBanksAPI';
import { CorrespondentBankModel } from '../../../../typings/banking/correspondentBanks';

// components
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';
import UpdateTransactCurrencyModalDialog, {
  FormValuesModel,
} from '../../../../components/Modals/TemplateModalDialogs/Banking/UpdateTransactCurrencyModalDialog';
import { CardWrapper } from '../../../../layouts/PageLayout';
import { Button, message } from 'antd';

interface IProps {
  correspondentBank: CorrespondentBankModel;
}

const CorrespondentBankInfo = ({ correspondentBank }: IProps) => {
  const { t } = useTranslation(['banking', 'common']);
  const [hasUpdateCurrencyAccess] = useUserAccess([
    'Banking_CorrespondentBanks_Details_Currencies_Update',
  ]);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const initialCurrencyValues = useMemo<FormValuesModel>(
    () => ({
      currencies: correspondentBank.transactCurrencies.map(
        (currency) => currency.id,
      ),
      currenciesInitialValue: correspondentBank.transactCurrencies.map(
        (currency) => ({ id: currency.id, content: currency.isoCode }),
      ),
    }),
    [correspondentBank],
  );

  const handleUpdateCurrency = async (values: FormValuesModel) => {
    await correspondentBanksAPI.updateTransactCurrencies(
      { currencies: values.currencies },
      correspondentBank.id,
    );
    message.success(
      t(
        'correspondent_banks.details.details_section.update_currency.success_message',
      ),
    );
    setOpenUpdateModal(false);
  };

  const descriptionsData = useMemo<DescriptionItem[]>(() => {
    const accountCurrencies = correspondentBank.holdCurrencies
      .map((e) => e.isoCode)
      .join(', ');
    const transactionCurrencies = correspondentBank.transactCurrencies
      .map((e) => e.isoCode)
      .join(', ');

    return [
      {
        label: t('correspondent_banks.details.details_section.name'),
        description: correspondentBank.name,
      },
      {
        label: t(
          'correspondent_banks.details.details_section.account_currencies',
        ),
        description: accountCurrencies,
      },
      {
        label: t(
          'correspondent_banks.details.details_section.transaction_currencies',
        ),
        description: (
          <StyledBox>
            {transactionCurrencies}
            {hasUpdateCurrencyAccess ? (
              <Button onClick={() => setOpenUpdateModal(true)}>
                {t('update', { ns: 'common' })}
              </Button>
            ) : undefined}
          </StyledBox>
        ),
      },
      {
        label: t('correspondent_banks.details.details_section.type'),
        description: correspondentBank.type,
      },
      {
        label: t('correspondent_banks.details.details_section.processing_type'),
        description: correspondentBank.processingType,
      },
    ];
  }, [correspondentBank, hasUpdateCurrencyAccess]);

  return (
    <CardWrapper>
      <StyledDescriptionSection
        data={descriptionsData}
        bordered={false}
        background="transparent"
      />
      <UpdateTransactCurrencyModalDialog
        closeCallback={() => setOpenUpdateModal(false)}
        initialValues={initialCurrencyValues}
        isVisible={openUpdateModal}
        onSubmit={handleUpdateCurrency}
      />
    </CardWrapper>
  );
};

const StyledBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-container {
    border-bottom: 1px solid ${({ theme }) => theme.tableBorderColor};
    padding-bottom: ${({ theme }) => theme.paddingXs};
  }

  .ant-descriptions-item-label {
    display: flex;
    align-items: end;
  }

  &.ant-descriptions-middle .ant-descriptions-row > td {
    padding-bottom: ${({ theme }) => theme.paddingXs};
  }
`;

export default CorrespondentBankInfo;
