import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { EntityHelpers } from '@helpers/crm/entity';
import { ContactHelpers } from '@helpers/crm/contact';
import { relationshipAPI } from 'api/crm/relationship/relationshipAPI';
import { relationshipAdapter } from 'apiAdapters/crm/relationship/relationshipAdapter';
import { AdditionalFieldHelpers } from '@helpers/additionalField';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';
import { AssociateTypes, RelationshipRelatedTypes } from 'enums/crm/crm';
import { IAdditionalFieldAnswer, IRelationshipModel } from 'typings/crm/entity';

// components
import EditRelationshipModalDialog, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/EditRelationshipModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  relationship: IRelationshipModel | null;
}

const ViewEditRelationshipModal = memo(
  ({ isVisible, closeCallback, relationship }: IProps) => {
    const { t } = useTranslation('crm');

    const initialFormValues: FormValuesModel | null = useMemo(() => {
      if (!isVisible || !relationship) {
        return null;
      }

      function formatAdditionalFields(
        initialData: IRelationshipModel,
      ): IAdditionalFieldAnswer[] {
        if (
          !initialData.relationshipTemplate ||
          !initialData.additionalFields
        ) {
          return [];
        }

        const { relationshipTemplate, additionalFields } = initialData;

        const result =
          AdditionalFieldHelpers.filterAndMapAdditionalFieldsFromTemplateToFormModel(
            relationshipTemplate,
            RelationshipRelatedTypes.Organization,
            additionalFields,
          );

        return result;
      }

      function getEntryName(type: AssociateTypes, entry: any) {
        let result = '';

        if (type === AssociateTypes.Contact) {
          result = ContactHelpers.getContactName(entry[type]);
        } else if (type === AssociateTypes.Organization) {
          result = EntityHelpers.getEntityName(entry[type]);
        }

        return result;
      }

      const additionalFields = formatAdditionalFields(relationship);

      const initialRelationshipEntry = {
        id: relationship._id,
        relationshipTemplate: {
          id: relationship.relationshipTemplateId,
          childRelationshipLabel: relationship.relationshipTemplate.childName,
        },

        parent: {
          id: relationship.parent.id,
          type: relationship.parent.type,
        },

        child: {
          id: relationship.child.id,
          type: relationship.child.type,
          label: getEntryName(relationship.child.type, relationship.child),
          autocompleteInitialValue: {
            id: relationship.child.id,
            content: getEntryName(relationship.child.type, relationship.child),
          },
        },

        additionalFields,
      };

      return {
        entryId: relationship.parent.id,
        entryName: getEntryName(relationship.parent.type, relationship.parent),
        entryType: relationship.parent.type,
        relationship: initialRelationshipEntry,
      };
    }, [relationship, isVisible]);

    const handleSubmit = async (values: FormValuesModel) => {
      if (values.delete) {
        await relationshipAPI.deleteRelationshipById(
          values.relationship.id as string,
        );
        message.success(t('entity.relationships.deleteSuccess'));
      } else {
        const formattedReqBody =
          relationshipAdapter.generateRequestBodyForEditRelationship(values);
        await relationshipAPI.updateRelationshipById(
          values.relationship.id as string,
          formattedReqBody,
        );
        message.success(t('entity.relationships.editSuccess'));
      }
    };

    return (
      <EditRelationshipModalDialog
        title={t('entity.relationships.relationship_details')}
        onSubmit={handleSubmit}
        isVisible={isVisible}
        initialValues={initialFormValues}
        closeCallback={closeCallback}
      />
    );
  },
);

export default ViewEditRelationshipModal;
