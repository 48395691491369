import i18n from '../i18n';

const LocalizationHelpers = {
  changeLanguage: function (lang: string) {
    i18n.changeLanguage(lang);
  },

  getCurrentLanguage: function () {
    return (
      i18n.language ||
      (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
      'en'
    );
  },
};

export { LocalizationHelpers };
