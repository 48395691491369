import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';

// helpers
import { Popover, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const OverridenValueInfoTooltip = () => {
  const { t } = useTranslation('crm');

  return (
    <Popover
      placement="bottom"
      content={
        <StyledTypography>
          {t('client_group.fee_schedule.overridden_value_msg')}
        </StyledTypography>
      }
    >
      <StyledInfoIcon />
    </Popover>
  );
};

const StyledInfoIcon = styled(ExclamationCircleOutlined)`
  cursor: pointer;
  color: ${({ theme }) => theme.warningColor};
  margin-left: ${({ theme }) => theme.marginXXs};
`;

const StyledTypography = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
`;

export default OverridenValueInfoTooltip;
