import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { StateModel } from '../../../../redux/reducers';
import { approvalAPI } from '../../../../api/crm/onboarding/approvalAPI';
import { useSelector } from 'react-redux';
import { approvalWorkflowAPI } from '../../../../api/approval/approvalManagement/approvalWorkflowAPI';
import {
  ApprovalStatuses,
  ApprovalWorkflowStatuses,
} from '../../../../enums/approvalManagement/workflow';
import {
  ApprovalProgressMemberModel,
  ApprovalWorkflowInstanceModel,
} from '../../../../typings/approval/approvalWorkflow';

// components
import WorkflowActions from '../WorkflowActions';
import ApprovalsMatrix from '../ApprovalsMatrix';
import { Space, Spin, Typography } from 'antd';

interface IComplianceWorkflowStatusProps {
  workflowId: string;
  workflow?: ApprovalWorkflowInstanceModel;
  hasComplianceInfo: boolean;
  onUpdate: () => void;
}

const ComplianceWorkflowStatus = ({
  workflow,
  workflowId,
  hasComplianceInfo,
  onUpdate,
}: IComplianceWorkflowStatusProps) => {
  const myId = useSelector<StateModel, string>(
    (state) => state.auth.profileData?._id || '',
  );
  const { t } = useTranslation('crm');
  const [updateTrigger, setUpdateTrigger] = useState({});

  const { response, loading } = useFetch(
    () =>
      !workflow
        ? approvalWorkflowAPI.fetchApprovalWorkflowInstanceById(workflowId)
        : null,
    [updateTrigger, workflowId],
  );

  const workflowInstance = workflow || response;

  const myMember = useMemo<ApprovalProgressMemberModel | undefined>(() => {
    let member: ApprovalProgressMemberModel | undefined;

    workflowInstance?.approvalProgressStateSets.forEach((e) => {
      e.progressStateItems.forEach(({ members }) => {
        member = members.find((member) => member.userId === myId) || member;
      });
    });

    return member;
  }, [workflowInstance]);

  if (loading) {
    return (
      <ContentWrapper>
        <Space size="middle">
          <Spin size="large" />
        </Space>
      </ContentWrapper>
    );
  }

  const handleApprove = async (reason: string) => {
    await approvalAPI.approveCompliance(workflowId, reason);
    setUpdateTrigger({});
    onUpdate();
  };

  const handleReject = async (reason: string) => {
    await approvalAPI.rejectCompliance(workflowId, reason);
    setUpdateTrigger({});
    onUpdate();
  };

  const isWorkflowInProgress =
    workflowInstance?.status === ApprovalWorkflowStatuses.PendingApproval;

  const canApprove =
    isWorkflowInProgress &&
    myMember?.approvalAction === ApprovalStatuses.Pending;

  return (
    <>
      {workflowInstance && (
        <ApprovalsMatrix
          approvalProgressStateSets={workflowInstance.approvalProgressStateSets}
        />
      )}
      {canApprove && !hasComplianceInfo && (
        <StyledMessage>
          {t('client_group.workflow.no_compliance_info')}
        </StyledMessage>
      )}
      <WorkflowActions
        canReject={canApprove}
        canApprove={canApprove}
        disabled={!hasComplianceInfo}
        onApprove={handleApprove}
        onReject={handleReject}
      />
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

const StyledMessage = styled(Typography.Text)`
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizeSm};
  color: ${({ theme }) => theme.textLightColor3} !important;
`;

export default ComplianceWorkflowStatus;
