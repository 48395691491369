import React, { useMemo } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { approvalGroupsAPI } from 'api/approval/approvalManagement/approvalGroupsAPI';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';

// components
import ApprovalGroupsModal, {
  ApprovalGroupFormValuesModel,
} from 'components/Modals/TemplateModalDialogs/ApprovalManagement/ApprovalGroupsModal';

interface IProps extends RequiredPropsForModalDialogModel {
  clientGroupId: number;
  approvalGroupId: string | null;
}

const EditApprovalGroupModal = ({
  isVisible,
  clientGroupId,
  approvalGroupId,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('common');

  const { response } = useFetch(() => {
    return approvalGroupId
      ? approvalGroupsAPI.fetchClientTransactionApprovalGroupById(
          clientGroupId,
          approvalGroupId,
        )
      : null;
  }, [clientGroupId, approvalGroupId]);

  const initialFormValues: ApprovalGroupFormValuesModel | null = useMemo(() => {
    let result = null;

    if (response) {
      const { name, description, participants } = response;
      const initialParticipantOptions = participants.map((e) => ({
        id: e.userId,
        content: e.userFullName,
      }));
      result = { name, description, participants, initialParticipantOptions };
    }

    return result;
  }, [response]);

  const handleSubmit = async (values: ApprovalGroupFormValuesModel) => {
    await approvalGroupsAPI.updateApprovalGroup(
      {
        name: values.name,
        description: values.description as string,
        participants: values.participants,
      },
      clientGroupId,
      approvalGroupId as string,
    );
    message.success(
      t(
        'client_group.approval.client_approval_groups.edit_approval_group_modal.success_submit_message',
      ),
    );
  };

  return (
    <ApprovalGroupsModal
      isEditMode
      title={t(
        'client_group.approval.client_approval_groups.edit_approval_group_modal.title',
      )}
      onSubmit={handleSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      loadingInitialData={!initialFormValues}
    />
  );
};

export default EditApprovalGroupModal;
