import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ApprovalGroupTemplateData } from '..';

// components
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

interface IProps {
  data: ApprovalGroupTemplateData;
}

const InnerTemplate = ({ data }: IProps) => {
  const { t } = useTranslation('crm');

  const descriptionsData = useMemo<DescriptionItem[]>(() => {
    return [
      {
        label: t(
          'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.name',
        ),
        description: data.groupName,
      },

      {
        label: t(
          'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.members',
        ),
        description: data.participants,
      },

      {
        label: t(
          'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.description',
        ),
        description: data.description,
      },
    ];
  }, [data]);

  return <DescriptionSection data={descriptionsData} />;
};

export default InnerTemplate;
