import React from 'react';

// helpers
import { IClientGroup } from 'typings/crm/client-group';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';

// components
import ApprovalEntryModalDialog from 'components/Modals/TemplateModalDialogs/CRM/ApprovalEntryModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  workflowId: string | null;
  disabled?: boolean;
  clientGroup: IClientGroup;
}

const ApprovalEntryModal = ({
  workflowId,
  isVisible,
  clientGroup,
  closeCallback,
}: IProps) => {
  return (
    <ApprovalEntryModalDialog
      isVisible={isVisible}
      workflowId={workflowId}
      clientGroup={clientGroup}
      closeCallback={closeCallback}
    />
  );
};

export default ApprovalEntryModal;
