import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { DateHelpers } from '@helpers/date';
import { NotificationMethods } from '../../../../../enums/admin/orac';
import { NotificationsBaseModel } from '../../../../../typings/ORAC/notifications';
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../../constants/global';

// components
import NotificationStatus from 'components/Additional/Statuses/NotificationStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<NotificationsBaseModel> {
  filterStatus: NotificationMethods;
}

const NotificationsTable = memo(({ filterStatus, ...rest }: IProps) => {
  const { t } = useTranslation('orac');
  const history = useHistory();

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('notifications.table.id'),
        key: 'referenceId',
        render: (record: NotificationsBaseModel) => record.referenceId,
        width: 350,
      },
      {
        title: t('notifications.table.date'),
        key: 'createdAt',
        render: (record: NotificationsBaseModel) =>
          DateHelpers.formatDateToString(
            record.createdAt,
            DEFAULT_DATE_TIME_FORMAT,
          ),
      },
      {
        title: t('notifications.table.name'),
        key: 'code',
        width: 350,
        render: (record: NotificationsBaseModel) => (
          <Typography.Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.ORAC_NotificationsDetails,
                search: `?id=${record.id}&type=${filterStatus}`,
              })
            }
          >
            {record.code}
          </Typography.Link>
        ),
      },
      {
        title: t('notifications.table.status'),
        key: 'status',
        render: (record: NotificationsBaseModel) => (
          <NotificationStatus status={record.status} />
        ),
      },
    ];

    return result;
  }, [filterStatus]);

  return <Table {...rest} columns={columns} />;
});

export default NotificationsTable;
