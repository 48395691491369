import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';
import {
  IAdditionalField,
  IRelationshipTemplateModel,
} from '../../../typings/crm/relationship-template';

interface FetchRelationshipTemplatesParamsModel extends FetchParamsModel {
  search?: string;
  keyRelationshipTemplateId?: string;
  childType?: string;
}

export interface CreateRelationshipTemplateBody {
  parentName: string;
  parentType: string;
  childName: string;
  childType: string;
  additionalFields: IAdditionalField[];
  individualAdditionalFields: IAdditionalField[];
}

export interface EditRelationshipTemplateBody {
  parentName: string;
  parentType: string;
  childName: string;
  childType: string;
  additionalFields: IAdditionalField[];
  individualAdditionalFields: IAdditionalField[];
}

const relationshipTemplatesAPI = {
  fetchRelationshipTemplates: (
    params: FetchRelationshipTemplatesParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<IRelationshipTemplateModel>>(
      `${APIConfig.crmApi}/relationship-templates`,
      { params },
    ).then(({ data }) => data);
  },

  createRelationshipTemplate: (body: CreateRelationshipTemplateBody) => {
    return APIService.post<IRelationshipTemplateModel>(
      `${APIConfig.crmApi}/relationship-templates`,
      body,
    ).then(({ data }) => data);
  },

  editRelationshipTemplate: (
    relationshipTemplateId: string,
    body: EditRelationshipTemplateBody,
  ) => {
    return APIService.patch<IRelationshipTemplateModel>(
      `${APIConfig.crmApi}/relationship-templates/${relationshipTemplateId}`,
      body,
    ).then(({ data }) => data);
  },

  deleteRelationshipTemplate: (
    relationshipTemplateId: string,
    reason: string,
  ) => {
    return APIService.delete(
      `${APIConfig.crmApi}/relationship-templates/${relationshipTemplateId}`,
      { data: { reason } },
    );
  },

  fetchAvailableRelationshipTemplates: (params: any) => {
    return APIService.get<FetchResponseModel<IRelationshipTemplateModel>>(
      `${APIConfig.crmApi}/relationship-templates/available`,
      {
        params,
      },
    ).then(({ data }) => data);
  },
};

export { relationshipTemplatesAPI };
