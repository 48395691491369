import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { RoleBaseModel } from '../../../../../typings/ORAC/roles';

// constants
import { DEFAULT_FULL_DATE_FORMAT } from '../../../../../constants/global';

// components
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

interface IProps {
  role: RoleBaseModel;
}

const RoleInfo = ({ role }: IProps) => {
  const { t } = useTranslation('orac');

  const descriptionsData = useMemo<DescriptionItem[]>(
    () => [
      { label: t('roles.view.id'), description: role.id },
      { label: t('roles.view.name'), description: role.name },
      { label: t('roles.view.description'), description: role.description },
      {
        label: t('roles.view.created_at'),
        description: DateHelpers.formatDateToString(
          role.createdAt as string,
          DEFAULT_FULL_DATE_FORMAT,
        ),
      },
      {
        label: t('roles.view.updated_at'),
        description: DateHelpers.formatDateToString(
          role.updatedAt as string,
          DEFAULT_FULL_DATE_FORMAT,
        ),
      },
    ],
    [role],
  );

  return (
    <StyledDescriptionSection
      data={descriptionsData}
      bordered={false}
      background="transparent"
    />
  );
};
const StyledDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-container {
    border-bottom: 1px solid ${({ theme }) => theme.tableBorderColor};
    padding-bottom: ${({ theme }) => theme.paddingXs};
  }

  .ant-descriptions-item-label {
    display: flex;
    align-items: end;
  }

  &.ant-descriptions-middle .ant-descriptions-row > td {
    padding-bottom: ${({ theme }) => theme.paddingXs};
  }
`;

export default RoleInfo;
