import React from 'react';
import styled from 'styled-components';
import { Button, Col, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons/lib';
import { useDispatch } from 'react-redux';
import { changeView } from '../../../redux/actions/view';

interface IProps {
  path: string;
  children?: any;
}

const NavTemplate = ({ children, path }: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const historyPush = (path: any, state?: any) =>
    dispatch(changeView(() => history.push(path, state)));

  return (
    <ButtonWrapper onClick={() => historyPush(path)}>
      <Row justify="space-between">
        <Col>
          <Typography.Text ellipsis style={{ maxWidth: '100%' }}>
            {children}
          </Typography.Text>
        </Col>
        <Col>
          <RightOutlined />
        </Col>
      </Row>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled(Button)`
  width: 100%;
  text-align: left;
  height: ${({ theme }) => theme.heightLg};
  font-size: ${({ theme }) => theme.fontSizeLg};
  padding: 4px ${({ theme }) => theme.paddingXXl} 0
    ${({ theme }) => theme.paddingXXl};
  background-color: ${({ theme }) => theme.textBackgroundColor};
  color: ${({ theme }) => theme.textLightColor2};
  text-transform: none;
  font-weight: 500;

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.textBackgroundColor};
    color: ${({ theme }) => theme.selectColor};
    border-color: ${({ theme }) => theme.selectColor};
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletScreen}) {
    font-size: ${({ theme }) => theme.fontSizeMd};
    height: ${({ theme }) => theme.heightMd};
    padding: 4px ${({ theme }) => theme.paddingMd} 0
      ${({ theme }) => theme.paddingMd};
  }
`;

export default NavTemplate;
