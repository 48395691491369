import { TransactionReviewTarget } from './transactions';
import {
  ComparisonOperators,
  ComplianceReviewTypes,
  ConfigurationRuleState,
  ExpectedDataTypes,
  ExpectedValueTypes,
  RDCReviewStatuses,
  ReviewConfigurationsStatuses,
  ReviewProcessStatuses,
} from '../../enums/compliance/reviewConfiguration';

export enum ReviewProcessType {
  RDC,
  Transaction,
}

export enum ReviewStatus {
  NotReviewed,
}

export enum ExpectedRiskLevelTypes {
  Low = 1,
  Medium,
  High,
  Forbidden,
}

export interface ReviewProcessModel {
  id: string;
  status: ReviewProcessStatuses;
  transactionId: string;
  reviewType: ComplianceReviewTypes;
  incomingTransaction?: TransactionReviewTarget;
  outgoingTransaction?: TransactionReviewTarget;
  complianceWorflowId: string;
  requestParamsJSON: string;
  createdOn: number;
  activeObjectsContainer: ReviewProcessObjectModel[];
}

export interface ReviewProcessObjectModel {
  isTriggered: boolean;
  reviewStatus: ReviewStatus;
  type: ReviewProcessType;
  objects: ReviewProcessAlertModel[];
}

export interface RDCAlertReviewResultModel {
  status: ReviewConfigurationsStatuses | RDCReviewStatuses;
  reason: string;
  reviewedAt: string;
  reviewedBy: string;
  reviewedByName: string;
}
export interface ReviewProcessAlertModel {
  inquiryId: string;
  isTriggered: boolean;
  objectId: string;
  objectType: ReviewProcessType;
  rdcAlert: RDCAlertInfoModel;
  reviewResult: RDCAlertReviewResultModel;
  rules: ReviewProcessConfigurationRuleModel[];
}

export interface RDCAlertInfoModel {
  EntityId: string;
  entityName: string;
  alertJSON: string;
  entityTyp: string;
}

export interface ReviewProcessConfigurationRuleModel {
  id: string;
  objectType: ReviewProcessType;
  comparisonOperator: ComparisonOperators;
  expectedDataType: ExpectedDataTypes;
  evaluatedAgainstValueJSON: string;
  expected: {
    type: ExpectedValueTypes;
    valueJSON: string;
  };
  isTriggered: boolean;
  propertyName: string;
  state: ConfigurationRuleState;
  reviewResult: RDCAlertReviewResultModel;
  ruleExecutionMetadata?: {
    riskRatings?: Record<string, ExpectedRiskLevelTypes>;
  };
}

// --- RDC review per client group ---
export interface RDCReviewAlertBaseModel {
  _id: string;
  associationId: string;
  reviewStatus: RDCAlertReviewStatusModel;
  alertEntity: RDCAlertBaseModel;
}

export interface RDCAlertBaseModel {
  id: string;
  name: string;
  type: string;
}

export interface RDCAlertFullModel {
  _id: string;
  associationId: string;
  alertEntityJSON: string;
  reviewStatus: RDCAlertReviewStatusModel;
}

export interface RDCAlertReviewStatusModel {
  isReviewed: boolean;
  match: boolean;
  reason: string;
}
