import React from 'react';

// components
import NotificationsTable from '../../../modules/ORAC/Notifications/NotificationsTable';

const Notifications = () => {
  return <NotificationsTable />;
};

export default Notifications;
