import { PermissionedAccessModel } from '.';

// Permission keys
export type FinancePermissionKeys =
  | 'Finance_View'
  | 'Finance_Journals_View'
  | 'Finance_Journals_Create'
  | 'Finance_Journals_Update'
  | 'Finance_Journals_Reverse'
  | 'Finance_Accounts_View'
  | 'Finance_Accounts_Create'
  | 'Finance_Accounts_Details_View'
  | 'Finance_Accounts_Details_Reconcile_JournalEntry'
  | 'Finance_Currencies_View'
  | 'Finance_Currencies_Update'
  | 'Finance_AccountCategories_View'
  | 'Finance_AccountCategories_Create'
  | 'Finance_AccountCategories_Details_View'
  | 'Finance_FiscalPeriod_View'
  | 'Finance_FiscalPeriod_Create'
  | 'Finance_CurrencyRates_View'
  | 'Finance_FinancialTypes_View';

// Permission config
export const FinancePermissions: Record<
  FinancePermissionKeys,
  PermissionedAccessModel
> = {
  Finance_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Accounting:Journal:Read' },
      { permissionKey: 'Accounting:Account:Read' },
      { permissionKey: 'Accounting:Currency:Read' },
      { permissionKey: 'Accounting:AccountCategory:Read' },
      { permissionKey: 'Accounting:Fiscal:Read' },
      { permissionKey: 'Accounting:CurrencyRate:Read' },
      { permissionKey: 'Accounting:FinancialType:Read' },
    ],
  },

  Finance_Journals_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:Journal:Read' },
      { permissionKey: 'CRM:ClientGroup:Read' },
    ],
  },

  Finance_Journals_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:Journal:Create' }],
  },

  Finance_Journals_Update: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:Journal:Read' },
      { permissionKey: 'CRM:ClientGroup:Read' },
      { permissionKey: 'Accounting:Journal:Create' },
      { permissionKey: 'Accounting:JournalEntry:Update' },
    ],
  },

  Finance_Journals_Reverse: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:Journal:Create' }],
  },

  Finance_Accounts_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:Account:Read' }],
  },

  Finance_Accounts_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:Account:Create' }],
  },

  Finance_Accounts_Details_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:Account:Read' },
      { permissionKey: 'Accounting:JournalEntry:Read' },
    ],
  },

  Finance_Accounts_Details_Reconcile_JournalEntry: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:JournalEntry:SetReconciliation' },
    ],
  },

  Finance_Currencies_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:Currency:Read' }],
  },

  Finance_Currencies_Update: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:Currency:Update' }],
  },

  Finance_AccountCategories_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:AccountCategory:Read' }],
  },

  Finance_AccountCategories_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:AccountCategory:Create' }],
  },

  Finance_AccountCategories_Details_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:AccountCategory:Read' }],
  },

  Finance_FiscalPeriod_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:Fiscal:Read' }],
  },

  Finance_FiscalPeriod_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:Fiscal:Create' }],
  },

  Finance_CurrencyRates_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:CurrencyRate:Read' }],
  },

  Finance_FinancialTypes_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:FinancialType:Read' }],
  },
};
