import React, { useState, useEffect } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../hooks/useFetch';
import useTranslation from '../../../hooks/useTranslation';
import { approvalWorkflowAPI } from '../../../api/approval/approvalManagement/approvalWorkflowAPI';
import { ApprovalWorkflowStatusModel } from '../../../typings/approval/approvalWorkflow';

// components
import { Select, Spin } from 'antd';

interface IProps {
  value: string;
  onSelect: (newValue: any) => void;
}

const ApprovalStatusAutocomplete = ({ onSelect, value }: IProps) => {
  const { t } = useTranslation('approval_management');

  const [data, setData] = useState<ApprovalWorkflowStatusModel[]>([]);

  const { response: getTypesResponse, loading: getTypesLoader } = useFetch(() =>
    approvalWorkflowAPI.fetchApprovalWorkflowStatuses(),
  );

  useEffect(() => {
    setData(getTypesResponse || []);
  }, [getTypesResponse]);

  const renderOptions = () => {
    if (!data || !data.length) return null;

    return data.map(({ value, name }) => (
      <Select.Option key={value} value={value}>
        {name}
      </Select.Option>
    ));
  };

  return (
    <StyledSelect
      value={value}
      showSearch
      size="large"
      loading={getTypesLoader}
      optionFilterProp="children"
      onChange={(newValue) => onSelect(newValue)}
      placeholder={t('approval_workflow.filter_by_status_placeholder')}
      filterOption={(input: string, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      notFoundContent={
        getTypesLoader ? (
          <LoaderWrapper>
            <Spin size="small" />
          </LoaderWrapper>
        ) : null
      }
    >
      <Select.Option value="0">
        {t('approval_workflow.all_statuses')}
      </Select.Option>

      {renderOptions()}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ApprovalStatusAutocomplete;
