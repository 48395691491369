import React from 'react';

// helpers
import { useFormikContext } from 'formik';
import { DocumentAssociationTypes } from '../../../../../enums/crm/crm';
import { UploadFormValuesModel } from '../../../../../typings/documents/documents';

// components
import Autocomplete from '../../../../../components/Fields/Autocomplete';
import EntityRequestsAutocomplete from '../../../../../components/Forms/FormComponents/Autocompletes/Documents/EntityRequestsAutocomplete';
import ContactRequestsAutocomplete from '../../../../../components/Forms/FormComponents/Autocompletes/Documents/ContactRequestsAutocomplete';
import ClientGroupRequestsAutocomplete from '../../../../../components/Forms/FormComponents/Autocompletes/Documents/ClientGroupRequestsAutocomplete';
import AccountRecoveryRequestsAutocomplete from '../../../../../components/Forms/FormComponents/Autocompletes/Documents/AccountRecoveryRequestsAutocomplete';

const AssociationObjectAutocomplete = (props: any) => {
  const { values } = useFormikContext<UploadFormValuesModel>();

  const renderAssociationObject = () => {
    switch (
      values.files[props.fileIndex].associations[props.associationIndex].type
    ) {
      case DocumentAssociationTypes.AccountRecovery: {
        return <AccountRecoveryRequestsAutocomplete {...props} />;
      }
      case DocumentAssociationTypes.ClientGroup: {
        return <ClientGroupRequestsAutocomplete {...props} />;
      }
      case DocumentAssociationTypes.Contact: {
        return <ContactRequestsAutocomplete {...props} />;
      }
      case DocumentAssociationTypes.Entity: {
        return <EntityRequestsAutocomplete {...props} />;
      }
      default:
        return <Autocomplete {...props} disabled />;
    }
  };

  return <>{renderAssociationObject()}</>;
};

export default AssociationObjectAutocomplete;
