import React from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { contactsAPI } from 'api/crm/contact/contactsAPI';
import { IContactModel } from 'typings/crm/contact';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import DescriptionSection from '@core_components/DescriptionSection';

interface IProps {
  contact: IContactModel;
}

const Compliance = ({ contact }: IProps) => {
  const { t } = useTranslation('crm');

  const { response, loading } = useFetch(
    () =>
      contact ? contactsAPI.fetchContactComplianceDataById(contact._id) : null,
    [contact._id],
  );

  return (
    <LoadingWrapper loading={loading}>
      <DescriptionSection
        size="small"
        bordered={false}
        background="none"
        data={[
          {
            label: t('contacts.compliance.date_of_completion'),
            description: response?.dateOfCompletion
              ? DateHelpers.formatDateToString(response.dateOfCompletion)
              : '',
          },
          {
            label: t('contacts.compliance.last_review_date'),
            description: response?.lastReviewDate
              ? DateHelpers.formatDateToString(response.lastReviewDate)
              : '',
          },
          {
            label: t('contacts.compliance.last_client_review_date'),
            description: response?.lastClientReviewDate
              ? DateHelpers.formatDateToString(response.lastClientReviewDate)
              : '',
          },
        ]}
      />
    </LoadingWrapper>
  );
};

export default Compliance;
