import React, { useCallback, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { userAPI } from '../../../../../api/auth/userAPI';
import { StateModel } from '../../../../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { TwoFactorVerificationTypes } from '../../../../../enums/auth/auth';
import { StateModel as AuthStateModel } from '../../../../../redux/reducers/auth';
import {
  setFidoAuthenticatorEnabledStatus,
  setTwoFactorVerificationData,
} from '../../../../../redux/actions/auth';

// components
import RegisterSecurityKeyModal, {
  FormValuesModel,
} from '../../../../../components/Modals/TemplateModalDialogs/Profile/RegisterSecurityKeyModal';
import { Button, message } from 'antd';

const RegisterSecurityKey = () => {
  const { t } = useTranslation('profile');
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { twoFactorVerificationData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const getChallengeCallback = useCallback(() => {
    return userAPI.getChallengeForRegistrationFido(
      twoFactorVerificationData?.temporaryToken as string,
    );
  }, [twoFactorVerificationData]);

  const handleModalClose = (wasRegistered?: boolean) => {
    if (wasRegistered) {
      dispatch(setFidoAuthenticatorEnabledStatus(true));
    }

    setIsModalVisible(false);
  };

  const handleSubmit = async (values: FormValuesModel) => {
    if (
      values.securityKeyCredInfo &&
      twoFactorVerificationData?.temporaryToken
    ) {
      await userAPI.registerFidoKey(
        { ...values.securityKeyCredInfo, name: values.name },
        twoFactorVerificationData?.temporaryToken,
      );
      message.success(
        t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_modal.success_submit_message',
        ),
      );

      const optionsCopy = twoFactorVerificationData.options.slice();
      const fidoItemIndex = optionsCopy.findIndex(
        (e) => e.code === TwoFactorVerificationTypes.Fido,
      );
      optionsCopy[fidoItemIndex].enabled = true;

      dispatch(
        setTwoFactorVerificationData({
          ...twoFactorVerificationData,
          options: optionsCopy,
        }),
      );
    }
  };

  return (
    <>
      <StyledButton
        size="large"
        type="primary"
        onClick={() => setIsModalVisible(true)}
      >
        {t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_button',
        )}
      </StyledButton>

      <RegisterSecurityKeyModal
        title={t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_modal.title',
        )}
        onSubmit={handleSubmit}
        isVisible={isModalVisible}
        closeCallback={handleModalClose}
        getChallengeCallback={getChallengeCallback}
      />
    </>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

export default RegisterSecurityKey;
