import React, { memo, useState } from 'react';

// helpers
import useFetch from '../../../../../../../hooks/useFetch';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../../constants/global';
import {
  PendingTransactionFromQuery,
  transactionsAPI,
} from '../../../../../../../api/transactions/transactionsAPI';

// components
import ClientTransferModal from '../../../../../../../components/Modals/TemplateModalDialogs/Accounting/Transfers/ClientTransferModal';
import FilterBar, { ActionKeys as FilterActionKeys } from './FilterBar';
import ClientTransactionsTable, {
  ActionKeys,
} from '../../../../../../../components/Tables/TableTemplates/Accounting/ClientTransactionsTable';
import { Divider } from 'antd';

interface IProps {
  clientGroupNumericId: number;
}

const PendingTransactionsTab = memo(({ clientGroupNumericId }: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTransaction, setSelectedTransaction] =
    useState<PendingTransactionFromQuery | null>(null);

  const [pendingTransactionsFilter, setPendingTransactionsFilter] = useState({
    accountNumber: '',
    status: 'all',
  });

  const { response, loading } = useFetch(
    () =>
      clientGroupNumericId
        ? transactionsAPI.fetchClientPendingTransactions({
            page: currentPage,
            limit: DEFAULT_TABLE_LIMIT,
            clientGroupId: String(clientGroupNumericId),
            status: pendingTransactionsFilter.status,
            accountNumber:
              pendingTransactionsFilter.accountNumber === 'all'
                ? undefined
                : pendingTransactionsFilter.accountNumber,
          })
        : null,
    [clientGroupNumericId, pendingTransactionsFilter, currentPage],
  );

  const handleActionsClick = (
    key: ActionKeys,
    record: PendingTransactionFromQuery,
  ) => {
    switch (key) {
      case 'view': {
        setSelectedTransaction(record);
      }
    }
  };

  const handlerFilterChange = (key: FilterActionKeys, value: any) => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }

    switch (key) {
      case 'filter-by-accounts':
        setPendingTransactionsFilter((prevState) => ({
          ...prevState,
          accountNumber: value.trim() || '',
        }));
        break;

      case 'filter-by-status':
        setPendingTransactionsFilter((prevState) => ({
          ...prevState,
          status: value,
        }));
        break;
    }
  };

  return (
    <>
      <FilterBar
        clientGroupNumericId={clientGroupNumericId}
        onFilterChange={handlerFilterChange}
        selectedStatus={pendingTransactionsFilter.status}
        selectedAccount={pendingTransactionsFilter.accountNumber}
      />

      <Divider />

      <ClientTransactionsTable
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={currentPage}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsClick}
      />

      <ClientTransferModal
        clientGroupId={clientGroupNumericId}
        transactionId={selectedTransaction?.id || null}
        isVisible={!!selectedTransaction}
        closeCallback={() => setSelectedTransaction(null)}
      />
    </>
  );
});

export default PendingTransactionsTab;
