import React from 'react';

// helpers
import styled from 'styled-components';
import { accountsAPI } from '../../../../../../api/finance/account/accountsAPI';
import { IAccountModel } from '../../../../../../typings/finance/account';
import { AccountsHelpers } from '@helpers/finance/accounts';

// components
import EllipsisTooltip from '../../../../../Tooltips/EllipsisTooltip';
import Autocomplete, {
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';
import { Typography } from 'antd';

interface IProps {
  value: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  isFullAccountNumber?: boolean;
  onSelect?: (id: string, model: IAccountModel) => void;
}

const AccountAutocomplete = ({ isFullAccountNumber, ...rest }: IProps) => {
  const fetchAccounts = async (searchText: string) => {
    const { data } = await accountsAPI.searchAccounts(
      searchText,
      true,
      isFullAccountNumber,
    );

    return data.map<AutocompleteOption>((account: IAccountModel) => {
      const { accountNumber, accountName, currencyCode, balance } = account;
      const accountBalance = `${currencyCode} ${AccountsHelpers.convertAmountBigIntToLocaleString(balance)}`;
      const name = `${accountNumber} - ${accountName} ${accountBalance}`;
      return {
        id: accountNumber,
        content: (
          <EllipsisTooltip
            placement="right"
            overlayStyle={{ maxWidth: '300px' }}
            title={
              <>
                <StyledParagraph>{accountNumber}</StyledParagraph>
                <StyledParagraph>{accountName}</StyledParagraph>
                <StyledParagraph>{accountBalance}</StyledParagraph>
              </>
            }
          >
            {name}
          </EllipsisTooltip>
        ),
        model: account,
      };
    });
  };

  return (
    <Autocomplete {...rest} fetchData={fetchAccounts} minSearchLength={4} />
  );
};

const StyledParagraph = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
`;

export default AccountAutocomplete;
