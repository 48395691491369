import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { securityAPI } from '../../../../../../api/profile/securityAPI';
import { useDispatch } from 'react-redux';
import { setFidoAuthenticatorEnabledStatus } from '../../../../../../redux/actions/auth';

// components
import RegisterSecurityKeyModal, {
  FormValuesModel,
} from '../../../../../../components/Modals/TemplateModalDialogs/Profile/RegisterSecurityKeyModal';
import { Button, message } from 'antd';

const EnableSecurityKey = () => {
  const { t } = useTranslation('profile');
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalClose = (wasRegistered?: boolean) => {
    if (wasRegistered) {
      dispatch(setFidoAuthenticatorEnabledStatus(true));
    }

    setIsModalVisible(false);
  };

  const handleSubmit = async (values: FormValuesModel) => {
    if (values.securityKeyCredInfo) {
      await securityAPI.registerSecurityKey({
        ...values.securityKeyCredInfo,
        name: values.name,
      });

      message.success(
        t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_modal.success_submit_message',
        ),
      );
    }
  };

  return (
    <>
      <StyledButton
        size="large"
        type="primary"
        onClick={() => setIsModalVisible(true)}
      >
        {t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_button',
        )}
      </StyledButton>

      <RegisterSecurityKeyModal
        getChallengeCallback={securityAPI.getChallengeForRegistrationNewKey}
        onSubmit={handleSubmit}
        title={t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_modal.title',
        )}
        isVisible={isModalVisible}
        closeCallback={handleModalClose}
      />
    </>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

export default EnableSecurityKey;
