import React from 'react';

// helpers
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import ActionButtons from '../ActionButtons';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import AdditionalFieldsSection from './AdditionalFieldsSection';
import AdditionalDocumentsSection from './AdditionalDocumentsSection';

const InnerForm = () => {
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <AdditionalFieldsSection />
      {!!values.additionalDocuments.length && <AdditionalDocumentsSection />}
      <HideIfDisabledForm>
        <ActionButtons />
      </HideIfDisabledForm>
    </>
  );
};

export default InnerForm;
