import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';

import styled from 'styled-components';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { StyledComponentProps } from '../../../../../typings/common';
import { ForexSpreadLimitModel } from '../../../../../typings/banking/foreignExchange';

// components
import TableActions from '../../../TableActions';
import OverridenValueInfoTooltip from './OverridenValueInfoToolting';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps
  extends StyledComponentProps,
    RequiredPropsForTableModel<ForexSpreadLimitModel> {
  canEdit?: boolean;
  onActionsClick?: (key: string, record: ForexSpreadLimitModel) => void;
}

const ForeignExchangeSpreadsTable = memo(
  ({ onActionsClick, canEdit, ...rest }: IProps) => {
    const { t } = useTranslation(['banking', 'crm', 'common']);

    const renderRangeValue = ({
      fromRange,
      toRange,
    }: ForexSpreadLimitModel) => {
      const formattedFrom =
        AccountsHelpers.formatMoneyToStringWithRounding(fromRange);
      const formattedTo = !toRange
        ? (t('forex.matrix.above', { ns: 'banking' }) as string)
        : AccountsHelpers.formatMoneyToStringWithRounding(toRange.value) || 0;
      return t('client_group.foreign_exchange.from_to_spread', {
        from: formattedFrom,
        to: formattedTo,
        ns: 'crm',
      });
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          width: 400,
          title: t('client_group.foreign_exchange.range', { ns: 'crm' }),
          key: 'range',
          render: renderRangeValue,
        },

        {
          width: 250,
          title: t('client_group.foreign_exchange.spread', { ns: 'crm' }),
          key: 'spread',
          render: (record: ForexSpreadLimitModel) => (
            <StyledAmountText isHighlighted={!record.isDefault}>
              {`${AccountsHelpers.formatMoneyToStringWithRounding(record.spread)} %`}{' '}
              {!record.isDefault && <OverridenValueInfoTooltip />}
            </StyledAmountText>
          ),
        },

        {
          width: 250,
          title: t('client_group.foreign_exchange.require_approval', {
            ns: 'crm',
          }),
          key: 'require_approval',
          render: (record: ForexSpreadLimitModel) =>
            t(`${JSON.stringify(record.isAboveThreshold)}`, { ns: 'common' }),
        },

        {
          key: 'actions',
          align: 'right',
          render: (record: ForexSpreadLimitModel) =>
            canEdit ? (
              <TableActions
                onSelect={(key: string) =>
                  onActionsClick && onActionsClick(key, record)
                }
                menu={[
                  {
                    key: 'update_spread_value',
                    name: t('update', { ns: 'common' }),
                  },
                ]}
              />
            ) : null,
        },
      ];

      return result;
    }, [canEdit, t, onActionsClick]);

    return <Table {...rest} columns={columns} />;
  },
);

const StyledAmountText = styled(Typography.Text)<{ isHighlighted?: boolean }>`
  ${({ theme, isHighlighted }) =>
    isHighlighted &&
    `
      color: ${theme.warningColor}
   `}
`;

export default ForeignExchangeSpreadsTable;
