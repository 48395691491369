import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../../../hooks/useTranslation';
import { PermissionType } from '../../../../../../../../../../typings/ORAC/permissions';
import { PermissionsHelpers } from '@helpers/permissions';
import {
  ObjectActionWithPermissionsModel,
  ObjectWithPermissionsModel,
} from '../../../../../../../../../../typings/ORAC/objects';

// components
import PermissionRadioButton from './PermissionRadioButton';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

interface IProps
  extends RequiredPropsForTableModel<ObjectActionWithPermissionsModel> {
  object: ObjectWithPermissionsModel;
  showForbiddenColumn?: boolean;
  showForbiddenOnly?: boolean;
}

const PermissionsMatrixForm = memo(
  ({ object, showForbiddenColumn, showForbiddenOnly, ...rest }: IProps) => {
    const { t } = useTranslation('orac');

    const renderPermissionToggleButton = (
      record: ObjectActionWithPermissionsModel,
      permissionType: PermissionType,
    ) => {
      const permission = PermissionsHelpers.findPermissionInAction(
        record,
        permissionType,
      );

      return (
        <PermissionRadioButton
          permissionType={permissionType}
          action={record}
          object={object}
          permission={permission || null}
        />
      );
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          key: 'name',
          title: t('objects.view.action_name'),
          render: (record: ObjectActionWithPermissionsModel) => record.action,
        },
        {
          hidden: showForbiddenOnly,
          align: 'center',
          width: '100px',
          key: 'single',
          title: t('objects.view.single_permission'),
          render: (record: ObjectActionWithPermissionsModel) =>
            renderPermissionToggleButton(record, 'single'),
        },
        {
          hidden: showForbiddenOnly,
          align: 'center',
          width: '100px',
          key: 'multiple',
          title: t('objects.view.multiple_permission'),
          render: (record: ObjectActionWithPermissionsModel) =>
            renderPermissionToggleButton(record, 'multiple'),
        },

        {
          hidden: !showForbiddenColumn && !showForbiddenOnly,
          align: 'center',
          width: '100px',
          key: 'forbidden_permission',
          title: t('objects.view.forbidden_permission'),
          render: (record: ObjectActionWithPermissionsModel) =>
            renderPermissionToggleButton(record, 'forbidden'),
        },
      ];

      return result;
    }, [object, showForbiddenOnly, showForbiddenColumn]);

    return (
      <Table {...rest} columns={columns} className="disable-table-row-hover" />
    );
  },
);

export default PermissionsMatrixForm;
