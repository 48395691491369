import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { useFormikContext } from 'formik';

// components
import SubmitButton from '@common_components/Buttons/SubmitButton';
import { Button, Col, Modal, Row } from 'antd';

interface IProps {
  isDisabled: boolean;
  setDisabledStatus: (newStatus: boolean) => void;
}

const FormEditModeButtons = memo(
  ({ isDisabled, setDisabledStatus }: IProps) => {
    const { t } = useTranslation(['common', 'form']);
    const { resetForm, dirty } = useFormikContext();

    const turnOffEditMode = () => {
      if (dirty) {
        Modal.confirm({
          title: t('cancel_without_save_title', { ns: 'form' }),
          content: t('cancel_without_save_helper', { ns: 'form' }),
          okText: t('yes'),
          cancelText: t('no'),
          onOk: () => {
            resetForm();
            setDisabledStatus(true);
          },
        });
      } else {
        setDisabledStatus(true);
      }
    };

    return (
      <StyledRow justify="end" gutter={[16, 16]}>
        {isDisabled ? (
          <Col flex="150px">
            <StyledButton
              type="primary"
              onClick={() => setDisabledStatus(false)}
            >
              {t('modal.edit')}
            </StyledButton>
          </Col>
        ) : (
          <>
            <Col>
              <StyledButton onClick={turnOffEditMode}>
                {t('modal.cancel')}
              </StyledButton>
            </Col>
            <Col>
              <StyledSubmitButton size="middle" type="primary">
                {t('modal.save')}
              </StyledSubmitButton>
            </Col>
          </>
        )}
      </StyledRow>
    );
  },
);

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
`;

export default FormEditModeButtons;
