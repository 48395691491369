import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { darkTheme } from '@resources/theme/styled';
import {
  ChangeRequestStatuses,
  FetchContactWithChangeRequestResponse,
} from 'api/crm/changeRequests/changeRequestsAPI';

// components
import Tabs from 'components/Tabs';
import SOWTab from './SOWTab';
import IconSVG from '@core_components/IconSVG';
import GeneralTab from './GeneralTab';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import VerificationTab from './VerificationTab';
import IdentificationTab from './IdentificationTab';
import RequestContactDataUpdate from '../RequestContactDataUpdate';
import { ReactComponent as CheckIcon } from 'resources/icons/remix-icons/check-line.svg';

interface IProps {
  contactWithChangeRequest: FetchContactWithChangeRequestResponse;
  updateContactCallback: () => void;
  updateChangeRequestCallback: () => void;
}

const KYCForm = ({
  contactWithChangeRequest,
  updateChangeRequestCallback,
  updateContactCallback,
}: IProps) => {
  const { t } = useTranslation('crm');
  const [currentStep, setCurrentStep] = useState<string>('general');

  const canReview =
    contactWithChangeRequest.clientChangeRequest?.status ===
      ChangeRequestStatuses.InReview &&
    !contactWithChangeRequest.clientChangeRequest.verification
      ?.complianceWorkflow;

  const tabs = useMemo(() => {
    const { clientChangeRequest } = contactWithChangeRequest;

    return [
      {
        key: 'general',
        title: (
          <DivAlignCenter>
            {t('contacts.kyc_form.general.title')}
            {clientChangeRequest?.changedSteps.general && (
              <StyledIconSVG
                component={CheckIcon}
                color={darkTheme.primaryGreen}
              />
            )}
          </DivAlignCenter>
        ),
        content: <GeneralTab data={contactWithChangeRequest} />,
      },

      {
        key: 'identification',
        title: (
          <DivAlignCenter>
            {t('contacts.kyc_form.identification.title')}
            {clientChangeRequest?.changedSteps.identification && (
              <StyledIconSVG
                component={CheckIcon}
                color={darkTheme.primaryGreen}
              />
            )}
          </DivAlignCenter>
        ),
        content: (
          <IdentificationTab
            data={contactWithChangeRequest}
            canReview={canReview}
            updateChangeRequestCallback={updateChangeRequestCallback}
          />
        ),
      },

      {
        key: 'sow',
        title: (
          <DivAlignCenter>
            {t('contacts.kyc_form.sow.title')}
            {clientChangeRequest?.changedSteps.sow && (
              <StyledIconSVG
                component={CheckIcon}
                color={darkTheme.primaryGreen}
              />
            )}
          </DivAlignCenter>
        ),
        content: <SOWTab data={contactWithChangeRequest} />,
      },

      {
        key: 'verification',
        title: (
          <DivAlignCenter>
            {t('contacts.kyc_form.verification.title')}
            {clientChangeRequest?.changedSteps.verification && (
              <StyledIconSVG
                component={CheckIcon}
                color={darkTheme.primaryGreen}
              />
            )}
          </DivAlignCenter>
        ),
        content: (
          <VerificationTab
            data={contactWithChangeRequest}
            canReview={canReview}
            updateContactCallback={updateContactCallback}
            updateChangeRequestCallback={updateChangeRequestCallback}
          />
        ),
      },
    ];
  }, [
    t,
    contactWithChangeRequest,
    canReview,
    updateChangeRequestCallback,
    updateContactCallback,
  ]);

  return (
    <>
      {canReview && contactWithChangeRequest.clientChangeRequest && (
        <RequestContactDataUpdate
          changeRequest={contactWithChangeRequest.clientChangeRequest}
        />
      )}
      <Tabs
        tabs={tabs}
        activeKey={currentStep}
        defaultActiveKey={currentStep}
        onChange={setCurrentStep}
      />
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

export default KYCForm;
