import React, { useMemo } from 'react';

// helpers
import moment from 'moment';
import useTranslation from '../../../../hooks/useTranslation';
import { DEFAULT_DATE_FORMAT } from '../../../../constants/global';
import { IAccountCategoryDetails } from '../../../../typings/finance/accountCategories';

// components
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

interface IProps {
  accountCategory: IAccountCategoryDetails;
}

const DetailsContainer = ({ accountCategory }: IProps) => {
  const { t } = useTranslation('finance');

  const renderUpdatedOnDate = (timestamp?: number) => {
    if (!timestamp) return '';

    return moment(timestamp * 1000).format(DEFAULT_DATE_FORMAT);
  };

  const descriptionsData = useMemo<DescriptionItem[]>(
    () => [
      {
        label: t('account_categories.table.client_account_category'),
        description: accountCategory.accountCategoryName || '',
      },
      {
        label: t('account_categories.table.description'),
        description: accountCategory.description || '',
      },
      {
        label: t('account_categories.table.parent_category'),
        description: accountCategory.parentCategoryName || '',
      },
      {
        label: t('account_categories.table.financial_type'),
        description: accountCategory.financialTypeName || '',
      },
      {
        label: t('account_categories.table.created_at'),
        description: renderUpdatedOnDate(accountCategory.creationDate),
      },
    ],
    [accountCategory],
  );

  return (
    <DescriptionSection data={descriptionsData} background="transparent" />
  );
};

export default DetailsContainer;
