import React, { memo, useCallback, useMemo, useState } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { entityAPI } from 'api/crm/entity/entityAPI';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from 'routes/routes';
import { entityAdapter } from 'apiAdapters/crm/entity/entityAdapter';

// components
import PageButton from '@common_components/Buttons/PageButton';
import EntityDetailsModalDialog, {
  EntityDetailsFormValues,
} from 'components/Modals/TemplateModalDialogs/CRM/EntityDetailsModalDialog';

const AddEntity = memo(() => {
  const history = useHistory();
  const { t } = useTranslation('crm');
  const [isVisible, setIsVisible] = useState(false);

  const initialFormValues = useMemo<EntityDetailsFormValues>(() => {
    return {
      legalName: '',
      tradeName: '',
      registrationNumber: '',
      dateOfIncorporation: null,
      jurisdiction: null,
      keyRelationshipTemplate: null,
      natureOfBusiness: '',
      numberOfEmployees: null,
      numberOfOffices: null,
      isRegulated: null,
      industryCodes: null,
      regulationCountry: null,
      canIssueBearerShares: null,
      isVendor: null,
      primaryAddressId: null,
      addresses: [],
      canUpdatePrimaryStatusForAddresses: true,
      socialMedia: {
        websiteLink: '',
        twitterLink: '',
        facebookLink: '',
        linkedInLink: '',
      },
    };
  }, []);

  const handleModalClose = useCallback((wasSubmitted?: boolean) => {
    if (wasSubmitted) {
      // TODO
    }

    setIsVisible(false);
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      const formattedBody =
        entityAdapter.formatEntityDataFromFormToQuery(values);
      const response = await entityAPI.createEntity(formattedBody);
      message.success(t('entity.new.successCreate'));
      history.push({
        pathname: RoutePaths.CRM_Entities_Edit,
        search: `?id=${response._id}`,
      });
    },
    [history],
  );

  return (
    <>
      <PageButton onClick={() => setIsVisible(true)}>
        {t('entity.new.title')}
      </PageButton>
      <EntityDetailsModalDialog
        title={t('entity.new.title')}
        isVisible={isVisible}
        onSubmit={handleSubmit}
        closeCallback={handleModalClose}
        initialValues={initialFormValues}
      />
    </>
  );
});

export default AddEntity;
