import React, { memo, useMemo } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { Moment } from 'moment';
import { useField } from 'formik';
import { COUNTRY_CODES } from 'constants/countryCodes';
import { DEFAULT_DATE_FORMAT } from 'constants/global';

// components
import DocumentLink from 'components/Typography/DocumentLink';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

export type PassportItemFormValue = {
  id: string;
  number: string;
  country: string;
  issuedAt: Moment | null;
  expirationDate: Moment | null;
  documents: { name: string; documentId: string; fileId: string }[];
};

interface IProps {
  fieldName: string;
}

const PassportItem = memo(({ fieldName }: IProps) => {
  const { t } = useTranslation('crm');
  const [field] = useField<PassportItemFormValue>(fieldName);

  const descriptionData = useMemo<DescriptionItem[]>(() => {
    return [
      { label: t('contacts.new.passport_type'), description: 'passport' },
      {
        label: t('contacts.new.passport_number'),
        description: field.value.number,
      },
      {
        label: t('contacts.new.passport_country'),
        description: field.value.country
          ? COUNTRY_CODES[field.value.country]
          : '',
      },
      {
        label: t('contacts.new.passport_issue_date'),
        description: field.value.issuedAt
          ? moment(field.value.issuedAt).format(DEFAULT_DATE_FORMAT)
          : '',
      },
      {
        label: t('contacts.new.passport_expiration_date'),
        description: field.value.expirationDate
          ? moment(field.value.expirationDate).format(DEFAULT_DATE_FORMAT)
          : '',
      },
      {
        label: t('contacts.new.documents'),
        description: field.value.documents.length
          ? field.value.documents.map((e) => (
              <div key={e.documentId}>
                <DocumentLink
                  text={e.name}
                  fileName={e.name}
                  documentId={e.documentId}
                  fileId={e.fileId}
                />
              </div>
            ))
          : null,
      },
    ];
  }, [field]);

  return (
    <StyledDescriptionSection
      background="transparent"
      bordered={false}
      size="small"
      data={descriptionData}
    />
  );
});

const StyledDescriptionSection = styled(DescriptionSection)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default PassportItem;
