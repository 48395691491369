import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { NoteMessageType } from 'enums/crm/crm';

// components
import Select, { SelectOption } from '@core_components/Select';

interface IProps {
  onChange: (value: string | undefined) => void;
}

const NoteTypeSelect = memo(({ onChange, ...rest }: IProps) => {
  const { t } = useTranslation('crm');

  const options = useMemo<SelectOption[]>(() => {
    return [
      {
        id: NoteMessageType.Call,
        label: t(`entity.notes.types.${NoteMessageType.Call}`),
      },
      {
        id: NoteMessageType.Compliance,
        label: t(`entity.notes.types.${NoteMessageType.Compliance}`),
      },
      {
        id: NoteMessageType.Research,
        label: t(`entity.notes.types.${NoteMessageType.Research}`),
      },
      {
        id: NoteMessageType.inPerson,
        label: t(`entity.notes.types.${NoteMessageType.inPerson}`),
      },
      {
        id: NoteMessageType.Other,
        label: t(`entity.notes.types.${NoteMessageType.Other}`),
      },
    ];
  }, []);

  return (
    <Select
      allowClear
      size="large"
      options={options}
      onChange={onChange}
      {...rest}
    />
  );
});

export default NoteTypeSelect;
