import React from 'react';

// helpers
import groupsAPI from '../../../../../../api/orac/groupsAPI';
import useTranslation from '../../../../../../hooks/useTranslation';
import { GroupBaseModel } from '../../../../../../typings/ORAC/groups';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import Autocomplete, {
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';

interface IProps {
  value: string;
}

const GroupsAutocomplete = (props: IProps) => {
  const { t } = useTranslation('orac');

  const mapObject = (group: GroupBaseModel) => {
    return {
      id: group.id,
      content: group.name,
      model: group,
    };
  };

  const fetchObjects = async (searchQuery: string) => {
    const { data } = await groupsAPI.fetchGroups({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      name: searchQuery,
    });

    return data.map<AutocompleteOption>(mapObject);
  };

  return (
    <Autocomplete
      {...props}
      placeholder={t('groups.form.enter_group_name')}
      fetchData={fetchObjects}
    />
  );
};

export default GroupsAutocomplete;
