import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { ClientReviewStatus } from '../../../../../enums/crm/crm';

// components
import { Select } from 'antd';

interface IProps {
  value: string;
  reviewStatuses: ClientReviewStatus[];
  onChange: (value: any) => void;
}

const ReviewStatusSelect = ({ value, reviewStatuses, onChange }: IProps) => {
  const { t } = useTranslation('crm');

  const renderOptions = () => {
    return reviewStatuses.map((el) => (
      <Select.Option key={el} value={el}>
        {t(`client_groups.business_review_status.${el}`)}
      </Select.Option>
    ));
  };

  return (
    <StyledSelect
      placeholder={t('client_group.select_client_group_status')}
      onChange={onChange}
      value={value}
      showArrow
      size="large"
      filterOption={false}
    >
      <Select.Option key="all" value="all">
        {t('client_group.all')}
      </Select.Option>

      {renderOptions()}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;
export default ReviewStatusSelect;
