import React, { useMemo } from 'react';

// helpers
import { DetailsOfChargesCodes } from '../../../../../enums/banking/transactions';

// components
import Select, {
  SelectOption,
  SelectProps,
} from '../../../../DesignSystem/Core/Select';

type IProps = SelectProps<any>;

const DetailsOfChargesSelect = (props: IProps) => {
  const options = useMemo<SelectOption[]>(
    () =>
      Object.keys(DetailsOfChargesCodes).map((key) => ({
        id: key,
        label: key,
      })),
    [],
  );

  return <Select {...props} options={options} />;
};

export default DetailsOfChargesSelect;
