import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { AccountsHelpers } from '@helpers/finance/accounts';

// components
import { Typography } from 'antd';

interface IProps {
  from: number;
  to: number | string;
}

const RangeInfo = ({ from, to }: IProps) => {
  const { t } = useTranslation('banking');

  return (
    <>
      <StyledTitleParagraph>
        {`${t('forex.matrix.from')}: `}
        <StyledRangeValueText>
          {AccountsHelpers.formatMoneyToString(from)}
        </StyledRangeValueText>
      </StyledTitleParagraph>

      <StyledTitleParagraph>
        {`${t('forex.matrix.to')}: `}
        <StyledRangeValueText>
          {AccountsHelpers.formatMoneyToString(to)}
        </StyledRangeValueText>
      </StyledTitleParagraph>
    </>
  );
};

export const StyledTitleParagraph = styled(Typography.Paragraph)`
  font-weight: 600;
  margin-bottom: 0px !important;
  transition: ${({ theme }) => theme.defaultTransition};
`;

const StyledRangeValueText = styled(Typography.Text)`
  font-weight: 400;
`;

export default RangeInfo;
