import React from 'react';

// helpers
import { IEntity } from 'typings/crm/entity';
import { OnboardType } from 'enums/crm/crm';
import { EntityHelpers } from '@helpers/crm/entity';
import { IContactModel } from 'typings/crm/contact';
import { ContactHelpers } from '@helpers/crm/contact';
import { clientGroupsAPI } from 'api/crm/clientGroup/clientGroupsAPI';
import { AutocompleteProps } from '@core_components/Autocomplete';
import { AUTOCOMPLETE_RESULT_LIMIT } from 'constants/global';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export interface IProps extends AutocompleteProps<ClientGroupMemberModel> {
  entryType: OnboardType;
  clientGroupId: string;
}

export interface ClientGroupMemberModel {
  id: string;
  type: OnboardType;
  contact: IContactModel | null;
  organization: IEntity | null;
}

const ClientGroupMembersAutocomplete = ({
  clientGroupId,
  entryType,
  ...rest
}: IProps) => {
  const fetchOptions = async (search: string) => {
    const { data } = await clientGroupsAPI.fetchMembers(clientGroupId, {
      page: 1,
      search,
      has_online_access: false,
      type: entryType,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
    });

    return data.map((recipient) => {
      return {
        id: recipient.id,
        label:
          recipient.type === OnboardType.Contact
            ? ContactHelpers.getContactName(recipient.contact as IContactModel)
            : EntityHelpers.getEntityName({
                names: recipient.organization?.names,
              }),
        model: recipient,
      };
    });
  };

  return <FormAutocomplete {...rest} fetchData={fetchOptions} />;
};

export default ClientGroupMembersAutocomplete;
