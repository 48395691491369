import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';

// components
import { Col, Row } from 'antd';
import { SearchInput } from 'components/Fields/SearchInput';

export type FilterKeys = 'search';

export interface IProps {
  onFilterChange: (key: FilterKeys, value: string | number) => void;
}

const FilterBar = ({ onFilterChange }: IProps) => {
  const { t } = useTranslation('compliance');

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Row>
          <Col span={24}>
            {t('expiring_documents.filter_bar.search_by_document_details')}
          </Col>
          <Col span={24}>
            <SearchInput
              size="large"
              allowClear
              placeholder={t(
                'expiring_documents.filter_bar.document_details_placeholder',
              )}
              type="search"
              onSearch={(searchQuery: string) =>
                onFilterChange('search', searchQuery)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FilterBar;
