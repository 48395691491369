import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { NewThreadBodyModel } from '../../apiAdapters/messaging/messagingAdapter';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import {
  ThreadCategoryModel,
  IMessagesStatus,
  ThreadWithLastMessageModel,
  MessageModel,
  ThreadModel,
} from '../../typings/messaging/messages';

export interface NewMessageBodyModel {
  body: string;
  attachments?: IMessageAttachmentBody[];
}

export interface IMessageAttachmentBody {
  documentId: string;
  contentType: string;
  fileId: string;
  name: string;
}

interface FetchMessagesParamsModel extends FetchParamsModel {
  clientUserId?: string;
}

export type ThreadWithNewMessagesFromQuery = {
  category: string;
  clientGroupId: string;
  clientGroupName: string;
  subject: string;
  threadId: string;
  updatedAt: string;
  message: MessageModel;
};

const messagingAPI = {
  fetchThreadCategories: () => {
    return APIService.get<ThreadCategoryModel[]>(
      `${APIConfig.messagingApi}/thread-categories`,
    ).then(({ data }) => data);
  },

  createThread: (clientGroupId: number, body: NewThreadBodyModel) => {
    return APIService.post<MessageModel>(
      `${APIConfig.messagingApi}/client-groups/${clientGroupId}/threads`,
      body,
    ).then(({ data }) => data);
  },

  fetchInboxThreads: (
    clientGroupId: number,
    params: FetchMessagesParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<ThreadWithLastMessageModel>>(
      `${APIConfig.messagingApi}/client-groups/${clientGroupId}/threads/inbox`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchSentThreads: (
    clientGroupId: number,
    params: FetchMessagesParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<ThreadWithLastMessageModel>>(
      `${APIConfig.messagingApi}/client-groups/${clientGroupId}/threads/sent`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  replyInThread: (
    clientGroupId: number,
    threadId: string,
    body: NewMessageBodyModel,
  ) => {
    return APIService.post<{ message: MessageModel }>(
      `${APIConfig.messagingApi}/client-groups/${clientGroupId}/threads/${threadId}/messages`,
      body,
    ).then(({ data }) => data);
  },

  fetchThreadById: (clientGroupId: number, threadId: string) => {
    return APIService.get<ThreadModel>(
      `${APIConfig.messagingApi}/client-groups/${clientGroupId}/threads/${threadId}`,
    ).then(({ data }) => data);
  },

  fetchMessagesByThreadId: (
    clientGroupId: number,
    threadId: string,
    params: FetchParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<MessageModel>>(
      `${APIConfig.messagingApi}/client-groups/${clientGroupId}/threads/${threadId}/messages`,
      { params },
    ).then(({ data }) => data);
  },

  getMessagesStatus: (clientGroupId: number | string) => {
    return APIService.get<IMessagesStatus>(
      `${APIConfig.messagingApi}/client-groups/${clientGroupId}/threads/messages/count-new`,
    ).then(({ data }) => data);
  },

  readThread: (clientGroupId: number, threadId: string) => {
    return APIService.patch(
      `${APIConfig.messagingApi}/client-groups/${clientGroupId}/threads//${threadId}/messages/set-read`,
    ).then(({ data }) => data);
  },

  fetchNewMessages: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<ThreadWithNewMessagesFromQuery>>(
      `${APIConfig.messagingApi}/threads/new`,
      { params },
    ).then(({ data }) => data);
  },
};

export { messagingAPI };
