import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';
import {
  ApprovalWorkflowTypeModel,
  ApprovalWorkflowStatusModel,
  ApprovalWorkflowInstanceModel,
} from '../../../typings/approval/approvalWorkflow';

export enum APPROVAL_INSTANCE_FILTER_ENUM {
  ALL,
  MINE,
  NEED_MY_APPROVAL,
}

interface FetchApprovalInstancesParamsModel extends FetchParamsModel {
  filterlevel: APPROVAL_INSTANCE_FILTER_ENUM;
  type: string;
  status: string;
}

const approvalWorkflowAPI = {
  fetchApprovalWorkflowTypes: () => {
    return APIService.get<ApprovalWorkflowTypeModel[]>(
      `${APIConfig.approvalApi}/workflows/types`,
    ).then(({ data }) => data);
  },

  fetchApprovalWorkflowStatuses: () => {
    return APIService.get<ApprovalWorkflowStatusModel[]>(
      `${APIConfig.approvalApi}/workflows/status`,
    ).then(({ data }) => data);
  },

  fetchApprovalWorkflowInstances: (
    params: FetchApprovalInstancesParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<ApprovalWorkflowInstanceModel>>(
      `${APIConfig.approvalApi}/workflows`,
      { params },
    ).then(({ data }) => data);
  },

  fetchApprovalWorkflowInstanceById: (instanceId: string) => {
    return APIService.get<ApprovalWorkflowInstanceModel>(
      `${APIConfig.approvalApi}/workflows/${instanceId}`,
    ).then(({ data }) => data);
  },

  fetchClientTransactionApprovalWorkflow: (
    workflowId: string,
    clientGroupId: number,
  ) => {
    return APIService.get<ApprovalWorkflowInstanceModel>(
      `${APIConfig.approvalApi}/transaction/client/${clientGroupId}/workflows/${workflowId}/status`,
    ).then(({ data }) => data);
  },
};

export { approvalWorkflowAPI };
