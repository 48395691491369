import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { ForexSpreadLimitModel } from '../../typings/banking/foreignExchange';
import foreignExchangeAPIAdapter from '../../apiAdapters/finance/treasuryManagement/foreignExchangeAPIAdapter';

export interface UpdateSpreadForTreasuryModel {
  isAboveThreshold: boolean;
  spread: number;
}

export interface UpdateSpreadForClientGroupModel {
  spread: number;
}

export interface NewSpreadForTreasuryModel {
  rangeId: number;
  spread: number;
  isAboveTrheshold: boolean;
}

export interface NewSpreadForClientGroupModel {
  rangeId: number;
  spread: number;
}

const foreignExchangeAPI = {
  fetchForeignExchangeSpreadsByClientGroupId: (clientGroupId: number) => {
    return APIService.get<ForexSpreadLimitModel[]>(
      `${APIConfig.accountingApi}/client-groups/${clientGroupId}/spread-configs`,
      {
        params: { isAboveThresholdOnly: false },
      },
    ).then(({ data }) =>
      foreignExchangeAPIAdapter.fetchFeeSchedulesByClientGroupId(data),
    );
  },

  updateTreasurySpreadById: (
    spreadConfigEntryId: number,
    updatedSpread: UpdateSpreadForTreasuryModel,
  ) => {
    return APIService.patch<boolean>(
      `${APIConfig.accountingApi}/client-groups/spread-configs/${spreadConfigEntryId}`,
      updatedSpread,
    ).then(({ data }) => data);
  },

  updateClientGroupSpreadById: (
    spreadConfigEntryId: number,
    updatedSpread: UpdateSpreadForClientGroupModel,
  ) => {
    return APIService.patch<boolean>(
      `${APIConfig.accountingApi}/client-groups/spread-configs/${spreadConfigEntryId}`,
      updatedSpread,
    ).then(({ data }) => data);
  },

  createNewSpreadForClientGroup: (
    clientGroupId: string,
    newSpread: NewSpreadForClientGroupModel,
  ) => {
    return APIService.post<boolean>(
      `${APIConfig.accountingApi}/client-groups/${clientGroupId}/spread-configs`,
      newSpread,
    ).then(({ data }) => data);
  },

  deleteSpreadEntryById: (spreadId: number) => {
    return APIService.delete<boolean>(
      `${APIConfig.accountingApi}/client-groups/spread-configs/${spreadId}`,
    ).then(({ data }) => data);
  },
};

export { foreignExchangeAPI };
