import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import { StyledComponentProps } from '../../../../typings/common';

interface IconProps extends StyledComponentProps {
  component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  size?: IconSizes;
  color?: string;
}

export type IconSizes =
  | 'extra-small'
  | 'small'
  | 'middle'
  | 'large'
  | 'extra-large';

const IconSVG = memo(
  ({ component, size = 'small', color, onClick, ...rest }: IconProps) => {
    const iconSize = useMemo(() => {
      switch (size) {
        case 'extra-small': {
          return {
            width: '12px',
            height: '12px',
          };
        }

        case 'small': {
          return {
            width: '18px',
            height: '18px',
          };
        }

        case 'middle': {
          return {
            width: '32px',
            height: '32px',
          };
        }

        case 'large': {
          return {
            width: '64px',
            height: '64px',
          };
        }

        case 'extra-large': {
          return {
            width: '84px',
            height: '84px',
          };
        }
      }
    }, [size]);

    const IconComponent = component;

    return (
      <Wrapper
        width={iconSize.width}
        height={iconSize.height}
        color={color}
        $cursorPointer={!!onClick}
        onClick={onClick}
        {...rest}
      >
        <IconComponent />
      </Wrapper>
    );
  },
);

const Wrapper = styled.div<{
  width: string;
  height: string;
  color?: string;
  $cursorPointer: boolean;
}>`
  position: relative;
  display: inline-block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  cursor: ${({ $cursorPointer }) => ($cursorPointer ? 'pointer' : 'default')};

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: ${({ width }) => width} !important;
    height: ${({ height }) => height} !important;
    fill: ${({ color }) => color} !important;
  }
`;

export default IconSVG;
