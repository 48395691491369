import React from 'react';

// helpers
import useTranslation from '../../../hooks/useTranslation';

// components
import { Row, Col, Typography } from 'antd';
import ApprovalCodeAutocomplete from './ApprovalCodeAutocomplete';
import ApprovalStatusAutocomplete from './ApprovalStatusAutocomplete';

interface IProps {
  onFilterChange: (key: string, value: any) => void;
  selectedTypeValue: string;
  selectedStatusValue: string;
}

const FilterBar = ({
  onFilterChange,
  selectedTypeValue,
  selectedStatusValue,
}: IProps) => {
  const { t } = useTranslation('approval_management');

  const gridSizes = { xl: 6, lg: 8, md: 12, sm: 24, xs: 24 };

  return (
    <Row gutter={[16, 16]}>
      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('approval_workflow.filter_by_code')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <ApprovalCodeAutocomplete
              value={selectedTypeValue}
              onSelect={(newValue: string) =>
                onFilterChange('filter-by-type', newValue)
              }
            />
          </Col>
        </Row>
      </Col>

      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('approval_workflow.filter_by_status')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <ApprovalStatusAutocomplete
              value={selectedStatusValue}
              onSelect={(newValue: any) =>
                onFilterChange('filter-by-status', newValue)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FilterBar;
