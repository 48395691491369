import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { Enable2FAValidationSchema } from '../../../../validations/auth';

// components
import Form from '@core_components/Form';
import OTPField from '../../FormComponents/OTPField';
import FormField from '@core_components/FormField';
import SubmitButton from '../../../Buttons/SubmitButton';
import { Typography } from 'antd';

interface IProps {
  onSubmit: (values: FormValuesModel) => Promise<void> | void;
}

export interface FormValuesModel {
  code: string | null;
}

const OTPCodeForm = ({ onSubmit }: IProps) => {
  const { t } = useTranslation(['orac', 'common']);

  return (
    <Form<FormValuesModel>
      onSubmit={onSubmit}
      initialValues={{ code: null } as FormValuesModel}
      validationSchema={Enable2FAValidationSchema}
      renderForm={
        <ContentWrapper>
          <StyledOTPLabelText>
            {t('users.view.enter_identity_code')}
          </StyledOTPLabelText>

          <OTPFieldWrapper>
            <FormField
              name="code"
              component={StyledOTPField}
              additionalProps={{ isNumberInput: true }}
            />
          </OTPFieldWrapper>

          <SubmitButton type="primary">
            {t('verify', { ns: 'common' })}
          </SubmitButton>
        </ContentWrapper>
      }
    />
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledOTPLabelText = styled(Typography.Text)`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const OTPFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledOTPField = styled(OTPField)`
  width: 300px;
`;

export default OTPCodeForm;
