import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { OnboardType } from 'enums/crm/crm';
import { HighlightFieldStatuses } from '@core_components/FormField';
import { FormValuesModel as EntityDetailsFormValuesModel } from 'components/Forms/TemplateForms/CRM/EntityDetailsForm';

// components
import InnerContent from './InnerContent';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export type FormValuesModel = {
  changedEntryDetails: {
    id: string;
    type: OnboardType;
    name: string;
    changedDate: string;
    user: {
      id: string;
      name: string;
    };
  };
  entry: EntityDetailsFormValuesModel;
};

interface IProps
  extends RequiredPropsForFormModel<FormValuesModel>,
    RequiredPropsForModalDialogModel {
  highlightFields: Record<string, HighlightFieldStatuses> | undefined;
}

const ViewEntryChangeLogDetailsModal = ({
  isVisible,
  initialValues,
  highlightFields,
  onSubmit,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('reporting');

  return (
    <Form
      disabled
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      highlightFields={highlightFields}
      renderForm={
        <ModalDialog
          hideFooterButtons
          title={t('details_changes.details_modal.title')}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          {initialValues && <InnerContent />}
        </ModalDialog>
      }
    />
  );
};

export default ViewEntryChangeLogDetailsModal;
