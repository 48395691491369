import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { Moment } from 'moment';
import { ICurrency } from 'typings/finance/currency';
import { FilterKeys } from '../../../../../modules/Finance/Journals/JournalsTable';
import { CrmItemType } from 'enums/crm/crm';
import { IJournalModel } from '../../../../../typings/finance/journal';

// components
import TypeSelect from '../../../../Forms/FormComponents/SelectInputs/TypeSelect';
import ContentCell from './ContentCell';
import CurrencySelect from 'components/Forms/FormComponents/SelectInputs/CurrencySelect';
import AccountsSelect from '../../../../Forms/FormComponents/SelectInputs/AccountsSelect';
import CrmItemsSelect from '../../../../Forms/FormComponents/SelectInputs/CrmItemsSelect';
import TemplateTypeSelect from '../../../../Forms/FormComponents/SelectInputs/TemplateTypeSelect';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { DatePicker } from 'antd';

export type RelatedPartyFilterValue = {
  id: string;
  type: CrmItemType;
  name: string;
};

interface JournalFilterModel {
  dateRangeValue: [Moment | null, Moment | null];
  filterByAccountValue: string;
  filterByRelatedPartyValue: RelatedPartyFilterValue | null;
}

interface IProps extends RequiredPropsForTableModel<IJournalModel> {
  journalsFilter: JournalFilterModel;
  onFilterChange: (key: FilterKeys, value: any) => void;
  onActionsClick: (key: string, value: any) => void;
  canNavigateToBankAccount?: boolean;
}

const JournalsTable = memo(
  ({
    journalsFilter,
    onActionsClick,
    onFilterChange,
    canNavigateToBankAccount,
    ...rest
  }: IProps) => {
    const { t } = useTranslation('finance');

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          title: t('journals.table.date'),
          key: 'date',
          dataIndex: 'date',
          filterDropdown: (
            <FilterWrapper>
              <StyledRangePicker
                allowClear
                size="large"
                value={journalsFilter.dateRangeValue}
                onChange={(date: any) => onFilterChange('date', date)}
                disabledDate={(currentDate: Moment) =>
                  !currentDate || currentDate.isAfter(new Date())
                }
              />
            </FilterWrapper>
          ),
        },
        {
          title: t('journals.table.posting_date'),
          key: 'journalPostingDate',
          dataIndex: 'journalPostingDate',
        },
        {
          key: 'journal_id',
          title: t('journals.table.journal_id'),
          dataIndex: 'journalId',
        },
        {
          title: t('journals.table.template'),
          key: 'journal_entry_type',
          dataIndex: 'journalEntryType',
          filterDropdown: (
            <FilterWrapper>
              <TemplateTypeSelect
                onChange={(value: string) =>
                  onFilterChange('filter-by-template-type', value)
                }
              />
            </FilterWrapper>
          ),
        },
        {
          title: t('journals.table.type'),
          dataIndex: 'type',
          key: 'type',
          filterDropdown: (
            <FilterWrapper>
              <StyledTypeSelect
                onChange={(value: string) =>
                  onFilterChange('filter-by-type', value)
                }
              />
            </FilterWrapper>
          ),
        },
        {
          title: t('journals.table.account_number'),
          key: 'account_number',
          dataIndex: 'accountNumber',
          filterDropdown: (
            <StyledAccountFilterWrapper>
              <AccountsSelect
                value={journalsFilter.filterByAccountValue}
                onChange={(value: string) => {
                  onFilterChange('filter-by-account', value);
                }}
                isFullAccountNumber
              />
            </StyledAccountFilterWrapper>
          ),
        },
        {
          title: t('journals.table.account_name'),
          key: 'account_name',
          dataIndex: 'accountName',
        },
        {
          title: t('journals.table.resident'),
          key: 'isResident',
          dataIndex: 'isResident',
        },
        {
          title: t('journals.table.account_currency'),
          key: 'account_currency',
          dataIndex: 'accountCurrency',
          filterDropdown: (
            <FilterWrapper>
              <StyledCurrency
                isActive={true}
                placeholder={t('journals.table.filter_by_currency_placeholder')}
                onClear={() => onFilterChange('filter-by-currency', '')}
                onSelect={(_: any, option: ICurrency) =>
                  onFilterChange('filter-by-currency', option.isoCode)
                }
              />
            </FilterWrapper>
          ),
        },
        {
          title: (
            <DescriptionTitleRow>
              {t('journals.table.description')}
            </DescriptionTitleRow>
          ),
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: t('journals.table.debit_base'),
          key: 'debit_home',
          align: 'right',
          dataIndex: 'debitHomeAmount',
        },
        {
          title: t('journals.table.credit_base'),
          key: 'credit_home',
          align: 'right',
          dataIndex: 'creditHomeAmount',
        },
        {
          title: t('journals.table.client'),
          dataIndex: 'clientGroupName',
          key: 'client',
          filterDropdown: (
            <StyledClientFilterWrapper>
              <CrmItemsSelect
                value={journalsFilter.filterByRelatedPartyValue?.id || ''}
                initialOption={
                  journalsFilter.filterByRelatedPartyValue || undefined
                }
                onChange={(value) =>
                  onFilterChange('filter-by-related-party', value)
                }
              />
            </StyledClientFilterWrapper>
          ),
        },
      ];

      return result;
    }, [journalsFilter, canNavigateToBankAccount]);

    const getColumns = () =>
      columns.map((e) => ({
        ...e,
        onCell: (record: IJournalModel) => ({
          record,
          dataIndex: e.dataIndex,
          onActionsClick,
          canNavigateToBankAccount,
        }),
      }));

    return (
      <Table
        {...rest}
        columns={getColumns() as TableColumnModel[]}
        components={{ body: { cell: ContentCell } }}
        disableMobileMode
      />
    );
  },
);

const DescriptionTitleRow = styled.div`
  padding-left: 15px;
`;

const FilterWrapper = styled.div`
  padding: 15px;
  min-width: 220px;
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;

const StyledCurrency = styled(CurrencySelect)`
  width: 100%;
`;

const StyledTypeSelect = styled(TypeSelect)`
  width: 100%;
`;

const StyledClientFilterWrapper = styled.div`
  padding: 15px;
  min-width: 350px;
`;

const StyledAccountFilterWrapper = styled.div`
  padding: 15px;
  min-width: 570px;
`;

export default JournalsTable;
