import { EntityTypeCategoryQuery } from '../../../api/crm/keyRelationship/keyRelationshipTemplatesAPI';
import { IKeyRelationshipTemplate } from 'typings/crm/key-relationship-templates';
import { EntityTypeCategoryFormValuesModel } from 'components/Modals/TemplateModalDialogs/CRM/EntityTypeCategoryModalDialog';
import {
  AdditionalFieldType,
  OnboardType,
  RelationshipRelatedTypes,
  RelationshipTrackType,
} from 'enums/crm/crm';

function getKeyRelationOwnershipField(keyRelationOptions?: {
  additionalFields?: any;
}) {
  return keyRelationOptions?.additionalFields
    ? Object.keys(keyRelationOptions?.additionalFields)[0]
    : undefined;
}

const keyRelationshipTemplatesAdapter = {
  formatEntityTypeCategoryFromFormToQuery: (
    values: EntityTypeCategoryFormValuesModel,
  ): EntityTypeCategoryQuery => {
    return {
      name: values.name.trim(),
      description: values.description,
      isVisible: values.isOnboardingOption,
      visibleFor: values.visibleFor || OnboardType.Organization,
      additionalFields: values.additionalFields.map((e) => ({
        name: e.name,
        type: e.type as AdditionalFieldType,
        description: e.description,
        relatedTo: RelationshipRelatedTypes.Any,
        options: {
          documentRequiresCertification: e.requireCertification,
          isForRegulatedOnly: !!e.isRegulatedOnly,
          onboardingRequired: !!e.onboardingRequired,
          isDirectClientOnly: !!e.isDirectClientOnly,
          formDocumentId:
            e.type === AdditionalFieldType.Form
              ? e.formDocument[0].id
              : undefined,
          selectOptions:
            e.type === AdditionalFieldType.Select
              ? e.selectOptions.map((e) => ({
                  type: AdditionalFieldType.String,
                  valueJSON: JSON.stringify(e),
                }))
              : [],
        },
      })),
      keyRelations: values.relationshipTemplates.map((e) => ({
        relationshipTemplateId: e.id,
        track: RelationshipTrackType.Parent,
        isRequired: e.required,
        isController: e.controller,
        options: {
          additionalFields:
            e.isOwnership && e.ownershipAdditionalField
              ? {
                  [e.ownershipAdditionalField]: {
                    equalTo: 100,
                    min: e.minPercentage,
                  },
                }
              : undefined,
        },
        relationshipsMinMax: { min: +e.minAmount, max: 100 },
      })),
    };
  },

  formatEntityTypeCategoryFromQueryToForm: (
    data: IKeyRelationshipTemplate,
  ): EntityTypeCategoryFormValuesModel => {
    return {
      name: data.name,
      description: data.description,
      isOnboardingOption: data.isVisible,
      visibleFor: data.visibleFor,
      additionalFields: data.additionalFields.map((e) => ({
        name: e.name,
        onboardingRequired: e.options.onboardingRequired,
        isDirectClientOnly: e.options.isDirectClientOnly,
        isRegulatedOnly: e.options.isForRegulatedOnly,
        description: e.description || '',
        type: e.type,
        requireCertification: e.options.documentRequiresCertification,
        formDocument:
          e.type === AdditionalFieldType.Form
            ? [
                {
                  id: e.options.formDocument.id,
                  name: e.options.formDocument.files[0].name,
                  fileId: e.options.formDocument.files[0].id,
                  file: null,
                },
              ]
            : [],
        selectOptions:
          e.options.selectOptions && e.options.selectOptions.length
            ? e.options.selectOptions.map((e) => JSON.parse(e.valueJSON))
            : [],
      })),
      relationshipTemplates: data.keyRelations.map((e) => {
        const ownershipInterestField = getKeyRelationOwnershipField(e.options);

        const ownershipAdditionalFields =
          e.relationshipTemplate.additionalFields
            .filter((e) => e.type === AdditionalFieldType.Percentage)
            .map((e) => ({ id: e._id as string, name: e.name, type: e.type }));

        return {
          id: e.relationshipTemplateId,
          name: `${e.relationshipTemplate.parentName} / ${e.relationshipTemplate.childName}`,
          isOwnership: !!ownershipInterestField,
          ownershipAdditionalField: ownershipInterestField || null,
          required: e.isRequired,
          controller: e.isController,
          minAmount: e.relationshipsMinMax.min,
          minPercentage: ownershipInterestField
            ? (e.options?.additionalFields[ownershipInterestField]
                ?.min as number)
            : 0,
          ownershipAdditionalFields,
        };
      }),
    };
  },
};

export { keyRelationshipTemplatesAdapter };
