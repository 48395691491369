import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { ReviewDetailsNodeModel } from '../../../../../api/crm/clientGroup/clientGroupsAPI';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel {
  expandable: ExpandableConfig<any>;
}

const ReviewDetailsNodesTable = (props: IProps) => {
  const { t } = useTranslation('crm');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        key: 'name',
        ellipsis: true,
        width: 500,
        render: (record: ReviewDetailsNodeModel) => record.details.name,
        title: t('client_group.approval.review_details_modal.table.name'),
      },
      {
        key: 'type',
        title: t('client_group.approval.review_details_modal.table.type'),
        render: (record: ReviewDetailsNodeModel) => (
          <Typography.Text>
            {t(
              `client_group.approval.review_details_modal.approval.types.${record.details.type}`,
            )}
          </Typography.Text>
        ),
      },
    ];

    return result;
  }, []);

  return <Table columns={columns} {...props} />;
};

export default ReviewDetailsNodesTable;
