import React, { useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import { ApprovalRule } from 'components/Tables/TableTemplates/Finance/TransactionApprovalRulesMatrix';
import { onboardingAPI } from 'api/crm/onboarding/onboardingAPI';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';

// components
import ApprovalRuleDetailsModal from './ApprovalRuleDetailsModal';
import OnboardingTransactionRulesTable, {
  TableRecord,
  ActionKeys,
} from 'components/Tables/TableTemplates/CRM/OnboardingTransactionRulesTable';

interface IProps {
  clientGroupName: string;
  clientGroupNumericId: number;
}

const ApprovalRulesTab = ({
  clientGroupName,
  clientGroupNumericId,
}: IProps) => {
  const [page, setPage] = useState(1);
  const [selectedApprovalRuleId, setSelectedApprovalRuleId] = useState<
    string | null
  >(null);

  const { response, loading } = useFetch<{
    data: TableRecord[];
    total: number;
  }>(async () => {
    const data = await onboardingAPI.fetchApprovalRules(
      clientGroupNumericId,
      {
        page,
        limit: DEFAULT_TABLE_LIMIT,
      },
      'onboarding-transaction',
    );

    const formattedData = data.data.map((e) => {
      const approvalMatrix: ApprovalRule = {
        limits: e.criteriaList.map((c) => ({
          from: c.range?.min || 0,
          to: c.range?.max ? c.range.max : undefined,
          toInfinite: !c.range?.max,
        })),
        conditions: e.ruleSets.map((r, conditionIndex) => ({
          conditions: r.items.map((g) => ({
            approvalGroup: {
              name: g.group.name,
              participants: g.group.participants.map((p) => p.name),
            },
            requiredApproversCount:
              g.group.participants.length < g.minimumThreshold
                ? 0
                : g.minimumThreshold,
          })),
          limitStatuses: e.criteriaList.map((c) => ({
            isChecked: c.activeSetIndices.includes(conditionIndex),
          })),
        })),
      };

      return {
        id: e._id,
        bankAccounts: e.associatedAccounts.map((b) => ({
          name: `${clientGroupName} ${b.currencyIsoCode}`,
          purpose: b.purpose,
        })),
        approvalMatrix,
        errors:
          e.validationErrors && e.validationErrors.length
            ? e.validationErrors.map((error) => error.message)
            : [],
      };
    });

    return {
      total: data.total,
      data: formattedData,
    };
  }, [clientGroupNumericId, page, clientGroupName]);

  const handleActionsClick = (key: ActionKeys, record: TableRecord) => {
    switch (key) {
      case 'view': {
        setSelectedApprovalRuleId(record.id);
        break;
      }
    }
  };

  const handleModalCloseCallback = () => {
    setSelectedApprovalRuleId(null);
  };

  return (
    <>
      <OnboardingTransactionRulesTable
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={page}
        onPaginationChange={setPage}
        onActionsClick={handleActionsClick}
      />
      <ApprovalRuleDetailsModal
        isVisible={!!selectedApprovalRuleId}
        clientGroupId={clientGroupNumericId}
        approvalRuleId={selectedApprovalRuleId}
        closeCallback={handleModalCloseCallback}
        clientGroupName={clientGroupName}
      />
    </>
  );
};

export default ApprovalRulesTab;
