import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';
import { AdditionalFieldType } from '../../../../../../enums/crm/crm';

// components
import FormField from '@core_components/FormField';
import DocumentsField from 'components/Forms/FormComponents/DocumentsField';
import FieldTypeSelect from '../../../../../../modules/CRM/RelationhipTemplates/AddEditRelationshipTemplate/AdditionalValuesTable/FieldTypeSelect';
import { Checkbox, Input, Select } from 'antd';

const InnerForm = () => {
  const { t } = useTranslation(['crm', 'form']);

  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  const handleChangeTypeSelect = (e: any) => {
    if (e === AdditionalFieldType.Form) {
      setFieldValue('options.formDocument', null);
    }

    setFieldValue('type', e);
  };

  const renderAdditionalField = useMemo(() => {
    let result = null;
    if (values.type === AdditionalFieldType.Select) {
      result = (
        <StyledFormField
          name="options.selectOptionsValues"
          component={Select}
          label={t('client_group.approval.options')}
          placeholder={t('enterPlaceholder', {
            ns: 'form',
            fieldName: t('client_group.approval.options').toLowerCase(),
          })}
          additionalProps={{
            allowClear: true,
            mode: 'tags',
            dropdownStyle: { display: 'none' },
          }}
        />
      );
    } else if (values.type === AdditionalFieldType.Document) {
      result = (
        <StyledFormField
          label={t('client_group.approval.options')}
          name="options.documentRequiresCertification"
          component={Checkbox}
          placeholder={t('enterPlaceholder', {
            ns: 'form',
            fieldName: t('client_group.approval.options').toLowerCase(),
          })}
          additionalProps={{
            children: t('entity.relationships.requireCertification'),
          }}
        />
      );
    } else if (values.type === AdditionalFieldType.Form) {
      result = (
        <StyledFormField
          name="options.formDocument"
          label={t('client_group.approval.options')}
          placeholder={t('enterPlaceholder', {
            ns: 'form',
            fieldName: t('client_group.approval.options').toLowerCase(),
          })}
          component={DocumentsField}
        />
      );
    }
    return result;
  }, [values.type]);

  return (
    <>
      <FormField
        name="name"
        label={t('client_group.approval.title')}
        component={Input}
        placeholder={t('enterPlaceholder', {
          ns: 'form',
          fieldName: t('client_group.approval.title'),
        })}
      />

      <FormField
        name="description"
        label={t('client_group.approval.description')}
        component={Input.TextArea}
        placeholder={t('enterPlaceholder', {
          ns: 'form',
          fieldName: t('client_group.approval.description'),
        })}
      />

      <FormField
        label={t('client_group.approval.type')}
        name="type"
        handleOnChangeManually={handleChangeTypeSelect}
        component={FieldTypeSelect}
        placeholder={t('enterPlaceholder', {
          ns: 'form',
          fieldName: t('client_group.approval.type'),
        })}
        additionalProps={{ allowClear: true }}
      />

      {renderAdditionalField}
    </>
  );
};

const StyledFormField = styled(FormField)`
  margin-top: ${({ theme }) => theme.marginMd};
`;

export default InnerForm;
