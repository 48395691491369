export enum CurrencyFilterOptions {
  AllAccounts = 'all',
  IsAccount = 'true',
  NoAccounts = 'false',
}

export enum TransferTypes {
  Internal = 1,
  Wire,
}

export enum AccountProcessingTypes {
  Native = 'NATIVE',
  Circle = 'CIRCLE',
}

export enum CorrespondentBankTypes {
  Single = 'SINGLE',
  Nostro = 'NOSTRO',
}

export enum JournalEntryTemplateTypes {
  Manual = 1,
  InternalTransfer,
  WireTransfer,
  BlockchainTransfer,
  AccountFee,
  WireFee,
}

export enum ReconciliationStatuses {
  RECONCILED = 'RECONCILED',
  UNRECONCILED = 'UNRECONCILED',
}

export enum CurrencyTypes {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  USDC = 'USDC',
}

export enum JournalTypes {
  Assets = 'Assets',
  Liabilities = 'Liabilities',
  Equity = 'Equity',
  Income = 'Income',
  Expenses = 'Expenses',
}

export enum AccountTypes {
  current = 'current',
  term = 'term',
}
