import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import { ThreadWithLastMessageModel } from '../../../../../typings/messaging/messages';
import { useHistory } from 'react-router-dom';
import { messagingAPI } from '../../../../../api/messaging/messagingAPI';

// constants
import { RoutePaths } from '../../../../../routes/routes';
import { DEFAULT_TABLE_LIMIT } from '../../../../../constants/global';
import { IDocumentAssociationInfo } from '../../../Documents/UploadDocumentsDialog';

// components
import MessagesTable, {
  ActionKeys,
} from '../../../../../components/Tables/TableTemplates/Messages/MessagesTable';

interface IProps {
  clientGroupId: number;
  clientUserId?: string;
  updateTableTrigger?: any;
  association: IDocumentAssociationInfo;
}

const SentTab = ({
  clientGroupId,
  clientUserId,
  updateTableTrigger,
  association,
}: IProps) => {
  const history = useHistory();
  const [currentPage, setPage] = useState(1);

  const { response, loading } = useFetch(() => {
    return messagingAPI.fetchSentThreads(clientGroupId, {
      limit: DEFAULT_TABLE_LIMIT,
      page: currentPage,
      clientUserId,
    });
  }, [currentPage, clientGroupId, clientUserId, updateTableTrigger]);

  const handleActionsClick = (
    key: ActionKeys,
    record: ThreadWithLastMessageModel,
  ) => {
    switch (key) {
      case 'view_thread': {
        history.push({
          pathname: RoutePaths.CRM_Client_Group_Message_Thread,
          search: `?id=${record.threadId}&clientGroupId=${record?.clientGroupId}&associationId=${association?.id || ''}`,
        });
      }
    }
  };

  return (
    <MessagesTable
      current={currentPage}
      loading={loading}
      total={response?.total || 0}
      data={response?.data || []}
      onPaginationChange={setPage}
      onActionsClick={handleActionsClick}
    />
  );
};

export default SentTab;
