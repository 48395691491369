import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import moment, { Moment } from 'moment';
import { enumToMap } from '@helpers/utils';
import { darkTheme } from '@resources/theme/styled';
import { DatePicker } from 'antd';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { DateHelpers } from '@helpers/date';
import { ExpiringDocument } from 'api/crm/expiringDocuments/expiringDocumentsAPI';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { ExpiringDocumentTypes } from 'enums/banking/expiringDocuments';

// components
import Text from '@core_components/Text';
import Link from '@common_components/Texts/Link';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import DescriptionSection from '@core_components/DescriptionSection';
import ContactAutocomlete from 'components/Forms/FormComponents/Autocompletes/Compliance/ContactAutocomplete/indext';
import InfoIconWithPopover from '@common_components/Icons/InfoIconWithPopover';
import FilterDropdownOverlay from 'components/Tables/FilterDropdownOverlay';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

interface ExpiringDocumentsTableProps
  extends RequiredPropsForTableModel<ExpiringDocument> {
  onFilterChange: (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => void;
  filterValues: {
    dateRange: [Moment | null, Moment | null] | undefined;
    documentType: string[] | undefined;
    contactIds: string[] | undefined;
    search: string | undefined;
  };
}

const ExpiringDocumentsTable = memo(
  ({ filterValues, onFilterChange, ...rest }: ExpiringDocumentsTableProps) => {
    const history = useHistory();
    const { t } = useTranslation('compliance');
    const [hasContactDetailsPageAccess] = useUserAccess([
      'CRM_Contacts_Details_View',
    ]);

    const columns = useMemo(() => {
      const getFormattedDocumentDetails = (record: ExpiringDocument) => {
        let result = '';
        switch (record.type) {
          case 'passport':
            {
              result = t('expiring_documents.table.passport_details', {
                passportNumber: record.details.passportDetails.number,
              });
            }
            break;
        }
        return result;
      };

      const renderNotificationsHistory = (record: ExpiringDocument) => {
        if (!record.notifications || !record.notifications.length) {
          return null;
        }

        return (
          <StyledDescriptionSection
            data={record.notifications.map((e) => ({
              label: t('expiring_documents.table.notification_sent', {
                notification: t(
                  `expiring_documents.notification_types.${e.type}`,
                ),
              }),
              description: (
                <DivAlignCenter>
                  {DateHelpers.formatDateToString(
                    DateHelpers.formatDateToUTC(e.triggeredAt),
                  )}
                  {!e.isSent && (
                    <InfoIconWithPopover popoverContent={e.reason} />
                  )}
                </DivAlignCenter>
              ),
            }))}
            size="small"
            bordered={false}
            background="none"
          />
        );
      };

      const renderExpirationDate = (record: ExpiringDocument) => {
        if (!record.expirationDate) {
          return null;
        }

        const difference = DateHelpers.formatDateToUTC(
          record.expirationDate,
        ).diff(moment().utc(), 'months');

        let color;

        if (difference <= 0) {
          const differenceInDays = DateHelpers.formatDateToUTC(
            record.expirationDate,
          ).diff(moment().utc(), 'days');
          color =
            differenceInDays <= 0
              ? darkTheme.errorColor
              : darkTheme.warningColor;
        } else if (difference <= 2) {
          color = darkTheme.warningColor;
        }

        return (
          <Text color={color}>
            {record.expirationDate
              ? DateHelpers.formatDateToString(
                  DateHelpers.formatDateToUTC(record.expirationDate),
                )
              : ''}
          </Text>
        );
      };

      const result: TableColumnModel[] = [
        {
          width: 250,
          key: 'contact_name',
          title: t('expiring_documents.table.contact_name'),
          filteredValue: filterValues.contactIds,
          filterDropdown: (filterProps: FilterDropdownProps) => {
            return (
              <FilterDropdownOverlay filterProps={filterProps}>
                <StyledContactAutocomlete
                  value={filterProps.selectedKeys as any}
                  onChange={(value) =>
                    filterProps.setSelectedKeys(value as any)
                  }
                />
              </FilterDropdownOverlay>
            );
          },
          render: (record: ExpiringDocument) => (
            <EllipsisTooltip
              maxTextContainerWidth="250px"
              title={record.entity.name}
            >
              {hasContactDetailsPageAccess ? (
                <Link
                  onClick={() =>
                    history.push({
                      pathname: RoutePaths.CRM_Contacts_Edit,
                      search: `?id=${record.entity.id}`,
                    })
                  }
                >
                  {record.entity.name}
                </Link>
              ) : (
                record.entity.name
              )}
            </EllipsisTooltip>
          ),
        },

        {
          width: 200,
          key: 'document_type',
          title: t('expiring_documents.table.document_type'),
          render: (record: ExpiringDocument) =>
            t(`expiring_documents.types.${record.type}`),
          filteredValue: filterValues.documentType,
          filterSearch: true,
          filterMultiple: false,
          filters: Array.from(enumToMap(ExpiringDocumentTypes)).map((e) => ({
            text: t(`expiring_documents.types.${e[1]}`),
            value: e[1],
          })),
        },

        {
          width: 250,
          key: 'document_details',
          title: t('expiring_documents.table.document_details'),
          render: (record: ExpiringDocument) => {
            const formattedValue = getFormattedDocumentDetails(record);
            return (
              <EllipsisTooltip
                maxTextContainerWidth="250px"
                title={formattedValue}
              >
                {formattedValue}
              </EllipsisTooltip>
            );
          },
        },

        {
          width: 250,
          key: 'expiry_date',
          title: t('expiring_documents.table.expiration_date'),
          render: renderExpirationDate,
          filteredValue: filterValues.dateRange,
          filterDropdown: (filterProps: FilterDropdownProps) => {
            return (
              <FilterDropdownOverlay filterProps={filterProps}>
                <StyledRangePicker
                  allowClear
                  size="large"
                  value={filterProps.selectedKeys as any}
                  onChange={(value) =>
                    filterProps.setSelectedKeys(value as any)
                  }
                />
              </FilterDropdownOverlay>
            );
          },
        },

        {
          width: 350,
          key: 'notification_history',
          title: t('expiring_documents.table.notification_history'),
          render: renderNotificationsHistory,
        },
      ];

      return result;
    }, [t, hasContactDetailsPageAccess, filterValues]);

    return (
      <Table {...rest} columns={columns} onFilterChange={onFilterChange} />
    );
  },
);

const StyledDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-label {
    width: 100px !important;
    max-width: 100px !important;
    min-width: 100px !important;
  }
`;

const StyledRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;

const StyledContactAutocomlete = styled(ContactAutocomlete)`
  width: 100%;
`;

export default ExpiringDocumentsTable;
