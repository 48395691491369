import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { FormikContextType, useFormikContext } from 'formik';

// components
import BankForm from './BankForm';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import CountryFormSelect from 'components/Forms/FormComponents/SelectInputs/CountryFormSelect';
import { Divider, Switch } from 'antd';

export type BankModel = {
  bankCodeType: number;
  bankCode: string;
  bankName: string;
  country: string;
  address: string;
  city: string;
  district: string;
  postCode: string;
};

export type BeneficiaryModel = {
  address: string;
  accountName: string;
  accountNumber: string;
  city: string;
  country: string;
  district: string;
  postCode: string;
};

export interface FormValuesModel {
  id?: string;

  isManualBeneficiary: boolean;

  beneficiary: BeneficiaryModel;

  beneficiaryBank: BankModel;
  intermediaryBank: BankModel;
}

interface IProps {
  loading?: boolean;
  onToggle?: (
    value: boolean,
    helpers: FormikContextType<FormValuesModel>,
  ) => void;
}

const gridSizes = { xl: 4, lg: 4, md: 5, sm: 24, xs: 24 };

const UpdateSSIForm = ({ loading, onToggle }: IProps) => {
  const { t } = useTranslation('finance');

  const form = useFormikContext<FormValuesModel>();
  return (
    <>
      <FormField
        name="isManualBeneficiary"
        label={t('accounts.wire_instructions_details.is_manual_beneficiary')}
        onChange={(e) => onToggle && onToggle(e, form)}
        additionalProps={{
          value: form.values.isManualBeneficiary,
          checked: !form.values.isManualBeneficiary,
          loading,
        }}
        component={Switch}
      />
      <LoadingWrapper loading={!!loading}>
        {form.values.isManualBeneficiary && (
          <>
            <Divider orientation="left">
              {t('accounts.wire_instructions_details.beneficiary.title')}
            </Divider>

            <FormField
              name="beneficiary.accountName"
              component={FormInput}
              label={t(
                'accounts.wire_instructions_details.beneficiary.form_fields.account_name',
              )}
              labelCol={gridSizes}
            />

            <FormField
              name="beneficiary.accountNumber"
              component={FormInput}
              label={t(
                'accounts.wire_instructions_details.beneficiary.form_fields.account_number',
              )}
              labelCol={gridSizes}
            />

            <FormField
              name="beneficiary.country"
              component={CountryFormSelect}
              label={t(
                'accounts.wire_instructions_details.beneficiary.form_fields.country',
              )}
              labelCol={gridSizes}
            />

            <FormField
              name="beneficiary.address"
              component={FormInput}
              label={t(
                'accounts.wire_instructions_details.beneficiary.form_fields.address',
              )}
              labelCol={gridSizes}
            />

            <FormField
              name="beneficiary.city"
              component={FormInput}
              label={t(
                'accounts.wire_instructions_details.beneficiary.form_fields.city',
              )}
              labelCol={gridSizes}
            />

            <FormField
              name="beneficiary.district"
              component={FormInput}
              label={t(
                'accounts.wire_instructions_details.beneficiary.form_fields.district',
              )}
              labelCol={gridSizes}
            />

            <FormField
              name="beneficiary.postCode"
              component={FormInput}
              label={t(
                'accounts.wire_instructions_details.beneficiary.form_fields.postcode',
              )}
              labelCol={gridSizes}
            />
          </>
        )}
        <Divider orientation="left">
          {t('accounts.wire_instructions_details.beneficiary_bank.title')}
        </Divider>
        <BankForm fieldBaseName="beneficiaryBank" />
        {!form.values.isManualBeneficiary && (
          <>
            <Divider orientation="left">
              {t('accounts.wire_instructions_details.intermediary_bank.title')}
            </Divider>
            <BankForm fieldBaseName="intermediaryBank" />
          </>
        )}
      </LoadingWrapper>
    </>
  );
};

export default UpdateSSIForm;
