import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ApprovalGroupModel } from 'typings/approval/templates';

// components
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

interface IProps {
  data: ApprovalGroupModel;
}

const InnerTemplate = ({ data }: IProps) => {
  const { t } = useTranslation('approval_management');

  const descriptionsData = useMemo<DescriptionItem[]>(() => {
    return [
      {
        label: t(
          'approval_workflow.approval_workflow_changes.approval_group_changes_template.name',
        ),
        description: data.name,
      },

      {
        label: t(
          'approval_workflow.approval_workflow_changes.approval_group_changes_template.members',
        ),
        description: data.participants.map((x) => x.userFullName).join(', '),
      },

      {
        label: t(
          'approval_workflow.approval_workflow_changes.approval_group_changes_template.description',
        ),
        description: data.description,
      },
    ];
  }, [data]);

  return <DescriptionSection data={descriptionsData} />;
};

export default InnerTemplate;
