import React from 'react';

// helpers
import { UpdateSecurityKeyValidationSchema } from '../../../../../validations/auth';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  title: string;
  initialFormValues: FormValuesModel | null;
  onSubmit: (values: FormValuesModel) => Promise<void>;
}

export enum RegisterSecurityKeySteps {
  Initial,
  RegisterKey,
  SetupNameForKey,
}

export interface FormValuesModel {
  name: string;
}

const SecurityKeyModal = ({
  title,
  isVisible,
  closeCallback,
  onSubmit,
  initialFormValues,
}: IProps) => {
  const handleSubmit = async (values: FormValuesModel) => {
    await onSubmit(values);
  };

  return (
    <Form
      enableReinitialize
      confirmExitWithoutSaving
      onSubmit={handleSubmit}
      initialValues={isVisible ? initialFormValues : null}
      validationSchema={UpdateSecurityKeyValidationSchema}
      renderForm={
        <ModalDialog
          width={600}
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          {initialFormValues && <InnerForm />}
        </ModalDialog>
      }
    />
  );
};

export default SecurityKeyModal;
