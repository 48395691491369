import React from 'react';

// helpers utils
import useTranslation from '../../../../../../hooks/useTranslation';
import { enumToMap } from '../../../../../../helpers/utils';
import { SelectProps } from 'antd/lib/select';
import { RiskLevelTypes } from '../../../../../../enums/compliance/reviewConfiguration';

// components
import { Select } from 'antd';

type IProps = SelectProps<any>;

const RiskLevelSelect = (props: IProps) => {
  const { t } = useTranslation('compliance');

  const renderOptions = () => {
    return Array.from(enumToMap(RiskLevelTypes)).map((el) => (
      <Select.Option key={el[1]} value={el[1]}>
        {t(`risk_level_types.${el[0]}`)}
      </Select.Option>
    ));
  };

  return (
    <Select {...props} placeholder={t('review_configuration.risk_level_ph')}>
      {renderOptions()}
    </Select>
  );
};

export default RiskLevelSelect;
