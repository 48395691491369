import React, { useState } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import transactionsAPI from '../../../api/compliance/transactionsAPI';
import { Moment } from 'moment';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../routes/routes';
import { DEFAULT_TABLE_LIMIT } from '../../../constants/global';
import {
  ComplianceReviewTypes,
  ReviewProcessStatuses,
} from 'enums/compliance/reviewConfiguration';

// components
import FilterBar, { FilterKeys } from './FilterBar';
import { Divider } from 'antd';
import {
  default as TransactionsTemplateTable,
  ActionTypes,
} from '../../../components/Tables/TableTemplates/Compliance/TransactionsTable';

const TransactionsTable = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterValues, setFilterValues] = useState<{
    status: ReviewProcessStatuses[] | undefined;
    type: ComplianceReviewTypes[] | undefined;
    valueDate: [Moment, Moment] | undefined;
    transactionDate: [Moment, Moment] | undefined;
    accountName: string[] | undefined;
  }>({
    status: [
      ReviewProcessStatuses.Empty,
      ReviewProcessStatuses.InProgress,
      ReviewProcessStatuses.PendingApproval,
      ReviewProcessStatuses.New,
    ],
    type: undefined,
    valueDate: undefined,
    transactionDate: undefined,
    accountName: undefined,
  });

  const { response, loading } = useFetch(
    () =>
      transactionsAPI.fetchReviewProcesses({
        page,
        limit: DEFAULT_TABLE_LIMIT,
        transactionSearch: searchQuery || undefined,
        types: filterValues.type
          ? filterValues.type.join(',')
          : [
              ComplianceReviewTypes.OutgoingTransaction,
              ComplianceReviewTypes.IncomingTransaction,
            ].join(','),
        reviewProcessStatuses: filterValues.status
          ? filterValues.status.join(',')
          : undefined,
        transactionFromValueDate:
          filterValues.valueDate && filterValues.valueDate[0]
            ? filterValues.valueDate[0].startOf('day').unix()
            : undefined,
        transactionToValueDate:
          filterValues.valueDate && filterValues.valueDate[1]
            ? filterValues.valueDate[1].endOf('day').unix()
            : undefined,
        transactionFromPostingDate:
          filterValues.transactionDate && filterValues.transactionDate[0]
            ? filterValues.transactionDate[0].startOf('day').unix()
            : undefined,
        transactionToPostingDate:
          filterValues.transactionDate && filterValues.transactionDate[1]
            ? filterValues.transactionDate[1].endOf('day').unix()
            : undefined,
        transactionAccountNumbers: filterValues.accountName
          ? filterValues.accountName.join(',')
          : undefined,
      }),
    [page, searchQuery, filterValues],
  );

  const handleActionsClick = (key: ActionTypes, requestId: string) => {
    switch (key) {
      case 'view_details':
        history.push({
          pathname: RoutePaths.Compliance_Transactions_Details,
          search: `?id=${requestId}`,
        });
        break;
    }
  };

  const handleFilterBarChange = (key: FilterKeys, value: string) => {
    switch (key) {
      case 'search':
        setSearchQuery(value ? value.trim() : '');
        break;
    }
  };

  const handleFilterChange = (key: string, value: unknown | undefined) => {
    switch (key) {
      case 'transaction_status':
        setFilterValues((prev) => ({
          ...prev,
          status: value as ReviewProcessStatuses[],
        }));
        break;

      case 'transaction_type':
        setFilterValues((prev) => ({
          ...prev,
          type: value as ComplianceReviewTypes[],
        }));
        break;

      case 'value_date':
        setFilterValues((prev) => ({
          ...prev,
          valueDate: (value as [Moment, Moment]) || undefined,
        }));
        break;

      case 'transaction_date':
        setFilterValues((prev) => ({
          ...prev,
          transactionDate: (value as [Moment, Moment]) || undefined,
        }));
        break;

      case 'accountName':
        setFilterValues((prev) => ({
          ...prev,
          accountName: (value as string[]) || undefined,
        }));
        break;
    }
  };

  const onFilterChange = (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => {
    if (Object.keys(filters).length) {
      Object.keys(filters).forEach((key) =>
        handleFilterChange(key as FilterKeys, filters[key] as any),
      );
    }
  };

  return (
    <>
      <FilterBar onFilterBarChange={handleFilterBarChange} />
      <Divider />
      <TransactionsTemplateTable
        data={response?.data || []}
        current={page}
        total={response?.total || 0}
        loading={loading}
        filters={filterValues}
        onFilterChange={onFilterChange}
        onActionsClick={handleActionsClick}
        onPaginationChange={setPage}
      />
    </>
  );
};

export default TransactionsTable;
