import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { useReviewProcessContext } from '../../../../hooks/domainHooks/useReviewProcess';

// components
import SectionTitle from '../../../../components/Typography/SectionTitle';
import ReviewProgressContainer from './ReviewProgressContainer';
import IncomingTransactionDetails from 'components/InfoContainers/Accounting/IncomingTransactionDetails';
import OutgoingTransactionDetails from 'components/InfoContainers/Accounting/OutgoingTransactionDetails';
import { Col, Row } from 'antd';

const ReviewProcessInfoSection = memo(() => {
  const { t } = useTranslation('compliance');
  const { transaction, incomingTransaction, outgoingTransaction, workflow } =
    useReviewProcessContext();

  const transferInfoGridSize = { xl: 14, lg: 24, md: 24, sm: 24, xs: 24 };
  const progressSectionGridSize = { xl: 10, lg: 24, md: 24, sm: 24, xs: 24 };

  const transactionInfo = useMemo(() => {
    if (outgoingTransaction && transaction) {
      return (
        <OutgoingTransactionDetails
          transaction={transaction}
          outgoingTransaction={outgoingTransaction}
          workflow={workflow}
        />
      );
    }

    if (incomingTransaction) {
      return (
        <IncomingTransactionDetails incomingTransaction={incomingTransaction} />
      );
    }

    return null;
  }, [incomingTransaction, outgoingTransaction]);

  return (
    <Row gutter={[24, 24]}>
      <Col {...transferInfoGridSize}>
        <SectionTitle>{t('transactions.transfer_info')}</SectionTitle>
        {transactionInfo}
      </Col>

      <Col {...progressSectionGridSize}>
        <SectionTitle>{t('transactions.review_progress')}</SectionTitle>
        <ReviewProgressContainer />
      </Col>
    </Row>
  );
});

export default ReviewProcessInfoSection;
