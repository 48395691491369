import React, { useEffect, useState } from 'react';

// helpers
import rolesAPI from '../../../api/orac/rolesAPI';
import useFetch from '../../../hooks/useFetch';
import useQueryParam from '../../../hooks/useQueryParam';
import useUserAccess from '../../../hooks/useUserAccess';
import useTranslation from '../../../hooks/useTranslation';
import { RoutePaths } from '../../../routes/routes';

// components
import EditRole from '../../../modules/ORAC/Roles/EditRole';
import LoadingWrapper from '../../../components/WrapperComponents/LoadingWrapper';
import { default as RoleDetailsContent } from '../../../modules/ORAC/Roles/RoleDetails';

interface IProps {
  setHeaderOptions: (options: any) => void;
}

const RoleDetails = ({ setHeaderOptions }: IProps) => {
  const { t } = useTranslation('page_titles');

  const [hasUpdateAccess] = useUserAccess(['ORAC_Roles_Details_Update']);

  const [updateUserTrigger, updateRole] = useState({});

  const roleId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.ORAC_Roles,
  });

  const { response, loading } = useFetch(
    () => (roleId ? rolesAPI.getRoleById(roleId) : null),
    [roleId, updateUserTrigger],
  );

  useEffect(() => {
    setHeaderOptions({
      title: `${t('Role')}${response ? ': ' + response.name : ''}`,
      extra:
        hasUpdateAccess && response
          ? () => <EditRole onEdit={() => updateRole({})} role={response} />
          : undefined,
    });
  }, [response, hasUpdateAccess]);

  return (
    <LoadingWrapper loading={loading}>
      {!!response && <RoleDetailsContent role={response} />}
    </LoadingWrapper>
  );
};

export default RoleDetails;
