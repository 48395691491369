import * as yup from 'yup';
import {
  NULLABLE_STRING_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
} from 'validations/common';

export const NewNoteValidationSchema = yup.object().shape({
  eventDate: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  type: STRING_VALIDATION_RULE.required('form:validation_messages.required'),
  text: STRING_VALIDATION_RULE.min(5, {
    key: 'form:validation_messages.min_characters_with_label',
    values: { label: 'entity.notes.note', number: 5 },
  })
    .max(10000, {
      key: 'form:validation_messages.max_characters_with_label',
      values: { label: 'entity.notes.note', number: 10000 },
    })
    .required('form:validation_messages.required'),
});
