import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { ReconciliationStatuses } from '../../../../../enums/finance/finance';

// components
import InnerForm from './InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export type FormValuesModel = {
  id: number;
  status: ReconciliationStatuses;
  message: string;
};

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  isViewOnly?: boolean;
}

const UpdateReconciliationStatusForJournalEntryModal = memo(
  ({ isVisible, closeCallback, onSubmit, initialValues }: IProps) => {
    const { t } = useTranslation('finance');

    return (
      <Form<FormValuesModel>
        confirmExitWithoutSaving
        onSubmit={onSubmit}
        enableReinitialize
        renderForm={
          <ModalDialog
            width="900px"
            title={t('update_reconciliation_status.update_status_modal.title')}
            isVisible={isVisible}
            closeCallback={closeCallback}
          >
            <InnerForm />
          </ModalDialog>
        }
        initialValues={isVisible ? initialValues : null}
      />
    );
  },
);

export default UpdateReconciliationStatusForJournalEntryModal;
