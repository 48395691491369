import React from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../../hooks/useFetch';
import useTranslation from '../../../../../../hooks/useTranslation';
import { AuthHelpers } from '../../../../../../helpers/auth';
import {
  AssertionForLoginModel,
  GetChallengeForLoginResponseModel,
} from '../../../../../../typings/profile/fido';

// components
import Content from './Content';
import BrowserIsNotSupported from '../../../../../Additional/Auth/TwoFactorVerification/BrowserIsNotSupported';
import { Typography } from 'antd';

interface IProps {
  onAssert: () => void;
  getChallenge: () => Promise<GetChallengeForLoginResponseModel>;
  submitAssertion: (assertion: AssertionForLoginModel) => Promise<any>;
}

const SignChallenge = ({ getChallenge, submitAssertion, onAssert }: IProps) => {
  const canUseWebAuthn = AuthHelpers.isWebAuthnSupported();
  const { t } = useTranslation('profile');

  const { response: challenge, loading } = useFetch(
    () => (canUseWebAuthn ? getChallenge() : null),
    [getChallenge],
  );

  return (
    <>
      <StyledTitle>
        {t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_modal.register_key_step.title',
        )}
      </StyledTitle>

      {canUseWebAuthn ? (
        <Content
          loadingChallenge={loading}
          challenge={challenge}
          onAssert={onAssert}
          submitAssertion={submitAssertion}
        />
      ) : (
        <BrowserIsNotSupported />
      )}
    </>
  );
};

const StyledTitle = styled(Typography.Paragraph)`
  text-align: center;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizeMd};
  color: ${({ theme }) => theme.whiteColor};
`;

export default SignChallenge;
