import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../../../../hooks/useFetch';
import objectsAPI from '../../../../../../../../api/orac/objectsAPI';
import useTranslation from '../../../../../../../../hooks/useTranslation';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../../../../../constants/global';

// components
import FilterBar from './FilterBar';
import InnerList from './InnerList';
import { Card, Divider } from 'antd';

interface IProps {
  canSetForbiddenPermissions?: boolean;
  onlyForbiddenPermissions?: boolean;
}

const PermissionsTab = ({
  canSetForbiddenPermissions,
  onlyForbiddenPermissions,
}: IProps) => {
  const { t } = useTranslation('orac');

  const [page, setPage] = useState(1);
  const [searchByName, setSearchByName] = useState('');

  const { response, loading } = useFetch(
    () =>
      objectsAPI.fetchObjectsWithPermissions({
        page,
        limit: DEFAULT_TABLE_LIMIT,
        name: searchByName,
      }),
    [page, searchByName],
  );

  const handleFilterBarChange = (key: string, value: string) => {
    if (page > 1) {
      setPage(1);
    }

    if (key === 'search_by_name') {
      setSearchByName(value || '');
    }
  };

  return (
    <StyledCard title={t('permissions.edit.permissions')}>
      <FilterBar onFilterChange={handleFilterBarChange} />

      <Divider />

      <InnerList
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={page}
        onPaginationChange={setPage}
        showForbiddenColumn={canSetForbiddenPermissions}
        showForbiddenOnly={onlyForbiddenPermissions}
      />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  .ant-card-body {
    padding-top: 0px;
    padding-bottom: 10px;
  }
`;

export default PermissionsTab;
