import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { ExpandableConfig } from 'antd/lib/table/interface';

// component
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

export type GeneralLedgerItem = {
  accountName: string;
  accountNumber: string;
  financialType: string;
  accountCategoryCode: string;
  debitAmount: string;
  creditAmount: string;
  netMovement: string;
};

interface IProps extends RequiredPropsForTableModel<GeneralLedgerItem> {
  expandable?: ExpandableConfig<any>;
}

const GeneralLedgerTable = ({ ...rest }: IProps) => {
  const { t } = useTranslation('reporting');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: 320,
        key: 'account_name',
        title: t('general_ledger.account_name'),
        render: (record: GeneralLedgerItem) => (
          <EllipsisTooltip
            title={record.accountName}
            maxTextContainerWidth="320px"
          >
            {record.accountName}
          </EllipsisTooltip>
        ),
      },

      {
        key: 'account_number',
        title: t('general_ledger.account_number'),
        render: (record: GeneralLedgerItem) => record.accountNumber,
      },

      {
        key: 'finance_type',
        title: t('general_ledger.financial_type'),
        render: (record: GeneralLedgerItem) => record.financialType,
      },

      {
        width: 320,
        key: 'account_category_code',
        title: t('general_ledger.account_category_code'),
        render: (record: GeneralLedgerItem) => (
          <EllipsisTooltip
            title={record.accountCategoryCode}
            maxTextContainerWidth="320px"
          >
            {record.accountCategoryCode}
          </EllipsisTooltip>
        ),
      },

      {
        key: 'debit_amount',
        title: t('general_ledger.debit_amount'),
        render: (record: GeneralLedgerItem) => record.debitAmount,
      },

      {
        key: 'credit_amount',
        title: t('general_ledger.credit_amount'),
        render: (record: GeneralLedgerItem) => record.creditAmount,
      },

      {
        key: 'net_movement',
        title: t('general_ledger.net_movement'),
        render: (record: GeneralLedgerItem) => record.netMovement,
      },
    ];

    return result;
  }, [t]);

  return <Table columns={columns} {...rest} />;
};

export default GeneralLedgerTable;
