import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { CashFlowDataReportModel } from 'apiAdapters/reporting/reportingAPIAdapter';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel;

const CashFlowTable = ({ ...rest }: IProps) => {
  const { t } = useTranslation('reporting');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: 400,
        key: 'name',
        title: t('cash_flow.table.name'),
        render: (record: CashFlowDataReportModel) => record.name,
      },

      {
        key: 'countryEntries',
        title: t('cash_flow.table.country_payment_system'),
        render: (record: CashFlowDataReportModel) =>
          record.countryEntries.length
            ? record.countryEntries.map((value, index: number) => (
                <div key={index}>{value}</div>
              ))
            : '—',
      },

      {
        key: 'totalValues',
        title: t('cash_flow.table.total_value'),
        render: (record: CashFlowDataReportModel) =>
          record.totalValues.length
            ? record.totalValues.map((value, index: number) => (
                <div key={index}>{value}</div>
              ))
            : '—',
      },

      {
        key: 'totalTransactions',
        title: t('cash_flow.table.total_transaction_number'),
        render: (record: CashFlowDataReportModel) =>
          record.totalTransactions.length
            ? record.totalTransactions.map((value, index: number) => (
                <div key={index}>{value}</div>
              ))
            : '—',
      },
    ];

    return result;
  }, [t]);

  return <Table size="small" columns={columns} {...rest} />;
};

export default CashFlowTable;
