import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';
import {
  CrmItemModel,
  relationshipAPI,
} from '../../../../../../api/crm/relationship/relationshipAPI';

// components
import Autocomplete, {
  AutocompleteInitialValue,
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';
import { Typography } from 'antd';

interface IProps {
  name: string;
  disabled?: boolean;
  allowClear?: boolean;
  initialValue?: AutocompleteInitialValue;
  isVendor?: boolean;
  includeClientGroups?: boolean;
  onSelect?: (id: string, model: any) => void;
}

const RelationshipEntryAutocomplete = ({
  allowClear = false,
  isVendor,
  includeClientGroups,
  onSelect,
  ...rest
}: IProps) => {
  const { t } = useTranslation('crm');

  const fetchCrmItems = async (search: string) => {
    const crmItemsResult = await relationshipAPI.getCrmItems({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      search,
      isVendor,
      includeClientGroups,
    });
    return crmItemsResult.result.map<AutocompleteOption>((e) => ({
      id: e._id,
      content: (
        <>
          <Typography.Text>{e.name}</Typography.Text>
          <Typography.Text type="secondary">
            {' '}
            ({t(`association_types.${e.type}`)})
          </Typography.Text>
        </>
      ),
      type: e.type,
      model: e,
    }));
  };

  const handleSelect = (id: string, model: CrmItemModel) => {
    onSelect && onSelect(id, model);
  };

  return (
    <Autocomplete
      {...rest}
      fetchData={fetchCrmItems}
      onSelect={handleSelect}
      allowClear={allowClear}
    />
  );
};

export default RelationshipEntryAutocomplete;
