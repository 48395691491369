import React, { useMemo } from 'react';

// Helpers & Utils
import moment from 'moment';
import useFetch from '../../../../hooks/useFetch';
import useUserAccess from '../../../../hooks/useUserAccess';
import useTranslation from '../../../../hooks/useTranslation';
import { message } from 'antd';
import { journalsAPI } from '../../../../api/finance/journal/journalsAPI';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { journalsAdapter } from '../../../../apiAdapters/finance/journal/journalsAdapter';
import { JournalEntryModel } from '../../../../components/Modals/TemplateModalDialogs/Finance/AddViewJournalModal/InnerForm/JournalForm';

// components
import {
  default as TemplateViewJournalModal,
  JournalFormValues,
} from '../../../../components/Modals/TemplateModalDialogs/Finance/AddViewJournalModal';

interface IProps {
  journalId?: number;
  isVisible: boolean;
  closeCallback: (shouldUpdate?: boolean) => void;
}

const AddViewJournalModal = ({
  isVisible,
  closeCallback,
  journalId,
}: IProps) => {
  const { t } = useTranslation('finance');
  const { response } = useFetch(
    () => (journalId ? journalsAPI.fetchJournalById(journalId) : null),
    [journalId],
  );
  const [hasUpdateAccess, hasReverseAccess] = useUserAccess([
    'Finance_Journals_Update',
    'Finance_Journals_Reverse',
  ]);

  const modalDialogTitle = useMemo(() => {
    return journalId ? t('journals.journal_details') : t('journals.new.title');
  }, [journalId, t]);

  const initialFormValues = useMemo<JournalFormValues | null>(() => {
    if (!isVisible) {
      return null;
    }

    const initialEntry: JournalEntryModel = {
      description: '',
      accountNumber: '',
      isVisibleForClient: false,
      relatedPartyId: undefined,
      isResident: null,
      relatedPartyType: undefined,
      documents: [],
    };

    let result: JournalFormValues | null = null;

    if (journalId && response) {
      result = {
        isReversalFrom: response.isReversal
          ? response.reversalJournalId
          : undefined,
        journalId,
        isReversalJournal: false,
        journalValueDate: moment.unix(response.valueDate),
        journal: {
          entries: AccountsHelpers.formatJournalEntryToFormJournalEntry(
            response.journalEntries,
          ),
        },
        isEditMode: false,
      };
    } else if (!journalId) {
      result = {
        journalValueDate: moment(),
        journal: {
          entries: [{ ...initialEntry }, { ...initialEntry }],
        },
        isReversalJournal: false,
        isReversalFrom: undefined,
        isEditMode: false,
      };
    }

    return result;
  }, [response, journalId, isVisible]);

  const handleSubmit = async (values: JournalFormValues) => {
    if (values.isEditMode && !values.isReversalJournal) {
      await journalsAPI.updateJournalEntries(
        journalsAdapter.generateUpdateJournalRequestBody(values),
      );
      message.success(t('journals.update.success_update'));
    } else {
      await journalsAPI.createJournal(
        journalsAdapter.generateRequestBody(values),
      );
      message.success(t('journals.new.success_create'));
    }
  };

  return (
    <TemplateViewJournalModal
      title={modalDialogTitle}
      canEdit={hasUpdateAccess}
      onSubmit={handleSubmit}
      isVisible={isVisible}
      canReverse={hasReverseAccess}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
    />
  );
};

export default AddViewJournalModal;
