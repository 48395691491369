import React, { memo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { FormValuesModel } from '../../ContactDetailsWithIdentificationForm';
import { useFormikContext } from 'formik';

// components
import AddressItem from './AddressItem';

const AddressesSection = memo(() => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  if (!values.addresses.length) {
    return t('contacts.new.addresses_are_not_provided');
  }

  return (
    <>
      {values.addresses.map((e, i) => (
        <AddressItem key={e.id} fieldName={`addresses.${i}`} />
      ))}
    </>
  );
});

export default AddressesSection;
