import React, { useEffect, useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { Moment } from 'moment';
import { reportingAPI } from '../../../../api/reporting/reportingAPI';
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';

// components
import LoadingWrapper from '../../../../components/WrapperComponents/LoadingWrapper';
import TrialBalanceCurrencyEntriesTable from '../../../../components/Tables/TableTemplates/Reporting/TrialBalanceCurrencyEntriesTable';

interface IProps {
  currencyId: number;
  filterDate: Moment;
}

const TrialBalanceEntriesTable = ({ currencyId, filterDate }: IProps) => {
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    if (current > 1) {
      setCurrent(1);
    }
  }, [filterDate]);

  const { response, loading } = useFetch(
    () =>
      currencyId
        ? reportingAPI.fetchTrialBalanceEntriesReport(
            {
              toDate: filterDate.format('X'),
              page: current,
              limit: DEFAULT_TABLE_LIMIT,
            },
            currencyId,
          )
        : null,
    [filterDate, currencyId, current],
  );

  return (
    <LoadingWrapper loading={!response && loading}>
      {response && (
        <TrialBalanceCurrencyEntriesTable
          data={response?.data || []}
          total={response?.total || 0}
          loading={loading}
          current={current}
          onPaginationChange={setCurrent}
        />
      )}
    </LoadingWrapper>
  );
};

export default TrialBalanceEntriesTable;
