import React, { memo, useMemo } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '../../../hooks/useTranslation';
import { message } from 'antd';
import { FormikHelpers } from 'formik';
import { bankingTransactionsAPI } from '../../../api/banking/bankingTransactionsAPI';
import { bankingTransactionsAPIAdapter } from '../../../apiAdapters/banking/bankingTransactionsAPIAdapter';
import {
  TransactionReviewStatuses,
  TransactionTypes,
} from '../../../enums/banking/transactions';

// components
import TransactionModalDialog, {
  FormValuesModel,
} from '../../../components/Modals/TemplateModalDialogs/Banking/TransactionModalDialog';

interface IProps {
  transactionId: number | null;
  onModalClose: (wasSubmitted?: boolean) => void;
}

const ViewEditTransaction = memo(({ transactionId, onModalClose }: IProps) => {
  const [hasEditAccess] = useUserAccess(['Banking_Transactions_Update']);
  const { t } = useTranslation(['banking', 'server_errors']);
  const { response } = useFetch(
    () =>
      transactionId
        ? bankingTransactionsAPI.fetchTransactionById(transactionId)
        : null,
    [transactionId],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!response || !transactionId) {
      return null;
    }

    return {
      submitAction: null,
      status: response.statusId,
      transactionId,
      transactionType: response.transactionType as TransactionTypes,
      incomingTransaction:
        response.transactionType === TransactionTypes.Incoming
          ? bankingTransactionsAPIAdapter.formatIncomingTransactionFromQueryToFormData(
              response,
            )
          : null,
      outgoingTransaction:
        response.transactionType === TransactionTypes.Outgoing
          ? bankingTransactionsAPIAdapter.formatOutgoingTransactionFromQueryToFormData(
              response,
            )
          : null,
    };
  }, [response, transactionId]);

  const handleSubmit = async (
    values: FormValuesModel,
    formHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    if (transactionId) {
      try {
        if (values.submitAction !== 'transmit') {
          const formattedTransaction =
            bankingTransactionsAPIAdapter.addTransaction(values);
          await bankingTransactionsAPI.updateTransactionById(
            transactionId,
            formattedTransaction,
          );
          message.success(
            t(
              'banking.transactions.add_transaction.edit_modal.success_message',
            ),
          );
        } else {
          await bankingTransactionsAPI.transmitOutgoingTransactionById(
            transactionId,
            values.outgoingTransaction?.general?.senderReference || '',
          );
          message.success(
            t(
              'banking.transactions.add_transaction.add_modal.success_transmit',
            ),
          );
        }
        // TODO: remove close callback call when we update this modal dialog to use new components
        onModalClose(true);
      } catch (error: any) {
        if (error && error?.response?.data?.code) {
          switch (error.response.data.code) {
            // Beneficiary customer account number is invalid
            case '1313061':
              {
                formHelpers.setFieldError(
                  'incomingTransaction.beneficiaryCustomer.accountNumber',
                  t('1313061', { ns: 'server_errors' }),
                );
              }
              break;

            // Beneficiary customer account name is invalid
            case '1313064':
              {
                formHelpers.setFieldError(
                  'incomingTransaction.beneficiaryCustomer.accountName',
                  t('1313064', { ns: 'server_errors' }),
                );
              }
              break;
          }
        }
      }
    }
  };

  const handleReturn = async () => {
    if (transactionId) {
      await bankingTransactionsAPI.returnTransactionById(transactionId);
      message.success(
        t(
          'banking.transactions.add_transaction.return_transaction.success_message',
        ),
      );
      onModalClose(true);
    }
  };

  const handleRejectWireTransaction = async () => {
    if (transactionId) {
      await bankingTransactionsAPI.rejectOutgoingTransactionById(transactionId);
      message.success(
        t(
          'banking.transactions.add_transaction.reject_outgoing_transaction.success_message',
        ),
      );
      onModalClose(true);
    }
  };

  const title =
    response?.statusId == TransactionReviewStatuses.Suspense ||
    response?.statusId == TransactionReviewStatuses.PendingTransmit
      ? t('banking.transactions.add_transaction.edit_modal.title')
      : t('banking.transactions.add_transaction.view_modal.title');

  const modalMode = !hasEditAccess
    ? 'view'
    : response?.statusId == TransactionReviewStatuses.Suspense ||
        response?.statusId == TransactionReviewStatuses.PendingTransmit
      ? 'update'
      : 'view';

  return (
    <TransactionModalDialog
      mode={modalMode}
      title={title}
      onSubmit={handleSubmit}
      isVisible={!!transactionId}
      closeCallback={onModalClose}
      initialValues={initialFormValues}
      onReturnClick={handleReturn}
      onRejectOutgoingTransactionClick={handleRejectWireTransaction}
    />
  );
});

export default ViewEditTransaction;
