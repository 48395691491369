import React from 'react';

// ** helpers utils **
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { StateModel } from '../../../../redux/reducers';
import { StateModel as UploadStateModel } from '../../../../redux/reducers/upload';

// ** components **
import { Space, Progress } from 'antd';
import UploadFileProgress from './UploadFileProgress';

const UploadFilesProgress = () => {
  const { files, progress } = useSelector<StateModel, UploadStateModel>(
    (state) => state.upload,
  );

  const renderFiles = () => {
    return Object.keys(files).map((key: string) => (
      <UploadFileProgress key={key} file={files[key]} />
    ));
  };

  return (
    <>
      <UploadProgressWrapper align="center" direction="vertical">
        <Progress type="circle" percent={progress} size="small" />
      </UploadProgressWrapper>

      {renderFiles()}
    </>
  );
};

const UploadProgressWrapper = styled(Space)`
  width: 100%;
  margin-bottom: 15px;
`;

export default UploadFilesProgress;
