import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { ICurrencyRate } from '../../../../../typings/finance/currencyRates';
import { AccountsHelpers } from '@helpers/finance/accounts';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel<ICurrencyRate>;

const ExchangeCurrencyRatesTable = memo((props: IProps) => {
  const { t } = useTranslation(['finance', 'common']);

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('date', { ns: 'common' }),
        width: 300,
        key: 'date',
        render: (record: ICurrencyRate) =>
          DateHelpers.formatTimestampToString(record.creationDateTimestamp),
      },
      {
        title: t('currency_rates.exchange_rate'),
        key: 'exchange-rate',
        render: ({
          currencyOneIsoCode,
          rate,
          currencyTwoIsoCode,
        }: ICurrencyRate) =>
          `1 ${currencyOneIsoCode} = ${AccountsHelpers.convertAmountBigIntToLocaleString(rate)} ${currencyTwoIsoCode}`,
      },
    ];

    return result;
  }, [t]);

  return <Table {...props} columns={columns} />;
});

export default ExchangeCurrencyRatesTable;
