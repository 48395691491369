import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import { darkTheme } from '../../../resources/theme/styled';

// components
import IconSVG from '../../DesignSystem/Core/IconSVG';
import { Trans } from 'react-i18next';
import { Tooltip, Typography } from 'antd';
import { ReactComponent as InfoIcon } from '../../../resources/icons/remix-icons/information-line.svg';

interface IProps {
  link: string;
}

const TooltipToDocumentation = memo(({ link }: IProps) => {
  const { t } = useTranslation('common');

  return (
    <Tooltip
      title={
        <Trans
          t={t}
          i18nKey="documentation_tooltip.message"
          components={[
            <Typography.Link key="here-link" target="_blank" href={link} />,
          ]}
        />
      }
      placement="bottom"
    >
      <StyledIconSVG
        size="middle"
        component={InfoIcon}
        color={darkTheme.whiteColor}
      />
    </Tooltip>
  );
});

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
`;

export default TooltipToDocumentation;
