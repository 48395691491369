import React from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { IContactModel } from 'typings/crm/contact';
import { ContactStatuses } from 'enums/crm/crm';
import { changeRequestsAPI } from 'api/crm/changeRequests/changeRequestsAPI';

// components
import Text from '@core_components/Text';
import SectionTitle from 'components/Typography/SectionTitle';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ComplianceWorkflow from 'components/Additional/ApprovalWorkflow/ComplianceWorkflow';

interface IProps {
  contact: IContactModel;
  updateContactCallback: () => void;
}

const Workflow = ({ contact, updateContactCallback }: IProps) => {
  const { t } = useTranslation('crm');

  const { response, loading } = useFetch(
    () =>
      contact.status == ContactStatuses.InReview
        ? changeRequestsAPI.fetchContactChangeRequest(contact._id)
        : null,
    [contact],
  );

  return (
    <>
      <LoadingWrapper loading={loading}>
        {response &&
        response?.clientChangeRequest?.verification?.complianceWorkflow ? (
          <>
            <SectionTitle>
              {t('onboarding_statuses.compliance_review')}
            </SectionTitle>
            <ComplianceWorkflow
              hasComplianceInfo
              workflowId={
                response.clientChangeRequest?.verification?.complianceWorkflow
                  ?.id
              }
              onUpdate={() => updateContactCallback()}
            />
          </>
        ) : (
          <Text>{t('contacts.kyc_form.nothing_to_review')}</Text>
        )}
      </LoadingWrapper>
    </>
  );
};

export default Workflow;
