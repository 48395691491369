import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { UsersBaseModel } from '../../../../../typings/ORAC/users';
import { GroupBaseModel } from '../../../../../typings/ORAC/groups';

// components
import Table, {
  RequiredPropsForTableModel,
  TableActionModel,
  TableColumnModel,
} from '@core_components/Table';
import TableActions from '../../../TableActions';

import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<UsersBaseModel> {
  actions?: TableActionModel[];
  showAssignWayColumn?: boolean;
  onActionsClick?: (key: string, value: string) => void;
}

const UsersTable = memo(
  ({ onActionsClick, actions, showAssignWayColumn, ...rest }: IProps) => {
    const { t } = useTranslation('orac');
    const history = useHistory();

    const renderInherittedFrom = (groups: GroupBaseModel[]) => {
      let result = null;

      if (groups && groups.length) {
        const groupsArray = groups.map(({ id, name }) => (
          <Typography.Link
            key={id}
            onClick={() =>
              history.push({
                pathname: RoutePaths.ORAC_Group_Details,
                search: `?id=${id}`,
              })
            }
          >
            {name + ' '}
          </Typography.Link>
        ));
        result = (
          <Typography.Text>
            {t('roles.table.inherited_from_title') + ' - '} {groupsArray}
          </Typography.Text>
        );
      }

      return result;
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          title: t('users.table.username'),
          key: 'username',
          width: 250,
          render: (record: UsersBaseModel) => (
            <Typography.Link
              onClick={() =>
                history.push({
                  pathname: RoutePaths.ORAC_User_Details,
                  search: `?id=${record._id}`,
                })
              }
            >
              {record.username}
            </Typography.Link>
          ),
        },
        {
          title: t('users.table.email'),
          key: 'email',
          width: 350,
          render: (record: UsersBaseModel) => record.email,
        },
        {
          title: t('users.table.name'),
          key: 'name',
          render: (record: UsersBaseModel) => record.name || '-',
        },
      ];

      if (showAssignWayColumn) {
        result.push({
          title: t('users.view.assign_way'),
          key: 'groups',
          dataIndex: 'groups',
          render: renderInherittedFrom,
        });
      }

      if (actions?.length) {
        result.push({
          key: 'actions',
          align: 'right',
          dataIndex: 'id',
          render: (id: string) => (
            <TableActions
              menu={actions}
              onSelect={(key) => onActionsClick && onActionsClick(key, id)}
            />
          ),
        });
      }

      return result;
    }, [actions, showAssignWayColumn, onActionsClick]);

    return <Table {...rest} columns={columns} />;
  },
);

export default UsersTable;
