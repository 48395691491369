import React, { Key, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import { FormValuesModel } from '../../..';
import { useFormikContext } from 'formik';
import { ObjectWithPermissionsModel } from '../../../../../../../../../typings/ORAC/objects';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import PermissionsMatrixForm from './PermissionsMatrixForm';

import { Typography } from 'antd';

interface IProps
  extends RequiredPropsForTableModel<ObjectWithPermissionsModel> {
  onPaginationChange: (page: number) => void;
  showForbiddenColumn?: boolean;
  showForbiddenOnly?: boolean;
}

const InnerList = ({
  onPaginationChange,
  showForbiddenColumn,
  showForbiddenOnly,
  ...rest
}: IProps) => {
  const { t } = useTranslation('orac');
  const { values } = useFormikContext<FormValuesModel>();

  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly Key[]>([]);

  const renderObjectName = ({ id, name }: ObjectWithPermissionsModel) => {
    const { selectedPermissions } = values;
    const numberOfSelectedPermissions =
      (!!selectedPermissions[id] &&
        Object.keys(selectedPermissions[id]?.actions).length) ||
      0;

    return (
      <Typography.Text>
        {name}{' '}
        {!!numberOfSelectedPermissions && (
          <YellowText>
            {t('permissions.view.selected_number', {
              number: numberOfSelectedPermissions,
            })}
          </YellowText>
        )}
      </Typography.Text>
    );
  };

  const handlePaginationChange = (newPage: number) => {
    setExpandedRowKeys([]);
    onPaginationChange(newPage);
  };

  const tableColumns = useMemo(() => {
    const result: TableColumnModel[] = [
      { title: t('objects.table.name'), key: 'name', render: renderObjectName },
    ];

    return result;
  }, []);

  const expandableRow = useMemo(
    () => ({
      expandedRowKeys: expandedRowKeys as readonly Key[],
      onExpandedRowsChange: (keys: readonly Key[]) => setExpandedRowKeys(keys),
      rowExpandable: ({ actions }: ObjectWithPermissionsModel) =>
        !!actions.length,
      expandedRowRender: (record: ObjectWithPermissionsModel) => {
        return (
          <PermissionsMatrixForm
            object={record}
            data={record.actions || []}
            showForbiddenColumn={showForbiddenColumn}
            showForbiddenOnly={showForbiddenOnly}
          />
        );
      },
    }),
    [expandedRowKeys, showForbiddenColumn, showForbiddenOnly],
  );

  return (
    <Table
      {...rest}
      size="small"
      columns={tableColumns}
      expandable={expandableRow}
      onPaginationChange={handlePaginationChange}
    />
  );
};

const YellowText = styled(Typography.Text)`
  color: ${({ theme }) => theme.warningColor};
`;

export default InnerList;
