import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { IEntity } from '../../../../../../../../typings/crm/entity';
import { EntityType } from '../../../../../../../../enums/crm/crm';
import { DateHelpers } from '@helpers/date';
import { EntityHelpers } from '@helpers/crm/entity';
import { COUNTRY_CODES } from '../../../../../../../../constants/countryCodes';
import { ISelectRangeOption } from '../../../../../../../../components/Forms/FormComponents/SelectInputs/SelectRangeInput';

// components
import RangeLabel from '../../../../../../../../components/Typography/RangeLabel';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';
import { Tag } from 'antd';

interface IProps {
  entity: IEntity;
}

const EntityInfo = ({ entity }: IProps) => {
  const { t } = useTranslation('crm');

  const renderText = (text?: string) => <div>{text || '-'}</div>;

  const renderRange = (range: ISelectRangeOption) => (
    <RangeLabel range={range} />
  );

  const renderDate = (date: Date | string) =>
    date ? DateHelpers.formatDateToString(date) : '-';

  const renderIndustryCodes = (codes: number[]) => {
    return codes.length
      ? codes.map((code) => <Tag key={`codes-${code}`}>{code}</Tag>)
      : '-';
  };

  const descriptionData = useMemo<DescriptionItem[]>(
    () => [
      {
        label: t('entity.new.entityName'),
        description: renderText(EntityHelpers.getEntityName(entity)),
      },
      {
        label: t('entity.new.tradeName'),
        description: renderText(
          EntityHelpers.getEntityName(entity, EntityType.Trade),
        ),
      },
      {
        label: t('entity.new.dateOfIncorporation'),
        description: renderDate(entity.dateOfIncorporation),
      },
      {
        label: t('entity.new.jurisdiction'),
        description: renderText(COUNTRY_CODES[entity.jurisdiction]),
      },
      {
        label: t('entity.new.industryCodes'),
        description: renderIndustryCodes(entity.industryCodes),
      },
      {
        label: t('entity.new.natureOfBusiness'),
        description: renderText(entity.natureOfBusiness),
      },
      {
        label: t('entity.new.numberOfEmployees'),
        description: renderRange(entity.numberOfEmployees),
      },
      {
        label: t('entity.new.numberOfOffices'),
        description: renderRange(entity.numberOfOffices),
      },
    ],
    [entity],
  );

  return <DescriptionSection data={descriptionData} />;
};

export default EntityInfo;
