import * as yup from 'yup';
import { STRING_VALIDATION_RULE } from 'validations/common';

export const UpdateCurrenciesOfCorrespondentBankValidationSchema = yup
  .object()
  .shape({
    currencies: yup
      .array()
      .of(yup.number())
      .min(1, 'Please add at least currency')
      .required('form:validation_messages.required'),
  });

export const UpdateSSIOfCorrespondentBankValidationSchema = yup.object().shape({
  isManualBeneficiary: yup.boolean(),
  beneficiary: yup.object().when('isManualBeneficiary', {
    is: true,
    then: (schema) =>
      schema.shape({
        accountName: STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ).max(32, {
          key: 'form:validation_messages.max_characters',
          values: { number: 32 },
        }),
        accountNumber: STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ).max(100, {
          key: 'form:validation_messages.max_characters',
          values: { number: 100 },
        }),
        country: STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ),
        address: STRING_VALIDATION_RULE.required(
          'form:validation_messages.required',
        ).max(256, {
          key: 'form:validation_messages.max_characters',
          values: { number: 256 },
        }),
        city: STRING_VALIDATION_RULE.max(20, {
          key: 'form:validation_messages.max_characters',
          values: { number: 20 },
        }),
        district: STRING_VALIDATION_RULE.max(20, {
          key: 'form:validation_messages.max_characters',
          values: { number: 20 },
        }),
        postCode: STRING_VALIDATION_RULE.max(10, {
          key: 'form:validation_messages.max_characters',
          values: { number: 20 },
        }),
      }),
  }),

  beneficiaryBank: yup.object().shape({
    bankCodeType: yup.number().required('form:validation_messages.required'),
    bankName: STRING_VALIDATION_RULE.required(
      'form:validation_messages.required',
    ),
    bankCode: STRING_VALIDATION_RULE.required(
      'form:validation_messages.required',
    ),
    address: STRING_VALIDATION_RULE.required(
      'form:validation_messages.required',
    ),
    country: STRING_VALIDATION_RULE.required(
      'form:validation_messages.required',
    ),
  }),
  intermediaryBank: yup.object().shape({
    bankCodeType: yup.number(),
    bankName: STRING_VALIDATION_RULE.when(
      'bankCodeType',
      ([bankCodeType], schema) => {
        return bankCodeType
          ? schema.required('form:validation_messages.required')
          : schema;
      },
    ),
    bankCode: STRING_VALIDATION_RULE.when(
      'bankCodeType',
      ([bankCodeType], schema) => {
        return bankCodeType
          ? schema.required('form:validation_messages.required')
          : schema;
      },
    ),
    address: STRING_VALIDATION_RULE.when(
      'bankCodeType',
      ([bankCodeType], schema) => {
        return bankCodeType
          ? schema.required('form:validation_messages.required')
          : schema;
      },
    ),
    country: STRING_VALIDATION_RULE.when(
      'bankCodeType',
      ([bankCodeType], schema) => {
        return bankCodeType
          ? schema.required('form:validation_messages.required')
          : schema;
      },
    ),
  }),
});
