import React, { useEffect, useState } from 'react';

// helpers
import useUserAccess from '../../../hooks/useUserAccess';
import { PageLayoutHelperPropsModel } from '../../../layouts/PageLayout';
import { GENESIS_REVIEW_CONFIGURATION_DOCUMENTATION_LINK } from '../../../constants/externalLinks';

// components
import TooltipToDocumentation from '../../../components/Tooltips/TooltipToDocumentation';
import AddReviewConfigurationRule from '../../../modules/Compliance/ReviewConfiguration/AddReviewConfigurationRule';
import { default as ReviewConfigurationContent } from '../../../modules/Compliance/ReviewConfiguration';

type IProps = PageLayoutHelperPropsModel;

const ReviewConfiguration = ({ setHeaderOptions }: IProps) => {
  const [hasCreateAccess] = useUserAccess([
    'Compliance_ReviewConfiguration_Create',
  ]);
  const [updateTableTrigger, updateTable] = useState<unknown>();

  useEffect(() => {
    if (hasCreateAccess) {
      setHeaderOptions({
        appendToTitle: (
          <TooltipToDocumentation
            link={GENESIS_REVIEW_CONFIGURATION_DOCUMENTATION_LINK}
          />
        ),
        extra: <AddReviewConfigurationRule onAdd={() => updateTable({})} />,
      });
    }
  }, [hasCreateAccess]);

  return <ReviewConfigurationContent updateTableTrigger={updateTableTrigger} />;
};

export default ReviewConfiguration;
