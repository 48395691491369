import { useTranslation as useTranslationI18next } from 'react-i18next';

// A custom hook for translation
const useTranslation = (namespaces?: string | string[]) => {
  const { t, ready } = useTranslationI18next(namespaces);

  return { t, ready };
};

export default useTranslation;
