import React, { useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import useTranslation from '../../../../../hooks/useTranslation';
import { message } from 'antd';
import { FormikHelpers } from 'formik';
import { financeTemplateAPIAdapter } from '../../../../../apiAdapters/approval/templates/financeTemplateAPIAdapter';
import {
  FinanceApprovalRule,
  financeTemplateAPI,
} from '../../../../../api/approval/approvalManagement/financeTemplateAPI';
import { FinanceApprovalWorkflowTemplateValidationSchema } from 'validations/approval';

// components
import Form from '@core_components/Form';
import LoaderSection from '../../../../../components/Loaders/LoaderSection';
import FinanceApprovalRulesForm, {
  ConditionModel,
  FormValuesModel,
  LimitModel,
} from '../../../../../components/Forms/TemplateForms/Approval/FinanceApprovalRulesForm';

const ApprovalRulesTab = () => {
  const [updateRuleTrigger, updateApprovalRule] = useState({});
  const { t } = useTranslation('approval_management');
  const { response: approvalRule } = useFetch(
    () => financeTemplateAPI.fetchApprovalRuleTemplate(),
    [updateRuleTrigger],
  );
  const { response: approvalGroups } = useFetch(() =>
    // TODO: use -1 limit after backend update
    // financeTemplateAPI.fetchApprovalGroups({ page: 1, limit: -1 }),
    financeTemplateAPI.fetchApprovalGroups({ page: 1, limit: 100 }),
  );

  const formatLimitsArray = (template: FinanceApprovalRule): LimitModel[] => {
    const { approvalRuleSets } = template;

    return approvalRuleSets[0].approvalThreasholds.map(
      ({ threasholdMax, threasholdMin }, i) => {
        const toInfinite = threasholdMax === -1;

        const isUsed = Boolean(
          approvalRuleSets.find((e) => e.approvalThreasholds[i].ticked),
        );

        return {
          from: Number(threasholdMin),
          to: toInfinite ? 0 : Number(threasholdMax),
          isUsed,
          toInfinite,
        };
      },
    );
  };

  const formatConditionsArray = (
    template: FinanceApprovalRule,
  ): ConditionModel[] => {
    const { approvalRuleSets } = template;

    return approvalRuleSets.map((ruleSet) => ({
      conditions: ruleSet.approvalRuleItems.map(
        ({ approvalGroup, minimunApprovalThreashold }) => ({
          approvalGroup: approvalGroup.id,
          requiredApproversCount: minimunApprovalThreashold,
        }),
      ),
      limitStatuses: ruleSet.approvalThreasholds.map(({ ticked }) => ({
        isChecked: !!ticked,
      })),
    }));
  };

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!approvalRule) {
      return null;
    }

    const limits = approvalRule.approvalRuleSets.length
      ? formatLimitsArray(approvalRule)
      : [{ from: 0, to: undefined, toInfinite: true, isUsed: false }];
    const conditions = approvalRule.approvalRuleSets.length
      ? formatConditionsArray(approvalRule)
      : [
          {
            conditions: [{ approvalGroup: '', requiredApproversCount: 0 }],
            limitStatuses: [{ isChecked: false }],
          },
        ];

    return {
      isEditMode: false,
      limits,
      conditions,
      filterClientGroupsInDropdown: true,
    };
  }, [approvalRule]);

  const handleSubmit = async (
    values: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const updatedRule = financeTemplateAPIAdapter.editApprovalRuleTemplate(
      values,
      approvalRule?.id as string,
    );

    if (approvalRule?.id) {
      await financeTemplateAPI.editApprovalRuleTemplate(updatedRule);
    } else {
      await financeTemplateAPI.createApprovalRuleTemplate(updatedRule);
      updateApprovalRule({});
    }

    formikHelpers.resetForm({ values: { ...values, isEditMode: false } });
    message.success(t('finance_template.approval_rules.success_update'));
  };

  if (!initialFormValues || !approvalGroups) {
    return <LoaderSection />;
  }

  return (
    <Form<FormValuesModel>
      confirmExitWithoutSaving
      onSubmit={handleSubmit}
      renderForm={
        <FinanceApprovalRulesForm approvalGroups={approvalGroups.data} />
      }
      initialValues={initialFormValues}
      validationSchema={FinanceApprovalWorkflowTemplateValidationSchema}
    />
  );
};

export default ApprovalRulesTab;
