import React, { memo, useMemo } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import useUserAccess from '../../../../hooks/useUserAccess';
import useTranslation from '../../../../hooks/useTranslation';
import { message } from 'antd';
import { RangeOption } from '../../../../typings/banking/bankAccounts';
import { AccountTypes } from '../../../../enums/finance/finance';
import { FormikHelpers } from 'formik';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { BankAccountStatuses } from 'enums/banking/accounts';
import { bankingBankAccountsAPI } from '../../../../api/banking/bankingBankAccountsAPI';
import { bankingBankAccountsAdapter } from '../../../../apiAdapters/banking/bankingBankAccountsAdapter';
import {
  WireRangeKeys,
  WireRangeOptions,
} from '../../../../components/Forms/FormComponents/SelectInputs/WireRangesFormSelect';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';
import {
  InitialDepositRangeKeys,
  InitialDepositRangeOptions,
} from '../../../../components/Forms/FormComponents/SelectInputs/InitialDepositRangeFormSelect';
import {
  EstimatedAmountFundsRangeKeys,
  EstimatedAmountFundsRangeOptions,
} from '../../../../components/Forms/FormComponents/SelectInputs/EstimatedAmountFundsRangeFormSelect';

// components
import BankAccountModalDialog, {
  FormValuesModel,
} from '../../../../components/Modals/TemplateModalDialogs/Banking/BankAccountModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  accountNumber: string | null;
}

const BankAccountModal = memo(
  ({ isVisible, closeCallback, accountNumber }: IProps) => {
    const { t } = useTranslation(['banking', 'common']);
    const [hasUpdateAccess] = useUserAccess(['Banking_BankAccount_Update']);
    const { response } = useFetch(
      () =>
        isVisible && accountNumber
          ? bankingBankAccountsAPI.fetchBankAccountByAccountNumber(
              accountNumber,
            )
          : null,
      [accountNumber, isVisible],
    );

    const canUpdate = useMemo(
      () =>
        hasUpdateAccess &&
        response?.status &&
        [BankAccountStatuses.Active, BankAccountStatuses.Closed].includes(
          response.status,
        ),
      [hasUpdateAccess, response],
    );

    const initialValues = useMemo<FormValuesModel | null>(() => {
      if (!response) {
        return null;
      }

      return {
        accountName: response.accountName,
        accountNumber: response.accountNumber,
        currency: response.currencyIsoCode,
        accountCategory: response.accountCategoryName,
        type: response.accountType as AccountTypes,
        balance: response.balance,
        balanceHome: response.balanceHome,
        isActive:
          response.status == BankAccountStatuses.Active ? 'true' : 'false',
        isFrozen:
          typeof response.isFrozen === 'boolean'
            ? String(response.isFrozen)
            : undefined,
        purpose: response.purpose,
        initialDeposit: AccountsHelpers.getKeyOfRangeByRangeOption(
          response.initialDeposit as RangeOption,
          InitialDepositRangeOptions,
        ) as InitialDepositRangeKeys,

        initialDepositOrigin: response.initialDepositOrigin || null,

        jurisdictions: response.jurisdictions || [],

        incomingWiresNumber: response.incomingWiresNumber
          ? (AccountsHelpers.getKeyOfRangeByRangeOption(
              response.incomingWiresNumber as RangeOption,
              WireRangeOptions,
            ) as WireRangeKeys)
          : null,

        outgoingWiresNumber: response.outgoingWiresNumber
          ? (AccountsHelpers.getKeyOfRangeByRangeOption(
              response.outgoingWiresNumber as RangeOption,
              WireRangeOptions,
            ) as WireRangeKeys)
          : null,

        estimatedIncomingFunds: response.estimatedIncomingFunds
          ? (AccountsHelpers.getKeyOfRangeByRangeOption(
              response.estimatedIncomingFunds as RangeOption,
              EstimatedAmountFundsRangeOptions,
            ) as EstimatedAmountFundsRangeKeys)
          : null,

        estimatedOutgoingFunds: response.estimatedOutgoingFunds
          ? (AccountsHelpers.getKeyOfRangeByRangeOption(
              response.estimatedOutgoingFunds as RangeOption,
              EstimatedAmountFundsRangeOptions,
            ) as EstimatedAmountFundsRangeKeys)
          : null,
        statistics: {
          ...response.statistics,
          avgEodBalanceSinceCreation:
            AccountsHelpers.formatAmountToLocaleString(
              response.statistics.avgEodBalanceSinceCreation,
            ),
          avgEodBalanceCurrentCalendarMonth:
            AccountsHelpers.formatAmountToLocaleString(
              response.statistics.avgEodBalanceCurrentCalendarMonth,
            ),
          avgEodBalanceLast3Months: AccountsHelpers.formatAmountToLocaleString(
            response.statistics.avgEodBalanceLast3Months,
          ),
          avgEodBalanceLast6Months: AccountsHelpers.formatAmountToLocaleString(
            response.statistics.avgEodBalanceLast6Months,
          ),
        },
        status: response.status,
      };
    }, [response]);

    const handleSubmit = async (
      values: FormValuesModel,
      _form: FormikHelpers<FormValuesModel>,
      isUpdated?: boolean,
    ) => {
      if (!isUpdated) {
        message.info(t('nothing_to_update', { ns: 'common' }));
      } else {
        const formattedRequestBody =
          bankingBankAccountsAdapter.updateBankAccount(values);
        await bankingBankAccountsAPI.updateBankAccount(
          accountNumber as string,
          formattedRequestBody,
        );
        message.success(
          t(
            'banking.bank_accounts.bank_account_modal_dialog.success_submit_message',
          ),
        );
      }
    };

    const submitForComplianceReview = async () => {
      if (accountNumber) {
        await bankingBankAccountsAPI.submitForComplianceReview(accountNumber);
        message.success(
          t(
            'banking.bank_accounts.bank_account_modal_dialog.success_submit_for_compliance_review_message',
          ),
        );
        closeCallback(true);
      }
    };

    return (
      <BankAccountModalDialog
        isVisible={isVisible}
        closeCallback={closeCallback}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mode={canUpdate ? 'update' : 'view'}
        submitForComplianceReviewCallback={submitForComplianceReview}
        complianceReviewStatus={
          response
            ? {
                canSubmit: response.status == BankAccountStatuses.Processing,
                hasPendingWorkflow: response.hasActiveComplianceWorkflow,
              }
            : undefined
        }
      />
    );
  },
);

export default BankAccountModal;
