import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { DateHelpers } from '@helpers/date';

// constants
import { RoutePaths } from '../../../../../routes/routes';
import { IDocumentBase } from '../../../../../typings/documents/documents';
import { DocumentAssociationTypes } from '../../../../../enums/crm/crm';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Tag, Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<IDocumentBase> {
  showAssociation?: boolean;
  onActionsClick: (key: string, document: IDocumentBase) => void;
}

const DocumentsTable = memo(
  ({ onActionsClick, showAssociation, ...rest }: IProps) => {
    const { t } = useTranslation(['crm', 'common']);
    const history = useHistory();

    const renderDocumentTags = (document: IDocumentBase) => {
      if (!document.lastTagIds.length) return '';

      const { lastTagIds, totalTagsCount } = document;
      const remainedTagsCount = totalTagsCount - lastTagIds.length;

      return (
        <>
          {lastTagIds.map(({ name }) => (
            <Tag color="blue" key={name}>
              {name.toLowerCase()}
            </Tag>
          ))}

          {remainedTagsCount > 0 && (
            <Typography.Text type="secondary">{`+${remainedTagsCount} ${t('more', { ns: 'common' })}`}</Typography.Text>
          )}
        </>
      );
    };

    const getNavPathForAssociatedElement = (
      associationType: DocumentAssociationTypes,
    ) => {
      switch (associationType) {
        case DocumentAssociationTypes.ClientGroup:
          return RoutePaths.CRM_Client_Group_Details;

        case DocumentAssociationTypes.Contact:
          return RoutePaths.CRM_Contacts_Edit;

        case DocumentAssociationTypes.Entity:
          return RoutePaths.CRM_Entities_Edit;

        case DocumentAssociationTypes.AccountRecovery:
          return RoutePaths.ORAC_AccountRecoveryRequest_Details;

        case DocumentAssociationTypes.User:
          return RoutePaths.ORAC_User_Details;
      }
    };

    const renderAssociation = ({ associations }: IDocumentBase) => {
      if (!associations) {
        return;
      }
      return (
        <>
          {associations.map((association) => {
            const pathname = getNavPathForAssociatedElement(association.type);
            const associationName = association.name
              ? association.name
              : association.id;

            return (
              <AssociationWrapper key={association.id}>
                <AssociationType>
                  {t(
                    `documents.uploadDocuments.select_association.${association.type}`,
                  )}
                  :
                </AssociationType>
                {!pathname ? (
                  associationName
                ) : (
                  <Typography.Link
                    onClick={() =>
                      history.push({
                        pathname,
                        search: `?id=${association.id}`,
                      })
                    }
                  >
                    {associationName}
                  </Typography.Link>
                )}
              </AssociationWrapper>
            );
          })}
        </>
      );
    };

    const renderUploadedBy = (record: IDocumentBase) => {
      const pathname = getNavPathForAssociatedElement(
        DocumentAssociationTypes.User,
      );
      return (
        <Typography.Link
          onClick={() =>
            history.push({ pathname, search: `?id=${record.uploadedBy?.id}` })
          }
        >
          {record.uploadedBy?.name}
        </Typography.Link>
      );
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          width: 300,
          key: 'name',
          title: t('documents.table.name'),
          render: (record: IDocumentBase) => (
            <Typography.Link onClick={() => onActionsClick('details', record)}>
              {record.name}
            </Typography.Link>
          ),
        },

        {
          width: 150,
          key: 'tags',
          title: t('documents.table.tags'),
          render: renderDocumentTags,
        },
        {
          width: 300,
          key: 'associations',
          title: t('documents.table.association'),
          hidden: !showAssociation,
          render: renderAssociation,
        },
        {
          width: 150,
          key: 'created',
          title: t('documents.table.uploaded'),
          render: (record: IDocumentBase) => (
            <Typography.Text>
              {DateHelpers.formatDateToString(record._audit.createdAt)}
            </Typography.Text>
          ),
        },
        {
          width: 150,
          key: 'uploadedBy',
          title: t('documents.table.uploaded_by'),
          render: renderUploadedBy,
        },
      ];

      return result;
    }, [showAssociation]);

    return <Table columns={columns} {...rest} />;
  },
);

const AssociationWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const AssociationType = styled.span`
  min-width: 150px;
`;

export default DocumentsTable;
