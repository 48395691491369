import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';

// components
import Tabs from '../../../../components/Tabs';
import RDCTab from './RDCTab';
import NotesTab from './NotesTab';
import ReviewConfigurationsTab from './ReviewConfigurationsTab';

const ReviewConfigurationsSection = memo(() => {
  const { t } = useTranslation('compliance');

  const tabs = useMemo(() => {
    return [
      {
        key: 'rdc',
        title: 'RDC',
        content: <RDCTab />,
      },
      {
        key: 'review-configurations',
        title: t('transactions.review_configurations'),
        content: <ReviewConfigurationsTab />,
      },
      {
        key: 'notes',
        title: t('transactions.notes'),
        content: <NotesTab />,
      },
    ];
  }, []);

  return <Tabs tabs={tabs} />;
});

export default ReviewConfigurationsSection;
