import React, { memo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { ClientGroupUserChange } from '..';

// components
import DescriptionItem from 'components/Typography/DescriptionItem';
import DescriptionSection from '@core_components/DescriptionSection';

interface IProps {
  record: ClientGroupUserChange;
}

const UpdatedPermissionsInfo = memo(({ record }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <DescriptionSection
      data={[
        {
          label: t(
            'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.from',
          ),
          description: (
            <>
              <DescriptionItem
                title={t(
                  'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.permissions',
                )}
                content={t(
                  `client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.account_permission_types.${record.oldPermissions?.type}`,
                )}
              />
              <br />
              <DescriptionItem
                title={t(
                  'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.is_admin',
                )}
                content={t(
                  `client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.administration_permissions.${record.oldPermissions?.adminPermissionType}`,
                )}
              />
            </>
          ),
        },
        {
          label: t(
            'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.to',
          ),
          description: (
            <>
              <DescriptionItem
                title={t(
                  'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.permissions',
                )}
                content={t(
                  `client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.account_permission_types.${record.newPermissions?.type}`,
                )}
              />
              <br />
              <DescriptionItem
                title={t(
                  'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.is_admin',
                )}
                content={t(
                  `client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.approval_group_changes_template.administration_permissions.${record.newPermissions?.adminPermissionType}`,
                )}
              />
            </>
          ),
        },
      ]}
    />
  );
});

export default UpdatedPermissionsInfo;
