import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from '@services/error-handler/service';
import { OnboardType } from 'enums/crm/crm';
import { FormikHelpers } from 'formik';
import { relationshipAPI } from 'api/crm/relationship/relationshipAPI';
import { relationshipAdapter } from 'apiAdapters/crm/relationship/relationshipAdapter';

// components
import Button from '@core_components/Button';
import StructureMessageModalDialog, {
  FormValuesModel as StructureFormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/StructureMessageModalDialog';
import AddNewNodeModalDialog, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/AddNewNodeModalDialog';
import { Col, Row, message } from 'antd';

interface IProps {
  clientGroupId: string;
  relationshipScopeId: string;
  closeCallback: (wasSubmitted?: boolean) => void;
  onEnableStructureEditing: (message: string) => Promise<void>;
}

const ChartActions = ({
  clientGroupId,
  relationshipScopeId,
  closeCallback,
  onEnableStructureEditing,
}: IProps) => {
  const { t } = useTranslation(['crm', 'server_errors']);
  const [isStructureMessageModalVisible, setIsStructureMessageModalVisible] =
    useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!isModalVisible) {
      return null;
    }

    return {
      clientGroupId,
      activeApplicationScopeId: relationshipScopeId,

      template: {
        id: null,
        visibleFor: null,
      },

      contact: {
        isPermissionsSelectAvailable: false,
        firstName: '',
        lastName: '',
        middleName: '',
        isPEP: null,
        pepInformation: '',
        isOnlineUser: null,
        adminPermissionType: null,
        accountPermissions: null,
        phone: '',
        email: '',
      },

      clientGroupEntryType: OnboardType.Organization,

      organization: null,

      canAddOwnerships: true,
      relationships: [],
    };
  }, [isModalVisible]);

  const handleSubmit = async (values: FormValuesModel) => {
    const formattedRequestBody =
      relationshipAdapter.generateRequestBodyToCreateNode(values);
    await relationshipAPI.updateRelationships(
      formattedRequestBody,
      clientGroupId as string,
    );
    message.success(
      t('client_group.structure.relationships.add_modal.success_add'),
    );
  };

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case '1001007': {
        formikHelpers.setFieldError(
          'contact.email',
          t('1001007', { ns: 'server_errors' }),
        );
        break;
      }

      case '1016010': {
        formikHelpers.setFieldError(
          'contact.email',
          t('1016010', { ns: 'server_errors' }),
        );
        break;
      }

      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  const handleModalClose = (wasSubmitted?: boolean) => {
    setIsModalVisible(false);
    closeCallback(wasSubmitted);
  };

  const handleOnEnableStructureEditing = async (
    values: StructureFormValuesModel,
  ) => {
    await onEnableStructureEditing(values.message);
    message.success(
      t('client_group.structure.finish_editing_message_modal.success_message'),
    );
  };

  const structureMessageInitialValues = useMemo<StructureFormValuesModel>(
    () => ({ message: '' }),
    [],
  );

  return (
    <>
      <StyledRow justify="end" gutter={[16, 16]}>
        <Col>
          <Button onClick={() => setIsModalVisible(true)}>
            {t('client_group.structure.add_node')}
          </Button>
        </Col>
        <Col>
          <Button
            type="bordered"
            onClick={() => setIsStructureMessageModalVisible(true)}
          >
            {t('client_group.structure.finish_editing')}
          </Button>
          <StructureMessageModalDialog
            isVisible={isStructureMessageModalVisible}
            onSubmit={handleOnEnableStructureEditing}
            closeCallback={() => setIsStructureMessageModalVisible(false)}
            initialValues={structureMessageInitialValues}
          />
        </Col>
      </StyledRow>

      <AddNewNodeModalDialog
        title={t('client_group.structure.new_node_modal.title')}
        onSubmit={handleSubmit}
        onSubmitError={handleOnSubmitError}
        isVisible={isModalVisible}
        closeCallback={handleModalClose}
        initialValues={initialFormValues}
      />
    </>
  );
};

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default ChartActions;
