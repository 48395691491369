import React, { useMemo } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import { RelationshipHelpers } from '@helpers/crm/relationship';
import { relationshipTemplatesAPI } from '../../../../../api/crm/relationship/relationshipTemplatesAPI';
import {
  SelectOption,
  SelectProps,
} from '../../../../DesignSystem/Core/Select';
import { IRelationshipTemplateModel } from '../../../../../typings/crm/relationship-template';
import {
  OnboardType,
  RelationshipTrackType,
} from '../../../../../enums/crm/crm';

// constants
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../constants/global';

// components
import FormSelect from '@common_components/Form/FormSelect';

interface IProps extends SelectProps<IRelationshipTemplateModel> {
  childId: string;
  entryType: OnboardType;
  relationshipScopeId?: string;
  withoutOwnershipTemplates?: boolean;
  filterByKeyRelationshipTemplateId?: boolean;
}

const RelationshipTemplateSelect = ({
  childId,
  entryType,
  withoutOwnershipTemplates = undefined,
  relationshipScopeId,
  filterByKeyRelationshipTemplateId = undefined,
  ...rest
}: IProps) => {
  const { response, loading } = useFetch(() => {
    return entryType && childId
      ? relationshipTemplatesAPI.fetchAvailableRelationshipTemplates({
          page: 1,
          limit: AUTOCOMPLETE_RESULT_LIMIT,
          childId,
          relationshipScopeId,
          parentType: entryType,
          withoutOwnershipTemplates,
          filterByKeyRelationshipTemplateId,
        })
      : null;
  }, [
    childId,
    entryType,
    relationshipScopeId,
    filterByKeyRelationshipTemplateId,
    withoutOwnershipTemplates,
  ]);

  const options = useMemo<SelectOption<IRelationshipTemplateModel>[]>(() => {
    if (!response) {
      return [];
    }

    return response.data.map((rel) => {
      return {
        id: rel._id,
        label: RelationshipHelpers.getRelationshipName(
          rel,
          RelationshipTrackType.Parent,
        ),
        model: rel,
      };
    });
  }, [response]);

  return <FormSelect {...rest} options={options} loading={loading} />;
};

export default RelationshipTemplateSelect;
