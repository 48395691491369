import i18n from '../../i18n';
import { message } from 'antd';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { trackException } from '@services/azure-app-insights/service';
import { NOTIFICATION_DURATION } from '../../constants/global';
import {
  ApiErrorResponseModel,
  TOO_MANY_REQUESTS_STATUS,
} from '../../api/axiosInstance';

let isInitialized = false;

export type ErrorFromServer = PromiseRejectionEvent & ApiErrorResponseModel;

const ErrorHandlerService = {
  getErrorCodeFromError: function (error: ErrorFromServer) {
    const { response, reason } = error;
    return (
      response?.data?.code ||
      response?.data?.error?.code ||
      reason?.response?.data?.code ||
      reason?.response?.data?.error?.code
    );
  },

  getErrorMessage: function (error: ErrorFromServer): string {
    let result = '';

    // here is 3 cases:
    // 1. error object has an code property so we can show an error message by this code (e.g. 1001002 - Contact with this email already exists)
    // 2. no error code but there is a message so we can just show this message
    // 3. no error code and error message, so in this case we should show a default error message (Oops, something went wrong!) :)

    // get an error code from received error
    if (error && error?.response?.status === TOO_MANY_REQUESTS_STATUS) {
      result = i18n.t(`status.${TOO_MANY_REQUESTS_STATUS}`, {
        ns: 'server_errors',
      });
    } else {
      const errorMessageCode = this.getErrorCodeFromError(error);
      if (
        errorMessageCode &&
        i18n.exists(`${errorMessageCode}`, { ns: 'server_errors' })
      ) {
        result = i18n.t(`${errorMessageCode}`, { ns: 'server_errors' });
      } else {
        const errorMessage =
          error.response?.data?.message ||
          error.response?.data?.error?.message ||
          error.reason?.response?.data?.message ||
          error.reason?.response?.data?.error?.message;

        result =
          errorMessage ||
          i18n.t('custom_errors.generic_error_message', {
            ns: 'server_errors',
          });
      }
    }

    return result;
  },

  handleError: function (
    error: PromiseRejectionEvent | ApiErrorResponseModel | unknown,
  ) {
    ErrorHandlerService.displayErrorMessage(
      ErrorHandlerService.getErrorMessage(
        error as PromiseRejectionEvent & ApiErrorResponseModel,
      ),
    );
  },

  handleOnErrorEvent: function (error: ErrorEvent | any) {
    if (error) {
      // This error means that ResizeObserver was not able to deliver all observations within a single animation frame.
      // It is benign (site will not break)
      // Posts that explains that issue:
      // https://github.com/WICG/resize-observer/issues/38
      // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
      if (/resizeobserver/.test(error?.message || error || '').toString()) {
        error.preventDefault();
        error.stopImmediatePropagation();
      } else {
        const errorMessage = error?.message || error;
        trackException(SeverityLevel.Error, errorMessage);
      }
    }
  },

  displayErrorMessage: function (text: string) {
    message.error(text, NOTIFICATION_DURATION);
  },

  initialize: function () {
    if (!isInitialized) {
      window.addEventListener('error', ErrorHandlerService.handleOnErrorEvent);
      window.addEventListener(
        'unhandledrejection',
        ErrorHandlerService.handleError,
      );
      isInitialized = true;
    }
  },

  stop: function () {
    if (isInitialized) {
      window.removeEventListener(
        'error',
        ErrorHandlerService.handleOnErrorEvent,
      );
      window.removeEventListener(
        'unhandledrejection',
        ErrorHandlerService.handleError,
      );
      isInitialized = false;
    }
  },
};

export default ErrorHandlerService;
