import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { ClientTransactionTypes } from 'enums/banking/transactions';
import { TransactionDocumentModel } from 'typings/finance/transaction';
import { ClientTransferApprovalStatuses } from '../../enums/transfers/transfers';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

export type PendingTransactionFromQuery = {
  id: number;
  sourceType: number;
  sourceTypeName: string;
  statusId: ClientTransferApprovalStatuses;
  statusName: string;
  workflowExpirationDate: number;
  description: string;
  currencyId: number;
  currencyIsoCode: string;
  amount: number;
  valueDate: number;
  creationDate: number;
  postingDate: number;
  balance: number;
  debit: number;
  credit: number;
  fromAccountNumber: string;
  fromAccountName: string;
  fromCurrencyISOCode: string;
  fromClientId: number;
  fromClientName: string;
  relatedDocuments: {
    dmsId: string;
    name: string;
    type: string;
    size: string;
    linkToDownload: string;
  }[];
};

export type WireTransferTemplateFromQuery = {
  id: number;
  templateName: string;
  templateTypeId: number;
  templateTypeName: string;
  templateTypeDescription: string;
  clientGroupId: number;
  isOnlyMe: boolean;
  beneficiaryAccountName: string;
  beneficiaryAccountNumber: string;
  beneficiaryCountry: string;
  beneficiaryAddress1: string;
  beneficiaryAddress2: string;
  beneficiaryAddress3: string;
  beneficiaryBankCodeId: number;
  beneficiaryBankCode: string;
  beneficiaryBankCodeType: number;
  beneficiaryBankName: string;
  beneficiaryBankCountry: string;
  beneficiaryBankAddress1: string;
  beneficiaryBankAddress2: string;
  beneficiaryBankAddress3: string;
  intermediaryBankCodeId: number;
  intermediaryBankCode: string;
  intermediaryBankCodeType: number;
  intermediaryBankName: string;
  intermediaryBankCountry: string;
  intermediaryBankAddress1: string;
  intermediaryBankAddress2: string;
  intermediaryBankAddress3: string;
  reference: string;
  beneficiaryBankCodeTypeName: string;
  intermediaryBankCodeTypeName: string;
  beneficiaryCity: string;
  beneficiaryDistrict: string;
  beneficiaryPostCode: string;
  derivedFrom: string;
  processingType: string;
  beneficiaryBankCity: string;
  beneficiaryBankDistrict: string;
  beneficiaryBankPostCode: string;
  intermediaryBankCity: string;
  intermediaryBankDistrict: string;
  intermediaryBankPostCode: string;
};

export type BankDataFromQuery = {
  code: string;
  codeType: string;
  name: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  branch: string;
  postCode: string;
  country: string;
  countryCode: string;
  district: string;
};

export type JournalEntryFromQuery = {
  sourceType: ClientTransactionTypes;
  sourceTypeName: string;
  statusId: number;
  statusName: string;
  purpose: string;
  currencyId: number;
  currencyIsoCode: string;
  amount: number;
  postingDate: number;
  valueDate: number;
  creationDate: number;
  requestedById: string;
  workflowId: string;
  repaired: boolean;
  isSender: boolean;
  requestedByName: string;
  relatedDocuments: TransactionDocumentModel[];
  templateId: number;
  templateName: string;
  workflowExpirationDate: number;
  transactionNumber: string;
  senderReference: string;

  orderingCustomer: {
    accountName: string;
    accountNumber: string;
    senderReference: string;
    address1: string;
    address2: string;
    address3: string;
    sendingInstitution: BankDataFromQuery | null;
    orderingInstitution: BankDataFromQuery | null;
    senderCorrespondent: BankDataFromQuery | null;
  };

  beneficiaryCustomer: {
    accountName: string;
    accountNumber: string;
    countryCode: string;
    city: string;
    postCode: string;
    district: string;
    address1: string;
    address2: string;
    address3: string;
    beneficiaryReference: string;
    remittanceInformationLine1: string;
    remittanceInformationLine2: string;
    remittanceInformationLine3: string;
    remittanceInformationLine4: string;
    intermediaryInstitution: BankDataFromQuery | null;
    accountWithInstitution: BankDataFromQuery | null;
  };
};

interface FetchClientPendingTransactionsParams extends FetchParamsModel {
  status?: string;
  accountNumber?: string;
  clientGroupId?: string;
}

const transactionsAPI = {
  fetchClientPendingTransactions: (
    params: FetchClientPendingTransactionsParams,
  ) => {
    return APIService.get<FetchResponseModel<PendingTransactionFromQuery>>(
      `${APIConfig.accountingApi}/transactions/client/pending`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchClientPendingTransactionById: (id: number, clientGroupId: number) => {
    return APIService.get<JournalEntryFromQuery>(
      `${APIConfig.accountingApi}/transactions/client/pending/${id}`,
      {
        params: { clientGroupId },
      },
    ).then(({ data }) => data);
  },
};

export { transactionsAPI };
