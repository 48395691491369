import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { useFormikContext } from 'formik';
import { ApprovalGroupFormValuesModel } from '..';

// components
import FormField from '@core_components/FormField';
import ApprovalGroupParticipantsAutocomplete from '../../../../../Forms/FormComponents/Autocompletes/Approval/ApprovalGroupParticipantsAutocomplete';
import { Input } from 'antd';

interface IProps {
  isEditMode?: boolean;
}

const InnerForm = ({ isEditMode }: IProps) => {
  const { t } = useTranslation(['approval_management', 'form']);

  const { values } = useFormikContext<ApprovalGroupFormValuesModel>();

  return (
    <>
      <FormField
        name="name"
        label={t('approval_management.approval_groups.name')}
        component={Input}
        placeholder={t('enterPlaceholder', {
          ns: 'form',
          fieldName: t(
            'approval_management.approval_groups.name',
          ).toLowerCase(),
        })}
      />

      <FormField
        name="participants"
        label={t('approval_management.approval_groups.participants')}
        component={ApprovalGroupParticipantsAutocomplete}
        disabled={!!isEditMode}
        placeholder={t('selectPlaceholder', {
          ns: 'form',
          fieldName: t(
            'approval_management.approval_groups.participants',
          ).toLowerCase(),
        })}
        additionalProps={{
          allowClear: true,
          mode: 'multiple',
          initialValue: values.initialParticipantOptions,
          workflowType: values.approvalWorkflowType,
          clientGroupId: values.clientGroupId,
        }}
      />

      <FormField
        name="description"
        label={t('approval_management.approval_groups.description')}
        component={Input.TextArea}
        additionalProps={{ autoSize: { minRows: 6, maxRows: 6 } }}
        placeholder={t('enterPlaceholder', {
          ns: 'form',
          fieldName: t(
            'approval_management.approval_groups.description',
          ).toLowerCase(),
        })}
      />
    </>
  );
};

export default InnerForm;
