import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { darkTheme } from '@resources/theme/styled';
import { DateHelpers } from '@helpers/date';
import { COUNTRY_CODES } from 'constants/countryCodes';
import { AccountsHelpers } from '@helpers/finance/accounts';
import {
  AccountInfo,
  ExternalTransactionBank,
  TransactionReviewTarget,
} from 'typings/compliance/transactions';

// components
import Text from '@core_components/Text';
import DocumentLink from 'components/Typography/DocumentLink';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';
import { Divider } from 'antd';

interface IProps {
  incomingTransaction: TransactionReviewTarget;
}

const ValueWrapper = memo(({ children }) => {
  return (
    <EllipsisTooltip maxTextContainerWidth="240px" title={children}>
      {children}
    </EllipsisTooltip>
  );
});

const IncomingTransactionDetails = memo(({ incomingTransaction }: IProps) => {
  const { t } = useTranslation(['banking', 'compliance', 'common']);

  const data = useMemo<DescriptionItem[]>(() => {
    function renderCustomerInfo(data: AccountInfo) {
      return (
        <StyledInnerDescriptionSection
          size="small"
          bordered={false}
          background="none"
          data={[
            {
              label: t(
                'banking.transactions.add_transaction.form_fields.customer_section.account_number',
              ),
              description: <ValueWrapper>{data.accountNumber}</ValueWrapper>,
            },
            {
              label: t(
                'banking.transactions.add_transaction.form_fields.customer_section.account_name',
              ),
              description: <ValueWrapper>{data.accountName}</ValueWrapper>,
            },
            {
              label: t(
                'banking.transactions.add_transaction.form_fields.customer_section.country',
              ),
              description: data.country ? (
                <ValueWrapper>{COUNTRY_CODES[data.country]}</ValueWrapper>
              ) : (
                ''
              ),
            },
            {
              label: t(
                'banking.transactions.add_transaction.form_fields.customer_section.address',
              ),
              description: <ValueWrapper>{data.address1}</ValueWrapper>,
            },
            {
              label: t(
                'banking.transactions.add_transaction.form_fields.customer_section.city',
              ),
              description: <ValueWrapper>{data.city}</ValueWrapper>,
            },
            {
              label: t(
                'banking.transactions.add_transaction.form_fields.customer_section.district',
              ),
              description: <ValueWrapper>{data.district}</ValueWrapper>,
            },
            {
              label: t(
                'banking.transactions.add_transaction.form_fields.customer_section.post_code',
              ),
              description: <ValueWrapper>{data.postCode}</ValueWrapper>,
            },
          ]}
        />
      );
    }

    function renderBankInfo(data: ExternalTransactionBank) {
      return (
        <StyledInnerDescriptionSection
          size="small"
          bordered={false}
          background="none"
          data={[
            {
              label: t(
                'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_code',
              ),
              description: <ValueWrapper>{data.code}</ValueWrapper>,
            },
            {
              label: t(
                'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_name',
              ),
              description: <ValueWrapper>{data.name}</ValueWrapper>,
            },
            {
              label: t(
                'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_country',
              ),
              description: (
                <ValueWrapper>
                  {data.countryCode ? COUNTRY_CODES[data.countryCode] : ''}
                </ValueWrapper>
              ),
            },
            {
              label: t(
                'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_address',
              ),
              description: <ValueWrapper>{data.address1}</ValueWrapper>,
            },
            {
              label: t(
                'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_city',
              ),
              description: <ValueWrapper>{data.city}</ValueWrapper>,
            },
            {
              label: t(
                'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_district',
              ),
              description: <ValueWrapper>{data.district}</ValueWrapper>,
            },
            {
              label: t(
                'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_postal_code',
              ),
              description: <ValueWrapper>{data.postCode}</ValueWrapper>,
            },
          ]}
        />
      );
    }

    const { senderCorrespondent } =
      incomingTransaction.correspondentInstitution;

    const canDisplaySenderCorrespondent = !!(
      senderCorrespondent.code ||
      senderCorrespondent.name ||
      senderCorrespondent.countryCode ||
      senderCorrespondent.address1 ||
      senderCorrespondent.city ||
      senderCorrespondent.district ||
      senderCorrespondent.postCode
    );

    return [
      {
        label: t('transactions.sender_reference', { ns: 'compliance' }),
        description: (
          <ValueWrapper>
            {incomingTransaction.transaction.senderReference}
          </ValueWrapper>
        ),
      },
      {
        label: t('transactions.ordering_customer', { ns: 'compliance' }),
        description: (
          <>
            <Text variant="h5" color={darkTheme.whiteColor}>
              {t(
                'banking.transactions.add_transaction.ordering_customer_section_title',
              )}
            </Text>
            {renderCustomerInfo(incomingTransaction.orderingAccount)}
            <StyledDivider />

            <Text variant="h5" color={darkTheme.whiteColor}>
              {t(
                'banking.transactions.add_transaction.sending_institution_section_title',
              )}
            </Text>
            {incomingTransaction.correspondentInstitution.sendingInstitution ? (
              renderBankInfo(
                incomingTransaction.correspondentInstitution.sendingInstitution,
              )
            ) : (
              <Text color={darkTheme.textLightColor4}>
                {t('value_is_not_provided', { ns: 'common' })}
              </Text>
            )}
            <StyledDivider />

            <Text variant="h5" color={darkTheme.whiteColor}>
              {t(
                'banking.transactions.add_transaction.ordering_institution_section_title',
              )}
            </Text>
            {incomingTransaction.correspondentInstitution
              .orderingInstitution ? (
              renderBankInfo(
                incomingTransaction.correspondentInstitution
                  .orderingInstitution,
              )
            ) : (
              <Text color={darkTheme.textLightColor4}>
                {t('value_is_not_provided', { ns: 'common' })}
              </Text>
            )}

            {canDisplaySenderCorrespondent && (
              <>
                <StyledDivider />
                <Text variant="h5" color={darkTheme.whiteColor}>
                  {t(
                    'banking.transactions.add_transaction.sender_correspondent_section_title',
                  )}
                </Text>
                {renderBankInfo(
                  incomingTransaction.correspondentInstitution
                    .senderCorrespondent,
                )}
              </>
            )}
          </>
        ),
      },
      {
        label: t('transactions.beneficiary'),
        description: (
          <>
            <Text variant="h5" color={darkTheme.whiteColor}>
              {t(
                'banking.transactions.add_transaction.intermediary_institution_section_title',
              )}
            </Text>
            {renderBankInfo(
              incomingTransaction.correspondentInstitution
                .intermediaryInstitution,
            )}
            <StyledDivider />

            <Text variant="h5" color={darkTheme.whiteColor}>
              {t(
                'banking.transactions.add_transaction.account_with_institution_section_title',
              )}
            </Text>
            {renderBankInfo(
              incomingTransaction.correspondentInstitution
                .accountWithInstitution,
            )}
            <StyledDivider />

            <Text variant="h5" color={darkTheme.whiteColor}>
              {t(
                'banking.transactions.add_transaction.beneficiary_customer_section_title',
              )}
            </Text>
            {renderCustomerInfo(incomingTransaction.beneficiaryAccount)}
            <StyledDivider />

            <Text variant="h5" color={darkTheme.whiteColor}>
              {t(
                'banking.transactions.add_transaction.remittance_information_section_title',
              )}
            </Text>
            <StyledInnerDescriptionSection
              size="small"
              bordered={false}
              background="none"
              data={[
                {
                  label: t(
                    'banking.transactions.add_transaction.form_fields.line',
                    { number: 1 },
                  ),
                  description: (
                    <ValueWrapper>
                      {
                        incomingTransaction.remittance
                          .remittanceInformationLine1
                      }
                    </ValueWrapper>
                  ),
                },
                {
                  label: t(
                    'banking.transactions.add_transaction.form_fields.line',
                    { number: 2 },
                  ),
                  description: (
                    <ValueWrapper>
                      {
                        incomingTransaction.remittance
                          .remittanceInformationLine2
                      }
                    </ValueWrapper>
                  ),
                },
                {
                  label: t(
                    'banking.transactions.add_transaction.form_fields.line',
                    { number: 3 },
                  ),
                  description: (
                    <ValueWrapper>
                      {
                        incomingTransaction.remittance
                          .remittanceInformationLine3
                      }
                    </ValueWrapper>
                  ),
                },
                {
                  label: t(
                    'banking.transactions.add_transaction.form_fields.line',
                    { number: 4 },
                  ),
                  description: (
                    <ValueWrapper>
                      {
                        incomingTransaction.remittance
                          .remittanceInformationLine4
                      }
                    </ValueWrapper>
                  ),
                },
              ]}
            />
          </>
        ),
      },
      {
        label: t('transactions.repaired', { ns: 'compliance' }),
        description: (
          <>
            <Text>
              {incomingTransaction.remittance.repair
                ? t('yes', { ns: 'common' })
                : t('no', { ns: 'common' })}
            </Text>
            {incomingTransaction.remittance.repair && (
              <StyledInnerDescriptionSection
                size="small"
                bordered={false}
                background="none"
                data={[
                  {
                    label: t(
                      'banking.transactions.add_transaction.form_fields.repairedBeneficiary',
                    ),
                    description: (
                      <ValueWrapper>
                        {incomingTransaction.remittance.repairedBeneficiary}
                      </ValueWrapper>
                    ),
                  },
                ]}
              />
            )}
          </>
        ),
      },
      {
        label: t('transactions.value_date', { ns: 'compliance' }),
        description: (
          <Text>
            {incomingTransaction.transaction.valueDate
              ? DateHelpers.formatTimestampToString(
                  incomingTransaction.transaction.valueDate as any,
                )
              : null}
          </Text>
        ),
      },
      {
        label: t('transactions.amount', { ns: 'compliance' }),
        description: (
          <Text>
            {incomingTransaction.transaction.currencyIsoCode}{' '}
            {incomingTransaction.transaction.settledAmount
              ? AccountsHelpers.formatAmountToLocaleString(
                  incomingTransaction.transaction.settledAmount,
                )
              : null}
          </Text>
        ),
      },
      {
        label: t('transactions.related_documents', { ns: 'compliance' }),
        description: (
          <>
            {incomingTransaction.documents &&
            incomingTransaction.documents.length
              ? incomingTransaction.documents.map(
                  ({ dmsId, name, linkToDownload }) => (
                    <div key={dmsId}>
                      <DocumentLink
                        text={name}
                        fileId={linkToDownload}
                        documentId={dmsId}
                      />
                    </div>
                  ),
                )
              : null}
          </>
        ),
      },
    ];
  }, [incomingTransaction, t]);

  return <StyledDescriptionSection data={data} />;
});

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.marginXs} 0`};
`;

const StyledDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-label {
    width: 200px !important;
    max-width: 200px !important;
  }
`;

const StyledInnerDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-label {
    width: 160px !important;
    max-width: 160px !important;
  }
`;

export default IncomingTransactionDetails;
