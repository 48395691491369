import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '../../../../../hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { DateHelpers } from '@helpers/date';
import { AccountsHelpers } from '../../../../../helpers/finance/accounts';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { PendingTransactionFromQuery } from '../../../../../api/transactions/transactionsAPI';
import { ClientTransferApprovalStatuses } from 'enums/transfers/transfers';

// components
import Link from '@common_components/Texts/Link';
import DocumentLink from '../../../../Typography/DocumentLink';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import FilterDropdownOverlay from 'components/Tables/FilterDropdownOverlay';
import ClientTransactionStatus from '../../../../Additional/Statuses/ClientTransactionStatus';
import ClientBankAccountsAutocomplete from 'components/Forms/FormComponents/Autocompletes/Finance/ClientBankAccountsAutocomplete';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

export type ActionKeys = 'view';

interface IProps
  extends RequiredPropsForTableModel<PendingTransactionFromQuery> {
  filters: Record<string, any>;
  onFilterChange: (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => void;
  onActionsClick?: (
    key: ActionKeys,
    record: PendingTransactionFromQuery,
  ) => void;
}

const PendingTransactionsTable = ({
  onActionsClick,
  filters,
  onFilterChange,
  ...rest
}: IProps) => {
  const { t } = useTranslation('banking');
  const [hasClientGroupDetailsAccess] = useUserAccess([
    'CRM_ClientGroup_Details_View',
  ]);
  const history = useHistory();

  const ClientTransactionStatuses = useMemo(
    () => [
      {
        title: t(
          `transactions.transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Completed]}`,
        ),
        value: 'completed',
      },
      {
        title: t(
          `transactions.transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.PendingApprovals]}`,
        ),
        value: 'pending',
      },
      {
        title: t(
          `transactions.transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Processing]}`,
        ),
        value: 'processing',
      },
      {
        title: t(
          `transactions.transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Rejected]}`,
        ),
        value: 'rejected',
      },
      {
        title: t(
          `transactions.transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Cancelled]}`,
        ),
        value: 'cancelled',
      },
      {
        title: t(
          `transactions.transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Scheduled]}`,
        ),
        value: 'scheduled',
      },
    ],
    [],
  );

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    const renderDescription = (record: PendingTransactionFromQuery) => {
      if (!record.description) {
        return null;
      }

      if (!onActionsClick) {
        return record.description;
      }

      return (
        <Typography.Link onClick={() => onActionsClick('view', record)}>
          {record.description}
        </Typography.Link>
      );
    };

    return [
      {
        key: 'requested_date',
        width: 170,
        title: t('transactions.table.requested_date'),
        render: (record: PendingTransactionFromQuery) =>
          record.creationDate > 0
            ? DateHelpers.formatTimestampToString(record.creationDate)
            : '',
      },
      {
        key: 'value_date',
        width: 170,
        title: t('transactions.table.value_date'),
        render: (record: PendingTransactionFromQuery) =>
          record.valueDate > 0
            ? DateHelpers.formatTimestampToString(record.valueDate)
            : '',
      },
      {
        key: 'status',
        width: 170,
        title: t('transactions.table.transaction_status'),
        render: (record: PendingTransactionFromQuery) => (
          <ClientTransactionStatus
            transaction={{
              status: record.statusId,
              expiresAt: record.workflowExpirationDate,
            }}
          />
        ),
        // --- filter ---
        filteredValue: filters.status,
        filterSearch: true,
        filterMultiple: false,
        filters: ClientTransactionStatuses.map((e) => ({
          text: e.title,
          value: e.value,
        })),
      },
      {
        key: 'from_account',
        width: 400,
        title: t('transactions.table.from_account'),
        render: (record: PendingTransactionFromQuery) =>
          AccountsHelpers.generateAccountFullName(
            record.fromAccountName,
            record.fromAccountNumber,
            record.fromCurrencyISOCode,
          ),
        // filter
        filteredValue: filters.fromAccount,
        filterDropdown: (filterProps: FilterDropdownProps) => {
          return (
            <FilterDropdownOverlay filterProps={filterProps}>
              <StyledClientBankAccountsAutocomplete
                value={filterProps.selectedKeys as any}
                onChange={(value) => filterProps.setSelectedKeys(value as any)}
                isActive="all"
                isFrozen="all"
              />
            </FilterDropdownOverlay>
          );
        },
      },
      {
        key: 'description',
        width: 350,
        title: t('transactions.table.description'),
        render: renderDescription,
      },
      {
        key: 'documents',
        width: 200,
        title: t('transactions.table.documents'),
        render: (record: PendingTransactionFromQuery) =>
          record.relatedDocuments.map(({ dmsId, linkToDownload, name }) => (
            <div key={dmsId}>
              <DocumentLink
                text={name}
                fileId={linkToDownload}
                documentId={dmsId}
              />
            </div>
          )),
      },
      {
        key: 'type',
        width: 170,
        title: t('transactions.table.transaction_type'),
        render: (record: PendingTransactionFromQuery) => record.sourceTypeName,
      },
      {
        key: 'currency',
        width: 150,
        title: t('transactions.table.currency'),
        render: (record: PendingTransactionFromQuery) => record.currencyIsoCode,
      },
      {
        key: 'amount',
        width: 200,
        title: t('transactions.table.amount'),
        render: (record: PendingTransactionFromQuery) =>
          record.amount
            ? AccountsHelpers.convertAmountBigIntToLocaleString(record.amount)
            : '',
      },
      {
        key: 'client_group',
        width: 200,
        title: t('transactions.table.client_group'),
        render: (record: PendingTransactionFromQuery) => {
          const label = (
            <EllipsisTooltip
              maxTextContainerWidth="180px"
              title={record.fromClientName}
            >
              {record.fromClientName}
            </EllipsisTooltip>
          );

          return hasClientGroupDetailsAccess ? (
            <Link
              onClick={() =>
                history.push({
                  pathname: RoutePaths.CRM_Client_Group_Details,
                  search: `?id=${record.fromClientId}`,
                })
              }
            >
              {label}
            </Link>
          ) : (
            label
          );
        },
      },
    ];
  }, [filters, hasClientGroupDetailsAccess, history, onActionsClick]);

  return (
    <Table columns={tableColumns} onFilterChange={onFilterChange} {...rest} />
  );
};

const StyledClientBankAccountsAutocomplete = styled(
  ClientBankAccountsAutocomplete,
)`
  width: 100%;
`;

export default PendingTransactionsTable;
