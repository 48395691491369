interface IFingerprintConfig {
  components: {
    [key: string]: boolean;
  };
}

export const fingerprintConfig: IFingerprintConfig = {
  components: {
    osCpu: true,
    languages: true,
    deviceMemory: true,
    hardwareConcurrency: true,
    timezoneOffset: true,
    timezone: true,
    sessionStorage: true,
    localStorage: true,
    indexedDB: true,
    openDatabase: true,
    cpuClass: true,
    platform: true,
    plugins: true,
    touchSupport: true,
    fonts: true,
    pluginsSupport: true,
    productSub: true,
    emptyEvalLength: true,
    errorFF: true,
    vendor: true,
    chrome: true,
    cookiesEnabled: true,

    // Turned off because from some reason this field is always changing in Safari (Private mode) version 17.0 and higher, every API call
    audio: false,

    // Turned off display related fields for fingerprinting due to bug with several displays on the same device
    canvas: false,
    screenResolution: false,
    availableScreenResolution: false,
    colorDepth: false,
  },
};
