import React from 'react';

// Helpers & Utils
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import {
  ALLOWED_ATTACHMENT_DOCUMENT_TYPES,
  ALLOWED_UPLOAD_TYPES,
} from '../../../constants/documents';

// Components
import { Space, Typography } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';

const { Paragraph, Text, Link } = Typography;

interface IProps {
  onSelect: (files: File[]) => void;
}

const UploadDragAndDrop = ({ onSelect }: IProps) => {
  const { t } = useTranslation('crm');

  const [isActive, setActiveStatus] = React.useState(false);
  const [activeDrag, setActiveDrag] = React.useState(0);
  const inputRef = React.useRef<any>(null);

  function onFileSelect() {
    if (inputRef.current && inputRef.current.files.length) {
      onSelect(Array.prototype.slice.call(inputRef.current.files));
      inputRef.current.value = '';
    }
  }

  function renderInput() {
    return (
      <input
        accept={ALLOWED_UPLOAD_TYPES}
        style={{ display: 'none' }}
        multiple
        type="file"
        ref={inputRef}
        onChange={() => onFileSelect()}
      />
    );
  }

  function onDragEnter(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    let activeDragCopy = activeDrag;
    activeDragCopy += 1;
    setActiveStatus(activeDragCopy > 0);
    setActiveDrag(activeDragCopy);
  }

  function onDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  function onDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    let activeDragCopy = activeDrag;
    activeDragCopy -= 1;
    if (activeDragCopy === 0) {
      setActiveStatus(false);
    }
    setActiveDrag(activeDragCopy);
  }

  function isFiledAllowed(fileName: string) {
    const fileExtension = fileName.slice(
      ((fileName.lastIndexOf('.') - 1) >>> 0) + 2,
    );
    return ALLOWED_ATTACHMENT_DOCUMENT_TYPES.includes(`.${fileExtension}`);
  }

  function onDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    setActiveStatus(false);
    setActiveDrag(0);

    const droppedFiles: File[] = Array.prototype.slice.call(
      e.dataTransfer.files,
    );
    const filteredFiles = [...droppedFiles].filter((file) =>
      isFiledAllowed(file.name),
    );

    if (filteredFiles && filteredFiles.length) {
      onSelect(filteredFiles);
    }
  }

  function onBrowseClick() {
    inputRef.current.click();
  }

  return (
    <DropperContent
      isActive={isActive}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={onBrowseClick}
    >
      <Space direction="vertical" align="center">
        <CloudUploadOutlined style={{ fontSize: 45 }} />
        <Paragraph>
          <Text strong>{t('documents.uploadDocuments.dragAndDrop')}</Text>
          &nbsp;{t('documents.uploadDocuments.filesHereOr')}&nbsp;
          <Link>{t('documents.uploadDocuments.browseMyFiles')}</Link>
        </Paragraph>
      </Space>
      {renderInput()}
    </DropperContent>
  );
};

const DropperContent = styled('div')<{ isActive: boolean }>`
  border: 1px dashed #d1d3d4;
  background-color: ${(props: any) =>
    props.isActive
      ? props.theme.dragAndDropBackground
      : props.theme.dragAndDropBackground};
  cursor: pointer;
  height: 150px;
  color: ${({ theme }) => theme.dragAndDropTextColor};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default UploadDragAndDrop;
