import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';

// components
import { Descriptions, Typography } from 'antd';

interface IProps {
  accountName: string;
  accountNumber: string;
  accountCurrency: string;
}

const AccountInfo = ({
  accountName,
  accountNumber,
  accountCurrency,
}: IProps) => {
  const { t } = useTranslation('banking');

  return (
    <>
      <Descriptions layout="horizontal" column={1}>
        <Descriptions.Item label={t('forex.transfer_details.account_name')}>
          <Typography.Text>{accountName}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label={t('forex.transfer_details.account_number')}>
          <Typography.Text>{accountNumber}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label={t('forex.transfer_details.account_currency')}>
          <Typography.Text>{accountCurrency}</Typography.Text>
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default AccountInfo;
