import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { darkTheme } from '@resources/theme/styled';
import { useFormikContext } from 'formik';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { DISABLED_FORM_STATUS } from 'constants/form';
import { IApprovalRuleAccount } from 'components/Modals/TemplateModalDialogs/CRM/ApprovalEntryModalDialog/InnerContent/ChangeDifferenceSection/Templates/TransactionRule';
import {
  ApprovalGroupBaseModel,
  ApprovalGroupModel,
} from 'typings/approval/templates';

// components
// TODO: create reusable component and use it instead
import Text from '@core_components/Text';
import IconSVG from '@core_components/IconSVG';
import FormField from '@core_components/FormField';
import HeaderCell from './Components/HeaderCell';
import ContentCell from './Components/ContentCell';
import FormEditModeButtons from 'components/Forms/FormComponents/FormEditModeButtons';
import AccountsForTransactionRulesAutocomplete, {
  AccountsForTransactionRulesAutocompleteProps,
} from 'components/Forms/FormComponents/Autocompletes/CRM/TransactionRuleAccountsAutocomplete';
import { Button, Table } from 'antd';
import { ReactComponent as InfoIcon } from '../../../../../resources/icons/remix-icons/information-line.svg';

export interface ConditionModel {
  conditions: {
    approvalGroup: string;
    approvalGroupFull?: ApprovalGroupBaseModel | null;
    requiredApproversCount: number | null;
  }[];

  // Include or no status (checkboxes in table content)
  limitStatuses: { isChecked: boolean }[];
}

export interface LimitModel {
  from: number;
  to?: number;

  isUsed: boolean;
  toInfinite: boolean;
}

export type FormValuesModel = {
  isEditMode: boolean;
  limits: LimitModel[];
  conditions: ConditionModel[];
  bankAccounts?: IApprovalRuleAccount[];
  initialBankAccounts?: AutocompleteOption<IApprovalRuleAccount>[];
  clientGroupId?: number;
  filterClientGroupsInDropdown: boolean;
};

interface IProps {
  approvalGroups: ApprovalGroupModel[];
}

// TODO: refactor this form because it has a lot of business logic for different cases
const FinanceApprovalRulesForm = ({ approvalGroups }: IProps) => {
  const { t } = useTranslation(['crm', 'approval_management']);
  const { values, setValues, setFieldValue, status, errors } =
    useFormikContext<FormValuesModel>();

  const deleteLastLimit = () => {
    const limitsCopy = values.limits.slice();

    let conditionsCopy = values.conditions.slice();
    limitsCopy.pop();
    conditionsCopy = conditionsCopy.map((el) => ({
      ...el,
      limitStatuses: el.limitStatuses.slice(0, -1),
    }));

    setValues({
      ...values,
      conditions: conditionsCopy,
      limits: limitsCopy,
    });
  };

  const handleAddLimit = () => {
    const conditionsCopy = values.conditions.slice();

    const updatedConditions = conditionsCopy.map((el) => ({
      ...el,
      limitStatuses: [...el.limitStatuses, { isChecked: false }],
    }));

    const lastLimit = { ...values.limits[values.limits.length - 1] };
    const updatedLimits = [
      ...values.limits,
      {
        from: lastLimit.to ? lastLimit.to + 0.01 : 0.01,
        to: undefined,
        toInfinite: true,
        isUsed: false,
      },
    ];

    setValues({
      ...values,
      limits: updatedLimits,
      conditions: updatedConditions,
    });
  };

  const handleAddCondition = (typeToAdd: 'and' | 'or', toPosition = 0) => {
    let result = [];
    const limitsCount = values.limits.length;
    const conditionArrayCopy = values.conditions.slice();

    if (typeToAdd === 'and') {
      conditionArrayCopy[toPosition].conditions.push({
        approvalGroup: '',
        requiredApproversCount: 0,
      });
      result = conditionArrayCopy;
    } else {
      const newCondition: any = {
        conditions: [{ approvalGroup: '', requiredApproversCount: 0 }],
        limitStatuses: Array.from(Array(limitsCount), () => ({
          isChecked: false,
        })),
      };
      result = [...conditionArrayCopy, newCondition];
    }

    setFieldValue('conditions', result);
  };

  const canRenderTransactionRules = () => {
    return (
      !values.clientGroupId ||
      (values.clientGroupId &&
        !!(values.bankAccounts && values.bankAccounts.length))
    );
  };

  const tableColumns = useMemo(() => {
    const { limits } = values;

    const limitColumnsCount = limits.length;
    const canAddLimit =
      typeof limits[limits.length - 1].to !== 'undefined' &&
      typeof limits[limits.length - 1].from !== 'undefined' &&
      limits[limits.length - 1].from! < limits[limits.length - 1].to!;

    const numberOfLimitColumns = limits.length;

    const limitColumnsArray = Array.from(
      Array(numberOfLimitColumns),
      (_, i) => ({
        title: t(
          'client_group.approval.client_transaction_rules.edit_transaction_rule_modal.table.transaction_range',
        ),
        key: `limit-${i}`,
        dataIndex: 'limitStatuses',
      }),
    );

    const columns = [
      {
        key: 'conditions',
        title: t(
          'client_group.approval.client_transaction_rules.edit_transaction_rule_modal.table.transaction_approval_rules',
        ),
        dataIndex: 'conditions',
        width: '300px',
      },
      ...limitColumnsArray,
    ];

    return columns.map((col, index: number) => {
      const baseProps = {
        title: col.title,
        colIndex: index > 0 ? index - 1 : 0,
        dataIndex: col.dataIndex,
      };

      return {
        ...col,
        onHeaderCell: () => ({
          ...baseProps,
          canEdit: values.isEditMode,
          isDisabled:
            baseProps.colIndex < limitColumnsCount - 1 || !values.isEditMode,
          disabledFrom: limitColumnsCount > 1 || !values.isEditMode,
          canAddLimit,
          deleteCallback: () => deleteLastLimit(),
          onAddLimitClick: () => handleAddLimit(),
        }),
        onCell: (record: any, rowIndex: number) => ({
          ...baseProps,
          isDisabled: !values.isEditMode,
          record,
          rowIndex,
          approvalGroups,
          handleAddAnd: (toPosition: number) =>
            handleAddCondition('and', toPosition),
        }),
      };
    });
  }, [values, approvalGroups, values.isEditMode]);

  const handleBankAccountChange = (
    action: 'select' | 'deselect',
    accountModel: IApprovalRuleAccount,
  ) => {
    let newValue: IApprovalRuleAccount[] = [];
    if (values.bankAccounts) {
      if (action === 'select') {
        newValue = [...values.bankAccounts, accountModel];
      } else if (action === 'deselect') {
        newValue = values.bankAccounts.filter(
          (e) => e.bankAccountNumber !== accountModel.bankAccountNumber,
        );
      }
    }

    setFieldValue('bankAccounts', newValue);
  };

  const renderFormError = () => {
    if (!errors || !errors.limits) return null;

    let warning = null;

    if (typeof errors.limits === 'string') {
      warning = errors.limits;
    } else {
      const hasNoUsedWarning =
        errors.limits.findIndex((e: any) => e && e.isUsed) !== -1;

      if (hasNoUsedWarning) {
        warning =
          'client_group.approval.client_transaction_rules.edit_transaction_rule_modal.must_have_condition_warning';
      }
    }

    return (
      warning && (
        <FormErrorWrapper>
          <StyledIconSVG component={InfoIcon} color={darkTheme.errorColor} />
          <Text variant="body1" color={darkTheme.errorColor}>
            {t(warning)}
          </Text>
        </FormErrorWrapper>
      )
    );
  };

  return (
    <>
      {values.clientGroupId && (
        <FormField<AccountsForTransactionRulesAutocompleteProps>
          name="bankAccounts"
          label={t(
            'client_group.approval.client_transaction_rules.edit_transaction_rule_modal.form_fields.bank_account',
          )}
          component={AccountsForTransactionRulesAutocomplete}
          additionalProps={{
            clientGroupId: values.clientGroupId as number,
            initialValue: values.initialBankAccounts,
            onSelect: (_, option) =>
              option.model && handleBankAccountChange('select', option.model),
            onDeselect: (_, option) =>
              option.model && handleBankAccountChange('deselect', option.model),
          }}
        />
      )}
      {status !== DISABLED_FORM_STATUS && !values.clientGroupId && (
        <FormEditModeButtons />
      )}
      {canRenderTransactionRules() ? (
        <>
          {renderFormError()}
          <Table
            bordered
            className="disable-table-row-hover"
            size="large"
            scroll={{ x: 'max-content' }}
            dataSource={values.conditions.slice()}
            columns={tableColumns as any}
            pagination={false}
            components={{
              header: { cell: HeaderCell },
              body: { cell: ContentCell },
            }}
          />

          {values.isEditMode && (
            <BottomButtonsWrapper>
              <Button onClick={() => handleAddCondition('or')}>
                {t('finance_template.template.add_or_button', {
                  ns: 'approval_management',
                })}
              </Button>
            </BottomButtonsWrapper>
          )}
        </>
      ) : null}
    </>
  );
};

const FormErrorWrapper = styled.div`
  padding: 20px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomButtonsWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`;

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

export default FinanceApprovalRulesForm;
