import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { ICurrencyRate } from '../../../typings/finance/currencyRates';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';

interface FetchCurrencyRatesParamsModel extends FetchParamsModel {
  date: number;
}

export interface IGetRatesForCurrencyParams extends FetchParamsModel {
  currencyOneId: number;
  currencyTwoId: number;
  fromDate?: number | null;
  toDate?: number | null;
}

const currencyRatesAPI = {
  fetchCurrencyRates: (params: FetchCurrencyRatesParamsModel) => {
    return APIService.get<FetchResponseModel<ICurrencyRate>>(
      `${APIConfig.accountingApi}/currencies/rates`,
      { params },
    ).then(({ data }) => data);
  },

  getRatesForCurrency: (params: IGetRatesForCurrencyParams) => {
    return APIService.get<FetchResponseModel<ICurrencyRate>>(
      `${APIConfig.accountingApi}/currencies/rates`,
      { params },
    ).then(({ data }) => data);
  },
};

export { currencyRatesAPI };
