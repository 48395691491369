import { PermissionedAccessModel } from '.';

// Permission keys
export type CompliancePermissionKeys =
  | 'Compliance_View'
  | 'Compliance_OngoingReviews_View'
  | 'Compliance_Transactions_View'
  | 'Compliance_Transactions_Details_View'
  | 'Compliance_Transactions_Details_ReviewAlert'
  | 'Compliance_ReviewConfiguration_View'
  | 'Compliance_ReviewConfiguration_Create'
  | 'Compliance_ReviewConfiguration_Update'
  | 'Compliance_ReviewConfiguration_Delete'
  | 'Compliance_PropertyConfiguration_View'
  | 'Compliance_CountryRiskRatings_View'
  | 'Compliance_RDCMonitoring_View'
  | 'Compliance_ExpiringDocuments_View';

// Permission config
export const CompliancePermissions: Record<
  CompliancePermissionKeys,
  PermissionedAccessModel
> = {
  Compliance_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'AML:Review:Read' },
      { permissionKey: 'AML:ReviewProcess:Read' },
      { permissionKey: 'Accounting:Transaction:Read' },
      { permissionKey: 'AML:ReviewItem:Update' },
      { permissionKey: 'AML:ReviewConfigurationRule:Read' },
      { permissionKey: 'AML:ReviewConfigurationRule:Create' },
      { permissionKey: 'AML:ReviewConfigurationRule:Update' },
      { permissionKey: 'AML:ReviewConfigurationRule:Delete' },
      { permissionKey: 'AML:ReferenceObject:Read' },
      { permissionKey: 'AML:Country:Read' },
    ],
  },

  Compliance_OngoingReviews_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'AML:Review:Read' }],
  },

  Compliance_Transactions_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'AML:ReviewProcess:Read' }],
  },

  Compliance_Transactions_Details_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'AML:ReviewProcess:Read' },
      { permissionKey: 'Accounting:Transaction:Read' },
    ],
  },

  Compliance_Transactions_Details_ReviewAlert: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'AML:ReviewItem:Update' }],
  },

  Compliance_ReviewConfiguration_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'AML:ReviewConfigurationRule:Read' }],
  },

  Compliance_ReviewConfiguration_Create: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'AML:ReviewConfigurationRule:Create' }],
  },

  Compliance_ReviewConfiguration_Update: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'AML:ReviewConfigurationRule:Update' }],
  },

  Compliance_ReviewConfiguration_Delete: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'AML:ReviewConfigurationRule:Delete' }],
  },

  Compliance_PropertyConfiguration_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'AML:ReferenceObject:Read' }],
  },

  Compliance_CountryRiskRatings_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'AML:Country:Read' }],
  },

  Compliance_RDCMonitoring_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'AML:RDCMonitoringItem:Read' }],
  },

  Compliance_ExpiringDocuments_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:ExpiringDocument:Read' }],
  },
};
