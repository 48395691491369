import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';

const InnerForm = () => {
  const { t } = useTranslation('crm');

  return (
    <>
      <FormField
        label={t(
          'client_group.structure.finish_editing_message_modal.form_values.message',
        )}
        name="message"
        component={FormInputTextArea}
      />
    </>
  );
};

export default InnerForm;
