import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { TransactionRuleFromQuery } from 'api/approval/approvalManagement/clientTransactionRulesAPI';

// components
import SectionIntro from '@common_components/Texts/SectionIntro';
import InnerTemplate from './InnerTemplate';

export interface TransactionRuleModel extends TransactionRuleFromQuery {
  accounts: {
    bankAccountNumber: string;
    bankAccountName: string;
    bankAccountCurrency: string;
  }[];
}

export type TransactionApprovalRuleData = {
  before: TransactionRuleModel | null;
  after: TransactionRuleModel;
};

interface IProps {
  data: TransactionApprovalRuleData;
}

const TransactionRuleDetails = ({ data }: IProps) => {
  const { t } = useTranslation('crm');

  const renderContent = () => {
    if (data.before === null) {
      return (
        <>
          <SectionIntro
            titleVariant="h5"
            title={t(
              'client_group.administration_rules.pending_approvals.template_change_versions.new',
            )}
          />
          <InnerTemplate data={data.after} />
        </>
      );
    } else {
      return (
        <>
          <SectionIntro
            titleVariant="h5"
            title={t(
              'client_group.administration_rules.pending_approvals.template_change_versions.old',
            )}
          />
          <InnerTemplate data={data.before} />

          <SectionIntro
            titleVariant="h5"
            title={t(
              'client_group.administration_rules.pending_approvals.template_change_versions.new',
            )}
          />
          <InnerTemplate data={data.after} />
        </>
      );
    }
  };

  return <>{renderContent()}</>;
};

export default TransactionRuleDetails;
