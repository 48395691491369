import React from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';

// components
import RelationshipsTable from './RelationshipsTable';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  templateId: string | null;
}

const RelationshipInstancesModal = ({
  templateId,
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <ModalDialog
      title={t('relationship_templates.relationship_instances')}
      hideFooterButtons
      isVisible={isVisible}
      closeCallback={closeCallback}
    >
      {templateId && <RelationshipsTable relationshipTemplateId={templateId} />}
    </ModalDialog>
  );
};

export default RelationshipInstancesModal;
