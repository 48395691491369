import React from 'react';

// helpers
import useUserAccess from '../../../../hooks/useUserAccess';
import useTranslation from '../../../../hooks/useTranslation';
import { RoleBaseModel } from '../../../../typings/ORAC/roles';

// components
import Tabs from '../../../../components/Tabs';
import RoleInfo from './RoleInfo';

import UsersTab from './UsersTab';
import GroupsTab from './GroupsTab';
import PermissionsTab from './PermissionsTab';

import { CardWrapper } from '../../../../layouts/PageLayout';

interface IProps {
  role: RoleBaseModel;
}

const RoleDetails = ({ role }: IProps) => {
  const { t } = useTranslation('orac');

  const [
    hasGroupsAccess,
    hasGroupUpdateAccess,
    hasUsersAccess,
    hasPermissionsAccess,
    hasPermissionsUpdateAccess,
  ] = useUserAccess([
    'ORAC_Roles_Details_Groups_View',
    'ORAC_Roles_Details_Groups_Update',
    'ORAC_Roles_Details_Users_View',
    'ORAC_Roles_Details_Permissions_View',
    'ORAC_Roles_Details_Permissions_Update',
  ]);

  const tabs = [
    {
      key: 'groups',
      title: t('roles.view.groups'),
      hidden: !hasGroupsAccess,
      content: (
        <GroupsTab
          roleId={role.id}
          access={{ canEditGroup: hasGroupUpdateAccess }}
        />
      ),
    },

    {
      key: 'users',
      title: t('roles.view.users'),
      hidden: !hasUsersAccess,
      content: <UsersTab roleId={role.id} />,
    },

    {
      key: 'permissions',
      title: t('roles.view.permissions'),
      hidden: !hasPermissionsAccess,
      content: (
        <PermissionsTab
          roleId={role.id}
          access={{ canEdit: hasPermissionsUpdateAccess }}
        />
      ),
    },
  ];

  return (
    <>
      <CardWrapper>
        <RoleInfo role={role} />
      </CardWrapper>

      <CardWrapper>
        <Tabs tabs={tabs} />
      </CardWrapper>
    </>
  );
};

export default RoleDetails;
