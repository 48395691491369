import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ApprovalRuleSetFullModel } from 'apiAdapters/approval/clientAccountManagementRulesAdapterAPI';
import { ClientApprovalWorkflowChangeTypes } from 'typings/approval/approvalWorkflow';

// components
import SectionIntro from '@common_components/Texts/SectionIntro';
import InnerTemplate from './InnerTemplate';

export type ApprovalRuleModel = {
  approvalRuleSets: ApprovalRuleSetFullModel[];
  id: string;
  templateCode: string;
};

export type UpdateApprovalRuleData = {
  before: ApprovalRuleModel | null;
  after: ApprovalRuleModel;
  changeType: ClientApprovalWorkflowChangeTypes;
};

interface IProps {
  data: UpdateApprovalRuleData;
}

const UpdateApprovalRuleDetails = ({ data }: IProps) => {
  const { t } = useTranslation('crm');

  const renderContent = () => {
    switch (data.changeType) {
      case ClientApprovalWorkflowChangeTypes.Create:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t(
                'client_group.administration_rules.pending_approvals.template_change_versions.new',
              )}
            />
            <InnerTemplate data={data.after as ApprovalRuleModel} />
          </>
        );

      case ClientApprovalWorkflowChangeTypes.Delete:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t(
                'client_group.administration_rules.pending_approvals.template_change_versions.removed',
              )}
            />
            <InnerTemplate data={data.after as ApprovalRuleModel} />
          </>
        );

      case ClientApprovalWorkflowChangeTypes.Edit:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t(
                'client_group.administration_rules.pending_approvals.template_change_versions.old',
              )}
            />
            <InnerTemplate data={data.before as ApprovalRuleModel} />

            <SectionIntro
              titleVariant="h5"
              title={t(
                'client_group.administration_rules.pending_approvals.template_change_versions.new',
              )}
            />
            <InnerTemplate data={data.after as ApprovalRuleModel} />
          </>
        );
    }
  };

  return <>{renderContent()}</>;
};

export default UpdateApprovalRuleDetails;
