import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { ICurrencyRate } from '../../../../../typings/finance/currencyRates';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { ExchangeCurrencyRateParams } from '../../../../../modules/Finance/CurrencyRates/CurrencyRatesTable';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<ICurrencyRate> {
  onSelectCurrency: (currencyRateParams: ExchangeCurrencyRateParams) => void;
}

const CurrencyRatesTable = memo(({ onSelectCurrency, ...rest }: IProps) => {
  const { t } = useTranslation('finance');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('currency_rates.currency_one'),
        key: 'currency-one',
        render: (record: ICurrencyRate) => record.currencyOneIsoCode,
      },
      {
        title: t('currency_rates.currency_two'),
        key: 'currency-two',
        render: (record: ICurrencyRate) => record.currencyTwoIsoCode,
      },
      {
        title: t('currency_rates.currency_two_name'),
        key: 'currency-two',
        render: (record: ICurrencyRate) => record.currencyTwoName,
      },
      {
        title: t('currency_rates.exchange_rate'),
        key: 'exchange-rate',
        render: ({
          currencyOneIsoCode,
          currencyOneId,
          currencyTwoId,
          currencyTwoIsoCode,
          rate,
        }: ICurrencyRate) => (
          <Typography.Link
            onClick={() => onSelectCurrency({ currencyOneId, currencyTwoId })}
          >
            {`1 ${currencyOneIsoCode} = ${AccountsHelpers.convertAmountBigIntToLocaleString(rate)} ${currencyTwoIsoCode}`}
          </Typography.Link>
        ),
      },
    ];

    return result;
  }, [t, onSelectCurrency]);

  return <Table columns={columns} {...rest} />;
});

export default CurrencyRatesTable;
