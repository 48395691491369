import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { FullTransactionFromQuery } from '../../../../../apiAdapters/banking/bankingTransactionsAPIAdapter';
import { ApprovalManagementTemplateCodes } from '../../../../../enums/approvalManagement/templates';
import {
  ApprovalFXTransferDataModel,
  ApprovalWorkflowInstanceModel,
} from '../../../../../typings/approval/approvalWorkflow';

// components
import ApprovalStateInfo from './ApprovalStateInfo';
import FXTransferDetails from './EntrySnapshots/AWT_FXTRANSFER/FXTransferDetails';
import ApprovalInstanceInfo from './ApprovalInstanceInfo';
import IncomingTransactionDetails from './EntrySnapshots/AWT_FINANCE/IncomingTransactionDetails';
import NewJournalDetails, {
  NewJournalDetailsDataFromQuery,
} from './EntrySnapshots/AWT_FINANCE/NewJournalDetails';
import UpdateWireDetails, {
  UpdateWireDetailsData,
} from './EntrySnapshots/AWT_SENIORMNGT/UpdateWireDetails';
import TransactionRuleDetails, {
  TransactionApprovalRuleData,
} from './EntrySnapshots/AWT_SENIORMNGT/TransactionRuleDetails';
import ComplianceEntityDetails, {
  ComplianceEntityData,
} from './EntrySnapshots/AWT_COMPLIANCE/ComplianceEntityDetails';
import UpdateFeeScheduleDetails, {
  UpdateFeeScheduleData,
} from './EntrySnapshots/AWT_SENIORMNGT/UpdateFeeScheduleDetails';
import ComplianceContactDetails, {
  ComplianceContactData,
} from './EntrySnapshots/AWT_COMPLIANCE/ComplianceContactDetails';
import UpdateBankAccountDetails, {
  UpdateBankAccountData,
} from './EntrySnapshots/AWT_SENIORMNGT/UpdateBankAccountDetails';
import NewBusinessEntityDetails, {
  NewBusinessEntityData,
} from './EntrySnapshots/AWT_NEWBUSINESS/NewBusinessEntityDetails';
import UpdateApprovalRuleDetails, {
  UpdateApprovalRuleData,
} from './EntrySnapshots/AWT_SENIORMNGT/UpdateApprovalRuleDetails';
import NewBusinessContactDetails, {
  NewBusinessContactData,
} from './EntrySnapshots/AWT_NEWBUSINESS/NewBusinessContactDetails';
import ClientApprovalGroupDetails, {
  ClientApprovalGroupData,
} from './EntrySnapshots/AWT_SENIORMNGT/ClientApprovalGroupDetails';
import RejectOutgoingWireTransferDetails, {
  RejectOutgoingTransactionData,
} from './EntrySnapshots/AWT_FINANCE/RejectOutgoingWireTransferDetails';
import UpdateTransferTemplateStatusDetails, {
  UpdateTransferTemplateStatusData,
} from './EntrySnapshots/AWT_SENIORMNGT/UpdateTransferTemplateStatusDetails';
import ComplianceIncomingTransactionDetails, {
  ComplianceIncomingTransaction,
} from './EntrySnapshots/AWT_COMPLIANCE/ComplianceIncomingTransactionDetails';
import UpdateReconciliationStatusesDetails, {
  UpdatedReconciliationStatusesFromQuery,
} from './EntrySnapshots/AWT_FINANCE/UpdateReconciliationStatusesDetails';
import ComplianceOutgoingTransactionDetails, {
  ComplianceOutgoingTransaction,
} from './EntrySnapshots/AWT_COMPLIANCE/ComplianceOutgoingTransactionDetails';
import UpdateTransactionCurrencyListDetails, {
  UpdateTransactionCurrencyListData,
} from './EntrySnapshots/AWT_SENIORMNGT/UpdateTransactionCurrencyListDetails';
import ComplianceContactUpdatePersonalDetails, {
  ComplianceContactChangeRequestData,
} from './EntrySnapshots/AWT_COMPLIANCE/ComplianceContactUpdatePersonalDetails';
import ComplianceClientBankAccountDetails, {
  ComplianceClientBankAccountDetailsData,
} from './EntrySnapshots/AWT_COMPLIANCE/ComplianceClientBankAccountDetails';
import { Divider } from 'antd';

interface IProps {
  approvalInstance: ApprovalWorkflowInstanceModel;
  updateData: () => void;
}

const InstanceDetails = ({ approvalInstance, updateData }: IProps) => {
  const { t } = useTranslation('approval_management');

  const renderApprovalEntryDetails = (
    instance: ApprovalWorkflowInstanceModel,
  ) => {
    let result = null;

    switch (instance.templateCode) {
      case ApprovalManagementTemplateCodes.FXTransfer:
        result = (
          <FXTransferDetails
            forexInfo={
              JSON.parse(
                approvalInstance.entryData as string,
              ) as ApprovalFXTransferDataModel
            }
          />
        );
        break;

      case ApprovalManagementTemplateCodes.AWT_FINANCE:
        {
          switch (instance.entryType) {
            case 'FINANCE_INCOMING_WIRE_TRANSACTION':
            case 'FINANCE_INCOMING_WIRE_TRANSACTION_RETURN':
              {
                result = (
                  <IncomingTransactionDetails
                    incomingTransaction={
                      JSON.parse(
                        approvalInstance.entryData as string,
                      ) as FullTransactionFromQuery
                    }
                  />
                );
              }
              break;

            case 'FINANCE_JOURNAL_ENTRY_RECONCILIATION':
              {
                result = (
                  <UpdateReconciliationStatusesDetails
                    data={
                      JSON.parse(
                        approvalInstance.entryData as string,
                      ) as UpdatedReconciliationStatusesFromQuery
                    }
                  />
                );
              }
              break;

            case 'FINANCE_JOURNAL_ENTRY':
              {
                result = (
                  <NewJournalDetails
                    data={
                      JSON.parse(
                        approvalInstance.entryData as string,
                      ) as NewJournalDetailsDataFromQuery
                    }
                    creationDate={approvalInstance.startedBy.startedDate}
                  />
                );
              }
              break;

            case 'FINANCE_OUTGOING_WIRE_TRANSACTION_REJECT':
              {
                result = (
                  <RejectOutgoingWireTransferDetails
                    data={
                      JSON.parse(
                        approvalInstance.entryData as string,
                      ) as RejectOutgoingTransactionData
                    }
                  />
                );
              }
              break;
          }
        }
        break;

      case ApprovalManagementTemplateCodes.Compliance:
        {
          switch (instance.entryType) {
            case 'COMPLIANCE_INCOMING_TRANSACTION': {
              result = (
                <ComplianceIncomingTransactionDetails
                  complianceIncomingTransactionData={
                    JSON.parse(
                      approvalInstance.entryData as string,
                    ) as ComplianceIncomingTransaction
                  }
                />
              );
              break;
            }

            case 'COMPLIANCE_OUTGOING_TRANSACTION': {
              result = (
                <ComplianceOutgoingTransactionDetails
                  complianceOutgoingTransactionData={
                    JSON.parse(
                      approvalInstance.entryData as string,
                    ) as ComplianceOutgoingTransaction
                  }
                />
              );
              break;
            }

            case 'COMPLIANCE_ENTITY': {
              result = (
                <ComplianceEntityDetails
                  data={
                    JSON.parse(
                      approvalInstance.entryData as string,
                    ) as ComplianceEntityData
                  }
                />
              );
              break;
            }

            case 'COMPLIANCE_CONTACT': {
              result = (
                <ComplianceContactDetails
                  data={
                    JSON.parse(
                      approvalInstance.entryData as string,
                    ) as ComplianceContactData
                  }
                />
              );
              break;
            }

            case 'COMPLIANCE_CONTACT_UPDATE_NONKYC':
            case 'COMPLIANCE_CONTACT_UPDATE_KYC': {
              result = (
                <ComplianceContactUpdatePersonalDetails
                  data={
                    JSON.parse(
                      approvalInstance.entryData as string,
                    ) as ComplianceContactChangeRequestData
                  }
                />
              );
              break;
            }
            case 'COMPLIANCE_NEW_BANK_ACCOUNT': {
              result = (
                <ComplianceClientBankAccountDetails
                  data={
                    JSON.parse(
                      approvalInstance.entryData as string,
                    ) as ComplianceClientBankAccountDetailsData
                  }
                />
              );
              break;
            }
          }
        }
        break;

      case ApprovalManagementTemplateCodes.NewBusiness:
        {
          switch (instance.entryType) {
            case 'NEWBUSINESS_ENTITY': {
              result = (
                <NewBusinessEntityDetails
                  data={
                    JSON.parse(
                      approvalInstance.entryData as string,
                    ) as NewBusinessEntityData
                  }
                />
              );
              break;
            }

            case 'NEWBUSINESS_CONTACT': {
              result = (
                <NewBusinessContactDetails
                  data={
                    JSON.parse(
                      approvalInstance.entryData as string,
                    ) as NewBusinessContactData
                  }
                />
              );
              break;
            }
          }
        }
        break;

      case ApprovalManagementTemplateCodes.AWT_SENIORMNGT:
        {
          switch (instance.entryType) {
            case 'SENIORMNGT_ACCOUNT_STATUS':
              {
                result = (
                  <UpdateBankAccountDetails
                    data={
                      JSON.parse(
                        approvalInstance.entryData as string,
                      ) as UpdateBankAccountData
                    }
                  />
                );
              }
              break;

            case 'SENIORMNGT_FEE_SCHEDULE':
              {
                result = (
                  <UpdateFeeScheduleDetails
                    data={
                      JSON.parse(
                        approvalInstance.entryData as string,
                      ) as UpdateFeeScheduleData
                    }
                  />
                );
              }
              break;

            case 'SENIORMNGT_TRANSACTION_CURRENCY_LIST':
              {
                result = (
                  <UpdateTransactionCurrencyListDetails
                    data={
                      JSON.parse(
                        approvalInstance.entryData as string,
                      ) as UpdateTransactionCurrencyListData
                    }
                  />
                );
              }
              break;

            case 'SENIORMNGT_INTERNATIONAL_TRANSFER_TEMPLATE_ARCH': {
              result = (
                <UpdateTransferTemplateStatusDetails
                  data={
                    JSON.parse(
                      approvalInstance.entryData as string,
                    ) as UpdateTransferTemplateStatusData
                  }
                />
              );
              break;
            }
            case 'ADMIN_ACCOUNT_MNGT_APPROVAL_GROUP':
            case 'SENIORMNGT_CLIENT_TRANSACTION_APPROVAL_GROUP': {
              result = (
                <ClientApprovalGroupDetails
                  data={
                    JSON.parse(
                      approvalInstance.entryData as string,
                    ) as ClientApprovalGroupData
                  }
                />
              );
              break;
            }

            case 'ADMIN_ACCOUNT_MNGT_APPROVAL_RULE': {
              result = (
                <UpdateApprovalRuleDetails
                  data={
                    {
                      ...JSON.parse(approvalInstance.entryData as string),
                      changeType: approvalInstance.value,
                    } as UpdateApprovalRuleData
                  }
                />
              );
              break;
            }

            case 'SENIORMNGT_CLIENT_TRANSACTION_APPROVAL_TEMPLATE': {
              result = (
                <TransactionRuleDetails
                  data={
                    JSON.parse(
                      approvalInstance.entryData as string,
                    ) as TransactionApprovalRuleData
                  }
                />
              );
              break;
            }

            case 'ADMIN_SENIORMNGT_ACCOUNT_WIRE_DETAILS_CHANGE': {
              result = (
                <UpdateWireDetails
                  data={
                    JSON.parse(
                      approvalInstance.entryData as string,
                    ) as UpdateWireDetailsData
                  }
                />
              );
            }
          }
        }
        break;
    }

    return result ? (
      <>
        <Divider orientation="left">
          {t('approval_workflow.changes_snapshot_section_title')}
        </Divider>
        {result}
      </>
    ) : null;
  };

  return (
    <>
      <ApprovalInstanceInfo approvalInstance={approvalInstance} />
      {renderApprovalEntryDetails(approvalInstance)}
      <ApprovalStateInfo
        updateData={updateData}
        approvalInstance={approvalInstance}
      />
    </>
  );
};

export default InstanceDetails;
