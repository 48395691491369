import { RoleBaseModel } from 'typings/ORAC/roles';
import { GroupBaseModel } from 'typings/ORAC/groups';
import { UserPermissionModel } from '../typings/profile/profile';
import { PermissionCheckModel } from '../typings/profile/security';
import { ObjectActionWithPermissionsModel } from 'typings/ORAC/objects';
import { PermissionBaseModel, PermissionType } from 'typings/ORAC/permissions';

const PermissionsHelpers = {
  hasAllPermissions: function (
    userPermissions: Map<string, UserPermissionModel>,
    expectedPermissions: PermissionCheckModel[] = [],
  ) {
    return expectedPermissions.every(({ permissionKey }) => {
      return userPermissions.has(permissionKey);
    });
  },

  hasSomePermission: function (
    userPermissions: Map<string, UserPermissionModel>,
    expectedPermissions: PermissionCheckModel[] = [],
  ) {
    return expectedPermissions.some(({ permissionKey }) => {
      return userPermissions.has(permissionKey);
    });
  },

  hasPermission: function (
    action: ObjectActionWithPermissionsModel,
    permissionType: PermissionType,
  ) {
    const { permissions = [] } = action;

    let result = false;

    switch (permissionType) {
      case 'single':
        result = permissions.some((e) => !e.multiple && !e.forbidden);
        break;

      case 'multiple':
        result = permissions.some((e) => e.multiple && !e.forbidden);
        break;

      case 'forbidden':
        result = permissions.some((e) => e.forbidden);
        break;
    }

    return result;
  },

  findPermissionInAction: function (
    action: ObjectActionWithPermissionsModel,
    permissionType: PermissionType,
  ) {
    const { permissions = [] } = action;

    let result: PermissionBaseModel | null = null;

    switch (permissionType) {
      case 'single':
        result = permissions.find((e) => !e.multiple && !e.forbidden) || null;
        break;

      case 'multiple':
        result = permissions.find((e) => e.multiple && !e.forbidden) || null;
        break;

      case 'forbidden':
        result = permissions.find((e) => e.forbidden) || null;
        break;
    }

    return result;
  },

  getPermissionType: function (
    permission: PermissionBaseModel,
  ): PermissionType | null {
    let result: PermissionType | null = null;

    if (permission.multiple && !permission.forbidden) {
      result = 'multiple';
    } else if (!permission.multiple && !permission.forbidden) {
      result = 'single';
    } else if (permission.forbidden) {
      result = 'forbidden';
    }

    return result;
  },

  getPermissionAssignWayInfo: function (permission: PermissionBaseModel) {
    const result: {
      direct: { users: string[] };
      inherited: { groups: GroupBaseModel[]; roles: RoleBaseModel[] };
    } = {
      direct: { users: [] },
      inherited: { groups: [], roles: [] },
    };

    // If the array of direct users is not empty, it means that this permission was assigned directly
    if (permission.directUsers?.length) {
      result.direct.users.push(...permission.directUsers);
    }

    // If the array of roles is not empty, it means that user received this permission from some role
    if (permission.roles?.length) {
      permission.roles.forEach((role) => {
        if (role.groups?.length) {
          result.inherited.groups.push(...role.groups);
        } else {
          result.inherited.roles.push(role);
        }
      });
    }

    return result;
  },
};

export { PermissionsHelpers };
