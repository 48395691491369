import React, { useMemo, useState } from 'react';

// helpers
import usersAPI from '../../../../../../../api/orac/usersAPI';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { message } from 'antd';
import { UserFullModel } from '../../../../../../../typings/ORAC/users';
import { AccountRecoveryRequestTypes } from '../../../../../../../enums/admin/orac';

// components
import AuthenticatorTypeSelect from './AuthenticatorTypeSelect';

interface IProps {
  defaultAuthenticator: AccountRecoveryRequestTypes;
  onChange: (type: AccountRecoveryRequestTypes) => void;
  user: UserFullModel;
  isTwoFactorFidoEnabled: boolean;
  isTwoFactorAuthenticationEnabled: boolean;
}

const ChangeDefaultAuthenticator = ({
  defaultAuthenticator,
  onChange,
  user,
  isTwoFactorFidoEnabled,
  isTwoFactorAuthenticationEnabled,
}: IProps) => {
  const { t } = useTranslation('orac');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const value = useMemo(() => {
    if (
      defaultAuthenticator === AccountRecoveryRequestTypes.Fido &&
      isTwoFactorFidoEnabled
    ) {
      return AccountRecoveryRequestTypes.Fido;
    } else if (
      defaultAuthenticator === AccountRecoveryRequestTypes.Authenticator &&
      isTwoFactorAuthenticationEnabled
    ) {
      return AccountRecoveryRequestTypes.Authenticator;
    }
  }, [user, defaultAuthenticator]);

  const canUpdateAuthenticator =
    isTwoFactorFidoEnabled && isTwoFactorAuthenticationEnabled;

  const handleDefaultAuthenticatorSelect = async (
    defaultTFAType: AccountRecoveryRequestTypes,
  ) => {
    setIsSubmitting(true);
    await usersAPI.changeDefaultAuthenticator(user._id, { defaultTFAType });
    onChange(defaultTFAType);
    message.success(
      t('users.view.two_step_verification.success_update_default_option'),
    );
    setIsSubmitting(false);
  };

  return (
    <AuthenticatorTypeSelect
      user={user}
      value={value}
      onSelect={handleDefaultAuthenticatorSelect}
      loading={isSubmitting}
      allowClear
      disabled={!canUpdateAuthenticator || isSubmitting}
    />
  );
};

export default ChangeDefaultAuthenticator;
