import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { DocumentAssociationTypes } from '../../../../../enums/crm/crm';

// components
import { Select, SelectProps } from 'antd';

interface IProps extends SelectProps<any> {
  allowedAssociationTypes?: DocumentAssociationTypes[];
}

const AssociationTypeSelect = ({
  allowedAssociationTypes,
  ...rest
}: IProps) => {
  const { t } = useTranslation('crm');

  const renderOptions = () => {
    const allowedOptions = allowedAssociationTypes
      ? allowedAssociationTypes.map((type) => ({
          key: type,
          content: t(`documents.uploadDocuments.select_association.${type}`),
        }))
      : [
          {
            key: DocumentAssociationTypes.AccountRecovery,
            content: t(
              `documents.uploadDocuments.select_association.${DocumentAssociationTypes.AccountRecovery}`,
            ),
          },
          {
            key: DocumentAssociationTypes.ClientGroup,
            content: t(
              `documents.uploadDocuments.select_association.${DocumentAssociationTypes.ClientGroup}`,
            ),
          },
          {
            key: DocumentAssociationTypes.Contact,
            content: t(
              `documents.uploadDocuments.select_association.${DocumentAssociationTypes.Contact}`,
            ),
          },
          {
            key: DocumentAssociationTypes.Entity,
            content: t(
              `documents.uploadDocuments.select_association.${DocumentAssociationTypes.Entity}`,
            ),
          },
        ];

    return allowedOptions.map((el) => (
      <Select.Option key={el.key} value={el.key}>
        {el.content}
      </Select.Option>
    ));
  };

  return (
    <Select {...rest} value={rest.value ? rest.value : undefined} showArrow>
      {renderOptions()}
    </Select>
  );
};
export default AssociationTypeSelect;
