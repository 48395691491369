import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useTranslation from '../../../hooks/useTranslation';
import { signOut } from '../../../redux/actions/auth';
import { changeView } from 'redux/actions/view';
import { userProfileRoutes } from '../../../routes/userProfileRoutes';
import { matchPath, useHistory } from 'react-router-dom';

// redux
import { StateModel } from '../../../redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from '../../../redux/reducers/auth';

// components
import Link from '@common_components/Texts/Link';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Divider, Menu, Dropdown, Row, Col } from 'antd';

const ProfileActions = () => {
  const { t } = useTranslation(['profile', 'common', 'page_titles']);
  const dispatch = useDispatch();
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const [visibleStatus, setVisibleStatus] = useState(false);

  const greetingText = useMemo(() => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return t('greeting_text.good_morning');
    } else if (currentHour >= 12 && currentHour < 18) {
      return t('greeting_text.good_afternoon');
    } else {
      return t('greeting_text.good_evening');
    }
  }, []);

  const { profileData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const onSignOut = () => {
    dispatch(signOut());
  };

  const renderActions = () => (
    <Menu
      getPopupContainer={(triggerNode: HTMLElement) =>
        (triggerNode?.parentNode as HTMLElement) || document.body
      }
    >
      {renderMenuItems()}
      <StyledDivider />
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={onSignOut}>
        {t('signout', { ns: 'common' })}
      </Menu.Item>
    </Menu>
  );

  const renderMenuItems = () => {
    const routesToRender = userProfileRoutes[0].childRoutes?.slice() || [];

    return routesToRender.map((e, i) => {
      const menuItemClassName = matchPath(location.pathname, { path: e.path })
        ? 'menu-item-selected'
        : '';

      return (
        <StyledMenuItem key={i} className={menuItemClassName}>
          <Link
            href={e.path}
            onClick={(event) => {
              event.preventDefault();
              dispatch(changeView(() => history.push(e.path)));
            }}
          >
            {t(e.title, { ns: 'page_titles' })}
          </Link>
        </StyledMenuItem>
      );
    });
  };

  const getAvatarLetter = () => {
    if (!profileData) return 'User';

    if (profileData.name) {
      return profileData.name;
    }

    return profileData.email;
  };

  return (
    <Row align="middle">
      <Col>
        <Dropdown
          getPopupContainer={(triggerNode: HTMLElement) =>
            (triggerNode?.parentNode as HTMLElement) || document.body
          }
          overlay={renderActions()}
          placement="bottomRight"
          arrow
          trigger={['click']}
          visible={visibleStatus}
          onVisibleChange={(visible: boolean) => setVisibleStatus(visible)}
        >
          <ProfileWrapper align="middle" gutter={[16, 0]}>
            <Col>
              <Avatar icon={<UserOutlined />} />
            </Col>
            {!breakpoint.xs && (
              <Col>
                {greetingText}, <br /> {getAvatarLetter()}
              </Col>
            )}
          </ProfileWrapper>
        </Dropdown>
      </Col>
    </Row>
  );
};

const ProfileWrapper = styled(Row)`
  color: ${({ theme }) => theme.textLightColor2};
  line-height: 18px;
  margin: ${({ theme }) => theme.marginXs} 0;

  &:hover {
    cursor: pointer;
  }
  .ant-divider-vertical {
    margin-right: ${({ theme }) => theme.marginMd};
    height: 20px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 10px 0px;
  border-color: ${({ theme }) => theme.textLightColor2};
`;

const StyledMenuItem = styled(Menu.Item)`
  &.ant-dropdown-menu-item a {
    color: ${({ theme }) => theme.colorLight} !important;
    &:hover {
      color: ${({ theme }) => theme.primaryGreen} !important;
      filter: none;
    }
  }

  &.menu-item-selected a {
    color: ${({ theme }) => theme.primaryGreen} !important;
  }
`;

export default ProfileActions;
