import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { IAccountModel } from '../../typings/finance/account';
import { CorrespondentBankModel } from '../../typings/banking/correspondentBanks';
import { WireDetailsModelFromQuery } from 'api/finance/account/accountsAPI';
import { BankCodeTypeFromQuery, IBank } from './bankingBankAccountsAPI';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

interface FetchCorrespondentBanksParams extends FetchParamsModel {
  name?: string;
  holdCurrency?: number;
}

interface FetchCorrespondentBankAccountsParams extends FetchParamsModel {
  accountQuery?: string;
  currencyId?: number;
}

interface FetchClientBankAccountsParams extends FetchParamsModel {
  correspondentBankAccounts?: string[];
}

export interface NewCorrespondentBankAccountModel {
  accountName: string;
  accountNumberName: string;
  currencyId: number;
  accountCategoryId: number;
  correspondentBankId: number;
}

export interface UpdateTransactCurrenciesBody {
  currencies: number[];
}

const correspondentBanksAPI = {
  fetchCorrespondentBanks: (params: FetchCorrespondentBanksParams) => {
    return APIService.get<FetchResponseModel<CorrespondentBankModel>>(
      `${APIConfig.accountingApi}/correspondent-banks`,
      { params },
    ).then(({ data }) => data);
  },

  fetchCorrespondentBankAccounts: (
    params: FetchCorrespondentBankAccountsParams,
  ) => {
    return APIService.get<FetchResponseModel<IAccountModel>>(
      `${APIConfig.accountingApi}/accounts/correspondent/search`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  createCorrespondentBankAccount: (
    newAccount: NewCorrespondentBankAccountModel,
  ) => {
    return APIService.post<IAccountModel>(
      `${APIConfig.accountingApi}/accounts/correspondent`,
      newAccount,
    ).then(({ data }) => data);
  },

  fetchCorrespondentBankById: (bankId: string) => {
    return APIService.get<CorrespondentBankModel>(
      `${APIConfig.accountingApi}/correspondent-banks/${bankId}`,
    ).then(({ data }) => data);
  },

  fetchCorrespondentBankAccountsByBankId: (
    bankId: string,
    params: FetchParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<IAccountModel>>(
      `${APIConfig.accountingApi}/correspondent-banks/${bankId}/correspondent-accounts`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchClientAccountsByBankId: (
    bankId: string,
    params: FetchClientBankAccountsParams,
  ) => {
    return APIService.get<FetchResponseModel<IAccountModel>>(
      `${APIConfig.accountingApi}/correspondent-banks/${bankId}/client-accounts`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  updateTransactCurrencies: (
    body: UpdateTransactCurrenciesBody,
    correspondentBankId: number,
  ) => {
    return APIService.patch(
      `${APIConfig.accountingApi}/correspondent-banks/${correspondentBankId}/currencies/transact`,
      body,
    ).then(({ data }) => data);
  },

  updateSSI: (
    data: WireDetailsModelFromQuery,
    correspondentAccountNumber: string,
  ) => {
    return APIService.patch(
      `${APIConfig.accountingApi}/accounts/correspondent/wire-details/${correspondentAccountNumber}`,
      data,
    ).then(({ data }) => data);
  },

  fetchBankCodeTypes: () => {
    return APIService.get<FetchResponseModel<BankCodeTypeFromQuery>>(
      `${APIConfig.accountingApi}/banks/codes/types`,
    ).then(({ data }) => data);
  },

  fetchCorrespondentBankCodesSuggest: (
    bankCode: string,
    bankCodeTypeId: number,
  ) => {
    return APIService.get<FetchResponseModel<IBank>>(
      `${APIConfig.accountingApi}/banks/codes/suggest`,
      {
        params: { bankCode, bankCodeTypeId },
      },
    ).then(({ data }) => data);
  },
};

export { correspondentBanksAPI };
