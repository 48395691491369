import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ReviewProcessStatuses } from 'enums/compliance/reviewConfiguration';

// components
import StatusLabel from 'components/Typography/StatusLabel';

const STATUS_MAP = {
  info: [ReviewProcessStatuses.New, ReviewProcessStatuses.Empty],
  warning: [
    ReviewProcessStatuses.InProgress,
    ReviewProcessStatuses.PendingApproval,
  ],
  success: [ReviewProcessStatuses.Approved],
  error: [ReviewProcessStatuses.Rejected],
};

interface IProps {
  status: ReviewProcessStatuses;
}

const RDCReviewProcessStatuses = ({ status }: IProps) => {
  const { t } = useTranslation('compliance');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(`review_process_statuses.${ReviewProcessStatuses[status]}`, '')}
    />
  );
};

export default RDCReviewProcessStatuses;
