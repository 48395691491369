import APIConfig from '../../../config/api';
import feeScheduleAPIAdapter from '../../../apiAdapters/finance/feeSchedule';
import { APIService } from '../../axiosInstance';
import { FetchResponseModel } from '../../../typings/common';
import {
  FeeScheduleModel,
  NewFeeScheduleModel,
  UpdateFeeScheduleEntryModel,
} from '../../../typings/finance/feeSchedule';

const feeScheduleAPI = {
  fetchFeeSchedulesByClientGroupId: (clientGroupId: number) => {
    return APIService.get<FetchResponseModel<FeeScheduleModel>>(
      `${APIConfig.accountingApi}/client-groups/${clientGroupId}/fee-schedules`,
    ).then(({ data }) =>
      feeScheduleAPIAdapter.fetchFeeSchedulesByClientGroupId(data),
    );
  },

  fetchDefaultFeeSchedules: () => {
    return APIService.get<FetchResponseModel<FeeScheduleModel>>(
      `${APIConfig.accountingApi}/client-groups/0/fee-schedules`,
    ).then(({ data }) =>
      feeScheduleAPIAdapter.fetchFeeSchedulesByClientGroupId(data),
    );
  },

  createFeeScheduleEntryForClientGroup: (
    clientGroupId: number,
    feeScheduleEntry: NewFeeScheduleModel,
  ) => {
    return APIService.post(
      `${APIConfig.accountingApi}/client-groups/${clientGroupId}/fee-schedules`,
      feeScheduleEntry,
    ).then(({ data }) => data);
  },

  updateFeeScheduleEntryById: (
    feeScheduleEntryId: number,
    updatedFeeSchedule: UpdateFeeScheduleEntryModel,
  ) => {
    return APIService.patch(
      `${APIConfig.accountingApi}/client-groups/fee-schedules/${feeScheduleEntryId}`,
      updatedFeeSchedule,
    ).then(({ data }) => data);
  },

  deleteFeeScheduleEntryById: (feeScheduleEntryId: number) => {
    return APIService.delete(
      `${APIConfig.accountingApi}/client-groups/fee-schedules/${feeScheduleEntryId}`,
    ).then(({ data }) => data);
  },
};

export default feeScheduleAPI;
