import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import { useReviewProcessContext } from '../../../../../../hooks/domainHooks/useReviewProcess';

// components
import { Progress } from 'antd';

const ProgressChart = memo(() => {
  const { reviewProgress } = useReviewProcessContext();
  return <StyledProgress percent={reviewProgress} />;
});

const StyledProgress = styled(Progress)<any>`
  width: 100%;

  .ant-progress-inner {
    width: 100% !important;
    height: 100% !important;
  }
`;

export default ProgressChart;
