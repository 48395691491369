import React, { memo } from 'react';

// components
import RDCMonitoringTable from 'modules/Compliance/RDCMonitoring/RDCMonitoringTable';

const RDCMonitoringPage = memo(() => {
  return <RDCMonitoringTable />;
});

export default RDCMonitoringPage;
