import { FormValuesModel } from '../../components/Modals/TemplateModalDialogs/Compliance/ReviewConfigurationRuleModal';
import { FetchResponseModel } from '../../typings/common';
import {
  ConfigurationRuleModel,
  EditConfigurationRuleModel,
  NewConfigurationRuleModel,
} from '../../typings/compliance/configurationRules';
import {
  ComparisonOperators,
  ComplianceReviewTypes,
  ConfigurationRuleState,
  ExpectedValueTypes,
  RiskLevelTypes,
} from '../../enums/compliance/reviewConfiguration';

const reviewConfigurationAdapter = {
  fetchConfigurationRules: (
    response: FetchResponseModel<ConfigurationRuleModel>,
  ): FetchResponseModel<ConfigurationRuleModel> => {
    const formattedData = response.data.map((e) => {
      const valueJSON =
        e.expected.type === ExpectedValueTypes.ReferenceObjectProperty
          ? JSON.stringify(
              JSON.parse(e.expected.valueJSON)?.ReferencePropertyName || '',
            )
          : e.expected.valueJSON;

      return {
        ...e,
        expected: {
          ...e.expected,
          valueJSON,
        },
      };
    });

    return {
      data: [...formattedData],
      total: response.total,
    };
  },

  fetchReviewConfigurationRuleById: (
    response: ConfigurationRuleModel,
  ): ConfigurationRuleModel => {
    const valueJSON =
      response.expected.type === ExpectedValueTypes.ReferenceObjectProperty
        ? JSON.stringify(
            JSON.parse(response.expected.valueJSON)?.ReferencePropertyName ||
              '',
          )
        : response.expected.valueJSON;

    return {
      ...response,
      expected: {
        ...response.expected,
        valueJSON,
      },
    };
  },

  createReviewConfigurationRule: (
    formValues: FormValuesModel,
  ): NewConfigurationRuleModel => {
    return {
      reviewType: formValues.reviewType as ComplianceReviewTypes,
      riskLevel: formValues.riskLevel as RiskLevelTypes,
      referenceObjectId: formValues.referenceObject as string,
      referencePropertyName: formValues.referenceProperty as string,
      comparationOperator: formValues.comparisonOperator as ComparisonOperators,
      expected: {
        type: formValues.expectedValueType as ExpectedValueTypes,
        valueJSON: JSON.stringify(formValues.expectedValue),
      },
      isManualReview: formValues.isManualReview as boolean,
      state: formValues.state as ConfigurationRuleState,
    };
  },

  editReviewConfigurationRule: (
    formValues: FormValuesModel,
  ): EditConfigurationRuleModel => {
    return {
      comparationOperator: formValues.comparisonOperator as ComparisonOperators,
      expected: {
        type: formValues.expectedValueType as ExpectedValueTypes,
        valueJSON: JSON.stringify(formValues.expectedValue),
      },
      isManualReview: formValues.isManualReview as boolean,
      state: formValues.state as ConfigurationRuleState,
    };
  },
};

export default reviewConfigurationAdapter;
