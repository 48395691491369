import { ISendMessageForm } from '../../components/Modals/TemplateModalDialogs/Messaging/SendMessageModal/InnerForm';
import { IMessageAttachmentBody } from '../../api/messaging/messagingAPI';

export interface NewThreadBodyModel {
  category: number;
  subject: string;
  body: string;
  attachments?: IMessageAttachmentBody[];
  to: MessageRecipient[];
  isHighImportant: boolean;
}

export interface MessageRecipient {
  id: string;
  name: string;
}

const messagingAdapter = {
  createThread: (formValues: ISendMessageForm): NewThreadBodyModel => {
    return {
      body: formValues.message as string,
      category: formValues.categoryId as number,
      subject: formValues.subject as string,
      isHighImportant: formValues.isHighImportant,
      attachments: formValues.attachments?.map((attachment) => {
        return {
          documentId: attachment.id,
          contentType: attachment.file?.contentType || '',
          fileId: attachment.file?.id || '',
          name: attachment.file?.name || '',
        };
      }),
      to: formValues.recipients,
    };
  },
};

export { messagingAdapter };
