import { RangeOption } from 'components/Forms/FormComponents/SelectInputs/RangeFormSelect';
import { ISelectRangeOption } from '../components/Forms/FormComponents/SelectInputs/SelectRangeInput';

interface AnyObject {
  [key: string]: any;
}

// Generate unique id
export function generateUniqId() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function enumToMap(enumeration: any): Map<string, string | number> {
  const map = new Map<string, string | number>();
  for (const key in enumeration) {
    //TypeScript does not allow enum keys to be numeric
    if (!isNaN(Number(key))) continue;

    const val = enumeration[key] as string | number;

    //TypeScript does not allow enum value to be null or undefined
    if (val !== undefined && val !== null) map.set(key, val);
  }

  return map;
}

// Generate all path array from current location pathname (from tree)
// e.g Input Value = /system/admin/user -> Result = ['/system', '/system/admin', '/system/admin/user']
export const getDefaultOpenedKeys = (pathName: string) => {
  const pathArray = pathName.split('/').filter((el) => el);
  if (pathArray.length <= 1) {
    return [pathName];
  }

  let path = '';
  const result: string[] = [];

  pathArray.forEach((e, i) => {
    if (i === 0) {
      path = `/${e}`;
    } else {
      path = `${path}/${e}`;
    }

    result.push(path);
  });

  return result;
};

export const sortObjectKeys = (obj: { [key: string]: any }) => {
  if (!Object.keys(obj).length) {
    return obj;
  }

  return Object.keys(obj)
    .sort()
    .reduce((acc: { [key: string]: any }, key: string) => {
      if (Array.isArray(obj[key])) {
        acc[key] = obj[key].map(sortObjectKeys);
      } else if (typeof obj[key] === 'object') {
        acc[key] = sortObjectKeys(obj[key]);
      } else {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
};

export const getRangeValue = (
  options: RangeOption[],
  range?: {
    min?: number;
    max?: number;
  },
): ISelectRangeOption | undefined => {
  return options.find(
    ({ min, max }) =>
      range?.min === min && (range?.max ? range?.max === max : true),
  );
};

export function accessPropertyByString<T>(
  obj: T,
  fieldString: string,
): unknown {
  const keys = fieldString.split('.');
  let value: unknown = obj;

  for (const key of keys) {
    if (Array.isArray(value)) {
      const index = parseInt(key, 10);
      if (!isNaN(index) && index >= 0 && index < value.length) {
        value = value[index];
      } else {
        value = undefined;
        break;
      }
    } else {
      value = (value as { [key: string]: unknown })[key];
    }
  }

  return value;
}

export function tryToParseJson(value: string) {
  const result: {
    success: boolean;
    value: unknown;
  } = {
    success: true,
    value,
  };

  try {
    result.value = JSON.parse(value);
  } catch {
    result.success = false;
  }

  return result;
}

function hasOwnProperty(obj: AnyObject, prop: string): boolean {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function compareObjects(
  oldObj: AnyObject,
  newObj: AnyObject,
  prefix = '',
): AnyObject {
  const changes: AnyObject = {};

  for (const key in oldObj) {
    if (hasOwnProperty(oldObj, key)) {
      const oldVal = oldObj[key];
      const newVal = newObj[key];

      if (Array.isArray(oldVal) && Array.isArray(newVal)) {
        // Handle arrays
        const arrayChanges = compareArrays(oldVal, newVal, `${prefix}${key}`);

        if (Object.keys(arrayChanges).length > 0) {
          changes[`${prefix}${key}`] = 'changed';
        }

        Object.keys(arrayChanges).forEach((arrayKey) => {
          changes[arrayKey] = arrayChanges[arrayKey];
        });
      } else if (typeof oldVal === 'object' && typeof newVal === 'object') {
        // Handle nested objects
        const nestedChanges = compareObjects(
          oldVal,
          newVal,
          `${prefix}${key}.`,
        );
        Object.keys(nestedChanges).forEach((nestedKey) => {
          changes[nestedKey] = nestedChanges[nestedKey];
        });
      } else if (oldVal !== newVal) {
        changes[`${prefix}${key}`] = 'changed';
      }
    }
  }

  // Check for additions
  for (const key in newObj) {
    if (hasOwnProperty(newObj, key) && !hasOwnProperty(oldObj, key)) {
      changes[`${prefix}${key}`] = 'new';
    }
  }

  // Check for deletions
  for (const key in oldObj) {
    if (hasOwnProperty(oldObj, key) && !hasOwnProperty(newObj, key)) {
      changes[`${prefix}${key}`] = 'deleted';
    }
  }

  return changes;
}

function compareArrays(
  oldArr: any[],
  newArr: any[],
  prefix: string,
): AnyObject {
  const changes: AnyObject = {};

  // Check for deleted items
  for (let i = newArr.length; i < oldArr.length; i++) {
    const deletedItem = oldArr[i];
    Object.keys(deletedItem).forEach((prop) => {
      changes[`${prefix}.${i}.${prop}`] = 'deleted';
    });
  }

  oldArr.forEach((oldVal, index) => {
    if (index < newArr.length) {
      const newVal = newArr[index];

      if (typeof oldVal === 'object' && typeof newVal === 'object') {
        const nestedPrefix = prefix.endsWith('.')
          ? prefix.slice(0, -1)
          : prefix;
        const nestedChanges = compareObjects(
          oldVal,
          newVal,
          `${nestedPrefix}.${index}.`,
        );
        Object.keys(nestedChanges).forEach((nestedKey) => {
          changes[nestedKey] = nestedChanges[nestedKey];
        });
      } else if (oldVal !== newVal) {
        changes[`${prefix}.${index}`] = 'changed';
      }
    } else {
      changes[`${prefix}.${index}`] = 'deleted';
    }
  });

  for (let i = oldArr.length; i < newArr.length; i++) {
    changes[`${prefix}.${i}`] = 'new';
    if (typeof newArr[i] === 'object') {
      const nestedChanges = compareObjects({}, newArr[i], `${prefix}.${i}.`);
      Object.keys(nestedChanges).forEach((nestedKey) => {
        changes[nestedKey] = nestedChanges[nestedKey];
      });
    }
  }

  return changes;
}

export const formatSortDirectionToCRMModel = (
  order: 'descend' | 'ascend' | null,
) => {
  switch (order) {
    case 'ascend':
      return true;
    case 'descend':
      return false;
    default:
      return undefined;
  }
};
