import React, { memo, useEffect, useState } from 'react';

// helpers
import useFetch from '../../../../../../../hooks/useFetch';
import useQueryParam from '@hooks/useQueryParam';
import { useHistory } from 'react-router-dom';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../../constants/global';
import { TransferTemplateTypes } from '../../../../../../../enums/finance/transferTemplates';
import { TransferTemplateStatuses } from 'enums/transfers/templates';
import { TransferTemplateStatusesFilterBar } from './FilterBar/TransferTemplateStatusSelect';
import {
  TransferTemplateFromQuery,
  transferTemplatesAPI,
} from '../../../../../../../api/transferTemplates/transferTemplatesAPI';

// components
import TransferTemplateModal from '../../../../../../../components/Modals/TemplateModalDialogs/Accounting/TransferTemplates/TransferTemplateModal';
import FilterBar, { FilterKeys } from './FilterBar';
import TransferTemplatesTable, {
  ActionKeys,
} from '../../../../../../../components/Tables/TableTemplates/Accounting/TransferTemplatesTable';
import { Divider } from 'antd';

interface IProps {
  clientGroupNumericId: number;
}

const TransferTemplatesTab = memo(({ clientGroupNumericId }: IProps) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTransferTemplate, setSelectedTransferTemplate] = useState<{
    id: number;
    type: TransferTemplateTypes;
  } | null>(null);

  const templateId = useQueryParam({ param: 'templateId' });
  const templateType = useQueryParam({ param: 'templateType' });

  const [filterValues, setFilterValues] = useState<{
    status?: TransferTemplateStatusesFilterBar;
  }>({
    status: 'All',
  });

  const { response, loading } = useFetch(
    () =>
      clientGroupNumericId
        ? transferTemplatesAPI.fetchTransferTemplatesByClientGroupId({
            page: currentPage,
            limit: DEFAULT_TABLE_LIMIT,
            clientGroupId: String(clientGroupNumericId),
            status:
              !filterValues.status || filterValues.status === 'All'
                ? undefined
                : TransferTemplateStatuses[filterValues.status],
          })
        : null,
    [clientGroupNumericId, currentPage, filterValues],
  );

  const handleActionsClick = (
    key: ActionKeys,
    record: TransferTemplateFromQuery,
  ) => {
    switch (key) {
      case 'view-details': {
        setSelectedTransferTemplate({
          id: record.id,
          type: record.templateTypeId,
        });
      }
    }
  };

  const handleFilterChange = (key: FilterKeys, value?: string) => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }

    switch (key) {
      case 'filter_by_status':
        setFilterValues((prev) => ({
          ...prev,
          status: value
            ? (value as TransferTemplateStatusesFilterBar)
            : undefined,
        }));
        break;
    }
  };

  const closeCallback = () => {
    if (templateId && templateType) {
      const searchParams = new URLSearchParams(location.search);

      searchParams.delete('templateId');
      searchParams.delete('templateType');

      const newSearchParams = searchParams.toString();

      history.replace({
        pathname: location.pathname,
        search: newSearchParams,
      });
    }
    setSelectedTransferTemplate(null);
  };

  useEffect(() => {
    if (templateId && templateType) {
      setSelectedTransferTemplate({
        id: Number(templateId),
        type: Number(templateType),
      });
    }
  }, [templateId, templateType]);

  return (
    <>
      <FilterBar
        selectedStatus={filterValues.status}
        onFilterChange={handleFilterChange}
      />

      <Divider />

      <TransferTemplatesTable
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={currentPage}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsClick}
      />

      <TransferTemplateModal
        template={selectedTransferTemplate}
        isVisible={!!selectedTransferTemplate}
        closeCallback={closeCallback}
      />
    </>
  );
});

export default TransferTemplatesTab;
