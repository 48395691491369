import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { IFinancialType } from '../../../../../typings/finance/financialTypes';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel<IFinancialType>;

const FinancialTypesTable = memo((props: IProps) => {
  const { t } = useTranslation('finance');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: 250,
        title: t('financial_types.table.financial_type_name'),
        key: 'financial_type_name',
        render: (record: IFinancialType) => record.financialTypeName,
      },
      {
        width: 200,
        title: t('financial_types.table.financial_type_id'),
        key: 'financial_type_id',
        render: (record: IFinancialType) => record.id,
      },
      {
        width: 200,
        title: t('financial_types.table.increase'),
        key: 'increase',
        render: (record: IFinancialType) =>
          record.isDebit
            ? t('financial_types.table.debit')
            : t('financial_types.table.credit'),
      },
    ];

    return result;
  }, [t]);

  return <Table columns={columns} {...props} />;
});

export default FinancialTypesTable;
