import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { AccountsHelpers } from '../../../../../helpers/finance/accounts';
import { PendingTransactionFromQuery } from '../../../../../api/transactions/transactionsAPI';

// components
import DocumentLink from '../../../../Typography/DocumentLink';
import ClientTransactionStatus from '../../../../Additional/Statuses/ClientTransactionStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

export type ActionKeys = 'view';

interface IProps
  extends RequiredPropsForTableModel<PendingTransactionFromQuery> {
  onActionsClick?: (
    key: ActionKeys,
    record: PendingTransactionFromQuery,
  ) => void;
}

const ClientTransactionsTable = ({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation('banking');

  const renderDescription = (record: PendingTransactionFromQuery) => {
    if (!record.description) {
      return null;
    }

    if (!onActionsClick) {
      return record.description;
    }

    return (
      <Typography.Link onClick={() => onActionsClick('view', record)}>
        {record.description}
      </Typography.Link>
    );
  };

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        title: t('transactions.table.requested_date'),
        key: 'requested_date',
        render: (record: PendingTransactionFromQuery) =>
          record.creationDate > 0
            ? DateHelpers.formatTimestampToString(record.creationDate)
            : '',
      },
      {
        title: t('transactions.table.value_date'),
        key: 'value_date',
        render: (record: PendingTransactionFromQuery) =>
          record.valueDate > 0
            ? DateHelpers.formatTimestampToString(record.valueDate)
            : '',
      },
      {
        title: t('transactions.table.transaction_status'),
        key: 'status',
        render: (record: PendingTransactionFromQuery) => (
          <ClientTransactionStatus
            transaction={{
              status: record.statusId,
              expiresAt: record.workflowExpirationDate,
            }}
          />
        ),
      },
      {
        width: 400,
        title: t('transactions.table.from_account'),
        key: 'from_account',
        mobileCardProps: { hideFromMainSection: true },
        render: (record: PendingTransactionFromQuery) =>
          AccountsHelpers.generateAccountFullName(
            record.fromAccountName,
            record.fromAccountNumber,
            record.fromCurrencyISOCode,
          ),
      },
      {
        title: t('transactions.table.description'),
        key: 'description',
        width: 350,
        render: renderDescription,
        mobileCardProps: { hideFromMainSection: true },
      },
      {
        title: t('transactions.table.documents'),
        key: 'documents',
        render: (record: PendingTransactionFromQuery) =>
          record.relatedDocuments.map(({ dmsId, linkToDownload, name }) => (
            <div key={dmsId}>
              <DocumentLink
                text={name}
                fileId={linkToDownload}
                documentId={dmsId}
              />
            </div>
          )),
        mobileCardProps: { hideFromMainSection: true },
      },
      {
        title: t('transactions.table.transaction_type'),
        key: 'type',
        mobileCardProps: { isCardTitle: true, hide: true },
        render: (record: PendingTransactionFromQuery) => record.sourceTypeName,
      },
      {
        title: t('transactions.table.currency'),
        key: 'currency',
        render: (record: PendingTransactionFromQuery) => record.currencyIsoCode,
      },
      {
        title: t('transactions.table.amount'),
        key: 'amount',
        render: (record: PendingTransactionFromQuery) =>
          record.amount
            ? AccountsHelpers.convertAmountBigIntToLocaleString(record.amount)
            : '',
      },
    ];
  }, [onActionsClick]);

  return <Table columns={tableColumns} {...rest} />;
};

export default ClientTransactionsTable;
