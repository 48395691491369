import React from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { IClientGroup } from 'typings/crm/client-group';
import { clientAccountManagementRulesAPI } from 'api/approval/approvalManagement/clientAccountManagementRulesAPI';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import InnerContent from './InnerContent';

interface IProps extends RequiredPropsForModalDialogModel {
  workflowId: string | null;
  clientGroup: IClientGroup;
}

const ApprovalEntryModalDialog = ({
  isVisible,
  workflowId,
  clientGroup,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation(['crm', 'common']);

  const { response, loading } = useFetch(
    () =>
      workflowId
        ? clientAccountManagementRulesAPI.getApprovalWorkflowById(
            workflowId,
            clientGroup.numericId,
          )
        : null,
    [workflowId, clientGroup.numericId],
  );

  return (
    <ModalDialog
      title={t(
        'client_group.administration_rules.pending_approvals.approval_details_modal.title',
      )}
      isVisible={isVisible}
      closeCallback={closeCallback}
      hideFooterButtons
      submitButtonProps={{ hidden: true }}
      cancelButtonProps={{
        text: t('cancel', { ns: 'common' }),
      }}
    >
      <LoadingWrapper loading={loading}>
        {response && (
          <InnerContent approvalEntry={response} clientGroup={clientGroup} />
        )}
      </LoadingWrapper>
    </ModalDialog>
  );
};

export default ApprovalEntryModalDialog;
