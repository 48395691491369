import React, { memo } from 'react';

// components
import DivAlignCenter from '../../../../Additional/DivAlignCenter';
import Button, { ButtonProps } from '@core_components/Button';

interface IProps extends ButtonProps {
  children: React.ReactNode;
}

const PageButton = memo(({ children, disabled, ...rest }: IProps) => {
  return (
    <DivAlignCenter>
      <Button type="primary" disabled={disabled} {...rest}>
        {children}
      </Button>
    </DivAlignCenter>
  );
});

export default PageButton;
