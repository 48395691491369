import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../../hooks/useFetch';
import usersAPI from '../../../../../../api/orac/usersAPI';
import useTranslation from '../../../../../../hooks/useTranslation';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../constants/global';

// components
import SessionHistoryTable from '../../../../../../components/Tables/TableTemplates/Profile/SessionHistoryTable';
import { Typography } from 'antd';
import { CardWrapper } from '../../../../../../layouts/PageLayout';

interface IProps {
  updateTableTrigger: any;
  userId: string;
}

const SessionHistory = ({ userId, updateTableTrigger }: IProps) => {
  const { t } = useTranslation('profile');

  const [currentPage, setPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      usersAPI.fetchSessionsByUserId(
        {
          limit: DEFAULT_TABLE_LIMIT,
          page: currentPage,
          inactive: true,
        },
        userId,
      ),
    [userId, currentPage, updateTableTrigger],
  );

  return (
    <CardWrapper>
      <Typography.Title level={4}>
        {t('sign_in_and_security.sessions.session_history')}
      </Typography.Title>
      <SessionHistoryTable
        loading={loading}
        current={currentPage}
        total={response?.total}
        data={response?.data || []}
        onPaginationChange={setPage}
      />
    </CardWrapper>
  );
};
export default SessionHistory;
