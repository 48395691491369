import { IReduxAction } from '../../typings/common';
import { ActionType } from '../actions/view';

// Models
export interface StateModel {
  handler: any | null;
}

// Initial state
export const initialState: StateModel = {
  handler: null,
};

// Reducer

// Reducer used to handle page, tabs and other view changing
// Allows to set handler function that will be called when user change the view
// Example of usage: show confirmation modal when user leave a page without saving a form
const view = (state = initialState, action: IReduxAction) => {
  const { type, payload, key } = action;

  switch (type) {
    case ActionType.SET_CHANGE_VIEW_HANDLER:
      return {
        ...state,
        [key]: payload,
      };

    default:
      return state;
  }
};

export default view;
