import React, { memo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { reportingAPI } from 'api/reporting/reportingAPI';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';

// components
import Text from '@core_components/Text';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import { default as TemplateTransactionsTable } from 'components/Tables/TableTemplates/Reporting/TransactionsTable';

interface IProps {
  accountNumber: string | null;
}

const TransactionsTable = memo(({ accountNumber }: IProps) => {
  const { t } = useTranslation('reporting');
  const [page, setPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      accountNumber
        ? reportingAPI.fetchEntriesForGeneralLedgerReport(accountNumber, {
            page,
            limit: DEFAULT_TABLE_LIMIT,
          })
        : null,
    [accountNumber, page],
  );

  return (
    <LoadingWrapper loading={!response}>
      {response?.data?.length ? (
        <TemplateTransactionsTable
          data={response?.data || []}
          total={response?.total || 0}
          current={page}
          loading={loading}
          onPaginationChange={setPage}
        />
      ) : (
        <StyledNoDataWrapper>
          <Text>{t('no_data')}</Text>
        </StyledNoDataWrapper>
      )}
    </LoadingWrapper>
  );
});

const StyledNoDataWrapper = styled(DivAlignCenter)`
  justify-content: center;
`;

export default TransactionsTable;
