// helpers
import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import {
  FiscalPeriodModel,
  FiscalPeriodStartDateModel,
} from '../../../typings/finance/fiscalPeriod';

interface GetFiscalPeriodParamsModel {
  calendarYear: number;
}

const fiscalPeriodAPI = {
  getFiscalPeriodData: (params: GetFiscalPeriodParamsModel) => {
    return APIService.get<FiscalPeriodModel[]>(
      `${APIConfig.accountingApi}/fiscal`,
      { params },
    ).then(({ data }) => data);
  },

  generateFiscalPeriod: () => {
    return APIService.post(`${APIConfig.accountingApi}/fiscal`).then(
      ({ data }) => data,
    );
  },

  getFiscalPeriodStartDate: () => {
    return APIService.get<FiscalPeriodStartDateModel>(
      `${APIConfig.accountingApi}/fiscal/start-date`,
    ).then(({ data }) => data);
  },
};

export { fiscalPeriodAPI };
