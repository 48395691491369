import React, { memo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { StyledComponentProps } from 'typings/common';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';

// components
import Text from '@core_components/Text';
import { Col, Row } from 'antd';
import { SearchInput } from 'components/Fields/SearchInput';

interface IProps extends StyledComponentProps {
  onFilterChange: (key: 'search' | 'date_range' | 'type', value: any) => void;
}

const FilterBar = memo(({ onFilterChange }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <Row gutter={[16, 16]}>
      <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
        <Text>{t('notes.table.search_by_name_label')}</Text>
        <SearchInput
          size="large"
          placeholder={t('notes.table.search_by_name_ph')}
          onSearch={(searchQuery: string) =>
            onFilterChange('search', searchQuery)
          }
        />
      </Col>
    </Row>
  );
});

export default FilterBar;
