import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { SelectProps } from 'antd/lib/select';
import { ComparisonOpertatorsByDataType } from '../../../../../../constants/compliance/reviewConfiguration';
import {
  ComparisonOperators,
  ExpectedDataTypes,
} from '../../../../../../enums/compliance/reviewConfiguration';

// components
import { Select } from 'antd';

interface IProps extends SelectProps<any> {
  dataType: ExpectedDataTypes;
}

const ComparisonOperatorSelect = ({ value, dataType, ...rest }: IProps) => {
  const { t } = useTranslation('compliance');

  const renderOptions = () => {
    if (typeof dataType === 'undefined' || dataType === null) {
      return null;
    }

    return ComparisonOpertatorsByDataType[dataType].map(
      (key: ComparisonOperators) => (
        <Select.Option key={key} value={key}>
          {t(`comparison_operator.${ComparisonOperators[key]}`)}
        </Select.Option>
      ),
    );
  };

  return (
    <Select
      {...rest}
      value={value}
      showSearch
      placeholder={t('review_configuration.comparison_operator_ph')}
    >
      {renderOptions()}
    </Select>
  );
};

export default ComparisonOperatorSelect;
