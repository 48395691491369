import React, { useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { ClientApprovalGroup } from 'typings/approval/approvalGroups';
import { clientAccountManagementRulesAPI } from 'api/approval/approvalManagement/clientAccountManagementRulesAPI';

// components
import ClientApprovalGroupsTable, {
  ActionKeys,
} from 'components/Tables/TableTemplates/ApprovalManagement/ClientApprovalGroupsTable';

interface IProps {
  clientGroupId: number;
  updateTrigger: any;
  onActionsClick: (key: ActionKeys, record: ClientApprovalGroup) => void;
}

const ApprovalGroupsTable = ({
  clientGroupId,
  updateTrigger,
  onActionsClick,
}: IProps) => {
  const [page, setPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      clientGroupId
        ? clientAccountManagementRulesAPI.fetchApprovalGroups(
            { page, limit: DEFAULT_TABLE_LIMIT },
            clientGroupId,
          )
        : null,
    [page, clientGroupId, updateTrigger],
  );

  return (
    <ClientApprovalGroupsTable
      current={page}
      loading={loading}
      data={response?.data || []}
      total={response?.total || 0}
      onPaginationChange={setPage}
      onActionsClick={onActionsClick}
    />
  );
};

export default ApprovalGroupsTable;
