import React from 'react';

// components
import Onboarding from './Onboarding';
import PostOnboarding from './PostOnboarding';

interface IProps {
  clientGroupName: string;
  clientGroupNumericId: number;
  isClientGroupInOnboardingProcess: boolean;
}

const TransactionRulesTab = ({
  clientGroupName,
  clientGroupNumericId,
  isClientGroupInOnboardingProcess,
}: IProps) => {
  return isClientGroupInOnboardingProcess ? (
    <Onboarding
      clientGroupName={clientGroupName}
      clientGroupNumericId={clientGroupNumericId}
    />
  ) : (
    <PostOnboarding clientGroupNumericId={clientGroupNumericId} />
  );
};

export default TransactionRulesTab;
