import React from 'react';

// helpers
import styled from 'styled-components';
import { IApprovalMatrixRecord } from '../../../typings/finance/transaction';

// components
import ContentCell from './ContentCell';
import { Table } from 'antd';

interface IProps {
  transactionApprovals: IApprovalMatrixRecord[][];
}

const ApprovalMatrix = ({ transactionApprovals }: IProps) => {
  const tableColumns = [
    {
      width: '300px',
      key: 'group-info',
      dataIndex: 'group-info',
    },
    {
      width: '350px',
      title: 'Status',
      key: 'status',
      dataIndex: 'status-info',
    },
    {
      width: '250px',
      title: 'Note',
      key: 'note',
      dataIndex: 'note-info',
    },
  ];

  const formatTableColumns = (): any[] => {
    return tableColumns.map((col) => ({
      ...col,
      onCell: (record: any, rowIndex: number) => ({
        style: { minWidth: col.width },
        record,
        rowIndex,
        isOrText: rowIndex < transactionApprovals.length - 1,
        dataIndex: col.dataIndex,
      }),
    }));
  };

  return (
    <StyledTable
      scroll={{ x: 'max-content' }}
      bordered={true}
      pagination={false}
      columns={formatTableColumns()}
      dataSource={transactionApprovals}
      className="disable-table-row-hover"
      components={{ body: { cell: ContentCell } }}
    />
  );
};

const StyledTable = styled(Table)`
  .ant-table {
    margin: 0 !important;
    border-bottom: ${({ theme }) => `1px solid ${theme.tableBorderColor}`};
  }
`;

export default ApprovalMatrix;
