import React, { memo, useMemo } from 'react';

// helpers
import useUserAccess from '../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { DateHelpers } from '@helpers/date';
import { OngoingReviewModel } from '../../../../../typings/compliance/ongoingReviews';
import { DEFAULT_FULL_DATE_FORMAT } from '../../../../../constants/global';
import { OngoingReviewReviewTypes } from 'enums/compliance/ongoingReviews';
import {
  OngoingReviewEntryTypes,
  OngoingReviewTriggerTypes,
  RiskLevelTypes,
} from '../../../../../enums/compliance/reviewConfiguration';

// components
import Link from '@common_components/Texts/Link';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel<OngoingReviewModel>;

const OngoingReviewsTable = memo((props: IProps) => {
  const history = useHistory();
  const { t } = useTranslation('compliance');
  const [
    hasClientGroupDetailsAccess,
    hasContactDetailsAccess,
    hasBankingBankAccountsAccess,
  ] = useUserAccess([
    'CRM_ClientGroup_Details_View',
    'CRM_Contacts_Details_View',
    'Banking_BankAccounts_View',
  ]);

  const renderName = ({ entry }: OngoingReviewModel) => {
    switch (entry.type) {
      case OngoingReviewEntryTypes.Contact: {
        if (!hasContactDetailsAccess) {
          return (
            <EllipsisTooltip maxTextContainerWidth="200px" title={entry.name}>
              {entry.name}
            </EllipsisTooltip>
          );
        }

        return (
          <Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.CRM_Contacts_Edit,
                search: `?id=${entry.id}`,
              })
            }
          >
            <EllipsisTooltip maxTextContainerWidth="200px" title={entry.name}>
              {entry.name}
            </EllipsisTooltip>
          </Link>
        );
      }

      case OngoingReviewEntryTypes.ClientGroup: {
        if (!hasClientGroupDetailsAccess) {
          return (
            <EllipsisTooltip maxTextContainerWidth="200px" title={entry.name}>
              {entry.name}
            </EllipsisTooltip>
          );
        }

        return (
          <Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.CRM_Client_Group_Details,
                search: `?id=${entry.id}`,
              })
            }
          >
            <EllipsisTooltip maxTextContainerWidth="200px" title={entry.name}>
              {entry.name}
            </EllipsisTooltip>
          </Link>
        );
      }

      case OngoingReviewEntryTypes.BankAccount: {
        if (!hasBankingBankAccountsAccess) {
          return (
            <EllipsisTooltip maxTextContainerWidth="200px" title={entry.name}>
              {entry.name}
            </EllipsisTooltip>
          );
        }

        return (
          <Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.Banking_BankAccounts,
                search: `?accountNumber=${entry.id}`,
              })
            }
          >
            <EllipsisTooltip maxTextContainerWidth="200px" title={entry.name}>
              {entry.name}
            </EllipsisTooltip>
          </Link>
        );
      }
    }
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('outgoing_reviews.table.name'),
        key: 'name',
        render: renderName,
      },

      {
        title: t('outgoing_reviews.table.type'),
        key: 'type',
        render: (record: OngoingReviewModel) =>
          t(
            `ongoing_review_entry_types.${OngoingReviewEntryTypes[record.entry.type]}`,
            '',
          ),
      },

      {
        width: 200,
        title: t('outgoing_reviews.table.review_type'),
        key: 'review_type',
        render: (record: OngoingReviewModel) =>
          t(
            `ongoing_review_review_types.${OngoingReviewReviewTypes[record.reviewType]}`,
          ),
      },

      {
        title: t('outgoing_reviews.table.risk_level'),
        key: 'risk_level',
        render: (record: OngoingReviewModel) =>
          typeof record.metadata?.riskLevel !== 'number'
            ? '-'
            : t(
                `risk_level_types.${RiskLevelTypes[record.metadata.riskLevel]}`,
              ),
      },

      {
        title: t('outgoing_reviews.table.trigger_type'),
        key: 'trigger_type',
        render: (record: OngoingReviewModel) =>
          t(
            `ongoing_review_types.${OngoingReviewTriggerTypes[record.triggerType]}`,
          ),
      },

      {
        title: t('outgoing_reviews.table.start_review_date'),
        key: 'start_review_date',
        render: (record: OngoingReviewModel) =>
          record.startReviewDate
            ? DateHelpers.formatDateToString(
                record.startReviewDate,
                DEFAULT_FULL_DATE_FORMAT,
              )
            : '-',
      },

      {
        title: t('outgoing_reviews.table.next_review_date'),
        key: 'next_review_date',
        render: (record: OngoingReviewModel) =>
          record.nextReviewDate
            ? DateHelpers.formatDateToString(
                record.nextReviewDate,
                DEFAULT_FULL_DATE_FORMAT,
              )
            : '-',
      },
    ];

    return result;
  }, [hasClientGroupDetailsAccess, hasContactDetailsAccess, t]);

  return <Table {...props} columns={columns} />;
});

export default OngoingReviewsTable;
