import React from 'react';

// Helpers & Utils
import { SelectProps } from 'antd/lib/select';
import useTranslation from '../../../../hooks/useTranslation';
import { RelationshipRelatedTypes } from '../../../../enums/crm/crm';

// Components
import { Select } from 'antd';

interface IProps extends SelectProps<any> {
  filterOptionsBy?: string;
}

const FieldForSelect = ({ filterOptionsBy, ...rest }: IProps) => {
  const { t } = useTranslation(['crm', 'common']);

  const renderOptions = () => {
    let options = [
      { key: RelationshipRelatedTypes.Any, name: t('all', { ns: 'common' }) },
      {
        key: RelationshipRelatedTypes.Contact,
        name: t('relationship_templates.contacts_only'),
      },
      {
        key: RelationshipRelatedTypes.Organization,
        name: t('relationship_templates.entities_only'),
      },
    ];

    if (filterOptionsBy && filterOptionsBy !== RelationshipRelatedTypes.Any) {
      options = options.filter(({ key }) => key === filterOptionsBy);
    }

    return options.map(({ key, name }) => (
      <Select.Option key={key} value={key}>
        {name}
      </Select.Option>
    ));
  };

  return <Select {...rest}>{renderOptions()}</Select>;
};

export default FieldForSelect;
