import { ActionType } from '../actions/auth';
import { IReduxAction } from '../../typings/common';
import { IUserProfile } from '../../typings/profile/profile';
import { LocalStorageHelpers } from '@helpers/storages/localStorage';
import { TwoFactorVerificationTypes } from '../../enums/auth/auth';

// Models

export interface TwoFactorVerificationDataModel {
  type: TwoFactorVerificationTypes;
  temporaryToken: string;
  options: TwoFactorVerificationOptionModel[];
}

export interface TwoFactorVerificationOptionModel {
  code: TwoFactorVerificationTypes;
  default: boolean;
  enabled: boolean;
}
export interface StateModel {
  isLoading: boolean;
  isAuthorized: boolean;
  profileData: IUserProfile | null;
  twoFactorVerificationData: TwoFactorVerificationDataModel | null;
}

// Initial state
const initialState: StateModel = {
  isAuthorized: false,
  profileData: null,
  isLoading: !!LocalStorageHelpers.getAccessToken(),
  twoFactorVerificationData: null,
};

// Reducer
const auth = (state = initialState, action: IReduxAction) => {
  const { type, payload, key } = action;

  switch (type) {
    case ActionType.SET_AUTH_STATUS:
      return {
        ...state,
        [key]: payload,
      };

    case ActionType.PROFILE_GET_START:
      return {
        ...state,
        isLoading: true,
      };

    case ActionType.PROFILE_GET_SUCCESS:
      return {
        ...state,
        isAuthorized: true,
        profileData: payload,
        isLoading: false,
      };

    case ActionType.PROFILE_GET_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionType.SET_AUTHENTICATOR_ENABLED_STATUS: {
      return {
        ...state,
        profileData: {
          ...state.profileData,
          isTwoFactorAuthenticationEnabled: payload.isEnabled,
        },
      };
    }

    case ActionType.SET_SESSION_DURATION:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          sessionDuration: payload.seconds,
        },
      };

    case ActionType.SET_FIDO_AUTHENTICATOR_ENABLED_STATUS: {
      return {
        ...state,
        profileData: {
          ...state.profileData,
          isTwoFactorFidoEnabled: payload.isEnabled,
        },
      };
    }

    case ActionType.SET_TWO_FACTOR_AUTHENTICATION_DATA:
      return {
        ...state,
        twoFactorVerificationData: payload.data,
      };

    default:
      return state;
  }
};

export default auth;
