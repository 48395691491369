import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { FormikHelpers } from 'formik';
import {
  PermissionBaseModel,
  PermissionType,
} from '../../../../../../typings/ORAC/permissions';
import {
  ObjectActionModel,
  ObjectBaseModel,
} from '../../../../../../typings/ORAC/objects';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Spin } from 'antd';

interface IProps extends RequiredPropsForModalDialogModel {
  initialFormValues: FormValuesModel | null;
  onSubmit: (
    values: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => Promise<any>;

  loadingInitialData?: boolean;

  canSetForbiddenPermissions?: boolean;
  onlyForbiddenPermissions?: boolean;
}

export interface FormValuesModel {
  selectedPermissions: Record<string, SelectedObjectModel>;
}

export interface SelectedObjectModel extends ObjectBaseModel {
  actions: Record<string, SelectedActionModel>;
}

export interface SelectedActionModel extends ObjectActionModel {
  permissions: Record<string, SelectedPermissionModel>;
}

export interface SelectedPermissionModel extends PermissionBaseModel {
  permissionType: PermissionType;
}

const EditPermissionsModal = ({
  isVisible,
  closeCallback,
  onSubmit,
  initialFormValues,
  loadingInitialData,
  canSetForbiddenPermissions,
  onlyForbiddenPermissions,
}: IProps) => {
  const { t } = useTranslation('orac');

  return (
    <Form<FormValuesModel>
      // deep object clone :)
      enableReinitialize
      initialValues={JSON.parse(JSON.stringify(initialFormValues))}
      onSubmit={onSubmit}
      renderForm={
        <ModalDialog
          title={t('users.view.edit_permissions')}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <Spin spinning={loadingInitialData}>
            {!loadingInitialData && initialFormValues && (
              <InnerForm
                canSetForbiddenPermissions={canSetForbiddenPermissions}
                onlyForbiddenPermissions={onlyForbiddenPermissions}
              />
            )}
          </Spin>
        </ModalDialog>
      }
      confirmExitWithoutSaving
    />
  );
};

export default EditPermissionsModal;
