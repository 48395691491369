import React from 'react';

// components
import RolesTable from './RolesTable';

interface IProps {
  userId: string;
}

const RolesTab = ({ userId }: IProps) => {
  return <RolesTable userId={userId} />;
};

export default RolesTab;
