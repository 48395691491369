import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';

// components
import { Col, Row } from 'antd';
import { SearchInput } from 'components/Fields/SearchInput';

interface IProps {
  onFilterChange: (key: FilterBarKeys, value: string) => void;
}

export type FilterBarKeys = 'search_by_account_number_name';

const FilterBar = memo(({ onFilterChange }: IProps) => {
  const { t } = useTranslation('banking');

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Row>
          <Col span={24}>
            {t(
              'banking.bank_accounts.filter_bar.search_by_account_number_name',
            )}
          </Col>
          <Col span={24}>
            <SearchInput
              size="large"
              type="search_by_account_number_name"
              placeholder={t(
                'banking.bank_accounts.filter_bar.search_by_account_number_name_placeholder',
              )}
              onSearch={(searchQuery: string) =>
                onFilterChange('search_by_account_number_name', searchQuery)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

export default FilterBar;
