import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { ApprovalGroupModel } from 'typings/approval/templates';

// components
import SectionIntro from '@common_components/Texts/SectionIntro';
import InnerTemplate from './InnerTemplate';

export type ClientApprovalGroupData = {
  before: ApprovalGroupModel | null;
  after: ApprovalGroupModel;
};

interface IProps {
  data: ClientApprovalGroupData;
}

const CreateApprovalGroupDetails = memo(({ data }: IProps) => {
  const { t } = useTranslation('approval_management');

  const renderContent = () => {
    if (data.before === null) {
      return (
        <>
          <SectionIntro
            titleVariant="h5"
            title={t('approval_workflow.approval_workflow_changes.new_version')}
          />
          <InnerTemplate data={data.after} />
        </>
      );
    } else {
      return (
        <>
          <SectionIntro
            titleVariant="h5"
            title={t('approval_workflow.approval_workflow_changes.old_version')}
          />
          <InnerTemplate data={data.before as ApprovalGroupModel} />

          <SectionIntro
            titleVariant="h5"
            title={t('approval_workflow.approval_workflow_changes.new_version')}
          />
          <InnerTemplate data={data.after} />
        </>
      );
    }
  };

  return <>{renderContent()}</>;
});

export default CreateApprovalGroupDetails;
