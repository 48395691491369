import React, { useState } from 'react';

// helpers & Utils
import useFetch from '../../../../hooks/useFetch';
import { debounce } from 'lodash';
import { entityAPI } from '../../../../api/crm/entity/entityAPI';

// constants
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  DEFAULT_TABLE_LIMIT,
} from '../../../../constants/global';

// components
import FilterBar from './FilterBar';
import EntitiesTable from '../../../../components/Tables/TableTemplates/CRM/EntitiesTable';
import { Divider } from 'antd';

const EntityTable = () => {
  // table
  const [current, setCurrent] = useState(1);

  // filters
  const [searchValue, setSearchValue] = useState('');

  const { response, loading } = useFetch(
    () =>
      entityAPI.fetchEntities({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        search: searchValue,
      }),
    [current, searchValue],
  );

  const handleFilterChange = (key: string, value: any) => {
    if (current > 1) {
      setCurrent(1);
    }

    switch (key) {
      case 'search':
        setSearchValue(value);
        break;
    }
  };

  return (
    <>
      <FilterBar
        onFilterChange={debounce(
          handleFilterChange,
          AUTOCOMPLETE_DEBOUNCE_DELAY,
        )}
      />
      <Divider />
      <EntitiesTable
        total={response?.total || 0}
        current={current}
        data={response?.data || []}
        loading={loading}
        onPaginationChange={setCurrent}
      />
    </>
  );
};

export default EntityTable;
