import React, { useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../../../hooks/useFetch';
import groupsAPI from '../../../../../../api/orac/groupsAPI';
import useTranslation from '../../../../../../hooks/useTranslation';
import { TableActionModel } from '@core_components/Table';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../../../constants/global';

// components
import FilterBar from './FilterBar';
import ViewRoleDetailsModal from '../../../../../../components/Modals/TemplateModalDialogs/ORAC/Roles/ViewRoleDetailsModal';
import { Divider, message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { default as TemplateRolesTable } from '../../../../../../components/Tables/TableTemplates/ORAC/RolesTable';

interface IProps {
  groupId: string;
  updateTableTrigger: any;
  canViewPermissions?: boolean;
  canRemoveRole?: boolean;
}

type TableActionKeys = 'view_permissions' | 'remove';
export type FilterBarKeys = 'search_by_name';

const RolesTable = ({
  groupId,
  updateTableTrigger,
  canRemoveRole = true,
  canViewPermissions = true,
}: IProps) => {
  const { t } = useTranslation(['orac', 'common']);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchByName, setSearchByName] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);
  const [localUpdateRolesTrigger, updateRoles] = useState({});

  const tableActions = useMemo(() => {
    const result: TableActionModel[] = [
      {
        key: 'view_permissions',
        name: t('groups.view.view_role_permissions'),
        hidden: !canViewPermissions,
      },
      {
        key: 'remove',
        name: t('remove', { ns: 'common' }),
        hidden: !canRemoveRole,
        danger: true,
      },
    ];

    return result;
  }, [canRemoveRole, canViewPermissions]);

  const { response, loading } = useFetch(
    () =>
      groupsAPI.fetchRolesByGroupId(groupId, {
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        name: searchByName,
      }),
    [currentPage, searchByName, updateTableTrigger, localUpdateRolesTrigger],
  );

  const handleFilterBarChange = (key: FilterBarKeys, value: string) => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }

    if (key === 'search_by_name') {
      setSearchByName(value || '');
    }
  };

  const handleActionsClick = (key: TableActionKeys, roleId: string) => {
    switch (key) {
      case 'remove':
        showRoleRemoveConfirmation(roleId);
        break;

      case 'view_permissions':
        setSelectedRoleId(roleId);
        break;
    }
  };

  const handleModalClose = () => {
    setSelectedRoleId(null);
  };

  const showRoleRemoveConfirmation = (roleId: string) => {
    Modal.confirm({
      title: t('groups.view.remove_role_confirmation'),
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: () => removeRoleFromGroup(roleId),
    });
  };

  const removeRoleFromGroup = async (roleId: string) => {
    await groupsAPI.removeRolesFromGroup(groupId, [roleId]);
    message.success(t('groups.view.remove_role_success'));
    updateRoles({});
  };

  return (
    <>
      <FilterBar onFilterChange={handleFilterBarChange} />

      <Divider />

      <TemplateRolesTable
        data={response?.data || []}
        total={response?.total || 0}
        current={currentPage}
        loading={loading}
        onPaginationChange={setCurrentPage}
        actions={tableActions}
        onActionsClick={(key, roleId) =>
          handleActionsClick(key as TableActionKeys, roleId)
        }
      />

      <ViewRoleDetailsModal
        roleId={selectedRoleId as string}
        isVisible={!!selectedRoleId}
        closeCallback={handleModalClose}
      />
    </>
  );
};

export default RolesTable;
