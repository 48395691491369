import { documentsAPI } from '../../api/documents/documentsAPI';
import { GetDataStatusEnum } from '../../enums/common';

// Actions
export enum ActionType {
  SET_DOCUMENTS = 'documents/SET_DOCUMENTS',
  SET_STATUS = 'documents/SET_STATUS',
  DESTROY = 'documents/DESTROY',
}

// Action creators
export const getDocuments = (limit: number) => async (dispatch: any) => {
  dispatch({
    type: ActionType.SET_STATUS,
    payload: GetDataStatusEnum.PENDING,
    key: 'documents',
  });

  const documents = await documentsAPI.fetchDocuments({ page: 1, limit });

  dispatch({
    type: ActionType.SET_DOCUMENTS,
    payload: documents.data,
    key: 'documents',
  });

  dispatch({
    type: ActionType.SET_STATUS,
    payload: GetDataStatusEnum.SUCCESS,
    key: 'documents',
  });
};

export const destroy = () => (dispatch: any) => {
  dispatch({
    type: ActionType.DESTROY,
  });
};
