import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from 'routes/routes';
import { DateHelpers } from '@helpers/date';
import { OnboardType } from 'enums/crm/crm';

// components
import Link from '@common_components/Texts/Link';
import Button from '@core_components/Button';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

export type ActionTypes = 'view';

interface IProps extends RequiredPropsForTableModel {
  onActionsClick?: (
    action: ActionTypes,
    record: EntryChangesLogReportItem,
  ) => void;
}

export type EntryChangesLogReportItem = {
  id: string;

  changedEntry: {
    id: string;
    type: OnboardType;
    name: string;
  };

  user: {
    id: string;
    name: string;
  };

  changedDate: string;
};

const EntryChangesLogTable = ({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation(['reporting', 'crm', 'common']);
  const history = useHistory();

  const renderEntry = (record: EntryChangesLogReportItem) => {
    let pushParams = { pathname: '', search: '' };

    switch (record.changedEntry.type) {
      case OnboardType.Contact:
        pushParams = {
          pathname: RoutePaths.CRM_Contacts_Edit,
          search: `?id=${record.changedEntry.id}`,
        };
        break;

      case OnboardType.Organization:
        pushParams = {
          pathname: RoutePaths.CRM_Entities_Edit,
          search: `?id=${record.changedEntry.id}`,
        };
        break;
    }

    return (
      <Link onClick={() => history.push(pushParams)}>
        <EllipsisTooltip
          title={record.changedEntry.name}
          maxTextContainerWidth="280px"
        >
          {record.changedEntry.name}
        </EllipsisTooltip>
      </Link>
    );
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: '100px',
        key: 'type',
        title: t('details_changes.type'),
        render: (record: EntryChangesLogReportItem) =>
          t(`association_types.${record.changedEntry.type}`, { ns: 'crm' }),
      },

      {
        width: '280px',
        key: 'name',
        title: t('details_changes.name'),
        render: (record: EntryChangesLogReportItem) => renderEntry(record),
      },

      {
        width: '165px',
        key: 'date',
        title: t('details_changes.date'),
        render: (record: EntryChangesLogReportItem) =>
          DateHelpers.formatDateToString(record.changedDate),
      },

      {
        width: '280px',
        key: 'user',
        title: t('details_changes.user'),
        render: (record: EntryChangesLogReportItem) => (
          <Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.ORAC_User_Details,
                search: `?id=${record.user.id}`,
              })
            }
          >
            <EllipsisTooltip
              title={record.user.name}
              maxTextContainerWidth="280px"
            >
              {record.user.name}
            </EllipsisTooltip>
          </Link>
        ),
      },

      {
        hidden: !onActionsClick,
        align: 'right',
        key: 'actions',
        render: (record: EntryChangesLogReportItem) => (
          <Button
            type="bordered"
            onClick={() => onActionsClick && onActionsClick('view', record)}
          >
            {t('details', { ns: 'common' })}
          </Button>
        ),
      },
    ];

    return result;
  }, [t, onActionsClick]);

  return <Table columns={columns} {...rest} />;
};

export default EntryChangesLogTable;
