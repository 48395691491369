import * as yup from 'yup';
import { WEBSITE_REG } from '../../../constants/regularExpressions';
import { CRM_CONTACT_NAME_MAX_LENGTH } from 'constants/global';
import {
  NULLABLE_ARRAY_VALIDATION_RULE,
  NULLABLE_BOOLEAN_VALIDATION_RULE,
  NULLABLE_DATE_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
} from 'validations/common';

export const AddressValidationSchema = yup.object().shape({
  id: yup.string(),
  type: yup.array().of(yup.string()),
  street: STRING_VALIDATION_RULE.max(50, {
    key: 'form:validation_messages.max_characters_with_label',
    values: { label: 'entity.new.street', number: 50 },
  }),
  city: STRING_VALIDATION_RULE.max(30, {
    key: 'form:validation_messages.max_characters_with_label',
    values: { label: 'entity.new.city', number: 30 },
  }),
  country: STRING_VALIDATION_RULE.max(50, {
    key: 'form:validation_messages.max_characters_with_label',
    values: { label: 'entity.new.country', number: 50 },
  }),
  state: STRING_VALIDATION_RULE.max(50, {
    key: 'form:validation_messages.max_characters_with_label',
    values: { label: 'entity.new.state', number: 50 },
  }),
  postalCode: STRING_VALIDATION_RULE.max(15, {
    key: 'form:validation_messages.max_characters_with_label',
    values: { label: 'entity.new.zipCode', number: 15 },
  }),
});

export const ContactDetailsFormValidationSchema = yup.object().shape({
  firstName: STRING_VALIDATION_RULE.max(CRM_CONTACT_NAME_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label: 'contacts.new.firstName',
      number: CRM_CONTACT_NAME_MAX_LENGTH,
    },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'contacts.new.firstName' },
  }),
  middleName: STRING_VALIDATION_RULE.max(CRM_CONTACT_NAME_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label: 'contacts.new.middleName',
      number: CRM_CONTACT_NAME_MAX_LENGTH,
    },
  }),
  lastName: STRING_VALIDATION_RULE.max(CRM_CONTACT_NAME_MAX_LENGTH, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label: 'contacts.new.lastName',
      number: CRM_CONTACT_NAME_MAX_LENGTH,
    },
  }),
  gender: NULLABLE_STRING_VALIDATION_RULE,
  dateOfBirth: NULLABLE_DATE_VALIDATION_RULE,
  countryOfBirth: NULLABLE_STRING_VALIDATION_RULE,
  nationality: NULLABLE_ARRAY_VALIDATION_RULE.of(yup.string()),
  isInvitationPermitted: NULLABLE_BOOLEAN_VALIDATION_RULE,
  isVendor: NULLABLE_BOOLEAN_VALIDATION_RULE,
  sourceOfWealthCategories: NULLABLE_ARRAY_VALIDATION_RULE,
  sourceOfWealthDescription: STRING_VALIDATION_RULE.max(500, {
    key: 'form:validation_messages.max_characters_with_label',
    values: { label: 'contacts.new.sourceOfWealth', number: 500 },
  }),

  socialMedia: yup.object().shape({
    facebookLink: yup
      .string()
      .trim()
      .matches(WEBSITE_REG, 'Wrong facebook link'),
    linkedInLink: yup
      .string()
      .trim()
      .matches(WEBSITE_REG, 'Wrong linked in link'),
    twitterLink: yup
      .string()
      .trim()
      .matches(WEBSITE_REG, 'Wrong twitter  link'),
  }),

  emails: yup.array().of(
    yup.object().shape({
      id: NULLABLE_STRING_VALIDATION_RULE,
      type: NULLABLE_STRING_VALIDATION_RULE.required({
        key: 'form:validation_messages.required_with_label',
        values: { label: 'email_form:type' },
      }),
      address: STRING_VALIDATION_RULE.email(
        'Please enter valid email address',
      ).required({
        key: 'form:validation_messages.required_with_label',
        values: { label: 'email_form:address' },
      }),
    }),
  ),

  phoneNumbers: yup.array().of(
    yup.object().shape({
      id: NULLABLE_STRING_VALIDATION_RULE,
      type: NULLABLE_STRING_VALIDATION_RULE.required({
        key: 'form:validation_messages.required_with_label',
        values: { label: 'phone_form:type' },
      }),
      number: STRING_VALIDATION_RULE.required({
        key: 'form:validation_messages.required_with_label',
        values: { label: 'phone_form:phone_number' },
      }),
    }),
  ),
});

export const ContactIdentificationValidationSchema = yup.object().shape({
  isPEP: NULLABLE_BOOLEAN_VALIDATION_RULE,
  pepInformation: STRING_VALIDATION_RULE.max(1000, {
    key: 'form:validation_messages.max_characters_with_label',
    values: { label: 'contacts.new.pepInformation', number: 1000 },
  }),
  isRegulated: NULLABLE_BOOLEAN_VALIDATION_RULE,
  regulationCountry: NULLABLE_ARRAY_VALIDATION_RULE.of(yup.string()),
  addresses: yup.array().of(AddressValidationSchema),
});

export const ContactDetailsWithIdentificationValidationSchema =
  ContactDetailsFormValidationSchema.concat(
    ContactIdentificationValidationSchema,
  );
