import React from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import useTranslation from '../../../hooks/useTranslation';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { ForexTransferFormValues } from '../../../components/Forms/TemplateForms/TreasureManagement/ForexTransferForm';
import {
  pendingForexTransfersAPI,
  UpdatePendingTransferBodyModel,
} from '../../../api/banking/pendingForexTransfersAPI';

// components
import ForexTransferModal from '../../../components/Modals/TemplateModalDialogs/Banking/ForexTransferModal';

interface IProps {
  isVisible: boolean;
  closeCallback: (wasUpdated?: boolean) => void;
  selectedTransferId: string;
}

const ForexTransferDetailsModal = ({
  isVisible,
  closeCallback,
  selectedTransferId,
}: IProps) => {
  const { t } = useTranslation('banking');

  const { response, loading } = useFetch(
    () =>
      selectedTransferId
        ? pendingForexTransfersAPI.getPendingTransferById(selectedTransferId)
        : null,
    [selectedTransferId],
  );

  const handleSubmit = async (values: ForexTransferFormValues) => {
    const requestBody: UpdatePendingTransferBodyModel = {
      spread: AccountsHelpers.convertAmountFromIntToBigInt(values.spread) || 0,
      fromCurrencyRate:
        AccountsHelpers.convertAmountFromIntToBigInt(values.fromCurrencyRate) ||
        0,
      toCurrencyRate:
        AccountsHelpers.convertAmountFromIntToBigInt(values.toCurrencyRate) ||
        0,
    };

    await pendingForexTransfersAPI.updatePendingTransferById(
      selectedTransferId as string,
      requestBody,
    );

    closeCallback(true);
  };

  return (
    <ForexTransferModal
      title={t('forex.transfer_details.modal_title')}
      transfer={response}
      isVisible={isVisible}
      closeCallback={closeCallback}
      loadingInitialData={loading}
      onSubmit={handleSubmit}
    />
  );
};

export default ForexTransferDetailsModal;
