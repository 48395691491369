import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import { StyledComponentProps } from '../../../../../../../../../typings/common';
import { ReconciliationStatuses } from '../../../../../../../../../enums/finance/finance';

// components
import Select, {
  SelectOption,
} from '../../../../../../../../DesignSystem/Core/Select';

interface IProps extends StyledComponentProps {
  value: ReconciliationStatuses | undefined;
  disabled: boolean;
  onSelect: (newValue: ReconciliationStatuses | undefined) => void;
}

const ReconciliationStatusSelect = memo(
  ({ value, onSelect, ...rest }: IProps) => {
    const { t } = useTranslation('finance');

    const options = useMemo<SelectOption[]>(() => {
      return Object.keys(ReconciliationStatuses).map((key) => ({
        id: key,
        label: t(`accounts.account_details.reconciliation_statuses.${key}`),
        disabled: value === key,
      }));
    }, [t, value]);

    return (
      <Select
        options={options}
        value={value}
        onSelect={onSelect as any}
        {...rest}
      />
    );
  },
);

export default ReconciliationStatusSelect;
