import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import {
  NewPermissionModel,
  PermissionBaseModel,
} from '../../typings/ORAC/permissions';

const permissionsAPI = {
  createPermission: (newPermission: NewPermissionModel) => {
    return APIService.post<PermissionBaseModel>(
      `${APIConfig.oracApi}/permissions`,
      newPermission,
    ).then(({ data }) => data);
  },
};

export default permissionsAPI;
