import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import FormField from '@core_components/FormField';
import InputAmount from '@core_components/InputAmount';
import { Input, Typography } from 'antd';

interface IProps {
  onResetClick?: () => void;
}

const InnerForm = ({ onResetClick }: IProps) => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <FormField
        label={t('client_group.fee_schedule.currency')}
        name="currency"
        component={Input}
        disabled
      />
      <FormField
        label={t('client_group.fee_schedule.amount')}
        name="amount"
        component={StyledAmountField}
      />
      {!values.isDefault && (
        <Typography.Link onClick={onResetClick}>
          {t('client_group.fee_schedule.reset_to_default')}
        </Typography.Link>
      )}
    </>
  );
};

const StyledAmountField = styled(InputAmount)`
  width: 100%;
`;

export default InnerForm;
