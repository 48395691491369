import React, { useState, useEffect } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { documentsAPI } from '../../../../api/documents/documentsAPI';
import { IFullDocument } from '../../../../typings/documents/documents';

// components
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import DocumentDetails from './DocumentDetails';
import { Spin } from 'antd';

interface IProps extends RequiredPropsForModalDialogModel {
  documentId: string;
}

const DocumentDetailsModal = ({
  documentId,
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('crm');

  const [document, setDocument] = useState<IFullDocument | null>(null);

  const { response: getDocumentResponse, loading: getDocumentLoader } =
    useFetch(
      () => (documentId ? documentsAPI.getDocumentById(documentId) : null),
      [documentId],
    );

  useEffect(() => {
    setDocument(getDocumentResponse || null);
  }, [getDocumentResponse]);

  return (
    <ModalDialog
      title={t('documents.details.title')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      hideFooterButtons
      width="65%"
    >
      <StyledSpin spinning={getDocumentLoader}>
        {document && (
          <DocumentDetails document={document} closeCallback={closeCallback} />
        )}
      </StyledSpin>
    </ModalDialog>
  );
};

const StyledSpin = styled(Spin)<any>`
  display: flex;
  justify-content: center;
`;

export default DocumentDetailsModal;
