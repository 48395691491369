export enum TransferTemplateTypes {
  Internal = 1,
  Wire,
  Blockchain,
}

export enum TransferTemplatePermissions {
  Public = 'Public',
  Private = 'Private',
}
