import * as yup from 'yup';

export const UpdateSpreadMatrixOfFXValidationSchema = yup.object().shape({
  requireApproval: yup.boolean(),
  spread: yup.number().required('This is required field.'),
});

export const UpdateSpreadMatrixForClientGroupValidationSchema = yup
  .object()
  .shape({
    spread: yup.number().required('This is required field.'),
  });
