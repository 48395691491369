import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import usersAPI from '../../../../../../../api/orac/usersAPI';
import useTranslation from '../../../../../../../hooks/useTranslation';

// components
import Default2FALabel from '../Default2FALabel';
import { Button, Col, message, Modal, Row, Typography } from 'antd';
import { ReactComponent as KeyIcon } from '../../../../../../../resources/images/icons/key.svg';
import SecurityKeysModal from './SecurityKeysModal';

interface IProps {
  userId: string;
  isDefault: boolean;
  isFidoEnabled: boolean;
  isTwoFactorAuthenticationEnabled: boolean;
  onChangeDefault: () => void;
  onDisable: () => void;
}

const SecurityKey = ({
  userId,
  isDefault,
  isFidoEnabled,
  isTwoFactorAuthenticationEnabled,
  onChangeDefault,
  onDisable,
}: IProps) => {
  const { t } = useTranslation(['orac', 'common']);

  const [isOpenSecurityKeysModal, setIsOpenSecurityKeysModal] = useState(false);

  const onDisableFidoAuthentication = () => {
    Modal.confirm({
      title: t(
        'users.view.two_step_verification.security_key.disable_key.content',
      ),
      content: t(
        'users.view.two_step_verification.security_key.disable_key.description',
      ),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        await usersAPI.disableFidoAuthentication(userId);
        message.success(
          t(
            'users.view.two_step_verification.security_key.disable_key.success_submit',
          ),
        );
        onDisable();
        if (isTwoFactorAuthenticationEnabled) onChangeDefault();
      },
    });
  };

  return (
    <Row gutter={[16, 0]}>
      <Col xl={18} lg={18} md={12} sm={24} xs={24}>
        <TitleWrapper>
          <StyledKeyIcon />
          <TextWrapper>
            <StyledTitle strong>
              {t('users.view.two_step_verification.security_key.title')}{' '}
              {isDefault && <Default2FALabel />}
            </StyledTitle>
            <Typography.Text>
              {t('users.view.two_step_verification.security_key.description')}
            </Typography.Text>
          </TextWrapper>
        </TitleWrapper>
      </Col>
      <StyledCol xl={6} lg={6} md={12} sm={24} xs={24}>
        {!isFidoEnabled ? (
          <StyledTypographyText>
            {t('users.view.two_step_verification.security_key.not_enabled')}
          </StyledTypographyText>
        ) : (
          <>
            <StyledButton
              size="large"
              type="default"
              onClick={() => setIsOpenSecurityKeysModal(true)}
            >
              {t(
                'users.view.two_step_verification.security_key.manage_security_keys.title',
              )}
            </StyledButton>
            <StyledButton
              danger
              size="large"
              type="default"
              onClick={onDisableFidoAuthentication}
            >
              {t(
                'users.view.two_step_verification.security_key.disable_key.disable_button',
              )}
            </StyledButton>
          </>
        )}
      </StyledCol>
      <SecurityKeysModal
        userId={userId}
        onChangeDefault={() => onChangeDefault()}
        isVisible={isOpenSecurityKeysModal}
        onDisable={onDisable}
        closeCallback={() => setIsOpenSecurityKeysModal(false)}
      />
    </Row>
  );
};

const StyledKeyIcon = styled(KeyIcon)`
  width: 45px;
  height: 45px;
  margin-right: ${({ theme }) => theme.marginSm};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
  color: ${({ theme }) => theme.whiteColor};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledTypographyText = styled(Typography.Text)`
  color: ${({ theme }) => theme.warningColor};
  font-size: ${({ theme }) => theme.fontSizeSm};
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${StyledButton}:first-child {
    margin-bottom: ${({ theme }) => theme.marginXs};
  }
`;

export default SecurityKey;
