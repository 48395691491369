import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { DateHelpers } from '@helpers/date';
import { ContactHelpers } from '@helpers/crm/contact';
import { InvitationModel } from '../../../../../api/crm/invitations/invitationsAPI';
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../../constants/global';

// components
import Table, {
  RequiredPropsForTableModel,
  SortProps,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<InvitationModel> {
  sortCallback: (sort: SortProps) => void;
  isContactInvitations?: boolean;
}

const InvitationsTable = memo(
  ({ sortCallback, isContactInvitations, ...rest }: IProps) => {
    const { t } = useTranslation('crm');

    const history = useHistory();

    const redirectToContactDetails = (contactId: string) => {
      history.push({
        pathname: RoutePaths.CRM_Contacts_Edit,
        search: `?id=${contactId}`,
      });
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          key: 'dateSent',
          title: t('invitations.table.date_sent'),
          width: '20%',
          render: (record: InvitationModel) =>
            record._audit.createdAt
              ? DateHelpers.formatDateToString(
                  record._audit.createdAt,
                  DEFAULT_DATE_TIME_FORMAT,
                )
              : null,
          sorter: true,
          showSorterTooltip: false,
        },
        {
          key: 'contact',
          title: t('invitations.table.invited_by'),
          width: '20%',
          hidden: isContactInvitations,
          render: (record: InvitationModel) =>
            record.metadata.invitedBy.map((user) => (
              <div key={user._id}>
                <Typography.Link
                  onClick={() => redirectToContactDetails(user._id)}
                >
                  {ContactHelpers.getContactName(user)}
                </Typography.Link>
              </div>
            )),
        },
        {
          key: 'code',
          title: t('invitations.table.code'),
          width: '20%',
          hidden: !isContactInvitations,
          dataIndex: 'code',
        },
        {
          key: 'dateAccepted',
          title: t('invitations.table.date_accepted'),
          width: '30%',
          render: (record: InvitationModel) =>
            record.acceptance?.acceptedOn
              ? DateHelpers.formatDateToString(
                  record.acceptance.acceptedOn,
                  DEFAULT_DATE_TIME_FORMAT,
                )
              : null,

          sorter: true,
          showSorterTooltip: false,
        },
        {
          key: 'acceptedBy',
          title: t('invitations.table.accepted_by'),
          width: '30%',
          render: (record: InvitationModel) => (
            <Typography.Link
              key={record.metadata.acceptedBy?._id}
              onClick={() =>
                redirectToContactDetails(record.metadata.acceptedBy?._id)
              }
            >
              {ContactHelpers.getContactName(record.metadata.acceptedBy)}
            </Typography.Link>
          ),
        },
      ];

      return result;
    }, []);

    return (
      <Table
        columns={columns}
        onSortChange={(sorter: SortProps) => sortCallback(sorter)}
        {...rest}
      />
    );
  },
);

export default InvitationsTable;
