import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import useFetch from '../../../../../../../../hooks/useFetch';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../../../../routes/routes';
import { relationshipAPI } from '../../../../../../../../api/crm/relationship/relationshipAPI';

// components
import GraphChart from '../../../../../../../../components/Charts/GraphChart';
import LoadingWrapper from '../../../../../../../../components/WrapperComponents/LoadingWrapper';
import DescriptionSection, {
  DescriptionItem,
} from '../../../../../../../../components/DesignSystem/Core/DescriptionSection';
import { Typography } from 'antd';

export type ComplianceEntityData = {
  clientGroupId: string;
  clientGroupName: string;
  clientGroupNumericId: string;
  directClientId: string;
  directClientName: string;
  directClientType: string;
  activeRelationshipScopeId: string;
  approvedRelationshipScopeId: string;
};

interface IProps {
  data: ComplianceEntityData;
}

const ComplianceEntityDetails = memo(({ data }: IProps) => {
  const history = useHistory();
  const { t } = useTranslation('approval_management');

  const { response, loading } = useFetch(
    () =>
      relationshipAPI.getRelationshipTree(
        data.directClientId,
        data.activeRelationshipScopeId,
      ),
    [data],
  );

  const descriptionData = useMemo<DescriptionItem[]>(() => {
    return [
      {
        label: t(
          'approval_workflow.approval_workflow_changes.compliance_entity.client_group_id',
        ),
        description: (
          <Typography.Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.CRM_Client_Group_Details,
                search: `?id=${data.clientGroupNumericId}`,
              })
            }
          >
            {data.clientGroupNumericId}
          </Typography.Link>
        ),
      },
      {
        label: t(
          'approval_workflow.approval_workflow_changes.compliance_entity.client_group_name',
        ),
        description: data.clientGroupName,
      },
      {
        label: t(
          'approval_workflow.approval_workflow_changes.compliance_entity.direct_client',
        ),
        description: data.directClientName,
      },
      {
        label: t(
          'approval_workflow.approval_workflow_changes.compliance_entity.direct_client_types',
        ),
        description: t(
          `approval_workflow.approval_workflow_changes.compliance_entity.client_types.${data.directClientType}`,
        ),
      },
    ];
  }, [data, t]);

  return (
    <>
      <DescriptionSection data={descriptionData} />
      <LoadingWrapper loading={loading}>
        {response && <GraphChart data={response} />}
      </LoadingWrapper>
    </>
  );
});

export default ComplianceEntityDetails;
