import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { AccountRecoveryRequestModel } from '../../../../../typings/ORAC/accountRecoveryRequests';

// components
import { Col, Row, Typography } from 'antd';

interface IProps {
  recoveryRequestEntry: AccountRecoveryRequestModel;
}

const DetailsSection = ({ recoveryRequestEntry }: IProps) => {
  const { t } = useTranslation('orac');

  return (
    <>
      <Row gutter={[20, 10]}>
        <Col span={4}>
          <Typography.Text strong>
            {t('account_recovery_requests.type')}
          </Typography.Text>
        </Col>
        <Col span={20}>
          {recoveryRequestEntry.types
            .map((type) => t(`account_recovery_requests.types.${type}`))
            .join(', ')}
        </Col>
      </Row>

      <Row gutter={[20, 10]}>
        <Col span={4}>
          <Typography.Text strong>
            {t('account_recovery_requests.name')}
          </Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text copyable>
            {recoveryRequestEntry.user?.name}
          </Typography.Text>
        </Col>
      </Row>

      <Row gutter={[20, 10]}>
        <Col span={4}>
          <Typography.Text strong>
            {t('account_recovery_requests.username')}
          </Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text copyable>
            {recoveryRequestEntry.user?.username}
          </Typography.Text>
        </Col>
      </Row>

      <Row gutter={[20, 10]}>
        <Col span={4}>
          <Typography.Text strong>
            {t('account_recovery_requests.email')}
          </Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text copyable>
            {recoveryRequestEntry.user?.email}
          </Typography.Text>
        </Col>
      </Row>

      <Row gutter={[20, 10]}>
        <Col span={4}>
          <Typography.Text strong>
            {t('account_recovery_requests.phone')}
          </Typography.Text>
        </Col>
        <Col span={20}>
          <Typography.Text copyable>
            {recoveryRequestEntry.phone}
          </Typography.Text>
        </Col>
      </Row>
    </>
  );
};

export default DetailsSection;
