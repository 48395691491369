import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { contactsAPI } from 'api/crm/contact/contactsAPI';
import { ContactHelpers } from '@helpers/crm/contact';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import Autocomplete, {
  AutocompleteOption,
  AutocompleteProps,
} from '@core_components/Autocomplete';

type IProps = AutocompleteProps;

const ContactAutocomlete = (props: IProps) => {
  const { t } = useTranslation('form');

  const fetchContacts = async (search: string) => {
    const { data } = await contactsAPI.fetchContacts({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      search,
    });

    return data.map<AutocompleteOption>((contact) => {
      return {
        id: contact._id,
        label: ContactHelpers.getContactName(contact),
        model: contact,
      };
    });
  };

  return (
    <Autocomplete
      {...props}
      mode="multiple"
      placeholder={t('field_placeholders.multi_select_optional')}
      fetchData={fetchContacts}
    />
  );
};

export default ContactAutocomlete;
