import React from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { userAPI } from '../../../../api/auth/userAPI';
import { useHistory } from 'react-router-dom';
import { StateModel } from '../../../../redux/reducers';
import { AuthHelpers } from '@helpers/auth';
import { initializeApp } from '../../../../redux/actions/app';
import { LocalStorageHelpers } from '@helpers/storages/localStorage';
import { AssertionForLoginModel } from '../../../../typings/profile/fido';
import { useDispatch, useSelector } from 'react-redux';
import { StateModel as AuthStateModel } from '../../../../redux/reducers/auth';
import { setTwoFactorVerificationData } from '../../../../redux/actions/auth';

// constants
import { RoutePaths } from '../../../../routes/routes';

// components
import Content from '../../../../components/Modals/TemplateModalDialogs/Profile/SignChallengeWithSecurityKeyModal/SignChallengeStep/Content';
import BrowserIsNotSupported from '../../../../components/Additional/Auth/TwoFactorVerification/BrowserIsNotSupported';
import { Typography } from 'antd';

const FidoAuthentication = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation('auth');
  const canUseWebAuthn = AuthHelpers.isWebAuthnSupported();

  const { twoFactorVerificationData } = useSelector<StateModel, AuthStateModel>(
    (state) => state.auth,
  );

  const { response: challenge, loading } = useFetch(
    () =>
      twoFactorVerificationData?.temporaryToken && canUseWebAuthn
        ? userAPI.getChallengeFor2FA(twoFactorVerificationData?.temporaryToken)
        : null,
    [twoFactorVerificationData, canUseWebAuthn],
  );

  const navigateToSignin = () => {
    dispatch(setTwoFactorVerificationData(null));
    history.push(RoutePaths.Auth_SignIn);
  };

  const handleSubmitAssertion = async (assertion: AssertionForLoginModel) => {
    const response = await userAPI.loginWithChallenge(
      assertion,
      twoFactorVerificationData?.temporaryToken as string,
    );

    if (response && response.jwt) {
      LocalStorageHelpers.setAuthenticationTokens(
        response.jwt,
        response.refreshToken,
      );
      dispatch(initializeApp());
      history.push(RoutePaths.UserRoutes_Root);
    }
  };

  return (
    <Wrapper>
      <StyledTitle>{t('two_factor_authentication.title')}</StyledTitle>

      {canUseWebAuthn ? (
        <Content
          loadingChallenge={loading}
          challenge={challenge}
          submitAssertion={handleSubmitAssertion}
        />
      ) : (
        <BrowserIsNotSupported />
      )}

      <LinkWrapper>
        <Typography.Link onClick={navigateToSignin}>
          {t('two_factor_authentication.go_back_to_signin')}
        </Typography.Link>
      </LinkWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled(Typography.Paragraph)`
  text-align: center;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizeMd};
  color: ${({ theme }) => theme.colorWhite};
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.marginXs};
`;

export default FidoAuthentication;
