import React, { useEffect, useMemo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { FormValuesModel } from 'components/Forms/TemplateForms/Approval/FinanceApprovalRulesForm';
import { approvalGroupsAPI } from 'api/approval/approvalManagement/approvalGroupsAPI';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { IApprovalRuleAccount } from 'components/Modals/TemplateModalDialogs/CRM/ApprovalEntryModalDialog/InnerContent/ChangeDifferenceSection/Templates/TransactionRule';
import { approvalGroupsAPIAdapter } from 'apiAdapters/approval/approvalGroupsAPIAdapter';
import {
  ConditionModel,
  LimitModel,
} from 'components/Forms/TemplateForms/Approval/FinanceApprovalRulesForm';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';
import {
  TransactionRuleFromQuery,
  clientTransactionRulesAPI,
} from 'api/approval/approvalManagement/clientTransactionRulesAPI';

// components
import TransactionRuleModal from 'components/Modals/TemplateModalDialogs/ApprovalManagement/TransactionRuleModal';

interface IProps extends RequiredPropsForModalDialogModel {
  clientGroupId: number;
  transactionRuleId: string | null;
}

const ViewTransactionRuleModal = ({
  isVisible,
  clientGroupId,
  transactionRuleId,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('crm');
  const [approvalRule, setApprovalRule] =
    useState<TransactionRuleFromQuery | null>(null);

  const { response: fetchGroupsResponse, loading: groupsLoader } = useFetch(
    () =>
      isVisible && clientGroupId
        ? approvalGroupsAPI.fetchClientTransactionApprovalGroups(
            clientGroupId,
            {
              page: 1,
              limit: 100,
              isActiveOnly: true,
            },
          )
        : null,
    [isVisible, clientGroupId],
  );

  const { response: fetchApprovalRuleResponse, loading: ruleLoader } = useFetch(
    () =>
      transactionRuleId && clientGroupId
        ? clientTransactionRulesAPI.fetchTransactionRuleById(
            clientGroupId,
            transactionRuleId,
          )
        : null,
    [clientGroupId, transactionRuleId],
  );

  useEffect(() => {
    setApprovalRule(fetchApprovalRuleResponse);
  }, [fetchApprovalRuleResponse]);

  const initialValues = useMemo<FormValuesModel | null>(() => {
    if (!isVisible) {
      return null;
    }

    function formatLimitsArray(
      template: TransactionRuleFromQuery,
    ): LimitModel[] {
      const { approvalRuleSets } = template;
      return approvalRuleSets[0].approvalThreasholds.map(
        ({ threasholdMax, threasholdMin }, i) => {
          const toInfinite = threasholdMax === -1;
          return {
            from: Number(threasholdMin),
            to: toInfinite ? 0 : Number(threasholdMax),
            isUsed: Boolean(
              approvalRuleSets.find((e) => e.approvalThreasholds[i].ticked),
            ),
            toInfinite,
          };
        },
      );
    }

    function formatConditionsArray(
      template: TransactionRuleFromQuery,
    ): ConditionModel[] {
      return template.approvalRuleSets.map((ruleSet) => ({
        conditions: ruleSet.approvalRuleItems.map(
          ({ approvalGroup, minimunApprovalThreashold }) => ({
            approvalGroup: approvalGroup.id,
            requiredApproversCount: minimunApprovalThreashold,
          }),
        ),
        limitStatuses: ruleSet.approvalThreasholds.map(({ ticked }) => ({
          isChecked: !!ticked,
        })),
      }));
    }

    const bankAccounts = approvalRule?.bankAccounts.length
      ? approvalRule.bankAccounts
      : [];
    let initialBankAccounts: AutocompleteOption<IApprovalRuleAccount>[] = [];

    if (approvalRule && approvalRule.bankAccounts.length) {
      initialBankAccounts = approvalRule.bankAccounts.map(
        ({ bankAccountName, bankAccountNumber, bankAccountCurrency }) => ({
          id: bankAccountNumber,
          label: AccountsHelpers.generateAccountFullName(
            bankAccountName,
            bankAccountNumber,
            bankAccountCurrency,
          ),
          model: {
            bankAccountNumber,
            bankAccountName,
            bankAccountCurrency,
          },
        }),
      );
    }

    const limits = approvalRule
      ? formatLimitsArray(approvalRule)
      : [{ from: 0, to: undefined, toInfinite: true, isUsed: false }];
    const conditions = approvalRule
      ? formatConditionsArray(approvalRule)
      : [
          {
            conditions: [{ approvalGroup: '', requiredApproversCount: 0 }],
            limitStatuses: [{ isChecked: false }],
          },
        ];

    return {
      isEditMode: true,
      limits,
      conditions,
      bankAccounts,
      initialBankAccounts,
      clientGroupId,
      filterClientGroupsInDropdown: false,
    };
  }, [approvalRule, isVisible, clientGroupId]);

  const onSubmit = async (values: FormValuesModel) => {
    const newRule = approvalGroupsAPIAdapter.generateNewTransactionRule(values);

    transactionRuleId
      ? await approvalGroupsAPI.updateTransactionApprovalRule(
          { id: transactionRuleId, ...newRule },
          clientGroupId,
          transactionRuleId as string,
        )
      : await approvalGroupsAPI.addTransactionApprovalRule(
          newRule,
          clientGroupId,
        );

    message.success(
      t(
        'client_group.approval.client_transaction_rules.edit_transaction_rule_modal.success_submit_message',
      ),
    );
  };

  const canRenderForm = !!(transactionRuleId
    ? approvalRule && fetchGroupsResponse
    : fetchGroupsResponse);

  const getModalTitle = () => {
    return transactionRuleId
      ? t(
          'client_group.approval.client_transaction_rules.edit_transaction_rule_modal.title',
        )
      : t(
          'client_group.approval.client_transaction_rules.add_transaction_rule_modal.title',
        );
  };

  return (
    <TransactionRuleModal
      title={getModalTitle()}
      isVisible={isVisible}
      closeCallback={closeCallback}
      onSubmit={onSubmit}
      initialValues={initialValues}
      loading={groupsLoader || ruleLoader}
      canRenderForm={canRenderForm}
      approvalGroups={fetchGroupsResponse?.data || []}
    />
  );
};

export default ViewTransactionRuleModal;
