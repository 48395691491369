import React from 'react';

// helpers
import styled from 'styled-components';

// components
import { Tooltip, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { StyledComponentProps } from '../../../typings/common';

type IProps = StyledComponentProps;

const NotIntegratedToAPIMark = (props: IProps) => {
  return (
    <StyledText {...props}>
      <Tooltip
        title="This Page / Functionality is not integrated to the API"
        placement="bottom"
      >
        <StyledNotificationIcon />
      </Tooltip>
      NA
    </StyledText>
  );
};

const StyledText = styled(Typography.Text)`
  color: ${({ theme }) => theme.warningColor};
`;

const StyledNotificationIcon = styled(ExclamationCircleOutlined)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default NotIntegratedToAPIMark;
