import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';

// components
import Text from '../../../../../../../../components/DesignSystem/Core/Text';
import PendingTransactionStatusSelect from '../../../../../../../../components/Forms/FormComponents/SelectInputs/PendingTransactionStatusSelect';
import PendingTransactionAccountSelect from '../../../../../../../../components/Forms/FormComponents/SelectInputs/PendingTransactionAccountSelect';
import { Col, Row } from 'antd';

export type ActionKeys = 'filter-by-accounts' | 'filter-by-status';

interface IProps {
  clientGroupNumericId: number;
  selectedAccount: string;
  onFilterChange: (key: ActionKeys, value: string) => void;
  selectedStatus: string;
}

const FilterBar = ({
  clientGroupNumericId,
  onFilterChange,
  selectedAccount,
  selectedStatus,
}: IProps) => {
  const { t } = useTranslation('banking');

  return (
    <StyledRow>
      <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
        <Col span={24}>
          <StyledText variant="body1">
            {' '}
            {t('transactions.filter_bar.account')}
          </StyledText>
        </Col>
        <Col span={24}>
          <PendingTransactionAccountSelect
            clientGroupNumericId={clientGroupNumericId}
            onFilterChange={onFilterChange}
            selectedAccount={selectedAccount}
          />
        </Col>
      </Col>
      <StyledCol {...FILTER_BAR_FIELDS_GRID_SIZES}>
        <Col span={24}>
          <StyledText variant="body1">
            {' '}
            {t('transactions.filter_bar.status')}
          </StyledText>
        </Col>
        <Col span={24}>
          <PendingTransactionStatusSelect
            onFilterChange={onFilterChange}
            selectedStatus={selectedStatus}
          />
        </Col>
      </StyledCol>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin-bottom: 20px;
`;

const StyledCol = styled(Col)`
  margin-left: ${({ theme }) => theme.marginSm};
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.whiteColor};
`;

export default FilterBar;
