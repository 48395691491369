import React, { memo, useRef } from 'react';

// helpers
import _ from 'lodash';
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { darkTheme } from '@resources/theme/styled';
import { AccountTypes } from '../../../../../enums/finance/finance';
import { FormikHelpers } from 'formik';
import { WireRangeKeys } from '../../../../Forms/FormComponents/SelectInputs/WireRangesFormSelect';
import { BankAccountStatuses } from 'enums/banking/accounts';
import { InitialDepositRangeKeys } from '../../../../Forms/FormComponents/SelectInputs/InitialDepositRangeFormSelect';
import { EstimatedAmountFundsRangeKeys } from '../../../../Forms/FormComponents/SelectInputs/EstimatedAmountFundsRangeFormSelect';
import { UpdateBankAccountValidationSchema } from 'validations/accounting/bankAccounts';

// components
import Text from '@core_components/Text';
import StatsTab from './Tabs/Stats';
import DetailsTab from './Tabs/Details';
import AsyncButton from '@common_components/Buttons/AsyncButton';
import LoadingWrapper from '../../../../WrapperComponents/LoadingWrapper';
import Tabs, { TabModel } from 'components/Tabs';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Col, Popover, Row } from 'antd';

export type FormValuesModel = {
  accountName: string;
  accountNumber: string;
  currency: string;
  accountCategory: string;
  type: AccountTypes;
  balance: number;
  balanceHome: number;
  isActive: string | undefined;
  isFrozen: string | undefined;
  purpose: string;
  initialDeposit: InitialDepositRangeKeys | null;
  initialDepositOrigin: string | null;
  jurisdictions: string[];
  incomingWiresNumber: WireRangeKeys | null;
  estimatedIncomingFunds: EstimatedAmountFundsRangeKeys | null;
  outgoingWiresNumber: WireRangeKeys | null;
  estimatedOutgoingFunds: EstimatedAmountFundsRangeKeys | null;
  statistics: {
    avgEodBalanceSinceCreation: string;
    avgEodBalanceCurrentCalendarMonth: string;
    avgEodBalanceLast3Months: string;
    avgEodBalanceLast6Months: string;
    avgEomTxCountSinceCreation: number;
    avgEomTxCountCurrentCalendarMonth: number;
    avgEomTxCountLast3Months: number;
    avgEomTxCountLast6Months: number;
  };
  status: BankAccountStatuses;
};

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  mode: 'view' | 'update';
  complianceReviewStatus?: {
    canSubmit: boolean;
    hasPendingWorkflow: boolean;
  };
  onSubmit: (
    values: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
    isUpdated?: boolean,
  ) => void | Promise<unknown>;
  submitForComplianceReviewCallback?: () => Promise<void>;
}

const BankAccountModalDialog = memo(
  ({
    mode,
    isVisible,
    closeCallback,
    initialValues,
    complianceReviewStatus,
    onSubmit,
    submitForComplianceReviewCallback,
  }: IProps) => {
    const { t } = useTranslation('banking');

    const tabs: TabModel[] = [
      {
        key: 'details',
        title: t(
          'banking.bank_accounts.bank_account_modal_dialog.tabs.details.title',
        ),
        content: <DetailsTab />,
      },
      {
        key: 'stats',
        title: t(
          'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.title',
        ),
        content: <StatsTab />,
      },
    ];

    const handleSubmit = async (
      values: FormValuesModel,
      formikHelpers: FormikHelpers<FormValuesModel>,
    ) => {
      const isDirty = !_.isEqual(values, initialValues);
      await onSubmit(values, formikHelpers, isDirty);
    };

    return (
      <Form<FormValuesModel>
        disabled={mode === 'view'}
        enableReinitialize
        confirmExitWithoutSaving
        onSubmit={handleSubmit}
        initialValues={isVisible ? initialValues : null}
        validationSchema={UpdateBankAccountValidationSchema}
        renderForm={
          <ModalDialog
            title={t('banking.bank_accounts.bank_account_modal_dialog.title')}
            isVisible={isVisible}
            closeCallback={closeCallback}
            hideFooterButtons={!initialValues || mode === 'view'}
          >
            <LoadingWrapper loading={!initialValues}>
              {initialValues && (
                <>
                  <StyledRow justify="space-between" align="middle">
                    {[
                      BankAccountStatuses.PendingApproval,
                      BankAccountStatuses.Processing,
                    ].includes(initialValues.status) && (
                      <Col>
                        <Text
                          color={darkTheme.warningColor}
                          gutterBottom={false}
                        >
                          {t(
                            'banking.bank_accounts.bank_account_modal_dialog.pending_approval_message',
                          )}
                        </Text>
                      </Col>
                    )}
                    {complianceReviewStatus?.canSubmit &&
                    submitForComplianceReviewCallback ? (
                      <Col>
                        {complianceReviewStatus.hasPendingWorkflow ? (
                          <Popover
                            content={t(
                              'banking.bank_accounts.bank_account_modal_dialog.bank_account_has_active_workflow_tooltip',
                            )}
                            placement="bottom"
                            overlayStyle={{ zIndex: 9999 }}
                          >
                            <div>
                              <AsyncButton
                                disabled={true}
                                onClick={submitForComplianceReviewCallback}
                              >
                                {t(
                                  'banking.bank_accounts.bank_account_modal_dialog.submit_for_compliance_review_btn',
                                )}
                              </AsyncButton>
                            </div>
                          </Popover>
                        ) : (
                          <AsyncButton
                            onClick={submitForComplianceReviewCallback}
                          >
                            {t(
                              'banking.bank_accounts.bank_account_modal_dialog.submit_for_compliance_review_btn',
                            )}
                          </AsyncButton>
                        )}
                      </Col>
                    ) : null}
                  </StyledRow>
                  <StyledTabs tabs={tabs} />
                </>
              )}
            </LoadingWrapper>
          </ModalDialog>
        }
      />
    );
  },
);

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab-active {
    background-color: ${({ theme }) => theme.textLightColor5};
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

export default BankAccountModalDialog;
