import React, { useEffect, useState } from 'react';

// helpers
import moment from 'moment';
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';

// components
import TrialBalanceReport from '../../modules/Reporting/TrialBalanceReport';
import ExportTrialBalanceInExcelButton from '../../components/Additional/ExportTrialBalanaceInExcelButton';

type IProps = PageLayoutHelperPropsModel;

const TrialBalance = ({ setHeaderOptions }: IProps) => {
  const [filterDate, setFilterDate] = useState(moment());

  useEffect(() => {
    setHeaderOptions({
      extra: (
        <ExportTrialBalanceInExcelButton selectedFilterDate={filterDate} />
      ),
    });
  }, [filterDate]);

  return (
    <TrialBalanceReport
      selectedFilterDate={filterDate}
      setSelectedDate={setFilterDate}
    />
  );
};

export default TrialBalance;
