import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { IApprovalMatrixRecord } from '../../../../../typings/finance/transaction';
import { ApprovalWorkflowInstanceModel } from '../../../../../typings/approval/approvalWorkflow';

// components
import ApprovalMatrix from '../../../ApprovalsMatrix';
import ApprovalWorkflowStatus from 'components/Additional/Statuses/ApprovalWorkflowStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps
  extends RequiredPropsForTableModel<ApprovalWorkflowInstanceModel> {
  onActionsClick: (key: string, value: any) => void;
}

const ApprovalWorflowTable = memo(({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation('approval_management');

  const renderApprovalState = (record: ApprovalWorkflowInstanceModel) => {
    const formattedApprovalState: IApprovalMatrixRecord[][] =
      record.approvalProgressStateSets.map((e) => e.progressStateItems);

    return <ApprovalMatrix transactionApprovals={formattedApprovalState} />;
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        key: 'number',
        ellipsis: true,
        title: t('approval_workflow.id'),
        render: (record: ApprovalWorkflowInstanceModel) => record.number,
      },
      {
        key: 'code',
        width: 300,
        ellipsis: true,
        title: t('approval_workflow.code'),
        render: (record: ApprovalWorkflowInstanceModel) => (
          <Typography.Link
            onClick={() => onActionsClick('details', record.id)}
          >{`${t(
            `approval_workflow.approval_templates.${record.templateCode}`,
          )} (${record.templateCode})`}</Typography.Link>
        ),
      },
      {
        key: 'event',
        width: 200,
        title: t('approval_workflow.event'),
        render: (record: ApprovalWorkflowInstanceModel) =>
          t(`approval_workflow.approval_entry_types.${record.entryType}`),
      },
      {
        key: 'status',
        width: 200,
        title: t('approval_workflow.status'),
        render: (record: ApprovalWorkflowInstanceModel) => (
          <ApprovalWorkflowStatus status={record.status} />
        ),
      },
      {
        title: t('approval_workflow.approval_state'),
        key: 'approval_state',
        render: renderApprovalState,
      },
    ];

    return result;
  }, [t, onActionsClick]);

  return <Table {...rest} bordered columns={columns} />;
});

export default ApprovalWorflowTable;
