import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import { darkTheme } from '@resources/theme/styled';
import { StyledComponentProps } from 'typings/common';

// components
import Text, { TextProps } from '@core_components/Text';

interface IProps extends TextProps, StyledComponentProps {
  href?: string;
}

const Link = memo(({ children, onClick, ...rest }: IProps) => {
  return (
    <StyledText
      color={darkTheme.primaryGreen}
      onClick={onClick}
      variant="link1"
      {...rest}
    >
      {children}
    </StyledText>
  );
});

const StyledText = styled(Text)`
  cursor: pointer;
`;

export default Link;
