import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { Moment } from 'moment';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { TransferTemplateTypes } from 'enums/finance/transferTemplates';
import { IJournalDocumentModel } from '../../../../../typings/finance/journal';
import { TransferTemplateStatuses } from 'enums/transfers/templates';
import { AutocompleteInitialValue } from '../../../../Fields/Autocomplete';
import { useField, useFormikContext } from 'formik';
import {
  BankOperationsCodes,
  DetailsOfChargesCodes,
} from '../../../../../enums/banking/transactions';

// components
import FormField from '@core_components/FormField';
import AmountField from '../../../FormComponents/AmountField';
import YesNoSelect from '../../../FormComponents/SelectInputs/YesNoSelect';
import DocumentLink from '../../../../Typography/DocumentLink';
import CountrySelect from '../../../FormComponents/SelectInputs/CountrySelect';
import CurrencySelect from '../../../FormComponents/SelectInputs/CurrencySelect';
import DatePickerComponent from '../../../FormComponents/DatePicker';
import AccountAutocomplete from '../../../FormComponents/Autocompletes/Finance/AccountAutocomplete';
import TransferTemplateStatus from 'components/Additional/Statuses/TransferTemplateStatus';
import DetailsOfChargesSelect from '../../../FormComponents/SelectInputs/DetailsOfChargesSelect';
import BankOperationsCodeSelect from '../../../FormComponents/SelectInputs/BankOperationsCodeSelect';
import { Checkbox, Col, Collapse, Input, Row, Typography } from 'antd';

export type BeneficiaryData = {
  accountNumber: string;
  accountName: string;
  country: string | null;
  city: string;
  district: string;
  postalCode: string;
  address1: string;
};

export type BankInfoData = {
  codeType: string;
  code: string;
  name: string;
  country: string | null;
  address: string;
  city: string;
  district: string;
  postalCode: string;
};

export type FormValuesModel = {
  tenetOS: {
    transactionNumber: string;
    purpose: string;
    requestedBy: { id: string; name: string } | null;
    template: {
      id: number;
      name: string;
      status: TransferTemplateStatuses;
      invalidityReason: string;
      clientGroupId: number;
    } | null;
    documents: IJournalDocumentModel[];
  };

  general: {
    senderReference: string;
    bankOperationsCode: BankOperationsCodes | null;
    valueDate: Moment | null;
    currency: number | null;
    currencyInitialOption?: AutocompleteInitialValue;
    settledAmount: number | null;
    detailsOfCharges: DetailsOfChargesCodes | null;
  };

  other: {
    incomingTransactionCurrency: number | null;
    incomingTransactionCurrencyInitialOption?: AutocompleteInitialValue;
    instructedAmount: number | null;
  };
  orderingCustomer: BeneficiaryData;
  sendingInstitution: BankInfoData;
  orderingInstitution: BankInfoData;
  senderCorrespondent: BankInfoData;
  receiverCorrespondent: BankInfoData;
  thirdReimbursement: BankInfoData;
  intermediaryInstitution: BankInfoData;
  accountWithInstitution: BankInfoData;
  beneficiaryCustomer: BeneficiaryData;
  remittanceInformation: {
    line1: string;
    line2: string;
    line3: string;
    line4: string;
  };
  senderToReceiver: {
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    line5: string;
    line6: string;
  };
};

interface IProps {
  formName: string;
}

const OutgoingTransactionForm = memo(({ formName }: IProps) => {
  const { t } = useTranslation(['banking', 'common']);
  const { setFieldValue } = useFormikContext<FormValuesModel>();
  const [field] = useField<FormValuesModel>(formName);
  const history = useHistory();
  const formFieldProps = useMemo(
    () => ({
      labelCol: { xl: 6, lg: 24, md: 24, sm: 24, xs: 24 },
      disabled: true,
    }),
    [],
  );

  const onRepairChange = (isChecked: boolean) => {
    if (isChecked) {
      setFieldValue(`${formName}.beneficiaryCustomer.fee`, true);
    }
  };

  const renderBankInfoFields = (fieldBaseName: string) => {
    return (
      <>
        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_code_type',
          )}
          name={`${fieldBaseName}.codeType`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_code',
          )}
          name={`${fieldBaseName}.code`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_name',
          )}
          name={`${fieldBaseName}.name`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_country',
          )}
          name={`${fieldBaseName}.country`}
          component={CountrySelect}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_address',
          )}
          name={`${fieldBaseName}.address`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_city',
          )}
          name={`${fieldBaseName}.city`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_district',
          )}
          name={`${fieldBaseName}.district`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_info_fields.bank_postal_code',
          )}
          name={`${fieldBaseName}.postalCode`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
      </>
    );
  };

  const renderBeneficiaryFields = (fieldBaseName: string) => {
    return (
      <>
        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.account_number',
          )}
          name={`${fieldBaseName}.accountNumber`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.account_name',
          )}
          name={`${fieldBaseName}.accountName`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.country',
          )}
          name={`${fieldBaseName}.country`}
          component={CountrySelect}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.address',
          )}
          name={`${fieldBaseName}.address1`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.city',
          )}
          name={`${fieldBaseName}.city`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.district',
          )}
          name={`${fieldBaseName}.district`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.customer_section.post_code',
          )}
          name={`${fieldBaseName}.postalCode`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
      </>
    );
  };

  const renderDocuments = (documents: IJournalDocumentModel[]) => {
    if (!documents || !documents.length) {
      return '';
    }

    return documents.map(({ dmsId, name, linkToDownload }) => (
      <div key={dmsId}>
        <DocumentLink text={name} fileId={linkToDownload} documentId={dmsId} />
      </div>
    ));
  };

  return (
    <Collapse
      defaultActiveKey={[
        'tenet_os',
        'general',
        'ordering_customer',
        'intermediary_institution',
        'account_with_institution',
        'beneficiary_customer',
        'remittance_information',
        'sender_to_receiver',
      ]}
    >
      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.tenet_os_section.title',
        )}
        key="tenet_os"
      >
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Typography.Text>
              {t(
                'banking.transactions.add_transaction.tenet_os_section.transaction_id',
              )}
            </Typography.Text>
          </Col>
          <Col span={20}>
            <Typography.Text>
              {field.value.tenetOS.transactionNumber}
            </Typography.Text>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Typography.Text>
              {t(
                'banking.transactions.add_transaction.tenet_os_section.purpose',
              )}
            </Typography.Text>
          </Col>
          <Col span={20}>
            <Typography.Text>{field.value.tenetOS.purpose}</Typography.Text>
          </Col>
        </Row>

        {field.value.tenetOS.requestedBy &&
          field.value.tenetOS.requestedBy.id && (
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <Typography.Text>
                  {t(
                    'banking.transactions.add_transaction.tenet_os_section.requested_by',
                  )}
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Typography.Text>
                  <Typography.Link
                    onClick={() =>
                      history.push({
                        pathname: RoutePaths.ORAC_User_Details,
                        search: `?id=${field.value.tenetOS?.requestedBy?.id}`,
                      })
                    }
                  >
                    {field.value.tenetOS.requestedBy.name ||
                      field.value.tenetOS.requestedBy.id}
                  </Typography.Link>
                </Typography.Text>
              </Col>
            </Row>
          )}

        {field.value.tenetOS.template && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={4}>
                <Typography.Text>
                  {t(
                    'banking.transactions.add_transaction.tenet_os_section.template',
                  )}
                </Typography.Text>
              </Col>
              <Col span={20}>
                <Typography.Text>
                  <Typography.Link
                    onClick={() =>
                      history.push({
                        pathname: RoutePaths.CRM_Client_Group_Details,
                        search: `?id=${String(field.value.tenetOS.template?.clientGroupId)}&tab=transferTemplates&templateId=${
                          field.value.tenetOS.template?.id
                        }&templateType=${TransferTemplateTypes.Wire}`,
                      })
                    }
                  >
                    {AccountsHelpers.getTransferTemplateName(
                      String(field.value.tenetOS.template.id),
                      field.value.tenetOS.template.name,
                    )}
                  </Typography.Link>
                </Typography.Text>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={4} />
              <Col span={20}>
                <Typography.Text>
                  {t(
                    'banking.transactions.add_transaction.tenet_os_section.status',
                  )}
                  :&nbsp;
                  <TransferTemplateStatus
                    status={field.value.tenetOS.template.status}
                  />
                </Typography.Text>
              </Col>
            </Row>

            {field.value.tenetOS.template.invalidityReason && (
              <Row gutter={[16, 16]}>
                <Col span={4} />
                <Col span={20}>
                  <Typography.Text>
                    {t(
                      'banking.transactions.add_transaction.tenet_os_section.reason',
                    )}
                    : {field.value.tenetOS.template.invalidityReason}
                  </Typography.Text>
                </Col>
              </Row>
            )}
          </>
        )}

        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Typography.Text>
              {t(
                'banking.transactions.add_transaction.tenet_os_section.documents',
              )}
            </Typography.Text>
          </Col>
          <Col span={20}>
            <Typography.Text>
              {renderDocuments(field.value.tenetOS.documents)}
            </Typography.Text>
          </Col>
        </Row>
      </Collapse.Panel>

      <Collapse.Panel
        header={t('banking.transactions.add_transaction.general_section_title')}
        key="general"
      >
        <FormField
          {...formFieldProps}
          disabled={false}
          label={t(
            'banking.transactions.add_transaction.form_fields.sender_reference',
          )}
          name={`${formName}.general.senderReference`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.bank_operations_code',
          )}
          name={`${formName}.general.bankOperationsCode`}
          component={BankOperationsCodeSelect}
          placeholder={t('select_option', { ns: 'common' })}
          additionalProps={{ allowClear: true }}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.value_date',
          )}
          name={`${formName}.general.valueDate`}
          component={StyledDatePicker}
          placeholder={t('select_date', { ns: 'common' })}
          additionalProps={{ allowClear: true }}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.incomingTransactionCurrency',
          )}
          name={`${formName}.general.currency`}
          component={CurrencySelect}
          placeholder={t('select_option', { ns: 'common' })}
          additionalProps={{
            allowClear: true,
            isActive: true,
            isExternal: 'false',
            initialValue: field.value.general.currencyInitialOption,
            loadDataOnMount: true,
            autoSelectIfOneOption: true,
          }}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.settledAmount',
          )}
          name={`${formName}.general.settledAmount`}
          component={StyledAmountField}
          placeholder={t('enter_value', { ns: 'common' })}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.detailsOfCharges',
          )}
          name={`${formName}.general.detailsOfCharges`}
          component={DetailsOfChargesSelect}
          additionalProps={{ allowClear: true }}
        />
      </Collapse.Panel>

      <Collapse.Panel
        header={t('banking.transactions.add_transaction.other_section_title')}
        key="other"
      >
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.currency')}
          name={`${formName}.other.incomingTransactionCurrency`}
          component={CurrencySelect}
          placeholder={t('select_option', { ns: 'common' })}
          additionalProps={{
            allowClear: true,
            isActive: true,
            isExternal: 'false',
            initialValue:
              field.value.other.incomingTransactionCurrencyInitialOption,
            loadDataOnMount: true,
            autoSelectIfOneOption: true,
          }}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.instructedAmount',
          )}
          name={`${formName}.other.instructedAmount`}
          component={StyledAmountField}
          placeholder={t('enter_value', { ns: 'common' })}
        />
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.ordering_customer_section_title',
        )}
        key="ordering_customer"
      >
        {renderBeneficiaryFields(`${formName}.orderingCustomer`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.sending_institution_section_title',
        )}
        key="sending_institution"
      >
        {renderBankInfoFields(`${formName}.sendingInstitution`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.ordering_institution_section_title',
        )}
        key="ordering_institution"
      >
        {renderBankInfoFields(`${formName}.orderingInstitution`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.sender_correspondent_section_title',
        )}
        key="sender_correspondent"
      >
        {renderBankInfoFields(`${formName}.senderCorrespondent`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.receiver_correspondent_section_title',
        )}
        key="receiver_correspondent"
      >
        {renderBankInfoFields(`${formName}.receiverCorrespondent`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.third_reimbursement_section_title',
        )}
        key="third_reimbursement"
      >
        {renderBankInfoFields(`${formName}.thirdReimbursement`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.intermediary_institution_section_title',
        )}
        key="intermediary_institution"
      >
        {renderBankInfoFields(`${formName}.intermediaryInstitution`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.account_with_institution_section_title',
        )}
        key="account_with_institution"
      >
        {renderBankInfoFields(`${formName}.accountWithInstitution`)}
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.beneficiary_customer_section_title',
        )}
        key="beneficiary_customer"
      >
        {renderBeneficiaryFields(`${formName}.beneficiaryCustomer`)}

        <FormField
          {...formFieldProps}
          disabled
          label={t('banking.transactions.add_transaction.form_fields.repair')}
          name={`${formName}.beneficiaryCustomer.repair`}
          component={Checkbox}
          onChange={(e) => onRepairChange(!!e?.target?.checked)}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t(
            'banking.transactions.add_transaction.form_fields.repairedBeneficiary',
          )}
          name={`${formName}.beneficiaryCustomer.repairedBeneficiary`}
          component={AccountAutocomplete}
          placeholder={t('select_option', { ns: 'common' })}
          additionalProps={{ allowClear: true, isFullAccountNumber: false }}
        />

        <FormField
          {...formFieldProps}
          disabled
          label={t('banking.transactions.add_transaction.form_fields.fee')}
          name={`${formName}.beneficiaryCustomer.fee`}
          component={YesNoSelect}
          additionalProps={{ allowClear: true }}
          placeholder={t('select_option', { ns: 'common' })}
        />
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.remittance_information_section_title',
        )}
        key="remittance_information"
      >
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 1,
          })}
          name={`${formName}.remittanceInformation.line1`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 2,
          })}
          name={`${formName}.remittanceInformation.line2`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 3,
          })}
          name={`${formName}.remittanceInformation.line3`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 4,
          })}
          name={`${formName}.remittanceInformation.line4`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
      </Collapse.Panel>

      <Collapse.Panel
        header={t(
          'banking.transactions.add_transaction.sender_to_receiver_section_title',
        )}
        key="sender_to_receiver"
      >
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 1,
          })}
          name={`${formName}.senderToReceiver.line1`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 2,
          })}
          name={`${formName}.senderToReceiver.line2`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 3,
          })}
          name={`${formName}.senderToReceiver.line3`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 4,
          })}
          name={`${formName}.senderToReceiver.line4`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 5,
          })}
          name={`${formName}.senderToReceiver.line5`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
        <FormField
          {...formFieldProps}
          label={t('banking.transactions.add_transaction.form_fields.line', {
            number: 6,
          })}
          name={`${formName}.senderToReceiver.line6`}
          component={Input}
          placeholder={t('enter_value', { ns: 'common' })}
        />
      </Collapse.Panel>
    </Collapse>
  );
});

const StyledDatePicker = styled(DatePickerComponent)`
  width: 100%;
`;

const StyledAmountField = styled(AmountField)`
  width: 100%;
`;

export default OutgoingTransactionForm;
