import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { onboardingAnswerAdapter } from '../../../../../../../../../apiAdapters/crm/onboarding/onboardingAnswerAdapter';
import { StateModel as UploadStateModel } from 'redux/reducers/upload';
import {
  AnswerModel,
  onboardingAnswerAPI,
} from '../../../../../../../../../api/crm/onboarding/onboardingAnswerAPI';
import { FormValuesModel as ApplicationDocumentationFormValuesModel } from '../../../..';

// components
import AdditionalDocumentModalDialog, {
  FormValuesModel,
} from '../../../../../../../../../components/Modals/TemplateModalDialogs/CRM/AdditionalDocumentModalDialog';

interface IProps {
  isVisible: boolean;
  closeCallback: (newAnswer?: AnswerModel) => void;
}

const AddAdminDocumentModal = ({ isVisible, closeCallback }: IProps) => {
  const { t } = useTranslation('crm');
  const { values } =
    useFormikContext<ApplicationDocumentationFormValuesModel>();
  const { isFinished, progress } = useSelector<StateModel, UploadStateModel>(
    (state) => state.upload,
  );

  const initialFormValues = useMemo<FormValuesModel>(() => {
    const { itemId, itemType, reviewProcess } = values;

    return {
      itemId,
      itemType,
      reviewProcess,
      name: '',
      description: '',
      type: null,
      options: {
        formDocument: null,
        documentRequiresCertification: false,
        formDocumentId: '',
        selectOptions: [],
        selectOptionsValues: [],
      },
    };
  }, [values]);

  const handleSubmit = async (values: FormValuesModel) => {
    const formattedBody = onboardingAnswerAdapter.addRequiredDocument(values);
    const response =
      await onboardingAnswerAPI.reviewApplicationDocumentation(formattedBody);
    closeCallback(response.operations[0].result);
  };

  return (
    <AdditionalDocumentModalDialog
      title={t('client_group.approval.add_additional_field_document')}
      isVisible={isVisible}
      disabled={!isFinished && progress > 0 && progress < 100}
      closeCallback={() => closeCallback()}
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
    />
  );
};

export default AddAdminDocumentModal;
