import React, { useState } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import countryRiskRatingsAPI from '../../../api/compliance/countryRiskRatingsAPI';
import { DEFAULT_TABLE_LIMIT } from '../../../constants/global';

// components
import FilterBar from './FilterBar';
import { Divider } from 'antd';
import { default as TemplatesCountryRiskRatingsTable } from '../../../components/Tables/TableTemplates/Compliance/CountryRiskRatingsTable';

export type FilterOptionTypes = 'search' | 'risk_rating';

const CountryRiskRatings = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const [countryRiskRatingsFilter, setCountryRiskRatingsFilter] = useState({
    search: '',
    riskRating: undefined,
  });

  const { response, loading } = useFetch(
    () =>
      countryRiskRatingsAPI.fetchCountryRiskRatings({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        search: countryRiskRatingsFilter.search,
        riskRating: countryRiskRatingsFilter.riskRating,
      }),
    [currentPage, countryRiskRatingsFilter],
  );

  const onFilterChange = (key: FilterOptionTypes, value: any) => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }

    switch (key) {
      case 'search': {
        setCountryRiskRatingsFilter((prevState) => ({
          ...prevState,
          search: value,
        }));
        return;
      }
      case 'risk_rating': {
        setCountryRiskRatingsFilter((prevState) => ({
          ...prevState,
          riskRating: value,
        }));
        return;
      }
    }
  };

  return (
    <>
      <FilterBar onFilterChange={onFilterChange} />

      <Divider />

      <TemplatesCountryRiskRatingsTable
        loading={loading}
        data={response?.data || []}
        total={response?.total || 0}
        current={currentPage}
        onPaginationChange={setCurrentPage}
      />
    </>
  );
};

export default CountryRiskRatings;
