import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { FormValuesModel } from '../';
import { useFormikContext } from 'formik';

// components
import Text from '@core_components/Text';
import SectionIntro from '@common_components/Texts/SectionIntro';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import FinanceApprovalRulesForm from 'components/Forms/TemplateForms/Approval/FinanceApprovalRulesForm';

const InnerForm = () => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <SectionIntro
        titleVariant="h5"
        title={t(
          'crm_approval.transaction_approval_rules.view_approval_rule.bank_accounts',
        )}
      />
      {values.accounts.map((bankAccount, index) => (
        <Text key={index} weight="semi-bold">
          <EllipsisTooltip title={bankAccount} maxTextContainerWidth="600px">
            {bankAccount}
          </EllipsisTooltip>
        </Text>
      ))}
      <SectionIntro
        gutterTop
        titleVariant="h5"
        title={t(
          'crm_approval.transaction_approval_rules.view_approval_rule.approval_rule',
        )}
      />
      <FinanceApprovalRulesForm approvalGroups={values.approvalGroups} />
    </>
  );
};

export default InnerForm;
