import React from 'react';

// Helpers & Utils
import { SelectProps } from 'antd/lib/select';
import useTranslation from '../../../../../hooks/useTranslation';

// Components
import { Select } from 'antd';
import { useFormikContext } from 'formik';

interface IProps extends SelectProps<any> {
  showNotProvidedOption?: boolean;
  name: string;

  yesText?: string;
  noText?: string;
}

const YesNoSelect = ({
  showNotProvidedOption,
  value,
  name,
  yesText,
  noText,
  ...rest
}: IProps) => {
  const { t } = useTranslation('common');

  const form = useFormikContext<any>();
  const emptyValue = showNotProvidedOption ? '' : undefined;

  const handleChange = (value: string) => {
    form.setFieldValue(name, value === '' ? undefined : value === 'true');
  };

  const renderOptions = () => {
    const options = [
      { key: 'true', name: yesText || t('yes') },
      { key: 'false', name: noText || t('no') },
    ];

    return options.map(({ key, name }) => (
      <Select.Option key={key} value={key}>
        {name}
      </Select.Option>
    ));
  };

  return (
    <Select
      {...rest}
      value={typeof value === 'boolean' ? value.toString() : emptyValue}
      onChange={handleChange}
    >
      {showNotProvidedOption && (
        <Select.Option value="">{t('not_provided')}</Select.Option>
      )}

      {renderOptions()}
    </Select>
  );
};

export default YesNoSelect;
