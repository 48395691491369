import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';

// components
import ApprovalRulesTab from './ApprovalRulesTab';
import ApprovalGroupsTab from './ApprovalGroupsTab';
import Tabs, { TabModel } from 'components/Tabs';

interface IProps {
  clientGroupName: string;
  clientGroupNumericId: number;
}

const Onboarding = ({ clientGroupName, clientGroupNumericId }: IProps) => {
  const { t } = useTranslation('crm');

  const tabs = useMemo<TabModel[]>(() => {
    return [
      {
        key: 'approval-groups',
        title: t('client_group.tabs.approval_groups'),
        content: (
          <ApprovalGroupsTab clientGroupNumericId={clientGroupNumericId} />
        ),
      },

      {
        key: 'approval-rules',
        title: t('client_group.tabs.approval_rules'),
        content: (
          <ApprovalRulesTab
            clientGroupName={clientGroupName}
            clientGroupNumericId={clientGroupNumericId}
          />
        ),
      },
    ];
  }, [t, clientGroupName, clientGroupNumericId]);

  return <Tabs tabs={tabs} renderOnChange />;
};

export default Onboarding;
