import React, { memo, useCallback } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { generateUniqId } from '@helpers/utils';
import { FormValuesModel } from '..';

// components
import AddButton from '@common_components/Buttons/AddButton';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import PhoneNumberForm, {
  FormValuesModel as PhoneNumberFormValuesModel,
} from '../../PhoneNumberForm';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';

const MAX_PHONE_NUMBERS_CAN_BE_ADDED = 5;

const PhoneNumberSection = memo(() => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  const renderItems = useCallback(
    (arrayHelpers: FieldArrayRenderProps) => {
      return (
        <>
          {values.phoneNumbers.map((e, i) => (
            <PhoneNumberForm
              key={e.id}
              baseName={`phoneNumbers.${i}`}
              onRemove={() => arrayHelpers.remove(i)}
            />
          ))}

          {values.phoneNumbers.length < MAX_PHONE_NUMBERS_CAN_BE_ADDED && (
            <HideIfDisabledForm>
              <AddButton
                onClick={() =>
                  arrayHelpers.push({
                    id: generateUniqId(),
                    type: null,
                    number: '',
                    primary: { status: false },
                  } as PhoneNumberFormValuesModel)
                }
              >
                {t('contacts.new.add_phone_number')}
              </AddButton>
            </HideIfDisabledForm>
          )}
        </>
      );
    },
    [values],
  );

  return <FieldArray name="phoneNumbers" render={renderItems} />;
});

export default PhoneNumberSection;
