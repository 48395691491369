import ErrorHandlerService from '../services/error-handler/service';
import { useState, useEffect } from 'react';

const useFetch = <T,>(
  callback: () => Promise<T> | null,
  dependencies?: any[],
) => {
  const [response, setResponse] = useState<T | null>(null as T);
  const [error, setError] = useState<any>(null); // TODO: Create a general type for all errors.
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(
    () => {
      const abortController = new AbortController();
      const signal = abortController.signal;

      const doFetch = async () => {
        setLoading(true);
        try {
          const res = await callback();
          if (!signal.aborted) {
            setResponse(res);
            if (error) {
              setError(null);
            }
          }
        } catch (error: any) {
          if (!signal.aborted) {
            ErrorHandlerService.handleError(error);
            setError(error);
            setResponse(null);
          }
        } finally {
          if (!signal.aborted) {
            setLoading(false);
          }
        }
      };
      doFetch();
      return () => {
        abortController.abort();
      };
    },
    dependencies ? [...dependencies] : [],
  );
  return { response, error, loading };
};

export default useFetch;
