import React, { memo } from 'react';

// helpers
import { ContactDetailsWithIdentificationValidationSchema } from 'validations/crm/contacts';

// components
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import ContactDetailsWithIdentificationForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/CRM/ContactDetailsWithIdentificationForm';

export type ContactDetailsFormValues = FormValuesModel;

interface IProps
  extends RequiredPropsForFormModel<FormValuesModel>,
    RequiredPropsForModalDialogModel {
  title: string;
}

const ContactDetailsModalDialog = memo(
  ({
    isVisible,
    closeCallback,
    onSubmitError,
    initialValues,
    onSubmit,
    title,
  }: IProps) => {
    return (
      <Form
        enableReinitialize
        confirmExitWithoutSaving
        onSubmit={onSubmit}
        onSubmitError={onSubmitError}
        initialValues={isVisible ? initialValues : null}
        validationSchema={ContactDetailsWithIdentificationValidationSchema}
        renderForm={
          <ModalDialog
            closeModalAfterError={false}
            title={title}
            isVisible={isVisible}
            closeCallback={closeCallback}
          >
            <ContactDetailsWithIdentificationForm />
          </ModalDialog>
        }
      />
    );
  },
);

export default ContactDetailsModalDialog;
