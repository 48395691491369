// helpers
import styled from 'styled-components';

// components
import { Typography } from 'antd';

export default styled(Typography.Paragraph)`
  font-weight: 600;
  color: ${({ theme }) => theme.whiteColor};
  font-size: ${({ theme }) => theme.fontSizeMd};
`;
