import React from 'react';

// Helpers & Utils
import useTranslation from '../../../../../../hooks/useTranslation';
import { contactsAPI } from '../../../../../../api/crm/contact/contactsAPI';
import { IContactModel } from '../../../../../../typings/crm/contact';
import { ContactHelpers } from '@helpers/crm/contact';

// constants
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// Components
import Autocomplete, {
  AutocompleteInitialValue,
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';

interface IProps {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  isAssociated?: boolean;
  initialValue?: AutocompleteInitialValue;
  onSelect?: (id: string, contact?: IContactModel) => void;

  shouldDisableContactsWithoutEmailAndPhone?: boolean;
}

const ContactAutocomplete = ({
  isAssociated,
  shouldDisableContactsWithoutEmailAndPhone,
  ...rest
}: IProps) => {
  const { t } = useTranslation('orac');

  const fetchContacts = async (search: string) => {
    const contactsResult = await contactsAPI.fetchContacts({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      isAssociated,
      search,
    });

    return contactsResult.data.map<AutocompleteOption>((c: any) => {
      const isDisabled =
        shouldDisableContactsWithoutEmailAndPhone &&
        (!c.emails.length ||
          !c.phoneNumbers.length ||
          (c.emails.length && !c.emails.find((email: any) => email.address)) ||
          (c.phoneNumbers.length &&
            !c.phoneNumbers.find((phone: any) => phone.number)));

      return {
        id: c._id,
        content: ContactHelpers.getContactName(c),
        isDisabled: isDisabled,
        disabledTooltipText: t('users.form.contactTooltip'),
        model: c,
      };
    });
  };

  return <Autocomplete {...rest} fetchData={fetchContacts} />;
};

export default ContactAutocomplete;
