import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { IRelationshipTemplateModel } from '../../../../../typings/crm/relationship-template';

// components
import TableActions from '../../../TableActions';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps
  extends RequiredPropsForTableModel<IRelationshipTemplateModel> {
  onActionsClick: (key: string, templateId: string) => void;
}

const RelationshipTemplatesTable = memo(
  ({ onActionsClick, ...rest }: IProps) => {
    const { t } = useTranslation('crm');

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          key: 'name',
          ellipsis: true,
          render: (record: IRelationshipTemplateModel) => (
            <Typography.Link
              onClick={() => onActionsClick('view_template', record._id)}
            >{`${record.parentName} / ${record.childName}`}</Typography.Link>
          ),
          title: t('relationship_templates.template_name'),
        },
        {
          key: 'parentName',
          render: (record: IRelationshipTemplateModel) => record.parentName,
          title: t('relationship_templates.parent_name'),
        },
        {
          key: 'childName',
          render: (record: IRelationshipTemplateModel) => record.childName,
          title: t('relationship_templates.child_name'),
        },
        {
          key: 'additionalFields',
          render: (record: IRelationshipTemplateModel) => (
            <Typography.Text>{record.additionalFields.length}</Typography.Text>
          ),
          title: t('relationship_templates.additional_fields_count'),
        },
        {
          key: 'relationshipsCount',
          render: (record: IRelationshipTemplateModel) =>
            record.relationshipsCount,
          title: t('relationship_templates.relationships_count'),
        },
        {
          key: 'actions',
          width: 70,
          render: (record: IRelationshipTemplateModel) => (
            <TableActions
              onSelect={(key: string) => onActionsClick(key, record._id)}
              menu={[
                {
                  key: 'view_instances',
                  name: t('relationship_templates.view_instances'),
                },
              ]}
            />
          ),
          mobileCardProps: { isActionsColumn: true },
        },
      ];

      return result;
    }, [onActionsClick]);

    return <Table columns={columns} {...rest} />;
  },
);

export default RelationshipTemplatesTable;
