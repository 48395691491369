import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { UserProfileModel } from '../../typings/profile/profile';

const profileAPI = {
  getMe: () => {
    return APIService.get<UserProfileModel>(
      `${APIConfig.crmApi}/users/profile`,
    ).then(({ data }) => data);
  },
};

export { profileAPI };
