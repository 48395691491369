import React, { useMemo } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';
import {
  onboardingAPI,
  OnboardingApprovalGroup,
} from 'api/crm/onboarding/onboardingAPI';

// components
import OnboardingTransactionRulesModal, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/OnboardingTransactionRulesModal';

interface IProps extends RequiredPropsForModalDialogModel {
  clientGroupId: number;
  clientGroupName: string;
  approvalRuleId: string | null;
}

const ApprovalRuleDetailsModal = ({
  isVisible,
  closeCallback,
  clientGroupId,
  approvalRuleId,
  clientGroupName,
}: IProps) => {
  const { t } = useTranslation('crm');
  const { response: approvalRuleResponse } = useFetch(
    () =>
      isVisible && approvalRuleId
        ? onboardingAPI.fetchApprovalRuleById(approvalRuleId, clientGroupId)
        : null,
    [isVisible, approvalRuleId, clientGroupId],
  );

  const { response: approvalGroupsResponse } = useFetch(
    () =>
      isVisible
        ? onboardingAPI.fetchApprovalGroups(
            {
              page: 1,
              limit: 100,
            },
            clientGroupId,
            'onboarding-transaction',
          )
        : null,
    [isVisible, clientGroupId],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!isVisible || !approvalRuleResponse || !approvalGroupsResponse) {
      return null;
    }

    const approvalGroupsMap = approvalGroupsResponse.data.reduce<
      Record<string, OnboardingApprovalGroup>
    >((acc, next) => {
      acc[next._id] = next;
      return acc;
    }, {});

    return {
      isEditMode: false,
      filterClientGroupsInDropdown: false,
      accounts: approvalRuleResponse.associatedAccounts.map((e) =>
        t(
          'crm_approval.transaction_approval_rules.view_approval_rule.client_group_name',
          {
            clientGroupName,
            currency: e.currencyIsoCode,
            purpose: e.purpose,
          },
        ),
      ),
      limits: approvalRuleResponse.criteriaList.map((e) => ({
        from: e.range?.min || 0,
        to: !e.range?.max ? undefined : e.range?.max || 0,
        toInfinite: !e.range?.max,
        isUsed: true,
      })),
      conditions: approvalRuleResponse.ruleSets.map((e, conditionIndex) => {
        const limitStatuses = approvalRuleResponse.criteriaList.map((c) => ({
          isChecked: c.activeSetIndices.includes(conditionIndex),
        }));

        return {
          conditions: e.items.map((c) => ({
            approvalGroup: c.group.id,
            requiredApproversCount:
              approvalGroupsMap[c.group.id].participants?.length <
              c.minimumThreshold
                ? null
                : c.minimumThreshold,
          })),
          limitStatuses,
        };
      }),
      approvalGroups: approvalGroupsResponse.data.map((e) => ({
        id: e._id,
        name: e.name,
        description: e.description,
        isActive: true,
        participants: e.participants.map((p) => ({
          userId: p.contactId,
          userFullName: p.name,
        })),
      })),
    };
  }, [
    isVisible,
    clientGroupName,
    approvalRuleResponse,
    approvalGroupsResponse,
  ]);

  return (
    <OnboardingTransactionRulesModal
      disabled
      title={t(
        'crm_approval.transaction_approval_rules.view_approval_rule.title',
      )}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      onSubmit={console.log}
    />
  );
};

export default ApprovalRuleDetailsModal;
