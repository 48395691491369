import React from 'react';

// helpers
import styled from 'styled-components';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { IMessageAttachmentsForm } from '../AttachmentsForm';
import { IDocumentAssociationInfo } from '../../../../../../modules/CRM/Documents/UploadDocumentsDialog';
import { ReplyFormValidationSchema } from '../../../../../../validations/messages';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import SubmitButton from '../../../../../Buttons/SubmitButton';

export interface FormValuesModel extends IMessageAttachmentsForm {
  message: string;
}

interface IProps {
  association: IDocumentAssociationInfo;
  initialFormValues: FormValuesModel;
  onSubmit: (values: FormValuesModel) => Promise<void>;
}

const ReplyForm = ({ onSubmit, initialFormValues, association }: IProps) => {
  const { t } = useTranslation('crm');

  const handleSubmit = async (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    await onSubmit(values);
    helpers.resetForm({ values: initialFormValues });
  };

  return (
    <Form<FormValuesModel>
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      validationSchema={ReplyFormValidationSchema}
      renderForm={
        <>
          <InnerForm association={association} />
          <StyledSubmitButton type="primary">
            {t('messaging.send_message.send_button')}
          </StyledSubmitButton>
        </>
      }
    />
  );
};

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: ${({ theme }) => theme.marginXXs};
`;

export default ReplyForm;
