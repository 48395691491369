import React, { useEffect, useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import useUserAccess from '../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../hooks/useTranslation';
import { message } from 'antd';
import { FormikHelpers } from 'formik';
import { FormValuesModel } from '../../../../../components/Forms/FormComponents/ApprovalManagementMatrix';
import { complianceTemplateAPI } from '../../../../../api/approval/approvalManagement/complianceTemplateAPI';
import { approvalTemplatesAdapter } from '../../../../../apiAdapters/approval/templates/approvalTemplatesAdapter';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../constants/global';
import {
  ApprovalMatrixThresholdBaseModel,
  OnboardingTemplateModel,
} from '../../../../../typings/approval/templates';

// components
import LoaderSection from '../../../../../components/Loaders/LoaderSection';
import ApprovalManagementMatrix from '../../../../../components/Forms/FormComponents/ApprovalManagementMatrix';

const ApprovalRulesTab = () => {
  const { t } = useTranslation('approval_management');
  const [initialTemplate, setInitialTemplate] =
    useState<OnboardingTemplateModel | null>(null);

  // TODO: need to prededifine approval groups in autocomplete
  // need to remove this when API will be updated (API should return detailed information for each approval group)
  const { response: approvalGroups, loading: approvalGroupsLoader } = useFetch(
    () =>
      complianceTemplateAPI.fetchApprovalGroups({
        page: 1,
        limit: AUTOCOMPLETE_RESULT_LIMIT,
      }),
  );
  const { response: ruleLimits, loading: ruleLimitsLoader } = useFetch(() =>
    complianceTemplateAPI.fetchApprovalRuleStatuses(),
  );
  const { response: approvalTemplate, loading: initialRulesLoader } = useFetch(
    () => complianceTemplateAPI.fetchApprovalRuleTemplate(),
  );

  useEffect(() => {
    setInitialTemplate(
      approvalTemplate && approvalTemplate.id ? approvalTemplate : null,
    );
  }, [approvalTemplate]);

  const [hasCreateAccess, hasEditAccess] = useUserAccess([
    'ApprovalManagement_Templates_Compliance_ApprovalRules_Create',
    'ApprovalManagement_Templates_Compliance_ApprovalRules_Edit',
  ]);

  const initialFormValues: FormValuesModel | null = useMemo(() => {
    let result = null;

    if (approvalGroups && ruleLimits) {
      if (initialTemplate) {
        result = generateInitialFormValuesBasedOnTemplate(
          initialTemplate,
          ruleLimits,
        );
      } else {
        result = generateDefaultFormValues(ruleLimits);
      }
    }

    return result;
  }, [approvalGroups, ruleLimits, initialTemplate]);

  function generateDefaultFormValues(
    limits: ApprovalMatrixThresholdBaseModel[],
  ): FormValuesModel {
    const thresholdStatuses: { [key: string]: { ticked: boolean } } = {};

    limits.forEach(({ id }) => (thresholdStatuses[id] = { ticked: true }));

    const initialApprovalRule = {
      thresholdStatuses,
      approvalGroupConditions: [
        {
          approvalGroupId: null,
          requiredApprovers: null,
          maximumRequiredApprovers: 0,
        },
      ],
    };

    return {
      id: null,
      isEditMode: false,
      approvalWorkflowType: 'compliance',
      approvalConditions: [initialApprovalRule],
    };
  }

  function generateInitialFormValuesBasedOnTemplate(
    initialRules: OnboardingTemplateModel,
    limits: ApprovalMatrixThresholdBaseModel[],
  ) {
    const result: FormValuesModel = {
      id: initialRules.id,
      isEditMode: false,
      approvalWorkflowType: 'compliance',
      approvalConditions: [],
    };

    const baseThreasholdStatuses: { [key: string]: { ticked: boolean } } = {};
    limits.forEach(
      ({ id }) => (baseThreasholdStatuses[id] = { ticked: false }),
    );

    result.approvalConditions = initialRules.approvalRuleSets.map(
      (condition) => {
        // Fill threshold statuses based on received infromation
        // By default we should take info from limits array
        const thresholdStatuses = { ...baseThreasholdStatuses };

        condition.approvalThreasholds.forEach(({ threasholdMin, ticked }) => {
          thresholdStatuses[threasholdMin] = { ticked: !!ticked };
        });

        const approvalGroupConditions = condition.approvalRuleItems.map(
          ({ approvalGroup, minimunApprovalThreashold }) => {
            const approvalGroupFull = approvalGroups?.data.find(
              ({ id }) => id === approvalGroup.id,
            );
            const maximumRequiredApprovers =
              approvalGroupFull?.participants.length || 0;
            const initialAutocompleteValue = {
              id: approvalGroupFull?.id as string,
              content: approvalGroupFull?.name,
            };

            return {
              maximumRequiredApprovers,
              initialAutocompleteValue,
              requiredApprovers: minimunApprovalThreashold,
              approvalGroupId: approvalGroup.id,
            };
          },
        );

        return { approvalGroupConditions, thresholdStatuses };
      },
    );

    return result;
  }

  const handleOnSubmit = async (
    values: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    let templateId = null;

    if (initialTemplate) {
      templateId = initialTemplate.id;
      await complianceTemplateAPI.editApprovalRuleTemplate(
        approvalTemplatesAdapter.editApprovalRuleTemplate(values),
      );
    } else {
      const newTemplate =
        await complianceTemplateAPI.createApprovalRuleTemplate(
          approvalTemplatesAdapter.createApprovalRuleTemplate(values),
        );

      templateId = newTemplate.id;
      setInitialTemplate(newTemplate);
    }

    message.success(t('compliance_template.approval_rules.success_update'));
    formikHelpers.resetForm({
      values: { ...values, id: templateId, isEditMode: false },
    });
  };

  if (approvalGroupsLoader || ruleLimitsLoader || initialRulesLoader) {
    return <LoaderSection />;
  }

  return (
    <>
      {initialFormValues && (
        <ApprovalManagementMatrix
          limits={ruleLimits as ApprovalMatrixThresholdBaseModel[]}
          onSubmit={handleOnSubmit}
          initialFormValues={initialFormValues}
          approvalWorkflowType="compliance"
          hasEditAccess={hasCreateAccess || hasEditAccess}
        />
      )}
    </>
  );
};

export default ApprovalRulesTab;
