// ------------ SOCIAL MEDIA LINK REGEX --------

export const WEBSITE_REG =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
export const FORMAT_AMOUNT_REG = /\B(?=(\d{3})+(?!\d))/g;
export const PHONE_VALIDATION_REG =
  /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;

// CRM
// eslint-disable-next-line no-useless-escape
export const CRM_NAME_REG = /[\w\u00C0-\u00ff\s'\.,-\/#!$%\^&\*;:{}=\-\`~()]+/g;
