import React, { memo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useUserAccess from '@hooks/useUserAccess';
import { forgotUsernameRequestsAPI } from 'api/orac/forgotUsernameRequestsAPI';
import { ForgotUsernameRequestStatuses } from 'components/Additional/Statuses/ForgotUsernameRequestStatus';

// components
import ActionButtons from './ActionButtons';
import DetailsSection from './DetailsSection';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import { Divider } from 'antd';

interface IProps {
  requestId: string;
}

const ForgotUsernameRequestDetails = memo(({ requestId }: IProps) => {
  const [updateRequestTrigger, updateRequest] = useState({});
  const [hasAccessToUpdateStatus] = useUserAccess([
    'ORAC_ForgotUsernameRequestDetails_UpdateStatus',
  ]);

  const { response, loading } = useFetch(
    () =>
      requestId
        ? forgotUsernameRequestsAPI.fetchForgotUsernameRequestById(requestId)
        : null,
    [requestId, updateRequestTrigger],
  );

  return (
    <LoadingWrapper loading={loading}>
      {response && (
        <>
          <DetailsSection request={response} />
          {hasAccessToUpdateStatus &&
            response.status !== ForgotUsernameRequestStatuses.Completed && (
              <>
                <Divider />
                <ActionButtons
                  request={response}
                  onStatusUpdate={() => updateRequest({})}
                />
              </>
            )}
        </>
      )}
    </LoadingWrapper>
  );
});

export default ForgotUsernameRequestDetails;
