import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { SelectProps } from 'antd/lib/select';
import { ReferencePropertyModel } from '../../../../../../typings/compliance/configurationRules';

// components
import { Select } from 'antd';

interface IProps extends SelectProps<any> {
  objectProperties: ReferencePropertyModel[];
}

const ReferenceObjectPropertySelect = ({
  value,
  objectProperties,
  ...rest
}: IProps) => {
  const { t } = useTranslation('compliance');

  const renderOptions = () => {
    return objectProperties.map((property) => (
      <Select.Option key={property.name} value={property.name} model={property}>
        {property.name}
      </Select.Option>
    ));
  };

  return (
    <Select
      {...rest}
      value={value}
      showSearch
      placeholder={t('review_configuration.reference_property_name_ph')}
    >
      {renderOptions()}
    </Select>
  );
};

export default ReferenceObjectPropertySelect;
