import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { RDCReviewStatuses } from '../../../../../enums/compliance/reviewConfiguration';
import { RDCAlertFullModel } from '../../../../../typings/compliance/reviewProcess';

// components
import JSONInTree from '../../../../Additional/JSONInTree';
import MatchButton from './MatchButton';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Card, Col, message, Row, Spin } from 'antd';

export type ReviewActionTypes = 'match' | 'not_match';

interface IProps extends RequiredPropsForModalDialogModel {
  alert: RDCAlertFullModel | null;
  loadingInitialData?: boolean;
  onReview: (
    action: ReviewActionTypes,
    alert: RDCAlertFullModel,
    reason?: string,
  ) => Promise<void>;
}

const ReviewRDCAlertModal = ({
  isVisible,
  loadingInitialData,
  alert,
  closeCallback,
  onReview,
}: IProps) => {
  const { t } = useTranslation('compliance');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (action: ReviewActionTypes, reason?: string) => {
    if (!alert) {
      return;
    }

    setIsSubmitting(true);
    await onReview(action, alert, reason);
    message.success(t('rdc_review.alert_review_success'));
    setIsSubmitting(false);
    closeCallback(true);
  };

  return (
    <ModalDialog
      hideFooterButtons
      title={t('rdc_review.review_alert_title')}
      isVisible={isVisible}
      closeCallback={closeCallback}
    >
      <Spin spinning={!!loadingInitialData}>
        {alert && (
          <>
            <Card>
              <JSONInTree json={alert?.alertEntityJSON} />
            </Card>

            {!alert.reviewStatus.isReviewed && (
              <StyledRow justify="end" gutter={[16, 16]}>
                <Col span={5}>
                  <StyledMatchButton
                    danger
                    size="large"
                    action="match"
                    loading={isSubmitting}
                    onClick={(reason: string) => handleSubmit('match', reason)}
                  >
                    {t(
                      `review_statuses.${RDCReviewStatuses[RDCReviewStatuses.Matched]}`,
                    )}
                  </StyledMatchButton>
                </Col>
                <Col span={5}>
                  <StyledMatchButton
                    size="large"
                    action="no_match"
                    loading={isSubmitting}
                    onClick={(reason: string) =>
                      handleSubmit('not_match', reason)
                    }
                  >
                    {t(
                      `review_statuses.${RDCReviewStatuses[RDCReviewStatuses.NotMatched]}`,
                    )}
                  </StyledMatchButton>
                </Col>
              </StyledRow>
            )}
          </>
        )}
      </Spin>
    </ModalDialog>
  );
};

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const StyledMatchButton = styled(MatchButton)`
  width: 100%;
  min-width: 0px;
`;

export default ReviewRDCAlertModal;
