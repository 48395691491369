import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';

// components
import Text from '@core_components/Text';
import ApprovalStatusSelect from 'components/Forms/FormComponents/SelectInputs/ApprovalStatusSelect';
import { Col, Row } from 'antd';

interface IProps {
  onFilterChange: (key: string, value: string) => void;
  selectedStatus: number;
}

const FilterBar = ({ selectedStatus, onFilterChange }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <Row gutter={[16, 16]}>
      <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
        <Row>
          <Col span={24}>
            <Text variant="body1">
              {t(
                'client_group.administration_rules.pending_approvals.table.filter_bar.filter_by_status',
              )}
            </Text>
          </Col>
          <Col span={24}>
            <StyledApprovalStatusSelect
              value={selectedStatus}
              size="large"
              onChange={(value: any) =>
                onFilterChange('filter-by-status', value)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledApprovalStatusSelect = styled(ApprovalStatusSelect)`
  width: 100%;
`;

export default FilterBar;
