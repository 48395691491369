import React, { useState } from 'react';

// helpers
import { IClientGroup } from 'typings/crm/client-group';

// components
import ApprovalsTable from './ApprovalsTable';
import ApprovalEntryModal from './ApprovalEntryModal';

export interface ApprovalStatusMap {
  [key: number]: string;
}

interface IProps {
  clientGroup: IClientGroup;
}

const PendingApprovalsTab = ({ clientGroup }: IProps) => {
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<string | null>(
    null,
  );

  const handleModalClose = () => {
    setSelectedWorkflowId(null);
  };

  const handleApprovalSelect = (approvalId: string) => {
    setSelectedWorkflowId(approvalId);
  };

  return (
    <>
      <ApprovalsTable
        onApprovalSelect={handleApprovalSelect}
        clientGroupId={clientGroup.numericId}
      />
      <ApprovalEntryModal
        isVisible={!!selectedWorkflowId}
        workflowId={selectedWorkflowId}
        closeCallback={handleModalClose}
        clientGroup={clientGroup}
      />
    </>
  );
};

export default PendingApprovalsTab;
