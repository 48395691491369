import React, { useMemo, useState } from 'react';

// helpers
import usersAPI from '../../../../../../api/orac/usersAPI';
import useFetch from '../../../../../../hooks/useFetch';
import groupsAPI from '../../../../../../api/orac/groupsAPI';
import useTranslation from '../../../../../../hooks/useTranslation';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../../../constants/global';

// components
import FilterBar from './FilterBar';
import RolesTable from './RolesTable';
import {
  default as TemplateGroupsTable,
  RecordModel,
} from '../../../../../../components/Tables/TableTemplates/ORAC/GroupsTable';

import { Divider, message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface IProps {
  userId: string;
  updateTableTrigger: unknown;

  access?: {
    canEditGroup?: boolean;
    canViewRoles?: boolean;
    canViewPermissions?: boolean;
  };
}

const GroupsTable = ({ userId, updateTableTrigger, access }: IProps) => {
  const { t } = useTranslation(['orac', 'common']);

  const [current, setCurrent] = useState(1);
  const [searchByName, setSearchByName] = useState('');
  const [localUpdateTableTrigger, updateTable] = useState<unknown>();

  const { response, loading } = useFetch(
    () =>
      usersAPI.fetchGroupsByUserId(userId, {
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        name: searchByName,
      }),
    [current, searchByName, updateTableTrigger, localUpdateTableTrigger],
  );

  const handleFilterBarChange = (key: string, value: string) => {
    if (current > 1) {
      setCurrent(1);
    }

    if (key === 'search_by_name') {
      setSearchByName(value || '');
    }
  };

  const handleGroupDeleteClick = (groupId: string) => {
    Modal.confirm({
      title: t('users.view.remove_user_from_group_confirmation'),
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: () => removeUserFromGroup(groupId),
    });
  };

  const removeUserFromGroup = async (groupId: string) => {
    await groupsAPI.removeUsersFromGroup(groupId, [userId]);
    message.success(t('users.view.success_remove_user_from_group'));
    updateTable({});
  };

  const handleActions = (key: string, value: string) => {
    switch (key) {
      case 'remove':
        handleGroupDeleteClick(value);
        break;
    }
  };

  const expandableRow = {
    expandedRowRender: ({ id }: RecordModel) => (
      <RolesTable
        groupId={id}
        canSeeRolePermissions={access?.canViewPermissions}
      />
    ),
  };

  const actions = useMemo(
    () => [
      {
        key: 'remove',
        name: t('delete', { ns: 'common' }),
        danger: true,
        hidden: !access?.canEditGroup,
      },
    ],
    [access],
  );

  return (
    <>
      <FilterBar onFilterChange={handleFilterBarChange} />

      <Divider />

      <TemplateGroupsTable
        data={response?.data || []}
        total={response?.total || 0}
        current={current}
        loading={loading}
        onPaginationChange={setCurrent}
        expandable={expandableRow}
        onActionsClick={handleActions}
        actions={actions}
      />
    </>
  );
};

export default GroupsTable;
