import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { TransferTemplateStatuses } from 'enums/transfers/templates';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

interface FetchTransferTemplatesByClientGroupIdParams extends FetchParamsModel {
  clientGroupId: string;
  status?: string;
}

export type TransferTemplateFromQuery = {
  id: number;
  name: string;
  isOnlyMe: boolean;
  templateTypeId: number;
  templateTypeName: string;
  creationDate: number;
  additionalData: {
    accountName: string;
    accountNumber: string;
    recipientReference: string;
    beneficiaryCountry: string;
    beneficiaryDistrict: string;
    beneficiaryCity: string;
    beneficiaryPostCode: string;
    beneficiaryAddress1: string;
    address: string;
    tag: string;
    chain: string;
    status: TransferTemplateStatuses;
    invalidityReason: string;

    derivedFrom?: string;

    beneficiaryBankData?: {
      bankCode: string;
      bankName: string;
      bankCountry: string;
      bankAddress1: string;
      bankCity: string;
      bankDistrict: string;
      bankPostCode: string;

      generalBankData: {
        bankCodeTypeName: string;
        bankCode: string;
        bankName: string;
      };
    };
    intermediaryBankData?: {
      bankCode: string;
      bankName: string;
      bankCountry: string;
      bankAddress1: string;
      bankCity: string;
      bankDistrict: string;
      bankPostCode: string;

      generalBankData: {
        bankCodeTypeName: string;
        bankCode: string;
        bankName: string;
      };
    };
  };
};

export type InternalTransferTemplateFromQuery = {
  id: number;
  isOnlyMe: boolean;

  templateName: string;
  templateTypeId: number;
  templateTypeName: string;
  templateTypeDescription: string;
  recipientReference: string;

  accountName: string;
  accountNumber: string;

  currencyId: number;
  currencyIsoCode: string;
  creationDate: number;
  clientGroupId: number;
};

export type WireTransferTemplateFromQuery = {
  id: number;
  templateName: string;
  templateTypeId: number;
  templateTypeName: string;
  templateTypeDescription: string;
  clientGroupId: number;
  isOnlyMe: boolean;
  beneficiaryAccountName: string;
  beneficiaryAccountNumber: string;
  beneficiaryCountry: string;
  beneficiaryAddress1: string;
  beneficiaryAddress2: string;
  beneficiaryAddress3: string;
  beneficiaryBankCodeId: number;
  beneficiaryBankCode: string;
  beneficiaryBankCodeType: number;
  beneficiaryBankName: string;
  beneficiaryBankCountry: string;
  beneficiaryBankAddress1: string;
  beneficiaryBankAddress2: string;
  beneficiaryBankAddress3: string;
  intermediaryBankCodeId: number;
  intermediaryBankCode: string;
  intermediaryBankCodeType: number;
  intermediaryBankName: string;
  intermediaryBankCountry: string;
  intermediaryBankAddress1: string;
  intermediaryBankAddress2: string;
  intermediaryBankAddress3: string;
  reference: string;
  beneficiaryBankCodeTypeName: string;
  intermediaryBankCodeTypeName: string;
  beneficiaryCity: string;
  beneficiaryDistrict: string;
  beneficiaryPostCode: string;
  derivedFrom: string;
  processingType: string;
  beneficiaryBankCity: string;
  beneficiaryBankDistrict: string;
  beneficiaryBankPostCode: string;
  intermediaryBankCity: string;
  intermediaryBankDistrict: string;
  intermediaryBankPostCode: string;
  status: TransferTemplateStatuses;
  invalidityReason: string;
  creationDate: number;
};

export type ArchivationTransferTemplateBodyModel = {
  invalidityReason: string;
};

const transferTemplatesAPI = {
  fetchTransferTemplatesByClientGroupId: (
    params: FetchTransferTemplatesByClientGroupIdParams,
  ) => {
    return APIService.get<FetchResponseModel<TransferTemplateFromQuery>>(
      `${APIConfig.accountingApi}/templates`,
      { params },
    ).then(({ data }) => data);
  },

  fetchInternalTransferTemplateById: (id: number) => {
    return APIService.get<InternalTransferTemplateFromQuery>(
      `${APIConfig.accountingApi}/templates/internal/${id}`,
    ).then(({ data }) => data);
  },

  fetchWireTransferTemplateById: (id: number) => {
    return APIService.get<WireTransferTemplateFromQuery>(
      `${APIConfig.accountingApi}/templates/international/${id}`,
    ).then(({ data }) => data);
  },

  archiveTransferTemplate: (
    templateId: number,
    body: ArchivationTransferTemplateBodyModel,
  ) => {
    return APIService.post(
      `${APIConfig.accountingApi}/templates/international/${templateId}/archive`,
      body,
    ).then(({ data }) => data);
  },
};

export { transferTemplatesAPI };
