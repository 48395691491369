import React, { useMemo, useState } from 'react';

// helpers
import { IMessagesStatus } from '../../../../typings/messaging/messages';
import { IContactModel } from '../../../../typings/crm/contact';
import { DocumentAssociationTypes } from '../../../../enums/crm/crm';

// components
import { Col, Row } from 'antd';
import SendMessage from './SendMessage';
import MessagingTabs from './Tabs';

interface IProps {
  clientGroupId?: string;
  clientGroupNumericId: number;
  contact?: IContactModel;
  messagesStatus?: IMessagesStatus;
}

const Messages = ({
  clientGroupId = '',
  clientGroupNumericId,
  contact,
  messagesStatus,
}: IProps) => {
  const [updateTableTrigger, triggerTableUpdate] = useState({});

  const handleMessageSent = () => {
    triggerTableUpdate({});
  };

  const association = useMemo(() => {
    if (contact) {
      return {
        type: DocumentAssociationTypes.Contact,
        id: contact._id,
      };
    }

    return {
      type: DocumentAssociationTypes.ClientGroup,
      id: clientGroupId,
    };
  }, [contact, clientGroupId]);

  return (
    <>
      <Row justify="end">
        <Col>
          <SendMessage
            clientGroupId={clientGroupId}
            clientGroupNumericId={clientGroupNumericId}
            onSent={handleMessageSent}
            association={association}
          />
        </Col>
      </Row>

      <MessagingTabs
        clientGroupId={clientGroupNumericId}
        clientUserId={contact?.associations?.auth}
        updateTableTrigger={updateTableTrigger}
        messagesStatus={messagesStatus}
        association={association}
        onSent={handleMessageSent}
      />
    </>
  );
};

export default Messages;
