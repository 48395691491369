import React from 'react';

// Helpers utils
import { DocumentHelpers } from '@helpers/documents';
import { StyledComponentProps } from '../../../typings/common';

// Components
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Typography } from 'antd';

interface IProps extends StyledComponentProps {
  text?: string;
  documentId: string;
  fileId?: string;
  fileName?: string;
  onClick?: () => void;
}

const DocumentLink = ({
  text,
  documentId,
  fileId = '',
  fileName,
  onClick,
  ...rest
}: IProps) => {
  const handleDownloadDocument = async () => {
    await DocumentHelpers.downloadDocument(documentId, fileId, fileName);
    onClick && onClick();
  };

  return (
    <Typography.Link {...rest} onClick={handleDownloadDocument}>
      <EllipsisTooltip title={text} maxTextContainerWidth="270px">
        {text}
      </EllipsisTooltip>
    </Typography.Link>
  );
};

export default DocumentLink;
