import React from 'react';

// components
import NotificationPreferences from '../../modules/Profile/AccountNotifications/AccountPreferences';

const AccountNotifications = () => {
  return <NotificationPreferences />;
};

export default AccountNotifications;
