import React from 'react';

// ** Helpers utils **
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';

// ** Components **
import CertificationRequiredTooltip from '../../Tooltips/TemplateTooltips/CertificationRequiredTooltip';

const CertificationRequiredLabel = () => {
  const { t } = useTranslation('crm');

  return (
    <StyledHint>
      {t('application.documentation.certification_required')}
      <CertificationRequiredTooltip />
    </StyledHint>
  );
};

const StyledHint = styled.span`
  color: ${({ theme }) => theme.warningColor};
`;

export default CertificationRequiredLabel;
