import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ChangeRequestStatuses } from 'api/crm/changeRequests/changeRequestsAPI';

// components
import StatusLabel from 'components/Typography/StatusLabel';

const STATUS_MAP = {
  info: [],
  warning: [ChangeRequestStatuses.InProgress, ChangeRequestStatuses.InReview],
  success: [],
  error: [],
};

interface IProps {
  status: ChangeRequestStatuses;
}

const ChangeRequestStatus = ({ status }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(`contacts.kyc_form.change_request_statuses.${status}`)}
    />
  );
};

export default ChangeRequestStatus;
