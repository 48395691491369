import React from 'react';

// helpers
import styled from 'styled-components';
import { enumToMap } from '../../../../../helpers/utils';
import useTranslation from '../../../../../hooks/useTranslation';
import { NotificationMethods } from '../../../../../enums/admin/orac';

// components
import { Select } from 'antd';

interface IProps {
  onChange: (value: any) => void;
  value: NotificationMethods;
}

const NotificationTypeSelect = ({ onChange, value }: IProps) => {
  const { t } = useTranslation('orac');

  const renderOptions = () => {
    const enumMap = enumToMap(NotificationMethods);

    return Array.from(enumMap).map((el) => {
      return (
        <Select.Option key={el[1]} value={el[1]}>
          {t(`notifications.notification_methods.${el[0]}`)}
        </Select.Option>
      );
    });
  };

  return (
    <StyledSelect
      placeholder={t('notifications.select_notifications_type')}
      onChange={onChange}
      showArrow
      size="large"
      value={value}
      filterOption={false}
    >
      {renderOptions()}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default NotificationTypeSelect;
