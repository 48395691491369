import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';
import {
  ApprovalGroupBaseModel,
  ApprovalGroupModel,
  ApprovalMatrixThresholdBaseModel,
  OnboardingTemplateBaseModel,
  OnboardingTemplateModel,
} from '../../../typings/approval/templates';

const newBusinessTemplateAPI = {
  fetchApprovalGroups: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<ApprovalGroupModel>>(
      `${APIConfig.approvalApi}/new-business/groups`,
      { params },
    ).then(({ data }) => data);
  },

  fetchApprovalGroupById: (groupId: string) => {
    return APIService.get<ApprovalGroupModel>(
      `${APIConfig.approvalApi}/new-business/groups/${groupId}`,
    ).then(({ data }) => data);
  },

  createApprovalGroup: (newGroup: ApprovalGroupBaseModel) => {
    return APIService.post<ApprovalGroupModel>(
      `${APIConfig.approvalApi}/new-business/groups`,
      newGroup,
    ).then(({ data }) => data);
  },

  editApprovalGroup: (updatedGroup: ApprovalGroupModel) => {
    return APIService.patch<ApprovalGroupModel>(
      `${APIConfig.approvalApi}/new-business/groups/${updatedGroup.id}`,
      updatedGroup,
    ).then(({ data }) => data);
  },

  fetchApprovalRuleStatuses: () => {
    return APIService.get<ApprovalMatrixThresholdBaseModel[]>(
      `${APIConfig.approvalApi}/new-business/template/types`,
    ).then(({ data }) => data);
  },

  fetchApprovalRuleTemplate: () => {
    return APIService.get<OnboardingTemplateModel>(
      `${APIConfig.approvalApi}/new-business/template/default`,
    ).then(({ data }) => data);
  },

  editApprovalRuleTemplate: (template: OnboardingTemplateModel) => {
    return APIService.patch<OnboardingTemplateModel>(
      `${APIConfig.approvalApi}/new-business/template/${template.id}`,
      template,
    ).then(({ data }) => data);
  },

  createApprovalRuleTemplate: (template: OnboardingTemplateBaseModel) => {
    return APIService.post<OnboardingTemplateModel>(
      `${APIConfig.approvalApi}/new-business/template`,
      template,
    ).then(({ data }) => data);
  },
};

export { newBusinessTemplateAPI };
