import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import useUserAccess from '../../../../hooks/useUserAccess';
import { Moment } from 'moment';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { changeView } from '../../../../redux/actions/view';
import { useDispatch } from 'react-redux';
import { journalsAPI } from '../../../../api/finance/journal/journalsAPI';

// constants
import { RoutePaths } from '../../../../routes/routes';
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  DEFAULT_TABLE_LIMIT,
} from '../../../../constants/global';

// components
import FilterBar from './FilterBar';
import AddViewJournalModal from '../AddViewJournalModal';
import { Divider } from 'antd';
import {
  RelatedPartyFilterValue,
  default as TemplateJournalsTable,
} from '../../../../components/Tables/TableTemplates/Finance/JournalsTable';

export type FilterKeys =
  | 'search'
  | 'filter-by-related-party'
  | 'filter-by-account'
  | 'filter-by-template-type'
  | 'date'
  | 'filter-by-type'
  | 'filter-by-currency';

interface IProps {
  updateTableTrigger?: any;
}

const JournalsTable = ({ updateTableTrigger }: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(1);
  const [hasAccountDetailsAccess] = useUserAccess([
    'Finance_Accounts_Details_View',
  ]);
  const historyPush = (path: any, state?: any) =>
    dispatch(changeView(() => history.push(path, state)));

  const [journalsFilter, setJournalsFilter] = useState<{
    relatedParty: RelatedPartyFilterValue | null;
    accountNumber: string;
    searchQuery: string;
    type: string;
    currency: string;
    journalSource: string;
    dateRange: [Moment | null, Moment | null];
  }>({
    relatedParty: null,
    accountNumber: '',
    searchQuery: '',
    type: '',
    currency: '',
    journalSource: '',
    dateRange: [null, null],
  });

  const [localUpdateTableTrigger, updateTable] = useState<any>();
  const [selectedJournal, setSelectedJournal] = useState<number | undefined>();

  const { response, loading } = useFetch(
    () =>
      journalsAPI.fetchJournals({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        relatedParty: journalsFilter.relatedParty
          ? journalsFilter.relatedParty.id
          : undefined,
        relatedPartyType: journalsFilter.relatedParty
          ? journalsFilter.relatedParty.type
          : undefined,
        accountQuery: journalsFilter.accountNumber || undefined,
        searchQuery: journalsFilter.searchQuery || undefined,
        financialTypeNameQuery: journalsFilter.type || undefined,
        currencyIsoCodeQuery: journalsFilter.currency || undefined,
        journalSource: journalsFilter.journalSource || undefined,
        fromDate: journalsFilter.dateRange[0]?.unix()
          ? journalsFilter.dateRange[0].unix()
          : undefined,
        toDate: journalsFilter.dateRange[1]?.unix()
          ? journalsFilter.dateRange[1].unix()
          : undefined,
      }),
    [current, journalsFilter, updateTableTrigger, localUpdateTableTrigger],
  );

  const handleFilterChange = (key: FilterKeys, value: any) => {
    if (current > 1) {
      setCurrent(1);
    }

    switch (key) {
      case 'search':
        setJournalsFilter((prevState) => ({
          ...prevState,
          searchQuery: value,
        }));
        break;

      case 'filter-by-account':
        setJournalsFilter((prevState) => ({
          ...prevState,
          accountNumber: value,
        }));
        break;

      case 'filter-by-related-party':
        setJournalsFilter((prevState) => ({
          ...prevState,
          relatedParty: value
            ? { id: value.id, name: value.name, type: value.type }
            : null,
        }));
        break;

      case 'filter-by-template-type':
        setJournalsFilter((prevState) => ({
          ...prevState,
          journalSource: value,
        }));
        break;

      case 'date': {
        setJournalsFilter((prevState) => ({
          ...prevState,
          dateRange: value
            ? [value[0].startOf('day'), value[1].endOf('day')]
            : [null, null],
        }));
        break;
      }
      case 'filter-by-type':
        setJournalsFilter((prevState) => ({ ...prevState, type: value }));
        break;

      case 'filter-by-currency':
        setJournalsFilter((prevState) => ({ ...prevState, currency: value }));
        break;
    }
  };

  const handleTableActions = (key: string, value: any) => {
    if (current > 1) {
      setCurrent(1);
    }

    switch (key) {
      case 'details':
        setSelectedJournal(value);
        break;

      case 'account-details':
        historyPush({
          pathname: RoutePaths.Finance_Account_Details,
          search: `?id=${value}`,
        });
        break;

      case 'filter-by-account':
        handleFilterChange('filter-by-account', value);
        break;

      case 'filter-by-client':
        handleFilterChange('filter-by-related-party', value);
        break;
    }
  };

  const handleModalClose = (shouldUpdateTable?: boolean) => {
    setSelectedJournal(undefined);

    shouldUpdateTable && updateTable({});
  };

  return (
    <>
      <FilterBar
        handleChange={debounce(handleFilterChange, AUTOCOMPLETE_DEBOUNCE_DELAY)}
      />
      <Divider />
      <TemplateJournalsTable
        canNavigateToBankAccount={hasAccountDetailsAccess}
        total={response?.total || 0}
        data={response?.data || []}
        current={current}
        loading={loading}
        onActionsClick={handleTableActions}
        onFilterChange={handleFilterChange}
        onPaginationChange={setCurrent}
        journalsFilter={{
          filterByRelatedPartyValue: journalsFilter.relatedParty,
          filterByAccountValue: journalsFilter.accountNumber,
          dateRangeValue: journalsFilter.dateRange,
        }}
      />
      <AddViewJournalModal
        journalId={selectedJournal}
        isVisible={!!selectedJournal}
        closeCallback={handleModalClose}
      />
    </>
  );
};

export default JournalsTable;
