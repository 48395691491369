import React, { useCallback, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import { approvalGroupsAPI } from 'api/approval/approvalManagement/approvalGroupsAPI';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { ClientApprovalGroup } from 'typings/approval/approvalGroups';
import { approvalGroupsAPIAdapter } from 'apiAdapters/approval/approvalGroupsAPIAdapter';

// components
import {
  default as TemplateClientApprovalGroupsTable,
  ActionKeys,
} from 'components/Tables/TableTemplates/ApprovalManagement/ClientApprovalGroupsTable';

interface IProps {
  updateTrigger: any;
  clientGroupId: number;
  onApprovalGroupSelect: (clientGroupId: string) => void;
}

const ApprovalGroupsTable = ({
  updateTrigger,
  clientGroupId,
  onApprovalGroupSelect,
}: IProps) => {
  const [page, setPage] = useState(1);

  const { response, loading } = useFetch(async () => {
    const response =
      await approvalGroupsAPI.fetchClientTransactionApprovalGroups(
        clientGroupId,
        {
          page,
          limit: DEFAULT_TABLE_LIMIT,
        },
      );

    return approvalGroupsAPIAdapter.formatApprovalGroupFromQueryToClientApprovalGroupModel(
      response,
    );
  }, [page, clientGroupId, updateTrigger]);

  const handleActionsClick = useCallback(
    (action: ActionKeys, record: ClientApprovalGroup) => {
      switch (action) {
        case 'view': {
          onApprovalGroupSelect(record.id);
        }
      }
    },
    [onApprovalGroupSelect],
  );

  return (
    <TemplateClientApprovalGroupsTable
      current={page}
      loading={loading}
      data={response?.data || []}
      total={response?.total || 0}
      onPaginationChange={setPage}
      onActionsClick={handleActionsClick}
    />
  );
};

export default ApprovalGroupsTable;
