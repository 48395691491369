import React, { useState } from 'react';

// helpers utils
import styled from 'styled-components';
import useRoute from '../hooks/useRoute';
import withPrivate from '../hoc/withPrivate';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useSidebarMenu from '../hooks/useSidebarMenu';
import withEnabledFido from '../hoc/withEnabledFido';
import UploadDocumentsService from '../modules/Services/UploadService';
import { useLocation } from 'react-router-dom';
import { indexRoutes } from '../routes/routes';
import { RouteLayoutPropsModel } from '../typings/routes';

// components
import Footer from '../modules/Layout/Footer';
import Header from '../modules/Layout/Header';
import Sidebar from '../modules/Layout/Navigation/Sidebar';
import SidebarForMobile from '../modules/Layout/SidebarForMobile';
import { Col, Row } from 'antd';

type IProps = RouteLayoutPropsModel;

const MainLayout = ({ children, route }: IProps) => {
  const location = useLocation();
  const menuItems = useSidebarMenu(
    location.pathname,
    indexRoutes,
    route.layoutProps?.showAllRoutesInLeftMenu,
  );
  const breakpoint = useBreakpoint();
  const { currentRoute } = useRoute(indexRoutes);
  const [drawerStatus, setDrawerStatus] = useState(false);
  const shouldRenderSidebar = !currentRoute?.fullPageContent && !!breakpoint.lg;

  return (
    <MainLayoutWrapper>
      <Header
        openDrawerCallback={() => setDrawerStatus(true)}
        showMenuButton={!shouldRenderSidebar}
      />
      <ContentWrapper justify="space-between">
        {shouldRenderSidebar && (
          <Col flex="1 1" xs={24} sm={24} md={5}>
            <Sidebar menuItems={menuItems} />
          </Col>
        )}
        <Col flex="1 0" xs={24} sm={24} md={shouldRenderSidebar ? 19 : 24}>
          <ContentBodyWrapper hasLeftBar={shouldRenderSidebar}>
            {children}
          </ContentBodyWrapper>
        </Col>
      </ContentWrapper>
      {!shouldRenderSidebar && (
        <SidebarForMobile
          isOpen={drawerStatus}
          closeCallback={() => setDrawerStatus(false)}
        />
      )}
      <Footer />
      <UploadDocumentsService />
    </MainLayoutWrapper>
  );
};

const MainLayoutWrapper = styled.div`
  background: ${({ theme }) => theme.backgroundColor};
`;

const ContentWrapper = styled(Row)`
  box-sizing: border-box;
  margin-top: ${({ theme }) => theme.headerHeight};
  min-height: calc(
    100vh - ${({ theme }) => theme.headerHeight} -
      ${({ theme }) => theme.footerHeight} -
      ${({ theme }) => theme.footerMargin}
  );

  @media screen and (max-width: ${({ theme }) => theme.secondHeaderScreen}) {
    margin-top: ${({ theme }) => theme.headerHeightTablet} !important;

    min-height: calc(
      100vh - ${({ theme }) => theme.headerHeightTablet} -
        ${({ theme }) => theme.footerHeight} -
        ${({ theme }) => theme.footerMargin}
    ) !important;
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletScreen}) {
    margin-top: ${({ theme }) => theme.headerHeightMobile} !important;

    min-height: calc(
      100vh - ${({ theme }) => theme.headerHeightMobile} -
        ${({ theme }) => theme.footerHeight} -
        ${({ theme }) => theme.footerMargin}
    ) !important;
  }
`;

const ContentBodyWrapper = styled.div<{ hasLeftBar: boolean }>`
  padding: 0 ${({ theme }) => theme.containerPadding};
  margin-bottom: ${({ theme }) => theme.marginXl};

  @media screen and (max-width: ${({ theme }) => theme.tabletScreen}) {
    padding: ${({ theme }) => theme.paddingMd};
  }
`;

export default withPrivate()(withEnabledFido()(MainLayout));
