import APIConfig from '../../../config/api';
import { APIService } from 'api/axiosInstance';
import { GenderOptions } from 'enums/crm/crm';
import { FetchReviewDetailsModel } from '../clientGroup/clientGroupsAPI';
import { IAddress, ISocialMediaModel } from 'typings/crm/entity';
import {
  ContactEmail,
  ContactPhoneNumber,
  IContactModel,
} from 'typings/crm/contact';

export enum ChangeRequestStatuses {
  InProgress = 'in-progress',
  InReview = 'in-review',
}

export type ChangeRequestFromQuery = {
  _id: string;
  type: 'contact-get-kyc' | 'contact-update-kyc';
  status: ChangeRequestStatuses;
  entityId: string;
  verification: {
    review: {
      isValid: boolean;
      comment: string;
    } | null;

    complianceWorkflow: {
      id: string;
      startedAt: string;
    } | null;
  } | null;
  changes: {
    contactKYCChange: {
      general: {
        firstName: string;
        middleName: string;
        lastName: string;
        gender: GenderOptions | null;
        countryOfBirth: string;
        dateOfBirth: string;
        socialMedia: ISocialMediaModel[];
        phoneNumbers: ContactPhoneNumber[];
        emails: ContactEmail[];
      } | null;

      identification: {
        isPEP: boolean;
        pepInformation: string;
        isRegulated: boolean;
        regulationCountry: string[];
        nationality: string[];
        addresses: IAddress[];
        passports: {
          country: string;
          expirationDate: string;
          issuedAt: string;
          number: string;
        }[];
      } | null;

      sow: {
        sourceOfWealthDescription: string;
        sourceOfWealthCategories: string[];
        employmentInformation: {
          isSelfEmployed: boolean | null;
          employerName: string;
          occupation: string;
          natureOfBusiness: string;
          isBussinessUnderOwnName: boolean | null;
        };
      } | null;
    };
  } | null;
  changedSteps: {
    sow?: boolean;
    general?: boolean;
    verification?: boolean;
    identification?: boolean;
  };
};

export interface FetchContactWithChangeRequestResponse {
  contact: IContactModel;
  clientChangeRequest: ChangeRequestFromQuery | null;
}

interface RequestChangeRequestUpdateBody {
  isValid: boolean;
  comment: string;
}

const changeRequestsAPI = {
  fetchContactChangeRequest: (contactId: string) => {
    return APIService.get<FetchContactWithChangeRequestResponse>(
      `${APIConfig.crmApi}/client-change-requests/kyc-contact/${contactId}/active`,
    ).then(({ data }) => data);
  },

  requestChangeRequestUpdate: (
    changeRequestId: string,
    data: RequestChangeRequestUpdateBody,
  ) => {
    return APIService.post(
      `${APIConfig.crmApi}/client-change-requests/${changeRequestId}/review`,
      data,
    );
  },

  deleteUpdateChangeRequestComment: (changeRequestId: string) => {
    return APIService.post(
      `${APIConfig.crmApi}/client-change-requests/${changeRequestId}/review`,
      {
        isValid: true,
        comment: '',
      },
    );
  },

  fetchChangeRequestReviewResults: (changeRequestId: string) => {
    return APIService.get<FetchReviewDetailsModel>(
      `${APIConfig.crmApi}/client-change-requests/${changeRequestId}/review-results`,
    ).then(({ data }) => data.data);
  },

  requireAdditionalInfoForChangeRequest: (changeRequestId: string) => {
    return APIService.post<FetchReviewDetailsModel>(
      `${APIConfig.crmApi}/client-change-requests/${changeRequestId}/require-additional-information`,
    ).then(({ data }) => data.data);
  },

  startApprovalWorkflowForChangeRequest: (changeRequestId: string) => {
    return APIService.post(
      `${APIConfig.crmApi}/client-change-requests/${changeRequestId}/start-workflow`,
    );
  },
};

export { changeRequestsAPI };
