import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { useFormikContext } from 'formik';
import {
  TransactionReviewStatuses,
  TransactionTypes,
} from '../../../../../enums/banking/transactions';

// components
import TransactionStatus from '../../../../Typography/TransactionStatus';
import IncomingTransactionForm, {
  FormValuesModel as IncomingTransactionFormValues,
} from '../IncomingTransactionForm';
import OutgoingTransactionForm, {
  FormValuesModel as OutgoingTransactionFormValues,
} from '../OutgoingTransactionForm';
import { Col, Row, Typography } from 'antd';

export type FormValuesModel = {
  submitAction: 'submit' | 'save' | 'transmit' | null;
  status?: TransactionReviewStatuses;
  transactionId: number | null;
  transactionType: TransactionTypes;
  incomingTransaction: IncomingTransactionFormValues | null;
  outgoingTransaction: OutgoingTransactionFormValues | null;
};

const TransactionForm = memo(() => {
  const { t } = useTranslation('banking');
  const { values } = useFormikContext<FormValuesModel>();

  const formComponent = useMemo(() => {
    switch (values.transactionType) {
      case TransactionTypes.Incoming:
        return (
          <IncomingTransactionForm
            formName="incomingTransaction"
            transactionId={values.transactionId}
          />
        );

      case TransactionTypes.Outgoing:
        return <OutgoingTransactionForm formName="outgoingTransaction" />;

      default:
        return null;
    }
  }, [values.transactionType, values.transactionId]);

  return (
    <>
      {values.status && (
        <StyledRow gutter={[16, 16]}>
          <Col span={4}>
            <Typography.Text>
              {t('banking.transactions.add_transaction.status')}
            </Typography.Text>
          </Col>
          <Col span={20}>
            <TransactionStatus status={values.status} />
          </Col>
        </StyledRow>
      )}
      {formComponent}
    </>
  );
});

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default TransactionForm;
