import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ForgotUsernameRequestStatuses } from 'components/Additional/Statuses/ForgotUsernameRequestStatus';

// components
import Select, { SelectOption } from '@core_components/Select';

interface IProps {
  onChange: (value?: ForgotUsernameRequestStatuses) => void;
}

const ForgotUsernameStatusSelect = memo(({ onChange, ...rest }: IProps) => {
  const { t } = useTranslation('orac');

  const options = useMemo<SelectOption[]>(() => {
    return [
      {
        id: ForgotUsernameRequestStatuses.Pending,
        label: t(
          `forgot_username_requests.statuses.${ForgotUsernameRequestStatuses.Pending}`,
        ),
      },
      {
        id: ForgotUsernameRequestStatuses.InProgress,
        label: t(
          `forgot_username_requests.statuses.${ForgotUsernameRequestStatuses.InProgress}`,
        ),
      },
      {
        id: ForgotUsernameRequestStatuses.Completed,
        label: t(
          `forgot_username_requests.statuses.${ForgotUsernameRequestStatuses.Completed}`,
        ),
      },
    ];
  }, []);

  return (
    <Select
      allowClear
      size="large"
      options={options}
      onChange={(value) => onChange(value as ForgotUsernameRequestStatuses)}
      {...rest}
    />
  );
});

export default ForgotUsernameStatusSelect;
