import moment, { MomentInput } from 'moment';
import { Relationship } from 'typings/crm/relationships-graph';
import { tryToParseJson } from './utils';
import { ShortDocumentModel } from 'typings/crm/relationships';
import { IAdditionalFieldAnswer } from 'typings/crm/entity';
import {
  AdditionalFieldFormItemModel,
  IDocumentForm,
} from 'components/Forms/FormComponents/AdditionalFieldNew';
import {
  IAdditionalField,
  IRelationshipTemplateModel,
} from 'typings/crm/relationship-template';
import { AdditionalFieldType, RelationshipRelatedTypes } from 'enums/crm/crm';

export interface IAdditionalFieldC extends IAdditionalField {
  _id: string;
}

const AdditionalFieldHelpers = {
  formatFromJSON: function (
    fieldType: AdditionalFieldType,
    value: string,
  ): any {
    const parsedValue = tryToParseJson(value);

    if (!parsedValue.success) {
      return parsedValue.value;
    }

    switch (fieldType) {
      case AdditionalFieldType.Date: {
        return parsedValue.value
          ? moment(parsedValue.value as MomentInput)
          : undefined;
      }

      case AdditionalFieldType.Form:
      case AdditionalFieldType.Document: {
        let result: ShortDocumentModel[] = [];

        if (
          parsedValue.value &&
          Array.isArray(parsedValue.value) &&
          parsedValue.value.length
        ) {
          result = parsedValue.value.map((e: any) => ({
            id: e.id,
            name: e.files[0].name,
            fileId: e.files[0].id,
            file: e.files[0],
          }));
        }

        return result;
      }

      default:
        return parsedValue.value;
    }
  },

  formatToJSON: function (fieldType: AdditionalFieldType, value: any) {
    if (value === undefined || value === '') {
      return '';
    }

    let result = '';

    switch (fieldType) {
      case AdditionalFieldType.Document:
      case AdditionalFieldType.Form:
        {
          if (value?.length) {
            const documentIds = value.map(({ id }: ShortDocumentModel) => id);
            result = JSON.stringify(documentIds);
          }
        }
        break;

      default: {
        result = JSON.stringify(value);
      }
    }

    return result;
  },

  // This method is used to map the additional field from the template to form field
  // The reason why we should do such conditions with data is because for now
  // We don't have all the information about options in our relationshipAdditionalField
  // So if we want to map removed additional field we put templateAdditionalField as a null
  // and we use all data from relationshipAdditionalField for name, type, and value
  // For options, _id and related fields we predefine a default value of related type
  mapAdditionalFieldFromTemplateToFormField: function (
    templateAdditionalField: IAdditionalFieldC | null,
    relationshipAdditionalField?: IAdditionalFieldAnswer,
  ) {
    return {
      name:
        templateAdditionalField?.name ||
        relationshipAdditionalField?.name ||
        '',
      type:
        (templateAdditionalField?.type as AdditionalFieldType) ||
        (relationshipAdditionalField?.type as AdditionalFieldType),
      value: relationshipAdditionalField
        ? AdditionalFieldHelpers.formatFromJSON(
            relationshipAdditionalField.type,
            relationshipAdditionalField.valueJSON,
          )
        : undefined,

      relatedTo: templateAdditionalField
        ? (templateAdditionalField.relatedTo as RelationshipRelatedTypes)
        : 'any',

      options: {
        onboardingRequired: templateAdditionalField
          ? templateAdditionalField.options.onboardingRequired
          : false,
        isForRegulatedOnly: templateAdditionalField
          ? templateAdditionalField.options.isForRegulatedOnly
          : false,
        formDocument: templateAdditionalField
          ? templateAdditionalField.options.formDocument
          : null,
        documentRequiresCertification: templateAdditionalField
          ? templateAdditionalField.options.documentRequiresCertification
          : false,
        selectOptions:
          templateAdditionalField &&
          templateAdditionalField.options.selectOptions.length
            ? templateAdditionalField.options.selectOptions.map((option) =>
                JSON.parse(option.valueJSON),
              )
            : [],
      },
      isRemoved: !templateAdditionalField,
      _id: templateAdditionalField ? templateAdditionalField._id : '',
    };
  },

  filterAndMapAdditionalFieldsFromTemplateToFormModel: function (
    relationshipTemplate: IRelationshipTemplateModel,
    nodeType: string,
    relationshipAdditionalFields: IAdditionalFieldAnswer[],
  ) {
    const relationshipAdditionalFieldsCopy: IAdditionalFieldAnswer[] = [
      ...relationshipAdditionalFields,
    ];
    const result = relationshipTemplate.additionalFields.reduce<any[]>(
      (acc, next) => {
        if (next.relatedTo === 'any' || next.relatedTo === nodeType) {
          const predefinedAdditionalFieldIndex =
            relationshipAdditionalFieldsCopy.findIndex(
              (e) => e._id === next._id,
            );
          // Map existed additional fields
          acc.push(
            this.mapAdditionalFieldFromTemplateToFormField(
              next as any,
              relationshipAdditionalFieldsCopy[predefinedAdditionalFieldIndex],
            ),
          );
          if (predefinedAdditionalFieldIndex !== -1) {
            relationshipAdditionalFieldsCopy.splice(
              predefinedAdditionalFieldIndex,
              1,
            );
          }
        }

        return acc;
      },
      [],
    );
    // Map removed additional fields
    const removedRelationshipFields = relationshipAdditionalFieldsCopy.map(
      (e) => this.mapAdditionalFieldFromTemplateToFormField(null, e),
    );
    result.push(...removedRelationshipFields);
    return result;
  },

  mapClientAdditionalFieldFromTemplateToFormField: function (
    templateAdditionalField: {
      name: string;
      type: AdditionalFieldType;
      valueJSON: string;
      relatedTo: RelationshipRelatedTypes;
      options: {
        onboardingRequired: boolean;
        isForRegulatedOnly: boolean;
        formDocument: IDocumentForm | undefined;
        documentRequiresCertification: boolean;
        selectOptions: {
          valueJSON: string;
          type: AdditionalFieldType;
        }[];
      };
      id: string;
    },
    isRemoved?: boolean,
  ): AdditionalFieldFormItemModel {
    return {
      name: templateAdditionalField.name,
      type: templateAdditionalField.type,
      value: AdditionalFieldHelpers.formatFromJSON(
        templateAdditionalField.type,
        templateAdditionalField.valueJSON,
      ),
      relatedTo: templateAdditionalField.relatedTo,
      options: {
        ...templateAdditionalField.options,
        formDocument: templateAdditionalField.options.formDocument
          ? templateAdditionalField.options.formDocument
          : null,
        selectOptions: templateAdditionalField.options.selectOptions.map(
          (option) => JSON.parse(option.valueJSON),
        ),
      },
      isRemoved: !!isRemoved,
      id: templateAdditionalField.id,
    };
  },

  filterAndMapClientAdditionalFieldsFromTemplateToFormModel: function (
    relationshipTemplate: IRelationshipTemplateModel,
    nodeType: string,
    relationship: Relationship,
  ) {
    const relationshipAdditionalFields = [...relationship.additionalFields];
    const result = relationshipTemplate.additionalFields.reduce<
      AdditionalFieldFormItemModel[]
    >((acc, next) => {
      if (next.relatedTo === 'any' || next.relatedTo === nodeType) {
        const predefinedAdditionalFieldIndex =
          relationshipAdditionalFields.findIndex((e) => e._id === next._id);
        const predefinedAdditionalField =
          relationshipAdditionalFields[predefinedAdditionalFieldIndex];
        // Map existed additional fields
        acc.push(
          this.mapClientAdditionalFieldFromTemplateToFormField({
            name: next.name,
            type: next.type,
            valueJSON: predefinedAdditionalField
              ? predefinedAdditionalField.valueJSON
              : '',
            relatedTo: next.relatedTo,
            options: {
              onboardingRequired: next.options.onboardingRequired,
              isForRegulatedOnly: next.options.isForRegulatedOnly,
              formDocument: next.options.formDocument,
              documentRequiresCertification:
                next.options.documentRequiresCertification,
              selectOptions: next.options.selectOptions,
            },
            id: next._id as string,
          }),
        );
        if (predefinedAdditionalFieldIndex !== -1) {
          relationshipAdditionalFields.splice(
            predefinedAdditionalFieldIndex,
            1,
          );
        }
      }

      return acc;
    }, []);
    // Map removed additional fields
    const removedRelationshipFields = relationshipAdditionalFields.map((e) =>
      this.mapClientAdditionalFieldFromTemplateToFormField(
        {
          name: e.name,
          type: e.type,
          valueJSON: e.valueJSON,
          relatedTo: RelationshipRelatedTypes.Any,
          options: {
            onboardingRequired: false,
            isForRegulatedOnly: false,
            formDocument: e.metadata?.formDocument,
            documentRequiresCertification:
              !!e.metadata?.documentRequiresCertification,
            selectOptions: e.metadata?.selectOptions || [],
          },
          id: e._id as string,
        },
        true,
      ),
    );
    result.push(...removedRelationshipFields);
    return result;
  },
};

export { AdditionalFieldHelpers };
