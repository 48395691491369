import React from 'react';

// helpers
import { Moment } from 'moment';
import { ReferenceObjectWithPropertiesModel } from '../../../../../typings/compliance/configurationRules';
import { NewConfigurationRuleValidationSchema } from '../../../../../validations/crm/compliance';
import {
  ComparisonOperators,
  ComplianceReviewTypes,
  ConfigurationRuleState,
  ExpectedDataTypes,
  ExpectedValueTypes,
  RiskLevelTypes,
} from '../../../../../enums/compliance/reviewConfiguration';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';

interface IProps extends RequiredPropsForModalDialogModel {
  title: string;
  initialFormValues?: FormValuesModel | null;
  loadingInitialData?: boolean;
  onSubmit: (values: FormValuesModel) => Promise<void>;
  mode: 'create' | 'update' | 'view';
}

export interface FormValuesModel {
  reviewType: ComplianceReviewTypes | null;
  riskLevel: RiskLevelTypes | null;
  referenceObject: string | null;
  referenceProperty: string | null;
  expectedDataType: ExpectedDataTypes | null;
  comparisonOperator: ComparisonOperators | null;
  expectedValueType: ExpectedValueTypes | null;
  // There are many type of fields (from [ExpectedDataTypes] enum)
  expectedValue: string | number | Moment | string[] | undefined;
  isManualReview: boolean | null;
  state: ConfigurationRuleState | null;

  isEditMode?: boolean;
  initialReferenceObject?: ReferenceObjectWithPropertiesModel;
}

const ReviewConfigurationRuleModal = ({
  title,
  mode,
  isVisible,
  closeCallback,
  loadingInitialData,
  initialFormValues = null,
  onSubmit,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      disabled={mode === 'view'}
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialFormValues : null}
      confirmExitWithoutSaving
      validationSchema={NewConfigurationRuleValidationSchema}
      renderForm={
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          hideFooterButtons={mode === 'view'}
        >
          <LoadingWrapper loading={!!loadingInitialData}>
            {!!initialFormValues && <InnerForm />}
          </LoadingWrapper>
        </ModalDialog>
      }
    />
  );
};

export default ReviewConfigurationRuleModal;
