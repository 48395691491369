import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { ISession } from '../../../../../typings/profile/security';
import { DateHelpers } from '@helpers/date';
import { SessionHelpers } from '@helpers/sessions';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { DesktopOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<ISession> {
  onDelete: (session: ISession) => void;
}

const CurrentSessionsTable = memo(({ onDelete, ...rest }: IProps) => {
  const { t } = useTranslation('profile');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('sign_in_and_security.sessions.table.date'),
        key: 'signInDate',
        render: (record: ISession) =>
          DateHelpers.formatDateToString(record.createdAt, 'llll'),
      },

      {
        title: t('sign_in_and_security.sessions.table.device'),
        key: 'device',
        render: (record: ISession) => (
          <Typography.Text>
            <StyledDesktopIcon />
            {SessionHelpers.getDeviceName(record.device)}
          </Typography.Text>
        ),
      },

      {
        title: t('sign_in_and_security.sessions.table.session_expiration'),
        key: 'sessionExpiration',
        render: (record: ISession) =>
          DateHelpers.formatDateToString(record.sessionExpiration, 'llll'),
      },

      {
        key: 'delete',
        render: (record: ISession) => (
          <Button danger onClick={() => onDelete(record)}>
            {t('sign_in_and_security.sessions.stop_session')}
          </Button>
        ),
      },
    ];

    return result;
  }, [t, onDelete]);

  return <Table columns={columns} {...rest} />;
});

const StyledDesktopIcon = styled(DesktopOutlined)`
  margin-right: 10px;
`;

export default CurrentSessionsTable;
