import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { ApprovalWorkflowInstanceModel } from '../../../../../typings/approval/approvalWorkflow';

// components
import LoadingWrapper from '../../../../WrapperComponents/LoadingWrapper';
import ApprovalsMatrix from '../../../../Additional/ApprovalWorkflow/ApprovalsMatrix';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  data: ApprovalWorkflowInstanceModel | null;
  loading: boolean;
}

const ApprovalMatrixModal = ({
  data,
  loading,
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('compliance');

  return (
    <ModalDialog
      hideFooterButtons
      title={t('approval_matrix_modal.title')}
      isVisible={isVisible}
      closeCallback={closeCallback}
    >
      <LoadingWrapper loading={loading}>
        <ApprovalsMatrix
          approvalProgressStateSets={data?.approvalProgressStateSets || []}
        />
      </LoadingWrapper>
    </ModalDialog>
  );
};

export default ApprovalMatrixModal;
