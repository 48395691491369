import jwt_decode from 'jwt-decode';
import { JWTModel } from 'typings/profile/security';

const AuthHelpers = {
  isWebAuthnSupported: function () {
    return typeof window.PublicKeyCredential !== 'undefined';
  },

  isSafariBrowser: function () {
    const userAgent = (navigator?.userAgent ?? '').toLowerCase();
    const isSafari = /version\/(\d+).+?safari/.test(userAgent);
    return isSafari;
  },

  decodeJWTToken: function (token: string): JWTModel | null {
    if (!token) {
      return null;
    }

    try {
      return jwt_decode(token) as JWTModel;
    } catch {
      return null;
    }
  },

  isAccessTokenAlive: function (token: string) {
    const decodedToken = AuthHelpers.decodeJWTToken(token);
    return decodedToken ? decodedToken.exp * 1000 > Date.now() : false;
  },
};

export { AuthHelpers };
