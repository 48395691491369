import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { TransferTemplateTypes } from 'enums/finance/transferTemplates';
import { TransferTemplateStatuses } from 'enums/transfers/templates';

// components
import DetailsRow from 'modules/ApprovalManagement/ApprovalWorkflowTabs/ApprovalWorkflowDetailsModal/DetailsRow';
import TransferTemplateStatus from 'components/Additional/Statuses/TransferTemplateStatus';
import { Collapse, Typography } from 'antd';

export type UpdateTransferTemplateStatusData = {
  templateId: number;
  templateName: string;
  clientGroupId: number;
  old: { status: TransferTemplateStatuses; invalidityReason: string };
  new: { status: TransferTemplateStatuses; invalidityReason: string };
};

interface IProps {
  data: UpdateTransferTemplateStatusData;
}

const UpdateTransferTemplateStatusDetails = ({ data }: IProps) => {
  const { t } = useTranslation('approval_management');
  const history = useHistory();

  return (
    <Collapse
      defaultActiveKey={[
        'transfer_template_info',
        'old_version',
        'new_version',
      ]}
    >
      <Collapse.Panel
        key="transfer_template_info"
        header={t(
          'approval_workflow.approval_workflow_changes.update_transfer_template_status.title',
        )}
      >
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_transfer_template_status.name',
          )}
          value={
            <Typography.Link
              onClick={() =>
                history.push({
                  pathname: RoutePaths.CRM_Client_Group_Details,
                  search: `?id=${String(data.clientGroupId)}&tab=transferTemplates&templateId=${data.templateId}&templateType=${
                    TransferTemplateTypes.Wire
                  }`,
                })
              }
            >
              {data.templateName}
            </Typography.Link>
          }
        />
      </Collapse.Panel>
      <Collapse.Panel
        header={t('approval_workflow.approval_workflow_changes.old_version')}
        key="old_version"
      >
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_transfer_template_status.status',
          )}
          value={<TransferTemplateStatus status={data.old.status} />}
        />
        {data.old.invalidityReason && (
          <DetailsRow
            title={t(
              'approval_workflow.approval_workflow_changes.update_transfer_template_status.reason',
            )}
            value={data.old.invalidityReason}
          />
        )}
      </Collapse.Panel>
      <Collapse.Panel
        header={t('approval_workflow.approval_workflow_changes.new_version')}
        key="new_version"
      >
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_transfer_template_status.status',
          )}
          value={<TransferTemplateStatus status={data.new.status} />}
        />
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_transfer_template_status.reason',
          )}
          value={data.new.invalidityReason}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default UpdateTransferTemplateStatusDetails;
