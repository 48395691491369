import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { ClientStatus } from '../../../../enums/crm/crm';

// components
import StatusLabel from '../../../Typography/StatusLabel';

const STATUS_MAP = {
  info: [ClientStatus.Onboarding],
  warning: [ClientStatus.InReview],
  success: [ClientStatus.Approved],
  error: [ClientStatus.Rejected],
};

interface IProps {
  status: ClientStatus;
}

const ClientGroupStatus = memo(({ status }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(`client_groups.statuses.${status || 'none'}`)}
    />
  );
});

export default ClientGroupStatus;
