import * as yup from 'yup';
import { STRING_VALIDATION_RULE } from 'validations/common';

export const SendMessageValidationSchema = yup.object().shape({
  categoryId: STRING_VALIDATION_RULE.required('This field is required.'),
  subject: STRING_VALIDATION_RULE.required('This field is required.'),
  message: STRING_VALIDATION_RULE.required('This field is required.'),
  recipients: yup.array().min(1, 'Please add at least one recipient.'),
});

export const ReplyFormValidationSchema = yup.object().shape({
  message: STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'profile.messages.send_message.form_fields.message' },
  }),
  attachments: yup.array().notRequired(),
});
