import ApiConfig from '../config/api';
import { UploadStatus } from '../constants/documents';
import { SessionHelpers } from './sessions';
import { LocalStorageHelpers } from './storages/localStorage';

export interface IChunkHash {
  [key: string]: IChunkItem;
}

export interface IChunkItem {
  index: number;
  file: Blob;
  status: UploadStatus;
  uploadPercentage: number;
}

const DocumentHelpers = {
  formatFileSize: function (bytes: number, si: boolean) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    let u = -1;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  },

  // Get chunk size depends on file size
  calcChunkSize: function (size: number) {
    if (size >= 16777216) {
      // size 16mb - more, chunk size - 3mb
      return 3145728;
    } else {
      // size - 16mb, chunk size - 1mb
      return 1048576;
    }
  },

  // Generate chunks for file
  createFileChunk: function (
    fileData: File,
    chunkSize: number,
    fileId: string,
  ) {
    const fileChunkList: IChunkHash = {};
    let cur = 0;
    let index = 1;
    while (cur < fileData.size) {
      const next = cur + chunkSize;
      fileChunkList[`${fileId}-${index}`] = {
        index: index - 1,
        file: fileData.slice(cur, next),
        status: UploadStatus.WAIT,
        uploadPercentage: 0,
      };
      index += 1;
      cur = next;
    }
    return fileChunkList;
  },

  getDocumentLink: function (
    documentId: string,
    fileId: string,
    fingerprint: string,
  ): string {
    const token = LocalStorageHelpers.getAccessToken();
    return `${ApiConfig.dmsApi}/${documentId}/download/${fileId}?tokenb64=${btoa(`Bearer ${token}`)}&fingerprint=${fingerprint}`;
  },

  downloadDocument: async function (
    documentId = '',
    fileId = '',
    fileName = '',
    downloadLink = '',
  ) {
    const fingerprint = await SessionHelpers.generateFingerprint();
    const link = document.createElement('a');
    link.href =
      downloadLink ||
      DocumentHelpers.getDocumentLink(documentId, fileId, fingerprint);
    link.download = fileName || '';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  verifyFilesThatCanBeUploaded: function (files: File[]): {
    approved: File[];
    rejected: { file: File; reason: 'empty' | 'max_size' }[];
  } {
    return files.reduce(
      (
        acc: {
          approved: File[];
          rejected: { file: File; reason: 'empty' | 'max_size' }[];
        },
        next,
      ) => {
        if (next.size && next.size <= 52428800) {
          acc.approved.push(next);
        }

        if (!next.size) {
          acc.rejected.push({ file: next, reason: 'empty' });
        }
        if (next.size > 52428800) {
          acc.rejected.push({ file: next, reason: 'max_size' });
        }

        return acc;
      },
      { approved: [], rejected: [] },
    );
  },
};

export { DocumentHelpers };
