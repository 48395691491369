import moment, { Moment } from 'moment';
import { DEFAULT_DATE_FORMAT } from 'constants/global';

const DateHelpers = {
  formatDateToString: function (
    date: Moment | string | number | Date,
    toFormat = DEFAULT_DATE_FORMAT,
  ) {
    return moment(date).format(toFormat);
  },

  formatTimestampToMomentDate: function (timestamp: number) {
    return moment(timestamp * 1000);
  },

  formatTimestampToString: function (
    timestamp: number,
    format = DEFAULT_DATE_FORMAT,
  ) {
    if (!timestamp) {
      return '';
    }

    return DateHelpers.formatTimestampToMomentDate(timestamp).format(format);
  },

  formatTimestampToUTCString: function (
    timestamp: number,
    format = DEFAULT_DATE_FORMAT,
  ) {
    if (!timestamp) {
      return '';
    }

    return DateHelpers.formatTimestampToMomentDate(timestamp)
      .utc()
      .format(format);
  },

  formatDateToUTC: function (date: Moment | Date | string | number) {
    const newDate = typeof date === 'string' ? new Date(date) : date;
    return moment.utc(newDate);
  },

  formatDateToLocalString: function (
    date: Date | Moment | string | number,
    format: string,
  ) {
    return moment.utc(date).local().format(format);
  },
};

export { DateHelpers };
