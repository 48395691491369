import React from 'react';

// helpers
import { currenciesAPI } from '../../../../../api/finance/currency/currenciesAPI';
import { StyledComponentProps } from '../../../../../typings/common';
import { CurrencyFilterOptions } from '../../../../../enums/finance/finance';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../constants/global';

// components
import Autocomplete from '../../../../Fields/Autocomplete';

interface IProps extends StyledComponentProps {
  isAccount?: CurrencyFilterOptions;
  isExternal?: CurrencyFilterOptions;
  isActive?: 'all' | boolean;
  size?: string;
  onClear?: any;
  onSelect?: any;
  placeholder?: string;
  loadDataOnMount?: boolean;
  autoSelectIfOneOption?: boolean;
}

const CurrencySelect = ({
  isAccount = CurrencyFilterOptions.IsAccount,
  isExternal = CurrencyFilterOptions.AllAccounts,
  isActive = 'all',
  ...rest
}: IProps) => {
  const fetchData = async (searchText: string) => {
    const currenciesResponse = await currenciesAPI.searchCurrencies({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      isAccount,
      isExternal,
      isActive,
      currencyQuery: searchText,
    });

    return currenciesResponse.data.map((currency) => ({
      id: currency.id,
      content: currency.isoCode,
      model: currency,
    }));
  };
  return <Autocomplete {...rest} fetchData={fetchData} />;
};

export default CurrencySelect;
