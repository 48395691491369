import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { Moment } from 'moment';

// components
import DatePicker from '../../../../components/Forms/FormComponents/DatePicker';
import { Col, Row } from 'antd';

interface IProps {
  date: Moment;
  isDisabled: boolean;
  filterChange: (key: string, value: Moment | null) => void;
}

const FilterBar = ({ date, isDisabled, filterChange }: IProps) => {
  const { t } = useTranslation('reporting');

  const filterGridSizes = { xl: 6, lg: 10, md: 24, sm: 24, xs: 24 };

  const disabledDate = (date: Moment) => !date || date.isAfter(new Date());

  return (
    <Row gutter={[16, 16]}>
      <Col {...filterGridSizes}>
        <Row>
          <Col span={24}>{t('client_group_and_balances.start_date')}</Col>
          <Col span={24}>
            <StyledDatePicker
              size="large"
              value={date}
              disabled={isDisabled}
              disabledDate={disabledDate}
              onChange={(newDate: any) => filterChange('date', newDate)}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export default FilterBar;
