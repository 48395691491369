import React from 'react';

// helpers
import groupsAPI from '../../../../../../api/orac/groupsAPI';
import useTranslation from '../../../../../../hooks/useTranslation';
import { message } from 'antd';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  userId: string;
}

interface FormValuesModel {
  groupId: string | null;
}

const AddGroupToUserModal = ({ isVisible, closeCallback, userId }: IProps) => {
  const { t } = useTranslation('orac');

  const initialFormValues: FormValuesModel = { groupId: null };

  const onSubmit = async (values: FormValuesModel) => {
    const { groupId } = values;
    await groupsAPI.addUsersToGroup(groupId as string, [userId]);
    message.success(t('users.view.success_add_user_to_group'));
  };

  return (
    <Form<FormValuesModel>
      enableReinitialize
      initialValues={isVisible ? initialFormValues : null}
      onSubmit={onSubmit}
      renderForm={
        <ModalDialog
          width="500px"
          title={t('users.view.add_to_group')}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default AddGroupToUserModal;
