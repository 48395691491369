import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setTwoFactorVerificationData } from '../../../../redux/actions/auth';

// constants
import { RoutePaths } from '../../../../routes/routes';

// components
import { Typography } from 'antd';

// images
import RegisterSecurityKey from './RegisterSecurityKey';
import { ReactComponent as PrimaryLogo } from '../../../../resources/app-logos/primary-logo.svg';

const EnableFido = () => {
  const { t } = useTranslation(['auth', 'common']);
  const dispatch = useDispatch();
  const history = useHistory();

  const onSignOut = () => {
    dispatch(setTwoFactorVerificationData(null));
    history.push(RoutePaths.Auth_SignIn);
  };

  return (
    <Wrapper>
      <StyledLogo />
      <Typography.Title level={4}>
        {t('verification.fido_not_enabled.title')}
      </Typography.Title>
      <StyledText>{t('verification.fido_not_enabled.description')}</StyledText>

      <RegisterSecurityKey />

      <LinkWrapper>
        <Typography.Link onClick={onSignOut}>
          {t('signout', { ns: 'common' })}
        </Typography.Link>
      </LinkWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const StyledText = styled(Typography.Paragraph)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const LinkWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const StyledLogo = styled(PrimaryLogo)`
  width: ${({ theme }) => theme.headerLogoWidth};
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default EnableFido;
