import React, { memo } from 'react';

// helpers
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { StyledComponentProps } from '../../../../typings/common';

// components
import { Checkbox as AntdCheckbox } from 'antd';

export interface CheckboxProps extends StyledComponentProps {
  name?: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
}

const Checkbox = memo(
  ({ value, onChange, disabled, name, ...rest }: CheckboxProps) => {
    return (
      <AntdCheckbox
        name={name}
        checked={value}
        disabled={disabled}
        onChange={onChange}
        {...rest}
      />
    );
  },
);

export default Checkbox;
