import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../../../../hooks/useFetch';
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { noteAPI } from '../../../../../../../../api/note/noteAPI';
import { SortProps } from '@core_components/Table';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../../../constants/global';
import {
  NoteMessageType,
  NoteType,
} from '../../../../../../../../enums/crm/crm';

// components
import AddNote from './AddNote';
import ComplianceNotesTable from '../../../../../../../../components/Tables/TableTemplates/CRM/ComplianceNotesTable';
import { Typography, Divider } from 'antd';

interface IProps {
  associationId: string;
  associationType: NoteType;
}

const ComplianceNotes = ({ associationId, associationType }: IProps) => {
  const { t } = useTranslation('crm');
  const [current, setCurrent] = useState(1);
  const [hasNotesCreateAccess] = useUserAccess([
    'CRM_ClientGroup_Details_Compliance_Notes_Create',
  ]);
  const [updateTrigger, updateTable] = useState({});
  const [sortByCreatedAt, setSortByCreatedAt] = useState<
    'descend' | 'ascend' | null
  >(null);

  const { response, loading } = useFetch(
    () =>
      noteAPI.fetchNotes(associationId, {
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        type: NoteMessageType.Compliance,
        sortBy: sortByCreatedAt ? 'createdAt' : undefined,
        sortDirection: sortByCreatedAt
          ? sortByCreatedAt === 'ascend'
          : undefined,
      }),
    [associationId, updateTrigger, current, sortByCreatedAt],
  );

  const handleSortChange = (sort: SortProps) => {
    if (Array.isArray(sort) || sort.order == sortByCreatedAt) {
      return;
    }
    if (sort.order) {
      setSortByCreatedAt(sort.order);
    } else {
      if (sortByCreatedAt) {
        setSortByCreatedAt(null);
      }
    }
  };

  return (
    <>
      <SectionTitle level={4}>
        {t('client_group.compliance.notes')}
      </SectionTitle>
      <StyledDivider />
      {hasNotesCreateAccess && (
        <AddNote
          onAdd={() => updateTable({})}
          association={{ id: associationId, type: associationType }}
        />
      )}
      <ComplianceNotesTable
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={current}
        onSortChange={handleSortChange}
        onPaginationChange={setCurrent}
      />
    </>
  );
};

const SectionTitle = styled(Typography.Title)`
  margin-top: ${({ theme }) => theme.marginMd};
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.marginXs} 0;
`;

export default ComplianceNotes;
