import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { useSelector } from 'react-redux';
import { StateModel } from '../../../../redux/reducers';
import { documentsAPI } from '../../../../api/documents/documentsAPI';
import { IDocumentBase } from '../../../../typings/documents/documents';
import { DocumentAssociationTypes } from '../../../../enums/crm/crm';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';

// components
import FilterBar from './FilterBar';
import DocumentDetailsModal from '../DocumentsDetailsModal';
import { Divider } from 'antd';
import { default as TemplateDocumentsTable } from '../../../../components/Tables/TableTemplates/Documents/DocumentsTable';

interface IProps {
  associatedId?: string;
  showAssociation?: boolean;
}

const DocumentsTable = ({ associatedId, showAssociation = true }: IProps) => {
  const uploadStatus = useSelector<StateModel, boolean>(
    (state) => state.upload.isFinished,
  );

  // table
  const [current, setCurrentPage] = useState(1);
  const [updateTableTrigger, updateTable] = useState({});
  const [documentsFilters, setDocumentsFilters] = useState({
    search: '',
    filterByTags: '',
    associationType: '',
    associationItemId: '',
  });

  const [selectedDocumentId, setDocumentId] = useState('');

  const { response, loading } = useFetch(
    () =>
      documentsAPI.fetchDocuments({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        itemId: associatedId,
        search: documentsFilters.search || undefined,
        filterTags: documentsFilters.filterByTags || undefined,
        associationType: documentsFilters.associationType || undefined,
        associationItemId: documentsFilters.associationItemId || undefined,
      }),
    [current, updateTableTrigger, uploadStatus, documentsFilters],
  );

  const handleActionsChange = (key: string, document: IDocumentBase) => {
    switch (key) {
      case 'details':
        setDocumentId(document._id);
        break;
    }
  };

  const handleDetailsModalClose = (wasModified?: boolean) => {
    wasModified && updateTable({});
    setDocumentId('');
  };

  const handleFilter = (type: string, value: any) => {
    if (current > 1) {
      setCurrentPage(1);
    }

    switch (type) {
      case 'filter_by_tags': {
        setDocumentsFilters((prevState) => ({
          ...prevState,
          filterByTags: value.toString(),
        }));
        break;
      }
      case 'filter_by_name': {
        setDocumentsFilters((prevState) => ({ ...prevState, search: value }));
        break;
      }

      case 'filter_by_association_id': {
        setDocumentsFilters((prevState) => ({
          ...prevState,
          associationItemId: value,
        }));
        break;
      }

      case 'filter_by_association_type': {
        setDocumentsFilters((prevState) => ({
          ...prevState,
          associationType: value,
        }));
        break;
      }
      default:
        return;
    }
  };

  return (
    <>
      <FilterBar
        onFilterChange={handleFilter}
        associationType={
          documentsFilters.associationType as DocumentAssociationTypes
        }
      />

      <Divider />

      <TemplateDocumentsTable
        total={response?.total || 0}
        data={response?.data || []}
        current={current}
        showAssociation={showAssociation}
        loading={loading}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsChange}
      />

      <DocumentDetailsModal
        documentId={selectedDocumentId}
        isVisible={!!selectedDocumentId}
        closeCallback={handleDetailsModalClose}
      />
    </>
  );
};

export default DocumentsTable;
