import React, { memo, useCallback, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { contactsAPI } from 'api/crm/contact/contactsAPI';
import { IContactModel } from 'typings/crm/contact';

// components
import Text from '@core_components/Text';
import Button from '@core_components/Button';
import SectionIntro from '@common_components/Texts/SectionIntro';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import DeleteConfirmation from 'components/Modals/DeleteConfirmation';
import InfoIconWithPopover from '@common_components/Icons/InfoIconWithPopover';
import { Col, Row, message } from 'antd';

interface IProps {
  contact: IContactModel;
}

const DeleteContact = memo(({ contact }: IProps) => {
  const history = useHistory();
  const { t } = useTranslation('crm');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const cannotDeleteReason = useMemo<React.ReactNode | null>(() => {
    if (
      !contact.hasRelationships &&
      !contact?.associations?.auth &&
      !contact.approval?.isApproved
    ) {
      return null;
    }

    return (
      <>
        <Text>{t('contacts.new.contact_cannot_be_deleted.description')}</Text>
        {contact.hasRelationships && (
          <Text>
            {t(
              'contacts.new.contact_cannot_be_deleted.has_active_relationships',
            )}
          </Text>
        )}
        {!!contact?.associations?.auth && (
          <Text>
            {t(
              'contacts.new.contact_cannot_be_deleted.has_association_with_online_user',
            )}
          </Text>
        )}
        {!!contact.approval?.isApproved && (
          <Text>
            {t(
              'contacts.new.contact_cannot_be_deleted.is_reviewed_and_approved',
            )}
          </Text>
        )}
      </>
    );
  }, [contact, t]);

  const deleteContact = useCallback(
    async (reason: string) => {
      await contactsAPI.deleteContactById(contact._id, reason);
      message.success(t('contacts.delete.success_delete_message'));
      history.push(RoutePaths.CRM_Contacts);
    },
    [contact],
  );

  return (
    <>
      <SectionIntro
        title={t('contacts.delete.section_title')}
        titleVariant="h4"
        description={
          <Row
            gutter={[16, 16]}
            align="top"
            wrap={false}
            justify="space-between"
          >
            <Col flex="auto">{t('contacts.delete.section_description')}</Col>
            <Col flex="150px">
              <DivAlignCenter>
                <StyledButton
                  danger
                  disabled={!!cannotDeleteReason}
                  onClick={() => setIsModalVisible(true)}
                >
                  {t('contacts.delete.delete_button')}
                </StyledButton>

                {!!cannotDeleteReason && (
                  <InfoIconWithPopover
                    placement="left"
                    popoverContent={cannotDeleteReason}
                  />
                )}
              </DivAlignCenter>
            </Col>
          </Row>
        }
      />
      <DeleteConfirmation
        isVisible={isModalVisible}
        onSubmitCallback={deleteContact}
        closeCallback={() => setIsModalVisible(false)}
      />
    </>
  );
});

const StyledButton = styled(Button)`
  width: 100%;
`;

export default DeleteContact;
