import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import { ClientStatus } from 'enums/crm/crm';

// components
import ViewRelationshipModal from '../ViewRelationshipModal';
import GraphChart, { GraphData, GraphNode } from 'components/Charts/GraphChart';

interface IProps {
  clientGroupStatus: ClientStatus;
  relationshipTree: GraphData | null;
}

const EntityRelationshipChart = ({
  relationshipTree,
  clientGroupStatus,
}: IProps) => {
  const [selectedParent, setSelectedParent] = useState<GraphNode>();
  const [isViewModalVisible, setViewModalVisible] = useState(false);

  const openViewModal = (node: GraphNode) => {
    setSelectedParent(node);
    setViewModalVisible(true);
  };

  const closeViewModal = () => {
    setViewModalVisible(false);
  };

  const formattedGraphData = useMemo<GraphData>(() => {
    const result: GraphData = { nodes: [], edges: [] };

    if (!relationshipTree) {
      return result;
    }

    result.nodes = relationshipTree.nodes.map((n) => {
      return {
        ...n,
        actions: {
          onClick: () => openViewModal(n),
        },
      };
    });

    result.edges = [...relationshipTree.edges];

    return result;
  }, [relationshipTree]);

  return (
    <>
      <TreeWrapper>
        {relationshipTree && (
          <GraphChart
            data={formattedGraphData}
            displayApplicantIcon={[
              ClientStatus.Onboarding,
              ClientStatus.InReview,
            ].includes(clientGroupStatus)}
          />
        )}
      </TreeWrapper>
      {selectedParent && (
        <ViewRelationshipModal
          isVisible={isViewModalVisible}
          parent={selectedParent}
          closeCallback={closeViewModal}
        />
      )}
    </>
  );
};

const TreeWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.tableBorderColor};
  min-height: 650px;
`;

export default EntityRelationshipChart;
