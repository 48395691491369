import React, { useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../hooks/useFetch';
import queryString from 'query-string';
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '../../hooks/useTranslation';
import { darkTheme } from '../../resources/theme/styled';
import { RoutePaths } from '../../routes/routes';
import { changeView } from '../../redux/actions/view';
import { useDispatch } from 'react-redux';
import { accountsAPI } from '../../api/finance/account/accountsAPI';
import { IAccountModel } from '../../typings/finance/account';
import { RouteComponentProps } from 'react-router-dom';
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';

// components
import Text from '../../components/DesignSystem/Core/Text';
import IconSVG from '../../components/DesignSystem/Core/IconSVG';
import AccountDetails from '../../modules/Finance/Accounts/AccountDetails';
import DivAlignCenter from '../../components/Additional/DivAlignCenter';
import AccountJournalEntriesTable from '../../modules/Finance/Accounts/AccountJournalEntriesTable';
import { Spin } from 'antd';
import { ReactComponent as InfoIcon } from '../../resources/icons/remix-icons/information-line.svg';

interface IProps extends PageLayoutHelperPropsModel, RouteComponentProps {}

const AccountDetailsPage = ({
  location,
  history,
  setHeaderOptions,
}: IProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('finance');
  const [accountId, setAccountId] = useState<string | null>(null);
  const [hasReconcileEntryAccess] = useUserAccess([
    'Finance_Accounts_Details_Reconcile_JournalEntry',
  ]);
  const [accountDetails, setAccountDetails] = useState<IAccountModel | null>(
    null,
  );
  const [updateAccountStatusTrigger, setUpdateAccountStatusTrigger] = useState(
    {},
  );
  const historyPush = (path: any, state?: any) =>
    dispatch(changeView(() => history.push(path, state)));

  useEffect(() => {
    const params: { id: string } | any = queryString.parse(location.search);

    if (!params || !params.id) {
      historyPush(RoutePaths.UserRoutes_Root);
    }

    setAccountId(params.id);
  }, [location]);

  const { response: getAccountResponse, loading: getAccountLoader } = useFetch(
    () => (accountId ? accountsAPI.fetchAccountByNumber(accountId) : null),
    [accountId],
  );

  const { response: accountStatusResponse, loading: accountStatusLoader } =
    useFetch(
      () =>
        accountId
          ? accountsAPI.fetchAccountActiveWorkflowStatus(accountId)
          : null,
      [accountId, updateAccountStatusTrigger],
    );

  useEffect(() => {
    setAccountDetails(getAccountResponse);
    setHeaderOptions({
      title: `${t('accounts.account_details_title', { ns: 'finance' })}: ${getAccountResponse?.accountName || ''}`,
    });
  }, [getAccountResponse]);

  const canRenderJournalEntriesTable = Boolean(accountId && accountDetails);

  return (
    <Spin spinning={getAccountLoader || accountStatusLoader}>
      {accountStatusResponse ? (
        <StyledWarningWrapper>
          <StyledIconSVG
            component={InfoIcon}
            color={darkTheme.warningColor}
            size="middle"
          />
          <Text color={darkTheme.warningColor} variant="h5">
            {t('accounts.account_details.account_is_pending_review_warning')}
          </Text>
        </StyledWarningWrapper>
      ) : null}

      <AccountDetails account={accountDetails} />

      {canRenderJournalEntriesTable && (
        <AccountJournalEntriesTable
          accountId={accountId || ''}
          isDebitStatus={accountDetails?.isDebit || false}
          onJournalsUpdate={() => setUpdateAccountStatusTrigger({})}
          canUpdateReconciliationStatus={
            !accountStatusResponse &&
            hasReconcileEntryAccess &&
            !!accountDetails?.isReconciled
          }
        />
      )}
    </Spin>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

const StyledWarningWrapper = styled(DivAlignCenter)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

export default AccountDetailsPage;
