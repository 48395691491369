import moment from 'moment';
import { IClientGroupUser } from 'typings/crm/client-group';
import { FetchResponseModel } from 'typings/common';
import { ClientGroupUserStatuses } from 'enums/crm/crm';

const clientGroupsAdapter = {
  fetchClientGroupUsers: (
    data: FetchResponseModel<IClientGroupUser>,
  ): FetchResponseModel<IClientGroupUser> => {
    const updatedData = data.data.map((e) => ({
      ...e,
      status:
        e.status &&
        e.status.name === ClientGroupUserStatuses.Invited &&
        moment().utc().isAfter(moment(e.status.ttl))
          ? {
              name: ClientGroupUserStatuses.InvitationExpired,
              ttl: e.status.ttl,
            }
          : e.status,
    }));

    return { data: updatedData, total: data.total };
  },
};

export { clientGroupsAdapter };
