import React, { useEffect, useState } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';
import { ExpectedValueTypes } from '../../../../../../enums/compliance/reviewConfiguration';
import {
  ReferenceObjectWithPropertiesModel,
  ReferencePropertyModel,
} from '../../../../../../typings/compliance/configurationRules';

// components
import FormField from '@core_components/FormField';
import YesNoSelect from '../../../../../Forms/FormComponents/SelectInputs/YesNoSelect';
import RiskLevelSelect from '../../../../../Forms/FormComponents/SelectInputs/Compliance/RiskLevelSelect';
import ReviewTypeSelect from '../../../../../Forms/FormComponents/SelectInputs/Compliance/ReviewTypeSelect';
import EsxpectedValueField from './ExpectedValueField';
import ExpectedDataTypeSelect from '../../../../../Forms/FormComponents/SelectInputs/Compliance/ExpectedDataTypeSelect';
import ExpectedValueTypeSelect from '../../../../../Forms/FormComponents/SelectInputs/Compliance/ExpectedValueTypeSelect';
import ComparisonOperatorSelect from '../../../../../Forms/FormComponents/SelectInputs/Compliance/ComparisonOperatorSelect';
import ReferenceObjectsAutocomlete from '../../../../../Forms/FormComponents/Autocompletes/Compliance/ReferenceObjectsAutocomplete';
import ConfigurationRuleStateSelect from '../../../../../Forms/FormComponents/SelectInputs/Compliance/ConfigurationRuleStateSelect';
import ReferenceObjectPropertySelect from '../../../../../Forms/FormComponents/SelectInputs/Compliance/ReferenceObjectPropertySelect';

const InnerForm = () => {
  const { t } = useTranslation(['compliance', 'common']);
  const { values, setValues, setFieldValue } =
    useFormikContext<FormValuesModel>();
  const [referenceProperties, setReferenceProperties] = useState<
    ReferencePropertyModel[]
  >([]);
  const [dataOptions, setDataOptions] = useState<string[] | null>(null);

  useEffect(() => {
    if (values.initialReferenceObject) {
      const { initialReferenceObject, referenceProperty } = values;
      const initialDataOptions =
        initialReferenceObject.properties.find(
          (e) => e.name === referenceProperty,
        )?.dataOptions || null;
      setDataOptions(initialDataOptions);
      setReferenceProperties(initialReferenceObject.properties);
    }
  }, [values.initialReferenceObject]);

  const handleReferenceObjectSelect = (
    newId: string,
    newReferenceObject: ReferenceObjectWithPropertiesModel,
  ) => {
    if (
      !newReferenceObject ||
      newReferenceObject.id === values.referenceObject
    ) {
      return;
    }

    setReferenceProperties(newReferenceObject.properties);

    setValues({
      ...values,
      referenceObject: newId,
      referenceProperty: null,
      expectedDataType: null,
      comparisonOperator: null,
      expectedValue: undefined,
    });
  };

  const handleReferencePropertySelect = (
    newPropertyId: string,
    { model: referenceProperty }: { model: ReferencePropertyModel },
  ) => {
    if (!referenceProperty) {
      return;
    }

    setDataOptions(referenceProperty?.dataOptions || null);

    setValues({
      ...values,
      referenceProperty: newPropertyId,
      expectedDataType: referenceProperty.dataType,
      comparisonOperator: null,
      expectedValue: undefined,
    });
  };

  const cleanExpectedValueField = () => {
    setFieldValue('expectedValue', undefined);
  };

  const renderExpectedValueField = () => {
    if (values.expectedValueType === null) {
      return null;
    }

    switch (values.expectedValueType) {
      case ExpectedValueTypes.ReferenceObjectProperty:
        return (
          <FormField
            disabled={!values.referenceObject}
            name="expectedValue"
            label={t('review_configuration.reference_property_name')}
            component={ReferenceObjectPropertySelect}
            additionalProps={{
              allowClear: true,
              objectProperties: [...referenceProperties],
            }}
          />
        );

      case ExpectedValueTypes.CustomValue:
        return (
          <FormField
            disabled={
              values.expectedDataType === null ||
              values.comparisonOperator === null
            }
            name="expectedValue"
            label={t('review_configuration.expected_value')}
            component={EsxpectedValueField}
            additionalProps={{
              dataType: values.expectedDataType,
              dataOptions: dataOptions || [],
              comparisonOperator: values.comparisonOperator,
            }}
          />
        );
    }
  };

  return (
    <>
      <FormField
        name="reviewType"
        label={t('review_configuration.review_type')}
        component={ReviewTypeSelect}
        disabled={values.isEditMode}
        additionalProps={{ allowClear: true }}
      />

      <FormField
        name="riskLevel"
        label={t('review_configuration.risk_level')}
        component={RiskLevelSelect}
        disabled={values.isEditMode}
        additionalProps={{ allowClear: true }}
      />

      <FormField
        name="referenceObject"
        label={t('review_configuration.reference_object')}
        disabled={values.isEditMode}
        component={ReferenceObjectsAutocomlete}
        additionalProps={{
          onSelect: handleReferenceObjectSelect,
          initialValue: values.initialReferenceObject && {
            id: values.initialReferenceObject.id,
            content: values.initialReferenceObject.name,
          },
        }}
      />

      <FormField
        disabled={!values.referenceObject || values.isEditMode}
        name="referenceProperty"
        label={t('review_configuration.reference_property_name')}
        component={ReferenceObjectPropertySelect}
        additionalProps={{
          allowClear: true,
          objectProperties: [...referenceProperties],
          onSelect: handleReferencePropertySelect,
        }}
      />

      <FormField
        disabled
        name="expectedDataType"
        label={t('review_configuration.expected_data_type')}
        component={ExpectedDataTypeSelect}
        additionalProps={{ allowClear: true }}
      />

      <FormField
        disabled={values.expectedDataType === null}
        name="comparisonOperator"
        label={t('review_configuration.comparison_operator')}
        component={ComparisonOperatorSelect}
        additionalProps={{
          allowClear: true,
          dataType: values.expectedDataType,
          onSelect: cleanExpectedValueField,
        }}
      />

      <FormField
        disabled={values.expectedDataType === null}
        name="expectedValueType"
        label={t('review_configuration.expected_value_type')}
        component={ExpectedValueTypeSelect}
        additionalProps={{
          allowClear: true,
          onSelect: cleanExpectedValueField,
        }}
      />

      {renderExpectedValueField()}

      <FormField
        name="isManualReview"
        label={t('review_configuration.is_manual_review')}
        component={YesNoSelect}
        additionalProps={{
          allowClear: true,
          yesText: t('yes', { ns: 'common' }),
          noText: t('no', { ns: 'common' }),
        }}
        placeholder={t('select_option', { ns: 'common' })}
      />

      <FormField
        name="state"
        label={t('review_configuration.state')}
        component={ConfigurationRuleStateSelect}
        additionalProps={{ allowClear: true }}
      />
    </>
  );
};

export default InnerForm;
