import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { AllYesNoEnum } from '../../../../enums/common';
import { accountCategoriesAPI } from '../../../../api/finance/account/accountCategoriesAPI';

// components
import FilterBar, { FilterBarKeys } from './FilterBar';
import { Divider } from 'antd';
import { default as TemplateAccountCategoriesTable } from '../../../../components/Tables/TableTemplates/Finance/AccountCategoriesTable';

interface IProps {
  updateTableTrigger: unknown;
}

const AccountCategoriesTable = ({ updateTableTrigger }: IProps) => {
  const [accountCategoryFilter, setAccountCategoryFilter] = useState({
    accountCategoryNameQuery: '',
    isClient: AllYesNoEnum.All,
  });

  const { response, loading } = useFetch(
    () =>
      accountCategoriesAPI.searchAccountCategoryHierarchy({
        page: 1,
        limit: -1,
        accountCategoryNameQuery:
          accountCategoryFilter.accountCategoryNameQuery,
        isClient:
          accountCategoryFilter.isClient === AllYesNoEnum.All
            ? undefined
            : accountCategoryFilter.isClient === AllYesNoEnum.Yes
              ? true
              : false,
      }),
    [accountCategoryFilter, updateTableTrigger],
  );

  const handleFilterBarChange = (key: FilterBarKeys, value: string) => {
    switch (key) {
      case 'search_by_name':
        setAccountCategoryFilter((prevState) => ({
          ...prevState,
          accountCategoryNameQuery: value,
        }));
        break;

      case 'filter_by_is_client':
        setAccountCategoryFilter((prevState) => ({
          ...prevState,
          isClient: value as AllYesNoEnum,
        }));
        break;
    }
  };

  return (
    <>
      <FilterBar
        onFilterChange={handleFilterBarChange}
        isClientValue={accountCategoryFilter.isClient}
      />
      <Divider />
      <TemplateAccountCategoriesTable
        data={response?.data || []}
        current={1}
        loading={loading}
      />
    </>
  );
};

export default AccountCategoriesTable;
