import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import useQueryParam from '../../../../../hooks/useQueryParam';
import { noteAPI } from '../../../../../api/note/noteAPI';
import { SortProps } from '@core_components/Table';
import { RoutePaths } from '../../../../../routes/routes';
import { NoteMessageType } from '../../../../../enums/crm/crm';
import { DEFAULT_TABLE_LIMIT } from '../../../../../constants/global';

// components
import AddNote from './AddNote';
import ComplianceNotesTable from '../../../../../components/Tables/TableTemplates/CRM/ComplianceNotesTable';

const NotesTab = () => {
  const reviewProcessId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.Compliance_Transactions,
  });
  const [current, setCurrent] = useState(1);
  const [updateTrigger, updateTable] = useState({});
  const [sortByCreatedAt, setSortByCreatedAt] = useState<
    'descend' | 'ascend' | null
  >(null);

  const { response, loading } = useFetch(
    () =>
      reviewProcessId
        ? noteAPI.fetchNotes(reviewProcessId, {
            page: current,
            limit: DEFAULT_TABLE_LIMIT,
            type: NoteMessageType.Compliance,
            sortBy: sortByCreatedAt ? 'createdAt' : undefined,
            sortDirection: sortByCreatedAt
              ? sortByCreatedAt === 'ascend'
              : undefined,
          })
        : null,
    [reviewProcessId, updateTrigger, current, sortByCreatedAt],
  );

  const handleSortChange = (sort: SortProps) => {
    if (Array.isArray(sort) || sort.order == sortByCreatedAt) {
      return;
    }

    if (sort.order) {
      setSortByCreatedAt(sort.order);
    } else {
      if (sortByCreatedAt) {
        setSortByCreatedAt(null);
      }
    }
  };

  return (
    <>
      <AddNote
        onAdd={() => updateTable({})}
        reviewProcessId={reviewProcessId}
      />
      <ComplianceNotesTable
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={current}
        onSortChange={handleSortChange}
        onPaginationChange={setCurrent}
      />
    </>
  );
};

export default NotesTab;
