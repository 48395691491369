import { ActionType } from '../actions/documents';
import { IReduxAction } from '../../typings/common';
import { IDocumentBase } from '../../typings/documents/documents';
import { GetDataStatusEnum } from '../../enums/common';

// Models
export interface StateModel {
  documents: IDocumentBase[] | null;
  documentsDataStatus: GetDataStatusEnum;
}

// Initial state
const initialState: StateModel = {
  documents: null,
  documentsDataStatus: GetDataStatusEnum.NONE,
};

// Reducer
const documents = (state = initialState, action: IReduxAction) => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.SET_STATUS:
      return {
        ...state,
        documentsDataStatus: payload,
      };

    case ActionType.SET_DOCUMENTS:
      return {
        ...state,
        documents: payload,
      };

    case ActionType.DESTROY:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default documents;
