import React from 'react';

// helpers
import { StyledComponentProps } from '../../../typings/common';

// components
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface IProps extends StyledComponentProps {
  tooltipMessage: string | React.ReactNode;
}

const InfoIconWithTooltip = ({ tooltipMessage, ...rest }: IProps) => {
  return (
    <Tooltip title={tooltipMessage} placement="bottom" {...rest}>
      <InfoCircleOutlined />
    </Tooltip>
  );
};

export default InfoIconWithTooltip;
