import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { approvalGroupsAPI } from 'api/approval/approvalManagement/approvalGroupsAPI';
import { financeTemplateAPI } from '../../../../../../api/approval/approvalManagement/financeTemplateAPI';
import { FormFieldPropsModel } from '@core_components/FormField';
import { ClientApprovalGroup } from 'typings/approval/approvalGroups';
import { complianceTemplateAPI } from '../../../../../../api/approval/approvalManagement/complianceTemplateAPI';
import { newBusinessTemplateAPI } from '../../../../../../api/approval/approvalManagement/newBusinsessTemplateAPI';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';
import { forexTransfersTemplateAPI } from '../../../../../../api/approval/approvalManagement/forexTransfersTemplateAPI';
import { seniorManagementTemplateAPI } from '../../../../../../api/approval/approvalManagement/seniorManagementTemplateAPI';
import { clientAccountManagementRulesAPI } from 'api/approval/approvalManagement/clientAccountManagementRulesAPI';
import {
  ApprovalGroupModel,
  ApprovalWorkflowType,
} from '../../../../../../typings/approval/templates';

// components
import Autocomplete, {
  AutocompleteInitialValue,
} from '../../../../../Fields/Autocomplete';

interface IProps extends FormFieldPropsModel {
  value: string;
  approvalWorkflowType: ApprovalWorkflowType;

  onSelect: (value: string, approvalGroup: ApprovalGroupModel) => void;

  initialValue?: AutocompleteInitialValue;
  excludeElementIds?: string[];
  clientGroupId?: number;
}

// TODO:
// 1. Add ability to search by approval group name
const ApprovalGroupsAutocomplete = ({
  approvalWorkflowType,
  clientGroupId,
  ...rest
}: IProps) => {
  const { t } = useTranslation('approval_management');

  const fetchApprovalGroups = async (searchText: string) => {
    const response =
      await fetchApprovalGroupsBasedOnWorkflowType(approvalWorkflowType);
    return response.data.map(
      (group: ClientApprovalGroup | ApprovalGroupModel) => ({
        id: group.id,
        content: group.name,
        model: group,
      }),
    );
  };

  const fetchApprovalGroupsBasedOnWorkflowType = async (
    workflowType: ApprovalWorkflowType,
  ) => {
    const apiParams = { page: 1, limit: AUTOCOMPLETE_RESULT_LIMIT };

    switch (workflowType) {
      case 'new-business':
        return newBusinessTemplateAPI.fetchApprovalGroups(apiParams);

      case 'administration':
        return clientAccountManagementRulesAPI.fetchApprovalGroups(
          { ...apiParams, isActiveOnly: true },
          clientGroupId as number,
        );

      case 'compliance':
        return complianceTemplateAPI.fetchApprovalGroups(apiParams);

      case 'forex-transfers':
        return forexTransfersTemplateAPI.fetchApprovalGroups(apiParams);

      case 'senior-management':
        return seniorManagementTemplateAPI.fetchApprovalGroups(apiParams);

      case 'finance':
        return financeTemplateAPI.fetchApprovalGroups(apiParams);

      case 'transactions':
        return approvalGroupsAPI.fetchClientTransactionApprovalGroups(
          clientGroupId as number,
          apiParams,
        );
    }
  };

  return (
    <Autocomplete
      {...rest}
      fetchData={fetchApprovalGroups}
      placeholder={t(
        'approval_management.approval_groups.select_approval_group',
      )}
    />
  );
};

export default ApprovalGroupsAutocomplete;
