import React from 'react';

// helpers
import { useFormikContext } from 'formik';
import { DISABLED_FORM_STATUS } from 'constants/form';

// components
import Button, { ButtonProps } from '@core_components/Button';

interface IProps extends ButtonProps {
  children?: React.ReactNode;
}

// Custom submit button component with formik context
const SubmitButton = ({
  children,
  loading = false,
  disabled,
  ...rest
}: IProps) => {
  const form = useFormikContext();

  return (
    <Button
      size="large"
      onClick={form?.submitForm}
      disabled={disabled || form?.status === DISABLED_FORM_STATUS}
      loading={form?.isSubmitting || loading}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
