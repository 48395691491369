import React from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import GroupsAutocomplete from '../../../../../../Forms/FormComponents/Autocompletes/ORAC/GroupsAutocomplete';

const InnerForm = () => {
  const { t } = useTranslation('orac');

  return (
    <FormField
      name="groupId"
      label={t('roles.view.group')}
      component={GroupsAutocomplete}
      additionalProps={{ allowClear: true }}
    />
  );
};

export default InnerForm;
