import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { message } from 'antd';
import { securityAPI } from '../../../../../../api/profile/securityAPI';
import { SecurityKeyModel } from '../../../../../../typings/profile/fido';

// components
import SecurityKeyModal, {
  FormValuesModel,
} from '../../../../../../components/Modals/TemplateModalDialogs/Profile/SecurityKeyModal';

interface IProps {
  securityKey: SecurityKeyModel | null;
  isVisible: boolean;
  closeCallback: (wasUpdated?: boolean) => void;
}

const EditSecurityKeyModal = ({
  securityKey,
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('profile');

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!securityKey) {
      return null;
    }

    const { name } = securityKey;

    return { name };
  }, [securityKey]);

  const handleSubmit = async (values: FormValuesModel) => {
    if (securityKey) {
      await securityAPI.updateSecurityKeyById(securityKey._id, {
        name: values.name,
      });
      message.success(
        t(
          'sign_in_and_security.two_factor_verification.security_key_option.edit_key.success_submit_message',
        ),
      );
    }
  };

  return (
    <SecurityKeyModal
      title={t(
        'sign_in_and_security.two_factor_verification.security_key_option.edit_key.title',
      )}
      onSubmit={handleSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialFormValues={initialFormValues}
    />
  );
};

export default EditSecurityKeyModal;
