import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { useFormikContext } from 'formik';
import { AdditionalFieldType } from '../../../../../enums/crm/crm';
import { ICreateRelationshipTemplateFormBody } from '..';

// components
import FormField from '@core_components/FormField';
import RemoveButton from '../../../../../components/Buttons/RemoveButton';
import FieldForSelect from '../FieldForSelect';
import FieldTypeSelect from './FieldTypeSelect';
import UploadDocumentField from '../../../../../components/Forms/FormComponents/UploadDocumentField';
import { Checkbox, Input, Select } from 'antd';

interface IProps {
  index: number;
  record: any;
  dataIndex: string;
  fieldName: 'additionalFields' | 'individualAdditionalFields';
  disableParentType?: boolean;
  onDelete: (index: number) => void;
}

const TableCell = ({
  index,
  record,
  onDelete,
  dataIndex,
  fieldName,
  disableParentType,
}: IProps) => {
  const { t } = useTranslation('crm');
  const { values, isSubmitting, setFieldValue } =
    useFormikContext<ICreateRelationshipTemplateFormBody>();

  const handleChangeTypeSelect = (e: any) => {
    if (e === AdditionalFieldType.Form) {
      setFieldValue(`${fieldName}.${index}.options.formDocument`, []);
    } else {
      setFieldValue(
        `${fieldName}.${index}.options`,
        values[fieldName]?.[index]?.options
          ? {
              onboardingRequired:
                values[fieldName][index].options?.onboardingRequired,
            }
          : undefined,
      );
    }

    setFieldValue(`${fieldName}.${index}.${dataIndex}`, e);
  };

  const renderContent = () => {
    let result = null;

    switch (dataIndex) {
      case 'relatedTo':
        result = (
          <FormField
            name={`${fieldName}.${index}.${dataIndex}`}
            component={FieldForSelect}
            shouldShowErrorMessage={false}
            disabled={disableParentType}
            additionalProps={{
              allowClear: true,
              filterOptionsBy: values.parentType,
            }}
          />
        );
        break;

      case 'name':
        result = (
          <FormField
            name={`${fieldName}.${index}.${dataIndex}`}
            component={Input}
            shouldShowErrorMessage={false}
            placeholder={t('enterPlaceholder', {
              ns: 'form',
              fieldName: t('relationship_templates.field_label').toLowerCase(),
            })}
          />
        );
        break;

      case 'type':
        result = (
          <FormField
            name={`${fieldName}.${index}.${dataIndex}`}
            handleOnChangeManually={handleChangeTypeSelect}
            component={FieldTypeSelect}
            shouldShowErrorMessage={false}
            additionalProps={{ allowClear: true }}
          />
        );
        break;

      case 'description':
        result = (
          <FormField
            name={`${fieldName}.${index}.${dataIndex}`}
            component={Input.TextArea}
            shouldShowErrorMessage={false}
            placeholder={t('enterPlaceholder', {
              ns: 'form',
              fieldName: t('relationship_templates.description').toLowerCase(),
            })}
          />
        );
        break;

      case 'additional':
        {
          const { type } = record;

          if (type === AdditionalFieldType.Select) {
            result = (
              <FormField
                name={`${fieldName}.${index}.options.selectOptionsValues`}
                component={Select}
                shouldShowErrorMessage={false}
                placeholder={t('enterPlaceholder', {
                  ns: 'form',
                  fieldName: t(
                    'entity.relationships.possibleValues',
                  ).toLowerCase(),
                })}
                additionalProps={{
                  allowClear: true,
                  mode: 'tags',
                  dropdownStyle: { display: 'none' },
                }}
              />
            );
          } else if (type === AdditionalFieldType.Document) {
            result = (
              <FormField
                name={`${fieldName}.${index}.options.documentRequiresCertification`}
                component={Checkbox}
                shouldShowErrorMessage={false}
                additionalProps={{
                  children: t('entity.relationships.requireCertification'),
                }}
              />
            );
          } else if (type === AdditionalFieldType.Form) {
            result = (
              <StyledFormField
                name={`${fieldName}.${index}.options.formDocument`}
                component={UploadDocumentField}
              />
            );
          }
        }
        break;

      case 'options.onboardingRequired':
        result = (
          <CenterAlign>
            <FormField
              name={`${fieldName}.${index}.options.onboardingRequired`}
              component={Checkbox}
              shouldShowErrorMessage={false}
            />
          </CenterAlign>
        );
        break;

      case 'options.isDirectClientOnly':
        result = (
          <CenterAlign>
            <FormField
              name={`${fieldName}.${index}.options.isDirectClientOnly`}
              component={Checkbox}
              shouldShowErrorMessage={false}
            />
          </CenterAlign>
        );
        break;

      case 'options.isForRegulatedOnly':
        result = (
          <CenterAlign>
            <FormField
              name={`${fieldName}.${index}.options.isForRegulatedOnly`}
              component={Checkbox}
              shouldShowErrorMessage={false}
            />
          </CenterAlign>
        );
        break;

      case 'deleteField':
        result = isSubmitting ? null : (
          <CenterAlign>
            <RemoveButton buttonSize="small" onClick={() => onDelete(index)} />
          </CenterAlign>
        );
    }

    return result;
  };

  return <td>{renderContent()}</td>;
};

const CenterAlign = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledFormField = styled(FormField)`
  margin-top: ${({ theme }) => theme.marginMd};
`;

export default TableCell;
