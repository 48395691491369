import i18n from 'i18n';
import { contactsAPI } from 'api/crm/contact/contactsAPI';

const ContactHelpers = {
  getContactName: function (contact?: {
    firstName?: string;
    lastName?: string;
    middleName?: string;
  }) {
    return [contact?.firstName, contact?.middleName, contact?.lastName]
      .filter(Boolean)
      .join(' ');
  },

  // Custom asynchronous validation function for array of phone numbers
  validatePhoneNumbers: async function (phoneNumbers: string[]) {
    try {
      // Call your API endpoint with the array of phone numbers
      const response = await contactsAPI.validatePhoneNumbers(phoneNumbers);

      // Assuming your API returns an object with invalid phone numbers
      const invalidNumbers = response.data.reduce<string[]>((acc, next) => {
        if (!next.isValid) {
          acc.push(next.phoneNumber);
        }

        return acc;
      }, []);

      // Create an object to store errors for each phone number
      const errors = {} as Record<string, { errorMessage: string }>;

      // Loop through all phone numbers and set errors for invalid ones
      phoneNumbers.forEach((phoneNumber) => {
        if (invalidNumbers.includes(phoneNumber)) {
          errors[phoneNumber] = {
            errorMessage: i18n.t('custom_errors.invalid_phone_number', {
              ns: 'server_errors',
            }),
          };
        }
      });

      return Object.keys(errors).length === 0 ? undefined : errors;
    } catch {
      // If there's an error, return an undefined value
      return undefined;
    }
  },
};

export { ContactHelpers };
