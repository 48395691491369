import queryString from 'query-string';
import { changeView } from '../redux/actions/view';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface IProps {
  param: string;
  noParamRedirect?: string;
  noParamCallback?: () => void;
}

const useQueryParam = ({
  param,
  noParamRedirect,
  noParamCallback,
}: IProps): string => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const historyPush = (path: any, state?: any) =>
    dispatch(changeView(() => history.push(path, state)));
  const [paramValue, setParamValue] = useState('');

  useEffect(() => {
    const params: any = queryString.parse(location.search);

    if (!params || !params[param]) {
      noParamCallback && noParamCallback();
      noParamRedirect && historyPush(noParamRedirect);
    } else {
      setParamValue(params[param]);
    }
  }, [location.search]);

  return paramValue;
};

export default useQueryParam;
