import React, { useCallback, useMemo } from 'react';

// helpers
import styled from 'styled-components';

// components
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';

interface IProps<MenuItemKeys = string> {
  menu: ITableMenuItem<MenuItemKeys>[];
  onSelect: (key: MenuItemKeys) => void;
}

export interface ITableMenuItem<MenuItemKeys = string> {
  key: MenuItemKeys;
  name: string;
  hidden?: boolean;
  style?: any;
  danger?: boolean;
}

function TableActions<MenuItemKeys = string>({
  menu,
  onSelect,
}: IProps<MenuItemKeys>) {
  const menuItems = useMemo(() => {
    let result: ITableMenuItem<MenuItemKeys>[] = [];

    if (menu.length) {
      result = menu.filter((e) => !e.hidden);
    }

    return result;
  }, [menu]);

  const renderContent = useCallback(() => {
    let result = null;

    if (menuItems.length === 1) {
      const { name, key, danger } = menuItems[0];

      result = (
        <SingleOptionWrapper>
          <Button
            size="small"
            onClick={() => onSelect(key)}
            type="ghost"
            danger={danger}
          >
            {name}
          </Button>
        </SingleOptionWrapper>
      );
    } else if (menuItems.length) {
      const dropdownOverlay = (
        <MenuWrapper onClick={({ key }: any) => onSelect(key)}>
          {menuItems.map(({ key, name, ...rest }) => (
            <Menu.Item key={key as any} {...rest}>
              {name}
            </Menu.Item>
          ))}
        </MenuWrapper>
      );

      result = (
        <Dropdown
          overlay={dropdownOverlay}
          placement="bottomRight"
          arrow
          trigger={['click']}
        >
          <StyledEllipsisIcon />
        </Dropdown>
      );
    }

    return result;
  }, [menuItems, onSelect]);

  return <>{renderContent()}</>;
}

const MenuWrapper = styled(Menu)`
  min-width: 150px;
`;

const StyledEllipsisIcon = styled(EllipsisOutlined)`
  cursor: pointer;
  font-size: 25px;
`;

const SingleOptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export default TableActions;
