import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { TWO_COL_GRID_SIZES } from 'constants/grids';

// components
import FormField from '@core_components/FormField';
import SectionIntro from '@common_components/Texts/SectionIntro';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import SourceOfWealthFormSelect from 'components/Forms/FormComponents/SelectInputs/SourceOfWealthFormSelect';
import EmploymentHistoryShortForm, {
  FormValuesModel as EmploymentHistoryFormValues,
} from '../EmploymentHistoryShortForm';
import { Col, Divider, Row } from 'antd';
import { FieldArray, useFormikContext } from 'formik';

export interface FormValuesModel {
  sourceOfWealthCategories: string[];
  sourceOfWealthDescription: string;
  employmentHistory: EmploymentHistoryFormValues[];
}

const SOWForm = () => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  const renderEmploymentHistory = () => {
    return values.employmentHistory.map((_, index: number) => (
      <>
        <EmploymentHistoryShortForm
          key={index}
          fieldName={`employmentHistory.${index}`}
        />
        <Divider />
      </>
    ));
  };

  return (
    <>
      <SectionIntro
        title={t('contacts.kyc_form.sow.employment_history_section_title')}
      />
      <FieldArray name="employmentHistory" render={renderEmploymentHistory} />
      <SectionIntro title={t('contacts.kyc_form.sow.sow_section_title')} />
      <Row gutter={[16, 16]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            name="sourceOfWealthCategories"
            component={SourceOfWealthFormSelect}
            additionalProps={{ mode: 'multiple' }}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            name="sourceOfWealthDescription"
            component={FormInputTextArea}
            additionalProps={{ autoSize: { minRows: 5, maxRows: 10 } }}
          />
        </Col>
      </Row>
    </>
  );
};

export default SOWForm;
