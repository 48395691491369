import React, { memo } from 'react';

// helpers
import { IProps as AccountJournalEntriesTableProps } from './InnerForm/AccountJournalEntriesTable';
import { FormValuesModel as FormEditModeButtonsValuesModel } from '../../../FormComponents/FormEditModeButtons';
import { FormValuesModel as UpdateReconciliationStatusesProgressFormValuesModel } from '../UpdateReconciliationStatusesProgressForm';

// components
import InnerForm from './InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';

export interface FormValuesModel
  extends FormEditModeButtonsValuesModel,
    UpdateReconciliationStatusesProgressFormValuesModel {}

interface IProps
  extends AccountJournalEntriesTableProps,
    RequiredPropsForFormModel<FormValuesModel> {}

const UpdateReconciliationStatusForJournalEntriesForm = memo(
  ({ onSubmit, initialValues, ...rest }: IProps) => {
    return (
      <Form<FormValuesModel>
        onSubmit={onSubmit}
        initialValues={initialValues}
        confirmExitWithoutSaving
        renderForm={<InnerForm {...rest} />}
      />
    );
  },
);

export default UpdateReconciliationStatusForJournalEntriesForm;
