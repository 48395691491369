import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import { Input } from 'antd';

const InnerForm = () => {
  const { t } = useTranslation(['finance', 'common']);

  return (
    <FormField
      name="message"
      label={t(
        'update_reconciliation_status.update_status_modal.form_fields.comment',
      )}
      component={Input.TextArea}
      placeholder={t('enter_value', { ns: 'common' })}
      additionalProps={{ autoSize: { minRows: 6, maxRows: 6 } }}
    />
  );
};

export default InnerForm;
