import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';
import { AdditionalFieldType } from 'enums/crm/crm';
import { DocumentFieldValueModel } from 'components/Forms/FormComponents/DocumentsField';

// components
import Checkbox from '@core_components/Checkbox';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import DeleteButton from '@common_components/Buttons/DeleteButton';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import AdditionalFieldOptions from './AdditionalFieldOptions';
import AdditionalFieldTypeFormSelect from 'components/Forms/FormComponents/SelectInputs/AdditionalFieldTypeFormSelect';
import Table, { TableColumnModel } from '@core_components/Table';

export type EntityTypeCategoryAdditionalField = {
  name: string;
  onboardingRequired: boolean | null;
  isDirectClientOnly: boolean | null;
  isRegulatedOnly: boolean | null;
  description: string;
  type: AdditionalFieldType | null;

  // ** Additional options for certain types of fields
  // * Document
  requireCertification: boolean;
  // * Form
  formDocument: DocumentFieldValueModel[];
  // * Select
  selectOptions: string[];
};

interface IProps {
  onDelete: (index: number) => void;
}

const AdditionalFieldsSection = memo(({ onDelete }: IProps) => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  const columns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        key: 'name',
        width: 300,
        title: t('entity_type_category.new.form_fields.additional_fields.name'),
        render: (value: any, record: any, index: number) => {
          return (
            <FormField
              name={`additionalFields.${index}.name`}
              component={FormInput}
            />
          );
        },
      },

      {
        key: 'onboarding_required',
        width: 175,
        align: 'center',
        title: t(
          'entity_type_category.new.form_fields.additional_fields.onboarding_required',
        ),
        render: (value: any, record: any, index: number) => {
          return (
            <FormField
              name={`additionalFields.${index}.onboardingRequired`}
              component={Checkbox}
            />
          );
        },
      },

      {
        key: 'is_direct_client_only',
        width: 175,
        align: 'center',
        title: t(
          'entity_type_category.new.form_fields.additional_fields.is_direct_client_only',
        ),
        render: (value: any, record: any, index: number) => {
          return (
            <FormField
              name={`additionalFields.${index}.isDirectClientOnly`}
              component={Checkbox}
            />
          );
        },
      },

      {
        key: 'is_regulated_only',
        width: 175,
        align: 'center',
        title: t(
          'entity_type_category.new.form_fields.additional_fields.is_regulated_only',
        ),
        render: (value: any, record: any, index: number) => {
          return (
            <FormField
              name={`additionalFields.${index}.isRegulatedOnly`}
              component={Checkbox}
            />
          );
        },
      },

      {
        key: 'description',
        width: 300,
        title: t(
          'entity_type_category.new.form_fields.additional_fields.description',
        ),
        render: (value: any, record: any, index: number) => {
          return (
            <FormField
              name={`additionalFields.${index}.description`}
              component={FormInputTextArea}
            />
          );
        },
      },

      {
        key: 'field_type',
        width: 200,
        title: t('entity_type_category.new.form_fields.additional_fields.type'),
        render: (value: any, record: any, index: number) => {
          return (
            <FormField
              name={`additionalFields.${index}.type`}
              component={AdditionalFieldTypeFormSelect}
            />
          );
        },
      },

      {
        key: 'options',
        width: 240,
        title: t(
          'entity_type_category.new.form_fields.additional_fields.options',
        ),
        render: (value: any, record: any, index: number) => {
          return (
            <AdditionalFieldOptions
              baseFieldName={`additionalFields.${index}`}
            />
          );
        },
      },

      {
        key: 'actions',
        align: 'right',
        render: (value: any, record: any, index: number) => (
          <DeleteButton onClick={() => onDelete(index)} />
        ),
      },
    ];
  }, [t, values]);

  return (
    <Table
      bordered
      size="small"
      hidePagination
      data={values.additionalFields}
      columns={columns}
    />
  );
});

export default AdditionalFieldsSection;
