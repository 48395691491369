import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { UserTypes } from '../../../../enums/admin/orac';
import { IContactModel } from '../../../../typings/crm/contact';
import { useFormikContext } from 'formik';

// components
import FormField from '@core_components/FormField';
import EmailSelect from '../../../../components/Forms/FormComponents/SelectInputs/EmailSelect';
import UserTypeSelect from './UserTypeSelect';
import PhoneNumberSelect from '../../../../components/Forms/FormComponents/SelectInputs/PhoneNumberSelect';
import ContactAutocomplete from '../../../../components/Forms/FormComponents/Autocompletes/CRM/ContactAutocomplete';
import { Col, Input, Row } from 'antd';

export interface ICreateUserForm {
  type?: UserTypes;
  contactId?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
}

interface IProps {
  disabled?: boolean;
}

const InnerForm = ({ disabled }: IProps) => {
  const { t } = useTranslation(['orac', 'form']);

  const [phoneNumbers, setpPoneNumbers] = useState<string[]>([]);
  const [contactEmails, setContactEmails] = useState<string[]>([]);

  const { setValues, values } = useFormikContext<ICreateUserForm>();

  const handleContactSelect = (id: string, contact: IContactModel) => {
    const emails = contact.emails?.map(({ address }) => address);
    const phoneNumbers = contact.phoneNumbers?.map(({ number }) => number);

    setContactEmails(emails);
    setpPoneNumbers(phoneNumbers);

    const data = {
      firstName: contact.firstName,
      lastName: contact.lastName,
      middleName: contact.middleName,
      contactId: contact._id,
      email: '',
      phoneNumber: '',
    };

    setValues({ ...values, ...data });
  };

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col span="24">
          <FormField
            name="type"
            label={t('users.form.type')}
            placeholder={t('selectPlaceholder', {
              ns: 'form',
              fieldName: t('users.form.type').toLowerCase(),
            })}
            component={UserTypeSelect}
            disabled={disabled}
            additionalProps={{ allowClear: true }}
          />
        </Col>
        <Col span="24">
          <FormField
            name="contactId"
            label={t('users.form.contact')}
            placeholder={t('selectPlaceholder', {
              ns: 'form',
              fieldName: t('users.form.contact').toLowerCase(),
            })}
            component={ContactAutocomplete}
            disabled={disabled}
            additionalProps={{
              allowClear: true,
              onSelect: handleContactSelect,
              isAssociated: false,
              shouldDisableContactsWithoutEmailAndPhone: true,
            }}
          />
        </Col>
        <Col span="24">
          <FormField
            name="firstName"
            label={t('users.form.firstName')}
            placeholder={t('enterPlaceholder', {
              ns: 'form',
              fieldName: t('users.form.firstName').toLowerCase(),
            })}
            component={Input}
            disabled
          />
        </Col>
        <Col span="24">
          <FormField
            name="middleName"
            label={t('users.form.middleName')}
            placeholder={t('enterPlaceholder', {
              ns: 'form',
              fieldName: t('users.form.middleName').toLowerCase(),
            })}
            component={Input}
            disabled
          />
        </Col>
        <Col span="24">
          <FormField
            name="lastName"
            label={t('users.form.lastName')}
            placeholder={t('enterPlaceholder', {
              ns: 'form',
              fieldName: t('users.form.lastName').toLowerCase(),
            })}
            component={Input}
            disabled
          />
        </Col>
        <Col span="24">
          <FormField
            name="email"
            disabled={!contactEmails.length}
            label={t('users.form.email')}
            component={EmailSelect}
            placeholder={t('selectPlaceholder', {
              ns: 'form',
              fieldName: t('users.form.email').toLowerCase(),
            })}
            additionalProps={{
              allowClear: true,
              data: contactEmails,
            }}
          />
        </Col>
        <Col span="24">
          <FormField
            name="phoneNumber"
            label={t('users.form.phone')}
            component={PhoneNumberSelect}
            placeholder={t('selectPlaceholder', {
              ns: 'form',
              fieldName: t('users.form.phone').toLowerCase(),
            })}
            disabled={!phoneNumbers.length}
            additionalProps={{
              allowClear: true,
              data: phoneNumbers,
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default InnerForm;
