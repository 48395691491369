import { ApprovalStatuses } from 'enums/approvalManagement/workflow';
import { BankAccountTemplateData } from 'components/Modals/TemplateModalDialogs/CRM/ApprovalEntryModalDialog/InnerContent/ChangeDifferenceSection/Templates/BankAccount';
import { ApprovalGroupTemplateData } from 'components/Modals/TemplateModalDialogs/CRM/ApprovalEntryModalDialog/InnerContent/ChangeDifferenceSection/Templates/ApprovalGroup';
import { TransactionRuleTemplateData } from 'components/Modals/TemplateModalDialogs/CRM/ApprovalEntryModalDialog/InnerContent/ChangeDifferenceSection/Templates/TransactionRule';
import { ClientGroupTemplateDataModel } from 'components/Modals/TemplateModalDialogs/CRM/ApprovalEntryModalDialog/InnerContent/ChangeDifferenceSection/Templates/ClientGroup';
import { CircleServiceTemplateDataModel } from 'components/Modals/TemplateModalDialogs/CRM/ApprovalEntryModalDialog/InnerContent/ChangeDifferenceSection/Templates/CircleService';
import { AccountManagementApprovalRuleTemplateData } from 'components/Modals/TemplateModalDialogs/CRM/ApprovalEntryModalDialog/InnerContent/ChangeDifferenceSection/Templates/AdministrationApprovalRule';
import {
  ApprovalRuleSetModel,
  ApprovalThreasholdModel,
} from 'typings/approval/templates';
import {
  ApprovalEntryModel,
  ApprovalRuleTemplateFromQuery,
} from 'api/approval/approvalManagement/clientAccountManagementRulesAPI';
import {
  ClientAccountManagementApprovalStatuses,
  ClientApprovalWorkflowChangeTypes,
  ClientApprovalWorkflowEntryTypes,
} from 'typings/approval/approvalWorkflow';

export interface ApprovalRuleTemplateFromQueryToTemplate {
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  approvalRuleSets: ApprovalRuleSetModel[];
  templateCode: string;
}

export interface ApprovalProgressStateModel {
  progressStateItems: ApprovalProgressStateItemModel[];
}

export interface ApprovalProgressStateItemModel {
  groupNameTittle: string;
  members: ApprovalProgressMemberModel[];
}

export interface ApprovalProgressMemberModel {
  userId: string;
  userFullName: string;
  approvalDate: string;
  approvalActionName: string;
  approvalAction: ApprovalStatuses;
  approvalReason: string;
}

export interface ApprovalWorkflowResponse extends ApprovalEntryModel {
  approvalProgressStateSets: ApprovalProgressStateModel[];
  entryData: string;
}

export interface ClientApprovalEntryModel<Template = unknown> {
  entry: {
    id: string;
    type: ClientApprovalWorkflowEntryTypes;
    status: ClientAccountManagementApprovalStatuses;
    templateCode: string;
    createdAt: string;
    changeType: ClientApprovalWorkflowChangeTypes;
  };

  approvalProgressStateSets: ApprovalProgressStateModel[];

  changes: ApprovalEntryChangesModel<Template>;
}
export interface ApprovalEntryChangesModel<Template = unknown> {
  new: Template | null;
  old: Template | null;
}

export interface ApprovalRuleSetFullModel {
  approvalThreasholds: ApprovalThreasholdModel[];
  approvalRuleItems: ApprovalRuleSetModel[];
}
export interface ApprovalRulesTemplateBaseModel {
  approvalRuleSets: ApprovalRuleSetFullModel[];
}

const clientAccountManagementRulesAdapterAPI = {
  fetchApprovalRuleTemplate: (
    data: ApprovalRuleTemplateFromQuery,
  ): ApprovalRuleTemplateFromQueryToTemplate => {
    return {
      id: data.id,
      isActive: data.isActive,
      isDeleted: data.isDeleted,
      approvalRuleSets: data.approvalRuleSets,
      templateCode: data.templateCode,
    };
  },

  getApprovalWorkflow: (
    workflow: ApprovalWorkflowResponse,
  ): ClientApprovalEntryModel => {
    const entry = {
      id: workflow.id,
      type: workflow.entryType,
      createdAt: workflow.createdAt,
      status: workflow.status,
      templateCode: workflow.templateCode,
      changeType: workflow.value,
    };

    const data = JSON.parse(workflow.entryData);
    let changes: ApprovalEntryChangesModel = {
      old: null,
      new: null,
    };

    switch (entry.type) {
      case ClientApprovalWorkflowEntryTypes.ACCOUNT_MNGT_APPROVAL_GROUP:
      case ClientApprovalWorkflowEntryTypes.TRANSACTION_APPROVAL_GROUP:
        {
          changes = {
            old: data.before
              ? {
                  groupName: data.before.name,
                  participants: data.before.participants?.length
                    ? data.before.participants
                        .map((e: any) => e.userFullName)
                        .join(', ')
                    : '',
                  description: data.before.description,
                }
              : null,
            new: data.after
              ? {
                  groupName: data.after.name,
                  participants: data.after.participants?.length
                    ? data.after.participants
                        .map((e: any) => e.userFullName)
                        .join(', ')
                    : '',
                  description: data.after.description,
                }
              : null,
          } as ApprovalEntryChangesModel<ApprovalGroupTemplateData>;
        }
        break;

      case ClientApprovalWorkflowEntryTypes.TRANSACTION_APPROVAL_RULE:
        {
          changes = {
            old: data.before
              ? {
                  id: data.before.id,
                  isActive: false,
                  bankAccounts: data.before.accounts,
                  approvalRuleSets: data.before.approvalRuleSets,
                }
              : null,
            new: data.after
              ? {
                  id: data.after.id,
                  isActive: false,
                  bankAccounts: data.after.accounts,
                  approvalRuleSets: data.after.approvalRuleSets,
                }
              : null,
          } as ApprovalEntryChangesModel<TransactionRuleTemplateData>;
        }
        break;

      case ClientApprovalWorkflowEntryTypes.ACCOUNT_MNGT_APPROVAL_RULE:
        {
          changes = {
            old: data.before
              ? {
                  approvalRule: {
                    approvalRuleSets: data.before.approvalRuleSets,
                  },
                }
              : null,
            new: data.after
              ? {
                  approvalRule: {
                    approvalRuleSets: data.after.approvalRuleSets,
                  },
                }
              : null,
          } as ApprovalEntryChangesModel<AccountManagementApprovalRuleTemplateData>;
        }
        break;

      case ClientApprovalWorkflowEntryTypes.ACCOUNT_MNGT_NEW_BANKACCOUNT:
        {
          // TODO: backend return wrong model without before and after
          changes = {
            old: null,
            new: {
              bankAccount: {
                accountCurrency: data.accountCurrency,
                accountName: data.accountName,
                accountNumber: data.accountNumber,
              },
            },
          } as ApprovalEntryChangesModel<BankAccountTemplateData>;
        }
        break;

      case ClientApprovalWorkflowEntryTypes.ACCOUNT_MNGT_UPDATE_CLIENTGROUP:
        {
          changes = {
            old: null,
            new: {
              approvedRelationshipScopeId: data.approvedRelationshipScopeId,
              clientGroupId: data.clientGroupId,
              relationshipScopeId: data.relationshipScopeId,
              clientGroupUserChanges: data.clientGroupUserChanges || [],
            },
          } as ApprovalEntryChangesModel<ClientGroupTemplateDataModel>;
        }
        break;

      case ClientApprovalWorkflowEntryTypes.ACCOUNT_MNGT_CIRCLE_INTEGRATION:
        {
          changes = {
            old: null,
            new: {
              connected: true,
            },
          } as ApprovalEntryChangesModel<CircleServiceTemplateDataModel>;
        }
        break;
    }

    return {
      entry,
      approvalProgressStateSets: workflow.approvalProgressStateSets,
      changes,
    };
  },
};

export { clientAccountManagementRulesAdapterAPI };
