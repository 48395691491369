import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { TableColumnModel } from '@core_components/Table';
import { ForexSpreadLimitModel } from '../../../../../../typings/banking/foreignExchange';

// components
import TableCell from './TableCell';
import RangeInfo from './RangeInfo';
import { Table, Typography } from 'antd';

interface IProps {
  onSpreadSelect: (spreadId: number) => void;
  spreadRanges: ForexSpreadLimitModel[];
}

const InnerMatrix = ({ spreadRanges, onSpreadSelect }: IProps) => {
  const { t } = useTranslation('banking');

  const columns = useMemo(() => {
    let result: TableColumnModel[] = [];

    const initialColumns: TableColumnModel[] = [
      {
        width: '300px',
        key: 'title-col',
        dataIndex: 'title-col',
        title: (
          <StyledTitleParagraph>{t('forex.matrix.range')}</StyledTitleParagraph>
        ),
      },
    ];

    const spreadRangeColumns: TableColumnModel[] = spreadRanges.map((e, i) => ({
      width: '300px',
      key: `action-type-${i}`,
      dataIndex: String(i),
      title: (
        <RangeInfo
          from={
            AccountsHelpers.convertAmountFromBigIntToInt(e.fromRange) as number
          }
          to={
            !e.toRange
              ? (t('forex.matrix.above') as string)
              : AccountsHelpers.convertAmountFromBigIntToInt(e.toRange.value) ||
                0
          }
        />
      ),
    }));

    initialColumns.push(...spreadRangeColumns);

    result = initialColumns.map((col, index: number) => {
      const baseProps = {
        onSpreadSelect,
        title: col.title,
        dataIndex: col.dataIndex,
        colIndex: index > 0 ? index - 1 : 0,
      };

      return {
        ...col,
        onCell: (record: ForexSpreadLimitModel, rowIndex: number) => ({
          ...baseProps,
          record,
          rowIndex,
        }),
      };
    });

    return result;
  }, [spreadRanges]);

  return (
    <Table
      bordered
      size="large"
      scroll={{ x: 'max-content' }}
      columns={columns}
      pagination={false}
      className="disable-table-row-hover"
      dataSource={[spreadRanges]}
      components={{ body: { cell: TableCell } }}
    />
  );
};

export const StyledTitleParagraph = styled(Typography.Paragraph)`
  font-weight: 600;
  margin-bottom: 0px !important;
  transition: ${({ theme }) => theme.defaultTransition};
`;

export default InnerMatrix;
