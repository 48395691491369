import React, { memo, useCallback, useMemo, useState } from 'react';

// helpers
import moment from 'moment';
import useFetch from '@hooks/useFetch';
import { Moment } from 'moment';
import { reportingAPI } from 'api/reporting/reportingAPI';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';

// components
import FilterBar from './FilterBar';
import TransactionsTable from './TransactionsTable';
import GeneralLedgerTable, {
  GeneralLedgerItem,
} from 'components/Tables/TableTemplates/Reporting/GeneralLedgerTable';
import { Divider } from 'antd';

interface IProps {
  selectedFilterDate: [Moment, Moment] | null;
  setSelectedDate: (range: [Moment, Moment] | null) => void;
}

const GeneralLedgerReport = memo(
  ({ selectedFilterDate, setSelectedDate }: IProps) => {
    const [page, setPage] = useState(1);

    const { response, loading } = useFetch(
      () =>
        selectedFilterDate
          ? reportingAPI.fetchGeneralLedgerReport({
              page,
              limit: DEFAULT_TABLE_LIMIT,
              fromDate: selectedFilterDate[0].format('X'),
              toDate: selectedFilterDate[1].format('X'),
            })
          : null,
      [page, selectedFilterDate],
    );

    const expandableRow = useMemo(() => {
      return {
        expandedRowRender: (record: GeneralLedgerItem) => {
          return (
            <TransactionsTable accountNumber={record.accountNumber || null} />
          );
        },
      };
    }, []);

    // handle filter bar change
    const handleFilterChange = useCallback(
      (key: string, value: any) => {
        if (selectedFilterDate) {
          switch (key) {
            case 'startDate':
              setSelectedDate([
                moment(value).startOf('day'),
                selectedFilterDate[1],
              ]);
              break;
            case 'endDate':
              setSelectedDate([
                selectedFilterDate[0],
                moment(value).endOf('day'),
              ]);
              break;
          }
        }
      },
      [selectedFilterDate],
    );

    return (
      <>
        <FilterBar
          filterChange={handleFilterChange}
          dateRangeValue={selectedFilterDate}
          isDisabled={loading}
        />
        <Divider />
        <GeneralLedgerTable
          data={response?.data || []}
          total={response?.total || 0}
          current={page}
          loading={loading}
          expandable={expandableRow}
          onPaginationChange={setPage}
        />
      </>
    );
  },
);

export default GeneralLedgerReport;
