import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useQueryParam from '@hooks/useQueryParam';
import useTranslation from '../../../../../hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { DateHelpers } from '@helpers/date';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { TransferTemplateStatuses } from 'enums/transfers/templates';
import {
  TransferTemplatePermissions,
  TransferTemplateTypes,
} from '../../../../../enums/finance/transferTemplates';

// components
import Link from '@common_components/Texts/Link';
import TwoColRow from '../../../TwoColRow';
import EllipsisTooltip from '../../../../Tooltips/EllipsisTooltip';
import TransferTemplateStatus from 'components/Additional/Statuses/TransferTemplateStatus';
import { Divider } from 'antd';

export type WireTemplate = {
  id: number;
  templateName: string;
  isOnlyMe: boolean;
  beneficiaryAccountName: string;
  beneficiaryAccountNumber: string;
  beneficiaryCountry: string;
  beneficiaryAddress: string;
  beneficiaryBankCode: string;
  beneficiaryBankName: string;
  beneficiaryBankCountry: string;
  beneficiaryBankAddress: string;
  intermediaryBankCode: string;
  intermediaryBankName: string;
  intermediaryBankCountry: string;
  intermediaryBankAddress: string;
  reference: string;
  beneficiaryBankCodeTypeName: string;
  intermediaryBankCodeTypeName: string;
  beneficiaryCity: string;
  beneficiaryDistrict: string;
  beneficiaryPostCode: string;
  beneficiaryBankCity: string;
  beneficiaryBankDistrict: string;
  beneficiaryBankPostCode: string;
  intermediaryBankCity: string;
  intermediaryBankDistrict: string;
  intermediaryBankPostCode: string;
  status: TransferTemplateStatuses;
  invalidityReason: string;
  creationDate: number;
};

interface IProps {
  template: WireTemplate;
  hideReference?: boolean;
  hideGeneralInfo?: boolean;
  displayTemplateName?: boolean;
  maxValueWidthForTooltip?: string;
}

const EnhancedTwoColRow = memo(
  ({
    label,
    value,
    maxTextContainerWidth,
  }: {
    label: React.ReactNode;
    value: React.ReactNode;
    maxTextContainerWidth?: string;
  }) => {
    return (
      <TwoColRow
        labelWidth="230px"
        label={label}
        value={
          <EllipsisTooltip
            title={value}
            maxTextContainerWidth={maxTextContainerWidth}
          >
            {value}
          </EllipsisTooltip>
        }
      />
    );
  },
);

const WireTransferTemplateInfo = memo(
  ({
    template,
    hideReference,
    hideGeneralInfo,
    displayTemplateName,
    maxValueWidthForTooltip,
  }: IProps) => {
    const { t } = useTranslation('crm');
    const history = useHistory();
    const clientGroupId = useQueryParam({ param: 'id' });

    return (
      <>
        {!hideGeneralInfo && (
          <>
            <EnhancedTwoColRow
              maxTextContainerWidth={maxValueWidthForTooltip}
              label={t(
                'transfer_templates.wire_transfer_template_details_section.id',
              )}
              value={template.id}
            />
            <EnhancedTwoColRow
              maxTextContainerWidth={maxValueWidthForTooltip}
              label={t(
                'transfer_templates.wire_transfer_template_details_section.date',
              )}
              value={DateHelpers.formatTimestampToString(template.creationDate)}
            />
            <EnhancedTwoColRow
              maxTextContainerWidth={maxValueWidthForTooltip}
              label={t(
                'transfer_templates.wire_transfer_template_details_section.template_name',
              )}
              value={template.templateName}
            />
            <EnhancedTwoColRow
              maxTextContainerWidth={maxValueWidthForTooltip}
              label={t(
                'transfer_templates.wire_transfer_template_details_section.status',
              )}
              value={<TransferTemplateStatus status={template.status} />}
            />
            {template.invalidityReason && (
              <EnhancedTwoColRow
                maxTextContainerWidth={maxValueWidthForTooltip}
                label={t(
                  'transfer_templates.wire_transfer_template_details_section.reason',
                )}
                value={template.invalidityReason}
              />
            )}
            <EnhancedTwoColRow
              maxTextContainerWidth={maxValueWidthForTooltip}
              label={t(
                'transfer_templates.wire_transfer_template_details_section.permissions',
              )}
              value={t(
                `transfer_templates.transfer_template_permissions.${
                  template.isOnlyMe
                    ? TransferTemplatePermissions.Private
                    : TransferTemplatePermissions.Public
                }`,
              )}
            />
          </>
        )}

        {displayTemplateName && (
          <EnhancedTwoColRow
            maxTextContainerWidth={maxValueWidthForTooltip}
            label={t(
              'transfer_templates.wire_transfer_template_details_section.transfer_template',
            )}
            value={
              <Link
                onClick={() =>
                  history.push({
                    pathname: RoutePaths.CRM_Client_Group_Details,
                    search: `?id=${String(clientGroupId)}&tab=transferTemplates&templateId=${template.id}&templateType=${
                      TransferTemplateTypes.Wire
                    }`,
                  })
                }
              >
                {AccountsHelpers.getTransferTemplateName(
                  String(template.id),
                  template.templateName,
                )}
              </Link>
            }
          />
        )}

        <StyledDivider orientation="left">
          {t(
            'transfer_templates.wire_transfer_template_details_section.beneficiary_section',
          )}
        </StyledDivider>
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.account_name',
          )}
          value={template.beneficiaryAccountName}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.account_number',
          )}
          value={template.beneficiaryAccountNumber}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.country',
          )}
          value={template.beneficiaryCountry}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.address',
          )}
          value={template.beneficiaryAddress}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.city',
          )}
          value={template.beneficiaryCity}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.district',
          )}
          value={template.beneficiaryDistrict}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.post_code',
          )}
          value={template.beneficiaryPostCode}
        />

        <StyledDivider orientation="left">
          {t(
            'transfer_templates.wire_transfer_template_details_section.beneficiary_bank_section',
          )}
        </StyledDivider>
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.bank_code',
          )}
          value={template.beneficiaryBankCode}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.bank_code_type',
          )}
          value={template.beneficiaryBankCodeTypeName}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.bank_name',
          )}
          value={template.beneficiaryBankName}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.country',
          )}
          value={template.beneficiaryBankCountry}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.address',
          )}
          value={template.beneficiaryBankAddress}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.city',
          )}
          value={template.beneficiaryBankCity}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.district',
          )}
          value={template.beneficiaryBankDistrict}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.post_code',
          )}
          value={template.beneficiaryBankPostCode}
        />

        <StyledDivider orientation="left">
          {t(
            'transfer_templates.wire_transfer_template_details_section.intermediary_bank_section',
          )}
        </StyledDivider>
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.bank_code',
          )}
          value={template.intermediaryBankCode}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.bank_code_type',
          )}
          value={template.intermediaryBankCodeTypeName}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.bank_name',
          )}
          value={template.intermediaryBankName}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.country',
          )}
          value={template.intermediaryBankCountry}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.address',
          )}
          value={template.intermediaryBankAddress}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.city',
          )}
          value={template.intermediaryBankCity}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.district',
          )}
          value={template.intermediaryBankDistrict}
        />
        <EnhancedTwoColRow
          maxTextContainerWidth={maxValueWidthForTooltip}
          label={t(
            'transfer_templates.wire_transfer_template_details_section.post_code',
          )}
          value={template.intermediaryBankPostCode}
        />

        {!hideReference && (
          <>
            <StyledDivider />
            <EnhancedTwoColRow
              maxTextContainerWidth={maxValueWidthForTooltip}
              label={t(
                'transfer_templates.wire_transfer_template_details_section.reference',
              )}
              value={template.reference}
            />
          </>
        )}
      </>
    );
  },
);

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.marginXs} 0`};
`;

export default WireTransferTemplateInfo;
