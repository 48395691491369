import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';
import { CurrencyFilterOptions } from '../../../../../../enums/finance/finance';

// components
import FormField from '@core_components/FormField';
import CurrencySelect from '../../../../../Forms/FormComponents/SelectInputs/CurrencySelect';

const InnerForm = () => {
  const { t } = useTranslation(['finance', 'common']);
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <FormField
        name="currencies"
        label={t('currencies.update.form_fields.account_currency')}
        component={CurrencySelect}
        placeholder={t('select_option', { ns: 'common' })}
        additionalProps={{
          allowClear: true,
          mode: 'multiple',
          isAccount: CurrencyFilterOptions.AllAccounts,
          initialValue: values.currenciesInitialValue,
        }}
      />
    </>
  );
};

export default InnerForm;
