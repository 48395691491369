export const NOTIFICATION_DURATION = 5;
export const SMALL_NOTIFICATION_DURATION = 1;
export const DEFAULT_TABLE_LIMIT = 10;
export const MESSAGES_RESULT_LIMIT = 50;
export const INFINITE_LIMIT = -1;

export const REQ_TIMEOUT = 20000;
export const DOCUMENT_UPLOAD_TIMEOUT = 30000;

export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';
export const DEFAULT_FULL_DATE_FORMAT = 'DD MMMM YYYY';
export const DEFAULT_FULL_DATE_TIME_FORMAT = 'DD MMMM YYYY, HH:mm';
export const DEFAULT_DATE_TIME_FORMAT = 'DD MMM YYYY, HH:mm';
export const DEFAULT_DATE_TIME_FORMAT_UTC = 'DD MMM YYYY, HH:mm UTC';
export const YYYYMMDD_TIME_FORMAT = 'YYYY-MM-DD';
export const TIME_FROM_DATE_FORMAT = 'hh:mm A';

export const AUTOCOMPLETE_DEBOUNCE_DELAY = 300;
export const AUTOCOMPLETE_RESULT_LIMIT = 25;

export const LIST_RESULT_LIMIT = 50;

// ------ Validation ---------
export const NAME_MAX_LENGTH = 50;
export const CRM_CONTACT_NAME_MAX_LENGTH = 40;
