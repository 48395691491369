import React, { memo, useMemo } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../../../../routes/routes';
import { TransactionTypes } from '../../../../../../../../enums/banking/transactions';
import {
  FullTransactionFromQuery,
  bankingTransactionsAPIAdapter,
} from '../../../../../../../../apiAdapters/banking/bankingTransactionsAPIAdapter';

// components
import Form from '@core_components/Form';
import TransactionForm, {
  FormValuesModel,
} from '../../../../../../../../components/Forms/TemplateForms/Banking/TransactionForm';
import { Typography } from 'antd';

interface IProps {
  incomingTransaction: FullTransactionFromQuery;
}

const IncomingTransactionDetails = memo(({ incomingTransaction }: IProps) => {
  const { t } = useTranslation('approval_management');
  const history = useHistory();
  const [hasViewTransactionAccess] = useUserAccess([
    'Banking_Transactions_View',
  ]);
  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!incomingTransaction) {
      return null;
    }

    return {
      submitAction: null,
      transactionId: incomingTransaction.id || null,
      transactionType: TransactionTypes.Incoming,
      outgoingTransaction: null,
      incomingTransaction:
        bankingTransactionsAPIAdapter.formatIncomingTransactionFromQueryToFormData(
          incomingTransaction,
          false,
        ),
    };
  }, [incomingTransaction]);

  return (
    <>
      {hasViewTransactionAccess && incomingTransaction && (
        <Typography.Link
          onClick={() =>
            history.push({
              pathname: RoutePaths.Banking_Transactions,
              search: `?id=${incomingTransaction.id}`,
            })
          }
        >
          {t(
            'approval_workflow.approval_workflow_changes.incoming_transaction.navigate_to_transaction',
          )}
        </Typography.Link>
      )}

      <Form<FormValuesModel>
        disabled
        onSubmit={console.log}
        initialValues={initialFormValues}
        renderForm={<TransactionForm />}
      />
    </>
  );
});

export default IncomingTransactionDetails;
