import React, { useContext } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { FormContext } from '@core_components/Form';
import { StyledComponentProps } from 'typings/common';
import { HighlightFieldStatuses } from '@core_components/FormField';

// components
import StatusLabel, { LABEL_STATUSES } from 'components/Typography/StatusLabel';

const STATUS_MAP: Record<LABEL_STATUSES, HighlightFieldStatuses[]> = {
  info: [],
  warning: ['changed'],
  success: ['new'],
  error: ['deleted'],
};

interface IProps extends StyledComponentProps {
  name: string;
}

const ValueChangedStatus = ({ name, ...rest }: IProps) => {
  const { t } = useTranslation('form');
  const customFormContext = useContext(FormContext);

  if (
    !customFormContext.highlightFields ||
    !customFormContext.highlightFields[name]
  ) {
    return null;
  }

  return (
    <StatusLabel
      {...rest}
      statusMap={STATUS_MAP}
      status={customFormContext.highlightFields[name]}
      text={t(
        `form_value_change_statuses.${customFormContext.highlightFields[name]}`,
      )}
    />
  );
};

export default ValueChangedStatus;
