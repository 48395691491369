import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../../hooks/useFetch';
import rolesAPI from '../../../../../../api/orac/rolesAPI';
import useTranslation from '../../../../../../hooks/useTranslation';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../constants/global';

// components
import ObjectsTable from '../../../../../Tables/TableTemplates/ORAC/ObjectsTable';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Spin } from 'antd';

interface IProps extends RequiredPropsForModalDialogModel {
  roleId: string;
}

const ViewRoleDetailsModal = ({ isVisible, closeCallback, roleId }: IProps) => {
  const { t } = useTranslation('orac');

  const [page, setPage] = useState(1);

  const { response, loading } = useFetch(() => {
    return isVisible && roleId
      ? rolesAPI.fetchPermissionsByRoleId(roleId, {
          page,
          limit: DEFAULT_TABLE_LIMIT,
        })
      : null;
  }, [page, roleId, isVisible]);

  return (
    <ModalDialog
      hideFooterButtons
      title={t('roles.view.role_details')}
      isVisible={isVisible}
      closeCallback={closeCallback}
    >
      <Spin spinning={!!loading}>
        {response && (
          <ObjectsTable
            isExpandable
            data={response?.data || []}
            total={response?.total || 0}
            loading={loading}
            current={page}
            onPaginationChange={setPage}
          />
        )}
      </Spin>
    </ModalDialog>
  );
};

export default ViewRoleDetailsModal;
