import React, { useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { TransactionRuleFromQuery } from 'api/approval/approvalManagement/clientTransactionRulesAPI';

// components
import Text from '@core_components/Text';
import HeaderCell from './HeaderCell';
import ContentCell from './ContentCell';
import { Table } from 'antd';

export type Condition = {
  conditions: {
    approvalGroup: string;
    requiredApproversCount: number;
    approvalGroupFull: ConditionApprovalGroup | null;
  }[];

  limitStatuses: { isChecked: boolean }[];
};

export type ConditionApprovalGroup = {
  id: string;
  name: string;
  participants: { userId: string; userFullName: string }[];
};

export type Limit = {
  from: number;
  toInfinite: boolean;
  to?: number;
};

interface IProps {
  record: TransactionRuleFromQuery;
}

const TransactionRuleMatrix = ({ record }: IProps) => {
  const { t } = useTranslation('crm');
  const [formattedData, setFormattedData] = useState<{
    conditions: Condition[];
    limits: Limit[];
  } | null>(null);
  const [remainedLimitsCount, setRemainedLimitsCount] = useState(0);

  useEffect(() => {
    function formatLimitsArray(record: TransactionRuleFromQuery): Limit[] {
      const { approvalRuleSets } = record;
      const limits = approvalRuleSets[0].approvalThreasholds.slice(0, 3);
      return limits.map(({ threasholdMax, threasholdMin }) => {
        const toInfinite = threasholdMax === -1 && threasholdMin === -1;
        return {
          from: Number(threasholdMin),
          to: Number(threasholdMax),
          isUsed: false,
          toInfinite,
        };
      });
    }

    function formatConditionsArray(
      record: TransactionRuleFromQuery,
    ): Condition[] {
      return record.approvalRuleSets.map((ruleSet) => ({
        conditions: ruleSet.approvalRuleItems.map(
          ({ approvalGroup, minimunApprovalThreashold }) => ({
            approvalGroup: approvalGroup.id,
            approvalGroupFull: {
              id: approvalGroup.id,
              name: approvalGroup.name,
              participants: approvalGroup.participants,
            },
            requiredApproversCount: minimunApprovalThreashold,
          }),
        ),
        limitStatuses: ruleSet.approvalThreasholds.map(({ ticked }) => ({
          isChecked: !!ticked,
        })),
      }));
    }

    const remainedLimits =
      record.approvalRuleSets[0].approvalThreasholds.length - 3;
    setFormattedData({
      limits: formatLimitsArray(record),
      conditions: formatConditionsArray(record),
    });
    setRemainedLimitsCount(remainedLimits > 0 ? remainedLimits : 0);
  }, [record]);

  const columns = useMemo(() => {
    if (!formattedData) {
      return [];
    }

    let result = [
      {
        key: 'conditions',
        dataIndex: 'condition',
        width: 300,
      },
    ];

    const numberOfLimitColumns = formattedData.limits.length;

    const limitColumns = Array.from(Array(numberOfLimitColumns), (_, i) => ({
      key: `limit-${i}`,
      width: 250,
      dataIndex: 'limit',
    }));

    result = [...result, ...limitColumns];

    result = result.map((col, index: number) => {
      const baseProps = {
        colIndex: index > 0 ? index - 1 : 0,
        dataIndex: col.dataIndex,
      };

      return {
        ...col,
        onHeaderCell: () => ({
          ...baseProps,
          record: formattedData.limits[index - 1],
        }),
        onCell: (record: Condition, rowIndex: number) => ({
          ...baseProps,
          record,
          isLastRow: rowIndex === formattedData.conditions.length - 1,
        }),
      };
    });

    return result;
  }, [formattedData]);

  return (
    <ContentWrapper>
      <StyledTable
        bordered
        size="small"
        columns={columns}
        className="disable-table-row-hover"
        dataSource={formattedData?.conditions || []}
        pagination={false}
        components={{
          header: { cell: HeaderCell },
          body: { cell: ContentCell },
        }}
      />

      {!!remainedLimitsCount && (
        <StyledTableHelperText variant="body1">
          {`+ ${remainedLimitsCount} ${t(
            remainedLimitsCount > 1
              ? 'client_group.approval.client_transaction_rules.matrix.more_ranges'
              : 'client_group.approval.client_transaction_rules.matrix.more_range',
          )}`}
        </StyledTableHelperText>
      )}
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTable = styled(Table)`
  display: flex;
  overflow: auto;

  .ant-table {
    margin: 0 !important;
    border-bottom: ${({ theme }) => `1px solid ${theme.colorDarkL4}`};

    table {
      width: auto;
    }
  }
`;

const StyledTableHelperText = styled(Text)`
  padding-left: 20px;
  padding-top: 20px;
`;

export default TransactionRuleMatrix;
