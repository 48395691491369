import { IUserProfile } from '../typings/profile/profile';

export default class StoreHelper {
  static store: any;

  static getStore() {
    return this.store.getState();
  }

  static setStore(store: any) {
    this.store = store;
  }

  static getUpload() {
    return this.store.getState().upload;
  }

  static getProfile(): IUserProfile {
    return this.store.getState().auth.profileData;
  }

  static dispatch(f: any) {
    return this.store.dispatch(f);
  }
}
