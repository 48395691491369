import * as yup from 'yup';
import { STRING_VALIDATION_RULE } from 'validations/common';

const noSpecialChars = yup
  .string()
  .trim()
  .matches(/^[a-zA-Z0-9 ]*$/, 'No special characters allowed');

const UploadFileValidationSchema = yup.object().shape({
  originalName: STRING_VALIDATION_RULE.required('This is required field'),
  tags: yup
    .array()
    .of(noSpecialChars)
    .max(20, 'The document can contain a maximum of 20 tags'),
  name: STRING_VALIDATION_RULE.max(
    150,
    'Document name must be less than 150 characters',
  ).required('This is required field'),
  associations: yup.array().of(
    yup.object().shape({
      id: STRING_VALIDATION_RULE.required('This is required field'),
      type: STRING_VALIDATION_RULE.required('This is required field'),
    }),
  ),
});

export const UploadDocumentsValidationSchema = yup.object().shape({
  files: yup.array().of(UploadFileValidationSchema),
});
