import React, { useMemo } from 'react';

// helpers utils
import useTranslation from '../../../../../hooks/useTranslation';
import { NoteMessageType } from '../../../../../enums/crm/crm';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

type IProps = SelectProps<NoteMessageType>;

const NoteTypeSelect = (props: IProps) => {
  const { t } = useTranslation('crm');

  const options = useMemo<SelectOption<NoteMessageType>[]>(() => {
    return Object.values(NoteMessageType).map((key) => ({
      id: key,
      label: t(`entity.notes.types.${key}`),
    }));
  }, []);

  return <FormSelect {...props} options={options} />;
};

export default NoteTypeSelect;
