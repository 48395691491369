import React, { memo } from 'react';

// components
import RDCAlertsTable from './RDCAlertsTable';

const RDCTab = memo(() => {
  return <RDCAlertsTable />;
});

export default RDCTab;
