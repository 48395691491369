import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { securityAPI } from '../../../../../../api/profile/securityAPI';

// components
import SignChallengeWithSecurityKeyModal from '../../../../../../components/Modals/TemplateModalDialogs/Profile/SignChallengeWithSecurityKeyModal';

interface IProps {
  keyId: string | null;
  isVisible: boolean;
  closeCallback: (wasRemoved?: boolean) => void;
}

const DeleteSecurityKeyModal = ({
  keyId,
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('profile');

  const getChallenge = () => {
    return securityAPI.getChallengeForRemovingKey(keyId as string);
  };

  return (
    <SignChallengeWithSecurityKeyModal
      title={t(
        'sign_in_and_security.two_factor_verification.security_key_option.remove_key.title',
      )}
      isVisible={isVisible}
      closeCallback={closeCallback}
      getChallenge={getChallenge}
      submitAssertion={(assertion) =>
        securityAPI.removeFidoKey(keyId as string, assertion)
      }
    />
  );
};

export default DeleteSecurityKeyModal;
