import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';

// components
import { Typography } from 'antd';

const PrimaryStatusText = () => {
  const { t } = useTranslation('common');

  return <PrimaryText>{t('primary')}</PrimaryText>;
};

const PrimaryText = styled(Typography.Text)`
  color: ${({ theme }) => theme.primaryDarkGreen} !important;

  // to make sure this is everywhere in the same format
  text-transform: none !important;
`;

export default PrimaryStatusText;
