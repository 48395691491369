import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../../../../routes/routes';
import { AccountsHelpers } from '@helpers/finance/accounts';

// components
import { Typography } from 'antd';

export type ComplianceOutgoingTransaction = {
  amlReviewProcessId: string;
  amlReviewType: string;
  transactionId: number;
  settledAmount: number;
  currencyIsoCode: string;
  transactionNumber: string;
  orderingCustomerAccountName: string;
  orderingCustomerAccountNumber: string;
  beneficiaryCustomerAccountName: string;
  remittanceInformationLine1: string;
};

interface IProps {
  complianceOutgoingTransactionData: ComplianceOutgoingTransaction;
}

const ComplianceIncomingTransactionDetails = memo(
  ({ complianceOutgoingTransactionData }: IProps) => {
    const { t } = useTranslation('approval_management');
    const history = useHistory();

    return (
      <Typography.Link
        onClick={() =>
          history.push({
            pathname: RoutePaths.Compliance_Transactions_Details,
            search: `?id=${complianceOutgoingTransactionData.amlReviewProcessId}`,
          })
        }
      >
        {t(
          'approval_workflow.approval_workflow_changes.compliance_outgoing_transaction.description',
          {
            fromName:
              complianceOutgoingTransactionData.orderingCustomerAccountName,
            fromNumber:
              complianceOutgoingTransactionData.orderingCustomerAccountNumber,
            toName:
              complianceOutgoingTransactionData.beneficiaryCustomerAccountName,
            line: complianceOutgoingTransactionData.remittanceInformationLine1,
            amount: `${complianceOutgoingTransactionData.currencyIsoCode} ${AccountsHelpers.formatAmountToLocaleString(
              complianceOutgoingTransactionData.settledAmount,
            )}`,
            transactionNumber:
              complianceOutgoingTransactionData.transactionNumber,
          },
        )}
      </Typography.Link>
    );
  },
);

export default ComplianceIncomingTransactionDetails;
