import React from 'react';

// helpers
import { SubRouteModel } from '../../typings/routes';

// components
import VerificationLayout from '../../layouts/VerificationLayout';
import AuthorizedTwoFactorAuthenication from '../../pages/Authentication/AuthorizedTwoFactorAuthenication';
import { Redirect } from 'react-router-dom';

enum VerificationRoutesEnum {
  VerificationRoot = '/verification',
  VerificationFido = '/verification/fido',
}

const verificationRoutes: SubRouteModel[] = [
  {
    title: 'Verification',
    path: VerificationRoutesEnum.VerificationRoot,
    layout: VerificationLayout,
    component: () => <Redirect to={VerificationRoutesEnum.VerificationFido} />,
    childRoutes: [
      {
        title: 'Verification Fido',
        path: VerificationRoutesEnum.VerificationFido,
        layout: VerificationLayout,
        component: AuthorizedTwoFactorAuthenication,
      },
    ],
  },
];

export { verificationRoutes, VerificationRoutesEnum };
