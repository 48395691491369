import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import YesNoSelect from '../../../../../Forms/FormComponents/SelectInputs/YesNoSelect';
import { Input } from 'antd';

const InnerForm = () => {
  const { t } = useTranslation(['finance', 'common']);

  return (
    <>
      <FormField
        name="name"
        label={t('currencies.update.form_fields.name')}
        component={Input}
        disabled
      />
      <FormField
        name="code"
        label={t('currencies.update.form_fields.code')}
        component={Input}
        disabled
      />
      <FormField
        name="currencyId"
        label={t('currencies.update.form_fields.currency_id')}
        component={Input}
        disabled
      />
      <FormField
        name="isActive"
        label={t('currencies.update.form_fields.is_active')}
        component={YesNoSelect}
        placeholder={t('select_option', { ns: 'common' })}
        additionalProps={{ allowClear: true }}
      />
      <FormField
        name="isAccount"
        label={t('currencies.update.form_fields.account_currency')}
        component={YesNoSelect}
        placeholder={t('select_option', { ns: 'common' })}
        additionalProps={{ allowClear: true }}
      />
    </>
  );
};

export default InnerForm;
