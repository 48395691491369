import { Typography } from 'antd';
import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { CorrespondentBankModel } from '../../../../../typings/banking/correspondentBanks';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

interface IProps extends RequiredPropsForTableModel<CorrespondentBankModel> {
  onDetailsClick?: (record: CorrespondentBankModel) => void;
}

const CorrespondentBanksTable = memo(({ onDetailsClick, ...rest }: IProps) => {
  const { t } = useTranslation('banking');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: 250,
        key: 'name',
        title: t('correspondent_banks.table.name'),
        render: (record: CorrespondentBankModel) =>
          onDetailsClick ? (
            <Typography.Link onClick={() => onDetailsClick(record)}>
              {record.name}
            </Typography.Link>
          ) : (
            record.name
          ),
      },

      {
        width: 250,
        key: 'account_currencies',
        title: t('correspondent_banks.table.account_currencies'),
        render: (record: CorrespondentBankModel) =>
          record.holdCurrencies.map(({ id, isoCode }) => (
            <StyledParagraph key={id}>{isoCode}</StyledParagraph>
          )),
      },

      {
        width: 250,
        key: 'transaction_currencies',
        title: t('correspondent_banks.table.transaction_currencies'),
        render: (record: CorrespondentBankModel) =>
          record.transactCurrencies.map(({ id, isoCode }) => (
            <StyledParagraph key={id}>{isoCode}</StyledParagraph>
          )),
      },

      {
        width: 400,
        key: 'accounts',
        title: t('correspondent_banks.table.accounts'),
        render: (record: CorrespondentBankModel) =>
          record.correspondentAccounts.map(
            ({ accountName, accountNumber, currencyCode, balance }) => (
              <StyledParagraph key={accountNumber}>
                {AccountsHelpers.generateAccountFullName(
                  accountName,
                  accountNumber,
                  currencyCode,
                  balance,
                )}
              </StyledParagraph>
            ),
          ),
      },
    ];

    return result;
  }, [onDetailsClick]);

  return <Table {...rest} columns={columns} />;
});

const StyledParagraph = styled(Typography.Paragraph)`
  margin-bottom: 0px;
`;

export default CorrespondentBanksTable;
