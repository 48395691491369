import React, { useMemo } from 'react';

// helpers
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { accountCategoriesAPI } from '../../../../../api/finance/account/accountCategoriesAPI';
import { accountsAdapter } from '../../../../../apiAdapters/finance/account/accountsAdapter';

// components
import AccountCategoryRuleModal, {
  FormValuesModel,
} from '../../../../../components/Modals/TemplateModalDialogs/Finance/AccountCategories';

interface IProps {
  isVisible: boolean;
  closeCallback: (wasAdded?: boolean) => void;
}

const AddAccountCategoryRuleModal = ({ isVisible, closeCallback }: IProps) => {
  const { t } = useTranslation('finance');

  const initialFormValues: FormValuesModel = useMemo(() => {
    return {
      accountCategoryName: '',
      accountCategoryTypeId: 0,
      description: '',
      financialTypeId: 0,
      isClient: undefined,
      parentCategoryId: null,
      financialTypeName: '',
    };
  }, []);

  const handleOnSubmit = async (values: FormValuesModel) => {
    const newAccountCategory =
      accountsAdapter.generateCreateAccountCategoryBody(values);
    await accountCategoriesAPI.createAccountCategory(newAccountCategory);
    message.success(t('account_categories.new.success_create'));
  };

  return (
    <AccountCategoryRuleModal
      title={t('account_categories.new.title')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialFormValues={initialFormValues}
      onSubmit={handleOnSubmit}
    />
  );
};

export default AddAccountCategoryRuleModal;
