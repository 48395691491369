import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../../../../routes/routes';
import { AccountsHelpers } from '@helpers/finance/accounts';

// components
import { Typography } from 'antd';

export type RejectOutgoingTransactionData = {
  transactionId: string;
  settledAmount: number;
  currencyIsoCode: string;
  transactionNumber: string;
  orderingCustomerAccountNumber: string;
  orderingCustomerAccountName: string;
  beneficiaryCustomerAccountName: string;
  remittanceInformationLine1: string;
};

interface IProps {
  data: RejectOutgoingTransactionData;
}

const RejectOutgoingWireTransferDetails = memo(({ data }: IProps) => {
  const { t } = useTranslation('approval_management');
  const history = useHistory();

  return (
    <Typography.Link
      onClick={() =>
        history.push({
          pathname: RoutePaths.Banking_Transactions,
          search: `?id=${data.transactionId}`,
        })
      }
    >
      {t(
        'approval_workflow.approval_workflow_changes.reject_outgoing_transaction.description',
        {
          from1: data.orderingCustomerAccountName,
          from2: data.orderingCustomerAccountNumber,
          to1: data.beneficiaryCustomerAccountName,
          to2: data.remittanceInformationLine1,
          amount: `${data.currencyIsoCode || ''} ${AccountsHelpers.formatAmountToLocaleString(data.settledAmount)}`,
          transactionNumber: data.transactionNumber,
        },
      )}
    </Typography.Link>
  );
});

export default RejectOutgoingWireTransferDetails;
