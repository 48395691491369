import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { ObjectActionModel } from '../../../../../typings/ORAC/objects';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel<ObjectActionModel>;

const ObjectActionsTable = memo((props: IProps) => {
  const { t } = useTranslation('orac');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('objects.view.action_name'),
        key: 'action',
        render: (record: ObjectActionModel) => record.action,
      },
    ];

    return result;
  }, []);

  return <Table {...props} columns={columns} />;
});

export default ObjectActionsTable;
