import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { ObjectBaseModel } from '../../../../../typings/ORAC/objects';

// components
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

interface IProps {
  object: ObjectBaseModel;
}

const ObjectInfo = ({ object }: IProps) => {
  const { t } = useTranslation('orac');

  const descriptionsData = useMemo<DescriptionItem[]>(() => {
    return [
      { label: t('objects.view.id'), description: object.id },
      { label: t('objects.view.name'), description: object.name },
    ];
  }, [object]);

  return (
    <StyledDescriptionSection
      data={descriptionsData}
      bordered={false}
      background="transparent"
    />
  );
};

const StyledDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-container {
    border-bottom: 1px solid ${({ theme }) => theme.tableBorderColor};
    padding-bottom: ${({ theme }) => theme.paddingXs};
  }

  .ant-descriptions-item-label {
    display: flex;
    align-items: end;
  }

  &.ant-descriptions-middle .ant-descriptions-row > td {
    padding-bottom: ${({ theme }) => theme.paddingXs};
  }
`;

export default ObjectInfo;
