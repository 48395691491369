import React, { memo, useEffect } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { Moment } from 'moment';
import { useFormikContext } from 'formik';
import { TWO_COL_GRID_SIZES } from 'constants/grids';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { AddressItemFormValue } from '../AddressForm';
import { IKeyRelationshipTemplate } from 'typings/crm/key-relationship-templates';

// components
import FormInput from '@common_components/Form/FormInput';
import FormField from '@core_components/FormField';
import SectionIntro from '@common_components/Texts/SectionIntro';
import FormDatePicker from '@common_components/Form/FormDatePicker';
import AddressesSection from './AddressesSection';
import CountryFormSelect from 'components/Forms/FormComponents/SelectInputs/CountryFormSelect';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import TrueFalseFormSelect from 'components/Forms/FormComponents/SelectInputs/TrueFalseFormSelect';
import NumberOfOfficesFormSelect from 'components/Forms/FormComponents/SelectInputs/NumberOfOfficesFormSelect';
import NumberOfEmployeesFormSelect from 'components/Forms/FormComponents/SelectInputs/NumberOfEmployeesFormSelect';
import IndustryCodesFormAutocomplete from 'components/Forms/FormComponents/Autocompletes/CRM/IndustryCodesFormAutocomplete';
import EntityTypeCategoryFormAutocomplete, {
  EntityTypeCategoryFormAutocompleteProps,
} from 'components/Forms/FormComponents/Autocompletes/CRM/EntityTypeCategoryFormAutocomplete';
import { Col, Row } from 'antd';

// icons
import WebsiteIcon from '../../../../../resources/images/social-media-icons/website.png';
import TwitterIcon from '../../../../../resources/images/social-media-icons/twitter.png';
import FacebookIcon from '../../../../../resources/images/social-media-icons/facebook.png';
import LinkedInIcon from '../../../../../resources/images/social-media-icons/linkedIn.png';

export type FormValuesModel = {
  legalName: string;
  tradeName: string;
  registrationNumber: string;
  dateOfIncorporation: Moment | null;
  jurisdiction: string | null;
  keyRelationshipTemplate: string | null;
  keyRelationshipTemplateInitialOption?: AutocompleteOption<IKeyRelationshipTemplate>;
  natureOfBusiness: string;
  numberOfEmployees: string | null;
  numberOfOffices: string | null;
  industryCodes: string[] | null;
  isRegulated: boolean | null;
  regulationCountry: string[] | null;
  canIssueBearerShares: boolean | null;
  isVendor: boolean | null;
  primaryAddressId: string | null;
  addresses: AddressItemFormValue[];
  canUpdatePrimaryStatusForAddresses: boolean;
  socialMedia: {
    websiteLink: string;
    twitterLink: string;
    facebookLink: string;
    linkedInLink: string;
  };
};

interface IProps {
  namePrefix?: string;
}

const EntityDetailsForm = memo(({ namePrefix }: IProps) => {
  const { t } = useTranslation('crm');
  const formattedNamePrefix = namePrefix ? `${namePrefix}.` : '';
  const form = useFormikContext<any>();
  const values = namePrefix
    ? (form.values[namePrefix] as FormValuesModel)
    : (form.values as FormValuesModel);

  useEffect(() => {
    if (
      !values.isRegulated &&
      values.regulationCountry &&
      values.regulationCountry.length
    ) {
      form.setFieldValue(`${formattedNamePrefix}regulationCountry`, null);
    }
  }, [values.isRegulated, formattedNamePrefix]);

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('entity.new.entityName')}
            name={`${formattedNamePrefix}legalName`}
            component={FormInput}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('entity.new.tradeName')}
            name={`${formattedNamePrefix}tradeName`}
            component={FormInput}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('entity.new.registration_number')}
            name={`${formattedNamePrefix}registrationNumber`}
            component={FormInput}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('entity.new.dateOfIncorporation')}
            name={`${formattedNamePrefix}dateOfIncorporation`}
            component={StyledFormDatePicker}
            additionalProps={{
              disabledDate: (d: any) => !d || d.isAfter(new Date()),
            }}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('entity.new.country_of_incorporation')}
            name={`${formattedNamePrefix}jurisdiction`}
            component={CountryFormSelect}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('entity.new.industryCodes')}
            name={`${formattedNamePrefix}industryCodes`}
            component={IndustryCodesFormAutocomplete}
            additionalProps={{ mode: 'multiple' }}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField<EntityTypeCategoryFormAutocompleteProps>
            label={t('entity.new.entity_type_category')}
            name={`${formattedNamePrefix}keyRelationshipTemplate`}
            component={EntityTypeCategoryFormAutocomplete}
            additionalProps={{
              initialValue: values.keyRelationshipTemplateInitialOption,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormField
            label={t('entity.new.natureOfBusiness')}
            name={`${formattedNamePrefix}natureOfBusiness`}
            component={FormInputTextArea}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('entity.new.numberOfEmployees')}
            name={`${formattedNamePrefix}numberOfEmployees`}
            component={StyledNumberOfEmployeesFormSelect}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('entity.new.numberOfOffices')}
            name={`${formattedNamePrefix}numberOfOffices`}
            component={StyledNumberOfOfficesFormSelect}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <FormField
            label={t('entity.new.isRegulated')}
            name={`${formattedNamePrefix}isRegulated`}
            component={TrueFalseFormSelect}
          />
        </Col>
        <Col span={12}>
          <FormField
            label={t('entity.new.regulationCountry')}
            name={`${formattedNamePrefix}regulationCountry`}
            component={CountryFormSelect}
            disabled={values.isRegulated !== true}
            additionalProps={{ mode: 'multiple' }}
          />
        </Col>
      </Row>
      <StyledRow gutter={[16, 0]}>
        <Col span={12}>
          <FormField
            label={t('entity.new.canIssueBearerShares')}
            name={`${formattedNamePrefix}canIssueBearerShares`}
            component={TrueFalseFormSelect}
          />
        </Col>
        <Col span={12}>
          <FormField
            label={t('entity.new.is_vendor')}
            name={`${formattedNamePrefix}isVendor`}
            component={TrueFalseFormSelect}
          />
        </Col>
      </StyledRow>
      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('entity.new.addressInformation')}
      />
      <AddressesSection namePrefix={namePrefix} />
      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('entity.new.socialMedia')}
      />
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <SocialMediaIcon src={WebsiteIcon} alt="Website icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name={`${formattedNamePrefix}socialMedia.websiteLink`}
            component={FormInput}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <SocialMediaIcon src={TwitterIcon} alt="Twitter icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name={`${formattedNamePrefix}socialMedia.twitterLink`}
            component={FormInput}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <SocialMediaIcon src={FacebookIcon} alt="Facebook icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name={`${formattedNamePrefix}socialMedia.facebookLink`}
            component={FormInput}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <SocialMediaIcon src={LinkedInIcon} alt="LinkedIn icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name={`${formattedNamePrefix}socialMedia.linkedInLink`}
            component={FormInput}
          />
        </Col>
      </Row>
    </>
  );
});

const StyledFormDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

const StyledNumberOfOfficesFormSelect = styled(NumberOfOfficesFormSelect)`
  width: 100%;
`;

const StyledNumberOfEmployeesFormSelect = styled(NumberOfEmployeesFormSelect)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  align-items: flex-end;
`;

const SocialMediaIcon = styled.img`
  width: 40px;
  height: auto;
`;

export default EntityDetailsForm;
