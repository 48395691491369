import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { message } from 'antd';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { FeeScheduleEntryModel } from '../../../../../../typings/finance/feeSchedule';
import { UpdateFeeScheduleValidationSchema } from '../../../../../../validations/accounting/feeSchedule';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  onSubmit: (values: FormValuesModel) => Promise<void>;
  handleResetToDefault?: (feeScheduleEntry: FeeScheduleEntryModel) => void;
  feeScheduleEntry: FeeScheduleEntryModel | null;
}

export interface FormValuesModel {
  isDefault: boolean;
  entryId: number;
  amount: number;
  currency: string;
  currencyId: number;
  feeTypeId: number;
}

const UpdateFeeScheduleEntryModal = ({
  isVisible,
  closeCallback,
  feeScheduleEntry,
  onSubmit,
  handleResetToDefault,
}: IProps) => {
  const { t } = useTranslation('crm');

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!feeScheduleEntry) {
      return null;
    }

    return {
      isDefault: feeScheduleEntry.isDefault,
      entryId: feeScheduleEntry.id,
      amount:
        AccountsHelpers.convertAmountFromBigIntToInt(feeScheduleEntry.amount) ||
        0,
      currency: feeScheduleEntry.currencyIsoCode,
      currencyId: feeScheduleEntry.currencyId,
      feeTypeId: feeScheduleEntry.feeScheduleTypeId,
    };
  }, [feeScheduleEntry]);

  const handleSubmit = async (values: FormValuesModel) => {
    await onSubmit(values);
    message.success(t('client_group.fee_schedule.update_fee_schedule_success'));
  };

  return (
    <Form<FormValuesModel>
      confirmExitWithoutSaving
      enableReinitialize
      initialValues={initialFormValues}
      validationSchema={UpdateFeeScheduleValidationSchema}
      onSubmit={handleSubmit}
      renderForm={
        initialFormValues && (
          <ModalDialog
            title={t('client_group.fee_schedule.update_fee_schedule')}
            width={500}
            isVisible={isVisible}
            closeCallback={closeCallback}
          >
            <InnerForm
              onResetClick={() =>
                handleResetToDefault &&
                handleResetToDefault(feeScheduleEntry as FeeScheduleEntryModel)
              }
            />
          </ModalDialog>
        )
      }
    />
  );
};

export default UpdateFeeScheduleEntryModal;
