import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { useFormikContext } from 'formik';
import { ForexTransferFormValues } from '.';

// components
import Description from '../../../../Additional/Description';
import AccountInfo from './AccountInfo';
import { Typography } from 'antd';

const ClientRequestSection = () => {
  const { t } = useTranslation('banking');
  const { values } = useFormikContext<ForexTransferFormValues>();

  return (
    <Description layout="vertical" column={2} size="small">
      <Description.Item
        label={
          <StyledLabelText>
            {t('forex.transfer_details.from_sell')}
          </StyledLabelText>
        }
      >
        <AccountInfo
          accountName={values.fromAccountName}
          accountNumber={values.fromAccountNumber}
          accountCurrency={values.fromAccountCurrency}
        />
        {values.isCurrencyFANNcy && (
          <StyledAmountText>
            <StyledAmountLabelText>{`${t('forex.transfer_details.sell')}: `}</StyledAmountLabelText>
            {`${AccountsHelpers.convertAmountBigIntToLocaleString(values.amount)} ${values.currency}`}
          </StyledAmountText>
        )}
      </Description.Item>

      <Description.Item
        label={
          <StyledLabelText>
            {t('forex.transfer_details.to_buy')}
          </StyledLabelText>
        }
      >
        <AccountInfo
          accountName={values.toAccountName}
          accountNumber={values.toAccountNumber}
          accountCurrency={values.toAccountCurrency}
        />

        {!values.isCurrencyFANNcy && (
          <StyledAmountText>
            <StyledAmountLabelText>{`${t('forex.transfer_details.buy')}: `}</StyledAmountLabelText>
            {`${AccountsHelpers.convertAmountBigIntToLocaleString(values.amount)} ${values.currency}`}
          </StyledAmountText>
        )}
      </Description.Item>
    </Description>
  );
};

const StyledLabelText = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.fontSizeSm} !important;
`;

const StyledAmountText = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
  margin-top: ${({ theme }) => theme.marginXs};
  color: ${({ theme }) => theme.primaryGreen};
  font-weight: 300;
  font-size: ${({ theme }) => theme.fontSizeSm};
`;

const StyledAmountLabelText = styled(Typography.Text)`
  color: ${({ theme }) => theme.whiteColor};
  font-weight: 600;
`;

export default ClientRequestSection;
