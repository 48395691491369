import React, { memo, useCallback, useMemo, useState } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import { IEntity } from 'typings/crm/entity';
import { entityAPI } from 'api/crm/entity/entityAPI';
import { entityAdapter } from 'apiAdapters/crm/entity/entityAdapter';
import { EntityDetailsFormValidationSchema } from 'validations/crm/entities';

// components
import Form from '@core_components/Form';
import DeleteEntity from './DeleteEntity';
import FormEditModeButtons from '@common_components/Form/FormEditModeButtons';
import EntityDetailsForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/CRM/EntityDetailsForm';
import { Divider, message } from 'antd';

interface IProps {
  entity: IEntity;
  updateEntityData: () => void;
}

const EntityInformation = memo(({ entity, updateEntityData }: IProps) => {
  const { t } = useTranslation('crm');
  const [isDisabled, setIsDisabled] = useState(true);
  const [hasUpdateAccess, hasDeleteAccess] = useUserAccess([
    'CRM_Entities_Details_DetailsTab_Update',
    'CRM_Entities_Details_DetailsTab_Delete',
  ]);

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    return entityAdapter.formatEntityDataFromQueryToForm(entity);
  }, [entity]);

  const handleSubmit = useCallback(
    async (values) => {
      const formattedBody =
        entityAdapter.formatEntityDataFromFormToQuery(values);
      await entityAPI.editEntity(entity._id, formattedBody);
      message.success(t('entity.new.editSuccess'));
      setIsDisabled(true);
      updateEntityData();
    },
    [entity, updateEntityData],
  );

  return (
    <>
      {initialFormValues && (
        <Form
          disabled={isDisabled}
          enableReinitialize
          confirmExitWithoutSaving
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
          validationSchema={EntityDetailsFormValidationSchema}
          renderForm={
            <>
              {hasUpdateAccess && (
                <FormEditModeButtons
                  isDisabled={isDisabled}
                  setDisabledStatus={setIsDisabled}
                />
              )}
              <EntityDetailsForm />
            </>
          }
        />
      )}
      <Divider />
      {hasDeleteAccess && <DeleteEntity entity={entity} />}
    </>
  );
});

export default EntityInformation;
