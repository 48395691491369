import React, { memo, useEffect, useMemo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import moment, { Moment } from 'moment';
import { SortProps } from '@core_components/Table';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { CrmItemType, NoteRelatedType } from 'enums/crm/crm';
import {
  FetchNotesForDashboardParamsModel,
  noteAPI,
  NoteFromQuery,
} from 'api/note/noteAPI';

// components
import FilterBar from './FilterBar';
import NotesDashboardTable from 'components/Tables/TableTemplates/CRM/NotesDashboardTable';
import NoteModalDialog, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/NoteModalDialog';
import { Divider } from 'antd';

interface IProps {
  hasDataCallback?: (hasData: boolean) => void;
  onFiltersChange?: (filters: FetchNotesForDashboardParamsModel) => void;
}

const DashboardNotesTable = memo(
  ({ onFiltersChange, hasDataCallback }: IProps) => {
    const { t } = useTranslation('crm');
    const [noteId, setNoteId] = useState<string | null>(null);
    const [page, setPage] = useState(1);
    const [sortByCreatedAt, setSortByCreatedAt] = useState<
      'descend' | 'ascend' | null
    >(null);
    const [filter, setFilter] = useState<{
      search: string | undefined;
      date: [Moment, Moment] | undefined;
      type: string | undefined;
      createdBy: string | undefined;
    }>({
      type: undefined,
      date: undefined,
      search: undefined,
      createdBy: undefined,
    });

    const { response, loading } = useFetch(() => {
      const filters = {
        page,
        limit: DEFAULT_TABLE_LIMIT,
        itemType: [
          NoteRelatedType.Contact,
          NoteRelatedType.Organization,
          NoteRelatedType.ClientGroup,
        ].join(','),
        sortBy: sortByCreatedAt ? 'createdAt' : undefined,
        sortDirection: sortByCreatedAt
          ? sortByCreatedAt === 'ascend'
          : undefined,
        search: filter.search,
        fromDate: filter.date
          ? moment(filter.date[0]).toISOString()
          : undefined,
        toDate: filter.date ? moment(filter.date[1]).toISOString() : undefined,
        type: filter.type,
        createdBy: filter.createdBy,
      };

      onFiltersChange && onFiltersChange(filters);

      return noteAPI.fetchNotesForDashboard(filters);
    }, [page, sortByCreatedAt, filter]);

    const { response: noteResponse, loading: noteLoading } = useFetch(
      () => (noteId ? noteAPI.fetchNoteById(noteId) : null),
      [noteId],
    );

    useEffect(() => {
      hasDataCallback && hasDataCallback(!!response?.data?.length);
    }, [response]);

    const handleSortChange = (sort: SortProps) => {
      if (Array.isArray(sort) || sort.order == sortByCreatedAt) {
        return;
      }

      if (sort.order) {
        setSortByCreatedAt(sort.order);
      } else {
        if (sortByCreatedAt) {
          setSortByCreatedAt(null);
        }
      }
    };

    const handleFilterChange = (
      key: 'search' | 'date_range' | 'type' | 'created_by',
      value: any,
    ) => {
      if (page > 1) {
        setPage(1);
      }

      switch (key) {
        case 'search':
          {
            setFilter((prev) => ({
              ...prev,
              search: value ? value.trim() : undefined,
            }));
          }
          break;

        case 'date_range':
          {
            setFilter((prev) => ({ ...prev, date: value || undefined }));
          }
          break;

        case 'type':
          {
            setFilter((prev) => ({ ...prev, type: value || undefined }));
          }
          break;

        case 'created_by':
          {
            setFilter((prev) => ({
              ...prev,
              createdBy: value ? value.trim() : undefined,
            }));
          }
          break;
      }
    };

    const onActionsClick = (key: string, value: NoteFromQuery) => {
      switch (key) {
        case 'view': {
          setNoteId(value._id);
          break;
        }
      }
    };

    const initialFormValues = useMemo<FormValuesModel | null>(() => {
      if (!noteId || !noteResponse) {
        return null;
      }

      return {
        text: noteResponse.text,
        type: noteResponse.type,
        eventDate: moment(noteResponse.createdAt),
        crmItems: noteResponse.sharedWith.map((shared) => ({
          itemId: shared.itemId,
          itemType: shared.itemType as unknown as CrmItemType,
        })),
        documents: noteResponse.documents.map((doc) => ({
          id: doc._id,
          name: doc.name,
        })),
        crmItemsInitialValue: noteResponse.sharedWith.map((shared) => ({
          id: shared.itemId,
          label: shared.name,
        })),
      };
    }, [noteId, noteResponse]);

    return (
      <>
        <FilterBar onFilterChange={handleFilterChange} />
        <Divider />
        <NotesDashboardTable
          current={page}
          loading={loading}
          data={response?.data || []}
          total={response?.total || 0}
          onActionsClick={onActionsClick}
          onPaginationChange={setPage}
          onSortChange={handleSortChange}
          onFilterChange={handleFilterChange}
        />
        <NoteModalDialog
          title={t('notes.view_modal.title')}
          initialValues={initialFormValues}
          isVisible={!!noteId}
          disabled
          loading={noteLoading}
          closeCallback={() => setNoteId(null)}
          onSubmit={console.log}
        />
      </>
    );
  },
);

export default DashboardNotesTable;
