import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';
import { AdditionalFieldType } from '../../../../../../../enums/crm/crm';

// components
import DocumentLink from '../../../../../../../components/Typography/DocumentLink';
import { Col, Divider, Row, Typography } from 'antd';

const AdditionalDocumentsSection = () => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  const renderDocument = (document: any) => {
    return (
      <div>
        <DocumentLink
          key={document.id}
          text={document?.files[0]?.name}
          documentId={document.id}
          fileId={document?.files[0]?.id}
          fileName={document?.files[0]?.name}
        />
      </div>
    );
  };

  const renderValue = (valueJSON: string, fieldType: AdditionalFieldType) => {
    if (!valueJSON) {
      return <Typography.Text>-</Typography.Text>;
    }

    const value = JSON.parse(valueJSON);

    if (fieldType === AdditionalFieldType.Document) {
      return Array.isArray(value)
        ? value.map(renderDocument)
        : renderDocument(value);
    }

    return <Typography.Text>{value}</Typography.Text>;
  };

  return (
    <Row>
      <SectionTitle level={4}>
        {t('client_group.approval.additional_documents')}
      </SectionTitle>
      <StyledDivider />

      {values.additionalDocuments.map((document) => (
        <StyledCol key={document._id} span="12">
          <FieldName>{document.field.name}:</FieldName> <br />
          {renderValue(
            document.answer?.valueJSON as string,
            AdditionalFieldType.Document,
          )}
        </StyledCol>
      ))}
    </Row>
  );
};

const SectionTitle = styled(Typography.Title)`
  margin-top: ${({ theme }) => theme.marginMd};
  margin-bottom: 0 !important;

  &.ant-typography-secondary {
    text-align: center;
  }
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.marginXs} 0;
`;

const StyledCol = styled(Col)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const FieldName = styled.span`
  color: ${({ theme }) => theme.textLightColor3};
`;

export default AdditionalDocumentsSection;
