import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';

// Components
import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';

interface IProps {
  name: string;
  value: string;
  onBlur: any;
  disabled: boolean;
  onChange: (value: any) => void;
  className?: string;
}

const PhoneInputField = (props: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <StyledPhoneInput
      {...props}
      defaultCountry="KY"
      international
      className={props.className ? `${props.className} ant` : 'ant'}
      numberInputProps={{ className: 'ant-input ant-input-lg' }}
      placeholder={t('contacts.new.phoneNumberPlaceholder')}
    />
  );
};

const StyledPhoneInput = styled(PhoneInput)<{ disabled: boolean }>`
  .PhoneInputCountry {
    margin-right: 0px;
    padding: 0px 10px;

    border: ${({ theme }) => `1px solid ${theme.cardBorderColor}`};
    border-right: 0px;
  }
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`;

export default PhoneInputField;
