import React, { useEffect, useState } from 'react';

// helpers
import useUserAccess from '../../../hooks/useUserAccess';
import { PageLayoutHelperPropsModel } from '../../../layouts/PageLayout';

// components
import AddGroup from '../../../modules/ORAC/Groups/AddGroup';
import GroupsTable from '../../../modules/ORAC/Groups/GroupsTable';

type IProps = PageLayoutHelperPropsModel;

const Groups = ({ setHeaderOptions }: IProps) => {
  const [hasCreateAccess] = useUserAccess(['ORAC_Groups_Create']);
  const [updateTableTrigger, updateTable] = useState({});

  useEffect(() => {
    if (hasCreateAccess) {
      setHeaderOptions({
        extra: () => <AddGroup onAdd={() => updateTable({})} />,
      });
    }
  }, [hasCreateAccess]);

  return <GroupsTable updateTableTrigger={updateTableTrigger} />;
};

export default Groups;
