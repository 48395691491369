interface ILang {
  [key: string]: string;
}

export const LocalizationNamespaces = [
  'translation',
  'reporting',
  'finance',
  'orac',
  'compliance',
  'approval_management',
  'crm',
  'common',
  'form',
  'server_errors',
  'auth',
  'profile',
  'banking',
  'page_titles',
];

export const localizationLanguages: ILang = {
  en: 'English',
};
