import React, { useMemo } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import useTranslation from '../../../../../hooks/useTranslation';
import { message } from 'antd';
import { financeTemplateAPI } from '../../../../../api/approval/approvalManagement/financeTemplateAPI';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';

// components
import ApprovalGroupsModal, {
  ApprovalGroupFormValuesModel,
} from '../../../../../components/Modals/TemplateModalDialogs/ApprovalManagement/ApprovalGroupsModal';

interface IProps extends RequiredPropsForModalDialogModel {
  groupId: string;
}

const EditApprovalGroupModal = ({
  isVisible,
  closeCallback,
  groupId,
}: IProps) => {
  const { t } = useTranslation(['approval_management', 'server_errors']);

  const { response } = useFetch(
    () => (groupId ? financeTemplateAPI.fetchApprovalGroupById(groupId) : null),
    [groupId],
  );

  const initialFormValues: ApprovalGroupFormValuesModel | null = useMemo(() => {
    let result = null;

    if (response) {
      const { name, description, participants } = response;
      const initialParticipantOptions = participants.map((e) => ({
        id: e.userId,
        content: e.userFullName,
      }));
      result = { name, description, participants, initialParticipantOptions };
    }

    return result;
  }, [response]);

  const handleFormSubmit = async (newGroup: ApprovalGroupFormValuesModel) => {
    const updatedGroup = { ...newGroup, id: groupId };
    await financeTemplateAPI.editApprovalGroup(updatedGroup);
    message.success(t('approval_management.approval_groups.success_edit'));
  };

  return (
    <ApprovalGroupsModal
      isEditMode
      title={t('approval_management.approval_groups.edit')}
      onSubmit={handleFormSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      loadingInitialData={!initialFormValues}
    />
  );
};

export default EditApprovalGroupModal;
