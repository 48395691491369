import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { useReviewProcessContext } from '../../../../../hooks/domainHooks/useReviewProcess';

// components
import ViewApprovalMatrixModal from './ViewApprovalMatrixModal';
import { Typography } from 'antd';

const ApprovalMatrix = () => {
  const { t } = useTranslation('compliance');
  const { workflow } = useReviewProcessContext();
  const [isApprovalMatrixVisible, setIsApprovalMatrixVisible] = useState(false);

  return (
    <>
      <Typography.Link onClick={() => setIsApprovalMatrixVisible(true)}>
        {t('transactions.view_approval_matrix')}
      </Typography.Link>
      {workflow && (
        <ViewApprovalMatrixModal
          instanceId={workflow.id}
          isVisible={isApprovalMatrixVisible}
          closeCallback={() => setIsApprovalMatrixVisible(false)}
        />
      )}
    </>
  );
};

export default ApprovalMatrix;
