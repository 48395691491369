import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { IEntity } from 'typings/crm/entity';
import { ContactModel } from 'typings/crm/relationships';
import { relationshipAPI } from 'api/crm/relationship/relationshipAPI';
import { RelationshipHelpers } from '@helpers/crm/relationship';
import { relationshipAdapter } from 'apiAdapters/crm/relationship/relationshipAdapter';
import { GraphEdge, GraphNode } from 'components/Charts/GraphChart';
import { AdditionalFieldHelpers } from '@helpers/additionalField';
import { AdditionalFieldFormItemModel } from 'components/Forms/FormComponents/AdditionalFieldNew';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';
import { OnboardType, RelationshipTrackType } from 'enums/crm/crm';

// components
import EditNodeModalDialog, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/EditNodeModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  isViewOnly: boolean;
  clientGroupId: string;
  data: {
    activeScopeId: string;
    node: GraphNode;
    edges: GraphEdge[];
    clientGroupId: string;
    allNodes: GraphNode[];
  } | null;
}

const EditNodeModal = ({
  isVisible,
  clientGroupId,
  closeCallback,
  isViewOnly,
  data,
}: IProps) => {
  const { t } = useTranslation('crm');

  const modalTitle = useMemo(() => {
    if (!data) {
      return '';
    }

    const title =
      data.node.entryType === OnboardType.Contact
        ? t('client_group.structure.node_modal.title.for_contact')
        : t('client_group.structure.node_modal.title.for_organization');

    return title;
  }, [data]);

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!data || !isVisible) {
      return null;
    }

    const { node, edges, clientGroupId, allNodes, activeScopeId } = data;
    const entity = node.model as IEntity;
    const contact = node.model as ContactModel;

    const hashNodes = allNodes.reduce<Record<string, GraphNode>>((acc, cur) => {
      acc[cur.id] = { ...cur };
      return acc;
    }, {});

    const relationships = node.isApplicantOrganization
      ? []
      : edges.map((e) => {
          const { relationship, relationshipTemplate } = e;

          const parentNode = hashNodes[e.target];
          const childNode = hashNodes[e.source];

          const isApplicantRelationship =
            (hashNodes[e.target].isApplicantContact ||
              hashNodes[e.target].isApplicantOrganization) &&
            (hashNodes[e.source].isApplicantContact ||
              hashNodes[e.source].isApplicantOrganization);

          const additionalFields: AdditionalFieldFormItemModel[] =
            AdditionalFieldHelpers.filterAndMapClientAdditionalFieldsFromTemplateToFormModel(
              relationshipTemplate,
              childNode.entryType,
              relationship,
            );

          return {
            customId: relationship._id,
            id: relationship._id,
            isApplicantRelationship,

            relationshipTemplate: {
              id: relationshipTemplate._id,
              initialOption: {
                id: relationshipTemplate._id,
                label: RelationshipHelpers.getRelationshipName(
                  {
                    parentName: relationshipTemplate.parentName,
                    childName: relationshipTemplate.childName,
                  },
                  RelationshipTrackType.Parent,
                ),
              },
              childRelationshipLabel: RelationshipHelpers.getRelationshipName(
                {
                  parentName: relationshipTemplate.parentName,
                  childName: relationshipTemplate.childName,
                },
                RelationshipTrackType.Child,
              ),
            },

            parent: {
              id: childNode.id,
              type: childNode.entryType,
            },

            child: {
              id: parentNode.id,
              type: parentNode.entryType,
              label: parentNode.label,

              autocompleteInitialValue: {
                id: parentNode.id,
                label: parentNode.label,
              },
            },

            additionalFields,
          };
        });

    // User should be able able to update node info only when the node is new (was not created before)
    return {
      activeScopeId,
      clientGroupId,
      clientUserId: contact?.onlineAccess?.clientGroupUserId || null,
      entryType: node.entryType,
      hideRelationshipsSection: node.isApplicantOrganization,
      disableNodeForm: node.sharedData,

      parentForNewRelationships: {
        id: node.id,
        type: node.entryType,
        autocompleteInitialValue: {
          id: node.id,
          content: node.label,
          model: node.model,
        },
      },

      contact:
        node.entryType === OnboardType.Contact
          ? {
              isPermissionsSelectAvailable: false,
              id: contact._id,
              firstName: contact.firstName,
              middleName: contact.middleName,
              lastName: contact.lastName,
              isPEP: typeof contact.isPEP === 'boolean' ? contact.isPEP : null,
              pepInformation: contact.pepInformation,
              isOnlineUser: !!contact.onlineAccess,
              adminPermissionType: contact.onlineAccess
                ? contact.onlineAccess.adminPermissionType
                : null,
              accountPermissions: contact.onlineAccess
                ? contact.onlineAccess.permissionType
                : null,
              phone: contact.phoneNumbers.length
                ? contact.phoneNumbers.find((e) => e.isPrimary)?.number || ''
                : '',
              email: contact.emails.length
                ? contact.emails.find((e) => e.isPrimary)?.address || ''
                : '',
            }
          : null,

      organization:
        node.entryType === OnboardType.Organization
          ? {
              id: entity._id,
              name: entity.names[0].name,
              isRegulated:
                typeof entity.isRegulated === 'boolean'
                  ? entity.isRegulated
                  : null,
              regulatedCountries: entity.regulationCountry,
            }
          : null,
      keyRelationshipTemplateId: node.model.keyRelationshipTemplateId as string,
      clientGroupEntryType: OnboardType.Organization,
      canAddOwnerships: true,
      relationships,
    };
  }, [isVisible, data]);

  const handleSubmit = async (values: FormValuesModel) => {
    if (initialFormValues) {
      const formattedRequestBody =
        relationshipAdapter.generateRequestBodyForEditNode(
          values,
          initialFormValues,
        );
      await relationshipAPI.updateRelationships(
        formattedRequestBody,
        clientGroupId,
      );
      message.success(
        t('client_group.structure.relationships.edit_modal.success_edit'),
      );
    }
  };

  return (
    <EditNodeModalDialog
      disabled={isViewOnly}
      title={modalTitle}
      onSubmit={handleSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
    />
  );
};

export default EditNodeModal;
