import React, { memo } from 'react';

// helpers
import { SelectProps } from '@core_components/Select';

// components
import RangeFormSelect, { RangeOption } from '../RangeFormSelect';

export const NUMBER_OF_OFFICES_ARRAY: RangeOption[] = [
  {
    min: 1,
    max: 1,
    key: 'numberOfOffices1',
  },
  {
    min: 2,
    max: 2,
    key: 'numberOfOffices2',
  },
  {
    min: 3,
    max: 3,
    key: 'numberOfOffices3',
  },
  {
    min: 4,
    max: 6,
    key: 'numberOfOffices4',
  },
  {
    min: 7,
    max: 10,
    key: 'numberOfOffices5',
  },
  {
    min: 11,
    max: 15,
    key: 'numberOfOffices6',
  },
  {
    min: 16,
    max: 25,
    key: 'numberOfOffices7',
  },
  {
    min: 26,
    max: 49,
    key: 'numberOfOffices8',
  },
  {
    min: 50,
    key: 'numberOfOffices9',
  },
];

export type NumberOfOfficesFormSelectProps = SelectProps;

const NumberOfOfficesFormSelect = memo(
  (props: NumberOfOfficesFormSelectProps) => {
    return (
      <RangeFormSelect rangeOptions={NUMBER_OF_OFFICES_ARRAY} {...props} />
    );
  },
);

export default NumberOfOfficesFormSelect;
