import React, { useMemo } from 'react';

// helpers
import { compareObjects } from '@helpers/utils';
import { HighlightFieldStatuses } from '@core_components/FormField';
import { FetchContactWithChangeRequestResponse } from 'api/crm/changeRequests/changeRequestsAPI';

// components
import Form from '@core_components/Form';
import SOWForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/CRM/SOWForm';

interface IProps {
  data: FetchContactWithChangeRequestResponse;
}

const SOWTab = ({ data }: IProps) => {
  const initialValues = useMemo<{
    initialFormValues: FormValuesModel;
    modifiedValues?: Record<string, HighlightFieldStatuses>;
  } | null>(() => {
    const formatDataToFormValues = (data: {
      sourceOfWealthCategories: string[];
      sourceOfWealthDescription: string;
      employmentInformation: {
        isSelfEmployed: boolean | null;
        employerName: string;
        occupation: string;
        natureOfBusiness: string;
        isBussinessUnderOwnName: boolean | null;
      };
    }): FormValuesModel => {
      return {
        sourceOfWealthCategories: data.sourceOfWealthCategories,
        sourceOfWealthDescription: data.sourceOfWealthDescription,
        employmentHistory: [
          {
            employerName: data.employmentInformation.employerName || '',
            occupation: data.employmentInformation.occupation || '',
            natureOfBusiness: data.employmentInformation.natureOfBusiness || '',
            isSelfEmployed:
              typeof data.employmentInformation.isSelfEmployed === 'boolean'
                ? data.employmentInformation.isSelfEmployed
                : null,
            isBusinessUnderOwnName:
              typeof data.employmentInformation.isBussinessUnderOwnName ===
              'boolean'
                ? data.employmentInformation.isBussinessUnderOwnName
                : null,
          },
        ],
      };
    };

    const { contact, clientChangeRequest } = data;

    const formValuesFromActiveVersion = formatDataToFormValues({
      sourceOfWealthCategories: contact.sourceOfWealthCategories,
      sourceOfWealthDescription: contact.sourceOfWealthDescription,
      employmentInformation: {
        isSelfEmployed: contact.employmentInformation?.isSelfEmployed,
        employerName: contact.employmentInformation?.employerName || '',
        occupation: contact.employmentInformation?.occupation || '',
        natureOfBusiness: contact.employmentInformation?.natureOfBusiness || '',
        isBussinessUnderOwnName:
          contact.employmentInformation?.isBussinessUnderOwnName,
      },
    });

    if (
      clientChangeRequest &&
      clientChangeRequest?.changes?.contactKYCChange?.sow
    ) {
      const employmentInformation =
        clientChangeRequest.changes.contactKYCChange.sow.employmentInformation;

      const formValuesFromNewVersion = formatDataToFormValues({
        sourceOfWealthCategories:
          clientChangeRequest.changes.contactKYCChange.sow
            .sourceOfWealthCategories,
        sourceOfWealthDescription:
          clientChangeRequest.changes.contactKYCChange.sow
            .sourceOfWealthDescription,
        employmentInformation: {
          isSelfEmployed: employmentInformation.isSelfEmployed,
          employerName: employmentInformation.employerName || '',
          occupation: employmentInformation.occupation || '',
          natureOfBusiness: employmentInformation.natureOfBusiness || '',
          isBussinessUnderOwnName:
            employmentInformation.isBussinessUnderOwnName,
        },
      });

      return {
        initialFormValues: formValuesFromNewVersion,
        modifiedValues:
          data.clientChangeRequest?.type == 'contact-update-kyc'
            ? compareObjects(
                formValuesFromActiveVersion,
                formValuesFromNewVersion,
              )
            : undefined,
      };
    } else {
      return {
        initialFormValues: formValuesFromActiveVersion,
      };
    }
  }, [data]);

  return (
    <>
      {initialValues && (
        <Form<FormValuesModel>
          disabled
          onSubmit={console.log}
          initialValues={initialValues?.initialFormValues}
          renderForm={SOWForm}
          highlightFields={initialValues.modifiedValues}
        />
      )}
    </>
  );
};

export default SOWTab;
