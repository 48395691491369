import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { useField } from 'formik';
import { darkTheme } from '@resources/theme/styled';
import { SelectOption } from '@core_components/Select';
import { AdditionalFieldType } from 'enums/crm/crm';
import { EntityTypeCategoryAdditionalField } from '..';

// components
import Text from '@core_components/Text';
import Checkbox from '@core_components/Checkbox';
import FormField from '@core_components/FormField';
import FormSelect from '@common_components/Form/FormSelect';
import DocumentsField from 'components/Forms/FormComponents/DocumentsField';

interface IProps {
  baseFieldName: string;
}

const AdditionalFieldOptions = memo(({ baseFieldName }: IProps) => {
  const { t } = useTranslation('crm');
  const [field] = useField<EntityTypeCategoryAdditionalField>(baseFieldName);

  const content = useMemo(() => {
    const additionalFieldsThatRequireOptions = [
      AdditionalFieldType.Form,
      AdditionalFieldType.Select,
      AdditionalFieldType.Document,
    ];

    if (!field.value || !field.value.type) {
      return null;
    }

    if (!additionalFieldsThatRequireOptions.includes(field.value.type)) {
      return (
        <Text color={darkTheme.tableHeadTextColor}>
          {t('entity_type_category.new.field_does_not_require_options')}
        </Text>
      );
    }

    switch (field.value.type) {
      case AdditionalFieldType.Form:
        return (
          <FormField
            label={t(
              'entity_type_category.new.form_fields.additional_fields.form_document',
            )}
            name={`${baseFieldName}.formDocument`}
            component={DocumentsField}
            additionalProps={{ isMultiple: false }}
          />
        );

      case AdditionalFieldType.Select: {
        const options: SelectOption[] = field.value.selectOptions.map((e) => ({
          id: e,
          label: e,
        }));
        return (
          <FormField
            label={t(
              'entity_type_category.new.form_fields.additional_fields.select_options',
            )}
            name={`${baseFieldName}.selectOptions`}
            component={FormSelect}
            additionalProps={{
              options,
              mode: 'tags',
              dropdownStyle: { display: 'none' },
            }}
          />
        );
      }
      case AdditionalFieldType.Document:
        return (
          <FormField
            label={t(
              'entity_type_category.new.form_fields.additional_fields.require_certification',
            )}
            name={`${baseFieldName}.requireCertification`}
            component={Checkbox}
          />
        );
    }
  }, [t, field.value]);

  return <>{content}</>;
});

export default AdditionalFieldOptions;
