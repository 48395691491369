import React from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../../../hooks/useFetch';
import useUserAccess from '../../../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { RoutePaths } from '../../../../../../../routes/routes';
import { accountsAPI } from '../../../../../../../api/crm/onboarding/accountsAPI';
import { ClientStatus } from '../../../../../../../enums/crm/crm';
import { COUNTRY_CODES } from '../../../../../../../constants/countryCodes';
import { AccountsHelpers } from '../../../../../../../helpers/finance/accounts';
import { IOnboardingAccount } from '../../../../../../../typings/finance/account';
import { IClientGroupResponse } from '../../../../../../../api/crm/clientGroup/clientGroupsAPI';

// components
import Link from '../../../../../../../components/Typography/Link';
import BankAccountStatus from 'components/Additional/Statuses/BankAccountStatus';
import DescriptionSection from '@core_components/DescriptionSection';
import { Typography, Divider, Space, Spin } from 'antd';

interface IProps {
  clientGroupData: IClientGroupResponse;
}

const AccountsTab = ({ clientGroupData }: IProps) => {
  const { t } = useTranslation(['crm', 'common']);
  const [hasAccountDetailsViewAccess, hasBankAccountsViewAccess] =
    useUserAccess([
      'Finance_Accounts_Details_View',
      'Banking_BankAccounts_View',
    ]);

  const { response, loading } = useFetch(() => {
    const { _id, status, numericId } = clientGroupData.clientGroup;

    if (status === ClientStatus.Approved) {
      return accountsAPI.fetchAccountsSupplementary({
        clientGroupId: numericId,
        page: 1,
        limit: 100,
      });
    }

    return accountsAPI.fetchAccounts(_id);
  }, [clientGroupData]);

  if (loading || !response) {
    return (
      <ContentWrapper>
        <Space size="middle">
          <Spin size="large" />
        </Space>
      </ContentWrapper>
    );
  }

  if (!response.length) {
    return (
      <SectionTitle level={4} type="secondary">
        {t('client_group.accounts.no_accounts_created')}
      </SectionTitle>
    );
  }

  const renderMinMaxField = (field: any) => {
    const formattedMin = AccountsHelpers.formatAmountToLocaleString(field?.min);
    const formattedMax = AccountsHelpers.formatAmountToLocaleString(field?.max);

    if (!formattedMin && formattedMax) {
      return `< ${formattedMax}`;
    }

    if (!formattedMax && formattedMin) {
      // Just to have the similar way of showing option value as we have on client application
      if (
        field.key.includes('initialDeposit') ||
        field.key.includes('amount')
      ) {
        return `${formattedMin} ${t('or_more', { ns: 'common' })}`;
      } else {
        return `>${formattedMin}`;
      }
    }

    return `${formattedMin} - ${formattedMax}`;
  };

  const renderAccount = (account: IOnboardingAccount, index: number) => {
    const correspondentAccount = account.correspondentBankName
      ? AccountsHelpers.generateAccountFullName(
          account.correspondentBankName,
          account.correspondentBankAccountNumber || '',
          '',
        )
      : account.correspondentBankAccountNumber;

    return (
      <React.Fragment key={index}>
        <SectionTitle level={4}>
          {t('client_group.accounts.title', { index })}
        </SectionTitle>
        <StyledDescriptionSection
          size="small"
          bordered={false}
          background="transparent"
          data={[
            {
              label: t('client_group.accounts.type'),
              description: t(`application.account_info.types.${account.type}`),
            },
            {
              label: t('client_group.accounts.currency'),
              description: `${account.currencyIsoCode} (${account.currencyName})`,
            },
            {
              hidden: !account.fullAccountNumber,
              label: t('client_group.accounts.full_account_number'),
              description: hasAccountDetailsViewAccess ? (
                <Link
                  to={{
                    pathname: RoutePaths.Finance_Account_Details,
                    search: `?id=${account.fullAccountNumber}`,
                  }}
                >
                  {account.fullAccountNumber}
                </Link>
              ) : (
                account.fullAccountNumber
              ),
            },
            {
              hidden: !account.number,
              label: t('client_group.accounts.client_account_number'),
              description: hasBankAccountsViewAccess ? (
                <Link
                  to={{
                    pathname: RoutePaths.Banking_BankAccounts,
                    search: `?accountNumber=${account.number}`,
                  }}
                >
                  {account.number}
                </Link>
              ) : (
                account.number
              ),
            },
            {
              hidden: !account.status,
              label: t('client_group.accounts.status'),
              description: account.status ? (
                <BankAccountStatus status={account.status} />
              ) : (
                account.status
              ),
            },
            {
              label: t('client_group.accounts.purpose_of_account'),
              description: account.purpose,
            },
            {
              label: t('client_group.accounts.initial_deposit'),
              description: renderMinMaxField(account.initialDeposit),
            },
            {
              label: t('client_group.accounts.initial_deposit_origin'),
              description:
                account?.initialDepositOrigin &&
                COUNTRY_CODES[account.initialDepositOrigin],
            },
            {
              hidden: !account.correspondentBankAccountNumber,
              label: t('client_group.accounts.correspondent_bank'),
              description: hasAccountDetailsViewAccess ? (
                <Link
                  to={{
                    pathname: RoutePaths.Finance_Account_Details,
                    search: `?id=${account.correspondentBankAccountNumber}`,
                  }}
                >
                  {correspondentAccount}
                </Link>
              ) : (
                correspondentAccount
              ),
            },
          ]}
        />
        <SectionTitle level={4}>
          {t('client_group.accounts.estimated_monthly_activity')}
        </SectionTitle>
        <DescriptionSection
          size="small"
          bordered={false}
          background="transparent"
          data={[
            {
              label: t('client_group.accounts.jurisdictions'),
              description: account.jurisdictions
                ?.map((j) => COUNTRY_CODES[j])
                .join(', '),
            },
            {
              label: t('client_group.accounts.estimated_incoming_funds'),
              description: renderMinMaxField(account.estimatedIncomingFunds),
            },
            {
              label: t('client_group.accounts.estimated_outgoing_funds'),
              description: renderMinMaxField(account.estimatedOutgoingFunds),
            },
            {
              label: t('client_group.accounts.incoming_wires_number'),
              description: renderMinMaxField(account.incomingWiresNumber),
            },
            {
              label: t('client_group.accounts.outgoing_wires_number'),
              description: renderMinMaxField(account.outgoingWiresNumber),
            },
          ]}
        />
        {index !== response.length && <Divider />}
      </React.Fragment>
    );
  };

  return (
    <>{response.map((account, index) => renderAccount(account, index + 1))}</>
  );
};

const SectionTitle = styled(Typography.Title)`
  &.ant-typography-secondary {
    text-align: center;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

const StyledDescriptionSection = styled(DescriptionSection)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default AccountsTab;
