import React, { useState } from 'react';

// ** Helpers & Utils **
import useTranslation from '../../../../hooks/useTranslation';

// ** Components **
import { Button } from 'antd';
import AddUserModal from './AddUserModal';

interface IProps {
  onAdd?: () => void;
}

const AddObject = ({ onAdd }: IProps) => {
  const { t } = useTranslation('orac');

  const [isModalVisible, setVisibleModal] = useState(false);

  const closeCallback = (wasAdded?: boolean) => {
    if (wasAdded) {
      onAdd && onAdd();
    }
    setVisibleModal(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setVisibleModal(true)}>
        {t('users.add.title')}
      </Button>
      <AddUserModal isVisible={isModalVisible} closeCallback={closeCallback} />
    </>
  );
};

export default AddObject;
