import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { FieldValidationRule } from '@core_components/FormField';

// components
import DatePicker, { DatePickerProps } from '@core_components/DatePicker';

interface FormDatePickerProps extends DatePickerProps {
  validationRules?: FieldValidationRule[];
}

const FormDatePicker = memo(
  ({ validationRules, ...rest }: FormDatePickerProps) => {
    const { t } = useTranslation('form');

    const placeholder = useMemo(() => {
      if (!validationRules || !validationRules.length) {
        return t('field_placeholders.select_date_optional');
      }

      const isRequired = validationRules.find((e) => e.type === 'required');

      if (isRequired) {
        return t('field_placeholders.select_date_required');
      } else {
        return t('field_placeholders.select_date_optional');
      }
    }, [validationRules]);

    return <DatePicker {...rest} placeholder={placeholder} />;
  },
);

export default FormDatePicker;
