export enum TransactionTypes {
  Incoming = 'INCOMING_WIRE',
  Outgoing = 'OUTGOING_WIRE',
}

export enum ClientTransactionTypes {
  InternalTransfer = 1,
  WireTransfer,
  BlockchainTemplate,
}

export enum TransactionReviewStatuses {
  PendingApproval = 1,
  Completed,
  Rejected,
  Processing,
  InReview,
  AMLReview,
  Failed,
  Cancelled,
  Suspense,
  PendingRDC,
  PendingReturn,
  Returned,
  PendingTransmit,
  BankRejected,
  PendingReject,
  Scheduled,
}

export enum BankOperationsCodes {
  CRED = 'CRED',
  CRTS = 'CRTS',
  SPAY = 'SPAY',
  SPRI = 'SPRI',
  SSTD = 'SSTD',
}

export enum DetailsOfChargesCodes {
  OUR = 'OUR',
  BEN = 'BEN',
  SHA = 'SHA',
}
