import React from 'react';

// helpers
import { industryCodesAPI } from 'api/crm/industryCodes/industryCodes';
import { IndustryCodeModel } from 'typings/crm/entity';
import {
  AutocompleteOption,
  AutocompleteProps,
} from '@core_components/Autocomplete';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export type IndustryCodesFormAutocompleteProps =
  AutocompleteProps<IndustryCodeModel>;

const IndustryCodesFormAutocomplete = (
  props: IndustryCodesFormAutocompleteProps,
) => {
  const fetchIndustryCodes = async (search: string) => {
    const { data } = await industryCodesAPI.fetchIndustryCodes({
      search,
      limit: -1,
    });
    return data.map<AutocompleteOption<IndustryCodeModel>>((e) => ({
      id: e.code,
      label: e.code,
      model: e,
    }));
  };

  return <FormAutocomplete {...props} fetchData={fetchIndustryCodes} />;
};

export default IndustryCodesFormAutocomplete;
