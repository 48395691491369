import React, { useMemo } from 'react';

// helpers
import rolesAPI from '../../../../../../api/orac/rolesAPI';
import useFetch from '../../../../../../hooks/useFetch';
import useTranslation from '../../../../../../hooks/useTranslation';
import permissionsAdapter from '../../../../../../apiAdapters/orac/permissionsAdapter';
import { PermissionsHelpers } from '@helpers/permissions';
import {
  PermissionBaseModel,
  PermissionType,
} from '../../../../../../typings/ORAC/permissions';
import {
  ObjectActionWithPermissionsModel,
  ObjectWithPermissionsModel,
} from '../../../../../../typings/ORAC/objects';
import { message } from 'antd';
import {
  FormValuesModel,
  SelectedActionModel,
  SelectedObjectModel,
  SelectedPermissionModel,
} from '../../../../../../components/Modals/TemplateModalDialogs/ORAC/Permissions/EditPermissionsModal';

// components
import { default as EditPermissionsRootModal } from '../../../../../../components/Modals/TemplateModalDialogs/ORAC/Permissions/EditPermissionsModal';

interface IProps {
  roleId: string;
  isVisible: boolean;
  closeCallback: (wasUpdated?: boolean) => void;
}

const EditPermissionsModal = ({ isVisible, closeCallback, roleId }: IProps) => {
  const { t } = useTranslation('orac');

  const { response, loading } = useFetch(
    () =>
      isVisible
        ? rolesAPI.fetchPermissionsByRoleId(roleId, { page: 1, limit: -1 })
        : null,
    [roleId, isVisible],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    let result: FormValuesModel | null = null;

    if (response) {
      result = { selectedPermissions: {} };

      // Convert from an array into object format with keys and values
      result.selectedPermissions = response.data.reduce<
        Record<string, SelectedObjectModel>
      >((acum, current: ObjectWithPermissionsModel) => {
        acum[current.id] = {
          ...current,
          actions: current.actions.reduce<Record<string, SelectedActionModel>>(
            (acum, current: ObjectActionWithPermissionsModel) => {
              acum[current.id] = {
                ...current,
                permissions: current.permissions.reduce<
                  Record<string, SelectedPermissionModel>
                >((acum, current: PermissionBaseModel) => {
                  acum[current.id] = {
                    ...current,
                    permissionType: PermissionsHelpers.getPermissionType(
                      current,
                    ) as PermissionType,
                  };
                  return acum;
                }, {}),
              };
              return acum;
            },
            {},
          ),
        };

        return acum;
      }, {});
    }

    return result;
  }, [response]);

  const onSubmit = async (formValues: FormValuesModel) => {
    const body = permissionsAdapter.formatAddEditPermissions(
      formValues,
      initialFormValues as FormValuesModel,
    );
    await rolesAPI.editPermissionsByRoleId(roleId, body);
    message.success(t('roles.view.edit_permissions_success'));
  };

  return (
    <EditPermissionsRootModal
      onSubmit={onSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialFormValues={initialFormValues}
      loadingInitialData={loading}
    />
  );
};

export default EditPermissionsModal;
