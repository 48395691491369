import React from 'react';

// helpers
import styled from 'styled-components';

// components
import { Image } from 'antd';

interface IProps {
  imageSrc: string;
}

const ImageCard = ({ imageSrc }: IProps) => {
  return (
    <ImageWrapper>
      <Image width={250} src={imageSrc} />
    </ImageWrapper>
  );
};

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  width: 250px;
  height: 170px;

  cursor: pointer;

  border-radius: ${({ theme }) => theme.borderRadiusRegular};
  border: ${({ theme }) => `1px solid ${theme.cardBorderColor}`};
`;

export default ImageCard;
