import React from 'react';

// helpers
import usersAPI from '../../../../../../api/orac/usersAPI';
import { UsersBaseModel } from 'typings/ORAC/users';
import { StyledComponentProps } from 'typings/common';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import Autocomplete, {
  AutocompleteOption,
  AutocompleteProps,
} from '@core_components/Autocomplete';

interface IProps
  extends AutocompleteProps<UsersBaseModel>,
    StyledComponentProps {}

const UsersAutocomplete = (props: IProps) => {
  const fetchUsers = async (searchQuery: string) => {
    const { data } = await usersAPI.fetchUsers({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      name: searchQuery,
    });

    return data.map<AutocompleteOption<UsersBaseModel>>((e) => ({
      id: e._id,
      label: e.name,
      model: e,
    }));
  };

  return <Autocomplete {...props} fetchData={fetchUsers} />;
};

export default UsersAutocomplete;
