import React from 'react';

// helpers
import { BankAccountValidationSchema } from 'validations/accounting/bankAccounts';
import { FormValuesModel as AccountSupplementaryFormValues } from 'components/Forms/TemplateForms/Finance/AccountSupplementaryForm';

// components
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import BankAccountForm, {
  FormValuesModel as TemplateFormValuesModel,
} from 'components/Forms/TemplateForms/Finance/BankAccountForm';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
}

export interface FormValuesModel extends TemplateFormValuesModel {
  accountSupplementary: AccountSupplementaryFormValues;
}

const BankAccountModal = ({
  title,
  isVisible,
  initialValues,
  onSubmit,
  closeCallback,
  onSubmitError,
}: IProps) => {
  return (
    <Form
      enableReinitialize
      onSubmit={onSubmit}
      onSubmitError={onSubmitError}
      initialValues={isVisible ? initialValues : null}
      validationSchema={BankAccountValidationSchema}
      renderForm={
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <BankAccountForm />
        </ModalDialog>
      }
    />
  );
};

export default BankAccountModal;
