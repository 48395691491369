import React, { useState } from 'react';

// helpers
import usersAPI from '../../../../../../api/orac/usersAPI';
import useFetch from '../../../../../../hooks/useFetch';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../../../constants/global';

// components
import FilterBar from '../FilterBar';
import ViewRoleDetailsModal from '../../../../../../components/Modals/TemplateModalDialogs/ORAC/Roles/ViewRoleDetailsModal';
import { default as TemplateRolesTable } from '../../../../../../components/Tables/TableTemplates/ORAC/RolesTable';

import { Divider } from 'antd';

interface IProps {
  userId: string;
}

const RolesTable = ({ userId }: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchByName, setSearchByName] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);

  const { response, loading } = useFetch(
    () =>
      usersAPI.fetchInheritedRoles(userId, {
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        name: searchByName,
      }),
    [currentPage, searchByName],
  );

  const handleFilterBarChange = (key: string, value: string) => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }

    if (key === 'search_by_name') {
      setSearchByName(value || '');
    }
  };

  const handleModalClose = () => {
    setSelectedRoleId(null);
  };

  return (
    <>
      <FilterBar onFilterChange={handleFilterBarChange} />

      <Divider />

      <TemplateRolesTable
        showInheritedColumn
        data={response?.data || []}
        total={response?.total || 0}
        current={currentPage}
        loading={loading}
        onPaginationChange={setCurrentPage}
      />

      <ViewRoleDetailsModal
        roleId={selectedRoleId as string}
        isVisible={!!selectedRoleId}
        closeCallback={handleModalClose}
      />
    </>
  );
};

export default RolesTable;
