import React, { useEffect, useState } from 'react';

// helpers
import { Moment } from 'moment';
import { PageLayoutHelperPropsModel } from 'layouts/PageLayout';

// components
import IncomeStatementReport from '../../modules/Reporting/IncomeStatementReport';
import ExportIncomeStatementToExcelButton from 'components/Additional/ExportIncomeStatementToExcelButton';

type IProps = PageLayoutHelperPropsModel;

const IncomeStatement = ({ setHeaderOptions }: IProps) => {
  const [dateRange, setDateRange] = useState<[Moment, Moment] | null>(null);

  useEffect(() => {
    setHeaderOptions({
      extra: (
        <ExportIncomeStatementToExcelButton selectedFilterDate={dateRange} />
      ),
    });
  }, [dateRange]);

  return (
    <IncomeStatementReport
      selectedFilterDate={dateRange}
      setSelectedDate={setDateRange}
    />
  );
};

export default IncomeStatement;
