import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { ICurrency } from '../../../../../typings/finance/currency';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<ICurrency> {
  onActionsClick?: (key: string, record: ICurrency) => void;
}

const CurrenciesTable = memo(({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation(['finance', 'common']);

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('currencies.name'),
        key: 'name',
        width: '25%',
        render: (record: ICurrency) => (
          <Typography.Link
            onClick={() => onActionsClick && onActionsClick('update', record)}
          >
            {record.name}
          </Typography.Link>
        ),
      },
      {
        title: t('currencies.code'),
        key: 'code',
        render: (record: ICurrency) => record.isoCode,
        width: '15%',
      },
      {
        title: t('currencies.currency_id'),
        key: 'currency_id',
        render: (record: ICurrency) => record.isoCurrencyId,
        width: '15%',
      },
      {
        title: t('currencies.is_active'),
        key: 'is_active',
        width: '15%',
        render: (record: ICurrency) =>
          t(`${record.isActive ? 'yes' : 'no'}`, { ns: 'common' }),
      },
      {
        title: t('currencies.account_currency'),
        key: 'account_currency',
        width: '15%',
        render: (record: ICurrency) =>
          t(`${record.isAccount ? 'yes' : 'no'}`, { ns: 'common' }),
      },

      {
        title: t('currencies.is_external'),
        key: 'is_external',
        width: '15%',
        render: (record: ICurrency) =>
          t(`${record.isExternal ? 'yes' : 'no'}`, { ns: 'common' }),
      },
    ];

    return result;
  }, [t, onActionsClick]);

  return <Table columns={columns} {...rest} />;
});

export default CurrenciesTable;
