import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { ApprovalWorkflowStatuses } from 'enums/approvalManagement/workflow';

// components
import StatusLabel, { LABEL_STATUSES } from '../../../Typography/StatusLabel';

const STATUS_MAP: Record<LABEL_STATUSES, ApprovalWorkflowStatuses[]> = {
  info: [],
  warning: [ApprovalWorkflowStatuses.PendingApproval],
  success: [ApprovalWorkflowStatuses.Approved],
  error: [ApprovalWorkflowStatuses.Rejected],
};

interface IProps {
  status: ApprovalWorkflowStatuses;
}

const ApprovalWorkflowStatus = memo(({ status }: IProps) => {
  const { t } = useTranslation('approval_management');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(`approval_workflow.approval_statuses.${status}`)}
    />
  );
});

export default ApprovalWorkflowStatus;
