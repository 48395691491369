import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import { ReportElementModel } from '../../../typings/reporting/reporting';

// components
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Col, Row, Tree, Typography } from 'antd';

interface IProps {
  report: ReportElementModel[];
  displayResidentHeader?: boolean;
  renderNode?: (record: ReportElementModel) => React.ReactNode;
}

interface ReportRowProps {
  record: ReportElementModel;
  isParent: boolean;
  displayResidentHeader: boolean;
  renderNode?: (record: ReportElementModel) => React.ReactNode;
}

const ReportRow = ({
  record,
  isParent,
  displayResidentHeader,
  renderNode,
}: ReportRowProps) => {
  const { t } = useTranslation('reporting');

  const { name, shouldShowResidentHeader } = record;
  const formattedName = renderNode ? renderNode(record) : name;

  return (
    <StyledRow justify="end">
      <Col flex="auto">
        <StyledTypography>{formattedName}</StyledTypography>
      </Col>
      {shouldShowResidentHeader && (
        <>
          <Col flex="200px">
            <Typography.Text>{t('resident')}</Typography.Text>
          </Col>
          <Col flex="200px">
            <Typography.Text>{t('non_resident')}</Typography.Text>
          </Col>
          <Col flex="200px" />
        </>
      )}
      {!isParent && (
        <>
          {displayResidentHeader && (
            <>
              <Col flex="200px">
                <Typography.Text>{record.sumResident}</Typography.Text>
              </Col>
              <Col flex="200px">
                <Typography.Text>{record.sumNonResident}</Typography.Text>
              </Col>
            </>
          )}
          <StyledCol flex="200px">
            <Typography.Text>{record.sum}</Typography.Text>
          </StyledCol>
        </>
      )}
    </StyledRow>
  );
};

const ReportTree = ({
  report,
  displayResidentHeader = true,
  renderNode,
}: IProps) => {
  const { t } = useTranslation('reporting');

  const modifiedReport = useMemo(() => {
    const deepReportCopied = JSON.parse(JSON.stringify(report));
    if (displayResidentHeader) {
      deepReportCopied[0].shouldShowResidentHeader = true;
    }
    return deepReportCopied;
  }, [displayResidentHeader, report]);

  const renderTreeNodes = (
    treeData: ReportElementModel[],
    parentId?: number | string,
  ) => {
    return treeData.map((e, index) => (
      <>
        <Tree.TreeNode
          expanded
          key={`${e.id}${parentId ? '-' + parentId : ''}`}
          title={
            <ReportRow
              record={e}
              isParent={!!e.children.length}
              displayResidentHeader={displayResidentHeader}
              renderNode={renderNode}
            />
          }
        >
          {!!e.children.length && renderTreeNodes(e.children, e.id)}
        </Tree.TreeNode>

        {!!e.children.length && (
          <Tree.TreeNode
            expanded
            key={`total-${e.id}`}
            title={
              <StyledTotalRow
                isBorderShow={!parentId && index !== treeData.length - 1}
                justify="space-between"
              >
                <Col flex="auto">
                  <StyledParentTitle>
                    <EllipsisTooltip
                      maxTextContainerWidth="400px"
                      title={`${t('total')} ${e.name}`}
                    >
                      {`${t('total')} ${e.name}`}
                    </EllipsisTooltip>
                  </StyledParentTitle>
                </Col>
                {displayResidentHeader && (
                  <>
                    <Col flex="200px">
                      <StyledParentTitle>{e.sumResident}</StyledParentTitle>
                    </Col>
                    <Col flex="200px">
                      <StyledParentTitle>{e.sumNonResident}</StyledParentTitle>
                    </Col>
                  </>
                )}
                <StyledCol flex="200px">
                  <StyledParentTitle>{e.sum}</StyledParentTitle>
                </StyledCol>
              </StyledTotalRow>
            }
          />
        )}
      </>
    ));
  };

  return (
    <>
      <StyledTree
        showIcon={false}
        fullWidth={displayResidentHeader}
        defaultExpandAll
      >
        {renderTreeNodes(modifiedReport)}
      </StyledTree>
    </>
  );
};

const StyledRow = styled(Row)`
  width: 100%;
  min-width: 100%;
`;

const StyledTotalRow = styled(Row)<{ isBorderShow: boolean }>`
  border-top: 1px solid #fff;
  padding-top: 5px;

  ${({ isBorderShow }) =>
    isBorderShow &&
    `
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
    &:before {
      background: none;
      border-bottom: 1px solid #fff;
      content: '';
      display: block;
      position: absolute;
      top: 4px;
      left: 4px;
      right: 4px;
      bottom: 4px;
      pointer-events: none;`}
`;

const StyledTree = styled(Tree)<{ fullWidth: boolean }>`
  &.ant-tree .ant-tree-treenode {
    width: 100%;
    min-width: 100%;
    .ant-tree-node-content-wrapper {
      width: 100%;
    }
  }

  &.ant-tree .ant-tree-list-holder-inner {
    align-items: unset;
  }

  &.ant-tree {
    min-width: ${({ fullWidth }) => (fullWidth ? '1150px' : 'auto')};
  }
`;

const StyledParentTitle = styled(Typography.Text)`
  color: ${({ theme }) => theme.whiteColor};
  font-weight: 600;
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const StyledTypography = styled(Typography.Text)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.marginXXs};
`;

export default ReportTree;
