import i18n from 'i18next';
import backend from 'i18next-xhr-backend';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { LocalizationNamespaces } from 'constants/localization';

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en'],
    ns: LocalizationNamespaces,
    fallbackNS: LocalizationNamespaces,
    defaultNS: 'auth',
    debug: false,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      queryStringParams: process.env.REACT_APP_BUILD_TIME
        ? { v: process.env.REACT_APP_BUILD_TIME }
        : undefined,
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      nsMode: 'default',
      useSuspense: false,
      bindI18n: 'languageChanged editorSaved',
    },
  });

export default i18n;
