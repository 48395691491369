import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../../../../routes/routes';
import { JournalEntry } from '../../../../../../../../components/Tables/TableTemplates/Finance/UpdatedJournalEntriesTable';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { ReconciliationStatuses } from '../../../../../../../../enums/finance/finance';

// components
import Form from '@core_components/Form';
import UpdateReconciliationStatusesProgressForm, {
  FormValuesModel as UpdateReconciliationStatusesProgressFormValuesModel,
} from '../../../../../../../../components/Forms/TemplateForms/Finance/UpdateReconciliationStatusesProgressForm';
import { Divider, Typography } from 'antd';

export type UpdatedReconciliationStatusesFromQuery = {
  isDebit: boolean;
  accountName: string;
  accountNumber: string;
  journalEntries: JournalEntryFromQuery[];
};

export type JournalEntryFromQuery = {
  journalEntryId: number;
  comment: string;
  valueDate: number;
  journalId: number;
  description: string;
  debitAmount: number;
  creditAmount: number;
  new: {
    reconciliationStatus: ReconciliationStatuses;
  };
  old: {
    reconciliationStatus: ReconciliationStatuses;
  };
};

interface IProps {
  data: UpdatedReconciliationStatusesFromQuery;
}

const UpdateReconciliationStatusesDetails = ({ data }: IProps) => {
  const { t } = useTranslation('finance');
  const history = useHistory();

  const initialValues =
    useMemo<UpdateReconciliationStatusesProgressFormValuesModel>(() => {
      const { journalEntries } = data;

      const entriesHash = journalEntries.reduce<Record<number, JournalEntry>>(
        (acc, cur) => {
          acc[cur.journalEntryId] = {
            id: cur.journalEntryId,
            message: cur.comment,
            journalId: cur.journalId,
            valueDate: cur.valueDate,
            debitAmount: cur.debitAmount,
            creditAmount: cur.creditAmount,
            description: cur.description,
            newReconciliationStatus: cur.new.reconciliationStatus,
          };
          return acc;
        },
        {},
      );

      return { entriesHash, isDebit: data.isDebit };
    }, [data]);

  return (
    <>
      {t('update_reconciliation_status.related_bank_account')}
      <StyledLink
        onClick={() =>
          history.push({
            pathname: RoutePaths.Finance_Account_Details,
            search: `?id=${data.accountNumber}`,
          })
        }
      >
        {AccountsHelpers.generateAccountFullName(
          data.accountName,
          data.accountNumber,
          '',
        )}
      </StyledLink>
      <Divider />
      <Form<UpdateReconciliationStatusesProgressFormValuesModel>
        disabled
        onSubmit={console.log}
        initialValues={initialValues}
        renderForm={<UpdateReconciliationStatusesProgressForm />}
      />
    </>
  );
};

const StyledLink = styled(Typography.Link)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default UpdateReconciliationStatusesDetails;
