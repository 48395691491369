import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import { MessageModel } from '../../../../../../../typings/messaging/messages';

// helpers
import DocumentLink from '../../../../../../Typography/DocumentLink';
import { Divider, Typography } from 'antd';

interface IProps {
  message: MessageModel;
  isActive?: boolean;
}

const MessageBody = memo(({ message, isActive }: IProps) => {
  const hasAttachment = !!message.attachments.length;

  const renderAttachments = () => {
    return message.attachments?.map((attachment) => {
      return (
        <>
          <DocumentLink
            key={attachment.documentId}
            documentId={attachment.documentId}
            fileId={attachment.fileId}
            fileName={attachment.name}
            text={attachment.name}
          />
          <br />
        </>
      );
    });
  };

  return (
    <>
      <StyledMessageBody ellipsis={!isActive && { rows: 2 }}>
        {message.body.content}
      </StyledMessageBody>
      {hasAttachment && isActive && (
        <>
          <StyledDivider dashed />
          {renderAttachments()}
        </>
      )}
    </>
  );
});

const StyledMessageBody = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.paddingMd} 0px`};
`;

export default MessageBody;
