export enum ApprovalStatuses {
  Pending,
  Approved,
  Rejected,
}

export enum ApprovalWorkflowStatuses {
  All,
  PendingApproval,
  Approved,
  Rejected,
}
