import React from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import { useMemo } from 'react';
import { correspondentBanksAPI } from 'api/banking/correspondentBanksAPI';
import { BankCodeTypeFromQuery } from 'api/banking/bankingBankAccountsAPI';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type IProps = SelectProps<BankCodeTypeFromQuery>;

const BankCodeTypeSelect = (props: IProps) => {
  const { response, loading } = useFetch(
    () => correspondentBanksAPI.fetchBankCodeTypes(),
    [],
  );

  const options = useMemo<SelectOption<BankCodeTypeFromQuery>[]>(() => {
    if (!response) {
      return [];
    }

    return response.data.map((e) => ({
      id: e.id,
      label: e.title,
      model: e,
    }));
  }, [response]);

  return <FormSelect {...props} options={options} loading={loading} />;
};

export default BankCodeTypeSelect;
