import React, { useCallback, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import { ClientStatus } from 'enums/crm/crm';
import { IClientGroupResponse } from 'api/crm/clientGroup/clientGroupsAPI';

// components
import ChartActions from './ChartActions';
import EditNodeModal from './EditNodeModal';
import GraphChart, {
  GraphData,
  GraphEdge,
  GraphNode,
} from 'components/Charts/GraphChart';

interface IProps {
  disableStructureEditMode: (message: string) => Promise<void>;
  clientGroupData: IClientGroupResponse;
  relationshipTree: GraphData | null;
  onModify: () => void;
}

const UpdateEntityRelationshipChart = ({
  disableStructureEditMode,
  relationshipTree,
  clientGroupData,
  onModify,
}: IProps) => {
  const [openedModal, setOpenedModal] = useState<{
    modalKey: 'edit-node';
    edge?: GraphEdge;
    childNode?: GraphNode;
    parentNode?: GraphNode;
    edges?: GraphEdge[];
    clientUserId?: string;
    isOnlineContactWithLastRelationship?: boolean;
  } | null>(null);

  const onEditNodeClick = useCallback(
    (node: GraphNode) => {
      const nodeEdges = relationshipTree?.edges.filter(
        (e) => e.source === node.id,
      );

      setOpenedModal({
        modalKey: 'edit-node',
        parentNode: node,
        edges: nodeEdges,
      });
    },
    [relationshipTree],
  );

  const formattedGraphData = useMemo<GraphData>(() => {
    const result: GraphData = { nodes: [], edges: [] };

    if (!relationshipTree) {
      return result;
    }

    result.nodes = relationshipTree.nodes.map((n) => {
      return {
        ...n,
        actions: {
          onClick: () => onEditNodeClick(n),
        },
      };
    });

    result.edges = [...relationshipTree.edges];

    return result;
  }, [relationshipTree]);

  const handleModalClose = (wasModified?: boolean) => {
    if (wasModified) {
      onModify();
    }

    setOpenedModal(null);
  };

  return (
    <>
      <ChartActions
        onEnableStructureEditing={disableStructureEditMode}
        closeCallback={(wasSubmitted) => wasSubmitted && onModify()}
        clientGroupId={clientGroupData.clientGroup._id}
        relationshipScopeId={String(
          clientGroupData.activeRelationshipScope?._id,
        )}
      />

      <TreeWrapper>
        {relationshipTree && (
          <GraphChart
            data={formattedGraphData}
            displayApplicantIcon={[
              ClientStatus.Onboarding,
              ClientStatus.InReview,
            ].includes(clientGroupData.clientGroup.status)}
          />
        )}
      </TreeWrapper>

      <EditNodeModal
        isViewOnly={false}
        isVisible={openedModal?.modalKey === 'edit-node'}
        closeCallback={handleModalClose}
        clientGroupId={clientGroupData.clientGroup._id}
        data={
          openedModal?.modalKey === 'edit-node'
            ? {
                activeScopeId: clientGroupData.activeRelationshipScope
                  ?._id as string,
                node: openedModal?.parentNode as GraphNode,
                edges: openedModal.edges || [],
                clientGroupId: clientGroupData.clientGroup._id,
                allNodes: relationshipTree?.nodes as GraphNode[],
              }
            : null
        }
      />
    </>
  );
};

const TreeWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.tableBorderColor};
  min-height: 650px;
`;

export default UpdateEntityRelationshipChart;
