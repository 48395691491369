import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../../../../hooks/useFetch';
import { documentsAPI } from '../../../../../../../../api/documents/documentsAPI';
import { IDocumentBase } from '../../../../../../../../typings/documents/documents';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../../../../../constants/global';

// components
import DocumentDetailsModal from '../../../../../../Documents/DocumentsDetailsModal';
import { default as TemplateDocumentsTable } from '../../../../../../../../components/Tables/TableTemplates/Documents/DocumentsTable';

interface IProps {
  clientGroupId: string;
  showAssociation?: boolean;
}

const DocumentsTable = ({ clientGroupId, showAssociation = true }: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [updateTableTrigger, updateTable] = useState({});
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null,
  );

  const { response, loading } = useFetch(
    () =>
      documentsAPI.fetchDocumentsByClientGroupId(clientGroupId as string, {
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [clientGroupId, updateTableTrigger, currentPage],
  );

  const handleActionsChange = (key: string, document: IDocumentBase) => {
    switch (key) {
      case 'details':
        setSelectedDocumentId(document._id);
        break;
    }
  };

  const handleDetailsModalClose = (wasModified?: boolean) => {
    if (wasModified) {
      updateTable({});
    }

    setSelectedDocumentId(null);
  };

  return (
    <>
      <TemplateDocumentsTable
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={currentPage}
        showAssociation={showAssociation}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsChange}
      />

      <DocumentDetailsModal
        isVisible={!!selectedDocumentId}
        closeCallback={handleDetailsModalClose}
        documentId={selectedDocumentId as string}
      />
    </>
  );
};

export default DocumentsTable;
