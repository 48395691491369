import React, { memo, useCallback } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ForgotUsernameRequestStatuses } from 'components/Additional/Statuses/ForgotUsernameRequestStatus';
import {
  ForgotUsernameRequestFromQuery,
  forgotUsernameRequestsAPI,
} from 'api/orac/forgotUsernameRequestsAPI';

// components
import AsyncButton from '@common_components/Buttons/AsyncButton';
import { Col, Row, message } from 'antd';

interface IProps {
  request: ForgotUsernameRequestFromQuery;
  onStatusUpdate: () => void;
}

const ActionButtons = memo(({ request, onStatusUpdate }: IProps) => {
  const { t } = useTranslation('orac');

  const onStartReviewProcessClick = useCallback(async () => {
    await forgotUsernameRequestsAPI.startReviewProcessByRequestId(request.id);
    message.success(t('forgot_username_requests.details.success_start_msg'));
    onStatusUpdate();
  }, [request, onStatusUpdate]);

  const onCompleteReviewProcessClick = useCallback(async () => {
    await forgotUsernameRequestsAPI.completeReviewProcessByRequestId(
      request.id,
    );
    message.success(t('forgot_username_requests.details.success_complete_msg'));
    onStatusUpdate();
  }, [request, onStatusUpdate]);

  return (
    <Row justify="end" gutter={[16, 16]}>
      <Col span={4}>
        {request.status === ForgotUsernameRequestStatuses.Pending ? (
          <AsyncButton
            size="large"
            fullWidth
            type="primary"
            onClick={onStartReviewProcessClick}
          >
            {t('forgot_username_requests.details.start_review')}
          </AsyncButton>
        ) : (
          <AsyncButton
            size="large"
            fullWidth
            type="primary"
            onClick={onCompleteReviewProcessClick}
          >
            {t('forgot_username_requests.details.complete_review')}
          </AsyncButton>
        )}
      </Col>
    </Row>
  );
});

export default ActionButtons;
