import { ApprovalWorkflowStatuses } from '../../enums/approvalManagement/workflow';
import { ApprovalManagementTemplateCodes } from '../../enums/approvalManagement/templates';

export interface ApprovalWorkflowTypeModel {
  code: string;
  name: string;
}

export interface ApprovalWorkflowStatusModel {
  name: string;
  value: number;
}

export type ApprovalWorkflowEntryTypes =
  | 'NEWBUSINESS_ENTITY'
  | 'NEWBUSINESS_CONTACT'
  | 'COMPLIANCE_ENTITY'
  | 'COMPLIANCE_CONTACT'
  | 'COMPLIANCE_INCOMING_TRANSACTION'
  | 'COMPLIANCE_OUTGOING_TRANSACTION'
  | 'COMPLIANCE_CONTACT_UPDATE_KYC'
  | 'COMPLIANCE_CONTACT_UPDATE_NONKYC'
  | 'COMPLIANCE_NEW_BANK_ACCOUNT'
  | 'FOREINGEXCHANGE_FX_TRANSFER'
  | 'SENIORMNGT_FEE_SCHEDULE'
  | 'SENIORMNGT_ACCOUNT_STATUS'
  | 'SENIORMNGT_OVERDERAFT_LIMIT'
  | 'SENIORMNGT_SPREAD_CONFIGURATION'
  | 'SENIORMNGT_ACCOUNT_INTEREST_RATE'
  | 'SENIORMNGT_TRANSACTION_CURRENCY_LIST'
  | 'SENIORMNGT_TRANSFER_TEMPLATE_STATUS'
  | 'SENIORMNGT_INTERNATIONAL_TRANSFER_TEMPLATE_ARCH'
  | 'SENIORMNGT_CLIENT_TRANSACTION_APPROVAL_GROUP'
  | 'SENIORMNGT_CLIENT_TRANSACTION_APPROVAL_TEMPLATE'
  | 'ADMIN_SENIORMNGT_ACCOUNT_WIRE_DETAILS_CHANGE'
  | 'ADMIN_ACCOUNT_MNGT_APPROVAL_GROUP'
  | 'ADMIN_ACCOUNT_MNGT_APPROVAL_RULE'
  | 'FINANCE_JOURNAL_ENTRY'
  | 'FINANCE_INCOMING_WIRE_TRANSACTION'
  | 'FINANCE_JOURNAL_ENTRY_RECONCILIATION'
  | 'FINANCE_INCOMING_WIRE_TRANSACTION_RETURN'
  | 'TENETOS.Approval.Core.ComplianceOutgoingTransactionEntry'
  | 'FINANCE_OUTGOING_WIRE_TRANSACTION_REJECT';

export enum ClientApprovalWorkflowEntryTypes {
  TRANSACTION_APPROVAL_GROUP = 'TRANSACTION_APPROVAL_GROUP',
  TRANSACTION_APPROVAL_RULE = 'TRANSACTION_APPROVAL_RULE',
  ACCOUNT_MNGT_APPROVAL_GROUP = 'ACCOUNT_MNGT_APPROVAL_GROUP',
  ACCOUNT_MNGT_APPROVAL_RULE = 'ACCOUNT_MNGT_APPROVAL_RULE',
  ACCOUNT_MNGT_UPDATE_CLIENTGROUP = 'ACCOUNT_MNGT_UPDATE_CLIENTGROUP',
  ACCOUNT_MNGT_NEW_BANKACCOUNT = 'ACCOUNT_MNGT_NEW_BANKACCOUNT',
  ACCOUNT_MNGT_CIRCLE_INTEGRATION = 'ACCOUNT_MNGT_CIRCLE_INTEGRATION',
}

export enum ClientAccountManagementApprovalStatuses {
  All = 0,
  PendingApproval,
  Approved,
  Rejected,
  Expired,
}

export enum ClientApprovalWorkflowChangeTypes {
  Create = 1,
  Edit,
  Delete,
}

export interface ApprovalWorkflowInstanceModel {
  id: string;
  value: number;
  number: number;
  status: ApprovalWorkflowStatuses;
  statusName: string;
  templateCode: ApprovalManagementTemplateCodes;
  templateName: string;
  approvalProgressStateSets: ApprovalProgressStateModel[];
  entryData?: string;
  entryType: ApprovalWorkflowEntryTypes;
  startedBy: {
    startedDate: string;
    userEmailAddress: string;
    userId: string;
    userName: string;
  };
}

export interface ApprovalProgressStateModel {
  progressStateItems: ApprovalProgressStateItemModel[];
}

export interface ApprovalProgressStateItemModel {
  groupNameTittle: string;
  members: ApprovalProgressMemberModel[];
}

export interface ApprovalProgressMemberModel {
  userId: string;
  userFullName: string;
  approvalDate: string;
  approvalAction: number;
  approvalReason: string;
  approvalStatusName: string;
}

// TODO: move some interfaces to accounting typings
export interface ApprovalFXTransferDataModel {
  correspondentBankInformation: CorrespondentBankInfoModel;
  customerInformation: CustomerInfoModel;
  wireInformation: WireInfoModel;
}

export interface CorrespondentBankInfoModel {
  bankName: string;
  clientGroupName: string;
  sourceAccountCurrencyId: number;
  sourceAccountCurrencyName: string;
  sourceAccountName: string;
  sourceAccountNumber: string;
  sourceBalance: number;
  targetAccountCurrencyId: number;
  targetAccountCurrencyName: string;
  targetAccountName: string;
  targetAccountNumber: string;
  targetBalance: number;
}

export interface CustomerInfoModel {
  clientGroupId: number;
  clientGroupName: string;
  sourceAccountCurrencyId: number;
  sourceAccountCurrencyName: string;
  sourceAccountName: string;
  sourceAccountNumber: string;
  sourceBalance: number;
  targetAccountCurrencyId: number;
  targetAccountCurrencyName: string;
  targetAccountName: string;
  targetAccountNumber: string;
  targetBalance: number;
}

export interface WireInfoModel {
  clientGroupId: number;
  clientGroupName: string;
  estimatedConversionAmount: number;
  estimatedConversionAmountCurrencyId: number;
  estimatedConversionAmountCurrencyName: string;
  exchangeRateWithSpread: number;
  internalFeeAccountName: string;
  internalFeeAccountNumber: string;
  internalFeeAmount: number;
  internalFeeCurrencyId: number;
  internalFeeCurrencyName: string;
  isSpreadModified: boolean;
  originalExchangeRate: number;
  originalSpread: number;
  spread: number;
  totalClientAmount: number;
  totalClientCurrencyId: number;
  totalClientCurrencyName: string;
  transactionId: number;
  transferAmount: number;
  transferAmountCurrencyId: number;
  transferAmountCurrencyName: string;
}
