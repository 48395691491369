import React, { useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import {
  PendingTransactionFromQuery,
  transactionsAPI,
} from 'api/transactions/transactionsAPI';

// components
import ClientTransferModal from 'components/Modals/TemplateModalDialogs/Accounting/Transfers/ClientTransferModal';
import {
  ActionKeys,
  default as PendingTransactionsTemplateTable,
} from 'components/Tables/TableTemplates/Banking/PendingTransactionsTable';

const PendingTransactionsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTransaction, setSelectedTransaction] =
    useState<PendingTransactionFromQuery | null>(null);
  const [filterValues, setFilterValues] = useState<{
    fromAccount: string | undefined;
    status: string | undefined;
  }>({
    fromAccount: undefined,
    status: undefined,
  });

  const { response, loading } = useFetch(
    () =>
      transactionsAPI.fetchClientPendingTransactions({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        status: filterValues.status || undefined,
        accountNumber: filterValues.fromAccount || undefined,
      }),
    [currentPage, filterValues],
  );

  const handleActionsClick = (
    key: ActionKeys,
    record: PendingTransactionFromQuery,
  ) => {
    switch (key) {
      case 'view': {
        setSelectedTransaction(record);
      }
    }
  };

  const handleFilterChange = (key: string, value: unknown | undefined) => {
    switch (key) {
      case 'from_account':
        setFilterValues((prev) => ({
          ...prev,
          fromAccount: (value as string) || undefined,
        }));
        break;

      case 'status':
        setFilterValues((prev) => ({
          ...prev,
          status: (value as string) || undefined,
        }));
        break;
    }
  };

  const onFilterChange = (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => {
    if (Object.keys(filters).length) {
      Object.keys(filters).forEach((key) =>
        handleFilterChange(key, filters[key] as any),
      );
    }
  };

  return (
    <>
      <PendingTransactionsTemplateTable
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={currentPage}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsClick}
        filters={filterValues}
        onFilterChange={onFilterChange}
      />

      <ClientTransferModal
        clientGroupId={selectedTransaction?.fromClientId || null}
        transactionId={selectedTransaction?.id || null}
        isVisible={!!selectedTransaction}
        closeCallback={() => setSelectedTransaction(null)}
      />
    </>
  );
};

export default PendingTransactionsTable;
