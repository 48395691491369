import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { approvalGroupsAPIAdapter } from 'apiAdapters/approval/approvalGroupsAPIAdapter';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';
import {
  ApprovalGroupBaseModel,
  ApprovalMatrixThresholdBaseModel,
  GroupParticipantModel,
  OnboardingTemplateModel,
} from 'typings/approval/templates';
import {
  ClientAccountManagementApprovalStatuses,
  ClientApprovalWorkflowChangeTypes,
  ClientApprovalWorkflowEntryTypes,
} from 'typings/approval/approvalWorkflow';
import {
  ClientApprovalEntryModel,
  clientAccountManagementRulesAdapterAPI,
} from 'apiAdapters/approval/clientAccountManagementRulesAdapterAPI';

export interface ApprovalGroupFromQuery {
  id: string;
  name: string;
  isActive: boolean;
  isDeleted: boolean;
  description: string;
  participants: {
    userEmailAddress: string;
    userFullName: string;
    userId: string;
  }[];
}

export interface ApprovalRuleTemplateFromQuery extends OnboardingTemplateModel {
  isActive: boolean;
  isDeleted: boolean;
  templateCode: string;
}

export interface ApprovalEntryModel {
  id: string;
  createdAt: string;
  entryType: ClientApprovalWorkflowEntryTypes;

  status: ClientAccountManagementApprovalStatuses;
  statusName: string;

  templateCode: string;
  templateName: string;

  value: ClientApprovalWorkflowChangeTypes;
}

interface FetchApprovalStatusesResponse {
  items: { name: string; value: number }[];
}

interface FetchApprovalsParams extends FetchParamsModel {
  status: number;
  searchByUserNameQuery: string;
}

interface FetchApprovalGroupParams extends FetchParamsModel {
  isActiveOnly?: boolean;
}

const clientAccountManagementRulesAPI = {
  fetchApprovalGroups: (
    params: FetchApprovalGroupParams,
    clientGroupNumericId: number,
  ) => {
    return APIService.get<FetchResponseModel<ApprovalGroupFromQuery>>(
      `${APIConfig.approvalApi}/account-management/client/${clientGroupNumericId}/groups`,
      {
        params,
      },
    ).then(({ data }) =>
      approvalGroupsAPIAdapter.formatApprovalGroupFromQueryToClientApprovalGroupModel(
        data,
      ),
    );
  },

  fetchApprovalGroupById: (
    clientGroupNumericId: number,
    approvalGroupId: string,
  ) => {
    return APIService.get<ApprovalGroupFromQuery>(
      `${APIConfig.approvalApi}/account-management/client/${clientGroupNumericId}/groups/${approvalGroupId}`,
    ).then(({ data }) => data);
  },

  fetchApprovalTemplate: (clientGroupNumericId: number) => {
    return APIService.get<ApprovalRuleTemplateFromQuery>(
      `${APIConfig.approvalApi}/account-management/client/${clientGroupNumericId}/template`,
    ).then(({ data }) => data);
  },

  fetchApprovalTemplateTypes: () => {
    return APIService.get<ApprovalMatrixThresholdBaseModel[]>(
      `${APIConfig.approvalApi}/account-management/template/types`,
    ).then(({ data }) => data);
  },

  // TODO: Implement this method when the API will be ready
  fetchApprovalWorkflowStatuses: () => {
    const response = {
      items: [
        {
          name: 'All',
          value: 0,
        },
        {
          name: 'Pending Approval',
          value: 1,
        },
        {
          name: 'Approved',
          value: 2,
        },
        {
          name: 'Rejected',
          value: 3,
        },
      ],
    };
    return new Promise<FetchApprovalStatusesResponse>((resolve) => {
      setTimeout(() => resolve(response), 1000);
    });
  },

  fetchApprovalEntries: (
    params: FetchApprovalsParams,
    clientGroupId: number,
  ) => {
    return APIService.get<FetchResponseModel<ApprovalEntryModel>>(
      `${APIConfig.approvalApi}/account-management/client/${clientGroupId}/workflows`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  getApprovalWorkflowById: (
    approvalWorkflowId: string,
    clientGroupId: number,
  ) => {
    return APIService.get(
      `${APIConfig.approvalApi}/account-management/client/${clientGroupId}/workflows/${approvalWorkflowId}`,
    ).then<ClientApprovalEntryModel>(({ data }) =>
      clientAccountManagementRulesAdapterAPI.getApprovalWorkflow(data),
    );
  },

  createApprovalGroup: (
    newGroup: ApprovalGroupBaseModel,
    clientGroupId: number,
  ) => {
    return APIService.post(
      `${APIConfig.approvalApi}/account-management/client/${clientGroupId}/groups`,
      newGroup,
    ).then(({ data }) => data);
  },

  updateApprovalGroup: (
    updatedGroup: ApprovalGroupBaseModel,
    clientGroupId: number,
    groupId: string,
  ) => {
    return APIService.patch(
      `${APIConfig.approvalApi}/account-management/client/${clientGroupId}/groups/${groupId}`,
      updatedGroup,
    ).then(({ data }) => data);
  },

  updateAdministrationApprovalRule: (data: any, clientGroupdId: number) => {
    return APIService.patch(
      `${APIConfig.approvalApi}/account-management/client/${clientGroupdId}/template`,
      data,
    ).then(({ data }) => data);
  },

  fetchApprovalGroupParticipants: (clientGroupId: number) => {
    return APIService.get<FetchResponseModel<GroupParticipantModel>>(
      `${APIConfig.approvalApi}/account-management/client/${clientGroupId}/groups/participants`,
    ).then(({ data }) => data);
  },
};

export { clientAccountManagementRulesAPI };
