import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { TableColumnModel } from '@core_components/Table';
import { IAdditionalField } from '../../../../../typings/crm/relationship-template';

// components
import TableCell from './TableCell';
import { Table } from 'antd';

interface IProps {
  fields?: string[];
  fieldName?: string;
  handleDelete: (index: number) => void;
  hideParentType?: boolean;
  disableParentType?: boolean;
  additionalValues: IAdditionalField[];
}

const AdditionalValuesTable = ({
  fieldName = 'additionalFields',
  additionalValues,
  handleDelete,
  hideParentType,
  disableParentType,

  fields = [
    'name',
    'onboardingRequired',
    'isDirectClientOnly',
    'isForRegulatedOnly',
    'description',
    'type',
    'additional',
    'deleteField',
  ],
}: IProps) => {
  const { t } = useTranslation('crm');

  const columnsMap: Record<string, TableColumnModel> = {
    name: {
      title: t('relationship_templates.field_label'),
      key: 'name',
      width: '20%',
      dataIndex: 'name',
    },
    onboardingRequired: {
      title: t('relationship_templates.onboarding_required'),
      key: 'onboardingRequired',
      width: '10%',
      dataIndex: 'options.onboardingRequired',
    },
    isDirectClientOnly: {
      title: t('relationship_templates.is_direct_client_only'),
      key: 'isDirectClientOnly',
      width: '10%',
      dataIndex: 'options.isDirectClientOnly',
    },
    isForRegulatedOnly: {
      title: t('relationship_templates.regulated_only'),
      key: 'isForRegulatedOnly',
      width: '5%',
      dataIndex: 'options.isForRegulatedOnly',
    },
    description: {
      title: t('relationship_templates.description'),
      key: 'description',
      width: '20%',
      dataIndex: 'description',
    },
    type: {
      title: t('relationship_templates.field_type'),
      key: 'type',
      width: '10%',
      dataIndex: 'type',
    },
    additional: {
      title: t('relationship_templates.field_options'),
      key: 'additional',
      width: '30%',
      dataIndex: 'additional',
    },
    deleteField: {
      key: 'deleteField',
      width: '5%',
      dataIndex: 'deleteField',
    },
  };

  const mappedColumns = useMemo(
    () => fields?.map((field) => columnsMap[field]),
    [fields],
  );

  const columns = useMemo(() => {
    let mergedColumns = mappedColumns.slice();

    if (!hideParentType) {
      const parentTypeColumn = {
        title: t('relationship_templates.parent_type'),
        key: 'relatedTo',
        width: '15%',
        dataIndex: 'relatedTo',
      };

      mergedColumns = [parentTypeColumn, ...mergedColumns];
    }

    return mergedColumns.map((col) => ({
      ...col,
      onCell: (record: any, index: number) => ({
        index,
        record,
        fieldName,
        disableParentType,
        dataIndex: col.dataIndex,
        onDelete: (index: number) => handleDelete(index),
      }),
    }));
  }, [mappedColumns, hideParentType]);

  return (
    <StyledTable
      bordered
      size="small"
      columns={columns as any}
      dataSource={additionalValues}
      pagination={false}
      components={{ body: { cell: TableCell } }}
    />
  );
};

const StyledTable = styled(Table)`
  margin-bottom: 15px;
`;

export default AdditionalValuesTable;
