import React, { memo } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';

// components
import IncomingTransfersReport from 'modules/Reporting/IncomingTransfersReport';
import OutgoingTransfersReport from 'modules/Reporting/OutgoingTransfersReport';
import { Divider } from 'antd';

const ThresholdReporting = memo(() => {
  const [hasIncomingTransfersAccess, hasOutgoingTransfersAccess] =
    useUserAccess([
      'Reporting_Threshold_IncomingTransfers_View',
      'Reporting_Threshold_OutgoingTransfers_View',
    ]);

  return (
    <>
      {hasIncomingTransfersAccess && <IncomingTransfersReport />}
      {hasIncomingTransfersAccess && hasOutgoingTransfersAccess && <Divider />}
      {hasOutgoingTransfersAccess && <OutgoingTransfersReport />}
    </>
  );
});

export default ThresholdReporting;
