import React, { useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { GraphNode } from 'components/Charts/GraphChart';
import { OnboardType } from 'enums/crm/crm';
import { IClientGroup } from 'typings/crm/client-group';
import { onboardingAPI } from 'api/crm/onboarding/onboardingAPI';
import { IOnboardingStatus } from 'typings/crm/onboarding';

// components
import Text from '@core_components/Text';
import ContactReviewTabs from './ContactReviewTabs';
import VerificationDocuments from './VerificationDocuments';

interface IProps {
  node: GraphNode | null;
  isViewOnly: boolean;
  clientGroup: IClientGroup;
  updateNodesTreeCallback: () => void;
  updateClientGroupCallback: () => void;
}

const NodeDetails = ({
  node,
  clientGroup,
  isViewOnly,
  updateClientGroupCallback,
  updateNodesTreeCallback,
}: IProps) => {
  const { t } = useTranslation('crm');
  const itemId = node?.id || clientGroup.directClientItemId;
  const hasOnboardingProcess = !!node?.onboardingProcess;
  const [updateOnboardingStatusTrigger, updateOnboardingStatusCallback] =
    useState<any>();

  const { response: onboardingStatus } = useFetch<IOnboardingStatus>(
    () =>
      hasOnboardingProcess
        ? onboardingAPI.getStatus(itemId, clientGroup._id)
        : null,
    [
      hasOnboardingProcess,
      itemId,
      clientGroup._id,
      updateOnboardingStatusTrigger,
    ],
  );

  const updateNodeHandler = () => {
    updateNodesTreeCallback();
    updateOnboardingStatusCallback({});
  };

  const renderContent = () => {
    if (!node) {
      return null;
    }

    if (node.entryType === OnboardType.Contact) {
      return (
        <ContactReviewTabs
          node={node}
          isViewOnly={isViewOnly}
          clientGroup={clientGroup}
          onboardingStatus={onboardingStatus}
          updateNodeCallback={updateNodeHandler}
          updateClientGroupCallback={updateClientGroupCallback}
        />
      );
    } else if (node.entryType === OnboardType.Organization) {
      return (
        <VerificationDocuments
          node={node}
          isViewOnly={isViewOnly}
          clientGroup={clientGroup}
          onboardingStatus={onboardingStatus}
          updateNodeCallback={updateNodeHandler}
          updateClientGroupCallback={updateClientGroupCallback}
        />
      );
    }
  };

  return (
    <>
      {hasOnboardingProcess ? (
        renderContent()
      ) : (
        <Text>{t('client_group.approval.no_onboarding_process')}</Text>
      )}
    </>
  );
};

export default NodeDetails;
