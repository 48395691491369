import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { darkTheme } from '@resources/theme/styled';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';
import { AdditionalFieldType, OnboardType } from 'enums/crm/crm';

// components
import Text from '@core_components/Text';
import FormField from '@core_components/FormField';
import DeleteButton from '@common_components/Buttons/DeleteButton';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import FormInputNumber from '@common_components/Form/FormInputNumber';
import InfoIconWithPopover from '@common_components/Icons/InfoIconWithPopover';
import Table, { TableColumnModel } from '@core_components/Table';
import OwnershipAdditionalFieldFormSelect, {
  OwnershipAdditionalFieldFormSelectProps,
} from 'components/Forms/FormComponents/SelectInputs/OwnershipAdditionalFieldFormSelect';
import { Checkbox } from 'antd';

export type SelectedRelationshipTemplate = {
  id: string;
  name: string;
  isOwnership: boolean;
  ownershipAdditionalField: string | null;
  required: boolean;
  controller: boolean;
  minAmount: number;
  minPercentage: number;
  ownershipAdditionalFields: {
    id: string;
    name: string;
    type: AdditionalFieldType;
  }[];
};

interface IProps {
  onDelete: (index: number) => void;
}

const SelectedRelationshipTemplates = memo(({ onDelete }: IProps) => {
  const { t } = useTranslation('crm');
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  const ownershipRelationshipSelected = useMemo(() => {
    if (!values.relationshipTemplates.length) {
      return false;
    }

    return values.relationshipTemplates.some((e) => !!e.isOwnership);
  }, [values.relationshipTemplates]);

  const columns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        key: 'name',
        width: 300,
        title: t(
          'entity_type_category.new.form_fields.selected_relationships.name',
        ),
        render: (value: any, record: SelectedRelationshipTemplate) => (
          <EllipsisTooltip title={record.name} maxTextContainerWidth="270px">
            {record.name}
          </EllipsisTooltip>
        ),
      },

      {
        hidden: values.visibleFor == OnboardType.Contact,
        key: 'ownership',
        width: 300,
        title: t(
          'entity_type_category.new.form_fields.selected_relationships.ownership',
        ),
        render: (
          value: any,
          record: SelectedRelationshipTemplate,
          index: number,
        ) => {
          if (ownershipRelationshipSelected && !record.isOwnership) {
            return (
              <InfoIconWithPopover
                popoverContent={t(
                  'entity_type_category.new.cannot_have_multiple_ownerships',
                )}
              />
            );
          }
          const canBeOwnership = !!record.ownershipAdditionalFields.length;
          return (
            <>
              {canBeOwnership ? (
                <>
                  <FormField
                    name={`relationshipTemplates.${index}.isOwnership`}
                    component={Checkbox}
                    onChange={(e) => {
                      if (!record.required && e.target.checked) {
                        setFieldValue(
                          `relationshipTemplates.${index}.required`,
                          true,
                        );
                      }
                    }}
                  />
                  {!!record.isOwnership && (
                    <FormField<OwnershipAdditionalFieldFormSelectProps>
                      label={t(
                        'entity_type_category.new.form_fields.selected_relationships.ownership_additional_field',
                      )}
                      name={`relationshipTemplates.${index}.ownershipAdditionalField`}
                      component={OwnershipAdditionalFieldFormSelect}
                      additionalProps={{
                        additionalFields: record.ownershipAdditionalFields,
                      }}
                    />
                  )}
                </>
              ) : (
                <Text color={darkTheme.tableHeadTextColor}>
                  {t(
                    'entity_type_category.new.template_cannot_be_used_as_ownership.title',
                  )}
                </Text>
              )}
            </>
          );
        },
      },

      {
        hidden: values.visibleFor == OnboardType.Contact,
        key: 'required',
        width: 175,
        align: 'center',
        title: t(
          'entity_type_category.new.form_fields.selected_relationships.required',
        ),
        render: (
          value: any,
          record: SelectedRelationshipTemplate,
          index: number,
        ) => (
          <FormField
            name={`relationshipTemplates.${index}.required`}
            component={Checkbox}
            disabled={record.minAmount > 0}
          />
        ),
      },

      {
        hidden: values.visibleFor == OnboardType.Contact,
        key: 'controller',
        width: 175,
        align: 'center',
        title: t(
          'entity_type_category.new.form_fields.selected_relationships.controller',
        ),
        render: (
          value: any,
          record: SelectedRelationshipTemplate,
          index: number,
        ) => (
          <FormField
            name={`relationshipTemplates.${index}.controller`}
            component={Checkbox}
          />
        ),
      },

      {
        hidden: values.visibleFor == OnboardType.Contact,
        key: 'minimum_amount',
        width: 175,
        title: t(
          'entity_type_category.new.form_fields.selected_relationships.minimum_amount',
        ),
        render: (
          value: any,
          record: SelectedRelationshipTemplate,
          index: number,
        ) => (
          <FormField
            name={`relationshipTemplates.${index}.minAmount`}
            component={StyledFormInputNumber}
            additionalProps={{ min: 0 }}
            onChange={(newValue) => {
              if (newValue > 0 && !record.required) {
                setFieldValue(`relationshipTemplates.${index}.required`, true);
              } else if (!newValue && record.required) {
                setFieldValue(`relationshipTemplates.${index}.required`, false);
              }
            }}
          />
        ),
      },

      {
        hidden: values.visibleFor == OnboardType.Contact,
        key: 'minimum_percentage',
        width: 175,
        title: t(
          'entity_type_category.new.form_fields.selected_relationships.minimum_percentage',
        ),
        render: (
          value: any,
          record: SelectedRelationshipTemplate,
          index: number,
        ) => {
          return !record.isOwnership ? (
            <Text color={darkTheme.tableHeadTextColor}>
              {t(
                'entity_type_category.new.template_cannot_be_used_as_ownership.title',
              )}
            </Text>
          ) : (
            <FormField
              name={`relationshipTemplates.${index}.minPercentage`}
              component={StyledFormInputNumber}
            />
          );
        },
      },

      {
        key: 'actions',
        align: 'right',
        render: (value: any, record: any, index: number) => (
          <DeleteButton onClick={() => onDelete(index)} />
        ),
      },
    ];
  }, [t, values, ownershipRelationshipSelected, onDelete]);

  return (
    <Table
      bordered
      size="small"
      hidePagination
      data={values.relationshipTemplates}
      columns={columns}
    />
  );
});

const StyledFormInputNumber = styled(FormInputNumber)`
  width: 100%;
`;

export default SelectedRelationshipTemplates;
