import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { DateAcceptedStatus } from '../../../../../enums/crm/crm';

// components
import { Select, SelectProps } from 'antd';

type IProps = SelectProps<any>;

const InvitationDateAcceptedSelect = (props: IProps) => {
  const { t } = useTranslation('crm');

  const renderOptions = () => {
    const allowedOptions = [
      {
        key: 'all',
        content: 'All',
      },
      {
        key: DateAcceptedStatus.Pending,
        content: t(
          `invitations.table.date_accepted_statuses.${DateAcceptedStatus.Pending}`,
        ),
      },
      {
        key: DateAcceptedStatus.Accepted,
        content: t(
          `invitations.table.date_accepted_statuses.${DateAcceptedStatus.Accepted}`,
        ),
      },
      {
        key: DateAcceptedStatus.Expired,
        content: t(
          `invitations.table.date_accepted_statuses.${DateAcceptedStatus.Expired}`,
        ),
      },
    ];

    return allowedOptions.map((el) => (
      <Select.Option key={el.key} value={el.key}>
        {el.content}
      </Select.Option>
    ));
  };

  return (
    <Select
      defaultValue="all"
      {...props}
      value={props.value ? props.value : undefined}
      showArrow
    >
      {renderOptions()}
    </Select>
  );
};

export default InvitationDateAcceptedSelect;
