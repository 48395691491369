import React from 'react';

// helpers
import { useSelector } from 'react-redux';
import { SubRouteLayoutProps } from '../typings/routes';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { StateModel } from '../redux/reducers';
import { StateModel as UserAcccesStateModel } from '../redux/reducers/userAccess';

// constants
import { PERMISSION_KEYS } from '../config/permissions';

interface IComponentProps extends RouteComponentProps, SubRouteLayoutProps {}

// This custom component is responsible for securing permissions for pages.
// If the route has some permission, this hoc will check if the user has this permission
// This hoc is also responsible for safety when the user has an active onboarding status
// In this case user should see only the pages which has isOnboardingPage = true (in routes file)
const withPermissions = () => (Component: any) => {
  const WithPermissions = (componentProps: IComponentProps) => {
    const { permissions } = useSelector<StateModel, UserAcccesStateModel>(
      (state) => state.userAccess,
    );

    // Check if the user has permission to see selected page
    const canRender = () =>
      !componentProps.route.permission ||
      (componentProps.route.permission?.permissionKey &&
        permissions[
          componentProps.route.permission.permissionKey as PERMISSION_KEYS
        ].isAllowed);

    return canRender() ? (
      <Component {...componentProps} />
    ) : (
      <Redirect to="/" />
    );
  };

  return WithPermissions;
};

export default withPermissions;
