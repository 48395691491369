import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from 'react';

// helpers
import useTranslation from '../../useTranslation';
import transactionsAPI from '../../../api/compliance/transactionsAPI';
import ErrorHandlerService from '@services/error-handler/service';
import { message } from 'antd';
import { TransactionModel } from '../../../typings/finance/transaction';
import { TransactionReviewTarget } from '../../../typings/compliance/transactions';
import { ApprovalWorkflowInstanceModel } from '../../../typings/approval/approvalWorkflow';
import {
  ReviewConfigurationsStatuses,
  ReviewProcessStatuses,
} from '../../../enums/compliance/reviewConfiguration';
import {
  RDCAlertReviewResultModel,
  ReviewProcessAlertModel,
  ReviewProcessConfigurationRuleModel,
  ReviewProcessModel,
  ReviewProcessType,
} from '../../../typings/compliance/reviewProcess';

interface ReviewProcessProviderProps {
  children: ReactNode;
}

interface RDCReviewStateModel {
  alerts: ReviewProcessAlertModel[];
  progress?: ReviewProcessProgressModel;
}
interface ReviewConfigurationsStateModel {
  alert: ReviewProcessAlertModel | null;
  progress?: ReviewProcessProgressModel;
}

interface ReviewProcessContextData {
  transaction: TransactionModel | null;
  clientGroupId: number;
  workflow: ApprovalWorkflowInstanceModel | null;
  incomingTransaction: TransactionReviewTarget | null;
  outgoingTransaction: TransactionReviewTarget | null;
  reviewProgress: number;
  rdcReviewProcess: RDCReviewStateModel;
  reviewProcessStatus: ReviewProcessStatuses;
  transactionReviewProcess: ReviewConfigurationsStateModel;
  initContext: (
    transaction: TransactionModel,
    reviewProcess: ReviewProcessModel,
    workflow: any,
  ) => void;
  rejectAlert: (
    alert: ReviewProcessAlertModel,
    reason: string,
    rule?: ReviewProcessConfigurationRuleModel,
  ) => Promise<void>;
  approveAlert: (
    alert: ReviewProcessAlertModel,
    reason: string,
    rule?: ReviewProcessConfigurationRuleModel,
  ) => Promise<void>;
  submitProcessForReview: () => Promise<void>;
}

export interface ReviewProcessProgressModel {
  triggered: number;
  notTriggered: number;
  approved: number;
  rejected: number;
}

export enum ReviewActions {
  Approve,
  Reject,
}

// * Context
const ReviewProcessContext = createContext<ReviewProcessContextData>(
  {} as ReviewProcessContextData,
);

// * Provider
export const ReviewProcessContextProvider = ({
  children,
}: ReviewProcessProviderProps): JSX.Element => {
  const { t } = useTranslation('compliance');

  // --- States ---
  const [transaction, setTransaction] = useState<TransactionModel | null>(null);
  const [workflow, setWorkflow] =
    useState<ApprovalWorkflowInstanceModel | null>(null);
  const [incomingTransaction, setIncomingTransaction] =
    useState<TransactionReviewTarget | null>(null);
  const [outgoingTransaction, setOutgoingTransaction] =
    useState<TransactionReviewTarget | null>(null);
  const [clientGroupId, setClientGroupId] = useState(0);
  const [reviewProcessId, setReviewProcessId] = useState<string | null>(null);
  const [reviewProcessStatus, setReviewProcessStatus] =
    useState<ReviewProcessStatuses>(ReviewProcessStatuses.New);
  const [reviewProgress, setReviewProgress] = useState(0);

  const [rdcReviewProcess, setRDCReviewProcess] = useState<RDCReviewStateModel>(
    { alerts: [] },
  );
  const [transactionReviewProcess, setTransactionReviewProcess] =
    useState<ReviewConfigurationsStateModel>({ alert: null });
  // --- End states ---

  useEffect(() => {
    if (rdcReviewProcess.alerts) {
      const progress = getReviewProcessProgress(rdcReviewProcess.alerts);
      setRDCReviewProcess((prev) => ({ alerts: prev.alerts, progress }));
    }
  }, [rdcReviewProcess.alerts]);

  useEffect(() => {
    if (transactionReviewProcess.alert) {
      const progress = getReviewProcessProgress(
        transactionReviewProcess.alert.rules,
      );
      setTransactionReviewProcess((prev) => ({ alert: prev.alert, progress }));
    }
  }, [transactionReviewProcess.alert]);

  useEffect(() => {
    let result = 0;

    if (rdcReviewProcess.progress && transactionReviewProcess.progress) {
      const {
        triggered: rdcTotal,
        approved: rdcApproved,
        rejected: rdcRejected,
      } = rdcReviewProcess.progress;
      const {
        triggered: transferTotal,
        approved: transferApproved,
        rejected: transferRejected,
      } = transactionReviewProcess.progress;

      const sumTotal = rdcTotal + transferTotal;

      // Sum all reviewed items
      const sumReviewed =
        rdcApproved + transferApproved + rdcRejected + transferRejected;

      result = Math.round((sumReviewed / sumTotal) * 100);
    }

    setReviewProgress(result);
  }, [rdcReviewProcess.progress, transactionReviewProcess.progress]);

  // --- Methods ---
  const initContext = (
    transaction: TransactionModel,
    reviewProcess: ReviewProcessModel,
    workflow: ApprovalWorkflowInstanceModel | null,
  ) => {
    setTransaction(transaction);
    setReviewProcessId(reviewProcess.id);
    setWorkflow(workflow);

    if (reviewProcess.incomingTransaction) {
      setIncomingTransaction(reviewProcess.incomingTransaction);
      setClientGroupId(
        reviewProcess.incomingTransaction.transaction
          .beneficiaryAccountClientId,
      );
    }

    if (reviewProcess.outgoingTransaction) {
      setOutgoingTransaction(reviewProcess.outgoingTransaction);
      setClientGroupId(
        reviewProcess.outgoingTransaction.transaction.orderingAccountClientId,
      );
    }

    const activeRDC = reviewProcess.activeObjectsContainer.find(
      (e) => e.type === ReviewProcessType.RDC,
    );
    if (activeRDC) {
      setRDCReviewProcess({
        alerts: activeRDC.objects,
        progress: { triggered: 0, notTriggered: 0, approved: 0, rejected: 0 },
      });
    }

    const activeTransfer = reviewProcess.activeObjectsContainer.find(
      (e) => e.type === ReviewProcessType.Transaction,
    );
    if (activeTransfer && activeTransfer.objects.length) {
      // transaction object can have only 1 alert
      setTransactionReviewProcess({
        alert: activeTransfer.objects[0],
        progress: { triggered: 0, notTriggered: 0, approved: 0, rejected: 0 },
      });
    }

    setReviewProcessStatus(reviewProcess.status);
  };

  const approveAlert = async (
    alert: ReviewProcessAlertModel,
    reason: string,
    rule?: ReviewProcessConfigurationRuleModel,
  ) => {
    if (!reviewProcessId) {
      return;
    }

    switch (alert.objectType) {
      case ReviewProcessType.RDC:
        {
          const response = await transactionsAPI.approveReviewProcessAlert(
            ReviewProcessType.RDC,
            reviewProcessId,
            alert.objectId,
            reason,
            undefined,
            alert.inquiryId,
          );
          if (response && response.data.length) {
            const { reviewResult, metadata } = response.data[0];
            updateAlertById(
              alert.objectType,
              alert.objectId,
              reviewResult,
              metadata.inquiryId,
            );
          }
        }
        break;

      case ReviewProcessType.Transaction:
        {
          const response = await transactionsAPI.approveReviewProcessAlert(
            ReviewProcessType.Transaction,
            reviewProcessId,
            alert.objectId,
            reason,
            rule?.id,
          );
          if (response && response.data.length) {
            const { reviewResult, metadata } = response.data[0];
            updateAlertById(
              alert.objectType,
              rule?.id as string,
              reviewResult,
              metadata.inquiryId,
            );
          }
        }
        break;
    }

    if (reviewProcessStatus === ReviewProcessStatuses.New) {
      setReviewProcessStatus(ReviewProcessStatuses.InProgress);
    }

    message.success(t('transactions.success_approve_alert'));
  };

  const rejectAlert = async (
    alert: ReviewProcessAlertModel,
    reason: string,
    rule?: ReviewProcessConfigurationRuleModel,
  ) => {
    if (!reviewProcessId) {
      return;
    }

    switch (alert.objectType) {
      case ReviewProcessType.RDC:
        {
          const response = await transactionsAPI.rejectReviewProcessAlert(
            ReviewProcessType.RDC,
            reviewProcessId,
            alert.objectId,
            reason,
            undefined,
            alert.inquiryId,
          );

          if (response && response.data.length) {
            const { reviewResult, metadata } = response.data[0];
            updateAlertById(
              alert.objectType,
              alert.objectId,
              reviewResult,
              metadata.inquiryId,
            );
          }
        }
        break;

      case ReviewProcessType.Transaction:
        {
          const response = await transactionsAPI.rejectReviewProcessAlert(
            ReviewProcessType.Transaction,
            reviewProcessId,
            alert.objectId,
            reason,
            rule?.id,
          );
          if (response && response.data.length) {
            const { reviewResult, metadata } = response.data[0];
            updateAlertById(
              alert.objectType,
              rule?.id as string,
              reviewResult,
              metadata.inquiryId,
            );
          }
        }
        break;
    }

    if (reviewProcessStatus === ReviewProcessStatuses.New) {
      setReviewProcessStatus(ReviewProcessStatuses.InProgress);
    }

    message.success(t('transactions.success_reject_alert'));
  };

  const submitProcessForReview = async () => {
    if (reviewProgress < 100 || !reviewProcessId) {
      return;
    }

    try {
      await transactionsAPI.submitForReview(reviewProcessId);
    } catch (error) {
      ErrorHandlerService.handleError(error);
    }

    setReviewProcessStatus(ReviewProcessStatuses.PendingApproval);
  };

  // --- End methods ---

  // --- Helper methods ---
  const getReviewProcessProgress = (
    reviewAlerts: Array<
      ReviewProcessAlertModel | ReviewProcessConfigurationRuleModel
    >,
  ): ReviewProcessProgressModel => {
    const reviewProgress = reviewAlerts.reduce<ReviewProcessProgressModel>(
      (acc, el) => {
        if (el.isTriggered) {
          acc.triggered += 1;
        } else {
          acc.notTriggered += 1;
        }

        if (el.reviewResult) {
          if (el.reviewResult.status === ReviewConfigurationsStatuses.Passed) {
            acc.approved += 1;
          } else if (
            el.reviewResult.status === ReviewConfigurationsStatuses.NotPassed
          ) {
            acc.rejected += 1;
          }
        }

        return acc;
      },
      {
        triggered: 0,
        notTriggered: 0,
        approved: 0,
        rejected: 0,
      },
    );

    return reviewProgress;
  };

  const updateAlertById = (
    reviewProcessType: ReviewProcessType,
    targetId: string,
    newReviewResult: RDCAlertReviewResultModel,
    inquiryId?: string,
  ) => {
    switch (reviewProcessType) {
      case ReviewProcessType.RDC:
        {
          const alertsCopy = rdcReviewProcess.alerts.slice();
          const alertIndex = alertsCopy.findIndex(
            (e) => e.objectId === targetId && e.inquiryId === inquiryId,
          );
          alertsCopy[alertIndex].reviewResult = newReviewResult;
          setRDCReviewProcess((prev) => ({
            alerts: alertsCopy,
            progress: prev.progress,
          }));
        }
        break;

      case ReviewProcessType.Transaction:
        {
          const rulesCopy = transactionReviewProcess.alert?.rules.slice() || [];
          const ruleIndex = rulesCopy.findIndex((e) => e.id === targetId);
          rulesCopy[ruleIndex].reviewResult = newReviewResult;
          setTransactionReviewProcess(
            (prev) =>
              ({ ...prev, alert: { ...prev.alert, rules: rulesCopy } }) as any,
          );
        }
        break;
    }
  };
  // --- End helper methods ---

  return (
    <ReviewProcessContext.Provider
      value={{
        transaction,
        workflow,
        clientGroupId,
        reviewProgress,
        rdcReviewProcess,
        reviewProcessStatus,
        transactionReviewProcess,
        initContext,
        rejectAlert,
        approveAlert,
        submitProcessForReview,
        incomingTransaction,
        outgoingTransaction,
      }}
    >
      {children}
    </ReviewProcessContext.Provider>
  );
};

// * Hook
export function useReviewProcessContext(): ReviewProcessContextData {
  const context = useContext(ReviewProcessContext);
  return context;
}
