import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';

// components
import ApprovalGroupsTab from './ApprovalGroupsTab';
import Tabs, { TabModel } from 'components/Tabs';
import ApprovalRulesTab from './ApprovalRulesTab';

interface IProps {
  clientGroupNumericId: number;
}

const Onboarding = ({ clientGroupNumericId }: IProps) => {
  const { t } = useTranslation('crm');

  const tabs = useMemo<TabModel[]>(() => {
    return [
      {
        key: 'approval-groups',
        title: t('client_group.tabs.approval_groups'),
        content: (
          <ApprovalGroupsTab clientGroupNumericId={clientGroupNumericId} />
        ),
      },

      {
        key: 'approval-rules',
        title: t('client_group.tabs.approval_rules'),
        content: (
          <ApprovalRulesTab clientGroupNumericId={clientGroupNumericId} />
        ),
      },
    ];
  }, [t, clientGroupNumericId]);

  return <Tabs tabs={tabs} renderOnChange />;
};

export default Onboarding;
