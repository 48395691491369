import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import { Button, Typography } from 'antd';
import { ReactComponent as CheckIcon } from '../../../../../../../resources/images/icons/check.svg';

const SetupNameForKeyStep = () => {
  const { t } = useTranslation(['profile', 'common']);
  const { submitForm } = useFormikContext<FormValuesModel>();

  return (
    <>
      <StyledCheckIcon />
      <StyledParagraph>{t('success', { ns: 'common' })}</StyledParagraph>

      <FormField
        name="name"
        component={FormInput}
        label={t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_modal.setup_name_key.form_fields.name',
        )}
      />

      <Button size="large" type="primary" onClick={submitForm}>
        {t('save', { ns: 'common' })}
      </Button>
    </>
  );
};

const StyledParagraph = styled(Typography.Paragraph)`
  text-align: center;
  color: ${({ theme }) => theme.successColor};
  font-size: ${({ theme }) => theme.fontSizeMd};
  margin-top: ${({ theme }) => theme.marginSm};
`;

const StyledCheckIcon = styled(CheckIcon)`
  width: 100px;
  height: 100px;
  fill: ${({ theme }) => theme.successColor};
`;

export default SetupNameForKeyStep;
