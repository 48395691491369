import React, { memo } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';

// components
import NewMessagesTable from 'modules/CRM/Dashboard/NewMessagesTable';
import { Col, Row } from 'antd';
import { CardWrapper } from 'layouts/PageLayout';

const CRMDashboardPage = memo(() => {
  const [hasMessagesAccess] = useUserAccess([
    'CRM_Dashboard_MessagesWidget_View',
  ]);

  return (
    <Row gutter={[24, 24]}>
      {hasMessagesAccess && (
        <Col flex={1}>
          <CardWrapper noMargin>
            <NewMessagesTable />
          </CardWrapper>
        </Col>
      )}
    </Row>
  );
});

export default CRMDashboardPage;
