import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import { DOCUMENT_UPLOAD_TIMEOUT } from '../../constants/global';
import {
  IDocumentBase,
  IFullDocument,
  ITag,
} from '../../typings/documents/documents';

export interface IGetDocumentResponse {
  currentPage: number;
  totalCount: number;
  limit: number;
  lastPage: number;
  data: IDocumentBase[];
}

export interface FetchDocumentsParamsModel extends FetchParamsModel {
  itemId?: string;
  creatorId?: string;
  filterTags?: string;
  search?: string;
  associationItemId?: string;
  associationType?: string;
}

const documentsAPI = {
  fetchDocuments: (params: FetchDocumentsParamsModel) => {
    return APIService.get<FetchResponseModel<IDocumentBase>>(APIConfig.dmsApi, {
      params,
    }).then(({ data }) => data);
  },

  fetchDocumentsByClientGroupId: (
    clientGroupId: string,
    params: FetchParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<IDocumentBase>>(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/documents`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  getDocumentById: (documentId: string) => {
    return APIService.get<IFullDocument>(
      `${APIConfig.dmsApi}/${documentId}`,
    ).then(({ data }) => data);
  },

  uploadDocument: (
    formData: any,
    uploadProgressCallback: (percentage: number) => void,
  ) => {
    return APIService.post(`${APIConfig.dmsApi}/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: DOCUMENT_UPLOAD_TIMEOUT,
      onUploadProgress: uploadProgressCallback,
    }).then(({ data }) => data);
  },

  fetchTags: (search: string) => {
    return APIService.get<FetchResponseModel<ITag>>(
      `${APIConfig.dmsApi}/tags/autocomplete`,
      { params: { search } },
    ).then(({ data }) => data);
  },

  deleteDocument: (documentId: string) => {
    return APIService.delete(`${APIConfig.dmsApi}/${documentId}`).then(
      ({ data }) => data,
    );
  },
};

export { documentsAPI };
