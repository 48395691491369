import React, { useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import { IAccountModel } from '../../../../../../typings/finance/account';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { StyledComponentProps } from '../../../../../../typings/common';
import { correspondentBanksAPI } from '../../../../../../api/banking/correspondentBanksAPI';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import EllipsisTooltip from '../../../../../Tooltips/EllipsisTooltip';
import FormAutocomplete, {
  FormAutocompleteProps,
} from '@common_components/Form/FormAutocomplete';
import { Typography } from 'antd';

interface IProps
  extends StyledComponentProps,
    FormAutocompleteProps<IAccountModel> {
  currencyId?: number;
}

const CorrespondentBankAccountsFormAutocomplete = ({
  currencyId,
  ...rest
}: IProps) => {
  const [updateOptionsTrigger, updateOptions] = useState<any>();

  useEffect(() => {
    updateOptions({});
  }, [currencyId]);

  const fetchAccounts = async (accountQuery: string) => {
    if (!currencyId) {
      return [];
    }

    const { data } = await correspondentBanksAPI.fetchCorrespondentBankAccounts(
      {
        page: 1,
        limit: AUTOCOMPLETE_RESULT_LIMIT,
        accountQuery,
        currencyId,
      },
    );

    return data.map<AutocompleteOption<IAccountModel>>((account) => {
      const { accountNumber, accountName, currencyCode } = account;
      const name = AccountsHelpers.generateAccountFullName(
        accountName,
        accountNumber,
        currencyCode,
      );

      return {
        id: accountNumber,
        model: account,
        label: (
          <EllipsisTooltip
            placement="right"
            overlayStyle={{ maxWidth: '300px' }}
            title={
              <>
                <StyledParagraph>{accountNumber}</StyledParagraph>
                <StyledParagraph>{accountName}</StyledParagraph>
                <StyledParagraph>{currencyCode}</StyledParagraph>
              </>
            }
          >
            {name}
          </EllipsisTooltip>
        ),
      };
    });
  };

  return (
    <FormAutocomplete
      fetchData={fetchAccounts}
      refreshAutocompleteTrigger={updateOptionsTrigger}
      {...rest}
    />
  );
};

const StyledParagraph = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
`;

export default CorrespondentBankAccountsFormAutocomplete;
