import React from 'react';

// helpers
import { useFormikContext } from 'formik';

// constants
import { DISABLED_FORM_STATUS } from '../../../constants/form';

interface IProps {
  children: React.ReactNode;
  hideIfSubmitting?: boolean;
}

const HideIfDisabledForm = ({ hideIfSubmitting, children }: IProps) => {
  const { isSubmitting, status } = useFormikContext();

  if (hideIfSubmitting && isSubmitting) {
    return null;
  }

  if (status === DISABLED_FORM_STATUS) {
    return null;
  }

  return <>{children}</>;
};

export default HideIfDisabledForm;
