import * as yup from 'yup';
import {
  NULLABLE_STRING_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
} from 'validations/common';

export const Enable2FAValidationSchema = yup.object().shape({
  code: NULLABLE_STRING_VALIDATION_RULE.min(
    6,
    'Invalid one time password',
  ).required('This is required field'),
});

export const RegisterSecurityKeyValidationSchema = yup.object().shape({
  name: STRING_VALIDATION_RULE.required('This is required field'),
});

export const UpdateSecurityKeyValidationSchema = yup.object().shape({
  name: STRING_VALIDATION_RULE.required('This is required field'),
});
