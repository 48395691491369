import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { FieldArray } from 'formik';
import { OnboardType } from 'enums/crm/crm';
import { SelectedRelationshipTemplate } from './RelationshipTemplatesSection/SelectedRelationshipTemplates';

// components
import Checkbox from '@core_components/Checkbox';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import AddButton from '@common_components/Buttons/AddButton';
import SectionIntro from '@common_components/Texts/SectionIntro';
import FormErrorTooltip from 'components/Forms/FormErrorTooltip';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import RelationshipTemplatesSection from './RelationshipTemplatesSection';
import AdditionalFieldsSection, {
  EntityTypeCategoryAdditionalField,
} from './AdditionalFieldsSection';
import { Col, Row } from 'antd';

export type FormValuesModel = {
  name: string;
  description: string;
  visibleFor?: OnboardType;
  isOnboardingOption: boolean;
  additionalFields: EntityTypeCategoryAdditionalField[];
  relationshipTemplates: SelectedRelationshipTemplate[];
};

const EntityTypeCategoryForm = memo(() => {
  const { t } = useTranslation(['crm', 'common']);

  const baseAdditionalFieldValue = useMemo<EntityTypeCategoryAdditionalField>(
    () => ({
      name: '',
      onboardingRequired: null,
      isDirectClientOnly: null,
      isRegulatedOnly: null,
      description: '',
      type: null,
      requireCertification: false,
      selectOptions: [],
      formDocument: [],
    }),
    [],
  );

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={19}>
          <FormField
            name="name"
            component={FormInput}
            label={t('entity_type_category.new.form_fields.name')}
          />
        </Col>
        <Col span={5}>
          <FormField
            name="isOnboardingOption"
            component={Checkbox}
            label={t('entity_type_category.new.form_fields.onboarding_option')}
          />
        </Col>
      </Row>

      <FormField
        name="description"
        component={FormInputTextArea}
        label={t('entity_type_category.new.form_fields.description')}
      />

      <SectionIntro
        title={t('entity_type_category.new.additional_fields_section')}
        titleVariant="h4"
      />
      <FieldArray
        name="additionalFields"
        render={(helpers) => (
          <>
            <FormErrorTooltip<FormValuesModel> errorKey="additionalFields" />
            <AdditionalFieldsSection onDelete={(i) => helpers.remove(i)} />
            <StyledRow justify="end">
              <Col>
                <AddButton
                  onClick={() => helpers.push(baseAdditionalFieldValue)}
                >
                  {t('add', { ns: 'common' })}
                </AddButton>
              </Col>
            </StyledRow>
          </>
        )}
      />

      <SectionIntro
        title={t('entity_type_category.new.relationship_templates_section')}
        titleVariant="h4"
      />
      <RelationshipTemplatesSection />
    </>
  );
});

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginXs};
`;

export default EntityTypeCategoryForm;
