import React, { useEffect, useState } from 'react';

// helpers
import { FidoHelpers } from '@helpers/fido';
import { AuthHelpers } from '@helpers/auth';
import {
  AssertionForLoginModel,
  GetChallengeForLoginResponseModel,
} from '../../../../../../../typings/profile/fido';

// components
import LoadingWrapper from '../../../../../../WrapperComponents/LoadingWrapper';
import ErrorWithRetryButton from './ErrorWithRetryButton';
import ContentWithUseKeyButton from './ContentWithUseKeyButton';

interface IProps {
  challenge: GetChallengeForLoginResponseModel | null;
  loadingChallenge: boolean;
  onAssert?: () => void;
  submitAssertion: (assertion: AssertionForLoginModel) => Promise<any>;
}

const Content = ({
  challenge,
  loadingChallenge,
  onAssert,
  submitAssertion,
}: IProps) => {
  const isSafari = AuthHelpers.isSafariBrowser();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Safari browser is not allowing WebAuthn usage without user interaction
    // To use WebAuthn user will have to click on special button (by that trigger onClick event where we can run ceremony)
    if (challenge && !isSafari) {
      runFidoCeremony();
    }
  }, [challenge, isSafari]);

  async function runFidoCeremony() {
    if (challenge) {
      setIsError(false);
      setIsLoading(true);
      const publicKey = FidoHelpers.preformatChallengeForLogin(
        challenge.assertionChallenge,
      );

      await navigator.credentials
        .get({ publicKey })
        .then(async (assertion) => {
          if (assertion) {
            const formattedAssertion = FidoHelpers.publicKeyCredentialToJSON(
              assertion,
            ) as AssertionForLoginModel;
            await submitAssertion(formattedAssertion);
            onAssert && onAssert();
          }
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  return (
    <LoadingWrapper loading={isLoading || loadingChallenge}>
      {isSafari && !isError ? (
        <ContentWithUseKeyButton onTryClick={runFidoCeremony} />
      ) : (
        <ErrorWithRetryButton onRetryClick={runFidoCeremony} />
      )}
    </LoadingWrapper>
  );
};

export default Content;
