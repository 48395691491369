import React, { useEffect } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import useQueryParam from '../../../hooks/useQueryParam';
import transactionsAPI from '../../../api/compliance/transactionsAPI';
import { RoutePaths } from '../../../routes/routes';
import { useReviewProcessContext } from '../../../hooks/domainHooks/useReviewProcess';

// components
import ActionsSection from './ActionsSection';
import LoadingWrapper from '../../../components/WrapperComponents/LoadingWrapper';
import ReviewProcessInfoSection from './ReviewProcessInfoSection';
import ReviewConfigurationsSection from './ReviewConfigurationsSection';
import { Divider } from 'antd';

const TransactionDetails = () => {
  const reviewProcessId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.Compliance_Transactions,
  });

  const { initContext } = useReviewProcessContext();
  const { response, loading } = useFetch(
    () =>
      reviewProcessId
        ? transactionsAPI.fetchReviewProcessById(reviewProcessId)
        : null,
    [reviewProcessId],
  );

  useEffect(() => {
    if (response) {
      const { transaction, reviewProcess, workflow } = response;
      initContext(transaction, reviewProcess, workflow);
    }
  }, [response]);

  return (
    <LoadingWrapper loading={loading}>
      {!!response && (
        <>
          <ActionsSection />
          <ReviewProcessInfoSection />
          <Divider />
          <ReviewConfigurationsSection />
        </>
      )}
    </LoadingWrapper>
  );
};

export default TransactionDetails;
