import React, { useEffect, useState } from 'react';

// helpers
import useUserAccess from '../../hooks/useUserAccess';
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_ACCOUNT_CATEGORIES_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';
import AccountCategoriesTable from '../../modules/Finance/AccountCategories/AccountCategoriesTable';
import AddAccountCategoryRule from '../../modules/Finance/AccountCategories/AccountCategoriesTable/AddAccountCategoryRule';

type IProps = PageLayoutHelperPropsModel;

const AccountCategories = ({ setHeaderOptions }: IProps) => {
  const [hasCreateAccess] = useUserAccess(['Finance_AccountCategories_Create']);
  const [updateTableTrigger, updateTable] = useState<unknown>();

  useEffect(() => {
    if (hasCreateAccess) {
      setHeaderOptions({
        appendToTitle: (
          <TooltipToDocumentation
            link={GENESIS_ACCOUNT_CATEGORIES_DOCUMENTATION_LINK}
          />
        ),
        extra: <AddAccountCategoryRule onAdd={() => updateTable({})} />,
      });
    }
  }, [hasCreateAccess]);

  return <AccountCategoriesTable updateTableTrigger={updateTableTrigger} />;
};

export default AccountCategories;
