import React from 'react';

// ** Helpers utils **
import useTranslation from '../../../../hooks/useTranslation';

// ** Components **
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons/lib';

const CertificationRequiredTooltip = () => {
  const { t } = useTranslation('crm');

  const tooltipRows = [
    t('application.documentation.certification_required_tooltip.item_1'),
    t('application.documentation.certification_required_tooltip.item_2'),
    t('application.documentation.certification_required_tooltip.item_3'),
    t('application.documentation.certification_required_tooltip.item_4'),
  ];

  const tooltipText = (
    <div>
      {t('application.documentation.certification_required_tooltip.text')}

      <ul>
        {tooltipRows.map((content, i) => (
          <li key={i}>{content}</li>
        ))}
      </ul>
    </div>
  );

  return (
    <Popover content={tooltipText} placement="leftTop">
      <InfoCircleOutlined />
    </Popover>
  );
};

export default CertificationRequiredTooltip;
