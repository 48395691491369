import React, { memo, useMemo } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { ISession } from '../../../../../typings/profile/security';
import { DateHelpers } from '@helpers/date';
import { SessionHelpers } from '@helpers/sessions';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';
import { DesktopOutlined } from '@ant-design/icons';

type IProps = RequiredPropsForTableModel<ISession>;

const SessionHistoryTable = memo((props: IProps) => {
  const { t } = useTranslation('profile');

  const renderSessionDuration = (session: ISession) => {
    const duration = moment.utc(
      moment(session.updatedAt).diff(session.createdAt),
    );
    return `${duration.format('H')}:${duration.format('mm')}:${duration.format('ss')}`;
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('sign_in_and_security.sessions.table.date'),
        key: 'signInDate',
        render: (record: ISession) =>
          DateHelpers.formatDateToString(record.createdAt, 'llll'),
      },

      {
        title: t('sign_in_and_security.sessions.table.device'),
        key: 'device',
        render: (record: ISession) => (
          <Typography.Text>
            <StyledDesktopIcon />
            {SessionHelpers.getDeviceName(record.device)}
          </Typography.Text>
        ),
      },

      {
        title: t('sign_in_and_security.sessions.table.session_duration'),
        key: 'duration',
        render: renderSessionDuration,
      },
    ];

    return result;
  }, []);

  return <Table columns={columns} {...props} />;
});

const StyledDesktopIcon = styled(DesktopOutlined)`
  margin-right: 10px;
`;

export default SessionHistoryTable;
