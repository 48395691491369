import React, { useEffect } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_FOREX_TRANSFER_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';
import { default as PendingForexTransfersTable } from '../../modules/Banking/PendingForexTransfers';

type IProps = PageLayoutHelperPropsModel;

const PendingForexTransfers = ({ setHeaderOptions }: IProps) => {
  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation
          link={GENESIS_FOREX_TRANSFER_DOCUMENTATION_LINK}
        />
      ),
    });
  }, []);

  return <PendingForexTransfersTable />;
};

export default PendingForexTransfers;
