import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';

import { To } from 'history';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { changeView } from '../../../../../redux/actions/view';
import { useDispatch } from 'react-redux';
import { DateHelpers } from '@helpers/date';
import { AccountRecoveryRequestModel } from '../../../../../typings/ORAC/accountRecoveryRequests';
import { DEFAULT_FULL_DATE_TIME_FORMAT } from '../../../../../constants/global';

// components
import ResetAuthenticationRequestStatus from 'components/Additional/Statuses/ResetAuthenticationRequestStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Button, Typography } from 'antd';

type IProps = RequiredPropsForTableModel<AccountRecoveryRequestModel>;

const AccountRecoveryRequestsTable = memo((props: IProps) => {
  const { t } = useTranslation('orac');

  const history = useHistory();
  const dispatch = useDispatch();

  const historyPush = (path: To) => {
    dispatch(changeView(() => history.push(path)));
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('account_recovery_requests.date'),
        key: 'date',
        render: (record: AccountRecoveryRequestModel) =>
          DateHelpers.formatDateToString(
            record.createdAt,
            DEFAULT_FULL_DATE_TIME_FORMAT,
          ),
        width: '20%',
      },

      {
        title: t('account_recovery_requests.name'),
        key: 'name',
        width: '20%',
        render: ({ user }: AccountRecoveryRequestModel) => (
          <Typography.Text>{user?.name}</Typography.Text>
        ),
      },

      {
        title: t('account_recovery_requests.username'),
        key: 'username',
        width: '20%',
        render: ({ user }: AccountRecoveryRequestModel) => (
          <Typography.Text>{user?.username || '-'}</Typography.Text>
        ),
      },

      {
        title: t('account_recovery_requests.email'),
        key: 'email',
        width: '25%',
        render: ({ user }: AccountRecoveryRequestModel) => (
          <Typography.Text>{user?.email}</Typography.Text>
        ),
      },

      {
        title: t('account_recovery_requests.status'),
        key: 'status',
        render: (record: AccountRecoveryRequestModel) => (
          <ResetAuthenticationRequestStatus status={record.status} />
        ),
        width: '15%',
      },

      {
        key: 'date',
        render: (record: AccountRecoveryRequestModel) => (
          <StyledButton
            onClick={() =>
              historyPush({
                pathname: RoutePaths.ORAC_AccountRecoveryRequest_Details,
                search: `?id=${record._id}`,
              })
            }
          >
            {t('account_recovery_requests.details')}
          </StyledButton>
        ),
        width: '20%',
      },
    ];

    return result;
  }, []);

  return <Table columns={columns} {...props} />;
});

const StyledButton = styled(Button)`
  width: 100%;
`;

export default AccountRecoveryRequestsTable;
