import { SocialMediaLinksModel } from 'typings/crm/common';
import { EntityType, SocialMedia } from 'enums/crm/crm';
import { IEntityName, ISocialMediaModel } from 'typings/crm/entity';

const EntityHelpers = {
  getEntityName: function (
    entity?: { names?: IEntityName[] },
    kind: EntityType = EntityType.Legal,
  ): string {
    const nameItem = entity?.names?.find((el) => el.type == kind);
    return nameItem?.name || '';
  },

  getSocialMediaLink: function (
    kind: SocialMedia,
    socialMedia: ISocialMediaModel[] = [],
  ) {
    const socialMediaItem = socialMedia.find((el) => el.type == kind);
    return socialMediaItem?.path || '';
  },

  getSocialMediaData: function (socialMedia: SocialMediaLinksModel) {
    const result: { type: SocialMedia; path: string }[] = [];

    if (socialMedia.twitterLink) {
      result.push({
        type: SocialMedia.Twitter,
        path: socialMedia.twitterLink,
      });
    }

    if (socialMedia.facebookLink) {
      result.push({
        type: SocialMedia.Facebook,
        path: socialMedia.facebookLink,
      });
    }

    if (socialMedia.linkedInLink) {
      result.push({
        type: SocialMedia.LinkedIn,
        path: socialMedia.linkedInLink,
      });
    }

    if (socialMedia?.websiteLink) {
      result.push({
        type: SocialMedia.Website,
        path: socialMedia.websiteLink,
      });
    }

    return result;
  },
};

export { EntityHelpers };
