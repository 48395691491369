import React, { useMemo, useState } from 'react';

// helpers
import rolesAPI from '../../../../api/orac/rolesAPI';
import useTranslation from '../../../../hooks/useTranslation';

// components
import { Button, message } from 'antd';
import AddEditRoleModal, {
  FormValuesModel,
} from '../../../../components/Modals/TemplateModalDialogs/ORAC/Roles/AddEditRoleModal';

interface IProps {
  onAdd?: () => void;
}

const AddRole = ({ onAdd }: IProps) => {
  const { t } = useTranslation('orac');

  const [isModalVisible, setVisibleModal] = useState(false);
  const initialFormValues: FormValuesModel = useMemo(
    () => ({ name: '', description: '' }),
    [],
  );

  const handleSubmit = async (values: FormValuesModel) => {
    await rolesAPI.createNewRole(values);
    message.success(t('roles.add.success'));
  };

  const closeCallback = (wasAdded?: boolean) => {
    if (wasAdded) {
      onAdd && onAdd();
    }
    setVisibleModal(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setVisibleModal(true)}>
        {t('roles.add.title')}
      </Button>
      <AddEditRoleModal
        title={t('roles.add.title')}
        onSubmit={handleSubmit}
        isVisible={isModalVisible}
        closeCallback={closeCallback}
        initialFormValues={initialFormValues}
      />
    </>
  );
};

export default AddRole;
