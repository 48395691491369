import React, { memo } from 'react';

// helpers
import { FormikHelpers } from 'formik';
import { EntityTypeCategoryFormValidationSchema } from 'validations/crm/keyRelationshipTemplate';

// components
import ConfirmDeleteButton from 'components/Buttons/ConfirmDeleteButton';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import EntityTypeCategoryForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/CRM/EntityTypeCategoryForm';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export type EntityTypeCategoryFormValuesModel = FormValuesModel;

interface IProps
  extends RequiredPropsForFormModel<EntityTypeCategoryFormValuesModel>,
    RequiredPropsForModalDialogModel {
  title: string;
  onDelete?: (
    reason: string,
    form: FormikHelpers<FormValuesModel>,
  ) => Promise<void>;
}

const EntityTypeCategoryModalDialog = memo(
  ({
    isVisible,
    closeCallback,
    initialValues,
    onSubmit,
    title,
    onDelete,
  }: IProps) => {
    return (
      <Form
        enableReinitialize
        confirmExitWithoutSaving
        onSubmit={onSubmit}
        initialValues={isVisible ? initialValues : null}
        validationSchema={EntityTypeCategoryFormValidationSchema}
        renderForm={(form) => (
          <ModalDialog
            title={title}
            isVisible={isVisible}
            closeCallback={closeCallback}
            width="85%"
            extendedFooterButtonsPosition="before"
            extendedFooterButtons={
              onDelete && (
                <ConfirmDeleteButton
                  shouldAskReason
                  btnSize="large"
                  deleteCallback={(reason) => onDelete(reason || '', form)}
                />
              )
            }
          >
            <EntityTypeCategoryForm />
          </ModalDialog>
        )}
      />
    );
  },
);

export default EntityTypeCategoryModalDialog;
