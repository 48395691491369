import React, { memo, useMemo, useState } from 'react';

// hlpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';
import {
  RDCReviewStatuses,
  ReviewConfigurationsStatuses,
} from '../../../../../enums/compliance/reviewConfiguration';
import {
  RDCAlertInfoModel,
  ReviewProcessAlertModel,
  ReviewProcessType,
} from '../../../../../typings/compliance/reviewProcess';

// components
import InfoIconWithTooltip from '../../../../Icons/InfoIconWithTooltip';
import RDCTransactionModal from '../../../../Modals/TemplateModalDialogs/Compliance/RDCTransactionModal';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<ReviewProcessAlertModel> {
  reviewProcessType: ReviewProcessType;
  expandable?: ExpandableConfig<ReviewProcessAlertModel>;
}

const ReviewProcessObjectsTable = memo(
  ({ reviewProcessType, ...rest }: IProps) => {
    const { t } = useTranslation('compliance');

    const [selectedRDCTransaction, setSelectedRDCTransaction] =
      useState<RDCAlertInfoModel | null>(null);

    const getActionsByReviewProcessType = (
      reviewType: ReviewProcessType,
    ): { passedText: string; notPassedText: string } => {
      switch (reviewType) {
        case ReviewProcessType.RDC:
          return {
            passedText: t(
              `review_statuses.${RDCReviewStatuses[RDCReviewStatuses.NotMatched]}`,
            ),
            notPassedText: t(
              `review_statuses.${RDCReviewStatuses[RDCReviewStatuses.Matched]}`,
            ),
          };

        case ReviewProcessType.Transaction:
          return {
            passedText: t(
              `review_statuses.${ReviewConfigurationsStatuses[ReviewConfigurationsStatuses.Passed]}`,
            ),
            notPassedText: t(
              `review_statuses.${ReviewConfigurationsStatuses[ReviewConfigurationsStatuses.NotPassed]}`,
            ),
          };
      }
    };

    const renderReviewProcessStatus = ({
      reviewResult,
    }: ReviewProcessAlertModel) => {
      let result: React.ReactNode | null = null;
      if (reviewResult) {
        const { status, reason } = reviewResult;
        const { passedText, notPassedText } =
          getActionsByReviewProcessType(reviewProcessType);

        if (status === ReviewConfigurationsStatuses.Pending) {
          result = (
            <YellowText>
              {t(
                `review_statuses.${ReviewConfigurationsStatuses[ReviewConfigurationsStatuses.Pending as ReviewConfigurationsStatuses]}`,
              )}
            </YellowText>
          );
        } else if (status === ReviewConfigurationsStatuses.Passed) {
          result = (
            <GreenText>
              {passedText}{' '}
              <StyledInfoIcon
                tooltipMessage={`${t('transactions.reason')}: ${reason}`}
              />
            </GreenText>
          );
        } else if (status === ReviewConfigurationsStatuses.NotPassed) {
          result = (
            <RedText>
              {notPassedText}{' '}
              <StyledInfoIcon
                tooltipMessage={`${t('transactions.reason')}: ${reason}`}
              />
            </RedText>
          );
        }
      } else {
        result = <GreyText>{t('transactions.not_triggered')}</GreyText>;
      }
      return result;
    };

    const renderReviewProcessDetails = ({
      reviewResult,
    }: ReviewProcessAlertModel) => {
      if (reviewResult) {
        const { reviewedAt, reviewedByName, status } = reviewResult;
        // TODO: This is the temporary solution for the issue with the date in RDC with pending status
        if (status === ReviewConfigurationsStatuses.Pending) return '-';
        else {
          const reviewDate = DateHelpers.formatDateToString(
            String(reviewedAt),
            DEFAULT_DATE_TIME_FORMAT,
          );
          return reviewedByName
            ? `${reviewedByName} - ${reviewDate}`
            : reviewDate;
        }
      }
      return null;
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          width: 120,
          title: t('transactions.id'),
          key: 'id',
          render: (record: ReviewProcessAlertModel) => record.objectId,
        },

        {
          width: 350,
          title: t('transactions.name'),
          key: 'name',
          render: (record: ReviewProcessAlertModel) => (
            <Typography.Link
              onClick={() => setSelectedRDCTransaction(record.rdcAlert)}
            >
              {' '}
              {record.rdcAlert?.entityName}{' '}
            </Typography.Link>
          ),
        },

        {
          width: 350,
          title: t('transactions.review_status'),
          key: 'review_status',
          render: renderReviewProcessStatus,
        },
        {
          width: 350,
          title: t('transactions.review_details'),
          key: 'review_details',
          render: renderReviewProcessDetails,
        },
      ];

      return result;
    }, [reviewProcessType, setSelectedRDCTransaction]);

    return (
      <>
        <Table {...rest} columns={columns} />

        <RDCTransactionModal
          isVisible={!!selectedRDCTransaction}
          alert={selectedRDCTransaction}
          closeCallback={() => setSelectedRDCTransaction(null)}
        />
      </>
    );
  },
);

const GreyText = styled(Typography.Text)`
  color: ${({ theme }) => theme.textLightColor4};
  font-weight: 600;
`;

const YellowText = styled(Typography.Text)`
  color: ${({ theme }) => theme.warningColor};
  font-weight: 600;
`;

const GreenText = styled(Typography.Text)`
  color: ${({ theme }) => theme.successColor};
  font-weight: 600;
`;

const RedText = styled(Typography.Text)`
  color: ${({ theme }) => theme.errorColor};
  font-weight: 600;
`;

const StyledInfoIcon = styled(InfoIconWithTooltip)`
  cursor: pointer;
`;

export default ReviewProcessObjectsTable;
