import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { ApprovalGroupStatuses } from 'typings/approval/approvalGroups';

// components
import StatusLabel, { LABEL_STATUSES } from '../../../Typography/StatusLabel';

const STATUS_MAP: Record<LABEL_STATUSES, ApprovalGroupStatuses[]> = {
  info: [],
  warning: ['in-review'],
  success: ['active'],
  error: ['deactivated'],
};

interface IProps {
  status: ApprovalGroupStatuses;
}

const ApprovalGroupStatus = memo(({ status }: IProps) => {
  const { t } = useTranslation('crm');
  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(
        `client_group.approval.client_approval_groups.statuses.${status}`,
      )}
    />
  );
});

export default ApprovalGroupStatus;
