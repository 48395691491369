import React, { useEffect, useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import useQueryParam from '../../../../hooks/useQueryParam';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../routes/routes';
import { changeView } from '../../../../redux/actions/view';
import { accountsAPI } from '../../../../api/finance/account/accountsAPI';
import { useDispatch } from 'react-redux';
import { BankAccountStatuses } from 'enums/banking/accounts';
import { AutocompleteInitialValue } from '../../../../components/Fields/Autocomplete';
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  DEFAULT_TABLE_LIMIT,
} from '../../../../constants/global';

// components
import FilterBar from './FilterBar';
import { Divider } from 'antd';
import { default as TemplateAccountsTable } from '../../../../components/Tables/TableTemplates/Finance/AccountsTable';

interface IProps {
  updateTableTrigger?: any;
}

const AccountsTable = ({ updateTableTrigger }: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const historyPush = (path: any, state?: any) =>
    dispatch(changeView(() => history.push(path, state)));

  // table
  const [page, setPage] = useState(1);

  // filter bar
  const categoryIdFromUrl = useQueryParam({ param: 'categoryId' });
  const categoryNameFromUrl = useQueryParam({ param: 'categoryName' });

  const [accountsFilter, setAccountsFilter] = useState<{
    accountQuery: string;
    financialTypeNameQuery: string;
    currencyId: number | undefined;
    accountCategoryNameQuery: string;
    currencyIsoCodeQuery: string | undefined;
    isReconciled: string;
    status: BankAccountStatuses | null;
    accountCategoryId: string;
  }>({
    accountQuery: '',
    financialTypeNameQuery: '',
    currencyId: undefined,
    accountCategoryNameQuery: '',
    currencyIsoCodeQuery: undefined,
    isReconciled: '',
    status: null,
    accountCategoryId: '',
  });

  const [initialAccountCategoryOption, setInitialAccountCategoryOption] =
    useState<AutocompleteInitialValue>();

  const { response, loading } = useFetch(
    () =>
      accountsAPI.fetchAllAccounts({
        page,
        limit: DEFAULT_TABLE_LIMIT,
        status: accountsFilter.status || undefined,
        currencyId: accountsFilter.currencyId,
        accountQuery: accountsFilter.accountQuery,
        isReconciled: accountsFilter.isReconciled,
        accountCategoryId: accountsFilter.accountCategoryId,
        currencyIsoCodeQuery: accountsFilter.currencyIsoCodeQuery,
        financialTypeNameQuery: accountsFilter.financialTypeNameQuery,
        accountCategoryNameQuery: accountsFilter.accountCategoryNameQuery,
      }),
    [page, accountsFilter, updateTableTrigger],
  );

  useEffect(() => {
    if (categoryIdFromUrl && categoryNameFromUrl) {
      setAccountsFilter((prevState) => ({
        ...prevState,
        accountCategoryNameQuery: categoryNameFromUrl,
      }));
      setInitialAccountCategoryOption({
        id: categoryNameFromUrl,
        content: categoryNameFromUrl,
      });
    }
  }, [categoryIdFromUrl, categoryNameFromUrl]);

  const handleFilterChange = (key: string, value: string | any) => {
    if (page > 1) {
      setPage(1);
    }

    switch (key) {
      case 'search':
        setAccountsFilter((prevState) => ({
          ...prevState,
          accountQuery: value,
        }));
        break;
      case 'account-category-filter':
        if (initialAccountCategoryOption) {
          setInitialAccountCategoryOption(undefined);
          history.replace({
            search: '',
          });
        }

        setAccountsFilter((prevState) => ({
          ...prevState,
          accountCategoryNameQuery: value,
        }));
        break;
      case 'financial-type-filter':
        setAccountsFilter((prevState) => ({
          ...prevState,
          financialTypeNameQuery: value,
        }));
        break;
      case 'external-reconciliation-filter':
        setAccountsFilter((prevState) => ({
          ...prevState,
          isReconciled: value,
        }));
        break;
      case 'currency-filter':
        setAccountsFilter((prevState) => ({ ...prevState, currencyId: value }));
        break;
      case 'status':
        setAccountsFilter((prevState) => ({
          ...prevState,
          status: value || null,
        }));
        break;
      case 'accountCategoryCode':
        setAccountsFilter((prevState) => ({
          ...prevState,
          accountCategoryId: value,
        }));
        break;
    }
  };

  const handleActionsChange = (key: string, accountNumber: string) => {
    switch (key) {
      case 'details':
        historyPush({
          pathname: RoutePaths.Finance_Account_Details,
          search: `?id=${accountNumber}`,
        });
        break;
    }
  };

  return (
    <>
      <FilterBar
        accountCategory={accountsFilter.accountCategoryNameQuery}
        initialAccountCategoryOption={initialAccountCategoryOption}
        handleChange={debounce(handleFilterChange, AUTOCOMPLETE_DEBOUNCE_DELAY)}
      />
      <Divider />
      <TemplateAccountsTable
        data={response?.data || []}
        total={response?.total || 0}
        current={page}
        loading={loading}
        onActionsClick={handleActionsChange}
        onPaginationChange={setPage}
      />
    </>
  );
};

export default AccountsTable;
