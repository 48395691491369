import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import { Input } from 'antd';

const InnerForm = memo(() => {
  const { t } = useTranslation(['crm', 'common']);

  return (
    <FormField
      name="message"
      label={t('request_data_update_modal.form_fields.message')}
      placeholder={t('enter_value', { ns: 'common' })}
      component={Input.TextArea}
      additionalProps={{ autoSize: { minRows: 8, maxRows: 8 } }}
    />
  );
});

export default InnerForm;
