import React, { useState } from 'react';

// helpers
import permissionsAPI from '../../../../../../../../../../../api/orac/permissionsAPI';

import { useField } from 'formik';
import {
  NewPermissionModel,
  PermissionBaseModel,
  PermissionType,
} from '../../../../../../../../../../../typings/ORAC/permissions';
import {
  ObjectActionWithPermissionsModel,
  ObjectWithPermissionsModel,
} from '../../../../../../../../../../../typings/ORAC/objects';

// components
import { Radio } from 'antd';

interface IProps {
  action: ObjectActionWithPermissionsModel;
  object: ObjectWithPermissionsModel;
  permission: PermissionBaseModel | null;
  permissionType: PermissionType;
}

const PermissionRadioButton = ({
  permissionType,
  action,
  object,
  permission,
}: IProps) => {
  const [field, , helpers] = useField('selectedPermissions');

  const [selectedPermission, setSelectedPermission] =
    useState<PermissionBaseModel | null>(permission);
  const [loading, setLoading] = useState<boolean>(false);

  const handleRadioChange = async () => {
    if (loading) {
      return;
    }

    if (selectedPermission !== null) {
      setPermissionValue(selectedPermission);
    } else {
      setLoading(true);
      const newPermission: NewPermissionModel = {
        actionId: action.id,
        multiple: permissionType === 'multiple',
        forbidden: permissionType === 'forbidden',
      };

      try {
        const response = await permissionsAPI.createPermission(newPermission);
        setSelectedPermission(response || null);
        setPermissionValue(response);
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
  };

  const setPermissionValue = (newPermission: PermissionBaseModel) => {
    const selectedPermissionsCopy = { ...field.value };

    if (selectedPermissionsCopy[object.id]) {
      selectedPermissionsCopy[object.id].actions[action.id] = {
        ...action,
        permissions: {
          [newPermission.id]: {
            ...newPermission,
            permissionType,
          },
        },
      };
    } else {
      selectedPermissionsCopy[object.id] = {
        id: object.id,
        name: object.name,
        actions: {
          [action.id]: {
            ...action,
            permissions: {
              [newPermission.id]: {
                ...newPermission,
                permissionType,
              },
            },
          },
        },
      };
    }

    helpers.setValue(selectedPermissionsCopy);
  };

  return (
    <Radio
      onChange={handleRadioChange}
      checked={
        field.value[object.id]?.actions[action.id]?.permissions[
          selectedPermission?.id as string
        ]?.permissionType === permissionType
      }
    />
  );
};

export default PermissionRadioButton;
