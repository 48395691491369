import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../hooks/useTranslation';
import { DeleteEntityValidationSchema } from '../../../validations/crm/entities';

// components
import Form from '@core_components/Form';
import FormField from '@core_components/FormField';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';

interface IProps extends RequiredPropsForModalDialogModel {
  title?: string;
  description?: string;
  submitBtnText?: string;
  isSubmitButtonDanger?: boolean;
  onSubmitCallback: (reason: string) => void;
}

interface IFormValues {
  reason: string;
}

const DeleteConfirmation = ({
  isVisible,
  closeCallback,
  onSubmitCallback,
  title,
  description,
  submitBtnText,
  isSubmitButtonDanger = true,
}: IProps) => {
  const { t } = useTranslation(['common', 'crm', 'form']);

  const initialValues = useMemo(
    () => (isVisible ? { reason: '' } : null),
    [isVisible],
  );

  const handleSubmit = async (values: IFormValues) => {
    await onSubmitCallback(values.reason);
  };

  return (
    <Form<IFormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={DeleteEntityValidationSchema}
      onSubmit={handleSubmit}
      renderForm={() => (
        <ModalDialog
          width={520}
          title={title || t('modal.deleteConfirmation.title')}
          isVisible={isVisible}
          closeCallback={closeCallback}
          submitButtonProps={{
            danger: isSubmitButtonDanger,
            text: submitBtnText || t('delete'),
          }}
        >
          <FormField
            label={
              description || t('contacts.new.deleteMessage', { ns: 'crm' })
            }
            name="reason"
            component={FormInputTextArea}
            additionalProps={{ autoSize: { minRows: 6, maxRows: 6 } }}
          />
        </ModalDialog>
      )}
    />
  );
};

export default DeleteConfirmation;
