import React, { memo, useState } from 'react';

// helpers
import APIConfig from '../../../config/api';
import useTranslation from '../../../hooks/useTranslation';
import { Moment } from 'moment';
import { SessionHelpers } from '@helpers/sessions';
import { DocumentHelpers } from '@helpers/documents';
import { LocalStorageHelpers } from '@helpers/storages/localStorage';

// components
import { Button } from 'antd';

interface IProps {
  disabled?: boolean;
  selectedFilterDate: [Moment, Moment] | null;
}

const ExportOutgoingTransfersToCSVButton = memo(
  ({ disabled, selectedFilterDate }: IProps) => {
    const { t } = useTranslation('crm');
    const [loader, setLoader] = useState(false);

    const onExportClick = async () => {
      if (selectedFilterDate) {
        setLoader(true);
        const token = LocalStorageHelpers.getAccessToken();
        const fingerprint = await SessionHelpers.generateFingerprint();
        const link = `${APIConfig.accountingApi}/report/threshold/outgoing/csv?fromDate=${selectedFilterDate[0].format(
          'X',
        )}&toDate=${selectedFilterDate[1].format('X')}&tokenb64=${btoa(`Bearer ${token}`)}&fingerprint=${fingerprint}`;
        await DocumentHelpers.downloadDocument('', '', '', link);
        setLoader(false);
      }
    };

    return (
      <Button
        type="primary"
        loading={loader}
        onClick={onExportClick}
        disabled={disabled}
      >
        {t('documents.export_to_csv')}
      </Button>
    );
  },
);

export default ExportOutgoingTransfersToCSVButton;
