import React, { memo, useEffect, useState } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from 'layouts/PageLayout';
import { FetchNotesForDashboardParamsModel } from 'api/note/noteAPI';

// components
import DashboardNotesTable from 'modules/CRM/Notes/DashboardNotesTable';
import ExportNotesInExcelButton from 'components/Additional/ExportNotesInExcelButton';

type IProps = PageLayoutHelperPropsModel;

const NotesDashboardPage = memo(({ setHeaderOptions }: IProps) => {
  const [filters, setFilters] =
    useState<FetchNotesForDashboardParamsModel | null>(null);
  const [canExport, setCanExport] = useState(false);

  useEffect(() => {
    setHeaderOptions({
      extra: (
        <ExportNotesInExcelButton filters={filters} disabled={!canExport} />
      ),
    });
  }, [filters, canExport]);

  return (
    <DashboardNotesTable
      onFiltersChange={setFilters}
      hasDataCallback={setCanExport}
    />
  );
});

export default NotesDashboardPage;
