import React from 'react';

// helpers
import styled from 'styled-components';
import usersAPI from '../../../../../../../api/orac/usersAPI';
import useTranslation from '../../../../../../../hooks/useTranslation';

// components
import Default2FALabel from '../Default2FALabel';
import { Button, Col, message, Modal, Row, Typography } from 'antd';
import AuthenticatorIcon from '../../../../../../../resources/images/icons/verification-icon.png';

interface Props {
  userId: string;
  isDefault: boolean;
  isTwoFactorAuthenticationEnabled: boolean;
  isFidoEnabled: boolean;
  onChangeDefault: () => void;
  onDisable: () => void;
}

const Authenticator = ({
  userId,
  isDefault,
  isTwoFactorAuthenticationEnabled,
  isFidoEnabled,
  onChangeDefault,
  onDisable,
}: Props) => {
  const { t } = useTranslation(['orac', 'common']);

  const onDisableAuthenticator = () => {
    Modal.confirm({
      title: t('users.view.two_step_verification.authenticator.disable_title'),
      content: t(
        'users.view.two_step_verification.authenticator.disable_description',
      ),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        await usersAPI.disableTwoFactorAuthentication(userId);
        message.success(
          t(
            'users.view.two_step_verification.authenticator.success_disable_message',
          ),
        );
        onDisable();
        if (isFidoEnabled) onChangeDefault();
      },
    });
  };

  const renderButton = (isAuthenticatorEnabled?: boolean) => {
    return isAuthenticatorEnabled ? (
      <StyledButton
        danger
        size="large"
        type="default"
        onClick={onDisableAuthenticator}
      >
        {t('users.view.two_step_verification.authenticator.disable')}
      </StyledButton>
    ) : (
      <StyledTypographyText>
        {t('users.view.two_step_verification.authenticator.not_enabled')}
      </StyledTypographyText>
    );
  };
  return (
    <StyledRow gutter={[16, 0]}>
      <Col xl={18} lg={18} md={12} sm={24} xs={24}>
        <TitleWrapper>
          <AuthenticatorImage src={AuthenticatorIcon} />
          <TextWrapper>
            <StyledTitle strong>
              {t('users.view.two_step_verification.authenticator.title')}{' '}
              {isDefault && <Default2FALabel />}
            </StyledTitle>
            <Typography.Text>
              {t('users.view.two_step_verification.authenticator.description')}
            </Typography.Text>
          </TextWrapper>
        </TitleWrapper>
      </Col>

      <StyledCol xl={6} lg={6} md={12} sm={24} xs={24}>
        {renderButton(isTwoFactorAuthenticationEnabled)}
      </StyledCol>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const AuthenticatorImage = styled.img`
  width: 45px;
  height: 45px;
  margin-right: ${({ theme }) => theme.marginSm};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
  color: ${({ theme }) => theme.whiteColor};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledTypographyText = styled(Typography.Text)`
  color: ${({ theme }) => theme.warningColor};
  font-size: ${({ theme }) => theme.fontSizeSm};
`;

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export default Authenticator;
