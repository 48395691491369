import React, { memo, useMemo, useState } from 'react';

// helpers
import moment from 'moment';
import useTranslation from '../../../hooks/useTranslation';
import ErrorHandlerService from '@services/error-handler/service';
import { FormikHelpers } from 'formik';
import { bankingTransactionsAPI } from '../../../api/banking/bankingTransactionsAPI';
import { bankingTransactionsAPIAdapter } from '../../../apiAdapters/banking/bankingTransactionsAPIAdapter';
import {
  BankOperationsCodes,
  DetailsOfChargesCodes,
  TransactionTypes,
} from '../../../enums/banking/transactions';

// components
import TransactionModalDialog, {
  FormValuesModel,
} from '../../../components/Modals/TemplateModalDialogs/Banking/TransactionModalDialog';
import { Button, message } from 'antd';

interface IProps {
  onAdd: () => void;
}

const AddTransaction = memo(({ onAdd }: IProps) => {
  const { t } = useTranslation(['banking', 'server_errors']);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return {
      submitAction: null,
      transactionId: null,
      transactionType: TransactionTypes.Incoming,
      outgoingTransaction: null,
      incomingTransaction: {
        general: {
          senderReference: '',
          bankOperationsCode: BankOperationsCodes.CRED,
          valueDate: moment(),
          currency: null,
          settledAmount: null,
          detailsOfCharges: DetailsOfChargesCodes.BEN,
          documents: [],
        },

        other: {
          incomingTransactionCurrency: null,
          instructedAmount: null,
        },

        orderingCustomer: {
          accountNumber: '',
          accountName: '',
          country: null,
          city: '',
          district: '',
          postalCode: '',
          address1: '',
        },

        sendingInstitution: {
          code: '',
          name: '',
          country: null,
          address: '',
          city: '',
          district: '',
          postalCode: '',
        },

        orderingInstitution: {
          code: '',
          name: '',
          country: '',
          address: '',
          city: '',
          district: '',
          postalCode: '',
        },

        senderCorrespondent: {
          code: '',
          name: '',
          country: null,
          address: '',
          city: '',
          district: '',
          postalCode: '',
        },

        receiverCorrespondent: {
          code: '',
          name: '',
          country: null,
          address: '',
          city: '',
          district: '',
          postalCode: '',
        },

        thirdReimbursement: {
          code: '',
          name: '',
          country: null,
          address: '',
          city: '',
          district: '',
          postalCode: '',
        },

        intermediaryInstitution: {
          code: 'CNORUS33',
          name: 'Northern Trust International Banking Corporation, The',
          country: 'US',
          address: 'Harborside Financial Center, 3 Second Street',
          city: 'Jersey City',
          district: 'New Jersey',
          postalCode: '07311-3988',
        },

        accountWithInstitution: {
          code: '28985023230',
          name: 'Tenet Bank Ltd.',
          country: 'KY',
          address: '10 Market St.',
          city: 'George Town',
          district: 'Grand Cayman',
          postalCode: 'KY1-9006',
        },

        beneficiaryCustomer: {
          accountNumber: '',
          accountName: '',
          country: null,
          city: '',
          district: '',
          postalCode: '',
          address1: '',
          repair: false,
          repairedBeneficiary: null,
          fee: false,
        },

        remittanceInformation: {
          line1: '',
          line2: '',
          line3: '',
          line4: '',
        },

        senderToReceiver: {
          line1: '',
          line2: '',
          line3: '',
          line4: '',
          line5: '',
          line6: '',
        },
      },
    };
  }, []);

  const handleModalClose = (wasSubmitted?: boolean) => {
    if (wasSubmitted) {
      onAdd();
    }

    setIsModalVisible(false);
  };

  const handleSubmit = async (
    values: FormValuesModel,
    formHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    try {
      const formattedTransaction =
        bankingTransactionsAPIAdapter.addTransaction(values);
      await bankingTransactionsAPI.addTransaction(formattedTransaction);
      message.success(
        t('banking.transactions.add_transaction.add_modal.success_message'),
      );
      handleModalClose(true);
    } catch (error: any) {
      if (error && error?.response?.data?.code) {
        switch (error.response.data.code) {
          case '1313066':
            {
              formHelpers.setFieldError(
                'incomingTransaction.general.settledAmount',
                t('1313066', { ns: 'server_errors' }),
              );
            }
            break;

          case '1313067':
            {
              formHelpers.setFieldError(
                'incomingTransaction.other.instructedAmount',
                t('1313067', { ns: 'server_errors' }),
              );
            }
            break;

          default:
            ErrorHandlerService.handleError(error);
        }
      }
    }
  };

  return (
    <>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        {t('banking.transactions.add_transaction.add_modal.button')}
      </Button>

      <TransactionModalDialog
        mode="create"
        title={t('banking.transactions.add_transaction.add_modal.title')}
        onSubmit={handleSubmit}
        isVisible={isModalVisible}
        closeCallback={handleModalClose}
        initialValues={initialFormValues}
      />
    </>
  );
});

export default AddTransaction;
