import React, { useEffect, useState } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import reviewConfigurationAPI from '../../../api/compliance/reviewConfigurationAPI';
import { PageLayoutHelperPropsModel } from '../../../layouts/PageLayout';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../constants/global';
import { GENESIS_PROPERTY_CONFIGURATION_DOCUMENTATION_LINK } from '../../../constants/externalLinks';

// components
import ReferenceObjectsList from '../../../components/Tables/TableTemplates/Compliance/ReferenceObjectsList';
import TooltipToDocumentation from '../../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const PropertyConfiguration = ({ setHeaderOptions }: IProps) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation
          link={GENESIS_PROPERTY_CONFIGURATION_DOCUMENTATION_LINK}
        />
      ),
    });
  }, []);

  const { response, loading } = useFetch(
    () =>
      reviewConfigurationAPI.fetchReferenceObjects({
        page,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [page],
  );

  return (
    <>
      <ReferenceObjectsList
        data={response?.data || []}
        total={response?.total || 0}
        current={page}
        loading={loading}
        onPaginationChange={setPage}
      />
    </>
  );
};

export default PropertyConfiguration;
