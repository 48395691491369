import React from 'react';

// helpers
import { clientGroupsAPI } from '../../../../../../api/crm/clientGroup/clientGroupsAPI';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { IClientGroupShortResponse } from '../../../../../../typings/crm/client-group';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import FormAutocomplete, {
  FormAutocompleteProps,
} from '@common_components/Form/FormAutocomplete';

type IProps = FormAutocompleteProps<IClientGroupShortResponse>;

const ClientGroupsFormAutocomplete = (props: IProps) => {
  const fetchClientGroups = async (searchQuery: string) => {
    const { data } = await clientGroupsAPI.searchClientGroups({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      search: searchQuery,
    });

    return data.data.map<AutocompleteOption<IClientGroupShortResponse>>(
      (clientGroup) => {
        return {
          id: clientGroup.numericId,
          label: clientGroup.clientGroupName,
          model: clientGroup,
        };
      },
    );
  };

  return <FormAutocomplete fetchData={fetchClientGroups} {...props} />;
};

export default ClientGroupsFormAutocomplete;
