import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { enumToMap } from '../../../../../helpers/utils';
import { ClientStatus } from '../../../../../enums/crm/crm';

// components
import { Select } from 'antd';

interface IProps {
  value: string;
  onChange: (value: any) => void;
}

const ClientGroupStatusSelect = ({ value, onChange }: IProps) => {
  const { t } = useTranslation('crm');

  const renderOptions = () => {
    const enumMap = enumToMap(ClientStatus);

    return Array.from(enumMap).map((el) => (
      <Select.Option key={el[1]} value={el[1]}>
        {t(`client_groups.statuses.${el[1]}`)}
      </Select.Option>
    ));
  };

  return (
    <StyledSelect
      placeholder={t('client_group.select_client_group_status')}
      onChange={onChange}
      showArrow
      value={value}
      size="large"
      filterOption={false}
    >
      <Select.Option key="all" value="all">
        {t('client_group.all')}
      </Select.Option>

      {renderOptions()}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default ClientGroupStatusSelect;
