import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { OnboardType } from 'enums/crm/crm';
import { IDocumentAssociationInfo } from 'modules/CRM/Documents/UploadDocumentsDialog';
import { AdditionalFieldFormItemModel } from 'components/Forms/FormComponents/AdditionalFieldNew';
import { UpdateAnswerOfAdditionalFieldValidationSchema } from 'validations/crm/clientGroups';

// components
import InnerForm from './InnerForm';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export type FormValuesModel = {
  additionalField: AdditionalFieldFormItemModel;
  documentAssociation: IDocumentAssociationInfo;
  selectedEntry: {
    id: string;
    type: OnboardType;
  };
  reviewProcess: {
    type: 'contact-review' | 'client-group-review';
    entryId: string;
  };
};

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {}

const AdditionalFieldAnswerModalDialog = ({
  onSubmit,
  isVisible,
  closeCallback,
  initialValues,
}: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <Form
      enableReinitialize
      confirmExitWithoutSaving
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={UpdateAnswerOfAdditionalFieldValidationSchema}
      renderForm={
        <ModalDialog
          title={t(
            'client_group.approval.update_answer_of_additional_field_modal.title',
          )}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <LoadingWrapper loading={!initialValues}>
            {initialValues && <InnerForm />}
          </LoadingWrapper>
        </ModalDialog>
      }
    />
  );
};

export default AdditionalFieldAnswerModalDialog;
