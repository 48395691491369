import React, { useRef } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { darkTheme } from '../../../../../resources/theme/styled';
import { DocumentHelpers } from '@helpers/documents';
import { useFormikContext } from 'formik';
import { ALLOWED_UPLOAD_TYPES } from '../../../../../constants/documents';

// components
import Text from '@core_components/Text';
import Button from '../../../../DesignSystem/Core/Button';
import IconSVG from '../../../../DesignSystem/Core/IconSVG';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Col, Modal, Row } from 'antd';
import { ReactComponent as UploadIcon } from '../../../../../resources/icons/remix-icons/upload-2-line.svg';

interface IProps {
  multiple?: boolean;
  disabled?: boolean;
  onFilesSelect: (files: File[]) => void;
}

const UploadDocumentButton = ({
  onFilesSelect,
  multiple = true,
  disabled,
}: IProps) => {
  const uploadInputRef = useRef<any>(null);
  const { t } = useTranslation('crm');
  const { isSubmitting } = useFormikContext();

  const onBrowseClick = () => {
    uploadInputRef.current.click();
  };

  const onFileSelect = () => {
    if (uploadInputRef.current && uploadInputRef.current.files.length) {
      const allowedFiles = DocumentHelpers.verifyFilesThatCanBeUploaded(
        Array.prototype.slice.call(uploadInputRef.current.files),
      );

      if (allowedFiles.rejected.length) {
        Modal.error({
          width: 650,
          title: t('documents.upload_documents_warning_modal.title'),
          content: (
            <>
              {allowedFiles.rejected.map((e, i) => (
                <Row key={i} wrap={false} gutter={[16, 16]}>
                  <Col span={6}>
                    <Text key={i} variant="body1">
                      <EllipsisTooltip
                        maxTextContainerWidth="100%"
                        title={e.file.name}
                      >
                        {e.file.name}
                      </EllipsisTooltip>
                    </Text>
                  </Col>
                  <Col flex="auto">
                    <Text key={i} variant="body1">
                      {t(
                        `documents.upload_documents_warning_modal.errors.${e.reason}`,
                      )}
                    </Text>
                  </Col>
                </Row>
              ))}
            </>
          ),
        });
      }

      if (allowedFiles.approved.length) {
        onFilesSelect(allowedFiles.approved);
      }

      uploadInputRef.current.value = '';
    }
  };

  return (
    <>
      <StytedButton onClick={onBrowseClick} disabled={isSubmitting || disabled}>
        <StyledIconSVG component={UploadIcon} color={darkTheme.colorDarkL4} />{' '}
        {t('documents.upload_button')}
      </StytedButton>

      <input
        ref={uploadInputRef}
        type="file"
        multiple={multiple}
        accept={ALLOWED_UPLOAD_TYPES}
        style={{ display: 'none' }}
        onChange={() => onFileSelect()}
      />
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

const StytedButton = styled(Button)`
  display: flex;
  align-items: center;
`;

export default UploadDocumentButton;
