import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { IClientGroupResponse } from '../../../../../../../api/crm/clientGroup/clientGroupsAPI';
import {
  ClientReviewStatus,
  ClientStatus,
} from '../../../../../../../enums/crm/crm';

// components
import ComplianceWorkflow from '../../../../../../../components/Additional/ApprovalWorkflow/ComplianceWorkflow';
import NewBusinessWorkflow from '../../../../../../../components/Additional/ApprovalWorkflow/NewBusinessWorkflow';
import { Divider, Typography } from 'antd';

interface IProps {
  clientGroupData: IClientGroupResponse;
  updateClientGroup: () => void;
}

const WorkflowTab = ({ clientGroupData, updateClientGroup }: IProps) => {
  const { t } = useTranslation('crm');
  const relationshipScope = useMemo(() => {
    if (
      clientGroupData?.clientGroup.reviewStatus ===
        ClientReviewStatus.Reviewed &&
      clientGroupData?.clientGroup.status === ClientStatus.Approved
    ) {
      return clientGroupData?.approvedRelationshipScope;
    }
    return clientGroupData?.activeRelationshipScope;
  }, [clientGroupData]);

  const newBusinessWorkflow = relationshipScope?.approval.newBusiness;
  const complianceWorkflow = relationshipScope?.approval.compliance;

  return (
    <>
      <SectionTitle level={3}>
        {t('onboarding_statuses.compliance_review')}
      </SectionTitle>

      <ComplianceWorkflow
        workflowId={complianceWorkflow?.workflowId}
        hasComplianceInfo={!!clientGroupData.clientGroup.complianceInformation}
        onUpdate={updateClientGroup}
      />
      <StyledDivider />
      <NewBusinessWorkflow
        workflowId={newBusinessWorkflow?.workflowId}
        onUpdate={updateClientGroup}
      />
    </>
  );
};

const SectionTitle = styled(Typography.Title)`
  margin-top: ${({ theme }) => theme.marginXs};
`;

const StyledDivider = styled(Divider)`
  width: 100%;
  margin: ${({ theme }) => theme.marginXl} 0;
`;

export default WorkflowTab;
