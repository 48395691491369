import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { darkTheme } from '@resources/theme/styled';
import { StyledComponentProps } from '../../../../typings/common';

// components
import Spinner from '@core_components/Spinner';
import IconSVG from '@core_components/IconSVG';
import { Select as SelectAntD } from 'antd';
import { ReactComponent as CloseIcon } from '@resources/icons/remix-icons/close-line.svg';

export interface SelectOption<Option = unknown> {
  id: string | number;
  label: React.ReactNode;
  model?: Option;
  hidden?: boolean;
  disabled?: boolean;
}

export interface SelectProps<Option = unknown> extends StyledComponentProps {
  value?: string | number;
  options?: SelectOption<Option>[];
  mode?: 'multiple';
  size?: SelectSizes;
  loading?: boolean;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  onChange?: (value: string, option?: Option) => void;
  onSelect?: (
    value: string,
    option: {
      key: string;
      children: React.ReactNode;
      model?: Option;
      value: string;
    },
  ) => void;
  disabled?: boolean;
  allowClear?: boolean;
  placeholder?: string;
  excludeOptions?: string[];
}

export type SelectSizes = 'large' | 'middle' | 'small';

function Select<Option = unknown>({
  value,
  options,
  placeholder,
  disabled,
  size = 'large',
  onChange,
  onBlur,
  onSelect,
  mode,
  loading,
  allowClear = true,
  excludeOptions,
  ...rest
}: SelectProps<Option>) {
  const { t } = useTranslation('form');

  const modifiedValue = useMemo(() => {
    if ((typeof value !== 'number' && !value) || !options || !options.length) {
      return undefined;
    }

    return value;
  }, [value, options, loading]);

  const modifiedOptions = useMemo(() => {
    if (!options?.length) {
      return [];
    }

    let result = [...options];

    if (excludeOptions?.length) {
      result = result.filter((e) => !excludeOptions.some((el) => el === e.id));
    }

    return result;
  }, [options, excludeOptions]);

  const fieldPlaceholder = useMemo(() => {
    if (placeholder) {
      return placeholder;
    }

    if (mode === 'multiple') {
      return t('select_multiple_options');
    } else {
      return t('select_option');
    }
  }, [mode, placeholder, t]);

  const renderOptions = (items: SelectOption<Option>[]) => {
    return items.reduce<React.ReactNode[]>((acc, next) => {
      if (!next.hidden) {
        acc.push(
          <SelectAntD.Option
            key={next.id}
            value={next.id}
            model={next.model}
            disabled={next.disabled}
          >
            {next.label}
          </SelectAntD.Option>,
        );
      }

      return acc;
    }, []);
  };

  const handleFilterOption = (input: string, option: any) => {
    if (option.children) {
      if (typeof option.children === 'string') {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }
      if (typeof option.children === 'object') {
        if (option.children.props) {
          const children = option.children.props.children.join('');
          return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
      }
    }
    return (
      option.children &&
      option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  return (
    <StyledSelectAntD
      {...rest}
      showArrow
      mode={mode}
      clearIcon={
        <StyledIconSVG
          component={CloseIcon}
          color={darkTheme.colorWhite}
          size="extra-small"
        />
      }
      allowClear={allowClear}
      size={size}
      value={modifiedValue}
      disabled={disabled || loading}
      onBlur={onBlur}
      onChange={onChange as any}
      onSelect={onSelect as any}
      placeholder={fieldPlaceholder}
      loading={loading}
      showSearch
      optionFilterProp="children"
      filterOption={handleFilterOption}
      notFoundContent={
        loading ? (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        ) : undefined
      }
    >
      {renderOptions(modifiedOptions)}
    </StyledSelectAntD>
  );
}

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSelectAntD = styled(SelectAntD)`
  .ant-select-clear {
    background-color: ${({ theme }) => theme.warningColor};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
`;

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
`;

export default Select;
