import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { ApprovalWorkflowInstanceModel } from '../../../../../typings/approval/approvalWorkflow';
import { ApprovalManagementTemplateCodes } from '../../../../../enums/approvalManagement/templates';

// components
import FinanceWorkflow from '../../../../../components/Additional/ApprovalWorkflow/FinanceWorkflow';
import ComplianceWorkflow from '../../../../../components/Additional/ApprovalWorkflow/ComplianceWorkflow';
import ForexTransferWorkflow from '../../../../../components/Additional/ApprovalWorkflow/ForexTransferWorkflow';
import SeniorManagementWorkflow from '../../../../../components/Additional/ApprovalWorkflow/SeniorManagementWorkflow';
import NewBusinessWorkflowStatus from '../../../../../components/Additional/ApprovalWorkflow/NewBusinessWorkflow/NewBusinessWorkflowStatus';
import { Divider } from 'antd';

interface IProps {
  approvalInstance: ApprovalWorkflowInstanceModel;
  updateData: () => void;
}

const ApprovalStateInfo = ({ approvalInstance, updateData }: IProps) => {
  const { t } = useTranslation('approval_management');

  const renderApprovalMatrix = (templateCode: string) => {
    let result = null;

    switch (templateCode) {
      case ApprovalManagementTemplateCodes.NewBusiness:
        result = (
          <NewBusinessWorkflowStatus
            onUpdate={updateData}
            workflowId={approvalInstance.id}
          />
        );
        break;

      case ApprovalManagementTemplateCodes.Compliance:
        result = (
          <ComplianceWorkflow
            onUpdate={updateData}
            hasComplianceInfo
            workflowId={approvalInstance.id}
            workflow={approvalInstance}
          />
        );
        break;

      case ApprovalManagementTemplateCodes.FXTransfer:
        result = (
          <ForexTransferWorkflow
            onUpdate={updateData}
            workflowId={approvalInstance.id}
          />
        );
        break;

      case ApprovalManagementTemplateCodes.AWT_SENIORMNGT:
        result = (
          <SeniorManagementWorkflow
            onUpdate={updateData}
            workflowId={approvalInstance.id as string}
          />
        );
        break;

      case ApprovalManagementTemplateCodes.AWT_FINANCE:
        result = (
          <FinanceWorkflow
            onUpdate={updateData}
            workflowId={approvalInstance.id as string}
          />
        );
        break;
    }

    return result;
  };

  return (
    <>
      <Divider orientation="left">
        {t('approval_workflow.approval_state_matrix')}
      </Divider>
      {renderApprovalMatrix(approvalInstance.templateCode)}
    </>
  );
};

export default ApprovalStateInfo;
