import APIConfig from '../../../config/api';
import { IEntity } from '../../../typings/crm/entity';
import { APIService } from '../../axiosInstance';
import { RangeOption } from 'components/Forms/FormComponents/SelectInputs/RangeFormSelect';
import { SocialMedia } from 'enums/crm/crm';
import { EntityNameType } from 'constants/entities';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';

interface FetchEntitiesParamsModel extends FetchParamsModel {
  search?: string;
}

export type EntityDataQuery = {
  names: { type: EntityNameType; name: string }[];
  registrationNumber: string;
  dateOfIncorporation: string | null;
  jurisdiction: string | null;
  keyRelationshipTemplateId: string | null;
  natureOfBusiness: string;
  numberOfEmployees: Omit<RangeOption, 'key'> | null;
  numberOfOffices: Omit<RangeOption, 'key'> | null;
  isRegulated: boolean | null;
  industryCodes: string[] | null;
  regulationCountry: string[] | null;
  canIssueBearerShares: boolean | null;
  isVendor: boolean | null;
  addresses:
    | {
        _id?: string;
        city: string;
        country: string;
        isPrimary: boolean;
        postalCode: string;
        type: string[];
        street: string;
        state: string;
      }[]
    | null;
  socialMedia: { type: SocialMedia; path: string }[];
};

const entityAPI = {
  fetchEntities: (params: FetchEntitiesParamsModel) => {
    return APIService.get<FetchResponseModel<IEntity>>(
      `${APIConfig.crmApi}/entities`,
      { params },
    ).then(({ data }) => data);
  },

  createEntity: (body: EntityDataQuery) => {
    return APIService.post(`${APIConfig.crmApi}/entities`, body).then(
      ({ data }) => data,
    );
  },

  editEntity: (entityId: string, body: EntityDataQuery) => {
    return APIService.patch(
      `${APIConfig.crmApi}/entities/${entityId}`,
      body,
    ).then(({ data }) => data);
  },

  deleteEntityById: (entityId: string, deleteReason: string) => {
    return APIService.delete(`${APIConfig.crmApi}/entities/${entityId}`, {
      data: { reason: deleteReason },
    }).then(({ data }) => data);
  },

  getEntityById: (entityId: string) => {
    return APIService.get<IEntity>(
      `${APIConfig.crmApi}/entities/${entityId}`,
    ).then(({ data }) => data);
  },
};

export { entityAPI };
