import { useEffect, useState } from 'react';
import { RouteModel, SubRouteModel } from '../typings/routes';

export interface BreadcrumbModel {
  title: string;
  path: string;
}

// Custom hook that are responsible for generating breadcrumbs depends on current path
// Need to pass:
// - current location path
// - root routes array (all routes)
const useBreadcrumbs = (currentPath: string, routes: RouteModel[]) => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbModel[]>([]);

  // If the path has been changed -> update breadcrumbs
  useEffect(() => {
    const newBreadcrumbs = getBreadcrumbs(currentPath);
    setBreadcrumbs(newBreadcrumbs);
  }, [currentPath]);

  // Generate new breadcrumbs: this method are responsible for generating new breadcrumbs array
  // since our routes are separated for categories (e.g. client routes, admin routes, system administration routes ...)
  // would be better to find desired routes section by first element in location path,
  // and continue generating new breadcrumbs array using only this object
  const getBreadcrumbs = (currentPath: string) => {
    const splitPath = currentPath
      .split('/')
      .filter((e) => e)
      .map((e) => `/${e}`);

    if (splitPath.length <= 1) return [];

    let newBreadcrumbs: BreadcrumbModel[] = [];

    const parentRouteSectionIndex = routes.findIndex(
      (e) => e.path === splitPath[0],
    );

    if (parentRouteSectionIndex !== -1) {
      newBreadcrumbs = generateBreadcrumbs(
        splitPath,
        routes[parentRouteSectionIndex].routes,
      );
    } else {
      const clientRoutesSectionIndex = routes.findIndex((e) => e.path === '/');
      newBreadcrumbs = generateBreadcrumbs(
        splitPath,
        routes[clientRoutesSectionIndex].routes,
      );
    }

    return newBreadcrumbs;
  };

  // Recursively generate new breadcrumbs array
  const generateBreadcrumbs = (
    pathArray: string[],
    routesArray: SubRouteModel[],
  ) => {
    const acumulator: BreadcrumbModel[] = [];

    function findRoute(
      takeNum: number,
      searchPath: string,
      pathArray: string[],
      routesArray: SubRouteModel[] = [],
    ): BreadcrumbModel[] {
      if (!routesArray.length) {
        return acumulator;
      }

      const foundIndex = routesArray.findIndex((e) => e.path === searchPath);

      if (foundIndex === -1) return acumulator;

      acumulator.push({
        title: routesArray[foundIndex].title,
        path: routesArray[foundIndex].path,
      });

      const nextSearchPath = pathArray.slice(0, takeNum + 1).join('');

      return findRoute(
        takeNum + 1,
        `${nextSearchPath}`,
        pathArray,
        routesArray[foundIndex].childRoutes,
      );
    }

    return findRoute(
      1,
      `${pathArray.slice(0, 1).join('')}`,
      pathArray,
      routesArray,
    );
  };

  return breadcrumbs;
};

export default useBreadcrumbs;
