import { IEntity } from 'typings/crm/entity';
import { DateHelpers } from '@helpers/date';
import { getRangeValue } from '@helpers/utils';
import { EntityHelpers } from '@helpers/crm/entity';
import { EntityNameType } from '../../../constants/entities';
import { EntityDataQuery } from 'api/crm/entity/entityAPI';
import { YYYYMMDD_TIME_FORMAT } from 'constants/global';
import { EntityType, SocialMedia } from 'enums/crm/crm';
import { NUMBER_OF_OFFICES_ARRAY } from 'components/Forms/FormComponents/SelectInputs/NumberOfOfficesFormSelect';
import { NUMBER_OF_EMPLOYEES_ARRAY } from 'components/Forms/FormComponents/SelectInputs/NumberOfEmployeesFormSelect';
import { FormValuesModel as EntityDetailsFormValuesModel } from '../../../components/Forms/TemplateForms/CRM/EntityDetailsForm';

const getNames = (values: EntityDetailsFormValuesModel) => {
  const res = [
    {
      type: EntityNameType.LEGAL,
      name: values.legalName.trim(),
    },
  ];

  if (values.tradeName) {
    res.push({
      type: EntityNameType.TRADE,
      name: values.tradeName,
    });
  }

  return res;
};

const entityAdapter = {
  formatEntityDataFromFormToQuery: (
    values: EntityDetailsFormValuesModel,
  ): EntityDataQuery => {
    const filteredAddresses = values.addresses.filter((e) => !e.isApproved);
    const numberOfOfficesOption = values.numberOfOffices
      ? NUMBER_OF_OFFICES_ARRAY.find((e) => e.key === values.numberOfOffices)
      : null;
    const numberOfEmployeesOption = values.numberOfEmployees
      ? NUMBER_OF_EMPLOYEES_ARRAY.find(
          (e) => e.key === values.numberOfEmployees,
        )
      : null;

    return {
      names: getNames(values),
      registrationNumber: values.registrationNumber,
      dateOfIncorporation: values.dateOfIncorporation
        ? DateHelpers.formatDateToString(
            values.dateOfIncorporation,
            YYYYMMDD_TIME_FORMAT,
          )
        : null,
      jurisdiction: values.jurisdiction,
      keyRelationshipTemplateId: values.keyRelationshipTemplate,
      natureOfBusiness: values.natureOfBusiness,
      numberOfOffices: numberOfOfficesOption
        ? { min: numberOfOfficesOption.min, max: numberOfOfficesOption.max }
        : null,
      numberOfEmployees: numberOfEmployeesOption
        ? { min: numberOfEmployeesOption.min, max: numberOfEmployeesOption.max }
        : null,
      isRegulated: values.isRegulated,
      regulationCountry: values.isRegulated ? values.regulationCountry : null,
      industryCodes: values.industryCodes,
      canIssueBearerShares: values.canIssueBearerShares,
      isVendor: values.isVendor,
      socialMedia: EntityHelpers.getSocialMediaData(values.socialMedia),
      addresses: filteredAddresses.map((e) => ({
        _id: e.isCreated ? e.id : undefined,
        type: e.type,
        country: e.country,
        city: e.city,
        street: e.street,
        state: e.state,
        postalCode: e.postal,
        isPrimary: e.id === values.primaryAddressId,
      })),
    };
  },

  formatEntityDataFromQueryToForm: (
    entity: IEntity,
  ): EntityDetailsFormValuesModel => {
    const legalName =
      entity.names.find((e) => e.type === EntityType.Legal)?.name || '';
    const tradeName =
      entity.names.find((e) => e.type === EntityType.Trade)?.name || '';
    const primaryAddress = entity.addresses
      ? entity.addresses.find((e) => e.isPrimary)
      : null;
    const canUpdatePrimaryStatusForAddresses =
      !entity.addresses ||
      !entity.addresses.some((e) => e.approval?.isApproved);

    return {
      legalName,
      tradeName,
      registrationNumber: entity.registrationNumber || '',
      dateOfIncorporation: entity.dateOfIncorporation
        ? DateHelpers.formatDateToUTC(entity.dateOfIncorporation)
        : null,
      jurisdiction: entity.jurisdiction || null,
      keyRelationshipTemplate: entity.keyRelationshipTemplate
        ? entity.keyRelationshipTemplate._id
        : null,
      keyRelationshipTemplateInitialOption: entity.keyRelationshipTemplate
        ? {
            id: entity.keyRelationshipTemplate._id,
            label: entity.keyRelationshipTemplate.name,
            model: entity.keyRelationshipTemplate,
          }
        : undefined,
      natureOfBusiness: entity.natureOfBusiness || '',
      numberOfEmployees:
        getRangeValue(NUMBER_OF_EMPLOYEES_ARRAY, entity.numberOfEmployees)
          ?.key || null,
      numberOfOffices:
        getRangeValue(NUMBER_OF_OFFICES_ARRAY, entity.numberOfOffices)?.key ||
        null,
      industryCodes: entity.industryCodes
        ? (entity.industryCodes as any[])
        : null,
      isRegulated:
        typeof entity.isRegulated === 'boolean' ? entity.isRegulated : null,
      regulationCountry: entity.regulationCountry || null,
      canIssueBearerShares:
        typeof entity.canIssueBearerShares === 'boolean'
          ? entity.canIssueBearerShares
          : null,
      isVendor: typeof entity.isVendor === 'boolean' ? entity.isVendor : null,
      primaryAddressId: primaryAddress?._id || null,
      addresses: entity.addresses
        ? entity.addresses.map((e) => ({
            id: e._id as string,
            type: e.type || [],
            country: e.country || '',
            city: e.city || '',
            street: e.street || '',
            state: e.state || '',
            postal: e.postalCode,
            isApproved: !!e.approval?.isApproved,
            isCreated: true,
          }))
        : [],
      canUpdatePrimaryStatusForAddresses,
      socialMedia: {
        websiteLink: entity.socialMedia
          ? EntityHelpers.getSocialMediaLink(
              SocialMedia.Website,
              entity.socialMedia,
            )
          : '',
        twitterLink: entity.socialMedia
          ? EntityHelpers.getSocialMediaLink(
              SocialMedia.Twitter,
              entity.socialMedia,
            )
          : '',
        facebookLink: entity.socialMedia
          ? EntityHelpers.getSocialMediaLink(
              SocialMedia.Facebook,
              entity.socialMedia,
            )
          : '',
        linkedInLink: entity.socialMedia
          ? EntityHelpers.getSocialMediaLink(
              SocialMedia.LinkedIn,
              entity.socialMedia,
            )
          : '',
      },
    };
  },
};

export { entityAdapter };
