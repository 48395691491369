import * as yup from 'yup';
import { SelectedRelationshipTemplate } from 'components/Forms/TemplateForms/CRM/EntityTypeCategoryForm/RelationshipTemplatesSection/SelectedRelationshipTemplates';
import { AdditionalFieldType, OnboardType } from '../../../enums/crm/crm';
import {
  NULLABLE_BOOLEAN_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
} from 'validations/common';

export const EntityTypeCategoryFormValidationSchema = yup.object().shape({
  name: STRING_VALIDATION_RULE.min(4, {
    key: 'form:validation_messages.min_characters_with_label',
    values: { label: 'entity_type_category.new.form_fields.name', number: 4 },
  })
    .max(100, {
      key: 'form:validation_messages.max_characters_with_label',
      values: {
        label: 'entity_type_category.new.form_fields.name',
        number: 100,
      },
    })
    .required({
      key: 'form:validation_messages.required_with_label',
      values: { label: 'entity_type_category.new.form_fields.name' },
    }),
  description: STRING_VALIDATION_RULE.max(2000),
  isOnboardingOption: yup.boolean(),
  additionalFields: yup
    .array()
    .of(
      yup.object().shape({
        name: STRING_VALIDATION_RULE.max(100, {
          key: 'form:validation_messages.max_characters_with_label',
          values: {
            label:
              'entity_type_category.new.form_fields.additional_fields.name',
            number: 100,
          },
        }).required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'entity_type_category.new.form_fields.additional_fields.name',
          },
        }),
        type: STRING_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'entity_type_category.new.form_fields.additional_fields.type',
          },
        }),
        formDocument: yup.array().when('type', {
          is: AdditionalFieldType.Form,
          then: (schema) =>
            schema
              .min(1, 'form:validation_messages.required')
              .required('form:validation_messages.required'),
        }),
        selectOptions: yup.array().when('type', {
          is: AdditionalFieldType.Select,
          then: (schema) =>
            schema
              .min(1, 'form:validation_messages.required')
              .required('form:validation_messages.required'),
        }),
        isRegulatedOnly: NULLABLE_BOOLEAN_VALIDATION_RULE,
      }),
    )
    .test(
      'additional_field_requirements',
      'Please add at least two additional fields, one for regulated entries and one for non-regulated entries',
      (value) => {
        if (!value?.length || value.length < 2) {
          return false;
        }

        const additionalFieldForRegulated = value?.find(
          (e) => e.isRegulatedOnly,
        );

        return !!additionalFieldForRegulated;
      },
    ),

  relationshipTemplates: yup
    .array()
    .of(
      yup.object().shape({
        required: yup.boolean(),
        minAmount: yup
          .number()
          .moreThan(-1, 'form:validation_messages.required')
          .integer()
          .max(100)
          .required('form:validation_messages.required')
          .when('required', {
            is: true,
            then: (schema) =>
              schema.min(1, 'form:validation_messages.required'),
          }),
        minPercentage: yup
          .number()
          .moreThan(-1, 'form:validation_messages.required')
          .integer()
          .max(100)
          .required('form:validation_messages.required'),
        isOwnership: yup.boolean(),
        ownershipAdditionalField: NULLABLE_STRING_VALIDATION_RULE.when(
          'isOwnership',
          {
            is: true,
            then: (schema) =>
              schema.required('form:validation_messages.required'),
          },
        ),
      }),
    )
    .min(1, 'form:validation_messages.required')
    .required('form:validation_messages.required')
    .test(
      'relationship_templates_requirements',
      'Please add at least one relationship template, with is required and is a controller flags checked',
      (value, options) => {
        if (options.parent.visibleFor == OnboardType.Contact) {
          return true;
        }

        if (!value?.length) {
          return false;
        }

        const selectedRelationships = value as SelectedRelationshipTemplate[];
        const relationshipWithAllRequirements = selectedRelationships.some(
          (e) => e.required && e.controller,
        );
        return !!relationshipWithAllRequirements;
      },
    ),
});
