import { FetchResponseModel } from '../../../typings/common';
import { FeeScheduleModel } from '../../../typings/finance/feeSchedule';

const feeScheduleAPIAdapter = {
  fetchFeeSchedulesByClientGroupId: (
    data: FetchResponseModel<FeeScheduleModel>,
  ) => {
    const result = { ...data };

    result.data = result.data.map((e) => {
      const feeScheduleEntries = e.feeScheduleEntries.map((fs) => ({
        ...fs,
        isDefault: fs.clientId === 0,
        feeScheduleTypeId: e.feeScheduleTypeId,
      }));

      return {
        ...e,
        feeScheduleEntries,
      };
    });

    return result;
  },
};

export default feeScheduleAPIAdapter;
