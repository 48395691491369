// helpers utils
import styled from 'styled-components';

const NewMessageMarker = styled.div`
  background-color: ${({ theme }) => theme.errorColor};
  width: 8px;
  height: 8px;
  border-radius: 50%;
`;

export default NewMessageMarker;
