import React from 'react';

// helpers
import { FormikHelpers } from 'formik';
import { ApprovalWorkflowType } from '../../../../typings/approval/templates';
import { AutocompleteInitialValue } from '../../../Fields/Autocomplete';
import { ApprovalWorkflowTemplateValidationSchema } from 'validations/approval';

// components
import Form from '@core_components/Form';
import InnerMatrix from './InnerMatrix';
import FormEditModeButtons from '../FormEditModeButtons';

interface IProps {
  limits: ApprovalLimitModel[];
  hasEditAccess: boolean;
  initialFormValues: FormValuesModel;
  approvalWorkflowType: ApprovalWorkflowType;

  disabled?: boolean;

  onSubmit: (
    values: FormValuesModel,
    formHelpers: FormikHelpers<FormValuesModel>,
  ) => void;
}

export interface ApprovalLimitModel {
  id: string;
  name: string | React.ReactNode;
}

export interface FormValuesModel {
  id: string | null;
  isEditMode: boolean;
  approvalWorkflowType: ApprovalWorkflowType;
  approvalConditions: ApprovalConditionModel[];
  // We need this property for an API GET Administration Approval Groups in ApprovalGroupsAutocomplete component
  clientGroupId?: number;
}

export interface ApprovalConditionModel {
  approvalGroupConditions: ApprovalGroupConditionModel[];
  thresholdStatuses: { [key: string]: { ticked: boolean } };
}

export interface ApprovalGroupConditionModel {
  approvalGroupId: string | null;
  requiredApprovers: number | null;
  maximumRequiredApprovers: number;
  initialAutocompleteValue?: AutocompleteInitialValue;
}

const ApprovalManagementMatrix = ({
  disabled,
  limits,
  initialFormValues,
  onSubmit,
  hasEditAccess,
}: IProps) => {
  return (
    <Form<FormValuesModel>
      disabled={disabled}
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      validationSchema={ApprovalWorkflowTemplateValidationSchema}
      confirmExitWithoutSaving
      renderForm={
        <>
          {hasEditAccess && <FormEditModeButtons />}
          <InnerMatrix limits={limits} />
        </>
      }
    />
  );
};

export default ApprovalManagementMatrix;
