import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import { StyledComponentProps } from 'typings/common';

// components
import { Typography } from 'antd';

interface IProps extends StyledComponentProps {
  isVerified: boolean | undefined;
}

const VerifiedStatusText = ({ isVerified, ...rest }: IProps) => {
  const { t } = useTranslation('common');

  return (
    <VerifiedText isVerified={isVerified} {...rest}>
      {t(isVerified ? 'verified' : 'not_verified')}
    </VerifiedText>
  );
};

const VerifiedText = styled(Typography.Text)<{
  isVerified: boolean | undefined;
}>`
  color: ${({ theme, isVerified }) =>
    isVerified ? theme.clickableColor : theme.errorColor} !important;
  text-transform: none !important;
`;

export default VerifiedStatusText;
