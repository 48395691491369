// helpers
import { SubRouteModel } from '../../../typings/routes';

// helper components & layouts
import Navigation from '../../../pages/Navigation';
import PageLayout from '../../../layouts/PageLayout';

// pages
import Entities from '../../../pages/CRM/Entities';
import Contacts from '../../../pages/CRM/Contacts';
import Documents from '../../../pages/CRM/Documents';
import Invitations from '../../../pages/CRM/Invitations';
import ClientGroups from '../../../pages/CRM/ClientGroups';
import ClientDetails from '../../../modules/CRM/ClientGroups/ClientDetails';
import MessagesThread from '../../../pages/CRM/MessagesThread';
import CRMDashboardPage from 'pages/CRM/CRMDashboardPage';
import EntityDetailsPage from 'pages/CRM/EntityDetailsPage';
import NotesDashboardPage from 'pages/CRM/NotesDashboardPage';
import ContactDetailsPage from 'pages/CRM/ContactDetailsPage';
import EntityTypeCategories from '../../../pages/CRM/Configuration/EntityTypeCategories';
import RelationshipTemplates from '../../../pages/CRM/Configuration/RelationshipTemplates';
import ClientGroupDetailsPage from '../../../pages/CRM/ClientGroupDetailsPage';

enum CRMRoutesEnum {
  CRM_Root = '/crm',
  CRM_Documents = '/crm/documents',
  CRM_Entities = '/crm/entities',
  CRM_Entities_Edit = '/crm/entities/edit',
  CRM_Contacts = '/crm/contacts',
  CRM_Contacts_Edit = '/crm/contacts/edit',
  CRM_Invitations = '/crm/invitations',
  CRM_Client_Groups = '/crm/client-groups',
  CRM_Client_Group_Details = '/crm/client-groups/details',
  CRM_Client_Group_Client = '/crm/client-groups/details/client',
  CRM_Client_Group_Message_Thread = '/crm/client-groups/message-thread',
  CRM_Configuration = '/crm/configuration',
  CRM_Configuration_Relationship_Templates = '/crm/configuration/relationship-templates',
  CRM_Configuration_Entity_Type_Categories = '/crm/configuration/entity-type-categories',
  CRM_Notes = '/crm/notes',
}

const crmRoutes: SubRouteModel[] = [
  {
    title: 'CRM',
    path: CRMRoutesEnum.CRM_Root,
    layout: PageLayout,
    component: CRMDashboardPage,
    permission: { permissionKey: 'CRM_View' },
    layoutProps: { hideContentCard: true },
    childRoutes: [
      {
        title: 'Entities',
        path: CRMRoutesEnum.CRM_Entities,
        layout: PageLayout,
        component: Entities,
        permission: { permissionKey: 'CRM_Entities_View' },
        childRoutes: [
          {
            hideFromNavigation: true,
            title: 'Entity',
            path: CRMRoutesEnum.CRM_Entities_Edit,
            layout: PageLayout,
            component: EntityDetailsPage,
            fullPageContent: true,
            permission: { permissionKey: 'CRM_Entities_Details_View' },
            layoutProps: { goBackPath: CRMRoutesEnum.CRM_Entities },
          },
        ],
      },

      {
        title: 'Contacts',
        path: CRMRoutesEnum.CRM_Contacts,
        layout: PageLayout,
        component: Contacts,
        permission: { permissionKey: 'CRM_Contacts_View' },
        childRoutes: [
          {
            hideFromNavigation: true,
            title: 'Contact',
            path: CRMRoutesEnum.CRM_Contacts_Edit,
            layout: PageLayout,
            component: ContactDetailsPage,
            fullPageContent: true,
            permission: { permissionKey: 'CRM_Contacts_Details_View' },
            layoutProps: { goBackPath: CRMRoutesEnum.CRM_Contacts },
          },
        ],
      },

      {
        permission: { permissionKey: 'CRM_Invitations_View' },
        title: 'Invitations',
        path: CRMRoutesEnum.CRM_Invitations,
        layout: PageLayout,
        component: Invitations,
      },

      {
        title: 'Client Groups',
        path: CRMRoutesEnum.CRM_Client_Groups,
        layout: PageLayout,
        component: ClientGroups,
        permission: { permissionKey: 'CRM_ClientGroups_View' },
        childRoutes: [
          {
            hideFromNavigation: true,
            title: 'Client Group Details',
            path: CRMRoutesEnum.CRM_Client_Group_Details,
            layout: PageLayout,
            component: ClientGroupDetailsPage,
            fullPageContent: true,
            layoutProps: { goBackPath: CRMRoutesEnum.CRM_Client_Groups },
          },
          {
            hideFromNavigation: true,
            title: 'Client',
            path: CRMRoutesEnum.CRM_Client_Group_Client,
            layout: PageLayout,
            component: ClientDetails,
            layoutProps: {
              goBackPath: CRMRoutesEnum.CRM_Client_Groups,
              hideContentCard: true,
            },
          },
          {
            hideFromNavigation: true,
            title: 'Message Thread',
            path: CRMRoutesEnum.CRM_Client_Group_Message_Thread,
            layout: PageLayout,
            component: MessagesThread,
            layoutProps: {
              goBackPath: CRMRoutesEnum.CRM_Client_Groups,
            },
          },
        ],
      },

      {
        title: 'Configuration',
        path: CRMRoutesEnum.CRM_Configuration,
        component: Navigation,
        layout: PageLayout,
        permission: { permissionKey: 'CRM_Configuration_View' },
        childRoutes: [
          {
            title: 'Relationship Templates',
            path: CRMRoutesEnum.CRM_Configuration_Relationship_Templates,
            component: RelationshipTemplates,
            layout: PageLayout,
            permission: {
              permissionKey: 'CRM_Configuration_RelationshipTemplates_View',
            },
          },

          {
            title: 'Entity Type Category',
            path: CRMRoutesEnum.CRM_Configuration_Entity_Type_Categories,
            component: EntityTypeCategories,
            layout: PageLayout,
            permission: {
              permissionKey: 'CRM_Configuration_EntityTypeCategories_View',
            },
          },
        ],
      },
      {
        title: 'Documents',
        path: CRMRoutesEnum.CRM_Documents,
        layout: PageLayout,
        component: Documents,
      },

      {
        title: 'Notes dashboard',
        path: CRMRoutesEnum.CRM_Notes,
        layout: PageLayout,
        component: NotesDashboardPage,
        permission: { permissionKey: 'CRM_Notes_View' },
      },
    ],
  },
];

export { crmRoutes, CRMRoutesEnum };
