import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { AnswerModel } from '../../../../../../../../api/crm/onboarding/onboardingAnswerAPI';
import { useFormikContext } from 'formik';
import { AdditionalFieldFormItemModel, FormValuesModel } from '../../..';

// components
import AddButton from '../../../../../../../../components/Buttons/AddButton';
import AddAdminDocumentModal from './AddAdminDocumentModal';

const AddAdditionalField = () => {
  const { t } = useTranslation('crm');
  const [isVisible, setIsVisible] = useState(false);
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();

  const handleModalClose = (newAnswer?: AnswerModel) => {
    if (newAnswer) {
      const newDocument: AdditionalFieldFormItemModel = {
        isFinalized: false,
        _id: newAnswer._id,
        requestedByAdmin: true,
        itemId: newAnswer.itemId,
        itemType: newAnswer.itemType,
        clientGroupId: newAnswer.clientGroupId,
        field: newAnswer.field,
        sources: newAnswer.sources,
        isApproved: newAnswer.review ? newAnswer.review?.isValid : null,
        isRejected: newAnswer.review ? !newAnswer.review.isValid : null,
        notReviewed: !newAnswer.review,
        reviewComment: newAnswer.review?.comment || '',
        answer: newAnswer.answer
          ? {
              isProvided: newAnswer.answer?.isProvided || true,
              valueJSON: newAnswer.answer?.valueJSON || '',
              reason: newAnswer.answer?.reason || '',
            }
          : null,
      };
      const newAdditionalFields = [...values.additionalFields, newDocument];
      setFieldValue('additionalFields', newAdditionalFields);
    }

    setIsVisible(false);
  };

  return (
    <>
      <AddButton buttonSize="small" onClick={() => setIsVisible(true)}>
        {t('client_group.approval.add_additional_field_document')}
      </AddButton>

      <AddAdminDocumentModal
        isVisible={isVisible}
        closeCallback={handleModalClose}
      />
    </>
  );
};

export default AddAdditionalField;
