import React from 'react';

// helpers
import useUserAccess from '../../../hooks/useUserAccess';

// components
import TableSection from './TableSection';
import ForexSpreadMatrixSection from './ForexSpreadMatrixSection';
import { Divider } from 'antd';

const FeeSchedule = () => {
  const [hasFeeScheduleAccess, hasSpreadMatrixAccess] = useUserAccess([
    'Banking_FeeSchedule_FeeScheduleSection_View',
    'Banking_FeeSchedule_SpreadMatrixSection_View',
  ]);

  return (
    <>
      {hasFeeScheduleAccess && <TableSection />}
      <Divider />
      {hasSpreadMatrixAccess && <ForexSpreadMatrixSection />}
    </>
  );
};

export default FeeSchedule;
