import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { UpdateCurrencyValidationSchema } from '../../../../../validations/accounting/currencies';

// components
import InnerForm from './InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export interface FormValuesModel {
  name: string;
  code: string;
  isActive: boolean;
  isAccount: boolean;
  currencyId: string | number;
}

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  isViewOnly?: boolean;
}

const CurrencyModal = ({
  closeCallback,
  isVisible,
  initialValues,
  onSubmit,
  isViewOnly,
}: IProps) => {
  const { t } = useTranslation('finance');

  return (
    <Form<FormValuesModel>
      disabled={isViewOnly}
      onSubmit={onSubmit}
      enableReinitialize
      renderForm={
        <ModalDialog
          width={700}
          title={t('currencies.update.title')}
          isVisible={isVisible}
          closeCallback={closeCallback}
          hideFooterButtons={isViewOnly}
        >
          <InnerForm />
        </ModalDialog>
      }
      initialValues={isVisible ? initialValues : null}
      validationSchema={UpdateCurrencyValidationSchema}
      confirmExitWithoutSaving
    />
  );
};

export default CurrencyModal;
