import React, { useState } from 'react';

// helpers
import { AnswerModel } from '../../../../../../../../../api/crm/onboarding/onboardingAnswerAPI';
import { useFormikContext } from 'formik';
import { AdditionalFieldFormItemModel, FormValuesModel } from '../../../..';

// components
import EditButton from '../../../../../../../../../components/Buttons/EditButton';
import UpdateAdminDocumentModal from './UpdateAdminDocumentModal';

interface IProps {
  fieldIndex: number;
}

const UpdateAdditionalField = ({ fieldIndex }: IProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const { setFieldValue } = useFormikContext<FormValuesModel>();

  const handleModalClose = (updatedAnswer?: AnswerModel) => {
    if (updatedAnswer) {
      const updatedField: AdditionalFieldFormItemModel = {
        isFinalized: false,
        _id: updatedAnswer._id,
        requestedByAdmin: true,
        itemId: updatedAnswer.itemId,
        itemType: updatedAnswer.itemType,
        clientGroupId: updatedAnswer.clientGroupId,
        field: updatedAnswer.field,
        sources: updatedAnswer.sources,
        isApproved: updatedAnswer.review ? updatedAnswer.review?.isValid : null,
        isRejected: updatedAnswer.review ? !updatedAnswer.review.isValid : null,
        notReviewed: !updatedAnswer.review,
        reviewComment: updatedAnswer.review?.comment || '',
        answer: updatedAnswer.answer
          ? {
              isProvided: updatedAnswer.answer?.isProvided || true,
              valueJSON: updatedAnswer.answer?.valueJSON || '',
              reason: updatedAnswer.answer?.reason || '',
            }
          : null,
      };

      setFieldValue(`additionalFields.${fieldIndex}`, updatedField);
    }

    setIsVisible(false);
  };

  return (
    <>
      <EditButton onClick={() => setIsVisible(true)} />
      <UpdateAdminDocumentModal
        isVisible={isVisible}
        closeCallback={handleModalClose}
        fieldIndex={fieldIndex}
      />
    </>
  );
};

export default UpdateAdditionalField;
