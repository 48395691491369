import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { UsersBaseModel } from '../../typings/ORAC/users';
import { GroupBaseModel } from '../../typings/ORAC/groups';
import { ObjectWithPermissionsModel } from '../../typings/ORAC/objects';
import { AddEditPermissionsRequestBodyModel } from '../../typings/ORAC/permissions';
import { FetchResponseModel, FetchParamsModel } from '../../typings/common';
import {
  NewRoleModel,
  RoleBaseModel,
  RoleWithPermissionsModel,
} from '../../typings/ORAC/roles';

interface FetchRolesParamsModel extends FetchParamsModel {
  name: string;
}

const rolesAPI = {
  fetchRoles: (params: FetchRolesParamsModel) => {
    return APIService.get<FetchResponseModel<RoleWithPermissionsModel>>(
      `${APIConfig.oracApi}/roles`,
      { params },
    ).then(({ data }) => data);
  },

  getRoleById: (roleId: string) => {
    return APIService.get<RoleBaseModel>(
      `${APIConfig.oracApi}/roles/${roleId}`,
    ).then(({ data }) => data);
  },

  createNewRole: (newRole: NewRoleModel) => {
    return APIService.post<RoleBaseModel>(
      `${APIConfig.oracApi}/roles`,
      newRole,
    ).then(({ data }) => data);
  },

  editRoleById: (roleId: string, updatedRole: NewRoleModel) => {
    return APIService.patch<RoleBaseModel>(
      `${APIConfig.oracApi}/roles/${roleId}`,
      updatedRole,
    ).then(({ data }) => data);
  },

  fetchGroupsByRoleId: (roleId: string, params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<GroupBaseModel>>(
      `${APIConfig.oracApi}/roles/${roleId}/groups`,
      { params },
    ).then(({ data }) => data);
  },

  fetchUsersByRoleId: (roleId: string, params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<UsersBaseModel>>(
      `${APIConfig.oracApi}/roles/${roleId}/users`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchPermissionsByRoleId: (roleId: string, params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<ObjectWithPermissionsModel>>(
      `${APIConfig.oracApi}/roles/${roleId}/permissions`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  editPermissionsByRoleId: (
    roleId: string,
    newPermissions: AddEditPermissionsRequestBodyModel,
  ) => {
    return APIService.put<null>(
      `${APIConfig.oracApi}/roles/${roleId}/permissions`,
      newPermissions,
    ).then(({ data }) => data);
  },
};

export default rolesAPI;
