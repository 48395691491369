import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { Condition, ConditionApprovalGroup } from '.';

// components
import Text from '@core_components/Text';
import Checkbox from '@core_components/Checkbox';
import { Col, Row, Tooltip } from 'antd';

interface IProps {
  record: Condition;
  colIndex: number;
  dataIndex: string;
  isLastRow: boolean;
}

const ContentCell = ({ record, dataIndex, colIndex, isLastRow }: IProps) => {
  const { t } = useTranslation('crm');

  const renderGroupName = (approvalGroup: ConditionApprovalGroup | null) => {
    if (!approvalGroup) {
      return '';
    }

    return (
      <Tooltip placement="bottom" title={renderPopoverContent(approvalGroup)}>
        <div>
          <StyledDottedText variant="body1">
            {approvalGroup.name}
          </StyledDottedText>
        </div>
      </Tooltip>
    );
  };

  const renderPopoverContent = (approvalGroup: ConditionApprovalGroup) => {
    const isMore = approvalGroup.participants.length > 5;

    return (
      <>
        {approvalGroup.participants.slice(0, 5).map(({ userFullName }, i) => (
          <div key={i}>{userFullName}</div>
        ))}

        {isMore && (
          <Text variant="body1">
            {t(
              'client_group.approval.client_transaction_rules.matrix.more_users',
              { number: approvalGroup.participants.length - 5 },
            )}
          </Text>
        )}
      </>
    );
  };

  const renderContent = () => {
    switch (dataIndex) {
      case 'condition':
        return (
          <>
            {record.conditions.map((e, i: number) => {
              const shouldRenderAnd = i < record.conditions.length - 1;
              const shouldRenderOr =
                !isLastRow && i === record.conditions.length - 1;
              return (
                <>
                  <Row key={i}>
                    <Col>
                      {`${t('client_group.approval.client_transaction_rules.matrix.approval_group_name')}: `}
                      {renderGroupName(e.approvalGroupFull)}
                    </Col>

                    <Col span={24}>
                      {`${t('client_group.approval.client_transaction_rules.matrix.required_number_of_approvers')}: ${
                        e.requiredApproversCount
                      }`}
                    </Col>
                  </Row>

                  {shouldRenderAnd && (
                    <StyledANDText variant="body1" weight="semi-bold">
                      {t(
                        'client_group.approval.client_transaction_rules.matrix.and',
                      )}
                    </StyledANDText>
                  )}

                  {shouldRenderOr && (
                    <ORTextWrapper>
                      <Text variant="body1" weight="semi-bold">
                        {t(
                          'client_group.approval.client_transaction_rules.matrix.or',
                        )}
                      </Text>
                    </ORTextWrapper>
                  )}
                </>
              );
            })}
          </>
        );

      case 'limit': {
        const checkboxValue = record.limitStatuses[colIndex].isChecked;
        return (
          <AlignCenterWrapper>
            <Checkbox value={checkboxValue} disabled />
          </AlignCenterWrapper>
        );
      }

      default:
        return null;
    }
  };

  return <StyledColWrapper>{renderContent()}</StyledColWrapper>;
};

const StyledColWrapper = styled.td`
  padding: ${({ theme }) => theme.paddingXl} !important;
`;

const ORTextWrapper = styled.div`
  position: absolute;
  bottom: -13px;
  width: 50px;
  text-align: center;
  background: ${({ theme }) => theme.backgroundColor2};
`;

const StyledANDText = styled(Text)`
  padding: 8px 16px;
`;

const AlignCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDottedText = styled(Text)`
  cursor: pointer;
  border-bottom: ${({ theme }) => `1px dotted ${theme.primaryGreen}`};
`;

export default ContentCell;
