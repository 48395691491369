import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { RDCReviewStatuses } from '../../../../../enums/compliance/reviewConfiguration';
import { RDCReviewAlertBaseModel } from '../../../../../typings/compliance/reviewProcess';

// components
import InfoIconWithTooltip from '../../../../Icons/InfoIconWithTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import StatusLabel, {
  LABEL_STATUSES,
} from '../../../../Typography/StatusLabel';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<RDCReviewAlertBaseModel> {
  onDetailsClick: (record: RDCReviewAlertBaseModel) => void;
}

const RDCAlertsTable = memo(({ onDetailsClick, ...rest }: IProps) => {
  const { t } = useTranslation('compliance');

  const renderReviewStatus = (record: RDCReviewAlertBaseModel) => {
    let result = null;
    const { reviewStatus } = record;

    if (reviewStatus.isReviewed) {
      if (reviewStatus.match) {
        result = (
          <StatusLabel
            status={LABEL_STATUSES.error}
            text={
              <>
                {t(
                  `review_statuses.${RDCReviewStatuses[RDCReviewStatuses.Matched]}`,
                )}
                <StyledInfoIcon
                  tooltipMessage={t('rdc_review.reason', {
                    reason: reviewStatus.reason,
                  })}
                />
              </>
            }
          />
        );
      } else {
        result = (
          <StatusLabel
            status={LABEL_STATUSES.success}
            text={
              <>
                {t(
                  `review_statuses.${RDCReviewStatuses[RDCReviewStatuses.NotMatched]}`,
                )}
                <StyledInfoIcon
                  tooltipMessage={t('rdc_review.reason', {
                    reason: reviewStatus.reason,
                  })}
                />
              </>
            }
          />
        );
      }
    } else {
      result = (
        <StatusLabel
          status={LABEL_STATUSES.warning}
          text={t(
            `review_statuses.${RDCReviewStatuses[RDCReviewStatuses.Pending]}`,
          )}
        />
      );
    }

    return result;
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: 300,
        title: t('transactions.name'),
        key: 'name',
        render: (record: RDCReviewAlertBaseModel) => (
          <Typography.Link onClick={() => onDetailsClick(record)}>
            {record.alertEntity.name}
          </Typography.Link>
        ),
      },

      {
        width: 350,
        title: t('transactions.review_status'),
        key: 'review_status',
        render: renderReviewStatus,
      },
    ];

    return result;
  }, []);

  return <Table {...rest} columns={columns} />;
});

const StyledInfoIcon = styled(InfoIconWithTooltip)`
  margin-left: 5px;
  cursor: pointer;
`;

export default RDCAlertsTable;
