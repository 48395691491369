import React, { useEffect } from 'react';

// helpers
import useQueryParam from '../../../hooks/useQueryParam';
import useTranslation from '../../../hooks/useTranslation';
import { ReviewProcessContextProvider } from '../../../hooks/domainHooks/useReviewProcess';

// constants
import { RoutePaths } from '../../../routes/routes';

// components
import TransactionDetails from '../../../modules/Compliance/TransactionDetails';

interface IProps {
  setHeaderOptions: (options: any) => void;
}

const TransactionDetailsPage = ({ setHeaderOptions }: IProps) => {
  const { t } = useTranslation('page_titles');
  const requestId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.Compliance_Transactions,
  });

  useEffect(() => {
    setHeaderOptions({
      title: `${t('Transaction')}: ${requestId}`,
    });
  }, [requestId]);

  return (
    <ReviewProcessContextProvider>
      <TransactionDetails />
    </ReviewProcessContextProvider>
  );
};

export default TransactionDetailsPage;
