import React, { useState } from 'react';

// helpers
import { UserFullModel } from '../../../../../typings/ORAC/users';

// components
import Devices from './Devices';
import CurrentSessions from './CurrentSessions';
import IdentityVerification from './IdentityVerification';
import TwoFactorAuthentication from './TwoFactorAuthentication';
import SessionHistory from './SessionHistory';

interface IProps {
  user: UserFullModel;
}

const SecurityTab = ({ user }: IProps) => {
  const [updateTableTrigger, updateTable] = useState({});

  return (
    <>
      <TwoFactorAuthentication user={user} />
      <IdentityVerification userId={user._id} />
      <Devices
        userId={user._id}
        updateTableTrigger={updateTableTrigger}
        onSessionRemove={() => updateTable({})}
      />
      <CurrentSessions
        userId={user._id}
        updateTableTrigger={updateTableTrigger}
        onSessionRemove={() => updateTable({})}
      />
      <SessionHistory
        userId={user._id}
        updateTableTrigger={updateTableTrigger}
      />
    </>
  );
};

export default SecurityTab;
