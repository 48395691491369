import React from 'react';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

interface Sizes {
  xsmall: string;
  small: string;
  middle: string;
  large: string;
}

const sizes: Sizes = {
  xsmall: 'fontSizeXxs',
  small: 'fontSizeXs',
  middle: 'fontSizeSm',
  large: 'fontSizeLg',
};

interface IProps {
  children?: React.ReactNode;
  buttonSize?: keyof Sizes;
  icon?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

const AddButton = ({ children, icon, ...rest }: IProps) => {
  return (
    <StyledButton {...rest}>
      {icon || <PlusOutlined />} {children}
    </StyledButton>
  );
};

const StyledButton = styled.div<{ buttonSize?: keyof Sizes }>`
  display: inline-block;
  font-size: ${({ theme, buttonSize }) => theme[sizes[buttonSize || 'middle']]};
  color: ${({ theme }) => theme.clickableColor};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.selectColor};
  }
`;

export default AddButton;
