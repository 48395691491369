import React, { memo, useMemo } from 'react';

// helpers utils
import useTranslation from '../../../../../hooks/useTranslation';
import { enumToMap } from '../../../../../helpers/utils';
import { AdditionalFieldType } from '../../../../../enums/crm/crm';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type AdditionalFieldTypeFormSelectProps = SelectProps;

const AdditionalFieldTypeFormSelect = memo(
  (props: AdditionalFieldTypeFormSelectProps) => {
    const { t } = useTranslation('crm');

    const options = useMemo<SelectOption[]>(() => {
      const options = Array.from(enumToMap(AdditionalFieldType));

      return options.map((e) => ({
        id: e[1],
        label: t(`additional_field_type.${e[1]}`),
      }));
    }, [t]);

    return <FormSelect {...props} options={options} />;
  },
);

export default AdditionalFieldTypeFormSelect;
