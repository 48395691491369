import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ClientApprovalWorkflowChangeTypes } from 'typings/approval/approvalWorkflow';
import {
  ClientApprovalEntryModel,
  ApprovalRulesTemplateBaseModel,
} from 'apiAdapters/approval/clientAccountManagementRulesAdapterAPI';

// components
import SectionIntro from '@common_components/Texts/SectionIntro';
import InnerTemplate from './InnerTemplate';

export interface AccountManagementApprovalRuleTemplateData {
  approvalRule: ApprovalRulesTemplateBaseModel;
}

interface IProps {
  approvalEntry: ClientApprovalEntryModel<AccountManagementApprovalRuleTemplateData>;
}

const AdministrationApprovalRule = ({ approvalEntry }: IProps) => {
  const { t } = useTranslation('crm');

  const renderContent = () => {
    switch (approvalEntry.entry.changeType) {
      case ClientApprovalWorkflowChangeTypes.Create:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t(
                'client_group.administration_rules.pending_approvals.template_change_versions.new',
              )}
            />
            <InnerTemplate
              data={
                approvalEntry.changes
                  .new as AccountManagementApprovalRuleTemplateData
              }
            />
          </>
        );

      case ClientApprovalWorkflowChangeTypes.Delete:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t(
                'client_group.administration_rules.pending_approvals.template_change_versions.removed',
              )}
            />
            <InnerTemplate
              data={
                approvalEntry.changes
                  .new as AccountManagementApprovalRuleTemplateData
              }
            />
          </>
        );

      case ClientApprovalWorkflowChangeTypes.Edit:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t(
                'client_group.administration_rules.pending_approvals.template_change_versions.old',
              )}
            />
            <InnerTemplate
              data={
                approvalEntry.changes
                  .old as AccountManagementApprovalRuleTemplateData
              }
            />

            <SectionIntro
              titleVariant="h5"
              title={t(
                'client_group.administration_rules.pending_approvals.template_change_versions.new',
              )}
            />
            <InnerTemplate
              data={
                approvalEntry.changes
                  .new as AccountManagementApprovalRuleTemplateData
              }
            />
          </>
        );
    }
  };

  return <>{renderContent()}</>;
};

export default AdministrationApprovalRule;
