import React, { useMemo, memo } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from '../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../hooks/useTranslation';
import { darkTheme } from '@resources/theme/styled';
import { tryToParseJson } from '@helpers/utils';
import { useReviewProcessContext } from '../../../../../hooks/domainHooks/useReviewProcess';
import {
  ComparisonOperators,
  ExpectedDataTypes,
  ReviewConfigurationsStatuses,
  ReviewProcessStatuses,
} from '../../../../../enums/compliance/reviewConfiguration';
import {
  ExpectedRiskLevelTypes,
  ReviewProcessAlertModel,
  ReviewProcessConfigurationRuleModel,
  ReviewProcessType,
} from '../../../../../typings/compliance/reviewProcess';

// components
import Text from '@core_components/Text';
import StatusLabel from '../../../../Typography/StatusLabel';
import ReviewButton from '../../../../../modules/Compliance/TransactionDetails/ReviewConfigurationsSection/ReviewConfigurationsTab/TransactionReview/ReviewButton';
import EllipsisTooltip from '../../../../Tooltips/EllipsisTooltip';
import InfoIconWithTooltip from '../../../../Icons/InfoIconWithTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

type IProps = RequiredPropsForTableModel<ReviewProcessConfigurationRuleModel>;

const STATUS_MAP = {
  info: [''],
  warning: [ReviewConfigurationsStatuses.Pending],
  success: [ReviewConfigurationsStatuses.Passed],
  error: [ReviewConfigurationsStatuses.NotPassed],
};

const ReviewRulesTable = memo((props: IProps) => {
  const { t } = useTranslation(['compliance', 'common']);
  const {
    transactionReviewProcess,
    approveAlert,
    rejectAlert,
    reviewProcessStatus,
  } = useReviewProcessContext();
  const [hasReviewAccess] = useUserAccess([
    'Compliance_Transactions_Details_ReviewAlert',
  ]);

  const canReview =
    hasReviewAccess &&
    (reviewProcessStatus === ReviewProcessStatuses.New ||
      reviewProcessStatus === ReviewProcessStatuses.InProgress);

  const renderValueInEllipsis = (
    record: ReviewProcessConfigurationRuleModel,
  ) => {
    let result = null;

    switch (record.expectedDataType) {
      case ExpectedDataTypes.CountryRiskLevel:
        {
          const parsedValue = JSON.parse(
            record.expected?.valueJSON,
          ) as ExpectedRiskLevelTypes[];
          result = parsedValue
            .map((e) =>
              t(`expected_risk_level_types.${ExpectedRiskLevelTypes[e]}`),
            )
            .join(', ');
        }
        break;

      default:
        result = (
          <EllipsisTooltip
            maxTextContainerWidth="300px"
            overlayStyle={{ maxWidth: '400px' }}
            title={record.expected?.valueJSON}
          >
            {record.expected?.valueJSON}
          </EllipsisTooltip>
        );
    }

    return result;
  };

  const getCountryLabel = (
    countryCode: string,
    rule: ReviewProcessConfigurationRuleModel,
  ) => {
    const riskLevel = rule.ruleExecutionMetadata?.riskRatings
      ? rule.ruleExecutionMetadata?.riskRatings[
          rule.evaluatedAgainstValueJSON as any
        ]
      : null;
    return `${countryCode} ${riskLevel ? `(${t(`expected_risk_level_types.${ExpectedRiskLevelTypes[riskLevel]}`)})` : ''}`;
  };

  const renderActualValue = (record: ReviewProcessConfigurationRuleModel) => {
    if (record.evaluatedAgainstValueJSON === 'null') {
      return (
        <Text color={darkTheme.cardBorderColor}>
          {t('no_value', { ns: 'common' })}
        </Text>
      );
    }

    let result = null;

    switch (record.expectedDataType) {
      case ExpectedDataTypes.CountryRiskLevel:
        {
          const parsedValue = tryToParseJson(record.evaluatedAgainstValueJSON);
          if (parsedValue.success) {
            if (Array.isArray(parsedValue.value)) {
              result = parsedValue.value.map((e, i) => (
                <Text key={i}>{getCountryLabel(e, record)}</Text>
              ));
            } else {
              result = getCountryLabel(parsedValue.value as string, record);
            }
          } else {
            result = getCountryLabel(record.evaluatedAgainstValueJSON, record);
          }
        }
        break;

      default:
        result = (
          <EllipsisTooltip
            maxTextContainerWidth="300px"
            overlayStyle={{ maxWidth: '400px' }}
            title={record.evaluatedAgainstValueJSON}
          >
            {record.evaluatedAgainstValueJSON}
          </EllipsisTooltip>
        );
    }

    return result;
  };

  const columns = useMemo(() => {
    let result: TableColumnModel[] = [
      {
        width: 250,
        title: t('transactions.name'),
        key: 'propertyName',
        dataIndex: 'propertyName',
      },

      {
        width: 200,
        title: t('transactions.is_triggered'),
        key: 'isTriggered',
        render: (record: ReviewProcessConfigurationRuleModel) =>
          record.isTriggered ? (
            <HighlightedText>{t('yes', { ns: 'common' })}</HighlightedText>
          ) : (
            t('no', { ns: 'common' })
          ),
      },

      {
        width: 300,
        title: t('transactions.actual_value'),
        key: 'evaluatedAgainstValueJSON',
        render: renderActualValue,
      },

      {
        width: 250,
        title: t('transactions.comparison_operator'),
        key: 'comparisonOperator',
        render: (record: ReviewProcessConfigurationRuleModel) =>
          t(
            `comparison_operator.${ComparisonOperators[record.comparisonOperator]}`,
          ),
      },

      {
        width: 300,
        title: t('transactions.expected_value'),
        key: 'expectedValueJSON',
        render: renderValueInEllipsis,
      },

      {
        width: 250,
        title: t('transactions.object'),
        key: 'object',
        render: (record: ReviewProcessConfigurationRuleModel) =>
          t(`review_process.types.${ReviewProcessType[record.objectType]}`),
      },
    ];

    if (!transactionReviewProcess.alert?.isTriggered) {
      return result;
    }

    result = [
      ...result,

      {
        width: 180,
        title: t('transactions.review_status'),
        key: 'review_status',
        render: (record: ReviewProcessConfigurationRuleModel) => (
          <StatusLabel
            statusMap={STATUS_MAP}
            status={record.reviewResult.status}
            text={
              <>
                {t(
                  `review_statuses.${ReviewConfigurationsStatuses[record.reviewResult.status]}`,
                )}
                {record.reviewResult.status ===
                  ReviewConfigurationsStatuses.NotPassed && (
                  <StyledInfoIcon
                    tooltipMessage={`${t('transactions.reason')}: ${record.reviewResult.reason}`}
                  />
                )}
              </>
            }
          />
        ),
      },

      {
        hidden: !canReview,
        key: 'not_passed',
        render: (record: ReviewProcessConfigurationRuleModel) =>
          record.isTriggered && (
            <ReviewButton
              isDanger
              disabled={
                record.reviewResult.status ===
                ReviewConfigurationsStatuses.NotPassed
              }
              onReview={(reason) =>
                rejectAlert(
                  transactionReviewProcess.alert as ReviewProcessAlertModel,
                  reason,
                  record,
                )
              }
            >
              {t(
                `review_statuses.${ReviewConfigurationsStatuses[ReviewConfigurationsStatuses.NotPassed]}`,
              )}
            </ReviewButton>
          ),
      },

      {
        hidden: !canReview,
        key: 'passed',
        render: (record: ReviewProcessConfigurationRuleModel) =>
          record.isTriggered && (
            <ReviewButton
              disabled={
                record.reviewResult.status ===
                ReviewConfigurationsStatuses.Passed
              }
              onReview={(reason) =>
                approveAlert(
                  transactionReviewProcess.alert as ReviewProcessAlertModel,
                  reason,
                  record,
                )
              }
            >
              {t(
                `review_statuses.${ReviewConfigurationsStatuses[ReviewConfigurationsStatuses.Passed]}`,
              )}
            </ReviewButton>
          ),
      },
    ];

    return result;
  }, [transactionReviewProcess, canReview]);

  return <Table {...props} columns={columns} size="small" />;
});

const HighlightedText = styled(Typography.Text)`
  color: ${({ theme }) => theme.successColor};
`;

const StyledInfoIcon = styled(InfoIconWithTooltip)`
  margin-left: 5px;
  cursor: pointer;
`;

export default ReviewRulesTable;
