import React from 'react';

// helpers
import groupsAPI from '../../../../../../api/orac/groupsAPI';
import useTranslation from '../../../../../../hooks/useTranslation';
import { message } from 'antd';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  groupId: string;
}

interface FormValuesModel {
  contextId: string | null;
}

const AddContextToGroupModal = ({
  isVisible,
  closeCallback,
  groupId,
}: IProps) => {
  const { t } = useTranslation('orac');

  const initialFormValues: FormValuesModel = { contextId: null };

  const onSubmit = async (values: FormValuesModel) => {
    const { contextId } = values;
    await groupsAPI.addContextsToGroup(groupId, [contextId] as string[]);
    message.success(t('groups.view.add_role_to_group_success'));
  };

  return (
    <Form<FormValuesModel>
      enableReinitialize
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      renderForm={
        <ModalDialog
          width="500px"
          title={t('groups.context_modal.title')}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default AddContextToGroupModal;
