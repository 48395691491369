import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../hooks/useTranslation';
import { MessageModel } from '../../../typings/messaging/messages';
import { IDocumentAssociationInfo } from '../../CRM/Documents/UploadDocumentsDialog';
import {
  NewMessageBodyModel,
  messagingAPI,
} from '../../../api/messaging/messagingAPI';

// components
import ReplyForm, {
  FormValuesModel,
} from '../../../components/Forms/TemplateForms/Messages/Messages/ReplyForm';
import { Button, message as MessageNotification } from 'antd';

interface IProps {
  threadId: string;
  association: IDocumentAssociationInfo;
  onReply: (message: MessageModel) => void;
  clientGroupId: number;
  isReplyFormVisible: boolean;
  setIsReplyFormVisible: (value: boolean) => void;
}

const ReplyInThread = ({
  clientGroupId,
  onReply,
  association,
  threadId,
  isReplyFormVisible,
  setIsReplyFormVisible,
}: IProps) => {
  const { t } = useTranslation('crm');

  const initialFormValues = useMemo<FormValuesModel>(
    () => ({ message: '', attachments: [] }),
    [],
  );

  const handleOnSubmit = async (values: FormValuesModel) => {
    const newMessage: NewMessageBodyModel = {
      body: values.message,
      attachments: values.attachments?.map((attachment) => {
        return {
          documentId: attachment.id,
          contentType: attachment.file?.contentType || '',
          fileId: attachment.file?.id || '',
          name: attachment.file?.name || '',
        };
      }),
    };

    const data = await messagingAPI.replyInThread(
      clientGroupId,
      threadId,
      newMessage,
    );
    MessageNotification.success(
      t('messaging.send_message.success_submit_message'),
    );
    onReply(data?.message);
    setIsReplyFormVisible(false);
  };

  return (
    <>
      {isReplyFormVisible ? (
        <ReplyForm
          onSubmit={handleOnSubmit}
          initialFormValues={initialFormValues}
          association={association}
        />
      ) : (
        <Button onClick={() => setIsReplyFormVisible(true)}>
          {t('messaging.reply.reply_button')}
        </Button>
      )}
    </>
  );
};

export default ReplyInThread;
