import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';

/// constants
import { DocumentAssociationTypes } from '../../../../../../../enums/crm/crm';

// components
import DocumentsTable from './DocumentsTable';
import UploadDocumentsDialog from '../../../../../Documents/UploadDocumentsDialog';
import { Button, Col, Row } from 'antd';

interface IProps {
  clientGroupId: string;
}

const DocumentsTabs = ({ clientGroupId }: IProps) => {
  const { t } = useTranslation('crm');

  const [isUploadDialogVisible, setUploadDialogVisible] = React.useState(false);

  return (
    <Row gutter={[36, 16]}>
      <Col span={24}>
        <ButtonWrapper>
          <Button
            type="primary"
            size="large"
            onClick={() => setUploadDialogVisible(!isUploadDialogVisible)}
          >
            {t('entity.documents.uploadDocuments')}
          </Button>
        </ButtonWrapper>
      </Col>

      <Col span={24}>
        <DocumentsTable clientGroupId={clientGroupId} />
      </Col>

      <UploadDocumentsDialog
        isVisible={isUploadDialogVisible}
        closeCallback={() => setUploadDialogVisible(false)}
        association={{
          type: DocumentAssociationTypes.ClientGroup,
          id: clientGroupId,
        }}
      />
    </Row>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default DocumentsTabs;
