import { useState, useEffect } from 'react';
import { RouteModel, SubRouteModel } from '../typings/routes';

// This hook is responsible for searching the current page title
// Input props:
// - current path;
// - all routes in the system
const useBrowserTabTitle = (currentPath: string, routes: RouteModel[]) => {
  const [browserTabTitle, setBrowserTabTitle] = useState('');

  // Updated browser tab title if the current path has been changed
  useEffect(() => {
    const newTitle = getPageTitle();
    setBrowserTabTitle(newTitle);
  }, [currentPath]);

  // Find the routes section and start searching from there
  // If path array is equal to empty or ['/'], that means that this is first page 'Dashboard'
  const getPageTitle = () => {
    const pathArray = generateFullRoutePathArray(currentPath);

    if (!pathArray.length) return 'Dashboard';

    let newPageTitle = '';

    const parentRouteSectionIndex = routes.findIndex(
      ({ path }) => path === pathArray[0],
    );

    if (parentRouteSectionIndex !== -1) {
      newPageTitle = findPageTitle(
        pathArray,
        routes[parentRouteSectionIndex].routes,
      );
    } else {
      const clientRoutesSectionIndex = routes.findIndex((e) => e.path === '/');
      newPageTitle = findPageTitle(
        pathArray,
        routes[clientRoutesSectionIndex].routes,
      );
    }

    return newPageTitle;
  };

  // Find current page title using recursion
  const findPageTitle = (
    pathArray: string[],
    routes: SubRouteModel[],
  ): string => {
    let result = '';

    const routeIndex = routes.findIndex((e) => e.path === pathArray[0]);

    // This case should not happen if everything is ok with routes and paths for them
    if (routeIndex === -1) return 'Not found page title';

    if (pathArray.length > 1) {
      result = findPageTitle(
        pathArray.slice(1),
        routes[routeIndex].childRoutes || [],
      );
    } else {
      result = routes[routeIndex].title;
    }

    return result;
  };

  // Generate all path array from current location pathname (from tree)
  // e.g Input Value = /system/admin/user -> Result = ['/system', '/system/admin', '/system/admin/user']
  const generateFullRoutePathArray = (pathName: string) => {
    const pathArray = pathName.split('/').filter((el) => el);

    let path = '';
    const result: string[] = [];

    pathArray.forEach((e, i) => {
      if (i === 0) {
        path = `/${e}`;
      } else {
        path = `${path}/${e}`;
      }

      result.push(path);
    });

    return result;
  };

  return browserTabTitle;
};

export default useBrowserTabTitle;
