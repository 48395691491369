import React, { useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import rolesAPI from '../../../../../api/orac/rolesAPI';
import groupsAPI from '../../../../../api/orac/groupsAPI';
import useTranslation from '../../../../../hooks/useTranslation';
import { TableActionModel } from '@core_components/Table';
import { DEFAULT_TABLE_LIMIT } from '../../../../../constants/global';

// components
import GroupsTable from '../../../../../components/Tables/TableTemplates/ORAC/GroupsTable';
import AddGroupToRole from './AddGroupToRole';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Divider, message, Modal, Row } from 'antd';

interface IProps {
  roleId: string;
  access?: { canEditGroup?: boolean };
}

const GroupsTab = ({ roleId, access }: IProps) => {
  const { t } = useTranslation(['orac', 'common']);

  const [page, setPage] = useState(1);
  const [updateGroupsTrigger, updateGroups] = useState({});

  const { response, loading } = useFetch(
    () =>
      rolesAPI.fetchGroupsByRoleId(roleId, {
        page,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [page, updateGroupsTrigger],
  );

  const tableActions = useMemo(() => {
    const result: TableActionModel[] = [
      {
        key: 'remove',
        name: t('remove', { ns: 'common' }),
        hidden: !access?.canEditGroup,
        danger: true,
      },
    ];

    return result;
  }, [access]);

  const handleGroupDeleteClick = (groupId: string) => {
    Modal.confirm({
      title: t('roles.view.remove_role_from_group_confirmation'),
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: () => removeRoleFromGroup(groupId),
    });
  };

  const removeRoleFromGroup = async (groupId: string) => {
    await groupsAPI.removeRolesFromGroup(groupId, [roleId]);
    message.success(t('users.view.success_remove_user_from_group'));
    updateGroups({});
  };

  const handleActions = (key: string, value: string) => {
    switch (key) {
      case 'remove':
        handleGroupDeleteClick(value);
        break;
    }
  };

  return (
    <>
      {access?.canEditGroup && (
        <Row justify="end">
          <Col>
            <AddGroupToRole roleId={roleId} onAdd={() => updateGroups({})} />
          </Col>
        </Row>
      )}

      <Divider />

      <GroupsTable
        data={response?.data || []}
        total={response?.total || 0}
        current={page}
        loading={loading}
        onPaginationChange={setPage}
        actions={tableActions}
        onActionsClick={handleActions}
      />
    </>
  );
};

export default GroupsTab;
