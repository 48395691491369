import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { debounce } from 'lodash';
import { keyRelationshipTemplatesAPI } from '../../../../api/crm/keyRelationship/keyRelationshipTemplatesAPI';

// constants
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  DEFAULT_TABLE_LIMIT,
} from '../../../../constants/global';

// components
import FilterBar from './FilterBar';
import EntityTypeCategoriesTable from '../../../../components/Tables/TableTemplates/CRM/EntityTypeCategoriesTable';
import EditEntityTypeCategoryModal from '../EditEntityTypeCategoryModal';
import { Divider } from 'antd';

interface IProps {
  updateTableTrigger: any;
}

const EntityTypeCategories = ({ updateTableTrigger }: IProps) => {
  const [current, setCurrentPage] = useState(1);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [localUpdateTableTrigger, updateTable] = useState({});
  const [entityTypeCategoriesFilter, setEntityTypeCategoriesFilter] = useState({
    searchQuery: '',
    filterByValue: '',
  });

  const { response, loading } = useFetch(
    () =>
      keyRelationshipTemplatesAPI.fetchKeyRelationshipTemplates({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        search: entityTypeCategoriesFilter.searchQuery,
        isVisible: !entityTypeCategoriesFilter.filterByValue
          ? undefined
          : entityTypeCategoriesFilter.filterByValue === 'yes',
      }),
    [
      current,
      entityTypeCategoriesFilter,
      updateTableTrigger,
      localUpdateTableTrigger,
    ],
  );

  const handleActionsClick = (key: string, value: any) => {
    switch (key) {
      case 'edit':
        setSelectedItemId(value);
        break;
    }
  };

  const handleModalClose = (shouldUpdate?: boolean) => {
    shouldUpdate && updateTable({});
    setSelectedItemId(null);
  };

  const handleFilterChange = (key: string, value: string) => {
    if (current > 1) {
      setCurrentPage(1);
    }

    switch (key) {
      case 'search':
        setEntityTypeCategoriesFilter((prevState) => ({
          ...prevState,
          searchQuery: value,
        }));
        break;
      case 'filter-change':
        setEntityTypeCategoriesFilter((prevState) => ({
          ...prevState,
          filterByValue: value,
        }));
        break;
    }
  };

  return (
    <>
      <FilterBar
        filterValue={entityTypeCategoriesFilter.filterByValue}
        handleChange={debounce(handleFilterChange, AUTOCOMPLETE_DEBOUNCE_DELAY)}
      />

      <Divider />

      <EntityTypeCategoriesTable
        data={response?.data || []}
        total={response?.total || 0}
        current={current}
        loading={loading}
        onActionsClick={handleActionsClick}
        onPaginationChange={setCurrentPage}
      />

      <EditEntityTypeCategoryModal
        isVisible={!!selectedItemId}
        categoryId={selectedItemId}
        closeCallback={handleModalClose}
      />
    </>
  );
};

export default EntityTypeCategories;
