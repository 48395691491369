import React, { useEffect } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_CLIENT_GROUPS_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import ClientGroupsTable from '../../modules/CRM/ClientGroups/ClientGroupsTable';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const ClientGroups = ({ setHeaderOptions }: IProps) => {
  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation
          link={GENESIS_CLIENT_GROUPS_DOCUMENTATION_LINK}
        />
      ),
    });
  }, []);

  return <ClientGroupsTable />;
};

export default ClientGroups;
