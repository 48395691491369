import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../../hooks/useTranslation';

// components
import { Button, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface IProps {
  onRetryClick: () => void;
}

const ErrorWithRetryButton = ({ onRetryClick }: IProps) => {
  const { t } = useTranslation('profile');

  return (
    <>
      <StyledInfoIcon />
      <StyledParagraph>
        {t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_modal.register_key_step.error_in_registration',
        )}
      </StyledParagraph>
      <Button size="large" type="primary" onClick={onRetryClick}>
        {t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_modal.register_key_step.retry_button',
        )}
      </Button>
    </>
  );
};

const StyledInfoIcon = styled(ExclamationCircleOutlined)`
  font-size: 100px;
  color: ${({ theme }) => theme.whiteColor};
  margin-bottom: ${({ theme }) => theme.marginXl};
`;

const StyledParagraph = styled(Typography.Paragraph)`
  text-align: center;
`;

export default ErrorWithRetryButton;
