import React, { memo, useCallback, useMemo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { relationshipTemplatesAPI } from 'api/crm/relationship/relationshipTemplatesAPI';
import { IRelationshipTemplateModel } from 'typings/crm/relationship-template';
import { AdditionalFieldType, OnboardType } from 'enums/crm/crm';

// components
import RelationshipTemplatesJustNameTable, {
  ActionKeys,
} from 'components/Tables/TableTemplates/Compliance/RelationshipTemplatesJustNameTable';
import { SearchInput } from 'components/Fields/SearchInput';

const AvailableRelationshipTemplates = memo(() => {
  const { t } = useTranslation('common');
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const { response, loading } = useFetch(
    () =>
      relationshipTemplatesAPI.fetchRelationshipTemplates({
        page: currentPage,
        search: searchQuery,
        limit: DEFAULT_TABLE_LIMIT,
        childType:
          values.visibleFor == OnboardType.Contact
            ? ['any', 'contact'].join(',')
            : undefined,
      }),
    [currentPage, searchQuery, values.visibleFor],
  );

  const filteredData = useMemo(() => {
    if (!response || !response.data.length) {
      return [];
    }

    if (!values.relationshipTemplates.length) {
      return response.data;
    }

    const selectedRelationshipTemplates = values.relationshipTemplates.map(
      (e) => e.id,
    );
    return response.data.filter(
      (e) => !selectedRelationshipTemplates.includes(e._id),
    );
  }, [response, values.relationshipTemplates]);

  const handleActionsClick = useCallback(
    (key: ActionKeys, record: IRelationshipTemplateModel) => {
      if (key === 'view') {
        const relationshipTemplatesCopy = values.relationshipTemplates.slice();

        const ownershipAdditionalFields = record.additionalFields
          .filter((e) => e.type === AdditionalFieldType.Percentage)
          .map((e) => ({ id: e._id as string, name: e.name, type: e.type }));

        relationshipTemplatesCopy.push({
          id: record._id,
          name: `${record.parentName} / ${record.childName}`,
          required: false,
          controller: false,
          isOwnership: false,
          ownershipAdditionalField: null,
          minAmount: 0,
          minPercentage: 0,
          ownershipAdditionalFields,
        });

        setFieldValue('relationshipTemplates', relationshipTemplatesCopy);
      }
    },
    [values],
  );

  const handleSearch = useCallback((query: string) => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }

    setSearchQuery(query);
  }, []);

  return (
    <>
      <StyledSearchInput
        onSearch={handleSearch}
        size="large"
        placeholder={t('enter_name')}
      />
      <RelationshipTemplatesJustNameTable
        data={filteredData}
        total={response?.total || 0}
        loading={loading}
        current={currentPage}
        onActionsClick={handleActionsClick}
        onPaginationChange={setCurrentPage}
      />
    </>
  );
});

const StyledSearchInput = styled(SearchInput)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default AvailableRelationshipTemplates;
