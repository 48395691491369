import React from 'react';

// helpers
import { documentsAPI } from '../../../../../../api/documents/documentsAPI';
import { FormFieldPropsModel } from '@core_components/FormField';

// components
import Autocomplete, {
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';

type IProps = FormFieldPropsModel;

const DocumentTagAutocomplete = (props: IProps) => {
  const fetchTags = async (searchQuery: string) => {
    const { data } = await documentsAPI.fetchTags(searchQuery);
    return data.map<AutocompleteOption>((tag) => {
      return {
        id: tag.name,
        content: tag.name,
        model: tag,
      };
    });
  };

  return <Autocomplete {...props} fetchData={fetchTags} />;
};

export default DocumentTagAutocomplete;
