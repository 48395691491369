import React from 'react';

// components
import { Typography } from 'antd';
import { ISelectRangeOption } from '../../Forms/FormComponents/SelectInputs/SelectRangeInput';

interface IProps {
  range?: ISelectRangeOption;
}

const RangeLabel = ({ range }: IProps) => {
  if (!range) {
    return <Typography.Text>-</Typography.Text>;
  }

  if (typeof range.min !== 'number' || range.max === range.min) {
    return (
      <Typography.Text>
        {range.max?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}
      </Typography.Text>
    );
  }

  if (typeof range.max !== 'number') {
    return (
      <Typography.Text>
        {range.min?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}
        +
      </Typography.Text>
    );
  }

  return (
    <Typography.Text>
      {range.min?.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}
      -
      {range.max?.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}
    </Typography.Text>
  );
};

export default RangeLabel;
