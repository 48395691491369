import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../../../hooks/useTranslation';
import { PermissionType } from '../../../../../../../../../../typings/ORAC/permissions';
import { PermissionsHelpers } from '@helpers/permissions';
import { SelectedActionModel } from '../../../..';
import { StyledComponentProps } from '../../../../../../../../../../typings/common';

// components
import Icon from '../../../../../../../../../Icons/Icon';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import CheckIcon from '../../../../../../../../../../resources/images/icons/checkmark-green.png';
import RejectIcon from '../../../../../../../../../../resources/images/icons/rejected-icon.png';

interface IProps
  extends StyledComponentProps,
    RequiredPropsForTableModel<SelectedActionModel> {
  showForbiddenPermissions?: boolean;
  onlyForbiddenPermissions?: boolean;
}

const SelectedPermissionsMatrix = memo(
  ({
    className,
    showForbiddenPermissions,
    onlyForbiddenPermissions,
    ...rest
  }: IProps) => {
    const { t } = useTranslation('orac');

    const renderPermissionStatus = (
      record: SelectedActionModel,
      permissionType: PermissionType,
    ) => {
      const icon = permissionType === 'forbidden' ? RejectIcon : CheckIcon;
      const { id, objectId, action, permissions } = record;
      const formattedPermissions = Object.keys(permissions).map(
        (permissionId) => permissions[permissionId],
      );
      const permission = PermissionsHelpers.findPermissionInAction(
        { id, objectId, action, permissions: formattedPermissions },
        permissionType,
      );

      return !!permission && <Icon icon={icon} width="15px" height="15px" />;
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          key: 'name',
          title: t('objects.view.action_name'),
          render: (record: SelectedActionModel) => record.action,
        },

        {
          hidden: onlyForbiddenPermissions,
          align: 'center',
          width: '100px',
          key: 'single',
          title: t('objects.view.single_permission'),
          render: (record: SelectedActionModel) =>
            renderPermissionStatus(record, 'single'),
        },
        {
          hidden: onlyForbiddenPermissions,
          align: 'center',
          width: '100px',
          key: 'multiple',
          title: t('objects.view.multiple_permission'),
          render: (record: SelectedActionModel) =>
            renderPermissionStatus(record, 'multiple'),
        },

        {
          hidden: !showForbiddenPermissions && !onlyForbiddenPermissions,
          align: 'center',
          width: '100px',
          key: 'forbidden',
          title: t('objects.view.forbidden_permission'),
          render: (record: SelectedActionModel) =>
            renderPermissionStatus(record, 'forbidden'),
        },
      ];

      return result;
    }, [rest.data, onlyForbiddenPermissions, showForbiddenPermissions]);

    return (
      <Table
        {...rest}
        columns={columns}
        className={`disable-table-row-hover ${className}`}
      />
    );
  },
);

export default SelectedPermissionsMatrix;
