import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../constants/global';
import { NotificationDetailsModel } from '../../../../typings/ORAC/notifications';
import {
  NotificationMethods,
  NotificationStatuses,
} from '../../../../enums/admin/orac';

// components
import StatusLabel from '../../../../components/Typography/StatusLabel';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

interface IProps {
  notification: NotificationDetailsModel;
  notificationType: NotificationMethods;
}

const STATUS_MAP = {
  info: [NotificationStatuses.New],
  warning: [],
  error: [NotificationStatuses.Error],
  success: [NotificationStatuses.Processed],
};

const NotificationDetails = ({ notification, notificationType }: IProps) => {
  const { t } = useTranslation('orac');

  const renderType = () => {
    return notificationType[0].toLocaleUpperCase() + notificationType.slice(1);
  };

  const descriptionsData = useMemo<DescriptionItem[]>(() => {
    const result = [
      { label: t('notifications.view.id'), description: notification.id },
      { label: t('notifications.view.code'), description: notification.code },
      { label: t('notifications.view.type'), description: renderType() },
      {
        label: t('notifications.view.date'),
        description: DateHelpers.formatDateToString(
          notification.createdAt,
          DEFAULT_DATE_TIME_FORMAT,
        ),
      },
      {
        label: t('notifications.view.status'),
        description: (
          <StatusLabel
            statusMap={STATUS_MAP}
            status={notification.status}
            text={t(
              `notifications.notification_statuses.${notification.status}`,
            )}
          />
        ),
      },
      {
        label: t('notifications.view.retry_count'),
        description: notification.retryCount,
      },
      {
        hidden: !notification.errorMessage,
        label: t('notifications.view.error_message'),
        description: notification.errorMessage,
      },
    ];

    return result;
  }, [notification]);

  return (
    <StyledDescriptionSection
      data={descriptionsData}
      bordered={false}
      background="transparent"
    />
  );
};

const StyledDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-container {
    border-bottom: 1px solid ${({ theme }) => theme.tableBorderColor};
    padding-bottom: ${({ theme }) => theme.paddingXs};
  }

  .ant-descriptions-item-label {
    display: flex;
    align-items: end;
  }

  &.ant-descriptions-middle .ant-descriptions-row > td {
    padding-bottom: ${({ theme }) => theme.paddingXs};
  }
`;
export default NotificationDetails;
