import React from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import UsersByEmailAutocomplete from '../../../../../../Forms/FormComponents/Autocompletes/ORAC/UsersByEmailAutocomplete';

const InnerForm = () => {
  const { t } = useTranslation('orac');

  return (
    <FormField
      name="userId"
      label={t('groups.view.user')}
      component={UsersByEmailAutocomplete}
      additionalProps={{ allowClear: true }}
    />
  );
};

export default InnerForm;
