import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { IApprovalMatrixRecord } from 'typings/finance/transaction';
import { ClientApprovalEntryModel } from 'apiAdapters/approval/clientAccountManagementRulesAdapterAPI';
import { ClientApprovalWorkflowChangeTypes } from 'typings/approval/approvalWorkflow';

// components
import ApprovalMatrix from 'components/Tables/ApprovalsMatrix';
import ApprovalEntryStatus from 'components/Additional/Statuses/ApprovalEntryStatus';
import { Table } from 'antd';

interface IProps {
  approvalEntry: ClientApprovalEntryModel;
}

const EntryDetails = ({ approvalEntry }: IProps) => {
  const { t } = useTranslation('crm');

  const tableColumns = useMemo(() => {
    return [
      { key: 'title', dataIndex: 'title', width: 200 },
      { key: 'value', dataIndex: 'value' },
    ];
  }, []);

  const tableData = useMemo(() => {
    const { entry } = approvalEntry;
    const formattedApprovalState: IApprovalMatrixRecord[][] =
      approvalEntry.approvalProgressStateSets.map((e) =>
        e.progressStateItems.map((progressStateItem) => ({
          groupNameTittle: progressStateItem.groupNameTittle,
          members: progressStateItem.members.map((member) => ({
            userId: member.userId,
            userFullName: member.userFullName,
            approvalDate: member.approvalDate,
            approvalAction: member.approvalAction,
            approvalReason: member.approvalReason,
            approvalStatusName: member.approvalActionName,
          })),
        })),
      );

    return [
      {
        title: t(
          'client_group.administration_rules.pending_approvals.approval_details_modal.details_section.date',
        ),
        value: DateHelpers.formatDateToString(entry.createdAt),
      },

      {
        title: t(
          'client_group.administration_rules.pending_approvals.approval_details_modal.details_section.entry',
        ),
        value: t(
          `client_group.administration_rules.pending_approvals.approval_entry_types.${entry.type}`,
        ),
      },

      {
        title: t(
          'client_group.administration_rules.pending_approvals.approval_details_modal.details_section.type',
        ),
        value: t(
          `client_group.administration_rules.types.${String(ClientApprovalWorkflowChangeTypes[entry.changeType])}`,
        ),
      },

      {
        title: t(
          'client_group.administration_rules.pending_approvals.approval_details_modal.details_section.status',
        ),
        value: <ApprovalEntryStatus statusId={approvalEntry.entry.status} />,
      },
      {
        title: t(
          'client_group.administration_rules.pending_approvals.approval_details_modal.details_section.approval_matrix',
        ),
        value: <ApprovalMatrix transactionApprovals={formattedApprovalState} />,
      },
    ];
  }, [approvalEntry]);

  return (
    <Table
      bordered
      size="middle"
      showHeader={false}
      pagination={false}
      scroll={{ x: 'max-content' }}
      columns={tableColumns}
      dataSource={tableData}
    />
  );
};

export default EntryDetails;
