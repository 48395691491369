import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { IClientGroupResponse } from 'api/crm/clientGroup/clientGroupsAPI';
import { ClientStatus, DocumentAssociationTypes } from 'enums/crm/crm';

// components
import Text from '@core_components/Text';
import InternalDocuments from 'modules/CRM/ClientGroups/Approval/InternalDocuments';
import ClientGroupReview from './ClientGroupReview';

interface IProps {
  clientGroupData: IClientGroupResponse;
  updateClientGroup: () => void;
}

const ApprovalTab = ({ clientGroupData, updateClientGroup }: IProps) => {
  const { t } = useTranslation('crm');

  if (clientGroupData.clientGroup.status === ClientStatus.Rejected) {
    return <Text>{t('client_group.approval.already_rejected')}</Text>;
  }

  return (
    <>
      <ClientGroupReview
        clientGroup={clientGroupData}
        updateClientGroupCallback={updateClientGroup}
      />
      <InternalDocuments
        associationId={clientGroupData.clientGroup._id}
        associationType={DocumentAssociationTypes.ClientGroup}
      />
    </>
  );
};

export default ApprovalTab;
