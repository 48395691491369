import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle<{ theme?: any }>`
 html {
    font-size: 14px;
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: #c6cccf;
    }
  }

  body {
    background-color: ${({ theme }) => theme.backgroundColor};
    letter-spacing: .8px;
  }
`;
