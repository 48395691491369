import React, { ChangeEvent, useCallback } from 'react';

// helpers
import { debounce } from 'lodash';
import { InputProps } from 'antd/lib/input/Input';
import { AUTOCOMPLETE_DEBOUNCE_DELAY } from 'constants/global';

// components
import { Input } from 'antd';

interface IProps extends InputProps {
  onSearch: (value: string) => void;
}

export const SearchInput = ({ onSearch, ...inputProps }: IProps) => {
  const debouncedFetchData = useCallback(
    debounce(onSearch, AUTOCOMPLETE_DEBOUNCE_DELAY),
    [],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedFetchData(e.target.value);
  };

  return <Input {...inputProps} onChange={handleChange} />;
};
