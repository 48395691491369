import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { enumToMap } from '@helpers/utils';
import { darkTheme } from '@resources/theme/styled';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { BankingBankAccount } from '../../../../../typings/banking/bankAccounts';
import { BankAccountStatuses } from 'enums/banking/accounts';

// components
import IconSVG from '@core_components/IconSVG';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import BankAccountStatus from 'components/Additional/Statuses/BankAccountStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Popover, Typography } from 'antd';
import { ReactComponent as TimeIcon } from '../../../../../resources/icons/remix-icons/time-line.svg';

export type ActionKeys = 'bank-account-click';

interface IProps extends RequiredPropsForTableModel<BankingBankAccount> {
  filters: Record<string, any>;
  onActionsClick: (keys: ActionKeys, record: BankingBankAccount) => void;
  onFilterChange?: (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => void;
}

const BankAccountsTable = memo(
  ({ onActionsClick, filters, onFilterChange, ...rest }: IProps) => {
    const { t } = useTranslation(['banking', 'finance']);

    const renderAccountNumber = (record: BankingBankAccount) => {
      if (
        [
          BankAccountStatuses.Active,
          BankAccountStatuses.Closed,
          BankAccountStatuses.Processing,
          BankAccountStatuses.BankRejected,
        ].includes(record.status)
      ) {
        return (
          <Typography.Link
            onClick={() => onActionsClick('bank-account-click', record)}
          >
            {record.accountNumber}
          </Typography.Link>
        );
      } else if (record.status == BankAccountStatuses.PendingApproval) {
        return (
          <DivAlignCenter>
            {record.accountNumber}
            <Popover
              placement="bottom"
              content={t('banking.bank_accounts.account_in_review_warning')}
              overlayStyle={{ zIndex: 99999 }}
            >
              <StyledIconSVG
                color={darkTheme.warningColor}
                component={TimeIcon}
              />
            </Popover>
          </DivAlignCenter>
        );
      } else {
        return record.accountNumber;
      }
    };

    const columns = useMemo<TableColumnModel[]>(() => {
      return [
        {
          key: 'account_number',
          title: t('banking.bank_accounts.table.account_number'),
          render: renderAccountNumber,
        },

        {
          key: 'account_name',
          title: t('banking.bank_accounts.table.account_name'),
          render: (record: BankingBankAccount) => record.accountName,
        },

        {
          key: 'currency',
          title: t('banking.bank_accounts.table.currency'),
          render: (record: BankingBankAccount) => record.currencyIsoCode,
        },

        {
          key: 'type',
          title: t('banking.bank_accounts.table.type'),
          render: (record: BankingBankAccount) =>
            t(`banking.bank_accounts.types.${record.accountType}`),
        },

        {
          key: 'status',
          title: t('banking.bank_accounts.table.status'),
          render: (record: BankingBankAccount) => (
            <BankAccountStatus status={record.status} />
          ),
          filteredValue: filters.status,
          filterSearch: true,
          filters: Array.from(enumToMap(BankAccountStatuses)).map((e) => ({
            text: t(`bank_account_statuses.${e[1]}`, { ns: 'finance' }),
            value: e[1],
          })),
        },

        {
          key: 'debit_frozen',
          title: t('banking.bank_accounts.table.debit_frozen'),
          render: (record: BankingBankAccount) =>
            record.isFrozen
              ? t('accounts.table.debit_frozen.frozen')
              : t('accounts.table.debit_frozen.unfrozen'),
        },

        {
          key: 'balance',
          title: t('banking.bank_accounts.table.balance'),
          render: (record: BankingBankAccount) =>
            AccountsHelpers.formatAmountToLocaleString(record.balance),
        },

        {
          key: 'balance_home',
          title: t('banking.bank_accounts.table.balance_home'),
          render: (record: BankingBankAccount) =>
            AccountsHelpers.formatAmountToLocaleString(record.balanceHome),
        },
        {
          key: 'average_monthly_balance',
          title: t('banking.bank_accounts.table.average_monthly_balance'),
          render: (record: BankingBankAccount) =>
            AccountsHelpers.formatAmountToLocaleString(
              record.statistics.avgEodBalanceSinceCreation,
            ),
        },
        {
          key: 'monthly_transactions',
          title: t('banking.bank_accounts.table.monthly_transactions'),
          render: (record: BankingBankAccount) =>
            record.statistics.avgEomTxCountSinceCreation,
        },
      ];
    }, [filters, onActionsClick]);

    return (
      <Table {...rest} onFilterChange={onFilterChange} columns={columns} />
    );
  },
);

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXXs};
  cursor: pointer;
`;

export default BankAccountsTable;
