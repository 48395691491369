import { DateHelpers } from '@helpers/date';
import { Transaction } from '../../typings/banking/transactions';
import { AccountsHelpers } from '../../helpers/finance/accounts';
import { FetchResponseModel } from '../../typings/common';
import { IJournalDocumentModel } from '../../typings/finance/journal';
import { TransferTemplateStatuses } from 'enums/transfers/templates';
import { FormValuesModel as TransactionFormValuesModel } from '../../components/Modals/TemplateModalDialogs/Banking/TransactionModalDialog';
import { FormValuesModel as IncomingTransactionFormValues } from '../../components/Forms/TemplateForms/Banking/IncomingTransactionForm';
import { FormValuesModel as OutgoingTransactionFormValues } from '../../components/Forms/TemplateForms/Banking/OutgoingTransactionForm';
import {
  BankOperationsCodes,
  DetailsOfChargesCodes,
  TransactionReviewStatuses,
  TransactionTypes,
} from '../../enums/banking/transactions';

export type ShortTransactionFromQuery = {
  id: number;
  orderingCustomer: string;
  beneficiary: string;
  amount: number;
  currencyIsoCode: string;
  currency: number;
  statusId: TransactionReviewStatuses;
  statusName: string;
  type: TransactionTypes;
  postingDate: number;
  valueDate: number;
  creationDate: number;
  transactionNumber: string;
};

type TransactionBankDataFromQuery = {
  codeType?: string;
  code: string;

  name: string;
  address1: string;
  city: string;
  postCode: string;
  countryCode: string;
  district: string;
};

export type FullTransactionFromQuery = {
  id?: number;
  statusId?: TransactionReviewStatuses;
  statusName?: string;
  postingDate?: number;
  creationDate?: number;
  transactionType?: TransactionTypes;
  currencyIsoCode?: string;
  incomingCurrencyIsoCode?: string;
  templateStatus?: TransferTemplateStatuses;
  templateInvalidityReason?: string;
  templateClientGroupId?: number;

  senderReference: string;
  bankOperationsCode: BankOperationsCodes;
  valueDate: number;
  incomingCurrency: number;
  settledAmount: number;
  currency: number;
  instructedAmount: number;
  detailsOfCharges: DetailsOfChargesCodes;

  orderingCustomerAccountNumber: string;
  orderingCustomerAccountName: string;
  orderingCustomerCountry: string;
  orderingCustomerCity: string;
  orderingCustomerDistrict: string;
  orderingCustomerPostCode: string;
  orderingCustomerAddress1: string;

  sendingInstitution: TransactionBankDataFromQuery;
  orderingInstitution: TransactionBankDataFromQuery;
  senderCorrespondent: TransactionBankDataFromQuery;
  receiverCorrespondent: TransactionBankDataFromQuery;
  thirdReimbursement: TransactionBankDataFromQuery;
  intermediaryInstitution: TransactionBankDataFromQuery;
  accountWithInstitution: TransactionBankDataFromQuery;

  beneficiaryCustomerAccountNumber: string;
  beneficiaryCustomerAccountName: string;
  beneficiaryCustomerCountry: string;
  beneficiaryCustomerCity: string;
  beneficiaryCustomerDistrict: string;
  beneficiaryCustomerPostCode: string;
  beneficiaryCustomerAddress1: string;

  repair: boolean;
  repairedBeneficiary: string;
  fee: boolean;

  remittanceInformationLine1: string;
  remittanceInformationLine2: string;
  remittanceInformationLine3: string;
  remittanceInformationLine4: string;

  senderToReceiverLine1: string;
  senderToReceiverLine2: string;
  senderToReceiverLine3: string;
  senderToReceiverLine4: string;
  senderToReceiverLine5: string;
  senderToReceiverLine6: string;

  documents: IJournalDocumentModel[];

  // These fields will be empty for incoming transactions, only for outgoing
  purpose?: string;
  requestedBy?: string;
  requestedByName?: string;
  templateId?: number;
  templateName?: string;
  transactionNumber?: string;
};

export type PendingTransaction = {
  isSubmit: boolean;
  transactionData: FullTransactionFromQuery;
};

const bankingTransactionsAPIAdapter = {
  fetchTransactions: function (
    data: FetchResponseModel<ShortTransactionFromQuery>,
  ): FetchResponseModel<Transaction> {
    return {
      total: data.total,
      data: data.data.map((e) => ({
        id: e.id,
        type: e.type,
        creationDate: e.creationDate,
        valueDate: e.valueDate,
        postingDate: e.postingDate,
        orderingCustomer: e.orderingCustomer,
        beneficiary: e.beneficiary,
        reviewStatus: e.statusId,
        transactionNumber: e.transactionNumber,
        amount: `${e.currencyIsoCode} ${AccountsHelpers.convertAmountBigIntToLocaleString(+e.amount)}`,
      })),
    };
  },

  formatIncomingTransactionFromFormToQueryData: (
    transaction: IncomingTransactionFormValues,
  ): FullTransactionFromQuery => {
    return {
      senderReference: transaction.general.senderReference,
      bankOperationsCode: transaction.general
        .bankOperationsCode as BankOperationsCodes,
      valueDate: transaction.general.valueDate?.unix() as number,
      incomingCurrency: transaction.other.incomingTransactionCurrency as any,
      settledAmount:
        AccountsHelpers.convertAmountFromIntToBigInt(
          transaction.general.settledAmount || 0,
        ) || 0,
      currency: transaction.general.currency as any,
      instructedAmount:
        AccountsHelpers.convertAmountFromIntToBigInt(
          transaction.other.instructedAmount || 0,
        ) || 0,
      detailsOfCharges: transaction.general
        .detailsOfCharges as DetailsOfChargesCodes,

      documents: transaction.general.documents.map((e) => ({
        dmsId: e.id,
        name: e.name,
        size: String(e.file?.size) as string,
        type: e.file?.contentType as string,
        linkToDownload: e.file?.id as string,
      })),

      orderingCustomerAccountNumber: transaction.orderingCustomer.accountNumber,
      orderingCustomerAccountName: transaction.orderingCustomer.accountName,
      orderingCustomerCountry: transaction.orderingCustomer.country as string,
      orderingCustomerCity: transaction.orderingCustomer.city,
      orderingCustomerDistrict: transaction.orderingCustomer.district,
      orderingCustomerPostCode: transaction.orderingCustomer.postalCode,
      orderingCustomerAddress1: transaction.orderingCustomer.address1,

      sendingInstitution: {
        code: transaction.sendingInstitution.code,
        name: transaction.sendingInstitution.name,
        address1: transaction.sendingInstitution.address,
        city: transaction.sendingInstitution.city,
        postCode: transaction.sendingInstitution.postalCode,
        countryCode: transaction.sendingInstitution.country as string,
        district: transaction.sendingInstitution.district,
      },

      orderingInstitution: {
        code: transaction.orderingInstitution.code,
        name: transaction.orderingInstitution.name,
        address1: transaction.orderingInstitution.address,
        city: transaction.orderingInstitution.city,
        postCode: transaction.orderingInstitution.postalCode,
        countryCode: transaction.orderingInstitution.country as string,
        district: transaction.orderingInstitution.district,
      },

      senderCorrespondent: {
        code: transaction.senderCorrespondent.code,
        name: transaction.senderCorrespondent.name,
        address1: transaction.senderCorrespondent.address,
        city: transaction.senderCorrespondent.city,
        postCode: transaction.senderCorrespondent.postalCode,
        countryCode: transaction.senderCorrespondent.country as string,
        district: transaction.senderCorrespondent.district,
      },

      receiverCorrespondent: {
        code: transaction.receiverCorrespondent.code,
        name: transaction.receiverCorrespondent.name,
        address1: transaction.receiverCorrespondent.address,
        city: transaction.receiverCorrespondent.city,
        postCode: transaction.receiverCorrespondent.postalCode,
        countryCode: transaction.receiverCorrespondent.country as string,
        district: transaction.receiverCorrespondent.district,
      },

      thirdReimbursement: {
        code: transaction.thirdReimbursement.code,
        name: transaction.thirdReimbursement.name,
        address1: transaction.thirdReimbursement.address,
        city: transaction.thirdReimbursement.city,
        postCode: transaction.thirdReimbursement.postalCode,
        countryCode: transaction.thirdReimbursement.country as string,
        district: transaction.thirdReimbursement.district,
      },

      intermediaryInstitution: {
        code: transaction.intermediaryInstitution.code,
        name: transaction.intermediaryInstitution.name,
        address1: transaction.intermediaryInstitution.address,
        city: transaction.intermediaryInstitution.city,
        postCode: transaction.intermediaryInstitution.postalCode,
        countryCode: transaction.intermediaryInstitution.country as string,
        district: transaction.intermediaryInstitution.district,
      },

      accountWithInstitution: {
        code: transaction.accountWithInstitution.code,
        name: transaction.accountWithInstitution.name,
        address1: transaction.accountWithInstitution.address,
        city: transaction.accountWithInstitution.city,
        postCode: transaction.accountWithInstitution.postalCode,
        countryCode: transaction.accountWithInstitution.country as string,
        district: transaction.accountWithInstitution.district,
      },

      beneficiaryCustomerAccountNumber:
        transaction.beneficiaryCustomer.accountNumber,
      beneficiaryCustomerAccountName:
        transaction.beneficiaryCustomer.accountName,
      beneficiaryCustomerCountry: transaction.beneficiaryCustomer
        .country as string,
      beneficiaryCustomerCity: transaction.beneficiaryCustomer.city,
      beneficiaryCustomerDistrict: transaction.beneficiaryCustomer.district,
      beneficiaryCustomerPostCode: transaction.beneficiaryCustomer.postalCode,
      beneficiaryCustomerAddress1: transaction.beneficiaryCustomer.address1,
      repair: transaction.beneficiaryCustomer.repair,
      repairedBeneficiary: transaction.beneficiaryCustomer
        .repairedBeneficiary as string,
      fee: transaction.beneficiaryCustomer.fee,

      remittanceInformationLine1: transaction.remittanceInformation.line1,
      remittanceInformationLine2: transaction.remittanceInformation.line2,
      remittanceInformationLine3: transaction.remittanceInformation.line3,
      remittanceInformationLine4: transaction.remittanceInformation.line4,

      senderToReceiverLine1: transaction.senderToReceiver.line1,
      senderToReceiverLine2: transaction.senderToReceiver.line2,
      senderToReceiverLine3: transaction.senderToReceiver.line3,
      senderToReceiverLine4: transaction.senderToReceiver.line4,
      senderToReceiverLine5: transaction.senderToReceiver.line5,
      senderToReceiverLine6: transaction.senderToReceiver.line6,
    };
  },

  formatIncomingTransactionFromQueryToFormData: function (
    incomingTransaction: FullTransactionFromQuery,
    formatAmount = true,
  ): IncomingTransactionFormValues {
    return {
      general: {
        senderReference: incomingTransaction.senderReference,
        bankOperationsCode: incomingTransaction.bankOperationsCode || null,
        valueDate: incomingTransaction.valueDate
          ? DateHelpers.formatTimestampToMomentDate(
              incomingTransaction.valueDate,
            )
          : null,
        currency: incomingTransaction.currency || null,
        currencyInitialOption: incomingTransaction.currency
          ? {
              id: incomingTransaction.currency,
              content: incomingTransaction.currencyIsoCode,
            }
          : undefined,
        settledAmount: incomingTransaction.settledAmount
          ? (formatAmount
              ? AccountsHelpers.convertAmountFromBigIntToInt(
                  incomingTransaction.settledAmount,
                )
              : incomingTransaction.settledAmount) || null
          : null,
        detailsOfCharges: incomingTransaction.detailsOfCharges || null,
        documents: incomingTransaction.documents
          ? incomingTransaction.documents.map((e) => ({
              id: e.dmsId,
              name: e.name,
              fileId: e.linkToDownload,
              file: {
                contentType: e.type,
                size: e.size,
              } as any,
            }))
          : [],
      },

      other: {
        incomingTransactionCurrency:
          incomingTransaction.incomingCurrency || null,
        incomingTransactionCurrencyInitialOption:
          incomingTransaction.incomingCurrency
            ? {
                id: incomingTransaction.incomingCurrency,
                content: incomingTransaction.incomingCurrencyIsoCode,
              }
            : undefined,
        instructedAmount: incomingTransaction.instructedAmount
          ? (formatAmount
              ? AccountsHelpers.convertAmountFromBigIntToInt(
                  incomingTransaction.instructedAmount,
                )
              : incomingTransaction.instructedAmount) || null
          : null,
      },

      orderingCustomer: {
        accountNumber: incomingTransaction.orderingCustomerAccountNumber,
        accountName: incomingTransaction.orderingCustomerAccountName,
        country: incomingTransaction.orderingCustomerCountry,
        city: incomingTransaction.orderingCustomerCity,
        district: incomingTransaction.orderingCustomerDistrict,
        postalCode: incomingTransaction.orderingCustomerPostCode,
        address1: incomingTransaction.orderingCustomerAddress1,
      },

      sendingInstitution: {
        code: incomingTransaction.sendingInstitution.code,
        name: incomingTransaction.sendingInstitution.name,
        country: incomingTransaction.sendingInstitution.countryCode,
        address: incomingTransaction.sendingInstitution.address1,
        city: incomingTransaction.sendingInstitution.city,
        district: incomingTransaction.sendingInstitution.district,
        postalCode: incomingTransaction.sendingInstitution.postCode,
      },

      orderingInstitution: {
        code: incomingTransaction.orderingInstitution.code,
        name: incomingTransaction.orderingInstitution.name,
        country: incomingTransaction.orderingInstitution.countryCode,
        address: incomingTransaction.orderingInstitution.address1,
        city: incomingTransaction.orderingInstitution.city,
        district: incomingTransaction.orderingInstitution.district,
        postalCode: incomingTransaction.orderingInstitution.postCode,
      },

      senderCorrespondent: {
        code: incomingTransaction.senderCorrespondent.code,
        name: incomingTransaction.senderCorrespondent.name,
        country: incomingTransaction.senderCorrespondent.countryCode,
        address: incomingTransaction.senderCorrespondent.address1,
        city: incomingTransaction.senderCorrespondent.city,
        district: incomingTransaction.senderCorrespondent.district,
        postalCode: incomingTransaction.senderCorrespondent.postCode,
      },

      receiverCorrespondent: {
        code: incomingTransaction.receiverCorrespondent.code,
        name: incomingTransaction.receiverCorrespondent.name,
        country: incomingTransaction.receiverCorrespondent.countryCode,
        address: incomingTransaction.receiverCorrespondent.address1,
        city: incomingTransaction.receiverCorrespondent.city,
        district: incomingTransaction.receiverCorrespondent.district,
        postalCode: incomingTransaction.receiverCorrespondent.postCode,
      },

      thirdReimbursement: {
        code: incomingTransaction.thirdReimbursement.code,
        name: incomingTransaction.thirdReimbursement.name,
        country: incomingTransaction.thirdReimbursement.countryCode,
        address: incomingTransaction.thirdReimbursement.address1,
        city: incomingTransaction.thirdReimbursement.city,
        district: incomingTransaction.thirdReimbursement.district,
        postalCode: incomingTransaction.thirdReimbursement.postCode,
      },

      intermediaryInstitution: {
        code: incomingTransaction.intermediaryInstitution.code,
        name: incomingTransaction.intermediaryInstitution.name,
        country: incomingTransaction.intermediaryInstitution.countryCode,
        address: incomingTransaction.intermediaryInstitution.address1,
        city: incomingTransaction.intermediaryInstitution.city,
        district: incomingTransaction.intermediaryInstitution.district,
        postalCode: incomingTransaction.intermediaryInstitution.postCode,
      },

      accountWithInstitution: {
        code: incomingTransaction.accountWithInstitution.code,
        name: incomingTransaction.accountWithInstitution.name,
        country: incomingTransaction.accountWithInstitution.countryCode,
        address: incomingTransaction.accountWithInstitution.address1,
        city: incomingTransaction.accountWithInstitution.city,
        district: incomingTransaction.accountWithInstitution.district,
        postalCode: incomingTransaction.accountWithInstitution.postCode,
      },

      beneficiaryCustomer: {
        accountNumber: incomingTransaction.beneficiaryCustomerAccountNumber,
        accountName: incomingTransaction.beneficiaryCustomerAccountName,
        country: incomingTransaction.beneficiaryCustomerCountry,
        city: incomingTransaction.beneficiaryCustomerCity,
        district: incomingTransaction.beneficiaryCustomerDistrict,
        postalCode: incomingTransaction.beneficiaryCustomerPostCode,
        address1: incomingTransaction.beneficiaryCustomerAddress1,
        repair: incomingTransaction.repair || false,
        repairedBeneficiary: incomingTransaction.repairedBeneficiary || null,
        fee: !!incomingTransaction.fee,
      },

      remittanceInformation: {
        line1: incomingTransaction.remittanceInformationLine1,
        line2: incomingTransaction.remittanceInformationLine2,
        line3: incomingTransaction.remittanceInformationLine3,
        line4: incomingTransaction.remittanceInformationLine4,
      },

      senderToReceiver: {
        line1: incomingTransaction.senderToReceiverLine1,
        line2: incomingTransaction.senderToReceiverLine2,
        line3: incomingTransaction.senderToReceiverLine3,
        line4: incomingTransaction.senderToReceiverLine4,
        line5: incomingTransaction.senderToReceiverLine5,
        line6: incomingTransaction.senderToReceiverLine6,
      },
    };
  },

  formatOutgoingTransactionFromQueryToFormData: function (
    incomingTransaction: FullTransactionFromQuery,
  ): OutgoingTransactionFormValues {
    return {
      tenetOS: {
        transactionNumber: incomingTransaction.transactionNumber || '',
        requestedBy: incomingTransaction.requestedBy
          ? {
              id: incomingTransaction.requestedBy,
              name: incomingTransaction.requestedByName || '',
            }
          : null,
        purpose: incomingTransaction.purpose || '',
        template: incomingTransaction.templateId
          ? {
              id: incomingTransaction.templateId,
              name: incomingTransaction.templateName || '',
              status:
                incomingTransaction.templateStatus as TransferTemplateStatuses,
              invalidityReason:
                incomingTransaction.templateInvalidityReason as string,
              clientGroupId:
                incomingTransaction.templateClientGroupId as number,
            }
          : null,
        documents: incomingTransaction.documents,
      },
      general: {
        senderReference: incomingTransaction.senderReference,
        bankOperationsCode: incomingTransaction.bankOperationsCode || null,
        valueDate: incomingTransaction.valueDate
          ? DateHelpers.formatTimestampToMomentDate(
              incomingTransaction.valueDate,
            )
          : null,
        currency: incomingTransaction.currency || null,
        currencyInitialOption: incomingTransaction.currency
          ? {
              id: incomingTransaction.currency,
              content: incomingTransaction.currencyIsoCode,
            }
          : undefined,
        settledAmount: incomingTransaction.settledAmount
          ? AccountsHelpers.convertAmountFromBigIntToInt(
              incomingTransaction.settledAmount,
            ) || null
          : null,
        detailsOfCharges: incomingTransaction.detailsOfCharges || null,
      },

      other: {
        incomingTransactionCurrency:
          incomingTransaction.incomingCurrency || null,
        incomingTransactionCurrencyInitialOption:
          incomingTransaction.incomingCurrency
            ? {
                id: incomingTransaction.incomingCurrency,
                content: incomingTransaction.incomingCurrencyIsoCode,
              }
            : undefined,
        instructedAmount: incomingTransaction.instructedAmount
          ? AccountsHelpers.convertAmountFromBigIntToInt(
              incomingTransaction.instructedAmount,
            ) || null
          : null,
      },

      orderingCustomer: {
        accountNumber: incomingTransaction.orderingCustomerAccountNumber,
        accountName: incomingTransaction.orderingCustomerAccountName,
        country: incomingTransaction.orderingCustomerCountry,
        city: incomingTransaction.orderingCustomerCity,
        district: incomingTransaction.orderingCustomerDistrict,
        postalCode: incomingTransaction.orderingCustomerPostCode,
        address1: incomingTransaction.orderingCustomerAddress1,
      },

      sendingInstitution: {
        codeType: incomingTransaction.sendingInstitution.codeType as string,
        code: incomingTransaction.sendingInstitution.code,
        name: incomingTransaction.sendingInstitution.name,
        country: incomingTransaction.sendingInstitution.countryCode,
        address: incomingTransaction.sendingInstitution.address1,
        city: incomingTransaction.sendingInstitution.city,
        district: incomingTransaction.sendingInstitution.district,
        postalCode: incomingTransaction.sendingInstitution.postCode,
      },

      orderingInstitution: {
        codeType: incomingTransaction.orderingInstitution.codeType as string,
        code: incomingTransaction.orderingInstitution.code,
        name: incomingTransaction.orderingInstitution.name,
        country: incomingTransaction.orderingInstitution.countryCode,
        address: incomingTransaction.orderingInstitution.address1,
        city: incomingTransaction.orderingInstitution.city,
        district: incomingTransaction.orderingInstitution.district,
        postalCode: incomingTransaction.orderingInstitution.postCode,
      },

      senderCorrespondent: {
        codeType: incomingTransaction.senderCorrespondent.codeType as string,
        code: incomingTransaction.senderCorrespondent.code,
        name: incomingTransaction.senderCorrespondent.name,
        country: incomingTransaction.senderCorrespondent.countryCode,
        address: incomingTransaction.senderCorrespondent.address1,
        city: incomingTransaction.senderCorrespondent.city,
        district: incomingTransaction.senderCorrespondent.district,
        postalCode: incomingTransaction.senderCorrespondent.postCode,
      },

      receiverCorrespondent: {
        codeType: incomingTransaction.receiverCorrespondent.codeType as string,
        code: incomingTransaction.receiverCorrespondent.code,
        name: incomingTransaction.receiverCorrespondent.name,
        country: incomingTransaction.receiverCorrespondent.countryCode,
        address: incomingTransaction.receiverCorrespondent.address1,
        city: incomingTransaction.receiverCorrespondent.city,
        district: incomingTransaction.receiverCorrespondent.district,
        postalCode: incomingTransaction.receiverCorrespondent.postCode,
      },

      thirdReimbursement: {
        codeType: incomingTransaction.thirdReimbursement.codeType as string,
        code: incomingTransaction.thirdReimbursement.code,
        name: incomingTransaction.thirdReimbursement.name,
        country: incomingTransaction.thirdReimbursement.countryCode,
        address: incomingTransaction.thirdReimbursement.address1,
        city: incomingTransaction.thirdReimbursement.city,
        district: incomingTransaction.thirdReimbursement.district,
        postalCode: incomingTransaction.thirdReimbursement.postCode,
      },

      intermediaryInstitution: {
        codeType: incomingTransaction.intermediaryInstitution
          .codeType as string,
        code: incomingTransaction.intermediaryInstitution.code,
        name: incomingTransaction.intermediaryInstitution.name,
        country: incomingTransaction.intermediaryInstitution.countryCode,
        address: incomingTransaction.intermediaryInstitution.address1,
        city: incomingTransaction.intermediaryInstitution.city,
        district: incomingTransaction.intermediaryInstitution.district,
        postalCode: incomingTransaction.intermediaryInstitution.postCode,
      },

      accountWithInstitution: {
        codeType: incomingTransaction.accountWithInstitution.codeType as string,
        code: incomingTransaction.accountWithInstitution.code,
        name: incomingTransaction.accountWithInstitution.name,
        country: incomingTransaction.accountWithInstitution.countryCode,
        address: incomingTransaction.accountWithInstitution.address1,
        city: incomingTransaction.accountWithInstitution.city,
        district: incomingTransaction.accountWithInstitution.district,
        postalCode: incomingTransaction.accountWithInstitution.postCode,
      },

      beneficiaryCustomer: {
        accountNumber: incomingTransaction.beneficiaryCustomerAccountNumber,
        accountName: incomingTransaction.beneficiaryCustomerAccountName,
        country: incomingTransaction.beneficiaryCustomerCountry,
        city: incomingTransaction.beneficiaryCustomerCity,
        district: incomingTransaction.beneficiaryCustomerDistrict,
        postalCode: incomingTransaction.beneficiaryCustomerPostCode,
        address1: incomingTransaction.beneficiaryCustomerAddress1,
      },

      remittanceInformation: {
        line1: incomingTransaction.remittanceInformationLine1,
        line2: incomingTransaction.remittanceInformationLine2,
        line3: incomingTransaction.remittanceInformationLine3,
        line4: incomingTransaction.remittanceInformationLine4,
      },

      senderToReceiver: {
        line1: incomingTransaction.senderToReceiverLine1,
        line2: incomingTransaction.senderToReceiverLine2,
        line3: incomingTransaction.senderToReceiverLine3,
        line4: incomingTransaction.senderToReceiverLine4,
        line5: incomingTransaction.senderToReceiverLine5,
        line6: incomingTransaction.senderToReceiverLine6,
      },
    };
  },

  addTransaction: function (
    formValues: TransactionFormValuesModel,
  ): PendingTransaction {
    const transactionData = this.formatIncomingTransactionFromFormToQueryData(
      formValues.incomingTransaction as IncomingTransactionFormValues,
    );

    return {
      isSubmit: formValues.submitAction === 'save' ? false : true,
      transactionData,
    };
  },
};

export { bankingTransactionsAPIAdapter };
