import React from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { ReviewProcessStatuses } from '../../../../enums/compliance/reviewConfiguration';
import { useReviewProcessContext } from '../../../../hooks/domainHooks/useReviewProcess';

// components
import { Button, Col, Modal, Popover, Row, Typography } from 'antd';

const ActionsSection = () => {
  const { t } = useTranslation(['compliance', 'common']);
  const { reviewProgress, reviewProcessStatus, submitProcessForReview } =
    useReviewProcessContext();

  const renderPopoverContent = (status: ReviewProcessStatuses) => {
    switch (status) {
      case ReviewProcessStatuses.New:
      case ReviewProcessStatuses.InProgress:
        return (
          <Typography.Text>
            {t('transactions.not_all_alerts_are_reviewed')}
          </Typography.Text>
        );
      default:
        return undefined;
    }
  };
  const onSubmitForReviewClick = () => {
    Modal.confirm({
      content: t('transactions.submit_for_review_desc'),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        await submitProcessForReview();
      },
    });
  };

  return (
    <Row justify="end">
      <Col>
        {reviewProgress < 100 ? (
          <Popover
            content={renderPopoverContent(reviewProcessStatus)}
            placement="left"
          >
            <Button disabled>{t('transactions.submit_for_review')}</Button>
          </Popover>
        ) : (
          (reviewProcessStatus == ReviewProcessStatuses.InProgress ||
            reviewProcessStatus == ReviewProcessStatuses.New) && (
            <Button onClick={onSubmitForReviewClick}>
              {t('transactions.submit_for_review')}
            </Button>
          )
        )}
      </Col>
    </Row>
  );
};

export default ActionsSection;
