import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { financialTypeAPI } from '../../../../api/finance/financialType/financialTypeAPI';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';

// components
import { default as TemplateFinancialTypesTable } from '../../../../components/Tables/TableTemplates/Finance/FinancialTypesTable';

const FinancialTypesTable = () => {
  const [current, setCurrent] = useState(1);

  const { response, loading } = useFetch(
    () =>
      financialTypeAPI.fetchFinancialTypes({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [current],
  );

  return (
    <TemplateFinancialTypesTable
      total={response?.total || 0}
      current={current}
      data={response?.data || []}
      loading={loading}
      onPaginationChange={setCurrent}
    />
  );
};

export default FinancialTypesTable;
