import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { IndustryCodeModel } from 'typings/crm/entity';
import { FetchResponseModel } from 'typings/common';

export interface FetchIndustryCodesParams {
  limit: number;
  search: string;
}

const industryCodesAPI = {
  fetchIndustryCodes: (params: FetchIndustryCodesParams) => {
    return APIService.get<FetchResponseModel<IndustryCodeModel>>(
      `${APIConfig.crmApi}/industry-code`,
      { params },
    ).then(({ data }) => data);
  },
};

export { industryCodesAPI };
