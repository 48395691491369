import React from 'react';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Profiders
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import AppInsightsProvider from './services/azure-app-insights';
import ErrorHandlerProvider from './services/error-handler';

// Redux
import StateHelper from './redux/StoreHelper';
import { configureStore } from './redux/store/configureStore';

// Theme styles
import './resources/fonts/fonts.scss';
import './resources/theme/antd/antd.less';
import './resources/theme/antd/antd.scss';
import { darkTheme } from './resources/theme/styled';
import { GlobalStyle } from './resources/theme/styled/global';

// Localization file
import './i18n';

// components
import App from './App';
import LoadingPage from './pages/LoadingPage';

const store = configureStore({});
StateHelper.setStore(store);

ReactDOM.render(
  <React.Suspense fallback={<LoadingPage />}>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={darkTheme}>
          <AppInsightsProvider>
            <ErrorHandlerProvider>
              <App />
              <GlobalStyle />
            </ErrorHandlerProvider>
          </AppInsightsProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.Suspense>,
  document.getElementById('root'),
);
