import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '@hooks/useFetch';
import usersAPI from 'api/orac/usersAPI';
import useTranslation from '@hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { IContactModel } from 'typings/crm/contact';

// components
import Link from '@common_components/Texts/Link';
import ContactStatus from 'components/Additional/Statuses/ContactStatus';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import DescriptionSection from '@core_components/DescriptionSection';
import VerifiedStatusText from 'components/Typography/VerifiedStatusText';

interface IProps {
  contact: IContactModel;
}

const Details = memo(({ contact }: IProps) => {
  const { t } = useTranslation(['orac', 'crm']);
  const history = useHistory();
  const hasAssociationWithAuthUser = !!contact?.associations?.auth;

  const { response, loading } = useFetch(
    () =>
      hasAssociationWithAuthUser
        ? usersAPI.getUserById(contact?.associations.auth)
        : null,
    [contact?.associations?.auth, hasAssociationWithAuthUser],
  );

  const data = useMemo(() => {
    const result = [
      {
        label: t('users.view.name'),
        description: response ? (
          <Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.ORAC_User_Details,
                search: `?id=${response._id}`,
              })
            }
          >
            {response.name}
          </Link>
        ) : (
          '-'
        ),
      },

      {
        label: t('users.view.status'),
        description: <ContactStatus status={contact.status} />,
      },

      {
        label: t('users.view.username'),
        description: response ? response.username : '-',
      },
    ];

    const primaryPhoneNumber = contact.phoneNumbers?.find((f) => f.isPrimary);
    if (primaryPhoneNumber) {
      result.push({
        label: t('users.view.phone'),
        description: (
          <>
            {primaryPhoneNumber.number}{' '}
            <StyledVerifiedStatus
              isVerified={primaryPhoneNumber.verification?.isVerified}
            />
          </>
        ),
      });
    }

    const primaryEmail = contact.emails?.find((f) => f.isPrimary);
    if (primaryEmail) {
      result.push({
        label: t('users.view.email'),
        description: (
          <>
            {primaryEmail.address}
            <StyledVerifiedStatus isVerified={response?.emailConfirmed} />
          </>
        ),
      });
    }

    return result;
  }, [contact, response, history]);

  return (
    <LoadingWrapper loading={loading}>
      {hasAssociationWithAuthUser ? (
        <DescriptionSection
          data={data}
          size="small"
          bordered={false}
          background="none"
        />
      ) : (
        t('contacts.new.no_association_with_online_user', { ns: 'crm' })
      )}
    </LoadingWrapper>
  );
});

const StyledVerifiedStatus = styled(VerifiedStatusText)`
  margin-left: ${({ theme }) => theme.marginXs};
`;
export default Details;
