import React, { memo, useMemo } from 'react';

// helpers
import moment from 'moment';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { IJournalDocumentModel } from '../../../../../../../../typings/finance/journal';

// components
import Form from '@core_components/Form';
import DetailsRow from 'modules/ApprovalManagement/ApprovalWorkflowTabs/ApprovalWorkflowDetailsModal/DetailsRow';
import JournalForm, {
  JournalModel,
} from '../../../../../../../../components/Modals/TemplateModalDialogs/Finance/AddViewJournalModal/InnerForm/JournalForm';
import { Typography } from 'antd';

export type NewJournalDetailsDataFromQuery = {
  isReversal: boolean;
  journalValueDate: number;
  originalJournalId: number;
  journalEntries: {
    accountBalance: number;
    accountName: string;
    accountNumber: string;
    accountTypeId: number;
    accountTypeName: string;
    debitHomeAmount: number;
    creditHomeAmount: number;
    areDocumentsVisible: boolean;
    creditAmount: number;
    debitAmount: number;
    isResident: boolean;
    currency: string;
    currencyRateAmount: number;
    currencyRateId: number;
    description: string;
    relatedParty: string;
    relatedPartyName: string;
    relatedPartyType: string;
    documents: IJournalDocumentModel[];
  }[];
};

type FormValuesModel = {
  isReversalFrom?: number;
  journal: JournalModel;
};

interface IProps {
  data: NewJournalDetailsDataFromQuery;
  creationDate: string;
}

const NewJournalDetails = memo(({ data, creationDate }: IProps) => {
  const { t } = useTranslation('finance');
  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!data) {
      return null;
    }

    const entries = data.journalEntries.map((e, i) => ({
      id: i,
      currencyRate: AccountsHelpers.convertAmountFromBigIntToInt(
        e.currencyRateAmount,
      ),
      accountNumber: e.accountNumber,
      accountName: e.accountName,
      description: e.description,
      journalPostingDate: moment(creationDate).unix(),
      relatedPartyId: e.relatedParty ? String(e.relatedParty) : undefined,
      relatedPartyInitialOption: e.relatedParty
        ? {
            id: e.relatedParty,
            content: e.relatedPartyName,
          }
        : undefined,
      isResident: e.isResident,
      debitHome: e.debitHomeAmount
        ? AccountsHelpers.convertAmountFromBigIntToInt(e.debitHomeAmount)
        : 0,
      creditHome: e.creditHomeAmount
        ? AccountsHelpers.convertAmountFromBigIntToInt(e.creditHomeAmount)
        : 0,
      creditAmount: e.creditAmount
        ? AccountsHelpers.convertAmountFromBigIntToInt(e.creditAmount)
        : undefined,
      debitAmount: e.debitAmount
        ? AccountsHelpers.convertAmountFromBigIntToInt(e.debitAmount)
        : undefined,
      account: {
        isoCode: e.currency,
        accountNumber: e.accountNumber,
        financialTypeName: e.accountTypeName,
      },
      documents: e.documents.map((e) => ({
        id: e.dmsId,
        name: e.name,
        fileId: e.linkToDownload,
        file: null,
      })),
      isVisibleForClient: e.areDocumentsVisible,
    }));

    return {
      isReversalFrom: data.originalJournalId || undefined,
      journal: { entries },
    };
  }, [data, creationDate]);

  return (
    <Form<FormValuesModel>
      disabled
      onSubmit={console.log}
      initialValues={initialFormValues}
      renderForm={({ values }) => (
        <>
          {!!values.isReversalFrom && (
            <Typography.Paragraph>{`${t('journals.update.reversal_journal_of')}: ${values.isReversalFrom}`}</Typography.Paragraph>
          )}
          <DetailsRow
            title={t('journals.value_date')}
            value={DateHelpers.formatTimestampToString(data.journalValueDate)}
          />
          <JournalForm
            isEdit
            fieldName="journal.entries"
            isEditActive={false}
          />
        </>
      )}
    />
  );
});

export default NewJournalDetails;
