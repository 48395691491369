import * as yup from 'yup';
import { WEBSITE_REG } from '../../../constants/regularExpressions';
import { AdditionalFieldType } from '../../../enums/crm/crm';
import { AddressValidationSchema } from 'validations/crm/contacts';
import {
  NULLABLE_ARRAY_VALIDATION_RULE,
  NULLABLE_BOOLEAN_VALIDATION_RULE,
  NULLABLE_DATE_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
} from '../../common';

export const DeleteEntityValidationSchema = yup.object().shape({
  reason: STRING_VALIDATION_RULE.min(5, {
    key: 'form:validation_messages.min_characters',
    values: { number: 5 },
  })
    .max(1000, {
      key: 'form:validation_messages.max_characters',
      values: { number: 1000 },
    })
    .required('form:validation_messages.required'),
});

const AdditionalFieldValidationSchema = yup.object().shape({
  value: yup
    .mixed()
    .when(['type'], (values, schema): any => {
      switch (values[0]) {
        case AdditionalFieldType.Percentage:
          return yup
            .number()
            .typeError('Only numbers are allowed')
            .min(0, 'Value should be more than 0')
            .max(100, 'Value should be less than 100');

        case AdditionalFieldType.String:
          return STRING_VALIDATION_RULE;

        default:
          return schema;
      }
    })
    .when('options.onboardingRequired', {
      is: true,
      then: (schema) => schema.required('This is required field'),
    }),
});

export const RelationshipFormValidationSchema = yup.object().shape({
  id: NULLABLE_STRING_VALIDATION_RULE,

  parent: yup.object().shape({
    id: NULLABLE_STRING_VALIDATION_RULE,
    type: NULLABLE_STRING_VALIDATION_RULE,
  }),

  child: yup.object().shape({
    id: NULLABLE_STRING_VALIDATION_RULE.required('This is required field'),
    type: NULLABLE_STRING_VALIDATION_RULE,
    label: STRING_VALIDATION_RULE,
  }),

  relationshipTemplate: yup.object().shape({
    id: NULLABLE_STRING_VALIDATION_RULE.required('This is required field'),
    childRelationshipLabel: STRING_VALIDATION_RULE,
  }),

  additionalFields: yup.array().of(AdditionalFieldValidationSchema),
});

export const AddNewRelationshipFormValidationSchema = yup.object().shape({
  entryId: STRING_VALIDATION_RULE,
  entryName: STRING_VALIDATION_RULE,
  entryType: STRING_VALIDATION_RULE,

  relationships: yup
    .array()
    .of(RelationshipFormValidationSchema)
    .min(1, 'form:validation_messages.required'),
});

export const EditRelationshipFormValidationSchema = yup.object().shape({
  delete: yup.boolean().nullable(),
  entryId: STRING_VALIDATION_RULE,
  entryName: STRING_VALIDATION_RULE,
  entryType: STRING_VALIDATION_RULE,
  relationship: yup.object().when('delete', {
    is: true,
    then: (schema) => schema,
    otherwise: () => RelationshipFormValidationSchema,
  }),
});

export const EntityDetailsFormValidationSchema = yup.object().shape({
  legalName: STRING_VALIDATION_RULE.max(50, {
    key: 'form:validation_messages.max_characters_with_label',
    values: { label: 'entity.new.entityName', number: 50 },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'entity.new.entityName' },
  }),
  tradeName: STRING_VALIDATION_RULE.max(50, {
    key: 'form:validation_messages.max_characters_with_label',
    values: { label: 'entity.new.tradeName', number: 50 },
  }),
  registrationNumber: STRING_VALIDATION_RULE.max(50, {
    key: 'form:validation_messages.max_characters_with_label',
    values: { label: 'entity.new.registration_number', number: 50 },
  }),
  dateOfIncorporation: NULLABLE_DATE_VALIDATION_RULE,
  jurisdiction: NULLABLE_STRING_VALIDATION_RULE,
  keyRelationshipTemplate: NULLABLE_STRING_VALIDATION_RULE,
  natureOfBusiness: STRING_VALIDATION_RULE.max(500, {
    key: 'form:validation_messages.max_characters_with_label',
    values: { label: 'entity.new.natureOfBusiness', number: 500 },
  }),
  numberOfEmployees: NULLABLE_STRING_VALIDATION_RULE,
  numberOfOffices: NULLABLE_STRING_VALIDATION_RULE,
  industryCodes: NULLABLE_ARRAY_VALIDATION_RULE.of(STRING_VALIDATION_RULE),
  isRegulated: NULLABLE_BOOLEAN_VALIDATION_RULE,
  regulationCountry: NULLABLE_ARRAY_VALIDATION_RULE.of(
    STRING_VALIDATION_RULE,
  ).when('isRegulated', {
    is: true,
    then: (schema) =>
      schema
        .required({
          key: 'form:validation_messages.required_with_label',
          values: { label: 'entity.new.regulationCountry' },
        })
        .min(1, {
          key: 'form:validation_messages.required_with_label',
          values: { label: 'entity.new.regulationCountry' },
        }),
  }),
  canIssueBearerShares: NULLABLE_BOOLEAN_VALIDATION_RULE,
  isVendor: NULLABLE_BOOLEAN_VALIDATION_RULE,
  primaryAddressId: NULLABLE_STRING_VALIDATION_RULE,
  socialMedia: yup.object().shape({
    websiteLink: STRING_VALIDATION_RULE.matches(
      WEBSITE_REG,
      'Wrong website link',
    ),
    facebookLink: STRING_VALIDATION_RULE.matches(
      WEBSITE_REG,
      'Wrong facebook link',
    ),
    linkedInLink: STRING_VALIDATION_RULE.matches(
      WEBSITE_REG,
      'Wrong linked in link',
    ),
    twitterLink: STRING_VALIDATION_RULE.matches(
      WEBSITE_REG,
      'Wrong twitter  link',
    ),
  }),
  addresses: yup.array().of(AddressValidationSchema),
});
