import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../hooks/useFetch';
import useTranslation from '../../../../../hooks/useTranslation';
import { debounce } from 'lodash';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { accountsAPI } from '../../../../../api/finance/account/accountsAPI';
import { IAccountModel } from '../../../../../typings/finance/account';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { AUTOCOMPLETE_DEBOUNCE_DELAY } from '../../../../../constants/global';

// components
import { Select, Spin } from 'antd';

interface IProps {
  size?: SizeType;
  value: string;
  onChange: (value: string) => void;
  isFullAccountNumber?: boolean;
}

const AccountsSelect = ({
  size = 'large',
  value,
  onChange,
  isFullAccountNumber,
}: IProps) => {
  const { t } = useTranslation('finance');

  const [searchQuery, setSearchQuery] = useState('');

  const { response: getAccountResponse, loading: getAccountLoader } = useFetch(
    () =>
      !searchQuery
        ? null
        : accountsAPI.searchAccounts(
            searchQuery,
            undefined,
            isFullAccountNumber,
          ),
    [searchQuery],
  );

  const renderAccounts = (accounts: IAccountModel[]) => {
    if (!accounts.length) return null;

    return (
      <>
        {accounts.map(
          ({ accountName, accountNumber, currencyCode, balance }) => (
            <Select.Option key={accountNumber} value={accountNumber}>
              {accountNumber} - {accountName} {currencyCode}{' '}
              {AccountsHelpers.convertAmountBigIntToLocaleString(balance)}
            </Select.Option>
          ),
        )}
      </>
    );
  };

  const handleSearch = (query: string) =>
    query.length >= 4 ? setSearchQuery(query) : setSearchQuery('');

  const handleSelectChange = (value: any) => onChange(value);

  return (
    <StyledSelect
      allowClear
      placeholder={t('journals.table.select_account')}
      onSearch={debounce(handleSearch, AUTOCOMPLETE_DEBOUNCE_DELAY)}
      onChange={handleSelectChange}
      size={size}
      showSearch
      showArrow
      filterOption={false}
      loading={getAccountLoader}
      value={value || undefined}
      notFoundContent={
        getAccountLoader && (
          <LoaderWrapper>
            <Spin size="small" />
          </LoaderWrapper>
        )
      }
    >
      {getAccountResponse && renderAccounts(getAccountResponse.data)}
    </StyledSelect>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;
export default AccountsSelect;
