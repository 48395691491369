import React from 'react';

// helpers
import { ApprovalGroupModel } from 'typings/approval/templates';
import { FormValuesModel as FinanceApprovalRulesFormValuesModel } from 'components/Forms/TemplateForms/Approval/FinanceApprovalRulesForm';

// components
import InnerForm from './InnerForm';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export interface FormValuesModel extends FinanceApprovalRulesFormValuesModel {
  accounts: string[];
  approvalGroups: ApprovalGroupModel[];
}

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
}

const OnboardingTransactionRulesModal = ({
  title,
  isVisible,
  closeCallback,
  initialValues,
  onSubmit,
  disabled,
}: IProps) => {
  return (
    <Form
      disabled={disabled}
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      renderForm={
        <ModalDialog
          width="80%"
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          hideFooterButtons={disabled}
        >
          <LoadingWrapper loading={!initialValues}>
            {initialValues ? <InnerForm /> : null}
          </LoadingWrapper>
        </ModalDialog>
      }
    />
  );
};

export default OnboardingTransactionRulesModal;
