import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { approvalGroupsAPI } from 'api/approval/approvalManagement/approvalGroupsAPI';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';

// components
import ApprovalGroupsModal, {
  ApprovalGroupFormValuesModel,
} from 'components/Modals/TemplateModalDialogs/ApprovalManagement/ApprovalGroupsModal';

interface IProps extends RequiredPropsForModalDialogModel {
  clientGroupId: number;
}

const AddApprovalGroupModal = ({
  isVisible,
  clientGroupId,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('crm');

  const initialFormValues: ApprovalGroupFormValuesModel | null = useMemo(() => {
    if (!clientGroupId) return null;

    return {
      name: '',
      description: '',
      participants: [],
      initialParticipantOptions: [],
      clientGroupId,
      approvalWorkflowType: 'transactions',
    };
  }, [clientGroupId]);

  const handleSubmit = async (values: ApprovalGroupFormValuesModel) => {
    await approvalGroupsAPI.createApprovalGroup(
      {
        name: values.name,
        description: values.description as string,
        participants: values.participants,
      },
      clientGroupId,
    );
    message.success(
      t(
        'client_group.approval.client_approval_groups.add_approval_group_modal.success_submit_message',
      ),
    );
  };

  return (
    <ApprovalGroupsModal
      title={t(
        'client_group.approval.client_approval_groups.add_approval_group_modal.title',
      )}
      onSubmit={handleSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
    />
  );
};

export default AddApprovalGroupModal;
