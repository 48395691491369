import React from 'react';

// helpers
import { IKeyRelationshipTemplate } from 'typings/crm/key-relationship-templates';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';
import { keyRelationshipTemplatesAPI } from '../../../../../../api/crm/keyRelationship/keyRelationshipTemplatesAPI';
import {
  AutocompleteOption,
  AutocompleteProps,
} from '@core_components/Autocomplete';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export interface EntityTypeCategoryFormAutocompleteProps
  extends AutocompleteProps<IKeyRelationshipTemplate> {
  isVisible?: boolean;
}

const EntityTypeCategoryFormAutocomplete = ({
  isVisible,
  ...rest
}: EntityTypeCategoryFormAutocompleteProps) => {
  const fetchDataCallback = async (searchQuery: string) => {
    const { data } =
      await keyRelationshipTemplatesAPI.fetchKeyRelationshipTemplates({
        page: 1,
        limit: AUTOCOMPLETE_RESULT_LIMIT,
        search: searchQuery.trim(),
        isVisible,
      });

    return data.map<AutocompleteOption<IKeyRelationshipTemplate>>(
      (template) => {
        return {
          id: template._id,
          label: template.name,
          model: template,
        };
      },
    );
  };

  return <FormAutocomplete {...rest} fetchData={fetchDataCallback} />;
};

export default EntityTypeCategoryFormAutocomplete;
