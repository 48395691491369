import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import { Button } from 'antd';
import AddUserToGroupModal from '../../../../../../components/Modals/TemplateModalDialogs/ORAC/Groups/AddUserToGroupModal';

interface IProps {
  groupId: string;
  onAdd: () => void;
}

const AddUserToGroup = ({ groupId, onAdd }: IProps) => {
  const { t } = useTranslation('orac');

  const [isVisible, setIsVisible] = useState(false);

  const handleModalClose = (wasAdded?: boolean) => {
    if (wasAdded) {
      onAdd();
    }

    setIsVisible(false);
  };

  return (
    <>
      <Button onClick={() => setIsVisible(true)}>
        {t('groups.view.add_user_to_group')}
      </Button>
      <AddUserToGroupModal
        isVisible={isVisible}
        closeCallback={handleModalClose}
        groupId={groupId}
      />
    </>
  );
};

export default AddUserToGroup;
