import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { NoteAssociation } from '../../../../../typings/note/note';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { ShortDocumentModel } from 'components/Forms/FormComponents/UploadDocumentField';
import { NewNoteValidationSchema } from '../../../../../validations/notes';

// components
import InnerForm from './InnerForm';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title?: string;
  loading?: boolean;
  hideNoteTypeField?: boolean;
}

export type FormValuesModel = {
  text: string;
  eventDate: any;
  type?: string;
  crmItems?: NoteAssociation[];
  documents?: ShortDocumentModel[];
  crmItemsInitialValue?: AutocompleteOption[];
};

const NoteModalDialog = ({
  title,
  isVisible,
  closeCallback,
  onSubmit,
  hideNoteTypeField,
  loading,
  initialValues,
  disabled,
}: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <Form
      disabled={disabled}
      enableReinitialize
      confirmExitWithoutSaving
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={NewNoteValidationSchema}
      renderForm={
        <ModalDialog
          width={800}
          title={title || t('entity.notes.addNote')}
          isVisible={isVisible}
          closeCallback={closeCallback}
          submitButtonProps={{ hidden: disabled }}
        >
          <LoadingWrapper loading={!!loading}>
            <InnerForm showNoteType={!hideNoteTypeField} />
          </LoadingWrapper>
        </ModalDialog>
      }
    />
  );
};

export default NoteModalDialog;
