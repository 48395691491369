import React, { useMemo } from 'react';

// helpers
import { DateHelpers } from '@helpers/date';
import { compareObjects } from '@helpers/utils';
import { contactsAdapter } from 'apiAdapters/crm/contact/contactsAdapter';
import { YYYYMMDD_TIME_FORMAT } from 'constants/global';
import { HighlightFieldStatuses } from '@core_components/FormField';
import { FetchContactWithChangeRequestResponse } from 'api/crm/changeRequests/changeRequestsAPI';

// components
import Form from '@core_components/Form';
import GeneralForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/CRM/ContactKYCForms/GeneralForm';

interface IProps {
  data: FetchContactWithChangeRequestResponse;
}

const GeneralTab = ({ data }: IProps) => {
  const initialValues = useMemo<FormValuesModel | null>(() => {
    let result: FormValuesModel | null = null;
    const { contact, clientChangeRequest } = data;

    if (
      clientChangeRequest?.type == 'contact-update-kyc' &&
      clientChangeRequest?.changes?.contactKYCChange?.general
    ) {
      const oldVersion =
        contactsAdapter.formatKYCContactGeneralDataFromQueryToFormFieldsModel(
          contact,
        );
      const newVersion =
        contactsAdapter.formatKYCContactGeneralDataFromQueryToFormFieldsModel(
          clientChangeRequest.changes?.contactKYCChange.general,
        );

      result = { ...newVersion };

      if (oldVersion.phoneNumbers.length > newVersion.phoneNumbers.length) {
        const deletedPhoneNumbers = oldVersion.phoneNumbers.slice(
          -(oldVersion.phoneNumbers.length - newVersion.phoneNumbers.length),
        );
        result.phoneNumbers.push(...deletedPhoneNumbers);
      }

      if (oldVersion.emails.length > newVersion.emails.length) {
        const deletedEmails = oldVersion.emails.slice(
          -(oldVersion.emails.length - newVersion.emails.length),
        );
        result.emails.push(...deletedEmails);
      }
    } else if (
      clientChangeRequest?.type == 'contact-get-kyc' &&
      clientChangeRequest?.changes?.contactKYCChange?.general
    ) {
      result =
        contactsAdapter.formatKYCContactGeneralDataFromQueryToFormFieldsModel(
          clientChangeRequest.changes?.contactKYCChange.general,
        );
    } else {
      result =
        contactsAdapter.formatKYCContactGeneralDataFromQueryToFormFieldsModel(
          contact,
        );
    }

    return result;
  }, [data]);

  const modifiedValues = useMemo<
    Record<string, HighlightFieldStatuses> | undefined
  >(() => {
    const { contact, clientChangeRequest } = data;

    // we need to highlight fields only when user has made some changes
    if (
      clientChangeRequest?.type == 'contact-update-kyc' &&
      clientChangeRequest?.changes?.contactKYCChange?.general
    ) {
      const oldVersion =
        contactsAdapter.formatKYCContactGeneralDataFromQueryToFormFieldsModel({
          firstName: contact?.firstName || '',
          middleName: contact?.middleName || '',
          lastName: contact?.lastName || '',
          gender: contact?.gender || null,
          dateOfBirth: contact.dateOfBirth || null,
          countryOfBirth: contact?.countryOfBirth || '',
          emails: contact.emails,
          phoneNumbers: contact.phoneNumbers,
          socialMedia: contact.socialMedia,
        });

      const newVersion =
        contactsAdapter.formatKYCContactGeneralDataFromQueryToFormFieldsModel(
          clientChangeRequest.changes?.contactKYCChange.general,
        );

      // we need to format dateOfBirth to YYYY-MM-DD format to compare it correctly
      return compareObjects(
        {
          ...oldVersion,
          dateOfBirth: oldVersion.dateOfBirth
            ? DateHelpers.formatDateToString(
                oldVersion.dateOfBirth,
                YYYYMMDD_TIME_FORMAT,
              )
            : null,
        },
        {
          ...newVersion,
          dateOfBirth: newVersion.dateOfBirth
            ? DateHelpers.formatDateToString(
                newVersion.dateOfBirth,
                YYYYMMDD_TIME_FORMAT,
              )
            : null,
        },
      );
    }

    return undefined;
  }, [data]);

  return (
    <Form
      disabled
      onSubmit={console.log}
      initialValues={initialValues}
      renderForm={GeneralForm}
      highlightFields={modifiedValues}
    />
  );
};

export default GeneralTab;
