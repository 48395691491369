import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../../../hooks/useFetch';
import useUserAccess from '../../../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { StateModel } from '../../../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { documentsAPI } from '../../../../../../../api/documents/documentsAPI';
import { IContactModel } from 'typings/crm/contact';
import { IDocumentBase } from '../../../../../../../typings/documents/documents';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../../constants/global';
import { DocumentAssociationTypes } from '../../../../../../../enums/crm/crm';

// components
import DocumentsTable from '../../../../../../../components/Tables/TableTemplates/Documents/DocumentsTable';
import DocumentDetailsModal from '../../../../../Documents/DocumentsDetailsModal';
import UploadDocumentsDialog from '../../../../../Documents/UploadDocumentsDialog';
import { Row, Col, Button } from 'antd';

interface IProps {
  contact: IContactModel;
}

const Documents = ({ contact }: IProps) => {
  const uploadStatus = useSelector<StateModel, boolean>(
    (state) => state.upload.isFinished,
  );
  const { t } = useTranslation('crm');
  const [hasUploadAccess] = useUserAccess([
    'CRM_Contacts_Details_DocumentsTab_UploadDocuments',
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateTableTrigger, updateTable] = useState({});
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null,
  );
  const [isUploadDialogVisible, setUploadDialogVisible] = useState(false);

  const { response, loading } = useFetch(
    () =>
      documentsAPI.fetchDocuments({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        associationItemId: contact._id,
      }),
    [contact, currentPage, updateTableTrigger, uploadStatus],
  );

  const handleActionsChange = (key: string, document: IDocumentBase) => {
    switch (key) {
      case 'details':
        setSelectedDocumentId(document._id);
        break;
    }
  };

  const handleDetailsModalClose = (wasModified?: boolean) => {
    if (wasModified) {
      updateTable({});
    }

    setSelectedDocumentId(null);
  };

  return (
    <Row gutter={[36, 16]}>
      {hasUploadAccess && (
        <Col span={24}>
          <ButtonWrapper>
            <Button
              type="primary"
              size="large"
              onClick={() => setUploadDialogVisible(!isUploadDialogVisible)}
            >
              {t('entity.documents.uploadDocuments')}
            </Button>
          </ButtonWrapper>
        </Col>
      )}

      <Col span={24}>
        <DocumentsTable
          showAssociation
          data={response?.data || []}
          total={response?.total || 0}
          loading={loading}
          current={currentPage}
          onPaginationChange={setCurrentPage}
          onActionsClick={handleActionsChange}
        />
      </Col>

      <DocumentDetailsModal
        isVisible={!!selectedDocumentId}
        closeCallback={handleDetailsModalClose}
        documentId={selectedDocumentId as string}
      />

      <UploadDocumentsDialog
        isVisible={isUploadDialogVisible}
        closeCallback={() => setUploadDialogVisible(false)}
        association={{
          type: DocumentAssociationTypes.Contact,
          id: contact._id,
        }}
      />
    </Row>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default Documents;
