import React from 'react';

// helpers
import styled from 'styled-components';
import { IFileItem } from '../../../../redux/actions/upload';
import { UploadStatus } from '../../../../constants/documents';
import { DocumentHelpers } from '@helpers/documents';

// components
import { Row, Col, Typography, Progress, Tag, Divider } from 'antd';

// File icons
import PDFIcon from 'resources/images/file-types-pack/pdf.png';
import TXTIcon from 'resources/images/file-types-pack/txt.png';
import DOCIcon from 'resources/images/file-types-pack/doc.png';
import XLSIcon from 'resources/images/file-types-pack/xls.png';

interface IProps {
  file: IFileItem;
}

const UploadFileProgress = ({ file }: IProps) => {
  function getDocumentIconByType(contentType: string) {
    switch (contentType) {
      case 'text/plain':
        return TXTIcon;
      case 'application/pdf':
        return PDFIcon;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
        return DOCIcon;
      case 'text/csv':
        return XLSIcon;
      default:
        return DOCIcon;
    }
  }

  const getProgressStatus = (
    status: UploadStatus,
  ): 'exception' | 'success' | 'normal' => {
    if (status === UploadStatus.FAILED) return 'exception';

    if (status === UploadStatus.DONE) return 'success';

    return 'normal';
  };

  const renderFileTags = (tags: string[]) => (
    <>
      {tags.map((e, i) => (
        <Tag key={i}>{e}</Tag>
      ))}
    </>
  );

  return (
    <Row>
      <Col span={2}>
        <FileIcon src={getDocumentIconByType(file.file.type)} />
      </Col>
      <Col span={22}>
        <TextParagraph strong ellipsis>
          {file.fileName}
        </TextParagraph>

        <TextParagraph type="secondary" ellipsis>
          {DocumentHelpers.formatFileSize(file.file.size, true)}
        </TextParagraph>

        <TextParagraph>{renderFileTags(file.tags)}</TextParagraph>

        <Progress
          percent={file.uploadPercentage}
          status={getProgressStatus(file.status)}
        />
      </Col>

      <Divider />
    </Row>
  );
};

const FileIcon = styled.img`
  width: 40px;
  height: auto;
`;

const TextParagraph = styled(Typography.Paragraph)`
  margin-bottom: 0 !important;
  max-width: 100%;
`;

export default UploadFileProgress;
