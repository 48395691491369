import React from 'react';

// components
import CorrespondentBanksTable from '../../modules/Banking/CorrespondentBanks/CorrespondentBanksTable';

const CorrespondentBanksPage = () => {
  return <CorrespondentBanksTable />;
};

export default CorrespondentBanksPage;
