import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { useFormikContext } from 'formik';
import { ComplianceRiskLevel } from 'enums/crm/crm';

// components
import FormField from '@core_components/FormField';
import InputTextArea from '@core_components/InputTextArea';
import RiskLevelSelect from 'components/Forms/FormComponents/SelectInputs/RiskLevelFormSelect';
import TrueFalseFormSelect from 'components/Forms/FormComponents/SelectInputs/TrueFalseFormSelect';
import DocumentsField, {
  DocumentFieldValueModel,
} from 'components/Forms/FormComponents/DocumentsField';
import { Col, Row } from 'antd';

export type FormValuesModel = {
  riskLevel: ComplianceRiskLevel;
  dueDiligence?: boolean;
  dueDiligenceDetails?: string;
  riskRatingMatrixDocument: DocumentFieldValueModel[];
};

const ComplianceForm = () => {
  const { t } = useTranslation('crm');
  const form = useFormikContext<FormValuesModel>();

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <FormField
            label={t('client_group.compliance.riskRatingMatrix')}
            name="riskRatingMatrixDocument"
            component={DocumentsField}
            additionalProps={{
              isMultiple: false,
            }}
          />
        </Col>
        <Col span={12}>
          <FormField
            label={t('client_group.compliance.riskLevel')}
            name="riskLevel"
            component={RiskLevelSelect}
          />
        </Col>
        <Col span={12}>
          <FormField
            label={t('client_group.compliance.dueDiligence')}
            name="dueDiligence"
            component={TrueFalseFormSelect}
          />
        </Col>
        {form.values.dueDiligence === true && (
          <Col span={12}>
            <FormField
              label={t('client_group.compliance.dueDiligenceDetails')}
              name="dueDiligenceDetails"
              component={InputTextArea}
            />
          </Col>
        )}
      </Row>
    </>
  );
};

export default ComplianceForm;
