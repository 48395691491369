import React, { useEffect, useMemo, useRef, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../hooks/useFetch';
import useQueryParam from '../../hooks/useQueryParam';
import useInfinityScroll from '../../hooks/useInfinityScroll';
import { StateModel } from '../../redux/reducers';
import { RoutePaths } from '../../routes/routes';
import { useSelector } from 'react-redux';
import { MessageModel } from '../../typings/messaging/messages';
import { messagingAPI } from '../../api/messaging/messagingAPI';
import { MessagesHelpers } from '@helpers/messages';
import { DocumentAssociationTypes } from '../../enums/crm/crm';
import { IDocumentAssociationInfo } from '../../modules/CRM/Documents/UploadDocumentsDialog';

// constants
import { MESSAGES_RESULT_LIMIT } from '../../constants/global';

// components
import ThreadList from '../../components/Tables/TableTemplates/Messages/ThreadList';
import ReplyInThread from '../../modules/Messaging/ReplyInThread';
import { Divider, Spin, Typography } from 'antd';

const MessagesThread = () => {
  const messageContainerRef = useRef<any>(null);

  const [isLoading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [messages, setMessages] = useState<MessageModel[]>([]);
  const [isReplyFormVisible, setIsReplyFormVisible] = useState(false);
  const [isThreadInitialized, setIsThreadInitialized] = useState(false);

  const threadId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.CRM_Client_Groups,
  });
  const associationId = useQueryParam({ param: 'associationId' });
  const clientGroupId = parseInt(useQueryParam({ param: 'clientGroupId' }));

  const currentUserId = useSelector<StateModel, string>(
    (state) => state.auth.profileData?._id as string,
  );

  const { response: fetchThreadRes } = useFetch(
    () =>
      threadId ? messagingAPI.fetchThreadById(clientGroupId, threadId) : null,
    [threadId, clientGroupId],
  );

  const fetchMessages = async () => {
    if (isLoading || !hasMore) return;
    setLoading(true);
    const response = await messagingAPI.fetchMessagesByThreadId(
      clientGroupId,
      threadId,
      {
        page: currentPage,
        limit: MESSAGES_RESULT_LIMIT,
      },
    );

    if (messages.length + response.data.length < response.total) {
      setMessages((prevState: MessageModel[]) => [
        ...response.data,
        ...prevState,
      ]);
      setHasMore(true);
      setCurrentPage(currentPage + 1);
    } else {
      setMessages((prevState: MessageModel[]) => [
        ...response.data,
        ...prevState,
      ]);
      setHasMore(false);
    }
    setLoading(false);
  };

  useInfinityScroll(
    fetchMessages,
    messageContainerRef,
    hasMore,
    'top',
    !(clientGroupId && threadId),
  );

  useEffect(() => {
    const readThread = async (clientGroup: number, thread: string) => {
      await messagingAPI.readThread(clientGroup, thread);
    };

    if (messages.length && !isThreadInitialized) {
      // const { data } = fetchThreadMessagesRes;
      const newMessage = messages.some((e) =>
        MessagesHelpers.isMessageNew(e, currentUserId),
      );

      if (newMessage) {
        readThread(clientGroupId, threadId);
      }

      setIsThreadInitialized(true);
    }
  }, [clientGroupId, threadId, messages, isThreadInitialized, currentUserId]);

  const association = useMemo<IDocumentAssociationInfo>(() => {
    return {
      type: DocumentAssociationTypes.Contact,
      id: associationId,
    };
  }, [associationId]);

  const handleReply = (message: MessageModel) => {
    setMessages((prevState: MessageModel[]) => [...prevState, message]);
  };

  useEffect(() => {
    if (messageContainerRef.current && isThreadInitialized) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight -
        messageContainerRef.current.offsetHeight;
    }
  }, [isThreadInitialized]);

  return (
    <div>
      {fetchThreadRes && (
        <>
          <StyledSubjectName>{fetchThreadRes.subject}</StyledSubjectName>
          <StyledDivider />
        </>
      )}

      <Spin spinning={isLoading}>
        <ThreadList
          isChangeHeight={isReplyFormVisible}
          ref={messageContainerRef}
          threadMessages={messages || []}
        />
      </Spin>

      {messages.length ? (
        <ReplyWrapper>
          <ReplyInThread
            setIsReplyFormVisible={setIsReplyFormVisible}
            isReplyFormVisible={isReplyFormVisible}
            clientGroupId={clientGroupId}
            threadId={threadId}
            association={association}
            onReply={handleReply}
          />
        </ReplyWrapper>
      ) : null}
    </div>
  );
};

const StyledSubjectName = styled(Typography.Paragraph)`
  padding: ${({ theme }) => `0px ${theme.paddingSm}`};
  margin-bottom: 0px !important;

  color: ${({ theme }) => theme.colorWhite} !important;
  font-size: ${({ theme }) => theme.fontSizeMd};
  font-weight: 600;
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.marginXs} 0px`};
`;

const ReplyWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default MessagesThread;
