import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';

// components
import Text from '@core_components/Text';
import TransferTemplateStatusSelect from 'modules/CRM/ClientGroups/ClientGroupDetails/ClientGroupPageContent/Contents/TransferTemplatesTab/FilterBar/TransferTemplateStatusSelect';
import { Col, Row } from 'antd';

export type FilterKeys = 'filter_by_status';

interface IProps {
  selectedStatus?: string;
  onFilterChange: (key: FilterKeys, value?: string) => void;
}

const FilterBar = ({ selectedStatus, onFilterChange }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <StyledRow>
      <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
        <Col span={24}>
          <StyledText variant="body1">
            {' '}
            {t('transfer_templates.filter_bar.status')}
          </StyledText>
        </Col>
        <Col span={24}>
          <TransferTemplateStatusSelect
            value={selectedStatus}
            allowClear
            onChange={(newValue) =>
              onFilterChange('filter_by_status', newValue)
            }
          />
        </Col>
      </Col>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin-bottom: 20px;
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.whiteColor};
`;

export default FilterBar;
