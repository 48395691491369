import React, { useState } from 'react';

// components
import { Button } from 'antd';
import AddContextToGroupModal from '../../../../../../components/Modals/TemplateModalDialogs/ORAC/Groups/AddContextToGroupModal';

interface IProps {
  groupId: string;
  onAdd: () => void;
}

const AddContext = ({ groupId, onAdd }: IProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleModalClose = (wasAdded?: boolean) => {
    if (wasAdded) {
      onAdd();
    }

    setIsVisible(false);
  };

  return (
    <>
      <Button onClick={() => setIsVisible(true)}>Add context</Button>
      <AddContextToGroupModal
        isVisible={isVisible}
        closeCallback={handleModalClose}
        groupId={groupId}
      />
    </>
  );
};

export default AddContext;
