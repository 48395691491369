import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { IEntity } from '../../../../../typings/crm/entity';
import { RoutePaths } from '../../../../../routes/routes';
import { DateHelpers } from '@helpers/date';
import { OnboardType } from '../../../../../enums/crm/crm';
import { IClientGroup } from '../../../../../typings/crm/client-group';
import { EntityHelpers } from '@helpers/crm/entity';
import { IContactModel } from '../../../../../typings/crm/contact';
import { ContactHelpers } from '@helpers/crm/contact';
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../../constants/global';

// components
import Link from '../../../../../components/Typography/Link';
import ClientGroupStatus from '../../../../../components/Additional/Statuses/ClientGroupStatus';
import DescriptionSection from '../../../../../components/DesignSystem/Core/DescriptionSection';
import ClientGroupReviewStatus from '../../../../../components/Additional/Statuses/ClientGroupReviewStatus';

interface IProps {
  clientGroup: IClientGroup;
}

const ClientGroupDetailsSection = memo(({ clientGroup }: IProps) => {
  const { t } = useTranslation('crm');

  const data = useMemo(() => {
    function getDirectClientValue() {
      return clientGroup.directClientType === OnboardType.Contact ? (
        <Link
          to={{
            pathname: RoutePaths.CRM_Contacts_Edit,
            search: `?id=${clientGroup.directClientItemId}`,
          }}
        >
          {ContactHelpers.getContactName(
            clientGroup.directClient as IContactModel,
          )}
        </Link>
      ) : (
        <Link
          to={{
            pathname: RoutePaths.CRM_Entities_Edit,
            search: `?id=${clientGroup.directClientItemId}`,
          }}
        >
          {EntityHelpers.getEntityName(clientGroup.directClient as IEntity)}
        </Link>
      );
    }

    return [
      { label: t('client_group.info.id'), description: clientGroup.numericId },
      {
        label: t('client_group.info.name'),
        description: clientGroup.clientGroupName,
      },
      {
        label: t('client_group.info.direct_client'),
        description: getDirectClientValue(),
      },
      {
        label: t('client_group.info.direct_client_type'),
        description: t(`application.type.${clientGroup.directClientType}`),
      },
      {
        label: t('client_group.info.status'),
        description: <ClientGroupStatus status={clientGroup.status} />,
      },
      {
        label: t('client_group.info.review_status'),
        description: (
          <ClientGroupReviewStatus status={clientGroup.reviewStatus} />
        ),
      },
      {
        label: t('client_group.info.created_at'),
        description: DateHelpers.formatDateToString(
          clientGroup._audit.createdAt,
          DEFAULT_DATE_TIME_FORMAT,
        ),
      },
    ];
  }, [clientGroup]);

  return (
    <DescriptionSection
      data={data}
      size="small"
      bordered={false}
      background="none"
    />
  );
});

export default ClientGroupDetailsSection;
