import React from 'react';

// helpers
import useFetch from '../../../../../../../../hooks/useFetch';
import rdcReviewAPI from '../../../../../../../../api/compliance/rdcReviewAPI';
import { RDCAlertFullModel } from '../../../../../../../../typings/compliance/reviewProcess';

// components
import {
  default as ReviewRDCAlertModalBase,
  ReviewActionTypes,
} from '../../../../../../../../components/Modals/TemplateModalDialogs/Compliance/ReviewRDCAlertModal';

interface IProps {
  alertId: string | null;
  closeCallback: (wasCreated?: boolean) => void;
}

const ReviewRDCAlertModal = ({ alertId, closeCallback }: IProps) => {
  const { response, loading } = useFetch(
    () => (alertId ? rdcReviewAPI.fetchAlertById(alertId) : null),
    [alertId],
  );

  const handleReview = async (
    action: ReviewActionTypes,
    alert: RDCAlertFullModel,
    reason?: string,
  ) => {
    switch (action) {
      case 'match':
        await rdcReviewAPI.markAlertAsMatch(alert._id, reason);
        break;

      case 'not_match':
        await rdcReviewAPI.markAlertAsNotMatch(alert._id, reason);
        break;
    }
  };

  return (
    <ReviewRDCAlertModalBase
      isVisible={!!alertId}
      closeCallback={closeCallback}
      loadingInitialData={loading}
      alert={response}
      onReview={handleReview}
    />
  );
};

export default ReviewRDCAlertModal;
