import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { IAdditionalField } from '../../../typings/crm/relationship-template';
import { IKeyRelationshipTemplate } from '../../../typings/crm/key-relationship-templates';
import { OnboardType, RelationshipTrackType } from '../../../enums/crm/crm';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';

export interface ICreateKeyRelationshipTemplateBody {
  name: string;
  isVisible: boolean;
  visibleFor?: 'contact' | 'organization';
  additionalFields: IAdditionalField[];
  keyRelations: ICreateKeyRelationshipTemplateKeyRelation[];
}

interface ICreateKeyRelationshipTemplateKeyRelation {
  track: RelationshipTrackType;
  relationshipTemplateId: string;
  isRequired: boolean;
  isController: boolean;
  options?: any;
  relationshipsMinMax: { min: number; max: number };
}

export type EntityTypeCategoryQuery = {
  name: string;
  description: string;
  isVisible: boolean;
  visibleFor: OnboardType;
  additionalFields: IAdditionalField[];
  keyRelations: ICreateKeyRelationshipTemplateKeyRelation[];
};

interface FetchKeyRelationshipTemplatesModel extends FetchParamsModel {
  search?: string;
  isVisible?: boolean;
}

const keyRelationshipTemplatesAPI = {
  fetchKeyRelationshipTemplates: (
    params: FetchKeyRelationshipTemplatesModel,
  ) => {
    return APIService.get<FetchResponseModel<IKeyRelationshipTemplate>>(
      `${APIConfig.crmApi}/key-relationship-templates`,
      { params },
    ).then(({ data }) => data);
  },

  fetchKeyRelationshipTemplateById: (templateId: string) => {
    return APIService.get<IKeyRelationshipTemplate>(
      `${APIConfig.crmApi}/key-relationship-templates/${templateId}`,
    ).then(({ data }) => data);
  },

  createKeyRelationshipTemplate: (data: EntityTypeCategoryQuery) => {
    return APIService.post(
      `${APIConfig.crmApi}/key-relationship-templates`,
      data,
    ).then(({ data }) => data);
  },

  editKeyRelationshipTemplateById: (
    templateId: string,
    body: ICreateKeyRelationshipTemplateBody,
  ) => {
    return APIService.patch(
      `${APIConfig.crmApi}/key-relationship-templates/${templateId}`,
      body,
    ).then(({ data }) => data);
  },

  deleteKeyRelationshipTemplateById: (templateId: string, reason: string) => {
    return APIService.delete(
      `${APIConfig.crmApi}/key-relationship-templates/${templateId}`,
      { data: { reason } },
    );
  },
};

export { keyRelationshipTemplatesAPI };
