import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { StateModel } from '../../../../../redux/reducers';
import { useSelector } from 'react-redux';

// components
import EnableSecurityKey from './EnableSecurityKey';
import ManageSecurityKeys from './ManageSecurityKeys';
import DisableFIDOAuthentication from './DisableSecurityKey';
import { Col, Row, Typography } from 'antd';
import { ReactComponent as KeyIcon } from '../../../../../resources/images/icons/key.svg';

const SecurityKeyOption = () => {
  const { t } = useTranslation('profile');
  const isTwoFactorFidoEnabled = useSelector<StateModel, boolean>(
    (state) => !!state.auth.profileData?.isTwoFactorFidoEnabled,
  );

  return (
    <Row gutter={[16, 0]}>
      <Col xl={18} lg={18} md={12} sm={24} xs={24}>
        <TitleWrapper>
          <StyledKeyIcon />
          <TextWrapper>
            <StyledTitle strong>
              {t(
                'sign_in_and_security.two_factor_verification.security_key_option.title',
              )}
            </StyledTitle>
            <Typography.Text>
              {t(
                'sign_in_and_security.two_factor_verification.security_key_option.description',
              )}
            </Typography.Text>
          </TextWrapper>
        </TitleWrapper>
      </Col>

      <Col xl={6} lg={6} md={12} sm={24} xs={24}>
        {!isTwoFactorFidoEnabled ? (
          <EnableSecurityKey />
        ) : (
          <>
            <ManageSecurityKeys />
            <DisableFIDOAuthentication />
          </>
        )}
      </Col>
    </Row>
  );
};

const StyledKeyIcon = styled(KeyIcon)`
  width: 45px;
  height: 45px;
  margin-right: ${({ theme }) => theme.marginSm};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
  color: ${({ theme }) => theme.whiteColor};
`;

export default SecurityKeyOption;
