import React from 'react';

// helpers
import useFetch from '../../../../../../hooks/useFetch';
import useTranslation from '../../../../../../hooks/useTranslation';
import { transactionsAPI } from '../../../../../../api/transactions/transactionsAPI';
import { approvalWorkflowAPI } from 'api/approval/approvalManagement/approvalWorkflowAPI';
import { transactionsAPIAdapter } from 'apiAdapters/transactions/transactionsAPIAdapter';

// components
import InnerContent from './InnerContent';
import LoadingWrapper from '../../../../../WrapperComponents/LoadingWrapper';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  transactionId: number | null;
  clientGroupId: number | null;
}

const ClientTransferModal = ({
  isVisible,
  closeCallback,
  transactionId,
  clientGroupId,
}: IProps) => {
  const { t } = useTranslation('banking');

  const { response, loading } = useFetch(
    () =>
      transactionId && clientGroupId
        ? transactionsAPI.fetchClientPendingTransactionById(
            transactionId,
            clientGroupId,
          )
        : null,
    [transactionId, clientGroupId],
  );

  const {
    response: transactionApprovalWorkflow,
    loading: transactionApprovalWorkflowLoading,
  } = useFetch(
    () =>
      clientGroupId && response
        ? approvalWorkflowAPI.fetchClientTransactionApprovalWorkflow(
            response.workflowId,
            clientGroupId,
          )
        : null,
    [clientGroupId, response],
  );

  return (
    <ModalDialog
      width="80%"
      title={t('transactions.transaction_details.title')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      hideFooterButtons
    >
      <LoadingWrapper loading={loading || transactionApprovalWorkflowLoading}>
        {response && transactionApprovalWorkflow && (
          <InnerContent
            workflow={transactionApprovalWorkflow}
            transaction={transactionsAPIAdapter.formatJournalEntryForTransactionDetailsModal(
              response,
            )}
          />
        )}
      </LoadingWrapper>
    </ModalDialog>
  );
};

export default ClientTransferModal;
