import React from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import { RoutePaths } from '../../../routes/routes';
import useQueryParam from '../../../hooks/useQueryParam';
import { NotificationMethods } from '../../../enums/admin/orac';
import { notificationsAPI } from '../../../api/orac/notificationsAPI';

// components
import LoadingWrapper from '../../../components/WrapperComponents/LoadingWrapper';
import { default as NotificationDetailsContent } from '../../../modules/ORAC/Notifications/NotificationDetails/index';

const NotificationsDetails = () => {
  const notificationId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.ORAC_Notifications,
  });
  const notificationType = useQueryParam({
    param: 'type',
    noParamRedirect: RoutePaths.ORAC_Notifications,
  }) as NotificationMethods;

  const { response, loading } = useFetch(
    () =>
      notificationId
        ? notificationsAPI.fetchNotificationById({
            notificationId,
            notificationType,
          })
        : null,
    [notificationId, notificationType],
  );

  return (
    <LoadingWrapper loading={loading}>
      {!!response && (
        <NotificationDetailsContent
          notificationType={notificationType}
          notification={response}
        />
      )}
    </LoadingWrapper>
  );
};

export default NotificationsDetails;
