import React, { useEffect, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useQueryParam from '@hooks/useQueryParam';
import useTranslation from '@hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { contactsAPI } from 'api/crm/contact/contactsAPI';
import { ContactHelpers } from '@helpers/crm/contact';
import { PageLayoutHelperPropsModel } from 'layouts/PageLayout';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ContactDetailsPageContent from 'modules/CRM/Contacts/ContactDetails/ContactDetailsPageContent';

type IProps = PageLayoutHelperPropsModel;

const ContactDetailsPage = ({ setHeaderOptions }: IProps) => {
  const { t } = useTranslation(['crm', 'page_titles']);
  const contactId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.CRM_Contacts,
  });
  const [updateContactTrigger, updateContact] = useState({});

  const { response, loading } = useFetch(
    () => (contactId ? contactsAPI.fetchContactById(contactId) : null),
    [contactId, updateContactTrigger],
  );

  useEffect(() => {
    if (response) {
      const contactNameString = ContactHelpers.getContactName(response);
      setHeaderOptions({
        title: `${t('Contact', { ns: 'page_titles' })}: ${contactNameString}`,
        cardTitle: t('contacts.new.contactInformation'),
      });
    }
  }, [response]);

  return (
    <LoadingWrapper loading={loading}>
      {response && (
        <ContactDetailsPageContent
          contact={response}
          updateContact={() => updateContact({})}
        />
      )}
    </LoadingWrapper>
  );
};

export default ContactDetailsPage;
