import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { IApprovalRuleAccount } from '..';
import { TransactionRuleFromQuery } from 'api/approval/approvalManagement/clientTransactionRulesAPI';

// components
import Text from '@core_components/Text';
import TransactionRuleMatrix from 'components/Tables/TableTemplates/ApprovalManagement/ClientTransactionRulesTable/TransactionRuleMatrix';
import { Tag } from 'antd';

interface IProps {
  data: TransactionRuleFromQuery;
}

const InnerTemplate = ({ data }: IProps) => {
  const { t } = useTranslation('crm');

  const renderAccounts = (accounts: IApprovalRuleAccount[]) => {
    return accounts.map((e, i) => (
      <Tag key={i}>
        {AccountsHelpers.generateAccountFullName(
          e.bankAccountName,
          e.bankAccountNumber,
          e.bankAccountCurrency,
        )}
      </Tag>
    ));
  };

  return (
    <>
      <SectionWrapper>
        <Text variant="h5">
          {`${t(
            'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.transaction_rule_changes_template.bank_accounts',
          )}:`}
        </Text>
        {renderAccounts(data.bankAccounts)}
      </SectionWrapper>

      <SectionWrapper>
        <Text variant="h5">
          {`${t(
            'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.transaction_rule_changes_template.approval_rule',
          )}:`}
        </Text>
        <TransactionRuleMatrix record={data} />
      </SectionWrapper>
    </>
  );
};

const SectionWrapper = styled.section`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default InnerTemplate;
