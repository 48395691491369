import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { RoleBaseModel } from '../../typings/ORAC/roles';
import { UsersBaseModel } from '../../typings/ORAC/users';
import { ContextBaseModel } from '../../typings/ORAC/contexts';
import { GroupBaseModel, NewGroupModel } from '../../typings/ORAC/groups';
import { FetchResponseModel, FetchParamsModel } from '../../typings/common';

export interface FetchParamsWithNameSearchModel extends FetchParamsModel {
  name?: string;
}

const groupsAPI = {
  fetchGroups: (params: FetchParamsWithNameSearchModel) => {
    return APIService.get<FetchResponseModel<GroupBaseModel>>(
      `${APIConfig.oracApi}/groups`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  getGroupById: (groupId: string) => {
    return APIService.get<GroupBaseModel>(
      `${APIConfig.oracApi}/groups/${groupId}`,
    ).then(({ data }) => data);
  },

  createNewGroup: (newGroup: NewGroupModel) => {
    return APIService.post<GroupBaseModel>(
      `${APIConfig.oracApi}/groups`,
      newGroup,
    ).then(({ data }) => data);
  },

  editGroupById: (groupId: string, updatedGroup: NewGroupModel) => {
    return APIService.patch<GroupBaseModel>(
      `${APIConfig.oracApi}/groups/${groupId}`,
      updatedGroup,
    ).then(({ data }) => data);
  },

  fetchContextsByGroupId: (
    groupId: string,
    params: FetchParamsWithNameSearchModel,
  ) => {
    return APIService.get<FetchResponseModel<ContextBaseModel>>(
      `${APIConfig.oracApi}/groups/${groupId}/contexts`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchRolesByGroupId: (
    groupId: string,
    params: FetchParamsWithNameSearchModel,
  ) => {
    return APIService.get<FetchResponseModel<RoleBaseModel>>(
      `${APIConfig.oracApi}/groups/${groupId}/roles`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchUsersByGroupId: (
    groupId: string,
    params: FetchParamsWithNameSearchModel,
  ) => {
    return APIService.get<FetchResponseModel<UsersBaseModel>>(
      `${APIConfig.oracApi}/groups/${groupId}/users`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  addUsersToGroup: (groupId: string, users: string[]) => {
    return APIService.put<null>(
      `${APIConfig.oracApi}/groups/${groupId}/users`,
      {
        users,
      },
    ).then(({ data }) => data);
  },

  removeUsersFromGroup: (groupId: string, users: string[]) => {
    return APIService.delete<null>(
      `${APIConfig.oracApi}/groups/${groupId}/users`,
      {
        data: { users },
      },
    ).then(({ data }) => data);
  },

  addRolesToGroup: (groupId: string, roles: string[]) => {
    return APIService.put<null>(
      `${APIConfig.oracApi}/groups/${groupId}/roles`,
      { roles },
    ).then(({ data }) => data);
  },

  removeRolesFromGroup: (groupId: string, roles: string[]) => {
    return APIService.delete<null>(
      `${APIConfig.oracApi}/groups/${groupId}/roles`,
      {
        data: { roles },
      },
    ).then(({ data }) => data);
  },

  addContextsToGroup: (groupId: string, contexts: string[]) => {
    return APIService.put<null>(
      `${APIConfig.oracApi}/groups/${groupId}/contexts`,
      {
        contexts,
      },
    ).then(({ data }) => data);
  },

  removeContextsFromGroup: (groupId: string, contexts: string[]) => {
    return APIService.delete<null>(
      `${APIConfig.oracApi}/groups/${groupId}/contexts`,
      {
        data: { contexts },
      },
    ).then(({ data }) => data);
  },
};

export default groupsAPI;
