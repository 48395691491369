import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { CashFlowTotalDataReportModel } from 'apiAdapters/reporting/reportingAPIAdapter';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel;

const CashFlowTotalTable = ({ ...rest }: IProps) => {
  const { t } = useTranslation('reporting');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        key: 'name',
        title: t('cash_flow.total_table.name'),
        render: (record: CashFlowTotalDataReportModel) => record.name,
      },

      {
        key: 'value',
        title: t('cash_flow.total_table.value'),
        render: (record: CashFlowTotalDataReportModel) => record.value,
      },
    ];

    return result;
  }, [t]);

  return <Table size="small" columns={columns} {...rest} />;
};

export default CashFlowTotalTable;
