import React, { useEffect, useState } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import useQueryParam from '../../../hooks/useQueryParam';
import useTranslation from '../../../hooks/useTranslation';
import { Moment } from 'moment';
import { enumToMap } from '@helpers/utils';
import { SortProps } from '@core_components/Table';
import { SortOrder } from 'antd/lib/table/interface';
import { useHistory } from 'react-router-dom';
import { Transaction } from '../../../typings/banking/transactions';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { DEFAULT_TABLE_LIMIT } from '../../../constants/global';
import { bankingTransactionsAPI } from '../../../api/banking/bankingTransactionsAPI';
import { TransactionReviewStatuses } from '../../../enums/banking/transactions';

// components
import ViewEditTransaction from '../ViewEditTransaction';
import FilterBar, { FilterKeys } from './FilterBar';
import { Divider, Modal } from 'antd';
import {
  TableActions,
  default as TemplateTransactionsTable,
} from '../../../components/Tables/TableTemplates/Banking/TransactionsTable';

interface IProps {
  updateTransactionsTrigger?: any;
}

const TransactionsTable = ({ updateTransactionsTrigger }: IProps) => {
  const history = useHistory();
  const transactionIdFromURL = useQueryParam({ param: 'id' });

  const { t } = useTranslation(['banking', 'common']);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeSorter, setActiveSorter] = useState<{
    key: 'ValueDate' | 'CreatedOn';
    direction: SortOrder;
  } | null>(null);
  const [updateTableTrigger, updateTable] = useState<unknown>();
  const [selectedTransactionId, setSelectedTransactionId] = useState<
    number | null
  >(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filterValues, setFilterValues] = useState<{
    type: string[] | undefined;
    status: TransactionReviewStatuses[] | undefined;
    amount: number[] | undefined;
    beneficiary: string[] | undefined;
    requestedDate: [Moment, Moment] | undefined;
    valueDate: [Moment, Moment] | undefined;
    orderingCustomer: string[] | undefined;
    fromAmount: number | undefined;
    toAmount: number | undefined;
  }>({
    type: undefined,
    status: [TransactionReviewStatuses.PendingTransmit],
    amount: undefined,
    beneficiary: undefined,
    requestedDate: undefined,
    valueDate: undefined,
    orderingCustomer: undefined,
    fromAmount: undefined,
    toAmount: undefined,
  });

  useEffect(() => {
    transactionIdFromURL &&
      setSelectedTransactionId(transactionIdFromURL as any);
  }, [transactionIdFromURL]);

  const { response, loading } = useFetch(
    () =>
      bankingTransactionsAPI.fetchTransactions({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        type: filterValues.type ? filterValues.type.join(',') : undefined,
        searchQuery: searchQuery || undefined,
        status: filterValues.status ? filterValues.status.join(',') : undefined,
        sortBy: activeSorter ? activeSorter.key : undefined,
        sortDirection: activeSorter
          ? activeSorter.direction === 'ascend'
            ? true
            : false
          : undefined,
        fromRequestedDate:
          filterValues.requestedDate && filterValues.requestedDate[0]
            ? filterValues.requestedDate[0].startOf('day').unix()
            : undefined,
        toRequestedDate:
          filterValues.requestedDate && filterValues.requestedDate[1]
            ? filterValues.requestedDate[1].endOf('day').unix()
            : undefined,
        fromValueDate:
          filterValues.valueDate && filterValues.valueDate[0]
            ? filterValues.valueDate[0].startOf('day').unix()
            : undefined,
        toValueDate:
          filterValues.valueDate && filterValues.valueDate[1]
            ? filterValues.valueDate[1].endOf('day').unix()
            : undefined,
        orderingCustomerFilter: filterValues.orderingCustomer
          ? filterValues.orderingCustomer.join(',')
          : undefined,
        beneficiaryCustomerFilter: filterValues.beneficiary
          ? filterValues.beneficiary.join(',')
          : undefined,
        fromAmount:
          filterValues.amount && filterValues.amount[0]
            ? AccountsHelpers.convertAmountFromIntToBigInt(
                filterValues.amount[0],
              )
            : undefined,
        toAmount:
          filterValues.amount && filterValues.amount[1]
            ? AccountsHelpers.convertAmountFromIntToBigInt(
                filterValues.amount[1],
              )
            : undefined,
      }),
    [
      currentPage,
      updateTableTrigger,
      updateTransactionsTrigger,
      filterValues,
      activeSorter,
      searchQuery,
    ],
  );

  const handleActionsClick = (key: TableActions, record: Transaction) => {
    switch (key) {
      case 'view':
        setSelectedTransactionId(record.id);
        break;

      case 'send_to_rdc':
        Modal.confirm({
          title: t('banking.transactions.send_to_rdc_modal.title'),
          content: t('banking.transactions.send_to_rdc_modal.content'),
          okText: t('yes', { ns: 'common' }),
          cancelText: t('no', { ns: 'common' }),
          onOk: async () => {
            await bankingTransactionsAPI.sendTransactionToRDCReview(record.id);
            updateTable({});
          },
        });
        break;
    }
  };

  const handleModalClose = (wasSubmitted?: boolean) => {
    if (wasSubmitted) {
      updateTable({});
    }

    if (transactionIdFromURL) {
      history.replace({ search: '' });
    }

    setSelectedTransactionId(null);
  };

  const handleFilterBarChange = (key: FilterKeys, value: string) => {
    switch (key) {
      case 'search':
        setSearchQuery(value ? value.trim() : '');
        break;
    }
  };

  const handleFilterChange = (key: string, value: unknown | undefined) => {
    switch (key) {
      case 'type':
        setFilterValues((prev) => ({ ...prev, type: value as string[] }));
        break;

      case 'status':
        setFilterValues((prev) => ({
          ...prev,
          status: value as TransactionReviewStatuses[],
        }));
        break;

      case 'ordering_customer':
        setFilterValues((prev) => ({
          ...prev,
          orderingCustomer: value as string[],
        }));
        break;

      case 'beneficiary':
        setFilterValues((prev) => ({
          ...prev,
          beneficiary: value as string[],
        }));
        break;

      case 'amount':
        setFilterValues((prev) => ({
          ...prev,
          amount: (value as number[]) || undefined,
        }));
        break;

      case 'requested_date':
        setFilterValues((prev) => ({
          ...prev,
          requestedDate: (value as [Moment, Moment]) || undefined,
        }));
        break;

      case 'value_date':
        setFilterValues((prev) => ({
          ...prev,
          valueDate: (value as [Moment, Moment]) || undefined,
        }));
        break;
    }
  };

  const handleSortChange = (sort: SortProps) => {
    if (Array.isArray(sort)) {
      return;
    }

    switch (sort.columnKey) {
      case 'value_date':
        {
          setActiveSorter((prevState) =>
            sort.order
              ? {
                  ...prevState,
                  key: 'ValueDate',
                  direction: sort.order,
                }
              : null,
          );
        }
        break;

      case 'requested_date':
        setActiveSorter((prevState) =>
          sort.order
            ? {
                ...prevState,
                key: 'CreatedOn',
                direction: sort.order,
              }
            : null,
        );
        break;
    }
  };

  const onFilterChange = (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => {
    if (Object.keys(filters).length) {
      Object.keys(filters).forEach((key) =>
        handleFilterChange(key as FilterKeys, filters[key] as any),
      );
    }
  };

  return (
    <>
      <FilterBar onFilterBarChange={handleFilterBarChange} />
      <Divider />
      <TemplateTransactionsTable
        filters={filterValues}
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={currentPage}
        onSortChange={handleSortChange}
        onFilterChange={onFilterChange}
        onActionsClick={handleActionsClick}
        onPaginationChange={setCurrentPage}
      />
      <ViewEditTransaction
        transactionId={selectedTransactionId}
        onModalClose={handleModalClose}
      />
    </>
  );
};

export default TransactionsTable;
