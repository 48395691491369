import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { ReferenceObjectWithPropertiesModel } from '../../../../../typings/compliance/configurationRules';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import ReferenceObjectPropertiesList from '../ReferenceObjectPropertiesList';

type IProps = RequiredPropsForTableModel<ReferenceObjectWithPropertiesModel>;

const ReferenceObjectsList = memo((props: IProps) => {
  const { t } = useTranslation('compliance');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('property_configuration.object_name'),
        key: 'name',
        render: (record: ReferenceObjectWithPropertiesModel) => record.name,
      },
    ];

    return result;
  }, []);

  const expandableRow = useMemo(
    () => ({
      rowExpandable: ({ properties }: ReferenceObjectWithPropertiesModel) =>
        !!properties.length,
      expandedRowRender: ({
        properties,
      }: ReferenceObjectWithPropertiesModel) => (
        <ReferenceObjectPropertiesList
          size="small"
          className="disable-table-row-hover"
          hidePagination
          current={1}
          data={properties}
          total={properties.length}
        />
      ),
    }),
    [],
  );

  return <Table {...props} expandable={expandableRow} columns={columns} />;
});

export default ReferenceObjectsList;
