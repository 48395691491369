import { FetchResponseModel } from '../../typings/common';
import { NotificationPreferenceModel } from '../../typings/profile/accountNotifications';
import { APIService } from '../axiosInstance';
import APIConfig from '../../config/api';

const accountNotificationsAPI = {
  fetchNotificationSettings: () => {
    return APIService.get<FetchResponseModel<NotificationPreferenceModel>>(
      `${APIConfig.notificationApi}/account/settings`,
    ).then(({ data }) => data);
  },

  updateNotifications: (model: NotificationPreferenceModel) => {
    return APIService.patch(
      `${APIConfig.notificationApi}/account/settings`,
      model,
    ).then(({ data }) => data);
  },
};

export { accountNotificationsAPI };
