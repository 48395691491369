import * as yup from 'yup';
import { STRING_VALIDATION_RULE } from 'validations/common';

export const UpdateFeeScheduleValidationSchema = yup.object().shape({
  currency: STRING_VALIDATION_RULE.required('This is required field'),
  amount: yup
    .number()
    .typeError('The value should be in number format')
    .min(0, 'The one time exchange should be bigger than 0')
    .required('This is required field'),
});
