import React, { useMemo } from 'react';

// helpers
import { ContactModel } from 'typings/crm/relationships';
import { ContactHelpers } from '@helpers/crm/contact';
import { clientGroupsAPI } from '../../../../../../api/crm/clientGroup/clientGroupsAPI';
import { IClientGroupUser } from '../../../../../../typings/crm/client-group';
import { MessageRecipient } from '../../../../../../apiAdapters/messaging/messagingAdapter';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../constants/global';

// components
import Autocomplete, {
  AutocompleteInitialValue,
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';

interface IProps {
  disabled?: boolean;
  value: MessageRecipient[];
  clientGroupId: string;
  initialValue?: AutocompleteInitialValue;
  onChange: (newValue: MessageRecipient[]) => void;
}

const RecipientAutocomplete = ({
  clientGroupId,
  value,
  initialValue,
  onChange,
  ...rest
}: IProps) => {
  const fetchRecipients = async (search: string) => {
    const { data } = await clientGroupsAPI.fetchMembers(clientGroupId, {
      page: 1,
      search,
      has_online_access: true,
      type: 'contact',
      limit: DEFAULT_TABLE_LIMIT,
    });

    return data.map<AutocompleteOption>((recipient) => {
      return {
        id: String(recipient.contact?.associations?.auth),
        content: ContactHelpers.getContactName(
          recipient.contact as ContactModel,
        ),
        model: recipient,
      };
    });
  };

  const formattedFieldValue = useMemo(() => {
    let result: string[] = [];

    if (value.length) {
      result = value.map((e) => e.id);
    }

    return result;
  }, [value]);

  const handleChangeRecipients = (
    _: string,
    newValueModel: AutocompleteOption<IClientGroupUser>[],
  ) => {
    const newValueFormatted = newValueModel.map((e) => ({
      id: e.model.contact.associations.auth,
      name: ContactHelpers.getContactName(e.model.contact),
    }));
    onChange(newValueFormatted);
  };

  return (
    <Autocomplete
      {...rest}
      initialValue={initialValue}
      value={formattedFieldValue}
      onChange={handleChangeRecipients}
      fetchData={fetchRecipients}
    />
  );
};

export default RecipientAutocomplete;
