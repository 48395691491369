import React from 'react';

// components
import AccountRecoveryRequestsTable from '../../../modules/ORAC/AccountRecoveryRequests/AccountRecoveryRequestsTable';

const AccountRecoveryRequests = () => {
  return <AccountRecoveryRequestsTable />;
};

export default AccountRecoveryRequests;
