import React from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import { Moment } from 'moment';
import {
  TrialBalanceReportResponse,
  reportingAPI,
} from '../../../api/reporting/reportingAPI';

// components
import FilterBar from './FilterBar';
import TrialBalanceEntriesTable from './TrialBalanceEntriesTable';
import TrialBalanceCurrenciesTable from '../../../components/Tables/TableTemplates/Reporting/TrialBalanceCurrenciesTable';
import { Divider } from 'antd';

interface IProps {
  selectedFilterDate: Moment;
  setSelectedDate: (newDate: Moment) => void;
}

const TrialBalanceReport = ({
  selectedFilterDate,
  setSelectedDate,
}: IProps) => {
  const { response, loading } = useFetch(
    () =>
      reportingAPI.fetchTrialBalanceCurrenciesReport(
        selectedFilterDate.endOf('d').format('X'),
      ),
    [selectedFilterDate],
  );

  const expandableRow = {
    expandedRowRender: ({ currencyId }: TrialBalanceReportResponse) => {
      return (
        <TrialBalanceEntriesTable
          currencyId={currencyId}
          filterDate={selectedFilterDate}
        />
      );
    },
  };

  // handle filter bar change
  const handleFilterChange = (key: string, value: any) => {
    switch (key) {
      case 'date':
        setSelectedDate(value);
        break;
    }
  };

  return (
    <>
      <FilterBar
        date={selectedFilterDate}
        isDisabled={loading}
        filterChange={handleFilterChange}
      />
      <Divider />
      <TrialBalanceCurrenciesTable
        data={response?.data || []}
        total={response?.total || 0}
        expandable={expandableRow}
        loading={loading}
      />
    </>
  );
};

export default TrialBalanceReport;
