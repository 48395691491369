import APIConfig from '../../config/api';
import rdcReviewAPIAdapter from 'apiAdapters/compliance/rdcReviewAPIAdapter';
import { APIService } from '../axiosInstance';
import { OnboardType } from 'enums/crm/crm';
import { RDCMonitoringItem } from 'components/Tables/TableTemplates/Compliance/RDCMonitoringTable';
import { ClientGroupWithRDCModel } from '../../typings/crm/client-group';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import {
  RDCAlertFullModel,
  RDCReviewAlertBaseModel,
} from '../../typings/compliance/reviewProcess';

export type RDCMonitoringItemFromQuery = {
  id: string;
  createdAt: number;
  isCompleted: boolean;
  node: {
    id: string;
    name: string;
    type: OnboardType;
  };
};

export interface FetchAlertsByAssociation extends FetchParamsModel {
  associationId?: string;
  reviewProcessId?: string;
}

const rdcReviewAPI = {
  fetchClientGroupRelationshipsWithRDCData: (
    params: FetchParamsModel,
    clientGroupId: string,
  ) => {
    return APIService.get<FetchResponseModel<ClientGroupWithRDCModel>>(
      `${APIConfig.crmApi}/client-groups/${clientGroupId}/rdc`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchAlertsByAssociationId: (params: FetchAlertsByAssociation) => {
    return APIService.get<FetchResponseModel<RDCReviewAlertBaseModel>>(
      `${APIConfig.amlApi}/rdc`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchAlertById: (alertId: string) => {
    return APIService.get<RDCAlertFullModel>(
      `${APIConfig.amlApi}/rdc/${alertId}`,
    ).then(({ data }) => data);
  },

  fetchRDCMonitoringAlerts: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<RDCMonitoringItemFromQuery>>(
      `${APIConfig.amlApi}/rdc/monitoring`,
      { params },
    ).then<FetchResponseModel<RDCMonitoringItem>>(({ data }) =>
      rdcReviewAPIAdapter.fetchRDCMonitoringAlerts(data),
    );
  },

  markAlertAsMatch: (alertId: string, reason?: string) => {
    return APIService.post<boolean>(
      `${APIConfig.amlApi}/rdc/${alertId}/review`,
      {
        match: true,
        reason,
      },
    ).then(({ data }) => data);
  },

  markAlertAsNotMatch: (alertId: string, reason?: string) => {
    return APIService.post<boolean>(
      `${APIConfig.amlApi}/rdc/${alertId}/review`,
      {
        match: false,
        reason,
      },
    ).then(({ data }) => data);
  },
};

export default rdcReviewAPI;
