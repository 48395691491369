import React from 'react';

// helpers
import styled from 'styled-components';
import { generateUniqId } from '../../../../helpers/utils';
import { DocumentAssociationTypes } from 'enums/crm/crm';
import {
  UploadFormValuesModel,
  IUploadFile,
} from '../../../../typings/documents/documents';
import { useFormikContext, FieldArray, FieldArrayRenderProps } from 'formik';

// components
import FormFileItem from './FormFileItem';
import UploadDragAndDrop from '../../../../components/Fields/UploadDragAndDrop';

interface IProps {
  allowedAssociationTypes?: DocumentAssociationTypes[];
}

const InnerForm = ({ allowedAssociationTypes }: IProps) => {
  const formik = useFormikContext<UploadFormValuesModel>();

  const handleFilesSelect = (files: File[]) => {
    if (!files.length) {
      setFilesInFormik([]);
      return;
    }

    const formattedFiles = files.reduce<IUploadFile[]>((acc, file: File) => {
      if (file.size) {
        acc.push({
          id: generateUniqId(),
          associations: [],
          originalName: file.name,
          name: file.name,
          tags: [],
          file,
        });
      }

      return acc;
    }, []);

    if (formattedFiles.length) {
      setFilesInFormik(formattedFiles);
    }
  };

  const setFilesInFormik = (files: IUploadFile[]) =>
    formik.setFieldValue('files', files);

  const renderFiles = (arrayHelpers: FieldArrayRenderProps) => {
    const { files } = formik.values;

    if (!files.length) return null;

    return files.map((file, index) => {
      const isLastItem = index === files.length - 1;

      return (
        <FormFileItem
          key={file.id}
          fileIndex={index}
          fieldName="files"
          isLastItem={isLastItem}
          handleDelete={() => arrayHelpers.remove(index)}
          allowedAssociationTypes={allowedAssociationTypes}
        />
      );
    });
  };

  return (
    <>
      <DragDropWrapper>
        <UploadDragAndDrop onSelect={handleFilesSelect} />
      </DragDropWrapper>

      <FieldArray name="files" validateOnChange render={renderFiles} />
    </>
  );
};

const DragDropWrapper = styled.div`
  margin: 15px 0px;
`;

export default InnerForm;
