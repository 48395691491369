import React, { useMemo, useState } from 'react';

// components
import { ReviewProcessType } from '../../../../../../typings/compliance/reviewProcess';
import { useReviewProcessContext } from '../../../../../../hooks/domainHooks/useReviewProcess';

// components
import ReviewRulesTable from '../../../../../../components/Tables/TableTemplates/Compliance/ReviewRulesTable';

const TransactionReview = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { transactionReviewProcess } = useReviewProcessContext();

  const data = useMemo(() => {
    if (!transactionReviewProcess.alert?.rules) {
      return [];
    }

    return transactionReviewProcess.alert?.rules.map((e) => ({
      ...e,
      objectType: transactionReviewProcess.alert
        ?.objectType as ReviewProcessType,
    }));
  }, [transactionReviewProcess.alert?.rules]);

  return (
    <ReviewRulesTable
      data={data}
      current={currentPage}
      onPaginationChange={setCurrentPage}
    />
  );
};

export default TransactionReview;
