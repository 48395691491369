import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { RouteModel, SubRouteModel } from '../typings/routes';

const useRoute = (
  routes: RouteModel[],
): { currentRoute: SubRouteModel | null } => {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState<SubRouteModel | null>(null);

  useEffect(() => {
    // Find route title using recursion
    function findRoute(
      pathArray: string[],
      routes: SubRouteModel[],
    ): SubRouteModel | null {
      let result = null;
      const routeIndex = routes.findIndex((e) => e.path === pathArray[0]);

      // This case should not happen if everything is ok with routes and paths for them
      if (routeIndex === -1) {
        return null;
      }

      if (pathArray.length > 1) {
        result = findRoute(
          pathArray.slice(1),
          routes[routeIndex].childRoutes || [],
        );
      } else {
        result = routes[routeIndex];
      }

      return result;
    }

    function generateFullRoutePathArray(pathName: string) {
      const pathArray = pathName.split('/').filter((el) => el);

      let path = '';
      const result: string[] = [];

      pathArray.forEach((e, i) => {
        if (i === 0) {
          path = `/${e}`;
        } else {
          path = `${path}/${e}`;
        }

        result.push(path);
      });

      return result;
    }

    const splitPath = generateFullRoutePathArray(location.pathname);

    if (!splitPath.length) {
      setCurrentRoute(null);
    }

    let result = null;
    const parentRouteSectionIndex = routes.findIndex(
      ({ path }) => path === splitPath[0],
    );

    if (parentRouteSectionIndex !== -1) {
      result = findRoute(splitPath, routes[parentRouteSectionIndex].routes);
    } else {
      const clientRoutesSectionIndex = routes.findIndex((e) => e.path === '/');
      result = findRoute(splitPath, routes[clientRoutesSectionIndex].routes);
    }

    setCurrentRoute(result);
  }, [location, routes]);

  return { currentRoute };
};

export default useRoute;
