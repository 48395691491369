import React, { memo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { useFormikContext } from 'formik';
import { TWO_COL_GRID_SIZES } from 'constants/grids';
import { AddressItemFormValue } from './AddressesSection/AddressItem';
import { PassportItemFormValue } from './PassportsSection/PassportItem';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import SectionIntro from '@common_components/Texts/SectionIntro';
import PassportsSection from './PassportsSection';
import AddressesSection from './AddressesSection';
import CountryFormSelect from 'components/Forms/FormComponents/SelectInputs/CountryFormSelect';
import TrueFalseFormSelect from 'components/Forms/FormComponents/SelectInputs/TrueFalseFormSelect';
import { Col, Row } from 'antd';

export type FormValuesModel = {
  isPEP: boolean | null;
  pepInformation: string;
  isRegulated: boolean | null;
  regulationCountry: string[] | null;
  primaryAddressId: string | null;
  addresses: AddressItemFormValue[];
  canUpdatePrimaryStatusForAddresses: boolean;
  displayPassports?: boolean;
  passports?: PassportItemFormValue[];
};

const ContactIdentificationForm = memo(() => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.new.areYouPEP')}
            name="isPEP"
            component={TrueFalseFormSelect}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.new.pepInformation')}
            name="pepInformation"
            component={FormInput}
            disabled={values.isPEP !== true}
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.new.isRegulated')}
            name="isRegulated"
            component={TrueFalseFormSelect}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('entity.new.regulationCountry')}
            name="regulationCountry"
            component={CountryFormSelect}
            disabled={values.isRegulated !== true}
            additionalProps={{ mode: 'multiple' }}
          />
        </Col>
      </Row>
      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('contacts.new.addresses')}
      />
      <AddressesSection />
      {values.displayPassports && (
        <>
          <SectionIntro
            gutterTop
            titleVariant="h4"
            title={t('contacts.new.passports')}
          />
          <PassportsSection />
        </>
      )}
    </>
  );
});

export default ContactIdentificationForm;
