import React from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { ApprovalStatuses } from '../../../../enums/approvalManagement/workflow';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';
import {
  IApprovalProgressState,
  IApprovalMember,
} from '../../../../typings/crm/onboarding';

// components
import { List, Typography } from 'antd';

interface IProps {
  dataIndex: string;
  record: IApprovalProgressState;
  isOrText: boolean;
}

const ContentCell = ({ dataIndex, record, isOrText }: IProps) => {
  const { t } = useTranslation('approval_management');

  const renderCol = (
    keyToShow: 'userFullName' | 'approvalAction' | 'approvalReason',
  ) => {
    // We only need to render AND/OR/Group info in first column
    const isFirstCol = keyToShow === 'userFullName';
    const isStatusCol = keyToShow === 'approvalAction';

    return (
      <React.Fragment>
        {record.progressStateItems.map((e, i: number) => {
          const shouldRenderAnd = i < record.progressStateItems.length - 1;
          return (
            <React.Fragment key={i}>
              <GroupTitleRow strong isGroupName isMarginTop={i === 0}>
                {isFirstCol && e.groupNameTittle}
              </GroupTitleRow>

              <List
                size="small"
                dataSource={e.members}
                renderItem={(item: IApprovalMember, i: number) => (
                  <StyledListItem
                    key={i}
                    color={
                      isStatusCol ? getStatusTextColor(item.approvalAction) : ''
                    }
                  >
                    {isStatusCol
                      ? renderStatusValue(
                          item[keyToShow] as ApprovalStatuses,
                          item,
                        )
                      : item[keyToShow]}
                  </StyledListItem>
                )}
              />

              {shouldRenderAnd && (
                <GroupTitleRow style={{ paddingLeft: 36 }}>
                  {isFirstCol && t('approval_rules.form.and')}
                </GroupTitleRow>
              )}
            </React.Fragment>
          );
        })}

        {isOrText && isFirstCol && (
          <ORTextWrapper>
            <AndOrText>{t('approval_rules.form.or')}</AndOrText>
          </ORTextWrapper>
        )}
      </React.Fragment>
    );
  };

  const renderStatusValue = (
    status: ApprovalStatuses,
    item: IApprovalMember,
  ) => {
    if (status === ApprovalStatuses.Pending) return '';
    const approvalDate = moment(item.approvalDate).format(
      DEFAULT_DATE_TIME_FORMAT,
    );

    switch (status) {
      case ApprovalStatuses.Approved:
        return `${t('onboard_status_short.onboarding_approved')} - ${approvalDate}`;
      case ApprovalStatuses.Rejected:
        return `${t('onboard_status_short.onboarding_rejected')} - ${approvalDate}`;
      default:
        return '';
    }
  };

  const getStatusTextColor = (status = 0) => {
    switch (status) {
      case ApprovalStatuses.Approved:
        return 'successColor';
      case ApprovalStatuses.Rejected:
        return 'errorColor';
      default:
        return '';
    }
  };

  const renderContentCell = (dataKey: string) => {
    switch (dataKey) {
      case 'group-info':
        return renderCol('userFullName');

      case 'status-info':
        return renderCol('approvalAction');

      case 'note-info':
        return renderCol('approvalReason');

      default:
        return null;
    }
  };
  return <StyledTableCol>{renderContentCell(dataIndex)}</StyledTableCol>;
};

const StyledTableCol = styled.td`
  padding: 0px !important;
`;

const GroupTitleRow = styled(Typography.Paragraph)<{
  isGroupName?: boolean;
  isMarginTop?: boolean;
}>`
  min-height: 35px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 0px !important;
  margin-top: ${({ theme, isMarginTop }) =>
    isMarginTop ? theme.paddingXl : ''};

  color: ${({ theme, isGroupName }) => (isGroupName ? theme.successColor : '')};
`;

const ORTextWrapper = styled.div`
  position: absolute;
  bottom: -13px;
  left: 18px;
  width: 50px;
  text-align: center;
  background: ${({ theme }) => theme.backgroundColor2};
`;

const AndOrText = styled(Typography.Text)`
  font-weight: 500;
`;

const StyledListItem = styled(List.Item)`
  min-height: 45px;
  text-transform: capitalize;
  color: ${({ theme, color }: { color?: string; theme: any }) =>
    color ? theme[color] : ''} !important;
`;

export default ContentCell;
