import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ClientApprovalEntryModel } from 'apiAdapters/approval/clientAccountManagementRulesAdapterAPI';
import { ClientApprovalWorkflowChangeTypes } from 'typings/approval/approvalWorkflow';

// components
import SectionIntro from '@common_components/Texts/SectionIntro';
import InnerTemplate from './InnerTemplate';

export interface AccountForApprovalModel {
  accountName: string;
  accountNumber: string;
  accountCurrency: string;
}

export interface BankAccountTemplateData {
  bankAccount: AccountForApprovalModel;
}

interface IProps {
  approvalEntry: ClientApprovalEntryModel<BankAccountTemplateData>;
}

const BankAccount = ({ approvalEntry }: IProps) => {
  const { t } = useTranslation('crm');

  const renderContent = () => {
    switch (approvalEntry.entry.changeType) {
      case ClientApprovalWorkflowChangeTypes.Create:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t(
                'client_group.administration_rules.pending_approvals.template_change_versions.new',
              )}
            />
            <InnerTemplate
              data={approvalEntry.changes.new as BankAccountTemplateData}
            />
          </>
        );

      case ClientApprovalWorkflowChangeTypes.Delete:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t(
                'client_group.administration_rules.pending_approvals.template_change_versions.removed',
              )}
            />
            <InnerTemplate
              data={approvalEntry.changes.new as BankAccountTemplateData}
            />
          </>
        );

      case ClientApprovalWorkflowChangeTypes.Edit:
        return (
          <>
            <SectionIntro
              titleVariant="h5"
              title={t(
                'client_group.administration_rules.pending_approvals.template_change_versions.old',
              )}
            />
            <InnerTemplate
              data={approvalEntry.changes.old as BankAccountTemplateData}
            />

            <SectionIntro
              titleVariant="h5"
              title={t(
                'client_group.administration_rules.pending_approvals.template_change_versions.new',
              )}
            />
            <InnerTemplate
              data={approvalEntry.changes.new as BankAccountTemplateData}
            />
          </>
        );
    }
  };

  return <>{renderContent()}</>;
};

export default BankAccount;
