import { IAccountCategoryShort } from '../../../typings/finance/accountCategories';

export interface AccountCategoryWithKey extends IAccountCategoryShort {
  key: number;
}

export const formatAccountCategory = (
  accountCategory: IAccountCategoryShort,
): AccountCategoryWithKey => {
  const children = accountCategory.children?.length
    ? accountCategory.children.map(formatAccountCategory)
    : [];
  const result: AccountCategoryWithKey = {
    ...accountCategory,
    key: accountCategory.id,
    children,
  };
  return result;
};
