import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useActiveTab from '@hooks/useActiveTab';
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import { IContactModel } from 'typings/crm/contact';

// components
import RDC from './Contents/RDC';
import Notes from './Contents/Notes';
import Details from './Contents/Details';
import Approval from './Contents/Approval';
import Workflow from './Contents/Workflow';
import Documents from './Contents/Documents';
import Compliance from './Contents/Compliance';
import Invitations from './Contents/Invitations';
import ClientGroups from './Contents/ClientGroups';
import SectionIntro from '@common_components/Texts/SectionIntro';
import Relationships from './Contents/Relationships';
import Identification from './Contents/Identification';
import ContactInformation from './Contents/ContactInformation';
import { Col, Divider, Menu, Row } from 'antd';

interface IProps {
  contact: IContactModel;
  updateContact: () => void;
}

const ContactDetailsPageContent = memo(({ contact, updateContact }: IProps) => {
  const { t } = useTranslation('crm');
  const { activeKey, setActiveKey } = useActiveTab<
    keyof typeof contentData | null
  >('details');

  const [
    hasDetailsTabAccess,
    hasRelationshipsTabAccess,
    hasIdentificationTabAccess,
    hasDocumentsTabAccess,
    hasNotesTabAccess,
    hasRDCTabAccess,
  ] = useUserAccess([
    'CRM_Contacts_Details_DetailsTab_View',
    'CRM_Contacts_Details_RelationshipsTab_View',
    'CRM_Contacts_IdentificationTab_View',
    'CRM_Contacts_Details_DocumentsTab_View',
    'CRM_Contacts_Details_NotesTab_View',
    'CRM_Contacts_Details_RDCTab_View',
  ]);

  const contentData = useMemo(() => {
    return {
      details: {
        hidden: !hasDetailsTabAccess,
        title: t('contacts.tabs.details'),
        content: <Details contact={contact} />,
      },

      contact: {
        hidden: !hasDetailsTabAccess,
        title: t('contacts.tabs.contact_info'),
        content: (
          <ContactInformation
            contact={contact}
            updateContactData={updateContact}
          />
        ),
      },

      identification: {
        hidden: !hasIdentificationTabAccess,
        title: t('contacts.tabs.identification'),
        content: (
          <Identification contact={contact} updateContactData={updateContact} />
        ),
      },

      relationships: {
        hidden: !hasRelationshipsTabAccess,
        title: t('contacts.tabs.relationships'),
        content: <Relationships contact={contact} />,
      },

      documents: {
        hidden: !hasDocumentsTabAccess,
        title: t('contacts.tabs.documents'),
        content: <Documents contact={contact} />,
      },

      notes: {
        hidden: !hasNotesTabAccess,
        title: t('contacts.tabs.notes'),
        content: <Notes contact={contact} />,
      },

      rdc: {
        hidden: !hasRDCTabAccess,
        title: t('contacts.tabs.rdc'),
        content: <RDC contact={contact} />,
      },

      clientGroups: {
        hidden: false, // TODO: add permission check
        title: t('contacts.tabs.client_groups'),
        content: <ClientGroups contact={contact} />,
      },

      invitations: {
        hidden: !contact.isInvitationPermitted,
        title: t('contacts.tabs.invitations'),
        content: <Invitations contact={contact} />,
      },

      approval: {
        hidden: false, // TODO: add permission check
        title: t('contacts.tabs.approval'),
        content: (
          <Approval contact={contact} updateContactData={updateContact} />
        ),
      },

      workflow: {
        hidden: false, // TODO: add permission check
        title: t('contacts.tabs.workflow'),
        content: (
          <Workflow contact={contact} updateContactCallback={updateContact} />
        ),
      },

      compliance: {
        hidden: false, // TODO: add permission check
        title: t('contacts.tabs.compliance'),
        content: <Compliance contact={contact} />,
      },
    };
  }, [
    contact,
    hasDetailsTabAccess,
    hasIdentificationTabAccess,
    hasRelationshipsTabAccess,
    hasDocumentsTabAccess,
    hasNotesTabAccess,
    hasRDCTabAccess,
    updateContact,
  ]);

  return (
    <Row gutter={[16, 16]} wrap={false}>
      <Col flex="215px">
        <Menu
          mode="inline"
          selectedKeys={activeKey ? [activeKey] : undefined}
          onSelect={({ key }) =>
            key && setActiveKey(key as keyof typeof contentData)
          }
        >
          {Object.keys(contentData).map(
            (key) =>
              !contentData[key as keyof typeof contentData].hidden && (
                <StyledMenuItem key={key}>
                  {contentData[key as keyof typeof contentData].title}
                </StyledMenuItem>
              ),
          )}
        </Menu>
      </Col>

      <Col>
        <StyledDivider type="vertical" />
      </Col>

      <Col flex="auto">
        {activeKey && (
          <>
            <SectionIntro
              titleVariant="h4"
              title={contentData[activeKey].title}
            />
            {contentData[activeKey].content}
          </>
        )}
      </Col>
    </Row>
  );
});

const StyledMenuItem = styled(Menu.Item)`
  margin: 0px !important;
  padding-left: 0px !important;
`;

const StyledDivider = styled(Divider)`
  margin: 0px;
  height: 100% !important;
  border: ${({ theme }) => `1px solid ${theme.cardBorderColor}`};
`;

export default ContactDetailsPageContent;
