import React from 'react';

// components
import ExpiringDocuments from 'modules/Compliance/ExpiringDocuments';

const ExpiringDocumentsPage = () => {
  return <ExpiringDocuments />;
};

export default ExpiringDocumentsPage;
