import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import { darkTheme } from '@resources/theme/styled';

// components
import IconSVG from '@core_components/IconSVG';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import Button, { ButtonProps } from '@core_components/Button';
import { ReactComponent as PlusIcon } from '@resources/icons/remix-icons/add-line.svg';

interface IProps extends ButtonProps {
  children: React.ReactNode;
}

const AddButton = memo(({ children, ...rest }: IProps) => {
  return (
    <Button type="bordered" {...rest}>
      <DivAlignCenter>
        <StyledIconSVG component={PlusIcon} color={darkTheme.whiteColor} />
        {children}
      </DivAlignCenter>
    </Button>
  );
});

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginXXs};
`;

export default AddButton;
