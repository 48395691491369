import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { Moment } from 'moment';

// components
import DatePicker from '../../../../components/Forms/FormComponents/DatePicker';
import { Col, Row } from 'antd';

interface IProps {
  dateRangeValue: [Moment, Moment] | null;
  filterChange: (key: string, value: Moment | null) => void;
  isDisabled: boolean;
}

const FilterBar = ({ filterChange, dateRangeValue, isDisabled }: IProps) => {
  const { t } = useTranslation('reporting');
  const disabledEndDate = (date: Moment) =>
    !date ||
    (Array.isArray(dateRangeValue) && date.isBefore(dateRangeValue[0]));
  const disabledStartDate = (date: Moment) =>
    !date || (Array.isArray(dateRangeValue) && date.isAfter(dateRangeValue[1]));
  const filterGridSizes = { xl: 6, lg: 10, md: 24, sm: 24, xs: 24 };

  return (
    <Row gutter={[16, 16]}>
      <Col {...filterGridSizes}>
        <Row>
          <Col span={24}>
            {t('threshold_reporting.incoming_transfers.start_date')}
          </Col>
          <Col span={24}>
            <StyledDatePicker
              size="large"
              value={
                Array.isArray(dateRangeValue) ? dateRangeValue[0] : undefined
              }
              disabledDate={disabledStartDate}
              onChange={(newDate: any) => filterChange('startDate', newDate)}
              disabled={isDisabled}
            />
          </Col>
        </Row>
      </Col>
      <Col {...filterGridSizes}>
        <Row>
          <Col span={24}>
            {t('threshold_reporting.incoming_transfers.end_date')}
          </Col>
          <Col span={24}>
            <StyledDatePicker
              size="large"
              value={
                Array.isArray(dateRangeValue) ? dateRangeValue[1] : undefined
              }
              disabledDate={disabledEndDate}
              onChange={(newDate: any) => filterChange('endDate', newDate)}
              disabled={isDisabled}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

export default FilterBar;
