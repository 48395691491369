import React, { useState } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import { ActionKeys } from 'components/Tables/TableTemplates/ApprovalManagement/ClientApprovalGroupsTable';
import { ClientApprovalGroup } from 'typings/approval/approvalGroups';

// components
import PageButton from '@common_components/Buttons/PageButton';
import ApprovalGroupsTable from './ApprovalGroupsTable';
import AddApprovalGroupModal from './AddApprovalGroupModal';
import EditApprovalGroupModal from './EditApprovalGroupModal';
import { Col, Row } from 'antd';

interface IProps {
  clientGroupId: number;
}

const ApprovalGroupsTab = ({ clientGroupId }: IProps) => {
  const { t } = useTranslation('crm');

  const [hasCreateGroupAccess] = useUserAccess([
    'CRM_ClientGroup_Details_AdministrationRules_ApprovalGroups_Create',
  ]);

  const [updateTrigger, updateTable] = useState({});
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [isAddApprovalGroupModal, setIsAddApprovalGroupModal] = useState(false);

  const handleOnActionsClick = (
    key: ActionKeys,
    record: ClientApprovalGroup,
  ) => {
    switch (key) {
      case 'view':
        setSelectedGroup(record.id);
        break;
    }
  };

  const handleAddGroupClick = () => {
    setIsAddApprovalGroupModal(true);
  };

  const handleModalClose = (wasCreated?: boolean) => {
    wasCreated && updateTable({});
    setIsAddApprovalGroupModal(false);
  };

  const handleCloseEditModal = (wasUpdated?: boolean) => {
    wasUpdated && updateTable({});
    setSelectedGroup(null);
  };

  return (
    <>
      {hasCreateGroupAccess && (
        <Row justify="end">
          <Col flex="150px">
            <PageButton fullWidth onClick={handleAddGroupClick}>
              {t(
                'client_group.approval.client_approval_groups.add_group_button',
              )}
            </PageButton>
          </Col>
        </Row>
      )}
      <ApprovalGroupsTable
        clientGroupId={clientGroupId}
        onActionsClick={handleOnActionsClick}
        updateTrigger={updateTrigger}
      />
      <AddApprovalGroupModal
        isVisible={isAddApprovalGroupModal}
        closeCallback={handleModalClose}
        clientGroupId={clientGroupId}
      />
      <EditApprovalGroupModal
        isVisible={!!selectedGroup}
        closeCallback={handleCloseEditModal}
        clientGroupId={clientGroupId}
        groupId={selectedGroup}
      />
    </>
  );
};

export default ApprovalGroupsTab;
