import * as yup from 'yup';
import { STRING_VALIDATION_RULE } from 'validations/common';
import {
  AdditionalFieldType,
  RelationshipRelatedTypes,
} from '../../../enums/crm/crm';

export const CreateRelationshipTemplate = yup.object().shape({
  parentName: STRING_VALIDATION_RULE.min(
    5,
    'Parent Name should have at least 5 characters long.',
  )
    .max(30, 'Parent Name cannot be more than 30 characters long.')
    .required('Parent Name is required.'),
  childName: STRING_VALIDATION_RULE.min(
    5,
    'Child Name should have at least 5 characters long.',
  )
    .max(30, 'Child Name cannot be more than 30 characters long.')
    .required('Child Name is required.'),
  additionalFields: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .min(3, 'Title should have at least 3 characters long.')
        .max(100, 'Title cannot be more than 100 characters long.')
        .required('Title is required.'),
      type: STRING_VALIDATION_RULE.oneOf(
        Object.values(AdditionalFieldType),
      ).required('Field Type is required.'),
      options: yup.object().shape({
        selectOptionsValues: yup
          .array()
          .of(
            yup
              .mixed()
              .test(
                'minLength',
                'Answer should have at least 2 characters long.',
                (val) => {
                  return String(val).length > 2;
                },
              )
              .test(
                'maxLength',
                'Answer cannot be more than 30 characters long.',
                (val) => String(val).length < 30,
              ),
          )
          .min(1),
        formDocument: yup
          .array()
          .nullable()
          .min(1, 'This field is requried')
          .max(1, 'Only one document is required'),
        documentRequiresCertification: yup.boolean(),
      }),
      relatedTo: STRING_VALIDATION_RULE.oneOf(
        Object.values(RelationshipRelatedTypes),
      ).required('This field is required.'),
    }),
  ),
});
