import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { useField } from 'formik';
import { TWO_COL_GRID_SIZES } from 'constants/grids';

// components
import Text from '@core_components/Text';
import Checkbox from '@core_components/Checkbox';
import FormInput from '@common_components/Form/FormInput';
import FormField from '@core_components/FormField';
import TrueFalseFormSelect from 'components/Forms/FormComponents/SelectInputs/TrueFalseFormSelect';
import { Col, Row } from 'antd';

export type FormValuesModel = {
  isSelfEmployed: boolean | null;
  employerName: string;
  occupation: string;
  natureOfBusiness: string;
  isBusinessUnderOwnName: boolean | null;
};

interface IProps {
  disabled?: boolean;
  fieldName: string;
}

const EmploymentHistoryShortForm = memo(({ disabled, fieldName }: IProps) => {
  const { t } = useTranslation('crm');
  const [field] = useField<FormValuesModel>(fieldName);
  const isSelfEmployed = !!field.value.isSelfEmployed;
  const isSelfEmployedAnswered =
    typeof field.value.isSelfEmployed === 'boolean';

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.kyc_form.sow.is_self_employed')}
            name={`${fieldName}.isSelfEmployed`}
            component={TrueFalseFormSelect}
            disabled={disabled}
          />
        </Col>
      </Row>

      {isSelfEmployedAnswered &&
        (isSelfEmployed ? (
          <>
            <Row gutter={[16, 16]} align="middle">
              <Col {...TWO_COL_GRID_SIZES}>
                <FormField
                  name={`${fieldName}.employerName`}
                  label={t('contacts.kyc_form.sow.employer_name')}
                  component={FormInput}
                  disabled={disabled || !!field.value.isBusinessUnderOwnName}
                />
              </Col>
              <Col {...TWO_COL_GRID_SIZES}>
                <CheckboxFieldWrapper>
                  <FormField
                    shouldShowErrorMessage={false}
                    name={`${fieldName}.isBusinessUnderOwnName`}
                    disabled={disabled}
                    component={StyledCheckbox}
                  />
                  <Text variant="body1">
                    {t('contacts.kyc_form.sow.is_business_under_own_name')}
                  </Text>
                </CheckboxFieldWrapper>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col {...TWO_COL_GRID_SIZES}>
                <FormField
                  name={`${fieldName}.natureOfBusiness`}
                  label={t('contacts.kyc_form.sow.nature_of_business')}
                  component={FormInput}
                  disabled={disabled}
                />
              </Col>

              <Col {...TWO_COL_GRID_SIZES}>
                <FormField
                  name={`${fieldName}.occupation`}
                  label={t('contacts.kyc_form.sow.occupation')}
                  component={FormInput}
                  disabled={disabled || !!field.value.isBusinessUnderOwnName}
                />
              </Col>
            </Row>
          </>
        ) : (
          <Row gutter={[16, 16]}>
            <Col {...TWO_COL_GRID_SIZES}>
              <FormField
                name={`${fieldName}.employerName`}
                label={t('contacts.kyc_form.sow.employer')}
                component={FormInput}
                disabled={disabled}
              />
            </Col>
            <Col {...TWO_COL_GRID_SIZES}>
              <FormField
                name={`${fieldName}.occupation`}
                label={t('contacts.kyc_form.sow.occupation')}
                component={FormInput}
                disabled={disabled}
              />
            </Col>
          </Row>
        ))}
    </>
  );
});

const StyledCheckbox = styled(Checkbox)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

const CheckboxFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${({ theme }) => theme.marginSm};
`;

export default EmploymentHistoryShortForm;
