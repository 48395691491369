import { RouteModel } from '../typings/routes';

// routes
import { authRoutes, AuthRoutesEnum } from './authRoutes';
import { ProfileRoutesEnum, userProfileRoutes } from './userProfileRoutes';
import {
  verificationRoutes,
  VerificationRoutesEnum,
} from './verificationRoutes';
import {
  userRoutes,
  CombinedUserRouteEnums,
  UserRoutesEnum,
} from './userRoutes';

// layouts
import MainLayout from '../layouts/MainLayout';
import BlankLayout from '../layouts/BlankLayout';
import { CRMRoutesEnum } from './userRoutes/crmRoutes';
import { ORACRoutesEnum } from './userRoutes/ORACRoutes';
import { BankingRoutesEnum } from './userRoutes/bankingRoutes';
import { FinancesRoutesEnum } from './userRoutes/financeRoutes';
import { ReportingRoutesEnum } from './userRoutes/reportingRoutes';
import { ComplianceRoutesEnum } from './userRoutes/complianceRoutes';
import { ApprovalManagementRoutesEnum } from './userRoutes/approvalManagementRoutes';

// Merged routes
const RoutePaths = {
  ...AuthRoutesEnum,
  ...ProfileRoutesEnum,
  ...CRMRoutesEnum,
  ...CombinedUserRouteEnums,
  ...VerificationRoutesEnum,
  ...UserRoutesEnum,
  ...ORACRoutesEnum,
  ...FinancesRoutesEnum,
  ...ReportingRoutesEnum,
  ...ApprovalManagementRoutesEnum,
  ...BankingRoutesEnum,
  ...ComplianceRoutesEnum,
};

type RoutePathsType =
  | AuthRoutesEnum
  | ProfileRoutesEnum
  | UserRoutesEnum
  | CRMRoutesEnum
  | ORACRoutesEnum
  | FinancesRoutesEnum
  | ReportingRoutesEnum
  | ApprovalManagementRoutesEnum
  | BankingRoutesEnum
  | ComplianceRoutesEnum
  | VerificationRoutesEnum;

const indexRoutes: RouteModel[] = [
  {
    path: RoutePaths.Auth_Root,
    title: 'Authorization',
    layout: BlankLayout,
    isPrivate: false,
    routes: authRoutes,
  },
  {
    path: RoutePaths.VerificationRoot,
    title: 'Verification',
    layout: BlankLayout,
    isPrivate: true,
    routes: verificationRoutes,
  },
  {
    path: RoutePaths.Profile_Root,
    title: 'Profile',
    layout: MainLayout,
    isPrivate: true,
    routes: userProfileRoutes,
  },

  {
    path: RoutePaths.UserRoutes_Root,
    title: 'System Administration',
    layout: MainLayout,
    isPrivate: true,
    routes: userRoutes,
  },
];

export type { RoutePathsType };
export { indexRoutes, RoutePaths };
