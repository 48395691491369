export const COUNTRY_CODES: { [key: string]: string } = {
  AF: 'Afghanistan',
  AX: 'Aland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua And Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia And Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo, Democratic Republic',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Cote D'Ivoire",
  HR: 'Croatia',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard Island & Mcdonald Islands',
  VA: 'Holy See (Vatican City State)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran, Islamic Republic Of',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle Of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KR: 'Korea',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: "Lao People's Democratic Republic",
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libyan Arab Jamahiriya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States Of',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory, Occupied',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  BL: 'Saint Barthelemy',
  SH: 'Saint Helena',
  KN: 'Saint Kitts And Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin',
  PM: 'Saint Pierre And Miquelon',
  VC: 'Saint Vincent And Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome And Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia And Sandwich Isl.',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard And Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syrian Arab Republic',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad And Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks And Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  US: 'United States',
  UM: 'United States Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, U.S.',
  WF: 'Wallis And Futuna',
  EH: 'Western Sahara',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

export const NATIONALITY_CODES: { [key: string]: string } = {
  AF: 'Afghan',
  AX: 'Aland Islands', //
  AL: 'Albanian',
  DZ: 'Algerian',
  AS: 'American',
  AD: 'Andorran',
  AO: 'Angolan',
  AI: 'Anguilla', //
  AQ: 'Antarctica', //
  AG: 'Antiguans',
  AR: 'Argentinean',
  AM: 'Armenian',
  AW: 'Aruba', //
  AU: 'Australian',
  AT: 'Austrian',
  AZ: 'Azerbaijani',
  BS: 'Bahamian',
  BH: 'Bahraini',
  BD: 'Bangladeshi',
  BB: 'Barbadian',
  BY: 'Belarusian',
  BE: 'Belgian',
  BZ: 'Belizean',
  BJ: 'Beninese',
  BM: 'Bermuda', //
  BT: 'Bhutanese',
  BO: 'Bolivian',
  BA: 'Bosnian',
  BW: 'Botswana', //
  BV: 'Bouvet Island', //
  BR: 'Brazilian',
  IO: 'British',
  BN: 'Bruneian',
  BG: 'Bulgarian',
  BF: 'Burkinabe',
  BI: 'Burundian',
  KH: 'Cambodian',
  CM: 'Cameroonian',
  CA: 'Canadian',
  CV: 'Cape Verdean',
  KY: 'Cayman Islands', //
  CF: 'Central African',
  TD: 'Chadian',
  CL: 'Chilean',
  CN: 'Chinese',
  CX: 'Christmas Island', //
  CC: 'Cocos (Keeling) Islands', //
  CO: 'Colombian',
  KM: 'Comoran',
  CG: 'Congolese',
  CD: 'Congo, Democratic Republic', //
  CK: 'Cook Islands', //
  CR: 'Costa Rican',
  CI: "Cote D'Ivoire", //
  HR: 'Croatian',
  CU: 'Cuban',
  CY: 'Cypriot',
  CZ: 'Czech',
  DK: 'Danish',
  DJ: 'Djibouti',
  DM: 'Dominica', //
  DO: 'Dominican',
  EC: 'Ecuadorean',
  EG: 'Egyptian',
  SV: 'El Salvador', //
  GQ: 'Equatorial Guinean',
  ER: 'Eritrean',
  EE: 'Estonian',
  ET: 'Ethiopian',
  FK: 'Falkland Islands', //
  FO: 'Faroe Islands', //
  FJ: 'Fijian',
  FI: 'Finnish',
  FR: 'French',
  GF: 'French Guiana', //
  PF: 'French Polynesia', //
  TF: 'French Southern Territories', //
  GA: 'Gabonese',
  GM: 'Gambian',
  GE: 'Georgian',
  DE: 'German',
  GH: 'Ghanaian',
  GI: 'Gibraltar', //
  GR: 'Greek',
  GL: 'Greenland', //
  GD: 'Grenadian',
  GP: 'Guadeloupe', //
  GU: 'Guam', //
  GT: 'Guatemalan',
  GG: 'Guernsey', //
  GN: 'Guinean',
  GW: 'Guinea-Bissauan',
  GY: 'Guyanese',
  HT: 'Haitian',
  HM: 'Heard Island & Mcdonald Islands', //
  VA: 'Holy See (Vatican City State)', //
  HN: 'Honduran',
  HK: 'Hong Kong', //
  HU: 'Hungarian',
  IS: 'Icelander',
  IN: 'Indian',
  ID: 'Indonesian',
  IR: 'Iranian',
  IQ: 'Iraqi',
  IE: 'Irish',
  IM: 'Isle Of Man', //
  IL: 'Israeli',
  IT: 'Italian',
  JM: 'Jamaican',
  JP: 'Japanese',
  JE: 'Jersey', //
  JO: 'Jordanian',
  KZ: 'Kazakhstani',
  KE: 'Kenyan',
  KI: 'Kiribati', //
  KR: 'Korea', //
  KW: 'Kuwaiti',
  KG: 'Kyrgyz',
  LA: 'Laotian',
  LV: 'Latvian',
  LB: 'Lebanese',
  LS: 'Lesotho', //
  LR: 'Liberian',
  LY: 'Libyan',
  LI: 'Liechtensteiner',
  LT: 'Lithuanian',
  LU: 'Luxembourger',
  MO: 'Macao', //
  MK: 'Macedonian',
  MG: 'Madagascar', //
  MW: 'Malawian',
  MY: 'Malaysian',
  MV: 'Maldivan',
  ML: 'Malian',
  MT: 'Maltese',
  MH: 'Marshallese',
  MQ: 'Martinique', //
  MR: 'Mauritanian',
  MU: 'Mauritian',
  YT: 'Mayotte', //
  MX: 'Mexican',
  FM: 'Micronesian',
  MD: 'Moldovan',
  MC: 'Monacan',
  MN: 'Mongolian',
  ME: 'Montenegro', //
  MS: 'Montserrat', //
  MA: 'Moroccan',
  MZ: 'Mozambican',
  MM: 'Myanmar', //
  NA: 'Namibian',
  NR: 'Nauruan',
  NP: 'Nepalese',
  NL: 'Netherlands', //
  AN: 'Netherlands Antilles', //
  NC: 'New Caledonia', //
  NZ: 'New Zealander',
  NI: 'Nicaraguan',
  NE: 'Nigerian',
  NG: 'Nigerien',
  NU: 'Niue', //
  NF: 'Norfolk Island', //
  MP: 'Northern Irish',
  NO: 'Norwegian',
  OM: 'Omani',
  PK: 'Pakistani',
  PW: 'Palauan',
  PS: 'Palestinian Territory, Occupied', //
  PA: 'Panamanian',
  PG: 'Papua New Guinean',
  PY: 'Paraguayan',
  PE: 'Peruvian',
  PH: 'Philippines', //
  PN: 'Pitcairn', //
  PL: 'Polish',
  PT: 'Portuguese',
  PR: 'Puerto Rico', //
  QA: 'Qatari',
  RE: 'Reunion', //
  RO: 'Romanian',
  RU: 'Russian',
  RW: 'Rwandan',
  BL: 'Saint Barthelemy', //
  SH: 'Saint Helena', //
  KN: 'Saint Kitts And Nevis', //
  LC: 'Saint Lucian',
  MF: 'Saint Martin', //
  PM: 'Saint Pierre And Miquelon', //
  VC: 'Saint Vincent And Grenadines', //
  WS: 'Samoan',
  SM: 'San Marinese',
  ST: 'Sao Tomean',
  SA: 'Saudi',
  SN: 'Senegalese',
  RS: 'Serbian',
  SC: 'Seychellois',
  SL: 'Sierra Leonean',
  SG: 'Singaporean',
  SK: 'Slovakian',
  SI: 'Slovenian',
  SB: 'Solomon Islander',
  SO: 'Somali',
  ZA: 'South African',
  GS: 'South Georgia And Sandwich Isl.', //
  ES: 'Spanish',
  LK: 'Sri Lankan',
  SD: 'Sudanese',
  SR: 'Surinamer',
  SJ: 'Svalbard And Jan Mayen', //
  SZ: 'Swazi',
  SE: 'Swedish',
  CH: 'Swiss',
  SY: 'Syrian',
  TW: 'Taiwanese',
  TJ: 'Tajik',
  TZ: 'Tanzanian',
  TH: 'Thai',
  TL: 'Timor-Leste', //
  TG: 'Togolese',
  TK: 'Tokelau', //
  TO: 'Tongan',
  TT: 'Trinidadian/Tobagonian',
  TN: 'Tunisian',
  TR: 'Turkish',
  TM: 'Turkmenistan', //
  TC: 'Turks And Caicos Islands', //
  TV: 'Tuvaluan',
  UG: 'Ugandan',
  UA: 'Ukrainian',
  AE: 'United Arab Emirates', //
  GB: 'United Kingdom', //
  US: 'United States', //
  UM: 'United States Outlying Islands', //
  UY: 'Uruguayan',
  UZ: 'Uzbekistani',
  VU: 'Vanuatu', //
  VE: 'Venezuelan',
  VN: 'Vietnamese',
  VG: 'Virgin Islands, British', //
  VI: 'Virgin Islands, U.S.', //
  WF: 'Wallis And Futuna', //
  EH: 'Western Sahara', //
  YE: 'Yemenite',
  ZM: 'Zambian',
  ZW: 'Zimbabwean',
};
