import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { useFormikContext } from 'formik';
import {
  ApprovalConditionModel,
  ApprovalLimitModel,
  FormValuesModel,
} from '..';

// components
import ContentCell from './ContentCell';
import { Button, Table } from 'antd';

interface IProps {
  limits: ApprovalLimitModel[];
}

export enum ApprovalMatrixColumnTypes {
  ApprovalCondition = 'approval-condition',
  ApprovalThreashold = 'approval-threashold',
}

const InnerMatrix = ({ limits }: IProps) => {
  const { t } = useTranslation('approval_management');
  const { values, isSubmitting, setFieldValue } =
    useFormikContext<FormValuesModel>();

  const tableColumns: any = useMemo(() => {
    let result = [];

    const firstColumn = {
      key: 'approvalRuleItems',
      width: '300px',
      title: t('approval_rules.form.approval_group_condition'),
      dataIndex: ApprovalMatrixColumnTypes.ApprovalCondition,
    };

    const statusColumns = limits.map(({ id, name }, i) => ({
      title: name,
      key: `status-col-${i}`,
      dataIndex: ApprovalMatrixColumnTypes.ApprovalThreashold,
      threasholdId: id,
    }));

    const mergedColumns = [firstColumn, ...statusColumns];

    result = mergedColumns.map((col: any, index: number) => {
      const baseProps = {
        title: col.title,
        dataIndex: col.dataIndex,
        colIndex: index > 0 ? index - 1 : 0,
        threasholdId: col?.threasholdId,
      };

      return {
        ...col,
        onCell: (record: ApprovalConditionModel, rowIndex: number) => ({
          ...baseProps,
          record,
          rowIndex,
        }),
      };
    });

    return result;
  }, [limits]);

  const handleAddNewCondition = () => {
    const thresholdStatuses: { [key: string]: { ticked: boolean } } = {};

    limits.forEach(({ id }) => (thresholdStatuses[id] = { ticked: true }));

    const initialApprovalRule = {
      thresholdStatuses,
      approvalGroupConditions: [
        {
          approvalGroupId: null,
          requiredApprovers: null,
          maximumRequiredApprovers: 0,
        },
      ],
    };

    setFieldValue('approvalConditions', [
      ...values.approvalConditions,
      initialApprovalRule,
    ]);
  };

  return (
    <>
      <Table
        bordered
        size="large"
        columns={tableColumns}
        pagination={false}
        scroll={{ x: 'max-content' }}
        className="disable-table-row-hover"
        dataSource={values.approvalConditions}
        components={{ body: { cell: ContentCell } }}
      />

      {values.isEditMode && (
        <ButtonWrapper>
          <Button
            type="primary"
            onClick={handleAddNewCondition}
            disabled={isSubmitting}
          >
            {t('approval_management.approval_rules.add_or')}
          </Button>
        </ButtonWrapper>
      )}
    </>
  );
};

const ButtonWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`;

export default InnerMatrix;
