import React, { useMemo, memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { IEntity } from '../../../../../typings/crm/entity';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { DateHelpers } from '@helpers/date';
import { COUNTRY_CODES } from '../../../../../constants/countryCodes';
import { DEFAULT_DATE_FORMAT } from '../../../../../constants/global';

//components
import ClientGroupStatus from 'components/Additional/Statuses/ClientGroupStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Tag, Typography } from 'antd';

type IProps = RequiredPropsForTableModel<IEntity>;

const EntitiesTable = memo((props: IProps) => {
  const { t } = useTranslation('crm');

  const history = useHistory();

  const handleTradeNameClick = (entityId: string) =>
    history.push({
      pathname: RoutePaths.CRM_Entities_Edit,
      search: `?id=${entityId}`,
    });

  const renderPrimaryAddress = ({ address }: IEntity) => {
    if (!address) return '';

    const addressArray = [
      address.street,
      address.city,
      address.state,
      address.postalCode,
      address.country,
    ];

    return addressArray.filter((e) => e).join(', ');
  };

  const renderWebsiteLink = ({ socialMedia }: IEntity) => {
    if (!socialMedia?.length) return '';

    const websiteIndex = socialMedia.findIndex((el) => el.type === 'website');

    return (
      websiteIndex !== -1 && (
        <StyledLinkText
          ellipsis
          target="_blank"
          rel="noopener noreferrer"
          href={socialMedia[websiteIndex].path}
        >
          {socialMedia[websiteIndex].path}
        </StyledLinkText>
      )
    );
  };

  const renderSocialMedia = ({ socialMedia }: IEntity) => {
    if (!socialMedia || !socialMedia.length) {
      return null;
    }

    const socialMediaCopy = socialMedia.slice();

    const websiteIndex = socialMedia.findIndex((el) => el.type === 'website');

    if (websiteIndex !== -1) {
      socialMediaCopy.splice(websiteIndex, 1);
    }

    return socialMediaCopy.map((url, i: number) => (
      <div key={i}>
        <StyledSocialMediaLink
          href={url.path}
          target="_blank"
          rel="noopener noreferrer"
        >
          {url.type}
        </StyledSocialMediaLink>
      </div>
    ));
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: 300,
        key: 'name',
        title: t('entity.new.entityName'),
        render: (record: IEntity) => (
          <NamesWrapper>
            {record.names.map(({ name, type }) =>
              type === 'trade' ? (
                <StyledTradeName>{name}</StyledTradeName>
              ) : (
                <Typography.Link
                  key={name}
                  onClick={() => handleTradeNameClick(record._id)}
                >
                  {name}
                </Typography.Link>
              ),
            )}
          </NamesWrapper>
        ),
        mobileCardProps: { isCardTitle: true },
      },
      {
        width: 200,
        key: 'status',
        title: t('entity.table.status'),
        render: (record: IEntity) =>
          record.clientGroup ? (
            <ClientGroupStatus status={record.clientGroup?.status} />
          ) : (
            ''
          ),
      },
      {
        width: 200,
        key: 'dateOfIncorporation',
        title: t('entity.table.dateOfIncorporation'),
        render: (record: IEntity) =>
          record.dateOfIncorporation &&
          DateHelpers.formatDateToUTC(record.dateOfIncorporation).format(
            DEFAULT_DATE_FORMAT,
          ),
      },
      {
        width: 270,
        key: 'jurisdiction',
        title: t('entity.new.country_of_incorporation'),
        render: (record: IEntity) => COUNTRY_CODES[record.jurisdiction] || '',
      },
      {
        width: 200,
        key: 'industryCodes',
        title: t('entity.table.industryCodes'),
        render: (record: IEntity) =>
          !record.industryCodes || !record.industryCodes.length
            ? null
            : record.industryCodes.map((code) => (
                <Tag color="blue" key={code}>
                  {code}
                </Tag>
              )),
      },
      {
        width: 270,
        key: 'address',
        title: t('entity.table.primaryBusinessAddress'),
        render: renderPrimaryAddress,
      },
      {
        width: 250,
        key: 'website',
        title: t('entity.table.website'),
        render: renderWebsiteLink,
      },
      {
        width: 200,
        key: 'socialMedia',
        title: t('entity.table.socialMedias'),
        render: renderSocialMedia,
      },
    ];

    return result;
  }, []);

  return <Table columns={columns} {...props} />;
});

const NamesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTradeName = styled(Typography.Text)`
  font-style: italic;
`;

const StyledLinkText = styled(Typography.Link)`
  max-width: 250px;
`;

const StyledSocialMediaLink = styled(Typography.Link)`
  text-transform: capitalize;
`;

export default EntitiesTable;
