import React, { useEffect } from 'react';

// helpers utils
import styled from 'styled-components';
import UploadService from '../modules/Services/UploadService';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { setUploadNotifcationStatus } from '../redux/actions/upload';

// components
import Footer from '../modules/Layout/Footer';

interface IProps extends RouteComponentProps {
  children?: React.ReactNode;
}

const AuthLayout = ({ children }: IProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Disable upload documents notification
    dispatch(setUploadNotifcationStatus(false));
  }, []);

  return (
    <StyledContentWrapper>
      {children}

      <FooterBarWrapper>
        <Footer />
      </FooterBarWrapper>

      <UploadService />
    </StyledContentWrapper>
  );
};

const StyledContentWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${({ theme }) => `
    @media screen and (max-width: ${theme.tabletScreen}) {
      overflow: hidden;
    }
  `}
`;

const FooterBarWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
`;

export default AuthLayout;
