import React, { memo } from 'react';

// helpers
import { SelectProps } from '@core_components/Select';

// components
import RangeFormSelect, { RangeOption } from '../RangeFormSelect';

export const NUMBER_OF_EMPLOYEES_ARRAY: RangeOption[] = [
  {
    min: 1,
    max: 1,
    key: 'numberOfEmployees1',
  },
  {
    min: 2,
    max: 9,
    key: 'numberOfEmployees2',
  },
  {
    min: 10,
    max: 49,
    key: 'numberOfEmployees3',
  },
  {
    min: 50,
    max: 199,
    key: 'numberOfEmployees4',
  },
  {
    min: 200,
    max: 499,
    key: 'numberOfEmployees5',
  },
  {
    min: 500,
    max: 999,
    key: 'numberOfEmployees6',
  },
  {
    min: 1000,
    max: 4999,
    key: 'numberOfEmployees7',
  },
  {
    min: 5000,
    max: 9999,
    key: 'numberOfEmployees8',
  },
  {
    min: 10000,
    key: 'numberOfEmployees9',
  },
];

export type NumberOfEmployeesFormSelectProps = SelectProps;

const NumberOfEmployeesFormSelect = memo(
  (props: NumberOfEmployeesFormSelectProps) => {
    return (
      <RangeFormSelect rangeOptions={NUMBER_OF_EMPLOYEES_ARRAY} {...props} />
    );
  },
);

export default NumberOfEmployeesFormSelect;
