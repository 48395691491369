import React, { useEffect } from 'react';

// helpers
import useUserAccess from '../../hooks/useUserAccess';
import { GENESIS_ENTITIES_DOCUMENTATION_LINK } from '../../constants/externalLinks';
import {
  ILayoutHeaderOptions,
  PageLayoutHelperPropsModel,
} from '../../layouts/PageLayout';

// components
import AddEntity from 'modules/CRM/Entities/AddEntity';
import EntitiesTable from '../../modules/CRM/Entities/EntitiesTable';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const Entities = ({ setHeaderOptions }: IProps) => {
  const [hasCreateAccess] = useUserAccess(['CRM_Entities_Create']);

  useEffect(() => {
    const headerOptions: ILayoutHeaderOptions = {
      appendToTitle: (
        <TooltipToDocumentation link={GENESIS_ENTITIES_DOCUMENTATION_LINK} />
      ),
    };
    if (hasCreateAccess) {
      headerOptions['extra'] = <AddEntity />;
    }
    setHeaderOptions(headerOptions);
  }, [hasCreateAccess]);

  return <EntitiesTable />;
};

export default Entities;
