import React, { useCallback, useEffect } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { debounce } from 'lodash';
import { AccountsHelpers } from '../../../../../helpers/finance/accounts';
import { useFormikContext } from 'formik';
import { pendingForexTransfersAPI } from '../../../../../api/banking/pendingForexTransfersAPI';
import { AUTOCOMPLETE_DEBOUNCE_DELAY } from '../../../../../constants/global';

// components
import SummarySection from './SummarySection';
import ClientRequestSection from './ClientRequestSection';
import TreasuryRequestSection from './TreasuryRequestSection';
import { Card, Col, Divider, Row, Spin } from 'antd';

export interface ForexTransferFormValues {
  transferId: number;

  fromAccountName: string;
  fromAccountNumber: string;
  fromAccountCurrency: string;
  fromCurrencyRate: number;

  toAccountName: string;
  toAccountNumber: string;
  toAccountCurrency: string;
  toCurrencyRate: number;

  isCurrencyFANNcy: boolean;
  currency: string;
  amount: number;

  fromCorrespondentAccountName: string;
  fromCorrespondentAccountNumber: string;

  toCorrespondentAccountName: string;
  toCorrespondentAccountNumber: string;

  profit: number;
  profitAccountName: string;
  profitAccountNumber: string;
  profitAccountCurrency: string;

  fromAccountMidAmount: number;
  fromAccountBaseAmount: number;

  toAccountMidAmount: number;
  toAccountBaseAmount: number;

  spread: number;
  originalSpread: number;

  isLoadingSummaryData: boolean;

  isViewOnlyMode?: boolean;
  isSpreadModified?: boolean;
}

const ForexTransferForm = () => {
  const { t } = useTranslation('banking');

  const { values, setFieldValue, setValues } =
    useFormikContext<ForexTransferFormValues>();

  const debouncedChangeHandler = useCallback(
    debounce(fetchEstimatedValue, AUTOCOMPLETE_DEBOUNCE_DELAY),
    [],
  );

  async function fetchEstimatedValue(formValues: ForexTransferFormValues) {
    const { fromCurrencyRate, toCurrencyRate, spread } = formValues;

    setFieldValue('isLoadingSummaryData', true);

    const result = await pendingForexTransfersAPI.getCalculatedTransferSummary(
      values.transferId,
      AccountsHelpers.convertAmountFromIntToBigInt(spread) as number,
      AccountsHelpers.convertAmountFromIntToBigInt(fromCurrencyRate) as number,
      AccountsHelpers.convertAmountFromIntToBigInt(toCurrencyRate) as number,
    );

    const newValues: ForexTransferFormValues = {
      ...formValues,
      fromAccountBaseAmount: AccountsHelpers.roundNumber(
        AccountsHelpers.convertAmountFromBigIntToInt(
          result.fromAccountBaseAmount,
        ) || 0,
        2,
      ),
      fromAccountMidAmount: AccountsHelpers.roundNumber(
        AccountsHelpers.convertAmountFromBigIntToInt(
          result.fromAccountMidAmount,
        ) || 0,
        2,
      ),
      toAccountBaseAmount: AccountsHelpers.roundNumber(
        AccountsHelpers.convertAmountFromBigIntToInt(
          result.toAccountBaseAmount,
        ) || 0,
        2,
      ),
      toAccountMidAmount: AccountsHelpers.roundNumber(
        AccountsHelpers.convertAmountFromBigIntToInt(
          result.toAccountMidAmount,
        ) || 0,
        2,
      ),
      profit: AccountsHelpers.roundNumber(
        AccountsHelpers.convertAmountFromBigIntToInt(result.profit) || 0,
        2,
      ),
      isLoadingSummaryData: false,
    };

    setValues(newValues);
  }

  useEffect(() => {
    if (!values.isViewOnlyMode) {
      debouncedChangeHandler(values);
    }
  }, [
    values.fromCurrencyRate,
    values.toCurrencyRate,
    values.spread,
    values.isViewOnlyMode,
  ]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <ClientRequestSection />
          </Card>
        </Col>

        <Col span={24}>
          <Divider orientation="left">
            {t('forex.transfer_details.treasury_request')}
          </Divider>
          <Card>
            <TreasuryRequestSection />
          </Card>
        </Col>

        <Col span={24}>
          <Spin spinning={values.isLoadingSummaryData}>
            <Divider orientation="left">
              {t('forex.transfer_details.summary')}
            </Divider>
            <Card>
              <SummarySection />
            </Card>
          </Spin>
        </Col>
      </Row>
    </>
  );
};

export default ForexTransferForm;
