import React, { useEffect, useState } from 'react';

// helpers
import useUserAccess from '../../hooks/useUserAccess';
import useTranslation from '../../hooks/useTranslation';
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_JOURNALS_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import JournalsTable from '../../modules/Finance/Journals/JournalsTable';
import AddViewJournalModal from '../../modules/Finance/Journals/AddViewJournalModal';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';
import { Button } from 'antd';

type IProps = PageLayoutHelperPropsModel;

const Journals = ({ setHeaderOptions }: IProps) => {
  const { t } = useTranslation('finance');
  const [hasCreateAccess] = useUserAccess(['Finance_Journals_Create']);
  const [updateTableTrigger, updateTable] = useState({});
  const [isVisibleDialog, setVisibleDialog] = useState(false);

  // --------  LifeCycle hooks --------
  useEffect(() => {
    if (hasCreateAccess) {
      setHeaderOptions({
        appendToTitle: (
          <TooltipToDocumentation link={GENESIS_JOURNALS_DOCUMENTATION_LINK} />
        ),
        extra: () => (
          <Button type="primary" onClick={() => setVisibleDialog(true)}>
            {t('journals.new.title')}
          </Button>
        ),
      });
    }
  }, [hasCreateAccess, t]);

  const handleModalClose = (shouldUpdateTable?: boolean) => {
    if (shouldUpdateTable) {
      updateTable({});
    }
    setVisibleDialog(false);
  };

  return (
    <>
      <JournalsTable updateTableTrigger={updateTableTrigger} />
      <AddViewJournalModal
        isVisible={isVisibleDialog}
        closeCallback={handleModalClose}
      />
    </>
  );
};

export default Journals;
