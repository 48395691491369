import React, { useEffect, useState } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import groupsAPI from '../../../api/orac/groupsAPI';
import useQueryParam from '../../../hooks/useQueryParam';
import useUserAccess from '../../../hooks/useUserAccess';
import useTranslation from '../../../hooks/useTranslation';
import { RoutePaths } from '../../../routes/routes';

// components
import EditGroup from '../../../modules/ORAC/Groups/EditGroup';
import LoadingWrapper from '../../../components/WrapperComponents/LoadingWrapper';
import { default as GroupDetailsContent } from '../../../modules/ORAC/Groups/GroupDetails';

interface IProps {
  setHeaderOptions: (options: any) => void;
}

const GroupDetails = ({ setHeaderOptions }: IProps) => {
  const { t } = useTranslation('page_titles');
  const groupId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.ORAC_Groups,
  });
  const [updateGroupTrigger, updateGroup] = useState({});

  const [hasEditAccess] = useUserAccess(['ORAC_Groups_Details_Update']);

  const { response, loading } = useFetch(
    () => (groupId ? groupsAPI.getGroupById(groupId) : null),
    [groupId, updateGroupTrigger],
  );

  useEffect(() => {
    setHeaderOptions({
      title: `${t('Group')}${response ? `: ${response.name}` : ''}`,
      extra:
        hasEditAccess && response
          ? () => <EditGroup onEdit={() => updateGroup({})} group={response} />
          : undefined,
    });
  }, [response, hasEditAccess]);

  return (
    <LoadingWrapper loading={loading}>
      {!!response && <GroupDetailsContent group={response} />}
    </LoadingWrapper>
  );
};

export default GroupDetails;
