import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import { Input } from 'antd';

const InnerForm = () => {
  const { t } = useTranslation('profile');

  return (
    <FormField
      name="name"
      component={Input}
      label={t(
        'sign_in_and_security.two_factor_verification.security_key_option.edit_key.form_fields.name',
      )}
    />
  );
};

export default InnerForm;
