// ---------- Entities -------------
export enum OnboardStatus {
  OnboardingNotStarted = 'onboarding_not_started',
  OnboardingPre_approved = 'onboarding_pre_approved',
  OnboardingApproved = 'onboarding_approved',
  OnboardingRejected = 'onboarding_rejected',
  OnboardingPending = 'onboarding_pending',
  None = 'none',
}

export enum OnboardType {
  Contact = 'contact',
  Organization = 'organization',
}

export enum OnboardAccountType {
  Current = 'current',
  Term = 'term',
}

export enum DateAcceptedStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Expired = 'expired',
}

export enum ClientStatus {
  Onboarding = 'onboarding',
  InReview = 'in_review',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum ClientReviewStatus {
  NotReviewed = 'not_reviewed',
  ManagerReview = 'manager_review',
  BusinessReview = 'business_review',
  ComplianceReview = 'compliance_review',
  Reviewed = 'reviewed',
}

export enum AccountUserPermissionTypes {
  ViewOnly = 'view_only',
  InstructTransaction = 'instruct_transaction',
  AuthorizedSignatory = 'authorized_signatory',
}

export enum AdministrationPermissionTypes {
  Admin = 'admin',
  LimitedAdmin = 'limited_admin',
  None = 'none',
}

export enum ClientGroupUserStatuses {
  Onboarding = 'onboarding',
  ReadyForReview = 'ready_for_review',
  AccountManagementReview = 'in_account_management_review',
  InReview = 'in_review',
  Rejected = 'rejected',
  Approved = 'approved',
  Invited = 'invited',
  Enabled = 'enabled',
  Deactivated = 'deactivated',
  InvitationExpired = 'invitation_expired',
}

export enum ClientGroupApprovalStatuses {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export enum EntityType {
  Legal = 'legal',
  Trade = 'trade',
}

export enum OrganizationAddressType {
  RegisteredOffice = 'registered_office',
  BusinessAddress = 'business_address',
  MailingAddress = 'mailing_address',
}

export enum ContactAddressType {
  PermanentAddress = 'permanent_address',
  MailingAddress = 'mailing_address',
  ResidentialAddress = 'residential_address',
}
export enum EmailType {
  Personal = 'personal',
  Work = 'work',
  Other = 'other',
}

export enum PhoneNumberType {
  Home = 'home',
  Work = 'work',
  Mobile = 'mobile',
  Business = 'business',
  Fax = 'fax',
  Other = 'other',
}

export enum NoteType {
  Contact = 'contact',
  Organization = 'organization',
  ClientGroup = 'clientGroup',
  ReviewProcess = 'reviewProcess',
}

export enum NoteMessageType {
  Call = 'call',
  inPerson = 'inPerson',
  Research = 'research',
  Other = 'other',
  Compliance = 'compliance',
}

export enum SourceType {
  Document = 'document',
  Link = 'link',
  String = 'string',
}

export enum AdditionalFieldType {
  String = 'string',
  Number = 'number',
  Date = 'date',
  Select = 'select',
  Document = 'document',
  Percentage = 'percentage',
  Form = 'form',
  Boolean = 'boolean',
}

export enum AssociateTypes {
  All = 'all',
  Contact = 'contact',
  Organization = 'organization',
  ClientGroup = 'clientGroup',
}

export enum GenderOptions {
  None = 'none',
  Male = 'male',
  Female = 'female',
}

export enum SourceOfWealthCategories {
  IncomeFromProfession = 'income_from_profession',
  InvestmentIncome = 'investment_income',
  CompanyOwnership = 'company_ownership',
  CompanySale = 'company_sale',
  Inheritance = 'inheritance',
  PropertyRentalIncome = 'property_rental_income',
  PropertySale = 'property_sale',
}

export enum SocialMedia {
  Twitter = 'Twitter',
  LinkedIn = 'LinkedIn',
  Facebook = 'Facebook',
  Website = 'website',
}

export enum ComplianceRiskLevel {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum RelationshipTrackType {
  Parent = 'parent',
  Child = 'child',
}

export enum OnboardingReviewStatuses {
  ManagerReview = 'manager_review',
  BusinessReview = 'business_review',
  ComplianceReview = 'compliance_review',
}

export enum OnboardingStatuses {
  NotSubmitted = 'not_submitted',
  InReview = 'in_review',
  Rejected = 'rejected',
  Approved = 'approved',
  AdditionalInfoRequired = 'additional_info_required',
}

export enum RelationshipRelatedTypes {
  Contact = 'contact',
  Organization = 'organization',
  Any = 'any',
}

export enum DocumentAssociationTypes {
  AccountRecovery = 'accountRecovery',
  ClientGroup = 'clientGroup',
  Contact = 'contact',
  Entity = 'organization',
  User = 'user',
  Message = 'message',
  Note = 'note',
  Transaction = 'transaction',
  JournalEntry = 'journalEntry',
}

export enum CrmItemType {
  Contact = 'contact',
  Organization = 'organization',
  ClientGroup = 'clientGroup',
}

export enum NoteRelatedType {
  Contact = 'contact',
  Organization = 'organization',
  ClientGroup = 'clientGroup',
  ReviewProcess = 'reviewProcess',
}

export enum ContactStatuses {
  None = 'none',
  Approved = 'approved',
  InReview = 'in-review',
}
