import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// components
import SecurityKey from './SecurityKey';
import Authenticator from './Authenticator';
import { Col, Divider, Row, Typography } from 'antd';
import { CardWrapper } from '../../../../../../layouts/PageLayout';
import { UserFullModel } from '../../../../../../typings/ORAC/users';
import ChangeDefaultAuthenticator from './ChangeDefaultAuthenticator';
import { AccountRecoveryRequestTypes } from '../../../../../../enums/admin/orac';

interface IProps {
  user: UserFullModel;
}

const TwoFactorAuthentication = ({ user }: IProps) => {
  const { t } = useTranslation('orac');

  const [isFidoEnabled, setIsFidoEnabled] = useState(
    user.isTwoFactorFidoEnabled,
  );
  const [isAuthenticatorEnabled, setIsAuthenticatorEnabled] = useState(
    user.isTwoFactorAuthenticationEnabled,
  );
  const [defaultAuthenticator, setDefaultAuthenticator] = useState(
    user.default2FAType,
  );

  const canShowChangeDefaultAuthenticator =
    isFidoEnabled || isAuthenticatorEnabled;

  const isFIDOAuthenticatorDefault =
    defaultAuthenticator === AccountRecoveryRequestTypes.Fido && isFidoEnabled;
  const isAuthenticatorDefault =
    defaultAuthenticator === AccountRecoveryRequestTypes.Authenticator &&
    isAuthenticatorEnabled;

  return (
    <CardWrapper>
      <Row gutter={[16, 16]}>
        <TitleCol xl={18} lg={18} md={12} sm={24} xs={24}>
          <StyledTitle level={4}>
            {t('users.view.two_step_verification.title')}
          </StyledTitle>
          <Typography.Text>
            {t('users.view.two_step_verification.description')}
          </Typography.Text>
        </TitleCol>

        {canShowChangeDefaultAuthenticator && (
          <Col xl={6} lg={6} md={12} sm={24} xs={24}>
            <StyledTitle level={4}>
              {t('users.view.two_step_verification.default_authenticator')}
            </StyledTitle>
            <ChangeDefaultAuthenticator
              defaultAuthenticator={defaultAuthenticator}
              onChange={(type: AccountRecoveryRequestTypes) =>
                setDefaultAuthenticator(type)
              }
              user={user}
              isTwoFactorFidoEnabled={isFidoEnabled}
              isTwoFactorAuthenticationEnabled={isAuthenticatorEnabled}
            />
          </Col>
        )}
      </Row>

      <StyledDivider />

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Authenticator
            isDefault={isAuthenticatorDefault}
            userId={user._id}
            onDisable={() => setIsAuthenticatorEnabled(false)}
            isTwoFactorAuthenticationEnabled={isAuthenticatorEnabled}
            isFidoEnabled={isFidoEnabled}
            onChangeDefault={() =>
              setDefaultAuthenticator(AccountRecoveryRequestTypes.Fido)
            }
          />
        </Col>

        <Col span={24}>
          <SecurityKey
            isDefault={isFIDOAuthenticatorDefault}
            userId={user._id}
            onDisable={() => setIsFidoEnabled(false)}
            isFidoEnabled={isFidoEnabled}
            isTwoFactorAuthenticationEnabled={isAuthenticatorEnabled}
            onChangeDefault={() =>
              setDefaultAuthenticator(AccountRecoveryRequestTypes.Authenticator)
            }
          />
        </Col>
      </Row>
    </CardWrapper>
  );
};

const TitleCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;

const StyledDivider = styled(Divider)`
  margin: 0px !important;
`;

export default TwoFactorAuthentication;
