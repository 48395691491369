import React, { useCallback } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { RoleBaseModel } from '../../../../../typings/ORAC/roles';
import { GroupBaseModel } from '../../../../../typings/ORAC/groups';

// components
import { Popover, Typography } from 'antd';

interface IProps {
  children: React.ReactNode;
  isDirect: boolean;
  roles?: RoleBaseModel[];
  groups?: GroupBaseModel[];
}

const AssignWayInfoPopover = ({
  children,
  isDirect,
  groups,
  roles,
}: IProps) => {
  const { t } = useTranslation('orac');
  const history = useHistory();

  const renderPopoverContent = useCallback(() => {
    return (
      <>
        {isDirect && (
          <StyledParagraph>
            {t('permissions.table.direct_assigned')}
          </StyledParagraph>
        )}

        {!!groups?.length && (
          <StyledParagraph>
            {`${t('permissions.table.inherited_from_groups')} - `}
            {groups.map(({ id, name }) => (
              <Typography.Link
                key={id}
                onClick={() =>
                  history.push({
                    pathname: RoutePaths.ORAC_Group_Details,
                    search: `?id=${id}`,
                  })
                }
              >
                {`${name} `}
              </Typography.Link>
            ))}
          </StyledParagraph>
        )}

        {!!roles?.length && (
          <StyledParagraph>
            {`${t('permissions.table.inherited_from_roles')} - `}
            {roles.map(({ id, name }) => (
              <Typography.Link
                key={id}
                onClick={() =>
                  history.push({
                    pathname: RoutePaths.ORAC_Role_Details,
                    search: `?id=${id}`,
                  })
                }
              >
                {`${name} `}
              </Typography.Link>
            ))}
          </StyledParagraph>
        )}
      </>
    );
  }, [isDirect, groups, roles]);

  return (
    <Popover content={renderPopoverContent()} placement="left">
      {children}
    </Popover>
  );
};

const StyledParagraph = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
`;

export default AssignWayInfoPopover;
