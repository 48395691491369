import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { enumToMap } from '../../../../../../helpers/utils';
import { SelectProps } from 'antd/lib/select';
import { ComplianceReviewTypes } from '../../../../../../enums/compliance/reviewConfiguration';

// components
import { Select } from 'antd';

type IProps = SelectProps<any>;

const ReviewTypeSelect = ({ value, ...rest }: IProps) => {
  const { t } = useTranslation('compliance');

  const renderOptions = () => {
    return Array.from(enumToMap(ComplianceReviewTypes)).map((el) => (
      <Select.Option key={el[1]} value={el[1]}>
        {t(`review_types.${el[0]}`)}
      </Select.Option>
    ));
  };

  return (
    <Select
      {...rest}
      value={value}
      showSearch
      placeholder={t('review_configuration.review_type_ph')}
    >
      {renderOptions()}
    </Select>
  );
};

export default ReviewTypeSelect;
