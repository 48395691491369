import React from 'react';

// helpers utils
import useTranslation from '../../../../hooks/useTranslation';

// components
import SessionHistory from './SessionHistory';
import CurrentSessions from './CurrentSessions';
import { Typography } from 'antd';
import { CardWrapper } from '../../../../layouts/PageLayout';

const Sessions = () => {
  const { t } = useTranslation('profile');

  return (
    <>
      <CardWrapper>
        <Typography.Title level={4}>
          {t('sign_in_and_security.sessions.current_sessions')}
        </Typography.Title>
        <CurrentSessions />
      </CardWrapper>

      <CardWrapper>
        <Typography.Title level={4}>
          {t('sign_in_and_security.sessions.session_history')}
        </Typography.Title>
        <SessionHistory />
      </CardWrapper>
    </>
  );
};

export default Sessions;
