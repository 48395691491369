import React from 'react';

// helpers
import styled from 'styled-components';
import { darkTheme } from '../../../resources/theme/styled';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { StyledComponentProps } from '../../../typings/common';

// components
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export interface IProps extends StyledComponentProps {
  tooltipContent: React.ReactNode;

  color?: string;
  placement?: TooltipPlacement;
}

const InfoTooltip = ({
  placement = 'bottom',
  tooltipContent,
  color = darkTheme.warningColor,
  ...rest
}: IProps) => {
  return (
    <Tooltip title={tooltipContent} placement={placement} {...rest}>
      <StyledInfoIcon color={color} />
    </Tooltip>
  );
};

const StyledInfoIcon = styled(InfoCircleOutlined)<{ color: string }>`
  cursor: pointer;
  color: ${({ color }) => color};
`;

export default InfoTooltip;
