import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { AccountProcessingTypes } from '../../../../../enums/finance/finance';
import { TransferTemplateFromQuery } from '../../../../../api/transferTemplates/transferTemplatesAPI';
import {
  TransferTemplatePermissions,
  TransferTemplateTypes,
} from '../../../../../enums/finance/transferTemplates';

// components
import TemplateShortInfo from './Components';
import TransferTemplateStatus from 'components/Additional/Statuses/TransferTemplateStatus';
import DerivedFromCircleTooltip from '../../../../Tooltips/TemplateTooltips/DerivedFromCircleTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<TransferTemplateFromQuery> {
  onActionsClick?: (key: ActionKeys, record: TransferTemplateFromQuery) => void;
}

export type ActionKeys = 'view-details';

const TransferTemplatesTable = memo(({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation('crm');

  const renderTemplateName = (record: TransferTemplateFromQuery) => {
    const isDerivedFromCircle =
      record.additionalData.derivedFrom === AccountProcessingTypes.Circle;

    if (isDerivedFromCircle) {
      return (
        <>
          {record.name} <DerivedFromCircleTooltip derivedEntryType="template" />
        </>
      );
    } else {
      return onActionsClick ? (
        <Typography.Link
          onClick={() =>
            onActionsClick && onActionsClick('view-details', record)
          }
        >
          {record.name}
        </Typography.Link>
      ) : (
        record.name
      );
    }
  };

  const renderTemplatePermissions = ({
    isOnlyMe,
  }: TransferTemplateFromQuery) => {
    const permission = isOnlyMe
      ? TransferTemplatePermissions.Private
      : TransferTemplatePermissions.Public;
    return t(`transfer_templates.transfer_template_permissions.${permission}`);
  };

  const tableColumns = useMemo<TableColumnModel[]>(() => {
    return [
      {
        title: t('transfer_templates.table.date'),
        key: 'date',
        width: 150,
        render: (record: TransferTemplateFromQuery) =>
          DateHelpers.formatTimestampToString(record.creationDate),
      },

      {
        title: t('transfer_templates.table.id'),
        key: 'id',
        width: 100,
        render: (record: TransferTemplateFromQuery) => record.id,
      },

      {
        title: t('transfer_templates.table.name'),
        key: 'name',
        width: 150,
        render: renderTemplateName,
      },

      {
        title: t('transfer_templates.table.type'),
        key: 'templateTypeId',
        width: 150,
        mobileCardProps: { isCardTitle: true },
        render: (record: TransferTemplateFromQuery) =>
          t(
            `transfer_templates.transfer_template_types.${TransferTemplateTypes[record.templateTypeId]}`,
          ),
      },

      {
        title: t('transfer_templates.table.status'),
        key: 'status',
        width: 150,
        mobileCardProps: { isCardTitle: true },
        render: (record: TransferTemplateFromQuery) => (
          <TransferTemplateStatus status={record.additionalData.status} />
        ),
      },

      {
        title: t('transfer_templates.table.permissions'),
        key: 'isOnlyMe',
        width: 150,
        render: renderTemplatePermissions,
      },

      {
        title: t('transfer_templates.table.details'),
        key: 'additionalData',
        width: 250,
        render: (record: TransferTemplateFromQuery) => (
          <TemplateShortInfo template={record} />
        ),
        mobileCardProps: { hideFromMainSection: true },
      },
    ];
  }, [t, onActionsClick]);

  return <Table {...rest} columns={tableColumns} />;
});

export default TransferTemplatesTable;
