import React from 'react';

// helpers
import { IContactModel } from 'typings/crm/contact';

// components
import AssociatedClientGroupsTable from '../../../../../../../components/Tables/TableTemplates/CRM/AssociatedClientGroupsTable';

interface IProps {
  contact: IContactModel;
}

const ClientGroups = ({ contact }: IProps) => {
  return <AssociatedClientGroupsTable data={contact.clientGroups} />;
};

export default ClientGroups;
