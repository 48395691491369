import React, { ReactNode, useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useQueryParam from '../../hooks/useQueryParam';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useDispatch } from 'react-redux';
import { StyledComponentProps } from 'typings/common';
import { CHANGE_VIEW_TYPES, changeView } from '../../redux/actions/view';

// components
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Tabs as AntTabs, Typography } from 'antd';

const { TabPane } = AntTabs;

interface IProps extends StyledComponentProps {
  tabs: TabModel[];
  defaultActiveKey?: string;
  keyQueryParam?: string;
  renderOnChange?: boolean;
  activeKey?: string;
  onChange?: (newKey: any) => void;
}

export interface TabModel {
  key: string;
  title: string | ReactNode;
  content: ReactNode;
  hidden?: boolean;
}

// Custom tabs component with additional config
const Tabs = ({
  tabs,
  defaultActiveKey,
  keyQueryParam = '',
  renderOnChange = false,
  activeKey,
  onChange,
  ...props
}: IProps) => {
  const dispatch = useDispatch();
  const visibleTabs = useMemo(
    () => tabs.filter(({ hidden }) => !hidden),
    [tabs],
  );
  const keyFromQuery = useQueryParam({ param: keyQueryParam });
  const [activeTab, setActiveTab] = useState(getInitialKey());
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (activeKey && activeKey !== activeTab) {
      setActiveTab(activeKey);
    }
  }, [activeKey]);

  useEffect(() => {
    if (visibleTabs?.some(({ key }) => key === keyFromQuery)) {
      setActiveTab(keyFromQuery);
    }
  }, [keyFromQuery]);

  function getInitialKey() {
    if (defaultActiveKey) {
      return defaultActiveKey;
    }

    return visibleTabs[0]?.key;
  }

  const generateTabsForDropdown = () => {
    const buttonNodes = visibleTabs.map(({ key, title }) => (
      <Menu.Item key={key}>{title}</Menu.Item>
    ));

    return (
      <Menu onClick={({ key }) => handleTabChange(key)}>{buttonNodes}</Menu>
    );
  };

  const handleTabChange = (newKey: any) => {
    dispatch(
      changeView(() => {
        setActiveTab(newKey);
        onChange && onChange(newKey);
      }, CHANGE_VIEW_TYPES.TABS),
    );
  };

  const getActiveTabTitle = (activeKey: string) => {
    if (!activeKey) return '';

    return visibleTabs.find((e) => e.key === activeKey)?.title;
  };

  // render tabs depends on current screen
  // here is 2 cases:
  // - default pc/tablet screen where we need to render full tabs
  // - mobile screen where we need to render dropdown in header since it's better from UX
  // (mobile mode starts when the width of the screen is smaller than 768px md-breakpoint)
  const renderTabBar = (props: any, DefaultTabBar: any) => {
    if (breakpoint.md) {
      // render default mobile header tabs
      // set mobile as a true value to allow scrolling tabs horizontally
      return <DefaultTabBar {...props} mobile />;
    } else {
      return (
        <Dropdown overlay={generateTabsForDropdown()}>
          <ActiveTabButton size="large" type="default">
            <Typography.Text>{getActiveTabTitle(activeTab)}</Typography.Text>
            <StyledArrowIcon />
          </ActiveTabButton>
        </Dropdown>
      );
    }
  };

  return (
    <AntTabs
      activeKey={activeKey || activeTab}
      onChange={handleTabChange}
      renderTabBar={renderTabBar}
      key={renderOnChange ? `tabs-active-${activeTab}` : undefined}
      {...props}
    >
      {visibleTabs.map(({ key, title, content }) => (
        <TabPane key={key} tab={title}>
          {content}
        </TabPane>
      ))}
    </AntTabs>
  );
};

const ActiveTabButton = styled(Button)`
  padding: 0px;
  position: relative;

  background-color: ${({ theme }) => theme.activeTabBackgroundColor};
  height: 50px;
`;

const StyledArrowIcon = styled(DownOutlined)`
  margin-left: 10px;
`;

export default Tabs;
