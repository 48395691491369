import { AccountsHelpers } from '@helpers/finance/accounts';
import { FetchResponseModel } from '../../typings/common';
import { BankAccountStatuses } from 'enums/banking/accounts';
import { UpdateWireDetailsData } from 'modules/ApprovalManagement/ApprovalWorkflowTabs/ApprovalWorkflowDetailsModal/InstanceDetails/EntrySnapshots/AWT_SENIORMNGT/UpdateWireDetails';
import { WireDetailsModelFromQuery } from 'api/finance/account/accountsAPI';
import {
  BankingBankAccount,
  WireDetailsModel,
} from '../../typings/banking/bankAccounts';
import { FormValuesModel as BankAccountFormValuesModel } from '../../components/Modals/TemplateModalDialogs/Banking/BankAccountModalDialog';
import {
  BankingBankAccountFromQuery,
  UpdateBankAccountBody,
} from '../../api/banking/bankingBankAccountsAPI';

const bankingBankAccountsAdapter = {
  mapBankAccountFromQueryToBankAccountModel: function (
    bankAccount: BankingBankAccountFromQuery,
  ): BankingBankAccount {
    return {
      ...bankAccount,
      balance:
        AccountsHelpers.convertAmountFromBigIntToInt(bankAccount.balance) || 0,
      balanceHome:
        AccountsHelpers.convertAmountFromBigIntToInt(bankAccount.balanceHome) ||
        0,
      statistics: {
        ...bankAccount.statistics,
        avgEodBalanceSinceCreation:
          AccountsHelpers.convertAmountFromBigIntToInt(
            bankAccount.statistics.avgEodBalanceSinceCreation,
          ) || 0,
        avgEodBalanceCurrentCalendarMonth:
          AccountsHelpers.convertAmountFromBigIntToInt(
            bankAccount.statistics.avgEodBalanceCurrentCalendarMonth,
          ) || 0,
        avgEodBalanceLast3Months:
          AccountsHelpers.convertAmountFromBigIntToInt(
            bankAccount.statistics.avgEodBalanceLast3Months,
          ) || 0,
        avgEodBalanceLast6Months:
          AccountsHelpers.convertAmountFromBigIntToInt(
            bankAccount.statistics.avgEodBalanceLast6Months,
          ) || 0,
      },
    };
  },

  fetchBankAccounts: function (
    response: FetchResponseModel<BankingBankAccountFromQuery>,
  ): FetchResponseModel<BankingBankAccount> {
    return {
      data: response.data.map(this.mapBankAccountFromQueryToBankAccountModel),
      total: response.total,
    };
  },

  fetchBankAccountByAccountNumber: function (
    bankAccount: BankingBankAccountFromQuery,
  ): BankingBankAccount {
    return this.mapBankAccountFromQueryToBankAccountModel(bankAccount);
  },

  updateBankAccount: function (
    formValues: BankAccountFormValuesModel,
  ): UpdateBankAccountBody {
    return {
      status:
        formValues.isActive === 'true'
          ? BankAccountStatuses.Active
          : BankAccountStatuses.Closed,
      isFrozen: formValues.isFrozen === 'true',
    };
  },

  fetchWireDetailsByAccountNumber: function (
    data: WireDetailsModelFromQuery,
  ): WireDetailsModel {
    return {
      id: data.id,
      isManualBeneficiary: data.isManualBeneficiary,
      beneficiary: {
        city: data.beneficiaryCity,
        district: data.beneficiaryDistrict,
        postCode: data.beneficiaryPostCode,
        address: data.beneficiaryAddress,
        country: data.beneficiaryCountry,
        accountName: data.beneficiaryAccountName,
        accountNumber: data.beneficiaryAccountNumber,
      },
      beneficiaryBank: {
        city: data.beneficiaryBankCity,
        district: data.beneficiaryBankDistrict,
        postCode: data.beneficiaryBankPostCode,
        bankName: data.beneficiaryBankName,
        address: data.beneficiaryBankAddress,
        country: data.beneficiaryBankCountry,
        bankCodeType: data.beneficiaryBankCodeType,
        bankCode: data.beneficiaryBankCode,
      },
      intermediaryBank: {
        city: data.intermediaryBankCity,
        district: data.intermediaryBankDistrict,
        postCode: data.intermediaryBankPostCode,
        bankName: data.intermediaryBankName,
        address: data.intermediaryBankAddress,
        country: data.intermediaryBankCountry,
        bankCodeType: data.intermediaryBankCodeType,
        bankCode: data.intermediaryBankCode,
      },
    };
  },
  mapComplianceUpdateWireDetailsToWireDetailsModel: function (
    data: UpdateWireDetailsData,
  ): WireDetailsModel {
    return {
      isManualBeneficiary: data.wireDetails.isManualBeneficiary,
      beneficiary: {
        city: data.wireDetails.beneficiaryCity,
        district: data.wireDetails.beneficiaryDistrict,
        postCode: data.wireDetails.beneficiaryPostCode,
        address: data.wireDetails.beneficiaryAddress,
        country: data.wireDetails.beneficiaryCountry,
        accountName: data.wireDetails.beneficiaryAccountName,
        accountNumber: data.wireDetails.beneficiaryAccountNumber,
      },
      beneficiaryBank: {
        city: data.wireDetails.beneficiaryBankCity,
        district: data.wireDetails.beneficiaryBankDistrict,
        postCode: data.wireDetails.beneficiaryBankPostCode,
        bankName: data.wireDetails.beneficiaryBankName,
        address: data.wireDetails.beneficiaryBankAddress,
        country: data.wireDetails.beneficiaryBankCountry,
        bankCodeType: data.wireDetails.beneficiaryBankCodeType,
        bankCode: data.wireDetails.beneficiaryBankCode,
      },
      intermediaryBank: {
        city: data.wireDetails.intermediaryBankCity,
        district: data.wireDetails.intermediaryBankDistrict,
        postCode: data.wireDetails.intermediaryBankPostCode,
        bankName: data.wireDetails.intermediaryBankName,
        address: data.wireDetails.intermediaryBankAddress,
        country: data.wireDetails.intermediaryBankCountry,
        bankCodeType: data.wireDetails.intermediaryBankCodeType,
        bankCode: data.wireDetails.intermediaryBankCode,
      },
    };
  },

  mapWireDetailsFromQueryToWireDetailModel: (
    data: WireDetailsModel,
  ): WireDetailsModelFromQuery => {
    return {
      isManualBeneficiary: data.isManualBeneficiary,

      beneficiaryCity: data.beneficiary.city,
      beneficiaryCountry: data.beneficiary.country,
      beneficiaryAddress: data.beneficiary.address,
      beneficiaryDistrict: data.beneficiary.district,
      beneficiaryPostCode: data.beneficiary.postCode,
      beneficiaryAccountName: data.beneficiary.accountName,
      beneficiaryAccountNumber: data.beneficiary.accountNumber,

      beneficiaryBankName: data.beneficiaryBank.bankName,
      beneficiaryBankCodeType: data.beneficiaryBank.bankCodeType,
      beneficiaryBankCode: data.beneficiaryBank.bankCode,
      beneficiaryBankCountry: data.beneficiaryBank.country,
      beneficiaryBankDistrict: data.beneficiaryBank.district,
      beneficiaryBankCity: data.beneficiaryBank.city,
      beneficiaryBankAddress: data.beneficiaryBank.address,
      beneficiaryBankPostCode: data.beneficiaryBank.postCode,

      intermediaryBankName: data.intermediaryBank.bankName,
      intermediaryBankCodeType: data.intermediaryBank.bankCodeType,
      intermediaryBankCode: data.intermediaryBank.bankCode,
      intermediaryBankCountry: data.intermediaryBank.country,
      intermediaryBankDistrict: data.intermediaryBank.district,
      intermediaryBankCity: data.intermediaryBank.city,
      intermediaryBankAddress: data.intermediaryBank.address,
      intermediaryBankPostCode: data.intermediaryBank.postCode,
    };
  },
};

export { bankingBankAccountsAdapter };
