import { getMe, signOut } from './auth';
import { SessionHelpers } from '@helpers/sessions';
import { LocalStorageHelpers } from '@helpers/storages/localStorage';
import { SessionStorageHelper } from '@helpers/storages/sessionStorage';

// Actions
export enum ActionType {
  SET_INITIALIZED_STATUS = 'app/SET_INITIALIZED_STATUS',
  SET_ACTIVITY_TIMESTAMP = 'app/SET_ACTIVITY_TIMESTAMP',
}

// Action creators
export const setInitializedStatus = (newStatus: boolean) => ({
  type: ActionType.SET_INITIALIZED_STATUS,
  payload: { status: newStatus },
});

export const setLastActivityTimestamp = (lastActivityTimestamp: number) => ({
  type: ActionType.SET_ACTIVITY_TIMESTAMP,
  payload: { lastActivityTimestamp },
});

export const initializeApp = () => async (dispatch: any) => {
  dispatch(setInitializedStatus(false));

  const isTokenInStorage = LocalStorageHelpers.getAccessToken();
  const geolocationStatus = await SessionHelpers.getGeolocationStatus();

  if (geolocationStatus === 'denied') {
    SessionStorageHelper.clearUserSession();
  }

  // This is edge case that shouldn't happen
  // 'other-no-access' status can be returned when the user hasn't made a decision with a geolocation yet
  if (geolocationStatus === 'other-no-access') {
    const currentSession = SessionStorageHelper.getUserSession();
    if (currentSession && currentSession.geolocation) {
      SessionStorageHelper.clearUserSession();
    }
  }

  if (!isTokenInStorage) {
    dispatch(setInitializedStatus(true));
  } else {
    dispatch(initializeAppForAuthorizedUser());
  }
};

export const initializeAppForAuthorizedUser = () => async (dispatch: any) => {
  const getMePromise = dispatch(getMe());

  Promise.all([getMePromise])
    .then(() => {
      dispatch(setInitializedStatus(true));
    })
    .catch(() => {
      dispatch(signOut());
    });
};
