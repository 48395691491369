import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import { Button } from 'antd';
import EditForbiddenPermissionsModal from './EditForbiddenPermissionModal';

interface IProps {
  userId: string;
  onUpdate: () => void;
}

const EditForbiddenPermissions = ({ userId, onUpdate }: IProps) => {
  const { t } = useTranslation('orac');

  const [isVisible, setIsVisible] = useState(false);

  const handleModalClose = (wasAdded?: boolean) => {
    if (wasAdded) {
      onUpdate();
    }

    setIsVisible(false);
  };

  return (
    <>
      <Button onClick={() => setIsVisible(true)}>
        {t('users.view.edit_forbidden_permissions')}
      </Button>
      <EditForbiddenPermissionsModal
        isVisible={isVisible}
        closeCallback={handleModalClose}
        userId={userId}
      />
    </>
  );
};

export default EditForbiddenPermissions;
