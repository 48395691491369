import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { IContactModel } from '../../../typings/crm/contact';
import { DateAcceptedStatus } from '../../../enums/crm/crm';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';

export type SortInvitationType =
  | 'invitationDate'
  | 'acceptanceDate'
  | undefined;

interface FetchEntitiesParamsModel extends FetchParamsModel {
  search?: string;
  status?: DateAcceptedStatus;
  sortBy?: SortInvitationType;
  contactId?: string;
  sortDirection?: boolean;
}

export interface InvitationModel {
  acceptance: {
    acceptedBy: string;
    acceptedOn: string;
  };
  code: string;
  expiresOn: string;
  invitedBy: string[];
  metadata: {
    acceptedBy: IContactModel;
    invitedBy: IContactModel[];
  };
  _audit: {
    createdAt: string;
    createdBy: string;
    deleteDetails: string | null;
    isDeleted: boolean;
    updatedAt: string;
  };
}

const invitationsAPI = {
  fetchInvitations: (params: FetchEntitiesParamsModel) => {
    return APIService.get<FetchResponseModel<InvitationModel>>(
      `${APIConfig.crmApi}/invitation`,
      { params },
    ).then(({ data }) => data);
  },
};

export { invitationsAPI };
