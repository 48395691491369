import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { IAccountModel } from '../../../../../../typings/finance/account';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { StyledComponentProps } from '../../../../../../typings/common';
import { correspondentBanksAPI } from '../../../../../../api/banking/correspondentBanksAPI';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import EllipsisTooltip from '../../../../../Tooltips/EllipsisTooltip';
import Autocomplete, {
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';
import { Typography } from 'antd';

interface IProps extends StyledComponentProps {
  bankId: string;
  value?: string;
  name?: string;
  disabled?: boolean;
  onSelect?: (id: string, model: IAccountModel) => void;
  onChange?: (id: string) => void;
  allowClear?: boolean;
}

const CorrespondentBankAccountsByBankAutocomplete = ({
  bankId,
  ...rest
}: IProps) => {
  const { t } = useTranslation('common');

  const fetchAccounts = async () => {
    if (!bankId) {
      return [];
    }

    const { data } =
      await correspondentBanksAPI.fetchCorrespondentBankAccountsByBankId(
        bankId,
        {
          page: 1,
          limit: AUTOCOMPLETE_RESULT_LIMIT,
        },
      );

    return data.map<AutocompleteOption>((account: IAccountModel) => {
      const { accountNumber, accountName, currencyCode } = account;
      const name = AccountsHelpers.generateAccountFullName(
        accountName,
        accountNumber,
        currencyCode,
      );

      return {
        id: accountNumber,
        model: account,
        content: (
          <EllipsisTooltip
            placement="right"
            overlayStyle={{ maxWidth: '300px' }}
            title={
              <>
                <StyledParagraph>{accountNumber}</StyledParagraph>
                <StyledParagraph>{accountName}</StyledParagraph>
                <StyledParagraph>{currencyCode}</StyledParagraph>
              </>
            }
          >
            {name}
          </EllipsisTooltip>
        ),
      };
    });
  };

  return (
    <Autocomplete
      {...rest}
      fetchData={fetchAccounts}
      placeholder={t('select_option')}
    />
  );
};

const StyledParagraph = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
`;

export default CorrespondentBankAccountsByBankAutocomplete;
