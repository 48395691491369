import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { CountryRiskRatingModel } from '../../typings/compliance/countryRiskRatings';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

interface FetchCountryParams extends FetchParamsModel {
  search?: string;
  riskRating?: string;
}

const countryRiskRatingsAPI = {
  fetchCountryRiskRatings: (params: FetchCountryParams) => {
    return APIService.get<FetchResponseModel<CountryRiskRatingModel>>(
      `${APIConfig.amlApi}/countries`,
      {
        params,
      },
    ).then(({ data }) => data);
  },
};

export default countryRiskRatingsAPI;
