import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { noteAPI } from '../../../../api/note/noteAPI';
import { SortProps } from '@core_components/Table';
import { INoteModel } from 'typings/note/note';
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';

// components
import { default as TemplateNotesTable } from '../../../../components/Tables/TableTemplates/CRM/NotesTable';

interface IProps {
  onActionsClick?: (key: string, value: INoteModel) => void;
  updateTrigger: any;
  associationId: string;
}

const NotesTable = ({
  associationId,
  updateTrigger,
  onActionsClick,
}: IProps) => {
  const [current, setCurrent] = useState(1);
  const [sortByCreatedAt, setSortByCreatedAt] = useState<
    'descend' | 'ascend' | null
  >(null);

  const { response, loading } = useFetch(
    () =>
      noteAPI.fetchNotes(associationId, {
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        sortBy: sortByCreatedAt ? 'createdAt' : undefined,
        sortDirection: sortByCreatedAt
          ? sortByCreatedAt === 'ascend'
          : undefined,
      }),
    [current, updateTrigger, sortByCreatedAt],
  );

  const handleSortChange = (sort: SortProps) => {
    if (Array.isArray(sort) || sort.order == sortByCreatedAt) {
      return;
    }

    if (sort.order) {
      setSortByCreatedAt(sort.order);
    } else {
      if (sortByCreatedAt) {
        setSortByCreatedAt(null);
      }
    }
  };

  return (
    <TemplateNotesTable
      data={response?.data || []}
      total={response?.total || 0}
      current={current}
      loading={loading}
      onSortChange={handleSortChange}
      onActionsClick={onActionsClick}
      onPaginationChange={setCurrent}
    />
  );
};

export default NotesTable;
