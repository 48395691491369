import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../../hooks/useTranslation';

// components
import Icon from '../../../../../../../Icons/Icon';
import VerifiedIcon from '../../../../../../../../resources/images/rejected-red.png';
import { Typography } from 'antd';

const FailVerification = () => {
  const { t } = useTranslation('orac');

  return (
    <ContentWrapper>
      <Icon width="150px" height="150px" icon={VerifiedIcon} />
      <StyledText>{t('users.view.fail')}</StyledText>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledText = styled(Typography.Text)`
  font-size: 28px;
  margin-top: ${({ theme }) => theme.marginSm};
  color: ${({ theme }) => theme.whiteColor};
`;

export default FailVerification;
