import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { ClientReviewStatus } from '../../../../enums/crm/crm';

// components
import StatusLabel from '../../../Typography/StatusLabel';

const STATUS_MAP = {
  info: [ClientReviewStatus.NotReviewed],
  warning: [
    ClientReviewStatus.BusinessReview,
    ClientReviewStatus.ComplianceReview,
    ClientReviewStatus.ManagerReview,
  ],
  success: [ClientReviewStatus.Reviewed],
  error: [],
};

interface IProps {
  status: ClientReviewStatus;
}

const ClientGroupReviewStatus = memo(({ status }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(`client_groups.business_review_status.${status}`, '')}
    />
  );
});

export default ClientGroupReviewStatus;
