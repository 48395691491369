import React from 'react';

// helpers
import useUserAccess from '../../../hooks/useUserAccess';
import useTranslation from '../../../hooks/useTranslation';

// components
import Tabs from '../../../components/Tabs';

import ApprovalGroupsTab from '../../../modules/ApprovalManagement/Templates/Finance/ApprovalGroupsTab';
import ApprovalRulesTab from '../../../modules/ApprovalManagement/Templates/Finance/ApprovalRulesTab';

const Finance = () => {
  const { t } = useTranslation('approval_management');

  const [hasApprovalGroupsViewAccess, hasApprovalRulesViewAccess] =
    useUserAccess([
      'ApprovalManagement_Templates_Finance_ApprovalGroups_View',
      'ApprovalManagement_Templates_Finance_ApprovalRules_View',
    ]);

  const tabs = [
    {
      key: 'approval-groups',
      title: t('approval_management.approval_groups.title'),
      content: <ApprovalGroupsTab />,
      hidden: !hasApprovalGroupsViewAccess,
    },
    {
      key: 'approval-rules',
      title: t('approval_management.approval_rules.title'),
      content: <ApprovalRulesTab />,
      hidden: !hasApprovalRulesViewAccess,
    },
  ];

  return <Tabs tabs={tabs} renderOnChange />;
};

export default Finance;
