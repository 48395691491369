import React, { useMemo, useState } from 'react';

// helpers
import useUserAccess from '../../../../../../hooks/useUserAccess';
import { ReviewProcessStatuses } from '../../../../../../enums/compliance/reviewConfiguration';
import { useReviewProcessContext } from '../../../../../../hooks/domainHooks/useReviewProcess';
import {
  ReviewProcessAlertModel,
  ReviewProcessType,
} from '../../../../../../typings/compliance/reviewProcess';

// components
import FilterBar from './FilterBar';
import ReviewButtons from './ReviewButtons';
import RDCReviewRulesTable from '../../../../../../components/Tables/TableTemplates/Compliance/RDCReviewRulesTable';
import ReviewProcessObjectsTable from '../../../../../../components/Tables/TableTemplates/Compliance/ReviewProcessObjectsTable';
import { Divider } from 'antd';

export enum FilterByIsTriggeredStatusOptions {
  All,
  IsTriggered,
  NotTriggered,
}

const RDCAlertsTable = () => {
  const { rdcReviewProcess, reviewProcessStatus } = useReviewProcessContext();

  const [hasReviewAccess] = useUserAccess([
    'Compliance_Transactions_Details_ReviewAlert',
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterByIsTriggeredStatus, setFilterByIsTriggeredStatus] =
    useState<FilterByIsTriggeredStatusOptions>(
      FilterByIsTriggeredStatusOptions.IsTriggered,
    );

  const canReview =
    hasReviewAccess &&
    (reviewProcessStatus === ReviewProcessStatuses.New ||
      reviewProcessStatus === ReviewProcessStatuses.InProgress);

  const data = useMemo(() => {
    return rdcReviewProcess.alerts.filter((e) => {
      if (filterByIsTriggeredStatus === FilterByIsTriggeredStatusOptions.All) {
        return true;
      }

      return filterByIsTriggeredStatus ===
        FilterByIsTriggeredStatusOptions.IsTriggered
        ? e.isTriggered
        : !e.isTriggered;
    });
  }, [rdcReviewProcess.alerts, filterByIsTriggeredStatus]);

  const expandableRow = useMemo(
    () => ({
      rowExpandable: ({ rules }: ReviewProcessAlertModel) => !!rules.length,
      expandIconColumnIndex: 99,
      expandedRowRender: (record: ReviewProcessAlertModel) => (
        <>
          <RDCReviewRulesTable data={record.rules} />
          {canReview && record.isTriggered && <ReviewButtons alert={record} />}
        </>
      ),
    }),
    [canReview],
  );

  const handleFilterChange = (
    key: string,
    value: FilterByIsTriggeredStatusOptions,
  ) => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }

    if (key === 'is_triggered_status') {
      setFilterByIsTriggeredStatus(value);
    }
  };

  return (
    <>
      <FilterBar
        selectedStatus={filterByIsTriggeredStatus}
        onFilterChange={handleFilterChange}
      />
      <Divider />
      <ReviewProcessObjectsTable
        data={data}
        current={currentPage}
        onPaginationChange={setCurrentPage}
        expandable={expandableRow}
        reviewProcessType={ReviewProcessType.RDC}
      />
    </>
  );
};

export default RDCAlertsTable;
