import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from '../../../../../../hooks/useUserAccess';
import { IEntity } from 'typings/crm/entity';

// components
import AddRelationships from './AddRelationships';
import RelationshipsTable from './RelationshipsTable';

import { Col, Row } from 'antd';

interface IProps {
  entity: IEntity;
}

const Relationships = ({ entity }: IProps) => {
  const [hasCreateAccess] = useUserAccess([
    'CRM_Entities_Details_RelationshipsTab_CreateUpdate',
  ]);
  const [updateTrigger, updateTable] = useState({});

  return (
    <>
      <Row gutter={[36, 16]}>
        {hasCreateAccess && (
          <Col span={24}>
            <ButtonWrapper>
              <AddRelationships entity={entity} onAdd={() => updateTable({})} />
            </ButtonWrapper>
          </Col>
        )}

        <Col span={24}>
          <RelationshipsTable entity={entity} updateTrigger={updateTrigger} />
        </Col>
      </Row>
    </>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default Relationships;
