import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../../../../hooks/useFetch';
import useUserAccess from '../../../../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import feeScheduleAPI from '../../../../../../../../api/finance/feeSchedule/feeScheduleAPI';
import { IClientGroup } from '../../../../../../../../typings/crm/client-group';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { FormValuesModel as FeeScheduleFormValues } from '../../../../../../../../components/Modals/TemplateModalDialogs/Accounting/FeeSchedule/UpdateFeeScheduleEntryModal';
import {
  FeeScheduleEntryModel,
  FeeScheduleModel,
  NewFeeScheduleModel,
} from '../../../../../../../../typings/finance/feeSchedule';

// components
import FeeScheduleTable from '../../../../../../../../components/Tables/TableTemplates/Accounting/FeeScheduleTable';
import UpdateFeeScheduleEntryModal from '../../../../../../../../components/Modals/TemplateModalDialogs/Accounting/FeeSchedule/UpdateFeeScheduleEntryModal';
import { message, Modal } from 'antd';

interface IProps {
  clientGroup: IClientGroup;
}

const TableSection = ({ clientGroup }: IProps) => {
  const { t } = useTranslation(['crm', 'common']);
  const [hasUpdateAccess] = useUserAccess([
    'CRM_ClientGroup_Details_FeeScheduleTab_FeeSchedule_Edit',
  ]);
  const [updateTableTrigger, updateTable] = useState({});

  const { response, loading } = useFetch(
    () =>
      feeScheduleAPI.fetchFeeSchedulesByClientGroupId(clientGroup.numericId),
    [clientGroup.numericId, updateTableTrigger],
  );

  const [selectedFeeScheduleEntry, setSelectedFeeScheduleEntry] =
    useState<FeeScheduleEntryModel | null>(null);

  const handleActionsClick = (
    key: string,
    record: FeeScheduleModel | FeeScheduleEntryModel,
  ) => {
    switch (key) {
      case 'update_fee_schedule_entry':
        setSelectedFeeScheduleEntry(record as FeeScheduleEntryModel);
        break;
    }
  };

  const handleResetFeeScheduleEntryToDefault = (
    feeScheduleEntry: FeeScheduleEntryModel,
  ) => {
    Modal.confirm({
      content: t('client_group.fee_schedule.reset_to_default_msg'),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: () => resetFeeScheduleEntryToDefault(feeScheduleEntry.id),
    });
  };

  const resetFeeScheduleEntryToDefault = async (entryId: number) => {
    await feeScheduleAPI.deleteFeeScheduleEntryById(entryId);
    message.success(t('client_group.fee_schedule.reset_fee_schedule_success'));
    updateTable({});
  };

  const handleFormSubmit = async (values: FeeScheduleFormValues) => {
    if (values.isDefault) {
      const newFeeSchedule: NewFeeScheduleModel = {
        amount:
          AccountsHelpers.convertAmountFromIntToBigInt(values.amount) || 0,
        currencyId: values.currencyId,
        typeId: values.feeTypeId,
      };

      await feeScheduleAPI.createFeeScheduleEntryForClientGroup(
        clientGroup.numericId,
        newFeeSchedule,
      );
    } else {
      await feeScheduleAPI.updateFeeScheduleEntryById(values.entryId, {
        amount:
          AccountsHelpers.convertAmountFromIntToBigInt(values.amount) || 0,
      });
    }
  };

  const handleFeeScheduleModalClose = (shouldUpdateTable?: boolean) => {
    if (shouldUpdateTable) {
      updateTable({});
    }

    setSelectedFeeScheduleEntry(null);
  };
  return (
    <>
      <FeeScheduleTable
        clientRelated
        canEdit={hasUpdateAccess}
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={1}
        onActionsClick={handleActionsClick}
      />

      <UpdateFeeScheduleEntryModal
        onSubmit={handleFormSubmit}
        isVisible={!!selectedFeeScheduleEntry}
        closeCallback={handleFeeScheduleModalClose}
        feeScheduleEntry={selectedFeeScheduleEntry}
        handleResetToDefault={handleResetFeeScheduleEntryToDefault}
      />
    </>
  );
};

export default TableSection;
