import React from 'react';

// helpers
import { entityAPI } from '../../../../../../api/crm/entity/entityAPI';
import { EntityHelpers } from '@helpers/crm/entity';
import { FormFieldPropsModel } from '@core_components/FormField';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import Autocomplete, {
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';

type Props = FormFieldPropsModel;

const EntityRequestsAutocomplete = (props: Props) => {
  const fetchEntities = async (search: string) => {
    const { data } = await entityAPI.fetchEntities({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      search,
    });
    return data.map<AutocompleteOption>((entity) => {
      return {
        id: entity._id,
        content: EntityHelpers.getEntityName(entity),
        model: entity,
      };
    });
  };

  return <Autocomplete {...props} fetchData={fetchEntities} />;
};

export default EntityRequestsAutocomplete;
