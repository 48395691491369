import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { useFormikContext } from 'formik';
import { ForexTransferFormValues } from '.';

// components
import FormField from '@core_components/FormField';
import AccountInfo from './AccountInfo';
import Description from '../../../../Additional/Description';
import AmountField from '../../../FormComponents/AmountField';
import ChangedSpreadValueTooltip from './ChangedSpreadValueTooltip';
import { InputNumber, Typography } from 'antd';

const TreasuryRequestSection = () => {
  const { t } = useTranslation('banking');
  const { values } = useFormikContext<ForexTransferFormValues>();

  return (
    <Description colon layout="vertical" column={2} size="small">
      <Description.Item
        label={
          <StyledLabelText>
            {t('forex.transfer_details.from_sell')}
          </StyledLabelText>
        }
      >
        <AccountInfo
          accountName={values.fromCorrespondentAccountName}
          accountNumber={values.fromCorrespondentAccountNumber}
          accountCurrency={values.fromAccountCurrency}
        />
      </Description.Item>

      <Description.Item
        label={
          <StyledLabelText>
            {t('forex.transfer_details.to_buy')}
          </StyledLabelText>
        }
      >
        <AccountInfo
          accountName={values.toCorrespondentAccountName}
          accountNumber={values.toCorrespondentAccountNumber}
          accountCurrency={values.toAccountCurrency}
        />
      </Description.Item>

      <Description.Item>
        <StyledFormField
          name={values.isCurrencyFANNcy ? 'toCurrencyRate' : 'fromCurrencyRate'}
          label={t('forex.transfer_details.exchange_rate')}
          component={StyledAmountField}
          placeholder={t('forex.transfer_details.exchange_rate_ph')}
          disabled={values.isViewOnlyMode}
        />
      </Description.Item>

      <Description.Item>
        <StyledFormField
          name="spread"
          label={
            <Typography.Text>
              {`${t('forex.transfer_details.internal_spread')} (%)`}
              {values.isSpreadModified && (
                <ChangedSpreadValueTooltip
                  originalSpread={values.originalSpread}
                />
              )}
            </Typography.Text>
          }
          component={StyledInputNumber}
          placeholder={t('forex.transfer_details.internal_spread_ph')}
          disabled={values.isViewOnlyMode}
          isHighlighted={values.isSpreadModified}
        />
      </Description.Item>
    </Description>
  );
};

const StyledFormField = styled(FormField)<{ isHighlighted?: boolean }>`
  width: 95%;
  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    `.ant-input-number-input-wrap { color: ${theme.warningColor} !important; } `}
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

const StyledAmountField = styled(AmountField)`
  width: 100%;
`;

const StyledLabelText = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.fontSizeSm} !important;
`;

export default TreasuryRequestSection;
