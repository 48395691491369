import React from 'react';

// helpers
import { IBank } from 'api/banking/bankingBankAccountsAPI';
import { AutocompleteProps } from '@core_components/Autocomplete';
import { correspondentBanksAPI } from 'api/banking/correspondentBanksAPI';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export interface BankAutocompleteProps extends AutocompleteProps<IBank> {
  bankCodeType: number;
}

const BankAutocomplete = ({ bankCodeType, ...rest }: BankAutocompleteProps) => {
  const fetchBanks = async (searchText: string) => {
    const { data } =
      await correspondentBanksAPI.fetchCorrespondentBankCodesSuggest(
        searchText,
        bankCodeType,
      );

    return data.map((bank) => ({
      id: bank.bankCode,
      label: bank.bankCode,
      model: bank,
    }));
  };

  return <FormAutocomplete {...rest} fetchData={fetchBanks} />;
};

export default BankAutocomplete;
