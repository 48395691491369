import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../../../../hooks/useFetch';
import { IClientGroup } from '../../../../../../../../typings/crm/client-group';
import { clientGroupsAPI } from '../../../../../../../../api/crm/clientGroup/clientGroupsAPI';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../../../constants/global';

// components
import { default as TemplateUsersTable } from '../../../../../../../../components/Tables/TableTemplates/CRM/ClientGroup/UsersTable';

interface IProps {
  clientGroup: IClientGroup;
}

const ClientGroupInformationTab = ({ clientGroup }: IProps) => {
  const [currentPage, setPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      clientGroupsAPI.fetchClientGroupUsers(clientGroup._id, {
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [currentPage],
  );

  return (
    <TemplateUsersTable
      loading={loading}
      data={response?.data || []}
      total={response?.total || 0}
      current={currentPage}
      clientGroup={clientGroup}
      onPaginationChange={setPage}
    />
  );
};

export default ClientGroupInformationTab;
