export enum ClientTransferApprovalStatuses {
  PendingApprovals = 1,
  Completed,
  Rejected,
  Processing,
  InReview,
  AMLReview,
  Failed,
  Cancelled,
  PendingTransmit = 13,
  BankRejected = 14,
  Scheduled = 16,
  Expired = 17,
}
