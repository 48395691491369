import React, { useMemo, useState } from 'react';

// constants

// helpers
import styled from 'styled-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { generateUniqId } from '@helpers/utils';
import { FieldInputProps } from 'formik';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { StyledComponentProps } from 'typings/common';
import {
  ColumnFilterItem,
  ExpandableConfig,
  FilterDropdownProps,
  SorterResult,
  TableCurrentDataSource,
  TablePaginationConfig,
} from 'antd/lib/table/interface';

// components
import ExpandableWrapper from 'components/WrapperComponents/ExpandableWrapper';
import {
  Empty,
  Col,
  Pagination,
  Row,
  Spin,
  Table as AntTable,
  Typography,
} from 'antd';

// Icons
import { ReactComponent as DownArrowIcon } from 'resources/images/icons/down-arrow.svg';

export type SortProps = SorterResult<any> | SorterResult<any>[];

export interface TablePropsModel extends StyledComponentProps {
  data: any[];
  expandable?: ExpandableConfig<any>;
  total?: number;
  current?: number;
  size?: SizeType;
  loading?: boolean;
  disableMobileMode?: boolean;
  columns: TableColumnModel[];
  onPaginationChange?: (newPage: number) => void;
  bordered?: boolean;
  showSizeChanger?: boolean;
  hidePagination?: boolean;
  showHeader?: boolean;
  components?: any;
  defaultExpandAllRows?: boolean;
  disableHorizontalScroll?: boolean;
  onSortChange?: (sorter: SortProps) => void;
  onFilterChange?: (
    filters: Record<string, (boolean | React.Key)[] | null>,
  ) => void;
}

type SortDirections = 'descend' | 'ascend';

export interface TableColumnModel {
  key: string;
  title?: string | JSX.Element;
  dataIndex?: string;
  width?: string | number;
  render?: any;
  mobileCardProps?: TableCardMobilePropsModel;
  fixed?: any;
  hidden?: boolean;
  ellipsis?: boolean;
  align?: 'left' | 'center' | 'right';
  field?: FieldInputProps<any>;
  sorter?: any;
  showSorterTooltip?: boolean;
  sortDirections?: SortDirections[];

  filteredValue?: any;
  filterSearch?: boolean;
  filterMultiple?: boolean;
  filters?: ColumnFilterItem[];
  filterDropdown?:
    | JSX.Element
    | ((filterProps: FilterDropdownProps) => JSX.Element);
}

export interface TableActionModel {
  key: string;
  name: string;
  hidden?: boolean;
  danger?: boolean;
}

interface TableCardMobilePropsModel {
  hide?: boolean;
  isCardTitle?: boolean;
  hideFromMainSection?: boolean;
  isActionsColumn?: boolean;
}

interface CardRowProps {
  key: string;
  title: string;
  value: any;
}

export interface RequiredPropsForTableModel<DataItem = unknown> {
  data: Array<DataItem>;
  total?: number;
  current?: number;
  loading?: boolean;
  onPaginationChange?: (page: number) => void;
}

const CardValueRow = ({ key, title, value }: CardRowProps) => (
  <StyledRow key={key}>
    <Col span={9}>
      {title && <Typography.Text>{`${title}:`}</Typography.Text>}
    </Col>
    <StyledValueCol span={15}>
      <Typography.Text>{value}</Typography.Text>
    </StyledValueCol>
  </StyledRow>
);

function mapTableRows(rows: any[], tableName?: string) {
  return rows.map((row: any, i: number) => ({
    key: `${tableName}-row-${i}`,
    ...row,
  }));
}

// Custom Table component
// this component is responsible for showing web / mobile tables
// Notes:
// 1. Tree dot's menu: to handle tree dot's menu need to set [isActionsColumn] in [mobileCardProps] object
// 2. Table title: to show some table title need to set [isCardTitle] as true for the necessary column
// 3. Disable mobile mode: to disabled mobile mode (cards) need to put [disableMobileMode] into Table props
const Table = ({
  data,
  expandable,
  total = data.length,
  current = 1,
  columns,
  loading,
  onSortChange,
  onFilterChange,
  onPaginationChange,
  disableMobileMode,
  showSizeChanger = false,
  hidePagination = false,
  size = 'large',
  disableHorizontalScroll,
  ...rest
}: TablePropsModel) => {
  const tableId = useMemo(() => generateUniqId(), []);
  const breakpoint = useBreakpoint();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const tableColumns = useMemo(() => {
    return columns.filter((e) => !e.hidden);
  }, [columns]);

  const expandableConfig: ExpandableConfig<any> | undefined = useMemo(() => {
    if (!expandable) {
      return undefined;
    }

    const result: ExpandableConfig<any> = {
      ...expandable,
      expandedRowKeys: expandedRowKeys,
      onExpandedRowsChange: (keys) => setExpandedRowKeys([...keys] as string[]),
      expandIcon: ({
        expanded,
        onExpand,
        record,
        expandable: expandableFromCallback,
      }) => {
        return (Array.isArray(expandableFromCallback) &&
          expandableFromCallback.length) ||
          (typeof expandableFromCallback === 'boolean' &&
            expandableFromCallback) ? (
          <StyledExpandIconContainer>
            <ExpandIconWrapper
              onClick={(e) => onExpand(record, e)}
              $isOpened={expanded}
            >
              <DownArrowIcon />
            </ExpandIconWrapper>
          </StyledExpandIconContainer>
        ) : (
          <NonExpandedWrapper />
        );
      },
    };

    return result;
  }, [expandable, expandedRowKeys]);

  const isMobileMode = () => !breakpoint.md && !disableMobileMode;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (boolean | React.Key)[] | null>,
    sorter: SorterResult<any> | SorterResult<unknown>[],
    extra: TableCurrentDataSource<unknown>,
  ) => {
    switch (extra.action) {
      case 'paginate':
        {
          if (pagination.current !== current && onPaginationChange) {
            onPaginationChange(pagination.current || 1);
          }
        }
        break;

      case 'sort':
        {
          if (onSortChange) {
            onSortChange(sorter);
            if (current > 1) {
              onPaginationChange && onPaginationChange(1);
            }
          }
        }
        break;

      case 'filter': {
        if (onFilterChange) {
          onFilterChange(filters);
          if (current > 1) {
            onPaginationChange && onPaginationChange(1);
          }
        }
      }
    }
  };

  const renderTable = () => (
    <AntTable
      {...rest}
      loading={loading}
      expandable={expandableConfig}
      columns={tableColumns}
      dataSource={mapTableRows(data, tableId)}
      size={size}
      scroll={!disableHorizontalScroll ? { x: 'max-content' } : undefined}
      onChange={handleTableChange}
      pagination={
        !hidePagination && {
          total,
          current,
          pageSize: DEFAULT_TABLE_LIMIT,
          hideOnSinglePage: true,
          showSizeChanger,
        }
      }
    />
  );

  // render mobile table (cards)
  const renderCards = () => {
    const { mainValues, additionalValues, titleColumn, actionsColumn } =
      getCardValues(columns);

    const shouldRenderShowMore = !!additionalValues.length;
    const shouldRenderEmpty = !loading && !data.length;

    return (
      <StyledSpin spinning={loading} size="large">
        {data.map((dataEl, index) => (
          <Card key={index}>
            {titleColumn && (
              <CardHeader>
                <Typography.Text>{dataEl[titleColumn]}</Typography.Text>
              </CardHeader>
            )}

            <CardContent>
              {actionsColumn && (
                <CardValueRow
                  key="actions"
                  title=""
                  value={actionsColumn.render(dataEl)}
                />
              )}

              {renderCardValue(mainValues, dataEl)}

              {shouldRenderShowMore && (
                <ExpandableWrapper showTriggerAfter>
                  {renderCardValue(additionalValues, dataEl)}
                </ExpandableWrapper>
              )}
            </CardContent>
          </Card>
        ))}
        {shouldRenderEmpty && (
          <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        {renderPaginationBar()}
      </StyledSpin>
    );
  };

  // render card values by table columns
  const renderCardValue = (columns: TableColumnModel[], dataItem: any) =>
    columns.map(({ title, render, dataIndex }, index: number) => {
      // handle render
      const rowValue = render
        ? render(dataIndex ? dataItem[dataIndex] : dataItem, dataItem)
        : dataIndex && dataItem[dataIndex];

      return (
        <CardValueRow
          key={`${title}-${index}`}
          title={(title as string) || ''}
          value={rowValue}
        />
      );
    });

  // generate card values
  // return:
  // - main values: values which should be shown in main container (array of columns)
  // - additional values: values which should be shown in show more container (array of columns)
  // - title column: card title value (string)
  // - actions column: three dots column (1 column)
  const getCardValues = (columnsArray: TableColumnModel[]) => {
    const mainValues: TableColumnModel[] = [];
    const additionalValues: TableColumnModel[] = [];
    let titleColumn: string | null = null;
    let actionsColumn: TableColumnModel | null = null;

    columnsArray.forEach((e) => {
      if (e.mobileCardProps?.isCardTitle) {
        titleColumn = e.dataIndex || '';
      }

      if (e.mobileCardProps?.isActionsColumn) {
        actionsColumn = { ...e };
        return;
      }

      if (e.mobileCardProps?.hide) return;

      if (e.mobileCardProps?.hideFromMainSection) {
        additionalValues.push(e);
      } else {
        mainValues.push(e);
      }
    });

    return { mainValues, additionalValues, titleColumn, actionsColumn } as {
      mainValues: TableColumnModel[];
      additionalValues: TableColumnModel[];
      titleColumn: string | null;
      actionsColumn: TableColumnModel | null;
    };
  };

  const renderPaginationBar = () => (
    <EndAlignWrapper>
      <Pagination
        current={current}
        total={total}
        hideOnSinglePage
        onChange={onPaginationChange}
      />
    </EndAlignWrapper>
  );

  return <>{isMobileMode() ? renderCards() : renderTable()}</>;
};

const Card = styled.div`
  border: ${({ theme }) => `2px solid ${theme.tableBorderColor}`};
  border-radius: 8px;
  min-height: 100px;
  height: auto;
  margin-bottom: ${({ theme }) => theme.marginSm};
  box-shadow: ${({ theme }) => theme.tableCardShadow};
  overflow: hidden;
  transition: max-height 0.5s ease-out;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
  background-color: ${({ theme }) => theme.backgroundColor4};
  font-size: 15px;
`;

const CardContent = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.paddingMd};
  background: ${({ theme }) => theme.tableCardBackground};

  .ant-row:last-child {
    margin-bottom: 0px;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

const StyledExpandIconContainer = styled.div`
  display: inline-block;
`;

const StyledValueCol = styled(Col)`
  display: flex;
  justify-content: flex-end;

  overflow: hidden;

  .ant-typography {
    text-align: end;
  }
`;

const StyledSpin = styled(Spin)<any>`
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EndAlignWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledEmpty = styled(Empty)`
  color: ${({ theme }) => theme.whiteColor};
`;

const ExpandIconWrapper = styled.div<{ $isOpened: boolean }>`
  width: 26px;
  height: 26px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: ${({ theme }) => theme.backgroundColor3};

  &:hover {
    background-color: ${({ theme }) => theme.textBackgroundColorActive};
    box-shadow: ${({ theme }) => theme.whiteColor};
  }

  ${({ theme, $isOpened }) =>
    $isOpened ? `box-shadow:${theme.cardShadow}` : ''};

  svg {
    transition: all 0.5s ease;
    fill: ${({ theme }) => theme.whiteColor};

    width: 14px;
    height: 14px;
    transform: ${({ $isOpened }) =>
      $isOpened ? 'rotateX(180deg)' : 'rotateX(0deg)'};
  }
`;

const NonExpandedWrapper = styled.div`
  width: 26px;
  height: 26px;
  display: inline-block;
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default Table;
