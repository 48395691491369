export enum AccountRecoveryEntryStatus {
  Approved = 'approved',
  Rejected = 'rejected',
  Pending = 'pending',
  Withdrawn = 'withdrawn',
}

export enum VerificationDocumentType {
  PhotoId = 'photo-id',
  Passport = 'passport',
  DriverLicense = 'driver-license',
}

export enum VerificationDocumentPhotoType {
  Front = 'Front',
  Back = 'Back',
  Selfie = 'Selfie',
}
