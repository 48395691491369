import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import useUserAccess from '../../../../hooks/useUserAccess';
import feeScheduleAPI from '../../../../api/finance/feeSchedule/feeScheduleAPI';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { FormValuesModel as FeeScheduleFormValues } from '../../../../components/Modals/TemplateModalDialogs/Accounting/FeeSchedule/UpdateFeeScheduleEntryModal';
import {
  FeeScheduleEntryModel,
  FeeScheduleModel,
  NewFeeScheduleModel,
} from '../../../../typings/finance/feeSchedule';

// components
import FeeScheduleTable from '../../../../components/Tables/TableTemplates/Accounting/FeeScheduleTable';
import UpdateFeeScheduleEntryModal from '../../../../components/Modals/TemplateModalDialogs/Accounting/FeeSchedule/UpdateFeeScheduleEntryModal';

const TableSection = () => {
  const [hasEditAccess] = useUserAccess([
    'Banking_FeeSchedule_FeeScheduleSection_Edit',
  ]);
  const [updateTableTrigger, updateTable] = useState({});
  const [selectedFeeScheduleEntry, setSelectedFeeScheduleEntry] =
    useState<FeeScheduleEntryModel | null>(null);
  const { response, loading } = useFetch(
    () => feeScheduleAPI.fetchDefaultFeeSchedules(),
    [updateTableTrigger],
  );

  const handleActionsClick = (
    key: string,
    record: FeeScheduleModel | FeeScheduleEntryModel,
  ) => {
    switch (key) {
      case 'update_fee_schedule_entry':
        setSelectedFeeScheduleEntry(record as FeeScheduleEntryModel);
        break;
    }
  };

  const handleFormSubmit = async (values: FeeScheduleFormValues) => {
    const newFeeSchedule: NewFeeScheduleModel = {
      amount: AccountsHelpers.convertAmountFromIntToBigInt(values.amount) || 0,
      currencyId: values.currencyId,
      typeId: values.feeTypeId,
    };

    await feeScheduleAPI.updateFeeScheduleEntryById(
      values.entryId,
      newFeeSchedule,
    );
  };

  const handleFeeScheduleModalClose = (shouldUpdateTable?: boolean) => {
    if (shouldUpdateTable) {
      updateTable({});
    }

    setSelectedFeeScheduleEntry(null);
  };

  return (
    <>
      <FeeScheduleTable
        canEdit={hasEditAccess}
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={1}
        onActionsClick={handleActionsClick}
      />

      <UpdateFeeScheduleEntryModal
        onSubmit={handleFormSubmit}
        isVisible={!!selectedFeeScheduleEntry}
        closeCallback={handleFeeScheduleModalClose}
        feeScheduleEntry={selectedFeeScheduleEntry}
      />
    </>
  );
};

export default TableSection;
