import React, { useEffect, useState } from 'react';

// helpers
import { AuthHelpers } from '@helpers/auth';
import { FidoHelpers } from '@helpers/fido';
import { useFormikContext } from 'formik';
import { ChallengeForRegistrationModel } from '../../../../../../../../typings/profile/fido';
import {
  FormValuesModel,
  RegisterSecurityKeySteps,
  SecurityKeyCredModel,
} from '../../..';

// helpers
import LoadingWrapper from '../../../../../../../WrapperComponents/LoadingWrapper';
import ErrorWithRetryButton from './ErrorWithRetryButton';
import ContentWithUseKeyButton from './ContentWithUseKeyButton';

interface IProps {
  challenge: ChallengeForRegistrationModel | null;
  loadingChallenge: boolean;
}

const Content = ({ challenge, loadingChallenge }: IProps) => {
  const { values, setValues } = useFormikContext<FormValuesModel>();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isSafari = AuthHelpers.isSafariBrowser();

  useEffect(() => {
    // Safari browser is not allowing WebAuthn usage without user interaction
    // To use WebAuthn user will have to click on special button (by that trigger onClick event where we can run ceremony)
    if (challenge && !isSafari) {
      runFidoCeremony();
    }
  }, [challenge, isSafari]);

  async function runFidoCeremony() {
    if (challenge) {
      setIsError(false);
      setIsLoading(true);
      const publicKey: PublicKeyCredentialCreationOptions =
        FidoHelpers.preformatChallengeForRegistration(challenge);

      await navigator.credentials
        .create({ publicKey })
        .then(async (newCrdInfo) => {
          const formattedNewCrdInfo = FidoHelpers.publicKeyCredentialToJSON(
            newCrdInfo,
          ) as SecurityKeyCredModel;
          const securityKeyCredInfo: SecurityKeyCredModel = {
            id: formattedNewCrdInfo.id,
            rawId: formattedNewCrdInfo.rawId,
            type: formattedNewCrdInfo.type,
            response: {
              attestationObject: formattedNewCrdInfo.response.attestationObject,
              clientDataJSON: formattedNewCrdInfo.response.clientDataJSON,
            },
          };

          setValues({
            ...values,
            currentStep: RegisterSecurityKeySteps.SetupNameForKey,
            securityKeyCredInfo,
          });
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  return (
    <LoadingWrapper loading={isLoading || loadingChallenge}>
      {isSafari && !isError ? (
        <ContentWithUseKeyButton onTryClick={runFidoCeremony} />
      ) : (
        <ErrorWithRetryButton onRetryClick={runFidoCeremony} />
      )}
    </LoadingWrapper>
  );
};

export default Content;
