import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { TransactionModel } from '../../typings/finance/transaction';
import { ApprovalWorkflowInstanceModel } from '../../typings/approval/approvalWorkflow';
import { TransactionWithComplianceInfoModel } from '../../typings/compliance/transactions';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import {
  ReviewProcessModel,
  ReviewProcessType,
} from '../../typings/compliance/reviewProcess';
import {
  ComplianceReviewTypes,
  ReviewConfigurationsStatuses,
} from '../../enums/compliance/reviewConfiguration';

export interface ReviewProcessResponseModel {
  transaction: TransactionModel;
  reviewProcess: ReviewProcessModel;
  workflow: ApprovalWorkflowInstanceModel | null;
}

export interface ReviewAlertRequestBodyModel {
  itemId: string;
  itemType: ReviewProcessType;
  reviewResult: {
    status: ReviewConfigurationsStatuses;
    reason: string;
  };
  metadata: {
    reviewProcessId: string;
    inquiryId?: string;
    reviewConfigurationRuleId?: string;
  };
}

export interface ReviewAlertResult {
  id: string;
  itemId: string;
  itemType: ReviewProcessType;
  createdAt: string;
  metadata: {
    reviewProcessId: string;
    inquiryId?: string;
    reviewConfigurationRuleId?: string;
  };
  reviewResult: {
    reason: string;
    reviewedAt: string;
    reviewedBy: string;
    reviewedByName: string;
    status: ReviewConfigurationsStatuses;
  };
}

interface ReviewProcessParams extends FetchParamsModel {
  types: string | ComplianceReviewTypes;
  objectIds?: string;
  search?: string;
  transactionSearch?: string;
  statuses?: string;
  reviewProcessStatuses?: string;
  transactionFromValueDate?: number;
  transactionToValueDate?: number;
  transactionFromPostingDate?: number;
  transactionToPostingDate?: number;
  transactionAccountNumbers?: string;
}

const transactionsAPI = {
  fetchReviewProcesses: (params: ReviewProcessParams) => {
    return APIService.get<
      FetchResponseModel<TransactionWithComplianceInfoModel>
    >(`${APIConfig.amlApi}/review-process`, { params }).then(
      ({ data }) => data,
    );
  },

  fetchReviewProcessById: (reviewProcessId: string) => {
    return APIService.get<ReviewProcessResponseModel>(
      `${APIConfig.amlApi}/review-process/${reviewProcessId}`,
    ).then(({ data }) => data);
  },

  approveReviewProcessAlert: (
    reviewProcessType: ReviewProcessType,
    reviewProcessId: string,
    alertId: string,
    reason: string,
    reviewConfigurationRuleId?: string,
    inquiryId?: string,
  ) => {
    const body: ReviewAlertRequestBodyModel[] = [
      {
        itemId: alertId,
        itemType: reviewProcessType,
        reviewResult: { status: ReviewConfigurationsStatuses.Passed, reason },
        metadata: { reviewProcessId, reviewConfigurationRuleId, inquiryId },
      },
    ];

    return APIService.post<FetchResponseModel<ReviewAlertResult>>(
      `${APIConfig.amlApi}/review-item`,
      body,
    ).then(({ data }) => data);
  },

  rejectReviewProcessAlert: (
    reviewProcessType: ReviewProcessType,
    reviewProcessId: string,
    alertId: string,
    reason: string,
    reviewConfigurationRuleId?: string,
    inquiryId?: string,
  ) => {
    const body: ReviewAlertRequestBodyModel[] = [
      {
        itemId: alertId,
        itemType: reviewProcessType,
        reviewResult: {
          status: ReviewConfigurationsStatuses.NotPassed,
          reason,
        },
        metadata: { reviewProcessId, reviewConfigurationRuleId, inquiryId },
      },
    ];

    return APIService.post<FetchResponseModel<ReviewAlertResult>>(
      `${APIConfig.amlApi}/review-item`,
      body,
    ).then(({ data }) => data);
  },

  submitForReview: (reviewProcessId: string) => {
    return APIService.post(
      `${APIConfig.amlApi}/review-process/${reviewProcessId}/submit`,
    ).then(({ data }) => data);
  },
};

export default transactionsAPI;
