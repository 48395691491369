import React from 'react';

// helpers
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { ContactHelpers } from '@helpers/crm/contact';

// components
import Link from '@common_components/Texts/Link';

export type ComplianceContactChangeRequestData = {
  clientChangeRequest: { id: string };
  contactSnapshot: {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
  };
};

interface IProps {
  data: ComplianceContactChangeRequestData;
}

const ComplianceContactUpdatePersonalDetails = ({ data }: IProps) => {
  const history = useHistory();

  return (
    <Link
      onClick={() =>
        history.push({
          pathname: RoutePaths.CRM_Contacts_Edit,
          search: `?id=${data.contactSnapshot.id}`,
        })
      }
    >
      {ContactHelpers.getContactName(data.contactSnapshot)}
    </Link>
  );
};

export default ComplianceContactUpdatePersonalDetails;
