import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { IContactModel } from '../../../../typings/crm/contact';
import { ContactHelpers } from '@helpers/crm/contact';
import { IOnboardingStatus } from '../../../../typings/crm/onboarding';

// components
import Link from '../../../../components/Typography/Link';
import { Col, Row } from 'antd';

interface IProps {
  contact: IContactModel;
  onboardingStatus?: IOnboardingStatus | null;
}

const ClientInfo = ({ contact, onboardingStatus }: IProps) => {
  const { t } = useTranslation('crm');

  const renderClientGroup = () => {
    if (!contact.clientGroup) return '-';

    return (
      <Link
        to={{
          pathname: '/crm/client-groups/details',
          search: `?id=${contact.clientGroup._id}`,
        }}
      >
        {contact.clientGroup.clientGroupName}
      </Link>
    );
  };

  return (
    <>
      <StyledRow>
        <Col span="12">
          <FieldName>{t('client.info.direct_contact')}: </FieldName>
          <FieldValue>
            <Link
              to={{
                pathname: '/crm/contacts/edit',
                search: `?id=${contact._id}`,
              }}
            >
              {ContactHelpers.getContactName(contact)}
            </Link>
          </FieldValue>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col span="12">
          <FieldName>{t('client.info.client_group')}: </FieldName>
          <FieldValue>{renderClientGroup()}</FieldValue>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col span="12">
          <FieldName>{t('client_group.info.status')}: </FieldName>
          <FieldValue>
            {t(`application.statuses.${onboardingStatus?.status}`, '-')}
          </FieldValue>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col span="12">
          <FieldName>{t('client_group.info.review_status')}: </FieldName>
          <FieldValue>
            {t(
              `client_groups.business_review_status.${onboardingStatus?.reviewStatus}`,
              '-',
            )}
          </FieldValue>
        </Col>
      </StyledRow>
      <StyledRow>
        <Col span="12">
          <FieldName>{t('client_group.info.created_at')}: </FieldName>
          <FieldValue>
            {onboardingStatus?._audit.createdAt
              ? DateHelpers.formatDateToString(
                  onboardingStatus?._audit.createdAt,
                  'DD MMM YYYY, h:mm A',
                )
              : ''}
          </FieldValue>
        </Col>
      </StyledRow>
    </>
  );
};

const FieldName = styled.span`
  color: ${({ theme }) => theme.textLightColor3};
  padding-right: ${({ theme }) => theme.marginXXs};
`;

const FieldValue = styled.span`
  color: ${({ theme }) => theme.textLightColor2};
  font-weight: 600;
`;

const StyledRow = styled(Row)`
  margin: ${({ theme }) => theme.marginXXs} 0;
`;

export default ClientInfo;
