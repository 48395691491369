import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import {
  IApprovalStatus,
  IApprovalTemplateInfo,
} from '../../../typings/crm/onboarding';

export interface ApprovalRequestBody {
  isSubmit: boolean;
  clientGroupId: string;
  itemId: string;

  generalComment?: string;

  fields?: {
    fieldName: string;
    isApproved: boolean | '';
    comment?: string;
  }[];
}

const approvalAPI = {
  create: (body: ApprovalRequestBody) =>
    APIService.post(
      `${APIConfig.crmApi}/client-groups/${body.clientGroupId}/approval/create`,
      body,
    ),

  // New Business Workflow
  fetchNewBusinessApprovalTemplate: () => {
    return APIService.get<IApprovalTemplateInfo>(
      `${APIConfig.approvalApi}/new-business/template/info`,
    ).then(({ data }) => data);
  },
  fetchNewBusinessStatuses: (workflowId: string) => {
    return APIService.get<IApprovalStatus>(
      `${APIConfig.approvalApi}/workflows/${workflowId}`,
    ).then(({ data }) => data);
  },
  approveNewBusiness: (workflowId: string, reason: string) => {
    return APIService.post<IApprovalStatus>(
      `${APIConfig.approvalApi}/new-business/workflows/${workflowId}/approve`,
      {
        reason,
      },
    ).then(({ data }) => data);
  },
  rejectNewBusiness: (workflowId: string, reason: string) => {
    return APIService.post<IApprovalStatus>(
      `${APIConfig.approvalApi}/new-business/workflows/${workflowId}/reject`,
      {
        reason,
      },
    ).then(({ data }) => data);
  },

  // Compliance Workflow
  fetchComplianceApprovalTemplate: () => {
    return APIService.get<IApprovalTemplateInfo>(
      `${APIConfig.approvalApi}/compliance/template/info`,
    ).then(({ data }) => data);
  },
  fetchComplianceStatuses: (workflowId: string) => {
    return APIService.get<IApprovalStatus>(
      `${APIConfig.approvalApi}/workflows/${workflowId}`,
    ).then(({ data }) => data);
  },
  approveCompliance: (workflowId: string, reason: string) => {
    return APIService.post<IApprovalStatus>(
      `${APIConfig.approvalApi}/compliance/workflows/${workflowId}/approve`,
      { reason },
    ).then(({ data }) => data);
  },
  rejectCompliance: (workflowId: string, reason: string) => {
    return APIService.post<IApprovalStatus>(
      `${APIConfig.approvalApi}/compliance/workflows/${workflowId}/reject`,
      { reason },
    ).then(({ data }) => data);
  },

  // Forex transfer workflow
  fetchForexTransferWorkflowStatuses: (workflowId: string) => {
    return APIService.get<IApprovalStatus>(
      `${APIConfig.approvalApi}/workflows/${workflowId}`,
    ).then(({ data }) => data);
  },
  approveFxTransferWorkflow: (workflowId: string, reason: string) => {
    return APIService.post<IApprovalStatus>(
      `${APIConfig.approvalApi}/fx-transfer/workflows/${workflowId}/approve`,
      { reason },
    ).then(({ data }) => data);
  },
  rejectFxTransferWorkflow: (workflowId: string, reason: string) => {
    return APIService.post<IApprovalStatus>(
      `${APIConfig.approvalApi}/fx-transfer/workflow/${workflowId}/reject`,
      { reason },
    ).then(({ data }) => data);
  },

  // Senior management
  fetchSeniorManagamentWorkflowStatuses: (workflowId: string) => {
    return APIService.get<IApprovalStatus>(
      `${APIConfig.approvalApi}/workflows/${workflowId}`,
    ).then(({ data }) => data);
  },
  approveSeniorManagamentWorkflow: (workflowId: string, reason: string) => {
    return APIService.post<IApprovalStatus>(
      `${APIConfig.approvalApi}/senior-management/workflows/${workflowId}/approve`,
      { reason },
    ).then(({ data }) => data);
  },
  rejectSeniorManagamentWorkflow: (workflowId: string, reason: string) => {
    return APIService.post<IApprovalStatus>(
      `${APIConfig.approvalApi}/senior-management/workflows/${workflowId}/reject`,
      { reason },
    ).then(({ data }) => data);
  },

  // Finance
  fetchFinanceStatuses: (workflowId: string) => {
    return APIService.get<IApprovalStatus>(
      `${APIConfig.approvalApi}/workflows/${workflowId}`,
    ).then(({ data }) => data);
  },
  approveFinanceWorkflow: (workflowId: string, reason: string) => {
    return APIService.post<IApprovalStatus>(
      `${APIConfig.approvalApi}/finance/workflows/${workflowId}/approve`,
      { reason },
    ).then(({ data }) => data);
  },
  rejectFinanceWorkflow: (workflowId: string, reason: string) => {
    return APIService.post<IApprovalStatus>(
      `${APIConfig.approvalApi}/finance/workflows/${workflowId}/reject`,
      { reason },
    ).then(({ data }) => data);
  },
};

export { approvalAPI };
