import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { ForgotUsernameRequestStatuses } from 'components/Additional/Statuses/ForgotUsernameRequestStatus';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

interface FetchForgotUsernameRequestsParams extends FetchParamsModel {
  name?: string;
  status?: ForgotUsernameRequestStatuses;
}

export type ForgotUsernameRequestFromQuery = {
  id: string;
  email: string;
  name: string;
  clientGroupName: string;
  dateOfBirth: string;
  status: ForgotUsernameRequestStatuses;
  createdAt: string;
  updatedAt: string;
};

const forgotUsernameRequestsAPI = {
  fetchForgotUsernameRequests: (params: FetchForgotUsernameRequestsParams) => {
    return APIService.get<FetchResponseModel<ForgotUsernameRequestFromQuery>>(
      `${APIConfig.authApi}/forgot-username`,
      { params },
    ).then(({ data }) => data);
  },

  fetchForgotUsernameRequestById: (requestId: string) => {
    return APIService.get<ForgotUsernameRequestFromQuery>(
      `${APIConfig.authApi}/forgot-username/${requestId}`,
    ).then(({ data }) => data);
  },

  startReviewProcessByRequestId: (requestId: string) => {
    return APIService.patch<ForgotUsernameRequestFromQuery>(
      `${APIConfig.authApi}/forgot-username/${requestId}/status`,
      {
        status: ForgotUsernameRequestStatuses.InProgress,
      },
    ).then(({ data }) => data);
  },

  completeReviewProcessByRequestId: (requestId: string) => {
    return APIService.patch<ForgotUsernameRequestFromQuery>(
      `${APIConfig.authApi}/forgot-username/${requestId}/status`,
      {
        status: ForgotUsernameRequestStatuses.Completed,
      },
    ).then(({ data }) => data);
  },
};

export { forgotUsernameRequestsAPI };
