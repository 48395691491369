import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { Moment } from 'moment';
import { GenderOptions } from 'enums/crm/crm';
import { TREE_COL_GRID_SIZES, TWO_COL_GRID_SIZES } from 'constants/grids';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import SectionIntro from '@common_components/Texts/SectionIntro';
import FormDatePicker from '@common_components/Form/FormDatePicker';
import GenderFormSelect from 'components/Forms/FormComponents/SelectInputs/GenderFormSelect';
import CountryFormSelect from 'components/Forms/FormComponents/SelectInputs/CountryFormSelect';
import PhoneNumberForm, {
  FormValuesModel as PhoneNumberFormValuesModel,
} from '../../PhoneNumberForm';
import EmailAddressForm, {
  FormValuesModel as EmailAddressFormValuesModel,
} from '../../EmailAddressForm';
import { Col, Row } from 'antd';
import { FieldArray, useFormikContext } from 'formik';

// icons
import TwitterIcon from 'resources/images/social-media-icons/twitter.png';
import FacebookIcon from 'resources/images/social-media-icons/facebook.png';
import LinkedInIcon from 'resources/images/social-media-icons/linkedIn.png';

export type FormValuesModel = {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: GenderOptions | null;
  dateOfBirth: Moment | null;
  countryOfBirth: string;
  emails: EmailAddressFormValuesModel[];
  phoneNumbers: PhoneNumberFormValuesModel[];
  socialMedia: {
    twitterLink: string;
    facebookLink: string;
    linkedInLink: string;
  };
};

const GeneralForm = () => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <SectionIntro
        title={t('contacts.kyc_form.general.title')}
        description={t('contacts.kyc_form.general.description')}
      />
      <Row gutter={[16, 0]}>
        <Col {...TREE_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.kyc_form.general.first_name')}
            name="firstName"
            component={FormInput}
          />
        </Col>

        <Col {...TREE_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.kyc_form.general.middle_name')}
            name="middleName"
            component={FormInput}
          />
        </Col>

        <Col {...TREE_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.kyc_form.general.last_name')}
            name="lastName"
            component={FormInput}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.kyc_form.general.date_of_birth')}
            name="dateOfBirth"
            component={StyledDatePicker}
            additionalProps={{
              disabledDate: (d: any) => !d || d.isAfter(new Date()),
            }}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.kyc_form.general.country_of_birth')}
            name="countryOfBirth"
            component={CountryFormSelect}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('contacts.kyc_form.general.gender')}
            name="gender"
            component={GenderFormSelect}
          />
        </Col>
      </Row>

      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('contacts.kyc_form.general.contact_info_section_title')}
        description={t(
          'contacts.kyc_form.general.contact_info_section_description',
        )}
      />

      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('contacts.kyc_form.general.emails_section_title')}
      />
      <FieldArray
        name="emails"
        render={() =>
          values.emails.map((e, i) => (
            <EmailAddressForm key={e.id} baseName={`emails.${i}`} />
          ))
        }
      />

      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('contacts.kyc_form.general.phone_number_section_title')}
      />
      <FieldArray
        name="phoneNumbers"
        render={() =>
          values.phoneNumbers.map((e, i) => (
            <PhoneNumberForm key={e.id} baseName={`phoneNumbers.${i}`} />
          ))
        }
      />

      <SectionIntro
        gutterTop
        titleVariant="h4"
        title={t('contacts.kyc_form.general.social_media_section_title')}
        description={t(
          'contacts.kyc_form.general.social_media_section_description',
        )}
      />
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <SocialMediaIcon src={TwitterIcon} alt="Twitter icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name="socialMedia.twitterLink"
            component={FormInput}
            placeholder="https://twitter.com/username"
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <SocialMediaIcon src={FacebookIcon} alt="Facebook icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name="socialMedia.facebookLink"
            component={FormInput}
            placeholder="https://www.facebook.com/username"
          />
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col flex="40px">
          <SocialMediaIcon src={LinkedInIcon} alt="LinkedIn icon" />
        </Col>
        <Col flex="auto">
          <FormField
            name="socialMedia.linkedInLink"
            component={FormInput}
            placeholder="https://www.linkedin.com/in/username"
          />
        </Col>
      </Row>
    </>
  );
};

const StyledDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

const SocialMediaIcon = styled.img`
  width: 40px;
  height: auto;
`;

export default GeneralForm;
