import React, { useEffect } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_INVITATIONS_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import InvitationsTable from '../../modules/CRM/Invitations/InvitationsTable';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const Invitations = ({ setHeaderOptions }: IProps) => {
  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation link={GENESIS_INVITATIONS_DOCUMENTATION_LINK} />
      ),
    });
  }, []);

  return <InvitationsTable />;
};

export default Invitations;
