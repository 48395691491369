import React from 'react';

// helpers
import { RoutePaths } from '../../../routes/routes';
import objectsAPI from '../../../api/orac/objectsAPI';

import useFetch from '../../../hooks/useFetch';
import useQueryParam from '../../../hooks/useQueryParam';

// components
import LoadingWrapper from '../../../components/WrapperComponents/LoadingWrapper';
import { default as ObjectDetailsContent } from '../../../modules/ORAC/Objects/ObjectDetails';

const ObjectDetails = () => {
  const objectId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.ORAC_Objects,
  });

  const { response, loading } = useFetch(
    () => (objectId ? objectsAPI.getObjectDetailsById(objectId) : null),
    [objectId],
  );

  return (
    <LoadingWrapper loading={loading}>
      {!!response && <ObjectDetailsContent object={response} />}
    </LoadingWrapper>
  );
};

export default ObjectDetails;
