import { ApprovalRuleSetModel } from '../../../typings/approval/templates';
import {
  ConditionModel,
  FormValuesModel as FinanceApprovalTemplateFormValuesModel,
  LimitModel,
} from '../../../components/Forms/TemplateForms/Approval/FinanceApprovalRulesForm';

export type FinanceApprovalRule = {
  id: string;
  templateCode: 'AWT_FINANCE';
  approvalRuleSets: ApprovalRuleSetModel[];
};

const getFormattedApprovalRulesSets = (
  conditions: ConditionModel[],
  limits: LimitModel[],
) => {
  const result: ApprovalRuleSetModel[] = conditions.map(
    ({ conditions: innerConditions }, outerIndex) => ({
      approvalRuleItems: innerConditions.map(
        ({ requiredApproversCount, approvalGroup }) => ({
          minimunApprovalThreashold: requiredApproversCount as number,
          approvalGroup: { id: approvalGroup },
        }),
      ),
      approvalThreasholds: limits.map(
        ({ from = 0, to = 0, toInfinite }, innerIndex) => ({
          threasholdMin: from,
          threasholdMax: toInfinite ? -1 : to,
          ticked: conditions[outerIndex].limitStatuses[innerIndex].isChecked,
        }),
      ),
    }),
  );

  return result;
};

const financeTemplateAPIAdapter = {
  editApprovalRuleTemplate: (
    formValues: FinanceApprovalTemplateFormValuesModel,
    ruleId: string,
  ): FinanceApprovalRule => {
    return {
      id: ruleId,
      templateCode: 'AWT_FINANCE',
      approvalRuleSets: getFormattedApprovalRulesSets(
        formValues.conditions,
        formValues.limits,
      ),
    };
  },
};

export { financeTemplateAPIAdapter };
