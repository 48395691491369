import React, { memo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { FormValuesModel } from '..';
import { TWO_COL_GRID_SIZES } from 'constants/grids';

// components
import SectionIntro from '@common_components/Texts/SectionIntro';
import FormErrorTooltip from 'components/Forms/FormErrorTooltip';
import SelectedRelationshipTemplates from './SelectedRelationshipTemplates';
import AvailableRelationshipTemplates from './AvailableRelationshipTemplates';
import { Col, Row } from 'antd';
import { FieldArray } from 'formik';

const RelationshipTemplatesSection = memo(() => {
  const { t } = useTranslation('crm');

  return (
    <Row gutter={[16, 16]}>
      <Col {...TWO_COL_GRID_SIZES}>
        <SectionIntro
          title={t('entity_type_category.new.available_relationship_templates')}
          titleVariant="h5"
        />
        <AvailableRelationshipTemplates />
      </Col>
      <Col {...TWO_COL_GRID_SIZES}>
        <SectionIntro
          title={t('entity_type_category.new.selected_relationship_templates')}
          titleVariant="h5"
        />
        <FormErrorTooltip<FormValuesModel> errorKey="relationshipTemplates" />
        <FieldArray
          name="relationshipTemplates"
          render={(helpers) => (
            <SelectedRelationshipTemplates
              onDelete={(i) => helpers.remove(i)}
            />
          )}
        />
      </Col>
    </Row>
  );
});

export default RelationshipTemplatesSection;
