import React, { memo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import { To } from 'history';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from 'routes/routes';
import { changeView } from 'redux/actions/view';
import { useDispatch } from 'react-redux';
import { AssociateTypes } from 'enums/crm/crm';
import { relationshipAPI } from 'api/crm/relationship/relationshipAPI';
import { IRelationshipModel } from 'typings/crm/entity';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';

// components
import {
  default as TemplateRelationshipsTable,
  ActionKeys,
} from 'components/Tables/TableTemplates/CRM/RelationshipsTable';

interface IProps {
  relationshipTemplateId: string;
}

const RelationshipsTable = memo(({ relationshipTemplateId }: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const historyPush = (path: To, state?: any) =>
    dispatch(changeView(() => history.push(path, state)));

  const { response, loading } = useFetch(
    () =>
      relationshipAPI.fetchRelationships({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        relationshipTemplateId,
      }),
    [currentPage, relationshipTemplateId],
  );
  const handleEllipsisClick = (key: ActionKeys, record: IRelationshipModel) => {
    switch (key) {
      case 'view':
        {
          if (record.parent?.type === AssociateTypes.Contact) {
            historyPush(
              {
                pathname: RoutePaths.CRM_Contacts_Edit,
                search: `?id=${record.parent?.id}&tab=relationships`,
              },
              { relationshipToEdit: record },
            );
          } else {
            historyPush(
              {
                pathname: RoutePaths.CRM_Entities_Edit,
                search: `?id=${record.parent?.id}&tab=relationships`,
              },
              { relationshipToEdit: record },
            );
          }
        }
        break;
    }
  };

  return (
    <TemplateRelationshipsTable
      data={response?.data || []}
      total={response?.total || 0}
      loading={loading}
      current={currentPage}
      associationId=""
      onActionsClick={handleEllipsisClick}
      onPaginationChange={setCurrentPage}
    />
  );
});

export default RelationshipsTable;
