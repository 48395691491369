import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { FieldValidationRule } from '@core_components/FormField';

// components
import InputNumber, { InputNumberProps } from '@core_components/InputNumber';

interface FormInputNumberProps extends InputNumberProps {
  validationRules?: FieldValidationRule[];
}

const FormInputNumber = memo(
  ({ validationRules, ...rest }: FormInputNumberProps) => {
    const { t } = useTranslation('form');

    const placeholder = useMemo(() => {
      if (!validationRules || !validationRules.length) {
        return t('field_placeholders.input_optional');
      }

      const isRequired = validationRules.find((e) => e.type === 'required');

      if (isRequired) {
        return t('field_placeholders.input_required');
      } else {
        return t('field_placeholders.input_optional');
      }
    }, [validationRules]);

    return <InputNumber {...rest} placeholder={placeholder} />;
  },
);

export default FormInputNumber;
