import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { BankAccountStatuses } from 'enums/banking/accounts';

// components
import Select, { SelectOption, SelectProps } from '@core_components/Select';

const BankAccountStatusSelect = (
  props: SelectProps<BankAccountStatuses | null>,
) => {
  const { t } = useTranslation('finance');
  const options = useMemo<SelectOption<BankAccountStatuses>[]>(
    () =>
      Object.values(BankAccountStatuses).map((key) => ({
        id: key,
        label: t(`bank_account_statuses.${key}`),
      })),
    [],
  );

  return <Select {...props} options={options} />;
};

export default BankAccountStatusSelect;
