import React, { useMemo } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { onboardingAPI } from 'api/crm/onboarding/onboardingAPI';
import { clientAccountManagementRulesAPI } from 'api/approval/approvalManagement/clientAccountManagementRulesAPI';
import { ApprovalMatrixThresholdBaseModel } from 'typings/approval/templates';

// components
import Text from '@core_components/Text';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ApprovalManagementMatrix, {
  FormValuesModel,
} from 'components/Forms/FormComponents/ApprovalManagementMatrix';

interface IProps {
  clientGroupNumericId: number;
}

const ApprovalRulesTab = ({ clientGroupNumericId }: IProps) => {
  const { t } = useTranslation('crm');
  const { response: approvalRuleResponse, loading } = useFetch(
    () =>
      onboardingAPI.fetchApprovalRules(
        clientGroupNumericId,
        {
          page: 1,
          limit: 1,
        },
        'onboarding-account-management',
      ),
    [clientGroupNumericId],
  );

  const { response: approvalRuleActionsResponse } = useFetch(
    () => clientAccountManagementRulesAPI.fetchApprovalTemplateTypes(),
    [],
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!approvalRuleResponse || !approvalRuleActionsResponse) {
      return null;
    }

    if (approvalRuleResponse.data.length) {
      const approvalRule = approvalRuleResponse.data[0];
      const result: FormValuesModel = {
        id: approvalRuleResponse.data[0]._id,
        isEditMode: false,
        approvalWorkflowType: 'administration',
        approvalConditions: [],
      };

      const baseThresholdStatuses: { [key: string]: { ticked: boolean } } = {};
      approvalRuleActionsResponse.forEach(
        ({ id }) => (baseThresholdStatuses[id] = { ticked: true }),
      );

      result.approvalConditions = approvalRule.ruleSets.map((ruleSet) => {
        const approvalGroupConditions = ruleSet.items.map((item) => ({
          maximumRequiredApprovers: item.group.participants.length,
          initialAutocompleteValue: {
            id: item.group.id,
            content: item.group.name,
          },
          approvalGroupId: item.group.id,
          requiredApprovers:
            item.group.participants.length < item.minimumThreshold
              ? null
              : item.minimumThreshold,
        }));

        return {
          thresholdStatuses: baseThresholdStatuses,
          approvalGroupConditions,
        };
      });

      return result;
    } else {
      return null;
    }
  }, [approvalRuleResponse, approvalRuleActionsResponse]);

  return (
    <LoadingWrapper loading={loading}>
      {initialFormValues ? (
        <ApprovalManagementMatrix
          disabled
          limits={
            approvalRuleActionsResponse as ApprovalMatrixThresholdBaseModel[]
          }
          onSubmit={console.log}
          initialFormValues={initialFormValues}
          approvalWorkflowType="administration"
          hasEditAccess={false}
        />
      ) : (
        <Text>
          {t(
            'crm_approval.account_management_approval_rule.approval_rule_is_not_created',
          )}
        </Text>
      )}
    </LoadingWrapper>
  );
};

export default ApprovalRulesTab;
