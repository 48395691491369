import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import { StyledComponentProps } from '../../../typings/common';

interface IProps extends StyledComponentProps {
  children: React.ReactNode;
}

const DivAlignCenter = memo(({ children, ...rest }: IProps) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
});

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default DivAlignCenter;
