import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { FILE_TYPES } from 'constants/documents';
import { DateHelpers } from '@helpers/date';
import { documentsAPI } from '../../../../api/documents/documentsAPI';
import { DocumentHelpers } from '@helpers/documents';
import { IFullDocument, ITag } from '../../../../typings/documents/documents';

// components
import ConfirmDeleteButton from '../../../../components/Buttons/ConfirmDeleteButton';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';
import { Tag, Typography, Button, message } from 'antd';

interface IProps {
  document: IFullDocument;
  closeCallback: (wasModified?: boolean) => void;
}

const DocumentDetails = ({ document, closeCallback }: IProps) => {
  const { t } = useTranslation('crm');

  const renderTags = (tags: ITag[] = []) => {
    if (!tags.length)
      return (
        <Typography.Text type="secondary">
          {t('documents.details.noTags')}
        </Typography.Text>
      );

    return tags.map(({ name }) => (
      <Tag color="blue" key={name}>
        {name.toLowerCase()}
      </Tag>
    ));
  };

  const formatFileType = (contentType: string) => {
    return FILE_TYPES[contentType]
      ? FILE_TYPES[contentType].shortType
      : t('documents.undefinedType');
  };

  const descriptionsData = useMemo<DescriptionItem[]>(() => {
    if (!document) return [];

    return [
      { label: t('documents.table.name'), description: document.name },
      {
        label: t('documents.table.type'),
        description: formatFileType(document.files[0].contentType),
      },
      {
        label: t('documents.table.size'),
        description: DocumentHelpers.formatFileSize(
          document.files[0].size,
          true,
        ),
      },
      {
        label: t('documents.table.uploaded'),
        description: DateHelpers.formatDateToString(document._audit.createdAt),
      },
      {
        label: t('documents.table.tags'),
        description: renderTags(document.tags),
      },
      {
        label: t('documents.table.description'),
        description: document.description,
      },
    ];
  }, [document]);

  const handleDocumentDelete = async () => {
    await documentsAPI.deleteDocument(document._id);
    message.success(t('documents.table.success_delete'));
    closeCallback(true);
  };

  const handleDownloadDocument = async () => {
    const { _id, files } = document;
    await DocumentHelpers.downloadDocument(_id, files[0].id, files[0].name);
  };

  const renderFooterButtons = () => {
    return (
      <ButtonsContainer>
        <DownloadButton size="large" onClick={handleDownloadDocument}>
          {t('documents.details.download')}
        </DownloadButton>
        <ConfirmDeleteButton
          shouldAskReason
          btnSize="large"
          deleteCallback={handleDocumentDelete}
        />
      </ButtonsContainer>
    );
  };

  return (
    <>
      <DescriptionSection data={descriptionsData} />
      {renderFooterButtons()}
    </>
  );
};

const ButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DownloadButton = styled(Button)`
  margin-right: 15px;
`;

export default DocumentDetails;
