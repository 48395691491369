import { PermissionedAccessModel } from '.';

// Permission keys
export type ReportingPermissionKeys =
  | 'Reporting_View'
  | 'Reporting_BalanceSheet_View'
  | 'Reporting_IncomeStatement_View'
  | 'Reporting_ClientGroupAndBalances_View'
  | 'Reporting_ClientGroupAndRevenue_View'
  | 'Reporting_ExposureByCurrency_View'
  | 'Reporting_TrialBalance_View'
  | 'Reporting_GeneralLedger_View'
  | 'Reporting_GeneralLedger_View'
  | 'Reporting_Threshold_View'
  | 'Reporting_CashFlow_View'
  | 'Reporting_Threshold_IncomingTransfers_View'
  | 'Reporting_Threshold_OutgoingTransfers_View'
  | 'Reporting_EntryChangesLog_View';

// Permission config
export const ReportingPermissions: Record<
  ReportingPermissionKeys,
  PermissionedAccessModel
> = {
  Reporting_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Accounting:BalanceSheet:Read' },
      { permissionKey: 'Accounting:IncomeStatement:Read' },
      { permissionKey: 'Accounting:DepositsByClient:Read' },
      { permissionKey: 'Accounting:IncomeByClient:Read' },
      { permissionKey: 'Accounting:ExposureByCurrency:Read' },
      { permissionKey: 'Accounting:TrialBalance:Read' },
      { permissionKey: 'Accounting:GeneralLedger:Read' },
      { permissionKey: 'Accounting:CashFlow:Read' },
    ],
  },

  Reporting_BalanceSheet_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:BalanceSheet:Read' }],
  },

  Reporting_IncomeStatement_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:IncomeStatement:Read' }],
  },

  Reporting_ClientGroupAndBalances_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:DepositsByClient:Read' },
      { permissionKey: 'CRM:ClientGroup:Read' },
    ],
  },

  Reporting_ClientGroupAndRevenue_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:IncomeByClient:Read' },
      { permissionKey: 'CRM:ClientGroup:Read' },
    ],
  },

  Reporting_ExposureByCurrency_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:ExposureByCurrency:Read' }],
  },

  Reporting_TrialBalance_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:TrialBalance:Read' }],
  },

  Reporting_GeneralLedger_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:GeneralLedger:Read' }],
  },

  Reporting_Threshold_View: {
    requiredAllPermissions: true,
    permissions: [
      { permissionKey: 'Accounting:IncomingThreshold:Read' },
      { permissionKey: 'Accounting:OutgoingThreshold:Read' },
    ],
  },

  Reporting_CashFlow_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:CashFlow:Read' }],
  },

  Reporting_Threshold_IncomingTransfers_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:IncomingThreshold:Read' }],
  },

  Reporting_Threshold_OutgoingTransfers_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'Accounting:OutgoingThreshold:Read' }],
  },

  Reporting_EntryChangesLog_View: {
    requiredAllPermissions: true,
    permissions: [{ permissionKey: 'CRM:EntityChangeLog:Read' }],
  },
};
