import React from 'react';

import Button, { ButtonProps } from 'antd/lib/button';

import { useFormikContext } from 'formik';

interface IProps extends ButtonProps {
  children?: any;
}

// Custom submit button component with formik context
const SubmitButton = ({ children, loading = false, ...rest }: IProps) => {
  const { isSubmitting } = useFormikContext();

  return (
    <Button
      size="large"
      htmlType="submit"
      loading={isSubmitting || loading}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
