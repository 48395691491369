export enum ComplianceReviewTypes {
  OutgoingTransaction,
  IncomingTransaction,
  KYCEntity,
  KYCContact,
}

export enum RiskLevelTypes {
  High,
  Medium,
  Low,
}

export enum ExpectedDataTypes {
  String,
  Number,
  Boolean,
  Date,
  ArrayOfString,
  ArrayOfNumber,
  CountryRiskLevel,
}

export enum ComparisonOperators {
  Equal,
  NotEqual,
  More,
  MoreOrEqual,
  Less,
  LessOrEqual,
  In,
  NotIn,
  IsAnyIn,
}

export enum ConfigurationRuleState {
  Active,
  Testing,
  Archived,
}

export enum ExpectedValueTypes {
  CustomValue,
  ReferenceObjectProperty,
}

export enum ReviewConfigurationsStatuses {
  Pending,
  Passed,
  NotPassed,
}

export enum RDCReviewStatuses {
  Pending,
  NotMatched,
  Matched,
}

export enum ReviewProcessStatuses {
  Empty,
  New,
  InProgress,
  PendingApproval,
  Approved,
  Rejected,
}

export enum ReviewProcessSources {
  Webhook,
  Accounting,
  CRM,
}

export enum OngoingReviewEntryTypes {
  ClientGroup,
  Contact,
  BankAccount,
}

export enum OngoingReviewTriggerTypes {
  EventBased,
  TimeBased,
}
