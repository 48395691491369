import APIConfig from '../../config/api';
import { NewSecurityKeyModel } from '../profile/securityAPI';
import { TwoFactorVerificationTypes } from '../../enums/auth/auth';
import { APIService, AUTHORIZATION_HEADER } from '../axiosInstance';
import {
  AssertionForLoginModel,
  ChallengeForRegistrationModel,
  GetChallengeForLoginResponseModel,
} from '../../typings/profile/fido';

export interface ISessionInfo {
  geolocation?: ISessionInfoGeolocation;
}

export interface ISessionInfoGeolocation {
  accuracy: number;
  altitude?: number;
  altitudeAccuracy?: number;
  latitude: number;
  longitude: number;
}

export interface ActivateSessionResponse {
  code: '2FA_required';
  jwt: string;
  options: {
    code: TwoFactorVerificationTypes;
    default: boolean;
    enabled: boolean;
  }[];
}

export interface LoginWithChallengeResponseModel {
  jwt: string;
  refreshToken: string;
}

const userAPI = {
  activateSession: (jwt?: string) => {
    return APIService.post<ActivateSessionResponse>(
      `${APIConfig.authPublicApi}/azure/activate`,
      null,
      {
        headers: { [AUTHORIZATION_HEADER]: `Bearer ${jwt}` },
        addFingerprintToHeaders: true,
      },
    ).then(({ data }) => data);
  },

  refreshTokens: (jwt: string, refresh: string) => {
    return APIService.patch(`${APIConfig.authPublicApi}/refresh`, undefined, {
      headers: { [AUTHORIZATION_HEADER]: `Bearer ${jwt}`, refresh },
      skipTimestampTracking: true,
      addFingerprintToHeaders: true,
    }).then(({ data }) => data);
  },

  signOut: () => {
    return APIService.post(`${APIConfig.authApi}/sign-out`, undefined).then(
      ({ data }) => data,
    );
  },

  getChallengeFor2FA: (token: string) => {
    return APIService.post<GetChallengeForLoginResponseModel>(
      `${APIConfig.authPublicApi}/fido/login-challenge`,
      null,
      {
        headers: { [AUTHORIZATION_HEADER]: `Bearer ${token}` },
        addFingerprintToHeaders: true,
      },
    ).then((response) => response.data);
  },

  loginWithChallenge: (
    assertion: AssertionForLoginModel,
    tempToken: string,
  ) => {
    return APIService.post<LoginWithChallengeResponseModel>(
      `${APIConfig.authPublicApi}/fido/login/admin`,
      { ...assertion },
      {
        headers: { [AUTHORIZATION_HEADER]: `Bearer ${tempToken}` },
        addFingerprintToHeaders: true,
        addGeolocationDataToHeaders: true,
      },
    ).then((response) => response.data);
  },

  getChallengeForRegistrationFido: (token: string) => {
    return APIService.post<ChallengeForRegistrationModel>(
      `${APIConfig.authPublicApi}/fido/register-challenge`,
      null,
      {
        headers: { [AUTHORIZATION_HEADER]: `Bearer ${token}` },
        addFingerprintToHeaders: true,
      },
    ).then((response) => response.data);
  },

  registerFidoKey: (newKey: NewSecurityKeyModel, tempToken: string) => {
    return APIService.post(`${APIConfig.authPublicApi}/fido/register`, newKey, {
      headers: { [AUTHORIZATION_HEADER]: `Bearer ${tempToken}` },
      addFingerprintToHeaders: true,
    }).then((response) => response.data);
  },
};

export { userAPI };
