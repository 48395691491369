import React, { useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import usersAPI from '../../../../../../../api/orac/usersAPI';
import useTranslation from '../../../../../../../hooks/useTranslation';
import LoadingWrapper from '../../../../../../WrapperComponents/LoadingWrapper';
import OTPCodeForm, {
  FormValuesModel,
} from '../../../../../../Forms/TemplateForms/OTPCodeForm';

// components
import FailVerification from './FailVerification';
import SuccessVerification from './SuccessVerification';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForModalDialogModel {
  userId: string;
}

enum VerificationResults {
  NotSubmitted,
  Verified,
  NotVerified,
}

const VerifyIdentityCodeModal = ({
  isVisible,
  closeCallback,
  userId,
}: IProps) => {
  const { t } = useTranslation(['orac', 'common']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verificationResult, setVerificationResult] =
    useState<VerificationResults>(VerificationResults.NotSubmitted);

  useEffect(() => {
    if (!isVisible) {
      setVerificationResult(VerificationResults.NotSubmitted);
    }
  }, [isVisible]);

  const handleSubmit = async (values: FormValuesModel) => {
    setIsSubmitting(true);

    const result = await usersAPI.verifyIdentityCode(
      userId,
      values.code as string,
    );
    if (result.valid) {
      setVerificationResult(VerificationResults.Verified);
    } else {
      setVerificationResult(VerificationResults.NotVerified);
    }

    setIsSubmitting(false);
  };

  const renderContent = (currentState: VerificationResults) => {
    switch (currentState) {
      case VerificationResults.NotSubmitted:
        return <OTPCodeForm onSubmit={handleSubmit} />;

      case VerificationResults.Verified:
        return <SuccessVerification />;

      case VerificationResults.NotVerified:
        return (
          <>
            <FailVerification />
            <LinkWrapper>
              <StyledLink
                onClick={() =>
                  setVerificationResult(VerificationResults.NotSubmitted)
                }
              >
                {t('try_again', { ns: 'common' })}
              </StyledLink>
            </LinkWrapper>
          </>
        );
    }
  };

  return (
    <ModalDialog
      width={650}
      title={t('users.view.verify_user_identity')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      hideFooterButtons
    >
      <LoadingWrapper loading={isSubmitting}>
        {renderContent(verificationResult)}
      </LoadingWrapper>
    </ModalDialog>
  );
};

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Typography.Link)`
  text-align: center;
`;

export default VerifyIdentityCodeModal;
