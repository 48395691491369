import React, { memo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import rdcReviewAPI from 'api/compliance/rdcReviewAPI';
import useDataRefresh from '@hooks/useDataRefresh';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';

// components
import { default as RDCMonitoringTemplateTable } from 'components/Tables/TableTemplates/Compliance/RDCMonitoringTable';

const RDCMonitoringTable = memo(() => {
  const [page, setPage] = useState(1);
  const refreshTrigger = useDataRefresh();

  const { response, loading } = useFetch(
    () =>
      rdcReviewAPI.fetchRDCMonitoringAlerts({
        page,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [page, refreshTrigger],
  );

  return (
    <RDCMonitoringTemplateTable
      data={response?.data || []}
      total={response?.total || 0}
      loading={loading}
      onPaginationChange={setPage}
    />
  );
});

export default RDCMonitoringTable;
