import React from 'react';

// ** Helpers & Utils **
import styled from 'styled-components';
import useFetch from '../../../../hooks/useFetch';
import { approvalAPI } from '../../../../api/crm/onboarding/approvalAPI';

// ** Components **
import { Space, Spin } from 'antd';
import ApprovalsMatrix from '../ApprovalsMatrix';

const NewBusinessWorkflowTemplate = () => {
  const { response, loading } = useFetch(() =>
    approvalAPI.fetchNewBusinessApprovalTemplate(),
  );

  if (loading) {
    return (
      <ContentWrapper>
        <Space size="middle">
          <Spin size="large" />
        </Space>
      </ContentWrapper>
    );
  }

  return (
    <>
      {!!response && (
        <ApprovalsMatrix
          approvalProgressStateSets={response.approvalProgressStateSets}
        />
      )}
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

export default NewBusinessWorkflowTemplate;
