import {
  FormValuesModel,
  SelectedObjectModel,
  SelectedPermissionModel,
} from '../../components/Modals/TemplateModalDialogs/ORAC/Permissions/EditPermissionsModal';
import { AddEditPermissionsRequestBodyModel } from '../../typings/ORAC/permissions';

const getPermissionsArray = (
  selectedPermissions: Record<string, SelectedObjectModel>,
): SelectedPermissionModel[] => {
  return Object.keys(selectedPermissions).reduce<SelectedPermissionModel[]>(
    (acum, objectId) => {
      const { actions } = selectedPermissions[objectId];

      Object.keys(actions).forEach((actionId) => {
        const permissions = Object.keys(actions[actionId].permissions).map(
          (permissionId) => ({
            ...actions[actionId].permissions[permissionId],
          }),
        );

        acum.push(...permissions);
      });

      return acum;
    },
    [],
  );
};

const permissionsAdapter = {
  // TODO: code refactoring
  formatAddEditPermissions: (
    formValues: FormValuesModel,
    initialFormValues: FormValuesModel,
  ): AddEditPermissionsRequestBodyModel => {
    const { selectedPermissions } = formValues;

    const permissionsArray = getPermissionsArray(selectedPermissions);
    const initialPermissionsArray = getPermissionsArray(
      initialFormValues.selectedPermissions,
    ).map((e) => e.id);

    const result = permissionsArray.reduce<AddEditPermissionsRequestBodyModel>(
      (acum, curr) => {
        if (!initialPermissionsArray.includes(curr.id)) {
          acum.add.push(curr.id);
        } else {
          const elIndex = initialPermissionsArray.findIndex(
            (e) => e === curr.id,
          );
          initialPermissionsArray.splice(elIndex, 1);
        }

        return acum;
      },
      { add: [], remove: [] },
    );

    result.remove = initialPermissionsArray;

    return result;
  },
};

export default permissionsAdapter;
