import React from 'react';

// logo
import { ReactComponent as PrimaryLogo } from '../../../resources/app-logos/primary-logo.svg';

// helpers utils
import styled from 'styled-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useTranslation from '../../../hooks/useTranslation';
import { RoutePaths } from '../../../routes/routes';
import { StateModel } from '../../../redux/reducers';
import { changeView } from '../../../redux/actions/view';
import { SubRouteModel } from '../../../typings/routes';
import { PERMISSION_KEYS } from '../../../config/permissions';
import { headerNavigationRoutes } from '../../../routes/headerNavigationRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { StateModel as UserAccessStateModel } from '../../../redux/reducers/userAccess';

// components
import Link from '@common_components/Texts/Link';
import ProfileActions from './ProfileActions';
import { MenuOutlined } from '@ant-design/icons';
import { Col, Menu, Row } from 'antd';

interface IProps {
  showMenuButton: boolean;
  openDrawerCallback: () => void;
}

const { SubMenu } = Menu;

const Header = ({ openDrawerCallback, showMenuButton }: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();
  const { t } = useTranslation('page_titles');
  const { permissions } = useSelector<StateModel, UserAccessStateModel>(
    (state) => state.userAccess,
  );

  const historyPush = (path: string) => {
    dispatch(changeView(() => history.push(path)));
  };

  // Check route permissions
  // If the route has permissions -> check if user has such permissions
  const checkPermissions = (route: SubRouteModel) => {
    if (!route.permission?.permissionKey) {
      return true;
    }

    return permissions[route.permission.permissionKey as PERMISSION_KEYS]
      .isAllowed;
  };

  const renderNavItems = () => {
    return headerNavigationRoutes.map((route) => renderNavItem(route, true));
  };

  const renderNavItem = (route: SubRouteModel, renderChildren: boolean) => {
    if (route.hideFromNavigation) return null;

    const hasPermission = checkPermissions(route);

    if (!hasPermission && !route.permission?.options?.disableNavItem) return;

    const isDisabled =
      !hasPermission && route.permission?.options?.disableNavItem;

    if (route.childRoutes?.length && renderChildren) {
      const menuClassName = matchPath(location.pathname, { path: route.path })
        ? 'menu-submenu-selected'
        : '';

      return (
        <StyledSubMenu
          key={route.path}
          disabled={isDisabled}
          title={
            <Link
              href={route.path}
              onClick={(e) => {
                e.preventDefault();
                dispatch(changeView(() => history.push(route.path)));
              }}
            >
              {t(route.title)}
            </Link>
          }
          className={menuClassName}
          onTitleClick={() => historyPush(route.path)}
        >
          {route.childRoutes.map((subRoute) => renderNavItem(subRoute, false))}
        </StyledSubMenu>
      );
    }
    const menuItemClassName = matchPath(location.pathname, { path: route.path })
      ? 'menu-item-selected'
      : '';

    return (
      <Menu.Item
        className={menuItemClassName}
        key={route.path}
        disabled={isDisabled}
      >
        <Link
          href={route.path}
          onClick={(e) => {
            e.preventDefault();
            dispatch(changeView(() => history.push(route.path)));
          }}
        >
          {t(route.title)}
        </Link>
      </Menu.Item>
    );
  };

  return (
    <RootHeaderWrapper>
      <StyledRow align="middle" justify="space-between">
        <LogoCol>
          {(showMenuButton || !breakpoint.lg) && (
            <StyledSidebarSwitchCol>
              <MenuOutlined onClick={openDrawerCallback} />
            </StyledSidebarSwitchCol>
          )}

          <StyledLogo onClick={() => historyPush(RoutePaths.UserRoutes_Root)} />
        </LogoCol>

        {breakpoint.xl && (
          <MenuCol flex="1 1">
            <StyledMenu
              mode="horizontal"
              selectedKeys={[location.pathname]}
              getPopupContainer={(triggerNode: HTMLElement) =>
                (triggerNode?.parentNode as HTMLElement) || document.body
              }
            >
              {renderNavItems()}
            </StyledMenu>
          </MenuCol>
        )}

        <ProfileCol>
          <ProfileActions />
        </ProfileCol>
      </StyledRow>

      {!breakpoint.xl && breakpoint.lg && (
        <Row>
          <Col span={24}>
            <StyledMenu
              mode="horizontal"
              selectedKeys={[location.pathname]}
              getPopupContainer={(triggerNode: HTMLElement) =>
                (triggerNode?.parentNode as HTMLElement) || document.body
              }
            >
              {renderNavItems()}
            </StyledMenu>
          </Col>
        </Row>
      )}
    </RootHeaderWrapper>
  );
};

const RootHeaderWrapper = styled.div`
  width: 100%;

  height: ${({ theme }) => theme.headerHeight};
  min-height: ${({ theme }) => theme.headerHeight};
  max-height: ${({ theme }) => theme.headerHeight};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  z-index: 10;

  padding: 0 ${({ theme }) => theme.containerPadding};
  background-color: ${({ theme }) => theme.headerBackground};

  .ant-menu {
    font-size: ${({ theme }) => theme.headerMenuFontSize};
  }

  @media screen and (max-width: ${({ theme }) => theme.secondHeaderScreen}) {
    height: ${({ theme }) => theme.headerHeightTablet} !important;
    min-height: ${({ theme }) => theme.headerHeightTablet}!important;
    max-height: ${({ theme }) => theme.headerHeightTablet}!important;
  }

  @media screen and (max-width: ${({ theme }) => theme.tabletScreen}) {
    height: ${({ theme }) => theme.headerHeightMobile}!important;
    min-height: ${({ theme }) => theme.headerHeightMobile}!important;
    max-height: ${({ theme }) => theme.headerHeightMobile}!important;
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const LogoCol = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;

  @media screen and (max-width: ${({ theme }) => theme.tabletScreen}) {
    display: flex;
    justify-content: center;
  }
`;

const MenuCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
`;

const ProfileCol = styled(Col)`
  padding-left: 10px;
`;

const StyledLogo = styled(PrimaryLogo)`
  width: ${({ theme }) => theme.headerLogoWidth};
`;

const StyledMenu = styled(Menu)`
  .ant-menu-submenu-horizontal {
    margin: 0 10px !important;
  }
  &.ant-menu-horizontal {
    .ant-menu-submenu {
      &:hover {
        color: ${({ theme }) => theme.primaryGreen} !important;
        filter: none;
      }
      a {
        color: ${({ theme }) => theme.colorLight} !important;
        font-size: ${({ theme }) => theme.fontSizeXXs};

        &:hover {
          color: ${({ theme }) => theme.primaryGreen} !important;
          filter: none;
        }
      }
      .ant-menu-item {
        a {
          color: ${({ theme }) => theme.colorLight} !important;
          font-size: ${({ theme }) => theme.fontSizeXXs};

          &:hover {
            color: ${({ theme }) => theme.primaryGreen} !important;
            filter: none;
          }
        }
      }

      .menu-item-selected {
        a {
          color: ${({ theme }) => theme.primaryGreen} !important;
        }
      }
    }

    .ant-menu-submenu-selected,
    .menu-submenu-selected {
      .ant-menu-item-selected a {
        color: ${({ theme }) => theme.primaryGreen} !important;
      }
      a {
        color: ${({ theme }) => theme.primaryGreen} !important;
      }
    }
  }
`;

const StyledSidebarSwitchCol = styled(Col)`
  width: 45px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: #fff;
  cursor: pointer;
  font-size: 20px;
`;

const StyledSubMenu = styled(SubMenu)<any>`
  &.menu-submenu-selected a {
    color: ${({ theme }) => theme.primaryGreen} !important;
    font-size: ${({ theme }) => theme.fontSizeXXs};
  }
`;

export default Header;
