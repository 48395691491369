import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { useFormikContext } from 'formik';
import { ApprovalGroupModel } from '../../../../../../typings/approval/templates';
import { ApprovalMatrixColumnTypes } from '..';
import { ApprovalGroupConditionModel, FormValuesModel } from '../../';

// components
import FormField from '@core_components/FormField';
import ApprovalGroupsAutocomplete from '../../../Autocompletes/Approval/ApprovalGroupsAutocomplete';
import RequiredApprovalsCountSelect from '../../../SelectInputs/RequiredApprovalsCountSelect';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Row } from 'antd';

interface IProps {
  colIndex: number;
  rowIndex: number;

  dataIndex: ApprovalMatrixColumnTypes;

  threasholdId: string;
}

const InitialApprovalGroupCondition: ApprovalGroupConditionModel = {
  approvalGroupId: null,
  requiredApprovers: null,
  maximumRequiredApprovers: 0,
};

const ContentCell = ({ dataIndex, rowIndex, threasholdId }: IProps) => {
  const { t } = useTranslation('approval_management');

  const { values, setFieldValue, isSubmitting } =
    useFormikContext<FormValuesModel>();

  const renderContent = (
    dataIndex: ApprovalMatrixColumnTypes,
    isDisabledFields: boolean,
  ) => {
    let result = null;

    switch (dataIndex) {
      case ApprovalMatrixColumnTypes.ApprovalCondition:
        {
          const shouldRenderOR =
            rowIndex < values.approvalConditions.length - 1;
          const conditionsLength =
            values.approvalConditions[rowIndex].approvalGroupConditions.length;
          const shouldRenderDeleteSectionBtn =
            !isDisabledFields && values.approvalConditions.length > 1;

          result = (
            <>
              {shouldRenderDeleteSectionBtn && (
                <EndAlignWrapper>
                  <DeleteButton onClick={() => onDeleteRow(rowIndex)} />
                </EndAlignWrapper>
              )}

              {values.approvalConditions[rowIndex].approvalGroupConditions.map(
                (e, i) => {
                  const isDisabledRequiredApproversField = !e.approvalGroupId;
                  const numOfParticipantsInApprovalGroup =
                    e.maximumRequiredApprovers;
                  const shouldRenderAND = i < conditionsLength - 1;

                  return (
                    <>
                      <ApprovalGroupConditionWrapper key={`${rowIndex}-${i}`}>
                        <Row gutter={[16, 16]}>
                          <Col span={14}>
                            <FormField
                              name={`approvalConditions[${rowIndex}].approvalGroupConditions[${i}].approvalGroupId`}
                              component={ApprovalGroupsAutocomplete}
                              disabled={isDisabledFields}
                              shouldShowErrorMessage={false}
                              additionalProps={{
                                allowClear: true,
                                clientGroupId: values.clientGroupId,
                                approvalWorkflowType:
                                  values.approvalWorkflowType,
                                onSelect: (_: any, group: ApprovalGroupModel) =>
                                  handleOnApprovalGroupSelect(
                                    group,
                                    rowIndex,
                                    i,
                                  ),
                                initialValue:
                                  values.approvalConditions[rowIndex]
                                    .approvalGroupConditions[i]
                                    .initialAutocompleteValue,
                                excludeElementIds: values.approvalConditions[
                                  rowIndex
                                ].approvalGroupConditions.map(
                                  (e, conditionIdx) =>
                                    conditionIdx !== i && e.approvalGroupId,
                                ),
                              }}
                            />
                          </Col>

                          <Col span={10}>
                            <FormField
                              name={`approvalConditions[${rowIndex}].approvalGroupConditions[${i}].requiredApprovers`}
                              component={RequiredApprovalsCountSelect}
                              disabled={
                                isDisabledRequiredApproversField ||
                                isDisabledFields
                              }
                              additionalProps={{
                                allowClear: true,
                                numOfParticipantsInApprovalGroup,
                              }}
                              shouldShowErrorMessage={false}
                            />
                          </Col>
                        </Row>
                      </ApprovalGroupConditionWrapper>

                      {shouldRenderAND && (
                        <AndContainer>
                          {`${t('approval_management.approval_rules.and')} `}
                          {!isDisabledFields && (
                            <DeleteButton
                              onClick={() => onDeleteAnd(rowIndex, i + 1)}
                            />
                          )}
                        </AndContainer>
                      )}
                    </>
                  );
                },
              )}

              {!isDisabledFields && (
                <ButtonsWrapper>
                  <Button
                    type="primary"
                    onClick={() => onAddNewANDClick(rowIndex)}
                    disabled={isSubmitting}
                  >
                    {t('approval_management.approval_rules.add_and')}
                  </Button>
                </ButtonsWrapper>
              )}

              {shouldRenderOR && (
                <ORContainer>
                  {t('approval_management.approval_rules.or')}
                </ORContainer>
              )}
            </>
          );
        }
        break;

      case ApprovalMatrixColumnTypes.ApprovalThreashold:
        {
          const fieldName = `approvalConditions.${rowIndex}.thresholdStatuses[${threasholdId}].ticked`;
          result = (
            <CheckboxWrapper>
              <FormField
                name={fieldName}
                component={Checkbox}
                shouldShowErrorMessage={false}
                disabled={isDisabledFields || !!values.clientGroupId}
              />
            </CheckboxWrapper>
          );
        }
        break;
    }

    return result;
  };

  const onDeleteRow = (rowIndex: number) => {
    const approvalConditionsCopy = values.approvalConditions.slice();
    approvalConditionsCopy.splice(rowIndex, 1);
    setFieldValue('approvalConditions', approvalConditionsCopy);
  };

  const onDeleteAnd = (rowIndex: number, endEntryIndex: number) => {
    const approvalConditionsCopy = values.approvalConditions.slice();
    approvalConditionsCopy[rowIndex].approvalGroupConditions.splice(
      endEntryIndex,
      1,
    );
    setFieldValue('approvalConditions', approvalConditionsCopy);
  };

  const onAddNewANDClick = (rowIndex: number) => {
    const approvalConditionsCopy =
      values.approvalConditions[rowIndex].approvalGroupConditions.slice();
    approvalConditionsCopy.push(InitialApprovalGroupCondition);
    setFieldValue(
      `approvalConditions[${rowIndex}].approvalGroupConditions`,
      approvalConditionsCopy,
    );
  };

  const handleOnApprovalGroupSelect = (
    group: ApprovalGroupModel,
    rowIndex: number,
    conditionIndex: number,
  ) => {
    const { participants, name } = group;

    setFieldValue(
      `approvalConditions[${rowIndex}].approvalGroupConditions[${conditionIndex}].groupName`,
      name,
    );
    setFieldValue(
      `approvalConditions[${rowIndex}].approvalGroupConditions[${conditionIndex}].maximumRequiredApprovers`,
      participants.length,
    );
  };

  return <td>{renderContent(dataIndex, !values.isEditMode)}</td>;
};

const EndAlignWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const DeleteButton = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.errorColor};
  cursor: pointer;
`;

const ApprovalGroupConditionWrapper = styled.div`
  min-width: 300px;
  position: relative;
  margin: ${({ theme }) => `${theme.marginXs} 0`};
`;

const AndContainer = styled.div`
  font-weight: 600;
  margin-left: ${({ theme }) => theme.marginSm};
`;

const ButtonsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginSm};
  display: flex;
  justify-content: flex-end;
`;

const ORContainer = styled.div`
  position: absolute;
  bottom: -16px;
  left: 15px;

  width: 55px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.tableCardBackground};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ContentCell;
