import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { AllYesNoEnum } from '../../../../../enums/common';

// components
import { SearchInput } from 'components/Fields/SearchInput';
import { Col, Row, Select } from 'antd';

export type FilterBarKeys = 'search_by_name' | 'filter_by_is_client';

interface IProps {
  onFilterChange: (key: FilterBarKeys, value: string) => void;
  isClientValue?: AllYesNoEnum;
}

const FilterBar = ({ isClientValue, onFilterChange }: IProps) => {
  const { t } = useTranslation(['finance', 'common']);

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Row>
          <Col span={24}>{t('account_categories.table.search_title_name')}</Col>
          <Col span={24}>
            <SearchInput
              size="large"
              placeholder={t('enter_value', { ns: 'common' })}
              onSearch={(searchQuery: string) =>
                onFilterChange('search_by_name', searchQuery)
              }
            />
          </Col>
        </Row>
      </Col>

      <Col span={6}>
        <Row>
          <Col span={24}>{t('account_categories.table.is_client')}</Col>
          <Col span={24}>
            <StyledSelect
              value={isClientValue}
              size="large"
              placeholder={t('select_option', { ns: 'common' })}
              onChange={(value: any) =>
                onFilterChange('filter_by_is_client', value)
              }
            >
              <StyledSelect.Option value={AllYesNoEnum.All}>
                {t('all', { ns: 'common' })}
              </StyledSelect.Option>
              <StyledSelect.Option value={AllYesNoEnum.Yes}>
                {t('yes', { ns: 'common' })}
              </StyledSelect.Option>
              <StyledSelect.Option value={AllYesNoEnum.No}>
                {t('no', { ns: 'common' })}
              </StyledSelect.Option>
            </StyledSelect>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default FilterBar;
