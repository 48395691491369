import React, { useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import {
  TransactionRuleFromQuery,
  clientTransactionRulesAPI,
} from 'api/approval/approvalManagement/clientTransactionRulesAPI';

// components
import ClientTransactionRulesTable, {
  ActionKeys,
} from 'components/Tables/TableTemplates/ApprovalManagement/ClientTransactionRulesTable';

interface IProps {
  clientGroupId: number;
  updateTableTrigger: any;
  onRuleSelect: (action: ActionKeys, record: TransactionRuleFromQuery) => void;
}

const TransactionRulesTable = ({
  clientGroupId,
  updateTableTrigger,
  onRuleSelect,
}: IProps) => {
  const [page, setPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      clientTransactionRulesAPI.fetchTransactionRules(clientGroupId, {
        page,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [page, clientGroupId, updateTableTrigger],
  );

  return (
    <ClientTransactionRulesTable
      loading={loading}
      current={page}
      data={response?.data || []}
      total={response?.total || 0}
      onPaginationChange={setPage}
      onActionsClick={onRuleSelect}
    />
  );
};

export default TransactionRulesTable;
