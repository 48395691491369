import { ActionType } from '../actions/userAccess';
import { IReduxAction } from '../../typings/common';
import { PermissionsHelpers } from '@helpers/permissions';
import { UserPermissionModel } from '../../typings/profile/profile';
import {
  PermissionedAccessModel,
  PERMISSION_KEYS,
  permissionsConfig,
} from '../../config/permissions';

// Models
export interface StateModel {
  isInitialized: boolean;
  permissions: Record<PERMISSION_KEYS, UserAccessPermissionModel>;
}

interface UserAccessPermissionModel extends PermissionedAccessModel {
  isAllowed: boolean;
}

// Initial state
const getInitialUserPermissions = (
  permissionsConfig: Record<PERMISSION_KEYS, PermissionedAccessModel>,
) => {
  const result = {} as Record<PERMISSION_KEYS, UserAccessPermissionModel>;

  Object.keys(permissionsConfig).forEach((key) => {
    // should specify type for key value, since by default forEach set string type
    result[key as PERMISSION_KEYS] = {
      isAllowed: false,
      ...permissionsConfig[key as PERMISSION_KEYS],
    };
  });

  return result;
};

export const initialState: StateModel = {
  isInitialized: false,
  permissions: getInitialUserPermissions(permissionsConfig),
};

// Helpers
const getUserPermissionsHash = (
  userPermissionsList: UserPermissionModel[],
): Map<string, UserPermissionModel> => {
  return new Map(userPermissionsList.map((e) => [e.name, e]));
};

// Reducer
const userAccess = (
  state = initialState,
  action: IReduxAction<{ userPermissionsList: UserPermissionModel[] }>,
): StateModel => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.INIT_USER_PERMISSIONS: {
      const updatedPermissions = {} as Record<
        PERMISSION_KEYS,
        UserAccessPermissionModel
      >;

      // To increase performance of searching permission
      const userPermissionsMap = getUserPermissionsHash(
        payload.userPermissionsList,
      );

      Object.keys(state.permissions).forEach((key) => {
        const { permissions, requiredAllPermissions } =
          state.permissions[key as PERMISSION_KEYS];

        const isAllowed = requiredAllPermissions
          ? PermissionsHelpers.hasAllPermissions(
              userPermissionsMap,
              permissions,
            )
          : PermissionsHelpers.hasSomePermission(
              userPermissionsMap,
              permissions,
            );

        // should specify type for key value, since by default forEach set string type
        updatedPermissions[key as PERMISSION_KEYS] = {
          ...permissionsConfig[key as PERMISSION_KEYS],
          isAllowed,
        };
      });

      return { ...state, isInitialized: true, permissions: updatedPermissions };
    }

    default:
      return state;
  }
};

export default userAccess;
