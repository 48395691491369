import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '@hooks/useFetch';
import { GraphNode } from 'components/Charts/GraphChart';
import { useDispatch } from 'react-redux';
import { relationshipAPI } from 'api/crm/relationship/relationshipAPI';
import { IClientGroupResponse } from 'api/crm/clientGroup/clientGroupsAPI';
import { CHANGE_VIEW_TYPES, changeView } from 'redux/actions/view';
import { ClientReviewStatus, ClientStatus } from 'enums/crm/crm';

// components
import NodeDetails from './NodeDetails';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ClientGroupNodesTree from './ClientGroupNodesTree';
import { Col, Row } from 'antd';

interface IProps {
  clientGroup: IClientGroupResponse;
  updateClientGroupCallback: () => void;
}

const ClientGroupReview = ({
  clientGroup,
  updateClientGroupCallback,
}: IProps) => {
  const dispatch = useDispatch();
  const [selectedNode, setSelectedNode] = useState<GraphNode | null>(null);
  const [updateNodesTreeTrigger, updateNodesTree] = useState<any>();
  const isViewOnly =
    clientGroup.clientGroup.reviewStatus !== ClientReviewStatus.ManagerReview;

  const scopeId = useMemo(() => {
    const { status, reviewStatus, directClient } = clientGroup.clientGroup;
    return status === ClientStatus.Approved &&
      reviewStatus === ClientReviewStatus.Reviewed
      ? directClient.relationshipScopes?.approved
      : directClient.relationshipScopes?.active;
  }, [clientGroup]);

  const { response, loading } = useFetch(
    () =>
      relationshipAPI.getRelationshipTree(
        clientGroup.clientGroup.directClientItemId,
        scopeId,
      ),
    [scopeId, clientGroup, updateNodesTreeTrigger],
  );

  const handleNewNodeSelect = (node: GraphNode | null) => {
    dispatch(
      changeView(() => {
        setSelectedNode(node);
      }, CHANGE_VIEW_TYPES.TREE),
    );
  };

  return (
    <StyledLoadingWrapper loading={loading && !selectedNode}>
      <Row wrap={false} gutter={[16, 16]}>
        <Col flex="300px">
          <ClientGroupNodesTree
            graphData={response || null}
            selectedNode={selectedNode}
            onNodeSelect={handleNewNodeSelect}
          />
        </Col>
        <Col flex="auto">
          {selectedNode ? (
            <NodeDetails
              node={selectedNode}
              isViewOnly={isViewOnly}
              clientGroup={clientGroup.clientGroup}
              updateNodesTreeCallback={() => updateNodesTree({})}
              updateClientGroupCallback={updateClientGroupCallback}
            />
          ) : null}
        </Col>
      </Row>
    </StyledLoadingWrapper>
  );
};

const StyledLoadingWrapper = styled(LoadingWrapper)`
  height: auto;
`;

export default ClientGroupReview;
