import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { styled } from 'styled-components';
import { darkTheme } from '@resources/theme/styled';
import { ApprovalRule } from '.';

// components
import Text from '@core_components/Text';
import Checkbox from '@core_components/Checkbox';
import { Col, Popover, Row } from 'antd';

interface IProps {
  record: ApprovalRule['conditions'][0];
  dataIndex: string;
  colIndex: number;
  isLastRow: boolean;
}

const ContentCell = ({ record, dataIndex, colIndex, isLastRow }: IProps) => {
  const { t } = useTranslation(['crm', 'common']);

  const renderPopoverContent = (participants: string[] = []) => {
    const isMore = participants.length > 5;
    return (
      <>
        {participants.slice(0, 5).map((e, i) => (
          <div key={i}>{e}</div>
        ))}

        {isMore && (
          <Text variant="body1">
            {t(
              'crm_approval.transaction_approval_rules.approval_rule_matrix.more_users',
              {
                number: participants.length - 5,
              },
            )}
          </Text>
        )}
      </>
    );
  };

  const renderApprovalGroup = (
    approvalGroup: {
      name: string;
      participants: string[];
    } | null,
  ) => {
    if (!approvalGroup) {
      return '';
    }

    return (
      <Popover
        placement="bottom"
        content={renderPopoverContent(approvalGroup.participants)}
      >
        <div>
          <StyledDottedText variant="body1">
            {approvalGroup.name}
          </StyledDottedText>
        </div>
      </Popover>
    );
  };

  const renderContent = () => {
    switch (dataIndex) {
      case 'condition':
        return (
          <>
            {record.conditions.map((e, i: number) => {
              const shouldRenderAnd = i < record.conditions.length - 1;
              const shouldRenderOr =
                !isLastRow && i === record.conditions.length - 1;
              return (
                <>
                  <Row key={i}>
                    <Col>
                      {`${t(
                        'crm_approval.transaction_approval_rules.approval_rule_matrix.approval_group_name',
                      )}: `}
                      {renderApprovalGroup(e.approvalGroup)}
                    </Col>

                    <Col span={24}>
                      {`${t(
                        'crm_approval.transaction_approval_rules.approval_rule_matrix.required_number_of_approvers',
                      )}: `}
                      {!e.requiredApproversCount ? (
                        <Text variant="span" color={darkTheme.errorColor}>
                          {t('not_selected', { ns: 'common' })}
                        </Text>
                      ) : (
                        e.requiredApproversCount
                      )}
                    </Col>
                  </Row>

                  {shouldRenderAnd && (
                    <StyledANDText variant="body1" weight="semi-bold">
                      {t(
                        'crm_approval.transaction_approval_rules.approval_rule_matrix.and',
                      )}
                    </StyledANDText>
                  )}

                  {shouldRenderOr && (
                    <ORTextWrapper>
                      <Text variant="body1" weight="semi-bold">
                        {t(
                          'crm_approval.transaction_approval_rules.approval_rule_matrix.or',
                        )}
                      </Text>
                    </ORTextWrapper>
                  )}
                </>
              );
            })}
          </>
        );

      case 'limit': {
        const checkboxValue = record.limitStatuses[colIndex].isChecked;
        return (
          <AlignCenterWrapper>
            <Checkbox value={checkboxValue} disabled />
          </AlignCenterWrapper>
        );
      }

      default:
        return null;
    }
  };

  return <StyledColWrapper>{renderContent()}</StyledColWrapper>;
};

const StyledColWrapper = styled.td`
  padding: ${({ theme }) => theme.paddingXl} !important;
`;

const ORTextWrapper = styled.div`
  position: absolute;
  bottom: -13px;
  width: 50px;
  text-align: center;
  background: ${({ theme }) => theme.backgroundColor2};
`;

const StyledANDText = styled(Text)`
  padding: 8px 16px;
`;

const AlignCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDottedText = styled(Text)`
  cursor: pointer;
  border-bottom: ${({ theme }) => `1px dotted ${theme.primaryGreen}`};
`;

export default ContentCell;
