import React, { ReactNode } from 'react';

// helpers utils
import styled from 'styled-components';

// components
import { Badge } from 'antd';

interface IProps {
  children?: ReactNode;
  count: number;
}

const NewMessagesBadge = ({ children, count }: IProps) => {
  return (
    <StyledBadge hasChildren={Boolean(children)} count={count}>
      {children}
    </StyledBadge>
  );
};

const StyledBadge = styled(Badge)<{ hasChildren: boolean }>`
  .ant-badge-count {
    top: ${({ hasChildren }) => (hasChildren ? 0 : -2)}px;
    right: ${({ hasChildren }) => (hasChildren ? 0 : -8)}px;
  }
`;

export default NewMessagesBadge;
