import React from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import { Input } from 'antd';

const InnerForm = () => {
  const { t } = useTranslation('orac');

  return (
    <>
      <FormField
        name="name"
        label={t('groups.add.name')}
        placeholder={t('groups.add.name_placeholder')}
        component={Input}
      />

      <FormField
        name="description"
        label={t('groups.add.description')}
        placeholder={t('groups.add.description_placeholder')}
        component={Input.TextArea}
        additionalProps={{ autoSize: { minRows: 5, maxRows: 5 } }}
      />
    </>
  );
};

export default InnerForm;
