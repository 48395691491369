import React from 'react';

// helpers
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import Autocomplete, {
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';
import { ContextBaseModel } from '../../../../../../typings/ORAC/contexts';
import contextsAPI from '../../../../../../api/orac/contextsAPI';

interface IProps {
  value: string;
}

const ContextsAutocomplete = (props: IProps) => {
  const mapObject = (context: ContextBaseModel) => {
    return {
      id: context.id,
      content: context.name,
      model: context,
    };
  };

  const fetchContexts = async (searchQuery: string) => {
    const { data } = await contextsAPI.fetchContexts({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      name: searchQuery,
    });

    return data.map<AutocompleteOption>(mapObject);
  };

  return (
    <Autocomplete
      {...props}
      placeholder="Enter context name"
      fetchData={fetchContexts}
    />
  );
};

export default ContextsAutocomplete;
