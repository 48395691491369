import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { darkTheme } from '../../../../../resources/theme/styled';
import { FormikHelpers } from 'formik';
import { RequestDataUpdateValidationSchema } from '../../../../../validations/crm/clientGroups';

// components
import Text from '@core_components/Text';
import Button from '@core_components/Button';
import InnerForm from './InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Trans } from 'react-i18next';

export type FormValuesModel = {
  message: string;
};

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
  onDelete?: () => Promise<void>;
}

const RequestDataUpdateModal = memo(
  ({
    title,
    isVisible,
    closeCallback,
    initialValues,
    onSubmit,
    onDelete,
  }: IProps) => {
    const { t } = useTranslation(['crm', 'common']);

    const handleSubmit = async (
      values: FormValuesModel,
      formikHelpers: FormikHelpers<FormValuesModel>,
    ) => {
      await onSubmit(values, formikHelpers);
    };

    return (
      <Form<FormValuesModel>
        onSubmit={handleSubmit}
        initialValues={isVisible ? initialValues : null}
        confirmExitWithoutSaving
        enableReinitialize
        validationSchema={RequestDataUpdateValidationSchema}
        renderForm={
          <ModalDialog
            title={title}
            isVisible={isVisible}
            closeCallback={closeCallback}
            extendedFooterButtons={
              onDelete && (
                <Button danger type="bordered" onClick={onDelete} size="large">
                  {t('delete', { ns: 'common' })}
                </Button>
              )
            }
          >
            <Text variant="body1">
              <Trans
                t={t}
                values={{
                  buttonText: t(
                    'request_data_update_modal.description_button_text',
                  ),
                }}
                i18nKey="request_data_update_modal.description"
                components={[
                  <StyledDescription
                    key="buttonText"
                    variant="subtitle1"
                    weight="semi-bold"
                    color={darkTheme.whiteColor}
                  />,
                ]}
              />
            </Text>
            <InnerForm />
          </ModalDialog>
        }
      />
    );
  },
);

const StyledDescription = styled(Text)`
  display: inline;
`;

export default RequestDataUpdateModal;
