import React from 'react';

// helpers
import styled from 'styled-components';

// components
import { Spin } from 'antd';

const LoaderSection = () => {
  return (
    <SpinWrapper>
      <Spin />
    </SpinWrapper>
  );
};

const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${({ theme }) => theme.paddingMd};
`;

export default LoaderSection;
