import React, { memo, useCallback, useMemo } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { FormikHelpers } from 'formik';
import { keyRelationshipTemplatesAPI } from 'api/crm/keyRelationship/keyRelationshipTemplatesAPI';
import { keyRelationshipTemplatesAdapter } from 'apiAdapters/crm/keyRelationship/keyRelationshipTemplatesAdapter';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';

// components
import EntityTypeCategoryModalDialog, {
  EntityTypeCategoryFormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/EntityTypeCategoryModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  categoryId: string | null;
}

const EditEntityTypeCategoryModal = memo(
  ({ isVisible, closeCallback, categoryId }: IProps) => {
    const { t } = useTranslation('crm');

    const { response } = useFetch(
      () =>
        isVisible && categoryId
          ? keyRelationshipTemplatesAPI.fetchKeyRelationshipTemplateById(
              categoryId,
            )
          : null,
      [isVisible, categoryId],
    );

    const initialFormValues =
      useMemo<EntityTypeCategoryFormValuesModel | null>(() => {
        if (!response || !isVisible) {
          return null;
        }

        return keyRelationshipTemplatesAdapter.formatEntityTypeCategoryFromQueryToForm(
          response,
        );
      }, [response, isVisible]);

    const handleSubmit = useCallback(
      async (values) => {
        const formattedBody =
          keyRelationshipTemplatesAdapter.formatEntityTypeCategoryFromFormToQuery(
            values,
          );
        await keyRelationshipTemplatesAPI.editKeyRelationshipTemplateById(
          categoryId as string,
          formattedBody,
        );
        message.success(t('entity_type_category.edit.success_message'));
      },
      [categoryId],
    );

    const handleDelete = useCallback(
      async (
        reason: string,
        helpers: FormikHelpers<EntityTypeCategoryFormValuesModel>,
      ) => {
        await keyRelationshipTemplatesAPI.deleteKeyRelationshipTemplateById(
          categoryId as string,
          reason,
        );
        message.success(t('entity_type_category.delete_success'));

        await helpers.resetForm({
          values: {} as any,
          errors: {},
          touched: {},
          isSubmitting: false,
          isValidating: false,
          status: undefined,
          submitCount: 0,
        });

        closeCallback(true);
      },
      [categoryId],
    );

    return (
      <EntityTypeCategoryModalDialog
        title={t('entity_type_category.edit.title')}
        isVisible={isVisible}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        closeCallback={closeCallback}
        initialValues={initialFormValues}
      />
    );
  },
);

export default EditEntityTypeCategoryModal;
