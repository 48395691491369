import React from 'react';

// helpers
import { IDocumentAssociationInfo } from '../../../../../../../modules/CRM/Documents/UploadDocumentsDialog';

// components
import FormField from '@core_components/FormField';
import AttachmentsForm from '../../AttachmentsForm';
import { Input } from 'antd';

interface IProps {
  association: IDocumentAssociationInfo;
}

const InnerForm = ({ association }: IProps) => {
  return (
    <>
      <FormField
        name="message"
        component={Input.TextArea}
        additionalProps={{
          autoSize: { minRows: 4, maxRows: 4 },
        }}
      />

      <AttachmentsForm association={association} />
    </>
  );
};

export default InnerForm;
