import React, { useState, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { UploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { IDocumentAssociationInfo } from '../../../../modules/CRM/Documents/UploadDocumentsDialog';

import { StateModel } from '../../../../redux/reducers';
import { StateModel as UploadStateModel } from '../../../../redux/reducers/upload';

// constants
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { IDocumentFile } from '../../../../typings/documents/documents';
import {
  ALLOWED_UPLOAD_TYPES,
  UploadStatus,
} from '../../../../constants/documents';

// components
import { Button, Col, Row, Select } from 'antd';
import UploadDocumentButton from './UploadDocumentButton';

interface IProps {
  name: string;
  placeholder?: string;
  size?: SizeType;
  value?: ShortDocumentModel[];
  onChange: (value: ShortDocumentModel[]) => void;
  disabled?: boolean;
  association: IDocumentAssociationInfo;
  description?: string;
  tags?: string[];
  canUpload?: boolean;
}

export interface ShortDocumentModel {
  id: string;
  name: string;
  file?: IDocumentFile;
}

interface LocalFieldValueModel {
  value: string;
  label: string;
}

const UploadDocumentField = ({
  name,
  value,
  onChange,
  disabled,
  association,
  placeholder,
  description,
  canUpload,
  tags = [],
  size = 'large',
  ...rest
}: IProps) => {
  const { t } = useTranslation('crm');
  const [isLoading, setLoading] = useState(false);

  const uploadState = useSelector<StateModel, UploadStateModel>(
    (state) => state.upload,
  );

  // This is local input value variable
  // we need to store it separate variable, because original value includes full infromation about document
  // but for Select field component we need to put only {value: document id, label: document name}[]
  const localFielValue: LocalFieldValueModel[] | undefined = useMemo(
    () => getFormattedFieldValue(value),
    [value],
  );

  const handleUpload = (documents: ShortDocumentModel[]) => {
    onChange(value ? [...value, ...documents] : documents);
  };

  // We need to format the original value (IDocumentBase) into the model that Select component supports (LocalFieldValueModel)
  function getFormattedFieldValue(value?: ShortDocumentModel[]) {
    let result = undefined;

    if (value && Array.isArray(value)) {
      result = value.map((e) => ({ value: e.id, label: e.name }));
    }

    return result;
  }

  const handleOnFieldChange = (newValue: LocalFieldValueModel[]) => {
    let result = undefined;

    const newValueIds = newValue.map((e) => e.value);

    result = value?.filter(({ id }) => newValueIds.includes(id));

    onChange(result || []);
  };

  const isUploadInProgress = () => {
    return uploadState.status === UploadStatus.ACTIVE && isLoading;
  };

  return (
    <>
      <StyledRow gutter={[0, 16]}>
        <Col xl={16} lg={16} md={16} sm={16} xs={24}>
          <StyledSelect
            {...rest}
            placeholder={placeholder || t('documents.select_documents')}
            labelInValue
            dropdownStyle={{ display: 'none' }}
            size={size}
            mode="tags"
            value={localFielValue}
            onChange={handleOnFieldChange}
            disabled={disabled || isUploadInProgress()}
            onInputKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              // Prevent entering event
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </Col>

        <Col xl={8} lg={8} md={8} sm={8} xs={24}>
          <UploadDocumentButton
            name={name}
            association={association}
            description={description}
            tags={tags}
            accept={ALLOWED_UPLOAD_TYPES}
            setLoading={setLoading}
            onUpload={handleUpload}
          >
            <StyledButton
              type="primary"
              size={size}
              disabled={disabled || canUpload}
              loading={isUploadInProgress()}
            >
              <UploadOutlined /> {t('documents.uploadDocuments.upload')}
            </StyledButton>
          </UploadDocumentButton>
        </Col>
      </StyledRow>
    </>
  );
};

const StyledRow = styled(Row)`
  margin-bottom: 0 !important;
`;

const StyledButton = styled(Button)`
  width: 100%;
  min-width: 0px;
  height: 100%;
  max-height: 40px;
  min-height: 40px;
  padding: 0px;
`;

// Hide blinking cursor, since the user cannot enter anything into this input field
const StyledSelect = styled(Select)<any>`
  input {
    color: transparent !important;
  }
`;

export default UploadDocumentField;
