import { PermissionCheckModel } from '../../typings/profile/security';
import { CRMPermissionKeys, CRMPermissions } from './crmPermissions';
import { ORACPermissionKeys, ORACPermissions } from './oracPermissions';
import {
  BankingPermissionKeys,
  BankingPermissions,
} from './bankingPermissions';
import {
  FinancePermissionKeys,
  FinancePermissions,
} from './financePermissions';
import {
  ReportingPermissionKeys,
  ReportingPermissions,
} from './reportingPermissions';
import {
  CompliancePermissions,
  CompliancePermissionKeys,
} from './compliancePermissions';
import {
  ApprovalManagementPermissionKeys,
  ApprovalManagementPermissions,
} from './approvalManagementPermissions';

export interface PermissionedAccessModel {
  requiredAllPermissions: boolean;
  permissions: PermissionCheckModel[];
}

// Main permission keys type
export type PERMISSION_KEYS =
  | CRMPermissionKeys
  | ORACPermissionKeys
  | BankingPermissionKeys
  | FinancePermissionKeys
  | ReportingPermissionKeys
  | CompliancePermissionKeys
  | ApprovalManagementPermissionKeys;

// Main permission config
export const permissionsConfig: Record<
  PERMISSION_KEYS,
  PermissionedAccessModel
> = {
  ...CRMPermissions,
  ...ORACPermissions,
  ...BankingPermissions,
  ...FinancePermissions,
  ...ReportingPermissions,
  ...CompliancePermissions,
  ...ApprovalManagementPermissions,
};
