import React, { useState } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import ongoingReviewsAPI from '../../../api/compliance/ongoingReviewsAPI';
import { DEFAULT_TABLE_LIMIT } from '../../../constants/global';

// components
import OngoingReviewsTable from '../../../components/Tables/TableTemplates/Compliance/OngoingReviewsTable';

const OngoingReviews = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      ongoingReviewsAPI.fetchOngoingReviews({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [currentPage],
  );

  return (
    <OngoingReviewsTable
      loading={loading}
      data={response?.data || []}
      total={response?.total || 0}
      current={currentPage}
      onPaginationChange={setCurrentPage}
    />
  );
};

export default OngoingReviews;
