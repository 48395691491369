import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { debounce } from 'lodash';
import { accountRecoveryRequestsAPI } from '../../../../api/orac/accountRecoveryRequestsAPI';

// constants
import { AccountRecoveryEntryStatus } from '../../../../enums/auth/accountRecovery';
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  DEFAULT_TABLE_LIMIT,
} from '../../../../constants/global';

// components
import FilterBar from './FilterBar';
import { Divider } from 'antd';
import { default as TemplateAccountRecoveryRequestsTable } from '../../../../components/Tables/TableTemplates/ORAC/AccountRecoveryRequestsTable';

const AccountRecoveryRequestsTable = () => {
  const [current, setCurrent] = useState(1);

  // filter bar
  const [accountRecoveryRequestFilter, setAccountRecoveryRequestFilter] =
    useState<{
      name: string;
      status: AccountRecoveryEntryStatus | null;
    }>({ name: '', status: null });

  const { response, loading } = useFetch(
    () =>
      accountRecoveryRequestsAPI.fetchAccountRecoveryRequests({
        limit: DEFAULT_TABLE_LIMIT,
        page: current,
        name: accountRecoveryRequestFilter.name,
        status: accountRecoveryRequestFilter.status,
      }),
    [current, accountRecoveryRequestFilter],
  );

  const handlerFilterChange = (key: string, value = '') => {
    if (current > 1) {
      setCurrent(1);
    }

    switch (key) {
      case 'search-by-name':
        setAccountRecoveryRequestFilter((prevState) => ({
          ...prevState,
          name: value.trim(),
        }));
        break;

      case 'filter-by-status':
        setAccountRecoveryRequestFilter((prevState) => ({
          ...prevState,
          status: (value as AccountRecoveryEntryStatus) || null,
        }));
        break;
    }
  };

  return (
    <>
      <FilterBar
        selectedStatus={accountRecoveryRequestFilter.status}
        onFilterChange={debounce(
          handlerFilterChange,
          AUTOCOMPLETE_DEBOUNCE_DELAY,
        )}
      />
      <Divider />
      <TemplateAccountRecoveryRequestsTable
        data={response?.data || []}
        total={response?.total || 0}
        current={current}
        loading={loading}
        onPaginationChange={setCurrent}
      />
    </>
  );
};

export default AccountRecoveryRequestsTable;
