import React, { useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { onboardingAPI } from 'api/crm/onboarding/onboardingAPI';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';

// components
import ApprovalGroupDetailsModal from './ApprovalGroupDetailsModal';
import OnboardingApprovalGroupsTable, {
  TableActions,
  TableRecord,
} from 'components/Tables/TableTemplates/CRM/OnboardingApprovalGroupsTable';

interface IProps {
  clientGroupNumericId: number;
}

const ApprovalGroupsTab = ({ clientGroupNumericId }: IProps) => {
  const { t } = useTranslation(['crm', 'server_errors']);
  const [page, setPage] = useState(1);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);

  const { response, loading } = useFetch(async () => {
    const data = await onboardingAPI.fetchApprovalGroups(
      {
        page,
        limit: DEFAULT_TABLE_LIMIT,
      },
      clientGroupNumericId,
      'onboarding-transaction',
    );

    const formattedData: TableRecord[] = data.data.map((e) => ({
      id: e._id,
      name: e.name,
      description: e.description,
      members: e.participants
        ? e.participants.map((p) => ({ id: p.contactId, name: p.name }))
        : [],
      errors: e.validationErrors.length
        ? e.validationErrors.map((e) => t(e.code + '', { ns: 'server_errors' }))
        : [],
    }));

    return {
      total: data.total,
      data: formattedData,
    };
  }, [page, clientGroupNumericId]);

  const handleActionsClickCallback = (
    key: TableActions,
    record: TableRecord,
  ) => {
    switch (key) {
      case 'view':
        setSelectedGroupId(record.id);
        break;
    }
  };

  const handleCloseCallback = () => {
    setSelectedGroupId(null);
  };

  return (
    <>
      <OnboardingApprovalGroupsTable
        loading={loading}
        current={page}
        data={response?.data || []}
        total={response?.total || 0}
        onPaginationChange={setPage}
        onActionsClick={handleActionsClickCallback}
      />
      <ApprovalGroupDetailsModal
        isVisible={!!selectedGroupId}
        closeCallback={handleCloseCallback}
        clientGroupId={clientGroupNumericId}
        approvalGroupId={selectedGroupId}
      />
    </>
  );
};

export default ApprovalGroupsTab;
