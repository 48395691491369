import APIConfig from '../../config/api';
import { IEntity } from 'typings/crm/entity';
import { APIService } from '../axiosInstance';
import { OnboardType } from 'enums/crm/crm';
import { CurrencyTypes } from '../../enums/finance/finance';
import { GeneralLedgerItem } from 'components/Tables/TableTemplates/Reporting/GeneralLedgerTable';
import { GeneralLedgerReportEntry } from 'components/Tables/TableTemplates/Reporting/TransactionsTable';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import {
  ExposureByCurrencyModel,
  ReportElementModel,
} from '../../typings/reporting/reporting';
import reportingAPIAdapter, {
  BalanceSheetReportRequestModel,
  ClientBalanceSheetReportRequestModel,
  ClientRevenueSheetReportRequestModel,
} from '../../apiAdapters/reporting/reportingAPIAdapter';

export interface FetchIncomeStatementReportResponse
  extends FetchResponseModel<BalanceSheetReportRequestModel> {
  netProfit: number;
  netProfitResident: number;
  netProfitNonResident: number;
}

export interface TrialBalanceEntryModel {
  debit: number;
  credit: number;
  balance: number;
  accountName: string;
  accountNumber: string;
  financialTypeName: string;
  accountCategoryId: number;
  accountCategoryName: string;
}

export type GeneralLedgerReportItemFromQuery = {
  accountName: string;
  accountNumber: string;
  financialTypeName: string;
  accountCategoryId: number;
  accountCategoryName: string;
  debitAmount: number;
  creditAmount: number;
  netMovement: number;
};

export type GeneralLedgerReportEntryFromQuery = {
  valueDate: number;
  postingDate: number;
  description: string;
  debitAmount: number;
  creditAmount: number;
  journalEntryTypeId: number;
  journalEntryTypeName: string;
};

export interface TrialBalanceReportResponse {
  currencyId: number;
  currencyIsoCode: CurrencyTypes;
  debitTotal: number;
  creditTotal: number;
}

interface CashFlowCountryEntry {
  countryCode: string;
  totalValue: number;
  totalTransactions: number;
}

interface CashFlowBankCodeTypeEntry {
  bankCodeType: string;
  totalTransactions: number;
}

export interface CashFlowReportEntry {
  header: {
    totalClients: number;
    totalValue: number;
    totalTransactions: number;
  };
  data: {
    incoming: {
      countryEntries: CashFlowCountryEntry[];
      bankCodeTypeEntries: CashFlowBankCodeTypeEntry[];
    };

    outgoing: {
      countryEntries: CashFlowCountryEntry[];
      bankCodeTypeEntries: CashFlowBankCodeTypeEntry[];
    };
  };
}

export type IncomingTransferReportItem = {
  valueDate: number;
  postingDate: number;
  transactionReferenceNumber: string;
  currency: string;
  amount: number;
  amountBase: number;
  orderingCustomerAccountNumber: string;
  orderingCustomerAccountName: string;
  orderingInstitution: {
    bankCode: string;
    bankName: string;
    addressLine1: string;
    city: string;
    postCode: string;
    district: string;
    countryCode: string;
    bankAddress: string;
  };
  beneficiaryCustomerAccountNumber: string;
  beneficiaryCustomerAccountName: string;
  senderToReceiverLine1: string;
  senderToReceiverLine2: string;
  senderToReceiverLine3: string;
  senderToReceiverLine4: string;
  senderToReceiverLine5: string;
  senderToReceiverLine6: string;
  otherDetails: string;
};

export type OutgoingTransferReportItem = {
  otherDetails: string;
  valueDate: number;
  postingDate: number;
  transactionReferenceNumber: string;
  currency: string;
  amount: number;
  amountBase: number;
  orderingCustomerAccountNumber: string;
  orderingCustomerAccountName: string;
  beneficiaryCustomerAccountNumber: string;
  beneficiaryCustomerAccountName: string;
  remittanceInformationLine1: string;
  remittanceInformationLine2: string;
  remittanceInformationLine3: string;
  remittanceInformationLine4: string;
  accountWithInstitution: {
    bankAddress: string;
    addressLine1: string;
    bankCode: string;
    bankName: string;
    city: string;
    countryCode: string;
    district: string;
    postCode: string;
  };
};

export interface FetchTrialBalanceEntiresParams extends FetchParamsModel {
  toDate: string;
}

export interface FetchGeneralLedgerReportParams extends FetchParamsModel {
  fromDate: string;
  toDate: string;
}

export interface FetchCashFlowReportParams {
  fromDate: string;
  toDate: string;
}
export interface FetchIncomingTransfersReportParams extends FetchParamsModel {
  fromDate: string;
  toDate: string;
}

export interface FetchOutgoingTransfersReportParams extends FetchParamsModel {
  fromDate: string;
  toDate: string;
}

export interface FetchEntryChangesLogReportParams extends FetchParamsModel {
  fromDate: string;
  toDate: string;
}

export type EntryChangesLogReportItemFromQuery = {
  _id: string;
  actionType: string;
  entity: {
    id: string;
    type: OnboardType;
    name: string;
  };
  user: {
    id: string;
    name: string;
  };

  previousState: {
    organizationState: IEntity;
  } | null;
  newState: {
    organizationState: IEntity;
  } | null;
  _audit: {
    createdAt: string;
    createdBy: string;
    updatedAt: string;
  };
};

const reportingAPI = {
  fetchBalanceSheetReport: (toDate: string) => {
    return APIService.get<FetchResponseModel<BalanceSheetReportRequestModel>>(
      `${APIConfig.accountingApi}/report/balance-sheet`,
      {
        params: { toDate },
      },
    ).then<FetchResponseModel<ReportElementModel>>(({ data }) =>
      reportingAPIAdapter.fetchBalanceSheetReport(data),
    );
  },

  fetchIncomeStatementReport: (fromDate: string, toDate: string) => {
    return APIService.get<FetchIncomeStatementReportResponse>(
      `${APIConfig.accountingApi}/report/income-statement`,
      {
        params: { fromDate, toDate },
      },
    ).then<FetchResponseModel<ReportElementModel>>(({ data }) =>
      reportingAPIAdapter.fetchIncomeStatementReport(data),
    );
  },

  fetchClientGroupBalancesReport: (toDate: string) => {
    return APIService.get<
      FetchResponseModel<ClientBalanceSheetReportRequestModel>
    >(`${APIConfig.accountingApi}/report/deposits-by-client-statement`, {
      params: { toDate },
    }).then(({ data }) =>
      reportingAPIAdapter.fetchClientGroupBalancesReport(data),
    );
  },

  fetchClientGroupRevenueReport: (fromDate: string, toDate: string) => {
    return APIService.get<
      FetchResponseModel<ClientRevenueSheetReportRequestModel>
    >(`${APIConfig.accountingApi}/report/income-by-client-statement`, {
      params: { fromDate, toDate },
    }).then(({ data }) => reportingAPIAdapter.fetchClientRevenueReport(data));
  },

  fetchExposureByCurrencyReport: () => {
    return APIService.get<FetchResponseModel<ExposureByCurrencyModel>>(
      `${APIConfig.accountingApi}/report/exposure-by-currency`,
    ).then(({ data }) => data);
  },

  fetchTrialBalanceCurrenciesReport: (toDate: string) => {
    return APIService.get<FetchResponseModel<TrialBalanceReportResponse>>(
      `${APIConfig.accountingApi}/report/trial-balance`,
      {
        params: { toDate },
      },
    ).then(({ data }) => data);
  },

  fetchTrialBalanceEntriesReport: (
    params: FetchTrialBalanceEntiresParams,
    currencyId: number,
  ) => {
    return APIService.get<FetchResponseModel<TrialBalanceEntryModel>>(
      `${APIConfig.accountingApi}/report/trial-balance/${currencyId}/entries`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchGeneralLedgerReport: (params: FetchGeneralLedgerReportParams) => {
    return APIService.get<FetchResponseModel<GeneralLedgerReportItemFromQuery>>(
      `${APIConfig.accountingApi}/report/general-ledger`,
      {
        params,
      },
    ).then<FetchResponseModel<GeneralLedgerItem>>(({ data }) =>
      reportingAPIAdapter.fetchGeneralLedgerReport(data),
    );
  },

  fetchCashFlowReport: (params: FetchCashFlowReportParams) => {
    return APIService.get<CashFlowReportEntry>(
      `${APIConfig.accountingApi}/report/cash-flow`,
      { params },
    ).then(({ data }) =>
      reportingAPIAdapter.fetchEntriesOfCashFlowReport(data),
    );
  },

  fetchEntriesForGeneralLedgerReport: (
    accountNumber: string,
    params: FetchParamsModel,
  ) => {
    return APIService.get<
      FetchResponseModel<GeneralLedgerReportEntryFromQuery>
    >(
      `${APIConfig.accountingApi}/report/general-ledger/${accountNumber}/entries`,
      {
        params,
      },
    ).then<FetchResponseModel<GeneralLedgerReportEntry>>(({ data }) =>
      reportingAPIAdapter.fetchEntriesOfGeneralLedgerReport(data),
    );
  },

  fetchIncomingTransfersReport: (
    params: FetchIncomingTransfersReportParams,
  ) => {
    return APIService.get<FetchResponseModel<IncomingTransferReportItem>>(
      `${APIConfig.accountingApi}/report/threshold/incoming`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchOutgoingTransfersReport: (
    params: FetchOutgoingTransfersReportParams,
  ) => {
    return APIService.get<FetchResponseModel<OutgoingTransferReportItem>>(
      `${APIConfig.accountingApi}/report/threshold/outgoing`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchEntryChangesLogReport: (params: FetchEntryChangesLogReportParams) => {
    return APIService.get<
      FetchResponseModel<EntryChangesLogReportItemFromQuery>
    >(`${APIConfig.crmApi}/entity-change-logs`, {
      params,
    }).then(({ data }) =>
      reportingAPIAdapter.formatFetchEntryChangesLogReportToList(data),
    );
  },

  fetchEntryChangesLogById: (entryId: string) => {
    return APIService.get<EntryChangesLogReportItemFromQuery>(
      `${APIConfig.crmApi}/entity-change-logs/${entryId}`,
    ).then(({ data }) => data);
  },
};

export { reportingAPI };
