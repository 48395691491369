import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';

import { ForexTransferModel } from '../../typings/banking/forexTransfers';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

export interface UpdatePendingTransferBodyModel {
  spread: number;
  fromCurrencyRate: number;
  toCurrencyRate: number;
}

const pendingForexTransfersAPI = {
  fetchPendingTransfers: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<ForexTransferModel>>(
      `${APIConfig.accountingApi}/transactions/processing`,
      { params },
    ).then(({ data }) => data);
  },

  getPendingTransferById: (transferId: string) => {
    return APIService.get<ForexTransferModel>(
      `${APIConfig.accountingApi}/transactions/processing/${transferId}`,
    ).then(({ data }) => data);
  },

  getCalculatedTransferSummary: (
    transferId: number,
    spread: number,
    fromCurrencyRate: number,
    toCurrencyRate: number,
  ) => {
    return APIService.post<ForexTransferModel>(
      `${APIConfig.accountingApi}/transactions/processing/${transferId}/estimate`,
      {
        spread,
        fromCurrencyRate,
        toCurrencyRate,
      },
    ).then(({ data }) => data);
  },

  updatePendingTransferById: (
    transferId: string,
    updatedTransfer: UpdatePendingTransferBodyModel,
  ) => {
    return APIService.patch<ForexTransferModel>(
      `${APIConfig.accountingApi}/transactions/processing/${transferId}`,
      updatedTransfer,
    ).then(({ data }) => data);
  },
};

export { pendingForexTransfersAPI };
