import React from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';

// components
import FormField from '@core_components/FormField';
import RolesAutocomplete from '../../../../../../Forms/FormComponents/Autocompletes/ORAC/RolesAutocomplete';

const InnerForm = () => {
  const { t } = useTranslation('orac');

  return (
    <FormField
      name="roleId"
      label={t('groups.view.role')}
      component={RolesAutocomplete}
      additionalProps={{ allowClear: true }}
    />
  );
};

export default InnerForm;
