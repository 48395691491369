import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { FilterOptionTypes } from '..';

// components
import CountryRiskRatingSelect from '../../../../components/Forms/FormComponents/SelectInputs/Compliance/CountryRiskRatingSelect';
import { Col, Row } from 'antd';
import { SearchInput } from 'components/Fields/SearchInput';

interface IProps {
  onFilterChange: (key: FilterOptionTypes, value?: string) => void;
}

const FilterBar = ({ onFilterChange }: IProps) => {
  const { t } = useTranslation(['compliance', 'common']);

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Row>
          <Col span={24}>
            {t('country_risk_ratings.filter_bar.search_by_name')}
          </Col>
          <Col span={24}>
            <SearchInput
              size="large"
              placeholder={t('enter_value', { ns: 'common' })}
              type="search"
              onSearch={(searchQuery: string) =>
                onFilterChange('search', searchQuery)
              }
            />
          </Col>
        </Row>
      </Col>

      <Col span={6}>
        <Row>
          <Col span={24}>
            {t('country_risk_ratings.filter_bar.filter_by_risk_ratings')}
          </Col>
          <Col span={24}>
            <StyledRiskRatingSelect
              size="large"
              onChange={(newValue) => onFilterChange('risk_rating', newValue)}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledRiskRatingSelect = styled(CountryRiskRatingSelect)`
  width: 100%;
`;

export default FilterBar;
