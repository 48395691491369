import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { IContactModel } from '../../../../../../../../typings/crm/contact';
import { COUNTRY_CODES } from '../../../../../../../../constants/countryCodes';

// components
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

interface IProps {
  contact: IContactModel;
}

const ContactInfo = ({ contact }: IProps) => {
  const { t } = useTranslation('crm');

  const renderEmail = (emails: { address: string; isPrimary: boolean }[]) => {
    const email = emails.find(({ isPrimary }) => isPrimary) || emails[0];
    return email?.address || '-';
  };

  const renderPhoneNumber = (
    phoneNumbers: { number: string; isPrimary: boolean }[],
  ) => {
    const phone =
      phoneNumbers.find(({ isPrimary }) => isPrimary) || phoneNumbers[0];
    return phone?.number || '-';
  };

  const descriptionsData = useMemo<DescriptionItem[]>(
    () => [
      {
        label: t('contacts.new.firstName'),
        description: contact.firstName || '-',
      },
      {
        label: t('contacts.new.middleName'),
        description: contact.middleName || '-',
      },
      {
        label: t('contacts.new.lastName'),
        description: contact.lastName || '-',
      },
      {
        label: t('contacts.new.dateOfBirth'),
        description: contact.dateOfBirth
          ? DateHelpers.formatDateToString(contact.dateOfBirth)
          : '-',
      },
      {
        label: t('contacts.new.countryOfBirth'),
        description: COUNTRY_CODES[contact.countryOfBirth] || '-',
      },
      {
        label: t('contacts.new.email'),
        description: renderEmail(contact.emails),
      },
      {
        label: t('contacts.new.phoneNumber'),
        description: renderPhoneNumber(contact.phoneNumbers),
      },
    ],

    [contact],
  );

  return <DescriptionSection data={descriptionsData} />;
};

export default ContactInfo;
