import React, { useMemo, memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { ComparisonOperators } from '../../../../../enums/compliance/reviewConfiguration';
import { ReviewProcessConfigurationRuleModel } from '../../../../../typings/compliance/reviewProcess';

// components
import EllipsisTooltip from '../../../../Tooltips/EllipsisTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

type IProps = RequiredPropsForTableModel<ReviewProcessConfigurationRuleModel>;

const RDCReviewRulesTable = memo((props: IProps) => {
  const { t } = useTranslation(['compliance', 'common']);

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: 250,
        title: t('transactions.name'),
        key: 'propertyName',
        render: (record: ReviewProcessConfigurationRuleModel) =>
          record.propertyName,
      },

      {
        width: 200,
        title: t('transactions.is_triggered'),
        key: 'isTriggered',
        render: (record: ReviewProcessConfigurationRuleModel) =>
          record.isTriggered ? (
            <HighlightedText>{t('yes', { ns: 'common' })}</HighlightedText>
          ) : (
            t('no', { ns: 'common' })
          ),
      },

      {
        width: 300,
        title: t('transactions.actual_value'),
        key: 'evaluatedAgainstValueJSON',
        render: (record: ReviewProcessConfigurationRuleModel) => (
          <EllipsisTooltip
            maxTextContainerWidth="300px"
            overlayStyle={{ maxWidth: '400px' }}
            title={record.evaluatedAgainstValueJSON}
          >
            {record.evaluatedAgainstValueJSON}
          </EllipsisTooltip>
        ),
      },

      {
        width: 250,
        title: t('transactions.comparison_operator'),
        key: 'comparisonOperator',
        render: (record: ReviewProcessConfigurationRuleModel) =>
          t(
            `comparison_operator.${ComparisonOperators[record.comparisonOperator]}`,
          ),
      },

      {
        width: 300,
        title: t('transactions.expected_value'),
        key: 'expectedValueJSON',
        render: (record: ReviewProcessConfigurationRuleModel) => (
          <EllipsisTooltip
            maxTextContainerWidth="300px"
            overlayStyle={{ maxWidth: '400px' }}
            title={record.expected?.valueJSON}
          >
            {record.expected?.valueJSON}
          </EllipsisTooltip>
        ),
      },
    ];

    return result;
  }, [t]);

  return <Table {...props} columns={columns} size="small" />;
});

const HighlightedText = styled(Typography.Text)`
  color: ${({ theme }) => theme.successColor};
`;

export default RDCReviewRulesTable;
