import React, { useEffect } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_AUTH_OVERVIEW_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import Devices from '../../modules/Profile/Security/Devices';
import Sessions from '../../modules/Profile/Security/Sessions';
import SessionSettings from '../../modules/Profile/Security/SessionSettings';
import TwoFactorVerification from '../../modules/Profile/Security/TwoFactorVerification';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const Security = ({ setHeaderOptions }: IProps) => {
  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation
          link={GENESIS_AUTH_OVERVIEW_DOCUMENTATION_LINK}
        />
      ),
    });
  }, []);

  return (
    <>
      <TwoFactorVerification />
      <Devices />
      <Sessions />
      <SessionSettings />
    </>
  );
};

export default Security;
