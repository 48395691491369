import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import objectsAPI from '../../../../api/orac/objectsAPI';
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';

// components
import FilterBar from './FilterBar';
import { Divider } from 'antd';
import { default as TemplateObjectsTable } from '../../../../components/Tables/TableTemplates/ORAC/ObjectsTable';

export type FilterBarKeys = 'search_by_name';

const ObjectsTable = () => {
  const [current, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');

  const { response, loading } = useFetch(
    () =>
      objectsAPI.fetchObjects({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        name: searchValue,
      }),
    [current, searchValue],
  );

  const handleFilterBarChange = (key: FilterBarKeys, value: string) => {
    if (current > 1) {
      setCurrentPage(1);
    }

    switch (key) {
      case 'search_by_name':
        setSearchValue(value);
        break;
    }
  };

  return (
    <>
      <FilterBar onFilterChange={handleFilterBarChange} />

      <Divider />

      <TemplateObjectsTable
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={current}
        onPaginationChange={setCurrentPage}
      />
    </>
  );
};

export default ObjectsTable;
