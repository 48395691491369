import React, { useState, useEffect } from 'react';

// helpers
import styled from 'styled-components';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { StateModel } from '../../../redux/reducers';
import { changeView } from '../../../redux/actions/view';
import { SubRouteModel } from '../../../typings/routes';
import { getDefaultOpenedKeys } from '../../../helpers/utils';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from '../../../hooks/useTranslation';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { StateModel as UserAcccesStateModel } from '../../../redux/reducers/userAccess';

// constants
import { darkTheme } from '../../../resources/theme/styled/index';
import { userRoutes } from '../../../routes/userRoutes';
import { PERMISSION_KEYS } from '../../../config/permissions';

// components
import { CloseOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';

const { SubMenu } = Menu;

interface IProps {
  isOpen: boolean;
  closeCallback: () => void;
}

// left navigation drawer for mobile devices
// this component shows only client routes
const SidebarForMobile = ({ isOpen, closeCallback }: IProps) => {
  const { t } = useTranslation('page_titles');

  const history = useHistory();
  const location = useLocation();
  const breakpoint = useBreakpoint();
  const dispatch = useDispatch();
  const historyPush = (path: any, state?: any) =>
    dispatch(changeView(() => history.push(path, state)));

  const { permissions } = useSelector<StateModel, UserAcccesStateModel>(
    (state) => state.userAccess,
  );

  // routes to render in navigation drawer
  const [routes, setRoutes] = useState<SubRouteModel[]>([]);

  useEffect(() => {
    setRoutes(userRoutes);
  }, [breakpoint.xs]);

  // Check route permissions
  // If the route has permissions -> check if user has such permissions
  const checkPermissions = (route: SubRouteModel) => {
    if (!route.permission?.permissionKey) {
      return true;
    }

    return permissions[route.permission.permissionKey as PERMISSION_KEYS]
      .isAllowed;
  };

  const renderDrawerHeader = () => <StyledCloseIcon onClick={closeCallback} />;

  // navigate to derived path and close drawer
  const navigateToPath = (pathName: string) => {
    closeCallback();
    historyPush(pathName);
  };

  const renderNavigationMenu = (routesToRender: SubRouteModel[]) => (
    <Menu
      mode="inline"
      selectedKeys={getDefaultOpenedKeys(location.pathname)}
      defaultOpenKeys={getDefaultOpenedKeys(location.pathname)}
      onSelect={({ key }: any) => navigateToPath(key)}
    >
      {routesToRender.map((route) => renderNavItem(route))}
    </Menu>
  );

  const renderNavItem = (route: SubRouteModel) => {
    if (route.hideFromNavigation) return null;

    const hasPermission = checkPermissions(route);

    if (!hasPermission && !route.permission?.options?.disableNavItem) return;

    const isDisabled =
      !hasPermission && route.permission?.options?.disableNavItem;

    const filteredChildRoutes = !route.childRoutes?.length
      ? []
      : route.childRoutes.filter((e) => !e.hideFromNavigation);

    if (filteredChildRoutes.length) {
      const menuClassName = matchPath(location.pathname, { path: route.path })
        ? 'menu-submenu-selected'
        : '';

      return (
        <SubMenu
          key={route.path}
          disabled={isDisabled}
          className={menuClassName}
          title={
            <div
              className="title-el"
              onClick={() => navigateToPath(route.path)}
            >
              {t(route.title)}
            </div>
          }
        >
          {filteredChildRoutes.map(renderNavItem)}
        </SubMenu>
      );
    }

    return (
      <Menu.Item key={route.path} disabled={isDisabled}>
        {t(route.title)}
      </Menu.Item>
    );
  };
  return (
    <StyledDrawer
      placement="left"
      visible={isOpen}
      closable={false}
      getContainer={false}
      onClose={closeCallback}
      width={darkTheme.leftDrawerWidth}
      style={{ position: 'fixed' }}
    >
      {renderDrawerHeader()}
      {renderNavigationMenu(routes)}
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content {
    padding: 0px;
  }
`;

const StyledCloseIcon = styled(CloseOutlined)`
  color: #fff;
  font-size: 20px;
  cursor: pointer;
`;

export default SidebarForMobile;
