import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { AccountTypes } from 'enums/finance/finance';
import { IAccountModel } from '../../../typings/finance/account';
import { BankAccountStatuses } from 'enums/banking/accounts';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../constants/global';
import { bankingBankAccountsAdapter } from 'apiAdapters/banking/bankingBankAccountsAdapter';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';

interface IPaginationBase {
  total: number;
}

interface IAccountsResponse extends IPaginationBase {
  data: IAccountModel[];
}

interface FetchAllAccountsParamsModel extends FetchParamsModel {
  accountQuery: string;
  financialTypeNameQuery: string;
  accountCategoryNameQuery: string;
  currencyIsoCodeQuery?: string;
  isReconciled: string;
  status?: BankAccountStatuses;
  currencyId?: number;
  accountCategoryId: string;
}

interface GetAccountNumberBody {
  clientId: number;
  currencyId: number;
  accountCategoryId: number;
}

interface GetAccountNumberResponse {
  accountNumber: string;
  shouldAddShortName: boolean;
}

export interface INewAccountBody {
  accountName: string;
  accountNumberName?: string;
  correspondentBankAccountNumber: string;
  clientGroupId?: number;
  currencyId: number;
  accountCategoryId: number;
  status: BankAccountStatuses;
  isFrozen: boolean;
  isReconciled: boolean;

  // Needs to be passed for client bank accounts
  accountSupplementary?: {
    type: AccountTypes | null;
    purpose: string;
    initialDepositOrigin: string;
    initialDepositFrom: number;
    initialDepositTo: number;
    incomingWiresNumberFrom: number;
    incomingWiresNumberTo: number;
    outgoingWiresNumberFrom: number;
    outgoingWiresNumberTo: number;
    estimatedIncomingFundsFrom: number;
    estimatedIncomingFundsTo: number;
    estimatedOutgoingFundsFrom: number;
    estimatedOutgoingFundsTo: number;
    jurisdictions: string[];
  };
}

export interface BankAccountWithBeneficiaryDataFromQuery {
  accountNumber: string;
  accountName: string;
  countryCode: string;
  address: string;
  city: string;
  district: string;
  postalCode: string;
}

export interface WireDetailsModelFromQuery {
  id?: string;

  isManualBeneficiary: boolean;

  beneficiaryCity: string;
  beneficiaryCountry: string;
  beneficiaryAddress: string;
  beneficiaryDistrict: string;
  beneficiaryPostCode: string;
  beneficiaryAccountName: string;
  beneficiaryAccountNumber: string;

  beneficiaryBankName: string;
  beneficiaryBankCode: string;
  beneficiaryBankCodeType: number;
  beneficiaryBankAddress: string;
  beneficiaryBankCity: string;
  beneficiaryBankDistrict: string;
  beneficiaryBankPostCode: string;
  beneficiaryBankCountry: string;

  intermediaryBankName: string;
  intermediaryBankCode: string;
  intermediaryBankCodeType: number;
  intermediaryBankAddress: string;
  intermediaryBankCity: string;
  intermediaryBankDistrict: string;
  intermediaryBankPostCode: string;
  intermediaryBankCountry: string;
}

const accountsAPI = {
  fetchAccounts: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<IAccountModel>>(
      `${APIConfig.accountingApi}/accounts`,
      { params },
    ).then(({ data }) => data);
  },

  fetchAllAccounts: (params: FetchAllAccountsParamsModel) => {
    return APIService.get<FetchResponseModel<IAccountModel>>(
      `${APIConfig.accountingApi}/accounts/filter`,
      { params },
    ).then(({ data }) => data);
  },

  searchAccounts: (
    searchQuery?: string,
    isActive?: boolean,
    isFullAccountNumber?: boolean,
    clientGroupId?: number,
  ) => {
    return APIService.get<FetchResponseModel<IAccountModel>>(
      `${APIConfig.accountingApi}/accounts/search`,
      {
        params: {
          page: 1,
          limit: AUTOCOMPLETE_RESULT_LIMIT,
          accountQuery: searchQuery,
          isActive,
          isFullAccountNumber,
          clientGroupId,
        },
      },
    ).then(({ data }) => data);
  },

  fetchBankAccountWithBeneficiaryDetails: (accountNumber: string) => {
    return APIService.get<BankAccountWithBeneficiaryDataFromQuery>(
      `${APIConfig.accountingApi}/accounts/${accountNumber}/client-address`,
    ).then(({ data }) => data);
  },

  createAccount: (newAccount: INewAccountBody) => {
    return APIService.post<IAccountsResponse>(
      `${APIConfig.accountingApi}/accounts`,
      newAccount,
    ).then(({ data }) => data);
  },

  fetchAccountByNumber: (accountNumber: string) => {
    return APIService.get<IAccountModel>(
      `${APIConfig.accountingApi}/accounts/${accountNumber}`,
    ).then(({ data }) => data);
  },

  getAccountNumber: (accountInfo: GetAccountNumberBody) => {
    return APIService.post<GetAccountNumberResponse>(
      `${APIConfig.accountingApi}/accounts/generate`,
      accountInfo,
    ).then(({ data }) => data);
  },

  checkUniqueStatus: (accountNumber: string) => {
    return APIService.get<boolean>(
      `${APIConfig.accountingApi}/accounts/${accountNumber}/unique`,
    ).then(({ data }) => data);
  },

  fetchWireDetailsByAccountNumber: (accountNumber: string) => {
    return APIService.get<WireDetailsModelFromQuery>(
      `${APIConfig.accountingApi}/accounts/correspondent/${accountNumber}/wire-details`,
    ).then(({ data }) =>
      bankingBankAccountsAdapter.fetchWireDetailsByAccountNumber(data),
    );
  },

  fetchWireDetailsToogleByAccountNumber: (accountNumber: string) => {
    return APIService.get<WireDetailsModelFromQuery>(
      `${APIConfig.accountingApi}/accounts/correspondent/${accountNumber}/wire-details/toggle`,
    ).then(({ data }) =>
      bankingBankAccountsAdapter.fetchWireDetailsByAccountNumber(data),
    );
  },

  fetchAccountActiveWorkflowStatus: (accountNumber: string) => {
    return APIService.get(
      `${APIConfig.accountingApi}/journals/entries/reconciliation/${accountNumber}/active-workflows`,
    ).then<boolean>(({ data }) => data.hasActiveWorkflows);
  },
};

export { accountsAPI };
