import React from 'react';

// components
import FormField from '@core_components/FormField';
import ContextsAutocomplete from '../../../../../../Forms/FormComponents/Autocompletes/ORAC/ContextsAutocomplete';

const InnerForm = () => {
  return (
    <FormField
      name="contextId"
      label="Context"
      component={ContextsAutocomplete}
      additionalProps={{ allowClear: true }}
    />
  );
};

export default InnerForm;
