import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { Tag } from 'antd';
import { useField } from 'formik';
import { PaperClipOutlined } from '@ant-design/icons/lib';
import { ShortDocumentModel } from '../../../../FormComponents/UploadDocumentField';
import { IDocumentAssociationInfo } from '../../../../../../modules/CRM/Documents/UploadDocumentsDialog';

// constants
import {
  ALLOWED_ATTACHMENT_DOCUMENT_TYPES,
  MESSAGE_ATTACHMENT_TAGS,
} from '../../../../../../constants/documents';

// components
import AddButton from '../../../../../Buttons/AddButton';
import DocumentLink from '../../../../../Typography/DocumentLink';
import LoadingWrapper from '../../../../../WrapperComponents/LoadingWrapper';
import UploadDocumentButton from '../../../../FormComponents/UploadDocumentField/UploadDocumentButton';

export interface IMessageAttachmentsForm {
  attachments: ShortDocumentModel[];
}

interface IProps {
  association: IDocumentAssociationInfo;
}

const AttachmentsForm = ({ association }: IProps) => {
  const { t } = useTranslation('crm');
  const [field, , helpers] = useField('attachments');
  const [loading, setLoading] = useState(false);

  const handleAddAttachment = (documents: ShortDocumentModel[]) => {
    if (Array.isArray(field.value)) {
      helpers.setValue([...field.value, ...documents]);
    } else {
      helpers.setValue([...documents]);
    }
  };

  const removeAttachment = (document: ShortDocumentModel) => {
    if (Array.isArray(field.value)) {
      const filteredAttachments = field.value.filter(({ id }) => {
        return document.id !== id;
      });

      helpers.setValue(filteredAttachments);
    }
  };

  const renderAttachments = () => {
    return field.value?.map((document: ShortDocumentModel) => {
      return (
        <StyledTag
          closable
          key={document.id}
          onClose={() => removeAttachment(document)}
        >
          <DocumentLink
            documentId={document.id}
            fileId={document.file?.id}
            text={document.name}
            fileName={document.name}
          />
        </StyledTag>
      );
    });
  };

  return (
    <LoadingWrapper loading={loading}>
      {renderAttachments()}

      <UploadDocumentButton
        name="attachments"
        association={association}
        tags={MESSAGE_ATTACHMENT_TAGS}
        accept={ALLOWED_ATTACHMENT_DOCUMENT_TYPES}
        onUpload={handleAddAttachment}
        setLoading={setLoading}
      >
        <AddButton icon={<PaperClipOutlined />} buttonSize="small">
          {t('messaging.send_message.form_fields.files')}
        </AddButton>
      </UploadDocumentButton>
    </LoadingWrapper>
  );
};

const StyledTag = styled(Tag)`
  margin-bottom: ${({ theme }) => theme.marginXXs};

  a {
    margin-right: ${({ theme }) => theme.marginXXs};
    color: ${({ theme }) => theme.textLightColor};
  }

  .anticon-close {
    color: ${({ theme }) => theme.textLightColor};
  }
`;

export default AttachmentsForm;
