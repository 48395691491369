import React, { useMemo } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { clientAccountManagementRulesAPI } from 'api/approval/approvalManagement/clientAccountManagementRulesAPI';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';

// components
import ApprovalGroupsModal, {
  ApprovalGroupFormValuesModel,
} from 'components/Modals/TemplateModalDialogs/ApprovalManagement/ApprovalGroupsModal';

interface IProps extends RequiredPropsForModalDialogModel {
  groupId: string | null;
  clientGroupId: number;
}

const EditApprovalGroupModal = ({
  groupId,
  clientGroupId,
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('crm');

  const { response, loading } = useFetch(
    () =>
      groupId && clientGroupId
        ? clientAccountManagementRulesAPI.fetchApprovalGroupById(
            clientGroupId,
            groupId,
          )
        : null,
    [groupId, clientGroupId],
  );

  const initialFormValues: ApprovalGroupFormValuesModel | null = useMemo(() => {
    let result = null;

    if (response) {
      const { name, description, participants } = response;
      const initialParticipantOptions = participants.map((e) => ({
        id: e.userId,
        content: e.userFullName,
        model: e,
      }));
      result = { name, description, participants, initialParticipantOptions };
    }

    return result;
  }, [response]);

  const handleSubmit = async (values: ApprovalGroupFormValuesModel) => {
    await clientAccountManagementRulesAPI.updateApprovalGroup(
      {
        name: values.name,
        description: values.description as string,
        participants: values.participants,
      },
      clientGroupId,
      groupId as string,
    );
    message.success(
      t(
        'client_group.approval.client_approval_groups.update_group_modal.success_submit_message',
      ),
    );
  };

  return (
    <ApprovalGroupsModal
      isEditMode
      title={t(
        'client_group.approval.client_approval_groups.update_group_modal.title',
      )}
      onSubmit={handleSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      loadingInitialData={loading}
    />
  );
};

export default EditApprovalGroupModal;
