import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { NoteMessageType, NoteType } from '../../enums/crm/crm';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import {
  DocumentNoteModel,
  INoteModel,
  NoteAssociation,
  SharedNoteModel,
} from '../../typings/note/note';

export interface INewNoteBody {
  type: NoteMessageType;
  text: string;
  eventDate: string;
  assignTo: NoteAssociation[];
  documentIds?: string[];
}

interface FetchNotesParamsModel extends FetchParamsModel {
  type?: NoteMessageType;
  sortBy?: string;
  sortDirection?: boolean;
}

export type NoteFromQuery = {
  _id: string;
  text: string;
  type: string;
  noteType: NoteType;
  documents: DocumentNoteModel[];
  sharedWith: SharedNoteModel[];
  creator: { _id: string; name: string };
  createdAt: string;
};

export interface FetchNotesForDashboardParamsModel extends FetchParamsModel {
  search?: string;
  itemType?: string;
  sortBy?: string;
  sortDirection?: boolean;
  fromDate?: string;
  toDate?: string;
  type?: string;
  createdBy?: string;
  itemId?: string;
}

const noteAPI = {
  createNote: (body: INewNoteBody) => {
    return APIService.post(APIConfig.notesApi, body).then(({ data }) => data);
  },

  fetchNotes: (id: string, params: FetchNotesParamsModel) => {
    return APIService.get<FetchResponseModel<INoteModel>>(
      `${APIConfig.notesApi}`,
      { params: { ...params, itemId: id } },
    ).then(({ data }) => data);
  },

  fetchNotesForDashboard: (params: FetchNotesForDashboardParamsModel) => {
    return APIService.get<FetchResponseModel<NoteFromQuery>>(
      `${APIConfig.notesApi}`,
      { params },
    ).then(({ data }) => data);
  },

  fetchNoteById: (id: string) => {
    return APIService.get<NoteFromQuery>(`${APIConfig.notesApi}/${id}`).then(
      ({ data }) => data,
    );
  },
};
export { noteAPI };
