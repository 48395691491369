import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';
import { CrmItemType } from '../../../../../../../enums/crm/crm';
import { NoteAssociation } from '../../../../../../../typings/note/note';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { StyledComponentProps } from '../../../../../../../typings/common';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../../constants/global';
import {
  CrmItemModel,
  relationshipAPI,
} from 'api/crm/relationship/relationshipAPI';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';
import { Typography } from 'antd';

interface IProps extends StyledComponentProps {
  name: string;
  value: NoteAssociation[];
  initialValue?: AutocompleteOption;
  onChange: (newValue: NoteAssociation[]) => void;
}

// TODO: refactor this component, to be able to use it as reusable components for other cases
// Currently, this component is developed, so it only works with type='multiple', and always requires value to be passed
// This way, it cannot be used for some other cases, except the form for new note
const CrmItemAutocomplete = ({
  initialValue,
  value,
  onChange,
  ...rest
}: IProps) => {
  const { t } = useTranslation('crm');

  const formattedFieldValue = useMemo(() => {
    let result: string[] = [];

    if (value.length) {
      result = value.map((e) => e.itemId);
    }

    return result;
  }, [value]);

  const mapCrmObject = (
    model: CrmItemModel,
  ): AutocompleteOption<NoteAssociation> => {
    return {
      id: model._id,
      label: (
        <>
          <Typography.Text>{model.name}</Typography.Text>
          <Typography.Text type="secondary">
            {' '}
            ({t(`association_types.${model.type}`)})
          </Typography.Text>
        </>
      ),
      model: { itemId: model._id, itemType: model.type as CrmItemType },
    };
  };

  const handleOnChange = (
    _: string,
    newValueModel: AutocompleteOption<NoteAssociation>[],
  ) => {
    const newValueFormatted = newValueModel.map((e) => e.model);
    onChange(newValueFormatted as NoteAssociation[]);
  };

  const fetchCrmItems = async (search: string) => {
    const response = await relationshipAPI.getCrmItems({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      search,
      includeClientGroups: true,
    });

    const result = response.result.map((crmItem) => {
      return mapCrmObject(crmItem);
    });

    return result;
  };

  return (
    <FormAutocomplete
      {...rest}
      value={formattedFieldValue}
      onChange={handleOnChange as any}
      fetchData={fetchCrmItems}
      initialValue={initialValue}
    />
  );
};

export default CrmItemAutocomplete;
