import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ClientApprovalEntryModel } from 'apiAdapters/approval/clientAccountManagementRulesAdapterAPI';

// components
import EntryDetails from './EntryDetails';
import SectionIntro from '@common_components/Texts/SectionIntro';

interface IProps {
  approvalEntry: ClientApprovalEntryModel;
}

const ApprovalEntryDetailsSection = ({ approvalEntry }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <>
      <SectionIntro
        titleVariant="h4"
        title={t(
          'client_group.administration_rules.pending_approvals.approval_details_modal.details_section.title',
        )}
      />
      <EntryDetails approvalEntry={approvalEntry} />
    </>
  );
};

export default ApprovalEntryDetailsSection;
