import { ActionType } from '../actions/settings';
import { IReduxAction } from '../../typings/common';
import { LocalizationHelpers } from '@helpers/localization';

// Models
export interface StateModel {
  language: string;
}

// Initial state
const initialState: StateModel = {
  language: LocalizationHelpers.getCurrentLanguage(),
};

// Reducer
const settings = (state = initialState, action: IReduxAction) => {
  const { type, payload, key } = action;

  switch (type) {
    case ActionType.SET_LANGUAGE:
      return {
        ...state,
        [key]: payload,
      };
    default:
      return state;
  }
};

export default settings;
