import React from 'react';

// ** Components **
import ComplianceWorkflowTemplate from './ComplianceWorkflowTemplate';
import ComplianceWorkflowStatus from './ComplianceWorkflowStatus';
import { ApprovalWorkflowInstanceModel } from '../../../../typings/approval/approvalWorkflow';

interface IProps {
  workflowId?: string;
  workflow?: ApprovalWorkflowInstanceModel;
  hasComplianceInfo: boolean;
  onUpdate: () => void;
}

const ComplianceWorkflow = ({
  workflowId,
  workflow,
  hasComplianceInfo,
  onUpdate,
}: IProps) => {
  return (
    <>
      {workflowId ? (
        <ComplianceWorkflowStatus
          workflowId={workflowId}
          workflow={workflow}
          hasComplianceInfo={hasComplianceInfo}
          onUpdate={onUpdate}
        />
      ) : (
        <ComplianceWorkflowTemplate />
      )}
    </>
  );
};

export default ComplianceWorkflow;
