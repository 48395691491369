import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { SelectOption } from '@core_components/Select';
import { IDocumentAssociationInfo } from 'modules/CRM/Documents/UploadDocumentsDialog';
import { AdditionalFieldType, RelationshipRelatedTypes } from 'enums/crm/crm';

// components
import Text from '@core_components/Text';
import Checkbox from '@core_components/Checkbox';
import FormInput from '@common_components/Form/FormInput';
import FormSelect from '@common_components/Form/FormSelect';
import DocumentLink from 'components/Typography/DocumentLink';
import DocumentsField from '../DocumentsField';
import FormDatePicker from '@common_components/Form/FormDatePicker';
import FormInputNumber from '@common_components/Form/FormInputNumber';

export interface IDocumentForm {
  creatorId: string;
  description: string;
  files: {
    id: string;
    name: string;
    size: number;
  }[];
  id: string;
}

export type AdditionalFieldFormItemModel = {
  id: string;
  name: string;
  value?: any;
  type: AdditionalFieldType;
  relatedTo: RelationshipRelatedTypes;
  isRemoved?: boolean;
  isNotProvidedReason?: boolean;
  notProvidedReason?: string;
  options: {
    onboardingRequired: boolean;
    isForRegulatedOnly: boolean;
    documentRequiresCertification: boolean;
    selectOptions: string[];
    formDocument: IDocumentForm | null;
  };
};

interface AdditionalFieldModel {
  _id: string;
  type: AdditionalFieldType;
  documentAssociation: IDocumentAssociationInfo;

  tags?: string[];
  options?: string[];
  formDocument?: IDocumentForm;
}

interface IProps {
  name: string;
  value: any;
  field: AdditionalFieldModel;
  onChange: (newValue?: any) => void;
  disabled?: boolean;
}

const AdditionalFieldNew = ({ field, ...rest }: IProps) => {
  const { t } = useTranslation('crm');

  const renderField = (type: AdditionalFieldType) => {
    switch (type) {
      case AdditionalFieldType.String: {
        return <FormInput {...rest} />;
      }

      case AdditionalFieldType.Date: {
        return <StyledFormDatePicker {...rest} />;
      }

      case AdditionalFieldType.Number:
      case AdditionalFieldType.Percentage: {
        return <StyledFormInputNumber {...rest} />;
      }

      case AdditionalFieldType.Select: {
        const { value } = rest;
        const optionNodes: SelectOption<string>[] =
          field.options && field.options.length
            ? field.options.map((e) => ({
                id: e,
                label: e,
                model: e,
              }))
            : rest.value
              ? [{ id: value, label: value, model: value }]
              : [];

        return <FormSelect {...rest} options={optionNodes} />;
      }

      case AdditionalFieldType.Document: {
        return (
          <DocumentsField
            {...rest}
            association={field.documentAssociation}
            tags={field.tags}
          />
        );
      }

      case AdditionalFieldType.Form: {
        return (
          <>
            <CurrentDocumentWrapper>
              {field.formDocument && (
                <>
                  <Text variant="body1">{`${t('documents.additional_field.current_document')}:`}</Text>
                  <StyledDocumentLink
                    documentId={field.formDocument.id}
                    fileId={field.formDocument.files[0].id}
                    text={field.formDocument.files[0].name}
                    fileName={field.formDocument.files[0].name}
                  />
                </>
              )}
            </CurrentDocumentWrapper>
            <DocumentsField
              {...rest}
              association={field.documentAssociation}
              tags={field.tags}
            />
          </>
        );
      }

      case AdditionalFieldType.Boolean: {
        return <Checkbox {...rest} />;
      }

      default:
        return null;
    }
  };

  return <>{renderField(field.type)}</>;
};

const StyledFormDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

const StyledFormInputNumber = styled(FormInputNumber)`
  width: 100%;
`;

const CurrentDocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  span {
    margin-right: ${({ theme }) => theme.marginXs};
  }
`;

const StyledDocumentLink = styled(DocumentLink)`
  display: block;
  width: max-content;
  margin: ${({ theme }) => theme.marginXs} 0;
`;

export default AdditionalFieldNew;
