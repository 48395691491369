import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { CrmItemType } from '../../../../../../../enums/crm/crm';
import { DocumentFieldValueModel } from 'components/Forms/FormComponents/DocumentsField';
import { AutocompleteInitialValue } from '../../../../../../Fields/Autocomplete';

// components
import InnerFormTable from './InnerFormTable';
import HideIfDisabledForm from '../../../../../../Forms/HideIfDisabledForm';
import { Button, Col, Row } from 'antd';
import { FieldArray, useField } from 'formik';

export interface JournalModel {
  entries: JournalEntryModel[];
}

export interface JournalEntryModel {
  id?: number;
  account?: {
    accountNumber: string;
    financialTypeName: string;
    isoCode: string;
  };
  isResident: boolean | null;
  relatedParty?: any;
  accountNumber: string;
  accountName?: string;
  creditAmount?: number;
  debitAmount?: number;
  debitHome?: number;
  creditHome?: number;
  currencyRate?: number;
  description: string;
  relatedPartyId?: string;
  relatedPartyType?: CrmItemType;
  relatedPartyInitialOption?: AutocompleteInitialValue;
  isVisibleForClient: boolean;
  journalPostingDate?: number;
  documents: DocumentFieldValueModel[];
}

interface IProps {
  fieldName: string;
  isEdit?: boolean;
  isEditActive?: boolean;
  displayPostingDate?: boolean;
}

const JournalForm = ({
  isEdit,
  fieldName,
  isEditActive,
  displayPostingDate,
}: IProps) => {
  const { t } = useTranslation('finance');
  const [field] = useField<JournalModel[]>(fieldName);

  const initialEntry: JournalEntryModel = useMemo(() => {
    return {
      description: '',
      accountNumber: '',
      isResident: null,
      isVisibleForClient: false,
      relatedPartyId: undefined,
      documents: [],
    };
  }, []);

  return (
    <FieldArray
      name={fieldName}
      render={(arrayHelper) => (
        <>
          {!!field.value.length && (
            <InnerFormTable
              displayPostingDate={!!displayPostingDate}
              disabled={isEdit}
              isEditMode={!!isEdit}
              isEditActive={!!isEditActive}
              fieldName={fieldName}
              handleDelete={arrayHelper.remove}
            />
          )}

          {!isEdit && (
            <HideIfDisabledForm>
              <StyledRow justify="end" gutter={[0, 20]}>
                {field.value.length < 50 && (
                  <Col>
                    <StyledButton
                      type="primary"
                      onClick={() => arrayHelper.push({ ...initialEntry })}
                    >
                      {t('journals.new.add_entry')}
                    </StyledButton>
                  </Col>
                )}
              </StyledRow>
            </HideIfDisabledForm>
          )}
        </>
      )}
    />
  );
};

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const StyledRow = styled(Row)`
  margin: 24px 0;
`;

export default JournalForm;
