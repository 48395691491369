import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { ExposureByCurrencyModel } from '../../../../../typings/reporting/reporting';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel<ExposureByCurrencyModel>;

const ExposureByCurrencyTable = (props: IProps) => {
  const { t } = useTranslation('reporting');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('exposure_by_currency.currency'),
        key: 'currency',
        render: (record: ExposureByCurrencyModel) =>
          t(`exposure_by_currency.currencies.${record.currency}`),
      },

      {
        title: t('exposure_by_currency.assets'),
        key: 'assets',
        render: (record: ExposureByCurrencyModel) =>
          AccountsHelpers.convertAmountBigIntToLocaleString(record.assets),
      },

      {
        title: t('exposure_by_currency.liabilities'),
        key: 'liabilities',
        render: (record: ExposureByCurrencyModel) =>
          AccountsHelpers.convertAmountBigIntToLocaleString(record.liabilities),
      },

      {
        title: t('exposure_by_currency.net_assets'),
        key: 'netAssets',
        render: (record: ExposureByCurrencyModel) =>
          AccountsHelpers.convertAmountBigIntToLocaleString(record.netAssets),
      },

      {
        title: t('exposure_by_currency.net_assets_usd'),
        key: 'netAssetsUSD',
        render: (record: ExposureByCurrencyModel) =>
          AccountsHelpers.convertAmountBigIntToLocaleString(
            record.netAssetsUSD,
          ),
      },
    ];

    return result;
  }, [t]);

  return <Table {...props} columns={columns} />;
};

export default ExposureByCurrencyTable;
