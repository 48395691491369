import React from 'react';

// helpers
import { AccountRecoveryEntryDocumentModel } from '../../../../../typings/ORAC/accountRecoveryRequests';

// components
import ImageCard from '../ImageCard';
import { Col, Row } from 'antd';

interface IProps {
  documentPhotos: AccountRecoveryEntryDocumentModel[];
}

const VerificationDocumentPhotosSection = ({ documentPhotos }: IProps) => {
  const renderPhotoCards = (photos: AccountRecoveryEntryDocumentModel[]) => {
    return photos.map((photo, i) => (
      <Col key={i} flex="250px">
        <ImageCard imageSrc={photo.src} />
      </Col>
    ));
  };

  return <Row gutter={[20, 20]}>{renderPhotoCards(documentPhotos)}</Row>;
};

export default VerificationDocumentPhotosSection;
