import React, { useState } from 'react';

// helpers
import moment, { Moment } from 'moment';
import useFetch from '../../../hooks/useFetch';
import { fiscalPeriodAPI } from '../../../api/finance/fiscalPeriod/fiscalPeriodAPI';

// components
import FilterBar from './FilterBar';
import { Divider } from 'antd';
import { default as TemplateFiscalPeriodTable } from '../../../components/Tables/TableTemplates/Finance/FiscalPeriodTable';

interface IProps {
  updateTrigger: any;
}

const FiscalPeriodTable = ({ updateTrigger }: IProps) => {
  const [filterDate, setFilterDate] = useState(moment());

  const handleFilterChange = (key: string, value: Moment) => {
    switch (key) {
      case 'date':
        setFilterDate(value);
        break;
    }
  };

  const { response, loading } = useFetch(
    () =>
      fiscalPeriodAPI.getFiscalPeriodData({
        calendarYear: filterDate.year(),
      }),
    [filterDate, updateTrigger],
  );

  return (
    <>
      <FilterBar
        date={filterDate}
        isDisabled={loading}
        filterChange={handleFilterChange}
      />

      <Divider />

      <TemplateFiscalPeriodTable data={response || []} loading={loading} />
    </>
  );
};

export default FiscalPeriodTable;
