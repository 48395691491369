import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { enumToMap } from '../../../../../../helpers/utils';
import { SelectProps } from 'antd/lib/select';
import { CountryRiskRating } from '../../../../../../typings/compliance/countryRiskRatings';

// components
import { Select } from 'antd';

type IProps = SelectProps<any>;

const CountryExpectedValueSelect = ({ value, ...rest }: IProps) => {
  const { t } = useTranslation('compliance');

  const renderOptions = () => {
    return Array.from(enumToMap(CountryRiskRating)).map((value) => (
      <Select.Option key={value[1]} value={value[1]}>
        {t(`country_risk_ratings.types.${value[0]}`)}
      </Select.Option>
    ));
  };

  return (
    <Select
      {...rest}
      value={value}
      mode="multiple"
      showSearch
      placeholder={t('review_configuration.expected_country_ph')}
    >
      {renderOptions()}
    </Select>
  );
};

export default CountryExpectedValueSelect;
