import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { AutocompleteInitialValue } from '../../../../Fields/Autocomplete';
import { UpdateCurrenciesOfCorrespondentBankValidationSchema } from '../../../../../validations/accounting/correspondentBanks';

// components
import InnerForm from './InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export interface FormValuesModel {
  currencies: number[];
  currenciesInitialValue: AutocompleteInitialValue[];
}

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {}

const UpdateTransactCurrencyModalDialog = ({
  closeCallback,
  isVisible,
  initialValues,
  onSubmit,
}: IProps) => {
  const { t } = useTranslation('finance');

  return (
    <Form<FormValuesModel>
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={isVisible ? initialValues : null}
      validationSchema={UpdateCurrenciesOfCorrespondentBankValidationSchema}
      confirmExitWithoutSaving
      renderForm={
        <ModalDialog
          width={700}
          title={t('currencies.update.title')}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default UpdateTransactCurrencyModalDialog;
