import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../../../../routes/routes';
import { AccountsHelpers } from '@helpers/finance/accounts';

// components
import { Typography } from 'antd';

export type ComplianceIncomingTransaction = {
  amlReviewProcessId: string;
  amlReviewType: string;
  transactionId: number;
  settledAmount: number;
  currencyIsoCode: string;
  orderingCustomerAccountName: string;
  beneficiaryCustomerAccountNumber: string;
  beneficiaryCustomerAccountName: string;
  senderToReceiverLine1: string;
};

interface IProps {
  complianceIncomingTransactionData: ComplianceIncomingTransaction;
}

const ComplianceIncomingTransactionDetails = memo(
  ({ complianceIncomingTransactionData }: IProps) => {
    const { t } = useTranslation('approval_management');
    const history = useHistory();

    return (
      <Typography.Link
        onClick={() =>
          history.push({
            pathname: RoutePaths.Compliance_Transactions_Details,
            search: `?id=${complianceIncomingTransactionData.amlReviewProcessId}`,
          })
        }
      >
        {t(
          'approval_workflow.approval_workflow_changes.compliance_incoming_transaction.description',
          {
            from: complianceIncomingTransactionData.orderingCustomerAccountName,
            toName:
              complianceIncomingTransactionData.beneficiaryCustomerAccountName,
            toNumber:
              complianceIncomingTransactionData.beneficiaryCustomerAccountNumber,
            line: complianceIncomingTransactionData.senderToReceiverLine1,
            amount: `${complianceIncomingTransactionData.currencyIsoCode} ${AccountsHelpers.formatAmountToLocaleString(
              complianceIncomingTransactionData.settledAmount,
            )}`,
          },
        )}
      </Typography.Link>
    );
  },
);

export default ComplianceIncomingTransactionDetails;
