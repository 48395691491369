import React from 'react';

//  helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { AdditionalFieldType } from '../../../../../../../../enums/crm/crm';
import { DEFAULT_DATE_FORMAT } from '../../../../../../../../constants/global';
import { ErrorMessage, useFormikContext } from 'formik';
import { AdditionalFieldFormItemModel, FormValuesModel } from '../../..';

// components
import Text from '@core_components/Text';
import Link from '@common_components/Texts/Link';
import FormField from '@core_components/FormField';
import DocumentLink from '../../../../../../../../components/Typography/DocumentLink';
import ValueChangedStatus from 'components/Forms/FormComponents/ValueChangedStatus';
import UpdateAdditionalField from './UpdateAdditionalField';
import RemoveAdditionalField from './RemoveAdditionalField';
import UpdateAnswerFromClient from './UpdateAnswerFromClient';
import CertificationRequiredLabel from '../../../../../../../../components/Typography/CertificationRequiredLabel';
import { Checkbox, Col, Input, Row, Typography } from 'antd';

interface IProps {
  fieldIndex: number;
  disabled?: boolean;
}

const AdditionalField = ({ fieldIndex, disabled }: IProps) => {
  const history = useHistory();
  const { t } = useTranslation(['crm', 'common']);
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();
  const additionalField = values.additionalFields[fieldIndex];

  const renderDocument = (document: any) => {
    return (
      <div>
        <DocumentLink
          key={document.id}
          text={document.files[0].name}
          documentId={document.id}
          fileId={document.files[0].id}
          fileName={document.files[0].name}
        />
      </div>
    );
  };

  const renderValue = (field: AdditionalFieldFormItemModel) => {
    if (!field.answer) {
      return (
        <>
          <StyledNotProvided>
            {t('not_provided', { ns: 'common' })}
          </StyledNotProvided>
          <br />
        </>
      );
    }

    if (!field.answer.isProvided) {
      return (
        <>
          <StyledNotProvided>
            {t('not_provided', { ns: 'common' })}:
          </StyledNotProvided>
          <br />
          <Typography.Text>{field.answer.reason}</Typography.Text>
          <br />
        </>
      );
    }

    let result = null;
    const formattedValue = field.answer?.valueJSON
      ? JSON.parse(field.answer.valueJSON)
      : null;

    switch (field.field.type) {
      case AdditionalFieldType.Document:
      case AdditionalFieldType.Form:
        result = formattedValue
          ? Array.isArray(formattedValue)
            ? formattedValue.map(renderDocument)
            : renderDocument(formattedValue)
          : '';
        break;

      case AdditionalFieldType.Boolean:
        result = formattedValue
          ? t('client_group.approval.yes')
          : t('client_group.approval.no');
        break;

      case AdditionalFieldType.Date:
        result = formattedValue
          ? moment(formattedValue).format(DEFAULT_DATE_FORMAT)
          : '';
        break;

      default:
        result = <Typography.Text>{formattedValue}</Typography.Text>;
    }

    return (
      <>
        {field.answer.providedBy ? (
          <StyledText>
            {t('client_group.approval.provided_by')}
            <StyledLink
              onClick={() =>
                history.push({
                  pathname: RoutePaths.ORAC_User_Details,
                  search: `?id=${field.answer?.providedBy?.id}`,
                })
              }
            >
              {field.answer.providedBy.name}
            </StyledLink>
          </StyledText>
        ) : null}
        <div>{result}</div>
      </>
    );
  };

  const handleApprovedChange = (isApproved: boolean) => {
    setFieldValue(`additionalFields.${fieldIndex}.isApproved`, isApproved);
    setFieldValue(`additionalFields.${fieldIndex}.isRejected`, !isApproved);
    setFieldValue(`additionalFields.${fieldIndex}.notReviewed`, false);
  };

  const handleNotReviewedChange = (notReviewed: boolean) => {
    setFieldValue(`additionalFields.${fieldIndex}.isApproved`, false);
    setFieldValue(`additionalFields.${fieldIndex}.isRejected`, false);
    setFieldValue(`additionalFields.${fieldIndex}.notReviewed`, notReviewed);
  };

  return (
    <Row gutter={[16, 0]}>
      <Col flex="400px">
        <StyledTitle>
          {additionalField.field.name}
          {!!additionalField.field.options.documentRequiresCertification && (
            <CertificationRequiredLabel />
          )}
        </StyledTitle>
        <StyledDescription>
          {additionalField.field.description}
        </StyledDescription>

        <StyledAnswer>
          <Text>
            {t('client_group.approval.answer')}
            <StyledValueChangedStatus
              name={`additionalFields.${fieldIndex}.answer.valueJSON`}
            />
          </Text>
          {renderValue(additionalField)}
          <UpdateAnswerFromClient
            fieldIndex={fieldIndex}
            additionalField={additionalField}
            reviewProcess={values.reviewProcess}
            selectedEntry={{ id: values.itemId, type: values.itemType }}
          />
        </StyledAnswer>
      </Col>

      <Col flex="auto">
        {values.additionalFields[fieldIndex].answer && (
          <>
            <Row align="middle">
              <Col>
                <FormField
                  name={`additionalFields.${fieldIndex}.notReviewed`}
                  shouldShowErrorMessage={false}
                  component={StyledCheckbox}
                  additionalProps={{
                    children: t('client_group.approval.not_reviewed'),
                  }}
                  handleOnChangeManually={() => handleNotReviewedChange(true)}
                  disabled={disabled}
                />
              </Col>
            </Row>
            <Row align="middle">
              <Col>
                <FormField
                  name={`additionalFields.${fieldIndex}.isApproved`}
                  shouldShowErrorMessage={false}
                  component={StyledCheckbox}
                  additionalProps={{
                    children: t('client_group.approval.valid'),
                  }}
                  handleOnChangeManually={() => handleApprovedChange(true)}
                  disabled={disabled}
                />
              </Col>
              <Col>
                <FormField
                  name={`additionalFields.${fieldIndex}.isRejected`}
                  shouldShowErrorMessage={false}
                  component={StyledCheckbox}
                  additionalProps={{
                    children: t('client_group.approval.invalid'),
                  }}
                  handleOnChangeManually={() => handleApprovedChange(false)}
                  disabled={disabled}
                />
              </Col>
              <Col>
                <StyledErrorMessage>
                  <ErrorMessage
                    name={`additionalFields.${fieldIndex}.isApproved`}
                  />
                </StyledErrorMessage>
              </Col>
            </Row>
            {(additionalField.isApproved || additionalField.isRejected) && (
              <StyledTypographyText isValid={!!additionalField.isApproved}>
                {additionalField.isApproved
                  ? t('client_group.approval.valid_document_info')
                  : t('client_group.approval.invalid_document_info')}
              </StyledTypographyText>
            )}
            {!additionalField.notReviewed && (
              <FormField
                name={`additionalFields.${fieldIndex}.reviewComment`}
                component={StyledInput}
                placeholder={t('client_group.approval.provide_comments')}
                disabled={!!additionalField.isApproved || disabled}
              />
            )}
          </>
        )}
      </Col>

      {additionalField.requestedByAdmin && !disabled && (
        <Col flex="80px">
          <StyledActionsRow justify="space-between" align="middle">
            <Col>
              <UpdateAdditionalField fieldIndex={fieldIndex} />
            </Col>
            <Col>
              <RemoveAdditionalField fieldIndex={fieldIndex} />
            </Col>
          </StyledActionsRow>
        </Col>
      )}
    </Row>
  );
};

const StyledTypographyText = styled(Typography.Text)<{ isValid: boolean }>`
  color: ${({ theme, isValid }) =>
    isValid ? theme.primaryGreen : theme.warningColor};
  padding-top: ${({ theme }) => theme.paddingSm};
`;

const StyledTitle = styled(Typography.Text)`
  display: block;
  color: ${({ theme }) => theme.textLightColor2};
  padding-top: ${({ theme }) => theme.paddingSm};
  font-size: ${({ theme }) => theme.fontSizeSm};
`;

const StyledDescription = styled(Typography.Text)`
  display: block;
  color: ${({ theme }) => theme.textLightColor3};
  min-height: 20px;
`;

const StyledAnswer = styled.div`
  display: block;
  padding-top: ${({ theme }) => theme.paddingXs};
  padding-bottom: ${({ theme }) => theme.paddingSm};
`;

const StyledNotProvided = styled(Typography.Text)`
  color: ${({ theme }) => theme.textLightColor3};
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

const StyledInput = styled(Input)`
  margin-top: ${({ theme }) => theme.marginXs};
`;

const StyledErrorMessage = styled.span`
  color: ${({ theme }) => theme.errorColor};
`;

const StyledActionsRow = styled(Row)`
  height: 100%;
  padding-top: ${({ theme }) => theme.paddingSm};
`;

const StyledText = styled(Text)`
  margin-bottom: 0px !important;
`;

const StyledLink = styled(Link)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

const StyledValueChangedStatus = styled(ValueChangedStatus)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default AdditionalField;
