import React, { useCallback, useState } from 'react';

// helpers
import { debounce } from 'lodash';
import { documentsAPI } from '../../../../../api/documents/documentsAPI';
import { AutocompleteOption } from '../../../../Fields/Autocomplete';
import { AUTOCOMPLETE_DEBOUNCE_DELAY } from 'constants/global';

// components
import { Select, SelectProps } from 'antd';

type IProps = SelectProps<any>;

const TagsSelect = (props: IProps) => {
  const [tagsData, setTagsData] = useState<any>([]);

  const fetchTags = async (searchQuery: string) => {
    const { data } = await documentsAPI.fetchTags(searchQuery);
    const mappedData = data.map<AutocompleteOption>((tag) => {
      return {
        id: tag.name,
        content: tag.name,
        model: tag,
      };
    });
    setTagsData(mappedData);
  };

  const renderOptions = () => {
    return tagsData.map((tag: any) => (
      <Select.Option key={tag.content} value={tag.content}>
        {tag.content}
      </Select.Option>
    ));
  };

  const debouncedFetchData = useCallback(
    debounce(fetchTags, AUTOCOMPLETE_DEBOUNCE_DELAY),
    [],
  );

  const handleChange = (e: string) => {
    debouncedFetchData(e);
  };

  return (
    <Select {...props} onSearch={handleChange} mode="multiple">
      {renderOptions()}
    </Select>
  );
};

export default TagsSelect;
