import React from 'react';

// components
import Text from '@core_components/Text';
import { Skeleton, Space } from 'antd';

interface IProps {
  title: string;
  content: any;
  loading?: boolean;
}

const DescriptionItem = ({ title, content, loading }: IProps) => (
  <Skeleton loading={loading} active paragraph>
    <Space direction="horizontal" align="start">
      <Text variant="body1" weight="semi-bold">
        {title}:
      </Text>
      <Text variant="body1">
        {typeof content == 'function' ? content() : content}
      </Text>
    </Space>
  </Skeleton>
);

export default DescriptionItem;
