import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { RDCAlertInfoModel } from '../../../../../typings/compliance/reviewProcess';

// components
import JSONInTree from '../../../../Additional/JSONInTree';
import ModalDialog from '@core_components/ModalDialog';
import { Card } from 'antd';

interface IProps {
  alert: RDCAlertInfoModel | null;
  isVisible: boolean;
  closeCallback: (wasCreated?: boolean) => void;
}

const RDCTransactionModal = ({ isVisible, alert, closeCallback }: IProps) => {
  const { t } = useTranslation('compliance');

  return (
    <ModalDialog
      hideFooterButtons
      title={t('rdc_transaction_view_modal.title')}
      isVisible={isVisible}
      closeCallback={closeCallback}
    >
      {alert && (
        <Card>
          <JSONInTree json={alert.alertJSON} />
        </Card>
      )}
    </ModalDialog>
  );
};

export default RDCTransactionModal;
