import React from 'react';

// helpers
import { ClientStatus } from 'enums/crm/crm';
import { IClientGroup } from 'typings/crm/client-group';

// components
import Onboarding from './Onboarding';
import PostOnboarding from './PostOnboarding';

interface IProps {
  clientGroup: IClientGroup;
}

const AdministrationRulesTab = ({ clientGroup }: IProps) => {
  return [ClientStatus.Onboarding, ClientStatus.InReview].includes(
    clientGroup.status,
  ) ? (
    <Onboarding clientGroupNumericId={clientGroup.numericId} />
  ) : (
    <PostOnboarding clientGroup={clientGroup} />
  );
};

export default AdministrationRulesTab;
