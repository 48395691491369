import React, { Key, useEffect, useMemo } from 'react';

// helpers
import { darkTheme } from '@resources/theme/styled';
import { OnboardType } from 'enums/crm/crm';
import { GraphData, GraphNode } from 'components/Charts/GraphChart';

// components
import IconSVG from '@core_components/IconSVG';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import ReviewStatusIcon from 'components/Additional/Crm/ReviewStatusIcon';
import Tree, { DataNode } from 'antd/lib/tree';
import { ReactComponent as SharedDataIcon } from 'resources/icons/remix-icons/share-line.svg';

interface IProps {
  graphData: GraphData | null;
  selectedNode: GraphNode | null;
  onNodeSelect: (node: GraphNode | null) => void;
}

const ClientGroupNodesTree = ({
  graphData,
  selectedNode,
  onNodeSelect,
}: IProps) => {
  const renderTitle = (node: GraphNode) => {
    const sharedDataIcon = node.onboardingProcess?.isShared ? (
      <IconSVG
        component={SharedDataIcon}
        size="small"
        color={darkTheme.primaryGreen}
      />
    ) : null;

    const isContactWithOnboardingProcess =
      node.entryType == OnboardType.Contact && !!node.onboardingProcess;

    return (
      <DivAlignCenter>
        <EllipsisTooltip maxTextContainerWidth="200px" title={node.label}>
          {node.label}
        </EllipsisTooltip>
        &nbsp;
        {sharedDataIcon}
        {node.onboardingProcess ? (
          <ReviewStatusIcon
            status={
              isContactWithOnboardingProcess
                ? [
                    node.onboardingProcess.review.applicationDocumentation
                      .status,
                    node.onboardingProcess.review.identification.status,
                  ]
                : node.onboardingProcess.review.applicationDocumentation.status
            }
          />
        ) : null}
      </DivAlignCenter>
    );
  };

  const handleTreeItemSelect = (selectedKeys: Key[]) => {
    if (!selectedKeys.length) {
      onNodeSelect(null);
    }
    const newSelectedNode = graphData?.nodes.find(
      (e) => e.id == selectedKeys[0],
    );
    onNodeSelect(newSelectedNode || null);
  };

  const treeData: DataNode[] = useMemo(() => {
    if (!graphData) {
      return [];
    }

    const { nodes } = graphData;
    const applicantNode = nodes.find((e) => e.isApplicant);

    if (!applicantNode) {
      return [];
    }

    return [
      {
        key: applicantNode.id,
        title: renderTitle(applicantNode),
        node: applicantNode,
        children: nodes.reduce<DataNode[]>(
          (acc: DataNode[], next: GraphNode) => {
            if (!next.isApplicant) {
              acc.push({
                key: next.id,
                node: next,
                title: renderTitle(next),
              } as DataNode);
            }

            return acc;
          },
          [],
        ),
      },
    ];
  }, [graphData]);

  // Initial node select
  useEffect(() => {
    if (!selectedNode && treeData.length) {
      handleTreeItemSelect([treeData[0].key]);
    }
  }, [treeData, selectedNode]);

  return (
    <Tree
      defaultExpandAll
      treeData={treeData}
      selectedKeys={[selectedNode?.id || '']}
      onSelect={handleTreeItemSelect}
    />
  );
};

export default ClientGroupNodesTree;
