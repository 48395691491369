import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../hooks/useFetch';
import useTranslation from '../../../hooks/useTranslation';
import moment, { Moment } from 'moment';
import { reportingAPI } from '../../../api/reporting/reportingAPI';

// components
import FilterBar from './FilterBar';
import ReportTree from '../../../components/Additional/ReportTree';
import LoadingWrapper from '../../../components/WrapperComponents/LoadingWrapper';
import { Divider } from 'antd';

const ClientGroupAndRevenueReport = () => {
  const { t } = useTranslation('reporting');

  const [dateRange, setDateRange] = useState<[Moment, Moment]>([
    moment().add(-7, 'M'),
    moment(),
  ]);

  const { response, loading } = useFetch(
    () =>
      reportingAPI.fetchClientGroupRevenueReport(
        dateRange[0].startOf('d').format('X'),
        dateRange[1].endOf('d').format('X'),
      ),
    [dateRange],
  );

  const handleFilterChange = (key: string, value: any) => {
    switch (key) {
      case 'startDate':
        setDateRange([value, dateRange[1]]);
        break;
      case 'endDate':
        setDateRange([dateRange[0], value]);
        break;
    }
  };

  return (
    <>
      <FilterBar
        dateRangeValue={dateRange}
        isDisabled={loading}
        filterChange={handleFilterChange}
      />

      <Divider />

      <LoadingWrapper loading={loading}>
        {response && response.data.length ? (
          <ReportTree displayResidentHeader={false} report={response.data} />
        ) : (
          <StyledEmpty>{t('no_data')}</StyledEmpty>
        )}
      </LoadingWrapper>
    </>
  );
};

const StyledEmpty = styled.div`
  font-size: 18px;
  text-align: center;
`;

export default ClientGroupAndRevenueReport;
