import React, { useEffect } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from '../../../layouts/PageLayout';
import { GENESIS_COMPLIANCE_TRANSACTIONS_DOCUMENTATION_LINK } from '../../../constants/externalLinks';

// components
import TooltipToDocumentation from '../../../components/Tooltips/TooltipToDocumentation';
import { default as TransactionsContent } from '../../../modules/Compliance/TransactionsTable';

type IProps = PageLayoutHelperPropsModel;

const TransactionsPage = ({ setHeaderOptions }: IProps) => {
  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation
          link={GENESIS_COMPLIANCE_TRANSACTIONS_DOCUMENTATION_LINK}
        />
      ),
    });
  }, []);
  return <TransactionsContent />;
};

export default TransactionsPage;
