import ApiConfig from '../../config/api';
import { UserTypes } from '../../enums/admin/orac';
import { NewUserModel } from '../../typings/ORAC/users';
import { ICreateUserForm } from '../../modules/ORAC/Users/AddUser/InnerForm';

const usersAdapter = {
  createUser: (formValues: ICreateUserForm): NewUserModel => {
    return {
      isAdmin: formValues.type === UserTypes.Admin,
      email: formValues.email?.trim() || '',
      phoneNumber: formValues.phoneNumber?.trim() || '',
      contactId: formValues.contactId as string,
      callbackUrl: `${ApiConfig.clientUrl}/auth/activate-account`,
      parameterName: 'token',
    };
  },
};

export default usersAdapter;
