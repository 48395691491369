import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../../../hooks/useFetch';
import transactionsAPI from 'api/compliance/transactionsAPI';
import { IContactModel } from 'typings/crm/contact';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { ComplianceReviewTypes } from 'enums/compliance/reviewConfiguration';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ReviewProcessDetails from './ReviewProcessDetails';
import RDCReviewProcessesTable from 'components/Tables/TableTemplates/Compliance/RDCReviewProcessesTable';

interface IProps {
  contact: IContactModel;
}

const RDC = ({ contact }: IProps) => {
  const [page, setPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      transactionsAPI.fetchReviewProcesses({
        types: ComplianceReviewTypes.KYCContact,
        objectIds: contact._id,
        page,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [contact, page],
  );

  return (
    <LoadingWrapper loading={loading}>
      <RDCReviewProcessesTable
        data={response?.data || []}
        current={page}
        total={response?.total || 0}
        loading={loading}
        onPaginationChange={setPage}
        renderExpandableRow={(record) => (
          <ReviewProcessDetails record={record} />
        )}
      />
    </LoadingWrapper>
  );
};

export default RDC;
