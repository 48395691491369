import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { ApprovalGroupModel } from '../../../../../typings/approval/templates';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<ApprovalGroupModel> {
  hasEditAccess?: boolean;
  onActionsClick: (key: string, id: string) => void;
}

const ApprovalGroupsTable = memo(
  ({ onActionsClick, hasEditAccess, ...rest }: IProps) => {
    const { t } = useTranslation('approval_management');

    const renderGroupName = ({ id, name }: ApprovalGroupModel) => {
      let result = null;

      if (hasEditAccess) {
        result = (
          <Typography.Link onClick={() => onActionsClick('edit', id)}>
            {name}
          </Typography.Link>
        );
      } else {
        result = <Typography.Text>{name}</Typography.Text>;
      }

      return result;
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          title: t('approval_management.approval_groups.name'),
          key: 'name',
          width: '30%',
          render: renderGroupName,
        },
        {
          title: t('approval_management.approval_groups.description'),
          width: '30%',
          key: 'description',
          render: (record: ApprovalGroupModel) => record.description,
        },
        {
          title: t('approval_management.approval_groups.participants'),
          width: '30%',
          key: 'participants',
          render: (record: ApprovalGroupModel) =>
            record.participants.map((e) => e.userFullName).join(', '),
        },
      ];

      return result;
    }, [hasEditAccess, onActionsClick]);

    return <Table {...rest} columns={columns} />;
  },
);

export default ApprovalGroupsTable;
