import React from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { StateModel } from '../../../../redux/reducers';
import { uploadFiles } from '../../../../redux/actions/upload';
import { UploadStatus } from '../../../../constants/documents';
import { FormikHelpers } from 'formik';
import { UploadFormValuesModel } from '../../../../typings/documents/documents';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentAssociationTypes } from '../../../../enums/crm/crm';
import { UploadDocumentsValidationSchema } from '../../../../validations/dms';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import UploadFilesInProgress from './UploadFilesInProgress';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  association?: IDocumentAssociationInfo;
  showExitWithoutSavingModal?: boolean;
  relatedToFieldName?: string;
  allowedAssociationTypes?: DocumentAssociationTypes[];
}

export interface IDocumentAssociationInfo {
  id: string;
  type: DocumentAssociationTypes;
}

const UploadDocumentsDialog = ({
  isVisible,
  association,
  closeCallback,
  relatedToFieldName,
  allowedAssociationTypes,
  showExitWithoutSavingModal = true,
}: IProps) => {
  const { t } = useTranslation('crm');
  const dispatch = useDispatch();
  const uploadStatus = useSelector<StateModel, UploadStatus>(
    (state) => state.upload.status,
  );

  const handleSubmit = (
    values: UploadFormValuesModel,
    formHelpers: FormikHelpers<any>,
  ) => {
    dispatch(uploadFiles([...values.files], association, relatedToFieldName));
    formHelpers.resetForm({ values: { files: [] } });
  };

  const getInitialValues = () => ({ files: [] });

  const isUploadActive = () => uploadStatus === UploadStatus.ACTIVE;

  return (
    <Form<UploadFormValuesModel>
      onSubmit={handleSubmit}
      enableReinitialize
      confirmExitWithoutSaving={showExitWithoutSavingModal}
      validationSchema={UploadDocumentsValidationSchema}
      initialValues={getInitialValues()}
      renderForm={
        <ModalDialog
          width="65%"
          title={t('documents.uploadDocuments.title')}
          isVisible={isVisible}
          closeCallback={closeCallback}
          cancelButtonProps={{ hidden: isUploadActive() }}
          submitButtonProps={{
            hidden: isUploadActive(),
            text: t('documents.uploadDocuments.upload'),
          }}
        >
          {isUploadActive() ? (
            <UploadFilesInProgress />
          ) : (
            <InnerForm allowedAssociationTypes={allowedAssociationTypes} />
          )}
        </ModalDialog>
      }
    />
  );
};

export default UploadDocumentsDialog;
