import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { darkTheme } from '../../../../../../resources/theme/styled';
import { RoutePaths } from '../../../../../../routes/routes';
import { ContactHelpers } from '@helpers/crm/contact';
import {
  IClientGroup,
  IClientGroupUser,
} from '../../../../../../typings/crm/client-group';

// components
import Link from '../../../../../Typography/Link';
import InfoTooltip from '../../../../../Tooltips/InfoTooltip';
import StatusLabel from '../../../../../Typography/StatusLabel';
import CRMUserStatus from 'components/Additional/Statuses/CRMUserStatus';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

interface IProps extends RequiredPropsForTableModel<IClientGroupUser> {
  clientGroup: IClientGroup;
}

const UsersTable = memo(({ clientGroup, ...rest }: IProps) => {
  const { t } = useTranslation('crm');

  const renderAccountPermissions = (user: IClientGroupUser) => {
    let isModified = false;
    let permission = user.permissionType;

    if (
      user.toUpdate &&
      !user.toUpdate.deactivate &&
      user.toUpdate.permissionType !== user.permissionType
    ) {
      isModified = true;
      permission = user.toUpdate.permissionType;
    }

    return (
      <>
        <StatusLabel
          status={isModified ? 'warning' : ''}
          text={t(`client_group.account_permissions.${permission}`)}
        />
        <StyledTooltip
          color={darkTheme.whiteColor}
          tooltipContent={t(
            `client_group.account_permission_tooltips.${permission}`,
          )}
        />
      </>
    );
  };

  const renderAccountAdmin = (user: IClientGroupUser) => {
    let isModified = false;
    let administrationPermission = user.adminPermissionType;

    if (
      user.toUpdate &&
      !user.toUpdate.deactivate &&
      user.toUpdate.adminPermissionType !== user.adminPermissionType
    ) {
      isModified = true;
      administrationPermission = user.toUpdate.adminPermissionType;
    }

    return (
      <StatusLabel
        status={isModified ? 'warning' : ''}
        text={t(
          `client_group.administration_permissions.${administrationPermission}`,
        )}
      />
    );
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('client_group.users.client'),
        key: 'client',
        render: (record: IClientGroupUser) => (
          <Link
            to={`${RoutePaths.CRM_Client_Group_Client}?id=${record.contactId}&clientGroupId=${clientGroup._id}&clientGroupNumericId=${clientGroup.numericId}`}
          >
            {ContactHelpers.getContactName(record.contact)}
          </Link>
        ),
      },
      {
        title: t('client_group.users.username'),
        key: 'username',
        render: (record: IClientGroupUser) => record.contact.username || '-',
      },
      {
        title: t('client_group.users.email'),
        key: 'email',
        render: (record: IClientGroupUser) =>
          record.contact.emails.find(({ isPrimary }) => isPrimary)?.address ||
          '-',
      },
      {
        title: t('client_group.users.phone'),
        key: 'phone',
        render: (record: IClientGroupUser) =>
          record.contact.phoneNumbers.find(({ isPrimary }) => isPrimary)
            ?.number || '-',
      },
      {
        title: t('client_group.users.status'),
        key: 'status',
        width: 250,
        render: (record: IClientGroupUser) => (
          <CRMUserStatus status={record.status.name} />
        ),
      },
      {
        title: t('client_group.users.account_permissions'),
        key: 'accountPermission',
        width: 250,
        render: renderAccountPermissions,
      },
      {
        title: (
          <DivAlignCenter>
            {t('client_group.users.account_admin')}
            <StyledTooltip
              color={darkTheme.whiteColor}
              tooltipContent={t('client_group.account_admin_tooltip')}
            />
          </DivAlignCenter>
        ),
        key: 'adminPermissionType',
        width: 200,
        render: renderAccountAdmin,
      },
    ];

    return result;
  }, [clientGroup]);

  return <Table columns={columns} {...rest} />;
});

const StyledTooltip = styled(InfoTooltip)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default UsersTable;
