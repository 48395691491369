// helpers
import i18n from '../i18n';
import StoreHelper from '../redux/StoreHelper';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Modal } from 'antd';
import { SessionHelpers } from '@helpers/sessions';
import { LocalStorageHelpers } from '@helpers/storages/localStorage';
import { SessionStorageHelper } from '@helpers/storages/sessionStorage';

// constants
import { REQ_TIMEOUT } from '../constants/global';

// actions
import { signOut } from '../redux/actions/auth';
import { setLastActivityTimestamp } from '../redux/actions/app';

export const GEOLOCATION_HEADER = 'Geolocation';
export const FINGERPRINT_HEADER = 'Fingerprint';
export const AUTHORIZATION_HEADER = 'Authorization';

interface ConfigModel extends AxiosRequestConfig {
  // object with the keys and values that need's to be added into path
  // Important: note that the key in object and the key in path should be fully the same
  // Example:
  // PATH: https://apim-pofs-stg.azure-api.net/authbeta/app/me/:userId
  // Endpoint params: { userId: '123456789' }
  // Final path: https://apim-pofs-stg.azure-api.net/authbeta/app/me/123456789
  endpointParams?: EndpointParamModel;
  skipTimestampTracking?: boolean;
  addFingerprintToHeaders?: boolean;
  addGeolocationDataToHeaders?: boolean;
}

interface ErrorResponseModel {
  code: string;
  message: string;
  details: any;
}

export interface ApiErrorResponseModel
  extends AxiosError<ErrorResponseModel & { error?: ErrorResponseModel }> {
  config: ConfigModel;
}

interface EndpointParamModel {
  [key: string]: string | number;
}

export const UNAUTHORIZED_STATUS = 401;
export const TOO_MANY_REQUESTS_STATUS = 429;

// ** Main axios instance **
const instance = axios.create({
  timeout: REQ_TIMEOUT,
});

// **  Request interceptor **
// TODO: handle empty path
instance.interceptors.request.use(async function (config: ConfigModel) {
  if (!config.url) {
    throw new axios.Cancel('Operation canceled by the user.');
  }

  const token = LocalStorageHelpers.getAccessToken();

  if (config.addFingerprintToHeaders || token) {
    config.headers.common.fingerprint =
      await SessionHelpers.generateFingerprint();
  }

  if (token) {
    config.headers.common[AUTHORIZATION_HEADER] = `Bearer ${token}`;

    if (!config.skipTimestampTracking) {
      StoreHelper.dispatch(setLastActivityTimestamp(Date.now()));
    }
  }

  if (config.addGeolocationDataToHeaders) {
    const userSessionFromStorage = SessionStorageHelper.getUserSession();

    if (!userSessionFromStorage || !userSessionFromStorage.geolocation) {
      const geolocationData = await SessionHelpers.getUserGeolocation();

      if (geolocationData) {
        if (geolocationData?.status !== 'granted' || !geolocationData.data) {
          throw new axios.Cancel(
            JSON.stringify({
              type: 'geolocation_not_enabled',
            }),
          );
        } else {
          SessionStorageHelper.setUserSession({
            geolocation: { ...geolocationData.data },
          });
          config.headers.common[GEOLOCATION_HEADER] = JSON.stringify(
            geolocationData.data,
          );
        }
      }
    } else {
      config.headers.common[GEOLOCATION_HEADER] = JSON.stringify(
        userSessionFromStorage.geolocation,
      );
    }
  }

  return config;
});

instance.interceptors.response.use(
  (response) => response,
  async (error: ApiErrorResponseModel) => {
    if (axios.isCancel(error)) {
      const cancelData = JSON.parse(error.message);
      if (cancelData.type === 'geolocation_not_enabled') {
        Modal.info({
          icon: '',
          width: 650,
          closable: true,
          content: i18n.t('error.geolocation_is_not_enabled', {
            ns: 'server_errors',
          }),
          okText: i18n.t('ok', { ns: 'common' }),
        });
      } else {
        // Handle other cancel cases
      }
    } else {
      const { status } = error.response || {};

      switch (status) {
        case UNAUTHORIZED_STATUS:
          StoreHelper.dispatch(signOut(true));
          break;

        default: {
          throw error;
        }
      }
    }
  },
);

interface APIModel {
  get<T = any, R = AxiosResponse<T>>(
    endpointKey: string,
    config?: ConfigModel | null,
  ): Promise<R>;

  post<T = any, R = AxiosResponse<T>>(
    endpointKey: string,
    data?: any,
    config?: ConfigModel | null,
  ): Promise<R>;

  put<T = any, R = AxiosResponse<T>>(
    endpointKey: string,
    data?: any,
    config?: ConfigModel | null,
  ): Promise<R>;

  delete<T = any, R = AxiosResponse<T>>(
    endpointKey: string,
    config?: ConfigModel | null,
  ): Promise<R>;

  patch<T = any, R = AxiosResponse<T>>(
    endpointKey: string,
    data?: any,
    config?: ConfigModel | null,
  ): Promise<R>;
}

// ** Main API instance for all requests **
export const APIService: APIModel = {
  get(path, config) {
    return instance.get(path, config || undefined);
  },

  post(path, data, config) {
    return instance.post(path, data, config || undefined);
  },

  put(path, data, config) {
    return instance.put(path, data, config || undefined);
  },

  delete(path, config) {
    return instance.delete(path, config || undefined);
  },

  patch(path, data, config) {
    return instance.patch(path, data, config || undefined);
  },
};

export default instance;
