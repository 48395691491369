import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import { Button, Typography } from 'antd';
import { ReactComponent as KeyIcon } from '../../../../../../resources/images/icons/key.svg';

interface IProps {
  onNextClick: () => void;
}

const InitialStep = ({ onNextClick }: IProps) => {
  const { t } = useTranslation(['profile', 'common']);

  return (
    <>
      <StyledKeyIcon />
      <StyledParagraph>
        {t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_modal.initial_step.description',
        )}
      </StyledParagraph>
      <StyledButton size="large" type="primary" onClick={onNextClick}>
        {t('next', { ns: 'common' })}
      </StyledButton>
    </>
  );
};

const StyledKeyIcon = styled(KeyIcon)`
  width: 100px;
  height: 100px;
  margin-bottom: ${({ theme }) => theme.marginXl};
`;

const StyledParagraph = styled(Typography.Paragraph)`
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default InitialStep;
