import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { useField } from 'formik';
import { TWO_COL_GRID_SIZES } from 'constants/grids';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ContactAddressType, OnboardType } from 'enums/crm/crm';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import DeleteButton from '@common_components/Buttons/DeleteButton';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import PrimaryStatusText from 'components/Typography/PrimaryStatusText';
import CountryFormSelect from 'components/Forms/FormComponents/SelectInputs/CountryFormSelect';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import ValueChangedStatus from 'components/Forms/FormComponents/ValueChangedStatus';
import EntityAddressTypeFormSelect from 'components/Forms/FormComponents/SelectInputs/EntityAddressTypeFormSelect';
import ContactAddressTypeFormSelect from 'components/Forms/FormComponents/SelectInputs/ContactAddressTypeFormSelect';
import Checkbox, { CheckboxProps } from '@core_components/Checkbox';
import DocumentsField, {
  DocumentFieldValueModel,
} from 'components/Forms/FormComponents/DocumentsField';
import { Card, Col, Row } from 'antd';

export type AddressItemFormValue = {
  id: string;
  country: string;
  city: string;
  street: string;
  state: string;
  isPrimary?: boolean;
  postal: string;
  type: ContactAddressType[];
  documents?: DocumentFieldValueModel[];
  // Needs investigation for [isApproved] and [isCreated] fields
  isApproved: boolean | null;
  isCreated: boolean | null;
};

interface IProps {
  index: number;
  baseName: string;
  entryType: OnboardType;
  isPrimary: boolean;
  disabled?: boolean;
  onPrimaryChange?: (newPrimaryAddressId: string | null) => void;
  onRemove?: () => void;
}

const AddressForm = memo(
  ({
    isPrimary,
    onPrimaryChange,
    baseName,
    onRemove,
    index,
    entryType,
    disabled,
  }: IProps) => {
    const { t } = useTranslation('crm');
    const [field] = useField<AddressItemFormValue>(baseName);

    const AddressTypeComponent = useMemo(() => {
      if (entryType === OnboardType.Contact) {
        return ContactAddressTypeFormSelect;
      } else if (entryType === OnboardType.Organization) {
        return EntityAddressTypeFormSelect;
      } else {
        return null;
      }
    }, [entryType]);

    const handlePrimaryCheck = onPrimaryChange
      ? (e: CheckboxChangeEvent) => {
          if (onPrimaryChange) {
            if (e.target.checked) {
              onPrimaryChange(field.value.id);
            } else {
              onPrimaryChange(null);
            }
          }
        }
      : null;

    return (
      <StyledCard
        title={t('entity.new.addressTitle', { index })}
        extra={
          <DivAlignCenter>
            {isPrimary ? (
              <PrimaryStatusText />
            ) : onRemove && !field.value.isApproved && !disabled ? (
              <HideIfDisabledForm>
                <DeleteButton onClick={() => onRemove && onRemove()} />
              </HideIfDisabledForm>
            ) : null}
          </DivAlignCenter>
        }
      >
        <Row gutter={[16, 0]}>
          <Col {...TWO_COL_GRID_SIZES}>
            <FormField
              label={t('entity.new.addressType')}
              name={`${baseName}.type`}
              component={AddressTypeComponent}
              additionalProps={{ mode: 'multiple' }}
              disabled={field.value.isApproved || disabled}
            />
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col {...TWO_COL_GRID_SIZES}>
            <FormField
              label={t('entity.new.country')}
              name={`${baseName}.country`}
              component={CountryFormSelect}
              disabled={field.value.isApproved || disabled}
            />
          </Col>

          <Col {...TWO_COL_GRID_SIZES}>
            <FormField
              label={t('entity.new.city')}
              name={`${baseName}.city`}
              component={FormInput}
              disabled={field.value.isApproved || disabled}
            />
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col {...TWO_COL_GRID_SIZES}>
            <FormField
              label={t('entity.new.street')}
              name={`${baseName}.street`}
              component={FormInput}
              disabled={field.value.isApproved || disabled}
            />
          </Col>

          <Col {...TWO_COL_GRID_SIZES}>
            <Row gutter={[16, 0]}>
              <Col xl={16} lg={16} md={24} sm={24} xs={24}>
                <FormField
                  label={`${t('entity.new.state')} / ${t('entity.new.region')}`}
                  name={`${baseName}.state`}
                  component={FormInput}
                  disabled={field.value.isApproved || disabled}
                />
              </Col>

              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <FormField
                  label={t('entity.new.zipCode')}
                  name={`${baseName}.postal`}
                  component={FormInput}
                  disabled={field.value.isApproved || disabled}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {field.value.type?.includes(ContactAddressType.PermanentAddress) && (
          <Col {...TWO_COL_GRID_SIZES}>
            <FormField
              label={
                <>
                  {t('entity.new.addresses_documents')}
                  <ValueChangedStatus name={`${baseName}.documents`} />
                </>
              }
              name={`${baseName}.documents`}
              component={DocumentsField}
              disabled={disabled}
            />
          </Col>
        )}

        {handlePrimaryCheck && (
          <Row>
            <Col {...TWO_COL_GRID_SIZES}>
              <FormField<CheckboxProps>
                label={t('contacts.new.isPrimary')}
                name={`${baseName}.isPrimary`}
                component={Checkbox}
                disabled={disabled}
                additionalProps={{
                  onChange: handlePrimaryCheck,
                  value: isPrimary,
                }}
              />
            </Col>
          </Row>
        )}
      </StyledCard>
    );
  },
);

const StyledCard = styled(Card)`
  margin: ${({ theme }) => theme.marginMd} 0;
  .ant-card-head {
    border-bottom: 1px solid ${({ theme }) => theme.cardBorderColor};
  }

  .ant-card-extra {
    text-transform: none;
  }
`;

export default AddressForm;
