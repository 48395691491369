import React, { useCallback } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../hooks/useFetch';
import useTranslation from '../../../hooks/useTranslation';
import { Moment } from 'moment';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../routes/routes';
import { reportingAPI } from '../../../api/reporting/reportingAPI';
import { ReportElementModel } from '../../../typings/reporting/reporting';

// components
import FilterBar from './FilterBar';
import ReportTree from '../../../components/Additional/ReportTree';
import LoadingWrapper from '../../../components/WrapperComponents/LoadingWrapper';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Divider, Tooltip } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

interface IProps {
  selectedFilterDate: Moment;
  setSelectedDate: (newDate: Moment) => void;
}

const BalanceSheetReport = ({
  selectedFilterDate,
  setSelectedDate,
}: IProps) => {
  const { t } = useTranslation('reporting');
  const history = useHistory();

  const { response, loading } = useFetch(
    () =>
      reportingAPI.fetchBalanceSheetReport(
        selectedFilterDate.endOf('d').format('X'),
      ),
    [selectedFilterDate],
  );

  // handle filter bar change
  const handleFilterChange = (key: string, value: any) => {
    switch (key) {
      case 'date':
        setSelectedDate(value);
        break;
    }
  };

  const redirectToAccounts = (node: ReportElementModel) => {
    history.push({
      pathname: RoutePaths.Finance_Accounts,
      search: `?categoryId=${node.id}&categoryName=${node.name}`,
    });
  };

  const renderNode = useCallback((node: ReportElementModel) => {
    if (!node.children.length) {
      return (
        <>
          <EllipsisTooltip maxTextContainerWidth="400px" title={node.name}>
            {node.name}
          </EllipsisTooltip>
          <Tooltip
            title={t('balance_sheet.go_to_account_tooltip_message')}
            placement="bottom"
          >
            <ArrowRightOutlined onClick={() => redirectToAccounts(node)} />
          </Tooltip>
        </>
      );
    }
    return node.name;
  }, []);

  return (
    <>
      <FilterBar
        date={selectedFilterDate}
        isDisabled={loading}
        filterChange={handleFilterChange}
      />
      <Divider />
      <LoadingWrapper loading={loading}>
        {response && (
          <Wrapper>
            <ReportTree report={response.data} renderNode={renderNode} />
          </Wrapper>
        )}
      </LoadingWrapper>
    </>
  );
};

const Wrapper = styled.div`
  max-width: 100%;
  overflow: auto;
`;

export default BalanceSheetReport;
