import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../hooks/useFetch';
import useTranslation from '../../../../../hooks/useTranslation';
import { debounce } from 'lodash';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { RelatedPartyFilterValue } from '../../../../Tables/TableTemplates/Finance/JournalsTable';
import {
  CrmItemModel,
  relationshipAPI,
} from '../../../../../api/crm/relationship/relationshipAPI';
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  AUTOCOMPLETE_RESULT_LIMIT,
} from '../../../../../constants/global';

// components
import { Select, Spin, Typography } from 'antd';

interface IProps {
  size?: SizeType;
  value: string;
  onChange: (value: RelatedPartyFilterValue | null) => void;
  initialOption?: RelatedPartyFilterValue;
}

const CrmItemsSelect = ({
  size = 'large',
  onChange,
  value,
  initialOption,
}: IProps) => {
  const { t } = useTranslation(['finance', 'crm']);
  const [searchQuery, setSearchQuery] = useState('');

  const { response, loading } = useFetch(
    () =>
      relationshipAPI.getCrmItems({
        page: 1,
        limit: AUTOCOMPLETE_RESULT_LIMIT,
        search: searchQuery,
        isVendor: true,
        includeClientGroups: true,
      }),
    [searchQuery],
  );

  const options = useMemo<
    { id: string; name: string; model?: CrmItemModel }[]
  >(() => {
    if (!response || !response.result.length) {
      if (initialOption) {
        return [
          {
            id: initialOption.id,
            name: initialOption.name,
            model: {
              _id: initialOption.id,
              name: initialOption.name,
              type: initialOption.type,
            },
          },
        ];
      } else {
        return [];
      }
    }

    const formattedOptions: {
      id: string;
      name: string;
      model?: CrmItemModel;
    }[] = response.result.map((e) => ({
      id: e._id,
      name: e.name,
      model: e,
    }));

    if (initialOption) {
      const existsInResponse = formattedOptions.some(
        (e) => e.id === initialOption.id,
      );
      if (!existsInResponse) {
        formattedOptions.unshift({
          id: initialOption.id,
          name: initialOption.name,
          model: {
            _id: initialOption.id,
            name: initialOption.name,
            type: initialOption.type,
          },
        });
      }
    }

    return formattedOptions;
  }, [response, initialOption]);

  const renderCrmItems = (
    options: { id: string; name: string; model?: CrmItemModel }[],
  ) => {
    if (!options.length) {
      return null;
    }

    return options.map((model) => (
      <Select.Option key={model.id} value={model.id} option={model}>
        <Typography.Text>{model.name}</Typography.Text>
        <Typography.Text type="secondary">
          {' '}
          ({t(`association_types.${model.model?.type}`, { ns: 'crm' })})
        </Typography.Text>
      </Select.Option>
    ));
  };

  const handleSelectChange = (_: any, opt: any) => {
    if (opt) {
      onChange({
        id: opt.option?.id,
        type: opt.option?.model?.type,
        name: opt.option?.name,
      });
    } else {
      onChange(null);
    }
  };

  return (
    <StyledSelect
      placeholder={t('journals.table.filter_by_client_placeholder')}
      onSearch={debounce(setSearchQuery, AUTOCOMPLETE_DEBOUNCE_DELAY)}
      onChange={handleSelectChange}
      size={size}
      showSearch
      showArrow
      filterOption={false}
      loading={loading}
      value={value || undefined}
      allowClear
      notFoundContent={
        loading ? (
          <LoaderWrapper>
            <Spin size="small" />
          </LoaderWrapper>
        ) : null
      }
    >
      {!!options.length && renderCrmItems(options)}
    </StyledSelect>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;
export default CrmItemsSelect;
