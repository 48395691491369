import React from 'react';

// helpers utils
import withPrivate from '../hoc/withPrivate';
import { RouteLayoutPropsModel } from '../typings/routes';

type IProps = RouteLayoutPropsModel;

const BlankLayout = ({ children }: IProps) => {
  return <>{children}</>;
};

export default withPrivate()(BlankLayout);
