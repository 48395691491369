import React from 'react';

// helpers
import styled from 'styled-components';
import { darkTheme } from '@resources/theme/styled';

// components
import IconSVG from '@core_components/IconSVG';
import { ReactComponent as FilterIcon } from '@resources/icons/remix-icons/filter-line.svg';

interface IProps {
  onClick?: () => void;
}

const DrillDownFilterIcon = ({ onClick }: IProps) => {
  return (
    <StyledIconSVG
      component={FilterIcon}
      color={darkTheme.warningColor}
      onClick={onClick}
    />
  );
};

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default DrillDownFilterIcon;
