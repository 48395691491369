import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../constants/global';

// components
import JSONInTree from '../../JSONInTree';
import { Col, Row, Typography } from 'antd';

interface IProps {
  data: {
    createdAt: number;
    requestParamsJSON: string;
  };
}

const RDCInfoSection = memo(({ data }: IProps) => {
  const { t } = useTranslation('compliance');

  return (
    <>
      <Row wrap={false} gutter={[16, 16]}>
        <Col span={4}>
          <StyledLabel>{t('rdc_review_info_section.last_rdc_run')}</StyledLabel>
        </Col>
        <Col span="flex">
          <Typography.Text>
            {DateHelpers.formatTimestampToString(
              data.createdAt,
              DEFAULT_DATE_TIME_FORMAT,
            )}
          </Typography.Text>
        </Col>
      </Row>
      <Row wrap={false} gutter={[16, 16]}>
        <Col span={4}>
          <StyledLabel>
            {t('rdc_review_info_section.query_parameters')}
          </StyledLabel>
        </Col>
        <Col span="flex">
          <JSONInTree json={data.requestParamsJSON} />
        </Col>
      </Row>
    </>
  );
});

const StyledLabel = styled(Typography.Text)`
  color: ${({ theme }) => theme.textLightColor3};
  font-weight: 600;
`;

export default RDCInfoSection;
