import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { AnswerModel } from '../../../../../api/crm/onboarding/onboardingAnswerAPI';

// component
import InfoTooltip from '../../../../Tooltips/InfoTooltip';
import AdditionalFieldAnswer from '../../../../Forms/FormComponents/AdditionalFieldAnswer';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

type IProps = RequiredPropsForTableModel;

const ReviewDetailsDocumentsTable = (props: IProps) => {
  const { t } = useTranslation('crm');
  const [current, setCurrent] = useState(1);

  const renderName = ({ field, sources }: AnswerModel) => {
    return (
      <>
        <StyledTitle>
          {field.name}
          {sources[0].type === 'admin' && (
            <StyledInfoTooltip
              tooltipContent={t(
                'client_group.approval.review_details_modal.approval.documents_table.requested_additional_field_description',
              )}
            />
          )}
        </StyledTitle>
        <StyledDescription>{field.description}</StyledDescription>
      </>
    );
  };

  const renderAnswer = (record: AnswerModel) => {
    return record.sources[0].type === 'admin' && !record.answer ? (
      <RequestedDescription>
        {t(
          'client_group.approval.review_details_modal.approval.documents_table.required_additional_field_description',
        )}
      </RequestedDescription>
    ) : (
      <AdditionalFieldAnswer data={record} />
    );
  };

  const renderRejectionReason = ({ review, sources, answer }: AnswerModel) => {
    return sources[0].type === 'admin' && !answer ? (
      <RequestedDescription>
        {t(
          'client_group.approval.review_details_modal.approval.documents_table.required_additional_field_description',
        )}
      </RequestedDescription>
    ) : (
      <Typography.Text>{review?.comment}</Typography.Text>
    );
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        key: 'name',
        ellipsis: true,
        render: renderName,
        width: 500,
        title: t(
          'client_group.approval.review_details_modal.approval.documents_table.name',
        ),
      },
      {
        key: 'answer',
        title: t(
          'client_group.approval.review_details_modal.approval.documents_table.answer',
        ),
        width: 400,
        render: renderAnswer,
      },
      {
        key: 'rejectionReason',
        title: t(
          'client_group.approval.review_details_modal.approval.documents_table.rejection_reason',
        ),
        render: renderRejectionReason,
      },
    ];

    return result;
  }, []);

  return (
    <Table
      columns={columns}
      current={current}
      onPaginationChange={setCurrent}
      {...props}
    />
  );
};

const StyledTitle = styled(Typography.Text)`
  display: block;
  color: ${({ theme }) => theme.textLightColor2};
  padding-top: ${({ theme }) => theme.paddingSm};
  font-size: ${({ theme }) => theme.fontSizeSm};
`;

const StyledDescription = styled(Typography.Text)`
  display: block;
  color: ${({ theme }) => theme.textLightColor3};
  min-height: 20px;
  white-space: pre-line;
  word-break: break-word;
`;

const RequestedDescription = styled.span`
  color: ${({ theme }) => theme.warningColor};
`;

const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default ReviewDetailsDocumentsTable;
