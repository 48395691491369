import * as yup from 'yup';
import { STRING_VALIDATION_RULE } from 'validations/common';

export const ApprovalGroupValidationSchema = yup.object().shape({
  name: STRING_VALIDATION_RULE.max(
    50,
    'Name must be less than 50 characters',
  ).required('This is required field'),
  participants: yup
    .array(
      yup.object().shape({
        userId: STRING_VALIDATION_RULE,
        userFullName: STRING_VALIDATION_RULE,
      }),
    )
    .min(1, 'This field is required')
    .required('This field is required'),
  description: STRING_VALIDATION_RULE.max(
    300,
    'Description must be less than 300 characters',
  ),
});

const limitSchema = yup.object().shape({
  isUsed: yup
    .boolean()
    .oneOf([true], 'custom_errors:accounts.must_have_condition_warning'),
  toInfinite: yup.boolean(),
  from: yup
    .number()
    .typeError('form:validation_messages.only_numbers')
    .required('form:validation_messages.required'),
  to: yup.number().when('toInfinite', {
    is: false,
    then: (schema) =>
      schema
        .typeError('form:validation_messages.only_numbers')
        .required('form:validation_messages.required')
        .moreThan(yup.ref('from'), {
          key: 'form:alidation_messages.valueA_should_be_bigger_than_valueB',
          values: {
            valueA:
              'account_management:approval_rules.bank_accounts.approval_rule_modal.form_fields.from_range_value',
            valueB:
              'account_management:approval_rules.bank_accounts.approval_rule_modal.form_fields.to_range_value',
          },
        }),
  }),
});

const innerConditionSchema = yup.object().shape({
  approvalGroup: STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  requiredApproversCount: yup
    .number()
    .transform((value) => (!value ? undefined : value))
    .typeError('form:validation_messages.only_numbers')
    .required('form:validation_messages.required'),
});

const innerLimitSchema = yup.object().shape({
  isChecked: yup.boolean().required('form:validation_messages.required'),
});

const conditionSchema = yup.object().shape({
  conditions: yup.array().of(innerConditionSchema),
  limitStatuses: yup.array().of(innerLimitSchema),
});

export const FinanceApprovalWorkflowTemplateValidationSchema = yup
  .object()
  .shape({
    bankAccounts: yup
      .array()
      .of(
        yup.object().shape({
          bankAccountNumber: STRING_VALIDATION_RULE,
          bankAccountName: STRING_VALIDATION_RULE,
          bankAccountCurrency: STRING_VALIDATION_RULE,
        }),
      )
      .min(1, {
        key: 'form:validation_messages.required_with_label',
        values: {
          label:
            'client_group.approval.client_transaction_rules.edit_transaction_rule_modal.form_fields.bank_account',
        },
      }),

    limits: yup
      .array()
      .of(limitSchema)
      .test(
        'is-last-infinite',
        'client_group.approval.client_transaction_rules.edit_transaction_rule_modal.last_limit_should_be_infinite',
        (limitsArray: any) => {
          if (limitsArray && Array.isArray(limitsArray)) {
            const lastLimitIndex = limitsArray.length - 1;
            const isLastToInfinite = limitsArray[lastLimitIndex].toInfinite;
            return isLastToInfinite;
          }
        },
      ),
    conditions: yup.array().of(conditionSchema),
  });

export const ReviewApprovalWorkflowItemValidationSchema = yup.object().shape({
  action: STRING_VALIDATION_RULE.nullable(),
  reason: yup
    .string()
    .trim()
    .max(1000, {
      key: 'form:validation_messages.max_characters_with_label',
      values: {
        label:
          'approval_workflow.review_approval_entry_modal.form_fields.reason',
        number: 1000,
      },
    })
    .when('action', {
      is: 'reject',
      then: (schema) => schema.required('form:validation_messages.required'),
    }),
});

// Approval matrix form
const approvalGroupConditionSchema = yup.object().shape({
  approvalGroupId: STRING_VALIDATION_RULE.required('This is required field'),
  requiredApprovers: yup
    .number()
    .typeError('Must contain only numbers')
    .required('This is required field'),
});

const approvalConditionSchema = yup.object().shape({
  approvalGroupConditions: yup.array().of(approvalGroupConditionSchema),
});

export const ApprovalWorkflowTemplateValidationSchema = yup.object().shape({
  approvalConditions: yup.array().of(approvalConditionSchema),
});
