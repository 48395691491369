import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { AccountTypes } from '../../../../../enums/finance/finance';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

type IProps = SelectProps;

const AccountTypeFormSelect = (props: IProps) => {
  const { t } = useTranslation('banking');

  const options = useMemo<SelectOption[]>(
    () =>
      Object.keys(AccountTypes).map((key: string) => ({
        id: key,
        label: t(`banking.bank_accounts.types.${key}`),
      })),
    [t],
  );

  return <FormSelect {...props} options={options} />;
};

export default AccountTypeFormSelect;
