import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { accountsAdapter } from '../../../apiAdapters/finance/account/accountsAdapter';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';
import {
  IAccountCategory,
  IAccountCategoryDetails,
  IAccountCategoryShort,
  ICreateAccountCategory,
} from '../../../typings/finance/accountCategories';
import AccountCategoriesByParentMockedResponse from '../../../mock/account-categories-by-parent.json';
import AccountCategoriesTreeByParentMockedResponse from '../../../mock/account-categories-tree-by-parent.json';

interface ISearchAccountCategoryHierarchy extends FetchParamsModel {
  accountCategoryNameQuery?: string;
  isClient?: boolean;
}

interface IFetchAccountCategoriesByParentParams extends FetchParamsModel {
  parentCategoryId: number;
}

// TODO: integrate API:
//   - getCategoriesTreeByParentId
//   - getAccountCategoryById
//   - fetchAccountCategoriesByParentCategory
//   - createAccountCategory

const accountCategoriesAPI = {
  fetchAccountCategories: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<IAccountCategory>>(
      `${APIConfig.accountingApi}/accounts/categories`,
      { params },
    ).then(({ data }) => data);
  },

  searchAccountCategoryHierarchy: (params: ISearchAccountCategoryHierarchy) => {
    return APIService.get<FetchResponseModel<IAccountCategoryShort>>(
      `${APIConfig.accountingApi}/accounts/categories/search`,
      {
        params,
      },
    ).then(({ data }) => accountsAdapter.searchAccountCategoryHierarchy(data));
  },

  getCategoriesTreeByParentId: (parentId: number) => {
    return new Promise<IAccountCategoryShort[]>((resolve) =>
      setTimeout(
        () =>
          resolve(
            AccountCategoriesTreeByParentMockedResponse as IAccountCategoryShort[],
          ),
        500,
      ),
    );
  },

  getAccountCategoryById: (accountCategoryId: string) => {
    return APIService.get<IAccountCategoryDetails>(
      `${APIConfig.accountingApi}/accounts/categories/${accountCategoryId}`,
    ).then(({ data }) => data);
  },

  fetchAccountCategoriesByParentCategory: (
    params: IFetchAccountCategoriesByParentParams,
  ) => {
    return new Promise<FetchResponseModel<IAccountCategory>>((resolve) =>
      setTimeout(
        () => resolve(AccountCategoriesByParentMockedResponse as any),
        500,
      ),
    );
  },

  createAccountCategory: (model: ICreateAccountCategory) => {
    return APIService.post<ICreateAccountCategory>(
      `${APIConfig.accountingApi}/accounts/categories`,
      model,
    ).then(({ data }) => data);
  },
};

export { accountCategoriesAPI };
