import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { ContextBaseModel } from '../../typings/ORAC/contexts';
import { FetchResponseModel, FetchParamsModel } from '../../typings/common';

interface FetchContextsParamsModel extends FetchParamsModel {
  name: string;
}

const contextsAPI = {
  fetchContexts: (params: FetchContextsParamsModel) => {
    return APIService.get<FetchResponseModel<ContextBaseModel>>(
      `${APIConfig.oracApi}/contexts`,
      { params },
    ).then(({ data }) => data);
  },
};

export default contextsAPI;
