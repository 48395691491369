import React from 'react';

// helpers
import styled from 'styled-components';
import { StateModel } from '../../../redux/reducers';
import { useSelector } from 'react-redux';
import { SubRouteModel } from '../../../typings/routes';
import useTranslation from '../../../hooks/useTranslation';
import { StateModel as UserAcccesStateModel } from '../../../redux/reducers/userAccess';

// Constants
import { PERMISSION_KEYS } from '../../../config/permissions';

// Components
import NavButton from './NavButton';
import { Col, Row } from 'antd';

interface IProps {
  routes: SubRouteModel[];
}

const NavTemplate = ({ routes }: IProps) => {
  const { t } = useTranslation('page_titles');

  const { permissions } = useSelector<StateModel, UserAcccesStateModel>(
    (state) => state.userAccess,
  );

  const gridSizes = { xl: 12, lg: 24, md: 24, sm: 24, xs: 24 };

  const renderRoutes = () => {
    const allowedRoutes = routes.filter(
      ({ permission }) =>
        !permission?.permissionKey ||
        permissions[permission.permissionKey as PERMISSION_KEYS].isAllowed,
    );

    return allowedRoutes.map(({ path, title }: any) => (
      <ColWrapper key={path} {...gridSizes}>
        <NavButton path={path}>{t(title)}</NavButton>
      </ColWrapper>
    ));
  };

  return <RowWrapper justify="space-between">{renderRoutes()}</RowWrapper>;
};

const RowWrapper = styled(Row)`
  margin: -${({ theme }) => theme.paddingMd} -${({ theme }) => theme.paddingXl};
`;

const ColWrapper = styled(Col)`
  padding: ${({ theme }) => theme.paddingXl};
`;

export default NavTemplate;
