import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { accountsAPI } from 'api/finance/account/accountsAPI';
import { AccountTypes } from 'enums/finance/finance';
import { accountsAdapter } from 'apiAdapters/finance/account/accountsAdapter';
import { correspondentBanksAPI } from 'api/banking/correspondentBanksAPI';
import { correspondentBanksAdapter } from 'apiAdapters/finance/account/correspondentBanksAdapter';

// components
import PageButton from '@common_components/Buttons/PageButton';
import BankAccountModal, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/Finance/BankAccountModal';

interface IProps {
  onCreate?: () => void;
}

const AddBankAccount = ({ onCreate }: IProps) => {
  const { t } = useTranslation('finance');
  const [isVisible, setIsVisible] = useState(false);

  const initialFormValues = useMemo<FormValuesModel>(() => {
    return {
      isReconciled: null,
      accountCategory: null,
      financialType: '',
      accountName: '',
      currency: null,
      isClient: null,
      isCorrespondentBank: null,
      correspondentBank: null,
      correspondentBankAccount: null,
      clientGroup: null,
      accountNumber: '',
      shouldAddShortName: false,
      isActive: null,
      isFrozen: null,
      accountShortName: '',
      accountSupplementary: {
        purpose: '',
        type: AccountTypes.current,
        initialDeposit: null,
        initialDepositOrigin: null,
        jurisdictions: [],
        incomingWiresNumber: null,
        estimatedIncomingFunds: null,
        outgoingWiresNumber: null,
        estimatedOutgoingFunds: null,
      },
    };
  }, []);

  const handleCloseCallback = (wasCreated?: boolean) => {
    if (wasCreated) {
      onCreate && onCreate();
    }

    setIsVisible(false);
  };

  const handleSubmit = async (values: FormValuesModel) => {
    if (values.isCorrespondentBank) {
      await correspondentBanksAPI.createCorrespondentBankAccount(
        correspondentBanksAdapter.createCorrespondentBankAccount(values),
      );
    } else {
      await accountsAPI.createAccount(
        accountsAdapter.generateCreateAccountBody(values),
      );
    }
    message.success(t('accounts.new.create_success'));
  };

  return (
    <>
      <PageButton onClick={() => setIsVisible(true)}>
        {t('accounts.create_account')}
      </PageButton>
      <BankAccountModal
        title={t('accounts.create_account')}
        isVisible={isVisible}
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        closeCallback={handleCloseCallback}
      />
    </>
  );
};

export default AddBankAccount;
