import React, { useMemo, useState } from 'react';

// helpers
import _ from 'lodash';
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { OnboardType } from 'enums/crm/crm';
import { compareObjects } from '@helpers/utils';
import { ReviewDetailsModel } from 'api/crm/clientGroup/clientGroupsAPI';
import { HighlightFieldStatuses } from '@core_components/FormField';
import { onboardingAnswerAdapter } from 'apiAdapters/crm/onboarding/onboardingAnswerAdapter';
import {
  AnswerModel,
  onboardingAnswerAPI,
} from 'api/crm/onboarding/onboardingAnswerAPI';
import {
  FetchContactWithChangeRequestResponse,
  changeRequestsAPI,
} from 'api/crm/changeRequests/changeRequestsAPI';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import RequireAdditionalInformationModalDialog from 'components/Modals/TemplateModalDialogs/CRM/RequireAdditionalInformationModalDialog';
import ApplicationDocumentationForm, {
  AdditionalFieldFormItemModel,
  FormValuesModel,
} from 'components/Forms/TemplateForms/CRM/ApplicationDocumentationForm';

interface IProps {
  data: FetchContactWithChangeRequestResponse;
  canReview: boolean;
  updateContactCallback: () => void;
  updateChangeRequestCallback: () => void;
}

const VerificationTab = ({
  data,
  canReview,
  updateChangeRequestCallback,
  updateContactCallback,
}: IProps) => {
  const { t } = useTranslation(['crm', 'common']);
  const [requireAdditionalInfoModalData, setRequireAdditionalInfoModalData] =
    useState<ReviewDetailsModel[] | null>(null);

  const { response, loading: fetchChangeRequestLoader } = useFetch(
    () =>
      data.clientChangeRequest
        ? onboardingAnswerAPI.fetchContactKYCFormVerificationDocuments(
            data.contact._id,
            data.clientChangeRequest?._id,
          )
        : null,
    [data],
  );

  const { response: contactDocuments, loading: fetchContactDocumentsLoader } =
    useFetch(
      () =>
        data
          ? onboardingAnswerAPI.fetchApplicationDocumentationStepAnswers(
              data.contact._id,
            )
          : null,
      [data],
    );

  const initialValues = useMemo<{
    initialValues: FormValuesModel;
    highlightFields: Record<string, HighlightFieldStatuses>;
  } | null>(() => {
    if (!response || !contactDocuments) {
      return null;
    }

    const mapAdditionalField = (
      answer: AnswerModel,
    ): AdditionalFieldFormItemModel => ({
      isFinalized: answer.status === 'finalized',
      _id: answer._id,
      requestedByAdmin: answer.sources.some((e) => e.type === 'admin'),
      itemId: answer.itemId,
      itemType: answer.itemType,
      clientGroupId: answer.clientGroupId,
      field: answer.field,
      sources: answer.sources,
      isApproved: answer.review ? answer.review?.isValid : null,
      isRejected: answer.review ? !answer.review.isValid : null,
      notReviewed: !answer.review,
      reviewComment: answer.review?.comment || '',
      answer: answer.answer
        ? {
            providedBy: answer.answer.details.providedBy,
            isProvided: answer.answer?.isProvided,
            valueJSON: answer.answer?.valueJSON || '',
            reason: answer.answer?.reason || '',
          }
        : null,
    });

    const { clientChangeRequest, admin: changeRequestAdmin } = response;
    const changeRequestAnswers = clientChangeRequest
      ? [...clientChangeRequest.data, ...changeRequestAdmin.data]
      : [...changeRequestAdmin.data];
    const changeRequestAdditionalFields: AdditionalFieldFormItemModel[] =
      _.orderBy(
        changeRequestAnswers.map(mapAdditionalField),
        ['field.name'],
        ['asc'],
      );

    const {
      relationship_keyRelationshipTemplate: relationshipTemplateAnswers,
      admin: contactAdmin,
    } = contactDocuments;
    const contactAnswers = relationshipTemplateAnswers
      ? [...relationshipTemplateAnswers.data, ...contactAdmin.data]
      : [...contactAdmin.data];

    const contactAdditionalFields: AdditionalFieldFormItemModel[] = _.orderBy(
      contactAnswers.map(mapAdditionalField),
      ['field.name'],
      ['asc'],
    );

    const modifiedValues = compareObjects(
      {
        additionalFields: changeRequestAdditionalFields.map((e) => ({
          answer: e.answer ? { valueJSON: e.answer.valueJSON } : null,
        })),
      },
      {
        additionalFields: contactAdditionalFields.map((e) => ({
          answer: e.answer ? { valueJSON: e.answer.valueJSON } : null,
        })),
      },
    );

    return {
      initialValues: {
        itemId: data.contact._id,
        itemType: OnboardType.Contact,
        submitActionType: null,
        reviewProcess: {
          type: 'contact-review',
          entryId: data.clientChangeRequest?._id || '',
        },
        additionalFields: changeRequestAdditionalFields,
        additionalDocuments: response.client.data,
      },
      highlightFields: modifiedValues,
    };
  }, [response, contactDocuments, data]);

  const onSubmit = async (values: FormValuesModel) => {
    try {
      const formattedBody =
        onboardingAnswerAdapter.reviewApplicationDocumentation(
          values,
          values.itemId,
          values.itemType,
        );
      await onboardingAnswerAPI.reviewApplicationDocumentation(formattedBody);

      if (values.submitActionType === 'start-approval-workflow') {
        await changeRequestsAPI.startApprovalWorkflowForChangeRequest(
          data.clientChangeRequest?._id || '',
        );
        updateContactCallback();
      } else if (values.submitActionType === 'require-additional-info') {
        const response =
          await changeRequestsAPI.fetchChangeRequestReviewResults(
            data.clientChangeRequest?._id || '',
          );
        if (response && response.length) {
          setRequireAdditionalInfoModalData(response);
        } else {
          message.error(t('client_group.approval.review_details_error'));
        }
      }
      message.success(t('successfully_saved', { ns: 'common' }));
    } finally {
      // todo
    }
  };

  const onReviewResultsModalSubmit = async () => {
    await changeRequestsAPI.requireAdditionalInfoForChangeRequest(
      data.clientChangeRequest?._id || '',
    );
    updateChangeRequestCallback();
  };

  return (
    <>
      <LoadingWrapper
        loading={fetchChangeRequestLoader || fetchContactDocumentsLoader}
      >
        {initialValues && (
          <ApplicationDocumentationForm
            isViewOnly={!canReview}
            initialFormValues={initialValues.initialValues}
            onSubmit={onSubmit}
            highlightFields={initialValues.highlightFields}
          />
        )}
      </LoadingWrapper>

      <RequireAdditionalInformationModalDialog
        title={t('client_group.approval.review_details_modal.title')}
        data={requireAdditionalInfoModalData}
        isVisible={!!requireAdditionalInfoModalData}
        onSubmit={onReviewResultsModalSubmit}
        closeCallback={() => setRequireAdditionalInfoModalData(null)}
      />
    </>
  );
};

export default VerificationTab;
