import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ChangeRequestFromQuery } from 'api/crm/changeRequests/changeRequestsAPI';

// components
import DescriptionSection from '@core_components/DescriptionSection';
import ChangeRequestStatus from 'components/Additional/Statuses/ChangeRequestStatus';

interface IProps {
  changeRequest: ChangeRequestFromQuery;
}

const ChangeRequestDetails = ({ changeRequest }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <DescriptionSection
      size="small"
      bordered={false}
      background="none"
      data={[
        {
          label: t('contacts.kyc_form.change_request_section.type'),
          description: t(
            `contacts.kyc_form.change_request_types.${changeRequest.type}`,
          ),
        },
        {
          label: t('contacts.kyc_form.change_request_section.status'),
          description: <ChangeRequestStatus status={changeRequest.status} />,
        },
      ]}
    />
  );
};

export default ChangeRequestDetails;
