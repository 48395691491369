import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import useUserAccess from '../../../../hooks/useUserAccess';
import { RoutePaths } from '../../../../routes/routes';
import { useHistory } from 'react-router-dom';
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';
import { correspondentBanksAPI } from '../../../../api/banking/correspondentBanksAPI';
import { CorrespondentBankModel } from '../../../../typings/banking/correspondentBanks';

// components
import { default as TemplateCorrespondentBanksTable } from '../../../../components/Tables/TableTemplates/Banking/CorrespondentBanksTable';

const CorrespondentBanksTable = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [hasViewDetailsAccess] = useUserAccess([
    'Banking_CorrespondentBanks_Details_View',
  ]);
  const { response, loading } = useFetch(
    () =>
      correspondentBanksAPI.fetchCorrespondentBanks({
        page,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [page],
  );

  const onDetailsClick = (record: CorrespondentBankModel) => {
    history.push({
      pathname: RoutePaths.Banking_CorrespondentBankDetails,
      search: `?id=${record.id}`,
    });
  };

  return (
    <TemplateCorrespondentBanksTable
      current={page}
      onPaginationChange={setPage}
      loading={loading}
      data={response?.data || []}
      total={response?.total || 0}
      onDetailsClick={hasViewDetailsAccess ? onDetailsClick : undefined}
    />
  );
};

export default CorrespondentBanksTable;
