import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { IAccountModel } from '../../../../../typings/finance/account';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { StyledComponentProps } from '../../../../../typings/common';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

export type ActionKeys = 'details';

export interface IProps
  extends StyledComponentProps,
    RequiredPropsForTableModel<IAccountModel> {
  onActionsClick?: (key: ActionKeys, record: IAccountModel) => void;
}

const CorrespondentBankAccountsTable = memo(
  ({ onActionsClick, ...rest }: IProps) => {
    const { t } = useTranslation('finance');
    const history = useHistory();

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          title: t('accounts.table.account_number'),
          key: 'account_number',
          width: 270,
          render: (record: IAccountModel) =>
            onActionsClick ? (
              <Typography.Link
                onClick={() => onActionsClick('details', record)}
              >
                {record.accountNumber}
              </Typography.Link>
            ) : (
              record.accountNumber
            ),
        },
        {
          title: t('accounts.table.account_name'),
          key: 'account_name',
          width: 270,
          render: (record: IAccountModel) => record.accountName,
        },
        {
          title: t('accounts.table.currency'),
          key: 'currency',
          width: 100,
          render: (record: IAccountModel) => record.currencyCode,
        },

        {
          title: t('accounts.table.balance'),
          key: 'balance',
          width: 150,
          render: (record: IAccountModel) => (
            <Typography.Text>
              {AccountsHelpers.convertAmountBigIntToLocaleString(
                record.balance,
              )}
            </Typography.Text>
          ),
        },
        {
          title: t('accounts.table.correspondent_bank_account'),
          key: 'account_number',
          width: 270,
          render: (record: IAccountModel) => (
            <Typography.Link
              onClick={() =>
                history.push({
                  pathname: RoutePaths.Finance_Account_Details,
                  search: `?id=${record.correspondentBankAccountNumber}`,
                })
              }
            >
              {record.correspondentBankAccountNumber}
            </Typography.Link>
          ),
        },
      ];

      return result;
    }, [onActionsClick]);

    return <Table {...rest} columns={columns} />;
  },
);

export default CorrespondentBankAccountsTable;
