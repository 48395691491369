import * as yup from 'yup';
import { STRING_VALIDATION_RULE } from 'validations/common';

const JournalEntryValidationSchema = yup.object().shape({
  accountNumber: STRING_VALIDATION_RULE.required('Account is required.'),
  description: STRING_VALIDATION_RULE.required('Description is required.'),
  debitAmount: yup
    .number()
    .positive()
    .test('debitOrCredit', 'Debit or Credit is required', function (value) {
      return value || this.parent.creditAmount;
    })
    .min(0),
  creditAmount: yup
    .number()
    .positive()
    .test('debitOrCredit', 'Debit or Credit is required', function (value) {
      return value || this.parent.debitAmount;
    })
    .min(0),
  isResident: yup.boolean().required('This field is required'),
  relatedPartyId: STRING_VALIDATION_RULE.test(
    'financialTypeName',
    'Related Party is required',
    function (value?: any) {
      const financialType =
        this.parent.account?.financialTypeName?.toLowerCase();

      return financialType === 'expenses' || financialType === 'income'
        ? !!value
        : true;
    },
  ),
});

export const NewJournalValidationSchema = yup.object().shape({
  journalValueDate: yup.date().required('Journal Date is required.'),
  isReversalJournal: yup.boolean(),
  journal: yup.object().when('isReversalJournal', {
    is: false,
    then: (schema) =>
      schema.shape({
        entries: yup.array().of(JournalEntryValidationSchema),
      }),
  }),
  reversalJournal: yup.object().when('isReversalJournal', {
    is: true,
    then: (schema) =>
      schema.shape({
        entries: yup.array().of(JournalEntryValidationSchema),
      }),
    otherwise: (schema) => schema,
  }),
});
