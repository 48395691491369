import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { DateHelpers } from '@helpers/date';
import { ExpandableConfig } from 'antd/lib/table/interface';
import {
  GroupBaseModel,
  GroupWithRolesModel,
} from '../../../../../typings/ORAC/groups';

// constants
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../../constants/global';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import TableActions, { ITableMenuItem } from '../../../TableActions';
import { Typography } from 'antd';

export type RecordModel = GroupBaseModel | GroupWithRolesModel;

interface IProps extends RequiredPropsForTableModel<RecordModel> {
  expandable?: ExpandableConfig<RecordModel>;
  actions?: ITableMenuItem[];
  onActionsClick?: (key: string, value: string) => void;
}

const GroupsTable = memo(
  ({ actions, onActionsClick, expandable, ...rest }: IProps) => {
    const history = useHistory();
    const { t } = useTranslation('orac');

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          title: t('groups.table.name'),
          key: 'name',
          width: 250,
          render: (record: RecordModel) => (
            <Typography.Link
              onClick={() =>
                history.push({
                  pathname: RoutePaths.ORAC_Group_Details,
                  search: `?id=${record.id}`,
                })
              }
            >
              {record.name}
            </Typography.Link>
          ),
        },
        {
          title: t('groups.table.description'),
          key: 'description',
          width: 400,
          render: (record: RecordModel) => record.description,
        },
        {
          title: t('roles.table.created_at'),
          key: 'createdAt',
          width: 200,
          render: (record: RecordModel) =>
            DateHelpers.formatDateToString(
              record.createdAt,
              DEFAULT_DATE_TIME_FORMAT,
            ),
        },
        {
          key: 'actions',
          align: 'right',
          hidden: !actions?.length,
          render: (record: RecordModel) =>
            actions && (
              <TableActions
                menu={actions}
                onSelect={(key) =>
                  onActionsClick && onActionsClick(key, record.id)
                }
              />
            ),
        },
      ];

      return result;
    }, [actions, onActionsClick]);

    return (
      <Table
        {...rest}
        columns={columns}
        expandable={expandable ? expandable : undefined}
      />
    );
  },
);

export default GroupsTable;
