import React, { memo, useMemo } from 'react';

// helpers
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type RangeOption = {
  min?: number;
  max?: number;
  key: string;
};

export interface RangeFormSelectProps extends SelectProps {
  rangeOptions: RangeOption[];
}

const RangeFormSelect = memo(
  ({ rangeOptions, ...rest }: RangeFormSelectProps) => {
    const options = useMemo<SelectOption<RangeOption>[]>(() => {
      let label = null;

      return rangeOptions.map((e) => {
        if (typeof e.min !== 'number' || e.max === e.min) {
          label = e.max?.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        } else if (typeof e.max !== 'number') {
          label = (
            <>
              {e.min?.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
              +
            </>
          );
        } else {
          label = (
            <>
              {e.min?.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
              -
              {e.max?.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </>
          );
        }

        return {
          id: e.key,
          model: e,
          label,
        };
      });
    }, [rangeOptions]);

    return <FormSelect {...rest} options={options} />;
  },
);

export default RangeFormSelect;
