import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { darkTheme } from '@resources/theme/styled';
import { StyledComponentProps } from 'typings/common';

// components
import IconSVG from '@core_components/IconSVG';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import { ReactComponent as InfoIcon } from 'resources/icons/remix-icons/information-line.svg';

type IProps = StyledComponentProps;

const CannotDeleteLastApplicantRelationshipWarning = memo((props: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <DivAlignCenter {...props}>
      <StyledIconSVG component={InfoIcon} color={darkTheme.warningColor} />
      {t(
        'client_group.structure.relationships.cannot_delete_last_applicant_relationship_warning',
      )}
    </DivAlignCenter>
  );
});

const StyledIconSVG = styled(IconSVG)`
  margin-right: ${({ theme }) => theme.marginSm};
`;

export default CannotDeleteLastApplicantRelationshipWarning;
