import React, { useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import useUserAccess from '../../../../hooks/useUserAccess';
import useTranslation from '../../../../hooks/useTranslation';
import { debounce } from 'lodash';
import { ICurrency } from '../../../../typings/finance/currency';
import { currenciesAPI } from '../../../../api/finance/currency/currenciesAPI';
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  DEFAULT_TABLE_LIMIT,
} from '../../../../constants/global';

// constants
import { CurrencyFilterOptions } from '../../../../enums/finance/finance';

// components
import FilterBar from './FilterBar';
import CurrencyModal, {
  FormValuesModel,
} from '../../../../components/Modals/TemplateModalDialogs/Finance/CurrencyModal';
import { Divider, message } from 'antd';
import { default as TemplateCurrenciesTable } from '../../../../components/Tables/TableTemplates/Finance/CurrenciesTable';

interface IProps {
  updateTableTrigger?: any;
}

const CurrenciesTable = ({ updateTableTrigger }: IProps) => {
  const { t } = useTranslation('finance');
  const [hasUpdateAccess] = useUserAccess(['Finance_Currencies_Update']);
  const [currentPage, setPage] = useState(1);
  const [updateTrigger, setUpdateTrigger] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState<ICurrency | null>(
    null,
  );
  const [currenciesFilter, setCurrenciesFilter] = useState({
    currencyQuery: '',
    isAccount: CurrencyFilterOptions.IsAccount,
  });

  const { response, loading } = useFetch(
    () =>
      currenciesAPI.searchCurrencies({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        currencyQuery: currenciesFilter.currencyQuery,
        isAccount: currenciesFilter.isAccount,
        isExternal:
          currenciesFilter.isAccount === CurrencyFilterOptions.AllAccounts
            ? CurrencyFilterOptions.AllAccounts
            : CurrencyFilterOptions.NoAccounts,
        isActive: 'all',
      }),
    [currentPage, updateTableTrigger, currenciesFilter, updateTrigger],
  );

  const handleFilterChange = (key: string, value: any) => {
    if (currentPage !== 1) {
      setPage(1);
    }

    switch (key) {
      case 'search':
        setCurrenciesFilter((prevState) => ({
          ...prevState,
          currencyQuery: value,
        }));
        break;
      case 'filter-change':
        setCurrenciesFilter((prevState) => ({
          ...prevState,
          isAccount: value,
        }));
        break;
    }
  };

  const initialCurrencyValues = useMemo<FormValuesModel>(
    () => ({
      code: selectedCurrency?.isoCode || '',
      name: selectedCurrency?.name || '',
      isActive: selectedCurrency?.isActive || false,
      isAccount: selectedCurrency?.isAccount || false,
      currencyId: selectedCurrency?.isoCurrencyId || '',
    }),
    [selectedCurrency],
  );

  const handleUpdateCurrency = async (values: FormValuesModel) => {
    if (selectedCurrency) {
      await currenciesAPI.updateCurrency(
        { isActive: values.isActive, isAccount: values.isAccount },
        selectedCurrency.id,
      );
      message.success(t('currencies.update.success_message'));
      setSelectedCurrency(null);
      setUpdateTrigger({});
    }
  };

  const onActionsClick = (key: string, record: ICurrency) => {
    switch (key) {
      case 'update': {
        setSelectedCurrency(record);
        break;
      }
      default:
        return;
    }
  };

  return (
    <>
      <FilterBar
        handleChange={debounce(handleFilterChange, AUTOCOMPLETE_DEBOUNCE_DELAY)}
        filterValue={currenciesFilter.isAccount}
      />
      <Divider />
      <TemplateCurrenciesTable
        total={response?.total || 0}
        current={currentPage}
        data={response?.data || []}
        loading={loading}
        onPaginationChange={setPage}
        onActionsClick={(key: string, record: ICurrency) =>
          onActionsClick(key, record)
        }
      />
      <CurrencyModal
        onSubmit={handleUpdateCurrency}
        isViewOnly={!hasUpdateAccess}
        isVisible={!!selectedCurrency}
        initialValues={initialCurrencyValues}
        closeCallback={() => setSelectedCurrency(null)}
      />
    </>
  );
};

export default CurrenciesTable;
