export enum UserTypes {
  Admin = 'admin',
  Client = 'client',
}

export enum NotificationMethods {
  Email = 'email',
  SMS = 'sms',
  // Teams,
}

export enum NotificationStatuses {
  New = 'New',
  Error = 'Error',
  Processed = 'Processed',
}

export enum AccountRecoveryRequestTypes {
  Authenticator = 'authenticator',
  Fido = 'fido',
}
