import { StateModel } from '../redux/reducers';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { StateModel as UserAcccesStateModel } from '../redux/reducers/userAccess';
import { LayoutPropsModel, RouteModel, SubRouteModel } from '../typings/routes';

// constants
import { PERMISSION_KEYS } from '../config/permissions';

export interface MenuItemModel {
  title: string;
  path: string;
  layoutProps?: LayoutPropsModel;
  subItems: MenuItemModel[];
  disabled?: boolean;
}

// Custom hook that are responsible for generating menu items for sidebar
// Need to pass:
// - current location
// - root routes array (all routes)
// - shouldShowAllRoutes (not required) - need to pass in the case when all routes from root section needs to be rendered in left bar
// (e.g. User profile where all routes should be rendered in left bar)
const useSidebarMenu = (
  currentPath: string,
  routes: RouteModel[],
  shouldShowAllRoutes?: boolean,
): MenuItemModel[] => {
  const { permissions } = useSelector<StateModel, UserAcccesStateModel>(
    (state) => state.userAccess,
  );

  const [menuItems, setMenuItems] = useState<MenuItemModel[]>([]);

  useEffect(() => {
    const newMenuItems = getNewMenuItems();
    setMenuItems(newMenuItems);
  }, [currentPath]);

  // Check route permissions
  // If the route has permissions -> check if user has such permissions
  const checkPermissions = (route: SubRouteModel) => {
    if (!route.permission?.permissionKey) {
      return true;
    }

    return permissions[route.permission.permissionKey as PERMISSION_KEYS]
      .isAllowed;
  };

  const getNewMenuItems = (): MenuItemModel[] => {
    const splitPath = currentPath
      .split('/')
      .filter((e) => e)
      .map((e) => `/${e}`);

    if (!splitPath.length || splitPath[0] === '/') {
      return [];
    }

    let newMenuItems: MenuItemModel[] = [];

    const parentRouteSectionIndex = routes.findIndex(
      (e) => e.path === splitPath[0],
    );

    if (parentRouteSectionIndex !== -1) {
      newMenuItems = generateNewMenuItems(
        splitPath[0],
        routes[parentRouteSectionIndex].routes,
      );
    } else {
      const clientRoutesSectionIndex = routes.findIndex((e) => e.path === '/');
      newMenuItems = generateNewMenuItems(
        splitPath[0],
        routes[clientRoutesSectionIndex].routes,
      );
    }

    return newMenuItems;
  };

  const generateNewMenuItems = (
    sectionPath: string,
    routes: SubRouteModel[],
  ): MenuItemModel[] => {
    let result: MenuItemModel[] = [];

    if (shouldShowAllRoutes) {
      result = routes.map((e) => ({
        title: e.title,
        path: e.path,
        layoutProps: e.layoutProps,
        subItems: generateSubMenuItems(e.childRoutes || []),
      }));
    } else {
      const subRouteIndex = routes.findIndex((e) => e.path === sectionPath);

      if (
        subRouteIndex !== -1 &&
        routes[subRouteIndex].childRoutes &&
        routes[subRouteIndex].childRoutes?.length
      ) {
        const elements =
          routes[subRouteIndex].childRoutes?.map((e) => {
            const filteredRoutes = e.childRoutes
              ? e.childRoutes.filter((e) => !e.hideFromNavigation)
              : [];

            const hasPermission = e.permission ? checkPermissions(e) : true;

            if (!hasPermission && !e.permission?.options?.disableNavItem)
              return;

            return {
              title: e.title,
              path: e.path,
              disabled: !hasPermission && e.permission?.options?.disableNavItem,
              layoutProps: e.layoutProps,
              subItems: generateSubMenuItems(filteredRoutes),
            };
          }) || [];

        result = elements.filter((e) => e) as MenuItemModel[];
      } else if (!routes[subRouteIndex].childRoutes) {
        const { title, path, permission, layoutProps } = routes[subRouteIndex];

        result = [
          {
            title,
            path,
            disabled: permission?.options?.disableNavItem,
            layoutProps,
            subItems: [],
          },
        ];
      }
    }

    return result;
  };

  const generateSubMenuItems = (routes: SubRouteModel[]): MenuItemModel[] => {
    if (!routes.length) return [];

    const result = routes.map((e) => {
      const filteredRoutes = e.childRoutes
        ? e.childRoutes.filter((e) => !e.hideFromNavigation)
        : [];

      const hasPermission = e.permission ? checkPermissions(e) : true;

      if (!hasPermission && !e.permission?.options?.disableNavItem) return;

      return {
        title: e.title,
        path: e.path,
        disabled: !hasPermission && e.permission?.options?.disableNavItem,
        layoutProps: e.layoutProps,
        subItems: generateSubMenuItems(filteredRoutes),
      };
    });

    return result.filter((e) => e) as MenuItemModel[];
  };

  return menuItems;
};

export default useSidebarMenu;
