import React from 'react';

// helpers
import { ApprovalGroupModel } from 'typings/approval/templates';
import { ApprovalGroupFromQuery } from 'api/approval/approvalManagement/approvalGroupsAPI';
import { FinanceApprovalWorkflowTemplateValidationSchema } from 'validations/approval';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import FinanceApprovalRulesForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Approval/FinanceApprovalRulesForm';

export type TransactionRuleData = {
  rule: FormValuesModel;
  approvalGroups: ApprovalGroupModel[];
} | null;

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
  loading: boolean;
  canRenderForm: boolean;
  approvalGroups: ApprovalGroupFromQuery[];
}

const TransactionRuleModal = ({
  title,
  loading,
  canRenderForm,
  isVisible,
  initialValues,
  onSubmit,
  approvalGroups,
  closeCallback,
}: IProps) => {
  return (
    <Form
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={FinanceApprovalWorkflowTemplateValidationSchema}
      confirmExitWithoutSaving
      renderForm={
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <LoadingWrapper loading={loading}>
            {canRenderForm && (
              <FinanceApprovalRulesForm approvalGroups={approvalGroups} />
            )}
          </LoadingWrapper>
        </ModalDialog>
      }
    />
  );
};

export default TransactionRuleModal;
