import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { TransferTypes } from '../../../../../enums/finance/finance';
import { AccountsHelpers } from '../../../../../helpers/finance/accounts';
import { ForexTransferModel } from '../../../../../typings/banking/forexTransfers';

// components
import TableActions from '../../../TableActions';
import TransactionStatus from '../../../../Typography/TransactionStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Col, Row, Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<ForexTransferModel> {
  onActionsClick: (key: string, transferId: string) => void;
}

const ForexTransfersTable = memo(({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation('banking');

  const renderSection = (rows: { title: string; value: string }[]) => {
    return (
      <>
        {rows.map(({ title, value }, i) => (
          <Row key={i} wrap={false}>
            <Col flex="170px">{title}</Col>
            <Col flex="auto">
              <StyledText>{value}</StyledText>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const renderCustomerInformation = (transfer: ForexTransferModel) => {
    const isCurrencyIdFANccy = transfer.currencyId === transfer.fromCurrencyId;

    const rows = [
      {
        title: t('forex.pending_transfers.client_tree'),
        value: transfer.fromClientName,
      },

      {
        title: t('forex.pending_transfers.from_sell'),
        value: AccountsHelpers.generateAccountFullName(
          transfer.fromAccountName,
          transfer.fromAccountNumber,
          transfer.fromCurrencyIsoCode,
        ),
      },

      {
        title: t('forex.pending_transfers.to_buy'),
        value: AccountsHelpers.generateAccountFullName(
          transfer.beneficiaryAccountName,
          transfer.beneficiaryAccountNumber,
          transfer.toCurrencyIsoCode,
        ),
      },

      {
        title: t(
          isCurrencyIdFANccy
            ? 'forex.pending_transfers.sell'
            : 'forex.pending_transfers.buy',
        ),
        value: `${AccountsHelpers.convertAmountBigIntToLocaleString(transfer.amount)} ${transfer.currencyIsoCode}`,
      },
    ];

    return renderSection(rows);
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('forex.pending_transfers.code'),
        key: 'sourceType',
        width: '200px',
        render: (record: ForexTransferModel) =>
          t(`transfer_types.${TransferTypes[record.sourceType]}`),
      },

      {
        title: t('forex.pending_transfers.status'),
        key: 'status',
        width: '200px',
        render: (record: ForexTransferModel) => (
          <TransactionStatus status={record.status} />
        ),
      },

      {
        title: t('forex.pending_transfers.client_information'),
        key: 'client_info',
        render: renderCustomerInformation,
      },

      {
        key: 'actions',
        align: 'right',
        render: (record: ForexTransferModel) => (
          <TableActions
            onSelect={(key: string) => onActionsClick(key, String(record.id))}
            menu={[
              {
                key: 'view_details',
                name: t('forex.pending_transfers.view_details'),
              },
            ]}
          />
        ),
      },
    ];

    return result;
  }, [t, onActionsClick]);

  return <Table {...rest} columns={columns} />;
});

const StyledText = styled(Typography.Text)`
  font-weight: 600;
  color: #fff;
`;

export default ForexTransfersTable;
