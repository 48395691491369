import React from 'react';

// ** Helpers & Utils **
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';

// ** Components **
import { Typography } from 'antd';
import NewBusinessWorkflowTemplate from './NewBusinessWorkflowTemplate';
import NewBusinessWorkflowStatus from './NewBusinessWorkflowStatus';

interface IProps {
  workflowId?: string;
  onUpdate: () => void;
}

const NewBusinessWorkflow = ({ workflowId, onUpdate }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <>
      <SectionTitle level={3}>
        {t('onboarding_statuses.business_review')}
      </SectionTitle>
      {workflowId ? (
        <NewBusinessWorkflowStatus
          workflowId={workflowId}
          onUpdate={onUpdate}
        />
      ) : (
        <NewBusinessWorkflowTemplate />
      )}
    </>
  );
};

const SectionTitle = styled(Typography.Title)`
  margin-top: ${({ theme }) => theme.marginXs};
`;

export default NewBusinessWorkflow;
