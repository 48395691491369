import React, { useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import rdcReviewAPI from 'api/compliance/rdcReviewAPI';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { RDCReviewAlertBaseModel } from 'typings/compliance/reviewProcess';
import { TransactionWithComplianceInfoModel } from 'typings/compliance/transactions';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ReviewRDCAlertModal from '../ReviewRDCAlertModal';
import { default as TemplateRDCAlertsTable } from 'components/Tables/TableTemplates/Compliance/RDCAlertsTable';

interface IProps {
  reviewProcess: TransactionWithComplianceInfoModel;
}

const RDCAlertsTable = ({ reviewProcess }: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAlertId, setSelectedAlertId] = useState<string | null>(null);
  const [updateAlertsTrigger, updateAlerts] = useState({});

  const { response, loading } = useFetch(
    () =>
      rdcReviewAPI.fetchAlertsByAssociationId({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        reviewProcessId: reviewProcess.id,
      }),
    [currentPage, reviewProcess.id, updateAlertsTrigger],
  );

  const handleDetailsClick = (record: RDCReviewAlertBaseModel) => {
    setSelectedAlertId(record._id);
  };

  const handleModalClose = (wasUpdated?: boolean) => {
    setSelectedAlertId(null);

    if (wasUpdated) {
      updateAlerts({});
    }
  };

  return (
    <>
      <LoadingWrapper loading={!response}>
        <TemplateRDCAlertsTable
          data={response?.data || []}
          total={response?.total || 0}
          loading={loading}
          current={currentPage}
          onPaginationChange={setCurrentPage}
          onDetailsClick={handleDetailsClick}
        />
      </LoadingWrapper>
      <ReviewRDCAlertModal
        alertId={selectedAlertId}
        closeCallback={handleModalClose}
      />
    </>
  );
};

export default RDCAlertsTable;
