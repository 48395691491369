import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { IKeyRelationshipTemplate } from '../../../../../typings/crm/key-relationship-templates';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<IKeyRelationshipTemplate> {
  onActionsClick: (key: string, value: any) => void;
}

const EntityTypeCategoriesTable = memo(
  ({ onActionsClick, ...rest }: IProps) => {
    const { t } = useTranslation(['crm', 'common']);

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          key: 'name',
          ellipsis: true,
          title: t('entity_type_category.category_name'),
          render: (record: IKeyRelationshipTemplate) => (
            <Typography.Link onClick={() => onActionsClick('edit', record._id)}>
              {record.name}
            </Typography.Link>
          ),
        },
        {
          key: 'keyRelations',
          title: t('entity_type_category.relationships_count'),
          render: (record: IKeyRelationshipTemplate) => (
            <Typography.Text>{record.keyRelations.length}</Typography.Text>
          ),
        },
        {
          key: 'visibleFor',
          title: t('entity_type_category.type'),
          render: (record: IKeyRelationshipTemplate) => (
            <Typography.Text>
              {record.visibleFor === 'contact'
                ? t('entity_type_categories.contact')
                : t('entity_type_categories.entity')}
            </Typography.Text>
          ),
        },
        {
          title: t('entity_type_category.onboarding_question'),
          key: 'isVisible',
          render: (record: IKeyRelationshipTemplate) => (
            <Typography.Text>
              {record.isVisible
                ? t('yes', { ns: 'common' })
                : t('no', { ns: 'common' })}
            </Typography.Text>
          ),
        },
      ];

      return result;
    }, [onActionsClick, t]);

    return <Table columns={columns} {...rest} />;
  },
);

export default EntityTypeCategoriesTable;
