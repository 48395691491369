import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { NotificationMethods } from '../../../../../enums/admin/orac';

// components
import NotificationTypeSelect from '../../../../../components/Forms/FormComponents/SelectInputs/NotificationTypeSelect';
import { SearchInput } from 'components/Fields/SearchInput';
import { Col, Row, Typography } from 'antd';

interface IProps {
  filterStatus: NotificationMethods;
  onFilterChange: (key: string, value: string) => void;
}

const FilterBar = ({ onFilterChange, filterStatus }: IProps) => {
  const { t } = useTranslation('orac');
  const gridSizes = { xl: 6, lg: 8, md: 12, sm: 24, xs: 24 };

  return (
    <Row gutter={[16, 16]}>
      <Col {...gridSizes}>
        <Row>
          <Col span={24}>{t('notifications.table.search_notifications')}</Col>
          <Col span={24}>
            <SearchInput
              size="large"
              placeholder={t(
                'notifications.table.search_notifications_placeholder',
              )}
              onSearch={(searchQuery: string) =>
                onFilterChange('search_by_name', searchQuery)
              }
            />
          </Col>
        </Row>
      </Col>
      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('notifications.select_notifications_type')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <NotificationTypeSelect
              value={filterStatus}
              onChange={(value) => onFilterChange('filter_by_type', value)}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FilterBar;
