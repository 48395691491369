import React, { memo, useCallback, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';
import { forgotUsernameRequestsAPI } from 'api/orac/forgotUsernameRequestsAPI';
import { forgotUsernameRequestAdapter } from 'apiAdapters/orac/forgotUsernameRequestsAdapter';
import { ForgotUsernameRequestStatuses } from 'components/Additional/Statuses/ForgotUsernameRequestStatus';

// components
import FilterBar, { FilterKeys as FilterBarKeys } from './FilterBar';
import { Divider } from 'antd';
import {
  default as TemplateForgotUsernameRequestsTable,
  FilterKeys,
} from 'components/Tables/TableTemplates/ORAC/ForgotUsernameRequestsTable';

const ForgotUsernameRequestsTable = memo(() => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<{
    search: string;
    status: ForgotUsernameRequestStatuses | undefined;
  }>({
    search: '',
    status: undefined,
  });

  const { response, loading } = useFetch(
    () =>
      forgotUsernameRequestsAPI.fetchForgotUsernameRequests({
        page,
        limit: DEFAULT_TABLE_LIMIT,
        name: filters.search,
        status: filters.status,
      }),
    [page, filters],
  );

  const handleFilterChange = useCallback(
    (key: FilterBarKeys | FilterKeys, value?: string) => {
      if (page > 1) {
        setPage(1);
      }

      switch (key) {
        case 'search':
          setFilters((prev) => ({
            ...prev,
            search: value ? value.trim() : '',
          }));
          break;

        case 'filter-by-status':
          setFilters((prev) => ({
            ...prev,
            status: value as ForgotUsernameRequestStatuses,
          }));
          break;
      }
    },
    [],
  );

  return (
    <>
      <FilterBar onFilterChange={handleFilterChange} />
      <Divider />
      <TemplateForgotUsernameRequestsTable
        data={forgotUsernameRequestAdapter.formatRequestsToTableFormat(
          response?.data || [],
        )}
        total={response?.total || 0}
        current={page}
        loading={loading}
        onFilterChange={handleFilterChange}
        onPaginationChange={setPage}
      />
    </>
  );
});

export default ForgotUsernameRequestsTable;
