import React from 'react';

// ** Helpers & Utils **
import { IClientGroup } from '../../../../../../../typings/crm/client-group';

// ** Components **
import UsersTable from './UsersTable';

interface IProps {
  clientGroup: IClientGroup;
}

const ClientGroupInformationTab = ({ clientGroup }: IProps) => {
  return <UsersTable clientGroup={clientGroup} />;
};

export default ClientGroupInformationTab;
