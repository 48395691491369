import React from 'react';

// helpers
import { IClientGroup } from 'typings/crm/client-group';
import { ClientApprovalEntryModel } from 'apiAdapters/approval/clientAccountManagementRulesAdapterAPI';

// components
import ChangeDifferenceSection from './ChangeDifferenceSection';
import ApprovalEntryDetailsSection from './ApprovalEntryDetailsSection';

interface IProps {
  approvalEntry: ClientApprovalEntryModel;
  clientGroup: IClientGroup;
}

const InnerContent = ({ approvalEntry, clientGroup }: IProps) => {
  return (
    <>
      <ApprovalEntryDetailsSection approvalEntry={approvalEntry} />
      <ChangeDifferenceSection
        approvalEntry={approvalEntry}
        clientGroup={clientGroup}
      />
    </>
  );
};

export default InnerContent;
