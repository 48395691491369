import React, { useState } from 'react';

//helpers
import useFetch from '../../../../../hooks/useFetch';
import objectsAPI from '../../../../../api/orac/objectsAPI';
import { DEFAULT_TABLE_LIMIT } from '../../../../../constants/global';

// components
import ObjectAttributesTable from '../../../../../components/Tables/TableTemplates/ORAC/ObjectAttributesTable';

interface IProps {
  objectId: string;
}

const ObjectAttributes = ({ objectId }: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      objectsAPI.fetchAttributesByObjectId(objectId, {
        limit: DEFAULT_TABLE_LIMIT,
        page: currentPage,
      }),
    [objectId, currentPage],
  );

  return (
    <ObjectAttributesTable
      data={response?.data || []}
      current={currentPage}
      onPaginationChange={setCurrentPage}
      loading={loading}
      total={response?.total || 0}
    />
  );
};

export default ObjectAttributes;
