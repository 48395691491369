import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';

// components
import Select, {
  SelectOption,
  SelectProps,
} from 'components/DesignSystem/Core/Select';

type IProps = SelectProps<any>;

type OptionValues = 'true' | 'false' | 'not_selected';

const ResidentSelect = ({ value, onChange, ...rest }: IProps) => {
  const { t } = useTranslation('finance');

  const formattedValue = useMemo<OptionValues>(() => {
    let result: OptionValues = 'not_selected';
    const formattedValue = value as any;

    if (formattedValue === true) {
      result = 'true';
    } else if (formattedValue === false) {
      result = 'false';
    }

    return result;
  }, [value]);

  const getFormattedNewFormValue = (newValue: OptionValues): boolean | null => {
    let result: boolean | null = null;

    if (newValue === 'true') {
      result = true;
    } else if (newValue === 'false') {
      result = false;
    }

    return result;
  };

  const handleChange = (value: OptionValues) => {
    const formattedValue = getFormattedNewFormValue(value);
    onChange && onChange(formattedValue as any);
  };

  const options = useMemo<SelectOption[]>(() => {
    const data = [
      {
        id: 'not_selected',
        label: t('journals.new.resident_tags.not_selected'),
      },
      {
        id: 'false',
        label: t('journals.new.resident_tags.non_resident'),
      },
      {
        id: 'true',
        label: t('journals.new.resident_tags.resident'),
      },
    ];

    return data;
  }, []);

  return (
    <Select
      {...rest}
      value={formattedValue}
      onChange={handleChange as any}
      options={options}
    />
  );
};

export default ResidentSelect;
