import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import FormField from '@core_components/FormField';
import { InputNumber, Typography } from 'antd';

interface IProps {
  onResetClick?: () => void;
}

const InnerForm = ({ onResetClick }: IProps) => {
  const { t } = useTranslation(['crm', 'banking']);
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <FormField
        name="spread"
        label={t('forex.matrix.spread', { ns: 'banking' })}
        labelCol={{ span: 8 }}
        component={StyledInputNumber}
        additionalProps={{
          min: 0,
          max: 100,
          formatter: (value: string) => `${value}%`,
          parser: (value: string) => value.replace('%', ''),
        }}
      />

      {!values.isDefault && (
        <Typography.Link onClick={onResetClick}>
          {t('client_group.foreign_exchange.reset_to_default')}
        </Typography.Link>
      )}
    </>
  );
};

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

export default InnerForm;
