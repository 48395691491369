import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';

// components
import DeleteConfirmation from '../../../../../../../../components/Modals/DeleteConfirmation';
import { Button } from 'antd';

interface IProps {
  children?: React.ReactNode;
  disabled?: boolean;
  isDanger?: boolean;
  onSubmit: (reason: string) => Promise<any>;
}

const ReviewButton = ({ children, disabled, onSubmit, isDanger }: IProps) => {
  const { t } = useTranslation(['compliance', 'common']);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Button
        danger={isDanger}
        disabled={disabled}
        onClick={() => setIsVisible(true)}
      >
        {children}
      </Button>
      <DeleteConfirmation
        isSubmitButtonDanger={false}
        title={t('transactions.review_alert')}
        description={t('transactions.review_alert_desc')}
        submitBtnText={t('modal.save', { ns: 'common' })}
        isVisible={isVisible}
        closeCallback={() => setIsVisible(false)}
        onSubmitCallback={onSubmit}
      />
    </>
  );
};

export default ReviewButton;
