import { CrmItemType } from '../../../enums/crm/crm';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { JournalFormValues } from '../../../components/Modals/TemplateModalDialogs/Finance/AddViewJournalModal';
import { IJournalDocumentModel } from '../../../typings/finance/journal';
import { FormValuesModel as UpdateReconciliationStatusFormValuesModel } from '../../../components/Forms/TemplateForms/Finance/UpdateReconciliationStatusForJournalEntriesForm';
import {
  UpdateJournalEntryReqBodyModel,
  UpdateReconciliationStatusesBody,
} from '../../../api/finance/journal/journalsAPI';

export interface NewJournalRequestBody {
  journalValueDate: number;
  isReversal?: boolean;
  originalJournalId?: number;
  journalEntries: {
    accountNumber: string;
    creditAmount?: number;
    debitAmount?: number;
    description: string;
    relatedParty: string;
    relatedPartyType: CrmItemType | string;
    isDocumentsPublic: boolean;
    documents: IJournalDocumentModel[];
  }[];
}

const journalsAdapter = {
  generateRequestBody: (values: JournalFormValues): NewJournalRequestBody => {
    const journalEntries = values.isReversalJournal
      ? values.reversalJournal?.entries
      : values.journal.entries;

    return {
      journalValueDate: values.journalValueDate.unix(),
      originalJournalId: values.isReversalJournal
        ? values.journalId
        : undefined,
      isReversal: values.isReversalJournal || undefined,
      journalEntries: journalEntries
        ? journalEntries.map((entry) => {
            const {
              account,
              creditAmount,
              debitAmount,
              description,
              relatedPartyId,
              relatedPartyType,
              isResident,
              isVisibleForClient,
              documents,
            } = entry;

            return {
              accountNumber: account?.accountNumber || '',
              creditAmount:
                typeof creditAmount === 'undefined'
                  ? undefined
                  : AccountsHelpers.convertAmountFromIntToBigInt(creditAmount),
              debitAmount:
                typeof debitAmount === 'undefined'
                  ? undefined
                  : AccountsHelpers.convertAmountFromIntToBigInt(debitAmount),
              description,
              isResident,
              relatedParty: relatedPartyId as string,
              relatedPartyType: relatedPartyId
                ? (relatedPartyType as CrmItemType)
                : '',
              isDocumentsPublic: isVisibleForClient,
              documents: documents.map((e) => ({
                dmsId: e.id,
                name: e.name,
                size: String(e.file?.size) as string,
                type: e.file?.contentType as string,
                linkToDownload: e.file?.id as string,
              })),
            };
          })
        : [],
    };
  },

  generateUpdateJournalRequestBody: (
    values: JournalFormValues,
  ): UpdateJournalEntryReqBodyModel[] => {
    const journalEntries = values.journal.entries;

    return journalEntries
      ? journalEntries.map((e) => ({
          id: e?.id as number,
          description: e.description,
          isResident: !!e.isResident,
          relatedParty: e.relatedPartyId || undefined,
          relatedPartyType:
            e.relatedPartyId && e.relatedPartyType
              ? e.relatedPartyType
              : undefined,
        }))
      : [];
  },

  updateReconciliationStatuses: (
    values: UpdateReconciliationStatusFormValuesModel,
    accountNumber: string,
  ): UpdateReconciliationStatusesBody => {
    const { entriesHash } = values;

    const journalEntries = Object.keys(entriesHash).map((key) => ({
      journalEntryId: key as any,
      reconciliationStatus: entriesHash[key as any].newReconciliationStatus,
      comment: entriesHash[key as any].message,
    }));

    return {
      accountNumber,
      journalEntries,
    };
  },
};

export { journalsAdapter };
