import React from 'react';

// helpers
import { Moment } from 'moment';
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';

// constants
import { DEFAULT_DATE_FORMAT } from '../../../../constants/global';

// components
import { Col, DatePicker, Row, Typography } from 'antd';

const AntdPicker = DatePicker as any;

interface IProps {
  dateRangeValue: [Moment, Moment];
  onFilterChange: (key: string, newValue: any) => void;
}

const FilterBar = ({ dateRangeValue, onFilterChange }: IProps) => {
  const { t } = useTranslation('finance');

  return (
    <StyledFilterRow>
      <FilterLabelCol>
        <Typography.Text>{`${t('accounts.table.filter_by_range')}:`}</Typography.Text>
      </FilterLabelCol>

      <Col flex="auto">
        <AntdPicker.RangePicker
          size="large"
          allowClear={false}
          value={dateRangeValue}
          format={DEFAULT_DATE_FORMAT}
          onChange={(newValue: string) =>
            onFilterChange('date-range', newValue)
          }
        />
      </Col>
    </StyledFilterRow>
  );
};

const StyledFilterRow = styled(Row)`
  margin-bottom: 15px;
`;

const FilterLabelCol = styled(Col)`
  display: flex;
  align-items: center;
  padding-right: 10px;
`;

export default FilterBar;
