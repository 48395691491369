import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { debounce } from 'lodash';
import { contactsAPI } from '../../../../api/crm/contact/contactsAPI';

// constants
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  DEFAULT_TABLE_LIMIT,
} from '../../../../constants/global';

// components
import FilterBar from './FilterBar';
import { Divider } from 'antd';
import { default as TemplateContactsTable } from '../../../../components/Tables/TableTemplates/CRM/ContactsTable';

const ContactsTable = () => {
  const [current, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');

  const { response, loading } = useFetch(
    () =>
      contactsAPI.fetchContacts({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        search: searchValue || undefined,
      }),
    [current, searchValue],
  );

  const handleFilterChange = (key: string, value: any) => {
    if (current > 1) {
      setCurrentPage(1);
    }

    switch (key) {
      case 'search':
        setSearchValue(value.trim() || '');
        break;
    }
  };

  return (
    <>
      <FilterBar
        onFilterChange={debounce(
          handleFilterChange,
          AUTOCOMPLETE_DEBOUNCE_DELAY,
        )}
      />
      <Divider />
      <TemplateContactsTable
        total={response?.total || 0}
        current={current}
        data={response?.data || []}
        loading={loading}
        onPaginationChange={setCurrentPage}
      />
    </>
  );
};

export default ContactsTable;
