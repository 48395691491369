import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from 'routes/routes';
import { DateHelpers } from '@helpers/date';
import { OnboardType } from 'enums/crm/crm';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import Link from '@common_components/Texts/Link';
import SectionIntro from '@common_components/Texts/SectionIntro';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import EntityDetailsForm from 'components/Forms/TemplateForms/CRM/EntityDetailsForm';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';
import { Divider } from 'antd';

const InnerContent = () => {
  const { t } = useTranslation(['reporting', 'crm']);
  const form = useFormikContext<FormValuesModel>();
  const history = useHistory();

  const content = useMemo(() => {
    let result: React.ReactNode | null = null;

    switch (form.values.changedEntryDetails.type) {
      case OnboardType.Organization:
        result = <EntityDetailsForm namePrefix="entry" />;
        break;
    }

    return result;
  }, [form]);

  const descriptionsSectionData = useMemo<DescriptionItem[]>(() => {
    const { values } = form;

    function getEntry() {
      let pushParams = { pathname: '', search: '' };

      switch (values.changedEntryDetails.type) {
        case OnboardType.Contact:
          pushParams = {
            pathname: RoutePaths.CRM_Contacts_Edit,
            search: `?id=${values.changedEntryDetails.id}`,
          };
          break;

        case OnboardType.Organization:
          pushParams = {
            pathname: RoutePaths.CRM_Entities_Edit,
            search: `?id=${values.changedEntryDetails.id}`,
          };
          break;
      }

      return (
        <Link onClick={() => history.push(pushParams)}>
          <EllipsisTooltip
            title={values.changedEntryDetails.name}
            maxTextContainerWidth="280px"
          >
            {values.changedEntryDetails.name}
          </EllipsisTooltip>
        </Link>
      );
    }

    return [
      {
        label: t('details_changes.type'),
        description: t(`association_types.${values.changedEntryDetails.type}`, {
          ns: 'crm',
        }),
      },

      {
        label: t('details_changes.name'),
        description: getEntry(),
      },

      {
        label: t('details_changes.date'),
        description: DateHelpers.formatDateToString(
          values.changedEntryDetails.changedDate,
        ),
      },

      {
        label: t('details_changes.user'),
        description: (
          <Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.ORAC_User_Details,
                search: `?id=${values.changedEntryDetails.user.id}`,
              })
            }
          >
            <EllipsisTooltip
              title={values.changedEntryDetails.user.name}
              maxTextContainerWidth="280px"
            >
              {values.changedEntryDetails.user.name}
            </EllipsisTooltip>
          </Link>
        ),
      },
    ];
  }, [form, history, t]);

  return (
    <>
      <SectionIntro
        title={t('details_changes.details_modal.details_section_title')}
        titleVariant="h4"
      />
      <DescriptionSection data={descriptionsSectionData} />
      <Divider />
      <SectionIntro
        title={t('details_changes.details_modal.changes_section_title')}
        titleVariant="h4"
      />
      {content}
    </>
  );
};

export default InnerContent;
