import React, { useMemo } from 'react';

// helpers
import { NATIONALITY_CODES } from '../../../../../constants/countryCodes';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type NationalityFormSelectProps = SelectProps;

const NationalityFormSelect = (props: NationalityFormSelectProps) => {
  const options = useMemo<SelectOption[]>(() => {
    return Object.keys(NATIONALITY_CODES).map((key) => ({
      id: key,
      label: NATIONALITY_CODES[key],
    }));
  }, []);

  return <FormSelect {...props} options={options} />;
};

export default NationalityFormSelect;
