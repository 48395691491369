import * as yup from 'yup';
import { STRING_VALIDATION_RULE } from 'validations/common';

export const UpdateCurrencyValidationSchema = yup.object().shape({
  name: STRING_VALIDATION_RULE,
  code: STRING_VALIDATION_RULE,
  currencyId: STRING_VALIDATION_RULE,
  isActive: yup.boolean().required('This field is required'),
  isAccount: yup.boolean().required('This field is required'),
});
