import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { Limit } from '.';
import { darkTheme } from '@resources/theme/styled';
import { AccountsHelpers } from '@helpers/finance/accounts';

// components
import Text from '@core_components/Text';

interface IProps {
  dataIndex: string;
  record: Limit;
}

const HeaderCell = ({ dataIndex, record }: IProps) => {
  const { t } = useTranslation('crm');

  const renderContent = () => {
    switch (dataIndex) {
      case 'limit':
        return (
          <>
            <Text variant="body1">
              {`${t('client_group.approval.client_transaction_rules.matrix.from_range_value')}: `}
              <Text variant="body1" color={darkTheme.warningColor}>
                {AccountsHelpers.formatAmountToLocaleString(record.from)}
              </Text>
            </Text>

            <Text variant="body1">
              {`${t('client_group.approval.client_transaction_rules.matrix.to_range_value')}: `}
              <Text variant="body1" color={darkTheme.warningColor}>
                {record.to === -1
                  ? t(
                      'client_group.approval.client_transaction_rules.matrix.and_above_option',
                    )
                  : AccountsHelpers.formatAmountToLocaleString(record.to || 0)}
              </Text>
            </Text>
          </>
        );

      default:
        return null;
    }
  };
  return <th>{renderContent()}</th>;
};

export default HeaderCell;
