import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import rolesAPI from '../../../../api/orac/rolesAPI';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';

// components
import FilterBar from './FilterBar';
import { Divider } from 'antd';
import { default as TemplateRolesTable } from '../../../../components/Tables/TableTemplates/ORAC/RolesTable';

interface IProps {
  updateTableTrigger?: any;
}

const RolesTable = ({ updateTableTrigger }: IProps) => {
  const [current, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');

  const { response, loading } = useFetch(
    () =>
      rolesAPI.fetchRoles({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        name: searchValue,
      }),
    [current, searchValue, updateTableTrigger],
  );

  const handleFilterBarChange = (key: string, value: string) => {
    if (current > 1) {
      setCurrentPage(1);
    }

    switch (key) {
      case 'search_by_name':
        setSearchValue(value);
        break;
    }
  };

  return (
    <>
      <FilterBar onFilterChange={handleFilterBarChange} />

      <Divider />

      <TemplateRolesTable
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={current}
        onPaginationChange={setCurrentPage}
      />
    </>
  );
};

export default RolesTable;
