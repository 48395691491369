import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { IAccountCategoryShort } from '../../../../../typings/finance/accountCategories';
import {
  AccountCategoryWithKey,
  formatAccountCategory,
} from '../../../../../apiAdapters/finance/accountCategory';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';

type IProps = RequiredPropsForTableModel<IAccountCategoryShort>;

const AccountCategoriesTable = memo((props: IProps) => {
  const { t } = useTranslation('finance');
  const history = useHistory();

  const renderCategoryName = (record: IAccountCategoryShort) => {
    return !record.isFinancialType ? (
      <Typography.Link
        onClick={() =>
          history.push({
            pathname: RoutePaths.Finance_AccountCategory_Details,
            search: `?id=${record.id}`,
          })
        }
      >
        <AccountCategoryWrapper>
          {record.id} - {record.accountCategoryName}
        </AccountCategoryWrapper>
      </Typography.Link>
    ) : (
      <AccountCategoryWrapper>
        {record.id} - {record.accountCategoryName}
      </AccountCategoryWrapper>
    );
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('account_categories.table.financial_type'),
        key: 'financial_type_name',
        render: renderCategoryName,
      },
    ];

    return result;
  }, [t]);

  const renderExpandIcon = (record: {
    onExpand: any;
    record: AccountCategoryWithKey;
  }) => {
    const shouldRenderIcon = !!record.record.children?.length;
    return (
      shouldRenderIcon && (
        <DownOutlined onClick={(e) => record.onExpand(record.record, e)} />
      )
    );
  };

  // Need to add key value to each data item, key is required by nested table
  const formattedAccountCategories = useMemo<AccountCategoryWithKey[]>(() => {
    let result: AccountCategoryWithKey[] = [];

    if (props.data.length) {
      result = props.data.map(formatAccountCategory);
    }

    return result;
  }, [props.data]);

  return (
    <Table
      {...props}
      hidePagination
      data={formattedAccountCategories}
      total={formattedAccountCategories.length}
      columns={columns}
      expandable={{
        rowExpandable: ({ children }: AccountCategoryWithKey) =>
          !!children?.length,
        expandIcon: renderExpandIcon,
      }}
    />
  );
});

const AccountCategoryWrapper = styled.div`
  display: inline-block;
  top: -1px;
  position: relative;
`;

export default AccountCategoriesTable;
