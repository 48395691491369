import React from 'react';

// helpers
import { DocumentAssociationTypes } from '../../../../../../enums/crm/crm';

// components
import Autocomplete from '../../../../../Fields/Autocomplete';
import EntityRequestsAutocomplete from '../../Documents/EntityRequestsAutocomplete';
import ContactRequestsAutocomplete from '../../Documents/ContactRequestsAutocomplete';
import ClientGroupRequestsAutocomplete from '../../Documents/ClientGroupRequestsAutocomplete';
import AccountRecoveryRequestsAutocomplete from '../../Documents/AccountRecoveryRequestsAutocomplete';

const AssociationObjectAutocomplete = (props: any) => {
  const renderAssociationObject = () => {
    switch (props.type) {
      case DocumentAssociationTypes.AccountRecovery: {
        return <AccountRecoveryRequestsAutocomplete {...props} />;
      }
      case DocumentAssociationTypes.ClientGroup: {
        return <ClientGroupRequestsAutocomplete {...props} />;
      }
      case DocumentAssociationTypes.Contact: {
        return <ContactRequestsAutocomplete {...props} />;
      }
      case DocumentAssociationTypes.Entity: {
        return <EntityRequestsAutocomplete {...props} />;
      }
      default:
        return <Autocomplete {...props} disabled />;
    }
  };

  return <>{renderAssociationObject()}</>;
};
export default AssociationObjectAutocomplete;
