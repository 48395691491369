import React from 'react';

// helpers
import { FormikHelpers } from 'formik';
import { ShortDocumentModel } from '../../../../Forms/FormComponents/UploadDocumentField';
import { AdditionalFieldType, OnboardType } from '../../../../../enums/crm/crm';
import { AddAdditionalDocumentValidationSchema } from '../../../../../validations/crm/clientGroups';

// components
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import InnerForm from './InnerForm';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export interface FormValuesModel {
  fieldId?: string;
  itemId: string;
  itemType: OnboardType;
  reviewProcess: {
    type: 'contact-review' | 'client-group-review';
    entryId: string;
  };
  name: string;
  description: string;
  type: AdditionalFieldType | null;
  options?: {
    documentRequiresCertification?: boolean;
    selectOptions?: string[];
    selectOptionsValues?: string[] | null;
    formDocument?: ShortDocumentModel[] | null;
    formDocumentId?: string;
  };
}

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
}

const AdditionalDocumentModalDialog = ({
  title,
  isVisible,
  disabled,
  initialValues,
  onSubmit,
  closeCallback,
}: IProps) => {
  const handleSubmit = async (
    values: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    await onSubmit(values, formikHelpers);
  };

  return (
    <Form<FormValuesModel>
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={AddAdditionalDocumentValidationSchema}
      confirmExitWithoutSaving
      renderForm={
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          submitButtonProps={{
            disabled: disabled,
          }}
        >
          {initialValues && <InnerForm />}
        </ModalDialog>
      }
    />
  );
};

export default AdditionalDocumentModalDialog;
