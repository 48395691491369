import React, { useEffect, useState } from 'react';

// helpers
import { AutocompleteOption } from '@core_components/Autocomplete';
import { correspondentBanksAPI } from '../../../../../../api/banking/correspondentBanksAPI';
import { CorrespondentBankModel } from '../../../../../../typings/banking/correspondentBanks';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import FormAutocomplete, {
  FormAutocompleteProps,
} from '@common_components/Form/FormAutocomplete';

interface IProps extends FormAutocompleteProps<CorrespondentBankModel> {
  currency?: number;
}

const CorrespondentBankFormAutocomplete = ({ currency, ...rest }: IProps) => {
  const [updateOptionsTrigger, updateOptions] = useState<any>();

  useEffect(() => {
    updateOptions({});
  }, [currency]);

  const fetchData = async (searchText: string) => {
    const { data } = await correspondentBanksAPI.fetchCorrespondentBanks({
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      page: 1,
      name: searchText,
      holdCurrency: currency,
    });

    return data.map<AutocompleteOption<CorrespondentBankModel>>((bank) => ({
      id: bank.id,
      label: bank.name,
      model: bank,
    }));
  };

  return (
    <FormAutocomplete
      {...rest}
      fetchData={fetchData}
      refreshAutocompleteTrigger={updateOptionsTrigger}
    />
  );
};

export default CorrespondentBankFormAutocomplete;
