import React, { memo, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { ClientReviewStatus } from '../../../../../../../../enums/crm/crm';
import {
  IClientGroupResponse,
  clientGroupsAPI,
} from '../../../../../../../../api/crm/clientGroup/clientGroupsAPI';

// components
import EllipsisTooltip from '../../../../../../../../components/Tooltips/EllipsisTooltip';
import RequestDataUpdateModal, {
  FormValuesModel,
} from '../../../../../../../../components/Modals/TemplateModalDialogs/CRM/RequestDataUpdateModal';
import { Button, Col, Modal, Row, Typography, message } from 'antd';

interface IProps {
  clientGroupData: IClientGroupResponse;
}

const RequestStructureChange = memo(({ clientGroupData }: IProps) => {
  const { t } = useTranslation(['crm', 'common']);
  const [actualMessage, setActualMessage] = useState<string | null>(
    clientGroupData.onboardingProcess.review.structure.comment || '',
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const initialFormValues = useMemo<FormValuesModel>(
    () => ({ message: actualMessage || '' }),
    [actualMessage],
  );

  const handleSubmit = async (values: FormValuesModel) => {
    await clientGroupsAPI.requestStructureChartUpdate(
      clientGroupData.clientGroup._id,
      {
        status: 'invalid',
        comment: values.message.trim(),
      },
    );
    setActualMessage(values.message);
    message.success(
      actualMessage
        ? t(
            'client_group.structure.request_structure_update.edit.success_submit_message',
          )
        : t(
            'client_group.structure.request_structure_update.create.success_submit_message',
          ),
    );
  };

  const handleMessageDelete = async () => {
    Modal.confirm({
      icon: null,
      closable: true,
      maskClosable: true,
      width: 600,
      title: t('client_group.structure.request_structure_update.delete.title'),
      content: t(
        'client_group.structure.request_structure_update.delete.confirmation_text',
      ),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        await clientGroupsAPI.requestStructureChartUpdate(
          clientGroupData.clientGroup._id,
          { status: 'notReviewed', comment: '' },
        );
        message.success(
          t(
            'client_group.structure.request_structure_update.delete.success_submit_message',
          ),
        );
        setActualMessage(null);
        setIsModalVisible(false);
      },
    });
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <StyledRow justify="space-between" align="middle" wrap={false}>
        <Col span={16}>
          {actualMessage && (
            <EllipsisTooltip title={actualMessage}>
              <StyledText>{actualMessage}</StyledText>
            </EllipsisTooltip>
          )}
        </Col>
        <Col>
          {clientGroupData.clientGroup.reviewStatus ===
            ClientReviewStatus.ManagerReview && (
            <Button onClick={() => setIsModalVisible(true)}>
              {actualMessage
                ? t(
                    'client_group.structure.request_structure_update.edit.button_text',
                  )
                : t(
                    'client_group.structure.request_structure_update.create.button_text',
                  )}
            </Button>
          )}
        </Col>
      </StyledRow>

      <RequestDataUpdateModal
        title={
          actualMessage
            ? t('client_group.structure.request_structure_update.edit.title')
            : t('client_group.structure.request_structure_update.create.title')
        }
        isVisible={isModalVisible}
        closeCallback={handleModalClose}
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        onDelete={actualMessage ? handleMessageDelete : undefined}
      />
    </>
  );
});

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const StyledText = styled(Typography.Text)`
  color: ${({ theme }) => theme.warningColor};
`;

export default RequestStructureChange;
