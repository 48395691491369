import React, { memo, useEffect, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useQueryParam from '@hooks/useQueryParam';
import useTranslation from '@hooks/useTranslation';
import { entityAPI } from 'api/crm/entity/entityAPI';
import { RoutePaths } from 'routes/routes';
import { EntityHelpers } from '@helpers/crm/entity';
import { PageLayoutHelperPropsModel } from 'layouts/PageLayout';

// components
import EntityDetails from 'modules/CRM/Entities/EntityDetails';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';

type IProps = PageLayoutHelperPropsModel;

const EntityDetailsPage = memo(({ setHeaderOptions }: IProps) => {
  const { t } = useTranslation('crm');
  const entityId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.CRM_Entities,
  });
  const [updateEntityTrigger, updateEntity] = useState({});

  const { response, loading } = useFetch(
    () => (entityId ? entityAPI.getEntityById(entityId) : null),
    [entityId, updateEntityTrigger],
  );

  useEffect(() => {
    if (response) {
      const entityNameString = EntityHelpers.getEntityName(response);
      setHeaderOptions({
        title: t('entity.entity_details_title', { name: entityNameString }),
        cardTitle: t('entity.new.entityInformation'),
      });
    }
  }, [response]);

  return (
    <LoadingWrapper loading={loading}>
      {response && (
        <EntityDetails
          entity={response}
          updateEntity={() => updateEntity({})}
        />
      )}
    </LoadingWrapper>
  );
});

export default EntityDetailsPage;
