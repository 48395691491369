import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { useFormikContext } from 'formik';
import { DISABLED_FORM_STATUS } from 'constants/form';

// components
import SubmitButton from '../../../Buttons/SubmitButton';
import { Button, Col, Modal, Row } from 'antd';

export interface FormValuesModel {
  isEditMode: boolean;
}

const FormEditModeButtons = memo(() => {
  const { t } = useTranslation(['common', 'form']);
  const { values, resetForm, dirty, initialValues, setStatus } =
    useFormikContext<FormValuesModel>();

  const turnOnEditMode = () => {
    updateIsEditStatus(true);
    setStatus(DISABLED_FORM_STATUS);
  };

  const turnOffEditMode = () => {
    if (dirty) {
      Modal.confirm({
        title: t('cancel_without_save_title', { ns: 'form' }),
        content: t('cancel_without_save_helper', { ns: 'form' }),
        okText: t('yes'),
        cancelText: t('no'),
        onOk: () => {
          resetForm();
          updateIsEditStatus(false);
          setStatus(null);
        },
      });
    } else {
      updateIsEditStatus(false);
      setStatus(null);
    }
  };

  const updateIsEditStatus = (newStatus: boolean) => {
    resetForm({ values: { ...initialValues, isEditMode: newStatus } });
  };

  return (
    <StyledRow justify="end" gutter={[16, 16]}>
      {values.isEditMode ? (
        <>
          <Col>
            <StyledButton onClick={turnOffEditMode}>
              {t('modal.cancel')}
            </StyledButton>
          </Col>
          <Col>
            <StyledSubmitButton size="middle" type="primary">
              {t('modal.save')}
            </StyledSubmitButton>
          </Col>
        </>
      ) : (
        <Col flex="150px">
          <StyledButton type="primary" onClick={turnOnEditMode}>
            {t('modal.edit')}
          </StyledButton>
        </Col>
      )}
    </StyledRow>
  );
});

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
`;

export default FormEditModeButtons;
