import React, { memo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import PassportItem from './PassportItem';

const PassportsSection = memo(() => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  if (!values.passports?.length) {
    return t('contacts.new.passports_were_not_provided');
  }

  return (
    <>
      {values.passports.map((e, i) => (
        <PassportItem key={e.id} fieldName={`passports.${i}`} />
      ))}
    </>
  );
});

export default PassportsSection;
