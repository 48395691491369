import React from 'react';

// helpers utils
import { SubRouteModel } from '../typings/routes';
import { PageLayoutHelperPropsModel } from '../layouts/PageLayout';

// components
import NavTemplate from '../modules/Layout/Navigation/NavTemplate';

interface IProps extends PageLayoutHelperPropsModel {
  route: SubRouteModel;
}

const Navigation = ({ route }: IProps) => {
  return <NavTemplate routes={route.childRoutes || []} />;
};

export default Navigation;
