import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import moment from 'moment';
import useTranslation from '@hooks/useTranslation';
import { INewNoteBody, noteAPI } from 'api/note/noteAPI';
import { NoteMessageType, NoteType } from 'enums/crm/crm';

// components
import Button from '@core_components/Button';
import NoteModalDialog, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/NoteModalDialog';
import { Col, Row, message } from 'antd';

interface IProps {
  onAdd: () => void;
  association: { id: string; type: NoteType };
}

const AddNote = ({ onAdd, association }: IProps) => {
  const { t } = useTranslation('crm');
  const [isVisible, setIsVisible] = useState(false);

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!isVisible) {
      return null;
    }

    return {
      eventDate: moment(),
      text: '',
      type: NoteMessageType.Compliance,
      crmItems: [],
    };
  }, [isVisible]);

  const handleModalClose = (shouldUpdate?: boolean) => {
    if (shouldUpdate) {
      onAdd();
    }

    setIsVisible(false);
  };

  const handleSubmit = async (values: FormValuesModel) => {
    const assignToFiltered =
      values.crmItems && values.crmItems.length
        ? values.crmItems.filter((e) => e.itemId !== association.id)
        : [];

    const reqBody = {
      text: values.text,
      type: values.type,
      eventDate: values.eventDate
        ? values.eventDate.toString()
        : moment().toString(),
      assignTo: [
        { itemId: association.id, itemType: association.type },
        ...assignToFiltered,
      ],
      documentIds: values.documents?.map((document) => document.id),
    } as INewNoteBody;

    await noteAPI.createNote(reqBody);
    message.success(t('entity.notes.successAdd'));
  };

  return (
    <>
      <StyledRow justify="end">
        <Col>
          <Button
            type="primary"
            size="large"
            onClick={() => setIsVisible(true)}
          >
            {t('entity.notes.addNote')}
          </Button>
        </Col>
      </StyledRow>

      <NoteModalDialog
        hideNoteTypeField
        onSubmit={handleSubmit}
        isVisible={isVisible}
        closeCallback={handleModalClose}
        initialValues={initialFormValues}
      />
    </>
  );
};

const StyledRow = styled(Row)`
  margin: ${({ theme }) => theme.marginMd} 0;
`;

export default AddNote;
