import React, { useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { ShortDocumentModel } from 'typings/crm/relationships';
import { IAdditionalFieldAnswer } from '../../../../typings/crm/entity';
import { FormikValues, useFormikContext } from 'formik';

// constants
import { IDocumentAssociationInfo } from '../../../../modules/CRM/Documents/UploadDocumentsDialog';
import {
  AdditionalFieldType,
  RelationshipRelatedTypes,
} from '../../../../enums/crm/crm';

// components
import FormField from '@core_components/FormField';
import DatePicker from '../DatePicker';
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import DocumentsField from '../DocumentsField';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import { Checkbox, Input, InputNumber, Select } from 'antd';

export interface AdditionalFieldModel {
  name: string;
  description?: string;
  type: AdditionalFieldType;
  relatedTo: RelationshipRelatedTypes;
  options: {
    selectOptions: string[];
    selectOptionsValues?: string[];
    formDocumentId?: string;
    formDocument?: ShortDocumentModel[] | null;
    isForRegulatedOnly: boolean;
    isDirectClientOnly: boolean;
    onboardingRequired: boolean;
    documentRequiresCertification: boolean;
  };
  isTemplateField?: boolean;
  _id?: string;
}

interface IProps {
  name: string;
  disabled?: boolean;
  additionalField: IAdditionalFieldAnswer;
  hideFieldLabel?: boolean;
  documentAssociation?: IDocumentAssociationInfo;
  showNotProvided?: boolean;
  notProvidedName?: string;
}

const AdditionalField = ({
  name,
  disabled,
  hideFieldLabel,
  additionalField,
  documentAssociation,
  showNotProvided,
  notProvidedName,
}: IProps) => {
  const { t } = useTranslation(['crm', 'form']);

  const form = useFormikContext<FormikValues>();
  const [isOptional, setOptional] = useState(
    showNotProvided &&
      !!notProvidedName &&
      !!form.getFieldProps(notProvidedName).value,
  );
  const value = form.getFieldProps(name).value;
  const hasValue = Array.isArray(value) ? !!value.length : !!value;
  const notProvidedSelectedName = `${notProvidedName}Selected`;

  useEffect(() => {
    if (showNotProvided && notProvidedName) {
      form.setFieldValue(notProvidedSelectedName, isOptional);
      form.setFieldValue(isOptional ? name : notProvidedName, undefined);
    }
  }, [isOptional]);

  const renderField = (field: IAdditionalFieldAnswer) => {
    const baseInputProps = {
      label: (
        <DivAlignCenter>
          {!hideFieldLabel && <div>{field.name}</div>}
          {field.isRemoved && (
            <div>
              <StyledInfoTooltip
                tooltipContent={t(
                  'entity.relationships.relationship_modal.removed_additional_field_info',
                )}
              />
            </div>
          )}
        </DivAlignCenter>
      ),
      name,
      disabled,
    };

    if (isOptional && notProvidedName) {
      return (
        <FormField
          name={notProvidedName}
          component={Input}
          disabled={disabled}
          placeholder={t('entity.relationships.noAdditionalFieldReason')}
        />
      );
    }

    switch (field.type) {
      case AdditionalFieldType.String:
        return (
          <FormField
            {...baseInputProps}
            component={Input}
            placeholder={t('enterPlaceholder', {
              ns: 'form',
              fieldName: t('entity.relationships.value').toLowerCase(),
            })}
          />
        );

      case AdditionalFieldType.Date:
        return (
          <FormField
            {...baseInputProps}
            component={StyledDatePicker}
            placeholder={t('selectPlaceholder', {
              ns: 'form',
              fieldName: t('entity.relationships.value').toLowerCase(),
            })}
            additionalProps={{ allowClear: true }}
          />
        );

      case AdditionalFieldType.Number:
      case AdditionalFieldType.Percentage:
        return (
          <FormField
            {...baseInputProps}
            component={InputNumber}
            additionalProps={{ style: { width: '100%' } }}
            placeholder={t('selectPlaceholder', {
              ns: 'form',
              fieldName: t('entity.relationships.value').toLowerCase(),
            })}
          />
        );

      case AdditionalFieldType.Select: {
        const children = additionalField.options.selectOptions?.map((el, i) => (
          <Select.Option key={`${el}-${i}`} value={el}>
            {el}
          </Select.Option>
        ));

        return (
          <FormField
            {...baseInputProps}
            component={Select}
            additionalProps={{ children }}
            placeholder={t('selectPlaceholder', {
              ns: 'form',
              fieldName: t('entity.relationships.value').toLowerCase(),
            })}
          />
        );
      }

      case AdditionalFieldType.Document:
        return (
          <FormField
            {...baseInputProps}
            component={DocumentsField}
            additionalProps={{
              allowClear: true,
              mode: 'multiple',
              association: documentAssociation,
              tags: [field.name],
              description: field.description,
            }}
          />
        );

      case AdditionalFieldType.Form: {
        const {
          options: { formDocument },
        } = field;
        return (
          <FormField
            {...baseInputProps}
            component={DocumentsField}
            additionalProps={{
              formDocument: formDocument,
              association: documentAssociation,
            }}
          />
        );
      }

      case AdditionalFieldType.Boolean: {
        return <FormField {...baseInputProps} component={Checkbox} />;
      }

      default:
        return null;
    }
  };

  return (
    <>
      {showNotProvided && (
        <FormField
          name={notProvidedSelectedName}
          component={StyledCheckbox}
          disabled={disabled || hasValue}
          onChange={(e: any) => setOptional(e.target.checked)}
          shouldShowErrorMessage={false}
          additionalProps={{
            children: t('entity.relationships.noAdditionalFieldValue'),
          }}
        />
      )}
      {renderField(additionalField)}
    </>
  );
};

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: ${({ theme }) => theme.marginXXs};
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
`;

const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default AdditionalField;
