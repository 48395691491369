import React from 'react';

// Helpers & Utils
import { SelectProps } from 'antd/lib/select';

// Components
import { Select } from 'antd';
import { COUNTRY_CODES } from '../../../../../constants/countryCodes';

type IProps = SelectProps<any>;

const CountrySelect = ({ value, ...rest }: IProps) => {
  const renderCountries = () => {
    return Object.keys(COUNTRY_CODES).map((key) => (
      <Select.Option key={key} value={key}>
        {COUNTRY_CODES[key]}
      </Select.Option>
    ));
  };

  return (
    <Select
      {...rest}
      value={value || undefined}
      showSearch
      filterOption={(input: string, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {renderCountries()}
    </Select>
  );
};

export default CountrySelect;
