import React, { useEffect } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from '../../../layouts/PageLayout';
import { GENESIS_COUNTRY_RISK_RATINGS_DOCUMENTATION_LINK } from '../../../constants/externalLinks';

// components
import TooltipToDocumentation from '../../../components/Tooltips/TooltipToDocumentation';
import { default as CountryRiskRatingsContent } from '../../../modules/Compliance/CountryRiskRatings';

type IProps = PageLayoutHelperPropsModel;

const CountryRiskRatings = ({ setHeaderOptions }: IProps) => {
  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation
          link={GENESIS_COUNTRY_RISK_RATINGS_DOCUMENTATION_LINK}
        />
      ),
    });
  }, []);

  return <CountryRiskRatingsContent />;
};

export default CountryRiskRatings;
