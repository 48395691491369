import React from 'react';

// helpers
import useUserAccess from '../../../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { TableColumnModel } from '@core_components/Table';
import { ForexSpreadLimitModel } from '../../../../../../../typings/banking/foreignExchange';

// components
import SpreadShortDetails from './SpreadShortDetails';
import { StyledTitleParagraph } from '..';

interface IProps extends TableColumnModel {
  colIndex: number;
  record: ForexSpreadLimitModel[] | null;
  onSpreadSelect: (spreadId: number) => void;
}

const TableCell = ({ dataIndex, record, colIndex, onSpreadSelect }: IProps) => {
  const { t } = useTranslation('banking');
  const [hasEditAccess] = useUserAccess([
    'Banking_FeeSchedule_SpreadMatrixSection_Edit',
  ]);

  const renderContent = (dataIndex: any) => {
    let result = null;

    switch (dataIndex) {
      case 'title-col':
        result = (
          <StyledTitleParagraph>
            {t('forex.matrix.default_spread')}
          </StyledTitleParagraph>
        );
        break;

      default:
        result = record && (
          <SpreadShortDetails
            canEdit={hasEditAccess}
            spread={record[colIndex]}
            onEditClick={onSpreadSelect}
          />
        );
    }

    return result;
  };

  return <td>{renderContent(dataIndex)}</td>;
};

export default TableCell;
