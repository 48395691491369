import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../hooks/useTranslation';
import { RoutePaths } from '../../../routes/routes';

// components
import NavigationOption from './NavigationOption';
import { Col, Row } from 'antd';

// icons
import { ReactComponent as TransactionsIcon } from '../../../resources/images/icons/swap-outline.svg';
import { ReactComponent as OngoingReviewsIcon } from '../../../resources/images/icons/sync-outline.svg';
import { ReactComponent as ReviewConfiguration } from '../../../resources/images/icons/options-outline.svg';
// import { ReactComponent as DashboardIcon } from '../../../resources/images/icons/globe-outline.svg';
// import { ReactComponent as OnboardingIcon } from '../../../resources/images/icons/people-outline.svg';
// import { ReactComponent as DocumentationIcon } from '../../../resources/images/icons/file-text-outline.svg';
// import { ReactComponent as DynamicReviewsIcon } from '../../../resources/images/icons/shuffle-outline.svg';

// TODO:
// 1) Integrate real API
// 2) After click on NavigationOption user should be navigated to the related page

const CompliancePage = () => {
  const { t } = useTranslation('page_titles');

  const navigationOptions = useMemo(() => {
    return [
      // {
      //   title: t('Dashboard'),
      //   icon: <DashboardIcon />,
      //   postRequestsCount: 65,
      //   nonPostRequestsCount: 14,
      //   path: RoutePaths.Compliance_Dashboard,
      // },
      // {
      //   title: t('Onboarding'),
      //   icon: <OnboardingIcon />,
      //   postRequestsCount: 12,
      //   nonPostRequestsCount: 5,
      //   path: RoutePaths.Compliance_Onboarding,
      // },
      {
        title: t('Ongoing Reviews'),
        icon: <OngoingReviewsIcon />,
        postRequestsCount: 5,
        nonPostRequestsCount: 5,
        path: RoutePaths.Compliance_OngoingReviews,
      },
      {
        title: t('Transactions'),
        icon: <TransactionsIcon />,
        postRequestsCount: 10,
        nonPostRequestsCount: 2,
        path: RoutePaths.Compliance_Transactions,
      },
      {
        title: t('Review Configuration'),
        icon: <ReviewConfiguration />,
        hidePendingApprovals: true,
        path: RoutePaths.Compliance_ReviewConfiguration,
      },
      // {
      //   title: t('pages.Documentation'),
      //   icon: <DocumentationIcon />,
      //   postRequestsCount: 12,
      //   nonPostRequestsCount: 5,
      //   path: RoutePaths.Compliance_Documentation,
      // },
      // {
      //   title: t('pages.Dynamic Reviews'),
      //   icon: <DynamicReviewsIcon />,
      //   postRequestsCount: 13,
      //   nonPostRequestsCount: 7,
      //   path: RoutePaths.Compliance_DynamicReviews,
      // },
    ];
  }, []);

  return (
    <Row gutter={[16, 16]}>
      {navigationOptions.map(
        (
          {
            title,
            icon,
            hidePendingApprovals,
            path,
            postRequestsCount = 0,
            nonPostRequestsCount = 0,
          },
          i,
        ) => (
          <Col key={i} span={8}>
            <NavigationOption
              icon={icon}
              title={title}
              navigateToPath={path}
              hidePendingApprovals={hidePendingApprovals}
              postRequestsCount={postRequestsCount}
              nonPostRequestsCount={nonPostRequestsCount}
            />
          </Col>
        ),
      )}
    </Row>
  );
};

export default CompliancePage;
