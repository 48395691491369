import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { RDCReviewStatuses } from '../../../../../../../enums/compliance/reviewConfiguration';
import { useReviewProcessContext } from '../../../../../../../hooks/domainHooks/useReviewProcess';
import { ReviewProcessAlertModel } from '../../../../../../../typings/compliance/reviewProcess';

// components
import ReviewButton from './ReviewButton';
import { Col, Row } from 'antd';

interface IProps {
  alert: ReviewProcessAlertModel;
}

const ReviewButtons = ({ alert }: IProps) => {
  const { t } = useTranslation('compliance');
  const { approveAlert, rejectAlert } = useReviewProcessContext();

  const canApprove = alert.reviewResult.status !== RDCReviewStatuses.NotMatched;
  const canReject = alert.reviewResult.status !== RDCReviewStatuses.Matched;

  return (
    <StyledRow gutter={[16, 16]} justify="end">
      <Col>
        <ReviewButton
          isDanger
          onSubmit={(reason) => rejectAlert(alert, reason)}
          disabled={!canReject}
        >
          {t(`review_statuses.${RDCReviewStatuses[RDCReviewStatuses.Matched]}`)}
        </ReviewButton>
      </Col>

      <Col>
        <ReviewButton
          onSubmit={(reason) => approveAlert(alert, reason)}
          disabled={!canApprove}
        >
          {t(
            `review_statuses.${RDCReviewStatuses[RDCReviewStatuses.NotMatched]}`,
          )}
        </ReviewButton>
      </Col>
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default ReviewButtons;
