import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { accountsAdapter } from '../../../apiAdapters/crm/onboarding/accountsAdapter';
import { OnboardAccountType } from '../../../enums/crm/crm';
import { IOnboardingAccount } from '../../../typings/finance/account';
import { BankAccountStatuses } from 'enums/banking/accounts';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';

export interface IRangeValue {
  min?: number;
  max?: number;
}

export interface IAccountResponse {
  _id: string;
  type: OnboardAccountType;
  clientGroupId: string;
  number?: string;

  currencyId?: number;
  currencyIsoCode: string;
  currencyName: string;
  status: BankAccountStatuses;

  purpose?: string;
  initialDeposit?: IRangeValue;
  initialDepositOrigin?: string;
  initialDepositSource?: string;

  jurisdictions?: string[];
  incomingWiresNumber?: IRangeValue;
  outgoingWiresNumber?: IRangeValue;
  estimatedIncomingFunds?: IRangeValue;
  estimatedOutgoingFunds?: IRangeValue;
}

interface FetchAccountsSupplementaryParams extends FetchParamsModel {
  clientGroupId: number;
}

const accountsAPI = {
  fetchAccounts: (clientGroupId?: string) => {
    if (!clientGroupId) {
      return Promise.resolve([]);
    }

    return APIService.get<FetchResponseModel<IAccountResponse>>(
      `${APIConfig.crmApi}/onboarding/accounts/${clientGroupId}`,
    ).then<IOnboardingAccount[]>(({ data }) =>
      accountsAdapter.fetchAccounts(data),
    );
  },

  fetchAccountsSupplementary: (params: FetchAccountsSupplementaryParams) => {
    return APIService.get<FetchResponseModel<IAccountResponse>>(
      `${APIConfig.accountingApi}/accounts/supplementary`,
      { params },
    ).then<IOnboardingAccount[]>(({ data }) =>
      accountsAdapter.fetchAccounts(data),
    );
  },
};

export { accountsAPI };
