import React, { useState } from 'react';

// components
import GroupsTable from './GroupsTable';
import AddGroupToUser from './AddGroupToUser';

import { Col, Row } from 'antd';

interface IProps {
  userId: string;

  access?: {
    canEditGroup?: boolean;
    canViewRoles?: boolean;
    canViewPermissions?: boolean;
  };
}

const GroupsTab = ({ userId, access }: IProps) => {
  const [updateTableTrigger, updateTable] = useState({});

  return (
    <>
      {access?.canEditGroup && (
        <Row justify="end">
          <Col>
            <AddGroupToUser userId={userId} onUpdate={() => updateTable({})} />
          </Col>
        </Row>
      )}

      <GroupsTable
        userId={userId}
        updateTableTrigger={updateTableTrigger}
        access={access}
      />
    </>
  );
};

export default GroupsTab;
