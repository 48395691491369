import React, { useState } from 'react';

// helpers
import { AccountsHelpers } from '@helpers/finance/accounts';
import { ForexSpreadLimitModel } from '../../../../../typings/banking/foreignExchange';
import {
  foreignExchangeAPI,
  UpdateSpreadForTreasuryModel,
} from '../../../../../api/banking/foreignExchangeAPI';

// components
import InnerMatrix from './InnerMatrix';
import EditSpreadModal, {
  FormValuesModel,
} from '../../../../../components/Modals/TemplateModalDialogs/Accounting/ForeignExchange/EditSpreadModal';

interface IProps {
  spreadRanges: ForexSpreadLimitModel[];
  updateSpreadRangesCallback: () => void;
}

const SpreadMatrix = ({ spreadRanges, updateSpreadRangesCallback }: IProps) => {
  const [selectedSpread, setSelectedSpread] =
    useState<ForexSpreadLimitModel | null>(null);

  const handleSpreadSelect = (spreadId: number) => {
    const spread = spreadRanges.find((e) => e.id === spreadId);
    setSelectedSpread(spread as ForexSpreadLimitModel);
  };

  const hadleSpreadModalClose = (wasUpdated?: boolean) => {
    setSelectedSpread(null);
    wasUpdated && updateSpreadRangesCallback();
  };

  const handleEditSpreadSubmit = async (values: FormValuesModel) => {
    const updatedSpread: UpdateSpreadForTreasuryModel = {
      isAboveThreshold: !!values.requireApproval,
      spread: AccountsHelpers.convertAmountFromIntToBigInt(values.spread) || 0,
    };

    await foreignExchangeAPI.updateTreasurySpreadById(
      values.spreadId,
      updatedSpread,
    );
  };

  return (
    <>
      <InnerMatrix
        spreadRanges={spreadRanges}
        onSpreadSelect={handleSpreadSelect}
      />

      <EditSpreadModal
        isVisible={!!selectedSpread}
        spread={selectedSpread as ForexSpreadLimitModel}
        closeCallback={hadleSpreadModalClose}
        onSubmit={handleEditSpreadSubmit}
      />
    </>
  );
};

export default SpreadMatrix;
