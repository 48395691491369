import base64 from './base64';
import {
  ChallengeForLoginModel,
  ChallengeForRegistrationModel,
} from '../typings/profile/fido';

const FidoHelpers = {
  preformatChallengeForRegistration: function (
    credentialsChallengeRequest: ChallengeForRegistrationModel,
  ): PublicKeyCredentialCreationOptions {
    return {
      ...credentialsChallengeRequest,
      challenge: base64.base64ToBuffer(
        String(credentialsChallengeRequest.challenge),
      ),
      excludeCredentials: credentialsChallengeRequest.excludeCredentials.map(
        (e) => ({
          id: base64.base64ToBuffer(String(e.id)),
          type: e.type,
        }),
      ),
      user: {
        ...credentialsChallengeRequest.user,
        id: base64.base64ToBuffer(String(credentialsChallengeRequest.user.id)),
      },
      authenticatorSelection: {
        userVerification: 'required',
        authenticatorAttachment: 'cross-platform',
      },
    };
  },

  preformatChallengeForLogin: function (
    challenge: ChallengeForLoginModel,
  ): PublicKeyCredentialRequestOptions {
    return {
      challenge: base64.base64ToBuffer(String(challenge.challenge)),
      allowCredentials: challenge.allowCredentials.map((e) => ({
        ...e,
        id: base64.base64ToBuffer(String(e.id)),
      })),
    };
  },

  publicKeyCredentialToJSON: function (item: Credential | any): unknown {
    if (item instanceof Array) {
      return item.map(FidoHelpers.publicKeyCredentialToJSON);
    }

    if (item instanceof ArrayBuffer) {
      return base64.bufferToBase64(new Uint8Array(item));
    }

    if (item instanceof Object) {
      const obj: { [n: string]: unknown } = {};

      for (const key in item) {
        obj[key] = FidoHelpers.publicKeyCredentialToJSON(item[key]);
      }

      return obj;
    }

    return item;
  },
};

export { FidoHelpers };
