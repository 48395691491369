import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { StructureMessageValidationSchema } from 'validations/crm/clientGroups';

// components
import InnerForm from './InnerForm';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  onSubmit: (values: FormValuesModel) => Promise<void>;
}

export type FormValuesModel = {
  message: string;
};

const StructureMessageModalDialog = ({
  onSubmit,
  initialValues,
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <Form
      enableReinitialize
      confirmExitWithoutSaving
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={StructureMessageValidationSchema}
      renderForm={
        <ModalDialog
          width={800}
          title={t('client_group.structure.finish_editing_message_modal.title')}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <LoadingWrapper loading={!initialValues}>
            {initialValues && <InnerForm />}
          </LoadingWrapper>
        </ModalDialog>
      }
    />
  );
};

export default StructureMessageModalDialog;
