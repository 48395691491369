import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { RoutePaths } from '../../../../routes/routes';
import { useHistory } from 'react-router-dom';
import { IAccountModel } from '../../../../typings/finance/account';
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';
import { correspondentBanksAPI } from '../../../../api/banking/correspondentBanksAPI';

// components
import FilterBar, { FilterBarKeys } from './FilterBar';
import CorrespondentBankAccountsTable, {
  ActionKeys,
} from '../../../../components/Tables/TableTemplates/Banking/CorrespondentBankAccountsTable';
import { Divider } from 'antd';

interface IProps {
  bankId: string;
}

const ClientBankAccountsTab = ({ bankId }: IProps) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCorrespondentAccount, setSelectedCorrespondentAccount] =
    useState<string>();

  const { response, loading } = useFetch(
    () =>
      bankId
        ? correspondentBanksAPI.fetchClientAccountsByBankId(bankId, {
            page: currentPage,
            limit: DEFAULT_TABLE_LIMIT,
            correspondentBankAccounts: selectedCorrespondentAccount
              ? [selectedCorrespondentAccount]
              : undefined,
          })
        : null,
    [bankId, currentPage, selectedCorrespondentAccount],
  );

  const handleActionsClick = (key: ActionKeys, record: IAccountModel) => {
    switch (key) {
      case 'details': {
        history.push({
          pathname: RoutePaths.Banking_BankAccounts,
          search: `?accountNumber=${record.accountNumber}`,
        });
      }
    }
  };

  const onFilterChange = (key: FilterBarKeys, newValue: string) => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }

    switch (key) {
      case 'correspondent_account': {
        setSelectedCorrespondentAccount(newValue);
      }
    }
  };

  return (
    <>
      <FilterBar bankId={bankId} onFilterChange={onFilterChange} />
      <Divider />
      <CorrespondentBankAccountsTable
        loading={loading}
        total={response?.total || 0}
        data={response?.data || []}
        current={currentPage}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsClick}
      />
    </>
  );
};

export default ClientBankAccountsTab;
