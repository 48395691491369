import React, { useState, useEffect } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import styled from 'styled-components';
import useFetch from '../../../../../hooks/useFetch';
import { messagingAPI } from '../../../../../api/messaging/messagingAPI';
import { ThreadCategoryModel } from '../../../../../typings/messaging/messages';

// components
import { Select, Spin } from 'antd';

interface IProps {
  onChange: (value: string) => void;
  [key: string]: any;
}

const MessageCategorySelect = (props: IProps) => {
  const { t } = useTranslation('crm');

  const [categories, setCategories] = useState<ThreadCategoryModel[]>([]);

  const { response, loading } = useFetch(() =>
    messagingAPI.fetchThreadCategories(),
  );

  useEffect(() => {
    setCategories(response || []);
  }, [response]);

  const renderOptions = () => {
    return categories.map(({ id, name }) => (
      <Select.Option key={id} value={id}>
        {t([`messaging.categories.${name}`, name])}
      </Select.Option>
    ));
  };

  return (
    <Select
      {...props}
      showArrow
      filterOption={false}
      notFoundContent={
        loading ? (
          <LoaderWrapper>
            <Spin size="small" />
          </LoaderWrapper>
        ) : undefined
      }
    >
      {renderOptions()}
    </Select>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default MessageCategorySelect;
