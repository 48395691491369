import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import { TableColumnModel } from '@core_components/Table';
import { IApprovalProgressState } from '../../../../typings/crm/onboarding';

// components
import ContentCell from './ContentCell';
import { Table } from 'antd';

interface IProps {
  approvalProgressStateSets: IApprovalProgressState[];
}

const ApprovalMatrix = memo(({ approvalProgressStateSets }: IProps) => {
  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: '30%',
        key: 'group-info',
        dataIndex: 'group-info',
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status-info',
      },
      {
        title: 'Note',
        key: 'note',
        dataIndex: 'note-info',
      },
    ];

    return result;
  }, []);

  const mappedColumns = useMemo(() => {
    const result: TableColumnModel[] = columns.map((col) => ({
      ...col,
      onCell: (record: any, rowIndex: number) => ({
        record,
        rowIndex,
        isOrText: rowIndex < approvalProgressStateSets.length - 1,
        dataIndex: col.dataIndex,
      }),
    }));

    return result;
  }, [columns]);

  return (
    <StyledTable
      className="disable-table-row-hover"
      bordered
      pagination={false}
      components={{ body: { cell: ContentCell } }}
      dataSource={approvalProgressStateSets}
      columns={mappedColumns}
    />
  );
});

const StyledTable = styled(Table)`
  .ant-table {
    margin: 0 !important;
    border-bottom: ${({ theme }) => `1px solid ${theme.tableBorderColor}`};
  }
`;

export default ApprovalMatrix;
