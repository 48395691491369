import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { IRelationshipModel } from '../../../../../typings/crm/entity';
import { RelationshipTrackType } from '../../../../../enums/crm/crm';

// components
import TableActions from '../../../TableActions';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

export type ActionKeys = 'view';

interface IProps extends RequiredPropsForTableModel<IRelationshipModel> {
  associationId: string;
  onActionsClick?: (key: ActionKeys, record: IRelationshipModel) => void;
}

const RelationshipsTable = memo(
  ({ associationId, onActionsClick, ...rest }: IProps) => {
    const { t } = useTranslation('crm');
    const history = useHistory();

    const actionMenu = useMemo(() => {
      return [
        {
          key: 'view',
          name: t('relationship_templates.viewRelationship'),
        },
      ];
    }, []);

    const getRelationshipSelectedType = (
      type: RelationshipTrackType,
      relationship: IRelationshipModel,
    ) => {
      if (!relationship || !relationship.relationshipTemplate) {
        return '';
      }

      return type === RelationshipTrackType.Parent
        ? relationship.relationshipTemplate.parentName
        : relationship.relationshipTemplate.childName;
    };

    const getRelationshipName = (
      type: RelationshipTrackType,
      relationship: IRelationshipModel,
    ): any => {
      if (!relationship || !relationship[type]) {
        return '';
      }

      let name = '';

      if (
        relationship[type].type === 'organization' &&
        relationship[type].organization
      ) {
        if (
          !relationship[type].organization.names ||
          !relationship[type].organization.names.length
        ) {
          return '';
        }

        const index = relationship[type].organization.names.findIndex(
          (el: any) => el.type === 'legal',
        );

        if (index === -1) {
          return '';
        }

        name = relationship[type].organization.names[index].name;
      } else if (
        relationship[type].type === 'contact' &&
        relationship[type].contact
      ) {
        const { firstName, lastName, middleName } = relationship[type].contact;
        name =
          [firstName, middleName, lastName].filter(Boolean).join(' ') || '';
      }

      const isMe = relationship[type].id === associationId;

      if (isMe) {
        return name;
      } else {
        const navigateLink =
          relationship[type].type === 'organization'
            ? RoutePaths.CRM_Entities_Edit
            : relationship[type].type === 'contact'
              ? RoutePaths.CRM_Contacts_Edit
              : '';

        return (
          <Typography.Link
            onClick={() =>
              history.push(`${navigateLink}?id=${relationship[type].id}`)
            }
          >
            {name}
          </Typography.Link>
        );
      }
    };

    const getRelationshipType = (
      displayFor: RelationshipTrackType,
      relationship: IRelationshipModel,
    ) => {
      if (!relationship || !relationship[displayFor]) {
        return '';
      }

      return t(`association_types.${relationship[displayFor].type}`);
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          title: t('entity.relationships.parentRelationship'),
          ellipsis: true,
          key: 'parentRelationship',
          render: (record: IRelationshipModel) => (
            <Typography.Text>
              {getRelationshipSelectedType(
                RelationshipTrackType.Parent,
                record,
              )}
            </Typography.Text>
          ),
        },
        {
          title: t('entity.relationships.name'),
          ellipsis: true,
          key: 'parentName',
          render: (relationship: IRelationshipModel) =>
            getRelationshipName(RelationshipTrackType.Parent, relationship),
        },
        {
          title: t('entity.relationships.type'),
          ellipsis: true,
          key: 'parentType',
          render: (relationship: IRelationshipModel) =>
            getRelationshipType(RelationshipTrackType.Parent, relationship),
        },
        {
          title: t('entity.relationships.childRelationship'),
          ellipsis: true,
          key: 'childRelationship',
          render: (relationship: IRelationshipModel) =>
            getRelationshipSelectedType(
              RelationshipTrackType.Child,
              relationship,
            ),
        },
        {
          title: t('entity.relationships.name'),
          ellipsis: true,
          key: 'childName',
          render: (relationship: IRelationshipModel) =>
            getRelationshipName(RelationshipTrackType.Child, relationship),
        },
        {
          title: t('entity.relationships.type'),
          ellipsis: true,
          key: 'childType',
          render: (relationship: IRelationshipModel) =>
            getRelationshipType(RelationshipTrackType.Child, relationship),
        },
        {
          key: 'ellipsis',
          hidden: !onActionsClick,
          width: '60px',
          render: (relationship: IRelationshipModel) =>
            onActionsClick ? (
              <TableActions
                menu={actionMenu}
                onSelect={(key) =>
                  onActionsClick(key as ActionKeys, relationship)
                }
              />
            ) : null,
        },
      ];

      return result;
    }, [onActionsClick]);

    return <Table columns={columns} {...rest} />;
  },
);

export default RelationshipsTable;
