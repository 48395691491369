import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { enumToMap } from '../../../../../helpers/utils';
import { SourceOfWealthCategories } from '../../../../../enums/crm/crm';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

type SourceOfWealthFormSelectProps = SelectProps;

const SourceOfWealthFormSelect = (props: SourceOfWealthFormSelectProps) => {
  const { t } = useTranslation('crm');

  const options = useMemo<SelectOption[]>(() => {
    const sourceOfWealthOptions = Array.from(
      enumToMap(SourceOfWealthCategories),
    );

    return sourceOfWealthOptions.map((el) => ({
      id: el[1],
      label: t(`contacts.new.sourceOfWealthCategories.${el[1]}`),
    }));
  }, [t]);

  return <FormSelect {...props} options={options} />;
};

export default SourceOfWealthFormSelect;
