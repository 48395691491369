import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { darkTheme } from '../../../../../resources/theme/styled';
import { DateHelpers } from '@helpers/date';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { useFormikContext } from 'formik';
import { ReconciliationStatuses } from '../../../../../enums/finance/finance';

// components
import IconSVG from '../../../../DesignSystem/Core/IconSVG';
import IconButton from '../../../../DesignSystem/Core/IconButton';
import EllipsisTooltip from '../../../../Tooltips/EllipsisTooltip';
import InfoIconWithTooltip from '../../../../Icons/InfoIconWithTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { ReactComponent as DeleteIcon } from '../../../../../resources/icons/remix-icons/delete-bin-6-line.svg';

export type JournalEntry = {
  id: number;
  valueDate: number;
  journalId: number;
  message: string;
  description: string;
  newReconciliationStatus: ReconciliationStatuses;
  debitAmount: number;
  creditAmount: number;
};

export type ActionKeys = 'delete';

export interface IProps extends RequiredPropsForTableModel<JournalEntry> {
  isDebit: boolean;
  onActionsClick?: (key: ActionKeys, record: JournalEntry) => void;
}

const UpdatedJournalEntriesTable = ({
  onActionsClick,
  isDebit,
  ...rest
}: IProps) => {
  const { t } = useTranslation('finance');

  // TODO: try to remove dependency with form
  const { values } = useFormikContext();

  const renderActions = (record: JournalEntry) => {
    if (onActionsClick) {
      return (
        <IconButton
          danger
          icon={<IconSVG component={DeleteIcon} color={darkTheme.errorColor} />}
          type="bordered"
          onClick={() => onActionsClick('delete', record)}
        />
      );
    }
  };

  const renderFormattedMoney = (amount?: number) => {
    if (!amount) {
      return '';
    }

    return AccountsHelpers.convertAmountBigIntToLocaleString(amount);
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        key: 'value_date',
        title: t(
          'update_reconciliation_status.updated_journal_entries_section.table.value_date',
        ),
        render: (record: JournalEntry) =>
          DateHelpers.formatTimestampToString(record.valueDate),
      },

      {
        key: 'journal_id',
        title: t(
          'update_reconciliation_status.updated_journal_entries_section.table.journal',
        ),
        render: (record: JournalEntry) => record.journalId,
      },

      {
        key: 'description',
        title: t(
          'update_reconciliation_status.updated_journal_entries_section.table.description',
        ),
        render: (record: JournalEntry) => (
          <EllipsisTooltip
            maxTextContainerWidth="200px"
            title={record.description}
          >
            {record.description}
          </EllipsisTooltip>
        ),
      },

      {
        title: `${t('update_reconciliation_status.updated_journal_entries_section.table.decrease')} (${
          isDebit
            ? t(
                'update_reconciliation_status.updated_journal_entries_section.table.credit',
              )
            : t(
                'update_reconciliation_status.updated_journal_entries_section.table.debit',
              )
        })`,
        key: 'decrease',
        render: (record: JournalEntry) =>
          renderFormattedMoney(
            isDebit ? record.creditAmount : record.debitAmount,
          ),
      },
      {
        title: `${t('update_reconciliation_status.updated_journal_entries_section.table.increase')} (${
          isDebit
            ? t(
                'update_reconciliation_status.updated_journal_entries_section.table.debit',
              )
            : t(
                'update_reconciliation_status.updated_journal_entries_section.table.credit',
              )
        })`,
        key: 'increase',
        render: (record: JournalEntry) =>
          renderFormattedMoney(
            isDebit ? record.debitAmount : record.creditAmount,
          ),
      },

      {
        width: 300,
        title: t(
          'update_reconciliation_status.updated_journal_entries_section.table.new_status',
        ),
        key: 'status',
        render: (record: JournalEntry) => (
          <>
            {t(
              `accounts.account_details.reconciliation_statuses.${record.newReconciliationStatus}`,
            )}
            {record.message && (
              <StyledInfoIconWithTooltip tooltipMessage={record.message} />
            )}
          </>
        ),
      },

      {
        hidden: !onActionsClick,
        key: 'actions',
        render: renderActions,
      },
    ];
    return result;
  }, [values, onActionsClick]);

  return <Table columns={columns} {...rest} />;
};

const StyledInfoIconWithTooltip = styled(InfoIconWithTooltip)`
  color: ${({ theme }) => theme.warningColor};
  margin-left: ${({ theme }) => theme.marginXs};
  cursor: pointer;
`;

export default UpdatedJournalEntriesTable;
