// helpers
import { SubRouteModel } from '../../../typings/routes';

// helper components & layouts
import PageLayout from '../../../layouts/PageLayout';
import Navigation from '../../../pages/Navigation';

// pages
import FeeSchedulePage from '../../../pages/Banking/FeeSchedulePage';
import BankAccountsPage from '../../../pages/Banking/BankAccountsPage';
import TransactionsPage from '../../../pages/Banking/TransactionsPage';
import PendingForexTransfers from '../../../pages/Banking/PendingForexTranfers';
import CorrespondentBanksPage from '../../../pages/Banking/CorrespondentBanksPage';
import PendingTransactionsPage from 'pages/Banking/PendingTransactionsPage';
import CorrespondentBankDetailsPage from '../../../pages/Banking/CorrespondentBankDetailsPage';

enum BankingRoutesEnum {
  Banking_Root = '/banking',
  Banking_ForeignExchange = '/banking/foreign-exchange',
  Banking_PendingForexTransfers = '/banking/foreign-exchange/pending-forex-transfers',
  Banking_FeeSchedule = '/banking/fee-schedule',
  Banking_CorrespondentBanks = '/banking/correspondent-banks',
  Banking_CorrespondentBankDetails = '/banking/correspondent-banks/correspondent-bank',
  Banking_BankAccounts = '/banking/bank-accounts',
  Banking_Transactions = '/banking/transactions',
  Banking_PendingTransactions = '/banking/pending-transactions',
}

const bankingRoutes: SubRouteModel[] = [
  {
    title: 'Banking',
    path: BankingRoutesEnum.Banking_Root,
    layout: PageLayout,
    component: Navigation,
    permission: { permissionKey: 'Banking_View' },
    childRoutes: [
      {
        title: 'Foreign Exchange',
        path: BankingRoutesEnum.Banking_ForeignExchange,
        layout: PageLayout,
        component: Navigation,
        childRoutes: [
          {
            title: 'Pending FOREX Transfers',
            path: BankingRoutesEnum.Banking_PendingForexTransfers,
            layout: PageLayout,
            component: PendingForexTransfers,
          },
        ],
      },

      {
        title: 'Fee Schedule',
        path: BankingRoutesEnum.Banking_FeeSchedule,
        layout: PageLayout,
        component: FeeSchedulePage,
        permission: { permissionKey: 'Banking_FeeSchedule_View' },
      },

      {
        title: 'Correspondent Banks',
        path: BankingRoutesEnum.Banking_CorrespondentBanks,
        layout: PageLayout,
        component: CorrespondentBanksPage,
        permission: { permissionKey: 'Banking_CorrespondentBanks_View' },
        childRoutes: [
          {
            hideFromNavigation: true,
            title: 'Correspondent bank',
            path: BankingRoutesEnum.Banking_CorrespondentBankDetails,
            layout: PageLayout,
            component: CorrespondentBankDetailsPage,
            permission: {
              permissionKey: 'Banking_CorrespondentBanks_Details_View',
            },
            layoutProps: {
              goBackPath: BankingRoutesEnum.Banking_CorrespondentBanks,
              hideContentCard: true,
            },
          },
        ],
      },

      {
        title: 'Bank Accounts',
        path: BankingRoutesEnum.Banking_BankAccounts,
        layout: PageLayout,
        component: BankAccountsPage,
        permission: { permissionKey: 'Banking_BankAccounts_View' },
      },

      {
        title: 'Transactions',
        path: BankingRoutesEnum.Banking_Transactions,
        layout: PageLayout,
        component: TransactionsPage,
        permission: { permissionKey: 'Banking_Transactions_View' },
      },

      {
        title: 'Pending transactions',
        path: BankingRoutesEnum.Banking_PendingTransactions,
        layout: PageLayout,
        component: PendingTransactionsPage,
        // TODO: add permission
        // permission: { permissionKey: 'Banking_Transactions_View' },
      },
    ],
  },
];

export { bankingRoutes, BankingRoutesEnum };
