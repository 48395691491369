import React, { useState } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';

// components
import PageButton from '@common_components/Buttons/PageButton';
import ApprovalGroupsTable from './ApprovalGroupsTable';
import AddApprovalGroupModal from './AddApprovalGroupModal';
import EditApprovalGroupModal from './EditApprovalGroupModal';
import { Col, Row } from 'antd';

interface IProps {
  clientGroupId: number;
}

const ApprovalGroupsTab = ({ clientGroupId }: IProps) => {
  const { t } = useTranslation('approval_management');
  const [hasAccessToCreateApprovalGroup] = useUserAccess([
    'CRM_ClientGroup_Details_TransactionRules_ApprovalGroups_Create',
  ]);
  const [updateTrigger, updateTable] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [selectedApprovalGroupId, setSelectedApprovalGroupId] = useState<
    string | null
  >(null);

  const handleModalClose = (wasCreated?: boolean) => {
    wasCreated && updateTable({});
    setIsVisible(false);
  };

  const handleEditModalClose = (wasUpdated?: boolean) => {
    wasUpdated && updateTable({});
    setSelectedApprovalGroupId(null);
  };

  return (
    <>
      {hasAccessToCreateApprovalGroup && (
        <Row justify="end">
          <Col flex="150px">
            <PageButton fullWidth onClick={() => setIsVisible(true)}>
              {t('approval_rules.add_group_button')}
            </PageButton>
          </Col>
        </Row>
      )}
      <ApprovalGroupsTable
        clientGroupId={clientGroupId}
        onApprovalGroupSelect={setSelectedApprovalGroupId}
        updateTrigger={updateTrigger}
      />
      <AddApprovalGroupModal
        clientGroupId={clientGroupId}
        isVisible={isVisible}
        closeCallback={handleModalClose}
      />
      <EditApprovalGroupModal
        clientGroupId={clientGroupId}
        approvalGroupId={selectedApprovalGroupId}
        isVisible={!!selectedApprovalGroupId}
        closeCallback={handleEditModalClose}
      />
    </>
  );
};

export default ApprovalGroupsTab;
