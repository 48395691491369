import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../../../../../hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { onboardingAnswerAdapter } from '../../../../../../../../../../apiAdapters/crm/onboarding/onboardingAnswerAdapter';
import { StateModel as UploadStateModel } from 'redux/reducers/upload';
import {
  AnswerModel,
  onboardingAnswerAPI,
} from '../../../../../../../../../../api/crm/onboarding/onboardingAnswerAPI';
import { FormValuesModel as ApplicationDocumentationFormValuesModel } from '../../../../..';

// components
import AdditionalDocumentModalDialog, {
  FormValuesModel,
} from '../../../../../../../../../../components/Modals/TemplateModalDialogs/CRM/AdditionalDocumentModalDialog';

interface IProps {
  isVisible: boolean;
  fieldIndex: number;
  closeCallback: (updatedAnswer?: AnswerModel) => void;
}

const UpdateAdminDocumentModal = ({
  isVisible,
  fieldIndex,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('crm');
  const { values } =
    useFormikContext<ApplicationDocumentationFormValuesModel>();
  const { isFinished, progress } = useSelector<StateModel, UploadStateModel>(
    (state) => state.upload,
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!isVisible) {
      return null;
    }

    const { itemId, itemType, reviewProcess } = values;
    const additionalField = values.additionalFields[fieldIndex];

    return {
      itemId,
      itemType,
      reviewProcess,
      fieldId: values.additionalFields[fieldIndex]._id,
      name: additionalField.field.name,
      type: additionalField.field.type,
      options: {
        formDocument:
          additionalField.field.options &&
          additionalField.field.options.formDocument
            ? [
                {
                  id: additionalField.field.options.formDocument.id,
                  name: additionalField.field.options.formDocument.files[0]
                    .name,
                },
              ]
            : null,
        selectOptionsValues:
          additionalField.field.options &&
          additionalField.field.options.selectOptions.length
            ? additionalField.field.options.selectOptions.map((option) =>
                JSON.parse(option.valueJSON),
              )
            : null,
        documentRequiresCertification: additionalField.field.options
          ? additionalField.field.options.documentRequiresCertification
          : false,
      },
      description: additionalField.field.description,
    };
  }, [values, fieldIndex, isVisible]);

  const handleSubmit = async (values: FormValuesModel) => {
    const formattedBody =
      onboardingAnswerAdapter.updateRequiredDocument(values);
    const response =
      await onboardingAnswerAPI.reviewApplicationDocumentation(formattedBody);
    closeCallback(response.operations[0].result);
  };

  return (
    <AdditionalDocumentModalDialog
      title={t('client_group.approval.edit_additional_field_document')}
      isVisible={isVisible}
      closeCallback={() => closeCallback()}
      disabled={!isFinished && progress > 0 && progress < 100}
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
    />
  );
};

export default UpdateAdminDocumentModal;
