import React, { memo, useState } from 'react';

// components
import Button, { ButtonProps } from '../../../Core/Button';

interface IProps extends ButtonProps {
  children: React.ReactNode;
  onClick: () => Promise<void> | void;
}

const AsyncButton = memo(({ onClick, children, ...rest }: IProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  return (
    <Button onClick={handleClick} loading={isLoading} {...rest}>
      {children}
    </Button>
  );
});

export default AsyncButton;
