import React, { useEffect } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from '../../../layouts/PageLayout';
import { GENESIS_ONGOING_REVIEWS_DOCUMENTATION_LINK } from '../../../constants/externalLinks';

// components
import TooltipToDocumentation from '../../../components/Tooltips/TooltipToDocumentation';
import { default as OngoingReviewsContent } from '../../../modules/Compliance/OngoingReviews';

type IProps = PageLayoutHelperPropsModel;

const OngoingReviewsPage = ({ setHeaderOptions }: IProps) => {
  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation
          link={GENESIS_ONGOING_REVIEWS_DOCUMENTATION_LINK}
        />
      ),
    });
  }, []);
  return <OngoingReviewsContent />;
};

export default OngoingReviewsPage;
