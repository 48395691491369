import React from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../../../../hooks/useFetch';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { IEntity } from '../../../../../../../../typings/crm/entity';
import { entityAPI } from '../../../../../../../../api/crm/entity/entityAPI';
import { GraphNode } from '../../../../../../../../components/Charts/GraphChart';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../../../../routes/routes';
import { changeView } from '../../../../../../../../redux/actions/view';
import { useDispatch } from 'react-redux';
import { OnboardType } from '../../../../../../../../enums/crm/crm';
import { contactsAPI } from '../../../../../../../../api/crm/contact/contactsAPI';
import { IContactModel } from '../../../../../../../../typings/crm/contact';
import { EntityHelpers } from '@helpers/crm/entity';
import { ContactHelpers } from '@helpers/crm/contact';

// components
import EntityInfo from './EntityInfo';
import ContactInfo from './ContactInfo';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import { Button, Col, Row, Spin, Typography } from 'antd';

interface IProps extends RequiredPropsForModalDialogModel {
  parent: GraphNode;
}

// TODO: decide what to do with relationships section
const ViewRelationshipModal = ({
  parent,
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('common');

  const history = useHistory();
  const dispatch = useDispatch();
  const historyPush = (path: any, state?: any) =>
    dispatch(changeView(() => history.push(path, state)));
  const isContact = parent.entryType === OnboardType.Contact;
  const name = isContact
    ? ContactHelpers.getContactName(parent.model as IContactModel)
    : EntityHelpers.getEntityName(parent.model as IEntity);

  const { response, loading } = useFetch<IContactModel | IEntity>(() => {
    return isContact
      ? contactsAPI.fetchContactById(parent.id)
      : entityAPI.getEntityById(parent.id);
  }, [parent]);

  const redirectToParent = () => {
    const navigateLink = isContact
      ? RoutePaths.CRM_Contacts_Edit
      : RoutePaths.CRM_Entities_Edit;
    historyPush(`${navigateLink}?id=${parent.id}`);
  };

  const renderResponse = () => {
    if (!response) {
      return (
        <StyledNoDataMessage level={4}>
          {t('no_data_received')}
        </StyledNoDataMessage>
      );
    }

    if (isContact) {
      return <ContactInfo contact={response as IContactModel} />;
    }

    return <EntityInfo entity={response as IEntity} />;
  };

  return (
    <ModalDialog
      width={1080}
      title={name}
      isVisible={isVisible}
      closeCallback={closeCallback}
      hideFooterButtons
    >
      {loading ? (
        <LoaderWrapper>
          <Spin spinning={loading} />
        </LoaderWrapper>
      ) : (
        <>
          {renderResponse()}
          <StyledRow justify="end">
            <Col>
              <Button type="primary" onClick={redirectToParent}>
                {t('go_to', { name })}
              </Button>
            </Col>
          </StyledRow>
        </>
      )}
    </ModalDialog>
  );
};

const StyledNoDataMessage = styled(Typography.Title)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: ${({ theme }) => theme.textLightColor3} !important;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
`;

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginMd};
`;

export default ViewRelationshipModal;
