import React, { memo, useEffect } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_BANK_ACCOUNTS_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import BankAccountsTable from '../../modules/Banking/BankAccounts/BankAccountsTable';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const BankAccountsPage = memo(({ setHeaderOptions }: IProps) => {
  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation
          link={GENESIS_BANK_ACCOUNTS_DOCUMENTATION_LINK}
        />
      ),
    });
  }, []);

  return <BankAccountsTable />;
});

export default BankAccountsPage;
