import React from 'react';

// components
import ForexTransferWorkflowStatus from './ForexTransferWorkflowStatus';

interface IProps {
  workflowId?: string;
  onUpdate: () => void;
}

const ForexTransferWorkflow = ({ workflowId, onUpdate }: IProps) => {
  return (
    <ForexTransferWorkflowStatus
      workflowId={workflowId as string}
      onUpdate={onUpdate}
    />
  );
};

export default ForexTransferWorkflow;
