import React from 'react';

// helpers
import { useMemo } from 'react';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { AutocompleteProps } from '@core_components/Autocomplete';
import { approvalGroupsAPI } from 'api/approval/approvalManagement/approvalGroupsAPI';
import { IApprovalRuleAccount } from 'components/Modals/TemplateModalDialogs/CRM/ApprovalEntryModalDialog/InnerContent/ChangeDifferenceSection/Templates/TransactionRule';
import { AUTOCOMPLETE_RESULT_LIMIT } from 'constants/global';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export interface AccountsForTransactionRulesAutocompleteProps
  extends AutocompleteProps<IApprovalRuleAccount> {
  clientGroupId: number;
  value?: any;
}

const TransactionRuleAccountsAutocomplete = ({
  value,
  clientGroupId,
  initialValue,
  ...rest
}: AccountsForTransactionRulesAutocompleteProps) => {
  const formattedValue = useMemo(() => {
    return value && value.length
      ? value.map((e: any) => e.bankAccountNumber)
      : undefined;
  }, [value]);

  const fetchOptions = async () => {
    const { data } = await approvalGroupsAPI.fetchTransactionRuleAccounts(
      clientGroupId,
      { page: 1, limit: AUTOCOMPLETE_RESULT_LIMIT },
    );
    let formattedOptions = data.map((e) => ({
      id: e.accountNumber,
      label: AccountsHelpers.generateAccountFullName(
        e.accountName,
        e.accountNumber,
        e.currencyCode,
      ),
      model: {
        bankAccountNumber: e.accountNumber,
        bankAccountName: e.accountName,
        bankAccountCurrency: e.currencyCode,
      },
    }));

    if (initialValue) {
      formattedOptions = [...(initialValue as any), ...formattedOptions];
    }

    return formattedOptions;
  };

  return (
    <FormAutocomplete
      {...rest}
      value={formattedValue}
      fetchData={fetchOptions}
      initialValue={initialValue}
      mode="multiple"
    />
  );
};

export default TransactionRuleAccountsAutocomplete;
