import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../../routes/routes';
import { securityAPI } from '../../../../../../api/profile/securityAPI';
import { useDispatch } from 'react-redux';
import { setFidoAuthenticatorEnabledStatus } from '../../../../../../redux/actions/auth';

// components
import { Button, message } from 'antd';
import SignChallengeWithSecurityKeyModal from '../../../../../../components/Modals/TemplateModalDialogs/Profile/SignChallengeWithSecurityKeyModal';

const DisableFIDOAuthentication = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('profile');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onDisableFIDOClick = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = (wasSigned?: boolean) => {
    if (wasSigned) {
      message.success(
        t(
          'sign_in_and_security.two_factor_verification.security_key_option.disable_key.success_submit',
        ),
      );
      dispatch(setFidoAuthenticatorEnabledStatus(false));
      history.push(RoutePaths.VerificationFido);
    }

    setIsModalVisible(false);
  };

  return (
    <>
      <StyledButton size="large" danger onClick={onDisableFIDOClick}>
        {t(
          'sign_in_and_security.two_factor_verification.security_key_option.disable_key.disable_button',
        )}
      </StyledButton>

      <SignChallengeWithSecurityKeyModal
        title={t(
          'sign_in_and_security.two_factor_verification.security_key_option.disable_key.title',
        )}
        isVisible={isModalVisible}
        closeCallback={handleModalClose}
        getChallenge={securityAPI.getChallengeForDisablingFIDO}
        submitAssertion={securityAPI.disableSecurityKey2FA}
      />
    </>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default DisableFIDOAuthentication;
