import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ComplianceRiskLevel } from 'enums/crm/crm';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

type RiskRatingFormSelectProps = SelectProps<any>;

const RiskRatingFormSelect = memo((props: RiskRatingFormSelectProps) => {
  const { t } = useTranslation('crm');

  const options = useMemo<SelectOption[]>(() => {
    const riskOptions = Object.values(ComplianceRiskLevel);

    return riskOptions.map((key) => ({
      id: key,
      label: t(`client_group.compliance.${key}`),
    }));
  }, []);

  return <FormSelect {...props} options={options} />;
});

export default RiskRatingFormSelect;
