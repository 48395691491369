import React, { useMemo } from 'react';

// helpers
import moment from 'moment';
import useTranslation from '@hooks/useTranslation';
import { ComplianceReviewTypes } from 'enums/compliance/reviewConfiguration';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';
import { TransactionWithComplianceInfoModel } from 'typings/compliance/transactions';

// components
import RDCReviewProcessStatuses from 'components/Additional/Statuses/RDCReviewProcessStatuses';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

export interface IProps
  extends RequiredPropsForTableModel<TransactionWithComplianceInfoModel> {
  renderExpandableRow?: (
    record: TransactionWithComplianceInfoModel,
  ) => React.ReactNode;
}

const RDCReviewProcessesTable = ({ renderExpandableRow, ...rest }: IProps) => {
  const { t } = useTranslation('compliance');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        key: 'created_at',
        title: t('rdc_review_processes_table.created_at'),
        render: (record: TransactionWithComplianceInfoModel) =>
          moment.unix(record.createdAt).format(DEFAULT_DATE_TIME_FORMAT),
      },

      {
        key: 'type',
        title: t('rdc_review_processes_table.type'),
        render: (record: TransactionWithComplianceInfoModel) =>
          t(`review_types.${ComplianceReviewTypes[record.reviewType]}`),
      },

      {
        key: 'status',
        title: t('rdc_review_processes_table.status'),
        render: (record: TransactionWithComplianceInfoModel) => (
          <RDCReviewProcessStatuses status={record.status} />
        ),
      },
    ];

    return result;
  }, [t]);

  const expandableRow = useMemo(() => {
    return { expandedRowRender: renderExpandableRow };
  }, [renderExpandableRow]);

  return <Table {...rest} columns={columns} expandable={expandableRow} />;
};

export default RDCReviewProcessesTable;
