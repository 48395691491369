import React, { memo, useCallback, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { PermissionType } from '../../../../../typings/ORAC/permissions';
import { PermissionsHelpers } from '@helpers/permissions';
import { StyledComponentProps } from '../../../../../typings/common';
import { ObjectActionWithPermissionsModel } from '../../../../../typings/ORAC/objects';

// components
import Icon from '../../../../Icons/Icon';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import CheckIcon from '../../../../../resources/images/icons/checkmark-green.png';
import RejectedIcon from '../../../../../resources/images/icons/rejected-icon.png';
import InheritanceIcon from '../../../../../resources/images/icons/sitemap-green.png';
import AssignWayInfoPopover from '../../../../Tooltips/TemplateTooltips/orac/AssignWayInfoPopover';
import { Col, Row } from 'antd';

interface IProps
  extends StyledComponentProps,
    RequiredPropsForTableModel<ObjectActionWithPermissionsModel> {
  icon?: string;
  inheritedIcon?: string;
  fobiddenIcon?: string;
  showForbiddenOnly?: boolean;
  showAssignWayInfo?: boolean;
  showForbiddenColumn?: boolean;
}

const PermissionsMatrix = memo(
  ({
    icon = CheckIcon,
    inheritedIcon = InheritanceIcon,
    fobiddenIcon = RejectedIcon,
    className,
    showForbiddenColumn,
    showForbiddenOnly,
    showAssignWayInfo,
    ...rest
  }: IProps) => {
    const { t } = useTranslation('orac');

    const renderPermissionStatus = useCallback(
      (
        record: ObjectActionWithPermissionsModel,
        permissionType: PermissionType,
      ) => {
        const permission = PermissionsHelpers.findPermissionInAction(
          record,
          permissionType,
        );

        if (!permission) {
          return null;
        }

        const selectedIcon =
          permissionType === 'forbidden' ? fobiddenIcon : icon;

        if (!showAssignWayInfo) {
          return <Icon icon={selectedIcon} width="15px" height="15px" />;
        }

        const { inherited, direct } =
          PermissionsHelpers.getPermissionAssignWayInfo(permission);

        const isDirect = !!direct.users.length;
        const isInherited =
          !!inherited.groups.length || !!inherited.roles.length;

        return (
          <Row justify="center">
            <Col span={12} hidden={!isDirect}>
              <AssignWayInfoPopover isDirect>
                <StyledIcon icon={selectedIcon} width="15px" height="15px" />
              </AssignWayInfoPopover>
            </Col>

            <Col span={12} hidden={!isInherited}>
              <AssignWayInfoPopover
                isDirect={false}
                groups={inherited.groups}
                roles={inherited.roles}
              >
                <StyledIcon icon={inheritedIcon} width="15px" height="15px" />
              </AssignWayInfoPopover>
            </Col>
          </Row>
        );
      },
      [],
    );

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          key: 'action_name',
          title: t('objects.view.action_name'),
          render: (record: ObjectActionWithPermissionsModel) => record.action,
        },

        {
          align: 'center',
          hidden: showForbiddenOnly,
          width: '100px',
          key: 'single_permission',
          title: t('objects.view.single_permission'),
          render: (record: ObjectActionWithPermissionsModel) =>
            renderPermissionStatus(record, 'single'),
        },

        {
          align: 'center',
          hidden: showForbiddenOnly,
          width: '100px',
          key: 'multiple_permissions',
          title: t('objects.view.multiple_permission'),
          render: (record: ObjectActionWithPermissionsModel) =>
            renderPermissionStatus(record, 'multiple'),
        },

        {
          align: 'center',
          hidden: !showForbiddenColumn && !showForbiddenOnly,
          width: '100px',
          key: 'forbidden_permission',
          title: t('objects.view.forbidden_permission'),
          render: (record: ObjectActionWithPermissionsModel) =>
            renderPermissionStatus(record, 'forbidden'),
        },
      ];

      return result;
    }, [showForbiddenColumn, showForbiddenOnly]);

    return (
      <Table
        {...rest}
        columns={columns}
        className={`disable-table-row-hover ${className}`}
      />
    );
  },
);

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export default PermissionsMatrix;
