import ApiConfig from '../../config/api';
import { Component } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { AppInsightsService } from './service';
import { RouteComponentProps, withRouter } from 'react-router-dom';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking
 * that requires this to be a Class Component rather than a Functional Component
 */

type IProps = RouteComponentProps;

interface IState {
  initialized: boolean;
}

class AppInsightsProvider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    // Disable azure app insights for localhost developing
    if (process.env.REACT_APP_STAGE === 'local') {
      return;
    }

    const { initialized } = this.state;
    const instrumentationKey = ApiConfig.azureInstrumentationKey;

    if (!initialized && Boolean(instrumentationKey)) {
      AppInsightsService.initialize(instrumentationKey);
      this.setState({ initialized: true });
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withRouter(
  withAITracking(
    AppInsightsService.reactPlugin,
    AppInsightsProvider as any,
  ) as any,
);
