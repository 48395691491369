import React, { useState, useCallback, useEffect, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import { debounce } from 'lodash';
import { AUTOCOMPLETE_DEBOUNCE_DELAY } from '../../../constants/global';

// components
import { Select, Spin, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export interface AutocompleteInitialValue {
  content: any;
  id: string | number;
  isDisabled?: boolean;
  disabledTooltipText?: string;
}

export interface AutocompleteOption<T = any> extends AutocompleteInitialValue {
  model: T;
}

interface IProps {
  initialValue?: AutocompleteInitialValue | AutocompleteInitialValue[];
  placeholder?: string;
  disabled?: boolean;
  minSearchLength?: number;
  fetchData: (searchQuery: string) => Promise<AutocompleteOption[]>;
  onSelect?: (id: string, model: any) => void;
  onChange?: (e: any, options: any) => void;
  updateOptionsTrigger?: any;
  allowClear?: boolean;
  // Use to filter options before rendering
  // E.g: We have multiple aucomplete fields in one form, and we want to exclue selected elements in all other autocomplete fields
  excludeElementIds?: Array<string | number>;
  value?: any;

  loadDataOnMount?: boolean;
  autoSelectIfOneOption?: boolean;
}

const Autocomplete = ({
  initialValue,
  placeholder,
  disabled,
  fetchData,
  minSearchLength,
  value,
  excludeElementIds,
  updateOptionsTrigger,
  loadDataOnMount,
  autoSelectIfOneOption,
  ...rest
}: IProps) => {
  const [isLoading, setLoading] = useState(false);
  const [availableOptions, setOptions] = useState<AutocompleteOption[]>([]);

  useEffect(() => {
    if (loadDataOnMount && !isLoading) {
      onSearch('');
    }
  }, []);

  useEffect(() => {
    if (updateOptionsTrigger) {
      onSearch('');
    }
  }, [updateOptionsTrigger]);

  const formattedValue = useMemo(() => {
    if (value) {
      return value;
    }

    return undefined;
  }, [value, initialValue]);

  const fetchAndSaveOptions = async (searchQuery: string) => {
    if (fetchData) {
      const options = await fetchData(searchQuery);
      setOptions(options);
      if (autoSelectIfOneOption && !value && options.length === 1) {
        rest.onChange && rest.onChange(options[0].id as any, options[0]);
      }
      setLoading(false);
    }
  };

  const debouncedFetchData = useCallback(
    debounce(fetchAndSaveOptions, AUTOCOMPLETE_DEBOUNCE_DELAY),
    [fetchData],
  );

  const onSearch = (searchQuery: string) => {
    if (!minSearchLength || searchQuery.length >= minSearchLength) {
      setLoading(true);
      debouncedFetchData(searchQuery);
    } else {
      setOptions([]);
      setLoading(false);
    }
  };

  const onFocus: React.FocusEventHandler<HTMLElement> = async () => {
    if (!availableOptions.length) {
      await onSearch('');
    }
  };

  const onSelect = (value: string, options: any) => {
    if (rest.onSelect) {
      rest.onSelect(value, options.model);
    }
  };

  const renderOptions = useCallback(() => {
    if (isLoading) {
      return null;
    }

    if (!availableOptions.length) {
      const initialOptions = Array.isArray(initialValue)
        ? initialValue
        : [initialValue];

      if (initialOptions) {
        return initialOptions?.map((option) => {
          return (
            option && (
              <Select.Option key={option.id} value={option.id || ''}>
                {option.content}
              </Select.Option>
            )
          );
        });
      }

      return null;
    }

    let availableOptionsCopy = availableOptions.slice();

    if (excludeElementIds) {
      availableOptionsCopy = availableOptionsCopy.filter(
        (e) => !excludeElementIds.includes(e.id),
      );
    }

    return availableOptionsCopy.map((o) => (
      <Select.Option
        key={o.id}
        value={o.id}
        model={o.model}
        disabled={o.isDisabled}
      >
        {o.content}
        {o.isDisabled && o.disabledTooltipText && (
          <Tooltip title={o.disabledTooltipText}>
            <StyledOutlinedIcon />
          </Tooltip>
        )}
      </Select.Option>
    ));
  }, [availableOptions, initialValue, excludeElementIds, isLoading]);

  return (
    <Select
      {...rest}
      allowClear
      onFocus={onFocus}
      onSelect={onSelect}
      onSearch={onSearch}
      placeholder={placeholder}
      value={formattedValue || undefined}
      size="large"
      showSearch
      showArrow
      filterOption={false}
      disabled={disabled}
      notFoundContent={
        isLoading ? (
          <LoaderWrapper>
            <Spin size="small" />
          </LoaderWrapper>
        ) : null
      }
    >
      {renderOptions()}
    </Select>
  );
};

const StyledOutlinedIcon = styled(ExclamationCircleOutlined)`
  margin-left: 10px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Autocomplete;
