import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { enumToMap } from '../../../../../helpers/utils';
import { EmailType } from '../../../../../enums/crm/crm';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type EmailTypeFormSelectProps = SelectProps;

const EmailTypeFormSelect = (props: EmailTypeFormSelectProps) => {
  const { t } = useTranslation('crm');

  const options = useMemo<SelectOption[]>(() => {
    const emailTypes = Array.from(enumToMap(EmailType));

    return emailTypes.map((el) => ({
      id: el[1],
      label: t(`email_type.${el[1]}`),
    }));
  }, [t]);

  return <FormSelect {...props} options={options} />;
};

export default EmailTypeFormSelect;
