import React, { useState } from 'react';

// helpers
import useTranslation from '../../../hooks/useTranslation';

// constants
import { APPROVAL_INSTANCE_FILTER_ENUM } from '../../../api/approval/approvalManagement/approvalWorkflowAPI';

// components
import Tabs from '../../../components/Tabs';
import FilterBar from './FilterBar';
import ApprovalWorkflowTable from './ApprovalWorkflowTable';

import { Divider } from 'antd';

const ApprovalWorkflowTabs = () => {
  const { t } = useTranslation('approval_management');

  // filter bar
  const [approvalWorkflowFilter, setApprovalWorkflowFilter] = useState({
    type: '',
    status: '0',
  });

  // --- handlers ---

  const handleFilterChange = (key: string, value: any) => {
    switch (key) {
      case 'filter-by-type':
        setApprovalWorkflowFilter((prevState) => ({
          ...prevState,
          type: value,
        }));
        break;

      case 'filter-by-status':
        setApprovalWorkflowFilter((prevState) => ({
          ...prevState,
          status: value,
        }));
        break;
    }
  };

  // --- end handlers ---

  const tabs = [
    {
      key: 'all',
      title: t('approval_workflow.all'),
      content: (
        <ApprovalWorkflowTable
          displayFilter={APPROVAL_INSTANCE_FILTER_ENUM.ALL}
          selectedType={approvalWorkflowFilter.type}
          selectedStatus={approvalWorkflowFilter.status}
        />
      ),
    },

    {
      key: 'mine',
      title: t('approval_workflow.mine'),
      content: (
        <ApprovalWorkflowTable
          displayFilter={APPROVAL_INSTANCE_FILTER_ENUM.MINE}
          selectedType={approvalWorkflowFilter.type}
          selectedStatus={approvalWorkflowFilter.status}
        />
      ),
    },

    {
      key: 'need-my-approval',
      title: t('approval_workflow.need_my_approval'),
      content: (
        <ApprovalWorkflowTable
          displayFilter={APPROVAL_INSTANCE_FILTER_ENUM.NEED_MY_APPROVAL}
          selectedType={approvalWorkflowFilter.type}
          selectedStatus={approvalWorkflowFilter.status}
        />
      ),
    },
  ];

  return (
    <>
      <FilterBar
        onFilterChange={handleFilterChange}
        selectedStatusValue={approvalWorkflowFilter.status}
        selectedTypeValue={approvalWorkflowFilter.type}
      />

      <Divider />

      <Tabs defaultActiveKey="all" tabs={tabs} renderOnChange />
    </>
  );
};

export default ApprovalWorkflowTabs;
