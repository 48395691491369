import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';

// components
import ForgotUsernameStatusSelect from 'modules/ORAC/ForgotUsernameRequests/ForgotUsernameRequestsTable/FilterBar/ForgotUsernameStatusSelect';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import ForgotUsernameRequestStatus, {
  ForgotUsernameRequestStatuses,
} from 'components/Additional/Statuses/ForgotUsernameRequestStatus';
import { Button } from 'antd';

export type ForgotUsernameRequest = {
  id: string;
  user: string;
  email: string;
  clientGroup: string;
  dateOfBirth: string;
  status: ForgotUsernameRequestStatuses;
  creationDate: string;
};

export type FilterKeys = 'filter-by-status';

interface IProps extends RequiredPropsForTableModel<ForgotUsernameRequest> {
  onFilterChange: (key: FilterKeys, value?: string) => void;
}

const ForgotUsernameRequestsTable = memo(
  ({ onFilterChange, ...rest }: IProps) => {
    const { t } = useTranslation('orac');
    const history = useHistory();

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          title: t('forgot_username_requests.table.date'),
          key: 'date',
          width: 300,
          render: (record: ForgotUsernameRequest) => record.creationDate,
        },

        {
          title: t('forgot_username_requests.table.name'),
          key: 'name',
          width: 300,
          render: (record: ForgotUsernameRequest) => record.user,
        },

        {
          title: t('forgot_username_requests.table.email'),
          key: 'email',
          width: 300,
          render: (record: ForgotUsernameRequest) => record.email,
        },

        {
          title: t('forgot_username_requests.table.client_group'),
          key: 'client_group',
          width: 300,
          render: (record: ForgotUsernameRequest) => record.clientGroup,
        },

        {
          title: t('forgot_username_requests.table.date_of_birth'),
          key: 'date_of_birth',
          width: 300,
          render: (record: ForgotUsernameRequest) => record.dateOfBirth,
        },

        {
          filterDropdown: (
            <FilterWrapper>
              <StyledForgotUsernameStatusSelect
                onChange={(value) => onFilterChange('filter-by-status', value)}
              />
            </FilterWrapper>
          ),
          title: t('forgot_username_requests.table.status'),
          key: 'status',
          width: 300,
          render: (record: ForgotUsernameRequest) => (
            <ForgotUsernameRequestStatus status={record.status} />
          ),
        },

        {
          key: 'actions',
          align: 'right',
          width: 100,
          render: (record: ForgotUsernameRequest) => (
            <StyledButton
              onClick={() =>
                history.push({
                  pathname: RoutePaths.ORAC_ForgotUsernameRequests_Details,
                  search: `?id=${record.id}`,
                })
              }
            >
              {t('forgot_username_requests.table.view_details')}
            </StyledButton>
          ),
        },
      ];

      return result;
    }, [t, onFilterChange]);

    return <Table columns={columns} {...rest} />;
  },
);

const StyledButton = styled(Button)`
  width: 100%;
`;

const FilterWrapper = styled.div`
  padding: 15px;
  min-width: 300px;
  max-width: 300px;
`;

const StyledForgotUsernameStatusSelect = styled(ForgotUsernameStatusSelect)`
  width: 100%;
`;

export default ForgotUsernameRequestsTable;
