import React from 'react';

// helpers
import { AutocompleteInitialValue } from '../../../../Fields/Autocomplete';
import { ApprovalGroupValidationSchema } from '../../../../../validations/approval';
import {
  ApprovalWorkflowType,
  GroupParticipantModel,
} from '../../../../../typings/approval/templates';

// components
import InnerForm from './InnerForm';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export interface ApprovalGroupFormValuesModel {
  name: string;
  description: string;
  participants: GroupParticipantModel[];
  initialParticipantOptions?: AutocompleteInitialValue[];
  clientGroupId?: number;
  approvalWorkflowType?: ApprovalWorkflowType;
}

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<ApprovalGroupFormValuesModel> {
  title: string;
  viewOnly?: boolean;
  isEditMode?: boolean;
  loadingInitialData?: boolean;
}

const ApprovalGroupsModal = ({
  title,
  isVisible,
  closeCallback,
  initialValues,
  onSubmit,
  isEditMode,
  viewOnly,
}: IProps) => {
  return (
    <Form<ApprovalGroupFormValuesModel>
      disabled={viewOnly}
      enableReinitialize
      confirmExitWithoutSaving
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={ApprovalGroupValidationSchema}
      renderForm={
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          hideFooterButtons={viewOnly}
        >
          <LoadingWrapper loading={!initialValues}>
            <InnerForm isEditMode={isEditMode} />
          </LoadingWrapper>
        </ModalDialog>
      }
    />
  );
};

export default ApprovalGroupsModal;
