import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import { Button } from 'antd';
import ViewRoleDetailsModal from '../../../../../../components/Modals/TemplateModalDialogs/ORAC/Roles/ViewRoleDetailsModal';

interface IProps {
  roleId: string;
}

const ViewRoleDetails = ({ roleId }: IProps) => {
  const { t } = useTranslation('orac');

  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Button size="small" onClick={() => setIsVisible(true)}>
        {t('roles.table.view_role_permissions')}
      </Button>

      <ViewRoleDetailsModal
        isVisible={isVisible}
        closeCallback={() => setIsVisible(false)}
        roleId={roleId}
      />
    </>
  );
};

export default ViewRoleDetails;
