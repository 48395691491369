import React, { useMemo } from 'react';

// helpers
import { AccountsHelpers } from '../../../../../../../../helpers/finance/accounts';
import { ApprovalFXTransferDataModel } from '../../../../../../../../typings/approval/approvalWorkflow';

// components
import Form from '@core_components/Form';
import InnerForm, {
  ForexTransferFormValues,
} from '../../../../../../../../components/Forms/TemplateForms/TreasureManagement/ForexTransferForm';

interface IProps {
  forexInfo: ApprovalFXTransferDataModel;
}

// TODO: add FEE accountName and accountNumber to the initial form values
const FXTransferDetails = ({ forexInfo }: IProps) => {
  const initialFormValues = useMemo(() => {
    let result: ForexTransferFormValues | null = null;

    if (forexInfo) {
      const {
        customerInformation,
        correspondentBankInformation,
        wireInformation,
      } = forexInfo;
      const isCurrencyFANNcy =
        customerInformation.sourceAccountCurrencyId ===
        wireInformation.transferAmountCurrencyId;

      result = {
        isViewOnlyMode: true,
        isSpreadModified: wireInformation.isSpreadModified,

        transferId: wireInformation.transactionId,

        fromAccountName: customerInformation.sourceAccountName,
        fromAccountNumber: customerInformation.sourceAccountNumber,
        fromAccountCurrency: customerInformation.sourceAccountCurrencyName,
        fromCurrencyRate:
          AccountsHelpers.convertAmountFromBigIntToInt(
            wireInformation.originalExchangeRate,
          ) || 0,

        toAccountName: customerInformation.targetAccountName,
        toAccountNumber: customerInformation.targetAccountNumber,
        toAccountCurrency: customerInformation.targetAccountCurrencyName,
        toCurrencyRate:
          AccountsHelpers.convertAmountFromBigIntToInt(
            wireInformation.originalExchangeRate,
          ) || 0,

        isCurrencyFANNcy,
        currency: wireInformation.transferAmountCurrencyName,
        amount: wireInformation.transferAmount,

        fromCorrespondentAccountName:
          correspondentBankInformation.sourceAccountName,
        fromCorrespondentAccountNumber:
          correspondentBankInformation.sourceAccountNumber,

        toCorrespondentAccountName:
          correspondentBankInformation.targetAccountName,
        toCorrespondentAccountNumber:
          correspondentBankInformation.targetAccountNumber,

        profit: AccountsHelpers.roundNumber(
          AccountsHelpers.convertAmountFromBigIntToInt(
            wireInformation.internalFeeAmount,
          ) || 0,
          2,
        ),
        profitAccountName: wireInformation.internalFeeAccountName,
        profitAccountNumber: wireInformation.internalFeeAccountNumber,
        profitAccountCurrency: wireInformation.internalFeeCurrencyName,

        fromAccountMidAmount: AccountsHelpers.roundNumber(
          AccountsHelpers.convertAmountFromBigIntToInt(
            wireInformation.transferAmount,
          ) || 0,
          2,
        ),
        fromAccountBaseAmount: AccountsHelpers.roundNumber(
          AccountsHelpers.convertAmountFromBigIntToInt(
            wireInformation.transferAmount,
          ) || 0,
          2,
        ),

        toAccountMidAmount: AccountsHelpers.roundNumber(
          AccountsHelpers.convertAmountFromBigIntToInt(
            wireInformation.estimatedConversionAmount,
          ) || 0,
          2,
        ),
        toAccountBaseAmount: AccountsHelpers.roundNumber(
          AccountsHelpers.convertAmountFromBigIntToInt(
            wireInformation.totalClientAmount,
          ) || 0,
          2,
        ),

        spread:
          AccountsHelpers.convertAmountFromBigIntToInt(
            wireInformation.spread,
          ) || 0,
        originalSpread:
          AccountsHelpers.convertAmountFromBigIntToInt(
            wireInformation.originalSpread,
          ) || 0,
        isLoadingSummaryData: false,
      };
    }

    return result;
  }, [forexInfo]);

  return (
    <Form<ForexTransferFormValues>
      onSubmit={console.log}
      initialValues={initialFormValues as ForexTransferFormValues}
      renderForm={<InnerForm />}
      confirmExitWithoutSaving
    />
  );
};

export default FXTransferDetails;
