import React, { useEffect, useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';
import {
  approvalWorkflowAPI,
  APPROVAL_INSTANCE_FILTER_ENUM,
} from '../../../../api/approval/approvalManagement/approvalWorkflowAPI';

// components
import ApprovalWorkflowDetailsModal from '../ApprovalWorkflowDetailsModal';
import { default as TemplateApprovalWorkflowTable } from '../../../../components/Tables/TableTemplates/ApprovalManagement/ApprovalWorkflowTable';

interface IProps {
  displayFilter: APPROVAL_INSTANCE_FILTER_ENUM;
  selectedStatus: string;
  selectedType: string;
}

const ApprovalWorkflowTable = ({
  displayFilter,
  selectedType,
  selectedStatus,
}: IProps) => {
  const [current, setCurrent] = useState(1);
  const [updateTableTrigger, updateTable] = useState({});
  const [selectedInstanceId, setSelectedInstanceId] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (current > 1) {
      setCurrent(1);
    }
  }, [selectedType, selectedStatus]);

  const { response, loading } = useFetch(
    () =>
      approvalWorkflowAPI.fetchApprovalWorkflowInstances({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        filterlevel: displayFilter,
        type: selectedType,
        status: selectedStatus,
      }),
    [current, selectedType, selectedStatus, updateTableTrigger],
  );

  const handleModalClose = () => {
    setSelectedInstanceId(null);
  };

  const handleActionsClick = (key: string, value: string) => {
    if (key === 'details') {
      setSelectedInstanceId(value);
    }
  };

  return (
    <>
      <TemplateApprovalWorkflowTable
        data={response?.data || []}
        total={response?.total || 0}
        current={current}
        loading={loading}
        onActionsClick={handleActionsClick}
        onPaginationChange={setCurrent}
      />

      <ApprovalWorkflowDetailsModal
        instanceId={selectedInstanceId as string}
        isVisible={!!selectedInstanceId}
        closeCallback={handleModalClose}
        updateTable={updateTable}
      />
    </>
  );
};

export default ApprovalWorkflowTable;
