import React, { useEffect, useState } from 'react';

// helpers
import useFetch from '../../hooks/useFetch';
import useTranslation from '../../hooks/useTranslation';
import { message } from 'antd';
import { RoutePaths } from '../../routes/routes';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { securityAPI } from '../../api/profile/securityAPI';
import { SecurityKeyModel } from '../../typings/profile/fido';
import { DEFAULT_TABLE_LIMIT } from '../../constants/global';
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { setFidoAuthenticatorEnabledStatus } from '../../redux/actions/auth';

// components
import AddSecurityKey from '../../modules/Profile/Security/TwoFactorVerification/SecurityKeyOption/AddSecurityKey';
import DeleteSecurityKeyModal from '../../modules/Profile/Security/TwoFactorVerification/SecurityKeyOption/DeleteSecurityKeyModal';
import SecurityKeysTable, {
  ActionKeys,
} from '../../components/Tables/TableTemplates/Profile/SecurityKeysTable';
import EditSecurityKeyModal from '../../modules/Profile/Security/TwoFactorVerification/SecurityKeyOption/EditSecurityKeyModal';

const ManageSecurityKeys = ({
  setHeaderOptions,
}: PageLayoutHelperPropsModel) => {
  const { t } = useTranslation('profile');
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [updateTableTrigger, updateTable] = useState({});

  const [selectedKey, setSelectedKey] = useState<SecurityKeyModel | null>(null);
  const [selectedKeyIdForDeletion, setSelectedKeyIdForDeletion] = useState<
    string | null
  >(null);

  useEffect(() => {
    setHeaderOptions({
      extra: () => <AddSecurityKey onAdd={() => updateTable({})} />,
    });
  }, []);

  const { response, loading } = useFetch(
    () =>
      securityAPI.fetchSecurityKeys({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [currentPage, updateTableTrigger],
  );

  const handleActionsClick = (key: ActionKeys, record: SecurityKeyModel) => {
    switch (key) {
      case 'details':
        setSelectedKey(record);
        break;

      case 'delete':
        setSelectedKeyIdForDeletion(record._id);
        break;
    }
  };

  const handleDeleteModalClose = (wasDeleted?: boolean) => {
    if (wasDeleted) {
      message.success(
        t(
          'sign_in_and_security.two_factor_verification.security_key_option.remove_key.submit_success_message',
        ),
      );

      if (response?.total === 1) {
        dispatch(setFidoAuthenticatorEnabledStatus(false));

        history.push(RoutePaths.Profile_SignInSecurity);
      } else {
        updateTable({});
      }
    }

    setSelectedKeyIdForDeletion(null);
  };

  const handleModalClose = (wasUpdated?: boolean) => {
    if (wasUpdated) {
      updateTable({});
    }

    setSelectedKey(null);
  };

  return (
    <>
      <SecurityKeysTable
        current={currentPage}
        loading={loading}
        data={response?.data || []}
        total={response?.total || 0}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsClick}
      />
      <EditSecurityKeyModal
        isVisible={!!selectedKey}
        closeCallback={handleModalClose}
        securityKey={selectedKey}
      />
      <DeleteSecurityKeyModal
        isVisible={!!selectedKeyIdForDeletion}
        keyId={selectedKeyIdForDeletion}
        closeCallback={handleDeleteModalClose}
      />
    </>
  );
};

export default ManageSecurityKeys;
