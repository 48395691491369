import React from 'react';

// helpers utils
import styled from 'styled-components';
import useFetch from '../../../../hooks/useFetch';
import useQueryParam from '../../../../hooks/useQueryParam';
import useTranslation from '../../../../hooks/useTranslation';
import { RoutePaths } from '../../../../routes/routes';
import { accountCategoriesAPI } from '../../../../api/finance/account/accountCategoriesAPI';

// components
import LoadingWrapper from '../../../../components/WrapperComponents/LoadingWrapper';
import DetailsContainer from './DetailsContainer';
import AccountCategoryTable from '../../../../components/Tables/TableTemplates/AccountCategories/AccountCategoryTable';
import { Typography } from 'antd';

const AccountCategoryDetails = () => {
  const { t } = useTranslation('finance');

  const categoryId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.Finance_AccountCategories,
  });
  const { response, loading } = useFetch(
    () =>
      categoryId
        ? accountCategoriesAPI.getAccountCategoryById(categoryId)
        : null,
    [categoryId],
  );

  return (
    <LoadingWrapper loading={loading}>
      {response && (
        <>
          <DetailsContainer accountCategory={response} />
          <StyledTitle level={3}>{t('account_categories.title')}</StyledTitle>
          <AccountCategoryTable
            data={response.children || []}
            loading={loading}
          />
        </>
      )}
    </LoadingWrapper>
  );
};

const StyledTitle = styled(Typography.Title)`
  margin-top: ${({ theme }) => theme.marginMd};
`;

export default AccountCategoryDetails;
