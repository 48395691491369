import React, { useState } from 'react';

// helpers
import useUserAccess from '../../../../../../hooks/useUserAccess';
import { IEntity } from 'typings/crm/entity';
import { NoteType } from '../../../../../../enums/crm/crm';

// components
import AddNote from './AddNote';
import NotesTable from '../../../../Notes/NotesTable';
import { Row, Col } from 'antd';

interface IProps {
  entity: IEntity;
}

const Notes = ({ entity }: IProps) => {
  const [hasCreateAccess] = useUserAccess([
    'CRM_Entities_Details_NotesTab_Create',
  ]);
  const [updateTrigger, updateTable] = useState({});

  return (
    <>
      <Row gutter={[36, 16]}>
        {hasCreateAccess && (
          <Col span={24}>
            <AddNote
              association={{ id: entity._id, type: NoteType.Organization }}
              onAdd={() => updateTable({})}
            />
          </Col>
        )}
        <Col span={24}>
          <NotesTable
            associationId={entity._id}
            updateTrigger={updateTrigger}
          />
        </Col>
      </Row>
    </>
  );
};

export default Notes;
