import React, { memo, useEffect, useState } from 'react';

// helpers
import moment from 'moment';
import { Moment } from 'moment';
import { PageLayoutHelperPropsModel } from 'layouts/PageLayout';

// components
import CashFlowReport from 'modules/Reporting/CashFlowReport';
import ExportCashFlowToExcelButton from 'components/Additional/ExportCashFlowToExcelButton';

type IProps = PageLayoutHelperPropsModel;

const CashFlow = memo(({ setHeaderOptions }: IProps) => {
  const [dateRange, setDateRange] = useState<[Moment, Moment]>([
    moment().add(-1, 'M').startOf('day'),
    moment().endOf('day'),
  ]);

  useEffect(() => {
    setHeaderOptions({
      extra: <ExportCashFlowToExcelButton selectedFilterDate={dateRange} />,
    });
  }, [dateRange]);

  return (
    <CashFlowReport
      selectedFilterDate={dateRange}
      setSelectedDate={setDateRange}
    />
  );
});

export default CashFlow;
