import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { IAccountModel } from '../../../typings/finance/account';

// components
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

interface IProps {
  account: IAccountModel | null;
}

const AccountDetails = ({ account }: IProps) => {
  const { t } = useTranslation('finance');

  const descriptionsData = useMemo<DescriptionItem[]>(
    () => [
      {
        label: t('accounts.account_activity.account_number'),
        description: account?.accountNumber,
      },
      {
        label: t('accounts.account_activity.currency'),
        description: account?.currencyCode,
      },
      {
        label: t('accounts.account_activity.type'),
        description: account?.financialTypeName,
      },
      {
        label: t('accounts.account_activity.parent_category'),
        description: account?.accountCategoryName,
      },
      {
        label: t('accounts.account_activity.opening_date'),
        description: account?.creationDateTimestamp
          ? DateHelpers.formatTimestampToString(account.creationDateTimestamp)
          : null,
      },
    ],
    [account],
  );

  return (
    <StyledDescriptionSection
      data={descriptionsData}
      background="transparent"
    />
  );
};

const StyledDescriptionSection = styled(DescriptionSection)`
  margin-bottom: ${({ theme }) => theme.marginMd};
`;

export default AccountDetails;
