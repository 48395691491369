import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import BankAccount, { BankAccountTemplateData } from './Templates/BankAccount';
import { IClientGroup } from 'typings/crm/client-group';
import { ClientApprovalEntryModel } from 'apiAdapters/approval/clientAccountManagementRulesAdapterAPI';
import { TransactionRuleFromQuery } from 'api/approval/approvalManagement/clientTransactionRulesAPI';
import { ClientApprovalWorkflowEntryTypes } from 'typings/approval/approvalWorkflow';

// components
import SectionIntro from '@common_components/Texts/SectionIntro';
import TransactionRule from './Templates/TransactionRule';
import ClientGroup, {
  ClientGroupTemplateDataModel,
} from './Templates/ClientGroup';
import ApprovalGroup, {
  ApprovalGroupTemplateData,
} from './Templates/ApprovalGroup';
import CircleService, {
  CircleServiceTemplateDataModel,
} from './Templates/CircleService';
import AdministrationApprovalRule, {
  AccountManagementApprovalRuleTemplateData,
} from './Templates/AdministrationApprovalRule';

interface IProps {
  approvalEntry: ClientApprovalEntryModel;
  clientGroup: IClientGroup;
}

const ChangeDifferenceSection = ({ approvalEntry, clientGroup }: IProps) => {
  const { t } = useTranslation('crm');

  const renderContent = () => {
    switch (approvalEntry.entry.type) {
      case ClientApprovalWorkflowEntryTypes.TRANSACTION_APPROVAL_GROUP:
      case ClientApprovalWorkflowEntryTypes.ACCOUNT_MNGT_APPROVAL_GROUP:
        return (
          <ApprovalGroup
            approvalEntry={
              approvalEntry as ClientApprovalEntryModel<ApprovalGroupTemplateData>
            }
          />
        );

      case ClientApprovalWorkflowEntryTypes.TRANSACTION_APPROVAL_RULE:
        return (
          <TransactionRule
            approvalEntry={
              approvalEntry as ClientApprovalEntryModel<TransactionRuleFromQuery>
            }
          />
        );

      case ClientApprovalWorkflowEntryTypes.ACCOUNT_MNGT_APPROVAL_RULE:
        return (
          <AdministrationApprovalRule
            approvalEntry={
              approvalEntry as ClientApprovalEntryModel<AccountManagementApprovalRuleTemplateData>
            }
          />
        );

      case ClientApprovalWorkflowEntryTypes.ACCOUNT_MNGT_NEW_BANKACCOUNT:
        return (
          <BankAccount
            approvalEntry={
              approvalEntry as ClientApprovalEntryModel<BankAccountTemplateData>
            }
          />
        );

      case ClientApprovalWorkflowEntryTypes.ACCOUNT_MNGT_UPDATE_CLIENTGROUP:
        return (
          <ClientGroup
            approvalEntry={
              approvalEntry as ClientApprovalEntryModel<ClientGroupTemplateDataModel>
            }
            clientGroup={clientGroup}
          />
        );

      case ClientApprovalWorkflowEntryTypes.ACCOUNT_MNGT_CIRCLE_INTEGRATION:
        return (
          <CircleService
            approvalEntry={
              approvalEntry as ClientApprovalEntryModel<CircleServiceTemplateDataModel>
            }
          />
        );
    }
  };

  return (
    <>
      <SectionIntro
        titleVariant="h4"
        title={t(
          'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.title',
        )}
      />
      {renderContent()}
    </>
  );
};

export default ChangeDifferenceSection;
