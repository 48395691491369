import React, { useEffect } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_APPROVAL_MANAGEMENT_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import ApprovalWorkflowTabs from '../../modules/ApprovalManagement/ApprovalWorkflowTabs';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const ApprovalWorkflow = ({ setHeaderOptions }: IProps) => {
  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation
          link={GENESIS_APPROVAL_MANAGEMENT_DOCUMENTATION_LINK}
        />
      ),
    });
  }, []);

  return <ApprovalWorkflowTabs />;
};

export default ApprovalWorkflow;
