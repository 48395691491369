import React from 'react';

// helpers
import { Moment } from 'moment';
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import { Col, DatePicker, Row } from 'antd';

interface IProps {
  filterChange: (dateRange: any) => void;
  dateFilterValue: [Moment, Moment];
}

const FilterBar = ({ dateFilterValue, filterChange }: IProps) => {
  const { t } = useTranslation(['finance', 'common']);

  return (
    <StyledRow gutter={[16, 16]}>
      <Col span={6}>
        <Row>
          <Col span={24}>{t('currency_rates.filter_by_date')}</Col>
          <Col span={24}>
            <StyledDatePicker
              size="large"
              allowClear={false}
              placeholder={t('select_date', { ns: 'common' })}
              value={dateFilterValue}
              onChange={filterChange}
              disabledDate={(currentDate: Moment) =>
                !currentDate || currentDate.isAfter(new Date())
              }
            />
          </Col>
        </Row>
      </Col>
    </StyledRow>
  );
};

const StyledDatePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;
export default FilterBar;
