import React from 'react';

// helpers utils
import useTranslation from '../../../../../../hooks/useTranslation';
import { enumToMap } from '../../../../../../helpers/utils';
import { SelectProps } from 'antd/lib/select';

// components
import { Select } from 'antd';
import { CountryRiskRating } from '../../../../../../typings/compliance/countryRiskRatings';

type IProps = SelectProps<any>;

const CountryRiskRatingSelect = (props: IProps) => {
  const { t } = useTranslation('compliance');

  const renderOptions = () => {
    return Array.from(enumToMap(CountryRiskRating)).map((el) => (
      <Select.Option key={el[1]} value={el[0].toLowerCase()}>
        {t(`country_risk_ratings.types.${el[0]}`)}
      </Select.Option>
    ));
  };

  return (
    <Select
      {...props}
      placeholder={t('country_risk_ratings.filter_bar.select_placeholder')}
    >
      {renderOptions()}
    </Select>
  );
};

export default CountryRiskRatingSelect;
