import React, { useCallback } from 'react';

// helpers
import { messagingAPI } from '../../../../../api/messaging/messagingAPI';
import { messagingAdapter } from '../../../../../apiAdapters/messaging/messagingAdapter';
import { ISendMessageForm } from '../../../../../components/Modals/TemplateModalDialogs/Messaging/SendMessageModal/InnerForm';
import { IDocumentAssociationInfo } from '../../../Documents/UploadDocumentsDialog';

// components
import SendMessageComponent from '../../../../Messaging/SendMessage';

interface IProps {
  clientGroupNumericId: number;
  clientGroupId: string;
  categoryId?: number;
  association: IDocumentAssociationInfo;
  onSent?: () => void;
}

const SendMessage = ({
  clientGroupNumericId,
  clientGroupId,
  categoryId,
  association,
  onSent,
}: IProps) => {
  const handleSendMessage = useCallback(async (values: ISendMessageForm) => {
    await messagingAPI.createThread(
      clientGroupNumericId,
      messagingAdapter.createThread(values),
    );
    onSent && onSent();
  }, []);

  return (
    <SendMessageComponent
      createThread={handleSendMessage}
      categoryId={categoryId}
      clientGroupId={clientGroupId}
      association={association}
    />
  );
};

export default SendMessage;
