import React, { useEffect } from 'react';

// Helpers & Utils
import useUserAccess from '../../hooks/useUserAccess';
import { GENESIS_CONTACTS_DOCUMENTATION_LINK } from '../../constants/externalLinks';
import {
  ILayoutHeaderOptions,
  PageLayoutHelperPropsModel,
} from '../../layouts/PageLayout';

// Components
import AddContact from 'modules/CRM/Contacts/AddContact';
import ContactsTable from '../../modules/CRM/Contacts/ContactsTable';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const Contacts = ({ setHeaderOptions }: IProps) => {
  const [hasCreateAccess] = useUserAccess(['CRM_Contacts_Create']);

  useEffect(() => {
    const headerOptions: ILayoutHeaderOptions = {
      appendToTitle: (
        <TooltipToDocumentation link={GENESIS_CONTACTS_DOCUMENTATION_LINK} />
      ),
    };
    if (hasCreateAccess) {
      headerOptions['extra'] = <AddContact />;
    }

    setHeaderOptions(headerOptions);
  }, [hasCreateAccess]);

  return <ContactsTable />;
};

export default Contacts;
