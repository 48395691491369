import React, { useEffect, useState } from 'react';

// helpers
import moment from 'moment';
import { PageLayoutHelperPropsModel } from 'layouts/PageLayout';

// components
import BalanceSheetReport from '../../modules/Reporting/BalanceSheetReport';
import ExportBalanceSheetToExcelButton from 'components/Additional/ExportBalanceSheetToExcelButton';

type IProps = PageLayoutHelperPropsModel;

const BalanceSheet = ({ setHeaderOptions }: IProps) => {
  const [filterDate, setFilterDate] = useState(moment());

  useEffect(() => {
    setHeaderOptions({
      extra: (
        <ExportBalanceSheetToExcelButton selectedFilterDate={filterDate} />
      ),
    });
  }, [filterDate]);

  return (
    <BalanceSheetReport
      selectedFilterDate={filterDate}
      setSelectedDate={setFilterDate}
    />
  );
};

export default BalanceSheet;
