import React, { useState, useEffect } from 'react';

// helpers
import { approvalGroupsAPI } from '../../../../../../api/approval/approvalManagement/approvalGroupsAPI';
import { FetchResponseModel } from 'typings/common';
import { FormFieldPropsModel } from '@core_components/FormField';
import { clientAccountManagementRulesAPI } from 'api/approval/approvalManagement/clientAccountManagementRulesAPI';
import {
  ApprovalWorkflowType,
  GroupParticipantModel,
} from '../../../../../../typings/approval/templates';

// components
import Autocomplete, {
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';

interface IProps extends FormFieldPropsModel {
  workflowType: ApprovalWorkflowType;
  clientGroupId?: number;
  value: GroupParticipantModel[];
  onSelect: (value: string, participant: GroupParticipantModel) => void;
  onChange: (newValue: GroupParticipantModel[]) => void;
}

// TODO:
// 1. Integrate real API for autocomplete (transaction approval groups autocomplete)
const ApprovalGroupParticipantsAutocomplete = ({
  value,
  workflowType,
  clientGroupId,
  onChange,
  ...rest
}: IProps) => {
  const [privateValue, setPrivateValue] = useState(
    value ? value.map((e) => e.userId) : [],
  );
  const [participantsArray, setParticipants] = useState<
    GroupParticipantModel[]
  >([]);

  //  Reinitialize local value if the original value was cleared
  useEffect(() => {
    if (!value || (!value.length && privateValue.length)) {
      setPrivateValue([]);
    }
  }, [value]);

  const getParticipantsBasedOnWorkflowType = async (
    workflowType: ApprovalWorkflowType,
  ): Promise<FetchResponseModel<GroupParticipantModel>> => {
    if (!workflowType) {
      return { data: [], total: 0 };
    }

    switch (workflowType) {
      case 'compliance':
      case 'finance':
      case 'forex-transfers':
      case 'new-business':
      case 'senior-management':
        return approvalGroupsAPI.fetchApprovalGroupParticipants();

      case 'administration':
        return clientAccountManagementRulesAPI.fetchApprovalGroupParticipants(
          clientGroupId as number,
        );

      case 'transactions':
        return approvalGroupsAPI.fetchTransactionApprovalGroupParticipants(
          clientGroupId as number,
        );
    }
  };

  const handleChange = (newValue: string[]) => {
    let arrayCopy = value.slice();

    // delete item case
    if (newValue.length < arrayCopy.length) {
      arrayCopy = arrayCopy.filter((e) => newValue.includes(e.userId));
    } else {
      // take only new items
      const newItems = newValue.filter((el) => !privateValue.includes(el));
      // push new items to array
      newItems.forEach((newItemKey) => {
        const itemIndex = participantsArray.findIndex(
          (el) => el.userId === newItemKey,
        );
        arrayCopy.push(participantsArray[itemIndex]);
      });
    }

    onChange(arrayCopy);
    setPrivateValue(newValue);
  };

  const fetchParticipants = async () => {
    let result: AutocompleteOption[] = [];
    const { data } = await getParticipantsBasedOnWorkflowType(workflowType);

    result = data.map((participant) => ({
      id: participant.userId,
      content: participant.userFullName,
      model: participant,
    }));

    setParticipants(data || []);
    return result;
  };

  return (
    <Autocomplete
      {...rest}
      value={privateValue}
      onChange={handleChange}
      fetchData={fetchParticipants}
    />
  );
};

export default ApprovalGroupParticipantsAutocomplete;
