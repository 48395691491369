import React, { memo, useCallback } from 'react';

// helpers
import moment from 'moment';
import useFetch from '@hooks/useFetch';
import { Moment } from 'moment';
import { reportingAPI } from 'api/reporting/reportingAPI';

// components
import FilterBar from './FilterBar';
import CashFlowTable from 'components/Tables/TableTemplates/Reporting/CashFlowTable';
import CashFlowTotalTable from 'components/Tables/TableTemplates/Reporting/CashFlowTotalTable';
import { Divider } from 'antd';

interface IProps {
  selectedFilterDate: [Moment, Moment] | null;
  setSelectedDate: (range: [Moment, Moment]) => void;
}

const CashFlow = memo(({ selectedFilterDate, setSelectedDate }: IProps) => {
  const { response, loading } = useFetch(
    () =>
      selectedFilterDate
        ? reportingAPI.fetchCashFlowReport({
            fromDate: selectedFilterDate[0].format('X'),
            toDate: selectedFilterDate[1].format('X'),
          })
        : null,
    [selectedFilterDate],
  );

  const handleFilterChange = useCallback(
    (key: string, value: any) => {
      if (selectedFilterDate) {
        switch (key) {
          case 'startDate':
            setSelectedDate([
              moment(value).startOf('day'),
              selectedFilterDate[1],
            ]);
            break;
          case 'endDate':
            setSelectedDate([
              selectedFilterDate[0],
              moment(value).endOf('day'),
            ]);
            break;
        }
      }
    },
    [selectedFilterDate],
  );

  return (
    <>
      <FilterBar
        filterChange={handleFilterChange}
        dateRangeValue={selectedFilterDate}
        isDisabled={false}
      />
      <Divider />
      <CashFlowTotalTable
        data={response?.totalReportData || []}
        loading={loading}
      />
      <Divider />
      <CashFlowTable
        data={response?.cashFlowReportData || []}
        loading={loading}
      />
    </>
  );
});

export default CashFlow;
