import { IReduxAction } from '../../typings/common';
import { ActionType } from '../actions/app';

export interface StateModel {
  isInitialized: boolean;
  lastActivityTimestamp: number;
}

// Initial state
export const initialState: StateModel = {
  isInitialized: false,
  lastActivityTimestamp: Date.now(),
};

const app = (state = initialState, action: IReduxAction) => {
  switch (action.type) {
    case ActionType.SET_INITIALIZED_STATUS:
      return {
        ...state,
        isInitialized: action.payload.status,
      };

    case ActionType.SET_ACTIVITY_TIMESTAMP:
      return {
        ...state,
        lastActivityTimestamp: action.payload.lastActivityTimestamp,
      };

    default:
      return state;
  }
};

export default app;
