import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../../../../routes/routes';
import { BankAccountStatuses } from 'enums/banking/accounts';

// components
import DetailsRow from '../../../../DetailsRow';
import BankAccountStatus from 'components/Additional/Statuses/BankAccountStatus';
import { Collapse, Typography } from 'antd';

export type UpdateBankAccountData = {
  accountName: string;
  accountNumber: string;
  old: {
    status: BankAccountStatuses;
    isFrozen: boolean;
  };
  new: {
    status: BankAccountStatuses;
    isFrozen: boolean;
  };
};

interface IProps {
  data: UpdateBankAccountData;
}

const UpdateBankAccountDetails = memo(({ data }: IProps) => {
  const { t } = useTranslation(['approval_management', 'finance']);
  const history = useHistory();

  return (
    <Collapse
      defaultActiveKey={['bank_account_info', 'old_version', 'new_version']}
    >
      <Collapse.Panel
        key="bank_account_info"
        header={t(
          'approval_workflow.approval_workflow_changes.update_bank_account.bank_account_info',
        )}
      >
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_bank_account.account_name',
          )}
          value={data.accountName}
        />
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_bank_account.account_number',
          )}
          value={
            <Typography.Link
              onClick={() =>
                history.push({
                  pathname: RoutePaths.Banking_BankAccounts,
                  search: `?accountNumber=${data.accountNumber}`,
                })
              }
            >
              {data.accountNumber}
            </Typography.Link>
          }
        />
      </Collapse.Panel>

      <Collapse.Panel
        header={t('approval_workflow.approval_workflow_changes.old_version')}
        key="old_version"
      >
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_bank_account.is_active',
          )}
          value={<BankAccountStatus status={data.old.status} />}
        />
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_bank_account.is_frozen',
          )}
          value={t(
            `accounts.table.debit_frozen.${data.old.isFrozen ? 'frozen' : 'unfrozen'}`,
            { ns: 'finance' },
          )}
        />
      </Collapse.Panel>

      <Collapse.Panel
        header={t('approval_workflow.approval_workflow_changes.new_version')}
        key="new_version"
      >
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_bank_account.is_active',
          )}
          value={<BankAccountStatus status={data.new.status} />}
        />
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_bank_account.is_frozen',
          )}
          value={t(
            `accounts.table.debit_frozen.${data.new.isFrozen ? 'frozen' : 'unfrozen'}`,
            { ns: 'finance' },
          )}
        />
      </Collapse.Panel>
    </Collapse>
  );
});

export default UpdateBankAccountDetails;
