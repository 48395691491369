import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import {
  CountryRiskRating,
  CountryRiskRatingModel,
} from '../../../../../typings/compliance/countryRiskRatings';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel<CountryRiskRatingModel>;

const CountryRiskRatingsTable = memo((props: IProps) => {
  const { t } = useTranslation('compliance');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('country_risk_ratings.table.name'),
        key: 'name',
        render: (record: CountryRiskRatingModel) => record.name,
      },

      {
        title: t('country_risk_ratings.table.code'),
        key: 'code',
        render: (record: CountryRiskRatingModel) => record.alpha2Code,
      },

      {
        title: t('country_risk_ratings.table.risk_rating'),
        key: 'risk_rating',
        render: (record: CountryRiskRatingModel) =>
          CountryRiskRating[record.riskRating],
      },
    ];

    return result;
  }, []);

  return <Table {...props} columns={columns} />;
});

export default CountryRiskRatingsTable;
