import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { WireDetailsModel } from 'typings/banking/bankAccounts';
import { bankingBankAccountsAdapter } from 'apiAdapters/banking/bankingBankAccountsAdapter';

// components
import Form from '@core_components/Form';
import Link from '@common_components/Texts/Link';
import UpdateSSIForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Banking/UpdateSSIForm';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

export type UpdateWireDetailsData = {
  correspondentBankId: number;
  correspondentBankName: string;
  correspondentBankAccountNumber: string;
  correspondentBankAccountName: string;
  wireDetails: {
    isManualBeneficiary: boolean;
    beneficiaryCity: string;
    beneficiaryCountry: string;
    beneficiaryAddress: string;
    beneficiaryDistrict: string;
    beneficiaryPostCode: string;
    beneficiaryAccountName: string;
    beneficiaryAccountNumber: string;

    beneficiaryBankName: string;
    beneficiaryBankCode: string;
    beneficiaryBankCodeType: number;
    beneficiaryBankCodeTypeName: string;
    beneficiaryBankAddress: string;
    beneficiaryBankCity: string;
    beneficiaryBankDistrict: string;
    beneficiaryBankPostCode: string;
    beneficiaryBankCountry: string;

    intermediaryBankName: string;
    intermediaryBankCode: string;
    intermediaryBankCodeType: number;
    intermediaryBankCodeTypeName: string;
    intermediaryBankAddress: string;
    intermediaryBankCity: string;
    intermediaryBankDistrict: string;
    intermediaryBankPostCode: string;
    intermediaryBankCountry: string;
  };
};
interface IProps {
  data: UpdateWireDetailsData;
}

const UpdateWireDetails = ({ data }: IProps) => {
  const { t } = useTranslation('finance');
  const history = useHistory();

  const initialFormValues = useMemo<WireDetailsModel>(() => {
    const formattedData =
      bankingBankAccountsAdapter.mapComplianceUpdateWireDetailsToWireDetailsModel(
        data,
      );

    return {
      isManualBeneficiary: formattedData.isManualBeneficiary,

      beneficiary: {
        address: formattedData.beneficiary.address,
        accountName: formattedData.beneficiary.accountName,
        accountNumber: formattedData.beneficiary.accountNumber,
        city: formattedData.beneficiary.city,
        country: formattedData.beneficiary.country,
        district: formattedData.beneficiary.district,
        postCode: formattedData.beneficiary.postCode,
      },

      beneficiaryBank: {
        bankCodeType: formattedData.beneficiaryBank.bankCodeType,
        bankCode: formattedData.beneficiaryBank.bankCode,
        bankName: formattedData.beneficiaryBank.bankName,
        address: formattedData.beneficiaryBank.address,
        country: formattedData.beneficiaryBank.country,
        city: formattedData.beneficiaryBank.city,
        district: formattedData.beneficiaryBank.district,
        postCode: formattedData.beneficiaryBank.postCode,
      },
      intermediaryBank: {
        bankCodeType: formattedData.intermediaryBank.bankCodeType,
        bankCode: formattedData.intermediaryBank.bankCode,
        bankName: formattedData.intermediaryBank.bankName,
        address: formattedData.intermediaryBank.address,
        country: formattedData.intermediaryBank.country,
        city: formattedData.intermediaryBank.city,
        district: formattedData.intermediaryBank.district,
        postCode: formattedData.intermediaryBank.postCode,
      },
    };
  }, [data]);

  const descriptiptionsData = useMemo<DescriptionItem[]>(() => {
    return [
      {
        label: t('accounts.compliance_workflow.correspondent_bank_name'),
        description: (
          <Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.Banking_CorrespondentBankDetails,
                search: `?id=${data.correspondentBankId}`,
              })
            }
          >
            {data.correspondentBankName}
          </Link>
        ),
      },
      {
        label: t(
          'accounts.compliance_workflow.correspondent_bank_account_number',
        ),
        description: data.correspondentBankAccountNumber,
      },
      {
        label: t(
          'accounts.compliance_workflow.correspondent_bank_account_name',
        ),
        description: data.correspondentBankName,
      },
    ];
  }, [data]);
  return (
    <>
      <StyledDescriptionSection
        size="small"
        background="transparent"
        bordered={false}
        data={descriptiptionsData}
      />

      <Form<FormValuesModel>
        disabled
        onSubmit={console.log}
        initialValues={initialFormValues}
        renderForm={<UpdateSSIForm />}
      />
    </>
  );
};

const StyledDescriptionSection = styled(DescriptionSection)`
  margin-bottom: ${({ theme }) => theme.marginSm};

  .ant-descriptions-item-label {
    width: 300px !important;
    max-width: 300px !important;
  }
`;

export default UpdateWireDetails;
