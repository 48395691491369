import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import { MessageModel } from '../../../../../../../typings/messaging/messages';
import { useTranslation } from 'react-i18next';

// helpers
import NewMessageMarker from '../../../../../../Additional/Messaging/NewMessageMarker';
import { Typography } from 'antd';

interface IProps {
  message: MessageModel;
  isUnread: boolean;
  isActive?: boolean;
}

const MessageAuthor = memo(({ message, isActive, isUnread }: IProps) => {
  const { t } = useTranslation('crm');

  const senderName = message.from.name;

  return (
    <>
      <StyledSenderName>
        {senderName}
        {isUnread && <StyledNewMessageMarker />}
      </StyledSenderName>

      {isActive && (
        <StyledRecipientName>
          {`${t('messaging.view_message.to')}: `}
          <>
            {message && message.to
              ? message.to.map((user, i, usersArray) => (
                  <UserNameText key={user.id}>
                    {user.name}
                    {i < usersArray?.length - 1 ? ', ' : ' '}
                  </UserNameText>
                ))
              : null}
          </>
        </StyledRecipientName>
      )}
    </>
  );
});

const StyledSenderName = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.whiteColor} !important;
  margin-bottom: 0px !important;

  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const StyledRecipientName = styled(Typography.Paragraph)`
  font-weight: 100;
  color: ${({ theme }) => theme.textLightColor2} !important;
  margin-top: ${({ theme }) => theme.marginXXs} !important;
  margin-bottom: 0px !important;
`;

const UserNameText = styled(Typography.Text)`
  color: ${({ theme }) => theme.whiteColor};
`;

const StyledNewMessageMarker = styled(NewMessageMarker)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default MessageAuthor;
