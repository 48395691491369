import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { darkTheme } from '../../../../../../../resources/theme/styled';
import { SelectOption } from '@core_components/Select';
import { ApprovalGroupModel } from '../../../../../../../typings/approval/templates';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FieldArray, useFormikContext } from 'formik';
import { ConditionModel, FormValuesModel } from '../..';

// components
import Text from '../../../../../../DesignSystem/Core/Text';
import IconSVG from '../../../../../../DesignSystem/Core/IconSVG';
import FormField from '@core_components/FormField';
import FormSelect from '@common_components/Form/FormSelect';
import { Button, Checkbox, Col, Row } from 'antd';
import { ReactComponent as DeleteIcon } from 'resources/icons/remix-icons/delete-bin-6-line.svg'; //'resources/icons/remix-icons/delete-bin-6-line.svg';

interface IProps {
  dataIndex: string;
  colIndex: number;
  isDisabled: boolean;
  record: ConditionModel;
  rowIndex: number;
  approvalGroups: ApprovalGroupModel[];
  handleAddAnd: (toPosition: number) => void;
}

const ContentCell = memo(
  ({
    dataIndex,
    colIndex,
    record,
    rowIndex,
    handleAddAnd,
    approvalGroups,
    isDisabled,
  }: IProps) => {
    const formik = useFormikContext<FormValuesModel>();
    const { t } = useTranslation('approval_management');

    const approvalGroupFieldOptions = useMemo<
      SelectOption<ApprovalGroupModel>[]
    >(() => {
      return approvalGroups.map((e) => {
        return {
          id: e.id,
          model: e,
          label: e.name,
        };
      });
    }, [approvalGroups]);

    // Check if the limit is using in at least of one condition
    const isLimitUsed = (
      limitIndex: number,
      updatedConditions: ConditionModel[],
    ) => {
      let isUsing = false;

      for (let i = 0; i < updatedConditions.length; i++) {
        const { limitStatuses } = updatedConditions[i];

        if (limitStatuses[limitIndex].isChecked) {
          isUsing = true;
          break;
        }
      }

      return isUsing;
    };

    const handleLimitCheckboxChange = (e: CheckboxChangeEvent) => {
      const value = e.target.checked;

      const conditionsCopy = formik.values.conditions.slice();
      conditionsCopy[rowIndex].limitStatuses[colIndex].isChecked = value;

      formik.setFieldValue(
        `conditions[${rowIndex}].limitStatuses[${colIndex}].isChecked`,
        value,
      );

      const isLimitStillUsed = value || isLimitUsed(colIndex, conditionsCopy);
      formik.setFieldValue(`limits[${colIndex}].isUsed`, isLimitStillUsed);
    };

    const onDeleteRow = (conditionIndex: number) => {
      const approvalRulesCopy = formik.values.conditions.slice();
      approvalRulesCopy.splice(conditionIndex, 1);
      formik.setFieldValue('conditions', approvalRulesCopy);
    };

    const handleGroupSelect = (
      fieldName: string,
      newGroupId: string,
      innerIndex: number,
    ) => {
      const { approvalGroup } =
        formik.values.conditions[rowIndex].conditions[innerIndex];
      if (!approvalGroup || newGroupId === approvalGroup) {
        formik.setFieldValue(fieldName, newGroupId);
        formik.setFieldValue(
          `conditions[${rowIndex}].conditions[${innerIndex}].requiredApproversCount`,
          null,
        );
      } else {
        formik.setFieldValue(fieldName, newGroupId);
        formik.setFieldValue(
          `conditions[${rowIndex}].conditions[${innerIndex}].requiredApproversCount`,
          null,
        );
      }
    };

    const getApproversRequiredOptionsArray = (
      innerIndex: number,
    ): SelectOption[] => {
      const result: SelectOption[] = [];
      const approvalGroupsCopy = approvalGroups.slice();
      const { approvalGroup } =
        formik.values.conditions[rowIndex].conditions[innerIndex];
      const selectedGroupIdx = approvalGroupsCopy.findIndex(
        (el) => el.id === approvalGroup,
      );

      if (selectedGroupIdx === -1) {
        return result;
      }

      const participantsCount =
        approvalGroupsCopy[selectedGroupIdx].participants.length;

      result.push(
        ...Array.from(Array(participantsCount), (_, i) => ({
          id: i + 1,
          label: i + 1,
        })),
      );

      return result;
    };

    const renderContentCell = () => {
      let result = null;

      switch (dataIndex) {
        case 'conditions':
          {
            const isLastRowInDataSource =
              rowIndex === formik.values.conditions.length - 1;
            const shouldRenderDeleteSectionBtn =
              !isDisabled && formik.values.conditions.length > 1;

            result = (
              <>
                {shouldRenderDeleteSectionBtn && (
                  <EndAlignWrapper onClick={() => onDeleteRow(rowIndex)}>
                    <StyledIconSVG
                      component={DeleteIcon}
                      color={darkTheme.errorColor}
                    />
                  </EndAlignWrapper>
                )}
                <FieldArray
                  name={`conditions[${rowIndex}].conditions`}
                  render={(helpers) => {
                    return record.conditions.map(
                      (_: any, innerIndex: number) => {
                        const approvalGroupFieldName = `conditions[${rowIndex}].conditions[${innerIndex}].approvalGroup`;
                        const isApproversDisabled =
                          !record.conditions[innerIndex].approvalGroup;
                        const shouldRenderANDBtn =
                          !isDisabled &&
                          innerIndex === record.conditions.length - 1;
                        const shouldRenderAND =
                          innerIndex < record.conditions.length - 1;
                        const shouldRenderOR =
                          !isLastRowInDataSource &&
                          innerIndex === record.conditions.length - 1;

                        return (
                          <RelativeWrapper key={`${rowIndex}-${innerIndex}`}>
                            <StyledRow gutter={[16, 16]}>
                              <Col span={24}>
                                <FormField
                                  disabled={isDisabled}
                                  name={approvalGroupFieldName}
                                  component={FormSelect}
                                  shouldShowErrorMessage={false}
                                  additionalProps={{
                                    allowClear: true,
                                    options: approvalGroupFieldOptions,
                                    onSelect: (value: string) =>
                                      handleGroupSelect(
                                        approvalGroupFieldName,
                                        value,
                                        innerIndex,
                                      ),
                                    excludeOptions: !formik.values
                                      .filterClientGroupsInDropdown
                                      ? undefined
                                      : record.conditions.map(
                                          (e, conditionIdx) =>
                                            conditionIdx !== innerIndex &&
                                            e.approvalGroup,
                                        ),
                                  }}
                                />
                              </Col>

                              <Col span={24}>
                                <FormField
                                  name={`conditions[${rowIndex}].conditions[${innerIndex}].requiredApproversCount`}
                                  disabled={isApproversDisabled || isDisabled}
                                  component={StyledSelect}
                                  shouldShowErrorMessage={false}
                                  additionalProps={{
                                    allowClear: true,
                                    options:
                                      getApproversRequiredOptionsArray(
                                        innerIndex,
                                      ),
                                  }}
                                />
                              </Col>
                            </StyledRow>

                            {shouldRenderAND && (
                              <AndContainer>
                                {t('finance_template.template.and')}
                                {!isDisabled && (
                                  <StyledIconSVG
                                    component={DeleteIcon}
                                    color={darkTheme.errorColor}
                                    onClick={() =>
                                      helpers.remove(innerIndex + 1)
                                    }
                                  />
                                )}
                              </AndContainer>
                            )}

                            {shouldRenderOR && (
                              <ORContainer canDelete={!isDisabled}>
                                <Text variant="body1" weight="semi-bold">
                                  {t('finance_template.template.or')}
                                </Text>
                              </ORContainer>
                            )}

                            {shouldRenderANDBtn && (
                              <AlignEndWrapper>
                                <Button onClick={() => handleAddAnd(rowIndex)}>
                                  {t('finance_template.template.add_and')}
                                </Button>
                              </AlignEndWrapper>
                            )}
                          </RelativeWrapper>
                        );
                      },
                    );
                  }}
                />
              </>
            );
          }
          break;

        case 'limitStatuses':
          {
            const checkboxValue =
              formik.values.conditions[rowIndex].limitStatuses[colIndex]
                .isChecked;
            result = (
              <AlignCenterWrapper>
                <Checkbox
                  checked={checkboxValue}
                  onChange={handleLimitCheckboxChange}
                  disabled={isDisabled}
                />
              </AlignCenterWrapper>
            );
          }
          break;
      }

      return result;
    };

    return <td>{renderContentCell()}</td>;
  },
);

const StyledRow = styled(Row)`
  margin: ${({ theme }) => `${theme.marginSm} 0`};
`;

const RelativeWrapper = styled.div`
  position: relative;
`;

const AlignCenterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AlignEndWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AndContainer = styled.div`
  font-weight: 600;
  margin-left: ${({ theme }) => theme.marginSm};
  display: flex;
  align-items: center;
`;

const ORContainer = styled.div<{ canDelete: boolean }>`
  position: absolute;
  bottom: ${({ canDelete }) => (canDelete ? '-33px' : '-48px')};
  left: 0;

  width: 55px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.tableCardBackground};
`;

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.marginSm};
`;

const EndAlignWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledSelect = styled(FormSelect)`
  width: 100%;
`;

export default ContentCell;
