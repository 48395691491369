import React, { memo, useCallback, useMemo, useState } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { keyRelationshipTemplatesAPI } from 'api/crm/keyRelationship/keyRelationshipTemplatesAPI';
import { keyRelationshipTemplatesAdapter } from 'apiAdapters/crm/keyRelationship/keyRelationshipTemplatesAdapter';

// components
import PageButton from '@common_components/Buttons/PageButton';
import EntityTypeCategoryModalDialog, {
  EntityTypeCategoryFormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/EntityTypeCategoryModalDialog';

interface IProps {
  onAdd: () => void;
}

const AddEntityTypeCategory = memo(({ onAdd }: IProps) => {
  const { t } = useTranslation('crm');
  const [isVisible, setIsVisible] = useState(false);

  const initialFormValues = useMemo<EntityTypeCategoryFormValuesModel>(() => {
    return {
      name: '',
      description: '',
      isOnboardingOption: false,
      additionalFields: [],
      relationshipTemplates: [],
    };
  }, []);

  const handleModalClose = useCallback(
    (wasSubmitted?: boolean) => {
      if (wasSubmitted) {
        onAdd();
      }

      setIsVisible(false);
    },
    [onAdd],
  );

  const handleSubmit = useCallback(async (values) => {
    const formattedBody =
      keyRelationshipTemplatesAdapter.formatEntityTypeCategoryFromFormToQuery(
        values,
      );
    await keyRelationshipTemplatesAPI.createKeyRelationshipTemplate(
      formattedBody,
    );
    message.success(t('entity_type_category.new.success_message'));
  }, []);

  return (
    <>
      <PageButton onClick={() => setIsVisible(true)}>
        {t('entity_type_category.new.title')}
      </PageButton>
      <EntityTypeCategoryModalDialog
        title={t('entity_type_category.new.title')}
        isVisible={isVisible}
        onSubmit={handleSubmit}
        closeCallback={handleModalClose}
        initialValues={initialFormValues}
      />
    </>
  );
});

export default AddEntityTypeCategory;
