import * as yup from 'yup';
import { accountsAPI } from '../../../api/finance/account/accountsAPI';
import {
  STRING_VALIDATION_RULE,
  NULLABLE_NUMBER_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  NULLABLE_BOOLEAN_VALIDATION_RULE,
} from 'validations/common';

const noSpecialChars = STRING_VALIDATION_RULE.matches(
  /^[_a-zA-Z0-9.,-<>\n\s]*$/,
  'No special characters allowed',
);

export const AccountSupplementaryFormValidationSchema = yup.object().shape({
  purpose: STRING_VALIDATION_RULE.min(3, {
    key: 'form:validation_messages.min_characters',
    values: { number: 3 },
  })
    .max(2000, {
      key: 'form:validation_messages.max_characters',
      values: { number: 2000 },
    })
    .required('form:validation_messages.required'),
  type: yup.mixed().nullable().required('form:validation_messages.required'),
  initialDeposit: yup
    .mixed()
    .nullable()
    .required('form:validation_messages.required'),
  initialDepositOrigin: NULLABLE_STRING_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  jurisdictions: yup
    .array()
    .min(1, 'form:validation_messages.required')
    .required('form:validation_messages.required'),
  incomingWiresNumber: yup
    .mixed()
    .nullable()
    .required('form:validation_messages.required'),
  estimatedIncomingFunds: yup
    .mixed()
    .nullable()
    .required('form:validation_messages.required'),
  outgoingWiresNumber: yup
    .mixed()
    .nullable()
    .required('form:validation_messages.required'),
  estimatedOutgoingFunds: yup
    .mixed()
    .nullable()
    .required('form:validation_messages.required'),
});

export const UpdateBankAccountValidationSchema = yup.object().shape({
  isActive: yup.boolean().required('This field is required'),
  isFrozen: yup.boolean().required('This field is required'),
});

export const BankAccountValidationSchema = yup.object().shape({
  isReconciled: NULLABLE_BOOLEAN_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  accountCategory: NULLABLE_NUMBER_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  financialType: STRING_VALIDATION_RULE,
  accountName: noSpecialChars.required('form:validation_messages.required'),
  currency: NULLABLE_NUMBER_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  isClient: NULLABLE_BOOLEAN_VALIDATION_RULE,

  correspondentBankAccount: NULLABLE_STRING_VALIDATION_RULE.when('isClient', {
    is: true,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),

  clientGroup: NULLABLE_NUMBER_VALIDATION_RULE.when('isClient', {
    is: true,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),

  isCorrespondentBank: NULLABLE_BOOLEAN_VALIDATION_RULE.when('isClient', {
    is: false,
    then: (schema) => schema.required('form:validation_messages.required'),
  }),

  correspondentBank: NULLABLE_STRING_VALIDATION_RULE.when(
    ['isClient', 'isCorrespondentBank'],
    (data, schema) => {
      if (!data[0] && data[1]) {
        return schema.required('form:validation_messages.required');
      } else {
        return schema;
      }
    },
  ),

  isFrozen: NULLABLE_BOOLEAN_VALIDATION_RULE.when(
    ['isClient', 'isCorrespondentBank'],
    (data, schema) => {
      if (data[0] || (!data[0] && !data[1])) {
        return schema.required('form:validation_messages.required');
      } else {
        return schema;
      }
    },
  ),

  isActive: NULLABLE_BOOLEAN_VALIDATION_RULE.when(
    ['isClient', 'isCorrespondentBank'],
    (data, schema) => {
      if (data[0] || (!data[0] && !data[1])) {
        return schema.required('form:validation_messages.required');
      } else {
        return schema;
      }
    },
  ),

  shouldAddShortName: yup.boolean(),
  accountShortName: STRING_VALIDATION_RULE.min(10, {
    key: 'form:validation_messages.min_characters',
    values: { number: 10 },
  })
    .max(10, {
      key: 'form:validation_messages.max_characters',
      values: { number: 10 },
    })
    .test(
      'checkDuplicate',
      'Same account number name exists',
      async function (value) {
        if (!value) {
          return true;
        }

        const fullAccountNumber = `${this.parent.accountNumber}${value}`;

        try {
          const response =
            await accountsAPI.checkUniqueStatus(fullAccountNumber);

          return response;
        } catch {
          return false;
        }
      },
    )
    .when('isClient', {
      is: (data: boolean | null) => !data,
      then: (schema) => schema.required('form:validation_messages.required'),
    }),

  accountSupplementary: yup.object().when('isClient', {
    is: true,
    then: () => AccountSupplementaryFormValidationSchema,
  }),
});
