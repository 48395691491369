// helpers
import { SubRouteModel } from '../../../typings/routes';

// components
import Compliance from '../../../pages/Compliance';
import PageLayout from '../../../layouts/PageLayout';
import TransactionsPage from '../../../pages/Compliance/Transactions/TransactionsPage';
import RDCMonitoringPage from 'pages/Compliance/RDCMonitoringPage';
import OngoingReviewsPage from '../../../pages/Compliance/OngoingReviewsPage';
import CountryRiskRatings from '../../../pages/Compliance/CountryRiskRatings';
import ReviewConfiguration from '../../../pages/Compliance/ReviewConfiguration';
import PropertyConfiguration from '../../../pages/Compliance/PropertyConfiguration';
import ExpiringDocumentsPage from 'pages/Compliance/ExpiringDocumentsPage';
import TransactionDetailsPage from '../../../pages/Compliance/Transactions/TransactionDetailsPage';
// import Dashboard from '../../../pages/Compliance/Dashboard';
// import Onboarding from '../../../pages/Compliance/Onboarding';
// import Documentation from '../../../pages/Compliance/Documentation';
// import DynamicReviews from '../../../pages/Compliance/DynamicReviews';

enum ComplianceRoutesEnum {
  Compliance_Root = '/compliance',
  Compliance_Dashboard = '/compliance/dashboard',
  Compliance_Onboarding = '/compliance/onboarding',
  Compliance_Transactions = '/compliance/transactions',
  Compliance_Documentation = '/compliance/documentation',
  Compliance_OngoingReviews = '/compliance/ongoing-reviews',
  Compliance_DynamicReviews = '/compliance/dynamic-reviews',
  Compliance_CountryRiskRatings = '/compliance/country-risk-ratings',
  Compliance_ReviewConfiguration = '/compliance/review-configuration',
  Compliance_Transactions_Details = '/compliance/transactions/transaction',
  Compliance_PropertyConfiguration = '/compliance/property-configuration',
  Compliance_RDCMonitoringDashboard = '/compliance/rdc-monitoring-dashboard',
  Compliance_ExpiringDocuments = '/compliance/expiring-documents',
}

const complianceRoutes: SubRouteModel[] = [
  {
    title: 'Compliance',
    path: ComplianceRoutesEnum.Compliance_Root,
    component: Compliance,
    layout: PageLayout,
    layoutProps: { hideContentCard: true },
    permission: { permissionKey: 'Compliance_View' },
    childRoutes: [
      {
        title: 'Ongoing Reviews',
        path: ComplianceRoutesEnum.Compliance_OngoingReviews,
        layout: PageLayout,
        component: OngoingReviewsPage,
        permission: { permissionKey: 'Compliance_OngoingReviews_View' },
      },

      {
        title: 'Transactions',
        path: ComplianceRoutesEnum.Compliance_Transactions,
        layout: PageLayout,
        component: TransactionsPage,
        permission: { permissionKey: 'Compliance_Transactions_View' },
        childRoutes: [
          {
            title: 'Transaction',
            path: ComplianceRoutesEnum.Compliance_Transactions_Details,
            layout: PageLayout,
            component: TransactionDetailsPage,
            hideFromNavigation: true,
            permission: {
              permissionKey: 'Compliance_Transactions_Details_View',
            },
            layoutProps: {
              goBackPath: ComplianceRoutesEnum.Compliance_Transactions,
            },
          },
        ],
      },

      {
        title: 'Review Configuration',
        path: ComplianceRoutesEnum.Compliance_ReviewConfiguration,
        layout: PageLayout,
        component: ReviewConfiguration,
        permission: { permissionKey: 'Compliance_ReviewConfiguration_View' },
      },

      {
        title: 'Property Configuration',
        path: ComplianceRoutesEnum.Compliance_PropertyConfiguration,
        layout: PageLayout,
        component: PropertyConfiguration,
        permission: { permissionKey: 'Compliance_PropertyConfiguration_View' },
      },

      {
        title: 'Country risk ratings',
        path: ComplianceRoutesEnum.Compliance_CountryRiskRatings,
        layout: PageLayout,
        component: CountryRiskRatings,
        permission: { permissionKey: 'Compliance_CountryRiskRatings_View' },
      },

      {
        title: 'RDC monitoring dashboard',
        path: ComplianceRoutesEnum.Compliance_RDCMonitoringDashboard,
        layout: PageLayout,
        component: RDCMonitoringPage,
        permission: { permissionKey: 'Compliance_RDCMonitoring_View' },
      },

      {
        title: 'Expiring documents',
        path: ComplianceRoutesEnum.Compliance_ExpiringDocuments,
        layout: PageLayout,
        component: ExpiringDocumentsPage,
        permission: { permissionKey: 'Compliance_ExpiringDocuments_View' },
      },

      // {
      //   title: 'Dashboard',
      //   path: ComplianceRoutesEnum.Compliance_Dashboard,
      //   layout: PageLayout,
      //   component: Dashboard,
      // },

      // {
      //   title: 'Onboarding',
      //   path: ComplianceRoutesEnum.Compliance_Onboarding,
      //   layout: PageLayout,
      //   component: Onboarding,
      // },

      // {
      //   title: 'Documentation',
      //   path: ComplianceRoutesEnum.Compliance_Documentation,
      //   layout: PageLayout,
      //   component: Documentation,
      // },

      // {
      //   title: 'Dynamic Reviews',
      //   path: ComplianceRoutesEnum.Compliance_DynamicReviews,
      //   layout: PageLayout,
      //   component: DynamicReviews,
      // },
    ],
  },
];

export { complianceRoutes, ComplianceRoutesEnum };
