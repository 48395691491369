// helpers
import { FormValuesModel } from '../../../components/Forms/FormComponents/ApprovalManagementMatrix';
import {
  OnboardingTemplateBaseModel,
  OnboardingTemplateModel,
} from '../../../typings/approval/templates';

const approvalTemplatesAdapter = {
  editApprovalRuleTemplate: (updatedRules: FormValuesModel) => {
    const result = formatApprovalTemplate(updatedRules);
    return result as OnboardingTemplateModel;
  },

  createApprovalRuleTemplate: (updatedRules: FormValuesModel) => {
    const result = formatApprovalTemplate(updatedRules);
    return result as OnboardingTemplateBaseModel;
  },
};

const formatApprovalTemplate = (
  updatedRules: FormValuesModel,
): OnboardingTemplateModel | OnboardingTemplateBaseModel => {
  const approvalRuleSets = updatedRules.approvalConditions.map((e) => {
    const approvalThreasholds = Object.keys(e.thresholdStatuses).map(
      (key: any) => ({
        threasholdMin: +key,
        threasholdMax: +key,
        ticked: e.thresholdStatuses[key].ticked,
      }),
    );

    const approvalRuleItems = e.approvalGroupConditions.map((condition) => ({
      minimunApprovalThreashold: condition.requiredApprovers as number,
      approvalGroup: { id: condition.approvalGroupId as string },
    }));

    return { approvalThreasholds, approvalRuleItems };
  });

  return {
    id: updatedRules.id || undefined,
    approvalRuleSets,
  };
};

export { approvalTemplatesAdapter };
