import React, { useEffect } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_INTEREST_RATES_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import FeeSchedule from '../../modules/Banking/FeeSchedule';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const FeeSchedulePage = ({ setHeaderOptions }: IProps) => {
  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation
          link={GENESIS_INTEREST_RATES_DOCUMENTATION_LINK}
        />
      ),
    });
  }, []);

  return <FeeSchedule />;
};

export default FeeSchedulePage;
