import React, { memo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';

// components
import Text from '@core_components/Text';
import { Col, Row } from 'antd';
import { SearchInput } from 'components/Fields/SearchInput';

export type FilterKeys = 'search';

interface IProps {
  onFilterChange: (key: FilterKeys, value: string) => void;
}

const FilterBar = memo(({ onFilterChange }: IProps) => {
  const { t } = useTranslation('orac');

  return (
    <Row gutter={[16, 16]}>
      <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
        <Row>
          <Col span={24}>
            <Text>{t('forgot_username_requests.table.search_label')}</Text>
          </Col>
          <Col span={24}>
            <SearchInput
              size="large"
              onSearch={(searchQuery: string) =>
                onFilterChange('search', searchQuery)
              }
              placeholder={t(
                'forgot_username_requests.table.search_placeholder',
              )}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

export default FilterBar;
