import React, { useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import groupsAPI from '../../../../../api/orac/groupsAPI';
import useTranslation from '../../../../../hooks/useTranslation';
import { TableActionModel } from '@core_components/Table';
import { DEFAULT_TABLE_LIMIT } from '../../../../../constants/global';

// components
import AddContext from './AddContext';
import ContextsTable from '../../../../../components/Tables/TableTemplates/ORAC/ContextsTable';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Divider, message, Modal, Row } from 'antd';

interface IProps {
  groupId: string;
  access: { canEditContexts?: boolean };
}

const ContextsTab = ({ groupId, access }: IProps) => {
  const { t } = useTranslation(['orac', 'common']);

  const [page, setPage] = useState(1);
  const [updateContextsTrigger, updateContexts] = useState({});
  const [localUpdateRolesTrigger, updateRoles] = useState({});

  const { response, loading } = useFetch(
    () =>
      groupsAPI.fetchContextsByGroupId(groupId, {
        page,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [page, updateContextsTrigger, localUpdateRolesTrigger],
  );

  const tableActions = useMemo(() => {
    const result: TableActionModel[] = [
      {
        key: 'remove',
        name: t('remove', { ns: 'common' }),
        hidden: !access.canEditContexts,
        danger: true,
      },
    ];

    return result;
  }, [access]);

  const handleActions = (key: string, value: string) => {
    switch (key) {
      case 'remove':
        showRemoveContextConfirmation(value);
        break;
    }
  };

  const showRemoveContextConfirmation = (contextId: string) => {
    Modal.confirm({
      title: t('groups.view.remove_user_confirmation'),
      icon: <ExclamationCircleOutlined />,
      okType: 'danger',
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: () => removeContextFromGroup(contextId),
    });
  };

  const removeContextFromGroup = async (contextId: string) => {
    await groupsAPI.removeContextsFromGroup(groupId, [contextId]);
    message.success(t('groups.view.remove_user_success'));
    updateRoles({});
  };

  return (
    <>
      {access.canEditContexts && (
        <Row justify="end">
          <Col>
            <AddContext groupId={groupId} onAdd={() => updateContexts({})} />
          </Col>
        </Row>
      )}

      <Divider />

      <ContextsTable
        data={response?.data || []}
        total={response?.total || 0}
        current={page}
        loading={loading}
        actions={tableActions}
        onActionsClick={handleActions}
        onPaginationChange={setPage}
      />
    </>
  );
};

export default ContextsTab;
