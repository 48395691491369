import React, { useMemo } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import { IClientGroup } from 'typings/crm/client-group';

// components
import ApprovalRulesTab from './ApprovalRulesTab';
import ApprovalGroupsTab from './ApprovalGroupsTab';
import PendingApprovalsTab from './PendingApprovalsTab';
import Tabs, { TabModel } from 'components/Tabs';

interface IProps {
  clientGroup: IClientGroup;
}

const PostOnboarding = ({ clientGroup }: IProps) => {
  const { t } = useTranslation('crm');

  const [hasApprovalGroupsAccess, hasApprovalRulesAccess] = useUserAccess([
    'CRM_ClientGroup_Details_AdministrationRules_ApprovalGroups_View',
    'CRM_ClientGroup_Details_AdministrationRules_ApprovalRules_View',
  ]);

  const tabs = useMemo<TabModel[]>(() => {
    return [
      {
        hidden: !hasApprovalGroupsAccess,
        key: 'approval-groups',
        title: t('client_group.tabs.approval_groups'),
        content: <ApprovalGroupsTab clientGroupId={clientGroup.numericId} />,
      },

      {
        hidden: !hasApprovalRulesAccess,
        key: 'approval-rules',
        title: t('client_group.tabs.approval_rules'),
        content: <ApprovalRulesTab clientGroupId={clientGroup.numericId} />,
      },

      {
        // TODO: Add the permissions for this tab
        // hidden: !hasApprovalRulesAccess,
        key: 'pending-approvals',
        title: t('client_group.tabs.pending_approvals'),
        content: <PendingApprovalsTab clientGroup={clientGroup} />,
      },
    ];
  }, [t, clientGroup, hasApprovalGroupsAccess, hasApprovalRulesAccess]);

  return <Tabs tabs={tabs} renderOnChange />;
};

export default PostOnboarding;
