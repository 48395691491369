export enum CountryRiskRating {
  Low = 1,
  Medium,
  High,
  Prohibited,
}

export interface CountryRiskRatingModel {
  id: string;
  name: string;
  alpha2Code: string;
  riskRating: CountryRiskRating;
}
