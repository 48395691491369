import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { UserFullModel } from '../../../../../../../../typings/ORAC/users';
import { AccountRecoveryRequestTypes } from '../../../../../../../../enums/admin/orac';

// components
import { Select } from 'antd';

interface IProps {
  user: UserFullModel;
  disabled: boolean;
  loading: boolean;
  value: AccountRecoveryRequestTypes | undefined;
  allowClear?: boolean;
  onSelect: (value: AccountRecoveryRequestTypes) => void | Promise<void>;
}

const AuthenticatorTypeSelect = ({
  user,
  disabled,
  allowClear,
  loading,
  value,
  onSelect,
}: IProps) => {
  const { t } = useTranslation('orac');

  const options = useMemo(() => {
    const result = [];

    if (user.isTwoFactorAuthenticationEnabled) {
      result.push({
        key: AccountRecoveryRequestTypes.Authenticator,
        name: t('users.view.two_step_verification.authenticator.title'),
      });
    }

    if (user.isTwoFactorFidoEnabled) {
      result.push({
        key: AccountRecoveryRequestTypes.Fido,
        name: t('users.view.two_step_verification.security_key.title'),
      });
    }

    return result;
  }, [user]);

  return (
    <StyledSelect
      size="large"
      value={value}
      allowClear={allowClear}
      onSelect={(value) => onSelect(value as AccountRecoveryRequestTypes)}
      loading={loading}
      disabled={disabled}
    >
      {options.map(({ key, name }) => (
        <Select.Option key={key} value={key}>
          {name}
        </Select.Option>
      ))}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.marginSm};
`;
export default AuthenticatorTypeSelect;
