import React, { memo, useCallback, useMemo, useState } from 'react';

// helpers
import { useFormikContext } from 'formik';
import { DISABLED_FORM_STATUS } from '../../../../../constants/form';

// components
import UpdatedJournalEntriesTable, {
  ActionKeys,
  JournalEntry,
} from '../../../../Tables/TableTemplates/Finance/UpdatedJournalEntriesTable';

export type FormValuesModel = {
  isDebit: boolean;
  entriesHash: Record<number, JournalEntry>;
};

const UpdateReconciliationStatusesProgressForm = memo(() => {
  const [page, setPage] = useState(1);
  const { values, setFieldValue, status } = useFormikContext<FormValuesModel>();

  const entriesArray = useMemo<JournalEntry[]>(() => {
    const { entriesHash } = values;
    return Object.keys(entriesHash).map((key) => entriesHash[key as any]);
  }, [values.entriesHash]);

  const handleActionsClick = useCallback(
    (key: ActionKeys, record: JournalEntry) => {
      switch (key) {
        case 'delete':
          {
            const entriesHashCopy = { ...values.entriesHash };
            delete entriesHashCopy[record.id];
            setFieldValue('entriesHash', entriesHashCopy);
          }
          break;
      }
    },
    [values.entriesHash],
  );

  return (
    <UpdatedJournalEntriesTable
      data={entriesArray}
      current={page}
      onPaginationChange={setPage}
      total={entriesArray.length}
      isDebit={values.isDebit}
      onActionsClick={
        status === DISABLED_FORM_STATUS ? undefined : handleActionsClick
      }
    />
  );
});

export default UpdateReconciliationStatusesProgressForm;
