import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import { DataNode } from 'antd/lib/tree';

// components
import { Tree, Typography } from 'antd';

interface IProps {
  json: string;
}

type TypeofTypes =
  | 'string'
  | 'number'
  | 'bigint'
  | 'boolean'
  | 'symbol'
  | 'undefined'
  | 'object'
  | 'function';

const TreeTitle = ({
  title,
  value,
}: {
  title: string;
  value?: string | number;
}) => {
  return (
    <>
      <TreeTitleLabel>{`${title}:`}</TreeTitleLabel>
      <TreeTitleValue>{value}</TreeTitleValue>
    </>
  );
};

const JSONInTree = memo(({ json }: IProps) => {
  const { t } = useTranslation('compliance');

  const getTitle = (
    type: TypeofTypes,
    key: string,
    value: any,
  ): React.ReactNode => {
    let result = <TreeTitle title={key} />;

    switch (type) {
      case 'string':
      case 'number':
        result = <TreeTitle title={key} value={value as number} />;
        break;

      case 'object':
        {
          if (!value) {
            result = <TreeTitle title={key} value={JSON.stringify(value)} />;
          } else if (Array.isArray(value)) {
            result = (
              <TreeTitle
                title={key}
                value={`${t('rdc_review.array')} [${value.length}]`}
              />
            );
          } else {
            result = (
              <TreeTitle
                title={key}
                value={`${t('rdc_review.object')} {${Object.keys(value).length}}`}
              />
            );
          }
        }
        break;
    }

    return result;
  };

  const formatTreeItem = (
    key: string,
    value: any,
    uniqueIndex: string,
  ): DataNode => {
    const valueType = typeof value;
    const formattedKey = `${key}-${valueType}-${uniqueIndex}`;
    const title = getTitle(valueType, key, value);

    switch (valueType) {
      case 'string':
      case 'number':
        return { title, key: formattedKey, children: [] };

      case 'object': {
        if (!value) {
          return { title, key: formattedKey, children: [] };
        } else if (Array.isArray(value)) {
          const children: DataNode[] = value.map((e, i) =>
            formatTreeItem(String(i), e, `${uniqueIndex}-${i}`),
          );
          return { title, key: formattedKey, children };
        } else {
          const children: DataNode[] = Object.keys(value).map((key, i) =>
            formatTreeItem(key, value[key], `${uniqueIndex}-${i}`),
          );
          return { title, key: formattedKey, children };
        }
      }
      default:
        return { title, key: formattedKey, children: [] };
    }
  };

  const treeData = useMemo<DataNode[]>(() => {
    if (!json) {
      return [];
    }

    const formattedJSON = JSON.parse(json);
    const result = Object.keys(formattedJSON).map((key, i) =>
      formatTreeItem(key, formattedJSON[key], String(i)),
    );
    return result;
  }, [json]);

  return <Tree treeData={treeData} />;
});

const TreeTitleLabel = styled(Typography.Text)`
  margin-right: 10px;
`;

const TreeTitleValue = styled(Typography.Text)`
  color: ${({ theme }) => theme.whiteColor};
  font-weight: 600;
`;

export default JSONInTree;
