import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { MessageRecipient } from '../../../../../../apiAdapters/messaging/messagingAdapter';
import { IDocumentAssociationInfo } from '../../../../../../modules/CRM/Documents/UploadDocumentsDialog';

// components
import Checkbox from '@core_components/Checkbox';
import FormField from '@core_components/FormField';
import MessageCategorySelect from '../../../../../Forms/FormComponents/SelectInputs/MessageCategorySelect';
import RecipientAutocomplete from '../../../../../Forms/FormComponents/Autocompletes/CRM/RecipientAutocomplete';
import AttachmentsForm, { IMessageAttachmentsForm } from './AttachmentsForm';
import { Input } from 'antd';

export interface ISendMessageForm extends IMessageAttachmentsForm {
  categoryId?: number;
  subject?: string;
  message?: string;
  recipients: MessageRecipient[];
  isHighImportant: boolean;
}

interface IProps {
  categoryId?: number;
  clientGroupId: string;
  subject?: string;
  association?: IDocumentAssociationInfo;
}

const SendMessageForm = ({
  categoryId,
  clientGroupId,
  subject,
  association,
}: IProps) => {
  const { t } = useTranslation(['crm', 'form']);

  return (
    <>
      <FormField
        name="categoryId"
        label={t('messaging.new.category')}
        placeholder={t('selectPlaceholder', {
          ns: 'form',
          fieldName: t('messaging.new.category').toLowerCase(),
        })}
        disabled={!!categoryId}
        component={MessageCategorySelect}
        additionalProps={{ allowClear: true }}
      />
      <FormField
        name="subject"
        label={t('messaging.new.subject')}
        placeholder={t('enterPlaceholder', {
          ns: 'form',
          fieldName: t('messaging.new.subject').toLowerCase(),
        })}
        disabled={!!subject}
        component={Input}
      />
      <FormField
        name="recipients"
        label={t('messaging.new.recipients')}
        placeholder={t('enterPlaceholder', {
          ns: 'form',
          fieldName: t('messaging.new.recipients').toLowerCase(),
        })}
        component={RecipientAutocomplete}
        additionalProps={{
          allowClear: true,
          clientGroupId,
          mode: 'multiple',
        }}
      />
      <FormField
        name="isHighImportant"
        label={t('messaging.new.action_required')}
        component={Checkbox}
      />
      <FormField
        name="message"
        label={t('messaging.new.message')}
        placeholder={t('messaging.new.message_placeholder')}
        component={Input.TextArea}
        additionalProps={{ autoSize: { minRows: 8, maxRows: 8 } }}
      />
      {!!association && <AttachmentsForm association={association} />}
    </>
  );
};

export default SendMessageForm;
