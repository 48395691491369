import { useEffect, useState } from 'react';

const useDataRefresh = (refreshInterval = 120000): number => {
  const [trigger, setTrigger] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTrigger((prevTrigger) => prevTrigger + 1);
    }, refreshInterval);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [refreshInterval]);

  return trigger;
};

export default useDataRefresh;
