import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { OngoingReviewModel } from '../../typings/compliance/ongoingReviews';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

const ongoingReviewsAPI = {
  fetchOngoingReviews: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<OngoingReviewModel>>(
      `${APIConfig.amlApi}/ongoing-reviews`,
      { params },
    ).then(({ data }) => data);
  },
};

export default ongoingReviewsAPI;
