import React, { useEffect, useState } from 'react';

// helpers
import useUserAccess from '../../hooks/useUserAccess';
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_FISCAL_PERIOD_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import GenerateButton from '../../modules/Finance/FiscalPeriod/GenerateButton';
import FiscalPeriodTable from '../../modules/Finance/FiscalPeriod';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const FiscalPeriod = ({ setHeaderOptions }: IProps) => {
  const [hasCreateAccess] = useUserAccess(['Finance_FiscalPeriod_Create']);
  const [updateTrigger, updateTable] = useState({});

  useEffect(() => {
    if (hasCreateAccess) {
      setHeaderOptions({
        appendToTitle: (
          <TooltipToDocumentation
            link={GENESIS_FISCAL_PERIOD_DOCUMENTATION_LINK}
          />
        ),
        extra: () => <GenerateButton onGenerate={() => updateTable({})} />,
      });
    }
  }, [updateTrigger, hasCreateAccess]);

  return <FiscalPeriodTable updateTrigger={updateTrigger} />;
};

export default FiscalPeriod;
