import React, { useMemo } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { onboardingAPI } from 'api/crm/onboarding/onboardingAPI';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';

// components
import ApprovalGroupsModal, {
  ApprovalGroupFormValuesModel,
} from 'components/Modals/TemplateModalDialogs/ApprovalManagement/ApprovalGroupsModal';

interface IProps extends RequiredPropsForModalDialogModel {
  approvalGroupId: string | null;
  clientGroupId: number;
}

const ApprovalGroupDetailsModal = ({
  isVisible,
  closeCallback,
  clientGroupId,
  approvalGroupId,
}: IProps) => {
  const { t } = useTranslation('crm');

  const { response } = useFetch(
    () =>
      approvalGroupId
        ? onboardingAPI.fetchApprovalGroupById(approvalGroupId, clientGroupId)
        : null,
    [approvalGroupId, clientGroupId],
  );

  const initialFormValues = useMemo<ApprovalGroupFormValuesModel | null>(() => {
    let result = null;

    if (response) {
      const initialParticipantOptions = response.participants.map((e) => ({
        id: e.contactId,
        content: e.name,
      }));

      result = {
        name: response.name,
        description: response.description,
        participants: response.participants.map((e) => ({
          userId: e.contactId,
          userFullName: e.name,
        })),
        initialParticipantOptions,
      };
    }

    return result;
  }, [response]);

  return (
    <ApprovalGroupsModal
      title={t(
        'client_group.approval.client_approval_groups.view_approval_group_modal.title',
      )}
      viewOnly
      onSubmit={console.log}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      loadingInitialData={!initialFormValues}
    />
  );
};

export default ApprovalGroupDetailsModal;
