import { useEffect } from 'react';
import { useFormikContext } from 'formik';

const ExitConfirmation = () => {
  const { dirty, isValid } = useFormikContext();

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      return (event.returnValue = false);
    };

    if (dirty || !isValid) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [dirty, isValid]);

  return null;
};

export default ExitConfirmation;
