import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';

// components
import CorrespondentBankAccountsByBankAutocomplete from '../../../../../components/Forms/FormComponents/Autocompletes/Finance/CorrespondentBankAccountsByBankAutocomplete';
import { Col, Row, Typography } from 'antd';

export type FilterBarKeys = 'correspondent_account';

interface IProps {
  bankId: string;
  onFilterChange: (key: FilterBarKeys, value: string) => void;
}

const FilterBar = ({ bankId, onFilterChange }: IProps) => {
  const { t } = useTranslation('banking');

  return (
    <Row gutter={[16, 16]}>
      <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t(
                'correspondent_banks.details.client_accounts_tab.filter_by_correspondent_account',
              )}
            </Typography.Text>
          </Col>

          <Col span={24}>
            <StyledCorrespondentBankAccountAutocomplete
              bankId={bankId}
              onChange={(accountNumber: string) =>
                onFilterChange('correspondent_account', accountNumber)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledCorrespondentBankAccountAutocomplete = styled(
  CorrespondentBankAccountsByBankAutocomplete,
)`
  width: 100%;
`;

export default FilterBar;
