import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';

// components
import { Select } from 'antd';

interface IProps {
  value: any;
  numOfParticipantsInApprovalGroup: number;
}

const RequiredApprovalsCountSelect = ({
  value,
  numOfParticipantsInApprovalGroup,
  ...rest
}: IProps) => {
  const { t } = useTranslation('approval_management');

  const options = useMemo(() => {
    let result: { key: number; name: number }[] = [];

    if (numOfParticipantsInApprovalGroup > 0) {
      result = Array.from(Array(numOfParticipantsInApprovalGroup), (_, i) => ({
        key: i + 1,
        name: i + 1,
      }));
    }

    return result;
  }, [numOfParticipantsInApprovalGroup]);

  const renderOptions = () => {
    return options.map(({ key, name }) => (
      <Select.Option key={key} value={key}>
        {name}
      </Select.Option>
    ));
  };

  return (
    <Select
      {...rest}
      showSearch
      value={value || undefined}
      placeholder={t(
        'approval_management.approval_groups.select_required_approvers',
      )}
    >
      {renderOptions()}
    </Select>
  );
};

export default RequiredApprovalsCountSelect;
