import React, { useState } from 'react';

// helpers
import rolesAPI from '../../../../../api/orac/rolesAPI';
import useFetch from '../../../../../hooks/useFetch';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../../constants/global';

// components
import ObjectsTable from '../../../../../components/Tables/TableTemplates/ORAC/ObjectsTable';
import EditPermissions from './EditPermissions';
import { Col, Divider, Row } from 'antd';

interface IProps {
  roleId: string;
  access?: { canEdit?: boolean };
}

const PermissionsTab = ({ roleId, access }: IProps) => {
  const [page, setPage] = useState(1);
  const [updatePermissionsTrigger, updatePermissions] = useState({});

  const { response, loading } = useFetch(
    () =>
      rolesAPI.fetchPermissionsByRoleId(roleId, {
        page,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [page, roleId, updatePermissionsTrigger],
  );

  return (
    <>
      {access?.canEdit && (
        <Row justify="end">
          <Col>
            <EditPermissions
              roleId={roleId}
              onUpdate={() => updatePermissions({})}
            />
          </Col>
        </Row>
      )}

      <Divider />

      <ObjectsTable
        isExpandable
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={page}
        onPaginationChange={setPage}
      />
    </>
  );
};

export default PermissionsTab;
