import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ClientGroupUserStatuses } from 'enums/crm/crm';

// components
import StatusLabel from 'components/Typography/StatusLabel';

const STATUS_MAP = {
  info: [],
  warning: [
    ClientGroupUserStatuses.Onboarding,
    ClientGroupUserStatuses.ReadyForReview,
    ClientGroupUserStatuses.AccountManagementReview,
    ClientGroupUserStatuses.InReview,
    ClientGroupUserStatuses.Invited,
  ],
  success: [ClientGroupUserStatuses.Approved, ClientGroupUserStatuses.Enabled],
  error: [
    ClientGroupUserStatuses.Rejected,
    ClientGroupUserStatuses.Deactivated,
    ClientGroupUserStatuses.InvitationExpired,
  ],
};

interface IProps {
  status: ClientGroupUserStatuses;
}

const CRMUserStatus = ({ status }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(`client_group.user_statuses.${status}`, status)}
    />
  );
};

export default CRMUserStatus;
