import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../hooks/useTranslation';
import { signOut } from '../../redux/actions/auth';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../routes/routes';
import { useDispatch } from 'react-redux';

// components
import EnableSecurityKey from '../../modules/Profile/Security/TwoFactorVerification/SecurityKeyOption/EnableSecurityKey';
import { Card, Typography } from 'antd';
import { ReactComponent as PrimaryLogo } from '../../resources/app-logos/primary-logo.svg';

const AuthorizedTwoFactorAuthenication = () => {
  const { t } = useTranslation(['profile', 'common']);
  const dispatch = useDispatch();
  const history = useHistory();

  const onSignOut = () => {
    dispatch(signOut());
    history.push(RoutePaths.Auth_SignIn);
  };

  return (
    <StyledCard>
      <ContentWrapper>
        <StyledLogo />
        <Typography.Title level={4}>
          {t(
            'sign_in_and_security.two_factor_verification.security_key_option.title',
          )}
        </Typography.Title>
        <Typography.Text>
          {t(
            'sign_in_and_security.two_factor_verification.security_key_option.description',
          )}
        </Typography.Text>

        <EnableSecurityKey />

        <LinkWrapper>
          <Typography.Link onClick={onSignOut}>
            {t('signout', { ns: 'common' })}
          </Typography.Link>
        </LinkWrapper>
      </ContentWrapper>
    </StyledCard>
  );
};

const LinkWrapper = styled.div`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const StyledCard = styled(Card)`
  width: 500px;
  height: auto;
  text-align: center;

  display: flex;
  justify-content: center;

  @media screen and (max-width: 576px) {
    width: 100%;
    height: 100%;
    margin-top: 0px;
    padding: 30px;
    padding-top: 100px !important;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-card-body {
      width: 100%;
      padding: 10px;
    }
  }
`;

const ContentWrapper = styled.div`
  width: 400px;
  height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const StyledLogo = styled(PrimaryLogo)`
  width: ${({ theme }) => theme.headerLogoWidth};
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default AuthorizedTwoFactorAuthenication;
