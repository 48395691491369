import React, { useMemo } from 'react';

// helpers
import { IDocumentAssociationInfo } from '../../../../../modules/CRM/Documents/UploadDocumentsDialog';
import { SendMessageValidationSchema } from '../../../../../validations/messages';

// components
import Form from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import SendMessageForm, { ISendMessageForm } from './InnerForm';

interface IProps extends RequiredPropsForModalDialogModel {
  title: string;
  sendButtonTitle: string;
  categoryId?: number;
  clientGroupId: string;
  subject?: string;
  association?: IDocumentAssociationInfo;
  onSubmit: (values: ISendMessageForm) => void;
}

const SendMessageModal = ({
  title,
  sendButtonTitle,
  isVisible,
  categoryId,
  clientGroupId,
  subject,
  association,
  closeCallback,
  onSubmit,
}: IProps) => {
  const initialValues = useMemo(() => {
    return {
      categoryId: categoryId as number,
      subject: subject as string,
      message: '',
      attachments: [],
      recipients: [],
      isHighImportant: false,
    };
  }, [categoryId, subject]);

  return (
    <Form<ISendMessageForm>
      enableReinitialize
      initialValues={isVisible ? initialValues : null}
      validationSchema={SendMessageValidationSchema}
      onSubmit={onSubmit}
      renderForm={
        <ModalDialog
          width="700px"
          isVisible={isVisible}
          closeCallback={closeCallback}
          title={title}
          submitButtonProps={{ text: sendButtonTitle }}
        >
          <SendMessageForm
            categoryId={categoryId}
            subject={subject}
            clientGroupId={clientGroupId}
            association={association}
          />
        </ModalDialog>
      }
    />
  );
};

export default SendMessageModal;
