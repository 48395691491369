import React from 'react';

// helpers
import styled from 'styled-components';
import { darkTheme } from '@resources/theme/styled';
import { TooltipPlacement } from 'antd/lib/tooltip';

// components
import IconSVG from '@core_components/IconSVG';
import { Popover } from 'antd';
import { ReactComponent as InfoIcon } from '../../../../../resources/icons/remix-icons/information-line.svg';

interface IProps {
  placement?: TooltipPlacement;
  popoverContent: string | React.ReactNode;
}

const InfoIconWithPopover = ({
  popoverContent,
  placement = 'bottom',
}: IProps) => {
  return (
    <Popover
      placement={placement}
      content={popoverContent}
      overlayStyle={{ zIndex: 99999 }}
    >
      <StyledIconSVG color={darkTheme.warningColor} component={InfoIcon} />
    </Popover>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXXs};
  cursor: pointer;
`;

export default InfoIconWithPopover;
