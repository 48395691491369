import React, { memo, useMemo } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { OnboardType } from 'enums/crm/crm';

// components
import Link from '@common_components/Texts/Link';
import StatusLabel from 'components/Typography/StatusLabel';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

export type RDCMonitoringItemStatus = 'completed' | 'not_completed';

export type RDCMonitoringItem = {
  id: string;
  date: string;
  status: RDCMonitoringItemStatus;
  node: {
    id: string;
    name: string;
    type: OnboardType;
  };
};

type RDCMonitoringTableProps = RequiredPropsForTableModel<RDCMonitoringItem>;

const STATUS_MAP = {
  info: [],
  warning: ['not_completed'],
  success: ['completed'],
  error: [],
};

const RDCMonitoringTable = memo((props: RDCMonitoringTableProps) => {
  const history = useHistory();
  const { t } = useTranslation(['compliance', 'crm']);
  const [hasContactDetailsAccess, hasEntityDetailsAccess] = useUserAccess([
    'CRM_Contacts_Details_View',
    'CRM_Entities_Details_View',
  ]);

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: 200,
        key: 'date',
        title: t('rdc_monitoring.table.date'),
        render: (record: RDCMonitoringItem) => record.date,
      },

      {
        width: 300,
        key: 'name',
        title: t('rdc_monitoring.table.name'),
        render: (record: RDCMonitoringItem) => {
          let node: {
            id: string;
            name: string;
            hasAccess: boolean;
            link: string;
          } | null = null;

          if (record.node.type === OnboardType.Contact) {
            node = {
              id: record.node.id,
              name: record.node.name,
              hasAccess: hasContactDetailsAccess,
              link: RoutePaths.CRM_Contacts_Edit,
            };
          } else if (record.node.type === OnboardType.Organization) {
            node = {
              id: record.node.id,
              name: record.node.name,
              hasAccess: hasEntityDetailsAccess,
              link: RoutePaths.CRM_Entities_Edit,
            };
          }

          if (node) {
            if (node.hasAccess) {
              return (
                <Link
                  onClick={() =>
                    history.push({
                      pathname: node?.link,
                      search: `?id=${node?.id}`,
                    })
                  }
                >
                  <EllipsisTooltip
                    maxTextContainerWidth="250"
                    title={node.name}
                  >
                    {node.name}
                  </EllipsisTooltip>
                </Link>
              );
            } else {
              return (
                <EllipsisTooltip maxTextContainerWidth="250" title={node.name}>
                  {node.name}
                </EllipsisTooltip>
              );
            }
          } else {
            return null;
          }
        },
      },

      {
        width: 250,
        key: 'type',
        title: t('rdc_monitoring.table.type'),
        render: (record: RDCMonitoringItem) =>
          t(`association_types.${record.node.type}`, { ns: 'crm' }),
      },

      {
        width: 250,
        key: 'status',
        title: t('rdc_monitoring.table.status'),
        render: (record: RDCMonitoringItem) => (
          <StatusLabel
            statusMap={STATUS_MAP}
            status={record.status}
            text={t(`rdc_monitoring.item_statuses.${record.status}`)}
          />
        ),
      },
    ];

    return result;
  }, [t, hasContactDetailsAccess, hasEntityDetailsAccess, history]);

  return <Table {...props} columns={columns} />;
});

export default RDCMonitoringTable;
