import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import rolesAPI from '../../../../../../api/orac/rolesAPI';
import { RoleWithPermissionsModel } from '../../../../../../typings/ORAC/roles';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import Autocomplete, {
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';

interface IProps {
  value: string;
  placeholder: string;
}

const RolesAutocomplete = (props: IProps) => {
  const { t } = useTranslation('orac');

  const mapOption = (role: RoleWithPermissionsModel) => {
    return {
      id: role.id,
      content: role.name,
      model: role,
    };
  };

  const fetchRoles = async (searchQuery: string) => {
    const { data } = await rolesAPI.fetchRoles({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      name: searchQuery,
    });

    return data.map<AutocompleteOption>(mapOption);
  };

  return (
    <Autocomplete
      {...props}
      placeholder={t('groups.view.role_placeholder')}
      fetchData={fetchRoles}
    />
  );
};

export default RolesAutocomplete;
