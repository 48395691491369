import React, { memo, useMemo } from 'react';

//  helpers
import styled from 'styled-components';
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';
import { ThreadWithNewMessagesFromQuery } from 'api/messaging/messagingAPI';

// components
import Link from '@common_components/Texts/Link';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from '../../../../Tooltips/EllipsisTooltip';
import NewMessageMarker from 'components/Additional/Messaging/NewMessageMarker';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

export type ActionKeys = 'client_group_view';

interface IProps
  extends RequiredPropsForTableModel<ThreadWithNewMessagesFromQuery> {
  onActionsClick?: (
    key: ActionKeys,
    record: ThreadWithNewMessagesFromQuery,
  ) => void;
}

const NewMessagesTable = memo(({ onActionsClick, ...rest }: IProps) => {
  const { t } = useTranslation('crm');
  const [hasClientGroupMessagesAccess] = useUserAccess([
    'CRM_ClientGroup_Details_Messages_View',
  ]);

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: 210,
        key: 'date',
        title: t('messages.new_threads_dashboard.table.date'),
        render: (record: ThreadWithNewMessagesFromQuery) => (
          <>
            <DivAlignCenter>
              <StyledNewMessageMarker />
              {DateHelpers.formatDateToString(
                record.message.createdAt,
                DEFAULT_DATE_TIME_FORMAT,
              )}
            </DivAlignCenter>
          </>
        ),
      },

      {
        width: 200,
        key: 'client_group',
        title: t('messages.new_threads_dashboard.table.client_group'),
        render: (record: ThreadWithNewMessagesFromQuery) => (
          <EllipsisTooltip
            title={record.clientGroupName}
            overlayStyle={{ maxWidth: '200px' }}
            maxTextContainerWidth="200px"
          >
            {hasClientGroupMessagesAccess && onActionsClick ? (
              <Link onClick={() => onActionsClick('client_group_view', record)}>
                {record.clientGroupName}
              </Link>
            ) : (
              record.clientGroupName
            )}
          </EllipsisTooltip>
        ),
      },

      {
        width: 200,
        title: t('messages.new_threads_dashboard.table.user'),
        key: 'user',
        render: (record: ThreadWithNewMessagesFromQuery) => (
          <EllipsisTooltip
            title={record.message.from.name}
            overlayStyle={{ maxWidth: '200px' }}
            maxTextContainerWidth="200px"
          >
            {record.message.from.name}
          </EllipsisTooltip>
        ),
      },
      {
        width: 230,
        title: t('messages.new_threads_dashboard.table.subject'),
        key: 'subject',
        render: (record: ThreadWithNewMessagesFromQuery) => (
          <EllipsisTooltip
            title={record.subject}
            overlayStyle={{ maxWidth: '230px' }}
            maxTextContainerWidth="230px"
          >
            {record.subject}
          </EllipsisTooltip>
        ),
      },
    ];

    return result;
  }, [hasClientGroupMessagesAccess, onActionsClick]);

  return <Table {...rest} columns={columns} />;
});

const StyledNewMessageMarker = styled(NewMessageMarker)`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default NewMessagesTable;
