import React, { useState } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import { ActionKeys } from 'components/Tables/TableTemplates/ApprovalManagement/ClientTransactionRulesTable';
import { TransactionRuleFromQuery } from 'api/approval/approvalManagement/clientTransactionRulesAPI';

// components
import PageButton from '@common_components/Buttons/PageButton';
import TransactionRulesTable from './TransactionRulesTable';
import ViewTransactionRuleModal from './ViewTransactionRuleModal';
import { Col, Row } from 'antd';

interface IProps {
  clientGroupId: number;
}

const ApprovalRulesTab = ({ clientGroupId }: IProps) => {
  const { t } = useTranslation('crm');

  const [hasAccessToCreateApprovalRule] = useUserAccess([
    'CRM_ClientGroup_Details_TransactionRules_ApprovalRules_Create',
  ]);

  const [updateTableTrigger, updateTable] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTransactionRuleId, setSelectedTransactionRuleId] = useState<
    string | null
  >(null);

  const handleAddRuleClick = () => {
    setIsModalVisible(true);
  };

  const handleActionsClick = (
    action: ActionKeys,
    record: TransactionRuleFromQuery,
  ) => {
    switch (action) {
      case 'update': {
        setSelectedTransactionRuleId(record.id);
      }
    }
  };

  const handleModalClose = (shouldUpdateTable?: boolean) => {
    shouldUpdateTable && updateTable({});
    setIsModalVisible(false);
    setSelectedTransactionRuleId(null);
  };

  return (
    <>
      {hasAccessToCreateApprovalRule && (
        <Row justify="end">
          <Col flex="150px">
            <PageButton fullWidth onClick={handleAddRuleClick}>
              {t(
                'client_group.approval.client_transaction_rules.add_rule_button',
              )}
            </PageButton>
          </Col>
        </Row>
      )}

      <TransactionRulesTable
        clientGroupId={clientGroupId}
        onRuleSelect={handleActionsClick}
        updateTableTrigger={updateTableTrigger}
      />
      <ViewTransactionRuleModal
        clientGroupId={clientGroupId}
        isVisible={isModalVisible || !!selectedTransactionRuleId}
        transactionRuleId={selectedTransactionRuleId}
        closeCallback={handleModalClose}
      />
    </>
  );
};

export default ApprovalRulesTab;
