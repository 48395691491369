import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { ApprovalEntryModel } from 'api/approval/approvalManagement/clientAccountManagementRulesAPI';
import { DEFAULT_FULL_DATE_FORMAT } from 'constants/global';
import { ClientApprovalWorkflowChangeTypes } from 'typings/approval/approvalWorkflow';

// components
import Link from '@common_components/Texts/Link';
import ApprovalEntryStatus from 'components/Additional/Statuses/ApprovalEntryStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

interface IProps extends RequiredPropsForTableModel<ApprovalEntryModel> {
  onApprovalSelect: (approvalId: string) => void;
}

const ApprovalsTable = ({ onApprovalSelect, ...rest }: IProps) => {
  const { t } = useTranslation('crm');

  const tableColumns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t(
          'client_group.administration_rules.pending_approvals.table.date',
        ),
        key: 'date',
        render: (record: ApprovalEntryModel) =>
          DateHelpers.formatDateToString(
            record.createdAt,
            DEFAULT_FULL_DATE_FORMAT,
          ),
      },
      {
        title: t(
          'client_group.administration_rules.pending_approvals.table.entry',
        ),
        key: 'changed-entry',
        render: (record: ApprovalEntryModel) => (
          <Link onClick={() => onApprovalSelect(record.id)}>
            {t(
              `client_group.administration_rules.pending_approvals.approval_entry_types.${record.entryType}`,
            )}
          </Link>
        ),
      },
      {
        title: t(
          'client_group.administration_rules.pending_approvals.table.type',
        ),
        key: 'changed-type',
        render: (record: ApprovalEntryModel) =>
          t(
            `client_group.administration_rules.pending_approvals.change_types.${ClientApprovalWorkflowChangeTypes[record.value]}`,
          ),
      },
      {
        title: t(
          'client_group.administration_rules.pending_approvals.table.status',
        ),
        key: 'status',
        render: (record: ApprovalEntryModel) => (
          <ApprovalEntryStatus statusId={record.status} />
        ),
      },
    ];

    return result;
  }, [t]);

  return <Table {...rest} columns={tableColumns} />;
};

export default ApprovalsTable;
