import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';

// components
import VerifyIdentityCode from './VerifyIdentityCode';

import { CardWrapper } from '../../../../../../layouts/PageLayout';
import { Col, Row, Typography } from 'antd';

interface IProps {
  userId: string;
}

const IdentityVerification = ({ userId }: IProps) => {
  const { t } = useTranslation('orac');

  return (
    <CardWrapper>
      <Row gutter={[16, 16]}>
        <Col span={18}>
          <StyledTitle level={4}>
            {t('users.view.identity_verification')}
          </StyledTitle>
          <Typography.Text>
            {t('users.view.identity_verification_description')}
          </Typography.Text>
        </Col>

        <Col span={6}>
          <VerifyIdentityCode userId={userId} />
        </Col>
      </Row>
    </CardWrapper>
  );
};

const StyledTitle = styled(Typography.Title)`
  margin-bottom: 0px !important;
`;

export default IdentityVerification;
