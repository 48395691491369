import React, { memo, useCallback, useState } from 'react';

// helpers
import moment from 'moment';
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { Moment } from 'moment';
import { reportingAPI } from 'api/reporting/reportingAPI';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';

// components
import FilterBar from './FilterBar';
import SectionIntro from '@common_components/Texts/SectionIntro';
import IncomingTransfersTable from 'components/Tables/TableTemplates/Reporting/IncomingTransfersTable';
import ExportIncomingTransfersToCSVButton from 'components/Additional/ExportIncomingTransfersToCSVButton';
import { Divider } from 'antd';

const IncomingTransfersReport = memo(() => {
  const { t } = useTranslation('reporting');
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState<[Moment, Moment] | null>([
    moment().add(-1, 'M').startOf('day'),
    moment().endOf('day'),
  ]);

  const { response, loading } = useFetch(
    () =>
      dateRange
        ? reportingAPI.fetchIncomingTransfersReport({
            page,
            limit: DEFAULT_TABLE_LIMIT,
            fromDate: dateRange[0].format('X'),
            toDate: dateRange[1].format('X'),
          })
        : null,
    [page, dateRange],
  );

  // handle filter bar change
  const handleFilterChange = useCallback((key: string, value: any) => {
    if (page > 1) {
      setPage(1);
    }

    if (dateRange) {
      switch (key) {
        case 'startDate':
          setDateRange([moment(value).startOf('day'), dateRange[1]]);
          break;
        case 'endDate':
          setDateRange([dateRange[0], moment(value).endOf('day')]);
          break;
      }
    }
  }, []);

  return (
    <>
      <SectionIntro
        titleVariant="h4"
        title={t('threshold_reporting.incoming_transfers.title')}
        appendToTitle={
          <ExportIncomingTransfersToCSVButton
            disabled={!response?.data?.length}
            selectedFilterDate={dateRange}
          />
        }
      />
      <FilterBar
        filterChange={handleFilterChange}
        dateRangeValue={dateRange}
        isDisabled={loading}
      />
      <Divider />
      <IncomingTransfersTable
        current={page}
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        onPaginationChange={setPage}
      />
    </>
  );
});

export default IncomingTransfersReport;
