import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { ObjectAttributeModel } from '../../../../../typings/ORAC/objects';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel<ObjectAttributeModel>;

const ObjectAttributesTable = memo((props: IProps) => {
  const { t } = useTranslation('orac');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: '50%',
        title: t('objects.view.attribute_name'),
        key: 'name',
        render: (record: ObjectAttributeModel) => record.name,
      },

      {
        width: '50%',
        title: t('objects.view.attribute_type'),
        key: 'type',
        render: (record: ObjectAttributeModel) => record.type,
      },
    ];

    return result;
  }, []);

  return <Table {...props} columns={columns} />;
});

export default ObjectAttributesTable;
