import React from 'react';

// helpers
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import RelationshipForm from '../../../../../Forms/TemplateForms/CRM/RelationshipForm';

const InnerForm = () => {
  const { values } = useFormikContext<FormValuesModel>();
  return (
    <RelationshipForm
      entryId={values.entryId}
      fieldName="relationship"
      entryType={values.entryType}
    />
  );
};

export default InnerForm;
