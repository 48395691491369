import { PermissionedAccessModel } from '.';

// Permission keys
export type ApprovalManagementPermissionKeys =
  | 'ApprovalManagement_View'
  | 'ApprovalManagement_Workflow_View'
  | 'ApprovalManagement_Templates_View'
  | 'ApprovalManagement_Templates_NewBusiness_View'
  | 'ApprovalManagement_Templates_Compliance_View'
  | 'ApprovalManagement_Templates_ForexTransfers_View'
  | 'ApprovalManagement_Templates_SeniorManagement_View'
  | 'ApprovalManagement_Templates_Finance_View'
  | 'ApprovalManagement_Templates_Compliance_ApprovalGroups_View'
  | 'ApprovalManagement_Templates_Compliance_ApprovalGroups_Create'
  | 'ApprovalManagement_Templates_Compliance_ApprovalGroups_Edit'
  | 'ApprovalManagement_Templates_Compliance_ApprovalRules_View'
  | 'ApprovalManagement_Templates_Compliance_ApprovalRules_Create'
  | 'ApprovalManagement_Templates_Compliance_ApprovalRules_Edit'
  | 'ApprovalManagement_Templates_NewBusiness_ApprovalGroups_View'
  | 'ApprovalManagement_Templates_NewBusiness_ApprovalGroups_Create'
  | 'ApprovalManagement_Templates_NewBusiness_ApprovalGroups_Edit'
  | 'ApprovalManagement_Templates_NewBusiness_ApprovalRules_View'
  | 'ApprovalManagement_Templates_NewBusiness_ApprovalRules_Create'
  | 'ApprovalManagement_Templates_NewBusiness_ApprovalRules_Edit'
  | 'ApprovalManagement_Templates_ForexTransfer_ApprovalGroups_View'
  | 'ApprovalManagement_Templates_ForexTransfer_ApprovalGroups_Create'
  | 'ApprovalManagement_Templates_ForexTransfer_ApprovalGroups_Edit'
  | 'ApprovalManagement_Templates_ForexTransfer_ApprovalRules_View'
  | 'ApprovalManagement_Templates_ForexTransfer_ApprovalRules_Create'
  | 'ApprovalManagement_Templates_ForexTransfer_ApprovalRules_Edit'
  | 'ApprovalManagement_Templates_SeniorManagement_ApprovalGroups_View'
  | 'ApprovalManagement_Templates_SeniorManagement_ApprovalGroups_Create'
  | 'ApprovalManagement_Templates_SeniorManagement_ApprovalGroups_Edit'
  | 'ApprovalManagement_Templates_SeniorManagement_ApprovalRules_View'
  | 'ApprovalManagement_Templates_SeniorManagement_ApprovalRules_Create'
  | 'ApprovalManagement_Templates_SeniorManagement_ApprovalRules_Edit'
  | 'ApprovalManagement_Templates_Finance_ApprovalGroups_View'
  | 'ApprovalManagement_Templates_Finance_ApprovalGroups_Create'
  | 'ApprovalManagement_Templates_Finance_ApprovalGroups_Edit'
  | 'ApprovalManagement_Templates_Finance_ApprovalRules_View'
  | 'ApprovalManagement_Templates_Finance_ApprovalRules_Create'
  | 'ApprovalManagement_Templates_Finance_ApprovalRules_Edit'
  | 'ApprovalManagement_ClientGroup_Workflow_View';

// Permission config
export const ApprovalManagementPermissions: Record<
  ApprovalManagementPermissionKeys,
  PermissionedAccessModel
> = {
  ApprovalManagement_View: {
    requiredAllPermissions: false,
    permissions: [
      // Approval workflow page
      { permissionKey: 'Approval:NewBusinessWorkflow:Read' },
      { permissionKey: 'Approval:ComplianceWorkflow:Read' },
      { permissionKey: 'Approval:FxtransferWorkflow:Read' },

      // New business template page
      { permissionKey: 'Approval:NewBusinessTemplate:Read' },
      { permissionKey: 'Approval:NewBusinessTemplate:Create' },
      { permissionKey: 'Approval:NewBusinessGroup:Read' },
      { permissionKey: 'Approval:NewBusinessGroup:Create' },

      // New compliance template page
      { permissionKey: 'Approval:ComplianceTemplate:Read' },
      { permissionKey: 'Approval:ComplianceTemplate:Create' },
      { permissionKey: 'Approval:ComplianceGroup:Read' },
      { permissionKey: 'Approval:ComplianceGroup:Create' },

      // New fx transfers template page
      { permissionKey: 'Approval:FxTransferTemplate:Read' },
      { permissionKey: 'Approval:FxTransferTemplate:Create' },
      { permissionKey: 'Approval:FxTransferGroup:Read' },
      { permissionKey: 'Approval:FxTransferGroup:Create' },
    ],
  },

  ApprovalManagement_Workflow_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:NewBusinessWorkflow:Read' },
      { permissionKey: 'Approval:ComplianceWorkflow:Read' },
      { permissionKey: 'Approval:FxtransferWorkflow:Read' },
    ],
  },

  ApprovalManagement_Templates_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:NewBusinessTemplate:Read' },
      { permissionKey: 'Approval:NewBusinessTemplate:Create' },
      { permissionKey: 'Approval:NewBusinessGroup:Read' },
      { permissionKey: 'Approval:NewBusinessGroup:Create' },

      { permissionKey: 'Approval:ComplianceTemplate:Read' },
      { permissionKey: 'Approval:ComplianceTemplate:Create' },
      { permissionKey: 'Approval:ComplianceGroup:Read' },
      { permissionKey: 'Approval:ComplianceGroup:Create' },

      { permissionKey: 'Approval:FxTransferTemplate:Read' },
      { permissionKey: 'Approval:FxTransferTemplate:Create' },
      { permissionKey: 'Approval:FxTransferGroup:Read' },
      { permissionKey: 'Approval:FxTransferGroup:Create' },
    ],
  },

  ApprovalManagement_Templates_NewBusiness_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:NewBusinessTemplate:Read' },
      { permissionKey: 'Approval:NewBusinessTemplate:Create' },
      { permissionKey: 'Approval:NewBusinessGroup:Read' },
      { permissionKey: 'Approval:NewBusinessGroup:Create' },
    ],
  },

  ApprovalManagement_Templates_Compliance_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:ComplianceTemplate:Read' },
      { permissionKey: 'Approval:ComplianceTemplate:Create' },
      { permissionKey: 'Approval:ComplianceGroup:Read' },
      { permissionKey: 'Approval:ComplianceGroup:Create' },
    ],
  },

  ApprovalManagement_Templates_ForexTransfers_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:FxTransferTemplate:Read' },
      { permissionKey: 'Approval:FxTransferTemplate:Create' },
      { permissionKey: 'Approval:FxTransferGroup:Read' },
      { permissionKey: 'Approval:FxTransferGroup:Create' },
    ],
  },

  ApprovalManagement_Templates_SeniorManagement_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:SeniorManagementTemplate:Read' },
      { permissionKey: 'Approval:SeniorManagementTemplate:Create' },
      { permissionKey: 'Approval:SeniorManagementGroup:Read' },
      { permissionKey: 'Approval:SeniorManagementGroup:Create' },
    ],
  },

  ApprovalManagement_Templates_Finance_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:FinanceTemplate:Read' },
      { permissionKey: 'Approval:FinanceTemplate:Create' },
      { permissionKey: 'Approval:FinanceGroup:Read' },
      { permissionKey: 'Approval:FinanceGroup:Create' },
    ],
  },

  ApprovalManagement_Templates_Compliance_ApprovalGroups_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:ComplianceGroup:Read' },
      { permissionKey: 'Approval:ComplianceGroup:Create' },
    ],
  },

  ApprovalManagement_Templates_Compliance_ApprovalGroups_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:ComplianceGroup:Create' }],
  },

  ApprovalManagement_Templates_Compliance_ApprovalGroups_Edit: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:ComplianceGroup:Update' }],
  },

  ApprovalManagement_Templates_Compliance_ApprovalRules_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:ComplianceTemplate:Read' },
      { permissionKey: 'Approval:ComplianceTemplate:Create' },
    ],
  },

  ApprovalManagement_Templates_Compliance_ApprovalRules_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:ComplianceTemplate:Create' }],
  },

  ApprovalManagement_Templates_Compliance_ApprovalRules_Edit: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:ComplianceTemplate:Update' }],
  },

  ApprovalManagement_Templates_NewBusiness_ApprovalGroups_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:NewBusinessGroup:Read' },
      { permissionKey: 'Approval:NewBusinessGroup:Create' },
    ],
  },

  ApprovalManagement_Templates_NewBusiness_ApprovalGroups_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:NewBusinessGroup:Create' }],
  },

  ApprovalManagement_Templates_NewBusiness_ApprovalGroups_Edit: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:NewBusinessGroup:Update' }],
  },

  ApprovalManagement_Templates_NewBusiness_ApprovalRules_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:NewBusinessTemplate:Read' },
      { permissionKey: 'Approval:NewBusinessTemplate:Create' },
    ],
  },

  ApprovalManagement_Templates_NewBusiness_ApprovalRules_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:NewBusinessTemplate:Create' }],
  },

  ApprovalManagement_Templates_NewBusiness_ApprovalRules_Edit: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:NewBusinessTemplate:Update' }],
  },

  ApprovalManagement_Templates_ForexTransfer_ApprovalGroups_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:FxTransferGroup:Read' },
      { permissionKey: 'Approval:FxTransferGroup:Create' },
    ],
  },

  ApprovalManagement_Templates_ForexTransfer_ApprovalGroups_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:FxTransferGroup:Read' }],
  },

  ApprovalManagement_Templates_ForexTransfer_ApprovalGroups_Edit: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:FxTransferGroup:Update' }],
  },

  ApprovalManagement_Templates_ForexTransfer_ApprovalRules_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:FxTransferTemplate:Read' },
      { permissionKey: 'Approval:FxTransferTemplate:Create' },
    ],
  },

  ApprovalManagement_Templates_ForexTransfer_ApprovalRules_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:FxTransferTemplate:Create' }],
  },

  ApprovalManagement_Templates_ForexTransfer_ApprovalRules_Edit: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:FxTransferTemplate:Update' }],
  },

  ApprovalManagement_Templates_SeniorManagement_ApprovalGroups_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:SeniorManagementGroup:Read' },
      { permissionKey: 'Approval:SeniorManagementGroup:Create' },
    ],
  },

  ApprovalManagement_Templates_SeniorManagement_ApprovalGroups_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:SeniorManagementGroup:Read' }],
  },

  ApprovalManagement_Templates_SeniorManagement_ApprovalGroups_Edit: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:SeniorManagementGroup:Update' }],
  },

  ApprovalManagement_Templates_SeniorManagement_ApprovalRules_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:SeniorManagementTemplate:Read' },
      { permissionKey: 'Approval:SeniorManagementTemplate:Create' },
    ],
  },

  ApprovalManagement_Templates_SeniorManagement_ApprovalRules_Create: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:SeniorManagementTemplate:Create' },
    ],
  },

  ApprovalManagement_Templates_SeniorManagement_ApprovalRules_Edit: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:SeniorManagementTemplate:Update' },
    ],
  },

  ApprovalManagement_Templates_Finance_ApprovalGroups_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:FinanceGroup:Read' },
      { permissionKey: 'Approval:FinanceGroup:Create' },
    ],
  },

  ApprovalManagement_Templates_Finance_ApprovalGroups_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:FinanceGroup:Read' }],
  },

  ApprovalManagement_Templates_Finance_ApprovalGroups_Edit: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:FinanceGroup:Update' }],
  },

  ApprovalManagement_Templates_Finance_ApprovalRules_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:FinanceTemplate:Read' },
      { permissionKey: 'Approval:FinanceTemplate:Create' },
    ],
  },

  ApprovalManagement_Templates_Finance_ApprovalRules_Create: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:FinanceTemplate:Create' }],
  },

  ApprovalManagement_Templates_Finance_ApprovalRules_Edit: {
    requiredAllPermissions: false,
    permissions: [{ permissionKey: 'Approval:FinanceTemplate:Update' }],
  },

  ApprovalManagement_ClientGroup_Workflow_View: {
    requiredAllPermissions: false,
    permissions: [
      { permissionKey: 'Approval:NewBusinessTemplate:Read' },
      { permissionKey: 'Approval:ComplianceTemplate:Read' },
    ],
  },
};
