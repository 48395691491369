import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '.';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export let reactPlugin: ReactPlugin;
let appInsights: ApplicationInsights;

const createTelemetryService = () => {
  const initialize = (instrumentationKey: string) => {
    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided');
    }

    reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
      config: {
        enableCorsCorrelation: true,
        instrumentationKey,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: null,
          },
        },
      },
    });

    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((telemetryItem) => {
      if (telemetryItem.tags) {
        telemetryItem.tags['ai.cloud.role'] = 'UI Admin';
      }
    });
  };

  return {
    initialize,
    appInsights,
    reactPlugin,
  };
};

export const AppInsightsService = createTelemetryService();

// helper methods
// TODO: refactor helper methods
export const getAppInsights = () => {
  return appInsights;
};

export const trackEvent = (name: string, properties: any) => {
  if (!appInsights) {
    return;
  }

  appInsights.trackEvent({ name, properties });
};

export const trackException = (severity: SeverityLevel, exception: string) => {
  if (process.env.REACT_APP_STAGE === 'local') {
    return;
  }

  const insights = getAppInsights();

  if (insights) {
    insights.trackException({
      severityLevel: severity,
      exception: new Error(exception),
    });
  }
};
