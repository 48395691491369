import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { debounce } from 'lodash';
import { SortProps } from '@core_components/Table';
import { DateAcceptedStatus } from '../../../../enums/crm/crm';
import { formatSortDirectionToCRMModel } from '@helpers/utils';
import {
  invitationsAPI,
  SortInvitationType,
} from '../../../../api/crm/invitations/invitationsAPI';
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  DEFAULT_TABLE_LIMIT,
} from '../../../../constants/global';

// components
import FilterBar, { FilterTypes } from './FilterBar';
import { Divider } from 'antd';
import { default as TemplateInvitationsTable } from '../../../../components/Tables/TableTemplates/CRM/InvitationsTable';

const InvitationsTable = () => {
  // table
  const [current, setCurrent] = useState(1);

  // filters
  const [invitationsFilter, setInvitationsFilter] = useState<{
    search: string;
    status: string;
    sortBy: string;
    sortDirection: boolean | undefined;
  }>({
    search: '',
    status: '',
    sortBy: '',
    sortDirection: undefined,
  });

  const { response, loading } = useFetch(
    () =>
      invitationsAPI.fetchInvitations({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        search: invitationsFilter.search || undefined,
        status: (invitationsFilter.status as DateAcceptedStatus) || undefined,
        sortBy: (invitationsFilter.sortBy as SortInvitationType) || undefined,
        sortDirection: invitationsFilter.sortDirection,
      }),
    [current, invitationsFilter],
  );

  const handleFilterChange = (key: FilterTypes, value: any) => {
    if (current > 1) {
      setCurrent(1);
    }

    switch (key) {
      case 'search':
        setInvitationsFilter((prevState) => ({ ...prevState, search: value }));
        break;

      case 'filter_by_accepted_date_status':
        setInvitationsFilter((prevState) => ({
          ...prevState,
          status: value === 'all' ? undefined : value,
        }));
        break;
    }
  };

  const handleSortChange = (sort: SortProps) => {
    if (Array.isArray(sort)) return;

    switch (sort.columnKey) {
      case 'dateSent':
        setInvitationsFilter((prevState) => ({
          ...prevState,
          sortBy: 'invitationDate',
          sortDirection: formatSortDirectionToCRMModel(sort.order ?? null),
        }));
        break;

      case 'dateAccepted':
        setInvitationsFilter((prevState) => ({
          ...prevState,
          sortBy: 'acceptanceDate',
          sortDirection: formatSortDirectionToCRMModel(sort.order ?? null),
        }));
        break;
    }
  };

  return (
    <>
      <FilterBar
        onFilterChange={debounce(
          handleFilterChange,
          AUTOCOMPLETE_DEBOUNCE_DELAY,
        )}
      />
      <Divider />
      <TemplateInvitationsTable
        total={response?.total || 0}
        current={current}
        data={response?.data || []}
        loading={loading}
        onPaginationChange={setCurrent}
        sortCallback={handleSortChange}
      />
    </>
  );
};

export default InvitationsTable;
