import React, { memo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import { IContactModel } from 'typings/crm/contact';
import { relationshipAPI } from 'api/crm/relationship/relationshipAPI';
import { IRelationshipModel } from 'typings/crm/entity';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';

// components
import ViewEditRelationshipModal from './ViewEditRelationshipModal';
import {
  default as TemplateRelationshipsTable,
  ActionKeys,
} from 'components/Tables/TableTemplates/CRM/RelationshipsTable';

interface IProps {
  contact: IContactModel;
  updateTrigger: any;
}

const RelationshipsTable = memo(({ contact, updateTrigger }: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [localUpdateTrigger, updateTable] = useState({});
  const [selectedRelationship, setSelectedRelationship] =
    useState<IRelationshipModel | null>(null);

  const { response, loading } = useFetch(
    () =>
      relationshipAPI.fetchRelationships({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
        itemId: contact._id,
      }),
    [currentPage, updateTrigger, localUpdateTrigger, contact],
  );

  const handleEllipsisClick = (key: ActionKeys, record: IRelationshipModel) => {
    switch (key) {
      case 'view':
        setSelectedRelationship(record);
        break;
    }
  };

  const handleModalClose = (wasSubmitted?: boolean) => {
    if (wasSubmitted) {
      updateTable({});
    }

    setSelectedRelationship(null);
  };

  return (
    <>
      <TemplateRelationshipsTable
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={currentPage}
        associationId={contact._id || ''}
        onActionsClick={handleEllipsisClick}
        onPaginationChange={setCurrentPage}
      />

      <ViewEditRelationshipModal
        isVisible={!!selectedRelationship}
        relationship={selectedRelationship}
        closeCallback={handleModalClose}
      />
    </>
  );
});

export default RelationshipsTable;
