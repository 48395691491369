import React from 'react';

// helpers
import styled from 'styled-components';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import AdditionalField from '../Components/AdditionalField';
import AddAdditionalField from '../Components/AddAdditionalField';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';

const AdditionalFieldsSection = () => {
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      {values.additionalFields.map((e, i) => (
        <AdditionalField key={e._id} fieldIndex={i} disabled={e.isFinalized} />
      ))}

      <HideIfDisabledForm>
        <StyledSection>
          <AddAdditionalField />
        </StyledSection>
      </HideIfDisabledForm>
    </>
  );
};

const StyledSection = styled.div`
  margin: ${({ theme }) => theme.marginXs} 0 ${({ theme }) => theme.marginSm} 0;
`;

export default AdditionalFieldsSection;
