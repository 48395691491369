import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { AddressModel } from 'api/crm/expiringDocuments/expiringDocumentsAPI';

// components
import DocumentLink from 'components/Typography/DocumentLink';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel;

const ReviewAddressDocumentsTable = (props: IProps) => {
  const { t } = useTranslation('crm');
  const [current, setCurrent] = useState(1);

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        key: 'name',
        width: 250,
        title: t(
          'client_group.approval.review_details_modal.approval.addresses_table.name',
        ),
        render: (_: AddressModel, __: any, index: number) =>
          t(
            'client_group.approval.review_details_modal.approval.addresses_table.address',
            { index: index + 1 },
          ),
      },
      {
        key: 'answer',
        width: 250,
        title: t(
          'client_group.approval.review_details_modal.approval.addresses_table.answer',
        ),
        render: (record: AddressModel) => {
          return (
            record.documents &&
            record.documents.map((e) => (
              <div key={e.id}>
                <DocumentLink
                  text={e.files[0].name}
                  fileName={e.files[0].name}
                  documentId={e.id}
                  fileId={e.files[0].id}
                />
              </div>
            ))
          );
        },
      },
      {
        key: 'rejectionReason',
        width: 300,
        title: t(
          'client_group.approval.review_details_modal.approval.addresses_table.rejection_reason',
        ),
        render: (record: AddressModel) => {
          return (
            <EllipsisTooltip
              maxTextContainerWidth="300px"
              title={record.review?.comment || ''}
            >
              {record.review?.comment || ''}
            </EllipsisTooltip>
          );
        },
      },
    ];

    return result;
  }, []);

  return (
    <Table
      columns={columns}
      current={current}
      onPaginationChange={setCurrent}
      {...props}
    />
  );
};

export default ReviewAddressDocumentsTable;
