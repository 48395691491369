import React from 'react';

// helpers
import usersAPI from '../../../../../../api/orac/usersAPI';
import useTranslation from '../../../../../../hooks/useTranslation';
import { UsersBaseModel } from '../../../../../../typings/ORAC/users';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import Autocomplete, {
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';

interface IProps {
  value?: string;
}

const UsersByEmailAutocomplete = (props: IProps) => {
  const { t } = useTranslation('orac');

  const mapOption = (user: UsersBaseModel) => {
    return {
      id: user._id,
      content: user.email,
      model: user,
    };
  };

  const fetchUsers = async (searchQuery: string) => {
    const { data } = await usersAPI.fetchUsers({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      name: searchQuery,
    });

    return data.map<AutocompleteOption>(mapOption);
  };

  return (
    <Autocomplete
      {...props}
      placeholder={t('groups.view.user_placeholder')}
      fetchData={fetchUsers}
    />
  );
};

export default UsersByEmailAutocomplete;
