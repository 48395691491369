import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { BaseButtonProps } from 'antd/lib/button/button';
import { StyledComponentProps } from '../../../../../../typings/common';

// components
import DeleteConfirmation from '../../../../DeleteConfirmation';
import { Button } from 'antd';

interface IProps extends StyledComponentProps, BaseButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  action: 'match' | 'no_match';
  onClick: (reason: string) => Promise<any>;
}

const MatchButton = ({
  children,
  disabled,
  onClick,
  action,
  ...rest
}: IProps) => {
  const { t } = useTranslation(['compliance', 'common']);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Button {...rest} disabled={disabled} onClick={() => setIsVisible(true)}>
        {children}
      </Button>
      <DeleteConfirmation
        title={
          action === 'match'
            ? t('rdc_review.mark_as_match')
            : t('rdc_review.mark_as_no_match')
        }
        description={
          action === 'match'
            ? t('rdc_review.mark_as_match_desc')
            : t('rdc_review.mark_as_no_match_desc')
        }
        submitBtnText={t('modal.save', { ns: 'common' })}
        isSubmitButtonDanger={action === 'match'}
        isVisible={isVisible}
        onSubmitCallback={onClick}
        closeCallback={() => setIsVisible(false)}
      />
    </>
  );
};

export default MatchButton;
