import { WireTemplate } from '../../../components/Additional/DetailsSections/Accounting/WireTransferTemplateInfo';
import { COUNTRY_CODES } from '../../../constants/countryCodes';
import { CountryHelpers } from '@helpers/crm/country';
import { InternalTemplate } from '../../../components/Additional/DetailsSections/Accounting/InternalTransferTemplateInfo';
import {
  InternalTransferTemplateFromQuery,
  WireTransferTemplateFromQuery,
} from '../../../api/transferTemplates/transferTemplatesAPI';

const transferTemplatesAPIAdapter = {
  formatWireTransferTemplateFromQueryToWireTemplate: function (
    template: WireTransferTemplateFromQuery,
  ): WireTemplate {
    return {
      ...template,
      creationDate: template.creationDate,
      id: template.id,
      templateName: template.templateName,
      beneficiaryCountry: template.beneficiaryCountry
        ? COUNTRY_CODES[template.beneficiaryCountry]
        : '',
      beneficiaryAddress: template.beneficiaryAddress1,
      beneficiaryDistrict: template.beneficiaryDistrict
        ? CountryHelpers.getDistrict(
            template.beneficiaryCountry,
            template.beneficiaryDistrict,
          )
        : '',
      beneficiaryBankCountry: template.beneficiaryBankCountry
        ? COUNTRY_CODES[template.beneficiaryBankCountry]
        : '',
      beneficiaryBankAddress: template.beneficiaryBankAddress1,
      beneficiaryBankDistrict: template.beneficiaryBankDistrict
        ? CountryHelpers.getDistrict(
            template.beneficiaryBankCountry,
            template.beneficiaryBankDistrict,
          )
        : '',
      intermediaryBankCountry: template.intermediaryBankCountry
        ? COUNTRY_CODES[template.intermediaryBankCountry]
        : '',
      intermediaryBankAddress: template.intermediaryBankAddress1,
      intermediaryBankDistrict: template.intermediaryBankDistrict
        ? CountryHelpers.getDistrict(
            template.intermediaryBankCountry,
            template.intermediaryBankDistrict,
          )
        : '',
    };
  },

  formatInternalTransferTemplateFromQueryToInternalTemplate: function (
    template: InternalTransferTemplateFromQuery,
  ): InternalTemplate {
    return {
      id: template.id,
      creationDate: template.creationDate,
      templateName: template.templateName,
      isOnlyMe: template.isOnlyMe,
      accountName: template.accountName,
      accountNumber: template.accountNumber,
      reference: template.recipientReference,
    };
  },
};

export { transferTemplatesAPIAdapter };
