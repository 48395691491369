import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../../../hooks/useFetch';
import { SortProps } from '@core_components/Table';
import { IContactModel } from 'typings/crm/contact';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../../constants/global';
import { formatSortDirectionToCRMModel } from '@helpers/utils';
import {
  invitationsAPI,
  SortInvitationType,
} from '../../../../../../../api/crm/invitations/invitationsAPI';

// components
import InvitationsTable from '../../../../../../../components/Tables/TableTemplates/CRM/InvitationsTable';

interface IProps {
  contact: IContactModel;
}

const Invitations = ({ contact }: IProps) => {
  const [current, setCurrent] = useState(1);

  const [invitationsFilter, setInvitationsFilter] = useState<{
    sortBy: string;
    sortDirection: boolean | undefined;
  }>({
    sortBy: '',
    sortDirection: undefined,
  });

  const { response, loading } = useFetch(
    () =>
      invitationsAPI.fetchInvitations({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        contactId: contact._id,
        sortBy: (invitationsFilter.sortBy as SortInvitationType) || undefined,
        sortDirection: invitationsFilter.sortDirection,
      }),
    [current, contact, invitationsFilter],
  );

  const handleSortChange = (sort: SortProps) => {
    if (Array.isArray(sort)) {
      return;
    }

    switch (sort.columnKey) {
      case 'dateSent':
        setInvitationsFilter((prevState) => ({
          ...prevState,
          sortBy: 'invitationDate',
          sortDirection: formatSortDirectionToCRMModel(sort.order ?? null),
        }));
        break;

      case 'dateAccepted':
        setInvitationsFilter((prevState) => ({
          ...prevState,
          sortBy: 'acceptanceDate',
          sortDirection: formatSortDirectionToCRMModel(sort.order ?? null),
        }));
        break;
    }
  };

  return (
    <InvitationsTable
      isContactInvitations
      data={response?.data || []}
      total={response?.total || 0}
      current={current}
      loading={loading}
      sortCallback={handleSortChange}
      onPaginationChange={setCurrent}
    />
  );
};

export default Invitations;
