import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { ReviewProcessConfigurationRuleModel } from '../../../../../../../typings/compliance/reviewProcess';
import {
  ComparisonOperators,
  ReviewConfigurationsStatuses,
} from '../../../../../../../enums/compliance/reviewConfiguration';

// components
import StatusLabel from '../../../../../../../components/Typography/StatusLabel';
import { Typography } from 'antd';

interface IProps {
  rule: ReviewProcessConfigurationRuleModel;
}

const RuleSummaryRow = ({ rule }: IProps) => {
  const { t } = useTranslation('compliance');

  const ruleSummary = `${rule.propertyName} ${rule.evaluatedAgainstValueJSON} ${t(
    `comparison_operator.${ComparisonOperators[rule.comparisonOperator]}`,
  )} ${JSON.parse(rule.expected.valueJSON)}`;

  const isTriggeredString = rule.isTriggered ? (
    <StatusLabel status="warning" text={t('transactions.triggered')} />
  ) : (
    <StatusLabel status="info" text={t('transactions.not_triggered')} />
  );

  const statusString =
    rule.reviewResult.status === ReviewConfigurationsStatuses.Pending ? (
      <StatusLabel
        status="warning"
        text={`(${t(
          `review_statuses.${ReviewConfigurationsStatuses[ReviewConfigurationsStatuses.Pending as ReviewConfigurationsStatuses]}`,
        )})`}
      />
    ) : rule.reviewResult.status === ReviewConfigurationsStatuses.Passed ? (
      <StatusLabel
        status="success"
        text={`(${t(
          `review_statuses.${ReviewConfigurationsStatuses[ReviewConfigurationsStatuses.Passed as ReviewConfigurationsStatuses]}`,
        )})`}
      />
    ) : (
      <StatusLabel
        status="error"
        text={`(${t(`review_statuses.${ReviewConfigurationsStatuses[ReviewConfigurationsStatuses.NotPassed]}`)})`}
      />
    );

  return (
    <StyledTypography>
      {`${t('transactions.transfer')}: `} {ruleSummary} {isTriggeredString}{' '}
      {rule.isTriggered && statusString}
    </StyledTypography>
  );
};

const StyledTypography = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
`;

export default RuleSummaryRow;
