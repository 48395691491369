import React, { useEffect, useState } from 'react';

// helpers
import useTranslation from '../../hooks/useTranslation';
import { UploadStatus } from '../../constants/documents';
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_DOCUMENTS_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import DocumentsTable from '../../modules/CRM/Documents/DocumentsTable';
import UploadDocumentsDialog from '../../modules/CRM/Documents/UploadDocumentsDialog';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';
import { Button } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';

interface IProps extends PageLayoutHelperPropsModel {
  uploadStatus: UploadStatus;
}
const Documents = ({ setHeaderOptions }: IProps) => {
  const { t } = useTranslation('crm');

  const [uploadVisible, setUploadVisible] = useState(false);

  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation link={GENESIS_DOCUMENTS_DOCUMENTATION_LINK} />
      ),
      extra: () => (
        <Button type="primary" onClick={() => setUploadVisible(true)}>
          <CloudUploadOutlined />
          {t('documents.uploadDocuments.title')}
        </Button>
      ),
    });
  }, []);

  const closeHandler = () => {
    setUploadVisible(false);
  };
  return (
    <>
      <DocumentsTable />
      <UploadDocumentsDialog
        isVisible={uploadVisible}
        closeCallback={closeHandler}
      />
    </>
  );
};

export default Documents;
