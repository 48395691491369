import React from 'react';

// helpers
import { OnboardType } from 'enums/crm/crm';
import { AddNewNodeFormValidationSchema } from 'validations/crm/clientGroups';
import { FormValuesModel as ContactFormValuesModel } from '../../../../Forms/TemplateForms/CRM/ContactShortInfoForm';
import { FormValuesModel as RelationshipFormValuesModel } from '../../../../Forms/TemplateForms/CRM/ClientRelationshipForm';
import { FormValuesModel as OrganizationFormValuesModel } from '../../../../Forms/TemplateForms/CRM/OrganizationShortInfoForm';

// components
import InnerForm from './InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export type FormValuesModel = {
  clientGroupId: string;
  activeApplicationScopeId: string;

  template: {
    id: string | null;
    visibleFor: OnboardType | null;
  };
  clientGroupEntryType: OnboardType;
  contact: ContactFormValuesModel | null;
  organization: OrganizationFormValuesModel | null;

  canAddOwnerships: boolean;
  relationships: RelationshipFormValuesModel[];
};

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
}

const AddNewNodeModalDialog = ({
  title,
  isVisible,
  initialValues,
  onSubmit,
  onSubmitError,
  closeCallback,
}: IProps) => {
  return (
    <Form
      enableReinitialize
      onSubmit={onSubmit}
      onSubmitError={onSubmitError}
      initialValues={isVisible ? initialValues : null}
      validationSchema={AddNewNodeFormValidationSchema}
      renderForm={
        <ModalDialog
          closeModalAfterError={false}
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default AddNewNodeModalDialog;
