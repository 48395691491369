import React, { useEffect, useState } from 'react';

// helpers
import { AccountsHelpers } from '@helpers/finance/accounts';
import { StyledComponentProps } from '../../../../typings/common';

// components
import { InputNumber } from 'antd';

export type InputSizes = 'large' | 'middle' | 'small';

interface IProps extends StyledComponentProps {
  value?: number;
  // Set precision to limit decimal places which can be entered
  step?: number;
  name?: string;
  size?: InputSizes;
  placeholder?: string;
  onChange: (newValue: number) => void;
}

const AmountField = ({ onChange, value, step = 0.01, ...rest }: IProps) => {
  useEffect(() => {
    if (value !== tempValue) {
      setTempValue(value);
    }
  }, [value]);

  const [tempValue, setTempValue] = useState<string | number | undefined>(
    value,
  );

  // Just save a new value, this values will be parsed in onBlur handler
  const handleOnChange = (newValue: string | number) => {
    setTempValue(newValue);
  };

  // Handle on blur event
  // - Handle the case when user entered something wierd like a string (e.g. 12.12.12.12)
  // - Round value to 2 decimal places if it's needed
  // - Save new values
  const handleOnBlur = (newValue: string | number | undefined = 0) => {
    let inputValue = newValue;

    if (!Number(inputValue)) {
      inputValue = parseFloat(String(newValue));
    }

    let roundedNumber = 0;

    if (Number(inputValue) > 0) {
      roundedNumber = AccountsHelpers.roundNumber(+inputValue, 4);
    }

    // Save new value
    setTempValue(roundedNumber);
    onChange(roundedNumber);
  };

  return (
    <InputNumber
      {...rest}
      step={step}
      value={tempValue as any}
      onBlur={() => handleOnBlur(tempValue)}
      onChange={(newValue: any) => handleOnChange(newValue)}
    />
  );
};

export default AmountField;
