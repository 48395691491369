import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { enumToMap } from '../../../../../../helpers/utils';
import { SelectProps } from 'antd/lib/select';
import { ConfigurationRuleState } from '../../../../../../enums/compliance/reviewConfiguration';

// components
import { Select } from 'antd';

type IProps = SelectProps<any>;

const ConfigurationRuleStateSelect = ({ value, ...rest }: IProps) => {
  const { t } = useTranslation(['compliance', 'common']);

  const renderOptions = () => {
    return Array.from(enumToMap(ConfigurationRuleState)).map((el) => (
      <Select.Option key={el[1]} value={el[1]}>
        {t(`configuration_rule_state.${el[0]}`)}
      </Select.Option>
    ));
  };

  return (
    <Select
      {...rest}
      value={value}
      showSearch
      placeholder={t('select_option', { ns: 'common' })}
    >
      {renderOptions()}
    </Select>
  );
};

export default ConfigurationRuleStateSelect;
