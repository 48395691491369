import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import useTranslation from '../../../../../hooks/useTranslation';
import moment, { Moment } from 'moment';
import { DEFAULT_TABLE_LIMIT } from '../../../../../constants/global';
import { ExchangeCurrencyRateParams } from '../../../../../modules/Finance/CurrencyRates/CurrencyRatesTable';
import {
  FetchResponseType,
  FetchParams,
} from '../../../../../modules/Finance/CurrencyRates/CurrencyRateModal';

// components
import FilterBar from './FilterBar.tsx';
import ExchangeCurrencyRatesTable from '../../../../Tables/TableTemplates/Finance/ExchangeCurrencyRatesTable';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  selectedCurrency: ExchangeCurrencyRateParams | null;
  fetchCurrencyRatesCallback: (
    params: FetchParams,
  ) => Promise<FetchResponseType>;
}

const CurrencyRateModal = ({
  isVisible,
  selectedCurrency,
  fetchCurrencyRatesCallback,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('finance');

  const [currentPage, setCurrentPage] = useState(1);

  const [dateRange, setDateRange] = useState<[Moment, Moment]>(
    getInitialDateRange(),
  );

  const { response, loading } = useFetch(
    () =>
      selectedCurrency
        ? fetchCurrencyRatesCallback({
            ...selectedCurrency,
            limit: DEFAULT_TABLE_LIMIT,
            page: currentPage,
            fromDate: dateRange[0]?.unix() || moment().startOf('day').unix(),
            toDate: dateRange[1]?.unix() || moment().endOf('day').unix(),
          })
        : null,
    [selectedCurrency, dateRange, currentPage],
  );

  function getInitialDateRange(): [Moment, Moment] {
    return [moment().subtract(3, 'month'), moment()];
  }

  const handleFilterChange = (dateRange: [Moment, Moment]) => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }

    if (dateRange) {
      setDateRange([dateRange[0].startOf('day'), dateRange[1].endOf('day')]);
    }
  };

  return (
    <ModalDialog
      title={t('currency_rates.exchange_rate')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      hideFooterButtons
    >
      <FilterBar
        dateFilterValue={dateRange}
        filterChange={handleFilterChange}
      />

      <ExchangeCurrencyRatesTable
        loading={loading}
        current={currentPage}
        total={response?.total || 0}
        data={response?.data || []}
        onPaginationChange={setCurrentPage}
      />
    </ModalDialog>
  );
};

export default CurrencyRateModal;
