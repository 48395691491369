import React from 'react';

// components
import ObjectsTable from '../../../modules/ORAC/Objects/ObjectsTable';

const Objects = () => {
  return <ObjectsTable />;
};

export default Objects;
