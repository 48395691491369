import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { IEntity } from '../../../../../typings/crm/entity';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { DateHelpers } from '@helpers/date';
import { IClientGroup } from '../../../../../typings/crm/client-group';
import { EntityHelpers } from '@helpers/crm/entity';
import { IContactModel } from '../../../../../typings/crm/contact';
import { ContactHelpers } from '@helpers/crm/contact';
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../../constants/global';

// components
import ClientGroupStatus from 'components/Additional/Statuses/ClientGroupStatus';
import ClientGroupReviewStatus from 'components/Additional/Statuses/ClientGroupReviewStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

type IProps = RequiredPropsForTableModel<IClientGroup[]>;

const ClientGroupsTable = memo((props: IProps) => {
  const { t } = useTranslation('crm');

  const history = useHistory();

  const renderDirectClientName = (value: IClientGroup) => {
    if (value.directClientType === 'contact') {
      const directClient = value.directClient as IContactModel;
      return ContactHelpers.getContactName(directClient);
    } else {
      const directClient = value.directClient as IEntity;
      return (
        EntityHelpers.getEntityName(directClient) || (
          <NoNameText>{t('client_groups.no_direct_name')}</NoNameText>
        )
      );
    }
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('client_groups.name'),
        key: 'clientGroupName',
        render: (record: IClientGroup) => (
          <Typography.Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.CRM_Client_Group_Details,
                search: `?id=${record._id}`,
              })
            }
          >
            {record.clientGroupName || t('client_groups.no_group_name')}
          </Typography.Link>
        ),
      },
      {
        title: t('client_groups.direct_client_name'),
        key: 'directClientName',
        render: renderDirectClientName,
      },
      {
        title: t('client_groups.direct_client_type'),
        key: 'categoryType',
        render: (record: IClientGroup) =>
          t(`application.type.${record.directClientType}`),
      },
      {
        title: t('client_groups.review_status'),
        key: 'reviewStatus',
        render: (record: IClientGroup) => (
          <ClientGroupReviewStatus status={record.reviewStatus} />
        ),
      },
      {
        title: t('client_groups.status'),
        key: 'status',
        render: (record: IClientGroup) => (
          <ClientGroupStatus status={record.status} />
        ),
      },
      {
        title: t('client_groups.creation_date'),
        key: 'creationDate',
        render: (record: IClientGroup) => (
          <Typography.Text>
            {DateHelpers.formatDateToString(
              record._audit.createdAt,
              DEFAULT_DATE_TIME_FORMAT,
            )}
          </Typography.Text>
        ),
      },
    ];

    return result;
  }, []);

  return <Table columns={columns} {...props} />;
});

const NoNameText = styled(Typography.Text)`
  color: ${({ theme }) => theme.textLightColor4} !important;
`;
export default ClientGroupsTable;
