import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../../../../hooks/useTranslation';

// components
import DeleteConfirmation from '../../../../../../../components/Modals/DeleteConfirmation';
import { Button } from 'antd';

interface IProps {
  isDanger?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  onReview: (reason: string) => Promise<any>;
}

const ReviewButton = ({ children, onReview, disabled, isDanger }: IProps) => {
  const { t } = useTranslation(['compliance', 'common']);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Button
        danger={isDanger}
        disabled={disabled}
        onClick={() => setIsVisible(true)}
      >
        {children}
      </Button>

      <DeleteConfirmation
        isSubmitButtonDanger={!!isDanger}
        title={t('transactions.review_configuration_rule')}
        description={t('transactions.review_configuration_rule_desc')}
        submitBtnText={t('modal.save', { ns: 'common' })}
        isVisible={isVisible}
        closeCallback={() => setIsVisible(false)}
        onSubmitCallback={onReview}
      />
    </>
  );
};

export default ReviewButton;
