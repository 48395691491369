import APIConfig from '../../config/api';
import reviewConfigurationAdapter from '../../apiAdapters/compliance/reviewConfigurationAdapter';
import { APIService } from '../axiosInstance';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import {
  ConfigurationRuleModel,
  EditConfigurationRuleModel,
  NewConfigurationRuleModel,
  ReferenceObjectWithPropertiesModel,
} from '../../typings/compliance/configurationRules';

interface FetchConfigurationRulesParamsModel extends FetchParamsModel {
  riskLevel: string | null;
  reviewType: string | null;
  referenceObjectId: string | null;
}

interface FetchWithNameSearchParams extends FetchParamsModel {
  name?: string;
}

const reviewConfigurationAPI = {
  fetchConfigurationRules: (params: FetchConfigurationRulesParamsModel) => {
    return APIService.get<FetchResponseModel<ConfigurationRuleModel>>(
      `${APIConfig.amlApi}/review-configuration-rules`,
      {
        params,
      },
    ).then(({ data }) =>
      reviewConfigurationAdapter.fetchConfigurationRules(data),
    );
  },

  fetchReferenceObjects: (params: FetchWithNameSearchParams) => {
    return APIService.get<
      FetchResponseModel<ReferenceObjectWithPropertiesModel>
    >(`${APIConfig.amlApi}/reference-objects`, { params }).then(
      ({ data }) => data,
    );
  },

  fetchReviewConfigurationRuleById: (ruleId: string) => {
    return APIService.get<ConfigurationRuleModel>(
      `${APIConfig.amlApi}/review-configuration-rules/${ruleId}`,
    ).then(({ data }) =>
      reviewConfigurationAdapter.fetchReviewConfigurationRuleById(data),
    );
  },

  createReviewConfigurationRule: (newRule: NewConfigurationRuleModel) => {
    return APIService.post<boolean>(
      `${APIConfig.amlApi}/review-configuration-rules`,
      newRule,
    ).then(({ data }) => data);
  },

  editReviewConfigurationRule: (
    ruleId: string,
    updatedRule: EditConfigurationRuleModel,
  ) => {
    return APIService.put<boolean>(
      `${APIConfig.amlApi}/review-configuration-rules/${ruleId}`,
      updatedRule,
    ).then(({ data }) => data);
  },
};

export default reviewConfigurationAPI;
