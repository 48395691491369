import React from 'react';

// helpers
import { FormFieldPropsModel } from '@core_components/FormField';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';
import { accountRecoveryRequestsAPI } from '../../../../../../api/orac/accountRecoveryRequestsAPI';

// components
import Autocomplete, {
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';

type Props = FormFieldPropsModel;

const AccountRecoveryRequestsAutocomplete = (props: Props) => {
  const fetchAccountRecoveries = async (searchQuery: string) => {
    const { data } =
      await accountRecoveryRequestsAPI.fetchAccountRecoveryRequests({
        limit: AUTOCOMPLETE_RESULT_LIMIT,
        page: 1,
        name: searchQuery,
        status: null,
      });

    return data.map<AutocompleteOption>((x) => {
      return {
        id: x._id,
        content: x.user?.name,
        model: x,
      };
    });
  };
  return <Autocomplete {...props} fetchData={fetchAccountRecoveries} />;
};

export default AccountRecoveryRequestsAutocomplete;
