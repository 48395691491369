import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../../../../hooks/useFetch';
import usersAPI from '../../../../../../../../api/orac/usersAPI';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { message, Modal } from 'antd';
import { SecurityKeyModel } from '../../../../../../../../typings/profile/fido';

// components
import EditSecurityKeyModal from '../EditSecurityKeyModal';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import SecurityKeysTable, {
  ActionKeys,
} from '../../../../../../../../components/Tables/TableTemplates/Profile/SecurityKeysTable';

interface IProps extends RequiredPropsForModalDialogModel {
  userId: string;
  onDisable: () => void;
  onChangeDefault: () => void;
}

const SecurityKeysModal = ({
  isVisible,
  userId,
  closeCallback,
  onDisable,
  onChangeDefault,
}: IProps) => {
  const { t } = useTranslation(['orac', 'common']);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedKey, setSelectedKey] = useState<SecurityKeyModel | null>(null);
  const [updateTableTrigger, updateTable] = useState({});

  const { response, loading } = useFetch(
    () => (isVisible ? usersAPI.fetchSecurityKeys(userId) : null),
    [isVisible, userId, currentPage, updateTableTrigger],
  );

  const handleActionsClick = (key: ActionKeys, record: SecurityKeyModel) => {
    switch (key) {
      case 'details':
        setSelectedKey(record);
        break;

      case 'delete':
        handleDeleteKey(record.name, record._id);
        break;
    }
  };

  const handleModalClose = (wasUpdated?: boolean) => {
    if (wasUpdated) {
      updateTable({});
    }

    setSelectedKey(null);
  };

  const handleDeleteKey = (name: string, keyId: string) => {
    Modal.confirm({
      title: t(
        'users.view.two_step_verification.security_key.remove_key.title',
      ),
      content: t(
        'users.view.two_step_verification.security_key.remove_key.description',
        { keyName: name },
      ),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        await usersAPI.removeFidoKey(userId, keyId);
        message.success(
          t(
            'users.view.two_step_verification.security_key.remove_key.submit_success_message',
          ),
        );
        if (response?.data.length === 1) {
          closeCallback();
          onChangeDefault();
          onDisable();
        } else {
          updateTable({});
        }
      },
    });
  };

  return (
    <ModalDialog
      width={1200}
      title={t('users.view.two_step_verification.security_key.modal.title')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      hideFooterButtons
    >
      <SecurityKeysTable
        current={currentPage}
        loading={loading}
        data={response?.data || []}
        total={response?.total || 0}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsClick}
      />
      <EditSecurityKeyModal
        isVisible={!!selectedKey}
        userId={userId}
        closeCallback={handleModalClose}
        securityKey={selectedKey}
      />
    </ModalDialog>
  );
};

export default SecurityKeysModal;
