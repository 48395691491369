import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';

// components
import { Col, Row } from 'antd';
import { SearchInput } from 'components/Fields/SearchInput';

interface IProps {
  onFilterChange: (key: string, value: string) => void;
}

const FilterBar = ({ onFilterChange }: IProps) => {
  const { t } = useTranslation('orac');

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Row>
          <Col span={24}>{t('groups.table.search_groups')}</Col>
          <Col span={24}>
            <SearchInput
              size="large"
              placeholder={t('groups.table.search_groups_placeholder')}
              onSearch={(searchQuery: string) =>
                onFilterChange('search_by_name', searchQuery)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FilterBar;
