import React, { useMemo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import ErrorHandlerService from '@services/error-handler/service';
import { message } from 'antd';
import { GraphNode } from 'components/Charts/GraphChart';
import { OnboardType } from 'enums/crm/crm';
import { IClientGroup } from 'typings/crm/client-group';
import { IOnboardingStatus } from 'typings/crm/onboarding';
import { onboardingAnswerAdapter } from 'apiAdapters/crm/onboarding/onboardingAnswerAdapter';
import {
  AnswerModel,
  onboardingAnswerAPI,
} from 'api/crm/onboarding/onboardingAnswerAPI';
import {
  ReviewDetailsModel,
  clientGroupsAPI,
} from 'api/crm/clientGroup/clientGroupsAPI';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import RequireAdditionalInformationModalDialog from 'components/Modals/TemplateModalDialogs/CRM/RequireAdditionalInformationModalDialog';
import ApplicationDocumentationForm, {
  AdditionalFieldFormItemModel,
  FormValuesModel,
} from 'components/Forms/TemplateForms/CRM/ApplicationDocumentationForm';

interface IProps {
  node: GraphNode | null;
  isViewOnly: boolean;
  clientGroup: IClientGroup;
  onboardingStatus: IOnboardingStatus | null;
  updateNodeCallback: () => void;
  updateClientGroupCallback: () => void;
}

const VerificationDocuments = ({
  node,
  clientGroup,
  isViewOnly,
  onboardingStatus,
  updateNodeCallback,
  updateClientGroupCallback,
}: IProps) => {
  const { t } = useTranslation(['crm', 'common']);
  const [requireAdditionalInfoModalData, setRequireAdditionalInfoModalData] =
    useState<ReviewDetailsModel[] | null>(null);

  const { response: onboardingAnswers, loading: onboardingAnswersLoading } =
    useFetch(
      () =>
        node
          ? onboardingAnswerAPI.fetchApplicationDocumentationStepAnswers(
              node.id,
              clientGroup._id,
            )
          : null,
      [node, clientGroup._id],
    );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!onboardingAnswers || !onboardingStatus || !node) {
      return null;
    }

    const mapAdditionalField = (
      answer: AnswerModel,
    ): AdditionalFieldFormItemModel => ({
      isFinalized: answer.status === 'finalized',
      _id: answer._id,
      requestedByAdmin: answer.sources.some((e) => e.type === 'admin'),
      itemId: answer.itemId,
      itemType: answer.itemType,
      clientGroupId: answer.clientGroupId,
      field: answer.field,
      sources: answer.sources,
      isApproved: answer.review ? answer.review?.isValid : null,
      isRejected: answer.review ? !answer.review.isValid : null,
      notReviewed: !answer.review,
      reviewComment: answer.review?.comment || '',
      answer: answer.answer
        ? {
            providedBy: answer.answer.details.providedBy,
            isProvided: answer.answer?.isProvided,
            valueJSON: answer.answer?.valueJSON || '',
            reason: answer.answer?.reason || '',
          }
        : null,
    });

    const { relationship_keyRelationshipTemplate, client, admin } =
      onboardingAnswers;
    const filteredRelationshipData = relationship_keyRelationshipTemplate
      ? [...relationship_keyRelationshipTemplate.data, ...admin.data]
      : [...admin.data];

    const additionalFields: AdditionalFieldFormItemModel[] =
      filteredRelationshipData.map(mapAdditionalField);
    return {
      itemId: node.id,
      itemType: node.entryType,
      submitActionType: null,
      additionalFields,
      additionalDocuments: client.data,
      reviewProcess: {
        type: 'client-group-review',
        entryId: clientGroup._id,
      },
    };
  }, [onboardingAnswers, onboardingStatus, node, clientGroup]);

  const handleSubmit = async (values: FormValuesModel) => {
    try {
      const formattedBody =
        onboardingAnswerAdapter.reviewApplicationDocumentation(
          values,
          node?.model._id || clientGroup.directClientItemId,
          node?.entryType || OnboardType.Organization,
        );

      await onboardingAnswerAPI.reviewApplicationDocumentation(formattedBody);
      message.success(t('success', { ns: 'common' }));
      updateNodeCallback();

      if (values.submitActionType === 'start-approval-workflow') {
        await clientGroupsAPI.startApprovalWorkflow(clientGroup._id);
        updateClientGroupCallback();
      } else if (values.submitActionType === 'require-additional-info') {
        const response = await clientGroupsAPI.fetchReviewDetails(
          values.reviewProcess.entryId,
        );
        if (response.length) {
          setRequireAdditionalInfoModalData(response);
        } else {
          message.error(t('client_group.approval.review_details_error'));
        }
      }
    } catch (e) {
      ErrorHandlerService.handleError(e);
    }
  };

  const handleRequireAdditionalInfoModalSubmit = async () => {
    await onboardingAnswerAPI.requireAdditionalInfoForClientGroup(
      clientGroup._id,
    );
    updateClientGroupCallback();
  };

  return (
    <>
      <LoadingWrapper loading={onboardingAnswersLoading}>
        {initialFormValues && (
          <ApplicationDocumentationForm
            onSubmit={handleSubmit}
            isViewOnly={isViewOnly}
            initialFormValues={initialFormValues}
          />
        )}
      </LoadingWrapper>
      <RequireAdditionalInformationModalDialog
        title={t('client_group.approval.review_details_modal.title')}
        data={requireAdditionalInfoModalData}
        isVisible={!!requireAdditionalInfoModalData}
        onSubmit={handleRequireAdditionalInfoModalSubmit}
        closeCallback={() => setRequireAdditionalInfoModalData(null)}
      />
    </>
  );
};

export default VerificationDocuments;
