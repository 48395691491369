import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export interface RangeOptionModel {
  min: number | null;
  max: number | null;
}

export type InitialDepositRangeKeys =
  | 'initialDepositOption1'
  | 'initialDepositOption2'
  | 'initialDepositOption3'
  | 'initialDepositOption4'
  | 'initialDepositOption5'
  | 'initialDepositOption6';

export const InitialDepositRangeOptions: Record<
  InitialDepositRangeKeys,
  RangeOptionModel
> = {
  initialDepositOption1: {
    min: null,
    max: 50000,
  },
  initialDepositOption2: {
    min: 50000,
    max: 99999,
  },
  initialDepositOption3: {
    min: 100000,
    max: 249999,
  },
  initialDepositOption4: {
    min: 250000,
    max: 499999,
  },
  initialDepositOption5: {
    min: 500000,
    max: 999999,
  },
  initialDepositOption6: {
    min: 1000000,
    max: null,
  },
};

type IProps = SelectProps;

const InitialDepositRangeFormSelect = (props: IProps) => {
  const { t } = useTranslation('common');

  const options = useMemo<SelectOption[]>(() => {
    function getOptionLabel(key: InitialDepositRangeKeys) {
      const { min, max } = InitialDepositRangeOptions[key];

      if (typeof min !== 'number' || max === min) {
        const formattedMaxValue = max?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });

        return `${t('under')} ${formattedMaxValue}`;
      }

      if (typeof max !== 'number') {
        const formattedMinValue = min?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });

        return `${formattedMinValue} ${t('or_more')}`;
      }

      const formattedMinValue = min?.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      const formattedMaxValue = max?.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return `${formattedMinValue} ${t('to')} ${formattedMaxValue}`;
    }

    return Object.keys(InitialDepositRangeOptions).map((key) => ({
      id: key,
      label: getOptionLabel(key as InitialDepositRangeKeys),
    }));
  }, []);

  return <FormSelect {...props} options={options} />;
};

export default InitialDepositRangeFormSelect;
