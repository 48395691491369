import React, { useState } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import { DEFAULT_TABLE_LIMIT } from '../../../constants/global';
import { pendingForexTransfersAPI } from '../../../api/banking/pendingForexTransfersAPI';

// components
import ForexTransfersTable from '../../../components/Tables/TableTemplates/Banking/ForexTransfersTable';
import ForexTransferDetailsModal from './ForexTransferDetailsModal';

const PendingForexTransfers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTransferId, setSelectedTransferId] = useState<string | null>(
    null,
  );
  const [updateTransfersTrigger, updateTransfers] = useState<unknown>();

  const { response, loading } = useFetch(
    () =>
      pendingForexTransfersAPI.fetchPendingTransfers({
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [currentPage, updateTransfersTrigger],
  );

  const handleActionsChange = (key: string, transferId: string) => {
    if (key === 'view_details') {
      setSelectedTransferId(transferId);
    }
  };

  const handleModalClose = (wasUpdated?: boolean) => {
    setSelectedTransferId(null);

    if (wasUpdated) {
      updateTransfers({});
    }
  };

  return (
    <>
      <ForexTransfersTable
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={currentPage}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsChange}
      />

      <ForexTransferDetailsModal
        isVisible={!!selectedTransferId}
        closeCallback={handleModalClose}
        selectedTransferId={selectedTransferId as string}
      />
    </>
  );
};

export default PendingForexTransfers;
