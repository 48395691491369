import React from 'react';

// Helpers & Utils
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { SelectProps } from 'antd/lib/select';

// Components
import { Select } from 'antd';

interface IProps extends SelectProps<any> {
  items: string[];
}

const ArrayOfNumberField = ({ items, ...rest }: IProps) => {
  const { t } = useTranslation('compliance');

  const renderOptions = () => {
    return items.map((el) => (
      <Select.Option key={el} value={el}>
        {el}
      </Select.Option>
    ));
  };

  return (
    <Select
      {...rest}
      showArrow
      placeholder={t('review_configuration.boolean_ph')}
    >
      {renderOptions()}
    </Select>
  );
};

export default ArrayOfNumberField;
