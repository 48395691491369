import React from 'react';

// helpers
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeView } from '../../../redux/actions/view';

// components
import { Typography } from 'antd';

interface IProps {
  to: any;
  children: any;
}

const Link = ({ to, children }: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Typography.Link
      onClick={() => {
        dispatch(changeView(() => history.push(to)));
      }}
    >
      {children}
    </Typography.Link>
  );
};

export default Link;
