import React from 'react';

// components
import PendingTransactionsTable from 'modules/Banking/PendingTransactionsTable';

const PendingTransactionsPage = () => {
  return <PendingTransactionsTable />;
};

export default PendingTransactionsPage;
