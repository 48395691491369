import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ClientAccountManagementApprovalStatuses } from 'typings/approval/approvalWorkflow';

// components
import StatusLabel from 'components/Typography/StatusLabel';

const STATUS_MAP: any = {
  info: [ClientAccountManagementApprovalStatuses.All],
  warning: [
    ClientAccountManagementApprovalStatuses.PendingApproval,
    ClientAccountManagementApprovalStatuses.Expired,
  ],
  success: [ClientAccountManagementApprovalStatuses.Approved],
  error: [ClientAccountManagementApprovalStatuses.Rejected],
};

interface IProps {
  statusId: ClientAccountManagementApprovalStatuses;
}

const ApprovalEntryStatus = ({ statusId }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={statusId}
      text={t(
        `client_group.administration_rules.pending_approvals.approval_statuses.${statusId}`,
      )}
    />
  );
};

export default ApprovalEntryStatus;
