import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';

// component
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel;

export type GeneralLedgerReportEntry = {
  valueDate: string;
  postingDate: string;
  description: string;
  debit: string;
  credit: string;
  type: string;
};

const TransactionsTable = memo((props: IProps) => {
  const { t } = useTranslation('reporting');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        key: 'value_date',
        title: t('general_ledger.transactions.value_date'),
        render: (record: GeneralLedgerReportEntry) => record.valueDate,
      },

      {
        key: 'posting_date',
        title: t('general_ledger.transactions.posting_date'),
        render: (record: GeneralLedgerReportEntry) => record.postingDate,
      },

      {
        width: 320,
        key: 'description',
        title: t('general_ledger.transactions.description'),
        render: (record: GeneralLedgerReportEntry) => (
          <EllipsisTooltip
            maxTextContainerWidth="320px"
            title={record.description}
          >
            {record.description}
          </EllipsisTooltip>
        ),
      },

      {
        key: 'debit_amount',
        title: t('general_ledger.transactions.debit_amount'),
        render: (record: GeneralLedgerReportEntry) => record.debit,
      },

      {
        key: 'credit_amount',
        title: t('general_ledger.transactions.credit_amount'),
        render: (record: GeneralLedgerReportEntry) => record.credit,
      },

      {
        key: 'type',
        title: t('general_ledger.transactions.type'),
        render: (record: GeneralLedgerReportEntry) => record.type,
      },
    ];

    return result;
  }, [t]);

  return <Table columns={columns} {...props} />;
});

export default TransactionsTable;
