import React from 'react';

// helpers
import useUserAccess from '../../../../hooks/useUserAccess';
import useTranslation from '../../../../hooks/useTranslation';
import { ObjectBaseModel } from '../../../../typings/ORAC/objects';

// components
import Tabs from '../../../../components/Tabs';
import ObjectInfo from './ObjectInfo';

import { CardWrapper } from '../../../../layouts/PageLayout';
import ObjectAttributes from './ObjectAttributes';
import ObjectActions from './ObjectActions';

interface IProps {
  object: ObjectBaseModel;
}

const ObjectDetails = ({ object }: IProps) => {
  const { t } = useTranslation('orac');
  const [hasActionsAccess] = useUserAccess([
    'ORAC_Objects_Details_Actions_View',
  ]);

  const tabs = [
    {
      key: 'attributes',
      title: t('objects.view.attributes'),
      content: <ObjectAttributes objectId={object.id} />,
    },
    {
      key: 'actions',
      title: t('objects.view.actions'),
      hidden: !hasActionsAccess,
      content: <ObjectActions objectId={object.id} />,
    },
  ];

  return (
    <>
      <CardWrapper>
        <ObjectInfo object={object} />
      </CardWrapper>

      <CardWrapper>
        <Tabs tabs={tabs} />
      </CardWrapper>
    </>
  );
};

export default ObjectDetails;
