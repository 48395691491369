// COMPLIANCE
export const GENESIS_ONGOING_REVIEWS_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/compliance/ongoing-reviews/';
export const GENESIS_COMPLIANCE_TRANSACTIONS_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/compliance/transactions/';
export const GENESIS_REVIEW_CONFIGURATION_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/compliance/review-configuration/';
export const GENESIS_PROPERTY_CONFIGURATION_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/compliance/property-configuration/';
export const GENESIS_COUNTRY_RISK_RATINGS_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/compliance/country-risk-ratings/';

// CRM
export const GENESIS_ENTITIES_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/crm/entities/';
export const GENESIS_CONTACTS_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/crm/contacts/';
export const GENESIS_INVITATIONS_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/crm/invitations/';
export const GENESIS_CLIENT_GROUPS_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/crm/client-groups/';
export const GENESIS_RELATIONSHIP_TEMPLATES_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/crm/relationship-templates/';
export const GENESIS_ENTITY_TYPE_CATEGORIES_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/crm/entity-type-category/';
export const GENESIS_DOCUMENTS_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/crm/documents/';

// APPROVAL MANAGEMENT
// TODO: Need to add
// 1) Approval Workflow
// 2) Templates
export const GENESIS_APPROVAL_MANAGEMENT_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/approval-management/';

// FINANCE
export const GENESIS_JOURNALS_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/finance/journals/';
export const GENESIS_FINANCE_ACCOUNTS_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/finance/accounts/';
export const GENESIS_CURRENCIES_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/finance/currencies/';
export const GENESIS_ACCOUNT_CATEGORIES_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/finance/account-category/';
export const GENESIS_FISCAL_PERIOD_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/finance/fiscal/';
export const GENESIS_CURRENCY_RATES_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/finance/currency-rates/';
export const GENESIS_FINANCIAL_TYPES_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/finance/financial-types/';

// ORAC
// TODO: Need to add
// 1) Users
// 2) Roles
// 3) Groups
// 4) Objects
// 5) Reset Authentication Requests
// 6) Notifications

// REPORTING
// TODO: Need to add
// 1) Balance sheet
// 2) Income Statement
// 3) Client Group & Balances
// 4) Client Group & Revenue
// 5) Exposure by Currency

// BANKING
export const GENESIS_BANKING_HOME_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/banking/';
export const GENESIS_BANK_ACCOUNTS_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/banking/bank-accounts/';
export const GENESIS_INTEREST_RATES_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/banking/interest/';
export const GENESIS_FOREX_TRANSFER_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/banking/forex-transfer/';
export const GENESIS_BANKING_TRANSACTIONS_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/banking/transactions/';

// AUTHENTICATION
export const GENESIS_AUTH_OVERVIEW_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/authentication/';
export const GENESIS_WEBAUTHN_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/authentication/webauthn/';
export const GENESIS_AUTH_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/authentication/auth-admin-flow/';

// MESSAGES
export const GENESIS_MESSAGES_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/messages/';

// ANNEX
export const GENESIS_ADDITIONAL_FIELDS_DOCUMENTATION_LINK =
  'https://genesis.tenet.wiki/tenetos/platform/admin-site/admin-features/crm/additional-fields/';
