import React, { useMemo } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';

// components
import ApprovalRulesTab from './ApprovalRulesTab';
import ApprovalGroupsTab from './ApprovalGroupsTab';
import Tabs, { TabModel } from 'components/Tabs';

interface IProps {
  clientGroupNumericId: number;
}

const PostOnboarding = ({ clientGroupNumericId }: IProps) => {
  const { t } = useTranslation('crm');

  const [hasApprovalGroupsAccess, hasApprovalRulesAccess] = useUserAccess([
    'CRM_ClientGroup_Details_TransactionRules_ApprovalGroups_View',
    'CRM_ClientGroup_Details_TransactionRules_ApprovalRules_View',
  ]);

  const tabs = useMemo<TabModel[]>(() => {
    return [
      {
        hidden: !hasApprovalGroupsAccess,
        key: 'approval-groups',
        title: t('client_group.tabs.approval_groups'),
        content: <ApprovalGroupsTab clientGroupId={clientGroupNumericId} />,
      },

      {
        hidden: !hasApprovalRulesAccess,
        key: 'approval-rules',
        title: t('client_group.tabs.approval_rules'),
        content: <ApprovalRulesTab clientGroupId={clientGroupNumericId} />,
      },
    ];
  }, [
    t,
    clientGroupNumericId,
    hasApprovalGroupsAccess,
    hasApprovalRulesAccess,
  ]);

  return <Tabs tabs={tabs} renderOnChange />;
};

export default PostOnboarding;
