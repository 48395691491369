import { SubRouteModel } from '../../../typings/routes';

// Layouts
import PageLayout from '../../../layouts/PageLayout';

import Navigation from '../../../pages/Navigation';

// Pages
import Users from '../../../pages/ORAC/Users/Users';
import UserDetails from '../../../pages/ORAC/Users/UserDetails';

import Roles from '../../../pages/ORAC/Roles/Roles';
import RoleDetails from '../../../pages/ORAC/Roles/RoleDetails';

import Groups from '../../../pages/ORAC/Groups/Groups';
import GroupDetails from '../../../pages/ORAC/Groups/GroupDetails';

import Objects from '../../../pages/ORAC/Objects/Objects';
import ObjectDetails from '../../../pages/ORAC/Objects/ObjectDetails';

import AccountRecoveryRequests from '../../../pages/ORAC/AccountRecoveryRequests/AccountRecoveryRequests';
import AccountRecoveryRequestDetails from '../../../modules/ORAC/AccountRecoveryRequests/AccountRecoveryRequestDetails';
import Notifications from '../../../pages/ORAC/Notifications/Notifications';
import NotificationsDetails from '../../../pages/ORAC/Notifications/NotificationsDetails';
import ForgotUsernameRequestsPage from 'pages/ORAC/ForgotUsernameRequests/ForgotUsernameRequestsPage';
import ForgotUsernameRequestDetailsPage from 'pages/ORAC/ForgotUsernameRequests/ForgotUsernameRequestDetailsPage';

enum ORACRoutesEnum {
  ORAC_Root = '/orac',
  ORAC_Users = '/orac/users',
  ORAC_User_Details = '/orac/users/user',
  ORAC_Roles = '/orac/roles',
  ORAC_Role_Details = '/orac/roles/role',
  ORAC_Groups = '/orac/groups',
  ORAC_Group_Details = '/orac/groups/group',
  ORAC_Objects = '/orac/objects',
  ORAC_Object_Details = '/orac/objects/object',
  ORAC_Notifications = '/orac/notifications',
  ORAC_NotificationsDetails = '/orac/notifications/notification',
  ORAC_AccountRecoveryRequests = '/orac/account-recovery-requests',
  ORAC_AccountRecoveryRequest_Details = '/orac/account-recovery-requests/details',
  ORAC_ForgotUsernameRequests = '/orac/forgot-username-requests',
  ORAC_ForgotUsernameRequests_Details = '/orac/forgot-username-requests/details',
}

const ORACRoutes: SubRouteModel[] = [
  {
    title: 'ORAC',
    path: ORACRoutesEnum.ORAC_Root,
    component: Navigation,
    layout: PageLayout,
    permission: { permissionKey: 'ORAC_View' },
    childRoutes: [
      {
        title: 'Users',
        path: ORACRoutesEnum.ORAC_Users,
        layout: PageLayout,
        component: Users,
        permission: { permissionKey: 'ORAC_Users_View' },
        childRoutes: [
          {
            hideFromNavigation: true,
            title: 'User',
            path: ORACRoutesEnum.ORAC_User_Details,
            layout: PageLayout,
            component: UserDetails,
            permission: { permissionKey: 'ORAC_Users_Details_View' },

            layoutProps: {
              goBackPath: ORACRoutesEnum.ORAC_Users,
              hideContentCard: true,
            },
          },
        ],
      },

      {
        title: 'Roles',
        path: ORACRoutesEnum.ORAC_Roles,
        layout: PageLayout,
        component: Roles,
        permission: { permissionKey: 'ORAC_Roles_View' },
        childRoutes: [
          {
            hideFromNavigation: true,
            title: 'Role',
            path: ORACRoutesEnum.ORAC_Role_Details,
            layout: PageLayout,
            permission: { permissionKey: 'ORAC_Roles_Details_View' },
            component: RoleDetails,

            layoutProps: {
              goBackPath: ORACRoutesEnum.ORAC_Roles,
              hideContentCard: true,
            },
          },
        ],
      },

      {
        title: 'Groups',
        path: ORACRoutesEnum.ORAC_Groups,
        layout: PageLayout,
        component: Groups,
        permission: { permissionKey: 'ORAC_Groups_View' },
        childRoutes: [
          {
            hideFromNavigation: true,
            title: 'Group',
            path: ORACRoutesEnum.ORAC_Group_Details,
            layout: PageLayout,
            component: GroupDetails,
            permission: { permissionKey: 'ORAC_Groups_Details_View' },

            layoutProps: {
              goBackPath: ORACRoutesEnum.ORAC_Groups,
              hideContentCard: true,
            },
          },
        ],
      },

      {
        title: 'Objects',
        path: ORACRoutesEnum.ORAC_Objects,
        layout: PageLayout,
        component: Objects,
        permission: { permissionKey: 'ORAC_Objects_View' },
        childRoutes: [
          {
            hideFromNavigation: true,
            title: 'Object',
            path: ORACRoutesEnum.ORAC_Object_Details,
            layout: PageLayout,
            component: ObjectDetails,
            permission: { permissionKey: 'ORAC_Objects_Details_View' },
            layoutProps: {
              goBackPath: ORACRoutesEnum.ORAC_Objects,
              hideContentCard: true,
            },
          },
        ],
      },
      {
        title: 'Notifications',
        path: ORACRoutesEnum.ORAC_Notifications,
        layout: PageLayout,
        component: Notifications,
        childRoutes: [
          {
            hideFromNavigation: true,
            title: 'Notification',
            path: ORACRoutesEnum.ORAC_NotificationsDetails,
            layout: PageLayout,
            component: NotificationsDetails,
            layoutProps: { goBackPath: ORACRoutesEnum.ORAC_Notifications },
          },
        ],
      },
      {
        title: 'Reset authentication requests',
        path: ORACRoutesEnum.ORAC_AccountRecoveryRequests,
        layout: PageLayout,
        component: AccountRecoveryRequests,
        childRoutes: [
          {
            hideFromNavigation: true,
            title: 'Reset authentication request details',
            path: ORACRoutesEnum.ORAC_AccountRecoveryRequest_Details,
            layout: PageLayout,
            component: AccountRecoveryRequestDetails,
            layoutProps: {
              goBackPath: ORACRoutesEnum.ORAC_AccountRecoveryRequests,
            },
          },
        ],
      },
      {
        title: 'Forgot username requests',
        path: ORACRoutesEnum.ORAC_ForgotUsernameRequests,
        layout: PageLayout,
        component: ForgotUsernameRequestsPage,
        permission: { permissionKey: 'ORAC_ForgotUsernameRequests_View' },
        childRoutes: [
          {
            hideFromNavigation: true,
            title: 'Forgot username requests details',
            path: ORACRoutesEnum.ORAC_ForgotUsernameRequests_Details,
            layout: PageLayout,
            component: ForgotUsernameRequestDetailsPage,
            permission: {
              permissionKey: 'ORAC_ForgotUsernameRequestDetails_View',
            },
            layoutProps: {
              goBackPath: ORACRoutesEnum.ORAC_ForgotUsernameRequests,
            },
          },
        ],
      },
    ],
  },
];

export { ORACRoutes, ORACRoutesEnum };
