import React, { useMemo } from 'react';

// helpers
import useUserAccess from '../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../routes/routes';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { TrialBalanceEntryModel } from '../../../../../api/reporting/reportingAPI';

// component
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

type IProps = RequiredPropsForTableModel<TrialBalanceEntryModel>;

const TrialBalanceCurrencyEntriesTable = ({ ...rest }: IProps) => {
  const { t } = useTranslation('reporting');
  const history = useHistory();

  const [hasAccountDetailsAccess, hasAccountCategoryDetailsAccess] =
    useUserAccess([
      'Finance_Accounts_Details_View',
      'Finance_AccountCategories_Details_View',
    ]);

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        key: 'accountNumber',
        ellipsis: true,
        render: ({ accountNumber }: TrialBalanceEntryModel) =>
          hasAccountDetailsAccess ? (
            <Typography.Link
              onClick={() =>
                history.push({
                  pathname: RoutePaths.Finance_Account_Details,
                  search: `?id=${accountNumber}`,
                })
              }
            >
              {accountNumber}
            </Typography.Link>
          ) : (
            accountNumber
          ),
        title: t('trial_balance.entries_table.account_number'),
      },
      {
        key: 'accountName',
        ellipsis: true,
        render: (record: TrialBalanceEntryModel) => record.accountName,
        title: t('trial_balance.entries_table.account_name'),
      },
      {
        key: 'accountCategoryNumber',
        ellipsis: true,
        render: ({ accountCategoryId }: TrialBalanceEntryModel) =>
          hasAccountCategoryDetailsAccess ? (
            <Typography.Link
              onClick={() =>
                history.push({
                  pathname: RoutePaths.Finance_AccountCategory_Details,
                  search: `?id=${accountCategoryId}`,
                })
              }
            >
              {accountCategoryId}
            </Typography.Link>
          ) : (
            accountCategoryId
          ),
        title: t('trial_balance.entries_table.account_category_number'),
      },
      {
        key: 'accountCategoryName',
        ellipsis: true,
        render: (record: TrialBalanceEntryModel) => record.accountCategoryName,
        title: t('trial_balance.entries_table.account_category_name'),
      },
      {
        key: 'type',
        ellipsis: true,
        render: (record: TrialBalanceEntryModel) => record.financialTypeName,
        title: t('trial_balance.entries_table.type'),
      },
      {
        key: 'debit',
        ellipsis: true,
        render: (record: TrialBalanceEntryModel) =>
          AccountsHelpers.convertAmountBigIntToLocaleString(record.debit),
        title: t('trial_balance.entries_table.debit'),
      },
      {
        key: 'credit',
        ellipsis: true,
        render: (record: TrialBalanceEntryModel) =>
          AccountsHelpers.convertAmountBigIntToLocaleString(record.credit),
        title: t('trial_balance.entries_table.credit'),
      },
      {
        key: 'balance',
        ellipsis: true,
        render: (record: TrialBalanceEntryModel) =>
          AccountsHelpers.convertAmountBigIntToLocaleString(record.balance),
        title: t('trial_balance.entries_table.balance'),
      },
    ];

    return result;
  }, [hasAccountDetailsAccess, hasAccountCategoryDetailsAccess]);

  return <Table columns={columns} {...rest} />;
};

export default TrialBalanceCurrencyEntriesTable;
