import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../../../hooks/useFetch';
import groupsAPI from '../../../../../../../api/orac/groupsAPI';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../../constants/global';

// components
import { default as TemplateRolesTable } from '../../../../../../../components/Tables/TableTemplates/ORAC/GroupsTable/RolesTable/index';

interface IProps {
  groupId: string;
  canSeeRolePermissions?: boolean;
}

const RolesTable = ({ groupId, canSeeRolePermissions = true }: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      groupsAPI.fetchRolesByGroupId(groupId, {
        page: currentPage,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [currentPage, groupId],
  );

  return (
    <TemplateRolesTable
      loading={loading}
      canSeeRolePermissions={canSeeRolePermissions}
      onPaginationChange={setCurrentPage}
      data={response?.data || []}
      total={response?.total || 0}
    />
  );
};

export default RolesTable;
