import React, { memo, useMemo } from 'react';

// helpers
import { styled } from 'styled-components';
import { useField } from 'formik';
import { COUNTRY_CODES } from 'constants/countryCodes';
import { useTranslation } from 'react-i18next';
import { ContactAddressType } from 'enums/crm/crm';

// components
import DocumentLink from 'components/Typography/DocumentLink';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

export type AddressItemFormValue = {
  id: string;
  type: ContactAddressType[];
  country: string;
  city: string;
  street: string;
  state: string;
  postal: string;
  documents: { name: string; documentId: string; fileId: string }[];
  isPrimary: boolean;
};

interface IProps {
  fieldName: string;
}

const AddressItem = memo(({ fieldName }: IProps) => {
  const { t } = useTranslation(['crm', 'common']);
  const [field] = useField<AddressItemFormValue>(fieldName);

  const descriptionData = useMemo<DescriptionItem[]>(() => {
    return [
      {
        label: t('entity.new.addressType'),
        description: field.value.type
          .map((type) => t(`contact_address_type.${type}`))
          .join(', '),
      },
      {
        label: t('entity.new.country'),
        description: field.value.country
          ? COUNTRY_CODES[field.value.country]
          : '',
      },
      {
        label: t('entity.new.city'),
        description: field.value.city,
      },
      {
        label: t('entity.new.street'),
        description: field.value.street,
      },
      {
        label: `${t('entity.new.state')} / ${t('entity.new.region')}`,
        description: field.value.state,
      },
      {
        label: t('entity.new.zipCode'),
        description: field.value.postal,
      },
      {
        hidden: !field.value.type.includes(ContactAddressType.PermanentAddress),
        label: t('contacts.new.documents'),
        description: (
          <div>
            {field.value.documents.length
              ? field.value.documents.map((e) => (
                  <div key={e.documentId}>
                    <DocumentLink
                      text={e.name}
                      fileName={e.name}
                      documentId={e.documentId}
                      fileId={e.fileId}
                    />
                  </div>
                ))
              : null}
          </div>
        ),
      },
      {
        label: t('contacts.new.isPrimary'),
        description: field.value.isPrimary
          ? t('yes', { ns: 'common' })
          : t('no', { ns: 'common' }),
      },
    ];
  }, [field]);

  return (
    <StyledDescriptionSection
      bordered={false}
      background="transparent"
      size="small"
      data={descriptionData}
    />
  );
});

const StyledDescriptionSection = styled(DescriptionSection)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default AddressItem;
