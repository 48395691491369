import React, { memo, useState } from 'react';

// helpers
import APIConfig from '../../../config/api';
import useTranslation from '../../../hooks/useTranslation';
import { Moment } from 'moment';
import { SessionHelpers } from '@helpers/sessions';
import { DocumentHelpers } from '@helpers/documents';
import { LocalStorageHelpers } from '@helpers/storages/localStorage';

// components
import { Button } from 'antd';

interface IProps {
  selectedFilterDate: Moment;
}

const ExportBalanceSheetToExcelButton = memo(
  ({ selectedFilterDate }: IProps) => {
    const { t } = useTranslation('crm');
    const [loader, setLoader] = useState(false);

    const onExportClick = async () => {
      setLoader(true);
      const token = LocalStorageHelpers.getAccessToken();
      const fingerprint = await SessionHelpers.generateFingerprint();
      const link = `${APIConfig.accountingApi}/report/balance-sheet/excel?toDate=${selectedFilterDate.format('X')}&tokenb64=${btoa(
        `Bearer ${token}`,
      )}&fingerprint=${fingerprint}`;
      await DocumentHelpers.downloadDocument('', '', '', link);
      setLoader(false);
    };

    return (
      <Button
        type="primary"
        loading={loader}
        onClick={onExportClick}
        disabled={!selectedFilterDate}
      >
        {t('documents.export_to_excel')}
      </Button>
    );
  },
);

export default ExportBalanceSheetToExcelButton;
