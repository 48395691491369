import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { DocumentAssociationTypes } from '../../../../../enums/crm/crm';

// components
import TagsSelect from '../../../../../components/Forms/FormComponents/SelectInputs/TagsSelect';
import AssociationTypeSelect from '../../../../../components/Forms/FormComponents/SelectInputs/AssociationTypeSelect';
import AssociationObjectAutocomplete from '../../../../../components/Forms/FormComponents/Autocompletes/CRM/AssociationObjectAutocomplete';
import { SearchInput } from 'components/Fields/SearchInput';
import { Col, Row, Typography } from 'antd';

interface IProps {
  onFilterChange: (type: string, value: any) => void;
  associationType: DocumentAssociationTypes;
}

const FilterBar = ({ onFilterChange, associationType }: IProps) => {
  const { t } = useTranslation('crm');

  const gridSizes = { xl: 6, lg: 8, md: 12, sm: 24, xs: 24 };

  return (
    <Row gutter={[16, 16]}>
      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('documents.filter.filter_by_tags')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <StyledTagsSelect
              mode="multiple"
              onChange={(e) => onFilterChange('filter_by_tags', e)}
              placeholder={t('documents.filter.search_by_tag_name')}
            />
          </Col>
        </Row>
      </Col>
      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('documents.filter.search_by_name')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <SearchInput
              size="large"
              placeholder={t('documents.filter.search_placeholder_name')}
              onSearch={(searchQuery: string) =>
                onFilterChange('filter_by_name', searchQuery)
              }
            />
          </Col>
        </Row>
      </Col>

      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('documents.filter.filter_by_association_type')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <StyledDocumentAssociationTypeSelect
              onChange={(e) => onFilterChange('filter_by_association_type', e)}
              placeholder={t(
                'documents.filter.filter_placeholder_association_type',
              )}
            />
          </Col>
        </Row>
      </Col>

      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('documents.filter.filter_by_association_id')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <StyledAssociationObjectAutocomplete
              type={associationType}
              placeholder={t('documents.filter.filter_placeholder_association')}
              onChange={(e: any) =>
                onFilterChange('filter_by_association_id', e)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledTagsSelect = styled(TagsSelect)`
  width: 100%;
  font-size: 16px;

  .ant-select-selector {
    padding: 3.3px 0;

    .ant-select-selection-item {
      margin: 3px 5px;
    }

    .ant-select-selection-search {
      margin-inline-start: 12px;
    }
  }
`;

const StyledDocumentAssociationTypeSelect = styled(AssociationTypeSelect)`
  width: 100%;

  .ant-select-selector {
    height: 40px !important;
    display: flex !important;
    align-items: center;
    font-size: 16px;
  }
`;

const StyledAssociationObjectAutocomplete = styled(
  AssociationObjectAutocomplete,
)`
  width: 100%;
`;

export default FilterBar;
