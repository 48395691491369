import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import {
  ObjectBaseModel,
  ObjectWithPermissionsModel,
} from '../../../../../typings/ORAC/objects';

// constants
import { RoutePaths } from '../../../../../routes/routes';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import TableActions, { ITableMenuItem } from '../../../TableActions';
import PermissionsMatrix from '../PermissionsMatrix';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<ObjectBaseModel> {
  onActionsClick?: (key: string, value: string) => void;
  actions?: ITableMenuItem[];

  // Set this to true - to be able to expand object rows and see permissions that this object has
  isExpandable?: boolean;

  // It will add popup with assign way info to each permission
  showAssignWayInfo?: boolean;

  // Needed for permissions matrix
  showForbiddenPermissions?: boolean;
  showForbiddenOnly?: boolean;
}

const ObjectsTable = memo(
  ({
    actions,
    onActionsClick,
    isExpandable,
    showForbiddenPermissions,
    showForbiddenOnly,
    showAssignWayInfo,
    ...rest
  }: IProps) => {
    const { t } = useTranslation('orac');

    const history = useHistory();

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          title: t('objects.table.name'),
          key: 'name',
          render: (record: ObjectBaseModel) => (
            <Typography.Link
              onClick={() =>
                history.push({
                  pathname: RoutePaths.ORAC_Object_Details,
                  search: `?id=${record.id}`,
                })
              }
            >
              {record.name}
            </Typography.Link>
          ),
        },

        {
          key: 'actions',
          hidden: !actions?.length,
          align: 'right',
          render: (record: ObjectBaseModel) =>
            actions && (
              <TableActions
                menu={actions}
                onSelect={(key) =>
                  onActionsClick && onActionsClick(key, record.id)
                }
              />
            ),
        },
      ];

      return result;
    }, [actions, onActionsClick]);

    const expandableRow = useMemo(
      () => ({
        rowExpandable: ({ actions = [] }: ObjectWithPermissionsModel) =>
          !!actions.length,
        expandedRowRender: ({ actions = [] }: ObjectWithPermissionsModel) => (
          <StyledPermissionsMatrix
            data={actions}
            showAssignWayInfo={showAssignWayInfo}
            showForbiddenColumn={showForbiddenPermissions}
            showForbiddenOnly={showForbiddenOnly}
          />
        ),
      }),
      [showAssignWayInfo, showForbiddenPermissions, showForbiddenOnly],
    );

    return (
      <Table
        {...rest}
        columns={columns}
        expandable={isExpandable ? expandableRow : undefined}
      />
    );
  },
);

const StyledPermissionsMatrix = styled(PermissionsMatrix)`
  .ant-table-cell {
    padding: 8px 16px !important;
  }
`;

export default ObjectsTable;
