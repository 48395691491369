import React from 'react';

// helpers
import styled from 'styled-components';
import withEnabledFido from '../hoc/withEnabledFido';
import { RouteLayoutPropsModel } from '../typings/routes';

// components
import Footer from '../modules/Layout/Footer';

type IProps = RouteLayoutPropsModel;

const VerificationLayout = ({ children }: IProps) => {
  return (
    <StyledContainer>
      {children}
      <StyledFooter />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledFooter = styled(Footer)`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export default withEnabledFido()(VerificationLayout);
