import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { TrialBalanceReportResponse } from '../../../../../api/reporting/reportingAPI';

// component
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

interface IProps
  extends RequiredPropsForTableModel<TrialBalanceReportResponse> {
  expandable: ExpandableConfig<any>;
}

const TrialBalanceCurrenciesTable = ({ ...rest }: IProps) => {
  const { t } = useTranslation('reporting');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        key: 'currencyIsoCode',
        ellipsis: true,
        render: (record: TrialBalanceReportResponse) => record.currencyIsoCode,
        width: 450,
        title: t('trial_balance.table.currency'),
      },
      {
        key: 'debitTotal',
        title: t('trial_balance.table.debit_total'),
        width: 300,
        render: (record: TrialBalanceReportResponse) =>
          AccountsHelpers.convertAmountBigIntToLocaleString(record.debitTotal),
      },
      {
        key: 'creditTotal',
        title: t('trial_balance.table.credit_total'),
        render: (record: TrialBalanceReportResponse) =>
          AccountsHelpers.convertAmountBigIntToLocaleString(record.creditTotal),
      },
    ];

    return result;
  }, [t]);

  return <Table columns={columns} {...rest} />;
};

export default TrialBalanceCurrenciesTable;
