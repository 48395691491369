import React from 'react';

// helpers
import useUserAccess from '../../../../hooks/useUserAccess';
import useTranslation from '../../../../hooks/useTranslation';
import { message } from 'antd';
import { relationshipTemplatesAPI } from '../../../../api/crm/relationship/relationshipTemplatesAPI';
import { CreateRelationshipTemplate } from '../../../../validations/crm/relationshipTemplate';
import { relationshipTemplatesAdapter } from '../../../../apiAdapters/crm/relationship/relationshipTemplatesAdapter';
import {
  IAdditionalField,
  IRelationshipTemplateModel,
} from '../../../../typings/crm/relationship-template';
import {
  AdditionalFieldType,
  RelationshipRelatedTypes,
} from '../../../../enums/crm/crm';

// components
import Form from '@core_components/Form';
import ConfirmDeleteButton from '../../../../components/Buttons/ConfirmDeleteButton';
import RelationshipTemplateForm from './RelationshipTemplateForm';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  initialValues?: IRelationshipTemplateModel;
}
export interface ICreateRelationshipTemplateFormBody {
  parentName: string;
  childName: string;
  parentType: RelationshipRelatedTypes;
  childType: RelationshipRelatedTypes;
  additionalFields: IAdditionalField[];
  individualAdditionalFields: IAdditionalField[];
}

const AddEditRelationshipTemplate = ({
  isVisible,
  closeCallback,
  initialValues,
}: IProps) => {
  const { t } = useTranslation('crm');

  const [hasDeleteAccess] = useUserAccess([
    'CRM_Configuration_RelationshipTemplates_Delete',
  ]);

  const handleSaveClick = (values: ICreateRelationshipTemplateFormBody) =>
    initialValues
      ? updateRelationship(initialValues._id, values)
      : createRelationship(values);

  const createRelationship = async (
    values: ICreateRelationshipTemplateFormBody,
  ) => {
    await relationshipTemplatesAPI.createRelationshipTemplate(
      relationshipTemplatesAdapter.generateCreateRelationshipTemplateBody(
        values,
      ),
    );
    message.success(t('relationship_templates.create_template_success'));
  };

  const updateRelationship = async (
    id: string,
    values: ICreateRelationshipTemplateFormBody,
  ) => {
    await relationshipTemplatesAPI.editRelationshipTemplate(
      id,
      relationshipTemplatesAdapter.generateUpdateRelationshipTemplateBody(
        values,
      ),
    );
    message.success(t('relationship_templates.update_template_success'));
  };

  const getInitialData = () => {
    if (initialValues) {
      [
        ...initialValues.additionalFields,
        ...initialValues.individualAdditionalFields,
      ]
        .filter((av) => av.type === AdditionalFieldType.Select)
        .forEach((av) => {
          av.options.selectOptionsValues =
            av.options.selectOptions?.map((o) => JSON.parse(o.valueJSON)) || [];
        });
      return {
        parentName: initialValues.parentName,
        parentType: initialValues.parentType,
        childName: initialValues.childName,
        childType: initialValues.childType,
        additionalFields: mappedAdditionalFields(
          initialValues.additionalFields,
        ),
        individualAdditionalFields: mappedAdditionalFields(
          initialValues.individualAdditionalFields,
        ),
      };
    }

    return {
      parentName: '',
      parentType: RelationshipRelatedTypes.Any,
      childName: '',
      childType: RelationshipRelatedTypes.Any,
      additionalFields: [],
      individualAdditionalFields: [],
    };
  };

  const getFormTitle = () =>
    initialValues
      ? t('relationship_templates.update_title')
      : t('relationship_templates.create_title');

  const deleteRelationshipTemplate = async (reason = '') => {
    await relationshipTemplatesAPI.deleteRelationshipTemplate(
      initialValues?._id || '',
      reason,
    );
    message.success(t('relationship_templates.delete_template_success'));
    closeCallback(true);
  };

  function mappedAdditionalFields(additionalFields: IAdditionalField[]) {
    return (
      additionalFields.map((additionalField) => {
        if (additionalField.type === AdditionalFieldType.Select) {
          return {
            ...additionalField,
            options: {
              ...additionalField.options,
              selectOptionsValues:
                additionalField.options.selectOptions?.map((o) =>
                  JSON.parse(o.valueJSON),
                ) || [],
            },
          };
        } else if (additionalField.type === AdditionalFieldType.Form) {
          return {
            ...additionalField,
            options: {
              ...additionalField.options,
              formDocument: [
                {
                  name: additionalField.options.formDocument?.files[0]?.name,
                  id: additionalField.options.formDocument?.id,
                },
              ],
            },
          };
        } else {
          return additionalField;
        }
      }) || []
    );
  }

  return (
    <Form<ICreateRelationshipTemplateFormBody>
      enableReinitialize
      onSubmit={handleSaveClick}
      initialValues={getInitialData()}
      validationSchema={CreateRelationshipTemplate}
      confirmExitWithoutSaving
      renderForm={
        <ModalDialog
          width="80%"
          title={getFormTitle()}
          isVisible={isVisible}
          closeCallback={closeCallback}
          extendedFooterButtonsPosition="before"
          extendedFooterButtons={
            !!initialValues &&
            hasDeleteAccess && (
              <ConfirmDeleteButton
                shouldAskReason
                btnSize="large"
                deleteCallback={deleteRelationshipTemplate}
              />
            )
          }
        >
          <RelationshipTemplateForm />
        </ModalDialog>
      }
    />
  );
};

export default AddEditRelationshipTemplate;
