import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { ReviewProcessStatuses } from '../../../../../../enums/compliance/reviewConfiguration';
import { useReviewProcessContext } from '../../../../../../hooks/domainHooks/useReviewProcess';

// components
import StatusLabel from '../../../../../../components/Typography/StatusLabel';
import { Col, Row, Typography } from 'antd';

const STATUS_MAP = {
  info: [ReviewProcessStatuses.New],
  warning: [
    ReviewProcessStatuses.InProgress,
    ReviewProcessStatuses.PendingApproval,
  ],
  success: [ReviewProcessStatuses.Approved],
  error: [ReviewProcessStatuses.Rejected],
};

const StatusSection = () => {
  const { t } = useTranslation('compliance');
  const { reviewProcessStatus } = useReviewProcessContext();

  return (
    <Row>
      <Col span={8}>
        <StyledTitleText>{t('transactions.review_status')}</StyledTitleText>
      </Col>
      <Col>
        <StatusLabel
          statusMap={STATUS_MAP}
          status={reviewProcessStatus}
          text={t(
            `review_process_statuses.${ReviewProcessStatuses[reviewProcessStatus]}`,
            '',
          )}
        />
      </Col>
    </Row>
  );
};

const StyledTitleText = styled(Typography.Text)`
  color: ${({ theme }) => theme.whiteColor};
  font-weight: 600;
`;

export default StatusSection;
