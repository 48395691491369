import { FormValuesModel } from 'components/Modals/TemplateModalDialogs/Finance/BankAccountModal';
import { NewCorrespondentBankAccountModel } from '../../../api/banking/correspondentBanksAPI';

const correspondentBanksAdapter = {
  createCorrespondentBankAccount: (
    formValues: FormValuesModel,
  ): NewCorrespondentBankAccountModel => {
    return {
      accountName: formValues.accountName,
      accountNumberName: String(formValues.accountShortName),
      currencyId: Number(formValues.currency),
      accountCategoryId: Number(formValues.accountCategory),
      correspondentBankId: Number(formValues.correspondentBank),
    };
  },
};

export { correspondentBanksAdapter };
