import React, { useMemo } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import { IClientGroup } from 'typings/crm/client-group';
import { relationshipAPI } from 'api/crm/relationship/relationshipAPI';
import { ClientApprovalEntryModel } from 'apiAdapters/approval/clientAccountManagementRulesAdapterAPI';
import {
  AccountUserPermissionTypes,
  AdministrationPermissionTypes,
  ClientReviewStatus,
  ClientStatus,
} from 'enums/crm/crm';

// components
import InnerTree from './InnerTree';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import UserChangesSection from './UserChangesSection';
import { Divider } from 'antd';

export interface ClientGroupTemplateDataModel {
  clientGroupId: string;
  relationshipScopeId: string;
  approvedRelationshipScopeId: string;
  clientGroupUserChanges: ClientGroupUserChangeFromQuery[];
}

export type ClientGroupUserChangeFromQuery = {
  clientGroupUserId: string;
  clientGroupUserName: string;
  newPermissions: {
    adminPermissionType: AdministrationPermissionTypes;
    type: AccountUserPermissionTypes;
  } | null;
  oldPermissions: {
    adminPermissionType: AdministrationPermissionTypes;
    type: AccountUserPermissionTypes;
  } | null;
};

interface IProps {
  clientGroup: IClientGroup;
  approvalEntry: ClientApprovalEntryModel<ClientGroupTemplateDataModel>;
}

const ClientGroup = ({ approvalEntry, clientGroup }: IProps) => {
  const scopeId = useMemo(() => {
    const { status, reviewStatus, directClient } = clientGroup;

    if (
      status === ClientStatus.Approved &&
      reviewStatus === ClientReviewStatus.Reviewed
    ) {
      return directClient.relationshipScopes?.approved;
    }

    return directClient.relationshipScopes?.active;
  }, [clientGroup]);

  const { response, loading } = useFetch(
    () =>
      relationshipAPI.getRelationshipTree(
        clientGroup.directClientItemId,
        scopeId,
      ),
    [scopeId],
  );

  return (
    <LoadingWrapper loading={loading}>
      {response && (
        <>
          <UserChangesSection
            approvalEntry={
              approvalEntry.changes.new as ClientGroupTemplateDataModel
            }
          />
          <Divider />
          <InnerTree graph={response} />
        </>
      )}
    </LoadingWrapper>
  );
};

export default ClientGroup;
