import {
  ComparisonOperators,
  ExpectedDataTypes,
} from '../../enums/compliance/reviewConfiguration';

export const ComparisonOpertatorsByDataType = {
  [ExpectedDataTypes.Boolean]: [
    ComparisonOperators.Equal,
    ComparisonOperators.NotEqual,
  ],
  [ExpectedDataTypes.ArrayOfString]: [
    ComparisonOperators.In,
    ComparisonOperators.NotIn,
    ComparisonOperators.IsAnyIn,
  ],
  [ExpectedDataTypes.CountryRiskLevel]: [
    ComparisonOperators.In,
    ComparisonOperators.NotIn,
    ComparisonOperators.IsAnyIn,
  ],
  [ExpectedDataTypes.ArrayOfNumber]: [
    ComparisonOperators.In,
    ComparisonOperators.NotIn,
    ComparisonOperators.IsAnyIn,
  ],
  [ExpectedDataTypes.String]: [
    ComparisonOperators.Equal,
    ComparisonOperators.NotEqual,
    ComparisonOperators.In,
    ComparisonOperators.NotIn,
  ],
  [ExpectedDataTypes.Number]: [
    ComparisonOperators.Equal,
    ComparisonOperators.NotEqual,
    ComparisonOperators.In,
    ComparisonOperators.NotIn,
    ComparisonOperators.Less,
    ComparisonOperators.LessOrEqual,
    ComparisonOperators.More,
    ComparisonOperators.MoreOrEqual,
  ],
  [ExpectedDataTypes.Date]: [
    ComparisonOperators.Equal,
    ComparisonOperators.NotEqual,
    ComparisonOperators.Less,
    ComparisonOperators.LessOrEqual,
    ComparisonOperators.More,
    ComparisonOperators.MoreOrEqual,
  ],
};
