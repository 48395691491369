import React from 'react';

// helpers
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// components
import { Typography } from 'antd';
import { ReactComponent as KeyIcon } from '../../../../../resources/images/icons/key.svg';

const BrowserIsNotSupported = () => {
  const { t } = useTranslation('profile');

  return (
    <>
      <StyledKeyIcon />
      <StyledParagraph>
        {t(
          'sign_in_and_security.two_factor_verification.security_key_option.browser_is_not_supported.title',
        )}
      </StyledParagraph>
      <StyledParagraph>
        {t(
          'sign_in_and_security.two_factor_verification.security_key_option.browser_is_not_supported.description',
        )}
      </StyledParagraph>
    </>
  );
};

const StyledKeyIcon = styled(KeyIcon)`
  min-height: 100px;
  max-height: 100px;
  margin-bottom: ${({ theme }) => theme.marginXl};
`;

const StyledParagraph = styled(Typography.Paragraph)`
  text-align: center;
`;

export default BrowserIsNotSupported;
