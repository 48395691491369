import i18n from 'i18n';
import { DateHelpers } from '@helpers/date';
import { COUNTRY_CODES } from 'constants/countryCodes';
import { AccountsHelpers } from '../../helpers/finance/accounts';
import { GeneralLedgerItem } from 'components/Tables/TableTemplates/Reporting/GeneralLedgerTable';
import { FetchResponseModel } from '../../typings/common';
import { ReportElementModel } from '../../typings/reporting/reporting';
import { EntryChangesLogReportItem } from 'components/Tables/TableTemplates/Reporting/EntryChangesLogTable';
import { GeneralLedgerReportEntry } from 'components/Tables/TableTemplates/Reporting/TransactionsTable';
import {
  GeneralLedgerReportItemFromQuery,
  GeneralLedgerReportEntryFromQuery,
  FetchIncomeStatementReportResponse,
  CashFlowReportEntry,
  EntryChangesLogReportItemFromQuery,
} from '../../api/reporting/reportingAPI';

export interface BalanceSheetReportRequestModel {
  id: number | string;
  name: string;
  sum: number;
  sumResident: number;
  sumNonResident: number;
  children: BalanceSheetReportRequestModel[];
}

export interface ClientBalanceSheetReportRequestModel {
  clientId: number | string;
  clientName: string;
  sum: number;
  sumResident: number;
  sumNonResident: number;
}
export interface ClientRevenueDetailsReportModel {
  feeTypeName: string;
  sum: number;
  sumResident: number;
  sumNonResident: number;
}

export interface ClientRevenueSheetReportRequestModel {
  clientId: number | string;
  name: string;
  sum: number;
  sumResident: number;
  sumNonResident: number;
  details: ClientRevenueDetailsReportModel[];
}

export interface CashFlowDataReportModel {
  name: string;
  countryEntries: string[];
  totalValues: string[];
  totalTransactions: number[];
}

export interface CashFlowTotalDataReportModel {
  name: string;
  value: number | string;
}

export interface CashFlowReportModel {
  totalReportData: CashFlowTotalDataReportModel[];
  cashFlowReportData: CashFlowDataReportModel[];
}

const reportingAPIAdapter = {
  fetchBalanceSheetReport: (
    data: FetchResponseModel<BalanceSheetReportRequestModel>,
  ): FetchResponseModel<ReportElementModel> => {
    const result: FetchResponseModel<ReportElementModel> = {
      total: data.total,
      data: [],
    };
    result.data = data.data.map(formatItem);
    return result;
  },

  fetchIncomeStatementReport: (
    data: FetchIncomeStatementReportResponse,
  ): FetchResponseModel<ReportElementModel> => {
    const result: FetchResponseModel<ReportElementModel> = {
      total: data.total,
      data: [],
    };
    result.data = data.data.map(formatItem);

    result.data.push(
      formatItem({
        id: 'net-income',
        name: 'Net Income',
        sum: data.netProfit,
        sumResident: data.netProfitResident,
        sumNonResident: data.netProfitNonResident,
        children: [],
      }),
    );
    return result;
  },

  fetchClientGroupBalancesReport: (
    data: FetchResponseModel<ClientBalanceSheetReportRequestModel>,
  ): FetchResponseModel<ReportElementModel> => {
    const result: FetchResponseModel<ReportElementModel> = {
      total: data.total,
      data: [],
    };
    result.data = data.data.map((m: ClientBalanceSheetReportRequestModel) => ({
      id: m.clientId,
      name: m.clientName,
      sum: `$${AccountsHelpers.formatMoneyToStringWithRounding(m.sum)}`,
      sumResident: `$${AccountsHelpers.formatMoneyToStringWithRounding(m.sumResident)}`,
      sumNonResident: `$${AccountsHelpers.formatMoneyToStringWithRounding(m.sumNonResident)}`,
      children: [],
    }));
    return result;
  },

  fetchClientRevenueReport: (
    data: FetchResponseModel<ClientRevenueSheetReportRequestModel>,
  ): FetchResponseModel<ReportElementModel> => {
    const result: FetchResponseModel<ReportElementModel> = {
      total: data.total,
      data: [],
    };
    result.data = data.data.map((m: ClientRevenueSheetReportRequestModel) => ({
      id: m.clientId,
      name: m.name,
      sum: `$${AccountsHelpers.formatMoneyToStringWithRounding(m.sum)}`,
      sumResident: `$${AccountsHelpers.formatMoneyToStringWithRounding(m.sumResident)}`,
      sumNonResident: `$${AccountsHelpers.formatMoneyToStringWithRounding(m.sumNonResident)}`,
      children: m.details.map(
        (m: ClientRevenueDetailsReportModel, index: number) => ({
          id: index,
          children: [],
          name: m.feeTypeName,
          sum: AccountsHelpers.formatMoneyToStringWithRounding(m.sum),
          sumResident: AccountsHelpers.formatMoneyToStringWithRounding(
            m.sumResident,
          ),
          sumNonResident: AccountsHelpers.formatMoneyToStringWithRounding(
            m.sumNonResident,
          ),
        }),
      ),
    }));
    return result;
  },

  fetchGeneralLedgerReport: (
    data: FetchResponseModel<GeneralLedgerReportItemFromQuery>,
  ): FetchResponseModel<GeneralLedgerItem> => {
    const result: FetchResponseModel<GeneralLedgerItem> = {
      total: data.total,
      data: [],
    };

    result.data = data.data.map((e) => ({
      accountName: e.accountName,
      accountNumber: e.accountNumber,
      financialType: e.financialTypeName,
      accountCategoryCode: String(e.accountCategoryId),
      debitAmount: AccountsHelpers.convertAmountBigIntToLocaleString(
        e.debitAmount,
      ),
      creditAmount: AccountsHelpers.convertAmountBigIntToLocaleString(
        e.creditAmount,
      ),
      netMovement: AccountsHelpers.convertAmountBigIntToLocaleString(
        e.netMovement,
      ),
    }));

    return result;
  },

  fetchEntriesOfGeneralLedgerReport: (
    data: FetchResponseModel<GeneralLedgerReportEntryFromQuery>,
  ): FetchResponseModel<GeneralLedgerReportEntry> => {
    const result: FetchResponseModel<GeneralLedgerReportEntry> = {
      total: data.total,
      data: [],
    };

    result.data = data.data.map((e) => ({
      valueDate: DateHelpers.formatTimestampToString(e.valueDate),
      postingDate: DateHelpers.formatTimestampToString(e.postingDate),
      description: e.description,
      debit: AccountsHelpers.convertAmountBigIntToLocaleString(e.debitAmount),
      credit: AccountsHelpers.convertAmountBigIntToLocaleString(e.creditAmount),
      type: e.journalEntryTypeName,
    }));

    return result;
  },

  fetchEntriesOfCashFlowReport: (
    data: CashFlowReportEntry,
  ): CashFlowReportModel => {
    return {
      totalReportData: [
        {
          name: i18n.t('cash_flow.total_table.total_clients'),
          value: data.header.totalClients,
        },
        {
          name: i18n.t('cash_flow.total_table.total_transactions'),
          value: data.header.totalTransactions,
        },
        {
          name: i18n.t('cash_flow.total_table.total_assets'),
          value: `$${AccountsHelpers.convertAmountBigIntToLocaleString(data.header.totalValue)}`,
        },
      ],
      cashFlowReportData: [
        {
          name: i18n.t('cash_flow.table.incoming_total_funds'),
          countryEntries: data.data.incoming.countryEntries.map(
            (x) => COUNTRY_CODES[x.countryCode],
          ),
          totalValues: data.data.incoming.countryEntries.map(
            (x) =>
              `$${AccountsHelpers.convertAmountBigIntToLocaleString(x.totalValue)}`,
          ),
          totalTransactions: data.data.incoming.countryEntries.map(
            (x) => x.totalTransactions,
          ),
        },
        {
          name: i18n.t('cash_flow.table.incoming_total_transactions'),
          countryEntries: data.data.incoming.bankCodeTypeEntries.map(
            (x) => x.bankCodeType,
          ),
          totalValues: [],
          totalTransactions: data.data.incoming.bankCodeTypeEntries.map(
            (x) => x.totalTransactions,
          ),
        },
        {
          name: i18n.t('cash_flow.table.outgoing_total_funds'),
          countryEntries: data.data.outgoing.countryEntries.map(
            (x) => COUNTRY_CODES[x.countryCode],
          ),
          totalValues: data.data.outgoing.countryEntries.map(
            (x) =>
              `$${AccountsHelpers.convertAmountBigIntToLocaleString(x.totalValue)}`,
          ),
          totalTransactions: data.data.outgoing.countryEntries.map(
            (x) => x.totalTransactions,
          ),
        },
        {
          name: i18n.t('cash_flow.table.outgoing_total_transactions'),
          countryEntries: data.data.outgoing.bankCodeTypeEntries.map(
            (x) => x.bankCodeType,
          ),
          totalValues: [],
          totalTransactions: data.data.outgoing.bankCodeTypeEntries.map(
            (x) => x.totalTransactions,
          ),
        },
      ],
    };
  },

  formatFetchEntryChangesLogReportToList: (
    data: FetchResponseModel<EntryChangesLogReportItemFromQuery>,
  ): FetchResponseModel<EntryChangesLogReportItem> => {
    const result: FetchResponseModel<EntryChangesLogReportItem> = {
      total: data.total,
      data: [],
    };

    result.data = data.data.map((e) => ({
      id: e._id,
      changedDate: e._audit.createdAt,
      user: e.user,
      changedEntry: {
        id: e.entity.id,
        name: e.entity.name,
        type: e.entity.type,
      },
    }));

    return result;
  },
};

const formatItem = (
  item: BalanceSheetReportRequestModel,
): ReportElementModel => {
  const hasChildren = !!item.children.length;
  let children: ReportElementModel[] = [];

  if (hasChildren && item.children.length) {
    children = item.children.map(formatItem);
  }

  const formattedMoney = AccountsHelpers.formatMoneyToStringWithRounding(
    item.sum,
  );
  const formattedMoneyForResident =
    AccountsHelpers.formatMoneyToStringWithRounding(item.sumResident);
  const formattedMoneyForNonResident =
    AccountsHelpers.formatMoneyToStringWithRounding(item.sumNonResident);
  return {
    ...item,
    children,
    sum: hasChildren ? `$${formattedMoney}` : formattedMoney,
    sumResident: hasChildren
      ? `$${formattedMoneyForResident}`
      : formattedMoneyForResident,
    sumNonResident: hasChildren
      ? `$${formattedMoneyForNonResident}`
      : formattedMoneyForNonResident,
  };
};

export default reportingAPIAdapter;
