import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { styled } from 'styled-components';

// components
import Text from '@core_components/Text';
import HeaderCell from './HeaderCell';
import ContentCell from './ContentCell';
import { Table } from 'antd';

export type ApprovalRule = {
  conditions: {
    conditions: {
      approvalGroup: {
        name: string;
        participants: string[];
      } | null;
      requiredApproversCount: number;
    }[];

    limitStatuses: { isChecked: boolean }[];
  }[];
  limits: {
    toInfinite: boolean;
    from: number;
    to?: number;
  }[];
};

interface IProps {
  approvalRule: ApprovalRule;
}

const TransactionApprovalRulesMatrix = ({ approvalRule }: IProps) => {
  const { t } = useTranslation('crm');

  const formattedTableData = useMemo<{
    approvalRule: ApprovalRule;
    remainedLimits: number;
  }>(() => {
    return {
      approvalRule: {
        ...approvalRule,
        limits: approvalRule.limits.slice(0, 3),
      },
      remainedLimits:
        approvalRule.limits.length > 3 ? approvalRule.limits.length - 3 : 0,
    };
  }, [approvalRule]);

  const columns = useMemo(() => {
    let result: any[] = [
      {
        key: 'conditions',
        dataIndex: 'condition',
        width: 300,
      },
    ];

    const numberOfLimitColumns =
      formattedTableData.approvalRule.limits.length || 0;

    const limitColumns = Array.from(Array(numberOfLimitColumns), (_, i) => ({
      key: `limit-${i}`,
      width: 250,
      dataIndex: 'limit',
    }));

    result = [...result, ...limitColumns];

    result = result.map((col, index: number) => {
      const baseProps = {
        colIndex: index > 0 ? index - 1 : 0,
        dataIndex: col.dataIndex,
      };

      return {
        ...col,
        onHeaderCell: () => ({
          ...baseProps,
          record: formattedTableData.approvalRule.limits[index - 1],
        }),
        onCell: (record: ApprovalRule['conditions'], rowIndex: number) => ({
          ...baseProps,
          record,
          isLastRow:
            rowIndex === formattedTableData.approvalRule.conditions.length - 1,
        }),
      };
    });

    return result;
  }, [formattedTableData]);

  return (
    <TableWrapper>
      <StyledTable
        bordered
        className="disable-table-row-hover"
        size="small"
        dataSource={formattedTableData.approvalRule.conditions}
        columns={columns}
        pagination={false}
        components={{
          header: { cell: HeaderCell },
          body: { cell: ContentCell },
        }}
      />
      {formattedTableData.remainedLimits ? (
        <StyledTableHelperText variant="body1">
          {`+ ${formattedTableData.remainedLimits} ${t(
            formattedTableData.remainedLimits > 1
              ? 'crm_approval.transaction_approval_rules.approval_rule_matrix.more_ranges'
              : 'crm_approval.transaction_approval_rules.approval_rule_matrix.more_range',
          )}`}
        </StyledTableHelperText>
      ) : null}
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTableHelperText = styled(Text)`
  padding-left: 20px;
  padding-top: 20px;
`;

const StyledTable = styled(Table)`
  display: flex;
  overflow: auto;

  .ant-table {
    margin: 0 !important;
    border-bottom: ${({ theme }) => `1px solid ${theme.colorDarkL4}`};

    table {
      width: auto;
    }
  }
`;

export default TransactionApprovalRulesMatrix;
