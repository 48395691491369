import React from 'react';

// Helpers & Utils
import { documentsAPI } from '../../../api/documents/documentsAPI';
import { UploadStatus } from '../../../constants/documents';
import { DocumentHelpers, IChunkHash } from '../../../helpers/documents';
import {
  IDocumentBase,
  UploadDocumentAssociationModel,
} from '../../../typings/documents/documents';
import {
  IFileHash,
  setUploadStatus,
  setUploadFinishStatus,
  setUploadFileStatus,
  setFileChunks,
  setChunkStatus,
  updateChunkPercentage,
  setFileDataBaseId,
  setDataBaseFile,
} from '../../../redux/actions/upload';

// Redux
import { connect } from 'react-redux';

// ** components **
import UploadDocumentNotification from './UploadDocumentNotification';

interface IProps {
  uploadStatus: UploadStatus;
  files: IFileHash;
  shouldShowUploadNotification: boolean;
  setFileDataBaseId: (fileId: string, dataBaseId: string) => void;
  setDataBaseFile: (fileId: string, dataBaseEntry: IDocumentBase) => void;
  setUploadStatus: (status: UploadStatus) => void;
  setUploadFinishStatus: (isFinished: boolean) => void;
  setUploadFileStatus: (fileId: string, status: UploadStatus) => void;
  setFileChunks: (fileId: string, chunksArray: IChunkHash) => void;
  setChunkStatus: (
    fileId: string,
    chunkId: string,
    status: UploadStatus,
  ) => void;
  updateChunkPercentage: (
    fileId: string,
    chunkId: string,
    percentega: number,
  ) => void;
}

class UploadDocumentsService extends React.Component<IProps, any> {
  // Remove beforeunload listener
  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.keepOnPageHandler);
  }

  componentDidUpdate(prevProps: IProps) {
    // Start uploading files
    if (prevProps.uploadStatus !== this.props.uploadStatus) {
      if (this.props.uploadStatus === UploadStatus.ACTIVE) {
        window.addEventListener('beforeunload', this.keepOnPageHandler);
        this.uploadFiles();
      } else {
        window.removeEventListener('beforeunload', this.keepOnPageHandler);
      }
    }
  }

  keepOnPageHandler(e: any) {
    const message =
      'Warning!\n\nNavigating away from this page will stop the upload process.';

    if (typeof e === 'undefined') {
      e = window.event;
    }

    if (e) {
      e.returnValue = message;
    }

    return message;
  }

  // Upload file by chunks
  async uploadFiles() {
    const {
      files,
      setUploadStatus,
      setUploadFinishStatus,
      setUploadFileStatus,
    } = this.props;

    if (!files) return;
    // Only files that needs to be uploaded, only WAIT status
    const willUploadFileList = Object.keys(files)
      .sort()
      .filter((key) => files[key].status === UploadStatus.WAIT);

    if (willUploadFileList.length) {
      // Get first file from upload queue
      const uploadFileId = willUploadFileList[0];
      try {
        await this.handleUploadFile(uploadFileId);
      } catch {
        setUploadFileStatus(uploadFileId, UploadStatus.FAILED);
      }
    } else {
      // Uploaded all files
      setUploadStatus(UploadStatus.WAIT);
      setUploadFinishStatus(true);
    }
  }

  async handleUploadFile(fileId: string) {
    const { files, setFileChunks, setUploadFileStatus } = this.props;
    if (!files[fileId]) return;

    const fileObject = files[fileId];

    // Set uploading status to file
    setUploadFileStatus(fileId, UploadStatus.UPLOADING);

    // Generate file chunk list
    const chunkList = DocumentHelpers.createFileChunk(
      fileObject.file,
      fileObject.chunkSize,
      fileId,
    );

    // Store chunks to redux file item
    setFileChunks(fileId, chunkList);

    // Start uploading chunks
    setTimeout(async () => {
      await this.uploadChunks(fileId);
    }, 0);
  }

  async uploadChunks(fileId: string) {
    const file = this.props.files[fileId];
    const { chunksArray } = file;
    // Filter only chunks that needs to be uploaded
    const willUploadChunkList = Object.keys(chunksArray).filter(
      (key) => chunksArray[key].status === UploadStatus.WAIT,
    );

    if (willUploadChunkList.length) {
      const uploadChunkId = willUploadChunkList[0];

      try {
        const formData = new FormData();
        const { file, fileName, description, tags, association, associations } =
          this.props.files[fileId];

        const arrayOfAssociations: UploadDocumentAssociationModel[] =
          associations ? associations : [];

        if (association) {
          arrayOfAssociations.push({
            id: association.id,
            type: association.type,
          });
        }

        formData.append('id', fileId);
        formData.append('chunk', chunksArray[uploadChunkId].file);
        formData.append('chunkIndex', String(chunksArray[uploadChunkId].index));
        formData.append('chunks', String(Object.keys(chunksArray).length));
        // TODO: this check is added for .msg files since they don't have a type in file object
        // need to rework this
        formData.append(
          'contentType',
          file.type || 'application/vnd.ms-outlook',
        );
        formData.append('name', fileName);
        formData.append('fileName', file.name);
        formData.append('associations', JSON.stringify(arrayOfAssociations));

        if (description && description.length) {
          formData.append('description', String(description));
        }

        if (tags && tags.length) {
          formData.append('tags', String(tags));
        }

        const response = await documentsAPI.uploadDocument(
          formData,
          this.createProgressHandler((percentage: number) =>
            this.props.updateChunkPercentage(fileId, uploadChunkId, percentage),
          ),
        );

        if (response && response._id) {
          this.props.setFileDataBaseId(fileId, response._id);
          this.props.setDataBaseFile(fileId, response);
        }

        // Mark chunk as DONE
        this.props.setChunkStatus(fileId, uploadChunkId, UploadStatus.DONE);

        // Upload next chunk
        setTimeout(() => {
          this.uploadChunks(fileId);
        }, 0);
      } catch {
        this.props.setUploadFileStatus(fileId, UploadStatus.FAILED);
        this.uploadFiles();
      }
    } else {
      // Mark file as DONE
      this.props.setUploadFileStatus(fileId, UploadStatus.DONE);

      // Continue uploading next files
      this.uploadFiles();
    }
  }

  //Handle chunk progress percentage
  createProgressHandler(updatePercentageCallback: any) {
    return function (e: any) {
      const chunkPercentage = ((e.loaded / e.total) * 100) | 0;
      updatePercentageCallback(chunkPercentage);
    };
  }

  render() {
    const { shouldShowUploadNotification } = this.props;

    return (
      <>{shouldShowUploadNotification && <UploadDocumentNotification />}</>
    );
  }
}

const mapStateToProps = (state: any) => ({
  uploadStatus: state.upload.status,
  files: state.upload.files,
  shouldShowUploadNotification: state.upload.shouldShowUploadNotification,
});

const mapDispatchToProps = (dispatch: any) => ({
  setUploadStatus: (uploadStatus: UploadStatus) =>
    dispatch(setUploadStatus(uploadStatus)),
  setUploadFinishStatus: (isFinished: boolean) =>
    dispatch(setUploadFinishStatus(isFinished)),
  setUploadFileStatus: (fileId: string, status: UploadStatus) =>
    dispatch(setUploadFileStatus(fileId, status)),
  setFileChunks: (fileId: string, chunksArray: IChunkHash) =>
    dispatch(setFileChunks(fileId, chunksArray)),
  setChunkStatus: (fileId: string, chunkId: string, status: UploadStatus) =>
    dispatch(setChunkStatus(fileId, chunkId, status)),
  setFileDataBaseId: (fileId: string, dataBaseId: string) =>
    dispatch(setFileDataBaseId(fileId, dataBaseId)),
  setDataBaseFile: (fileId: string, dataBaseEntry: IDocumentBase) =>
    dispatch(setDataBaseFile(fileId, dataBaseEntry)),
  updateChunkPercentage: (
    fileId: string,
    chunkId: string,
    percentage: number,
  ) => dispatch(updateChunkPercentage(fileId, chunkId, percentage)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadDocumentsService as any) as any;
