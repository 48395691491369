import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';

// components
import StatusLabel from '../../../Typography/StatusLabel';

export enum ForgotUsernameRequestStatuses {
  Pending = 'PENDING',
  InProgress = 'IN_PROCESS',
  Completed = 'COMPLETED',
}

const STATUS_MAP = {
  info: [],
  warning: ['IN_PROCESS', 'PENDING'],
  success: ['COMPLETED'],
  error: [],
};

interface IProps {
  status: ForgotUsernameRequestStatuses;
}

const ForgotUsernameRequestStatus = memo(({ status }: IProps) => {
  const { t } = useTranslation('orac');
  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(`forgot_username_requests.statuses.${status}`)}
    />
  );
});

export default ForgotUsernameRequestStatus;
