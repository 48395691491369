import React, { useState } from 'react';

// helpers
import moment from 'moment';
import useFetch from '@hooks/useFetch';
import { Moment } from 'moment';
import { reportingAPI } from 'api/reporting/reportingAPI';
import { DEFAULT_TABLE_LIMIT } from 'constants/global';

// components
import FilterBar from './FilterBar';
import EntryDetailsModal from './EntryDetailsModal';
import EntryChangesLogTable, {
  ActionTypes,
  EntryChangesLogReportItem,
} from 'components/Tables/TableTemplates/Reporting/EntryChangesLogTable';
import { Divider } from 'antd';

const EntryChangesLogReport = () => {
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState<[Moment, Moment]>([
    moment().add(-1, 'week'),
    moment(),
  ]);
  const [selectedEntry, setSelectedEntry] = useState<string | null>(null);

  const { response, loading } = useFetch(
    () =>
      reportingAPI.fetchEntryChangesLogReport({
        page,
        limit: DEFAULT_TABLE_LIMIT,
        fromDate: dateRange[0].startOf('d').format('X'),
        toDate: dateRange[1].endOf('d').format('X'),
      }),
    [page, dateRange],
  );

  const handleFilterChange = (key: string, value: any) => {
    switch (key) {
      case 'startDate':
        setDateRange([value, dateRange[1]]);
        break;
      case 'endDate':
        setDateRange([dateRange[0], value]);
        break;
    }
  };

  const handleActions = (
    action: ActionTypes,
    record: EntryChangesLogReportItem,
  ) => {
    switch (action) {
      case 'view':
        setSelectedEntry(record.id);
        break;
    }
  };

  return (
    <>
      <FilterBar
        dateRangeValue={dateRange}
        isDisabled={loading}
        filterChange={handleFilterChange}
      />
      <Divider />
      <EntryChangesLogTable
        data={response?.data || []}
        current={page}
        loading={loading}
        onActionsClick={handleActions}
        onPaginationChange={setPage}
      />
      <EntryDetailsModal
        entryId={selectedEntry}
        onModalClose={() => setSelectedEntry(null)}
      />
    </>
  );
};

export default EntryChangesLogReport;
