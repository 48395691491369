import React, { useState } from 'react';

// helpers utils
import useFetch from '../../../../../hooks/useFetch';
import useTranslation from '../../../../../hooks/useTranslation';
import { ISession } from '../../../../../typings/profile/security';
import { securityAPI } from '../../../../../api/profile/securityAPI';
import { DEFAULT_TABLE_LIMIT } from '../../../../../constants/global';

// components
import CurrentSessionsTable from '../../../../../components/Tables/TableTemplates/Profile/CurrentSessionsTable';
import { Modal } from 'antd';

const CurrentSessions = () => {
  const { t } = useTranslation(['profile', 'common']);

  const [currentPage, setPage] = useState(1);
  const [updateTableTrigger, updateTable] = useState({});

  const { response, loading } = useFetch(
    () =>
      securityAPI.fetchSessions({
        limit: DEFAULT_TABLE_LIMIT,
        page: currentPage,
        inactive: false,
      }),
    [currentPage, updateTableTrigger],
  );

  const handleSessionDelete = async (session: ISession) => {
    Modal.confirm({
      content: t('sign_in_and_security.sessions.stop_session_confirmation'),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        await securityAPI.deleteSession(session._id);
        updateTable({});
      },
    });
  };

  const handlePaginationChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <CurrentSessionsTable
      loading={loading}
      current={currentPage}
      total={response?.total}
      data={response?.data || []}
      onDelete={handleSessionDelete}
      onPaginationChange={handlePaginationChange}
    />
  );
};

export default CurrentSessions;
