import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';

// components
import ReviewApprovalEntryModal, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/ApprovalManagement/ReviewApprovalEntryModal';
import { Button, Col, Row, message } from 'antd';

interface IProps {
  canReject: boolean;
  canApprove: boolean;
  onReject: (reason: string) => void;
  onApprove: (reason: string) => void;
  disabled?: boolean;
}

const WorkflowActions = ({
  canReject,
  canApprove,
  onApprove,
  onReject,
  disabled,
}: IProps) => {
  const { t } = useTranslation(['approval_management', 'common']);
  const [selectedReviewAction, setSelectedReviewAction] = useState<
    'approve' | 'reject' | null
  >(null);

  const initialValues = useMemo<FormValuesModel | null>(() => {
    if (!selectedReviewAction) {
      return null;
    }

    return {
      reason: '',
      action: selectedReviewAction,
    };
  }, [selectedReviewAction]);

  const handleSubmit = async (values: FormValuesModel) => {
    if (values.action === 'approve') {
      await onApprove(values.reason);
      message.success(
        t('approval_workflow.review_approval_entry_modal.success_approve'),
      );
    } else {
      await onReject(values.reason);
      message.success(
        t('approval_workflow.review_approval_entry_modal.success_reject'),
      );
    }
  };

  const handleModalClose = () => {
    setSelectedReviewAction(null);
  };

  return (
    <StyledRow gutter={[16, 0]} justify="end">
      {canReject && (
        <Col>
          <Button
            danger
            size="large"
            onClick={() => setSelectedReviewAction('reject')}
            disabled={disabled}
          >
            {t('reject', { ns: 'common' })}
          </Button>
        </Col>
      )}

      {canApprove && (
        <Col>
          <Button
            type="primary"
            size="large"
            onClick={() => setSelectedReviewAction('approve')}
            disabled={disabled}
          >
            {t('approve', { ns: 'common' })}
          </Button>
        </Col>
      )}

      <ReviewApprovalEntryModal
        onSubmit={handleSubmit}
        isVisible={!!selectedReviewAction}
        initialValues={initialValues}
        closeCallback={handleModalClose}
      />
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  margin-top: ${({ theme }) => theme.marginMd};
`;

export default WorkflowActions;
