import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';

// components
import { Button } from 'antd';
import VerifyIdentityCodeModal from '../../../../../../../components/Modals/TemplateModalDialogs/ORAC/Users/Security/VerifyIdentityCodeModal';

interface IProps {
  userId: string;
}

const VerifyIdentityCode = ({ userId }: IProps) => {
  const { t } = useTranslation('common');

  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <StyledButton
        size="large"
        type="default"
        onClick={() => setIsModalVisible(true)}
      >
        {t('verify')}
      </StyledButton>

      <VerifyIdentityCodeModal
        isVisible={isModalVisible}
        userId={userId}
        closeCallback={() => setIsModalVisible(false)}
      />
    </>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
  min-width: 0px;
`;

export default VerifyIdentityCode;
