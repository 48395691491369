import React, { useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { debounce } from 'lodash';
import { clientGroupsAPI } from '../../../../api/crm/clientGroup/clientGroupsAPI';
import { ClientReviewStatus, ClientStatus } from '../../../../enums/crm/crm';

// constants
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  DEFAULT_TABLE_LIMIT,
} from '../../../../constants/global';

// components
import FilterBar from './FilterBar';
import { Divider } from 'antd';
import { default as TemplateClientGroupsTable } from '../../../../components/Tables/TableTemplates/CRM/ClientGroupsTable';

const ClientGroupsTable = () => {
  // table
  const [current, setCurrentPage] = useState(1);
  const [clientGroupsFilter, setClientGroupsFilter] = useState({
    search: '',
    filter_status: 'all',
    filter_review_status: 'all',
  });

  const { response, loading } = useFetch(() => {
    return clientGroupsAPI.fetchClientGroups({
      page: current,
      limit: DEFAULT_TABLE_LIMIT,
      search: clientGroupsFilter.search || undefined,
      filter_status:
        clientGroupsFilter.filter_status === 'all'
          ? undefined
          : clientGroupsFilter.filter_status,
      filter_review_status:
        clientGroupsFilter.filter_review_status === 'all'
          ? undefined
          : clientGroupsFilter.filter_review_status,
    });
  }, [current, clientGroupsFilter]);

  const reviewStatuses = {
    all: Object.values(ClientReviewStatus),
    [ClientStatus.Onboarding]: [
      ClientReviewStatus.Reviewed,
      ClientReviewStatus.NotReviewed,
    ],
    [ClientStatus.InReview]: [
      ClientReviewStatus.ManagerReview,
      ClientReviewStatus.ComplianceReview,
      ClientReviewStatus.BusinessReview,
    ],
    [ClientStatus.Approved]: [
      ClientReviewStatus.Reviewed,
      ClientReviewStatus.ManagerReview,
    ],
    [ClientStatus.Rejected]: [ClientReviewStatus.Reviewed],
  };

  const filteredReviewStatuses = useMemo(() => {
    return reviewStatuses[clientGroupsFilter.filter_status as ClientStatus];
  }, [clientGroupsFilter.filter_status]);

  const handleFilterChange = (key: string, value: any) => {
    if (current > 1) {
      setCurrentPage(1);
    }

    switch (key) {
      case 'search':
        setClientGroupsFilter((prevState) => ({ ...prevState, search: value }));
        break;

      case 'filter_by_status':
        setClientGroupsFilter((prevState) => ({
          ...prevState,
          filter_status: value,
          filter_review_status: 'all',
        }));
        break;

      case 'filter_by_review_status':
        setClientGroupsFilter((prevState) => ({
          ...prevState,
          filter_review_status: value,
        }));
        break;
    }
  };

  return (
    <>
      <FilterBar
        status={clientGroupsFilter.filter_status}
        reviewStatus={clientGroupsFilter.filter_review_status}
        onFilterChange={debounce(
          handleFilterChange,
          AUTOCOMPLETE_DEBOUNCE_DELAY,
        )}
        reviewStatuses={filteredReviewStatuses}
      />

      <Divider />

      <TemplateClientGroupsTable
        total={response?.total || 0}
        current={current}
        data={response?.data || []}
        loading={loading}
        onPaginationChange={setCurrentPage}
      />
    </>
  );
};

export default ClientGroupsTable;
