import { INewAccountBody } from '../../../api/finance/account/accountsAPI';
import { FormValuesModel } from '../../../components/Modals/TemplateModalDialogs/Finance/AccountCategories';
import { FetchResponseModel } from '../../../typings/common';
import { BankAccountStatuses } from 'enums/banking/accounts';
import {
  WireRangeKeys,
  WireRangeOptions,
} from 'components/Forms/FormComponents/SelectInputs/WireRangesFormSelect';
import { FormValuesModel as BankaAccountFormValues } from 'components/Modals/TemplateModalDialogs/Finance/BankAccountModal';
import {
  IAccountCategoryShort,
  ICreateAccountCategory,
} from '../../../typings/finance/accountCategories';
import {
  EstimatedAmountFundsRangeKeys,
  EstimatedAmountFundsRangeOptions,
} from 'components/Forms/FormComponents/SelectInputs/EstimatedAmountFundsRangeFormSelect';
import {
  InitialDepositRangeKeys,
  InitialDepositRangeOptions,
} from 'components/Forms/FormComponents/SelectInputs/InitialDepositRangeFormSelect';

export interface FormattedAccountCategory {
  id: number;
  isClient: boolean;
  isFinancialType: boolean;
  accountCategoryName: string;
  children: IAccountCategoryShort[] | null;
}

const accountsAdapter = {
  generateCreateAccountBody: (
    formValues: BankaAccountFormValues,
  ): INewAccountBody => {
    const { accountSupplementary } = formValues;
    return {
      accountName: formValues.accountName.trim(),
      accountNumberName: formValues.accountShortName || undefined,
      correspondentBankAccountNumber:
        formValues.correspondentBankAccount as string,
      currencyId: formValues.currency as number,
      accountCategoryId: formValues.accountCategory as number,
      status: formValues.isActive
        ? BankAccountStatuses.Active
        : BankAccountStatuses.Closed,
      isFrozen: formValues.isFrozen as boolean,
      clientGroupId: formValues.clientGroup as number,
      isReconciled: formValues.isReconciled as boolean,
      accountSupplementary: formValues.isClient
        ? {
            type: accountSupplementary.type,
            purpose: accountSupplementary.purpose.trim(),
            initialDepositOrigin:
              accountSupplementary.initialDepositOrigin || '',
            initialDepositFrom: InitialDepositRangeOptions[
              accountSupplementary.initialDeposit as InitialDepositRangeKeys
            ].min as number,
            initialDepositTo: InitialDepositRangeOptions[
              accountSupplementary.initialDeposit as InitialDepositRangeKeys
            ].max as number,
            incomingWiresNumberFrom: WireRangeOptions[
              accountSupplementary.incomingWiresNumber as WireRangeKeys
            ].min as number,
            incomingWiresNumberTo: WireRangeOptions[
              accountSupplementary.incomingWiresNumber as WireRangeKeys
            ].max as number,
            outgoingWiresNumberFrom: WireRangeOptions[
              accountSupplementary.outgoingWiresNumber as WireRangeKeys
            ].min as number,
            outgoingWiresNumberTo: WireRangeOptions[
              accountSupplementary.outgoingWiresNumber as WireRangeKeys
            ].max as number,
            estimatedIncomingFundsFrom: EstimatedAmountFundsRangeOptions[
              accountSupplementary.estimatedIncomingFunds as EstimatedAmountFundsRangeKeys
            ].min as number,
            estimatedIncomingFundsTo: EstimatedAmountFundsRangeOptions[
              accountSupplementary.estimatedIncomingFunds as EstimatedAmountFundsRangeKeys
            ].max as number,
            estimatedOutgoingFundsFrom: EstimatedAmountFundsRangeOptions[
              accountSupplementary.estimatedOutgoingFunds as EstimatedAmountFundsRangeKeys
            ].min as number,
            estimatedOutgoingFundsTo: EstimatedAmountFundsRangeOptions[
              accountSupplementary.estimatedOutgoingFunds as EstimatedAmountFundsRangeKeys
            ].max as number,
            jurisdictions: accountSupplementary.jurisdictions,
          }
        : undefined,
    };
  },

  generateCreateAccountCategoryBody: (
    formValues: FormValuesModel,
  ): ICreateAccountCategory => {
    return {
      accountCategoryName: formValues.accountCategoryName.trim(),
      accountCategoryTypeId: 0,
      description: formValues.description.trim(),
      financialTypeId: 0,
      isClient: !!formValues.isClient,
      parentCategoryId: formValues.parentCategoryId || 0,
    };
  },

  searchAccountCategoryHierarchy: (
    response: FetchResponseModel<IAccountCategoryShort>,
  ): { total: number; data: FormattedAccountCategory[] } => {
    const formattedData = response.data.map((e) => ({
      ...e,
      isFinancialType: true,
    }));
    return { total: response.total, data: formattedData };
  },
};

export { accountsAdapter };
