import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../../../../routes/routes';
import { AccountsHelpers } from '@helpers/finance/accounts';

// components
import DetailsRow from '../../../../DetailsRow';
import { Collapse, Typography } from 'antd';

export type UpdateFeeScheduleData = {
  feeScheduleTypeId: number;
  feeScheduleTypeName: string;
  clientGroupNumber: number;
  clientGroupName: string;
  currencyId: number;
  currencyIsoCode: string;
  old: { amount: number };
  new: { amount: number };
};

interface IProps {
  data: UpdateFeeScheduleData;
}

const UpdateFeeScheduleDetails = memo(({ data }: IProps) => {
  const { t } = useTranslation('approval_management');
  const history = useHistory();

  return (
    <Collapse defaultActiveKey={['general', 'old', 'new']}>
      <Collapse.Panel
        key="general"
        header={t(
          'approval_workflow.approval_workflow_changes.update_fee_schedule.general_info_section_title',
        )}
      >
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_fee_schedule.type',
          )}
          value={
            data.clientGroupNumber ? (
              <Typography.Link
                onClick={() =>
                  history.push({
                    pathname: RoutePaths.CRM_Client_Group_Details,
                    search: `?id=${data.clientGroupNumber}`,
                  })
                }
              >
                {t(
                  'approval_workflow.approval_workflow_changes.update_fee_schedule.client',
                  { clientGroupName: data.clientGroupName },
                )}
              </Typography.Link>
            ) : (
              <Typography.Link
                onClick={() => history.push(RoutePaths.Banking_FeeSchedule)}
              >
                {t(
                  'approval_workflow.approval_workflow_changes.update_fee_schedule.general',
                )}
              </Typography.Link>
            )
          }
        />

        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_fee_schedule.fee_schedule_type',
          )}
          value={data.feeScheduleTypeName}
        />
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_fee_schedule.fee_schedule_currency',
          )}
          value={data.currencyIsoCode}
        />
      </Collapse.Panel>

      <Collapse.Panel
        key="old"
        header={t('approval_workflow.approval_workflow_changes.old_version')}
      >
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_fee_schedule.amount',
          )}
          value={AccountsHelpers.convertAmountBigIntToLocaleString(
            data.old.amount,
          )}
        />
      </Collapse.Panel>

      <Collapse.Panel
        key="new"
        header={t('approval_workflow.approval_workflow_changes.new_version')}
      >
        <DetailsRow
          title={t(
            'approval_workflow.approval_workflow_changes.update_fee_schedule.amount',
          )}
          value={AccountsHelpers.convertAmountBigIntToLocaleString(
            data.new.amount,
          )}
        />
      </Collapse.Panel>
    </Collapse>
  );
});

export default UpdateFeeScheduleDetails;
