import React from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../../../hooks/useFetch';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { AuthHelpers } from '@helpers/auth';
import { ChallengeForRegistrationModel } from '../../../../../../../typings/profile/fido';

// components
import Content from './Content';
import BrowserIsNotSupported from '../../../../../../Additional/Auth/TwoFactorVerification/BrowserIsNotSupported';
import { Typography } from 'antd';

interface IProps {
  getChallengeCallback: () => Promise<ChallengeForRegistrationModel>;
}

const RegisterKeyStep = ({ getChallengeCallback }: IProps) => {
  const canUseWebAuthn = AuthHelpers.isWebAuthnSupported();
  const { t } = useTranslation('profile');

  const { response: challenge, loading } = useFetch(
    () => (canUseWebAuthn ? getChallengeCallback() : null),
    [canUseWebAuthn, getChallengeCallback],
  );

  return (
    <>
      <StyledTitle>
        {t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_modal.register_key_step.title',
        )}
      </StyledTitle>
      {canUseWebAuthn ? (
        <Content loadingChallenge={loading} challenge={challenge} />
      ) : (
        <BrowserIsNotSupported />
      )}
    </>
  );
};

const StyledTitle = styled(Typography.Paragraph)`
  text-align: center;
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizeMd};
  color: ${({ theme }) => theme.whiteColor};
`;

export default RegisterKeyStep;
