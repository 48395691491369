import React, { memo, useMemo } from 'react';

// helpers
import TableActions from '../../../TableActions';
import useTranslation from '../../../../../hooks/useTranslation';
import { ConfigurationRuleModel } from '../../../../../typings/compliance/configurationRules';
import {
  ComparisonOperators,
  ComplianceReviewTypes,
  ConfigurationRuleState,
  ExpectedDataTypes,
  ExpectedValueTypes,
  RiskLevelTypes,
} from '../../../../../enums/compliance/reviewConfiguration';

// components
import EllipsisTooltip from '../../../../Tooltips/EllipsisTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

export type TableActions = 'view' | 'edit';

interface IProps extends RequiredPropsForTableModel<ConfigurationRuleModel> {
  canEdit?: boolean;
  onActionsClick?: (key: TableActions, record: ConfigurationRuleModel) => void;
}

const ReviewConfigurationRulesTable = memo(
  ({ onActionsClick, canEdit, ...rest }: IProps) => {
    const { t } = useTranslation(['compliance', 'common']);

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          width: '220px',
          title: t('review_configuration.review_type'),
          key: 'review_type',
          render: (record: ConfigurationRuleModel) =>
            t(`review_types.${ComplianceReviewTypes[record.reviewType]}`),
        },

        {
          width: '170px',
          title: t('review_configuration.risk_level'),
          key: 'risk_level',
          render: (record: ConfigurationRuleModel) =>
            t(`risk_level_types.${RiskLevelTypes[record.riskLevel]}`),
        },

        {
          width: '200',
          title: t('review_configuration.reference_object'),
          key: 'reference_object',
          render: (record: ConfigurationRuleModel) =>
            record.referenceObject.name,
        },

        {
          width: '220px',
          title: t('review_configuration.expected_data_type'),
          key: 'expected_data_type',
          render: (record: ConfigurationRuleModel) =>
            t(`data_type.${ExpectedDataTypes[record.expectedDataType]}`),
        },

        {
          width: '220px',
          title: t('review_configuration.reference_property_name'),
          key: 'reference_property_name',
          dataIndex: 'referencePropertyName',
        },

        {
          width: '220px',
          title: t('review_configuration.comparison_operator'),
          key: 'comparison_operator',
          render: (record: ConfigurationRuleModel) =>
            t(
              `comparison_operator.${ComparisonOperators[record.comparationOperator]}`,
            ),
        },

        {
          width: '170px',
          title: t('review_configuration.expected_value_type'),
          key: 'expected_value_type',
          render: (record: ConfigurationRuleModel) =>
            t(
              `expected_value_type_options.${ExpectedValueTypes[record.expected.type]}`,
            ),
        },

        {
          width: '170px',
          title: t('review_configuration.expected_value'),
          key: 'expected_value',
          render: (record: ConfigurationRuleModel) => (
            <EllipsisTooltip
              title={record.expected.valueJSON}
              maxTextContainerWidth="240px"
            >
              {record.expected.valueJSON}
            </EllipsisTooltip>
          ),
        },

        {
          width: '170px',
          title: t('review_configuration.is_manual_review'),
          key: 'aml_post_review',
          render: (record: ConfigurationRuleModel) =>
            t(`${record.isManualReview ? 'yes' : 'no'}`, { ns: 'common' }),
        },

        {
          width: '170px',
          title: t('review_configuration.state'),
          key: 'state',
          render: (record: ConfigurationRuleModel) =>
            t(
              `configuration_rule_state.${ConfigurationRuleState[record.state]}`,
            ),
        },

        {
          hidden: !onActionsClick,
          key: 'actions',
          align: 'right',
          render: (record: ConfigurationRuleModel) =>
            onActionsClick ? (
              <TableActions
                onSelect={(key: TableActions) => onActionsClick(key, record)}
                menu={[
                  {
                    hidden: canEdit,
                    key: 'view',
                    name: t('modal.view', { ns: 'common' }),
                  },

                  {
                    hidden: !canEdit,
                    key: 'edit',
                    name: t('modal.edit', { ns: 'common' }),
                  },
                ]}
              />
            ) : null,
        },
      ];

      return result;
    }, [t, onActionsClick, canEdit]);

    return <Table {...rest} columns={columns} />;
  },
);

export default ReviewConfigurationRulesTable;
