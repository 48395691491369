import React, { useEffect } from 'react';

// helpers
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_CURRENCY_RATES_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import CurrencyRatesTable from '../../modules/Finance/CurrencyRates/CurrencyRatesTable';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const CurrencyRates = ({ setHeaderOptions }: IProps) => {
  useEffect(() => {
    setHeaderOptions({
      appendToTitle: (
        <TooltipToDocumentation
          link={GENESIS_CURRENCY_RATES_DOCUMENTATION_LINK}
        />
      ),
    });
  }, []);

  return <CurrencyRatesTable />;
};

export default CurrencyRates;
