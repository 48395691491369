import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import FormField from '@core_components/FormField';
import NoteTypeSelect from '../../../../../Forms/FormComponents/SelectInputs/NoteTypeSelect';
import FormDatePicker from '@common_components/Form/FormDatePicker';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import CrmItemAutocomplete from './CrmItemAutocomplete';
import UploadDocumentField from '../../../../../Forms/FormComponents/UploadDocumentField';

interface IProps {
  showNoteType: boolean;
}

const InnerForm = ({ showNoteType }: IProps) => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      {showNoteType && (
        <FormField
          label={t('entity.notes.type')}
          name="type"
          component={StyledNoteTypeSelect}
          additionalProps={{ allowClear: true }}
        />
      )}

      <FormField
        label={t('entity.notes.date')}
        name="eventDate"
        component={StyledDatePicker}
        additionalProps={{ allowClear: true }}
      />

      <FormField
        label={t('entity.notes.crm_items')}
        name="crmItems"
        component={StyledCrmItemAutocomplete}
        additionalProps={{
          mode: 'multiple',
          initialValue: values.crmItemsInitialValue,
        }}
      />

      <FormField
        label={t('entity.notes.note')}
        name="text"
        component={FormInputTextArea}
        additionalProps={{ autoSize: { minRows: 6, maxRows: 6 } }}
      />

      <FormField
        label={t('entity.notes.documents')}
        name="documents"
        component={UploadDocumentField}
      />
    </>
  );
};

const StyledDatePicker = styled(FormDatePicker)`
  width: 100%;
`;

const StyledNoteTypeSelect = styled(NoteTypeSelect)`
  width: 100%;
`;

const StyledCrmItemAutocomplete = styled(CrmItemAutocomplete)`
  width: 100%;
`;

export default InnerForm;
