import React, { memo } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { AccountRecoveryEntryStatus } from 'enums/auth/accountRecovery';

// components
import StatusLabel from '../../../Typography/StatusLabel';

const STATUS_MAP = {
  info: [AccountRecoveryEntryStatus.Withdrawn],
  warning: [AccountRecoveryEntryStatus.Pending],
  success: [AccountRecoveryEntryStatus.Approved],
  error: [AccountRecoveryEntryStatus.Rejected],
};

interface IProps {
  status: AccountRecoveryEntryStatus;
}

const ResetAuthenticationRequestStatus = memo(({ status }: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(`account_recovery_requests.${status || 'none'}`)}
    />
  );
});

export default ResetAuthenticationRequestStatus;
