import React, { Key, memo, useMemo, useState } from 'react';

// helpers
import useTranslation from '../../../../../../../../../hooks/useTranslation';
import { useField } from 'formik';
import { SelectedActionModel, SelectedObjectModel } from '../../..';

// components
import SelectedPermissionsMatrix from './SelectedPermissionsMatrix';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import TableActions, {
  ITableMenuItem,
} from '../../../../../../../../Tables/TableActions';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<SelectedObjectModel> {
  actions: ITableMenuItem[];
  onActionsClick: (key: string, objectId: string) => void;
  showForbiddenPermissions?: boolean;
  onlyForbiddenPermissions?: boolean;
}

const ObjectsList = memo(
  ({
    actions,
    onActionsClick,
    onPaginationChange,
    showForbiddenPermissions,
    onlyForbiddenPermissions,
    ...rest
  }: IProps) => {
    const { t } = useTranslation('orac');
    const [field] = useField('selectedPermissions');
    const [expandedRowKeys, setExpandedRowKeys] = useState<readonly Key[]>([]);

    const handlePaginationChange = (newPage: number) => {
      setExpandedRowKeys([]);
      onPaginationChange && onPaginationChange(newPage);
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          title: t('objects.table.name'),
          key: 'name',
          render: (record: SelectedObjectModel) => (
            <Typography.Text>{record.name}</Typography.Text>
          ),
          field,
        },

        {
          key: 'actions',
          align: 'right',
          render: (record: SelectedActionModel) => (
            <TableActions
              menu={actions}
              onSelect={(key: string) => onActionsClick(key, record.id)}
            />
          ),
        },
      ];

      return result;
    }, [actions, onActionsClick, field]);

    const expandableRow = useMemo(
      () => ({
        expandedRowKeys: expandedRowKeys as readonly Key[],
        onExpandedRowsChange: (keys: readonly Key[]) =>
          setExpandedRowKeys(keys),
        rowExpandable: ({ actions }: SelectedObjectModel) =>
          !!Object.keys(actions).length,
        expandedRowRender: (object: SelectedObjectModel) => {
          const selectedPermissions: SelectedActionModel[] = Object.keys(
            object.actions,
          ).map((actionId: string) => ({
            actionId,
            ...object.actions[actionId as string],
          }));
          return (
            <SelectedPermissionsMatrix
              key={object.id}
              data={selectedPermissions}
              onlyForbiddenPermissions={onlyForbiddenPermissions}
              showForbiddenPermissions={showForbiddenPermissions}
            />
          );
        },
      }),
      [expandedRowKeys, onlyForbiddenPermissions, showForbiddenPermissions],
    );

    return (
      <Table
        {...rest}
        size="small"
        columns={columns}
        expandable={expandableRow}
        onPaginationChange={handlePaginationChange}
      />
    );
  },
);

export default ObjectsList;
