import React, { memo, useCallback, useMemo, useState } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from '@services/error-handler/service';
import { message } from 'antd';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { contactsAPI } from 'api/crm/contact/contactsAPI';
import { FormikHelpers } from 'formik';
import { ContactHelpers } from '@helpers/crm/contact';
import { contactsAdapter } from 'apiAdapters/crm/contact/contactsAdapter';

// components
import PageButton from '@common_components/Buttons/PageButton';
import ContactDetailsModalDialog, {
  ContactDetailsFormValues,
} from 'components/Modals/TemplateModalDialogs/CRM/ContactDetailsModalDialog';

const AddContact = memo(() => {
  const history = useHistory();
  const { t } = useTranslation('crm');
  const [isVisible, setIsVisible] = useState(false);

  const initialFormValues = useMemo<ContactDetailsFormValues>(() => {
    return {
      hasAssociationWithAuthUser: false,
      canUpdatePrimaryStatusForAddresses: true,
      firstName: '',
      middleName: '',
      lastName: '',
      gender: null,
      dateOfBirth: null,
      countryOfBirth: null,
      nationality: null,
      isInvitationPermitted: null,
      isVendor: null,
      isPEP: null,
      pepInformation: '',
      isRegulated: null,
      regulationCountry: null,
      sourceOfWealthCategories: null,
      sourceOfWealthDescription: '',
      primaryAddressId: null,
      emails: [],
      addresses: [],
      phoneNumbers: [],
      socialMedia: {
        twitterLink: '',
        facebookLink: '',
        linkedInLink: '',
      },
      primary: {
        email: null,
        phoneNumber: null,
      },
    };
  }, []);

  const handleModalClose = useCallback((wasSubmitted?: boolean) => {
    if (wasSubmitted) {
      // TODO
    }

    setIsVisible(false);
  }, []);

  const handleSubmit = useCallback(
    async (values: ContactDetailsFormValues) => {
      const errors = await ContactHelpers.validatePhoneNumbers(
        values.phoneNumbers.map((phone) => phone.number),
      );
      if (errors) {
        throw {
          response: { data: { code: 'phone_validation', details: errors } },
        };
      } else {
        const formattedBody =
          contactsAdapter.formatContactWithIdentificationDataFromFormToQuery(
            values,
          );
        const response = await contactsAPI.createContact(formattedBody);
        message.success(t('contacts.new.addSuccess'));
        history.push({
          pathname: RoutePaths.CRM_Contacts_Edit,
          search: `?id=${response._id}`,
        });
      }
    },
    [history],
  );

  const onSubmitError = (
    error: ErrorFromServer,
    values: ContactDetailsFormValues,
    helpers: FormikHelpers<ContactDetailsFormValues>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);

    switch (errorCode) {
      case 'phone_validation': {
        const errorDetails = error?.response?.data.details;
        values.phoneNumbers.forEach((phone, index) => {
          if (errorDetails[phone.number]) {
            helpers.setFieldError(
              `phoneNumbers.${index}.number`,
              errorDetails[phone.number].errorMessage,
            );
          }
        });
        break;
      }
      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <>
      <PageButton onClick={() => setIsVisible(true)}>
        {t('contacts.new.title')}
      </PageButton>
      <ContactDetailsModalDialog
        title={t('contacts.new.title')}
        isVisible={isVisible}
        onSubmit={handleSubmit}
        onSubmitError={onSubmitError}
        closeCallback={handleModalClose}
        initialValues={initialFormValues}
      />
    </>
  );
});

export default AddContact;
