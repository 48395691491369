import React, { useCallback, useEffect, useMemo, useState } from 'react';

// helpers utils
import styled from 'styled-components';
import { debounce } from 'lodash';
import { accountCategoriesAPI } from '../../../../../../api/finance/account/accountCategoriesAPI';
import { IAccountCategoryShort } from '../../../../../../typings/finance/accountCategories';
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  INFINITE_LIMIT,
} from '../../../../../../constants/global';

// components
import { Spin, TreeSelect } from 'antd';

const { TreeNode } = TreeSelect;

const PREFIX_FOR_PARENT_OPTIONS = 'parent-';

// TODO: rework this component to use new Form components
const AccountCategoryTreeSelect = ({
  onSelect,
  onChange,
  value,
  canSelectOnlyParent,
  ...rest
}: any) => {
  const [isLoading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<IAccountCategoryShort[]>([]);

  useEffect(() => {
    if (!isLoading) {
      handleSearch('');
    }
  }, []);

  const fieldValue = useMemo(() => {
    if (value) {
      return canSelectOnlyParent
        ? `${PREFIX_FOR_PARENT_OPTIONS}${value}`
        : value;
    }

    return null;
  }, [value]);

  useEffect(() => {
    if (rest.initialOption && !dataSource.length) {
      const initialOption: IAccountCategoryShort = {
        id: rest.initialOption.id,
        accountCategoryName: rest.initialOption.content,
        children: [],
        isClient: false,
      };

      setDataSource([initialOption]);
    } else {
      setDataSource([]);
    }
  }, [rest.initialOption]);

  const fetchData = async (searchQuery: string) => {
    const categoriesResponse =
      await accountCategoriesAPI.searchAccountCategoryHierarchy({
        page: 1,
        limit: INFINITE_LIMIT,
        accountCategoryNameQuery: searchQuery ? searchQuery.trim() : '',
      });

    setDataSource(categoriesResponse.data || []);
    setLoading(false);
  };

  const debouncedFetchData = useCallback(
    debounce(fetchData, AUTOCOMPLETE_DEBOUNCE_DELAY),
    [],
  );

  const handleSearch = async (searchQuery: string) => {
    setLoading(true);
    debouncedFetchData(searchQuery);
  };

  // render tree nodes using recursion
  const renderTreeNodes = (
    nodes: IAccountCategoryShort[] | null,
    financialTypeName?: string,
  ) => {
    if (!nodes || !nodes.length || isLoading) {
      return null;
    }

    return nodes.map(({ id, accountCategoryName, children, isClient }) => {
      const value = children?.length ? `${PREFIX_FOR_PARENT_OPTIONS}${id}` : id;

      return (
        <TreeNode
          key={value}
          value={value}
          title={accountCategoryName}
          categoryId={id}
          selectable={
            canSelectOnlyParent ? !!children?.length : !children?.length
          }
          isClient={isClient}
          financialTypeName={financialTypeName}
        >
          {renderTreeNodes(children, financialTypeName || accountCategoryName)}
        </TreeNode>
      );
    });
  };

  const handleOnSelect = (
    newValueId: number,
    option: IAccountCategoryShort,
  ) => {
    if (onSelect) {
      if (
        canSelectOnlyParent &&
        String(newValueId).includes(PREFIX_FOR_PARENT_OPTIONS)
      ) {
        const formattedValue = String(newValueId).replace(
          PREFIX_FOR_PARENT_OPTIONS,
          '',
        );
        onSelect(Number(formattedValue), option);
      } else if (
        !canSelectOnlyParent &&
        !String(newValueId).includes(PREFIX_FOR_PARENT_OPTIONS)
      ) {
        onSelect(newValueId, option);
      }
    }
  };

  const handleOnChange = (newValueId: number) => {
    if (onChange) {
      if (
        canSelectOnlyParent &&
        String(newValueId).includes(PREFIX_FOR_PARENT_OPTIONS)
      ) {
        const formattedValue = String(newValueId).replace(
          PREFIX_FOR_PARENT_OPTIONS,
          '',
        );
        onChange(Number(formattedValue));
      } else if (
        !canSelectOnlyParent &&
        !String(newValueId).includes(PREFIX_FOR_PARENT_OPTIONS)
      ) {
        onChange(newValueId);
      }
    }
  };

  const handleOnClear = () => {
    handleSearch('');
    rest.onClear && rest.onClear();
  };

  return (
    <TreeSelect
      {...rest}
      allowClear
      showSearch
      filterTreeNode={false}
      treeDefaultExpandAll
      value={fieldValue}
      loading={isLoading}
      onSelect={handleOnSelect}
      onChange={handleOnChange}
      onClear={handleOnClear}
      onSearch={handleSearch}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      notFoundContent={
        isLoading ? (
          <LoaderWrapper>
            <Spin size="small" />
          </LoaderWrapper>
        ) : null
      }
    >
      {dataSource && renderTreeNodes(dataSource)}
    </TreeSelect>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
`;

export default AccountCategoryTreeSelect;
