import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';
import {
  ApprovalGroupBaseModel,
  ApprovalGroupModel,
  GroupParticipantModel,
  OnboardingTemplateBaseModel,
  OnboardingTemplateModel,
} from '../../../typings/approval/templates';

export type FinanceApprovalRule = {
  id: string;
  approvalRuleSets: ApprovalRuleSetModel[];
};

export interface ApprovalRuleSetModel {
  approvalThreasholds: ApprovalThreasholdModel[];
  approvalRuleItems: ApprovalRuleItemModel[];
}

export interface ApprovalThreasholdModel {
  threasholdMin: number | string;
  threasholdMax: number | string;
  ticked?: boolean;
}

export interface ApprovalRuleItemModel {
  minimunApprovalThreashold: number;
  approvalGroup: { id: string };
}

const financeTemplateAPI = {
  fetchApprovalGroupParticipants: () => {
    return APIService.get<GroupParticipantModel[]>(
      `${APIConfig.approvalApi}/finance/groups/participants`,
      {
        params: { page: 1, limit: -1 },
      },
    ).then(({ data }) => data);
  },

  fetchApprovalGroups: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<ApprovalGroupModel>>(
      `${APIConfig.approvalApi}/finance/groups`,
      { params },
    ).then(({ data }) => data);
  },

  fetchApprovalGroupById: (groupId: string) => {
    return APIService.get<ApprovalGroupModel>(
      `${APIConfig.approvalApi}/finance/groups/${groupId}`,
    ).then(({ data }) => data);
  },

  createApprovalGroup: (newGroup: ApprovalGroupBaseModel) => {
    return APIService.post<ApprovalGroupModel>(
      `${APIConfig.approvalApi}/finance/groups`,
      newGroup,
    ).then(({ data }) => data);
  },

  editApprovalGroup: (updatedGroup: ApprovalGroupModel) => {
    return APIService.patch<ApprovalGroupModel>(
      `${APIConfig.approvalApi}/finance/groups/${updatedGroup.id}`,
      updatedGroup,
    ).then(({ data }) => data);
  },

  fetchApprovalRuleTemplate: () => {
    return APIService.get<FinanceApprovalRule>(
      `${APIConfig.approvalApi}/finance/template/default`,
    ).then(({ data }) => data);
  },

  editApprovalRuleTemplate: (template: OnboardingTemplateModel) => {
    return APIService.patch<OnboardingTemplateModel>(
      `${APIConfig.approvalApi}/finance/template/${template.id}`,
      template,
    ).then(({ data }) => data);
  },

  createApprovalRuleTemplate: (template: OnboardingTemplateBaseModel) => {
    return APIService.post<OnboardingTemplateModel>(
      `${APIConfig.approvalApi}/finance/template`,
      template,
    ).then(({ data }) => data);
  },
};

export { financeTemplateAPI };
