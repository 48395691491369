import * as yup from 'yup';
import {
  NULLABLE_NUMBER_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
} from 'validations/common';

export const NewAccountCategoryValidationSchema = yup.object().shape({
  accountCategoryName: STRING_VALIDATION_RULE.min(
    2,
    'Account category name is too short',
  )
    .max(50, 'Account category name must be less than 50 characters')
    .required('This field is required'),
  isClient: yup.boolean().required('This field is required'),
  parentCategoryId: NULLABLE_NUMBER_VALIDATION_RULE.required(
    'This field is required',
  ),
});
