const USER_ACCESS_TOKEN = 'token';
const USER_REFRESH_TOKEN = 'refresh_token';

const LocalStorageHelpers = {
  getAccessToken: function (): string {
    return localStorage.getItem(USER_ACCESS_TOKEN) || '';
  },

  setAccessToken: function (token: string) {
    localStorage.setItem(USER_ACCESS_TOKEN, token);
  },

  getRefreshToken: function (): string {
    return localStorage.getItem(USER_REFRESH_TOKEN) || '';
  },

  setRefreshToken: function (token: string) {
    localStorage.setItem(USER_REFRESH_TOKEN, token);
  },

  setAuthenticationTokens: function (
    accessToken: string,
    refreshToken: string,
  ) {
    localStorage.setItem(USER_ACCESS_TOKEN, accessToken);
    localStorage.setItem(USER_REFRESH_TOKEN, refreshToken);
  },

  clear: function () {
    localStorage.clear();
  },
};

export { LocalStorageHelpers };
