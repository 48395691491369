import React from 'react';
import { StateModel } from '../redux/reducers';
import { RoutePaths } from '../routes/routes';
import { useSelector } from 'react-redux';
import { SubRouteLayoutProps } from '../typings/routes';
import { StateModel as AuthStateModel } from '../redux/reducers/auth';
import { Redirect, RouteComponentProps } from 'react-router-dom';

interface IComponentProps extends RouteComponentProps, SubRouteLayoutProps {}

const withEnabledFido = () => (Component: any) => {
  const WithEnabledFido = (componentProps: IComponentProps) => {
    const { profileData } = useSelector<StateModel, AuthStateModel>(
      (store) => store.auth,
    );

    const shouldNavigateToVerificationPage =
      !profileData?.isTwoFactorFidoEnabled;

    // Show enabling fido page
    if (componentProps.location.pathname === RoutePaths.VerificationFido) {
      return shouldNavigateToVerificationPage ? (
        <Component {...componentProps} />
      ) : (
        <Redirect to="/" {...componentProps} />
      );
    } else if (shouldNavigateToVerificationPage) {
      return <Redirect to={RoutePaths.VerificationFido} {...componentProps} />;
    }

    return <Component {...componentProps} />;
  };

  return WithEnabledFido;
};

export default withEnabledFido;
