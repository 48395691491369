// helpers utils
import React from 'react';
import { Redirect } from 'react-router-dom';
import { SubRouteModel } from '../../typings/routes';

// Routes
import { crmRoutes, CRMRoutesEnum } from './crmRoutes';
import { ORACRoutes, ORACRoutesEnum } from './ORACRoutes';
import { bankingRoutes, BankingRoutesEnum } from './bankingRoutes';
import { financeRoutes, FinancesRoutesEnum } from './financeRoutes';
import { reportingRoutes, ReportingRoutesEnum } from './reportingRoutes';
import { complianceRoutes, ComplianceRoutesEnum } from './complianceRoutes';
import {
  approvalManagementRoutes,
  ApprovalManagementRoutesEnum,
} from './approvalManagementRoutes';

// layouts
import PageLayout from '../../layouts/PageLayout';

enum UserRoutesEnum {
  UserRoutes_Root = '/',
}

const CombinedUserRouteEnums = {
  ...UserRoutesEnum,
  ...CRMRoutesEnum,
  ...ORACRoutesEnum,
  ...FinancesRoutesEnum,
  ...ReportingRoutesEnum,
  ...ApprovalManagementRoutesEnum,
  ...BankingRoutesEnum,
  ...ComplianceRoutesEnum,
};

const userRoutes: SubRouteModel[] = [
  {
    title: 'System Administration',
    path: UserRoutesEnum.UserRoutes_Root,
    layout: PageLayout,
    hideFromNavigation: true,
    component: () => <Redirect to={CRMRoutesEnum.CRM_Root} />,
  },
  ...complianceRoutes,
  ...crmRoutes,
  ...approvalManagementRoutes,
  ...financeRoutes,
  ...ORACRoutes,
  ...reportingRoutes,
  ...bankingRoutes,
];

export { userRoutes, CombinedUserRouteEnums, UserRoutesEnum };
