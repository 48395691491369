import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { BankingBankAccount } from '../../typings/banking/bankAccounts';
import { BankAccountStatuses } from 'enums/banking/accounts';
import { bankingBankAccountsAdapter } from '../../apiAdapters/banking/bankingBankAccountsAdapter';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';

interface FetchBankAccountsParams extends FetchParamsModel {
  accountQuery?: string;
  accountType?: string;
  isFrozen?: boolean | 'all';
  isActive?: boolean | 'all';
  status?: string;
}

export type BankingBankAccountFromQuery = {
  accountName: string;
  accountNumber: string;
  currencyId: number;
  currencyIsoCode: string;
  accountCategoryId: number;
  accountCategoryName: string;
  accountType: string;
  status: BankAccountStatuses;
  isFrozen: boolean;
  balance: number;
  balanceHome: number;
  financialTypeName: string;
  creationDate: number;

  purpose: string;
  correspondentBankAccountNumber: string;
  initialDepositOrigin: string;

  hasActiveComplianceWorkflow: boolean;

  initialDeposit: {
    min: number | null;
    max: number | null;
  };

  incomingWiresNumber: {
    min: number | null;
    max: number | null;
  };

  outgoingWiresNumber: {
    min: number | null;
    max: number | null;
  };

  estimatedIncomingFunds: {
    min: number | null;
    max: number | null;
  };

  estimatedOutgoingFunds: {
    min: number | null;
    max: number | null;
  };

  jurisdictions: string[];
  statistics: {
    avgEodBalanceSinceCreation: number;
    avgEodBalanceCurrentCalendarMonth: number;
    avgEodBalanceLast3Months: number;
    avgEodBalanceLast6Months: number;
    avgEomTxCountSinceCreation: number;
    avgEomTxCountCurrentCalendarMonth: number;
    avgEomTxCountLast3Months: number;
    avgEomTxCountLast6Months: number;
  };
};

export type UpdateBankAccountBody = {
  status: BankAccountStatuses;
  isFrozen: boolean;
};

export interface IBank {
  id: number;
  bankCode: string;
  bankCodeType: number | null;
  bankCodeTypeName: string;
  bankName: string;
  city: string;
  district?: string;
  branch: string;
  postCode: string;
  country: string;
  bankAddress1: string;
  bankAddress2: string;
}

export type BankCodeTypeFromQuery = {
  id: number;
  title: string;
};

const bankingBankAccountsAPI = {
  fetchBankAccounts: (params: FetchBankAccountsParams) => {
    return APIService.get<FetchResponseModel<BankingBankAccountFromQuery>>(
      `${APIConfig.accountingApi}/accounts/banking/search`,
      {
        params,
      },
    ).then<FetchResponseModel<BankingBankAccount>>(({ data }) =>
      bankingBankAccountsAdapter.fetchBankAccounts(data),
    );
  },

  fetchBankAccountByAccountNumber: (accountNumber: string) => {
    return APIService.get<BankingBankAccountFromQuery>(
      `${APIConfig.accountingApi}/accounts/banking/${accountNumber}`,
    ).then<BankingBankAccount>(({ data }) =>
      bankingBankAccountsAdapter.fetchBankAccountByAccountNumber(data),
    );
  },

  updateBankAccount: (
    accountNumber: string,
    updatedAccount: UpdateBankAccountBody,
  ) => {
    return APIService.patch(
      `${APIConfig.accountingApi}/accounts/${accountNumber}/status`,
      updatedAccount,
    ).then(({ data }) => data);
  },

  submitForComplianceReview: (accountNumber: string) => {
    return APIService.post(
      `${APIConfig.accountingApi}/accounts/banking/${accountNumber}/start-compliance-workflow`,
    ).then(({ data }) => data);
  },
};

export { bankingBankAccountsAPI };
