import React, { useEffect } from 'react';

// helpers
import { DISABLED_FORM_STATUS } from 'constants/form';

// components
import ExitConfirmation from '../ExitConfirmation';
import ConfirmationExitWithoutSaving from '../ConfirmationExitWithoutSaving';
import { Form as FormikForm, FormikProps } from 'formik';

interface IProps<Values> {
  form: FormikProps<Values>;
  onSave?: () => Promise<void>;
  disabled?: boolean;
  confirmExitWithoutSaving?: boolean;
  renderForm:
    | ((form: FormikProps<Values>) => React.ReactNode)
    | React.ReactNode
    | null;
}

function RenderForm<Values = unknown>({
  onSave,
  renderForm,
  form,
  confirmExitWithoutSaving,
  disabled,
  ...rest
}: IProps<Values>) {
  useEffect(() => {
    if (disabled) {
      form.setStatus(DISABLED_FORM_STATUS);
    } else {
      form.setStatus(null);
    }
  }, [disabled, form.status]);

  return (
    <FormikForm noValidate {...rest}>
      {typeof renderForm === 'function' ? renderForm(form) : renderForm}
      <ExitConfirmation />
      {confirmExitWithoutSaving && (
        <ConfirmationExitWithoutSaving onSave={onSave} />
      )}
    </FormikForm>
  );
}

export default RenderForm;
