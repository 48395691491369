import React, { useEffect } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { accountsAPI } from 'api/finance/account/accountsAPI';
import { useFormikContext } from 'formik';

// components
import FormInput from '@common_components/Form/FormInput';
import FormField from '@core_components/FormField';
import TrueFalseFormSelect from 'components/Forms/FormComponents/SelectInputs/TrueFalseFormSelect';
import AccountSupplementaryForm from '../AccountSupplementaryForm';
import CurrencyFormAutocomplete from 'components/Forms/FormComponents/Autocompletes/Finance/CurrencyFormAutocomplete';
import AccountCategoryTreeSelect from 'components/Forms/FormComponents/Autocompletes/Finance/AccountCategoryTreeSelect';
import ClientGroupsFormAutocomplete from 'components/Forms/FormComponents/Autocompletes/CRM/ClientGroupsFormAutocomplete';
import CorrespondentBankFormAutocomplete from 'components/Forms/FormComponents/Autocompletes/Finance/CorrespondentBanksFormAutocomplete';
import CorrespondentBankAccountsFormAutocomplete from 'components/Forms/FormComponents/Autocompletes/Finance/CorrespondentBankAccountsFormAutocomplete';
import { Col, Row } from 'antd';

export type FormValuesModel = {
  isReconciled: boolean | null;
  accountCategory: number | null;
  financialType: string;
  accountName: string;
  currency: number | null;
  isClient: boolean | null;

  isCorrespondentBank: boolean | null;
  correspondentBank: string | null;
  correspondentBankAccount: string | null;
  clientGroup: number | null;

  accountNumber: string | undefined;
  accountShortName: string;
  shouldAddShortName: boolean;

  isActive: boolean | null;
  isFrozen: boolean | null;
};

const BankAccountForm = () => {
  const { t } = useTranslation(['finance', 'form']);
  const { values, setValues, setFieldValue } =
    useFormikContext<FormValuesModel>();

  useEffect(() => {
    async function updateAccountNumber() {
      const { isClient, clientGroup, currency, accountCategory } = values;

      if (
        (isClient && currency && accountCategory && clientGroup) ||
        (!isClient && currency && accountCategory)
      ) {
        const accountInfo = {
          clientId: clientGroup || 0,
          currencyId: currency,
          accountCategoryId: accountCategory,
        };

        const { accountNumber, shouldAddShortName } =
          await accountsAPI.getAccountNumber(accountInfo);

        setValues({
          ...values,
          accountNumber,
          shouldAddShortName,
          accountShortName: shouldAddShortName ? values.accountShortName : '',
        });
      } else {
        if (values.accountNumber) {
          setValues({
            ...values,
            accountNumber: undefined,
            shouldAddShortName: false,
          });
        }
      }
    }

    updateAccountNumber();
  }, [
    values.isClient,
    values.clientGroup,
    values.currency,
    values.accountCategory,
  ]);

  const handleAccountCategorySelect = (
    newValue: number,
    item?: {
      financialTypeName: string;
      isClient: boolean;
      categoryId: number;
      title: string;
    },
  ) => {
    setValues({
      ...values,
      isClient: item?.isClient || null,
      financialType: item?.financialTypeName || '',
      accountCategory: newValue || null,
    });
  };

  const handleOnCurrencySelect = () => {
    setFieldValue('correspondentBank', null);
    setFieldValue('correspondentBankAccount', null);
  };

  const handleAccountCategoryClear = () => {
    setValues({
      ...values,
      accountCategory: null,
      financialType: '',
      isActive: null,
      isFrozen: null,

      accountNumber: '',
      accountShortName: '',
      shouldAddShortName: false,

      isCorrespondentBank: null,
      correspondentBank: null,
      correspondentBankAccount: null,
      clientGroup: null,
      accountName: '',
      currency: null,
      isClient: null,
    });
  };

  return (
    <>
      <FormField
        name="isReconciled"
        component={TrueFalseFormSelect}
        label={t('accounts.new.external_reconciliation')}
      />

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <FormField
            name="accountCategory"
            // TODO: rework AccountCategoryTreeSelect to use new formComponents
            component={AccountCategoryTreeSelect}
            label={t('accounts.new.account_category')}
            additionalProps={{
              onSelect: handleAccountCategorySelect,
              onClear: handleAccountCategoryClear,
            }}
            // TODO: remove placeholder when AccountCategoryTreeSelect is reworked
            placeholder={t('field_placeholders.select_required', {
              ns: 'form',
            })}
          />
        </Col>

        <Col span={12}>
          <FormField
            disabled
            name="financialType"
            component={FormInput}
            label={t('accounts.new.financial_type')}
          />
        </Col>
      </Row>

      <FormField
        label={t('accounts.new.account_name')}
        name="accountName"
        component={FormInput}
      />

      <FormField
        label={t('accounts.new.currency')}
        name="currency"
        component={CurrencyFormAutocomplete}
        additionalProps={{ onSelect: handleOnCurrencySelect }}
      />

      {values.accountCategory ? (
        <>
          {!values.isClient ? (
            <>
              <FormField
                label={t('accounts.new.is_correspondent_bank')}
                name="isCorrespondentBank"
                component={TrueFalseFormSelect}
              />
              {values.isCorrespondentBank ? (
                <FormField
                  name="correspondentBank"
                  label={t('accounts.new.correspondent_bank')}
                  component={CorrespondentBankFormAutocomplete}
                  disabled={!values.isCorrespondentBank || !values.currency}
                  additionalProps={{ currencyId: values.currency }}
                />
              ) : null}
            </>
          ) : (
            <>
              <FormField
                name="correspondentBankAccount"
                label={t('accounts.new.correspondent_bank_account')}
                component={CorrespondentBankAccountsFormAutocomplete}
                disabled={!values.currency}
                additionalProps={{ currencyId: values.currency }}
              />
              <FormField
                label={t('accounts.new.client_group')}
                name="clientGroup"
                component={ClientGroupsFormAutocomplete}
              />
            </>
          )}
        </>
      ) : null}

      {values.accountNumber ? (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <FormField
              disabled
              name="accountNumber"
              component={FormInput}
              label={t('accounts.new.account_number')}
            />
          </Col>

          {values.shouldAddShortName && (
            <Col span={12}>
              <FormField
                component={FormInput}
                name="accountShortName"
                label={t('accounts.new.account_short_name')}
              />
            </Col>
          )}
        </Row>
      ) : null}

      {values.isClient ||
      (!values.isClient && values.isCorrespondentBank === false) ? (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <FormField
              name="isActive"
              label={t('accounts.new.active')}
              component={TrueFalseFormSelect}
              additionalProps={{
                trueLabel: t('accounts.new.active'),
                falseLabel: t('accounts.new.inactive'),
              }}
            />
          </Col>

          <Col span={12}>
            <FormField
              name="isFrozen"
              label={t('accounts.new.frozen')}
              component={TrueFalseFormSelect}
              additionalProps={{
                trueLabel: t('accounts.new.frozen'),
                falseLabel: t('accounts.new.unfrozen'),
              }}
            />
          </Col>
        </Row>
      ) : null}

      {values.isClient ? (
        <AccountSupplementaryForm namePrefix="accountSupplementary" />
      ) : null}
    </>
  );
};

export default BankAccountForm;
