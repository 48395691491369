import { FORMAT_AMOUNT_REG } from '../../constants/regularExpressions';
import { JournalEntryModel } from '../../components/Modals/TemplateModalDialogs/Finance/AddViewJournalModal/InnerForm/JournalForm';
import { IJournalEntryModelNew } from '../../typings/finance/journal';
import { RangeOption } from 'typings/banking/bankAccounts';

const AccountsHelpers = {
  convertAmountFromBigIntToInt: function (amount: string | number): number {
    return +amount / 10000;
  },

  convertAmountFromIntToBigInt: function (amount: number): number {
    return Math.round(amount * 10000);
  },

  formatAmountToLocaleString: function (
    amount: number,
    minDigits = 2,
    maxDigits = 2,
  ) {
    if (typeof amount === 'undefined') {
      return '';
    }

    return amount.toLocaleString(undefined, {
      minimumFractionDigits: minDigits,
      maximumFractionDigits: maxDigits,
    });
  },

  convertAmountBigIntToLocaleString: function (
    amount: string | number,
    minDigits = 2,
    maxDigits = 2,
  ): string {
    const amountInt = AccountsHelpers.convertAmountFromBigIntToInt(amount);
    return AccountsHelpers.formatAmountToLocaleString(
      amountInt,
      minDigits,
      maxDigits,
    );
  },

  roundNumber: function (number: number, precision: number, isDown?: boolean) {
    const factor = Math.pow(10, precision);

    const tempNumber = number * factor;
    let roundedTempNumber = 0;

    if (isDown) {
      roundedTempNumber = Math.round(-tempNumber) * -1;
    } else {
      roundedTempNumber = Math.round(tempNumber);
    }

    return roundedTempNumber / factor;
  },

  getTransferTemplateName: function (templateId: string, templateName: string) {
    return `${templateId} - ${templateName}`;
  },

  generateAccountFullName: function (
    accountName: string,
    accountNumber: string,
    currencyName: string,
    balance?: number,
  ) {
    return `${accountName} - ${accountNumber} ${currencyName} ${balance ? AccountsHelpers.formatAmountToLocaleString(balance) : ''}`;
  },

  // TODO: consider removing this function
  formatMoneyToStringWithRounding: function (amount: number) {
    // format amount from BigInt to Int
    const formattedIntMoney = parseInt(`${amount}`) / 10000;

    const roundedValue = AccountsHelpers.roundNumber(
      formattedIntMoney,
      2,
    ).toFixed(2);

    return AccountsHelpers.formatMoneyToString(roundedValue);
  },

  // TODO: consider removing this function
  formatMoneyToString: function (amount: string | number, round?: boolean) {
    let result = amount;

    if (round) {
      result = AccountsHelpers.roundNumber(+amount, 2);
    }

    return String(result).replace(FORMAT_AMOUNT_REG, ',');
  },

  formatJournalEntryToFormJournalEntry: function (
    journalEntries: IJournalEntryModelNew[],
  ): JournalEntryModel[] {
    return journalEntries.map((e) => ({
      id: e.id,
      currencyRate: AccountsHelpers.convertAmountFromBigIntToInt(
        e.homeCurrencyRate,
      ),
      accountNumber: e.accountNumber,
      accountName: e.accountName,
      description: e.description,
      relatedPartyId: e.relatedParty ? String(e.relatedParty) : undefined,
      relatedPartyType: e.relatedPartyType || undefined,
      relatedPartyInitialOption: e.relatedParty
        ? {
            id: e.relatedParty,
            content: e.relatedPartyName,
          }
        : undefined,
      isResident: e.isResident,
      debitHome: e.debitHomeAmount
        ? AccountsHelpers.convertAmountFromBigIntToInt(e.debitHomeAmount)
        : 0,
      creditHome: e.creditHomeAmount
        ? AccountsHelpers.convertAmountFromBigIntToInt(e.creditHomeAmount)
        : 0,
      creditAmount: e.creditAmount
        ? AccountsHelpers.convertAmountFromBigIntToInt(e.creditAmount)
        : undefined,
      debitAmount: e.debitAmount
        ? AccountsHelpers.convertAmountFromBigIntToInt(e.debitAmount)
        : undefined,
      account: {
        isoCode: e.currencyIsoCode,
        accountNumber: e.accountNumber,
        financialTypeName: e.financialTypeName,
      },
      documents: e.documents.map((e) => ({
        id: e.dmsId,
        name: e.name,
        fileId: e.linkToDownload,
        file: null,
      })),
      journalPostingDate: e.postingDate,
      isVisibleForClient: e.areDocumentsVisible,
    }));
  },

  getKeyOfRangeByRangeOption(
    option: RangeOption,
    options: Record<string, RangeOption>,
  ) {
    const { min, max } = option;

    return Object.keys(options).find(
      (key) =>
        (options[key].min === min || (!options[key]?.min && !min)) &&
        (options[key]?.max === max || (!options[key]?.max && !max)),
    );
  },
};

export { AccountsHelpers };
