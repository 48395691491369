import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { GroupBaseModel } from '../../../../../typings/ORAC/groups';

// constants
import { DEFAULT_FULL_DATE_FORMAT } from '../../../../../constants/global';

// components
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

interface IProps {
  group: GroupBaseModel;
}

const GroupInfo = ({ group }: IProps) => {
  const { t } = useTranslation('orac');

  const descriptionsData = useMemo<DescriptionItem[]>(() => {
    return [
      { label: t('groups.view.id'), description: group.id },
      { label: t('groups.view.name'), description: group.name },
      { label: t('groups.view.description'), description: group.description },
      {
        label: t('groups.view.created_at'),
        description: DateHelpers.formatDateToString(
          group.createdAt,
          DEFAULT_FULL_DATE_FORMAT,
        ),
      },
      {
        label: t('groups.view.updated_at'),
        description: DateHelpers.formatDateToString(
          group.updatedAt,
          DEFAULT_FULL_DATE_FORMAT,
        ),
      },
    ];
  }, [group]);

  return (
    <StyledDescriptionSection
      data={descriptionsData}
      bordered={false}
      background="transparent"
    />
  );
};

const StyledDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-container {
    border-bottom: 1px solid ${({ theme }) => theme.tableBorderColor};
    padding-bottom: ${({ theme }) => theme.paddingXs};
  }

  .ant-descriptions-item-label {
    display: flex;
    align-items: end;
  }

  &.ant-descriptions-middle .ant-descriptions-row > td {
    padding-bottom: ${({ theme }) => theme.paddingXs};
  }
`;

export default GroupInfo;
