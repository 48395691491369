import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';

import { ExpectedDataTypes } from '../../../../../../enums/compliance/reviewConfiguration';
import { SelectProps } from 'antd/lib/select';

// components
import { Select } from 'antd';
import { enumToMap } from '../../../../../../helpers/utils';

type IProps = SelectProps<any>;

const ExpectedDataTypeSelect = ({ value, ...rest }: IProps) => {
  const { t } = useTranslation('compliance');

  const renderOptions = () => {
    return Array.from(enumToMap(ExpectedDataTypes)).map((value) => (
      <Select.Option key={value[1]} value={value[1]}>
        {t(`data_type.${value[0]}`)}
      </Select.Option>
    ));
  };

  return (
    <Select
      {...rest}
      value={value}
      showSearch
      placeholder={t('review_configuration.expected_data_type_ph')}
    >
      {renderOptions()}
    </Select>
  );
};

export default ExpectedDataTypeSelect;
