import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { PhoneNumberType } from 'enums/crm/crm';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type PhoneNumberFormSelectProps = SelectProps;

const PhoneNumberFormSelect = (props: PhoneNumberFormSelectProps) => {
  const { t } = useTranslation('crm');

  const options = useMemo<SelectOption[]>(() => {
    return [
      PhoneNumberType.Home,
      PhoneNumberType.Work,
      PhoneNumberType.Mobile,
      PhoneNumberType.Other,
    ].map((key) => ({
      id: key,
      label: t(`phone_types.${key}`),
    }));
  }, [t]);

  return <FormSelect {...props} options={options} />;
};

export default PhoneNumberFormSelect;
