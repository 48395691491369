import React, { useMemo, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { FormikHelpers } from 'formik';
import { approvalTemplatesAdapter } from 'apiAdapters/approval/templates/approvalTemplatesAdapter';
import { ApprovalMatrixThresholdBaseModel } from 'typings/approval/templates';
import { ApprovalRuleTemplateFromQueryToTemplate } from 'apiAdapters/approval/clientAccountManagementRulesAdapterAPI';
import {
  ApprovalRuleTemplateFromQuery,
  clientAccountManagementRulesAPI,
} from 'api/approval/approvalManagement/clientAccountManagementRulesAPI';

// components
import Text from '@core_components/Text';
import StatusLabel from 'components/Typography/StatusLabel';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ApprovalManagementMatrix, {
  FormValuesModel,
} from 'components/Forms/FormComponents/ApprovalManagementMatrix';

interface IProps {
  clientGroupId: number;
}

const ApprovalRulesTab = ({ clientGroupId }: IProps) => {
  const { t } = useTranslation('crm');

  const [updateTrigger, setUpdateTrigger] = useState({});
  const [hasEditRuleAccess] = useUserAccess([
    'CRM_ClientGroup_Details_AdministrationRules_ApprovalRules_Edit',
  ]);
  const { response: template, loading: templateLoader } = useFetch(
    () =>
      clientGroupId
        ? clientAccountManagementRulesAPI.fetchApprovalTemplate(clientGroupId)
        : null,
    [clientGroupId, updateTrigger],
  );

  const { response: types, loading: typesLoader } = useFetch(
    () => clientAccountManagementRulesAPI.fetchApprovalTemplateTypes(),
    [],
  );

  const initialFormValues: FormValuesModel | null = useMemo(() => {
    let result = null;

    if (template && types) {
      result = generateInitialFormValuesBasedOnTemplate(
        template,
        types,
        clientGroupId,
      );
    }
    return result;
  }, [template, types, clientGroupId]);

  function generateInitialFormValuesBasedOnTemplate(
    initialRules: ApprovalRuleTemplateFromQueryToTemplate,
    limits: ApprovalMatrixThresholdBaseModel[],
    clientGroupId: number,
  ) {
    const result: FormValuesModel = {
      id: initialRules.id,
      isEditMode: false,
      approvalWorkflowType: 'administration',
      approvalConditions: [],
    };

    const baseThreasholdStatuses: { [key: string]: { ticked: boolean } } = {};
    limits.forEach(
      ({ id }) => (baseThreasholdStatuses[id] = { ticked: false }),
    );

    result.approvalConditions = initialRules.approvalRuleSets.map(
      (condition) => {
        // Fill threshold statuses based on received information
        // By default we should take info from limits array
        const thresholdStatuses = { ...baseThreasholdStatuses };

        condition.approvalThreasholds.forEach(({ threasholdMin, ticked }) => {
          thresholdStatuses[threasholdMin] = { ticked: !!ticked };
        });

        const approvalGroupConditions = condition.approvalRuleItems.map(
          ({ approvalGroup, minimunApprovalThreashold }) => {
            const approvalGroupFull = approvalGroup;
            const maximumRequiredApprovers =
              approvalGroupFull?.participants?.length || 0;
            const initialAutocompleteValue = {
              id: approvalGroupFull?.id as string,
              content: approvalGroupFull?.name,
            };

            return {
              maximumRequiredApprovers,
              initialAutocompleteValue,
              requiredApprovers: minimunApprovalThreashold,
              approvalGroupId: approvalGroup.id,
            };
          },
        );

        return { approvalGroupConditions, thresholdStatuses };
      },
    );

    return { ...result, clientGroupId };
  }

  const renderStatus = (template: ApprovalRuleTemplateFromQuery | null) => {
    let result = null;

    if (template && template.isDeleted) {
      result = (
        <StatusLabel
          status="error"
          text={t(
            'client_group.approval.client_approval_groups.statuses.deactivated',
          )}
        />
      );
    } else {
      result = template?.isActive ? (
        <StatusLabel
          status="success"
          text={t(
            'client_group.approval.client_approval_groups.statuses.active',
          )}
        />
      ) : (
        <StatusLabel
          status="warning"
          text={t(
            'client_group.approval.client_approval_groups.statuses.in-review',
          )}
        />
      );
    }

    return result;
  };

  const handleSubmit = async (
    values: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    if (template) {
      const response =
        approvalTemplatesAdapter.editApprovalRuleTemplate(values);
      await clientAccountManagementRulesAPI.updateAdministrationApprovalRule(
        response,
        clientGroupId,
      );
      formikHelpers.setFieldValue('isEditMode', false);
      setUpdateTrigger({});
      message.success(
        t(
          'client_group.approval.approval_rule_modal.success_submit_for_account_management_review_message',
        ),
      );
    }
  };

  return (
    <LoadingWrapper loading={templateLoader || typesLoader}>
      {initialFormValues && (
        <>
          <Text>
            {t('client_group.approval.client_approval_groups.table.status')}:{' '}
            {renderStatus(template)}
          </Text>
          <ApprovalManagementMatrix
            limits={types as ApprovalMatrixThresholdBaseModel[]}
            onSubmit={handleSubmit}
            initialFormValues={initialFormValues}
            approvalWorkflowType="administration"
            hasEditAccess={
              !!template?.isActive && !template.isDeleted && hasEditRuleAccess
            }
          />
        </>
      )}
    </LoadingWrapper>
  );
};

export default ApprovalRulesTab;
