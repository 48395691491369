export interface IFileTypes {
  [key: string]: {
    shortType: string;
  };
}

export const FILE_TYPES: IFileTypes = {
  'application/pdf': {
    shortType: 'PDF',
  },
  'application/xml': {
    shortType: 'XML',
  },
  'application/vnd.ms-excel': {
    shortType: 'MS Excel (.xls)',
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    shortType: 'MS Excel (.xlsx)',
  },
  'application/vnd.ms-powerpoint': {
    shortType: 'MS PowerPoint (.ppt)',
  },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    shortType: 'MS PowerPoint (.pptx)',
  },
  'application/msword': {
    shortType: 'MS Word (.doc)',
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    shortType: 'MS Word (.docx)',
  },
  'text/csv': {
    shortType: 'CSV',
  },
  'text/tab-separated-values': {
    shortType: 'TSV',
  },
  'text/plain': {
    shortType: 'TXT (.txt)',
  },
  'image/png': {
    shortType: 'PNG',
  },
  'image/jpeg': {
    shortType: 'JPEG',
  },
  'image/jpg': {
    shortType: 'JPG',
  },

  'message/rfc822': {
    shortType: 'EML',
  },

  'application/vnd.ms-outlook': {
    shortType: 'MSG',
  },

  // there is no standard mime type for .eml and .msg
};

export const ALLOWED_UPLOAD_TYPES =
  '.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.eml,.msg';
export const ALLOWED_ATTACHMENT_DOCUMENT_TYPES =
  '.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.jpg,.jpeg,.png,.eml,.msg';

export const ALLOWED_VERIFICATION_DOCUMENT_TYPES = '.jpg,.png';

export enum UploadStatus {
  WAIT = 'WAIT',
  UPLOADING = 'UPLOADING',
  DONE = 'DONE',
  FAILED = 'FAILED',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export const MESSAGE_ATTACHMENT_TAGS = ['attachment', 'client', 'message'];
