import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';

// components
import { SearchInput } from 'components/Fields/SearchInput';
import { Col, Row, Select, Typography } from 'antd';

interface IProps {
  filterValue: string;
  handleChange: (key: string, value: any) => void;
}

const FilterBar = ({ filterValue, handleChange }: IProps) => {
  const { t } = useTranslation(['crm', 'finance', 'common']);

  const gridSizes = { xl: 6, lg: 8, md: 12, sm: 24, xs: 24 };

  const handleSelectChange = (value: any) =>
    handleChange('filter-change', value);

  return (
    <Row gutter={[16, 16]}>
      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('entity_type_category.search_title')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <SearchInput
              size="large"
              placeholder={t('account_categories.table.search_placeholder', {
                ns: 'finance',
              })}
              onSearch={(searchQuery: string) =>
                handleChange('search', searchQuery)
              }
            />
          </Col>
        </Row>
      </Col>
      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('account_categories.table.filter_by_onboarding_question', {
                ns: 'finance',
              })}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <StyledSelect
              value={filterValue}
              size="large"
              placeholder={t('currencies.select_account_currency', {
                ns: 'finance',
              })}
              onChange={handleSelectChange}
            >
              <Select.Option value="">
                {t('all', { ns: 'common' })}
              </Select.Option>
              <Select.Option value="yes">
                {t('yes', { ns: 'common' })}
              </Select.Option>
              <Select.Option value="no">
                {t('no', { ns: 'common' })}
              </Select.Option>
            </StyledSelect>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default FilterBar;
