import React from 'react';

// helpers
import styled from 'styled-components';
import { Moment } from 'moment';
import { StyledComponentProps } from 'typings/common';

// components
import { DatePicker as DatePickerAntD } from 'antd';

const DatePickerRoot = DatePickerAntD as any;

export interface DatePickerProps extends StyledComponentProps {
  value?: Moment;
  placeholder?: string;
  disabled?: boolean;
}

const DatePicker = ({ placeholder, ...rest }: DatePickerProps) => {
  const dateRender = (currentDate: Moment, today: Moment) => {
    return (
      <StyledDate
        className="ant-picker-cell-inner"
        isFuture={today.diff(currentDate) < 0}
      >
        {currentDate.date()}
      </StyledDate>
    );
  };

  return (
    <DatePickerRoot
      {...rest}
      dateRender={dateRender}
      allowClear
      placeholder={placeholder}
    />
  );
};

const StyledDate = styled.div<{ isFuture: boolean }>`
  color: ${({ isFuture, theme }) =>
    isFuture ? theme.successColor : 'none'} !important;
`;

export default DatePicker;
