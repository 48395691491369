import React, { memo, useEffect, useState } from 'react';

// helpers
import useUserAccess from '../../hooks/useUserAccess';
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_BANKING_TRANSACTIONS_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import AddTransaction from '../../modules/Banking/AddTransaction';
import TransactionsTable from '../../modules/Banking/TransactionsTable';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const TransactionsPage = memo(({ setHeaderOptions }: IProps) => {
  const [hasCreateAccess] = useUserAccess(['Banking_Transactions_Create']);
  const [updateTableTrigger, updateTable] = useState<unknown>();

  useEffect(() => {
    if (hasCreateAccess) {
      setHeaderOptions({
        appendToTitle: (
          <TooltipToDocumentation
            link={GENESIS_BANKING_TRANSACTIONS_DOCUMENTATION_LINK}
          />
        ),
        extra: <AddTransaction onAdd={() => updateTable({})} />,
      });
    }
  }, [hasCreateAccess]);

  return <TransactionsTable updateTransactionsTrigger={updateTableTrigger} />;
});

export default TransactionsPage;
