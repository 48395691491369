import React from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';

// components
import Text from '../../../../components/DesignSystem/Core/Text';
import { Col, Row } from 'antd';
import { SearchInput } from 'components/Fields/SearchInput';

export type FilterKeys = 'search';

interface IProps {
  onFilterBarChange: (key: FilterKeys, value: string) => void;
}

const FilterBar = ({ onFilterBarChange }: IProps) => {
  const { t } = useTranslation(['banking', 'common']);

  return (
    <Row gutter={[16, 16]}>
      <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
        <Text>{t('search_by', { ns: 'common' })}</Text>
        <SearchInput
          allowClear
          size="large"
          type="search_by_account_number_name"
          placeholder={t(
            'banking.transactions.filter_bar.search_by_placeholder',
          )}
          onSearch={(searchQuery: string) =>
            onFilterBarChange('search', searchQuery)
          }
        />
      </Col>
    </Row>
  );
};

export default FilterBar;
