import { IEntity } from '../../../typings/crm/entity';
import { EntityHelpers } from '@helpers/crm/entity';
import { IContactModel } from '../../../typings/crm/contact';
import { ContactHelpers } from '@helpers/crm/contact';
import { RelationshipHelpers } from '@helpers/crm/relationship';
import { GraphData, GraphNode } from '../../../components/Charts/GraphChart';
import { AdditionalFieldHelpers } from '@helpers/additionalField';
import { IKeyRelationshipInstance } from '../../../typings/crm/onboarding';
import { IRelationshipTemplateModel } from '../../../typings/crm/relationship-template';
import { FormValuesModel as EditNodeFormValuesModel } from 'components/Modals/TemplateModalDialogs/CRM/EditNodeModalDialog';
import { FormValuesModel as AddNewNodeFormValuesModel } from 'components/Modals/TemplateModalDialogs/CRM/AddNewNodeModalDialog';
import { FormValuesModel as ContactInfoFormValuesModel } from 'components/Forms/TemplateForms/CRM/ContactShortInfoForm';
import { FormValuesModel as EditRelationshipFormValues } from '../../../components/Modals/TemplateModalDialogs/CRM/EditRelationshipModalDialog';
import { FormValuesModel as CreateRelationshipFormValues } from '../../../components/Modals/TemplateModalDialogs/CRM/AddRelationshipModalDialog';
import { FormValuesModel as OrganizationInfoFormValuesModel } from 'components/Forms/TemplateForms/CRM/OrganizationShortInfoForm';
import {
  AdditionalFieldModel,
  Node,
  Relationship,
  RelationshipsGraph,
} from '../../../typings/crm/relationships-graph';
import {
  AdditionalFieldType,
  OnboardType,
  AdministrationPermissionTypes,
  RelationshipTrackType,
} from '../../../enums/crm/crm';
import {
  NewRelationshipModel,
  AdditionalFieldAnswerModel,
  CreateRelationshipsBodyModel,
  UpdateRelationshipBodyModel,
  ModifyRelationshipsBodyModel,
  CreateRelationshipOptionModel,
  IContactCreateShortModel,
  UpdateRelationshipOptionModel,
  RemoveRelationshipOptionModel,
  UpdateRelationshipMemberModel,
  IOrganizationUpdateShortModel,
  UpdateRelationshipMemberContactModel,
  UpdateRelationshipMemberOrganizationModel,
} from '../../../typings/crm/relationships';

const getFormattedUpdateNodeData = (
  node: ContactInfoFormValuesModel | OrganizationInfoFormValuesModel,
  type: OnboardType,
  addUserAccessData?: boolean,
): UpdateRelationshipMemberModel => {
  switch (type) {
    case OnboardType.Contact: {
      const contact = node as ContactInfoFormValuesModel;

      const result: UpdateRelationshipMemberContactModel = {
        type: 'contact',
        model: {
          firstName: contact.firstName ? contact.firstName.trim() : '',
          middleName: contact.middleName ? contact.middleName.trim() : '',
          lastName: contact.lastName ? contact.lastName.trim() : '',
          isPEP: !!contact.isPEP,
          email: contact.email,
          phoneNumber: contact.phone,
          pepInformation: contact.isPEP
            ? String(contact.pepInformation).trim()
            : '',
        },
      };

      if (contact.isPermissionsSelectAvailable || addUserAccessData) {
        result.model = {
          ...result.model,
          onlineAccess: contact?.isOnlineUser
            ? {
                adminPermissionType:
                  !contact.adminPermissionType ||
                  contact.adminPermissionType ==
                    AdministrationPermissionTypes.LimitedAdmin
                    ? null
                    : contact.adminPermissionType,
                permission: contact.accountPermissions,
              }
            : null,
        };
      }

      return result;
    }

    case OnboardType.Organization: {
      const organization = node as OrganizationInfoFormValuesModel;

      const result: UpdateRelationshipMemberOrganizationModel = {
        type: 'organization',
        model: {
          legalName: String(organization?.name).trim(),
          isRegulated: !!organization?.isRegulated,
          regulationCountry: organization?.isRegulated
            ? organization?.regulatedCountries
            : [],
        },
      };

      return result;
    }
  }
};

const getFormattedCreateNodeData = (
  node: ContactInfoFormValuesModel | OrganizationInfoFormValuesModel,
  type: OnboardType,
  keyRelationshipTemplateId: string,
): IOrganizationUpdateShortModel | IContactCreateShortModel => {
  switch (type) {
    case OnboardType.Contact: {
      const contact = node as ContactInfoFormValuesModel;

      let result: IContactCreateShortModel = {
        firstName: contact.firstName ? contact.firstName.trim() : '',
        middleName: contact.middleName ? contact.middleName.trim() : '',
        lastName: contact.lastName ? contact.lastName.trim() : '',
        isPEP: !!contact.isPEP,
        pepInformation: contact.isPEP
          ? String(contact.pepInformation).trim()
          : '',
        email: contact.email ? contact.email.trim() : '',
        phoneNumber: contact.phone ? contact.phone.trim() : '',
      };

      if (contact.isPermissionsSelectAvailable) {
        result = {
          ...result,
          onlineAccess: contact?.isOnlineUser
            ? {
                adminPermissionType:
                  !contact.adminPermissionType ||
                  contact.adminPermissionType ==
                    AdministrationPermissionTypes.LimitedAdmin
                    ? null
                    : contact.adminPermissionType,
                permission: contact.accountPermissions,
              }
            : undefined,
        };
      }

      return result;
    }

    case OnboardType.Organization: {
      const organization = node as OrganizationInfoFormValuesModel;

      const result = {
        keyRelationshipTemplateId,
        legalName: String(organization?.name).trim(),
        isRegulated: !!organization?.isRegulated,
        regulationCountry: organization?.isRegulated
          ? organization?.regulatedCountries
          : [],
      };

      return result;
    }
  }
};
const getAdditionalFieldValueFromJSON = (
  valueJSON: string,
  type: AdditionalFieldType,
): string | number => {
  if (
    [
      AdditionalFieldType.Date,
      AdditionalFieldType.String,
      AdditionalFieldType.Number,
      AdditionalFieldType.Percentage,
      AdditionalFieldType.Document,
    ].includes(type)
  ) {
    return JSON.parse(valueJSON) as string | number;
  }

  throw new Error(`Not Supportered type additional field type: '${type}'`);
};

function getRelationshipValue(
  additionalFields: AdditionalFieldModel[],
  fieldKey: string,
) {
  const field = additionalFields.find(({ name }) => name === fieldKey);
  return getAdditionalFieldValueFromJSON(
    field?.valueJSON || '""',
    field?.type || AdditionalFieldType.String,
  );
}

function getRelationshipLabel(
  relationship: Relationship,
  relTemplate: IRelationshipTemplateModel,
) {
  let result = '';
  const relationshipName = RelationshipHelpers.getRelationshipName(
    { childName: relTemplate.childName, parentName: relTemplate.parentName },
    RelationshipTrackType.Parent,
  );

  const percentageField = relationship.additionalFields.find(
    ({ type }) => type === AdditionalFieldType.Percentage,
  )?.name;

  if (percentageField) {
    const relationshipAdditionalFieldValue = getRelationshipValue(
      relationship.additionalFields,
      percentageField,
    );
    result = `${relationshipAdditionalFieldValue}% - ${relationshipName}`;
  } else {
    result = relationshipName;
  }

  return result;
}

const relationshipAdapter = {
  fetchClientGroupRelationshipsGraph: (
    graphData: RelationshipsGraph,
    directClientId: string,
    applicantOrganizationId?: string,
    applicantContactId?: string,
  ): GraphData => {
    const result: GraphData = {
      nodes: [],
      edges: [],
    };

    const { graph, relationshipTemplates, keyRelationshipTemplates } =
      graphData;
    const { nodes, edges } = graph;

    const hashedKeyRelTemplates = keyRelationshipTemplates.reduce<
      Record<string, IKeyRelationshipInstance>
    >((acc, next) => {
      acc[next._id] = next;
      return acc;
    }, {});

    const hashedRelTemplates = relationshipTemplates.reduce<
      Record<string, IRelationshipTemplateModel>
    >((acc, next) => {
      acc[next._id] = next;
      return acc;
    }, {});

    const hashedNodes = nodes.reduce<Record<string, Node>>((acc, next) => {
      acc[next.id] = next;
      return acc;
    }, {});

    result.nodes = nodes.map<GraphNode>((e) => {
      const isApplicant = e.id === directClientId;
      const isApplicantOrganization =
        e.type === OnboardType.Organization && e.id === applicantOrganizationId;
      const isApplicantContact =
        e.type === OnboardType.Contact && e.id === applicantContactId;
      return {
        id: e.id,
        label:
          e.type === OnboardType.Contact
            ? ContactHelpers.getContactName(e.model as IContactModel)
            : EntityHelpers.getEntityName(e.model as IEntity),
        typeLabel: e.model.keyRelationshipTemplateId
          ? hashedKeyRelTemplates[e.model.keyRelationshipTemplateId]?.name
          : '',
        entryType: e.type,
        model: {
          ...e.model,
          keyRelationshipTemplate:
            hashedKeyRelTemplates[
              e.model.keyRelationshipInstance?.templateId || ''
            ],
        },
        onboardingProcess: e.onboardingProcess,
        isApplicant,
        isApplicantContact,
        isApplicantOrganization,
        sharedData: !!e.onboardingProcess?.isShared,
      };
    });

    result.edges = edges.map((e) => {
      const relatedKeyRelationshipId = hashedNodes[e.child.id].model
        .keyRelationshipTemplateId as string;
      const isOwnership =
        hashedRelTemplates[e.metadata.relationship.relationshipTemplateId] &&
        hashedKeyRelTemplates[relatedKeyRelationshipId]
          ? RelationshipHelpers.isRelationshipTemplateOwnership(
              hashedRelTemplates[
                e.metadata.relationship.relationshipTemplateId
              ],
              hashedKeyRelTemplates[relatedKeyRelationshipId],
            )
          : false;

      return {
        source: e.parent.id,
        target: e.child.id,
        label: getRelationshipLabel(
          e.metadata.relationship,
          hashedRelTemplates[e.metadata.relationship.relationshipTemplateId],
        ),
        isOwnership,
        relationship: e.metadata.relationship,
        relationshipTemplate:
          hashedRelTemplates[e.metadata.relationship.relationshipTemplateId],
      };
    });

    return result;
  },

  generateRequestBodyToCreateRelationship: (
    formValues: CreateRelationshipFormValues,
  ): CreateRelationshipsBodyModel => {
    const { relationships, entryType, entryId } = formValues;

    const newRelationships: NewRelationshipModel[] =
      relationships.map<NewRelationshipModel>((e) => {
        return {
          child: {
            id: e.child.id as string,
            type: e.child.type as OnboardType,
          },
          relationshipTemplateId: e.relationshipTemplate.id as string,
          additionalFields: e.additionalFields.map((af) => ({
            name: af.name,
            type: af.type,
            _id: af._id,
            valueJSON: AdditionalFieldHelpers.formatToJSON(af.type, af.value),
          })),
        };
      });

    return {
      parent: {
        id: entryId,
        type: entryType,
      },
      relationships: [...newRelationships],
    };
  },

  generateRequestBodyForEditRelationship: (
    formValues: EditRelationshipFormValues,
  ): UpdateRelationshipBodyModel => {
    let additionalFields: AdditionalFieldAnswerModel[] = [];

    if (formValues.relationship) {
      additionalFields = formValues.relationship.additionalFields.reduce<
        AdditionalFieldAnswerModel[]
      >((acc, next) => {
        if (!next.isRemoved) {
          acc.push({
            name: next.name,
            type: next.type,
            valueJSON: AdditionalFieldHelpers.formatToJSON(
              next.type,
              next.value,
            ),
            _id: next._id,
          });
        }
        return acc;
      }, []);
    }

    return { additionalFields };
  },

  generateRequestBodyToCreateNode: (
    formValues: AddNewNodeFormValuesModel,
  ): ModifyRelationshipsBodyModel => {
    const { relationships, contact, organization, template } = formValues;

    const newRelationships: NewRelationshipModel[] =
      relationships.map<NewRelationshipModel>((e) => {
        return {
          child: {
            id: e.child.id as string,
            type: e.child.type as OnboardType,
          },
          relationshipTemplateId: e.relationshipTemplate.id as string,
          additionalFields: e.additionalFields.map((af) => ({
            name: af.name,
            type: af.type,
            valueJSON: AdditionalFieldHelpers.formatToJSON(af.type, af.value),
            _id: af.id,
          })),
        };
      });

    const createOperation: CreateRelationshipOptionModel = {
      type: 'create',
      payload: {
        parent:
          template.visibleFor == OnboardType.Contact
            ? {
                type: 'contact',
                model: {
                  firstName: contact?.firstName ? contact.firstName.trim() : '',
                  middleName: contact?.middleName
                    ? contact.middleName.trim()
                    : '',
                  lastName: contact?.lastName ? contact.lastName.trim() : '',
                  isPEP: !!contact?.isPEP,
                  pepInformation: contact?.isPEP
                    ? String(contact?.pepInformation).trim()
                    : '',
                  phoneNumber: contact?.phone ? contact.phone : undefined,
                  email: contact?.email ? contact.email : undefined,
                  onlineAccess: contact?.isOnlineUser
                    ? {
                        adminPermissionType:
                          !contact.adminPermissionType ||
                          contact.adminPermissionType ==
                            AdministrationPermissionTypes.LimitedAdmin
                            ? null
                            : contact.adminPermissionType,
                        permission: contact?.accountPermissions,
                      }
                    : undefined,
                },
              }
            : {
                type: 'organization',
                model: {
                  legalName: String(organization?.name).trim(),
                  isRegulated: !!organization?.isRegulated,
                  regulationCountry: organization?.isRegulated
                    ? organization?.regulatedCountries
                    : [],
                  keyRelationshipTemplateId: template.id as string,
                },
              },

        relationships: [...newRelationships],
      },
    };

    return { operations: [createOperation] };
  },

  generateRequestBodyForEditNode: (
    formValues: EditNodeFormValuesModel,
    initialFormValues: EditNodeFormValuesModel,
    addUserAccessData?: boolean,
  ): ModifyRelationshipsBodyModel => {
    const { relationships, disableNodeForm, keyRelationshipTemplateId } =
      formValues;
    // -- Create operations --
    let createOperations: CreateRelationshipOptionModel[] = [];
    const newRelationships = relationships.filter((e) => !e.id);

    if (newRelationships.length) {
      createOperations = newRelationships.map((e) => {
        return {
          type: 'create',
          payload: {
            parent: {
              id: e.parent.id as string,
              type: e.parent.type as OnboardType,
            },
            relationships: [
              {
                child: {
                  id: e.child.id as string,
                  type: e.child.type as OnboardType,
                },
                relationshipTemplateId: e.relationshipTemplate.id as string,
                additionalFields: e.additionalFields.map((af) => ({
                  name: af.name,
                  type: af.type,
                  valueJSON: AdditionalFieldHelpers.formatToJSON(
                    af.type,
                    af.value,
                  ),
                  _id: af.id,
                })),
              },
            ],
          },
        };
      });
    }

    // -- Update operations --
    let updateOperations: UpdateRelationshipOptionModel[] = [];
    if (formValues.relationships.length) {
      updateOperations = formValues.relationships.reduce<
        UpdateRelationshipOptionModel[]
      >((acc, next) => {
        if (next.id) {
          acc.push({
            type: 'update',
            payload: {
              relationshipId: next.id as string,
              additionalFields: next.additionalFields.reduce<
                AdditionalFieldAnswerModel[]
              >((acc, next) => {
                if (!next.isRemoved) {
                  acc.push({
                    name: next.name,
                    type: next.type,
                    valueJSON: AdditionalFieldHelpers.formatToJSON(
                      next.type,
                      next.value,
                    ),
                    _id: next.id,
                  });
                }
                return acc;
              }, []),
            },
          });
        }

        return acc;
      }, []);
    }

    // -- Update node --
    if (!disableNodeForm) {
      const node =
        formValues.entryType == OnboardType.Contact
          ? formValues.contact
          : formValues.organization;

      // To be able to update node data, we should send it in at least 1 update operation
      // TODO: this part needs to be updated according to the server updates, not the best solution
      if (node && formValues.entryType) {
        if (updateOperations.length) {
          updateOperations[0].payload.parent = getFormattedUpdateNodeData(
            node,
            formValues.entryType,
            addUserAccessData,
          );
        } else if (createOperations.length) {
          // TODO: add the ability to update node for create operations when API gets updated
          createOperations[0].payload.parent.model = getFormattedCreateNodeData(
            node,
            formValues.entryType,
            keyRelationshipTemplateId,
          );
        }
      }
    }

    // -- Delete operations --
    const relationshipIds = formValues.relationships.reduce<string[]>(
      (acc, next) => {
        if (next.id) {
          acc.push(next.id);
        }

        return acc;
      },
      [],
    );

    const deleteOperations: RemoveRelationshipOptionModel[] =
      initialFormValues.relationships.reduce<RemoveRelationshipOptionModel[]>(
        (acc, next) => {
          const isDeleted = next.id && !relationshipIds.includes(next.id);

          if (isDeleted) {
            acc.push({
              type: 'remove',
              payload: { relationshipId: next.id as string },
            });
          }

          return acc;
        },
        [],
      );

    return {
      operations: [
        ...createOperations,
        ...updateOperations,
        ...deleteOperations,
      ],
    };
  },
};

export { relationshipAdapter };
