import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';
import {
  ApprovalGroupBaseModel,
  GroupParticipantModel,
} from '../../../typings/approval/templates';
import {
  INewTransactionRuleTemplate,
  TransactionAccountModel,
} from './clientTransactionRulesAPI';

export type ApprovalGroupFromQuery = {
  id: string;
  name: string;
  description: string;
  isActive: boolean;
  isDeleted: boolean;
  participants: {
    userId: string;
    userFullName: string;
    userEmailAddress: string;
  }[];
};

export interface FetchApprovalGroupsParamsModel extends FetchParamsModel {
  isActiveOnly?: boolean;
}

interface UpdateTransactionRuleFormValues extends INewTransactionRuleTemplate {
  id: string;
}

const approvalGroupsAPI = {
  fetchApprovalGroupParticipants: () => {
    return APIService.get<FetchResponseModel<GroupParticipantModel>>(
      `${APIConfig.approvalApi}/workflows/groups/participants`,
      {
        params: { page: 1, limit: -1 },
      },
    ).then(({ data }) => data);
  },

  fetchClientTransactionApprovalGroups: (
    clientGroupId: string | number,
    params: FetchApprovalGroupsParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<ApprovalGroupFromQuery>>(
      `${APIConfig.approvalApi}/transaction/client/${clientGroupId}/groups`,
      { params },
    ).then(({ data }) => data);
  },

  fetchClientTransactionApprovalGroupById: (
    clientGroupId: string | number,
    approvalGroupId: string,
  ) => {
    return APIService.get<ApprovalGroupFromQuery>(
      `${APIConfig.approvalApi}/transaction/client/${clientGroupId}/groups/${approvalGroupId}`,
    ).then(({ data }) => data);
  },

  createApprovalGroup: (
    newGroup: ApprovalGroupBaseModel,
    clientGroupId: number,
  ) => {
    return APIService.post(
      `${APIConfig.approvalApi}/transaction/client/${clientGroupId}/groups`,
      newGroup,
    ).then(({ data }) => data);
  },

  updateApprovalGroup: (
    updatedGroup: ApprovalGroupBaseModel,
    clientGroupId: number,
    groupId: string,
  ) => {
    return APIService.patch(
      `${APIConfig.approvalApi}/transaction/client/${clientGroupId}/groups/${groupId}`,
      updatedGroup,
    ).then(({ data }) => data);
  },

  updateTransactionApprovalRule: (
    data: UpdateTransactionRuleFormValues,
    clientGroupId: number,
    templateId: string,
  ) => {
    return APIService.patch(
      `${APIConfig.approvalApi}/transaction/client/${clientGroupId}/templates/${templateId}`,
      data,
    ).then(({ data }) => data);
  },

  addTransactionApprovalRule: (
    data: INewTransactionRuleTemplate,
    clientGroupId: number,
  ) => {
    return APIService.post(
      `${APIConfig.approvalApi}/transaction/client/${clientGroupId}/templates`,
      data,
    ).then(({ data }) => data);
  },

  fetchTransactionApprovalGroupParticipants: (clientGroupId: number) => {
    return APIService.get<FetchResponseModel<GroupParticipantModel>>(
      `${APIConfig.approvalApi}/transaction/client/${clientGroupId}/groups/participants`,
    ).then(({ data }) => data);
  },

  fetchTransactionRuleAccounts: (
    clientGroupNumberId: number,
    params: FetchParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<TransactionAccountModel>>(
      `${APIConfig.approvalApi}/transaction/client/${clientGroupNumberId}/template/accounts`,
      {
        params,
      },
    ).then(({ data }) => data);
  },
};

export { approvalGroupsAPI };
