import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import { StyledComponentProps } from '../../../../typings/common';
import { ButtonType as AntDButtonTypes } from 'antd/lib/button';

// components
import { Button } from 'antd';

export type CircleButtonTypes = 'primary' | 'bordered' | 'dashed';

export interface CircleButtonProps extends StyledComponentProps {
  icon: React.ReactNode;
  type?: CircleButtonTypes;
  danger?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const IconButton = memo(
  ({ icon, danger, type = 'primary', onClick, ...rest }: CircleButtonProps) => {
    const mappedType = useMemo<AntDButtonTypes | undefined>(() => {
      if (!type) {
        return undefined;
      }

      let result: AntDButtonTypes | undefined = undefined;
      switch (type) {
        case 'primary':
          result = 'primary';
          break;

        case 'bordered':
          result = 'default';
          break;

        case 'dashed':
          result = 'dashed';
          break;
      }

      return result;
    }, [type]);

    return (
      <StyledButton
        {...rest}
        shape="circle"
        type={mappedType}
        icon={icon}
        danger={danger}
        onClick={onClick}
      />
    );
  },
);

const StyledButton = styled(Button)`
  position: relative;
  min-width: 0px;
  padding: 0px;
  line-height: 0px;
  cursor: pointer;

  & > .anticon {
    line-height: 0px;
  }

  & > * {
    cursor: pointer;
  }
`;

export default IconButton;
