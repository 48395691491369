import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';

import styled from 'styled-components';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { StyledComponentProps } from '../../../../../typings/common';
import { FeeScheduleEntryModel } from '../../../../../typings/finance/feeSchedule';

// components
import TableActions from '../../../TableActions';
import InfoIconWithPopover from '@common_components/Icons/InfoIconWithPopover';
import OverridenValueInfoToolting from './OverridenValueInfoToolting';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps
  extends StyledComponentProps,
    RequiredPropsForTableModel<FeeScheduleEntryModel> {
  size?: SizeType;
  canEdit?: boolean;
  clientRelated?: boolean;
  onActionsClick?: (key: string, record: FeeScheduleEntryModel) => void;
}

const FeeScheduleEntriesTable = memo(
  ({ onActionsClick, canEdit, clientRelated, ...rest }: IProps) => {
    const { t } = useTranslation(['crm', 'common']);

    const renderActionsComponent = (record: FeeScheduleEntryModel) => {
      if (!canEdit) {
        return;
      }

      if (
        record.hasWorkflowPending &&
        (!clientRelated || (clientRelated && !!record.clientId))
      ) {
        return (
          <InfoIconWithPopover
            popoverContent={t(
              'client_group.fee_schedule.fee_schedule_pending_review_tooltip',
            )}
          />
        );
      }

      return (
        <TableActions
          onSelect={(key: string) =>
            onActionsClick && onActionsClick(key, record)
          }
          menu={[
            {
              key: 'update_fee_schedule_entry',
              name: t('update', { ns: 'common' }),
            },
          ]}
        />
      );
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          title: t('client_group.fee_schedule.currency'),
          key: 'currency',
          width: 300,
          render: (record: FeeScheduleEntryModel) => record.currencyIsoCode,
        },

        {
          title: t('client_group.fee_schedule.amount'),
          key: 'amount',
          width: 300,
          render: (record: FeeScheduleEntryModel) => (
            <StyledAmountText isHighlighted={!record.isDefault}>
              {AccountsHelpers.formatMoneyToStringWithRounding(record.amount)}{' '}
              {!record.isDefault && <OverridenValueInfoToolting />}
            </StyledAmountText>
          ),
        },

        {
          key: 'actions',
          align: 'right',
          render: renderActionsComponent,
        },
      ];

      return result;
    }, [canEdit, clientRelated, onActionsClick]);

    return <Table {...rest} columns={columns} />;
  },
);

const StyledAmountText = styled(Typography.Text)<{ isHighlighted?: boolean }>`
  ${({ theme, isHighlighted }) =>
    isHighlighted &&
    `
      color: ${theme.warningColor}
   `}
`;

export default FeeScheduleEntriesTable;
