import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../hooks/useFetch';
import useActiveTab from '../../../../../hooks/useActiveTab';
import useUserAccess from '../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../hooks/useTranslation';
import { ClientStatus } from '../../../../../enums/crm/crm';
import { messagingAPI } from '../../../../../api/messaging/messagingAPI';
import { IClientGroupResponse } from '../../../../../api/crm/clientGroup/clientGroupsAPI';

// components
import RDCTab from './Contents/RDCTab';
import Messages from '../../Messages';
import UsersTab from './Contents/UsersTab';
import AccountsTab from './Contents/AccountsTab';
import WorkflowTab from './Contents/WorkflowTab';
import ApprovalTab from './Contents/ApprovalTab';
import StructureTab from './Contents/StructureTab';
import SectionIntro from '../../../../../components/DesignSystem/Common/Texts/SectionIntro';
import DocumentsTab from './Contents/DocumentsTab';
import ComplianceTab from './Contents/ComplianceTab';
import FeeScheduleTab from './Contents/FeeScheduleTab';
import LoadingWrapper from '../../../../../components/WrapperComponents/LoadingWrapper';
import NewMessagesBadge from '../../../../../components/Additional/Messaging/NewMessagesBadge';
import TransactionRulesTab from './Contents/TransactionRulesTab';
import TransferTemplatesTab from './Contents/TransferTemplatesTab';
import AdministrationRulesTab from './Contents/AdministrationRulesTab';
import PendingTransactionsTab from './Contents/PendingTransactionsTab';
import ClientGroupDetailsSection from '../ClientGroupDetailsSection';
import { Col, Divider, Menu, Row } from 'antd';

interface IProps {
  clientGroupData: IClientGroupResponse;
  updateClientGroupDataCallback: () => void;
}

const ClientGroupPageContent = memo(
  ({ clientGroupData, updateClientGroupDataCallback }: IProps) => {
    const { t } = useTranslation('crm');
    const { activeKey, setActiveKey } = useActiveTab<
      keyof typeof contentData | null
    >('details');

    const [
      hasAccountsAccess,
      hasStructureAccess,
      hasApprovalAccess,
      hasWorkflowAccess,
      hasUsersAccess,
      hasFeeScheduleTabAccess,
      hasTransferTemplatesTabAccess,
      hasMessagesAccess,
      hasTransactionRulesAccess,
      hasAdministrationRulesAccess,
    ] = useUserAccess([
      'CRM_ClientGroup_Account_View',
      'CRM_ClientGroup_Structure_View',
      'CRM_ClientGroup_Approval_View',
      'ApprovalManagement_ClientGroup_Workflow_View',
      'CRM_ClientGroup_Users_View',
      'CRM_ClientGroup_Details_FeeScheduleTab_View',
      'CRM_ClientGroup_Details_TransferTemplatesTab_View',
      'CRM_ClientGroup_Details_Messages_View',
      'CRM_ClientGroup_Details_TransactionRules_View',
      'CRM_ClientGroup_Details_AdministrationRules_View',
    ]);

    const { response: messagesResponse, loading: messagesLoader } = useFetch(
      () =>
        clientGroupData?.clientGroup.numericId
          ? messagingAPI.getMessagesStatus(
              clientGroupData?.clientGroup.numericId,
            )
          : null,
      [clientGroupData],
    );

    const contentData = useMemo(() => {
      return {
        details: {
          hidden: !hasAccountsAccess,
          title: t('client_group.tabs.details'),
          content: (
            <ClientGroupDetailsSection
              clientGroup={clientGroupData.clientGroup}
            />
          ),
        },

        accounts: {
          hidden: !hasAccountsAccess,
          title: t('client_group.tabs.accounts'),
          content: <AccountsTab clientGroupData={clientGroupData} />,
        },
        structure: {
          hidden: !hasStructureAccess,
          title: t('client_group.tabs.structure'),
          content: (
            <StructureTab
              clientGroupData={clientGroupData}
              updateClientGroup={updateClientGroupDataCallback}
            />
          ),
        },
        approval: {
          hidden: !hasApprovalAccess,
          title: t('client_group.tabs.approval'),
          content: (
            <ApprovalTab
              clientGroupData={clientGroupData}
              updateClientGroup={updateClientGroupDataCallback}
            />
          ),
        },
        compliance: {
          hidden: false,
          title: t('client_group.tabs.compliance'),
          content: (
            <ComplianceTab
              clientGroupData={clientGroupData}
              updateClientGroup={updateClientGroupDataCallback}
            />
          ),
        },
        workflow: {
          hidden: !hasWorkflowAccess,
          title: t('client_group.tabs.workflow'),
          content: (
            <WorkflowTab
              clientGroupData={clientGroupData}
              updateClientGroup={updateClientGroupDataCallback}
            />
          ),
        },
        messaging: {
          hidden: !hasMessagesAccess,
          title: (
            <>
              {t('client_group.tabs.messaging')}
              <NewMessagesBadge
                count={messagesResponse?.newMessagesCount as number}
              />
            </>
          ),
          content: (
            <Messages
              clientGroupNumericId={clientGroupData.clientGroup.numericId}
              clientGroupId={clientGroupData.clientGroup._id}
              messagesStatus={messagesResponse || undefined}
            />
          ),
        },
        users: {
          hidden:
            hasUsersAccess &&
            clientGroupData.clientGroup.status !== ClientStatus.Approved,
          title: t('client_group.tabs.users'),
          content: <UsersTab clientGroup={clientGroupData.clientGroup} />,
        },
        feeSchedule: {
          hidden: !hasFeeScheduleTabAccess,
          title: t('client_group.tabs.fee_schedule'),
          content: <FeeScheduleTab clientGroup={clientGroupData.clientGroup} />,
        },
        rdc: {
          hidden: false,
          title: t('client_group.tabs.rdc'),
          content: <RDCTab clientGroup={clientGroupData.clientGroup} />,
        },
        documents: {
          hidden: false,
          title: t('client_group.tabs.documents'),
          content: (
            <DocumentsTab clientGroupId={clientGroupData.clientGroup._id} />
          ),
        },
        transferTemplates: {
          hidden: !hasTransferTemplatesTabAccess,
          title: t('client_group.tabs.transfer_templates'),
          content: (
            <TransferTemplatesTab
              clientGroupNumericId={clientGroupData.clientGroup.numericId}
            />
          ),
        },
        pendingTransactions: {
          hidden: false,
          title: t('client_group.tabs.transactions'),
          content: (
            <PendingTransactionsTab
              clientGroupNumericId={clientGroupData.clientGroup.numericId}
            />
          ),
        },
        transactionRules: {
          hidden: !hasTransactionRulesAccess,
          title: t('client_group.tabs.transaction_rules'),
          content: (
            <TransactionRulesTab
              clientGroupName={clientGroupData.clientGroup.clientGroupName}
              clientGroupNumericId={clientGroupData.clientGroup.numericId}
              isClientGroupInOnboardingProcess={[
                ClientStatus.Onboarding,
                ClientStatus.InReview,
              ].includes(clientGroupData.clientGroup.status)}
            />
          ),
        },
        administrationRules: {
          hidden: !hasAdministrationRulesAccess,
          title: t('client_group.tabs.administration_rules'),
          content: (
            <AdministrationRulesTab clientGroup={clientGroupData.clientGroup} />
          ),
        },
      };
    }, [
      clientGroupData,
      messagesResponse,
      hasAccountsAccess,
      hasStructureAccess,
      hasApprovalAccess,
      hasWorkflowAccess,
      hasUsersAccess,
      hasFeeScheduleTabAccess,
      hasTransferTemplatesTabAccess,
      hasMessagesAccess,
      hasTransactionRulesAccess,
    ]);

    return (
      <LoadingWrapper loading={messagesLoader}>
        <Row gutter={[16, 16]} wrap={false}>
          <Col flex="215px">
            <Menu
              mode="inline"
              selectedKeys={activeKey ? [activeKey] : undefined}
              onSelect={({ key }) =>
                key && setActiveKey(key as keyof typeof contentData)
              }
            >
              {Object.keys(contentData).map(
                (key) =>
                  !contentData[key as keyof typeof contentData].hidden && (
                    <StyledMenuItem key={key}>
                      {contentData[key as keyof typeof contentData].title}
                    </StyledMenuItem>
                  ),
              )}
            </Menu>
          </Col>

          <Col>
            <StyledDivider type="vertical" />
          </Col>

          <Col flex="auto">
            {activeKey && (
              <>
                <SectionIntro
                  titleVariant="h4"
                  title={contentData[activeKey].title}
                />
                {contentData[activeKey].content}
              </>
            )}
          </Col>
        </Row>
      </LoadingWrapper>
    );
  },
);

const StyledDivider = styled(Divider)`
  margin: 0px;
  height: 100% !important;
  border: ${({ theme }) => `1px solid ${theme.cardBorderColor}`};
`;

const StyledMenuItem = styled(Menu.Item)`
  margin: 0px !important;
  padding-left: 0px !important;
`;

export default ClientGroupPageContent;
