// constants
import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { IFinancialType } from '../../../typings/finance/financialTypes';
import { FetchParamsModel, FetchResponseModel } from '../../../typings/common';

const financialTypeAPI = {
  fetchFinancialTypes: (params: FetchParamsModel) => {
    return APIService.get<FetchResponseModel<IFinancialType>>(
      `${APIConfig.accountingApi}/financial-types`,
      { params },
    ).then(({ data }) => data);
  },
};

export { financialTypeAPI };
