import React from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { IJournalModel } from '../../../../../../typings/finance/journal';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { DEFAULT_DATE_FORMAT } from '../../../../../../constants/global';

// components
import EllipsisTooltip from '../../../../../Tooltips/EllipsisTooltip';
import { Typography } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import { ReactComponent as AttachmentIcon } from '../../../../../../resources/images/icons/attachment.svg';

interface IProps {
  record: IJournalModel;
  dataIndex: string;
  canNavigateToBankAccount?: boolean;
  onActionsClick: (key: string, value: any) => void;
}

const ContentCell = ({
  record,
  dataIndex,
  onActionsClick,
  canNavigateToBankAccount,
}: IProps) => {
  const { t } = useTranslation(['finance', 'common']);

  const getObjectKeyDependOnDataIndex = (dataIndex: string) => {
    switch (dataIndex) {
      case 'description':
        return 'description';

      case 'clientGroupName':
        return 'relatedPartyName';

      case 'type':
        return 'financialTypeName';

      case 'debitHomeAmount':
        return 'debitHomeAmount';

      case 'creditHomeAmount':
        return 'creditHomeAmount';

      case 'accountNumber':
        return 'accountNumber';

      case 'accountName':
        return 'accountName';

      case 'isResident':
        return 'isResident';

      case 'accountCurrency':
        return 'currencyIsoCode';

      default:
        return '';
    }
  };

  const renderFilterIcon = (key: string, value: any) => (
    <FilterIcon onClick={() => onActionsClick(key, value)} />
  );

  const renderContent = () => {
    let result = null;

    switch (dataIndex) {
      case 'journalId':
        result = (
          <Typography.Link
            onClick={() => onActionsClick('details', record.journalId)}
          >
            {record.journalId}
          </Typography.Link>
        );
        break;

      case 'journalEntryType':
        result = <Typography.Text>{record.sourceName}</Typography.Text>;
        break;

      case 'date':
        result =
          record.valueDate > 0
            ? moment(record.valueDate * 1000).format(DEFAULT_DATE_FORMAT)
            : '';
        break;

      case 'journalPostingDate':
        result =
          record.postingDate > 0
            ? moment(record.postingDate * 1000).format(DEFAULT_DATE_FORMAT)
            : '';
        break;

      case 'accountNumber':
      case 'accountName':
      case 'isResident':
      case 'accountCurrency':
        {
          if (!record.journalEntries || !record.journalEntries.length) {
            return null;
          }

          const isAccountNumber = dataIndex === 'accountNumber';
          const isResident = dataIndex === 'isResident';
          const entries = record.journalEntries.slice(0, 4);
          const restAccountsNum = record.totalJournalEntries - 4;

          result = (
            <>
              {entries.map((e: any, i) => {
                const objectKey = getObjectKeyDependOnDataIndex(dataIndex);
                let residentStatus = null;
                if (isResident && typeof e.isResident !== 'boolean')
                  residentStatus = t('journals.new.resident_tags.not_selected');
                else {
                  residentStatus = e.isResident
                    ? t('journals.new.resident_tags.resident')
                    : t('journals.new.resident_tags.non_resident');
                }
                return (
                  <RowWrapper key={i}>
                    {isAccountNumber ? (
                      canNavigateToBankAccount ? (
                        <StyledLink
                          onClick={() =>
                            onActionsClick('account-details', e.accountNumber)
                          }
                        >
                          {e[objectKey]}
                        </StyledLink>
                      ) : (
                        e[objectKey]
                      )
                    ) : (
                      <StyledParagraph key={`empty-for-${dataIndex}`}>
                        {!isResident ? e[objectKey] : residentStatus}
                      </StyledParagraph>
                    )}

                    {isAccountNumber &&
                      renderFilterIcon('filter-by-account', e.accountNumber)}
                  </RowWrapper>
                );
              })}

              <StyledParagraph key={`empty-for-${dataIndex}`}>
                {restAccountsNum > 0
                  ? `${restAccountsNum} + ${t('more', { ns: 'common' })}`
                  : ''}
              </StyledParagraph>
            </>
          );
        }
        break;

      case 'description':
      case 'clientGroupName':
      case 'type':
        {
          if (!record.journalEntries || !record.journalEntries.length) return;

          const entries = record.journalEntries.slice(0, 4);
          const shouldRenderFilterIcon = dataIndex === 'clientGroupName';

          result = (
            <>
              {entries.map((e, i) => {
                const objectKey = getObjectKeyDependOnDataIndex(dataIndex);
                return (
                  objectKey && (
                    <RowWrapper key={i}>
                      {dataIndex === 'description' ? (
                        <DescriptionRow>
                          {e.documents.length ? <StyledAttachmentIcon /> : null}
                          <EllipsisTooltip
                            title={e[objectKey]}
                            maxTextContainerWidth="240px"
                          >
                            {e[objectKey]}
                          </EllipsisTooltip>
                        </DescriptionRow>
                      ) : (
                        <StyledParagraph>{e[objectKey]}</StyledParagraph>
                      )}
                      {!!(shouldRenderFilterIcon && e[objectKey]) &&
                        renderFilterIcon('filter-by-client', {
                          id: e.relatedPartyStringId,
                          type: e.relatedPartyType,
                          name: e.relatedPartyName,
                        })}
                    </RowWrapper>
                  )
                );
              })}
              {/* Need to leave extra space  */}
              <StyledParagraph key={`empty-for-${dataIndex}`} />
            </>
          );
        }
        break;

      case 'debitHomeAmount':
      case 'creditHomeAmount':
        {
          if (!record.journalEntries || !record.journalEntries.length) return;

          const formattedTotal =
            dataIndex === 'debitHomeAmount'
              ? AccountsHelpers.convertAmountBigIntToLocaleString(
                  record.debitHome,
                )
              : AccountsHelpers.convertAmountBigIntToLocaleString(
                  record.creditHome,
                );

          const entries = record.journalEntries.slice(0, 4);

          result = (
            <>
              {entries.map((e, i) => {
                const objectKey = getObjectKeyDependOnDataIndex(dataIndex);

                return (
                  objectKey && (
                    <StyledParagraph key={i} endAlign>
                      {AccountsHelpers.convertAmountBigIntToLocaleString(
                        e[objectKey] as any,
                      )}
                    </StyledParagraph>
                  )
                );
              })}

              <StyledParagraph key={`total${dataIndex}`} strong endAlign>
                {`${dataIndex === 'debitHomeAmount' ? `${t('journals.table.total')}: ` : ''}${formattedTotal}`}
              </StyledParagraph>
            </>
          );
        }
        break;
    }

    return result && <td>{result}</td>;
  };

  return <>{renderContent()}</>;
};

const DescriptionRow = styled.div`
  position: relative;
  padding-left: 15px;
`;

const StyledParagraph = styled(Typography.Paragraph)<{ endAlign?: boolean }>`
  min-height: 35px;
  margin-bottom: 0px !important;

  display: flex;
  align-items: center;
  justify-content: ${({ endAlign }) => endAlign && 'flex-end'};
`;

const StyledLink = styled(Typography.Link)`
  min-height: 35px;
  margin-bottom: 0px !important;

  display: flex;
  align-items: center;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 35px;
`;

const FilterIcon = styled(FilterOutlined)`
  margin-left: ${({ theme }) => theme.marginXXs};
  color: ${({ theme }) => theme.warningColor};
`;

const StyledAttachmentIcon = styled(AttachmentIcon)`
  height: 15px;
  width: 15px;
  position: absolute;
  left: -8px;
  top: calc(50% - 7px);
`;

export default ContentCell;
