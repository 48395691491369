import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { CrmItemType } from 'enums/crm/crm';
import { NewJournalRequestBody } from '../../../apiAdapters/finance/journal/journalsAdapter';
import { ReconciliationStatuses } from '../../../enums/finance/finance';
import { SortJournalEntryByType } from '../../../modules/Finance/Accounts/AccountJournalEntriesTable';
import { FetchResponseModel, FetchParamsModel } from '../../../typings/common';
import {
  IJournalEntryModel,
  IJournalModel,
  JournalAccountModel,
} from '../../../typings/finance/journal';

export interface FetchJournalsParamsModel extends FetchParamsModel {
  searchQuery?: string;
  relatedParty?: string;
  relatedPartyType?: string;
  accountQuery?: string;
  journalSource?: string;
  currencyIsoCodeQuery?: string;
  financialTypeNameQuery?: string;
  toDate?: number | null;
  fromDate?: number | null;
}
export interface FetchAccountJournalEntriesParamsModel
  extends FetchParamsModel {
  accountNumber: string;
  fromDate?: number;
  toDate?: number;
  sortBy?: SortJournalEntryByType;
  sortDirection?: boolean;
}

export interface JournalEntryModel {
  id: string;
  name: string;
}

export interface UpdateJournalEntryReqBodyModel {
  id: number;
  isResident: boolean;
  description: string;
  relatedParty?: string;
  relatedPartyType?: CrmItemType;
}

export type UpdateReconciliationStatusesBody = {
  accountNumber: string;
  journalEntries: {
    journalEntryId: number;
    reconciliationStatus: ReconciliationStatuses;
    comment: string;
  }[];
};

const journalsAPI = {
  createJournal: (body: NewJournalRequestBody) => {
    return APIService.post(`${APIConfig.accountingApi}/journals`, body).then(
      ({ data }) => data,
    );
  },

  updateJournalEntries: (body: UpdateJournalEntryReqBodyModel[]) => {
    return APIService.patch(
      `${APIConfig.accountingApi}/journals/entries`,
      body,
    ).then(({ data }) => data);
  },

  reverseJournalById: (journalId: number) => {
    return APIService.post(
      `${APIConfig.accountingApi}/journals/${journalId}/reverse`,
    ).then(({ data }) => data);
  },

  updateReconciliationStatuses: (body: UpdateReconciliationStatusesBody) => {
    return APIService.patch(
      `${APIConfig.accountingApi}/journals/entries/reconciliation`,
      body,
    ).then(({ data }) => data);
  },

  fetchJournals: (params: FetchJournalsParamsModel) => {
    return APIService.get<FetchResponseModel<IJournalModel>>(
      `${APIConfig.accountingApi}/journals`,
      { params },
    ).then(({ data }) => data);
  },

  fetchJournalEntryTypes: () => {
    return APIService.get<FetchResponseModel<JournalEntryModel>>(
      `${APIConfig.accountingApi}/journals/entry-types`,
    ).then(({ data }) => data);
  },

  fetchAccountJournalEntries: (
    params: FetchAccountJournalEntriesParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<IJournalEntryModel>>(
      `${APIConfig.accountingApi}/journals/entries`,
      { params },
    ).then(({ data }) => data);
  },

  fetchAccountsByJournalId: (journalId: number) => {
    return APIService.get<FetchResponseModel<JournalAccountModel>>(
      `${APIConfig.accountingApi}/journals/${journalId}/aggregation`,
    ).then(({ data }) => data);
  },

  fetchJournalById: (journalId: number) => {
    return APIService.get<IJournalModel>(
      `${APIConfig.accountingApi}/journals/${journalId}`,
    ).then(({ data }) => data);
  },

  fetchReverseJournal: (journalId: number) => {
    return APIService.get<IJournalModel>(
      `${APIConfig.accountingApi}/journals/${journalId}/reverse/prefill`,
    ).then(({ data }) => data);
  },
};

export { journalsAPI };
