import React from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';

// components
import { SearchInput } from 'components/Fields/SearchInput';
import { Row, Col, Typography } from 'antd';

interface IProps {
  onFilterChange: (key: string, value: string) => void;
}

const FilterBar = ({ onFilterChange }: IProps) => {
  const { t } = useTranslation('crm');

  const gridSizes = { xl: 6, lg: 8, md: 12, sm: 24, xs: 24 };

  return (
    <Row gutter={[16, 16]}>
      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('contacts.table.search_contacts')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <SearchInput
              size="large"
              placeholder={t('contacts.table.search_contacts_placeholder')}
              onSearch={(searchQuery: string) =>
                onFilterChange('search', searchQuery)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FilterBar;
