import React, { useMemo } from 'react';

// helpers
import { FormikHelpers } from 'formik';
import { AccountsHelpers } from '../../../../../helpers/finance/accounts';
import { ForexTransferModel } from '../../../../../typings/banking/forexTransfers';

// components
import Form from '@core_components/Form';
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import ForexTransferForm, {
  ForexTransferFormValues,
} from '../../../../Forms/TemplateForms/TreasureManagement/ForexTransferForm';

interface IProps extends RequiredPropsForModalDialogModel {
  title: string | React.ReactNode;
  loadingInitialData?: boolean;
  transfer: ForexTransferModel | null;
  onSubmit: (
    values: ForexTransferFormValues,
    formikHelpers: FormikHelpers<ForexTransferFormValues>,
  ) => Promise<void>;
}

const ForexTransferModal = ({
  title,
  isVisible,
  closeCallback,
  transfer,
  loadingInitialData,
  onSubmit,
}: IProps) => {
  const initialFormValues = useMemo(() => {
    let result: ForexTransferFormValues | null = null;

    if (transfer) {
      const isCurrencyFANNcy = transfer.fromCurrencyId === transfer.currencyId;

      result = {
        transferId: transfer.id,

        fromAccountName: transfer.fromAccountName,
        fromAccountNumber: transfer.fromAccountNumber,
        fromAccountCurrency: transfer.fromCurrencyIsoCode,
        fromCurrencyRate:
          AccountsHelpers.convertAmountFromBigIntToInt(
            transfer.fromCurrencyRate,
          ) || 0,

        toAccountName: transfer.beneficiaryAccountName,
        toAccountNumber: transfer.beneficiaryAccountNumber,
        toAccountCurrency: transfer.toCurrencyIsoCode,
        toCurrencyRate:
          AccountsHelpers.convertAmountFromBigIntToInt(
            transfer.toCurrencyRate,
          ) || 0,

        isCurrencyFANNcy,
        currency: transfer.currencyIsoCode,
        amount: transfer.amount,

        fromCorrespondentAccountName: transfer.fromCorrespondentAccountName,
        fromCorrespondentAccountNumber: transfer.fromCorrespondentAccountNumber,

        toCorrespondentAccountName: transfer.toCorrespondentAccountName,
        toCorrespondentAccountNumber: transfer.toCorrespondentAccountNumber,

        profit: AccountsHelpers.roundNumber(
          AccountsHelpers.convertAmountFromBigIntToInt(transfer.profit) || 0,
          2,
        ),
        profitAccountName: transfer.profitAccountName,
        profitAccountNumber: transfer.profitAccountNumber,
        profitAccountCurrency: transfer.profitAccountCurrencyIsoCode,

        fromAccountMidAmount: AccountsHelpers.roundNumber(
          AccountsHelpers.convertAmountFromBigIntToInt(
            transfer.fromAccountMidAmount,
          ) || 0,
          2,
        ),
        fromAccountBaseAmount: AccountsHelpers.roundNumber(
          AccountsHelpers.convertAmountFromBigIntToInt(
            transfer.fromAccountBaseAmount,
          ) || 0,
          2,
        ),

        toAccountMidAmount: AccountsHelpers.roundNumber(
          AccountsHelpers.convertAmountFromBigIntToInt(
            transfer.toAccountMidAmount,
          ) || 0,
          2,
        ),
        toAccountBaseAmount: AccountsHelpers.roundNumber(
          AccountsHelpers.convertAmountFromBigIntToInt(
            transfer.toAccountBaseAmount,
          ) || 0,
          2,
        ),

        spread:
          AccountsHelpers.convertAmountFromBigIntToInt(transfer.spread) || 0,
        originalSpread:
          AccountsHelpers.convertAmountFromBigIntToInt(
            transfer.originalSpread,
          ) || 0,

        isLoadingSummaryData: true,
      };
    }

    return result;
  }, [transfer]);

  return (
    <Form<ForexTransferFormValues>
      onSubmit={onSubmit}
      initialValues={initialFormValues}
      renderForm={
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <LoadingWrapper loading={!!loadingInitialData}>
            {!!(initialFormValues && transfer) && <ForexTransferForm />}
          </LoadingWrapper>
        </ModalDialog>
      }
      confirmExitWithoutSaving
    />
  );
};

export default ForexTransferModal;
