import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../../hooks/useTranslation';

// components
import { Button, Typography } from 'antd';
import { ReactComponent as KeyIcon } from '../../../../../../../../resources/images/icons/key.svg';

interface IProps {
  onTryClick: () => void;
}

const ContentWithUseKeyButton = ({ onTryClick }: IProps) => {
  const { t } = useTranslation('profile');

  return (
    <>
      <StyledKeyIcon />
      <StyledParagraph>
        {t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_modal.register_key_step.use_security_key_description',
        )}
      </StyledParagraph>
      <Button size="large" type="primary" onClick={onTryClick}>
        {t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_modal.register_key_step.use_security_key_button',
        )}
      </Button>
    </>
  );
};

const StyledKeyIcon = styled(KeyIcon)`
  min-height: 100px;
  max-height: 100px;
  margin-bottom: ${({ theme }) => theme.marginXl};
`;

const StyledParagraph = styled(Typography.Paragraph)`
  text-align: center;
`;

export default ContentWithUseKeyButton;
