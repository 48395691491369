import React, { useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { StateModel } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { approvalAPI } from '../../../../api/crm/onboarding/approvalAPI';
import { IApprovalMember } from '../../../../typings/crm/onboarding';
import {
  ApprovalStatuses,
  ApprovalWorkflowStatuses,
} from '../../../../enums/approvalManagement/workflow';

import LoaderSection from '../../../Loaders/LoaderSection';
import ApprovalsMatrix from '../ApprovalsMatrix';
import WorkflowActions from '../WorkflowActions';

interface IProps {
  workflowId: string;
  onUpdate: () => void;
}

const ForexTransferWorkflowStatus = ({ workflowId, onUpdate }: IProps) => {
  const myId = useSelector<StateModel, string>(
    (state) => state.auth.profileData?._id || '',
  );

  const [updateMatrixTrigger, updateMatrix] = useState({});

  const { response, loading } = useFetch(
    () =>
      workflowId
        ? approvalAPI.fetchForexTransferWorkflowStatuses(workflowId)
        : null,
    [updateMatrixTrigger, workflowId],
  );

  const canApproveOrReject = useMemo(() => {
    let result = false;

    if (response) {
      const isWorkflowInProgress =
        response?.status === ApprovalWorkflowStatuses.PendingApproval;

      if (isWorkflowInProgress) {
        let myMember: IApprovalMember | undefined;

        response?.approvalProgressStateSets.forEach((state) => {
          state.progressStateItems.forEach(({ members }) => {
            myMember =
              members.find((member) => member.userId === myId) || myMember;
          });
        });

        result = !!(myMember?.approvalAction === ApprovalStatuses.Pending);
      }
    }

    return result;
  }, [response]);

  const handleApprove = async (reason: string) => {
    await approvalAPI.approveFxTransferWorkflow(workflowId, reason);
    onUpdate();
    updateMatrix({});
  };

  const handleReject = async (reason: string) => {
    await approvalAPI.rejectFxTransferWorkflow(workflowId, reason);
    onUpdate();
    updateMatrix({});
  };

  if (loading) {
    return <LoaderSection />;
  }

  return (
    <>
      {!!response && (
        <ApprovalsMatrix
          approvalProgressStateSets={response.approvalProgressStateSets}
        />
      )}
      <WorkflowActions
        canReject={canApproveOrReject}
        canApprove={canApproveOrReject}
        onApprove={handleApprove}
        onReject={handleReject}
      />
    </>
  );
};

export default ForexTransferWorkflowStatus;
