import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../../routes/routes';
import { ContactHelpers } from '@helpers/crm/contact';
import { ComplianceReviewTypes } from '../../../../../../enums/compliance/reviewConfiguration';
import { ClientGroupWithRDCModel } from '../../../../../../typings/crm/client-group';

// components
import RDCReviewStatus from 'components/Additional/Statuses/RDCReviewStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

type IProps = RequiredPropsForTableModel<ClientGroupWithRDCModel>;

const ClientGroupRelationshipsWithRDCTable = memo((props: IProps) => {
  const { t } = useTranslation(['crm', 'compliance']);
  const history = useHistory();

  const renderName = (record: ClientGroupWithRDCModel) => {
    let result = null;

    switch (record.reviewType) {
      case ComplianceReviewTypes.KYCContact:
        {
          result = (
            <Typography.Link
              onClick={() =>
                history.push({
                  pathname: RoutePaths.CRM_Contacts_Edit,
                  search: `?id=${record.contact?.id}`,
                })
              }
            >
              {ContactHelpers.getContactName(record.contact)}
            </Typography.Link>
          );
        }
        break;

      case ComplianceReviewTypes.KYCEntity:
        {
          result = (
            <Typography.Link
              onClick={() =>
                history.push({
                  pathname: RoutePaths.CRM_Entities_Edit,
                  search: `?id=${record.entity?.id}`,
                })
              }
            >
              {record.entity?.legalName}
            </Typography.Link>
          );
        }
        break;
    }

    return result;
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('client_group.rdc.name'),
        key: 'name',
        render: renderName,
      },

      {
        title: t('client_group.rdc.type'),
        key: 'type',
        render: (record: ClientGroupWithRDCModel) =>
          t(`review_types.${ComplianceReviewTypes[record.reviewType]}`, {
            ns: 'compliance',
          }),
      },

      {
        title: t('client_group.rdc.status'),
        key: 'review_status',
        render: (record: ClientGroupWithRDCModel) => (
          <RDCReviewStatus status={record.status} />
        ),
      },
    ];

    return result;
  }, [t]);

  return <Table columns={columns} {...props} />;
});

export default ClientGroupRelationshipsWithRDCTable;
