import React, { ForwardedRef, forwardRef, memo } from 'react';

// helpers
import styled from 'styled-components';
import { StateModel } from '../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { MessageModel } from '../../../../../typings/messaging/messages';

// components
import ThreadMessage from './ThreadMessage';

interface IProps {
  threadMessages: MessageModel[];
  isChangeHeight: boolean;
}

const ThreadList = (
  { threadMessages, isChangeHeight }: IProps,
  messageContainerRef: ForwardedRef<HTMLDivElement>,
) => {
  const currentUserId = useSelector<StateModel, string>(
    (state) => state.auth.profileData?._id as string,
  );

  return (
    <ThreadMessagesWrapper
      ref={messageContainerRef}
      isChangeHeight={isChangeHeight}
    >
      {threadMessages.map((message) => (
        <ThreadMessage
          key={message.id}
          message={message}
          currentUserId={currentUserId}
        />
      ))}
    </ThreadMessagesWrapper>
  );
};

const ThreadMessagesWrapper = styled.div<{ isChangeHeight: boolean }>`
  max-height: ${({ isChangeHeight }) => (isChangeHeight ? 255 : 445)}px;
  overflow: auto;
  padding-right: 16px;

  .ant-comment:last-child {
    border-bottom: 0px !important;
  }
`;

export default memo(forwardRef(ThreadList));
