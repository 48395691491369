import React, { memo, useMemo, useState } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { OnboardType } from 'enums/crm/crm';
import { IContactModel } from 'typings/crm/contact';
import { ContactHelpers } from '@helpers/crm/contact';
import { relationshipAPI } from 'api/crm/relationship/relationshipAPI';
import { relationshipAdapter } from 'apiAdapters/crm/relationship/relationshipAdapter';

// components
import PageButton from '@common_components/Buttons/PageButton';
import AddRelationshipModalDialog, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/AddRelationshipModalDialog';

interface IProps {
  contact: IContactModel;
  onAdd: () => void;
}

const AddRelationships = memo(({ contact, onAdd }: IProps) => {
  const { t } = useTranslation('crm');
  const [isVisible, setIsVisible] = useState(false);

  const initialValues = useMemo<FormValuesModel>(() => {
    const initialRelationship = {
      id: null,
      relationshipTemplate: {
        id: null,
        childRelationshipLabel: '',
      },

      parent: {
        id: '',
        type: OnboardType.Contact,
      },

      child: {
        id: null,
        type: null,
        label: '',
      },

      additionalFields: [],
    };

    return {
      entryId: contact._id,
      entryName: ContactHelpers.getContactName(contact),
      entryType: OnboardType.Contact,
      relationships: [initialRelationship],
    };
  }, [contact]);

  const handleOnSubmit = async (values: FormValuesModel) => {
    const formattedReqBody =
      relationshipAdapter.generateRequestBodyToCreateRelationship(values);
    await relationshipAPI.createRelationships(formattedReqBody);
    message.success(t('entity.relationships.addSuccess'));
  };

  const handleModalClose = (shouldUpdate?: boolean) => {
    if (shouldUpdate) {
      onAdd();
    }

    setIsVisible(false);
  };

  return (
    <>
      <PageButton
        type="primary"
        size="large"
        onClick={() => setIsVisible(true)}
      >
        {t('entity.relationships.addRelationship')}
      </PageButton>

      <AddRelationshipModalDialog
        title={t('entity.relationships.addRelationship')}
        isVisible={isVisible}
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        closeCallback={handleModalClose}
      />
    </>
  );
});

export default AddRelationships;
