import React from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { fiscalPeriodAPI } from '../../../../api/finance/fiscalPeriod/fiscalPeriodAPI';

// components
import { Button } from 'antd';

interface IProps {
  onGenerate: () => void;
}

const GenerateButton = ({ onGenerate }: IProps) => {
  const { t } = useTranslation('finance');

  const generateFiscalPeriod = async () => {
    await fiscalPeriodAPI.generateFiscalPeriod();
    onGenerate();
  };

  return (
    <Button type="primary" onClick={generateFiscalPeriod}>
      {t('fiscal_period.generate_fiscal_period')}
    </Button>
  );
};

export default GenerateButton;
