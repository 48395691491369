import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';

// components
import { Button } from 'antd';
import AddReviewConfigurationRuleModal from './AddReviewConfigurationRuleModal';

interface IProps {
  onAdd?: () => void;
}

const AddReviewConfigurationRule = ({ onAdd }: IProps) => {
  const { t } = useTranslation('compliance');

  const [isModalVisible, setVisibleModal] = useState(false);

  const closeCallback = (wasAdded?: boolean) => {
    if (wasAdded) {
      onAdd && onAdd();
    }

    setVisibleModal(false);
  };

  return (
    <>
      <Button type="primary" onClick={() => setVisibleModal(true)}>
        {t('review_configuration.add_review_configuration_rule')}
      </Button>

      <AddReviewConfigurationRuleModal
        isVisible={isModalVisible}
        closeCallback={closeCallback}
      />
    </>
  );
};

export default AddReviewConfigurationRule;
