import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import {
  ChangeRequestFromQuery,
  ChangeRequestStatuses,
  changeRequestsAPI,
} from 'api/crm/changeRequests/changeRequestsAPI';

// components
import Button from '@core_components/Button';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import RequestDataUpdateModal, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/RequestDataUpdateModal';
import { Col, Modal, Row, message } from 'antd';

interface IProps {
  changeRequest: ChangeRequestFromQuery;
}

const RequestContactDataUpdate = ({ changeRequest }: IProps) => {
  const { t } = useTranslation(['crm', 'common']);
  const [actualMessage, setActualMessage] = useState<string | null>(
    changeRequest.verification?.review?.comment || null,
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const initialFormValues = useMemo<FormValuesModel>(
    () => ({ message: actualMessage || '' }),
    [actualMessage],
  );

  const handleSubmit = async (values: FormValuesModel) => {
    await changeRequestsAPI.requestChangeRequestUpdate(changeRequest._id, {
      isValid: false,
      comment: values.message.trim(),
    });

    message.success(
      actualMessage
        ? t(
            'contacts.kyc_form.request_contact_data_update.edit.success_submit_message',
          )
        : t(
            'contacts.kyc_form.request_contact_data_update.create.success_submit_message',
          ),
    );

    setActualMessage(values.message);
  };

  const handleMessageDelete = async () => {
    Modal.confirm({
      icon: null,
      closable: true,
      maskClosable: true,
      width: 600,
      title: t(
        'contacts.kyc_form.request_contact_data_update.delete.modal_title',
      ),
      content: t(
        'contacts.kyc_form.request_contact_data_update.delete.confirmation_text',
      ),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        await changeRequestsAPI.deleteUpdateChangeRequestComment(
          changeRequest._id,
        );
        message.success(
          t(
            'contacts.kyc_form.request_contact_data_update.delete.success_submit_message',
          ),
        );
        setActualMessage(null);
        setIsModalVisible(false);
      },
    });
  };

  return (
    <>
      <StyledRow justify="space-between" align="middle" wrap={false}>
        <Col flex="auto">
          {actualMessage && (
            <StyledEllipsisTooltip
              title={actualMessage}
              maxTextContainerWidth="500px"
            >
              {actualMessage}
            </StyledEllipsisTooltip>
          )}
        </Col>
        <Col flex="300px">
          {changeRequest.status === ChangeRequestStatuses.InReview && (
            <StyledButton onClick={() => setIsModalVisible(true)}>
              {actualMessage
                ? t(
                    'contacts.kyc_form.request_contact_data_update.edit.button_text',
                  )
                : t(
                    'contacts.kyc_form.request_contact_data_update.create.button_text',
                  )}
            </StyledButton>
          )}
        </Col>
      </StyledRow>

      <RequestDataUpdateModal
        title={
          actualMessage
            ? t(
                'contacts.kyc_form.request_contact_data_update.edit.button_text',
              )
            : t(
                'contacts.kyc_form.request_contact_data_update.create.button_text',
              )
        }
        isVisible={isModalVisible}
        closeCallback={() => setIsModalVisible(false)}
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        onDelete={actualMessage ? handleMessageDelete : undefined}
      />
    </>
  );
};

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledEllipsisTooltip = styled(EllipsisTooltip)`
  color: ${({ theme }) => theme.warningColor};
`;

export default RequestContactDataUpdate;
