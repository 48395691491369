// TODO: Find some way to move all variables to one place for scss + styled components.
// IMPORTANT: Keep Styled Theme values the same as in antd theme "src/resources/theme/antd/dark-theme.scss"
export const darkTheme = {
  // Colors
  primaryGreen: '#00c573',
  primaryDarkGreen: '#04a260',
  textLightColor: '#dfe3e5',
  textLightColor2: '#c6cccf',
  textLightColor3: '#848d93',
  textLightColor4: '#798d99',
  textLightColor5: '#5e6a70',
  backgroundColor: '#1d2228',
  backgroundColor2: '#202f3b',
  backgroundColor3: '#242f39',
  backgroundColor4: '#1c2025',
  clickableColor: '#04a260',
  selectColor: '#28b864',
  successColor: '#40bea1',
  infoColor: '#43a8e0',
  errorColor: '#da5671',
  errorColorHover: '#be314d',
  warningColor: '#cc9b4d',
  highlightColor: '#22877f',
  textBackgroundColor: '#2a353d',
  textBackgroundColorActive: '#37424a',
  activeTabBackgroundColor: '#2a353d',
  whiteColor: '#ffffff',
  colorDarkL4: '#37424a',
  colorLight: '#c6cccf',
  colorWhite: '#ffffff',
  dragAndDropBackground: 'rgba(0, 0, 0, 0.2)',

  errorInputColor: '#ff4d4f',

  tableCardShadow:
    '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
  tableCardBackground:
    'linear-gradient(180deg,rgba(36, 57, 67, 0.95),rgba(33, 43, 55, 0.95))',

  // box shadows
  errorHighlightShadow: '0 0 4px 3px rgba(218,86,113, 1)',
  successHighlightShadow: '0 0 4px 3px rgba(0, 197, 115, 1)',
  cardShadow:
    '0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07)',

  // Font Sizes
  fontSizeLg: '24px',
  fontSizeMd: '18px',
  fontSizeSm: '16px',
  fontSizeXs: '14px',
  fontSizeXXs: '12px',

  // Sizes
  heightLg: '80px',
  heightMd: '60px',

  // Paddings
  paddingXXl: '38px',
  paddingXl: '20px',
  paddingMd: '12px',
  paddingSm: '8px',
  paddingXs: '6px',
  containerPadding: '20px',

  // Margins
  marginXXl: '60px',
  marginXl: '40px',
  marginMd: '24px',
  marginSm: '15px',
  marginXs: '10px',
  marginXXs: '6px',

  // ** Components **
  // Card
  cardBorderColor: '#42515b',

  // Menu
  dropdownBorder: '#848d93',

  // Drag And Drop
  dragAndDropTextColor: '#ffffff',

  // Header
  headerBackground: '#1c2025',
  headerHeight: '80px',
  headerHeightTablet: '110px',
  headerHeightMobile: '60px',
  headerPadding: '12px',
  headerLogoWidth: '180px',
  headerMenuFontSize: '12px',

  // Footer
  footerBackground: '#1c2025',
  footerHeight: '64px',
  footerMargin: '0px',

  // Modal
  modalPadding: '38px',

  // Table
  tableBorderColor: '#34424c',
  tableBackground: '#1d2228',
  tableHeadTextColor: '#798d99',

  // Sidebar
  sidebarBackground: '#1c2025',
  leftDrawerWidth: '350px',

  // Screen sizes
  /* Screen Sizes */
  tabletScreen: '992px',
  secondHeaderScreen: '1200px',

  // Transition
  defaultTransition: '0.3s all',

  // Radius
  borderRadiusRegular: '5px',

  // Text
  letterSpacingForCodes: '1.5px',
};
