import React, { useCallback } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { generateUniqId } from '@helpers/utils';
import { UploadFormValuesModel } from '../../../../typings/documents/documents';
import { DocumentAssociationTypes } from 'enums/crm/crm';

// components
import FormField from '@core_components/FormField';
import DeleteButton from '@common_components/Buttons/DeleteButton';
import AssociationTypeSelect from '../../../../components/Forms/FormComponents/SelectInputs/AssociationTypeSelect';
import DocumentTagAutocomplete from '../../../../components/Forms/FormComponents/Autocompletes/Documents/DocumentTagAutocomplete';
import AssociationObjectAutocomplete from './AssociationObjectAutocomplete';
import { Input, Divider, Button, Row, Col } from 'antd';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';

interface IProps {
  fileIndex: number;
  fieldName: string;
  isLastItem: boolean;
  handleDelete: () => void;
  allowedAssociationTypes?: DocumentAssociationTypes[];
}

const FormFileItem = ({
  fieldName,
  fileIndex,
  isLastItem,
  handleDelete,
  allowedAssociationTypes,
}: IProps) => {
  const { t } = useTranslation(['crm', 'form']);
  const { values, setFieldValue } = useFormikContext<UploadFormValuesModel>();

  const renderAssociations = useCallback(
    (arrayHelper: FieldArrayRenderProps) => {
      const { files } = values;

      return (
        <>
          {files[fileIndex].associations.map((e, index) => (
            <Row
              gutter={[16, 16]}
              key={e.uniqueId}
              wrap={false}
              justify="space-between"
              align="middle"
            >
              <Col flex="350px">
                <FormField
                  label={t('documents.uploadDocuments.association_type')}
                  name={`${fieldName}.${fileIndex}.associations.${index}.type`}
                  component={AssociationTypeSelect}
                  placeholder={t(
                    'documents.uploadDocuments.select_association.choose_option',
                  )}
                  additionalProps={{
                    allowClear: true,
                    allowedAssociationTypes,
                  }}
                  handleOnChangeManually={(e: any) => {
                    setFieldValue(
                      `${fieldName}.${fileIndex}.associations.${index}.id`,
                      '',
                    );
                    setFieldValue(
                      `${fieldName}.${fileIndex}.associations.${index}.type`,
                      e,
                    );
                  }}
                />
              </Col>

              <Col flex="auto">
                <FormField
                  label={t('documents.uploadDocuments.association_object')}
                  name={`${fieldName}.${fileIndex}.associations.${index}.id`}
                  component={AssociationObjectAutocomplete}
                  placeholder={t(
                    'documents.uploadDocuments.search_association',
                  )}
                  additionalProps={{
                    allowClear: true,
                    fileIndex: fileIndex,
                    associationIndex: index,
                  }}
                />
              </Col>

              <Col>
                <StyledDeleteButton onClick={() => arrayHelper.remove(index)} />
              </Col>
            </Row>
          ))}
          <Button onClick={() => handleAddNewAssociation(arrayHelper)}>
            {t('contacts.new.add_association')}
          </Button>
        </>
      );
    },
    [values],
  );

  const handleAddNewAssociation = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.push({ uniqueId: generateUniqId(), id: '', type: '' });
  };

  const renderDocumentFields = () => (
    <>
      <FormField
        label={t('documents.uploadDocuments.original_name')}
        name={`${fieldName}.${fileIndex}.originalName`}
        component={Input}
        disabled
      />

      <FormField
        label={t('documents.uploadDocuments.file_name')}
        name={`${fieldName}.${fileIndex}.name`}
        component={Input}
      />

      <FormField
        label={t('documents.uploadDocuments.description')}
        name={`${fieldName}.${fileIndex}.description`}
        component={Input.TextArea}
        placeholder={t('enterPlaceholder', {
          ns: 'form',
          fieldName: t('documents.uploadDocuments.description').toLowerCase(),
        })}
      />

      <AssociationWrapper>
        <FieldArray
          name={`${fieldName}.${fileIndex}.associations`}
          render={renderAssociations}
        />
      </AssociationWrapper>

      <FormField
        label={t('documents.table.tags')}
        name={`${fieldName}.${fileIndex}.tags`}
        component={DocumentTagAutocomplete}
        placeholder={t('documents.uploadDocuments.search_tag')}
        additionalProps={{ allowClear: true, mode: 'tags' }}
      />

      <EndAlign>
        <Button type="default" danger onClick={handleDelete}>
          {t('documents.uploadDocuments.delete')}
        </Button>
      </EndAlign>
    </>
  );

  return (
    <>
      {renderDocumentFields()}
      {!isLastItem && <StyledDivider />}
    </>
  );
};

const StyledDivider = styled(Divider)`
  margin-bottom: 0px 0px 15px 0px;
`;

const EndAlign = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AssociationWrapper = styled.div`
  margin-bottom: 24px;
`;

const StyledDeleteButton = styled(DeleteButton)`
  margin-top: 10px;
`;

export default FormFileItem;
