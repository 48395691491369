import React, { memo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';

// components
import SectionIntro from '@common_components/Texts/SectionIntro';
import ContactDetailsForm, {
  FormValuesModel as ContactDetailsFormValuesModel,
} from '../ContactDetailsForm';
import ContactIdentificationForm, {
  FormValuesModel as ContactIdentificationFormValuesModel,
} from '../ContactIdentificationForm';

export interface FormValuesModel
  extends ContactDetailsFormValuesModel,
    ContactIdentificationFormValuesModel {}

const ContactDetailsWithIdentificationForm = memo(() => {
  const { t } = useTranslation('crm');
  return (
    <>
      <ContactDetailsForm />
      <SectionIntro
        titleVariant="h4"
        title={t('contacts.new.identification_section')}
      />
      <ContactIdentificationForm />
    </>
  );
});

export default ContactDetailsWithIdentificationForm;
