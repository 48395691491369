import React, { useMemo } from 'react';

// helpers
import { message } from 'antd';
import useTranslation from '../../../../hooks/useTranslation';
import reviewConfigurationAPI from '../../../../api/compliance/reviewConfigurationAPI';

// components
import ReviewConfigurationRuleModal, {
  FormValuesModel,
} from '../../../../components/Modals/TemplateModalDialogs/Compliance/ReviewConfigurationRuleModal';
import reviewConfigurationAdapter from '../../../../apiAdapters/compliance/reviewConfigurationAdapter';

interface IProps {
  isVisible: boolean;
  closeCallback: (wasAdded?: boolean) => void;
}

const AddReviewConfigurationRuleModal = ({
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('compliance');

  const initialFormValues: FormValuesModel = useMemo(() => {
    return {
      reviewType: null,
      riskLevel: null,
      referenceObject: null,
      referenceProperty: null,
      comparisonOperator: null,
      expectedDataType: null,
      expectedValueType: null,
      expectedValue: undefined,
      isManualReview: null,
      state: null,
    };
  }, []);

  const handleOnSubmit = async (values: FormValuesModel) => {
    const formattedRule =
      reviewConfigurationAdapter.createReviewConfigurationRule(values);
    await reviewConfigurationAPI.createReviewConfigurationRule(formattedRule);
    message.success(t('review_configuration.success_create'));
  };

  return (
    <ReviewConfigurationRuleModal
      mode="create"
      title={t('review_configuration.add_review_configuration_rule')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialFormValues={initialFormValues}
      onSubmit={handleOnSubmit}
    />
  );
};

export default AddReviewConfigurationRuleModal;
