import React, { useMemo } from 'react';

// helpers
import useFetch from '../../hooks/useFetch';
import useUserAccess from '../../hooks/useUserAccess';
import useQueryParam from '../../hooks/useQueryParam';
import useTranslation from '../../hooks/useTranslation';
import { RoutePaths } from '../../routes/routes';
import { correspondentBanksAPI } from '../../api/banking/correspondentBanksAPI';

// components
import LoadingWrapper from '../../components/WrapperComponents/LoadingWrapper';
import CorrespondentBankInfo from '../../modules/Banking/CorrespondentBankDetails/CorrespondentBankInfo';
import ClientBankAccountsTab from '../../modules/Banking/CorrespondentBankDetails/ClientBankAccountsTab';
import CorrespondentBankAccountsTab from '../../modules/Banking/CorrespondentBankDetails/CorrespondentBankAccountsTab';
import Tabs, { TabModel } from '../../components/Tabs';
import { CardWrapper } from '../../layouts/PageLayout';

const CorrespondentBankDetailsPage = () => {
  const { t } = useTranslation('banking');
  const bankId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.Banking_CorrespondentBanks,
  });
  const { response, loading } = useFetch(
    () =>
      bankId ? correspondentBanksAPI.fetchCorrespondentBankById(bankId) : null,
    [bankId],
  );
  const [hasCorrespondentAccountsTabAccess, hasClientAccountsAccess] =
    useUserAccess([
      'Banking_CorrespondentBanks_Details_CorrespondentAccountsTab_View',
      'Banking_CorrespondentBanks_Details_CorrespondentClientsTab_View',
    ]);

  const tabs: TabModel[] = useMemo(
    () => [
      {
        hidden: !hasCorrespondentAccountsTabAccess,
        key: 'correspondent_accounts',
        title: t(
          'correspondent_banks.details.correspondent_accounts_tab.title',
        ),
        content: <CorrespondentBankAccountsTab bankId={bankId} />,
      },

      {
        hidden: !hasClientAccountsAccess,
        key: 'client_bank_accounts',
        title: t('correspondent_banks.details.client_accounts_tab.title'),
        content: <ClientBankAccountsTab bankId={bankId} />,
      },
    ],
    [bankId, hasCorrespondentAccountsTabAccess, hasClientAccountsAccess],
  );

  return (
    <LoadingWrapper loading={loading}>
      {!!response && (
        <>
          <CorrespondentBankInfo correspondentBank={response} />

          <CardWrapper>
            <Tabs tabs={tabs} />
          </CardWrapper>
        </>
      )}
    </LoadingWrapper>
  );
};

export default CorrespondentBankDetailsPage;
