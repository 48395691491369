import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from '../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../hooks/useTranslation';

// components
import { Button, Col, Row } from 'antd';

import ApprovalGroupsTable from './ApprovalGroupsTable';
import AddApprovalGroupModal from './AddApprovalGroupModal';
import EditApprovalGroupModal from './EditApprovalGroupModal';

const ApprovalGroupsTab = () => {
  const { t } = useTranslation('approval_management');

  const [updateTableTrigger, updateTable] = useState({});
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [isAddApprovalGroupModal, setIsAddApprovalGroupModal] = useState(false);

  const [hasCreateAccess, hasEditAccess] = useUserAccess([
    'ApprovalManagement_Templates_Finance_ApprovalGroups_Create',
    'ApprovalManagement_Templates_Finance_ApprovalGroups_Edit',
  ]);

  const handleAddGroupClick = () => {
    setIsAddApprovalGroupModal(true);
  };

  const handleModalClose = (wasCreated?: boolean) => {
    wasCreated && updateTable({});
    setIsAddApprovalGroupModal(false);
  };

  const handleCloseEditModal = (shouldUpdate?: boolean) => {
    shouldUpdate && updateTable({});
    setSelectedGroup(null);
  };

  const handleOnActionsClick = (key: string, groupId: string) => {
    switch (key) {
      case 'edit':
        setSelectedGroup(groupId);
        break;
    }
  };

  return (
    <>
      {hasCreateAccess && (
        <Row justify="end">
          <Col flex="150px">
            <StyledButton
              size="large"
              type="primary"
              onClick={handleAddGroupClick}
            >
              {t('approval_management.approval_groups.add_group')}
            </StyledButton>
          </Col>
        </Row>
      )}

      <ApprovalGroupsTable
        onActionsClick={handleOnActionsClick}
        updateTableTrigger={updateTableTrigger}
        hasEditAccess={hasEditAccess}
      />

      {hasCreateAccess && (
        <AddApprovalGroupModal
          isVisible={isAddApprovalGroupModal}
          closeCallback={handleModalClose}
        />
      )}

      {hasEditAccess && (
        <EditApprovalGroupModal
          isVisible={!!selectedGroup}
          closeCallback={handleCloseEditModal}
          groupId={selectedGroup as string}
        />
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

export default ApprovalGroupsTab;
