// helpers
import { SubRouteModel } from '../../../typings/routes';

// helper components & layouts
import Navigation from '../../../pages/Navigation';
import PageLayout from '../../../layouts/PageLayout';

// pages
import Finance from '../../../pages/ApprovalManagement/Templates/Finance';
import Compliance from '../../../pages/ApprovalManagement/Templates/Compliance';
import NewBusiness from '../../../pages/ApprovalManagement/Templates/NewBusiness';
import ForexTransfers from '../../../pages/ApprovalManagement/Templates/ForexTransfers';
import SeniorManagement from '../../../pages/ApprovalManagement/Templates/SeniorManagement';
import ApprovalWorkflowApprovalManagement from '../../../pages/ApprovalManagement/ApprovalWorkflow';

enum ApprovalManagementRoutesEnum {
  ApprovalManagement_Root = '/approval-management',
  ApprovalManagement_ApprovalWorkflow = '/approval-management/approval-workflow',
  ApprovalManagement_Templates = '/approval-management/templates',
  ApprovalManagement_Templates_NewBusiness = '/approval-management/templates/new-business',
  ApprovalManagement_Templates_Compliance = '/approval-management/templates/compliance',
  ApprovalManagement_Templates_ForexTransfers = '/approval-management/templates/forex-transfers',
  ApprovalManagement_Templates_SeniorManagement = '/approval-management/templates/senior-management',
  ApprovalManagement_Templates_Finance = '/approval-management/templates/finance',
}

const approvalManagementRoutes: SubRouteModel[] = [
  {
    title: 'Approval Management',
    path: ApprovalManagementRoutesEnum.ApprovalManagement_Root,
    layout: PageLayout,
    component: Navigation,
    permission: { permissionKey: 'ApprovalManagement_View' },
    childRoutes: [
      {
        title: 'Approval Workflow',
        path: ApprovalManagementRoutesEnum.ApprovalManagement_ApprovalWorkflow,
        layout: PageLayout,
        component: ApprovalWorkflowApprovalManagement,
        permission: { permissionKey: 'ApprovalManagement_Workflow_View' },
      },

      {
        title: 'Templates',
        path: ApprovalManagementRoutesEnum.ApprovalManagement_Templates,
        component: Navigation,
        layout: PageLayout,
        permission: { permissionKey: 'ApprovalManagement_Templates_View' },
        childRoutes: [
          {
            title: 'New Business',
            path: ApprovalManagementRoutesEnum.ApprovalManagement_Templates_NewBusiness,
            layout: PageLayout,
            component: NewBusiness,
            permission: {
              permissionKey: 'ApprovalManagement_Templates_NewBusiness_View',
            },
          },

          {
            title: 'Compliance',
            path: ApprovalManagementRoutesEnum.ApprovalManagement_Templates_Compliance,
            layout: PageLayout,
            component: Compliance,
            permission: {
              permissionKey: 'ApprovalManagement_Templates_Compliance_View',
            },
          },

          {
            title: 'Forex transfers',
            path: ApprovalManagementRoutesEnum.ApprovalManagement_Templates_ForexTransfers,
            layout: PageLayout,
            component: ForexTransfers,
            permission: {
              permissionKey: 'ApprovalManagement_Templates_ForexTransfers_View',
            },
          },

          {
            title: 'Senior Management',
            path: ApprovalManagementRoutesEnum.ApprovalManagement_Templates_SeniorManagement,
            layout: PageLayout,
            component: SeniorManagement,
            permission: {
              permissionKey:
                'ApprovalManagement_Templates_SeniorManagement_View',
            },
          },

          {
            title: 'Finance',
            path: ApprovalManagementRoutesEnum.ApprovalManagement_Templates_Finance,
            layout: PageLayout,
            component: Finance,
            permission: {
              permissionKey: 'ApprovalManagement_Templates_Finance_View',
            },
          },
        ],
      },
    ],
  },
];

export { approvalManagementRoutes, ApprovalManagementRoutesEnum };
