import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { foreignExchangeAPI } from '../../../../api/banking/foreignExchangeAPI';

// components
import SpreadMatrix from './SpreadMatrix';
import SectionTitle from '../../../../components/Typography/SectionTitle';
import LoaderSection from '../../../../components/Loaders/LoaderSection';

const ForexSpreadMatrixSection = () => {
  const { t } = useTranslation('page_titles');
  const [updateRangesTrigger, updateRanges] = useState({});
  const { response, loading } = useFetch(
    () => foreignExchangeAPI.fetchForeignExchangeSpreadsByClientGroupId(0),
    [updateRangesTrigger],
  );

  const updateSpreadRanges = () => {
    updateRanges({});
  };

  if (loading) {
    return <LoaderSection />;
  }

  return (
    <>
      <SectionTitle>{t('Forex Spread Matrix')}</SectionTitle>
      <SpreadMatrix
        spreadRanges={response || []}
        updateSpreadRangesCallback={updateSpreadRanges}
      />
    </>
  );
};

export default ForexSpreadMatrixSection;
