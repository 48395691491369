import React, { memo } from 'react';

// helpers
import useQueryParam from '@hooks/useQueryParam';
import { RoutePaths } from 'routes/routes';

// components
import ForgotUsernameRequestDetails from 'modules/ORAC/ForgotUsernameRequests/ForgotUsernameRequestDetails';

const ForgotUsernameRequestDetailsPage = memo(() => {
  const requestId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.ORAC_ForgotUsernameRequests,
  });

  return <ForgotUsernameRequestDetails requestId={requestId} />;
});

export default ForgotUsernameRequestDetailsPage;
