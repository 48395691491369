import React from 'react';

// helpers
import { bankingTransactionsAPI } from 'api/banking/bankingTransactionsAPI';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import Autocomplete, {
  AutocompleteOption,
  AutocompleteProps,
} from '@core_components/Autocomplete';

type IProps = AutocompleteProps;

const OrderingCustomerAutocomplete = (props: IProps) => {
  const fetchOptions = async (searchQuery: string) => {
    const response = await bankingTransactionsAPI.fetchOrderingCustomers({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      searchQuery,
    });

    return response.data.map<AutocompleteOption>((e) => ({
      id: e,
      label: e,
      model: e,
    }));
  };

  return <Autocomplete {...props} fetchData={fetchOptions} />;
};

export default OrderingCustomerAutocomplete;
