import React, { memo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';
import { TWO_COL_GRID_SIZES } from '../../../../../../../constants/grids';
import { BankAccountStatuses } from 'enums/banking/accounts';

// components
import FormField from '@core_components/FormField';
import AmountField from '../../../../../../Forms/FormComponents/AmountField';
import SectionIntro from '../../../../../../DesignSystem/Common/Texts/SectionIntro';
import CountrySelect from '../../../../../../Forms/FormComponents/SelectInputs/CountrySelect';
import FrozenStatusSelect from '../../../../../../Forms/FormComponents/SelectInputs/FrozenStatusSelect';
import ActiveStatusSelect from '../../../../../../Forms/FormComponents/SelectInputs/ActiveStatusSelect';
import WireRangesFormSelect from '../../../../../../Forms/FormComponents/SelectInputs/WireRangesFormSelect';
import AccountTypeFormSelect from 'components/Forms/FormComponents/SelectInputs/AccountTypeFormSelect';
import InitialDepositRangeFormSelect from '../../../../../../Forms/FormComponents/SelectInputs/InitialDepositRangeFormSelect';
import EstimatedAmountFundsRangeFormSelect from '../../../../../../Forms/FormComponents/SelectInputs/EstimatedAmountFundsRangeFormSelect';
import { Col, Input, Row, Typography } from 'antd';

const InnerForm = memo(() => {
  const { t } = useTranslation('banking');

  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <FormField
        disabled
        label={t(
          'banking.bank_accounts.bank_account_modal_dialog.account_number',
        )}
        name="accountNumber"
        component={Input}
      />

      <FormField
        disabled
        label={t(
          'banking.bank_accounts.bank_account_modal_dialog.account_name',
        )}
        name="accountName"
        component={Input}
      />

      <FormField
        disabled
        label={t('banking.bank_accounts.bank_account_modal_dialog.currency')}
        name="currency"
        component={Input}
      />

      <FormField
        disabled
        label={t(
          'banking.bank_accounts.bank_account_modal_dialog.account_category',
        )}
        name="accountCategory"
        component={Input}
      />

      <FormField
        disabled
        label={t('banking.bank_accounts.bank_account_modal_dialog.balance')}
        name="balance"
        component={StyledAmountField}
      />

      <FormField
        disabled
        label={t(
          'banking.bank_accounts.bank_account_modal_dialog.balance_home',
        )}
        name="balanceHome"
        component={StyledAmountField}
      />
      {![
        BankAccountStatuses.Processing,
        BankAccountStatuses.PendingApproval,
      ].includes(values.status) && (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <FormField
              label={t(
                'banking.bank_accounts.bank_account_modal_dialog.is_active',
              )}
              name="isActive"
              component={ActiveStatusSelect}
              additionalProps={{ allowClear: true }}
            />
          </Col>
          <Col span={12}>
            <FormField
              label={t(
                'banking.bank_accounts.bank_account_modal_dialog.is_frozen',
              )}
              name="isFrozen"
              component={FrozenStatusSelect}
              additionalProps={{ allowClear: true }}
            />
          </Col>
        </Row>
      )}

      <FormField
        disabled
        label={t('banking.bank_accounts.bank_account_modal_dialog.purpose')}
        name="purpose"
        component={Input.TextArea}
        additionalProps={{ autoSize: { minRows: 2, maxRows: 6 } }}
      />

      <FormField
        disabled
        label={t('banking.bank_accounts.bank_account_modal_dialog.type')}
        name="type"
        component={AccountTypeFormSelect}
      />

      <FormField
        disabled
        label={t(
          'banking.bank_accounts.bank_account_modal_dialog.initial_deposit',
        )}
        name="initialDeposit"
        component={InitialDepositRangeFormSelect}
      />

      <FormField
        disabled
        label={t(
          'banking.bank_accounts.bank_account_modal_dialog.initial_deposit_origin',
        )}
        name="initialDepositOrigin"
        component={CountrySelect}
        additionalProps={{ allowClear: true }}
      />

      <SectionIntro
        title={t(
          'banking.bank_accounts.bank_account_modal_dialog.estimated_monthly_account_activity',
        )}
        titleVariant="h4"
      />

      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <Typography.Text>
            {t('banking.bank_accounts.bank_account_modal_dialog.jurisdictions')}
          </Typography.Text>
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            disabled
            name="jurisdictions"
            component={CountrySelect}
            additionalProps={{ allowClear: true, mode: 'multiple' }}
          />
        </Col>
      </Row>

      <StyledRow gutter={[16, 0]}>
        <Col offset={4} span={10}>
          <Typography.Text>
            {t('banking.bank_accounts.bank_account_modal_dialog.volume')}
          </Typography.Text>
        </Col>

        <Col span={10}>
          <Typography.Text>
            {t('banking.bank_accounts.bank_account_modal_dialog.amount')}
          </Typography.Text>
        </Col>
      </StyledRow>

      <Row gutter={[16, 16]}>
        <Col xl={4} lg={4} md={24} sm={24} xs={24}>
          <Typography.Text>
            {t(
              'banking.bank_accounts.bank_account_modal_dialog.incoming_activity',
            )}
          </Typography.Text>
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            disabled
            name="incomingWiresNumber"
            component={WireRangesFormSelect}
          />
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            disabled
            name="estimatedIncomingFunds"
            component={EstimatedAmountFundsRangeFormSelect}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xl={4} lg={4} md={24} sm={24} xs={24}>
          <Typography.Text>
            {t(
              'banking.bank_accounts.bank_account_modal_dialog.outgoing_activity',
            )}
          </Typography.Text>
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            disabled
            name="outgoingWiresNumber"
            component={WireRangesFormSelect}
          />
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            disabled
            name="estimatedOutgoingFunds"
            component={EstimatedAmountFundsRangeFormSelect}
          />
        </Col>
      </Row>
    </>
  );
});

const StyledAmountField = styled(AmountField)`
  width: 100%;
`;

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

export default InnerForm;
