import React from 'react';

// helpers
import groupsAPI from '../../../../../../api/orac/groupsAPI';
import useTranslation from '../../../../../../hooks/useTranslation';
import { message } from 'antd';

// components
import Form from '@core_components/Form';
import InnerForm from './InnerForm';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  groupId: string;
}

interface FormValuesModel {
  roleId: string | null;
}

const AddRoleToGroupModal = ({ isVisible, closeCallback, groupId }: IProps) => {
  const { t } = useTranslation('orac');

  const initialFormValues: FormValuesModel = { roleId: null };

  const onSubmit = async (values: FormValuesModel) => {
    const { roleId } = values;
    await groupsAPI.addRolesToGroup(groupId, [roleId] as string[]);
    message.success(t('groups.view.add_role_to_group_success'));
  };

  return (
    <Form<FormValuesModel>
      enableReinitialize
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      renderForm={
        <ModalDialog
          width="500px"
          title={t('groups.view.add_role_to_group')}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <InnerForm />
        </ModalDialog>
      }
    />
  );
};

export default AddRoleToGroupModal;
