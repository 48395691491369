import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { useField } from 'formik';
import { darkTheme } from '../../../../../resources/theme/styled';
import { CrmItemModel } from '../../../../../api/crm/relationship/relationshipAPI';
import { IDocumentForm } from 'components/Forms/FormComponents/AdditionalFieldNew';
import { RelationshipHelpers } from '@helpers/crm/relationship';
import { IAdditionalFieldAnswer } from '../../../../../typings/crm/entity';
import { AutocompleteInitialValue } from '../../../../Fields/Autocomplete';
import { IRelationshipTemplateModel } from '../../../../../typings/crm/relationship-template';
import { RELATIONSHIP_TEMPLATE_INVITED_BY_ID } from '../../../../../constants/crm';
import {
  AdditionalFieldType,
  OnboardType,
  RelationshipTrackType,
} from '../../../../../enums/crm/crm';

// components
import IconSVG from '../../../../DesignSystem/Core/IconSVG';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import IconButton from '../../../../DesignSystem/Core/IconButton';
import SectionIntro from '../../../../DesignSystem/Common/Texts/SectionIntro';
import AdditionalField from '../../../FormComponents/AdditionalField';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import RelationshipTemplateSelect from '../../../FormComponents/SelectInputs/RelationshipTemplateSelect';
import RelationshipEntryAutocomplete from '../../../FormComponents/Autocompletes/CRM/RelationshipEntryAutocomplete';
import { Col, Row } from 'antd';
import { ReactComponent as DeleteIcon } from '../../../../../resources/icons/remix-icons/delete-bin-6-line.svg';

export type ParentNodeOfRelationshipModel = {
  id: string | null;
  type: OnboardType | null;
};

export type FormValuesModel = {
  id: string | null;

  parent: ParentNodeOfRelationshipModel;

  child: {
    id: string | null;
    type: OnboardType | null;
    label: string;
    autocompleteInitialValue?: AutocompleteInitialValue;
  };

  relationshipTemplate: {
    id: string | null;
    childRelationshipLabel: string;
  };

  additionalFields: IAdditionalFieldAnswer[];

  isApplicantRelationship?: boolean;

  isOwnership?: boolean;
  disableAdditionalFields?: boolean;
};

export type AdditionalFieldFormItemModel = {
  name: string;
  value: any | undefined;
  type: AdditionalFieldType;
  relatedTo: OnboardType | null;
  options: {
    onboardingRequired: boolean;
    isForRegulatedOnly: boolean;
    documentRequiresCertification: boolean;
    selectOptions: string[];
    formDocument: IDocumentForm;
  };
};

interface IProps {
  entryId: string;
  entryType: OnboardType;
  fieldName: string;

  relationshipIndex?: number;
  removeCallback?: () => void;
}

const RelationshipForm = ({
  fieldName,
  removeCallback,
  relationshipIndex,
}: IProps) => {
  const { t } = useTranslation('crm');
  const [field, , helpers] = useField<FormValuesModel>(fieldName);

  const isNewRelationship = !field.value.id;

  const handleChildSelect = (newValue: string, item: CrmItemModel) => {
    helpers.setValue({
      ...field.value,
      child: {
        ...field.value.child,
        id: newValue,
        // For this case we need only 2 cases Entity and Contact.
        type: item.type as any,
        label: item.name,
      },
      relationshipTemplate: {
        id: null,
        childRelationshipLabel: '',
      },
    });
  };

  const renderAdditionalForm = (additionalFields: IAdditionalFieldAnswer[]) => {
    return additionalFields.map((additionalField, index) => (
      <Col span="12" key={`${additionalField.name}-${index}`}>
        <AdditionalField
          key={index}
          name={`${fieldName}.additionalFields.${index}.value`}
          disabled={
            (!isNewRelationship &&
              field.value.relationshipTemplate.id ===
                RELATIONSHIP_TEMPLATE_INVITED_BY_ID) ||
            additionalField.isRemoved
          }
          additionalField={additionalField}
        />
      </Col>
    ));
  };

  const handleTemplateSelect = (
    newValue: any,
    relationshipTemplate: IRelationshipTemplateModel,
  ) => {
    if (relationshipTemplate) {
      const additionalFields = relationshipTemplate.additionalFields
        .filter(
          ({ relatedTo }) =>
            relatedTo === 'any' ||
            (relatedTo as any) === field.value.parent.type,
        )
        .map((e) => ({
          name: e.name,
          type: e.type,
          value: undefined,
          relatedTo: e.relatedTo,
          options: {
            onboardingRequired: e.options.onboardingRequired,
            isForRegulatedOnly: e.options.isForRegulatedOnly,
            documentRequiresCertification:
              e.options.documentRequiresCertification,
            selectOptions: e.options.selectOptions.length
              ? e.options.selectOptions.map((e) => JSON.parse(e.valueJSON))
              : [],
            formDocument: e.options.formDocument,
            isDirectClientOnly: e.options.isDirectClientOnly,
          },
          valueJSON: '',
          _id: e._id as string,
        }));

      helpers.setValue({
        ...field.value,
        additionalFields,
        relationshipTemplate: {
          ...field.value.relationshipTemplate,
          id: newValue,
          childRelationshipLabel: RelationshipHelpers.getRelationshipName(
            {
              parentName: relationshipTemplate.parentName,
              childName: relationshipTemplate.childName,
            },
            RelationshipTrackType.Child,
          ),
        },
      });
    }
  };

  return (
    <>
      {!!relationshipIndex && (
        <SectionIntro
          titleVariant="h5"
          title={t(
            'entity.relationships.relationship_modal.relationship_title',
            { index: relationshipIndex },
          )}
          appendToTitle={
            removeCallback && (
              <HideIfDisabledForm hideIfSubmitting>
                <StyledDeleteButton
                  icon={
                    <IconSVG
                      component={DeleteIcon}
                      color={darkTheme.errorColor}
                    />
                  }
                  type="bordered"
                  danger
                  onClick={removeCallback}
                />
              </HideIfDisabledForm>
            )
          }
        />
      )}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FormField
            disabled={!isNewRelationship}
            label={t(
              'entity.relationships.relationship_modal.form_fields.child_node',
            )}
            name={`${fieldName}.child.id`}
            component={RelationshipEntryAutocomplete}
            placeholder={t('entity.relationships.selectEntry')}
            additionalProps={{
              allowClear: true,
              initialValue: field.value.child.autocompleteInitialValue,
              onSelect: (newValue: string, option: CrmItemModel) => {
                option && handleChildSelect(newValue, option);
              },
            }}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <FormField
            disabled
            label={t(
              'entity.relationships.relationship_modal.form_fields.parent_node',
            )}
            name="entryName"
            component={FormInput}
            placeholder={t(
              'entity.relationships.relationship_modal.placeholder_optional',
            )}
          />
        </Col>

        <Col span={12}>
          <FormField
            disabled={
              !isNewRelationship ||
              !(field.value.parent.type && field.value.child.id)
            }
            label={t(
              'entity.relationships.relationship_modal.form_fields.parent_relationship_template',
            )}
            name={`${fieldName}.relationshipTemplate.id`}
            component={RelationshipTemplateSelect}
            placeholder={t('entity.relationships.selectRelationshipTemplate')}
            additionalProps={{
              allowClear: true,
              childId: field.value.child.id,
              filterByKeyRelationshipTemplateId: false,
              entryType: field.value.parent.type,
              onSelect: (newValue: any, newTemplate: any) =>
                handleTemplateSelect(newValue, newTemplate.model),
            }}
          />
        </Col>

        <Col span={12}>
          <FormField
            disabled
            label={t(
              'entity.relationships.relationship_modal.form_fields.child_node',
            )}
            name={`${fieldName}.child.label`}
            placeholder={t(
              'entity.relationships.relationship_modal.placeholder_optional',
            )}
            component={FormInput}
          />
        </Col>

        <Col span={12}>
          <FormField
            disabled
            label={t(
              'entity.relationships.relationship_modal.form_fields.child_relationship_template',
            )}
            name={`${fieldName}.relationshipTemplate.childRelationshipLabel`}
            placeholder={t(
              'entity.relationships.relationship_modal.placeholder_optional',
            )}
            component={FormInput}
          />
        </Col>
      </Row>

      {!!field.value.additionalFields.length && (
        <>
          <SectionIntro
            title={t(
              'entity.relationships.relationship_modal.additional_fields_section',
            )}
            titleVariant="h5"
          />
          <Row gutter={[16, 0]}>
            {renderAdditionalForm(field.value.additionalFields)}
          </Row>
        </>
      )}
    </>
  );
};

const StyledDeleteButton = styled(IconButton)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

export default RelationshipForm;
