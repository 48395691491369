import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

// components
import DeleteConfirmation from '../../Modals/DeleteConfirmation';
import { Button, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface IProps {
  deleteCallback: (reason?: string) => Promise<any>;
  title?: string;
  confirmText?: string;
  shouldAskReason?: boolean;
  btnSize?: SizeType;
}

const ConfirmDeleteButton = ({
  confirmText,
  deleteCallback,
  shouldAskReason,
  btnSize = 'middle',
  title,
}: IProps) => {
  const { t } = useTranslation('common');
  const [isVisible, setIsVisible] = useState(false);

  const onDeleteClick = () => {
    if (shouldAskReason) {
      setIsVisible(true);
    } else {
      Modal.confirm({
        title,
        content: confirmText,
        okText: t('yes', { ns: 'common' }),
        cancelText: t('no', { ns: 'common' }),
        onOk: deleteCallback,
      });
    }
  };

  return (
    <>
      <Button danger onClick={onDeleteClick} size={btnSize}>
        <StyledDeleteIcon />
        {t('delete', { ns: 'common' })}
      </Button>

      {shouldAskReason && (
        <DeleteConfirmation
          isVisible={isVisible}
          closeCallback={() => setIsVisible(false)}
          onSubmitCallback={deleteCallback}
        />
      )}
    </>
  );
};

const StyledDeleteIcon = styled(DeleteOutlined)`
  color: ${({ theme }) => theme.errorColor};
  font-size: 14px;
`;

export default ConfirmDeleteButton;
