import React, { useEffect, useState } from 'react';

// helpers
import useUserAccess from '../../../hooks/useUserAccess';
import { PageLayoutHelperPropsModel } from '../../../layouts/PageLayout';

// components
import AddUser from '../../../modules/ORAC/Users/AddUser';
import UsersTable from '../../../modules/ORAC/Users/UsersTable';

type IProps = PageLayoutHelperPropsModel;

const Users = ({ setHeaderOptions }: IProps) => {
  const [updateTableTrigger, updateTable] = useState({});

  const [hasCreateAccess] = useUserAccess(['ORAC_Users_Create']);

  useEffect(() => {
    if (hasCreateAccess) {
      setHeaderOptions({
        extra: () => <AddUser onAdd={() => updateTable({})} />,
      });
    }
  }, [hasCreateAccess]);

  return <UsersTable updateTableTrigger={updateTableTrigger} />;
};

export default Users;
