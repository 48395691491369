import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { ActionKeys } from '../../../../../modules/CRM/ClientGroups/ClientGroupDetails/ClientGroupPageContent/Contents/PendingTransactionsTab/FilterBar';
import { ClientTransferApprovalStatuses } from '../../../../../enums/transfers/transfers';

// components
import { Select } from 'antd';

interface IProps {
  onFilterChange: (key: ActionKeys, value: string) => void;
  selectedStatus: string;
}

const PendingTransactionStatusSelect = ({
  onFilterChange,
  selectedStatus,
}: IProps) => {
  const { t } = useTranslation('banking');

  const ClientTransactionStatuses = useMemo(
    () => [
      {
        title: t(
          `transactions.transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Completed]}`,
        ),
        value: 'completed',
      },
      {
        title: t(
          `transactions.transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.PendingApprovals]}`,
        ),
        value: 'pending',
      },
      {
        title: t(
          `transactions.transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Processing]}`,
        ),
        value: 'processing',
      },
      {
        title: t(
          `transactions.transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Rejected]}`,
        ),
        value: 'rejected',
      },
      {
        title: t(
          `transactions.transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Cancelled]}`,
        ),
        value: 'cancelled',
      },
      {
        title: t(
          `transactions.transaction_statuses.${ClientTransferApprovalStatuses[ClientTransferApprovalStatuses.Scheduled]}`,
        ),
        value: 'scheduled',
      },
    ],
    [],
  );

  const renderOptions = () => {
    const result = ClientTransactionStatuses.map((status) => {
      return (
        <Select.Option key={status.title} value={status.value}>
          {status.title}
        </Select.Option>
      );
    });

    return result;
  };

  return (
    <StyledSelect
      value={selectedStatus}
      size="large"
      onChange={(value: any) => onFilterChange('filter-by-status', value)}
    >
      <Select.Option key="all-statuses-option" value="all">
        {t('transactions.filter_bar.all_statuses_option')}
      </Select.Option>
      {renderOptions()}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default PendingTransactionStatusSelect;
