import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { useFormikContext } from 'formik';
import { FormValuesModel, RegisterSecurityKeySteps } from '../..';

// components
import { Button, Typography } from 'antd';
import { ReactComponent as KeyIcon } from '../../../../../../../resources/images/icons/key.svg';

const InitialStep = () => {
  const { t } = useTranslation(['profile', 'common']);
  const { setFieldValue } = useFormikContext<FormValuesModel>();

  const handleNextClick = () => {
    setFieldValue('currentStep', RegisterSecurityKeySteps.RegisterKey);
  };

  return (
    <>
      <StyledKeyIcon />
      <StyledParagraph>
        {t(
          'sign_in_and_security.two_factor_verification.security_key_option.enable_modal.initial_step.description',
        )}
      </StyledParagraph>
      <StyledButton size="large" type="primary" onClick={handleNextClick}>
        {t('next', { ns: 'common' })}
      </StyledButton>
    </>
  );
};

const StyledKeyIcon = styled(KeyIcon)`
  width: 100px;
  height: 100px;
  margin-bottom: ${({ theme }) => theme.marginXl};
`;

const StyledParagraph = styled(Typography.Paragraph)`
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default InitialStep;
