import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { AccountTypes } from 'enums/finance/finance';
import { AccountsHelpers } from '@helpers/finance/accounts';
import {
  InitialDepositRangeKeys,
  InitialDepositRangeOptions,
} from 'components/Forms/FormComponents/SelectInputs/InitialDepositRangeFormSelect';
import {
  WireRangeKeys,
  WireRangeOptions,
} from 'components/Forms/FormComponents/SelectInputs/WireRangesFormSelect';
import {
  EstimatedAmountFundsRangeKeys,
  EstimatedAmountFundsRangeOptions,
} from 'components/Forms/FormComponents/SelectInputs/EstimatedAmountFundsRangeFormSelect';

// components
import Form from '@core_components/Form';
import Link from '@common_components/Texts/Link';
import DescriptionSection from '@core_components/DescriptionSection';
import AccountSupplementaryForm, {
  FormValuesModel as AccountSupplementaryFormValues,
} from 'components/Forms/TemplateForms/Finance/AccountSupplementaryForm';

export type ComplianceClientBankAccountDetailsData = {
  accountNumber: string;
  clientGroupId: number;
  clientGroupName: string;
  type: AccountTypes | null;
  purpose: string;
  initialDepositOrigin: string;
  initialDepositFrom: number;
  initialDepositTo: number;
  incomingWiresNumberFrom: number;
  incomingWiresNumberTo: number;
  outgoingWiresNumberFrom: number;
  outgoingWiresNumberTo: number;
  estimatedIncomingFundsFrom: number;
  estimatedIncomingFundsTo: number;
  estimatedOutgoingFundsFrom: number;
  estimatedOutgoingFundsTo: number;
  jurisdictions: string[];
};

interface IProps {
  data: ComplianceClientBankAccountDetailsData;
}

const ComplianceClientBankAccountDetails = ({ data }: IProps) => {
  const { t } = useTranslation('approval_management');
  const history = useHistory();

  const initialFormValues = useMemo<{
    accountSupplementary: AccountSupplementaryFormValues;
  } | null>(() => {
    if (!data) {
      return null;
    }

    return {
      accountSupplementary: {
        type: data.type,
        purpose: data.purpose,
        jurisdictions: data.jurisdictions,
        initialDepositOrigin: data.initialDepositOrigin,
        initialDeposit: AccountsHelpers.getKeyOfRangeByRangeOption(
          {
            min: data.initialDepositFrom,
            max: data.initialDepositTo,
          },
          InitialDepositRangeOptions,
        ) as InitialDepositRangeKeys,
        incomingWiresNumber: AccountsHelpers.getKeyOfRangeByRangeOption(
          {
            min: data.incomingWiresNumberFrom,
            max: data.incomingWiresNumberTo,
          },
          WireRangeOptions,
        ) as WireRangeKeys,
        outgoingWiresNumber: AccountsHelpers.getKeyOfRangeByRangeOption(
          {
            min: data.outgoingWiresNumberFrom,
            max: data.outgoingWiresNumberTo,
          },
          WireRangeOptions,
        ) as WireRangeKeys,
        estimatedIncomingFunds: AccountsHelpers.getKeyOfRangeByRangeOption(
          {
            min: data.estimatedIncomingFundsFrom,
            max: data.estimatedIncomingFundsTo,
          },
          EstimatedAmountFundsRangeOptions,
        ) as EstimatedAmountFundsRangeKeys,
        estimatedOutgoingFunds: AccountsHelpers.getKeyOfRangeByRangeOption(
          {
            min: data.estimatedOutgoingFundsFrom,
            max: data.estimatedOutgoingFundsTo,
          },
          EstimatedAmountFundsRangeOptions,
        ) as EstimatedAmountFundsRangeKeys,
      },
    };
  }, [data]);

  return (
    <>
      <StyledDescriptionSection
        data={[
          {
            label: t(
              'approval_workflow.approval_workflow_changes.client_bank_account.client_group',
            ),
            description: (
              <Link
                onClick={() =>
                  history.push({
                    pathname: RoutePaths.CRM_Client_Group_Details,
                    search: `?id=${data.clientGroupId}`,
                  })
                }
              >
                {data.clientGroupName}
              </Link>
            ),
          },
          {
            label: t(
              'approval_workflow.approval_workflow_changes.client_bank_account.account_number',
            ),
            description: (
              <Link
                onClick={() =>
                  history.push({
                    pathname: RoutePaths.Banking_BankAccounts,
                    search: `?accountNumber=${data.accountNumber}`,
                  })
                }
              >
                {data.accountNumber}
              </Link>
            ),
          },
        ]}
      />
      <Form
        disabled
        onSubmit={console.log}
        initialValues={initialFormValues}
        renderForm={
          <AccountSupplementaryForm namePrefix="accountSupplementary" />
        }
      />
    </>
  );
};

const StyledDescriptionSection = styled(DescriptionSection)`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

export default ComplianceClientBankAccountDetails;
