import React, { memo } from 'react';

// helpers
import useTranslation from '../../../hooks/useTranslation';
import { TransactionReviewStatuses } from '../../../enums/banking/transactions';

// components
import StatusLabel from '../StatusLabel';

const STATUS_MAP = {
  info: [
    TransactionReviewStatuses.Suspense,
    TransactionReviewStatuses.Cancelled,
  ],
  warning: [
    TransactionReviewStatuses.PendingRDC,
    TransactionReviewStatuses.PendingApproval,
    TransactionReviewStatuses.AMLReview,
    TransactionReviewStatuses.Processing,
    TransactionReviewStatuses.InReview,
    TransactionReviewStatuses.PendingReturn,
    TransactionReviewStatuses.PendingReject,
    TransactionReviewStatuses.Scheduled,
    TransactionReviewStatuses.PendingTransmit,
  ],
  success: [TransactionReviewStatuses.Completed],
  error: [
    TransactionReviewStatuses.Rejected,
    TransactionReviewStatuses.Failed,
    TransactionReviewStatuses.BankRejected,
    TransactionReviewStatuses.Returned,
  ],
};

interface IProps {
  status?: TransactionReviewStatuses | null;
}

const TransactionStatus = memo(({ status }: IProps) => {
  const { t } = useTranslation('banking');

  if (!status) {
    return null;
  }

  return (
    <StatusLabel
      statusMap={STATUS_MAP}
      status={status}
      text={t(
        `banking.transactions.transaction_review_statuses.${TransactionReviewStatuses[status]}`,
      )}
    />
  );
});

export default TransactionStatus;
