import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { SelectOption, SelectProps } from '@core_components/Select';
import { AccountUserPermissionTypes } from 'enums/crm/crm';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type IProps = SelectProps;

const AccountPermissionSelect = (props: IProps) => {
  const { t } = useTranslation('crm');

  const options = useMemo<SelectOption[]>(
    () =>
      Object.values(AccountUserPermissionTypes).map((key) => ({
        id: key,
        label: t(`client_group.account_permissions.${key}`),
      })),
    [t],
  );

  return <FormSelect {...props} options={options} />;
};

export default AccountPermissionSelect;
