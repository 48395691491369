import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import useTranslation from '../../../../../hooks/useTranslation';
import { ActionKeys } from '../../../../../modules/CRM/ClientGroups/ClientGroupDetails/ClientGroupPageContent/Contents/PendingTransactionsTab/FilterBar';
import { accountsAPI } from '../../../../../api/finance/account/accountsAPI';
import { AccountsHelpers } from '@helpers/finance/accounts';

// components
import { Select } from 'antd';

interface IProps {
  clientGroupNumericId: number;
  selectedAccount: string;
  onFilterChange: (key: ActionKeys, value: any) => void;
}

const PendingTransactionAccountSelect = ({
  clientGroupNumericId,
  selectedAccount,
  onFilterChange,
}: IProps) => {
  const { t } = useTranslation('banking');

  const { response: accounts } = useFetch(
    () => accountsAPI.searchAccounts('', true, false, clientGroupNumericId),
    [clientGroupNumericId],
  );

  const [privateValue, setPrivateValue] = useState('');

  const renderAccounts = () => {
    if (!accounts) {
      return null;
    }

    if (accounts.data.length <= 25) {
      return accounts.data.map(
        ({ accountName, accountNumber, currencyCode, balance }) => (
          <Select.Option key={accountNumber} value={accountNumber}>
            {AccountsHelpers.generateAccountFullName(
              accountName,
              accountNumber,
              currencyCode,
              balance,
            )}
          </Select.Option>
        ),
      );
    } else {
      if (privateValue.length >= 4) {
        return accounts.data.map(
          ({ accountName, accountNumber, currencyCode, balance }) => (
            <Select.Option key={accountNumber} value={accountNumber}>
              {AccountsHelpers.generateAccountFullName(
                accountName,
                accountNumber,
                currencyCode,
                balance,
              )}
            </Select.Option>
          ),
        );
      }

      return null;
    }
  };

  return (
    <Select
      onChange={(value: any) => onFilterChange('filter-by-accounts', value)}
      onSearch={setPrivateValue}
      size="large"
      showSearch
      style={{ width: 550, maxWidth: '100%' }}
      value={selectedAccount}
      optionFilterProp="children"
      filterOption={(input, option: any) =>
        option?.children &&
        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      <Select.Option key="all-accounts-option" value="">
        {t('transactions.filter_bar.all_accounts_option')}
      </Select.Option>

      {renderAccounts()}
    </Select>
  );
};

export default PendingTransactionAccountSelect;
