import React, { memo } from 'react';

// helpers
import { darkTheme } from '@resources/theme/styled';
import { StyledComponentProps } from 'typings/common';

// components
import IconSVG from '@core_components/IconSVG';
import IconButton from '@core_components/IconButton';
import { ReactComponent as DeleteIcon } from '@resources/icons/remix-icons/delete-bin-6-line.svg';

interface IProps extends StyledComponentProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const DeleteButton = memo(({ onClick, ...rest }: IProps) => {
  return (
    <IconButton
      {...rest}
      icon={<IconSVG component={DeleteIcon} color={darkTheme.errorColor} />}
      type="bordered"
      danger
      onClick={onClick}
    />
  );
});

export default DeleteButton;
