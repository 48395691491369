import React, { useState } from 'react';

// helpers
import { WireDetailsModel } from 'typings/banking/bankAccounts';
import { FormikContextType } from 'formik';
import { UpdateSSIOfCorrespondentBankValidationSchema } from 'validations/accounting/correspondentBanks';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import UpdateSSIForm, {
  BankModel,
  BeneficiaryModel,
  FormValuesModel,
} from 'components/Forms/TemplateForms/Banking/UpdateSSIForm';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string | React.ReactNode;
  accountNumber: string | null;
  loading: boolean;
  onToggleWireDetails: (
    accountNumber: string,
    helpers: FormikContextType<FormValuesModel>,
  ) => Promise<WireDetailsModel>;
}

const WireDetailsModalDialog = ({
  title,
  isVisible,
  closeCallback,
  onSubmit,
  onSubmitError,
  onToggleWireDetails,
  disabled,
  initialValues,
  loading,
  accountNumber,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleWireDetails = async (
    isManualBeneficiary: boolean,
    helpers: FormikContextType<FormValuesModel>,
  ) => {
    if (
      initialValues?.isManualBeneficiary === isManualBeneficiary &&
      accountNumber
    ) {
      setIsLoading(true);
      const response = await onToggleWireDetails(accountNumber, helpers);
      helpers.setValues({
        ...helpers.values,
        isManualBeneficiary: !isManualBeneficiary,
        beneficiaryBank: response.beneficiaryBank,
        intermediaryBank: response.intermediaryBank,
        beneficiary: response.beneficiary,
      });
      setIsLoading(false);
    } else {
      setIsLoading(true);
      helpers.setValues({
        ...helpers.values,
        isManualBeneficiary: !isManualBeneficiary,
        beneficiaryBank: initialValues?.beneficiaryBank as BankModel,
        intermediaryBank: initialValues?.intermediaryBank as BankModel,
        beneficiary: initialValues?.beneficiary as BeneficiaryModel,
      });
      setIsLoading(false);
    }
  };

  return (
    <Form
      enableReinitialize
      onSubmit={onSubmit}
      disabled={disabled}
      initialValues={isVisible ? initialValues : null}
      validationSchema={UpdateSSIOfCorrespondentBankValidationSchema}
      onSubmitError={onSubmitError}
      renderForm={
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
        >
          <LoadingWrapper loading={loading}>
            <UpdateSSIForm
              onToggle={handleToggleWireDetails}
              loading={isLoading}
            />
          </LoadingWrapper>
        </ModalDialog>
      }
    />
  );
};

export default WireDetailsModalDialog;
