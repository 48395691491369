import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import moment from 'moment';
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { NoteType } from 'enums/crm/crm';
import { INewNoteBody, noteAPI } from 'api/note/noteAPI';

// components
import Button from '@core_components/Button';
import NoteModalDialog, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/NoteModalDialog';

interface IProps {
  onAdd: () => void;
  association: { id: string; type: NoteType };
}

const AddNote = ({ onAdd, association }: IProps) => {
  const { t } = useTranslation('crm');
  const [isVisible, setIsVisible] = useState(false);

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!isVisible) {
      return null;
    }

    return {
      eventDate: moment(),
      text: '',
      crmItems: [],
    };
  }, [isVisible]);

  const handleModalClose = (shouldUpdate?: boolean) => {
    if (shouldUpdate) {
      onAdd();
    }

    setIsVisible(false);
  };

  const handleSubmit = async (values: FormValuesModel) => {
    const assignToFiltered =
      values.crmItems && values.crmItems.length
        ? values.crmItems.filter((e) => e.itemId !== association.id)
        : [];
    const reqBody = {
      text: values.text,
      type: values.type,
      eventDate: values.eventDate
        ? values.eventDate.toString()
        : moment().toString(),
      assignTo: [
        { itemId: association.id, itemType: association.type },
        ...assignToFiltered,
      ],
      documentIds: values.documents?.map((document) => document.id),
    } as INewNoteBody;

    await noteAPI.createNote(reqBody);
    message.success(t('entity.notes.successAdd'));
  };

  return (
    <>
      <ButtonWrapper>
        <Button type="primary" size="large" onClick={() => setIsVisible(true)}>
          {t('entity.notes.addNote')}
        </Button>
      </ButtonWrapper>

      <NoteModalDialog
        onSubmit={handleSubmit}
        isVisible={isVisible}
        closeCallback={handleModalClose}
        initialValues={initialFormValues}
      />
    </>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default AddNote;
