import React, { useState } from 'react';

// helpers
import moment, { Moment } from 'moment';
import useFetch from '../../../../hooks/useFetch';
import { currencyRatesAPI } from '../../../../api/finance/currency/currencyRatesAPI';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';

// components
import CurrencyRateModal from '../CurrencyRateModal';
import { Divider } from 'antd';
import { default as TemplateCurrencyRatesTable } from '../../../../components/Tables/TableTemplates/Finance/CurrencyRatesTable';
import FilterBar from './FilterBar';

export interface ExchangeCurrencyRateParams {
  currencyOneId: number;
  currencyTwoId: number;
}

const CurrencyRatesTable = () => {
  // table
  const [current, setCurrentPage] = useState(1);

  const [selectedCurrency, setSelectedCurrency] =
    useState<ExchangeCurrencyRateParams | null>(null);

  // filters bar
  const [dateFilterValue, setDateFilterValue] = useState<Moment | null>(
    moment(),
  );

  const { response, loading } = useFetch(
    () =>
      currencyRatesAPI.fetchCurrencyRates({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        date: dateFilterValue?.unix() || moment().unix(),
      }),
    [current, dateFilterValue],
  );

  const handleFilterChange = (date: Moment | null) => {
    if (current !== 1) {
      setCurrentPage(1);
    }

    setDateFilterValue(date);
  };

  return (
    <>
      <FilterBar
        dateFilterValue={dateFilterValue}
        filterChange={handleFilterChange}
      />

      <Divider />

      <TemplateCurrencyRatesTable
        total={response?.total || 0}
        current={current}
        data={response?.data || []}
        loading={loading}
        onPaginationChange={setCurrentPage}
        onSelectCurrency={setSelectedCurrency}
      />

      <CurrencyRateModal
        selectedCurrency={selectedCurrency}
        isVisible={!!selectedCurrency}
        closeCallback={() => setSelectedCurrency(null)}
      />
    </>
  );
};

export default CurrencyRatesTable;
