import React, { useRef, useState } from 'react';

// helpers
import styled from 'styled-components';
import { AbstractTooltipProps } from 'antd/lib/tooltip';

// components
import { Tooltip } from 'antd';

interface IProps extends AbstractTooltipProps {
  title: string | React.ReactNode;
  maxTextContainerWidth?: string;
}

// Reusable tooltip component
// Tooltip can be shown only when 'ellipsis' effect is active (when you can see '...' in the end of text)
const EllipsisTooltip = ({
  maxTextContainerWidth,
  children,
  placement = 'bottom',
  ...rest
}: IProps) => {
  const tooltipRef = useRef<any>();

  const [isVisible, setIsVisible] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    if (tooltipRef.current?.clientWidth < tooltipRef.current?.scrollWidth) {
      setIsVisible(visible);
    }
  };

  return (
    <Tooltip
      {...rest}
      visible={isVisible}
      onVisibleChange={handleVisibleChange}
      placement={placement}
    >
      <TextWrapper
        ref={tooltipRef}
        $maxTextContainerWidth={maxTextContainerWidth}
      >
        {children}
      </TextWrapper>
    </Tooltip>
  );
};

const TextWrapper = styled.div<{ $maxTextContainerWidth?: string }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ $maxTextContainerWidth }) =>
    $maxTextContainerWidth && `max-width: ${$maxTextContainerWidth};`}
`;

export default EllipsisTooltip;
