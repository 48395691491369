import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../../hooks/useFetch';
import usersAPI from '../../../../../../api/orac/usersAPI';
import useTranslation from '../../../../../../hooks/useTranslation';
import { DEFAULT_TABLE_LIMIT } from '../../../../../../constants/global';
import { ISession } from '../../../../../../typings/profile/security';

// components
import CurrentSessionsTable from '../../../../../../components/Tables/TableTemplates/Profile/CurrentSessionsTable';
import { CardWrapper } from '../../../../../../layouts/PageLayout';
import { Modal, Typography } from 'antd';

interface IProps {
  userId: string;
  updateTableTrigger: any;
  onSessionRemove: () => void;
}

const CurrentSessions = ({
  userId,
  updateTableTrigger,
  onSessionRemove,
}: IProps) => {
  const { t } = useTranslation(['profile', 'common']);

  const [currentPage, setPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      usersAPI.fetchSessionsByUserId(
        {
          limit: DEFAULT_TABLE_LIMIT,
          page: currentPage,
          inactive: false,
        },
        userId,
      ),
    [currentPage, updateTableTrigger],
  );

  const handleSessionDelete = async (session: ISession) => {
    Modal.confirm({
      content: t('sign_in_and_security.sessions.stop_session_confirmation'),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        await usersAPI.deleteSessionByUserId(session._id, userId);
        onSessionRemove();
      },
    });
  };

  return (
    <CardWrapper>
      <Typography.Title level={4}>
        {t('sign_in_and_security.sessions.current_sessions')}
      </Typography.Title>
      <CurrentSessionsTable
        loading={loading}
        current={currentPage}
        total={response?.total}
        data={response?.data || []}
        onPaginationChange={setPage}
        onDelete={handleSessionDelete}
      />
    </CardWrapper>
  );
};

export default CurrentSessions;
