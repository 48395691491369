import React, { memo } from 'react';

// components
import ProgressChart from './ProgressChart';
import StatusSection from './StatusSection';
import RDCReviewSection from './RDCReviewSection';
import ReviewConfigurationsSection from './ReviewConfigurationsSection';
import { Col, Divider, Row } from 'antd';

const ReviewProgressContainer = memo(() => {
  return (
    <>
      <ProgressChart />
      <Divider />
      <StatusSection />
      <Divider />
      <Row justify="center" gutter={[16, 16]}>
        <Col span={24}>
          <RDCReviewSection />
        </Col>
        <Col span={24}>
          <ReviewConfigurationsSection />
        </Col>
      </Row>
    </>
  );
});

export default ReviewProgressContainer;
