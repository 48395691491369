import { IAccountResponse } from '../../../api/crm/onboarding/accountsAPI';
import { IOnboardingAccount } from '../../../typings/finance/account';
import {
  amountOptions,
  initialDepositOptions,
  volumeOptions,
} from '../../../constants/accounts';
import { getRangeValue } from '../../../helpers/utils';
import { FetchResponseModel } from '../../../typings/common';

const accountsAdapter = {
  fetchAccounts: (
    accountsResponse: FetchResponseModel<IAccountResponse>,
  ): IOnboardingAccount[] =>
    accountsResponse.data.map((account) => ({
      ...account,
      initialDeposit: getRangeValue(
        initialDepositOptions,
        account.initialDeposit,
      ),
      incomingWiresNumber: getRangeValue(
        volumeOptions,
        account.incomingWiresNumber,
      ),
      outgoingWiresNumber: getRangeValue(
        volumeOptions,
        account.outgoingWiresNumber,
      ),
      estimatedIncomingFunds: getRangeValue(
        amountOptions,
        account.estimatedIncomingFunds,
      ),
      estimatedOutgoingFunds: getRangeValue(
        amountOptions,
        account.estimatedOutgoingFunds,
      ),
    })),
};

export { accountsAdapter };
