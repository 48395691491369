import React from 'react';

// helpers
import styled from 'styled-components';
import { StyledComponentProps } from 'typings/common';

// components
import { Space, Spin } from 'antd';

interface IProps extends StyledComponentProps {
  loading: boolean;
  children: React.ReactNode;
}

const LoadingWrapper = ({ children, loading, ...rest }: IProps) => {
  return (
    <>
      {loading ? (
        <SpinWrapper {...rest}>
          <Space size="middle">
            <Spin size="large" />
          </Space>
        </SpinWrapper>
      ) : (
        children
      )}
    </>
  );
};

const SpinWrapper = styled(Space)`
  height: 100%;
  min-height: 200px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export default LoadingWrapper;
