import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { DateHelpers } from '@helpers/date';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';
import { ForgotUsernameRequestFromQuery } from 'api/orac/forgotUsernameRequestsAPI';

// components
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import ForgotUsernameRequestStatus from 'components/Additional/Statuses/ForgotUsernameRequestStatus';
import DescriptionSection, {
  DescriptionItem,
} from '@core_components/DescriptionSection';

interface IProps {
  request: ForgotUsernameRequestFromQuery;
}

const DetailsSection = memo(({ request }: IProps) => {
  const { t } = useTranslation('orac');

  const data = useMemo<DescriptionItem[]>(() => {
    return [
      {
        label: t('forgot_username_requests.details.date'),
        description: DateHelpers.formatDateToString(
          request.createdAt,
          DEFAULT_DATE_TIME_FORMAT,
        ),
      },

      {
        label: t('forgot_username_requests.details.name'),
        description: (
          <EllipsisTooltip title={request.name} maxTextContainerWidth="300px">
            {request.name}
          </EllipsisTooltip>
        ),
      },

      {
        label: t('forgot_username_requests.details.email'),
        description: (
          <EllipsisTooltip title={request.email} maxTextContainerWidth="300px">
            {request.email}
          </EllipsisTooltip>
        ),
      },

      {
        label: t('forgot_username_requests.details.client_group'),
        description: (
          <EllipsisTooltip
            title={request.clientGroupName}
            maxTextContainerWidth="300px"
          >
            {request.clientGroupName}
          </EllipsisTooltip>
        ),
      },

      {
        label: t('forgot_username_requests.details.date_of_birth'),
        description: DateHelpers.formatDateToString(
          DateHelpers.formatDateToUTC(request.dateOfBirth),
        ),
      },

      {
        label: t('forgot_username_requests.details.status'),
        description: <ForgotUsernameRequestStatus status={request.status} />,
      },
    ];
  }, [request]);

  return <DescriptionSection data={data} />;
});

export default DetailsSection;
