import { ISelectRangeOption } from '../components/Forms/FormComponents/SelectInputs/SelectRangeInput';

export const initialDepositOptions: ISelectRangeOption[] = [
  {
    min: 0,
    max: 50000,
    key: 'initialDepositOption1',
  },
  {
    min: 50000,
    max: 99999,
    key: 'initialDepositOption2',
  },
  {
    min: 100000,
    max: 249999,
    key: 'initialDepositOption3',
  },
  {
    min: 250000,
    max: 499999,
    key: 'initialDepositOption4',
  },
  {
    min: 500000,
    max: 999999,
    key: 'initialDepositOption5',
  },
  {
    min: 1000000,
    key: 'initialDepositOption6',
  },
];

export const volumeOptions: ISelectRangeOption[] = [
  {
    min: 0,
    max: 20,
    key: 'volume1',
  },
  {
    min: 21,
    max: 40,
    key: 'volume2',
  },
  {
    min: 41,
    max: 60,
    key: 'volume3',
  },
  {
    min: 61,
    max: 80,
    key: 'volume4',
  },
  {
    min: 81,
    max: 100,
    key: 'volume5',
  },
  {
    min: 101,
    max: 120,
    key: 'volume6',
  },
  {
    min: 121,
    max: 140,
    key: 'volume7',
  },
  {
    min: 141,
    max: 160,
    key: 'volume8',
  },
  {
    min: 161,
    max: 180,
    key: 'volume9',
  },
  {
    min: 181,
    max: 200,
    key: 'volume10',
  },
  {
    min: 200,
    key: 'volume11',
  },
];

export const amountOptions: ISelectRangeOption[] = [
  {
    min: 0,
    max: 50000,
    key: 'amount1',
  },
  {
    min: 50000,
    max: 249999,
    key: 'amount2',
  },
  {
    min: 250000,
    max: 499999,
    key: 'amount3',
  },
  {
    min: 500000,
    max: 999999,
    key: 'amount4',
  },
  {
    min: 1000000,
    key: 'amount5',
  },
];

export const OTHER_BANK_CODE = 3;
