import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../../routes/routes';

// components
import { Button } from 'antd';

const ManageSecurityKeys = () => {
  const { t } = useTranslation('profile');
  const history = useHistory();

  return (
    <StyledButton
      size="large"
      onClick={() =>
        history.push(RoutePaths.Profile_SignInSecurity_ManageSecurityKeys)
      }
    >
      {t(
        'sign_in_and_security.two_factor_verification.security_key_option.manage_security_keys.button',
      )}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  width: 100%;
`;

export default ManageSecurityKeys;
