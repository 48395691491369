export enum GetDataStatusEnum {
  NONE = 'None',
  PENDING = 'Pending',
  SUCCESS = 'Success',
  FAIL = 'Fail',
}

export enum AllYesNoEnum {
  All = 'all',
  Yes = 'yes',
  No = 'no',
}
