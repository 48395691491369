import React from 'react';

// helpers
import styled from 'styled-components';

// components
import { Col, Row, Typography } from 'antd';

interface IProps {
  title: string;
  value: React.ReactNode;
}

const DetailsRow = ({ title, value }: IProps) => {
  return (
    <Row wrap={false}>
      <Col flex="230px">
        <Typography.Text>{title}</Typography.Text>
      </Col>
      <Col flex="auto">
        <StyledValueText>{value}</StyledValueText>
      </Col>
    </Row>
  );
};

const StyledValueText = styled(Typography.Text)`
  color: ${({ theme }) => theme.whiteColor};
  font-weight: 600;
`;

export default DetailsRow;
