import React, { memo } from 'react';

// helpers
import moment from 'moment';
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { MessageModel } from '../../../../../../../typings/messaging/messages';

// constants
import {
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_DATE_TIME_FORMAT_UTC,
} from '../../../../../../../constants/global';

// components
import Text from '@core_components/Text';
import Link from '@common_components/Texts/Link';
import IconSVG from '@core_components/IconSVG';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import { Trans } from 'react-i18next';
import { Popover, Typography } from 'antd';
import { ReactComponent as EyeIcon } from '../../../../../../../resources/icons/remix-icons/eye-line.svg';
import { ReactComponent as AttachmentIcon } from '../../../../../../../resources/icons/remix-icons/attachment-line.svg';

interface IProps {
  message: MessageModel;
}

const MessageTimeBar = memo(({ message }: IProps) => {
  const { t } = useTranslation('crm');
  const history = useHistory();
  const hasAttachment = !!message.attachments.length;

  return (
    <DivAlignCenter>
      {message.auditTrail.length ? (
        <Popover
          placement="bottom"
          content={
            <>
              {message.auditTrail.map((e) => (
                <Text key={e.userId}>
                  <Trans
                    t={t}
                    values={{
                      user: e.userFullName,
                      date: moment
                        .utc(e.createdAt)
                        .format(DEFAULT_DATE_TIME_FORMAT_UTC),
                    }}
                    i18nKey="messaging.view_message.message_read_by"
                    components={[
                      <Link
                        key="link-to-user"
                        onClick={() =>
                          history.push({
                            pathname: RoutePaths.ORAC_User_Details,
                            search: `?id=${e.userId}`,
                          })
                        }
                      />,
                    ]}
                  />
                </Text>
              ))}
            </>
          }
        >
          <StyledIconSVG component={EyeIcon} size="extra-small" />
        </Popover>
      ) : null}
      {hasAttachment && (
        <IconSVG component={AttachmentIcon} size="extra-small" />
      )}
      <StyledDateText>
        {moment(message.createdAt).format(DEFAULT_DATE_TIME_FORMAT)}
      </StyledDateText>
    </DivAlignCenter>
  );
});

const StyledDateText = styled(Typography.Text)`
  color: ${({ theme }) => theme.whiteColor};
  margin-left: ${({ theme }) => theme.marginXXs};
`;

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
`;

export default MessageTimeBar;
