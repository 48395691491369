import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ExpectedDataTypes } from '../../../../../enums/compliance/reviewConfiguration';
import { ReferencePropertyModel } from '../../../../../typings/compliance/configurationRules';

// components
import EllipsisTooltip from '../../../../Tooltips/EllipsisTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

interface IProps extends RequiredPropsForTableModel<ReferencePropertyModel> {
  size?: SizeType;
  className?: string;
  hidePagination?: boolean;
}

const ReferenceObjectPropertiesList = memo((props: IProps) => {
  const { t } = useTranslation('compliance');

  const renderPropertyOptions = ({ dataOptions }: ReferencePropertyModel) => {
    if (!dataOptions?.length) {
      return '';
    }

    const formattedDataOptions = dataOptions.join(', ');

    return (
      <EllipsisTooltip
        maxTextContainerWidth="300px"
        overlayStyle={{ maxWidth: '400px' }}
        title={formattedDataOptions}
      >
        {formattedDataOptions}
      </EllipsisTooltip>
    );
  };

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('property_configuration.property_name'),
        width: 300,
        key: 'name',
        render: (record: ReferencePropertyModel) => record.name,
      },

      {
        title: t('property_configuration.data_type'),
        width: 300,
        key: 'data_type',
        render: (record: ReferencePropertyModel) =>
          t(`data_type.${ExpectedDataTypes[record.dataType]}`),
      },

      {
        title: t('property_configuration.options'),
        width: 300,
        key: 'data_type',
        render: renderPropertyOptions,
      },

      {
        title: t('property_configuration.json_path'),
        key: 'json_path',
        render: (record: ReferencePropertyModel) => record.jsonPath,
      },
    ];

    return result;
  }, [t]);

  return <Table {...props} columns={columns} />;
});

export default ReferenceObjectPropertiesList;
