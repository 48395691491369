import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import usersAPI from '../../../../../api/orac/usersAPI';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../../constants/global';

// components
import FilterBar from './FilterBar';
import ObjectsTable from '../../../../../components/Tables/TableTemplates/ORAC/ObjectsTable';

import { Col, Divider, Row } from 'antd';
import EditPermissions from './EditPermissions';

interface IProps {
  userId: string;
  access?: { canUpdate?: boolean };
}

const PermissionsTab = ({ userId, access }: IProps) => {
  const [page, setPage] = useState(1);
  const [searchByName, setSearchByName] = useState('');

  const [updatePermissionsTrigger, updatePermissions] = useState({});

  const { response, loading } = useFetch(
    () =>
      usersAPI.fetchUserPermissions(userId, {
        page,
        limit: DEFAULT_TABLE_LIMIT,
        name: searchByName,
      }),
    [page, userId, searchByName, updatePermissionsTrigger],
  );

  const handleFilterBarChange = (key: string, value: string) => {
    if (page > 1) {
      setPage(1);
    }

    if (key === 'search_by_name') {
      setSearchByName(value.trim());
    }
  };

  return (
    <>
      {access?.canUpdate && (
        <Row justify="end">
          <Col>
            <EditPermissions
              userId={userId}
              onUpdate={() => updatePermissions({})}
            />
          </Col>
        </Row>
      )}
      <FilterBar onFilterChange={handleFilterBarChange} />

      <Divider />

      <ObjectsTable
        isExpandable
        showAssignWayInfo
        showForbiddenPermissions
        data={response?.data || []}
        total={response?.total || 0}
        loading={loading}
        current={page}
        onPaginationChange={setPage}
      />
    </>
  );
};

export default PermissionsTab;
