import React, { useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../hooks/useFetch';
import useTranslation from '../../../../../hooks/useTranslation';
import {
  JournalEntryModel,
  journalsAPI,
} from '../../../../../api/finance/journal/journalsAPI';

// components
import { Select } from 'antd';

interface IProps {
  onChange: (value: string) => void;
}

const TemplateTypeSelect = ({ onChange }: IProps) => {
  const { t } = useTranslation('finance');
  const [privateValue, setPrivateValue] = useState('');
  const { response, loading } = useFetch(
    () => journalsAPI.fetchJournalEntryTypes(),
    [],
  );

  const renderOptions = (types: JournalEntryModel[]) => {
    if (!types.length) {
      return null;
    }

    return types.map(({ id, name }) => (
      <Select.Option key={id} value={id}>
        {name}
      </Select.Option>
    ));
  };

  const handleSelectChange = (value: any) => {
    setPrivateValue(value);
    onChange(value);
  };

  return (
    <StyledSelect
      allowClear
      showArrow
      showSearch
      size="large"
      value={privateValue || undefined}
      onChange={handleSelectChange}
      loading={loading}
      placeholder={t('journals.table.filter_by_template_placeholder')}
    >
      {response && renderOptions(response?.data || [])}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default TemplateTypeSelect;
