import { ForexSpreadLimitModel } from '../../../typings/banking/foreignExchange';

const foreignExchangeAPIAdapter = {
  fetchFeeSchedulesByClientGroupId: (
    data: ForexSpreadLimitModel[],
  ): ForexSpreadLimitModel[] => {
    const result = data.map((e) => ({ ...e, isDefault: e.clientId === 0 }));
    return result;
  },
};

export default foreignExchangeAPIAdapter;
