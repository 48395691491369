import React, { useState } from 'react';

// components
import RolesTable from './RolesTable';
import AddRoleToGroup from './AddRoleToGroup';

import { Col, Row } from 'antd';

interface IProps {
  groupId: string;
  access?: { canEditRoles?: boolean; canViewPermissions?: boolean };
}

const RolesTab = ({ groupId, access }: IProps) => {
  const [updateTableTrigger, updateTable] = useState({});

  return (
    <>
      {access?.canEditRoles && (
        <Row justify="end">
          <Col>
            <AddRoleToGroup groupId={groupId} onAdd={() => updateTable({})} />
          </Col>
        </Row>
      )}

      <RolesTable
        groupId={groupId}
        updateTableTrigger={updateTableTrigger}
        canRemoveRole={access?.canEditRoles}
        canViewPermissions={access?.canViewPermissions}
      />
    </>
  );
};

export default RolesTab;
