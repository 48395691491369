import React, { useState } from 'react';

// helpers

import useTranslation from '../../../../../../hooks/useTranslation';
import { securityAPI } from '../../../../../../api/profile/securityAPI';

// components
import RegisterSecurityKeyModal, {
  FormValuesModel,
} from '../../../../../../components/Modals/TemplateModalDialogs/Profile/RegisterSecurityKeyModal';
import { Button, message } from 'antd';

interface IProps {
  onAdd: () => void;
}

const AddSecurityKey = ({ onAdd }: IProps) => {
  const { t } = useTranslation('profile');

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModalClose = (wasRegistered?: boolean) => {
    if (wasRegistered) {
      onAdd();
    }

    setIsModalVisible(false);
  };

  const handleSubmit = async (values: FormValuesModel) => {
    if (values.securityKeyCredInfo) {
      await securityAPI.registerSecurityKey({
        ...values.securityKeyCredInfo,
        name: values.name,
      });
      message.success(
        t(
          'sign_in_and_security.two_factor_verification.security_key_option.add_new_key.success_submit_message',
        ),
      );
    }
  };

  return (
    <>
      <Button type="primary" onClick={() => setIsModalVisible(true)}>
        {t(
          'sign_in_and_security.two_factor_verification.security_key_option.add_new_key.button',
        )}
      </Button>

      <RegisterSecurityKeyModal
        getChallengeCallback={securityAPI.getChallengeForRegistrationNewKey}
        onSubmit={handleSubmit}
        title={t(
          'sign_in_and_security.two_factor_verification.security_key_option.add_new_key.title',
        )}
        isVisible={isModalVisible}
        closeCallback={handleModalClose}
      />
    </>
  );
};

export default AddSecurityKey;
