import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { GraphNode } from 'components/Charts/GraphChart';
import { IClientGroup } from 'typings/crm/client-group';
import { IOnboardingStatus } from 'typings/crm/onboarding';

// components
import Tabs from 'components/Tabs';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import ReviewStatusIcon from 'components/Additional/Crm/ReviewStatusIcon';
import VerificationDocuments from '../VerificationDocuments';
import IdentificationDocuments from '../IdentificationDocuments';

interface IProps {
  node: GraphNode | null;
  isViewOnly: boolean;
  clientGroup: IClientGroup;
  onboardingStatus: IOnboardingStatus | null;
  updateNodeCallback: () => void;
  updateClientGroupCallback: () => void;
}

const ContactReviewTabs = ({
  node,
  isViewOnly,
  clientGroup,
  onboardingStatus,
  updateNodeCallback,
  updateClientGroupCallback,
}: IProps) => {
  const { t } = useTranslation('crm');

  return (
    <Tabs
      tabs={[
        {
          key: 'identification-documents',
          title: (
            <DivAlignCenter>
              {t('client_group.approval.identification')}
              &nbsp;
              <ReviewStatusIcon
                status={onboardingStatus?.review?.identification?.status}
              />
            </DivAlignCenter>
          ),
          content: (
            <IdentificationDocuments
              node={node}
              isViewOnly={isViewOnly}
              clientGroup={clientGroup}
              updateNodeCallback={updateNodeCallback}
              updateClientGroupCallback={updateClientGroupCallback}
            />
          ),
        },
        {
          key: 'verification-documents',
          title: (
            <DivAlignCenter>
              {t('client_group.approval.verification')}
              &nbsp;
              <ReviewStatusIcon
                status={
                  onboardingStatus?.review?.applicationDocumentation?.status
                }
              />
            </DivAlignCenter>
          ),
          content: (
            <VerificationDocuments
              node={node}
              isViewOnly={isViewOnly}
              clientGroup={clientGroup}
              onboardingStatus={onboardingStatus}
              updateNodeCallback={updateNodeCallback}
              updateClientGroupCallback={updateClientGroupCallback}
            />
          ),
        },
      ]}
    />
  );
};

export default ContactReviewTabs;
