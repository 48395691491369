import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { darkTheme } from '@resources/theme/styled';

// components
import IconSVG from '../../../DesignSystem/Core/IconSVG';
import { Popover } from 'antd';
import { ReactComponent as InfoIcon } from 'resources/icons/remix-icons/information-line.svg';

const IsPEPTooltip = () => {
  const { t } = useTranslation('crm');

  return (
    <Popover
      content={t('contacts.kyc_form.identification.is_pep_tooltip')}
      placement="rightTop"
      overlayStyle={{ maxWidth: '600px' }}
    >
      <StyledIconSVG component={InfoIcon} color={darkTheme.warningColor} />
    </Popover>
  );
};

const StyledIconSVG = styled(IconSVG)`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.paddingXs};
`;

export default IsPEPTooltip;
