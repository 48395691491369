import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { ForexSpreadLimitModel } from '../../../../../../../../typings/banking/foreignExchange';

// components
import { Button, Col, Row, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';

interface IProps {
  spread: ForexSpreadLimitModel;
  canEdit: boolean;
  onEditClick: (spreadId: number) => void;
}

const SpreadShortDetails = ({ spread, onEditClick, canEdit }: IProps) => {
  const { t } = useTranslation('banking');

  const renderCheckReject = (isChecked: boolean) => {
    return isChecked ? <StyledCheckIcon /> : <StyledRejectIcon />;
  };

  return (
    <div>
      {canEdit && (
        <ButtonWrapper>
          <Tooltip title={t('forex.matrix.edit')}>
            <StyledCircleButton
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => onEditClick(spread.id)}
            />
          </Tooltip>
        </ButtonWrapper>
      )}

      <Row>
        <Col span={14}>{`${t('forex.matrix.spread')}: `}</Col>
        <Col>{`${AccountsHelpers.convertAmountFromBigIntToInt(spread.spread)} %`}</Col>
      </Row>

      <Row>
        <Col span={14}>{`${t('forex.matrix.require_approval')}: `}</Col>
        <Col>{renderCheckReject(spread.isAboveThreshold)}</Col>
      </Row>
    </div>
  );
};

const ButtonWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.marginMd};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCircleButton = styled(Button)`
  padding: 0px;
  min-width: unset;
`;

const StyledCheckIcon = styled(CheckOutlined)`
  color: ${({ theme }) => theme.successColor};
`;

const StyledRejectIcon = styled(CloseOutlined)`
  color: ${({ theme }) => theme.errorColor};
`;

export default SpreadShortDetails;
