import React from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import SectionIntro from '@common_components/Texts/SectionIntro';
import DescriptionSection from '@core_components/DescriptionSection';

const StatsTab = () => {
  const { t } = useTranslation('translation');

  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <SectionIntro
        titleVariant="h5"
        title={t(
          'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.balances_table.title',
        )}
      />
      <DescriptionSection
        size="small"
        bordered={false}
        background="transparent"
        data={[
          {
            label: t(
              'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.balances_table.period',
            ),
            description: t(
              'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.balances_table.average_balance',
            ),
          },
          {
            label: t(
              'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.balances_table.since_inception',
            ),
            description: values.statistics.avgEodBalanceSinceCreation,
          },
          {
            label: t(
              'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.balances_table.current_month',
            ),
            description: values.statistics.avgEodBalanceCurrentCalendarMonth,
          },
          {
            label: t(
              'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.balances_table.last_three_month',
            ),
            description: values.statistics.avgEodBalanceLast3Months,
          },
          {
            label: t(
              'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.balances_table.last_six_month',
            ),
            description: values.statistics.avgEodBalanceLast6Months,
          },
        ]}
      />

      <SectionIntro
        gutterTop
        titleVariant="h5"
        title={t(
          'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.transactions_table.title',
        )}
      />
      <DescriptionSection
        size="small"
        bordered={false}
        background="transparent"
        data={[
          {
            label: t(
              'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.transactions_table.period',
            ),
            description: t(
              'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.transactions_table.number_of_transactions',
            ),
          },
          {
            label: t(
              'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.balances_table.since_inception',
            ),
            description: (
              <span>{values.statistics.avgEomTxCountSinceCreation}</span>
            ),
          },
          {
            label: t(
              'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.balances_table.current_month',
            ),
            description: (
              <span>{values.statistics.avgEomTxCountCurrentCalendarMonth}</span>
            ),
          },
          {
            label: t(
              'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.balances_table.last_three_month',
            ),
            description: (
              <span>{values.statistics.avgEomTxCountLast3Months}</span>
            ),
          },
          {
            label: t(
              'banking.bank_accounts.bank_account_modal_dialog.tabs.stats.balances_table.last_six_month',
            ),
            description: (
              <span>{values.statistics.avgEomTxCountLast6Months}</span>
            ),
          },
        ]}
      />
    </>
  );
};

export default StatsTab;
