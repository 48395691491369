import React, { useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import { financeTemplateAPI } from '../../../../../api/approval/approvalManagement/financeTemplateAPI';
import { DEFAULT_TABLE_LIMIT } from '../../../../../constants/global';

// components
import { default as TemplateApprovalGroupsTable } from '../../../../../components/Tables/TableTemplates/ApprovalManagement/ApprovalGroupsTable';

interface IProps {
  hasEditAccess: boolean;
  onActionsClick: (key: string, id: string) => void;

  updateTableTrigger?: any;
}

const ApprovalGroupsTable = ({
  onActionsClick,
  updateTableTrigger,
  hasEditAccess,
}: IProps) => {
  const [page, setPage] = useState(1);

  const { response, loading } = useFetch(
    () =>
      financeTemplateAPI.fetchApprovalGroups({
        page,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [updateTableTrigger, page],
  );

  return (
    <TemplateApprovalGroupsTable
      data={response?.data || []}
      total={response?.total || 0}
      loading={loading}
      current={page}
      onPaginationChange={setPage}
      onActionsClick={onActionsClick}
      hasEditAccess={hasEditAccess}
    />
  );
};

export default ApprovalGroupsTable;
