import React, { useState, useEffect } from 'react';

// helpers
import useUserAccess from '../../hooks/useUserAccess';
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';
import { GENESIS_FINANCE_ACCOUNTS_DOCUMENTATION_LINK } from '../../constants/externalLinks';

// components
import AccountsTable from '../../modules/Finance/Accounts/AccountsTable';
import AddBankAccount from 'modules/Finance/Accounts/AddBankAccount';
import TooltipToDocumentation from '../../components/Tooltips/TooltipToDocumentation';

type IProps = PageLayoutHelperPropsModel;

const Accounts = ({ setHeaderOptions }: IProps) => {
  const [hasCreateAccess] = useUserAccess(['Finance_Accounts_Create']);
  const [updateTableTrigger, updateTable] = useState({});

  useEffect(() => {
    if (hasCreateAccess) {
      setHeaderOptions({
        appendToTitle: (
          <TooltipToDocumentation
            link={GENESIS_FINANCE_ACCOUNTS_DOCUMENTATION_LINK}
          />
        ),
        extra: () => <AddBankAccount onCreate={() => updateTable({})} />,
      });
    }
  }, [hasCreateAccess]);

  return <AccountsTable updateTableTrigger={updateTableTrigger} />;
};

export default Accounts;
