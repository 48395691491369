import React from 'react';

// helpers
import styled from 'styled-components';
import { darkTheme } from '../../../resources/theme/styled';
import { StyledComponentProps } from 'typings/common';

export enum LABEL_STATUSES {
  info = 'info',
  warning = 'warning',
  success = 'success',
  error = 'error',
}

type StatusType = string | number;

interface IProps extends StyledComponentProps {
  statusMap?: Record<LABEL_STATUSES, StatusType[] | undefined>;
  status: StatusType;
  text?: React.ReactNode;
}

const StatusLabel = ({ statusMap, status, text, ...rest }: IProps) => {
  const statusKey =
    Object.values(LABEL_STATUSES).find((key) => {
      const statuses = statusMap ? statusMap[key] : [];
      return statuses?.includes(status);
    }) || status;

  const getColor = (): string | undefined => {
    switch (statusKey) {
      case LABEL_STATUSES.info:
        return darkTheme.textLightColor3;

      case LABEL_STATUSES.warning:
        return darkTheme.warningColor;

      case LABEL_STATUSES.success:
        return darkTheme.successColor;

      case LABEL_STATUSES.error:
        return darkTheme.errorColor;
    }

    return undefined;
  };

  return (
    <StyledStatus color={getColor()} {...rest}>
      {text || status}
    </StyledStatus>
  );
};

const StyledStatus = styled.span<{ color?: string }>`
  color: ${({ color }) => color};
`;

export default StatusLabel;
