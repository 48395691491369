import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../../../../hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { relationshipAPI } from '../../../../../../../api/crm/relationship/relationshipAPI';
import {
  ClientReviewStatus,
  ClientStatus,
} from '../../../../../../../enums/crm/crm';
import {
  IClientGroupResponse,
  clientGroupsAPI,
} from '../../../../../../../api/crm/clientGroup/clientGroupsAPI';

// components
import Button from '@core_components/Button';
import RequestStructureChange from './RequestStructureChange';
import EntityRelationshipChart from './EntityRelationshipChart';
import UpdateEntityRelationshipChart from './UpdateEntityRelationshipChart';
import { Col, Modal, Row, Space, Spin } from 'antd';

interface IProps {
  clientGroupData: IClientGroupResponse;
  updateClientGroup: () => void;
}

const StructureTab = ({ clientGroupData, updateClientGroup }: IProps) => {
  const { t } = useTranslation(['crm', 'common']);
  const [updateStructureChartTrigger, updateStructureChart] = useState({});

  const scopeId = useMemo(() => {
    const { status, reviewStatus, directClient } = clientGroupData.clientGroup;

    if (
      status === ClientStatus.Approved &&
      reviewStatus === ClientReviewStatus.Reviewed
    ) {
      return directClient.relationshipScopes?.approved;
    }

    return directClient.relationshipScopes?.active;
  }, [clientGroupData]);

  const shouldShowStartEditingButton = useMemo(
    () =>
      clientGroupData.clientGroup.status === ClientStatus.Onboarding &&
      (clientGroupData.clientGroup.reviewStatus ===
        ClientReviewStatus.NotReviewed ||
        clientGroupData.clientGroup.reviewStatus ===
          ClientReviewStatus.Reviewed) &&
      !clientGroupData.clientGroup.isEditRelationshipStructureDisabled,
    [clientGroupData.clientGroup],
  );

  const { response, loading } = useFetch(
    () =>
      relationshipAPI.getRelationshipTree(
        clientGroupData.clientGroup.directClientItemId,
        scopeId,
        clientGroupData.onboardingProcess.crmItemId,
        clientGroupData.clientGroup.applicantItemId,
      ),
    [scopeId, updateStructureChartTrigger],
  );

  if (loading) {
    return (
      <ContentWrapper>
        <Space size="middle">
          <Spin size="large" />
        </Space>
      </ContentWrapper>
    );
  }

  const enableStructureEditMode = async () => {
    Modal.confirm({
      icon: null,
      closable: true,
      maskClosable: true,
      width: 600,
      title: t('client_group.structure.confirm_start_editing.title'),
      content: t('client_group.structure.confirm_start_editing.description'),
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onOk: async () => {
        await clientGroupsAPI.startEditingStructureChart(
          clientGroupData.clientGroup._id,
        );
        updateClientGroup();
      },
    });
  };

  const disableStructureEditMode = async (message: string) => {
    await clientGroupsAPI.finishEditingStructureChart(
      clientGroupData.clientGroup._id,
      { message },
    );
    updateClientGroup();
  };

  return (
    <>
      {shouldShowStartEditingButton && (
        <Row justify="end">
          <Col>
            <Button type="bordered" onClick={enableStructureEditMode}>
              {t('client_group.structure.start_editing')}
            </Button>
          </Col>
        </Row>
      )}
      {clientGroupData.clientGroup.isEditRelationshipStructureDisabled ? (
        <UpdateEntityRelationshipChart
          disableStructureEditMode={disableStructureEditMode}
          relationshipTree={response}
          clientGroupData={clientGroupData}
          onModify={() => updateStructureChart({})}
        />
      ) : (
        <>
          <RequestStructureChange clientGroupData={clientGroupData} />
          <EntityRelationshipChart
            relationshipTree={response}
            clientGroupStatus={clientGroupData.clientGroup.status}
          />
        </>
      )}
    </>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

export default StructureTab;
