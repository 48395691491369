import React, { useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../hooks/useFetch';
import queryString from 'query-string';
import useTranslation from '../../../../hooks/useTranslation';
import { RoutePaths } from '../../../../routes/routes';
import { changeView } from '../../../../redux/actions/view';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { accountRecoveryRequestsAPI } from '../../../../api/orac/accountRecoveryRequestsAPI';

// constants
import {
  AccountRecoveryEntryStatus,
  VerificationDocumentType,
} from '../../../../enums/auth/accountRecovery';

// components
import Icon from '../../../../components/Icons/Icon';
import ImageCard from './ImageCard';
import DetailsSection from './DetailsSection';
import IndividualIcon from '../../../../resources/images/icons/individual-white.png';
import DriversLicense from '../../../../resources/images/icons/drivers-license-white.png';
import ResetAuthenticationRequestStatus from 'components/Additional/Statuses/ResetAuthenticationRequestStatus';
import VerificationDocumentPhotosSection from './VerificationDocumentPhotosSection';
import {
  Button,
  Col,
  Divider,
  message,
  Modal,
  Row,
  Spin,
  Typography,
} from 'antd';

const AccountRecoveryRequestDetails = () => {
  const { t } = useTranslation(['orac', 'common']);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const historyPush = (path: any, state?: any) =>
    dispatch(changeView(() => history.push(path, state)));

  const [requestId, setRequestId] = useState<string | null>(null);

  const [submittingAction, setSubmittingAction] = useState<
    'reject' | 'approve' | null
  >(null);

  useEffect(() => {
    const { id }: { id?: string } = queryString.parse(location.search);

    if (!id) {
      // TODO: handle this case
      // navigate to account recovery reqeusts and show desired message
    } else {
      setRequestId(id || null);
    }
  }, [location.search]);

  const { response, loading } = useFetch(
    () =>
      !requestId
        ? null
        : accountRecoveryRequestsAPI.fetchAccountRecoveryRequestById(requestId),
    [requestId],
  );

  const renderDocumentTypeName = (type: VerificationDocumentType) => {
    return t(`verification_document_type.${type}`);
  };

  const onActionClick = (action: 'reject' | 'approve', requestId: string) => {
    setSubmittingAction(action);

    const title = (
      <Typography.Text strong>
        {t(
          action === 'approve'
            ? 'account_recovery_requests.approve_request_confirm_title'
            : 'account_recovery_requests.reject_request_confirm_title',
        )}
      </Typography.Text>
    );

    const content = t(
      action === 'approve'
        ? 'account_recovery_requests.approve_request_description'
        : 'account_recovery_requests.reject_request_description',
    );

    Modal.confirm({
      title,
      content,
      width: 500,
      okText: t('yes', { ns: 'common' }),
      cancelText: t('no', { ns: 'common' }),
      onCancel: () => setSubmittingAction(null),
      onOk: () => handleRequestAction(action, requestId),
    });
  };

  const handleRequestAction = async (
    action: 'reject' | 'approve',
    requestId: string,
  ) => {
    const res =
      action === 'approve'
        ? await accountRecoveryRequestsAPI.approveAccountRecoveryRequestById(
            requestId,
          )
        : await accountRecoveryRequestsAPI.rejectAccountRecoveryRequestById(
            requestId,
          );

    if (res) {
      message.success(
        action === 'approve'
          ? t('account_recovery_requests.success_approve')
          : t('account_recovery_requests.success_reject'),
      );
      historyPush(RoutePaths.ORAC_AccountRecoveryRequests);
    }
  };

  const renderStatusParagraph = (status: AccountRecoveryEntryStatus) => {
    return (
      <StatusParagraph strong>
        {`${t('account_recovery_requests.status')}: `}
        <ResetAuthenticationRequestStatus status={status} />
      </StatusParagraph>
    );
  };

  if (loading || !response) {
    return (
      <SpinWrapper>
        <Spin />
      </SpinWrapper>
    );
  }

  return (
    <>
      {renderStatusParagraph(response.status)}

      <TitleText strong>
        {t('account_recovery_requests.account_details')}
      </TitleText>
      <StyledDivider />

      <DetailsSection recoveryRequestEntry={response} />

      <TitleText strong>
        {renderDocumentTypeName(response.documentType)}
        <StyledIcon icon={DriversLicense} />
      </TitleText>
      <StyledDivider />
      <VerificationDocumentPhotosSection documentPhotos={response.document} />

      <TitleText strong>
        {t('account_recovery_requests.selfie')}
        <StyledIcon icon={IndividualIcon} />
      </TitleText>
      <StyledDivider />

      <ImageCard imageSrc={response.selfieSrc} />

      {response.status === AccountRecoveryEntryStatus.Pending && (
        <Row justify="end" gutter={10}>
          <Col span={4}>
            <StyledButton
              size="large"
              color="error"
              disabled={!!submittingAction}
              loading={submittingAction === 'reject'}
              onClick={() => onActionClick('reject', response._id)}
            >
              {t('account_recovery_requests.reject')}
            </StyledButton>
          </Col>

          <Col span={4}>
            <StyledButton
              size="large"
              type="primary"
              disabled={!!submittingAction}
              loading={submittingAction === 'approve'}
              onClick={() => onActionClick('approve', response._id)}
            >
              {t('account_recovery_requests.approve')}
            </StyledButton>
          </Col>
        </Row>
      )}
    </>
  );
};

const StatusParagraph = styled(Typography.Paragraph)`
  display: flex;
  justify-content: flex-end;
`;

const SpinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: ${({ theme }) => theme.paddingMd};
`;

const TitleText = styled(Typography.Paragraph)`
  font-size: ${({ theme }) => theme.fontSizeMd};
  margin-bottom: 0px !important;
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.marginXs} 0px`};
`;

const StyledIcon = styled(Icon)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export default AccountRecoveryRequestDetails;
