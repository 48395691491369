import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { AccountsHelpers } from '../../../../../helpers/finance/accounts';
import { useFormikContext } from 'formik';
import { ForexTransferFormValues } from '.';

// components
import { Descriptions, Typography } from 'antd';

const SummarySection = () => {
  const { t } = useTranslation('banking');
  const { values } = useFormikContext<ForexTransferFormValues>();

  return (
    <Descriptions colon={false} size="small" layout="horizontal">
      <Descriptions.Item
        label={
          <StyledLabel>
            {' '}
            {`${t('forex.transfer_details.client_from_sell')}:`}
          </StyledLabel>
        }
        span={2}
      >
        <StyledAccountNameText>
          {AccountsHelpers.generateAccountFullName(
            values.fromAccountName,
            values.fromAccountNumber,
            values.fromAccountCurrency,
          )}
        </StyledAccountNameText>
      </Descriptions.Item>
      <Descriptions.Item>
        <StyledAmountText>{`${AccountsHelpers.formatMoneyToString(values.fromAccountBaseAmount)} ${
          values.fromAccountCurrency
        }`}</StyledAmountText>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <StyledLabel>{`${t('forex.transfer_details.client_to_buy')}:`}</StyledLabel>
        }
        span={2}
      >
        <StyledAccountNameText>
          {AccountsHelpers.generateAccountFullName(
            values.toAccountName,
            values.toAccountNumber,
            values.toAccountCurrency,
          )}
        </StyledAccountNameText>
      </Descriptions.Item>
      <Descriptions.Item>
        <StyledAmountText>{`${AccountsHelpers.formatMoneyToString(values.toAccountBaseAmount)} ${
          values.toAccountCurrency
        }`}</StyledAmountText>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <StyledLabel>{`${t('forex.transfer_details.treasury_from_sell')}:`}</StyledLabel>
        }
        span={2}
      >
        <StyledAccountNameText>
          {AccountsHelpers.generateAccountFullName(
            values.fromCorrespondentAccountName,
            values.fromCorrespondentAccountNumber,
            values.fromAccountCurrency,
          )}
        </StyledAccountNameText>
      </Descriptions.Item>
      <Descriptions.Item>
        <StyledAmountText>{`${AccountsHelpers.formatMoneyToString(values.fromAccountMidAmount)} ${
          values.fromAccountCurrency
        }`}</StyledAmountText>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <StyledLabel>{`${t('forex.transfer_details.treasury_to_buy')}:`}</StyledLabel>
        }
        span={2}
      >
        <StyledAccountNameText>
          {AccountsHelpers.generateAccountFullName(
            values.toCorrespondentAccountName,
            values.toCorrespondentAccountNumber,
            values.toAccountCurrency,
          )}
        </StyledAccountNameText>
      </Descriptions.Item>
      <Descriptions.Item>
        <StyledAmountText>{`${AccountsHelpers.formatMoneyToString(values.toAccountMidAmount)} ${
          values.toAccountCurrency
        }`}</StyledAmountText>
      </Descriptions.Item>

      <Descriptions.Item
        label={
          <StyledLabel>{`${t('forex.transfer_details.profit')}:`}</StyledLabel>
        }
        span={2}
      >
        <StyledAccountNameText>
          {AccountsHelpers.generateAccountFullName(
            values.profitAccountName,
            values.profitAccountNumber,
            values.profitAccountCurrency,
          )}
        </StyledAccountNameText>
      </Descriptions.Item>
      <Descriptions.Item>
        <StyledAmountText>{`${AccountsHelpers.formatMoneyToString(values.profit)} ${values.profitAccountCurrency}`}</StyledAmountText>
      </Descriptions.Item>
    </Descriptions>
  );
};

const StyledAccountNameText = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
  font-weight: 300;
`;

const StyledLabel = styled(Typography.Paragraph)`
  min-width: 200px;
`;

const StyledAmountText = styled(Typography.Text)`
  color: ${({ theme }) => theme.whiteColor};
`;

export default SummarySection;
