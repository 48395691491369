import React, { memo, useState } from 'react';

// helpers
import APIConfig from '../../../config/api';
import useTranslation from '../../../hooks/useTranslation';
import { SessionHelpers } from '@helpers/sessions';
import { DocumentHelpers } from '@helpers/documents';
import { LocalStorageHelpers } from '@helpers/storages/localStorage';
import { FetchNotesForDashboardParamsModel } from 'api/note/noteAPI';

// components
import { Button } from 'antd';

interface IProps {
  filters?: FetchNotesForDashboardParamsModel | null;
  disabled?: boolean;
}

const ExportNotesInExcelButton = memo(({ filters, disabled }: IProps) => {
  const { t } = useTranslation('crm');
  const [loader, setLoader] = useState(false);

  function generateLink(params: Record<string, any>): string {
    const queryParams = Object.entries(params)
      .filter(([_, value]) => value !== undefined)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`,
      )
      .join('&');

    return `?${queryParams}`;
  }

  const onExportClick = async () => {
    setLoader(true);
    const token = LocalStorageHelpers.getAccessToken();
    const fingerprint = await SessionHelpers.generateFingerprint();
    const linkParams = generateLink(
      filters
        ? { tokenb64: btoa(`Bearer ${token}`), fingerprint, ...filters }
        : { tokenb64: btoa(`Bearer ${token}`), fingerprint },
    );
    const link = `${APIConfig.notesApi}/excel${linkParams}`;
    await DocumentHelpers.downloadDocument('', '', '', link);
    setLoader(false);
  };

  return (
    <Button
      type="primary"
      loading={loader}
      disabled={disabled}
      onClick={onExportClick}
    >
      {t('documents.export_to_excel')}
    </Button>
  );
});

export default ExportNotesInExcelButton;
