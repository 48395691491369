import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';

// helpers
import { Popover, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
interface IProps {
  originalSpread: number;
}

const ChangedSpreadValueTooltip = ({ originalSpread }: IProps) => {
  const { t } = useTranslation('approval_management');

  return (
    <Popover
      placement="bottom"
      content={
        <>
          <StyledTypography>
            {t('approval_workflow.spread_modified_message')}
          </StyledTypography>
          <StyledTypography>
            {t('approval_workflow.default_spread', { value: originalSpread })}
          </StyledTypography>
        </>
      }
    >
      <StyledInfoIcon />
    </Popover>
  );
};

const StyledInfoIcon = styled(ExclamationCircleOutlined)`
  cursor: pointer;
  color: ${({ theme }) => theme.warningColor};
  margin-left: ${({ theme }) => theme.marginXXs};
`;

const StyledTypography = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
  white-space: pre-line;
`;

export default ChangedSpreadValueTooltip;
