import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { AccountManagementApprovalRuleTemplateData } from '..';

// components
import Text from '@core_components/Text';
import TableContentCell from './TableContentCell';
import { Table } from 'antd';

export enum ApprovalTemplateColumns {
  ApprovalGroupCondition = 'approval-group-condition',
  ApprovalRuleTriggerTypes = 'approval-trigger-types',
}

interface IProps {
  data: AccountManagementApprovalRuleTemplateData;
}

const InnerTemplate = ({ data }: IProps) => {
  const { t } = useTranslation('crm');

  const tableColumns = useMemo(() => {
    let result = [];

    const initialColumns: any[] = [
      {
        width: '40%',
        title: t(
          'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.administration_rule_changes_template.approval_group',
        ),
        key: 'approval-group-condition',
        dataIndex: ApprovalTemplateColumns.ApprovalGroupCondition,
      },

      {
        width: '20%',
        title: (
          <StyledText variant="body1" weight="semi-bold">
            {t(
              'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.administration_rule_changes_template.create',
            )}
          </StyledText>
        ),
        key: 'action-type-create',
        dataIndex: ApprovalTemplateColumns.ApprovalRuleTriggerTypes,
        approvalTriggerId: 1,
      },

      {
        width: '20%',
        title: (
          <StyledText variant="body1" weight="semi-bold">
            {t(
              'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.administration_rule_changes_template.edit',
            )}
          </StyledText>
        ),
        key: 'action-type-edit',
        dataIndex: ApprovalTemplateColumns.ApprovalRuleTriggerTypes,
        approvalTriggerId: 2,
      },

      {
        width: '20%',
        title: (
          <StyledText variant="body1" weight="semi-bold">
            {t(
              'client_group.administration_rules.pending_approvals.approval_details_modal.changes_section.administration_rule_changes_template.delete',
            )}
          </StyledText>
        ),
        key: 'action-type-delete',
        dataIndex: ApprovalTemplateColumns.ApprovalRuleTriggerTypes,
        approvalTriggerId: 3,
      },
    ];

    result = initialColumns.map((col, index: number) => {
      const baseProps = {
        title: col.title,
        dataIndex: col.dataIndex,
        colIndex: index > 0 ? index - 1 : 0,
      };

      return {
        ...col,
        onCell: (record: any, rowIndex: number) => ({
          ...baseProps,
          record,
          rowIndex,
          approvalTriggerId: col.approvalTriggerId || null,
          rulesCount: data.approvalRule.approvalRuleSets.length,
        }),
      };
    });

    return result;
  }, []);

  return (
    <Table
      bordered
      size="large"
      scroll={{ x: 'max-content' }}
      columns={tableColumns}
      pagination={false}
      className="disable-table-row-hover"
      dataSource={data.approvalRule.approvalRuleSets || []}
      components={{ body: { cell: TableContentCell } }}
    />
  );
};

const StyledText = styled(Text)`
  text-align: center;
  text-transform: uppercase;
`;

export default InnerTemplate;
