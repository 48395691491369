import React from 'react';
// helpers
import { contactsAPI } from '../../../../../../api/crm/contact/contactsAPI';
import { ContactHelpers } from '@helpers/crm/contact';
import { FormFieldPropsModel } from '@core_components/FormField';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import Autocomplete, {
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';

type Props = FormFieldPropsModel;

const ContactRequestsAutocomplete = (props: Props) => {
  const fetchContacts = async (searchQuery: string) => {
    const { data } = await contactsAPI.fetchContacts({
      search: searchQuery,
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
    });
    return data.map<AutocompleteOption>((contact) => {
      return {
        id: contact._id,
        content: ContactHelpers.getContactName(contact),
        model: contact,
      };
    });
  };

  return <Autocomplete {...props} fetchData={fetchContacts} />;
};

export default ContactRequestsAutocomplete;
