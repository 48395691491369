import React, { memo, useCallback, useMemo, useState } from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import { darkTheme } from '../../../../../../resources/theme/styled';
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';
import { IJournalEntryModel } from '../../../../../../typings/finance/journal';
import { ReconciliationStatuses } from '../../../../../../enums/finance/finance';

// components
import Text from '../../../../../DesignSystem/Core/Text';
import FormEditModeButtons from '../../../../FormComponents/FormEditModeButtons';
import UpdateReconciliationStatusesProgressForm from '../../UpdateReconciliationStatusesProgressForm';
import AccountJournalEntriesTable, {
  IProps as AccountJournalEntriesTableProps,
  ActionKeys,
} from './AccountJournalEntriesTable';
import UpdateReconciliationStatusForJournalEntryModal, {
  FormValuesModel as ModalFormValuesModel,
} from '../../../../../Modals/TemplateModalDialogs/Finance/UpdateReconciliationStatusForJournalEntryModal';
import { Card } from 'antd';

type IProps = AccountJournalEntriesTableProps;

const InnerForm = memo((props: IProps) => {
  const { t } = useTranslation('finance');
  const { values, setFieldValue } = useFormikContext<FormValuesModel>();
  const [selectedJournalEntry, setSelectedJournalEntry] =
    useState<IJournalEntryModel | null>(null);

  const initialValues = useMemo<ModalFormValuesModel | null>(() => {
    if (!selectedJournalEntry) {
      return null;
    }

    return {
      id: selectedJournalEntry.id,
      status: selectedJournalEntry.reconciliationStatus,
      message: '',
    };
  }, [selectedJournalEntry]);

  const handleActionsClick = useCallback(
    (key: ActionKeys, record: IJournalEntryModel, newValue: any) => {
      switch (key) {
        case 'edit':
          {
            setSelectedJournalEntry({
              ...record,
              reconciliationStatus: newValue as ReconciliationStatuses,
            });
          }
          break;

        case 'view_journal':
          {
            props.onActionsClick &&
              props.onActionsClick('view_journal', record);
          }
          break;
      }
    },
    [],
  );

  const handleModalClose = useCallback(() => {
    setSelectedJournalEntry(null);
  }, []);

  const handleModalSubmit = useCallback(
    (value: ModalFormValuesModel) => {
      if (selectedJournalEntry) {
        const entriesHashCopy = { ...values.entriesHash };

        entriesHashCopy[value.id] = {
          id: value.id,
          message: value.message,
          newReconciliationStatus: value.status,
          journalId: selectedJournalEntry.journalId,
          valueDate: selectedJournalEntry.valueDate,
          debitAmount: selectedJournalEntry.debitAmount,
          creditAmount: selectedJournalEntry.creditAmount,
          description: selectedJournalEntry.description,
        };

        setFieldValue('entriesHash', entriesHashCopy);
        setSelectedJournalEntry(null);
      }
    },
    [values, selectedJournalEntry],
  );

  return (
    <>
      {props.canChangeReconciliationStatus && <FormEditModeButtons />}

      {values.isEditMode && !!Object.keys(values.entriesHash).length ? (
        <Card>
          <Text
            variant="subtitle1"
            color={darkTheme.whiteColor}
            weight="semi-bold"
            gutterBottom
          >
            {t(
              'update_reconciliation_status.updated_journal_entries_section.title',
            )}
          </Text>
          <UpdateReconciliationStatusesProgressForm />
        </Card>
      ) : null}

      <AccountJournalEntriesTable
        {...props}
        onActionsClick={handleActionsClick}
      />

      <UpdateReconciliationStatusForJournalEntryModal
        onSubmit={handleModalSubmit}
        isVisible={!!selectedJournalEntry}
        initialValues={initialValues}
        closeCallback={handleModalClose}
      />
    </>
  );
});

export default InnerForm;
