import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { ExpiringDocument } from 'api/crm/expiringDocuments/expiringDocumentsAPI';

// component
import DocumentLink from 'components/Typography/DocumentLink';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel;

const ReviewDetailsExpiringDocumentsTable = (props: IProps) => {
  const { t } = useTranslation('crm');
  const [current, setCurrent] = useState(1);

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        key: 'name',
        width: 250,
        title: t(
          'client_group.approval.review_details_modal.approval.documents_table.name',
        ),
        render: (record: ExpiringDocument) =>
          record.details.passportDetails.number,
      },
      {
        key: 'answer',
        width: 250,
        title: t(
          'client_group.approval.review_details_modal.approval.documents_table.answer',
        ),
        render: (record: ExpiringDocument) => {
          return record.details.passportDetails.documents.map((e) => (
            <div key={e.id}>
              <DocumentLink
                text={e.files[0].name}
                fileName={e.files[0].name}
                documentId={e.id}
                fileId={e.files[0].id}
              />
            </div>
          ));
        },
      },
      {
        key: 'rejectionReason',
        width: 300,
        title: t(
          'client_group.approval.review_details_modal.approval.documents_table.rejection_reason',
        ),
        render: (record: ExpiringDocument) => {
          return (
            <EllipsisTooltip
              maxTextContainerWidth="300px"
              title={record.review?.comment || ''}
            >
              {record.review?.comment || ''}
            </EllipsisTooltip>
          );
        },
      },
    ];

    return result;
  }, []);

  return (
    <Table
      columns={columns}
      current={current}
      onPaginationChange={setCurrent}
      {...props}
    />
  );
};

export default ReviewDetailsExpiringDocumentsTable;
