import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { ReviewApprovalWorkflowItemValidationSchema } from 'validations/approval';

// components
import InnerForm from './InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {}

export type FormValuesModel = {
  reason: string;
  action: 'approve' | 'reject';
};

const ReviewApprovalEntryModal = memo(
  ({ isVisible, closeCallback, initialValues, onSubmit }: IProps) => {
    const { t } = useTranslation(['approval_management', 'common']);

    const submitButtonText = useMemo(() => {
      if (!initialValues) {
        return '';
      }

      return initialValues.action === 'approve'
        ? t(
            'approval_workflow.review_approval_entry_modal.submit_button.approve',
          )
        : t(
            'approval_workflow.review_approval_entry_modal.submit_button.reject',
          );
    }, [initialValues]);

    return (
      <Form
        enableReinitialize
        confirmExitWithoutSaving={false}
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={ReviewApprovalWorkflowItemValidationSchema}
        renderForm={
          <ModalDialog
            width={800}
            title={t('approval_workflow.review_approval_entry_modal.title')}
            isVisible={isVisible}
            closeCallback={closeCallback}
            submitButtonProps={{ text: submitButtonText }}
            cancelButtonProps={{ text: t('discard_changes', { ns: 'common' }) }}
          >
            <InnerForm />
          </ModalDialog>
        }
      />
    );
  },
);

export default ReviewApprovalEntryModal;
