import React, { useState, useEffect } from 'react';

// helpers
import useUserAccess from '../../../hooks/useUserAccess';
import {
  ILayoutHeaderOptions,
  PageLayoutHelperPropsModel,
} from '../../../layouts/PageLayout';
import { GENESIS_ENTITY_TYPE_CATEGORIES_DOCUMENTATION_LINK } from '../../../constants/externalLinks';

// components
import AddEntityTypeCategory from 'modules/CRM/EntityTypeCategories/AddEntityTypeCategory';
import TooltipToDocumentation from '../../../components/Tooltips/TooltipToDocumentation';
import EntityTypeCategoriesTable from '../../../modules/CRM/EntityTypeCategories/EnityTypeCategoriesTable';

type IProps = PageLayoutHelperPropsModel;

const EntityTypeCategories = ({ setHeaderOptions }: IProps) => {
  const [hasCreateAccess] = useUserAccess([
    'CRM_Configuration_EntityTypeCategories_Create',
  ]);
  const [updateTableTrigger, updateTable] = useState({});

  useEffect(() => {
    const headerOptions: ILayoutHeaderOptions = {
      appendToTitle: (
        <TooltipToDocumentation
          link={GENESIS_ENTITY_TYPE_CATEGORIES_DOCUMENTATION_LINK}
        />
      ),
    };

    if (hasCreateAccess) {
      headerOptions['extra'] = (
        <AddEntityTypeCategory onAdd={() => updateTable({})} />
      );
    }

    setHeaderOptions(headerOptions);
  }, [hasCreateAccess]);

  return <EntityTypeCategoriesTable updateTableTrigger={updateTableTrigger} />;
};

export default EntityTypeCategories;
