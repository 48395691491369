import React from 'react';

// helpers
import { IEntity } from 'typings/crm/entity';

// components
import AssociatedClientGroupsTable from '../../../../../../components/Tables/TableTemplates/CRM/AssociatedClientGroupsTable';

interface IProps {
  entity: IEntity;
}

const ClientGroups = ({ entity }: IProps) => {
  return <AssociatedClientGroupsTable data={entity.clientGroups || []} />;
};

export default ClientGroups;
