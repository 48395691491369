import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../../../routes/routes';
import { RoleBaseModel } from '../../../../../../typings/ORAC/roles';

// components
import ViewRoleDetails from '../../../../../../modules/ORAC/Users/UserDetails/GroupsTab/ViewRoleDetails';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<RoleBaseModel> {
  canSeeRolePermissions: boolean;
}

const RolesTable = memo(({ canSeeRolePermissions, ...rest }: IProps) => {
  const { t } = useTranslation('orac');
  const history = useHistory();

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('roles.table.name'),
        key: 'name',
        width: 350,
        render: (record: RoleBaseModel) => (
          <Typography.Link
            onClick={() =>
              history.push({
                pathname: RoutePaths.ORAC_Role_Details,
                search: `?id=${record.id}`,
              })
            }
          >
            {record.name}
          </Typography.Link>
        ),
      },

      {
        title: t('roles.table.description'),
        key: 'description',
        render: (record: RoleBaseModel) => record.description,
      },

      {
        key: 'actions',
        hidden: !canSeeRolePermissions,
        align: 'right',
        render: (record: RoleBaseModel) => (
          <ViewRoleDetails roleId={record.id} />
        ),
      },
    ];

    return result;
  }, [canSeeRolePermissions]);

  return (
    <StyledRolesTable
      {...rest}
      className="disable-table-row-hover"
      size="small"
      showHeader={false}
      columns={columns}
      disableHorizontalScroll
    />
  );
});

const StyledRolesTable = styled(Table)`
  .ant-table-cell {
    padding: 8px 16px !important;
  }
`;

export default RolesTable;
