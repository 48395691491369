import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { message } from 'antd';
import { darkTheme } from '@resources/theme/styled';
import { useFormikContext } from 'formik';
import { onboardingAnswerAPI } from 'api/crm/onboarding/onboardingAnswerAPI';
import { AdditionalFieldHelpers } from '@helpers/additionalField';
import { onboardingAnswerAdapter } from 'apiAdapters/crm/onboarding/onboardingAnswerAdapter';
import { DocumentAssociationTypes, OnboardType } from 'enums/crm/crm';
import {
  AdditionalFieldFormItemModel,
  FormValuesModel as ApplicationDocumentationFormValuesModel,
} from '../../../..';

// components
import Link from '@common_components/Texts/Link';
import IconSVG from '@core_components/IconSVG';
import AdditionalFieldAnswerModalDialog, {
  FormValuesModel,
} from 'components/Modals/TemplateModalDialogs/CRM/AdditionalFieldAnswerModalDialog';
import { ReactComponent as EditIcon } from '@resources/icons/remix-icons/edit-line.svg';

interface IProps {
  fieldIndex: number;
  additionalField: AdditionalFieldFormItemModel;
  selectedEntry: {
    id: string;
    type: OnboardType;
  };
  reviewProcess: {
    type: 'contact-review' | 'client-group-review';
    entryId: string;
  };
}

const UpdateAnswerFromClient = ({
  additionalField,
  reviewProcess,
  selectedEntry,
  fieldIndex,
}: IProps) => {
  const { t } = useTranslation(['crm', 'common']);
  const [isVisible, setIsVisible] = useState(false);
  const { values: applicationDocumentationFormValues, setFieldValue } =
    useFormikContext<ApplicationDocumentationFormValuesModel>();

  const initialValues = useMemo<FormValuesModel | null>(() => {
    const { _id, field, answer } = additionalField;
    const isNotProvidedReason = Boolean(answer && !answer.isProvided);
    const value =
      !isNotProvidedReason && answer
        ? AdditionalFieldHelpers.formatFromJSON(field.type, answer.valueJSON)
        : undefined;

    return {
      additionalField: {
        id: _id,
        name: field.name,
        value,
        isNotProvidedReason,
        notProvidedReason: answer?.reason || '',
        type: field.type,
        relatedTo: field.relatedTo,
        options: {
          ...field.options,
          selectOptions: field.options.selectOptions.length
            ? field.options.selectOptions.map((e) => JSON.parse(e.valueJSON))
            : [],
          formDocument: field.options.formDocument,
        },
      },
      reviewProcess,
      selectedEntry,
      documentAssociation: {
        id: selectedEntry.id,
        type:
          selectedEntry.type === OnboardType.Contact
            ? DocumentAssociationTypes.Contact
            : DocumentAssociationTypes.Entity,
      },
    };
  }, [additionalField, reviewProcess, selectedEntry]);

  const handleModalClose = () => {
    setIsVisible(false);
  };

  const handleSubmit = async (values: FormValuesModel) => {
    const response = await onboardingAnswerAPI.updateAnswerOfAdditionalField(
      onboardingAnswerAdapter.fillAnswer(values),
    );
    message.success(
      t(
        'client_group.approval.update_answer_of_additional_field_modal.success_submit_message',
      ),
    );

    if (response && response.operations.length) {
      const answer = response.operations[0].result.answer;
      setFieldValue(`additionalFields.${fieldIndex}`, {
        ...applicationDocumentationFormValues.additionalFields[fieldIndex],
        answer: {
          providedBy: answer?.details?.providedBy
            ? {
                id: answer.details.providedBy.id,
                name: answer.details.providedBy.name,
              }
            : undefined,
          isProvided: answer?.isProvided,
          valueJSON: answer?.valueJSON || '',
          reason: answer?.reason || '',
        },
      });
    }
  };

  return (
    <>
      <Link onClick={() => setIsVisible(true)}>
        {t('update', { ns: 'common' })}
        <StyledIconSVG
          component={EditIcon}
          size="extra-small"
          color={darkTheme.primaryGreen}
        />
      </Link>

      <AdditionalFieldAnswerModalDialog
        onSubmit={handleSubmit}
        isVisible={isVisible}
        closeCallback={handleModalClose}
        initialValues={initialValues}
      />
    </>
  );
};

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginXXs};
  cursor: pointer;
`;

export default UpdateAnswerFromClient;
