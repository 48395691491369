import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';

// components
import InvitationDateAcceptedSelect from '../../../../../components/Forms/FormComponents/SelectInputs/InvitationDateAcceptedSelect';
import { SearchInput } from 'components/Fields/SearchInput';
import { Row, Col, Typography } from 'antd';

interface IProps {
  onFilterChange: (key: FilterTypes, value: any) => void;
}

export type FilterTypes = 'search' | 'filter_by_accepted_date_status';

const FilterBar = ({ onFilterChange }: IProps) => {
  const { t } = useTranslation('crm');

  const gridSizes = { xl: 6, lg: 8, md: 12, sm: 24, xs: 24 };

  return (
    <Row gutter={[16, 16]}>
      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('invitations.table.search_contact')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <SearchInput
              size="large"
              placeholder={t('invitations.table.type_contact_name')}
              onSearch={(searchQuery: string) =>
                onFilterChange('search', searchQuery)
              }
            />
          </Col>
        </Row>
      </Col>

      <Col {...gridSizes}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('invitations.table.filter_by_accepted_date_status')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <StyledInvitationDateAcceptedSelect
              onChange={(e) =>
                onFilterChange('filter_by_accepted_date_status', e)
              }
              placeholder={t(
                'invitations.table.filter_placeholder_accepted_date_status',
              )}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
const StyledInvitationDateAcceptedSelect = styled(InvitationDateAcceptedSelect)`
  width: 100%;

  .ant-select-selector {
    height: 40px !important;
    display: flex !important;
    align-items: center;
    font-size: 16px;
  }
`;

export default FilterBar;
