import React, { useMemo } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { SelectOption, SelectProps } from '@core_components/Select';
import { AdministrationPermissionTypes } from 'enums/crm/crm';

// components
import FormSelect from '@common_components/Form/FormSelect';

export interface IProps extends SelectProps {
  hideLimitedPermission?: boolean;
}

const AdministrationPermissionSelect = ({
  hideLimitedPermission,
  ...rest
}: IProps) => {
  const { t } = useTranslation('crm');

  const options = useMemo<SelectOption[]>(() => {
    const options = hideLimitedPermission
      ? [
          AdministrationPermissionTypes.Admin,
          AdministrationPermissionTypes.None,
        ]
      : [
          AdministrationPermissionTypes.Admin,
          AdministrationPermissionTypes.None,
          AdministrationPermissionTypes.LimitedAdmin,
        ];

    return options.map((key) => ({
      id: key,
      label: t(`client_group.administration_permissions.${key}`),
    }));
  }, [t, hideLimitedPermission]);

  return <FormSelect {...rest} options={options} />;
};

export default AdministrationPermissionSelect;
