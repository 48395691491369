import React, { memo, useMemo } from 'react';

// helpers
import useUserAccess from '@hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';
import { ClientApprovalGroup } from 'typings/approval/approvalGroups';

// components
import Link from '@common_components/Texts/Link';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import ApprovalGroupStatus from 'components/Additional/Statuses/ApprovalGroupStatus';
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

export type ActionKeys = 'view';

interface IProps extends RequiredPropsForTableModel<ClientApprovalGroup> {
  hasEditAccess?: boolean;
  onActionsClick?: (key: ActionKeys, record: ClientApprovalGroup) => void;
}

const ClientApprovalGroupsTable = memo(
  ({ onActionsClick, ...rest }: IProps) => {
    const { t } = useTranslation('crm');

    const [hasEditGroupAccess, hasEditTransactionGroupAccess] = useUserAccess([
      'CRM_ClientGroup_Details_AdministrationRules_ApprovalGroups_Edit',
      'CRM_ClientGroup_Details_TransactionRules_ApprovalGroups_Edit',
    ]);

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          key: 'name',
          title: t('client_group.approval.client_approval_groups.table.name'),
          width: 250,
          render: (record: ClientApprovalGroup) =>
            onActionsClick &&
            record.status == 'active' &&
            (hasEditGroupAccess || hasEditTransactionGroupAccess) ? (
              <Link onClick={() => onActionsClick('view', record)}>
                {record.name}
              </Link>
            ) : (
              record.name
            ),
        },
        {
          key: 'description',
          title: t(
            'client_group.approval.client_approval_groups.table.description',
          ),
          width: 350,
          render: (record: ClientApprovalGroup) => (
            <EllipsisTooltip
              maxTextContainerWidth="350px"
              title={record.description}
            >
              {record.description}
            </EllipsisTooltip>
          ),
        },
        {
          key: 'members',
          title: t(
            'client_group.approval.client_approval_groups.table.members',
          ),
          width: 300,
          render: (record: ClientApprovalGroup) =>
            record.participants.map((e) => e.name).join(', '),
        },
        {
          key: 'status',
          title: t('client_group.approval.client_approval_groups.table.status'),
          width: 200,
          render: (record: ClientApprovalGroup) => (
            <ApprovalGroupStatus status={record.status} />
          ),
        },
      ];

      return result;
    }, [onActionsClick]);

    return <Table {...rest} columns={columns} />;
  },
);

export default ClientApprovalGroupsTable;
