import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { AccountTypes } from 'enums/finance/finance';
import { TWO_COL_GRID_SIZES } from 'constants/grids';

// components
import Text from '@core_components/Text';
import FormField from '@core_components/FormField';
import SectionIntro from '@common_components/Texts/SectionIntro';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import CountryFormSelect from 'components/Forms/FormComponents/SelectInputs/CountryFormSelect';
import WireRangesFormSelect, {
  WireRangeKeys,
} from 'components/Forms/FormComponents/SelectInputs/WireRangesFormSelect';
import AccountTypeFormSelect from 'components/Forms/FormComponents/SelectInputs/AccountTypeFormSelect';
import InitialDepositRangeFormSelect, {
  InitialDepositRangeKeys,
} from 'components/Forms/FormComponents/SelectInputs/InitialDepositRangeFormSelect';
import EstimatedAmountFundsRangeFormSelect, {
  EstimatedAmountFundsRangeKeys,
} from 'components/Forms/FormComponents/SelectInputs/EstimatedAmountFundsRangeFormSelect';
import { Col, Row } from 'antd';

export type FormValuesModel = {
  purpose: string;
  type: AccountTypes | null;
  initialDeposit: InitialDepositRangeKeys | null;
  initialDepositOrigin: string | null;
  jurisdictions: string[];
  incomingWiresNumber: WireRangeKeys | null;
  estimatedIncomingFunds: EstimatedAmountFundsRangeKeys | null;
  outgoingWiresNumber: WireRangeKeys | null;
  estimatedOutgoingFunds: EstimatedAmountFundsRangeKeys | null;
};

interface IProps {
  namePrefix: string;
}

const AccountSupplementaryForm = ({ namePrefix }: IProps) => {
  const { t } = useTranslation('finance');

  return (
    <>
      <FormField
        label={t('accounts.new.purpose_of_account')}
        name={`${namePrefix}.purpose`}
        component={FormInputTextArea}
        additionalProps={{ autoSize: { minRows: 2, maxRows: 6 } }}
      />

      <FormField
        disabled
        label={t('accounts.new.account_type')}
        name={`${namePrefix}.type`}
        component={AccountTypeFormSelect}
      />

      <FormField
        label={t('accounts.new.expected_initial_deposit')}
        name={`${namePrefix}.initialDeposit`}
        component={InitialDepositRangeFormSelect}
      />

      <FormField
        label={t('accounts.new.source_of_initial_deposit')}
        name={`${namePrefix}.initialDepositOrigin`}
        component={CountryFormSelect}
      />

      <SectionIntro
        title={t('accounts.new.estimated_monthly_account_activity')}
        titleVariant="h4"
      />
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <Text variant="body1">{t('accounts.new.jurisdictions')}</Text>
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            name={`${namePrefix}.jurisdictions`}
            component={CountryFormSelect}
            additionalProps={{ mode: 'multiple' }}
          />
        </Col>
      </Row>

      <StyledRow gutter={[16, 0]}>
        <Col offset={4} span={10}>
          <Text variant="body1">{t('accounts.new.volume')}</Text>
        </Col>

        <Col span={10}>
          <Text variant="body1">{t('accounts.new.amount')}</Text>
        </Col>
      </StyledRow>

      <Row gutter={[16, 16]}>
        <Col xl={4} lg={4} md={24} sm={24} xs={24}>
          <Text variant="body1" weight="semi-bold">
            {t('accounts.new.incoming_activity')}
          </Text>
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            name={`${namePrefix}.incomingWiresNumber`}
            component={WireRangesFormSelect}
          />
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            name={`${namePrefix}.estimatedIncomingFunds`}
            component={EstimatedAmountFundsRangeFormSelect}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xl={4} lg={4} md={24} sm={24} xs={24}>
          <Text variant="body1" weight="semi-bold">
            {t('accounts.new.outgoing_activity')}
          </Text>
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            name={`${namePrefix}.outgoingWiresNumber`}
            component={WireRangesFormSelect}
          />
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            name={`${namePrefix}.estimatedOutgoingFunds`}
            component={EstimatedAmountFundsRangeFormSelect}
          />
        </Col>
      </Row>
    </>
  );
};

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

export default AccountSupplementaryForm;
