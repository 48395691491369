import * as yup from 'yup';
import { NULLABLE_STRING_VALIDATION_RULE } from 'validations/common';

export const NewConfigurationRuleValidationSchema = yup.object().shape({
  reviewType: yup.number().nullable().required('This field is required'),
  riskLevel: yup.number().nullable().required('This field is required'),
  referenceObject: NULLABLE_STRING_VALIDATION_RULE.required(
    'This field is required',
  ),
  referenceProperty: NULLABLE_STRING_VALIDATION_RULE.required(
    'This field is required',
  ),
  expectedDataType: yup.number().nullable().required('This field is required'),
  comparisonOperator: yup
    .number()
    .nullable()
    .required('This field is required'),
  expectedValueType: yup.number().nullable().required('This field is required'),
  expectedValue: yup.mixed().nullable().required('This field is required'),
  isManualReview: yup.boolean().nullable().required('This field is required'),
  state: yup.number().nullable().required('This field is required'),
});
