import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { relationshipTemplatesAPI } from '../../../../api/crm/relationship/relationshipTemplatesAPI';
import { IRelationshipTemplateModel } from '../../../../typings/crm/relationship-template';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';

// components
import RelationshipInstancesModal from '../RelationshipInstancesModal';
import AddEditRelationshipTemplate from '../AddEditRelationshipTemplate';
import { Divider } from 'antd';

import FilterBar from './FilterBar';
import { default as TemplateRelationshipTemplatesTable } from '../../../../components/Tables/TableTemplates/CRM/RelationshipTemplatesTable';

interface IProps {
  updateTableTrigger?: any;
}

const RelationshipTemplatesTable = ({ updateTableTrigger }: IProps) => {
  // table
  const [current, setCurrentPage] = useState(1);
  const [localUpdateTableTrigger, updateTable] = useState({});

  // modal dialogs
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [viewInstancesModalOpen, setViewInstancesModalOpen] = useState(false);

  const [selectedTemplate, setSelectedTemplate] =
    useState<IRelationshipTemplateModel | null>(null);

  // filter bar
  const [searchQuery, setSearchQuery] = useState('');

  const { response, loading } = useFetch(
    () =>
      relationshipTemplatesAPI.fetchRelationshipTemplates({
        page: current,
        search: searchQuery,
        limit: DEFAULT_TABLE_LIMIT,
      }),
    [current, updateTableTrigger, localUpdateTableTrigger, searchQuery],
  );

  const handlePagination = (newPage: number) => setCurrentPage(newPage);

  const handleTableActions = (key: string, templateId: string) => {
    const template = response?.data.find((item) => item._id === templateId);
    template && setSelectedTemplate(template);

    switch (key) {
      case 'view_template':
        setEditModalOpen(true);
        break;

      case 'view_instances':
        setViewInstancesModalOpen(true);
        break;
    }
  };

  const handleEditModalClose = (shouldUpdateTable?: boolean) => {
    setSelectedTemplate(null);
    setEditModalOpen(false);
    shouldUpdateTable && updateTable({});
  };

  const handleViewInstancesClose = () => {
    setSelectedTemplate(null);
    setViewInstancesModalOpen(false);
  };

  const handleFilterBarChange = (key: string, value: string) => {
    if (current > 1) {
      setCurrentPage(1);
    }

    switch (key) {
      case 'search':
        setSearchQuery(value);
        break;
    }
  };

  return (
    <>
      <FilterBar handleChange={handleFilterBarChange} />

      <Divider />

      <TemplateRelationshipTemplatesTable
        data={response?.data || []}
        total={response?.total || 0}
        current={current}
        onPaginationChange={handlePagination}
        loading={loading}
        onActionsClick={handleTableActions}
      />

      <AddEditRelationshipTemplate
        isVisible={editModalOpen}
        closeCallback={handleEditModalClose}
        initialValues={selectedTemplate || undefined}
      />

      <RelationshipInstancesModal
        isVisible={viewInstancesModalOpen}
        closeCallback={handleViewInstancesClose}
        templateId={selectedTemplate?._id || null}
      />
    </>
  );
};

export default RelationshipTemplatesTable;
