import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { NotificationMethods } from '../../enums/admin/orac';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import {
  NotificationDetailsModel,
  NotificationsBaseModel,
} from '../../typings/ORAC/notifications';

type NotificationQueries = {
  notificationId?: string;
  notificationType: NotificationMethods;
};

interface NotificationsParams extends FetchParamsModel, NotificationQueries {}

const notificationsAPI = {
  fetchNotifications: (params: NotificationsParams) => {
    return APIService.get<FetchResponseModel<NotificationsBaseModel>>(
      `${APIConfig.notificationApi}`,
      { params },
    ).then(({ data }) => data);
  },

  fetchNotificationById: ({
    notificationId,
    notificationType,
  }: NotificationQueries) => {
    return APIService.get<NotificationDetailsModel>(
      `${APIConfig.notificationApi}/${notificationId}`,
      {
        params: { notificationType },
      },
    ).then(({ data }) => data);
  },
};

export { notificationsAPI };
