import React, { useEffect, useState } from 'react';

// helpers
import useFetch from '../../hooks/useFetch';
import useQueryParam from '../../hooks/useQueryParam';
import useTranslation from '../../hooks/useTranslation';
import { RoutePaths } from '../../routes/routes';
import { clientGroupsAPI } from '../../api/crm/clientGroup/clientGroupsAPI';
import { PageLayoutHelperPropsModel } from '../../layouts/PageLayout';

// components
import LoadingWrapper from '../../components/WrapperComponents/LoadingWrapper';
import ClientGroupPageContent from '../../modules/CRM/ClientGroups/ClientGroupDetails/ClientGroupPageContent';

type IProps = PageLayoutHelperPropsModel;

const ClientGroupDetailsPage = ({ setHeaderOptions }: IProps) => {
  const { t } = useTranslation('crm');
  const [updateClientGroupTrigger, updateClientGroupData] = useState<any>();
  const clientGroupId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.CRM_Client_Groups,
  });

  const { response, loading } = useFetch(
    () =>
      clientGroupId
        ? clientGroupsAPI.fetchClientGroupById(clientGroupId)
        : null,
    [clientGroupId, updateClientGroupTrigger],
  );

  useEffect(() => {
    if (response) {
      setHeaderOptions({
        title: t('client_group.title', {
          name: response.clientGroup.clientGroupName,
        }),
        cardTitle: t('client_group.details'),
      });
    }
  }, [response]);

  return (
    <LoadingWrapper loading={loading}>
      {response && (
        <ClientGroupPageContent
          clientGroupData={response}
          updateClientGroupDataCallback={() => updateClientGroupData({})}
        />
      )}
    </LoadingWrapper>
  );
};

export default ClientGroupDetailsPage;
