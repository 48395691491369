import React, { useMemo, useState } from 'react';

// helpers
import moment from 'moment';
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { Moment } from 'moment';
import { SortProps } from '@core_components/Table';
import { FormikHelpers } from 'formik';
import { journalsAdapter } from '../../../../apiAdapters/finance/journal/journalsAdapter';
import { IJournalEntryModel } from 'typings/finance/journal';
import {
  FetchAccountJournalEntriesParamsModel,
  journalsAPI,
} from '../../../../api/finance/journal/journalsAPI';

// constants
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';

// components
import FilterBar from './FilterBar';
import ViewEditJournalModal from './ViewEditJournalModal';
import ExportAccountEntriesToCSVButton from 'components/Additional/ExportAccountEntriesToCSVButton';
import UpdateReconciliationStatusForJournalEntriesForm, {
  FormValuesModel,
} from '../../../../components/Forms/TemplateForms/Finance/UpdateReconciliationStatusForJournalEntriesForm';
import { Col, Divider, Row, message } from 'antd';

interface IProps {
  accountId: string;
  isDebitStatus: boolean;
  canUpdateReconciliationStatus: boolean;
  onJournalsUpdate: () => void;
}

export type SortJournalEntryByType =
  | 'PostingDate'
  | 'ValueDate'
  | 'ReconciliationStatus';

export interface SortJournalEntryModel {
  sortBy: SortJournalEntryByType;
  sortDirection: boolean;
}

const AccountJournalEntriesTable = ({
  accountId,
  isDebitStatus,
  onJournalsUpdate,
  canUpdateReconciliationStatus,
}: IProps) => {
  const { t } = useTranslation('finance');
  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState<[Moment, Moment]>(
    getInitialHalfYearDateRange(),
  );
  const [updateTableTrigger, updateTable] = useState({});
  const [selectedJournal, setSelectedJournal] = useState<number | undefined>();
  const [sortJournalEntry, setSortJournalEntry] =
    useState<SortJournalEntryModel | null>(null);

  function getInitialHalfYearDateRange(): [Moment, Moment] {
    const startDate =
      moment().month(6).startOf('month') < moment()
        ? moment().month(6).startOf('month')
        : moment().month(6).startOf('month').subtract(1, 'year');

    return [startDate, moment().endOf('day')];
  }

  const initialValues = useMemo<FormValuesModel>(() => {
    return {
      isDebit: isDebitStatus,
      isEditMode: false,
      entriesHash: {},
    };
  }, [isDebitStatus]);

  const { response, loading } = useFetch(() => {
    if (!accountId) {
      return null;
    }

    const params: FetchAccountJournalEntriesParamsModel = {
      page,
      accountNumber: accountId,
      limit: DEFAULT_TABLE_LIMIT,
      toDate: dateRange[1].unix(),
      fromDate: dateRange[0].unix(),
      sortBy: sortJournalEntry ? sortJournalEntry.sortBy : undefined,
      sortDirection: sortJournalEntry
        ? sortJournalEntry.sortDirection
        : undefined,
    };

    return journalsAPI.fetchAccountJournalEntries(params);
  }, [page, dateRange, accountId, updateTableTrigger, sortJournalEntry]);

  const handleFilterChange = (key: string, newValue: any) => {
    if (page > 1) {
      setPage(1);
    }

    switch (key) {
      case 'date-range':
        {
          if (newValue) {
            const copyValue = [...newValue];
            copyValue[1] = copyValue[1].endOf('day');
            setDateRange(copyValue as any);
          } else {
            setDateRange(getInitialHalfYearDateRange());
          }
        }
        break;
    }
  };

  const handleModalClose = (shouldUpdateTable?: boolean) => {
    shouldUpdateTable && updateTable({});
    setSelectedJournal(undefined);
  };

  const handleTableActions = (key: string, record: IJournalEntryModel) => {
    switch (key) {
      case 'view_journal':
        setSelectedJournal(record.journalId);
        break;
    }
  };

  const handleSortChange = (sort: SortProps) => {
    if (Array.isArray(sort)) {
      return;
    }

    if (!sort.order) {
      setSortJournalEntry(null);
    } else {
      switch (sort.columnKey) {
        case 'posting_date':
          setSortJournalEntry({
            sortBy: 'PostingDate',
            sortDirection: false,
          });
          break;

        case 'value_date':
          setSortJournalEntry({
            sortBy: 'ValueDate',
            sortDirection: false,
          });
          break;

        case 'reconciliation_status':
          setSortJournalEntry({
            sortBy: 'ReconciliationStatus',
            sortDirection: false,
          });
          break;
      }
    }
  };

  const handleSubmit = async (
    values: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const formattedBody = journalsAdapter.updateReconciliationStatuses(
      values,
      accountId,
    );
    await journalsAPI.updateReconciliationStatuses(formattedBody);
    message.success(t('update_reconciliation_status.success_submit_message'));
    formikHelpers.resetForm({ values: initialValues });
    onJournalsUpdate();
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <FilterBar
            dateRangeValue={dateRange}
            onFilterChange={handleFilterChange}
          />
        </Col>
        <Col>
          <ExportAccountEntriesToCSVButton
            selectedFilterDate={dateRange}
            accountNumber={accountId}
            disabled={!response?.data?.length}
          />
        </Col>
      </Row>
      <Divider />
      <UpdateReconciliationStatusForJournalEntriesForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        data={response?.data || []}
        total={response?.total || 0}
        current={page}
        loading={loading}
        isDebit={isDebitStatus}
        sortCallback={handleSortChange}
        onActionsClick={handleTableActions}
        onPaginationChange={setPage}
        currentAccountNumber={accountId}
        canChangeReconciliationStatus={canUpdateReconciliationStatus}
      />
      <ViewEditJournalModal
        journalId={selectedJournal}
        isVisible={!!selectedJournal}
        closeCallback={handleModalClose}
      />
    </>
  );
};

export default AccountJournalEntriesTable;
