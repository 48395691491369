import { DateHelpers } from '@helpers/date';
import { COUNTRY_CODES } from 'constants/countryCodes';
import { CountryHelpers } from '@helpers/crm/country';
import { AccountsHelpers } from '@helpers/finance/accounts';
import { JournalEntryFromQuery } from 'api/transactions/transactionsAPI';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';
import { WireTransactionSenderData } from 'components/Additional/DetailsSections/Accounting/WireTransaction/Sender';
import { InternalTransactionSenderData } from 'components/Additional/DetailsSections/Accounting/InternalTransaction/Sender';
import {
  ClientTransactionTypes,
  TransactionReviewStatuses,
} from 'enums/banking/transactions';
import {
  TransactionData,
  TransactionTypes,
} from 'components/Modals/TemplateModalDialogs/Accounting/Transfers/ClientTransferModal/InnerContent';

function getTransactionTypeByJournalEntry(
  data: JournalEntryFromQuery,
): TransactionTypes | null {
  switch (data.sourceType) {
    case ClientTransactionTypes.InternalTransfer:
      return 'internal-sender';

    case ClientTransactionTypes.WireTransfer:
      return 'wire-sender';

    default:
      return null;
  }
}

function getWireTransferTemplateData(data: JournalEntryFromQuery) {
  const { beneficiaryCustomer, templateId, templateName } = data;

  return {
    id: templateId,
    templateName,
    beneficiaryAccountName: beneficiaryCustomer.accountName,
    beneficiaryAccountNumber: beneficiaryCustomer.accountNumber,
    beneficiaryCountry: beneficiaryCustomer.countryCode
      ? COUNTRY_CODES[beneficiaryCustomer.countryCode]
      : '',
    beneficiaryAddress: beneficiaryCustomer.address1,
    beneficiaryCity: beneficiaryCustomer.city,
    beneficiaryDistrict: CountryHelpers.getDistrict(
      beneficiaryCustomer.countryCode,
      beneficiaryCustomer.district,
    ),
    beneficiaryPostCode: beneficiaryCustomer.postCode,
    beneficiaryBankCode: beneficiaryCustomer.accountWithInstitution?.code || '',
    beneficiaryBankName: beneficiaryCustomer.accountWithInstitution?.name || '',
    beneficiaryBankCodeTypeName:
      beneficiaryCustomer.accountWithInstitution?.codeType || '',
    beneficiaryBankAddress:
      beneficiaryCustomer.accountWithInstitution?.address1 || '',
    beneficiaryBankCity: beneficiaryCustomer.accountWithInstitution?.city || '',
    beneficiaryBankPostCode:
      beneficiaryCustomer.accountWithInstitution?.postCode || '',
    beneficiaryBankCountry: beneficiaryCustomer.accountWithInstitution
      ?.countryCode
      ? COUNTRY_CODES[beneficiaryCustomer.accountWithInstitution?.countryCode]
      : '',
    beneficiaryBankDistrict: CountryHelpers.getDistrict(
      beneficiaryCustomer.accountWithInstitution?.countryCode || '',
      beneficiaryCustomer.accountWithInstitution?.district || '',
    ),
    intermediaryBankCode:
      beneficiaryCustomer.intermediaryInstitution?.code || '',
    intermediaryBankName:
      beneficiaryCustomer.intermediaryInstitution?.name || '',
    intermediaryBankCodeTypeName:
      beneficiaryCustomer.intermediaryInstitution?.codeType || '',
    intermediaryBankAddress:
      beneficiaryCustomer.intermediaryInstitution?.address1 || '',
    intermediaryBankCity:
      beneficiaryCustomer.intermediaryInstitution?.city || '',
    intermediaryBankPostCode:
      beneficiaryCustomer.intermediaryInstitution?.postCode || '',
    intermediaryBankDistrict: CountryHelpers.getDistrict(
      beneficiaryCustomer.intermediaryInstitution?.countryCode || '',
      beneficiaryCustomer.intermediaryInstitution?.district || '',
    ),
    intermediaryBankCountry: beneficiaryCustomer.intermediaryInstitution
      ?.countryCode
      ? COUNTRY_CODES[beneficiaryCustomer.intermediaryInstitution?.countryCode]
      : '',
    reference: beneficiaryCustomer.beneficiaryReference,
  };
}

function getFormattedTransaction(
  type: TransactionTypes,
  data: JournalEntryFromQuery,
): InternalTransactionSenderData | WireTransactionSenderData | null {
  switch (type) {
    case 'internal-sender': {
      const {
        sourceType,
        purpose,
        orderingCustomer,
        beneficiaryCustomer,
        currencyIsoCode,
        amount,
        postingDate,
        valueDate,
        requestedByName,
        statusId,
        templateId,
        templateName,
        relatedDocuments,
        workflowExpirationDate,
        transactionNumber,
      } = data;
      const address = [
        orderingCustomer.address1,
        orderingCustomer.address2,
        orderingCustomer.address3,
      ]
        .filter(Boolean)
        .join(', ');

      return {
        purpose,
        type: sourceType,
        workflowExpirationDate,
        status: statusId,
        amount: `${currencyIsoCode} ${AccountsHelpers.convertAmountBigIntToLocaleString(amount)}`,
        valueDate: DateHelpers.formatTimestampToString(valueDate),
        postingDate: DateHelpers.formatTimestampToString(
          postingDate,
          DEFAULT_DATE_TIME_FORMAT,
        ),
        requestedBy: requestedByName,
        template: templateId ? { id: templateId, name: templateName } : null,
        relatedDocuments: relatedDocuments,
        transactionNumber,
        orderingCustomer: {
          address,
          accountName: orderingCustomer.accountName,
          accountNumber: orderingCustomer.accountNumber,
          reference: orderingCustomer.senderReference,
        },
        beneficiary: {
          accountName: beneficiaryCustomer.accountName,
          accountNumber: beneficiaryCustomer.accountNumber,
          reference: beneficiaryCustomer.beneficiaryReference,
        },
      };
    }

    case 'wire-sender': {
      const {
        amount,
        purpose,
        statusId,
        valueDate,
        sourceType,
        postingDate,
        requestedByName,
        currencyIsoCode,
        orderingCustomer,
        relatedDocuments,
        transactionNumber,
        workflowExpirationDate,
        senderReference,
      } = data;
      const address = [
        orderingCustomer.address1,
        orderingCustomer.address2,
        orderingCustomer.address3,
      ]
        .filter(Boolean)
        .join(', ');

      return {
        purpose,
        type: sourceType,
        status: statusId,
        amount: `${currencyIsoCode} ${AccountsHelpers.convertAmountBigIntToLocaleString(amount)}`,
        valueDate: DateHelpers.formatTimestampToString(valueDate),
        postingDate: DateHelpers.formatTimestampToString(
          postingDate,
          DEFAULT_DATE_TIME_FORMAT,
        ),
        requestedBy: requestedByName,
        relatedDocuments: relatedDocuments,
        template: getWireTransferTemplateData(data),
        workflowExpirationDate,
        transactionNumber,
        senderReference,
        orderingCustomer: {
          address,
          accountName: orderingCustomer.accountName,
          accountNumber: orderingCustomer.accountNumber,
          reference: orderingCustomer.senderReference,
        },
      } as WireTransactionSenderData;
    }

    default:
      return null;
  }
}

const transactionsAPIAdapter = {
  formatJournalEntryForTransactionDetailsModal: (
    data: JournalEntryFromQuery,
  ): TransactionData => {
    const type = getTransactionTypeByJournalEntry(data);
    const transaction = type ? getFormattedTransaction(type, data) : null;

    return {
      type,
      transaction,
      sourceType: data.sourceType,
      requestorId: data.requestedById,
      transactionStatus: data.statusId as TransactionReviewStatuses,
      transactionId: null,
      journalEntryId: null,
      approvalWorkflowId: null,
      transactionApprovals: null,
    };
  },
};

export { transactionsAPIAdapter };
