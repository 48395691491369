import React from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import objectsAPI from '../../../../../api/orac/objectsAPI';

// components
import ObjectActionsTable from '../../../../../components/Tables/TableTemplates/ORAC/ObjectActionsTable';

interface IProps {
  objectId: string;
}

const ObjectActions = ({ objectId }: IProps) => {
  const { response, loading } = useFetch(
    () => objectsAPI.fetchActionsByObjectId(objectId),
    [objectId],
  );

  return (
    <ObjectActionsTable
      data={response?.data || []}
      loading={loading}
      total={response?.total || 0}
    />
  );
};

export default ObjectActions;
