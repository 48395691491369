import React, { ReactNode } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { OnboardType } from 'enums/crm/crm';
import { FormikHelpers } from 'formik';
import { EditNodeFormValidationSchema } from 'validations/crm/clientGroups';
import { FormValuesModel as ContactInfoFormValuesModel } from '../../../../Forms/TemplateForms/CRM/ContactShortInfoForm';
import { FormValuesModel as OrganizationInfoFormValuesModel } from '../../../../Forms/TemplateForms/CRM/OrganizationShortInfoForm';
import {
  ParentNodeOfRelationshipModel,
  FormValuesModel as RelationshipFormValuesModel,
} from 'components/Forms/TemplateForms/CRM/ClientRelationshipForm';

// components
import InnerForm from './InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export type FormValuesModel = {
  clientGroupId: string;
  activeScopeId: string;
  keyRelationshipTemplateId: string;
  clientUserId: string | null;
  entryType: OnboardType | null;
  clientGroupEntryType: OnboardType;
  contact: ContactInfoFormValuesModel | null;
  organization: OrganizationInfoFormValuesModel | null;
  relationships: RelationshipFormValuesModel[];
  parentForNewRelationships: ParentNodeOfRelationshipModel;
  hideRelationshipsSection?: boolean;
  canAddOwnerships: boolean;
  disableNodeForm?: boolean;
};

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string | ReactNode;

  disabled?: boolean;
}

const EditNodeModalDialog = ({
  isVisible,
  initialValues,
  title,
  closeCallback,
  onSubmit,
  disabled,
}: IProps) => {
  const { t } = useTranslation('server_errors');

  const handleSubmit = async (
    values: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    if (onSubmit) {
      await onSubmit(values, formikHelpers);
    }
  };

  const handleOnSubmitError = (
    error: ErrorFromServer,
    _: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>,
  ) => {
    const errorCode = ErrorHandlerService.getErrorCodeFromError(error);
    switch (errorCode) {
      case '1001009': {
        formikHelpers.setFieldError('contact.email', t('1001009'));
        break;
      }
      case '1016010': {
        formikHelpers.setFieldError('contact.email', t('1016010'));
        break;
      }
      case '1001010': {
        formikHelpers.setFieldError('contact.phone', t('1001010'));
        break;
      }
      case '1001002': {
        formikHelpers.setFieldError('contact.email', t('1001002'));
        break;
      }
      default: {
        ErrorHandlerService.handleError(error);
        break;
      }
    }
  };

  return (
    <Form
      enableReinitialize
      disabled={disabled}
      onSubmit={handleSubmit}
      onSubmitError={handleOnSubmitError}
      initialValues={isVisible ? initialValues : null}
      validationSchema={EditNodeFormValidationSchema}
      renderForm={() => (
        <ModalDialog
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          closeModalAfterError={false}
          hideFooterButtons={disabled}
        >
          <InnerForm />
        </ModalDialog>
      )}
    />
  );
};

export default EditNodeModalDialog;
