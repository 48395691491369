import React from 'react';

// helpers utils
import styled from 'styled-components';
import useTranslation from '../../../hooks/useTranslation';
import { changeView } from '../../../redux/actions/view';
import { RoutePaths } from '../../../routes/routes';
import { useDispatch } from 'react-redux';
import { MenuItemModel } from '../../../hooks/useSidebarMenu';
import { getDefaultOpenedKeys } from '../../../helpers/utils';
import { useHistory, useLocation, matchPath } from 'react-router-dom';

// components
import Link from '@common_components/Texts/Link';
import { Divider, Menu, Typography } from 'antd';

interface IProps {
  menuItems: MenuItemModel[];
}

const Sidebar = ({ menuItems = [] }: IProps) => {
  const { t } = useTranslation('page_titles');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const historyPush = (path: any, state?: any) =>
    dispatch(changeView(() => history.push(path, state)));

  const renderMenuItems = () => menuItems.map((e) => getMenuItem(e));

  const isMyProfileSection = (pathName: string) => {
    return pathName.includes(RoutePaths.Profile_Root);
  };

  const getMenuItem = (menuItem: MenuItemModel) => {
    const { title, path, subItems, disabled } = menuItem;

    if (!menuItem.subItems.length) {
      return (
        <StyledMenuItem
          key={path}
          disabled={disabled}
          onClick={() => historyPush(path)}
        >
          <StyledLink
            href={path}
            onClick={(e) => {
              e.preventDefault();
              dispatch(changeView(() => history.push(path)));
            }}
          >
            {t(`${title}`)}
          </StyledLink>
        </StyledMenuItem>
      );
    }

    return (
      <StyledMenuSubMenu
        key={path}
        disabled={disabled}
        className={
          matchPath(location.pathname, { path }) ? 'menu-submenu-selected' : ''
        }
        title={
          <StyledLink
            href={path}
            onClick={(e) => {
              e.preventDefault();
              dispatch(changeView(() => history.push(path)));
            }}
          >
            {t(`${title}`)}
          </StyledLink>
        }
      >
        {subItems.map((e) => getMenuItem(e))}
      </StyledMenuSubMenu>
    );
  };

  return (
    <MenuWrapper>
      {isMyProfileSection(location.pathname) && (
        <>
          <SidebarHeading strong>{t('My profile')}</SidebarHeading>
          <StyledDivider />
        </>
      )}

      <Menu
        mode="inline"
        selectedKeys={getDefaultOpenedKeys(location.pathname)}
        defaultOpenKeys={getDefaultOpenedKeys(location.pathname)}
      >
        {renderMenuItems()}
      </Menu>
    </MenuWrapper>
  );
};

const SidebarHeading = styled(Typography.Paragraph)`
  margin-bottom: 0px !important;
  font-size: ${({ theme }) => theme.fontSizeSm};
  padding-left: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

const MenuWrapper = styled.div`
  padding: ${({ theme }) => theme.paddingXl} ${({ theme }) => theme.paddingSm};
  margin: ${({ theme }) => theme.containerPadding};
  background-color: ${({ theme }) => theme.sidebarBackground};
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  min-height: 95%;
  width: auto;
`;

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => `${theme.marginXs} 0px`};
`;

const StyledMenuSubMenu = styled(Menu.SubMenu)<any>`
  &.ant-menu-submenu a {
    color: ${({ theme }) => theme.colorLight} !important;
  }

  &.menu-submenu-selected a {
    color: ${({ theme }) => theme.primaryGreen} !important;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  overflow: unset !important;

  &.ant-menu-submenu a {
    color: ${({ theme }) => theme.colorLight} !important;
  }

  &.ant-menu-item a {
    color: ${({ theme }) => theme.colorLight} !important;
    &:hover {
      color: ${({ theme }) => theme.primaryGreen} !important;
      filter: none;
    }
  }

  &.ant-menu-item-selected a {
    color: ${({ theme }) => theme.primaryGreen} !important;
  }

  &.menu-submenu-selected a {
    color: ${({ theme }) => theme.primaryGreen} !important;
  }
`;

const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizeXXs};
`;

export default Sidebar;
