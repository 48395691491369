import React, { useState } from 'react';

// helpers
import useFetch from '../../../hooks/useFetch';
import reviewConfigurationAPI from '../../../api/compliance/reviewConfigurationAPI';
import { DEFAULT_TABLE_LIMIT } from '../../../constants/global';
import { ConfigurationRuleModel } from '../../../typings/compliance/configurationRules';

// components
import FilterBar from './FilterBar';
import ViewEditReviewConfigurationRuleModal from './ViewEditReviewConfigurationRuleModal';
import ReviewConfigurationRulesTable, {
  TableActions,
} from '../../../components/Tables/TableTemplates/Compliance/ReviewConfigurationRulesTable';
import { Divider } from 'antd';

export type FilterOptionTypes =
  | 'review_type'
  | 'risk_level'
  | 'reference_object';

interface IProps {
  updateTableTrigger: unknown;
}

const ReviewConfiguration = ({ updateTableTrigger }: IProps) => {
  const [current, setCurrentPage] = useState(1);
  const [selectedRuleId, setSelectedRuleId] = useState<string | null>(null);
  const [localUpdateTableTrigger, updateTable] = useState<unknown>();
  const [filterValues, setFilterValues] = useState<{
    referenceObjectId: string | null;
    reviewType: string | null;
    riskLevel: string | null;
  }>({
    referenceObjectId: null,
    riskLevel: null,
    reviewType: null,
  });

  const { response, loading } = useFetch(
    () =>
      reviewConfigurationAPI.fetchConfigurationRules({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        riskLevel:
          filterValues.riskLevel === null ? null : filterValues.riskLevel,
        reviewType:
          filterValues.reviewType === null ? null : filterValues.reviewType,
        referenceObjectId:
          filterValues.referenceObjectId === null
            ? null
            : filterValues.referenceObjectId,
      }),
    [current, filterValues, updateTableTrigger, localUpdateTableTrigger],
  );

  const handleFilterChange = (key: FilterOptionTypes, newValue?: string) => {
    if (current > 1) {
      setCurrentPage(1);
    }

    const filterValuesCopy = { ...filterValues };

    // We need to do this because enums in filter bar starts from 0
    const formattedNewValue = typeof newValue === 'undefined' ? null : newValue;

    switch (key) {
      case 'reference_object':
        filterValuesCopy.referenceObjectId = formattedNewValue;
        break;

      case 'review_type':
        filterValuesCopy.reviewType = formattedNewValue;
        break;

      case 'risk_level':
        filterValuesCopy.riskLevel = formattedNewValue;
        break;
    }

    setFilterValues(filterValuesCopy);
  };

  const handleActionsClick = (
    key: TableActions,
    record: ConfigurationRuleModel,
  ) => {
    if (key === 'edit' || key === 'view') {
      setSelectedRuleId(record.id || null);
    }
  };

  const handleEditModalClose = (wasAdded?: boolean) => {
    if (wasAdded) {
      updateTable({});
    }

    setSelectedRuleId(null);
  };

  return (
    <>
      <FilterBar onFilterChange={handleFilterChange} />

      <Divider />

      <ReviewConfigurationRulesTable
        loading={loading}
        current={current}
        data={response?.data || []}
        total={response?.total || 0}
        onPaginationChange={setCurrentPage}
        onActionsClick={handleActionsClick}
      />

      <ViewEditReviewConfigurationRuleModal
        isVisible={!!selectedRuleId}
        closeCallback={handleEditModalClose}
        ruleId={selectedRuleId}
      />
    </>
  );
};

export default ReviewConfiguration;
