import * as yup from 'yup';
import { STRING_VALIDATION_RULE } from 'validations/common';

export const NewUserValidationSchema = yup.object().shape({
  type: STRING_VALIDATION_RULE.required('This field is required'),
  contactId: STRING_VALIDATION_RULE.required('This field is required'),
  firstName: STRING_VALIDATION_RULE.max(200, 'First name is too long'),
  lastName: STRING_VALIDATION_RULE.max(200, 'Last name is too long'),
  email: STRING_VALIDATION_RULE.email('Wrong email').required(
    'This field is required',
  ),
  phoneNumber: STRING_VALIDATION_RULE.required('This field is required'),
});

export const UpdateUserValidationSchema = yup.object().shape({
  name: STRING_VALIDATION_RULE.max(200, 'First name is too long').required(
    'This field is required',
  ),
});
