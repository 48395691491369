import React, { memo, useMemo } from 'react';

// constants
import { ContextBaseModel } from '../../../../../typings/ORAC/contexts';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import TableActions, { ITableMenuItem } from '../../../TableActions';

interface IProps extends RequiredPropsForTableModel<ContextBaseModel> {
  onActionsClick?: (key: string, value: string) => void;
  actions?: ITableMenuItem[];
}

const ContextsTable = memo(({ actions, onActionsClick, ...rest }: IProps) => {
  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        width: '180px',
        title: 'Context ID',
        key: 'context_id',
        render: (record: ContextBaseModel) => record.id,
      },

      {
        title: 'Name',
        key: 'name',
        render: (record: ContextBaseModel) => record.name,
      },

      {
        title: 'Type',
        key: 'type',
        render: (record: ContextBaseModel) => record.type,
      },
      {
        key: 'actions',
        hidden: !actions?.length,
        align: 'right',
        render: (record: ContextBaseModel) =>
          actions && (
            <TableActions
              menu={actions}
              onSelect={(key) =>
                onActionsClick && onActionsClick(key, record.id)
              }
            />
          ),
      },
    ];

    return result;
  }, [actions, onActionsClick]);

  return <Table {...rest} columns={columns} />;
});

export default ContextsTable;
