import React, { memo, useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { IAccountCategoryDetails } from '../../../../../typings/finance/accountCategories';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';

type IProps = RequiredPropsForTableModel<IAccountCategoryDetails>;

const AccountCategoryTable = memo((props: IProps) => {
  const { t } = useTranslation('finance');

  const columns = useMemo(() => {
    const result: TableColumnModel[] = [
      {
        title: t('account_categories.table.account_category_name'),
        width: '40%',
        key: 'accountCategoryName',
        render: (record: IAccountCategoryDetails) => (
          <AccountCategoryWrapper>
            {record.accountCategoryName}
          </AccountCategoryWrapper>
        ),
      },
      {
        title: t('account_categories.table.parent_category'),
        width: '40%',
        key: 'parentCategoryName',
        render: (record: IAccountCategoryDetails) => record.parentCategoryName,
      },
      {
        title: t('account_categories.table.financial_type'),
        width: '20%',
        key: 'financialTypeName',
        render: (record: IAccountCategoryDetails) => record.financialTypeName,
      },
    ];

    return result;
  }, [t]);

  return (
    <Table
      {...props}
      columns={columns}
      expandable={{ childrenColumnName: 'noChildren' }}
    />
  );
});

const AccountCategoryWrapper = styled.div`
  display: inline-block;
  top: -1px;
  position: relative;
`;

export default AccountCategoryTable;
