import React, { memo, useMemo } from 'react';

// helpers
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export interface OwnershipAdditionalFieldFormSelectProps extends SelectProps {
  additionalFields: { id: string; name: string }[];
}

const OwnershipAdditionalFieldFormSelect = memo(
  ({ additionalFields, ...rest }: OwnershipAdditionalFieldFormSelectProps) => {
    const options = useMemo<SelectOption[]>(() => {
      return additionalFields.map((e) => ({
        id: e.name,
        label: e.name,
      }));
    }, [additionalFields]);

    return <FormSelect {...rest} options={options} />;
  },
);

export default OwnershipAdditionalFieldFormSelect;
