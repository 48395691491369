import { DateHelpers } from '@helpers/date';
import { ForgotUsernameRequest } from 'components/Tables/TableTemplates/ORAC/ForgotUsernameRequestsTable';
import { DEFAULT_DATE_TIME_FORMAT } from 'constants/global';
import { ForgotUsernameRequestFromQuery } from 'api/orac/forgotUsernameRequestsAPI';

export const forgotUsernameRequestAdapter = {
  formatRequestsToTableFormat: (
    data: ForgotUsernameRequestFromQuery[],
  ): ForgotUsernameRequest[] => {
    return data.map((e) => ({
      id: e.id,
      user: e.name,
      email: e.email,
      clientGroup: e.clientGroupName,
      status: e.status,
      dateOfBirth: DateHelpers.formatDateToString(
        DateHelpers.formatDateToUTC(e.dateOfBirth),
      ),
      creationDate: DateHelpers.formatDateToString(
        e.createdAt,
        DEFAULT_DATE_TIME_FORMAT,
      ),
    }));
  },
};
