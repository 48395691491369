import { UserPermissionModel } from '../../typings/profile/profile';

// Actions
export enum ActionType {
  INIT_USER_PERMISSIONS = 'userAccess/INIT_USER_PERMISSIONS',
}

// Action creators
export const initUserPermissions = (
  userPermissionsList: UserPermissionModel[],
) => ({
  type: ActionType.INIT_USER_PERMISSIONS,
  payload: { userPermissionsList },
});
