import React, { memo, useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { useHistory } from 'react-router-dom';
import { DateHelpers } from '@helpers/date';
import {
  RoleBaseModel,
  RoleWithGroupsInfoModel,
} from '../../../../../typings/ORAC/roles';

// constants
import { RoutePaths } from '../../../../../routes/routes';
import { DEFAULT_DATE_TIME_FORMAT } from '../../../../../constants/global';

// components
import Table, {
  RequiredPropsForTableModel,
  TableColumnModel,
} from '@core_components/Table';
import TableActions, { ITableMenuItem } from '../../../TableActions';
import { Typography } from 'antd';

interface IProps extends RequiredPropsForTableModel<RoleBaseModel> {
  showInheritedColumn?: boolean;
  onActionsClick?: (key: string, value: string) => void;
  actions?: ITableMenuItem[];
}

const RolesTable = memo(
  ({
    actions,
    onActionsClick,
    showInheritedColumn = false,
    ...rest
  }: IProps) => {
    const { t } = useTranslation('orac');

    const history = useHistory();

    const renderInheritedFrom = ({ groups }: RoleWithGroupsInfoModel) => {
      let result = null;

      if (groups && groups.length) {
        const groupsArray = groups.map(({ id, name }) => (
          <Typography.Link
            key={id}
            onClick={() =>
              history.push({
                pathname: RoutePaths.ORAC_Group_Details,
                search: `?id=${id}`,
              })
            }
          >
            {`${name}  `}
          </Typography.Link>
        ));

        result = groupsArray;
      }

      return result;
    };

    const columns = useMemo(() => {
      const result: TableColumnModel[] = [
        {
          title: t('roles.table.name'),
          key: 'name',
          width: 350,
          render: (record: RoleBaseModel) => (
            <Typography.Link
              onClick={() =>
                history.push({
                  pathname: RoutePaths.ORAC_Role_Details,
                  search: `?id=${record.id}`,
                })
              }
            >
              {record.name}
            </Typography.Link>
          ),
        },

        {
          width: 350,
          title: t('roles.table.description'),
          key: 'description',
          render: (record: RoleBaseModel) => record.description,
        },

        {
          width: 350,
          hidden: !showInheritedColumn,
          title: t('roles.table.inherited_from_title'),
          key: 'description',
          render: renderInheritedFrom,
        },

        {
          title: t('roles.table.created_at'),
          key: 'createdAt',
          width: 350,
          render: (record: RoleBaseModel) =>
            DateHelpers.formatDateToString(
              record.createdAt,
              DEFAULT_DATE_TIME_FORMAT,
            ),
        },

        {
          hidden: !actions?.length,
          key: 'actions',
          align: 'right',
          render: (record: RoleBaseModel) => (
            <TableActions
              menu={actions || []}
              onSelect={(key) =>
                onActionsClick && onActionsClick(key, record.id)
              }
            />
          ),
        },
      ];

      return result;
    }, [actions, showInheritedColumn]);

    return <Table {...rest} columns={columns} />;
  },
);

export default RolesTable;
