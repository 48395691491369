import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { approvalWorkflowAPI } from '../../../../api/approval/approvalManagement/approvalWorkflowAPI';
import { ApprovalWorkflowInstanceModel } from '../../../../typings/approval/approvalWorkflow';

// components
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';
import LoaderSection from '../../../../components/Loaders/LoaderSection';
import InstanceDetails from './InstanceDetails';

interface IProps extends RequiredPropsForModalDialogModel {
  updateTable: (wasUpdated: any) => void;
  instanceId: string;
}

const ApprovalWorkflowDetailsModal = ({
  instanceId,
  isVisible,
  updateTable,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('approval_management');
  const [updateDataTrigger, updateData] = useState({});

  const { response, loading } = useFetch(
    () =>
      instanceId
        ? approvalWorkflowAPI.fetchApprovalWorkflowInstanceById(instanceId)
        : null,
    [instanceId, updateDataTrigger],
  );

  const handleUpdateData = () => {
    updateData({});
    updateTable({});
  };

  return (
    <ModalDialog
      title={t('approval_workflow.approval_entry_details')}
      isVisible={isVisible}
      closeCallback={closeCallback}
      hideFooterButtons
    >
      {loading ? (
        <LoaderSection />
      ) : (
        <InstanceDetails
          updateData={handleUpdateData}
          approvalInstance={response as ApprovalWorkflowInstanceModel}
        />
      )}
    </ModalDialog>
  );
};

export default ApprovalWorkflowDetailsModal;
