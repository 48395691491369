import React from 'react';

// Helpers & Utils
import { SelectProps } from 'antd/lib/select';
import useTranslation from '../../../../hooks/useTranslation';
import { UserTypes } from '../../../../enums/admin/orac';

// Components
import { Select } from 'antd';

type IProps = SelectProps<any>;

const UserTypeSelect = (props: IProps) => {
  const { t } = useTranslation('orac');

  const renderOptions = () => {
    return Object.values(UserTypes).map((key) => (
      <Select.Option key={key} value={key}>
        {t(`users.types.${key}`)}
      </Select.Option>
    ));
  };

  return <Select {...props}>{renderOptions()}</Select>;
};

export default UserTypeSelect;
