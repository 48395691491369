import React, { useState } from 'react';

// helpers
import useFetch from '../../../../hooks/useFetch';
import { notificationsAPI } from '../../../../api/orac/notificationsAPI';
import { FetchResponseModel } from '../../../../typings/common';
import { NotificationMethods } from '../../../../enums/admin/orac';
import { DEFAULT_TABLE_LIMIT } from '../../../../constants/global';
import { NotificationsBaseModel } from '../../../../typings/ORAC/notifications';

// components
import FilterBar from './FilterBar';
import { Divider } from 'antd';
import { default as TemplateNotificationsTable } from '../../../../components/Tables/TableTemplates/ORAC/NotificationsTable/index';

const NotificationsTable = () => {
  const [current, setCurrentPage] = useState(1);

  const [notificationFilter, setNotificationFilter] = useState({
    notificationId: undefined,
    notificationType: NotificationMethods.Email,
  });

  const { response, loading, error } = useFetch<
    FetchResponseModel<NotificationsBaseModel>
  >(
    () =>
      notificationsAPI.fetchNotifications({
        page: current,
        limit: DEFAULT_TABLE_LIMIT,
        notificationId: notificationFilter.notificationId,
        notificationType: notificationFilter.notificationType,
      }),
    [current, notificationFilter],
  );

  const handleFilterBarChange = (key: string, value: any) => {
    if (current > 1) {
      setCurrentPage(1);
    }

    switch (key) {
      case 'search_by_name':
        setNotificationFilter((prevState) => ({
          ...prevState,
          notificationId: value,
        }));

        break;

      case 'filter_by_type':
        setNotificationFilter((prevState) => ({
          ...prevState,
          notificationType: value as NotificationMethods,
        }));
        break;
    }
  };

  return (
    <>
      <FilterBar
        filterStatus={notificationFilter.notificationType}
        onFilterChange={handleFilterBarChange}
      />
      <Divider />
      <TemplateNotificationsTable
        data={error ? [] : response?.data || []}
        total={error ? 0 : response?.total || 0}
        loading={loading}
        current={current}
        filterStatus={notificationFilter.notificationType}
        onPaginationChange={setCurrentPage}
      />
    </>
  );
};

export default NotificationsTable;
