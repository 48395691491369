import { userAPI } from '../../api/auth/userAPI';
import { profileAPI } from '../../api/profile/profileAPI';
import { AuthHelpers } from '@helpers/auth';
import { initUserPermissions } from './userAccess';
import { LocalStorageHelpers } from '@helpers/storages/localStorage';
import { setInitializedStatus } from './app';
import { TwoFactorVerificationDataModel } from '../reducers/auth';

// Actions
export enum ActionType {
  PROFILE_GET_START = 'auth/PROFILE_GET_START',
  PROFILE_GET_SUCCESS = 'auth/PROFILE_GET_SUCCESS',
  PROFILE_GET_ERROR = 'auth/PROFILE_GET_ERROR',
  SET_AUTH_STATUS = 'auth/SET_AUTH_STATUS',
  SET_AUTHENTICATOR_ENABLED_STATUS = 'auth/SET_AUTHENTICATOR_ENABLED_STATUS',
  SET_SESSION_DURATION = 'auth/SET_SESSION_DURATION',
  SET_FIDO_AUTHENTICATOR_ENABLED_STATUS = 'auth/SET_FIDO_AUTHENTICATOR_ENABLED_STATUS',
  SET_TWO_FACTOR_AUTHENTICATION_DATA = 'auth/SET_TWO_FACTOR_AUTHENTICATION_DATA',
}

// Action creators
export const setSessionDuration = (seconds: number) => ({
  type: ActionType.SET_SESSION_DURATION,
  payload: { seconds },
});

export const setFidoAuthenticatorEnabledStatus = (isEnabled: boolean) => ({
  type: ActionType.SET_FIDO_AUTHENTICATOR_ENABLED_STATUS,
  payload: { isEnabled },
});

export const setTwoFactorVerificationData = (
  data: TwoFactorVerificationDataModel | null,
) => ({
  type: ActionType.SET_TWO_FACTOR_AUTHENTICATION_DATA,
  payload: { data },
});

export const signOut = (skipAPICall?: boolean) => async (dispatch: any) => {
  dispatch(setInitializedStatus(false));

  if (!skipAPICall) {
    const token = LocalStorageHelpers.getAccessToken();
    const isTokenAlive = AuthHelpers.isAccessTokenAlive(token);

    if (token && isTokenAlive) {
      try {
        await userAPI.signOut();
      } catch {
        // TODO: handle error
      }
    }
  }

  LocalStorageHelpers.clear();
  dispatch({
    type: ActionType.SET_AUTH_STATUS,
    payload: false,
    key: 'isAuthorized',
  });
  dispatch({
    type: ActionType.SET_AUTH_STATUS,
    payload: null,
    key: 'profileData',
  });

  dispatch(setInitializedStatus(true));
};

export const getMe = () => async (dispatch: any) => {
  try {
    dispatch({
      type: ActionType.PROFILE_GET_START,
      payload: true,
      key: 'profileData',
    });

    const response = await profileAPI.getMe();

    dispatch(initUserPermissions(response.permissions));

    dispatch({
      type: ActionType.PROFILE_GET_SUCCESS,
      payload: response.user,
      key: 'profileData',
    });
  } catch (error) {
    dispatch({
      type: ActionType.PROFILE_GET_ERROR,
      payload: true,
      key: 'profileData',
    });

    throw error;
  }
};
