import React from 'react';

// helpers
import useFetch from '../../../../../../hooks/useFetch';
import { approvalWorkflowAPI } from '../../../../../../api/approval/approvalManagement/approvalWorkflowAPI';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';

// components
import ApprovalMatrixModal from '../../../../../Modals/TemplateModalDialogs/Compliance/ApprovalMatrixModal';

interface IProps extends RequiredPropsForModalDialogModel {
  instanceId: string;
}

const ViewApprovalMatrixModal = ({
  instanceId,
  isVisible,
  closeCallback,
}: IProps) => {
  const { response, loading } = useFetch(
    () =>
      instanceId && isVisible
        ? approvalWorkflowAPI.fetchApprovalWorkflowInstanceById(instanceId)
        : null,
    [instanceId, isVisible],
  );

  return (
    <ApprovalMatrixModal
      data={response}
      loading={loading}
      isVisible={isVisible}
      closeCallback={closeCallback}
    />
  );
};

export default ViewApprovalMatrixModal;
