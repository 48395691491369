import React, { memo, useCallback, useState } from 'react';

// helpers
import useFetch from '@hooks/useFetch';
import useTranslation from '@hooks/useTranslation';
import { RoutePaths } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import {
  ThreadWithNewMessagesFromQuery,
  messagingAPI,
} from 'api/messaging/messagingAPI';

// components
import SectionIntro from '@common_components/Texts/SectionIntro';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import NewMessagesBadge from 'components/Additional/Messaging/NewMessagesBadge';
import {
  default as NewMessagesTemplateTable,
  ActionKeys,
} from '../../../../components/Tables/TableTemplates/Messages/NewMessagesTable';

const NewMessagesTable = memo(() => {
  const history = useHistory();
  const { t } = useTranslation('crm');
  const [page, setPage] = useState(1);
  const { response, loading } = useFetch(
    () => messagingAPI.fetchNewMessages({ page, limit: 5 }),
    [page],
  );

  const handleActionsClick = useCallback(
    (key: ActionKeys, record: ThreadWithNewMessagesFromQuery) => {
      switch (key) {
        case 'client_group_view':
          {
            history.push({
              pathname: RoutePaths.CRM_Client_Group_Details,
              search: `?id=${record.clientGroupId}&tab=messaging`,
            });
          }
          break;
      }
    },
    [],
  );

  return (
    <>
      <SectionIntro
        titleVariant="h5"
        title={
          <DivAlignCenter>
            {t('messages.new_threads_dashboard.title')}{' '}
            {response?.total ? (
              <NewMessagesBadge count={response.total} />
            ) : null}
          </DivAlignCenter>
        }
      />

      <NewMessagesTemplateTable
        data={response?.data || []}
        total={response?.total || 0}
        current={page}
        loading={loading}
        onPaginationChange={setPage}
        onActionsClick={handleActionsClick}
      />
    </>
  );
});

export default NewMessagesTable;
