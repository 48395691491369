import * as Yup from 'yup';
import { CRM_NAME_REG } from 'constants/regularExpressions';
import { ContactHelpers } from '@helpers/crm/contact';

export const STRING_VALIDATION_RULE = Yup.string().trim();
export const NULLABLE_STRING_VALIDATION_RULE =
  STRING_VALIDATION_RULE.nullable();
export const NULLABLE_DATE_VALIDATION_RULE = Yup.date().nullable();
export const NULLABLE_NUMBER_VALIDATION_RULE = Yup.number().nullable();
export const NULLABLE_BOOLEAN_VALIDATION_RULE = Yup.boolean().nullable();
export const NULLABLE_ARRAY_VALIDATION_RULE = Yup.array().nullable();
export const NULLABLE_OBJECT_VALIDATION_RULE = Yup.object().nullable();
export const NOT_REQUIRED_BOOLEAN_VALIDATION_RULE = Yup.boolean().notRequired();

export const CRM_NAME_VALIDATION_RULE = STRING_VALIDATION_RULE.matches(
  CRM_NAME_REG,
  {
    message: { key: 'form:validation_messages.no_special_characters' },
  },
);

export const EMAIL_VALIDATION_RULE = STRING_VALIDATION_RULE.email({
  key: 'form:validation_messages.invalid_value_with_label',
  values: { label: 'common:email' },
});

export const PHONE_ASYNC_VALIDATION_RULE = STRING_VALIDATION_RULE.test(
  'async-validation',
  'custom_errors:validation_phone_number.invalid_phone_number',
  async function (value) {
    // Call the custom asynchronous validation function
    const errors = await ContactHelpers.validatePhoneNumbers([value as string]);

    if (errors) {
      return false;
    }
    return true;
  },
);
