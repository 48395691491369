import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CHANGE_VIEW_TYPES, changeView } from 'redux/actions/view';

const useActiveTab = <KeyTypes,>(defaultTabKey: KeyTypes) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState(defaultTabKey);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const existingTabKey = searchParams.get('tab');

    if (existingTabKey) {
      if (activeKey !== existingTabKey) {
        setActiveKey(existingTabKey as KeyTypes);
      }
    } else {
      searchParams.set('tab', defaultTabKey as string); // Set the defaultTabKey initially
      history.replace({ search: searchParams.toString() });
    }
  }, [defaultTabKey, history, location.search]);

  const handleTabChange = (newKey: KeyTypes) => {
    dispatch(
      changeView(() => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('tab', newKey as string);
        history.replace({ search: searchParams.toString() });
        setActiveKey(newKey as KeyTypes);
      }, CHANGE_VIEW_TYPES.TABS),
    );
  };

  return { activeKey, setActiveKey: handleTabChange };
};

export default useActiveTab;
