import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import AdditionalFieldNew, {
  AdditionalFieldFormItemModel,
} from 'components/Forms/FormComponents/AdditionalFieldNew';
import { useField } from 'formik';
import { EntityHelpers } from '@helpers/crm/entity';
import { ContactHelpers } from '@helpers/crm/contact';
import { AutocompleteOption } from '@core_components/Autocomplete';
import { RelationshipHelpers } from '@helpers/crm/relationship';
import { IRelationshipTemplateModel } from '../../../../../typings/crm/relationship-template';
import {
  OnboardType,
  RelationshipTrackType,
} from '../../../../../enums/crm/crm';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import DeleteButton from '@common_components/Buttons/DeleteButton';
import SectionIntro from '../../../../DesignSystem/Common/Texts/SectionIntro';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import RelationshipTemplateSelect from '../../../FormComponents/SelectInputs/RelationshipTemplateSelect';
import ClientGroupMembersAutocomplete, {
  ClientGroupMemberModel,
} from 'components/Forms/FormComponents/Autocompletes/CRM/ClientGroupMemberAutocomplete';
import CannotDeleteOwnershipRelationshipTooltip from 'components/Tooltips/CannotDeleteOwnershipRelationshipTooltip';
import { Col, Row } from 'antd';

export type ParentNodeOfRelationshipModel = {
  id: string | null;
  type: OnboardType | null;
};

export type FormValuesModel = {
  customId: string;
  id: string | null;

  parent: ParentNodeOfRelationshipModel;

  child: {
    id: string | null;
    type: OnboardType | null;
    label: string;
    autocompleteInitialValue?: AutocompleteOption;
  };

  relationshipTemplate: {
    id: string | null;
    childRelationshipLabel: string;
  };

  additionalFields: AdditionalFieldFormItemModel[];

  isApplicantRelationship?: boolean;

  isOwnership?: boolean;
  disableAdditionalFields?: boolean;
};

interface IProps {
  entryId: string;
  entryType: OnboardType;
  fieldName: string;
  relationshipScopeId: string;
  relationshipIndex?: number;
  relationshipFromValue: string;
  removeCallback?: () => void;
}

const ClientRelationshipForm = ({
  entryId,
  entryType,
  relationshipScopeId,
  relationshipFromValue,
  fieldName,
  removeCallback,
  relationshipIndex,
}: IProps) => {
  const { t } = useTranslation('crm');
  const [field, , helpers] = useField<FormValuesModel>(fieldName);

  const isNewRelationship = !field.value.id;

  const handleChildSelect = (
    newValue: string,
    item: ClientGroupMemberModel,
  ) => {
    const label =
      item.type === OnboardType.Contact
        ? ContactHelpers.getContactName({
            firstName: item.contact?.firstName,
            lastName: item.contact?.lastName,
            middleName: item.contact?.middleName,
          })
        : EntityHelpers.getEntityName({
            names: item.organization?.names || [],
          });

    helpers.setValue({
      ...field.value,
      child: {
        ...field.value.child,
        id: newValue,
        type: item.type,
        label,
      },
      relationshipTemplate: {
        id: null,
        childRelationshipLabel: '',
      },
    });
  };

  const renderAdditionalForm = (
    additionalFields: AdditionalFieldFormItemModel[],
  ) => {
    return additionalFields.map((additionalField, index) => (
      <Col span="12" key={`${additionalField.name}-${index}`}>
        <FormField
          disabled={
            field.value.disableAdditionalFields || additionalField.isRemoved
          }
          label={
            <DivAlignCenter>
              {additionalField.name}
              {additionalField.isRemoved && (
                <StyledInfoTooltip
                  tooltipContent={t(
                    'client_group.structure.node_modal.relationships.removed_additional_field_info',
                  )}
                />
              )}
            </DivAlignCenter>
          }
          name={`${fieldName}.additionalFields.${index}.value`}
          component={AdditionalFieldNew}
          additionalProps={{
            field: {
              type: additionalField.type,
              options: additionalField.options.selectOptions,
              formDocument: additionalField.options.formDocument,
              documentAssociation: field.value.parent.id
                ? { id: field.value.parent.id, type: field.value.parent.type }
                : undefined,
            },
          }}
        />
      </Col>
    ));
  };

  const handleTemplateSelect = (
    newValue: any,
    relationshipTemplate: IRelationshipTemplateModel,
  ) => {
    if (relationshipTemplate) {
      const additionalFields = relationshipTemplate.additionalFields
        .filter(
          ({ relatedTo }) =>
            relatedTo === 'any' ||
            (relatedTo as any) === field.value.parent.type,
        )
        .map((e) => ({
          name: e.name,
          type: e.type,
          value: undefined,
          relatedTo: e.relatedTo,
          options: {
            onboardingRequired: e.options.onboardingRequired,
            isForRegulatedOnly: e.options.isForRegulatedOnly,
            documentRequiresCertification:
              e.options.documentRequiresCertification,
            selectOptions: e.options.selectOptions.length
              ? e.options.selectOptions.map((e) => JSON.parse(e.valueJSON))
              : [],
            formDocument: e.options.formDocument,
            isDirectClientOnly: e.options.isDirectClientOnly,
          },
          valueJSON: '',
          id: e._id as string,
        }));

      helpers.setValue({
        ...field.value,
        additionalFields,
        relationshipTemplate: {
          ...field.value.relationshipTemplate,
          id: newValue,
          childRelationshipLabel: RelationshipHelpers.getRelationshipName(
            {
              parentName: relationshipTemplate.parentName,
              childName: relationshipTemplate.childName,
            },
            RelationshipTrackType.Child,
          ),
        },
      });
    }
  };

  return (
    <>
      {!!relationshipIndex && (
        <SectionIntro
          titleVariant="h5"
          title={t(
            'entity.relationships.relationship_modal.relationship_title',
            { index: relationshipIndex },
          )}
          appendToTitle={
            removeCallback ? (
              field.value.isOwnership ? (
                <CannotDeleteOwnershipRelationshipTooltip />
              ) : (
                <HideIfDisabledForm hideIfSubmitting>
                  <StyledDeleteButton onClick={removeCallback} />
                </HideIfDisabledForm>
              )
            ) : null
          }
        />
      )}

      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FormField
            disabled={!isNewRelationship}
            labelCol={{ xl: 4, lg: 4, md: 5, sm: 24, xs: 24 }}
            label={t(
              'entity.relationships.relationship_modal.form_fields.child_node',
            )}
            name={`${fieldName}.child.id`}
            component={ClientGroupMembersAutocomplete}
            additionalProps={{
              clientGroupId: entryId,
              entryType,
              initialValue: field.value.child.autocompleteInitialValue,
              onSelect: (newValue: string, option: any) =>
                option.model && handleChildSelect(newValue, option.model),
            }}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <FormField
            disabled
            label={t(
              'entity.relationships.relationship_modal.form_fields.parent_node',
            )}
            name={`${fieldName}.parent.id`}
            component={FormInput}
            additionalProps={{ value: relationshipFromValue }}
            placeholder={t(
              'entity.relationships.relationship_modal.placeholder_optional',
            )}
          />
        </Col>

        <Col span={12}>
          <FormField
            disabled={
              !isNewRelationship ||
              !(field.value.parent.type && field.value.child.id)
            }
            label={t(
              'entity.relationships.relationship_modal.form_fields.parent_relationship_template',
            )}
            name={`${fieldName}.relationshipTemplate.id`}
            component={RelationshipTemplateSelect}
            placeholder={t('entity.relationships.selectRelationshipTemplate')}
            additionalProps={{
              allowClear: true,
              childId: field.value.child.id,
              relationshipScopeId,
              withoutOwnershipTemplates: false,
              entryType: field.value.parent.type,
              onSelect: (newValue: any, newTemplate: any) =>
                handleTemplateSelect(newValue, newTemplate.model),
            }}
          />
        </Col>

        <Col span={12}>
          <FormField
            disabled
            label={t(
              'entity.relationships.relationship_modal.form_fields.child_node',
            )}
            name={`${fieldName}.child.label`}
            placeholder={t(
              'entity.relationships.relationship_modal.placeholder_optional',
            )}
            component={FormInput}
          />
        </Col>

        <Col span={12}>
          <FormField
            disabled
            label={t(
              'entity.relationships.relationship_modal.form_fields.child_relationship_template',
            )}
            name={`${fieldName}.relationshipTemplate.childRelationshipLabel`}
            placeholder={t(
              'entity.relationships.relationship_modal.placeholder_optional',
            )}
            component={FormInput}
          />
        </Col>
      </Row>

      {!!field.value.additionalFields.length && (
        <>
          <SectionIntro
            title={t(
              'entity.relationships.relationship_modal.additional_fields_section',
            )}
            titleVariant="h5"
          />
          <Row gutter={[16, 0]}>
            {renderAdditionalForm(field.value.additionalFields)}
          </Row>
        </>
      )}
    </>
  );
};

const StyledDeleteButton = styled(DeleteButton)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: ${({ theme }) => theme.marginXXs};
`;

export default ClientRelationshipForm;
