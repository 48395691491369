import React, { useMemo } from 'react';

// helpers
import usersAPI from '../../../../../../../../api/orac/usersAPI';
import useTranslation from '../../../../../../../../hooks/useTranslation';
import { SecurityKeyModel } from '../../../../../../../../typings/profile/fido';

// components
import { message } from 'antd';
import SecurityKeyModal, {
  FormValuesModel,
} from '../../../../../../../../components/Modals/TemplateModalDialogs/Profile/SecurityKeyModal';

interface IProps {
  securityKey: SecurityKeyModel | null;
  userId: string;
  isVisible: boolean;
  closeCallback: (wasUpdated?: boolean) => void;
}

const EditSecutiryKeyModal = ({
  securityKey,
  userId,
  isVisible,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('orac');

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!securityKey) {
      return null;
    }

    const { name } = securityKey;

    return { name };
  }, [securityKey]);

  const handleSubmit = async (values: FormValuesModel) => {
    if (securityKey) {
      await usersAPI.updateSecurityKeyByUserId(securityKey._id, userId, {
        name: values.name /*, userId*/,
      });
      message.success(
        t(
          'users.view.two_step_verification.security_key.edit_key.success_submit_message',
        ),
      );
    }
  };

  return (
    <SecurityKeyModal
      title={t('users.view.two_step_verification.security_key.edit_key.title')}
      onSubmit={handleSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialFormValues={initialFormValues}
    />
  );
};

export default EditSecutiryKeyModal;
