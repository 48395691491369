import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { FILTER_BAR_FIELDS_GRID_SIZES } from 'constants/grids';
import { FilterByIsTriggeredStatusOptions } from '..';

// components
import { Col, Row, Select, Typography } from 'antd';

interface IProps {
  selectedStatus: FilterByIsTriggeredStatusOptions;
  onFilterChange: (
    key: string,
    value: FilterByIsTriggeredStatusOptions,
  ) => void;
}

const FilterBar = ({ selectedStatus, onFilterChange }: IProps) => {
  const { t } = useTranslation(['compliance', 'common']);

  return (
    <Row gutter={[16, 16]}>
      <Col {...FILTER_BAR_FIELDS_GRID_SIZES}>
        <Row>
          <Col span={24}>
            <Typography.Text>
              {t('transactions.filter_by_is_triggered_status')}
            </Typography.Text>
          </Col>
          <Col span={24}>
            <StyledSelect
              size="large"
              placeholder={t('select_option', { ns: 'common' })}
              value={selectedStatus}
              onChange={(value) =>
                onFilterChange(
                  'is_triggered_status',
                  value as FilterByIsTriggeredStatusOptions,
                )
              }
            >
              <Select.Option value={FilterByIsTriggeredStatusOptions.All}>
                {t('all', { ns: 'common' })}
              </Select.Option>
              <Select.Option
                value={FilterByIsTriggeredStatusOptions.IsTriggered}
              >
                {t('transactions.is_triggered')}
              </Select.Option>
              <Select.Option
                value={FilterByIsTriggeredStatusOptions.NotTriggered}
              >
                {t('transactions.not_triggered')}
              </Select.Option>
            </StyledSelect>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default FilterBar;
