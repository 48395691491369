import { SessionHelpers } from '@helpers/sessions';
import { DocumentHelpers } from '@helpers/documents';
import { AccountRecoveryRequestModel } from '../../typings/ORAC/accountRecoveryRequests';

export const accountRecoveryRequestAdapter = {
  fetchAccountRecoveryRequestById: async (
    recoveryRequest: AccountRecoveryRequestModel,
  ): Promise<AccountRecoveryRequestModel> => {
    const fingerprint = await SessionHelpers.generateFingerprint();

    const formattedDocumentsArray = recoveryRequest.document.map(
      ({ page, src }) => {
        const parsedSrc = JSON.parse(src);
        const documentImageUrl = DocumentHelpers.getDocumentLink(
          parsedSrc?._id,
          parsedSrc?.files[0].id,
          fingerprint,
        );
        return { page, src: documentImageUrl };
      },
    );

    const parsedSelfieSrc = JSON.parse(recoveryRequest?.selfieSrc || '');

    const selfieImgUrl = DocumentHelpers.getDocumentLink(
      parsedSelfieSrc?._id,
      parsedSelfieSrc?.files[0]?.id,
      fingerprint,
    );

    const result = {
      ...recoveryRequest,
      document: formattedDocumentsArray,
      selfieSrc: selfieImgUrl,
    };

    return result;
  },
};
