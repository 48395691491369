import React, { useEffect, useState } from 'react';

// helpers
import styled from 'styled-components';
import useFetch from '../../../../hooks/useFetch';
import useTranslation from '../../../../hooks/useTranslation';
import { accountNotificationsAPI } from '../../../../api/profile/accountNotificationsAPI';
import { NotificationPreferenceModel } from '../../../../typings/profile/accountNotifications';

// components
import LoadingWrapper from '../../../../components/WrapperComponents/LoadingWrapper';
import { CheckOutlined } from '@ant-design/icons';
import { CardWrapper } from '../../../../layouts/PageLayout';
import { Col, Divider, message, Row, Switch, Typography } from 'antd';

interface INotificationPreference {
  key: string;
  loading: boolean;
  isEmailEnabled: boolean;
  isSmsEnabled: boolean;
  isTeamsEnabled: boolean;
  title: string;
  helperMessage: string;
}

enum NotificationsType {
  Email = 0,
  SMS,
  // Teams,
}

const NotificationPreferences = () => {
  const { t } = useTranslation('profile');

  const [notificationPreferences, setNotificationPreferences] = useState<
    INotificationPreference[]
  >([]);

  const { response, loading: getPreferencesLoader } = useFetch(() =>
    accountNotificationsAPI.fetchNotificationSettings(),
  );

  useEffect(() => {
    if (response) {
      const formattedPreferences = response.data.map(
        (e: NotificationPreferenceModel) => ({
          key: e.code,
          isEmailEnabled: e.emailEnabled,
          isSmsEnabled: e.smsEnabled,
          isTeamsEnabled: e.teamsEnabled,
          loading: false,
          title: t(`notification_preferences.preferences.${e.code}`) as string,
          helperMessage: t(
            `notification_preferences.preferences.${e.code}_helper`,
          ),
        }),
      );

      setNotificationPreferences(formattedPreferences);
    }
  }, [response]);

  const handleToggleChange = async (
    newState: boolean,
    rowIndex: number,
    type: NotificationsType,
  ) => {
    setLoaderToPreference(rowIndex, true);
    try {
      const preferencesCopy = notificationPreferences.slice();

      switch (type) {
        case NotificationsType.Email:
          preferencesCopy[rowIndex].isEmailEnabled = newState;
          break;
        case NotificationsType.SMS:
          preferencesCopy[rowIndex].isSmsEnabled = newState;
          break;
        // case NotificationsType.Teams:
        //   preferencesCopy[rowIndex].isTeamsEnabled = newState;
        //   break;
      }

      const updatedReference: NotificationPreferenceModel = {
        code: preferencesCopy[rowIndex].key,
        smsEnabled: preferencesCopy[rowIndex].isSmsEnabled,
        emailEnabled: preferencesCopy[rowIndex].isEmailEnabled,
        teamsEnabled: preferencesCopy[rowIndex].isTeamsEnabled,
      };

      await accountNotificationsAPI.updateNotifications(updatedReference);
      setNotificationPreferences(preferencesCopy);
      message.success(t('notification_preferences.success_edit'));
    } finally {
      setLoaderToPreference(rowIndex, false);
    }
  };

  const setLoaderToPreference = (rowIndex: number, status: boolean) => {
    const preferencesCopy = notificationPreferences.slice();
    preferencesCopy[rowIndex].loading = status;
    setNotificationPreferences(preferencesCopy);
  };

  const renderNotificationPreferences = () => {
    if (!notificationPreferences.length) return null;

    return notificationPreferences.map((e, i) => (
      <>
        <Row key={i}>
          <Col flex="70%">
            <StyledParagraph>{e.title}</StyledParagraph>
            <StyledSubParagraph>{e.helperMessage}</StyledSubParagraph>
          </Col>

          {/*
          // UNCOMMENT WHEN AN API WILL BE READY
          <StyledToggleCol flex='10%'>
            <Switch
              loading={e.loading}
              checked={e.isTeamsEnabled}
              checkedChildren={<CheckOutlined />}
              onChange={(value: boolean) => handleToggleChange(value, i, NotificationsType.Teams)}
            />
          </StyledToggleCol> */}
          <StyledToggleCol flex="15%">
            <Switch
              loading={e.loading}
              checked={e.isSmsEnabled}
              checkedChildren={<CheckOutlined />}
              onChange={(value: boolean) =>
                handleToggleChange(value, i, NotificationsType.SMS)
              }
            />
          </StyledToggleCol>

          <StyledToggleCol flex="15%">
            <Switch
              loading={e.loading}
              checked={e.isEmailEnabled}
              checkedChildren={<CheckOutlined />}
              onChange={(value: boolean) =>
                handleToggleChange(value, i, NotificationsType.Email)
              }
            />
          </StyledToggleCol>
        </Row>
        <StyledDivider />
      </>
    ));
  };

  return (
    <CardWrapper>
      <Row justify="end">
        <Col flex="70%">
          <Typography.Title level={4}>
            {t('notification_preferences.title')}
          </Typography.Title>
        </Col>
        {/* 
        // UNCOMMENT WHEN AN API WILL BE READY
        <Col flex='10%'>
          <CustomTitle level={4}>Teams</CustomTitle>
        </Col> */}
        <Col flex="15%">
          <CustomTitle level={4}>Sms</CustomTitle>
        </Col>
        <Col flex="15%">
          <CustomTitle level={4}>Email</CustomTitle>
        </Col>
      </Row>

      <LoadingWrapper loading={getPreferencesLoader}>
        {renderNotificationPreferences()}
      </LoadingWrapper>
    </CardWrapper>
  );
};

const StyledToggleCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledParagraph = styled(Row)`
  margin-bottom: 0px !important;
`;

const StyledSubParagraph = styled(Row)`
  margin-bottom: 0px !important;
  font-size: 13px;
  color: ${({ theme }) => theme.textLightColor3};
`;

const StyledDivider = styled(Divider)`
  margin: 10px 0px;
`;

const CustomTitle = styled(Typography.Title)`
  display: flex;
  justify-content: flex-end;
`;

export default NotificationPreferences;
