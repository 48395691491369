import React from 'react';

// helpers
import useTranslation from '../../../../../../hooks/useTranslation';
import reviewConfigurationAPI from '../../../../../../api/compliance/reviewConfigurationAPI';
import { SelectProps } from 'antd';
import { ReferenceObjectBaseModel } from '../../../../../../typings/compliance/configurationRules';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';

// components
import Autocomplete, {
  AutocompleteInitialValue,
  AutocompleteOption,
} from '../../../../../Fields/Autocomplete';

interface IProps extends SelectProps<any> {
  value?: string;
  initialValue?: AutocompleteInitialValue | AutocompleteInitialValue[];
}

// TODO: integrate to the API
const ReferenceObjectsAutocomlete = (props: IProps) => {
  const { t } = useTranslation('compliance');

  const mapObject = (object: ReferenceObjectBaseModel) => {
    return { id: object.id, content: object.name, model: object };
  };

  const fetchContexts = async (searchQuery: string) => {
    const { data } = await reviewConfigurationAPI.fetchReferenceObjects({
      page: 1,
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      name: searchQuery,
    });

    return data.map<AutocompleteOption<ReferenceObjectBaseModel>>(mapObject);
  };

  return (
    <Autocomplete
      {...props}
      placeholder={t('review_configuration.reference_object_ph')}
      fetchData={fetchContexts}
    />
  );
};

export default ReferenceObjectsAutocomlete;
