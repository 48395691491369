import APIConfig from '../../../config/api';
import { APIService } from '../../axiosInstance';
import { IOnboardingStatus } from '../../../typings/crm/onboarding';
import {
  AuditData,
  FetchParamsModel,
  FetchResponseModel,
} from 'typings/common';

function getApplicationHeaders({
  crmItemId,
  clientGroupId,
}: {
  crmItemId?: string;
  clientGroupId?: string;
}) {
  const headers: any = {};

  if (crmItemId) {
    headers.crmItemId = crmItemId;
  }

  if (clientGroupId) {
    headers.clientGroupId = clientGroupId;
  }

  return headers;
}

export type ApprovalGroupTypes =
  | 'onboarding-transaction'
  | 'onboarding-account-management';
export type ApprovalRuleTypes =
  | 'onboarding-transaction'
  | 'onboarding-account-management';

export type OnboardingApprovalGroup = {
  _id: string;
  _audit: AuditData;
  name: string;
  description: string;
  type: ApprovalGroupTypes;
  status: string;
  validationErrors: { code: number; message: string }[];
  participants: {
    contactId: string;
    name: string;
  }[];
};

export type OnboardingApprovalRuleFromQuery = {
  _id: string;
  _audit: AuditData;
  type: ApprovalRuleTypes;
  associatedAccounts: {
    _id: string;
    type: string;
    clientGroupId: string;
    currencyId: number;
    currencyName: string;
    currencyIsoCode: string;
    purpose: string;
  }[];
  ruleSets: {
    items: {
      group: {
        id: string;
        name: string;
        participants: {
          contactId: string;
          name: string;
        }[];
      };
      minimumThreshold: number;
    }[];
  }[];
  criteriaList: {
    type: 'range' | 'key';
    activeSetIndices: number[];
    range?: { min: number; max: number | null };
    key?: string;
  }[];
  validationErrors?: { code: number; message: string }[];
};

const onboardingAPI = {
  getStatus: (crmItemId?: string, clientGroupId?: any) => {
    return APIService.get<IOnboardingStatus>(
      `${APIConfig.crmApi}/onboarding/status`,
      {
        headers: getApplicationHeaders({ crmItemId, clientGroupId }),
      },
    ).then(({ data }) => data);
  },

  fetchApprovalGroups: (
    params: FetchParamsModel,
    clientGroupId: string | number,
    type: ApprovalGroupTypes,
  ) => {
    return APIService.get<FetchResponseModel<OnboardingApprovalGroup>>(
      `${APIConfig.crmApi}/onboarding/approval-groups/${clientGroupId}`,
      {
        params: { ...params, type },
      },
    ).then((response) => response?.data);
  },

  fetchApprovalGroupById: (
    approvalGroupId: string,
    clientGroupId: string | number,
  ) => {
    return APIService.get<OnboardingApprovalGroup>(
      `${APIConfig.crmApi}/onboarding/approval-groups/${clientGroupId}/${approvalGroupId}`,
    ).then((response) => response?.data);
  },

  fetchApprovalRules: (
    clientGroupId: number,
    params: FetchParamsModel,
    type: ApprovalRuleTypes,
  ) => {
    return APIService.get<FetchResponseModel<OnboardingApprovalRuleFromQuery>>(
      `${APIConfig.crmApi}/onboarding/approval-rules/${clientGroupId}`,
      {
        params: { ...params, type },
      },
    ).then((response) => response?.data);
  },

  fetchApprovalRuleById: (approvalRuleId: string, clientGroupId: number) => {
    return APIService.get<OnboardingApprovalRuleFromQuery>(
      `${APIConfig.crmApi}/onboarding/approval-rules/${clientGroupId}/${approvalRuleId}`,
    ).then((response) => response?.data);
  },
};

export { onboardingAPI };
