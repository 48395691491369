import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '@hooks/useTranslation';
import { IBank } from 'api/banking/bankingBankAccountsAPI';
import { CountryHelpers } from '@helpers/crm/country';
import { FormValuesModel } from '..';
import { OTHER_BANK_CODE } from 'constants/accounts';
import { useFormikContext } from 'formik';

// components
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import CountryFormSelect from 'components/Forms/FormComponents/SelectInputs/CountryFormSelect';
import BankAutocomplete, {
  BankAutocompleteProps,
} from 'components/Forms/FormComponents/Autocompletes/Banking/BankAutocomplete';
import BankCodeTypeSelect, {
  IProps as BankCodeTypeSelectProps,
} from 'components/Forms/FormComponents/SelectInputs/BankCodeTypeSelect';
import { Form, Input as InputAntD } from 'antd';

interface IProps {
  fieldBaseName: string;
}

const gridSizes = { xl: 4, lg: 4, md: 5, sm: 24, xs: 24 };

const BankForm = ({ fieldBaseName }: IProps) => {
  const { t } = useTranslation('finance');

  const form = useFormikContext<FormValuesModel>();
  const selectedBankCode = form.getFieldProps(
    `${fieldBaseName}.bankCodeType`,
  ).value;

  const isOtherBankCode = selectedBankCode === OTHER_BANK_CODE;

  const handleBankSelect = (_: string | number, option: { model?: IBank }) => {
    if (option.model) {
      const {
        bankCode,
        bankName,
        country,
        bankAddress1,
        city = '',
        postCode = '',
        district = '',
      } = option.model;

      form.setFieldValue(fieldBaseName, {
        ...form.getFieldProps(fieldBaseName).value,
        bankCode,
        bankName,
        country,
        address: bankAddress1,
        city,
        district: CountryHelpers.getDistrict(country, district) || undefined,
        postCode,
      });
    }
  };

  const handleBankCodeTypeSelect = (newValue: string) => {
    form.setFieldValue(fieldBaseName, {
      bankCodeType: newValue,
      bankCode: undefined,
      bankName: undefined,
      country: undefined,
      address: undefined,
      city: undefined,
      district: undefined,
      postCode: undefined,
    });
  };

  return (
    <>
      <StyledFormItem
        label={t(
          'accounts.wire_instructions_details.beneficiary_bank.form_fields.bank_code',
        )}
        labelAlign="left"
        labelCol={gridSizes}
      >
        <InputGroupWrapper compact>
          <FormField<BankCodeTypeSelectProps>
            name={`${fieldBaseName}.bankCodeType`}
            component={StyledBankCodeTypeSelect}
            additionalProps={{
              onSelect: (newValue: string) =>
                handleBankCodeTypeSelect(newValue),
            }}
          />
          <FormField<BankAutocompleteProps>
            name={`${fieldBaseName}.bankCode`}
            component={
              isOtherBankCode ? StyledFormInput : StyledBankAutocomplete
            }
            disabled={!selectedBankCode}
            additionalProps={{
              onSelect: !isOtherBankCode ? handleBankSelect : undefined,
              bankCodeType: selectedBankCode,
            }}
          />
        </InputGroupWrapper>
      </StyledFormItem>

      <FormField
        name={`${fieldBaseName}.bankName`}
        component={FormInput}
        label={t(
          'accounts.wire_instructions_details.beneficiary_bank.form_fields.bank_name',
        )}
        labelCol={gridSizes}
      />

      <FormField
        name={`${fieldBaseName}.country`}
        component={CountryFormSelect}
        label={t(
          'accounts.wire_instructions_details.beneficiary_bank.form_fields.country',
        )}
        labelCol={gridSizes}
      />

      <FormField
        name={`${fieldBaseName}.address`}
        component={FormInput}
        label={t(
          'accounts.wire_instructions_details.beneficiary_bank.form_fields.address',
        )}
        labelCol={gridSizes}
      />

      <FormField
        name={`${fieldBaseName}.city`}
        component={FormInput}
        label={t(
          'accounts.wire_instructions_details.beneficiary_bank.form_fields.city',
        )}
        labelCol={gridSizes}
      />

      <FormField
        name={`${fieldBaseName}.district`}
        component={FormInput}
        label={t(
          'accounts.wire_instructions_details.beneficiary_bank.form_fields.district',
        )}
        labelCol={gridSizes}
      />

      <FormField
        name={`${fieldBaseName}.postCode`}
        component={FormInput}
        label={t(
          'accounts.wire_instructions_details.beneficiary_bank.form_fields.postcode',
        )}
        labelCol={gridSizes}
      />
    </>
  );
};

const InputGroupWrapper = styled(InputAntD.Group)`
  &.ant-input-group {
    display: flex;
  }
  .ant-form-item:last-child {
    width: 100%;
  }
`;

const StyledBankCodeTypeSelect = styled(BankCodeTypeSelect)`
  width: 250px;
  min-width: 250px;
`;

const StyledFormInput = styled(FormInput)`
  width: 100%;
`;

const StyledBankAutocomplete = styled(BankAutocomplete)`
  width: 100% !important;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

export default BankForm;
