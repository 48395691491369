import React, { memo, useCallback } from 'react';

// helpers
import useTranslation from '@hooks/useTranslation';
import { generateUniqId } from '@helpers/utils';
import { FormValuesModel } from '..';

// components
import AddButton from '@common_components/Buttons/AddButton';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import EmailAddressForm, {
  FormValuesModel as EmailAddressFormValuesModel,
} from '../../EmailAddressForm';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';

const MAX_EMAILS_CAN_BE_ADDED = 5;

const EmailAddressSection = memo(() => {
  const { t } = useTranslation('crm');
  const { values } = useFormikContext<FormValuesModel>();

  const renderItems = useCallback(
    (arrayHelpers: FieldArrayRenderProps) => {
      return (
        <>
          {values.emails.map((e, i) => (
            <EmailAddressForm
              key={e.id}
              baseName={`emails.${i}`}
              onRemove={() => arrayHelpers.remove(i)}
            />
          ))}

          {values.emails.length < MAX_EMAILS_CAN_BE_ADDED && (
            <HideIfDisabledForm>
              <AddButton
                onClick={() =>
                  arrayHelpers.push({
                    id: generateUniqId(),
                    type: null,
                    address: '',
                    primary: { status: false },
                  } as EmailAddressFormValuesModel)
                }
              >
                {t('contacts.new.add_email_address')}
              </AddButton>
            </HideIfDisabledForm>
          )}
        </>
      );
    },
    [values],
  );

  return <FieldArray name="emails" render={renderItems} />;
});

export default EmailAddressSection;
